import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { dadosCadastrarFichaContabil, dadosCadastroFichaGeral, FaturaContasAPagar, FichaContabilFornecedor, FichaGeralFornecedor } from '../cadastro-fornecedor/CadastroFornecedorModels';
import { RegistrarPagamentosModel } from '../gestao-simplificada/registrar-pagamentos/registrar-pagamentos.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const sessao = new SessaoUser

export class RegistrarPagamentosService {


    url = new environment().api
    api = "FichaPagamento/"
    api_contabil = "FichaContabilFornecedor/"
    api_geral = "FichaGeralFornecedor/"
    api_pagamento = 'FaturaContasAPagar/'
    api_cartaoCredito = 'CadastroCartaoCredito/'

    constructor(private http: HttpClient, private data: BuscarDataService) { }

    listar() {
        return this.http.get<RegistrarPagamentosModel[]>(this.url + this.api)
    }

    async getGeralFornecedor(codigoEmpresa) {
        let result = []
        await this.http.get<FichaGeralFornecedor[]>(this.url + this.api_geral).toPromise()
            .then(async res => {
                let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)

                for (let i = 0; i < filter.length; i++) {
                    let code = filter[i].codigo
                    let item = await this.listarFornecedor(code)
                    result.push(item)
                }
            })
        return result
    }
    async getCartaoCredito(codigoEmpresa) {
        let result = []
        await this.http.get<FaturaContasAPagar[]>(this.url + this.api_cartaoCredito).toPromise()
            .then(res => {
                let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)
                result = filter
            })
        return result
    }
    async getContasApagar(codigoEmpresa) {
        let result = []
        await this.http.get<FaturaContasAPagar[]>(this.url + this.api_pagamento).toPromise()
            .then(res => {
                let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)
                result = filter
            })
        return result
    }
    cadastrarContasApagar(dado: FaturaContasAPagar) {
        dado.status = "SDO0121"
        return this.http.post<FaturaContasAPagar[]>(this.url + this.api_pagamento, dado)
    }
    alterarContasApagar(dado: FaturaContasAPagar) {
        return this.http.put<FaturaContasAPagar[]>(this.url + this.api_pagamento + dado.id, dado)
    }
    deleteContasApagar(dado: FaturaContasAPagar) {
        return this.http.put<FaturaContasAPagar[]>(`${this.url}${this.api_pagamento}${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}`, dado)
    }
    async listarFornecedor(codigoFornecedor): Promise<FichaContabilFornecedor[]> {
        let result: any = []
        await this.http.get<FichaContabilFornecedor[]>(this.url + this.api_contabil + `FiltrarCodigoFornecedor/${codigoFornecedor}`)
            .toPromise().then(res => result = res)
        return result
    }

    enviar(registro: RegistrarPagamentosModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: RegistrarPagamentosModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: RegistrarPagamentosModel, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        }
        else {
            registro.status = 'SDA0002'
        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }
}