import { Component, OnInit, TemplateRef } from '@angular/core';
import { TokenDeAcessoModel } from './modalTokenDeAcesso';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { MatTableDataSource, MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';

interface DetalhesToken {
  cpf: string;
  nome: string;
  assinatura?: number;
  nomeEmpresa?: any[]
  tipoHabilitacao?: string;
  statusDaAssinatura?: string;
  statusDaEmpresa?: string;
  statusDoAdministrador?: string;
  statusDoClinico?: string;
  statusDaConexao?: string;
  statusDoRegistroProfissional?: string;
  statusUsuario?: string;
  statusHabilitacao?: string;
  statusRegistro?: string
}

interface RespostaApi {

  cpfUsuario?: string;
  administradorAssinante?: string;
  codigoAssinante?: number;
  cpfResponsavel?: string;
  cpfAdministrador?: string;
  nomeEmpresa?: string;
  codigoEmpresa?: number;
}


@Component({
  selector: 'app-admin-token',
  templateUrl: './admin-token.component.html',
  styleUrls: ['./admin-token.component.scss']
})
export class AdminTokenComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['cpf', 'assinatura', 'empresa', 'tipoHabilitacao', 'statusDaAssinatura', 'statusDaEmpresa', 'statusHabilitacao', 'statusRegistro', 'detalhes']

  detalhesToken: DetalhesToken

  respostaApi: RespostaApi[]

  viewDetalhe: boolean

  _formulario: FormGroup

  form = new TokenDeAcessoModel

  modalAberto: boolean

  dataSource

  modalRef: BsModalRef;

  registrosTabela

  constructor(
    private modalService: BsModalService,
    private service: TokenDeAcessoService,
    private mensagem: MatSnackBar,
    private _form: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.AtualizarRegistros()

  }

  CreateForm() {
    this._formulario = this._form.group({
      cpf: new FormControl(''),
      assinatura: new FormControl(''),
      empresa: new FormControl(''),
      tipoHabilitacao: new FormControl(''),
      statusDaAssinatura: new FormControl(''),
      statusDaEmpresa: new FormControl(''),
      statusDoAdministrador: new FormControl(''),
      statusDoClinico: new FormControl(''),
      statusDaConexao: new FormControl(''),
      statusDoRegistroProfissional: new FormControl(''),
      statusUsuario: new FormControl(''),
      statusHabilitacao: new FormControl(''),
      statusRegistro: new FormControl('')

    })
  }

  async AtualizarRegistros() {
    this.service.Get().toPromise().then(
      async (registro: TokenDeAcessoModel[]) => {
        registro.forEach(async item => {
          item.statusDaAssinatura = await this.service.TraduzirStatus(item.statusDaAssinatura)
          item.statusDaConexao = await this.service.TraduzirStatus(item.statusDaConexao)
          item.statusDaEmpresa = await this.service.TraduzirStatus(item.statusDaEmpresa)
          item.statusDoAdministrador = await this.service.TraduzirStatus(item.statusDoAdministrador)
          item.statusDoClinico = await this.service.TraduzirStatus(item.statusDoClinico)
          item.statusDoRegistroProfissional = await this.service.TraduzirStatus(item.statusDoRegistroProfissional)
          item.statusHabilitacao = await this.service.TraduzirStatus(item.statusHabilitacao)
          item.statusUsuario = await this.service.TraduzirStatus(item.statusUsuario)
        });
        this.dataSource = new MatTableDataSource(registro)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort

        console.log('aa' + registro)
      }
    ).catch(
      (err) => {
        console.log(err)
      }
    )
  }

  Filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }

  ModalAlterar(registro, id) {
    this.modalAberto = true
    this.form = registro
    this.form.id = id
  }

  AlterarRegistro() {
    // this.service.Put(this.form.id, this.form).toPromise().then(
    //   (res) => {
    //     this.mensagem.open('Registro alterado com sucesso', 'OK')
    //   }
    // ).catch((err) => {
    //   this.mensagem.open(err, 'OK')
    // })
  }

  openAdicionar(Adicionar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(Adicionar);
    this._formulario.reset();
    this.form = new TokenDeAcessoModel

  }

  async AbrirDetalhe(form) {

    this.viewDetalhe = true
    this.detalhesToken = form
    this.detalhesToken.nome = await this.service.ConsultarNome(form.cpf);
    this.respostaApi = await this.service.getEmpresas(form.cpf);
    this.detalhesToken.nomeEmpresa = []
    let empresas = []
    this.respostaApi.forEach((res) => empresas.push(res.nomeEmpresa))
    let removeDublicate = new Set(empresas)
    removeDublicate.forEach(element => {
      this.detalhesToken.nomeEmpresa.push(element)
    });
    console.log('teste array', removeDublicate, [removeDublicate])
  }

  FecharDetalhes() {
    this.viewDetalhe = false
    this.detalhesToken = null
    this.AtualizarRegistros()
  }


  //End
}
