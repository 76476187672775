import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { ConciliacaoBancariaService } from 'src/app/service/conciliacao-bancaria.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { ConciliacaoBancariaModel, FichaControleBancarioCabecalhoModel, FichaControleBancarioItemModel } from './conciliacao-bancaria.model';


const sessao = new SessaoUser

@Component({
  selector: 'app-conciliacao-bancaria',
  templateUrl: './conciliacao-bancaria.component.html',
  styleUrls: ['./conciliacao-bancaria.component.scss']
})
export class ConciliacaoBancariaComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort
  @ViewChild(MatSort, { static: false }) sortExtrato: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'banco', 'agencia', 'conta']
  displayedConciliacao = ['acao', 'realizar', 'periodo', 'status', 'delete']
  displayedRealizarConciliacao = ['acao', 'diaConciliacao', 'tipoLancamentoBancario', 'descricaoLancamento', 'valorLancamento']
  listRegistro: ConciliacaoBancariaModel[]
  listPeriodo = []
  listExtrato: FichaControleBancarioItemModel[]
  formulario: FormGroup
  formPeriodo: FormGroup
  formExtrato: FormGroup
  viewConciliacaoBancaria: boolean
  viewRealizarConciliacao: boolean
  data: ConciliacaoBancariaModel[]
  listEmpresa = []
  modoCriacao: boolean
  modoExibir: boolean
  modoAlteracao: boolean
  loading: boolean
  alteracao = false
  listBanco = []
  registroBanco = new ConciliacaoBancariaModel
  viewExtratoBancario: boolean
  listTipoLanc = []
  botaoSalvar = false
  botaoCancelar = false
  viewPeriodo: boolean
  statusConciliacao: any
  statusConciliado: any
  dataSource = new MatTableDataSource()
  dataSourceExtrato = new MatTableDataSource()
  selectEmpresa = 0
  viewConfirmDeletePeriodo: boolean
  viewConfirmDeleteExtrato: boolean
  viewConfirmCancelPeriodo: boolean
  viewConfirmCancelExtrato: boolean
  activePerido: any;
  listDias: any[];
  registroConciliacao: FichaControleBancarioCabecalhoModel
  testeFilter: any;
  listBancos = []
  botaoReabrir: boolean = false
  botaoEncerrar: boolean = false
  itemSelecionado
  validExtrato: boolean;
  buscarExtrato: any;
  selected: any
  sortedData: FichaControleBancarioItemModel[]

  constructor(
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private service: ConciliacaoBancariaService,
    private conteudo: ConteudoDeDadosService,
    private mensagem: MensagemService,
    private _status: StatusSistemaService,
    private _filtro: FiltrarDadosService
  ) { }

  async ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getTipoLanc()
    this.getStatus()
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: sessao.cpf,
      codigoContaBancaria: 0,
      pais: '',
      banco: '',
      agencia: '',
      numeroConta: '',
      endereco: '',
      contatoGerente: '',
      contaContabil: '',
      codigoEmpresa: 0,
      status: '',
      criadoEm: '',
      //

      nomeEmpresa: '',
      descBanco: ''
    })

    this.formPeriodo = this.form.group({
      id: '',
      criadoPor: sessao.cpf,
      codigoEmpresa: 0,
      codigoContaBancaria: 0,
      numeroDocumento: '',
      periodoConciliacao: '',
      dataDocumento: '',
      criadoEm: '',
      status: '',
      // 
      descStatus: ''
    })

    this.formExtrato = this.form.group({
      id: '',
      criadoPor: sessao.cpf,
      codigoEmpresa: 0,
      diaConciliacao: '',
      criadoEm: '',
      status: '',
      tipoLancamentoBancario: '',
      descricaoLancamento: '',
      valorLancamento: 0,
      numeroDocumento: '',
      nrItem: 0,
      ///
      nomeEmpresa: ''
    })
  }

  async getBancos(banco) {
    this.service.getBancos().subscribe(response => {
      banco.filter(item => {
        if (item.banco == response.codigoBanco) {
          banco = response.NomeBanco
        }
      })
    })
  }

  statusCheckbox(event, item) {
    let reqcheck = event.checked
    if (reqcheck) {
      this.selected = item.id
      if (item.status == 'SDO0134') {
        this.botaoEncerrar = true
        this.botaoReabrir = false
        this.itemSelecionado = item
      } else {
        this.botaoReabrir = true
        this.botaoEncerrar = false
        this.itemSelecionado = item
      }

    } else {
      this.selected = null
      this.botaoEncerrar = false
      this.botaoReabrir = false
    }
  }

  async reabrirConciliacao(item) {
    item = this.itemSelecionado

    await this.service.conciliacao(item, false)
    this.mensagem.creat('Período reaberto com sucesso.', { sucesso: true })
    this.getRegistro()
  }

  async encerrarConciliacao(item) {
    item = this.itemSelecionado

    await this.service.conciliacao(item, true)
    this.mensagem.creat('Período encerrado com sucesso.', { sucesso: true })
    this.getRegistro()
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getStatus() {
    let status = await this._status.getStatus(false, true, 'SDO0134')
    let status2 = await this._status.getStatus(false, true, 'SDO0135')
    this.statusConciliacao = status
    this.statusConciliado = status2
  }

  async getTipoLanc() {
    let lancamento = await this.conteudo.get('TIPLANBANC')
    this.listTipoLanc = lancamento
  }

  //SESSÃO CADASTRO

  openCadastro(registro) {
    this.formulario.patchValue(registro)
    this.formPeriodo.patchValue(registro)

    localStorage.setItem('backup_registro', JSON.stringify(registro))
    this.formulario.disable()
    this.formPeriodo.reset()
    this.viewConciliacaoBancaria = true // viewCadastro
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
    this.alteracao = false
    this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  async getRegistro() {
    this.loading = true

    // lista as contas (tela 1)
    await this.service.listarContas().toPromise()
      .then(registro => {

        this.listRegistro = registro.filter(item => item.status == 'SCA0001')
        this.listRegistro.forEach(async item => {
          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)

          //traz o nome do banco de acordo com o código da empresa
          let banco = this.service.getBancos().subscribe(response => {
            response.filter(resp => {
              if (item.banco == resp.codigoBanco) {
                item.descBanco = resp.NomeBanco
              }
            })
          })
          if (empresa.length > 0) item.nomeEmpresa = empresa[0].nomeEmpresa
          item.descBanco = banco.toString()
        })

        this.dataSource.sort = this.sort
        this.loading = false
      })

    // lista os períodos (tela 2)
    await this.service.listarPeriodo().toPromise()
      .then(registro => {
        let conta = this.formulario.value
        this.listPeriodo = registro.filter(item => item.status == "SDO0135" || item.status == "SDO0134" && item.codigoContaBancaria == conta.codigoContaBancaria)
        this.listPeriodo.forEach(async item => {

          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
          let status = await this._status.getStatus(false, true, item.status)

          if (empresa.length > 0) item.nomeEmpresa = empresa[0].nomeEmpresa

          item.descStatus = status.toString()
        })

        this.listPeriodo.sort((a, b) => {
          let aPeriodo = a.periodoConciliacao
          let bPeriodo = b.periodoConciliacao
          return aPeriodo < bPeriodo ? 1 : -1
        })
        this.loading = false
      })

    // lista os extratos
    await this.service.listarExtrato().toPromise()
      .then(registro => {

        let periodo = this.formPeriodo.value
        this.listExtrato = registro.filter(item => item.status == "SDO0125" && item.numeroDocumento == periodo.numeroDocumento)
        this.listExtrato.forEach(async item => {

          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
          let tipo = await this.conteudo.get('TIPLANBANC', true, item.tipoLancamentoBancario)

          if (empresa.length > 0) item.nomeEmpresa = empresa[0].nomeEmpresa

          item.descTipo = tipo.toString()
        })

        this.listExtrato.sort((a, b) => {
          let aDia = parseInt(a.diaConciliacao)
          let bDia = parseInt(b.diaConciliacao)
          return aDia < bDia ? 1 : -1
        })
        // this.dataSourceExtrato.sort = this.sortExtrato
        this.loading = false
        this.botaoEncerrar = false
        this.botaoReabrir = false
        this.selected = null
      })
  }

  async fecharRegistro() {
    this.viewConciliacaoBancaria = false
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  //SESSÃO PERÍODO

  adicionarPeriodo() {
    this.alteracao = false
    this.viewPeriodo = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formPeriodo.enable()
    this.formPeriodo.reset()
    this.formPeriodo.controls['status'].setValue("SDO0134")
    this.formPeriodo.controls['status'].disable()
    this.modoExibir = false
    this.modoAlteracao = false
    this.modoCriacao = true
  }

  inserirPeriodo() {
    let registro = this.formPeriodo.value
    let conta = this.formulario.controls['codigoContaBancaria'].value
    let empresa = this.formulario.controls['codigoEmpresa'].value
    let data = this.listPeriodo

    // função que exclui os campos nulos
    Object.keys(registro).forEach(value => {
      if (!registro[value]) {
        delete registro[value]
      }
    })

    registro.criadoPor = sessao.cpf
    registro.status = "SDO0134"
    registro.codigoEmpresa = empresa
    registro.codigoContaBancaria = conta
    registro.criadoEm = ''
    registro.dataDocumento = ''

    if (!registro.periodoConciliacao) {
      return this.mensagem.creat('Preencha o campo Período com mês e ano para inserir.', { erro: true })
    }

    let resultadoFilter = data.filter(item => {
      return item.periodoConciliacao == registro.periodoConciliacao
    })

    if (resultadoFilter.length >= 1) {
      return this.mensagem.creat('Este período já existe. Por favor, adicione um novo período.', { erro: true })
    } else {
      this.service.enviarPeriodo(registro).toPromise()
        .then(() => {
          this.mensagem.creat('Cadastro criado com sucesso.', { sucesso: true })
          this.botaoSalvar = false
          this.botaoCancelar = false
          this.modoExibir = true
          this.modoCriacao = false
          this.modoAlteracao = false
          this.formPeriodo.reset()
          this.getRegistro()
        })
    }
  }

  alterarPeriodo() {
    let registro = this.formPeriodo.value
    this.service.alterarPeriodo(registro).toPromise()
      .then(() => {
        this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
        this.viewPeriodo = true
        this.formPeriodo.disable()
        this.modoExibir = true
        this.modoAlteracao = false
        this.modoCriacao = false
      }).catch(error => {
        let erro = error.error
        this.mensagem.creat(erro, { erro: true })
      })
  }

  async excluirPeriodo(confirm, registro?) {
    if (confirm) {

      this.activePerido = registro
      this.viewConfirmDeletePeriodo = true
      return
    }

    await this.service.listarExtrato().toPromise()
      .then(reg => {

        this.listExtrato = reg.filter(item => item.status == "SDO0125" && item.numeroDocumento == this.buscarExtrato)
        this.viewConfirmDeletePeriodo = false

        if (this.listExtrato.length > 0) {
          this.mensagem.creat('O período escolhido possui extratos lançados. Apague-os e tente novamente. ', { erro: true })
          return
        } else {
          if (!this.activePerido.id) return
          let result = this.service.desativarPeriodo(this.activePerido, false)
          if (result) {
            this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
            this.activePerido = null
            this.getRegistro()
              .finally(() => this.filtrarEmpresa())
          } else {
            this.mensagem.creat('Algo deu errado', { erro: true })
          }
        }
      })
  }

  async cancelarPeriodo(confirm?) {
    if (confirm) {
      this.viewConfirmCancelPeriodo = true
      return
    }

    this.viewConfirmCancelPeriodo = false
    let get = localStorage.getItem('backup_periodo')
    let registro = JSON.parse(get)
    this.formPeriodo.setValue(registro)
    this.formPeriodo.disable()
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
  }

  fecharPeriodo() {
    this.selected = null
    this.alteracao = true
    this.viewPeriodo = false
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
    this.formPeriodo.reset()
    this.getRegistro()
  }

  modificarPeriodo() {
    this.formPeriodo.enable()
    this.botaoSalvar = true
    this.botaoCancelar = true
    this.modoAlteracao = true
    this.modoExibir = false
    this.modoCriacao = false
  }

  /// SESSÃO TELA 3

  adicionarTela3() {
    this.viewRealizarConciliacao = true
    this.modoCriacao = true
  }

  adicionarExtrato() {
    let periodo = this.formPeriodo.controls['status'].value

    this.viewExtratoBancario = true

    if (periodo == 'SDO0135') {
      this.mensagem.creat('Não é possivel criar, alterar ou excluir extratos. Por favor, reabra a conciliação deste período e tente novamente. ', { erro: true })
      this.botaoSalvar = false
      this.botaoCancelar = false
      this.formExtrato.disable()
      this.formExtrato.reset()
      this.modoCriacao = true
      this.modoAlteracao = false
      this.modoExibir = false
    } else {
      this.botaoSalvar = true
      this.botaoCancelar = false
      this.formExtrato.enable()
      this.formExtrato.reset()
      this.formExtrato.controls['status'].setValue('SDO0125')
      this.modoCriacao = true
      this.modoAlteracao = false
      this.modoExibir = false

      let getData = this.formPeriodo.get('periodoConciliacao').value
      let data = new Date(getData.split('-')[0], getData.split('-')[1], 0).getDate()
      this.listDias = []
      for (let i = 1; i <= data; i++)this.listDias.push({ value: i })
    }

  }

  inserirExtrato() {
    let registro = this.formExtrato.value
    let periodo = this.formPeriodo.controls['numeroDocumento'].value
    let empresa = this.formulario.controls['codigoEmpresa'].value

    registro.criadoPor = sessao.cpf
    registro.codigoEmpresa = empresa
    registro.status = 'SDO0125'
    registro.numeroDocumento = periodo

    delete registro.criadoEm
    delete registro.descStatus
    delete registro.id
    delete registro.nomeEmpresa
    delete registro.nrItem

    this.service.enviarExtrato(registro).toPromise()
      .then(
        () => {
          this.mensagem.creat('Extrato inserido com sucesso.', { sucesso: true })
          this.viewExtratoBancario = true
          this.formExtrato.reset()
          this.botaoSalvar = true
          this.botaoCancelar = false
          console.log('resposta inserir: ', registro)
          this.getRegistro()
            .finally(() => this.filtrarEmpresa())
        }).catch(error => {
          this.mensagem.creat('Erro ao inserir. Verifique os campos e tente novamente.', { erro: true })
        })

    console.log('Inserir: ', registro)
  }

  alterarExtrato() {
    let registro = this.formExtrato.value

    this.service.alterarExtrato(registro).toPromise()
      .then(() => {
        this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
        this.viewExtratoBancario = true
        this.formExtrato.disable()
        this.botaoSalvar = false
        this.botaoCancelar = false
        this.modoExibir = true
        this.modoCriacao = false
        this.modoAlteracao = false
        this.getRegistro()

      }).catch(error => {
        let erro = error.error
        this.mensagem.creat(erro, { erro: true })
      })

  }

  async openExtrato(registro) {
    let periodo = this.formPeriodo.controls['status'].value

    delete registro.descTipo

    let getData = this.formPeriodo.get('periodoConciliacao').value
    let data = new Date(getData.split('-')[0], getData.split('-')[1], 0).getDate()
    this.listDias = []
    for (let i = 1; i <= data; i++) {
      this.listDias.push({ value: i.toString() })
    }
    this.formExtrato.patchValue(registro)
    localStorage.setItem('backup_extrato', JSON.stringify(registro))
    await this.formExtrato.disable()
    this.viewExtratoBancario = true
    if (periodo == 'SDO0135') {
      this.alteracao = false
      this.botaoSalvar = false
      this.botaoCancelar = false
      this.modoExibir = true
      this.modoAlteracao = false
      this.modoCriacao = false
      this.mensagem.creat('Não é possível criar, alterar ou excluir extratos. Por favor, reabra a conciliação deste período e tente novamente. ', { erro: true })
    } else {
      this.alteracao = true
      this.botaoSalvar = false
      this.botaoCancelar = false
      this.modoExibir = true
      this.modoAlteracao = false
      this.modoCriacao = false
    }
  }

  async excluirExtrato(confirm?) {
    if (confirm) {
      this.viewConfirmDeleteExtrato = true
      return
    }

    this.viewConfirmDeleteExtrato = false
    let registro = this.formExtrato.value
    delete registro.nomeEmpresa
    if (!registro.id) return

    await this.service.desativarExtrato(registro)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewExtratoBancario = false
    this.getRegistro()

  }

  fecharRealizarConciliacao() {
    this.viewRealizarConciliacao = false
    this.formPeriodo.enable()
    this.formPeriodo.reset()
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
    this.getRegistro()
  }

  async cancelarExtrato(confirm) {
    if (confirm) {
      this.viewConfirmCancelExtrato = true
      return
    }
    this.viewConfirmCancelExtrato = false
    let get = localStorage.getItem('backup_extrato')
    let registro = JSON.parse(get)
    this.formExtrato.setValue(registro)
    this.formExtrato.disable()
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
  }

  modificarExtrato() {
    this.formExtrato.enable()
    this.botaoSalvar = true
    this.botaoCancelar = true
    this.modoAlteracao = true
    this.modoExibir = false
    this.modoCriacao = false
  }

  async fecharExtrato() {
    this.viewExtratoBancario = false
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  openRealizarConciliacao(registro: FichaControleBancarioCabecalhoModel) {
    if (registro.status == 'SDO0135') {
      this.mensagem.creat('Não é possivel criar, alterar ou excluir extratos. Por favor, reabra a conciliação deste período e tente novamente. ', { erro: true })
    }
    this.formPeriodo.patchValue(registro)
    localStorage.setItem('backup_periodo', JSON.stringify(registro))
    this.formPeriodo.disable()
    this.viewRealizarConciliacao = true
    this.modoExibir = true
    this.modoAlteracao = false
    this.modoCriacao = false
    this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
    this.dataSourceExtrato.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      let filterExtrato = this._filtro.filterTodos(this.listExtrato, this.listEmpresa)
      this.dataSource.data = filter
      this.dataSourceExtrato.data = filterExtrato
      return
    }

    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    let filterExtrato = this.listExtrato.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.dataSourceExtrato.data = filterExtrato
  }



}
