export class FormCadastroEmpresa {
    id?: string
    name: any
    usuarioId: string
    consultorio: any
    companyType: any
    customerCode: any
    nameResponsavel: any
    unidade: any
    cnpj: any
    contrato: any
    createdAt: any
    updatedAt: any
    ativo: any
    logradouro: any
    numero: any
    complemento: any
    cep: any
    bairro: any
    cidade: any
    estado: any
    tipo: any
    planoContas: any
    areaCustos: any
    centroCustos: any
    organizaVend: any
    centroVendas: any
    recepcao: any
    especialidad: any
    cpf: any
    crm: any
    celular: any
    logradouroRe: any
    numeroRes: any
    complementoR: any
    cepRes: any
    bairroRes: any
    cidadeRes: any
    estadoRes: any
    endereco: any
}
export class FormCadastroProfissao {
    id: string
    usuarioId: string
    companyId: string
    nome: string
    cpf: string
    profissao: string
    crm: string
    especialidade: string
    telefone: string
    uFcrm: string
    createdAt: string
    ativo: boolean
}