import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { __await } from 'tslib';
import { FormUsuario } from '../usuario/model';

@Injectable({
  providedIn: 'root'
})
export class BuscarUsuarioService {
  url: string = new environment().api
  api: string = "Usuarios/"
  constructor(private http: HttpClient) {

  }
 async idCompany(id){
  let idCompany 
  console.log('id' +id)
  await this.http.get(this.url + this.api).toPromise()
   .then(
     (dados : FormUsuario[])=>{
       idCompany
      let filtro  = dados.filter(function(reg : FormUsuario){
          return reg.usuarioId == id
       })
       console.log('Filtro idCompany : ' + JSON.stringify(filtro))
     }
   )
  }
}
