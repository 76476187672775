import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
export class InteracoesMedicamentosas {
    id: string
    medicamento: string
    interageCom: string
    resultado: string
}
@Injectable({
  providedIn: 'root'
})
export class InteracaoMedicamentosaService {

  url = new environment().api + 'InteracoesMedicamentosas/'
  constructor(private http: HttpClient) { }

  listar() {
    console.log(this.url)
    return this.http.get<InteracoesMedicamentosas[]>(this.url)

  }
  pesquisar(med1: string, med2: string) {
    console.log(`${this.url}${med1}/${med2}`)
    med1 = med1.replace('/', ' ')
    med2 = med2.replace('/', ' ')
    med2 = med2.replace('+', '')
    med1 = med1.replace('+', '')
    return this.http.get<InteracoesMedicamentosas[]>(`${this.url}${med1}/${med2}`)
  }
  adicionar(dados: InteracoesMedicamentosas) {
    //console.log('Adicionar : ' + this.url+'MenuMaster/',dados)
    return this.http.post<InteracoesMedicamentosas>(this.url, dados)
  }

  editar(dados: InteracoesMedicamentosas, id: number) {
    console.log('Editar : ' + this.url + id, dados)
    return this.http.put<InteracoesMedicamentosas>(this.url + id, dados)
  }
  excluir(id: number) {
    //console.log('Deletar : ' + this.url + id)
    return this.http.delete<InteracoesMedicamentosas>(this.url + id)

  }
}
