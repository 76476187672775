import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessaoUser } from '../user.reg';
import { FiltrarDadosService } from './filtrar-dados.service';
const sessao = new SessaoUser
export class SIS_PERSONALIZACAO {
  id: string
  cpf: string
  aplicacao: string
  parametro: string
  valorParametro: string
  descricao: string
  valorPadrao: string
  criadoPor: string
  criadoEm: string
  status: string
}

@Injectable({
  providedIn: 'root'
})
export class PersonalizacaoService {
  url = new environment().api + 'SIS_PERSONALIZACAO/'
  constructor(private http: HttpClient, private _filter: FiltrarDadosService) { }


  async get(cpf) {
    let result: SIS_PERSONALIZACAO[] = []
    await this.http.get<SIS_PERSONALIZACAO[]>(this.url).toPromise()
      .then(res => {
        let filter = res.filter(value => value.status == 'SDA0001' && value.cpf == cpf)
        if (filter.length > 0) {
          result = filter
        }
      })
    return result
  }


  async cadastrar(item: SIS_PERSONALIZACAO) {
    Object.keys(item).forEach(key => { if (!item[key]) delete item[key] })
    let result = false
    let verifc = await this.get(item.cpf)
    if (verifc.length > 0) {
      let registro = verifc[0]
      registro.status = 'SDA0002'
      let response = await this.alterar(registro)
      if (!response) return
    }
    await this.http.post(this.url, item).toPromise()
      .then(res => {
        result = true
      })
      .catch(error => {
        console.log('Erro ', error)
      })
    return result
  }
  async alterar(item: SIS_PERSONALIZACAO) {
    let result = false
    console.log(this.url + item.id, item)

    Object.keys(item).forEach(key => { if (!item[key]) delete item[key] })

    await this.http.put(this.url + `${item.id}/${sessao.sessao_id}/${new Date().toISOString()}`, item).toPromise()
      .then(res => result = true)
      .catch(error => console.log('ERROR', error))
    return result
  }


}
