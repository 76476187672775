import { Component, OnInit, Inject, ViewChild, ɵConsole } from '@angular/core';
import { ClassificacaoDeDadosService } from '../service/classificacao-de-dados/classificacao-de-dados.service';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { caracteristicaDeDado } from '../service/classificacao-de-dados/caracteristica-de-dados.model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatTableDataSource, MatSort } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { filter, isEmpty } from 'rxjs/operators';
import { MensagemService } from '../service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { SistemaClassificacaoDadosService } from '../service/sistema-classificacao-dados.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { camposCaracteristicas } from '../ajustes/CaracteristicasDados/caracteristicas-de-dados.model';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { async } from '@angular/core/testing';


const user = new SessaoUser


@Component({
  selector: 'app-conteudo-de-dados',
  templateUrl: './conteudo-de-dados.component.html',
  styleUrls: ['./conteudo-de-dados.component.scss']
})
export class ConteudoDeDadosComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns = ['acao','codigoReferencia','codigoDeNegocio','valor','descDependencia']
  dataSource
  selectList
  conteudosDeDados = []
  caracteristicas: caracteristicaDeDado[];
  caracteristicas_backup: caracteristicaDeDado[];
  itemsSelecionados: { id: string, checkbox: boolean }[];
  formularioFiltro: FormGroup;
  novoConteudoDeDado: conteudoDeDado = {
    id: '',
    valor: '',
    codigoCaracteristica: '',
    codValor: 0,
    codigoDeNegocio: '',
    codigoReferencia: '',
    status: '',
  }
  caracteristicaSelecionada2: caracteristicaDeDado = {
    codigoDaCaracteristica: '',
    descricaoCaracteristica: ''
  };
  teste

  valores = []
  valores2: string[] = []

  detalhes = false

  caracteristicaSelecionada

  resultadoFiltro = []

  alterar = false
  loadingOpenCad: boolean;

  formulario: FormGroup

  viewCadastro: boolean;

  alteracao: boolean;

  botaoModificar = false

  temDependencia = false

  dependencias

  dependencias_backup

  conteudoDeDadosModel = new conteudoDeDado

  descDependencia = ''
  traducaoStatus

  backupStatus = ''

  constructor(
    private classificacaoService: ClassificacaoDeDadosService,
    private construtorForm: FormBuilder,
    public dialog: MatDialog,
    private mensagem: ToastrService,
    private message: MensagemService,
    private form: FormBuilder,
    private service: TokenDeAcessoService,
    private SistemaClassificacaoService: SistemaClassificacaoDadosService,


  ) { }

  async ngOnInit() {
    this.gerarFormulario();
    this.creatForm();
    this.caracteristicas = await this.classificacaoService.listarCaracteristicas();
    this.caracteristicas_backup = await this.classificacaoService.listarCaracteristicas()

    this.alteracao = false


    let caract = sessionStorage.getItem('caracteristica')
    if (caract) {
      this.formularioFiltro.controls['caracteristica'].setValue(caract)
      setTimeout(() => {
        this.GetFiltrarCaracteristica(caract);
      }, 200)
      this.filterList(caract)
      this.SetCaracteristica();
      sessionStorage.removeItem('caracteristica')
    }
  }
  filterList(key: string) {
    key = key.toUpperCase()
    this.caracteristicas = this.caracteristicas_backup
    let filter = this.caracteristicas.filter(value => value.codigoDaCaracteristica.includes(key))
    this.caracteristicas = filter
  }
  GetConteudoDeDados() {
    this.classificacaoService.GetConteudoDeDados().toPromise().then(
      (registros: any) => {
        this.dataSource = new MatTableDataSource(registros)
       // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
      }
    )
  }

  GetFiltrarCaracteristica(codigoCaracteristica) {

    this.selectList = codigoCaracteristica
    this.teste = this.selectList
    this.caracteristicaSelecionada = codigoCaracteristica
    this.classificacaoService.GetJoinCodigoCaracteristica(codigoCaracteristica).toPromise().then(
       (registros: any) => {
        this.dataSource = new MatTableDataSource(registros)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
        this.resultadoFiltro = registros      
      }
    ).catch((err) => {
      this.dataSource.data = []
      this.message.creat(err.error, { erro: true })
    })
  }

  filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
    
  }

  async listarConteudos() {

    await this.classificacaoService.GetFiltrarCodigoCaracteristica(this.teste).toPromise().then(
      (res: any) => {
        this.conteudosDeDados = res
      }
    ).catch((err) => {
     // this.message.creat("caracteristica do LISTARCONTEUDOS: " + this.teste + " " + err.error, { erro: true })
    });
    this.itemsSelecionados = this.conteudosDeDados.map(conteudo => {
      return { id: conteudo.id, checkbox: false }
    })
    this.dataSource = new MatTableDataSource(this.conteudosDeDados)
    this.dataSource.sort = this.sort
  }


  popUpNovoConteudo(novoConteudoDeDado: conteudoDeDado = this.novoConteudoDeDado): void {
    try {
      if (this.caracteristica.codigoDaCaracteristica === '') throw 'Selecione primeiro uma característica'
      const dialogRef = this.dialog.open(novoConteudo, {
        width: '21em',
        height: '20em',
        data: {
          caracteristica: this.caracteristica,
          novoConteudoDeDado,
        }

      })
      this.classificacaoService.GetFiltrarCodigoCaracteristica(this.caracteristica.codigoDaCaracteristica).toPromise().then(
        (registros: any) => {
          this.valores = registros

          this.valores2 = registros.map(value => value.valor)
        }
      )



      dialogRef.afterClosed().subscribe(async () => {
        this.listarConteudos();
      })
    } catch (err) {
      this.mensagem.info(err)
    }
  }

  gerarFormulario(): void {
    this.formularioFiltro = this.construtorForm.group({
      caracteristica: {},
    });

    this.formularioFiltro.get('caracteristica').valueChanges.subscribe(async (caracteristica: caracteristicaDeDado) => {
      this.listarConteudos();
    })
  }

  get caracteristica(): caracteristicaDeDado {
    return this.formularioFiltro.get('caracteristica').value;
  }

  marcarDesmarcar(i: number) {
    this.itemsSelecionados[i].checkbox = !this.itemsSelecionados[i].checkbox;
  }

  removerConteudos() {
    this.itemsSelecionados.forEach(async (conteudo, index) => {
      if (conteudo.checkbox) {
        await this.classificacaoService.removerConteudo({ id: conteudo.id });
        this.itemsSelecionados.splice(index, 1);
        this.conteudosDeDados.splice(index, 1);
      }
    })
    this.listarConteudos();
  }

  async OpenAlterar(registro) {
    
    this.ConsultarCaracteristica(registro.codigoCaracteristica)
    this.formulario.setValue(registro)
    this.viewCadastro = true
    this.formulario.disable()
    this.botaoModificar = true
 
  }

  SetCaracteristica() {
    if (!this.caracteristicaSelecionada) {
      this.caracteristicaSelecionada = "Selecione uma característica"
    }

    this.formulario.controls['codigoCaracteristica'].disable()
    this.formulario.controls['codigoCaracteristica'].setValue(this.caracteristicaSelecionada)
    this.ConsultarCaracteristica(this.caracteristicaSelecionada)
    console.log('caracteristica selecionada: ', this.caracteristicaSelecionada)
  }

  async OpenCadastro() {
    this.loadingOpenCad = true
    this.formulario.enable()
    this.formulario.reset()
    this.creatForm()
    this.botaoModificar = false
    //this.formulario.patchValue(registro)
    this.viewCadastro = true
    this.loadingOpenCad = false
    this.SetCaracteristica();
    setTimeout(() => {

    }, 2000)

  }

  fecharCadastro() {
    this.formulario.reset()
    this.formulario.disable()

    this.alteracao = false
    this.viewCadastro = false
    this.temDependencia = false

  }

  creatForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: '',
      codigoCaracteristica: new FormControl({ disabled: true }),
      codValor: 0,
      codigoReferencia: '',
      valor: '',
      status: '',
      codigoDeNegocio: '',
      descricao: '',
      dependencia: '',
      criadoEm: '',
      descDependencia: ''
    })
  }

  Inserir() {
    let registro = this.formulario.getRawValue()
    registro.criadoPor = user.cpf
    registro.status = 'SCA0001'
    delete registro.id
    delete registro.descDependencia
    registro.codValor = 0
    console.log("JSON CONTEUDODEDADOS: ", JSON.stringify(registro))
  
    this.SistemaClassificacaoService.postConteudo(registro).subscribe(
      (resultado => {
        this.message.creat('Salvo com sucesso: ', { sucesso: true })
        console.log(resultado)
        console.log(JSON.stringify(resultado))
      this.GetFiltrarCaracteristica(this.caracteristicaSelecionada)
        console.log('caracteristica selecionada: ', this.caracteristicaSelecionada)
      }), erro => {
        this.message.creat('Erro: ' + erro.error, { erro: true })
        console.log(erro.error)

      })
    this.viewCadastro = false
    this.temDependencia = false
    this.formulario.reset()
    this.formulario.disable()

  }

  Excluir()
  {
    let element = this.formulario.getRawValue()
    element.status = 'SCA0002'
    delete element.descDependencia

    console.log(element)
    this.SistemaClassificacaoService.PutConteudoDeDados(element).toPromise().then(
      (res:any) =>{ 
        this.message.creat('Registro Desativado com sucesso', { sucesso: true })
        this.GetFiltrarCaracteristica(element.codigoCaracteristica)

      }).catch(err => console.log(err.error))

      this.fecharCadastro()

  }


  AlterarRegistro() {
    let registro = this.formulario.value
    console.log('registro: ', registro)
   // registro.delete = 'descDependencia'
   delete registro.descDependencia
    if(this.backupStatus)
    {
      registro.status = this.backupStatus
    }
    if (!registro.id) return
    
    this.SistemaClassificacaoService.PutConteudoDeDados(registro).toPromise().then(
      res => {
        this.message.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
        //this.buscarCaractetisticas()
        //this.buscarConteudoDados()
        this.alteracao = false
        this.viewCadastro = false
        this.GetFiltrarCaracteristica(this.caracteristicaSelecionada)

      }
    ).catch(
      error => console.log(error.error)

    )
    this.viewCadastro = false
    this.formulario.reset()
    this.formulario.enable()
    this.temDependencia = false

  }

  ConsultarCaracteristica(codigoCaracteristica) {
    let caracteristica
    this.classificacaoService.GetConsultarCaracteristica(codigoCaracteristica).toPromise().then(
      (registro: any) => {

        caracteristica = registro
        console.log('caracteristica: ', JSON.stringify(caracteristica))
        if (caracteristica.dependencia) {
          this.classificacaoService.GetFiltrarCodigoCaracteristica(caracteristica.dependencia).toPromise().then(
            (registros: any) => {

              this.temDependencia = true
              this.formulario.controls['dependencia'].enabled;
            

              let dados = registros
              let letra = registros.map(value => value.valor.slice(0, 1).toUpperCase()).filter((value, i, index) => { return i == index.indexOf(value) }).sort()
              let result = []

              letra.forEach(value => {
                let ref = { letra: value, registro: [] }
                result.push(ref)
              });

              result.forEach(value => {
                dados.filter(v => {
                  let L = v.valor.slice(0, 1).toUpperCase()
                  if (L == value.letra) {
                    value.registro.push(v)
                  }
                })
              })
              this.dependencias = result
              console.log('DEPENDENCIAS(ConsultarCaracteristica): ', result)
              this.dependencias_backup = result
            }
          ).catch(err => console.log(err.error))

        }
        else {
          this.temDependencia = false
          this.formulario.controls['dependencia'].disable();
        }
      }
    ).catch(err => console.log(err.error))



  }

  _filterGroup(value: string) {
    let result
    if (value) {

      result = this.dependencias_backup
        .map(group => ({ letra: group.letra, registro: _filter(group.registro, value) }))
        .filter(group => group.registro.length > 0);

      this.dependencias = result
      console.log('DEPENDENCIAS(filtergroup): ', this.dependencias)
    } else {
      this.dependencias = this.dependencias_backup
    }

  }


}

@Component({
  selector: 'novo-conteudo',
  templateUrl: 'novo-conteudo.component.html'
})

export class novoConteudo implements OnInit {

  formularioConteudo: FormGroup;
  alterarConteudo: boolean;
  valores: any;
  constructor(
    private classificacaoService: ClassificacaoDeDadosService,
    private construirForm: FormBuilder,
    public dialogRef: MatDialogRef<novoConteudo>,
    @Inject(MAT_DIALOG_DATA) public data: {
      caracteristica: caracteristicaDeDado,
      novoConteudoDeDado: conteudoDeDado,
    }
  ) { }

  ngOnInit() {
    this.gerarFormulario();
    this.getCaracteristica()
    this.alterarConteudo = this.data.novoConteudoDeDado.valor !== '';

  }

  gerarFormulario(): void {
    this.formularioConteudo = this.construirForm.group({
      codigoCaracteristica: this.data.caracteristica.codigoDaCaracteristica,
      valor: this.data.novoConteudoDeDado.valor,
      codigoDeNegocio: this.data.novoConteudoDeDado.codigoDeNegocio,

    })
  }
  getCaracteristica() {
    this.classificacaoService.GetFiltrarCodigoCaracteristica(this.data.caracteristica).toPromise().then(
      (registros: any) => {
        this.valores = registros
      }
    )
  }



  get valor() {
    return this.formularioConteudo.get('valor').value;
  }

  get codigoDeNegocio() {
    return this.formularioConteudo.get('codigoDeNegocio').value;
  }

  async salvarConteudo() {
    const dadosFormulario: { codigoCaracteristica: string, valor: string, codigoDeNegocio: string } = this.formularioConteudo.value;
    if (!this.alterarConteudo) {
      await this.classificacaoService.criarConteudo({
        codigoCaracteristica: this.data.caracteristica.codigoDaCaracteristica,
        valor: dadosFormulario.valor,
        codigoDeNegocio: dadosFormulario.codigoDeNegocio
      })
      this.formularioConteudo.reset()
    } else {
      await this.classificacaoService.alterarConteudo({
        id: this.data.novoConteudoDeDado.id,
        codigoCaracteristica: this.data.caracteristica.codigoDaCaracteristica,
        valor: dadosFormulario.valor,
        codigoDeNegocio: dadosFormulario.codigoDeNegocio,
        codValor: this.data.novoConteudoDeDado.codValor,
        codigoReferencia: this.data.novoConteudoDeDado.codigoReferencia,
        status: this.data.novoConteudoDeDado.status,
      })
      this.dialogRef.close();
    }
  }

  fechar() {
    this.dialogRef.close();
  }

}

export const _filter = (opt: conteudoDeDado[], value: string) => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.valor.toLowerCase().indexOf(filterValue) === 0);


};