import { Component, OnInit, TemplateRef, ɵConsole } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConsultarTabelasService } from '../service/consultar-tabelas.service';
import {TabelaTussModal, TabelaTussService} from '../service/tabela-tuss.service';
import { MatTableDataSource, MatSnackBar, MatSort, MatTable } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import {  FormControl, Validators } from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {  ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-tabela-tuss',
  templateUrl: './tabela-tuss.component.html',
  styleUrls: ['./tabela-tuss.component.scss']
})
export class TabelaTussComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

displayedColumns = ['detalhes','tab22TussCodigo', 'tab22TussTerminologia']

dataSource

dataSourceDetalhes

displayedColumnsDetalhes = ['tab22TussCodigo', 'tab22TussTerminologia', 'versaoTuss', 'versaoTuss','correlacao', 'rolANS', 'resolucaoNormativa', 'capitulo', 'grupo', 'subGrupo', 'od', 'amb', 'hco', 'hso', 'pac', 'dut']

registrosTabela

registroDetalhes

loading

registroArray2 = []
  openDetalhe: boolean;

  constructor(private modalService: BsModalService,
    private consultarTabelaService: ConsultarTabelasService,
    private service: TabelaTussService,
    private mensagem: MatSnackBar,
    private _form: FormBuilder,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.GetRegistros()
    this.openDetalhe = false
  }

 

GetRegistros(){
  this.loading = true
  this.service.Get().then(
    (registros: any) => {
      this.dataSource = new MatTableDataSource(registros)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
      this.loading = false
     // this.registrosTabela = registros
    }
  ).catch((error) => {
    console.log(error)
  })
  
}  

filtro(reg: string) {
  this.dataSource.filter = reg.trim().toLowerCase();
}

ViewDetalhe(registro){
  console.log("REGISTRO: " + registro)
  console.log("QUALQUER COISA: " + registro.grupo );
  this.dataSource = null  
  this.dataSourceDetalhes = new MatTableDataSource(registro)
  this.registroArray2.push(registro)
  this.registroDetalhes = registro
  console.log("REGISTRO ARRAY: " + this.registroDetalhes)
  console.log(this.registroArray2)
  this.openDetalhe = true

}

Fechar(){
  this.dataSourceDetalhes = null
  this.GetRegistros()
  this.openDetalhe = false

}

}
