import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LivroRazaoModels } from '../livro-razao/modelsLivroRazao';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { PlanoContaContabeisComponent } from '../plano-conta-contabeis/plano-conta-contabeis.component';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';

const user = new SessaoUser

@Injectable({
    providedIn: 'root'
})
export class LivroRazaoService {
    url = new environment().api
    api = "LivroRazao/"
    apiConta = "PlanoContaContabeis/"

    constructor(private http: HttpClient, private data: BuscarDataService) {
    }

    listar() {
        return this.http.get<LivroRazaoModels[]>(this.url + this.api)
    }
    async getLivroRazao(codigoEmpresa) {
        let result: LivroRazaoModels[] = []

        await this.http.get<LivroRazaoModels[]>(this.url + this.api).toPromise()
            .then(res => {
                if (res.length > 0) {
                    result = res.filter(value => value.codigoEmpresa == 1)
                }
            })

        return result
    }

    listarContas(codigoEmpresa) {
        return this.http.get(this.url + this.apiConta + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    // /api/PlanoContaContabeis/FiltrarCodigoEmpresa/{}


    enviar(registro: LivroRazaoModels) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: LivroRazaoModels) {
        return this.http.put(this.url + this.api + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: LivroRazaoModels, ativo) {
        if (ativo) {
            registro.status = 'SCA0001'
        } else {
            registro.status = 'SCA0002'
        }

        let resposta

        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })
        return resposta
    }

    async getProcessoContabilizacao(codigoEmpresa: number, codigoContaContabil) {
        let result = new PlanoContaContabeisModel
        await this.http.get<PlanoContaContabeisModel[]>(this.url + this.apiConta + 'FiltrarCodigoContaContabil/' + codigoContaContabil).toPromise()
            .then(res => {
                let filterEmpresa = res.filter(value => value.codigoEmpresa == codigoEmpresa)
                if (filterEmpresa.length > 0) {
                    result = filterEmpresa[0]
                }
            })
        return result
    }
}




