import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CdkStepLabel } from '@angular/cdk/stepper';

@Injectable({
    providedIn: 'root'
})
export class CatalogoTabelasService{
    api = 'https://www.myclinics.com.br/api/api/SIS_CATAB/'
    constructor(private http: HttpClient) {}

get() {
    return this.http.get(this.api)
}

post(registro){
    return this.http.post(this.api, registro)
}

put(id, registro){
    return this.http.put(this.api + id, registro)
}

delete(id) {
    return this.http.delete(this.api + id)
}


}