import { Component, OnInit, NgModule, TemplateRef } from '@angular/core';
import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [ModalModule.forRoot()],
})

@Component({
  selector: 'app-teste-teste',
  templateUrl: './teste-teste.component.html',
  styleUrls: ['./teste-teste.component.scss']
})
export class TesteTesteComponent implements OnInit {

  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

}
