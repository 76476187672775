import { Component, OnInit, TemplateRef, ViewChild, Pipe } from '@angular/core';
import { MensagemService } from 'src/app/service/mensagem.service';
import { MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { SessaoUser } from 'src/app/user.reg';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { MatPaginator } from '@angular/material/paginator';
import { ParametrosSistemaService } from '../service/parametros-sistema.service';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ClassificacaoDeDadosService } from '../service/classificacao-de-dados/classificacao-de-dados.service';

const user = new SessaoUser

@Component({
  selector: 'app-parametros-do-sistema',
  templateUrl: './parametros-do-sistema.component.html',
  styleUrls: ['./parametros-do-sistema.component.scss']
})
export class ParametrosDoSistemaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['detalhe','parametro', 'valorDoParametro','descricao','status']
 
  dataSource

  formulario: FormGroup
  
  alteracao: boolean;

  viewCadastro: boolean;

  botaoModificar: boolean

  backupStatus = ''

  constructor(
    private service: ParametrosSistemaService,
    private _data: BuscarDataService,
    private mensagem: MensagemService,
    private form: FormBuilder,
    private TraduzirService: TokenDeAcessoService,
    
  ) { }

  ngOnInit() {
    
    this.CreateForm()
    this.GetRegistros()
    
  }

  CreateForm(){
    this.formulario = this.form.group({
      id: '',
      parametro: '',
      aplicacao: '',
      valorDoParametro: '',
      limiteInferior: '',
      limiteSuperior: '',
      valorPadrao: '',
      valorRecomendado: '',
      descricao: '',
      status: '',
      criadoPor: '',
      criadoEm: ''
    })
  }

  GetRegistros(){
     this.service.GetNormal().toPromise().then(
      (registros: any ) =>{
        registros.forEach(async item => {
          if(item.status){
          this.backupStatus = item.status
           item.status = await  this.TraduzirService.TraduzirStatus(item.status) 
          }             
       });
        this.dataSource = new MatTableDataSource(registros)
        this.dataSource.sort = this.sort
      }
    ).catch(
      (err) => {
        console.log(err.response)       
      })
  }

  Inserir(){
    let registro = this.formulario.value
    delete registro.id
    registro.criadoPor = user.cpf

    console.log("Registro:", JSON.stringify(registro))

    this.service.Post(registro).toPromise().then(
      (res: any) =>{
        let message = res[0].mensagem
        this.mensagem.creat(message, {sucesso: true})
      }
    ), err =>{
      console.log("DEu erro: " + err)
      this.mensagem.creat('Erro: ' + err.error, { erro: true })
    }

    this.viewCadastro = false
    this.formulario.reset()
    this.formulario.enable()
    this.GetRegistros()

  }

  AlterarRegistro(){
    let registro = this.formulario.value
    console.log("JSON Alterar", JSON.stringify(registro))
    if(this.backupStatus)
    {
      registro.status = this.backupStatus
    }
    if(!registro.id) return

    this.service.Put(registro).toPromise().then(
      res => {
        this.mensagem.creat('Alterado com sucesso', {sucesso: true})
        this.formulario.disable()
        this.GetRegistros()
        this.alteracao = false 
        this.viewCadastro = false
      }
    ).catch(
      error => console.log(error.error))

      this.viewCadastro = false
      this.formulario.reset()
      this.formulario.enable()
  }

  Excluir(){
    let registro = this.formulario.value
    console.log("JSON Excluir", JSON.stringify(registro))
    if (!registro.id) return

    this.service.Delete(registro).toPromise().then(
      res => {
        this.mensagem.creat('Registro desativado com sucesso', {sucesso: true})
        this.formulario.disable()
        this.GetRegistros()
        this.alteracao = false 
        this.viewCadastro = false
      }
    ).catch(
      error => console.log(error.error))

      this.viewCadastro = false
      this.formulario.reset()
      this.formulario.enable()
  }

  OpenCadastro() {
    this.formulario.enable()
    this.formulario.reset()
    this.CreateForm()
    this.botaoModificar = false
    this.viewCadastro = true
  }

  OpenAlterar(registro) {
    this.formulario.setValue(registro)
    this.viewCadastro = true
    this.formulario.disable()
    this.botaoModificar = true
  }


  fecharCadastro() {
    this.formulario.reset()
    this.formulario.enable()
    this.alteracao = false
    this.viewCadastro = false
  }


  filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }




}
