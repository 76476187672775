import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BuscarDataService } from './buscar-data.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormConversaHistorico } from '../conversas/modal';

@Injectable({
  providedIn: 'root'
})
export class ConversasService {
  url = new environment().api
  api = 'HistoricoConversas/'
  db = this.firestore.collection('conversas')
  private itemDoc: AngularFirestoreCollection
  constructor(public firestore: AngularFirestore, public data: BuscarDataService, private http: HttpClient, private toast: ToastrService) { }

  getHoje(cpf) {
    const data = this.data.hojeBrasil(true)
    return this.db.doc(cpf).collection(data).valueChanges()
  }
  putChat(key,active?){
    const id = key
    return this.firestore.collection('videoChamada').doc(`${id}`).update({ chat: active });
  }
  getHistorico() {
    return this.http.get(this.url + this.api)
  }
  getHistorico_id(ref: FormConversaHistorico) {
      let data = ref.data.split('/').join('.')
      return this.db.doc(ref.de).collection(data).valueChanges()
  }
  addCPF(cpf, data, set, historico?) {
    if (historico) {
      this.http.post(this.url + this.api, set).toPromise().catch(
        () => { this.toast.error('Não foi possivel armazenar História') }
      )
    }

    return this.db.doc(cpf).collection(data).add(set)
  }
}
