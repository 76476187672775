import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadArquivosService, UploadArquivo } from '../service/upload-arquivos.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SessaoUser } from '../user.reg';

const sessao = new SessaoUser
@Component({
  selector: 'upload-documento',
  templateUrl: './upload-documento.component.html',
  styleUrls: ['./upload-documento.component.scss']
})
export class UploadDocumentoComponent implements OnInit {
  @Input('file') file: File[]
  @Input('info') info: UploadArquivo
  @Output('success') success = new EventEmitter
  @Output('exit') exit = new EventEmitter
  porcentagem: number = 0;
  uploadSuccess: boolean;
  src
  image: string | ArrayBuffer;
  pdf: any;

  constructor(private service: UploadArquivosService) { }

  ngOnInit() {
    //***** Visualizar documento
    const files = this.file[0]
    const reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = () => {
      if (files.type == 'image/png' || files.type == 'image/jpeg') {
        this.image = reader.result
        this.pdf = null
        return
      }
      this.pdf = reader.result
    };
    ////**** END Visualizar documento
  }
  uploading() {
    var formData = new FormData();
    formData.append('arquivo', this.file[0])
    console.log(formData)
    console.log()
    this.service.upload(formData, this.info).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.porcentagem = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.uploadSuccess = true;
          console.log('Response :', event.body[0])
          setTimeout(() => this.success.emit(true), 2000)
        }
      },
      error => {
        console.log('ERRO!', error)
        setTimeout(() => this.success.emit(false), 2000)
      }
    );
  }

  close() {
    this.exit.emit(true)
  }
}
