import { Component, OnInit, ViewChild } from '@angular/core';
import { MensagemService } from '../service/mensagem.service';
import { SessaoUser } from '../user.reg';
import { HierarquiaCentroLucroECentroCustoService } from '../service/hierarquia-centro-lucro-e-custo.service';
import { GrupoItemHierarquia, HierarquiaCustoELucroModel } from './modelsHierarquiaCustoELucro';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { element } from 'protractor';
import { FiltrarDadosService } from '../service/filtrar-dados.service';

const sessao = new SessaoUser


@Component({
  selector: 'app-hierarquia-de-custo-e-lucro',
  templateUrl: './hierarquia-de-custo-e-lucro.component.html',
  styleUrls: ['./hierarquia-de-custo-e-lucro.component.scss']
})
export class HierarquiaDeCustoELucroComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'codigo', 'descricao', 'tipoHierarquia']
  displayedColumnsItens = ['check', 'itemGrupo']

  listRegistro: HierarquiaCustoELucroModel[]
  listGrupos: HierarquiaCustoELucroModel[]
  listCentroCusto = []
  listCentroLucro = []
  listItens = []
  mask_codigo = "0.0.00.000.0000"
  formulario: FormGroup
  viewCadastro = false
  alteracao = false
  loading: boolean
  dataSource = new MatTableDataSource()
  dataSourceItem = new MatTableDataSource()
  descricaoHierarquia
  filtroDadosGrupo: any[]
  listItemGrupo = []
  selectEmpresa = 0
  listEmpresa = []
  listHierarquia = []
  botaoSalvar = false
  botaoCancelar = false
  codigoEmpresaSelectResult: number
  viewConfirmDelete: boolean

  //
  desativarCheckbox = false
  selectCheck: any
  BackuplistItemGrupo = [];
  loadingItem: boolean;

  constructor(
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private conteudo: ConteudoDeDadosService,
    private service: HierarquiaCentroLucroECentroCustoService,
    private mensagem: MensagemService,
    private _filtro: FiltrarDadosService

  ) { }

  async ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getHierarquia()
    this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      codigo: new FormControl({ disabled: true }),
      descricao: '',
      observacao: '',
      tipoHierarquia: '',
      codigoHierarquia: '',
      codigoEmpresa: 0,
      criadoPor: sessao.cpf,
      nomeEmpresa: '',
      status: '',
      criadoEm: '',
      descTipoHierarquia: '',
      itemGrupo: ''
      // timestamp: ''
    })
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getHierarquia() {
    let hierarquia = await this.conteudo.get('TPHIER')
    this.listHierarquia = hierarquia
  }

  async getItemCentroCusto(codigoEmpresa) {

  }

  async getItemCentroLucro(codigoEmpresa) {


  }

  getItemHierarquiaSelect(e) {
    this.codigoEmpresaSelectResult = e
    this.getItemHierarquia(e.codigoEmpresa)
  }

  async getItemHierarquia(codigoEmpresa) {
    this.loadingItem = true
    let registro = this.formulario.value
    let codigoHierarquia = registro.codigo

    if (registro.tipoHierarquia == "TPHIER1") {
      codigoEmpresa = registro.codigoEmpresa
      await this.service.listarCentroCusto(codigoEmpresa).toPromise()
        .then(async registro => {
          this.listCentroCusto = registro.filter(item => item.status == 'SDA0001')

          this.listItemGrupo = this.listCentroCusto

          this.dataSourceItem = new MatTableDataSource(this.listItemGrupo)
        })
    }

    if (registro.tipoHierarquia == "TPHIER2") {
      codigoEmpresa = registro.codigoEmpresa
      await this.service.listarCentroLucro(codigoEmpresa).toPromise()
        .then(registro => {
          this.listCentroLucro = registro.filter(item => item.status == 'SDA0001')
          this.listItemGrupo = this.listCentroLucro
          this.dataSourceItem = new MatTableDataSource(this.listItemGrupo)
        })
    }

    this.listItemGrupo = this._filtro.removeDublic(this.listItemGrupo, 'codigo')

    let itens = await this.service.listarItem(codigoEmpresa, codigoHierarquia)

    console.log('verifc', itens)

    itens.forEach(value => {
      this.listItemGrupo.filter(item => {
        if (value.descricaoHierarquia == item.descricao) {
          item.selecionado = true
          this.BackuplistItemGrupo.push(value)
        }
      })
    })
    setTimeout(() => {
      this.loadingItem = false
    }, 1000)
  }

  // O código é gerado automaticamente pelo banco de dados. 
  adicionarHierarquia() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.desativarCheckbox = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
    this.formulario.controls['codigo'].disable()
  }

  async getRegistro() {

    this.loading = true

    await this.service.listar().toPromise()
      .then(registro => {
        this.listRegistro = registro.filter(item => item.status == 'SDA0001')


        this.dataSource.sort = this.sort
        this.loading = false
      })
  }

  onChangeCheckbox(item, valorDado) {
    if (item.checked) {
      this.selectCheck = item.id
      this.listItens.push(valorDado)
    } else {
      this.selectCheck = null
      this.listItens.filter((value, index) => {
        if (value.id == valorDado.id) {
          this.listItens.splice(index, 1)
        }
      })
    }

    console.log("ONCHANGECHACKBOX VALORDADO: ", this.listItens)
  }


  async inserir() {
    this.loading = true
    let registro = this.formulario.value

    Object.keys(registro).forEach(value => { if (registro[value] == null) delete registro[value] }) // 

    registro.criadoPor = sessao.cpf
    // registro.itemGrupo = this.listItemGrupo.map(value => value.id).toString() // 
    let contItem = 0
    await this.service.enviar(registro).toPromise()
      .then(
        async resposta => {

          const codigo = resposta[0].codigoHierarquia
          const codigoEmpresa = registro.codigoEmpresa

          for (let i = 0; i < this.listItens.length; i++) {
            let item = this.listItens[i]

            let registro = new GrupoItemHierarquia

            registro.codigoHierarquia = codigo
            registro.codigoEmpresa = codigoEmpresa
            registro.descricaoHierarquia = item.descricao
            let result = await this.service.enviarItem(registro)

            if (result) contItem++

          }

          if (contItem == this.listItens.length) {
            console.log('response', resposta)
            console.log('sucesso', resposta[0].mensagem)
            this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
            this.viewCadastro = false
            this.formulario.disable()
            this.botaoSalvar = false
            this.botaoCancelar = false
          } else {
            console.log('cont', contItem, this.listItens.length)
            this.mensagem.creat('Não foi possivel cadastrar registro', { erro: true })
          }

        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          }
        )
    this.getRegistro().finally(() => this.filtrarEmpresa())
    this.loading = false
    console.log('Cadastro', registro)
  }

  async openCadastro(registro) {
    this.formulario.patchValue(registro)

    localStorage.setItem('backup_registro', JSON.stringify(registro))



    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.desativarCheckbox = true
    this.desativarCheckbox = false
    this.getItemHierarquiaSelect(registro)
    this.formulario.controls['codigo'].disable()
    this.codigoEmpresaSelectResult = this.formulario.controls['codigoEmpresa'].value
    console.log("Teste checkbox: ", registro)

  }

  async alterar() {

    let registro = this.formulario.value
    console.log('registro - - ', registro)
    const codigo = registro.codigo
    const codigoEmpresa = registro.codigoEmpresa
    delete registro.descTipoHierarquia

    let getItem = this.BackuplistItemGrupo.map(v => v.id)

    let cont = 0

    let newItens = []

    for (let i = 0; i < getItem.length; i++) {
      let item = getItem[i]
      console.log('deletear', item)
      await this.service.desativarItem(item)

    }

    for (let i = 0; i < this.listItens.length; i++) {
      let item = this.listItens[i]

      let registro = new GrupoItemHierarquia

      registro.codigoHierarquia = codigo
      registro.codigoEmpresa = codigoEmpresa
      registro.descricaoHierarquia = item.descricao

      console.log('enviar', registro)
      let result = await this.service.enviarItem(registro)

      if (result) cont++

    }

    if (cont == this.listItens.length) {
      await this.service.alterar(registro).toPromise()
        .then(
          resposta => {
            this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
            this.viewCadastro = true
            this.formulario.disable()
          }
        ).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
            console.log('Erro', error.error)
          }
        )
    } else {
      this.mensagem.creat('Não foi possivel alterar os itens selecionados', { erro: true })
      console.log('erro item =>', cont, newItens.length, newItens)
    }

    this.openCadastro(registro)

  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.viewCadastro = false
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())

  }

  async fecharRegistro() {
    this.viewCadastro = false
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    let filter
    let codigoEmpresa = this.selectEmpresa

    if (codigoEmpresa == 0) {
      filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
    } else {
      filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    }

    filter.forEach(async item => {
      let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
      let tipoHierarquia = await this.conteudo.get('TPHIER', true, item.tipoHierarquia)

      if (empresa.length > 0) {
        item.nomeEmpresa = empresa[0].nomeEmpresa
      }

      item.descTipoHierarquia = tipoHierarquia.toString()
    })

    this.dataSource.data = filter
  }
}
