import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ValidateBrService } from 'angular-validate-br';
import { dadosCadastroCentroCusto } from '../cadastro-centro-de-custo-completo/modelsCadastroCentroDeCustoCompleto';
import { CadastroPessoaForm } from '../cadastro-paciente/model';
import { ConsultorioMedico } from '../definir-consultorios-medicos/modelsDefinirConsultorioMedico';
import { CadastroCentroCustoService } from '../service/cadastro-centro-de-custo.service';
import { CadastroCentroLucroService } from '../service/cadastro-centro-de-lucro.service';
import { CadastroFuncionarioService } from '../service/cadastro-funcionario.service';
import { ConteudoDados, ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { MensagemService } from '../service/mensagem.service';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { UsuarioService } from '../service/usuario.service';
import { SessaoUser } from '../user.reg';
import { CadastroFuncionarioModel } from './cadastro-funcionario.model';

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-funcionario',
  templateUrl: './cadastro-funcionario.component.html',
  styleUrls: ['./cadastro-funcionario.component.scss']
})

export class CadastroFuncionarioComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['detalhe', 'nomeEmpresa', 'nrRegistro', 'cpf', 'nome']
  listRegistro: CadastroFuncionarioModel[]
  viewCadastro: boolean
  formulario: FormGroup
  modoCriacao: boolean
  modoAlteracao: boolean
  modoExibicao: boolean
  listPaises: any
  listCategoriaTrabalho = []
  listTipoSalario = []
  listMotivo = []
  listBancos: any
  listEmpresa = []
  loading: boolean
  viewConfirmCancelar: boolean
  viewConfirmDelete: boolean
  dataSource = new MatTableDataSource()
  cpfResponsavel: string
  nome: string
  nomeCoordenadorCusto: string
  nomeCoordenadorLucro: string
  validarDatas: boolean = true

  botaoCancelar: boolean
  botaoSalvar: boolean
  botaoFechar: boolean
  botaoExcluir: boolean
  botaoModificar: boolean
  novoCadastro: boolean


  ////


  @Input() atribuicao: boolean
  @Output() exit = new EventEmitter
  dataSourceMedicos = new MatTableDataSource()
  registro_doc = new ConsultorioMedico
  loadingReg: boolean
  cpfCoordenador
  cpfCoordenadorLucro
  empresas = []
  selecCheck: any;
  selectEmpresa = sessao.codigoEmpresa
  alteracao: boolean;
  modificar: boolean;
  excluir: boolean;
  viewDadosPessoa: boolean;
  dadosPessoa: CadastroPessoaForm
  dadosCentroCusto = new dadosCadastroCentroCusto
  listCentroCusto = [];
  resultadoJsonTodosOsBancos = []
  resultadoJsonPais = []
  noRegistro: boolean;
  yesRegistro: boolean;
  listConteudo = []
  listConteudo_CATEMPR: ConteudoDados[] = [];
  listConteudo_TIPSAL: ConteudoDados[] = [];
  listCentroLucro = []


  constructor(
    private form: FormBuilder,
    private service: CadastroFuncionarioService,
    private _centroCusto: CadastroCentroCustoService,
    private _centroLucro: CadastroCentroLucroService,
    private _mensagem: MensagemService,
    private _conteudo: ConteudoDeDadosService,
    private token: TokenDeAcessoService,
    private filtro: FiltrarDadosService,
    private usuario: UsuarioService,
    private ValidBr: ValidateBrService
  ) { }


  ngOnInit() {
    this.creatForm()
    this.getBancos()
    this.getPaises()
    this.getCategoriaTrabalho()
    this.getTipoSalario()
    this.getMotivoDemissao()
    this.getEmpresa()
    this.getRegistro(this.selectEmpresa)
    // this.buscarUsuarioCusto(this.formulario.controls['cpfResponsavel'].value)
  }

  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: new FormControl(0, Validators.required), // ok
      cpf: new FormControl('', [Validators.required, this.ValidBr.cpf]), //ok
      nrRegistro: 0,
      nrFuncao: 0,
      funcao: new FormControl('', Validators.required), // ok
      departamento: new FormControl('', Validators.required), // ok
      cpfResponsavel: new FormControl('', Validators.required), // ok
      categoriaContratoTrabalho: new FormControl('', Validators.required), // ok
      tipoSalario: new FormControl('', Validators.required), // ok
      dataExperiencia: new FormControl('', Validators.required),
      dataAdmissao: new FormControl('', Validators.required), // [Validators.required]] ok
      dataProrrogacao: '',
      dataRescisao: '',
      motivoDemissao: '',
      salarioInicial: new FormControl(0, Validators.required), //ok
      salarioAtual: new FormControl(0, Validators.required), //ok
      pais: '',
      banco: '',
      agencia: '',
      contaBancaria: '',
      centroCustoResp: new FormControl(0, Validators.required), // ok
      centroLucroResp: 0,
      observacao: '',
      atribuicaoResponsavelDesde: '',
      atribuicaoResponsavelAte: '',
      ativoDesde: '',
      inativoDesde: '',
      criadoPor: sessao.cpf,
      criadoEm: '',
      status: '',
      avisoPrevio: '',
      saldoFgts: 0,
      recolheuFgts: 0,
      ///
      nomeEmpresa: '',
      nomeCoordenadorCusto: '',
      cpfResponsavelLucro: '',
      nomeCoordenadorLucro: '',
      descNome: ''
    })
  }

  async adicionarCadastro() {

    // let cpfResp = this.formulario.controls['cpfResponsavel']

    this.cpfCoordenador = ''
    this.nomeCoordenadorCusto = ''
    this.cpfCoordenadorLucro = ''
    this.nomeCoordenadorLucro = ''
    this.viewCadastro = true
    this.formulario.enable()
    this.formulario.reset()
    // this.formulario.controls['cpfResponsavelLucro'].reset()
    // this.formulario.controls['nomeCoordenadorLucro'].reset()
    this.formulario.controls['cpfResponsavel'].disable()
    this.formulario.controls['nomeCoordenadorCusto'].disable()
    this.formulario.controls['cpfResponsavelLucro'].disable()
    this.formulario.controls['nomeCoordenadorLucro'].disable()
    this.yesRegistro = false
    this.noRegistro = false
    this.alteracao = false
    this.formulario.controls['status'].setValue('SCA0001')
    // await this.buscarUsuarioCusto(this.formulario.controls['cpfResponsavel'].value, true)
    this.novoCadastro = true
    this.getModo(true, false, false)
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getModo(criacao, alteracao, exibicao) {
    if (criacao) {
      this.modoCriacao = true
      this.modoAlteracao = false
      this.modoExibicao = false
      this.botaoCancelar = true
      this.botaoExcluir = false
      this.botaoModificar = false
      this.botaoFechar = false
      this.botaoSalvar = true
    } if (alteracao) {
      this.modoCriacao = false
      this.modoAlteracao = true
      this.modoExibicao = false
      this.botaoModificar = false
      this.botaoSalvar = true
      this.botaoExcluir = false
      this.botaoCancelar = true
      this.botaoFechar = false
    } if (exibicao) {
      this.modoCriacao = false
      this.modoAlteracao = false
      this.modoExibicao = true
      this.botaoModificar = true
      this.botaoExcluir = true
      this.botaoFechar = true
      this.botaoSalvar = false
      this.botaoCancelar = false
    }
  }

  async getPaises() {
    this.service.getPaises().subscribe(response => {
      this.listPaises = response
    })
  }

  async getBancos() {
    this.service.getBancosService().subscribe(response => {
      this.listBancos = response

      this.listBancos.sort((a, b) => {
        let aCodigo = a.codigoBanco
        let bCodigo = b.codigoBanco
        aCodigo < bCodigo ? -1 : 1
      })
    })
  }

  async getCategoriaTrabalho() {
    let categoria = await this._conteudo.get('CATEMPR')
    this.listCategoriaTrabalho = categoria
  }

  async getTipoSalario() {
    let tipo = await this._conteudo.get('TIPSAL')
    this.listTipoSalario = tipo
  }

  async getMotivoDemissao() {
    let motivo = await this._conteudo.get('TIPDEM')
    this.listMotivo = motivo
  }

  async getCentroCusto() {
    let codigoEmpresa = this.formulario.controls['codigoEmpresa'].value
    this.listCentroCusto = await this._centroCusto.get(codigoEmpresa)
    let centroCusto = this.listCentroCusto.filter(item => item.status == 'SDA0001')
    this.listCentroCusto = centroCusto
    console.log('listCentroCusto', this.listCentroCusto)
  }

  async getCentroLucro() {
    let codigoEmpresa = this.formulario.controls['codigoEmpresa'].value
    this.listCentroLucro = await this._centroLucro.get(codigoEmpresa)
    let centroLucro = this.listCentroLucro.filter(item => item.status == 'SDA0001')
    this.listCentroLucro = centroLucro
    console.log('listCentroLucro', this.listCentroLucro)
  }

  async fecharRegistro() {
    this.viewCadastro = false
    this.getRegistro(this.selectEmpresa)
      .finally(() => this.filtrarEmpresa())
  }

  async getFuncionario() {
    let cpf = this.formulario.controls['cpf']

    if (cpf.invalid) {
      this.noRegistro = false
      this.yesRegistro = false
    } else {
      if (cpf.valid) {
        let response = await this.service.getRegistroFuncionario(cpf.value)
        this.dadosPessoa = await response
        if (response) {
          this.nome = this.dadosPessoa.nomeCompleto
          this.noRegistro = false
          this.yesRegistro = true
        } else {
          this.noRegistro = true
          this.yesRegistro = false
        }
        // this.Nome.nativeElement.value = this.dadosPessoa.nome
      } else {
        this.noRegistro = false
        this.dadosPessoa = new CadastroPessoaForm
      }
    }
  }

  async getCPFResponsavelCusto() {
    let cpfResponsavel = this.formulario.controls['cpfResponsavel']
    this.listCentroCusto.filter(item => {
      cpfResponsavel = item.responsavel
      this.cpfCoordenador = cpfResponsavel
    })
  }

  async getCPFResponsavelLucro() {
    let cpfResponsavelLucro = this.formulario.controls['cpfResponsavelLucro']
    this.listCentroLucro.filter(item => {
      cpfResponsavelLucro = item.responsavel
      this.cpfCoordenadorLucro = cpfResponsavelLucro
    })
  }

  async buscarUsuarioCusto(cpf, openCadastro?) {
    let validCusto = this.formulario.controls['cpfResponsavel'].valid

    if (validCusto || openCadastro) {
      let resp = await this.usuario.getRegistro(cpf)
      this.nomeCoordenadorCusto = resp.nomeCompleto
    }
    else {
      this.nomeCoordenadorCusto = null
    }
  }

  async buscarUsuarioLucro(cpf, openCadastro?) {
    let validLucro = this.formulario.controls['cpfResponsavelLucro'].valid

    if (validLucro || openCadastro) {
      let resp = await this.usuario.getRegistro(cpf)
      this.nomeCoordenadorLucro = resp.nomeCompleto
    }
    else {
      this.nomeCoordenadorLucro = null
    }
  }

  async openCadastro(registro) {

    registro.nomeCoordenadorCusto = ''
    registro.nomeCoordenadorLucro = ''
    this.formulario.patchValue(registro)

    await this.getCentroCusto()
    await this.getCentroLucro()
    await this.getCPFResponsavelCusto()
    await this.getCPFResponsavelLucro()
    // await this.buscarUsuarioCusto(this.formulario.controls['cpfResponsavel'].value, true)
    // await this.buscarUsuarioLucro(this.formulario.controls['cpfResponsavelLucro'].value, true)

    let cpf = this.formulario.controls['cpf']
    this.dadosPessoa = await this.service.getRegistroFuncionario(cpf.value)
    this.nome = registro.descNome
    this.nomeCoordenadorCusto = registro.nomeCoordenadorCusto
    registro.cpfResponsavelLucro = this.cpfCoordenadorLucro
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.buscarUsuarioCusto(registro.cpfResponsavel, true)
    await this.buscarUsuarioLucro(registro.cpfResponsavelLucro, true)
    this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = false
    this.yesRegistro = true
    // delete registro.cpf

    this.getModo(false, false, true)
  }

  async getRegistro(codigoEmpresa) {
    this.loading = true

    let registro = this.formulario.value

    await this.service.listar().toPromise()
      .then(response => {
        this.listRegistro = response.filter(item => item.status == 'SCA0001')
        this.listRegistro.forEach(async item => {

          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
          let response = await this.service.getRegistroFuncionario(item.cpf)

          if (empresa.length > 0) item.nomeEmpresa = empresa[0].nomeEmpresa

          this.dadosPessoa = response
          item.descNome = this.dadosPessoa.nomeCompleto
        })
        this.dataSource.sort = this.sort
        this.loading = false
        console.log('listRegistro: ', response)
        if (codigoEmpresa) {
          this.filtrarEmpresa()
        }
      })
  }

  async inserir() {

    let registro = this.formulario.value
    let cpf = this.formulario.controls['cpf']
    let salarioInicial = this.formulario.controls['salarioInicial'].value
    let salarioAtual = this.formulario.controls['salarioAtual'].value
    let listCpf = this.listRegistro

    Object.keys(registro).forEach(value => {
      if (!registro[value]) delete registro[value]
    })

    registro.criadoPor = sessao.cpf
    registro.status = 'SCA0001'
    delete registro.nomeCoordenadorCusto
    registro.cpfResponsavel = this.cpfCoordenador

    if (this.noRegistro == true || cpf.invalid) {
      this._mensagem.creat('O CPF informado é inválido. Insira um CPF cadastrado.', { erro: true })
      return
    }

    let resultadoFilter = await this.service.getCadastroFuncionario(cpf.value, registro.codigoEmpresa)


    if (resultadoFilter.id) {
      return this._mensagem.creat('Este funcionário já está cadastrado. Por favor, insira outro.', { erro: true })
    }

    if (salarioAtual < salarioInicial) {
      return this._mensagem.creat('O salário inicial é maior que o atual. Verifique os campos e tente novamente.', { erro: true })
    }


    this.validacaoDatas()
    if (this.validarDatas == false) return

    this.service.enviar(registro).toPromise()
      .then(resp => {
        if (this.formulario.valid) {

          this._mensagem.creat(resp[0].mensagem, { sucesso: true })
          this.getModo(true, false, false)
          this.viewCadastro = true
          this.formulario.disable()
          this.alteracao = false
          this.botaoSalvar = false
          this.botaoFechar = true
          this.botaoCancelar = false
          this.botaoModificar = false
          this.botaoExcluir = false
        } else {
          return this._mensagem.creat('Erro ao inserir registro. Verifique os campos obrigatórios e tente novamente.', { erro: true })
        }
      }).catch(error => {
        let erro = error.error
        this._mensagem.creat(erro, { erro: true })
      })

    console.log('Inserir: ', registro)
  }

  async alterar() {
    let registro = this.formulario.getRawValue()
    let cpf = this.formulario.controls['cpf']
    let listCpf = this.listRegistro

    delete registro.nomeCoordenadorCusto
    registro.cpfResponsavel = this.cpfCoordenador

    if (this.noRegistro == true || cpf.invalid) {
      this._mensagem.creat('O CPF informado é inválido. Insira um CPF cadastrado.', { erro: true })
      return
    }

    if (registro.salarioAtual < registro.salarioInicial) {
      return this._mensagem.creat('O salário inicial é maior que o atual. Verifique os campos e tente novamente.', { erro: true })
    }

    this.validacaoDatas()
    if (this.validarDatas == false) return


    this.service.alterar(registro).toPromise()
      .then(() => {
        if (this.formulario.valid) {
          this._mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.alteracao = false
          this.getModo(false, false, true)
        } else {
          return this._mensagem.creat('Erro ao inserir registro. Veririque os campos obrigatórios e tente novamente.', { erro: true })
        }
      }).catch(() => {
        return this._mensagem.creat('Erro ao inserir registro. Veririque os campos obrigatórios e tente novamente.', { erro: true })
      })

    console.log('Alterar: ', registro)
  }

  modificarCadastro() {
    this.alteracao = true
    this.formulario.enable()
    this.formulario.controls['cpfResponsavel'].disable()
    this.formulario.controls['nomeCoordenadorCusto'].disable()
    this.formulario.controls['cpfResponsavelLucro'].disable()
    this.formulario.controls['nomeCoordenadorLucro'].disable()
    this.formulario.get('cpf').disable()
    this.getModo(false, true, false)
  }

  async cancelarRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmCancelar = true
      return
    }

    if (this.novoCadastro) {
      this.formulario.reset()
      this.formulario.disable()
      this.alteracao = false
      this.viewConfirmCancelar = false
      this.novoCadastro = false
      this.botaoFechar = true
      this.botaoCancelar = false
      this.botaoSalvar = false
      this.nome = ''
      this.yesRegistro = false
      return
    }

    this.viewConfirmCancelar = false
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)

    await this.buscarUsuarioCusto(registro.cpfResponsavel, true)
    await this.buscarUsuarioLucro(registro.cpfResponsavelLucro, true)
    registro.cpfResponsavelLucro = this.cpfCoordenadorLucro
    this.formulario.disable()
    this.alteracao = false
    this.nome = this.formulario.controls['descNome'].value
    this.yesRegistro = true
    this.getModo(false, false, true)

  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    await this.service.desativar(registro, false)
    this._mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro(this.selectEmpresa)
      .finally(() => this.filtrarEmpresa())
    this.filtrar('')
    console.log('Excluir: ', registro)
  }

  async validacaoCPF() {
    let cpf = this.formulario.controls['cpf']
    let campoCpf = cpf.value.split('')

    if (this.noRegistro == true || campoCpf.length < 11) {
      this._mensagem.creat('O CPF informado é inválido. Insira um CPF cadastrado.', { erro: true })
      return
    }
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this.filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }

    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }

  validacaoDatas() {
    let registro = this.formulario.value

    if (registro.dataExperiencia > registro.dataAdmissao) {
      this.validarDatas = true
    } else {
      this.validarDatas = false
      return this._mensagem.creat('A data de admissão é maior ou igual a data de experiência. Verifique as datas e tente novamente.', { erro: true })
    }

    if (registro.dataProrrogacao) {
      if (registro.dataProrrogacao > registro.dataExperiencia) {
        this.validarDatas = true
      } else {
        this.validarDatas = false
        return this._mensagem.creat('A data de experiência é maior ou igual a data de prorrogação. Verifique as datas e tente novamente.', { erro: true })
      }
    }

    if (registro.dataRescisao) {

      if (registro.dataRescisao <= registro.dataProrrogacao) {
        this.validarDatas = false
        return this._mensagem.creat('A data de prorrogação é maior ou igual a data de rescisão. Verifique as datas e tente novamente.', { erro: true })
      }

      if (registro.dataRescisao <= registro.dataAdmissao) {
        this.validarDatas = false
        return this._mensagem.creat('A data de rescisão é maior ou igual a data de admissão. Verifique as datas e tente novamente.', { erro: true })
      }

      else {
        this.validarDatas = true
      }
    }

    if (registro.avisoPrevio) {

      if (registro.avisoPrevio <= registro.dataExperiencia) {
        this.validarDatas = false
        return this._mensagem.creat('A data de experiência é maior ou igual ao aviso prévio. Verifique datas e tente novamente.', { erro: true })
      }

      if (registro.avisoPrevio <= registro.dataProrrogacao) {
        this.validarDatas = false
        return this._mensagem.creat('A data de prorrogação é maior ou igual ao aviso prévio. Verifique datas e tente novamente.', { erro: true })
      }

      if (registro.avisoPrevio <= registro.dataRescisao) {
        this.validarDatas = false
        return this._mensagem.creat('A data de recisão é maior ou igual ao aviso prévio. Verifique datas e tente novamente.', { erro: true })
      }
      else {
        this.validarDatas = true
      }
    }
  }



  /////////////////////


  // async getRegistro() {
  //   this.loadingReg = true
  //   let registro = await this.service.get(sessao.codigoEmpresa)
  //   console.log("REGISTRO", registro)
  //   this.dataSource.data = registro
  //   this.registro = registro
  //   this.loadingReg = false
  //   return
  // this.service.listaConsultorio().toPromise()
  //   .then(async res => {
  //     this.listConsultorio = res.filter(value => value.status == 'SDO0017')
  //     let getEmpresas = []
  //     for (let i = 0; i < this.listConsultorio.length; i++) {
  //       let item = this.listConsultorio[i]
  //       let empresa
  //       try {
  //         empresa = await this.token.getEmpresasToken(parseInt(item.codEmpresa))
  //       }
  //       catch {
  //         empresa = []
  //       }
  //       let especialidade = await this._conteudo.get('ESPPRO', true, item.especialidade)
  //       if (empresa.length > 0) {
  //         item.nomeEmpresa = empresa[0].nomeEmpresa
  //         getEmpresas.push(item)
  //         if (especialidade[0]) {
  //           item.descEspecialidade = especialidade[0].toString()
  //         }
  //       }

  //     }
  //     if (this.atribuicao) {
  //       getEmpresas = getEmpresas.filter(value => value.codEmpresa == sessao.codigoEmpresa.toString())

  //     }
  //     this.dataSource.data = getEmpresas
  //     this.loadingReg = false

  //   })






  // reset(open?) {
  //   this.viewCadastro = false
  //   this.formulario.reset()
  //   this.alteracao = false
  //   if (open) {
  //     this.formulario.enable()
  //     this.viewCadastro = true
  //     this.modificar = true
  //   }
  // }

}
