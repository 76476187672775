import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConteudoDados {
  id?: string
  codigoCaracteristica?: string
  codValor?: number
  codigoReferencia?: string
  valor?: string
  status?: string
  codigoDeNegocio?: string
  select?: boolean
  dependencia?: string
}


export class ConteudoDeDadosService {
  url = new environment().api
  constructor(private http: HttpClient) { }

  async get(codigo: string, referencia?: boolean, codigoReferencia?: string): Promise<ConteudoDados[]> {
    let registro = []
    await this.http.get<ConteudoDados[]>(this.url + 'ConteudoDeDados/FiltrarCodigoCaracteristica/' + codigo).toPromise()
      .then(
        (res) => {
          registro = res.filter(value => value.status == "SCA0001")
          registro = registro.sort((a, b) => {
            return parseFloat(a.codigoDeNegocio) < parseFloat(b.codigoDeNegocio) ? -1 : parseFloat(a.codigoDeNegocio) > parseFloat(b.codigoDeNegocio) ? 1 : 0
          })
          if (referencia) {
            registro = res.filter((reg) => { return reg.codigoReferencia == codigoReferencia }).map((i) => i.valor)

          }
        }
      ).catch(erorr => registro = [new ConteudoDados])

    return registro
  }
}
