export class LivroRazaoModels {
    // modeloDeLancamento: number
    // criadoPor: string
    // codigoEmpresa: number
    // contaDebito: string
    // contaCredito: string
    // areaNegocio: string
    // identificadorDoProcesso: string
    // processo: string
    // causaPatrimonio: string
    // razonete: string
    // status: string
    // ////
    // id: string
    // timestamp: string
    // nomeEmpresa: string
    // descAreaNegocio?: string
    // descProcesso?: string
    // descContaDebito: string
    // codigoContaContabil: string
    // descricao: string


    id: string
    codigoLancamento: number
    criadoPor: string
    codigoEmpresa: number
    contaDebito: string
    contaCredito: string
    descContaDebito?: string
    descContaCredito?: string
    areaNegocio: string
    descricaoProcesso: string
    status: string
    criadoEm: string
    nomeEmpresa: string
    descAreaNegocio: string

}