import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessaoUser } from '../user.reg';
import { TicketConsulta } from '../home/monitor-clinico/consultas/solicitacoes/modal';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class SalaEsperaService {
  url = new environment().api
  api = 'TicketMarcacaoConsultaMedica/'
  api_consultas = 'consultas/'
  constructor(private http: HttpClient) { }
  //************************** Sala de Espera **********************************
  async listarSalaEspera(codigoEempresa) {
    let result = []
    await this.http.get(this.url + this.api + 'SalaEspera/' + codigoEempresa).toPromise()
      .then((res: any) => {
        if (res.success) {
          result = res.result
        }
      })
    return result
  }
  async getTIcketID(id) {
    let result = new TicketConsulta
    await this.http.get<TicketConsulta>(this.url + this.api + id).toPromise()
      .then(res => { result = res })
    return result
  }
  cadastrarSalaEspera(dados: any) {
    console.log('SalaEspera-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarSalaEspera(dados) {
    console.log('SalaEspera-Alterar :', this.url + this.api + `${dados.id}/${new Date().toISOString()}/${sessao.sessao_id}`, dados)
    return this.http.put(this.url + this.api + `${dados.id}/${new Date().toISOString()}/${sessao.sessao_id}`, dados)
  }
  excluirSalaEspera(id: string, dados?) {
    console.log('SalaEspera-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }

  //**************************END Sala de Espera  **********************************


  //************************** Consultas **********************************
  listarConsultas(id?) {
    console.log('Consultas :' + this.url + this.api_consultas)
    if (id) {
      return this.http.get(this.url + this.api_consultas + id)
    }
    return this.http.get(this.url + this.api_consultas)
  }
  cadastrarConsultas(dados: any) {
    console.log('Consultas-Cadastrar :' + this.url + this.api_consultas, dados)
    return this.http.post(this.url + this.api_consultas, dados)
  }
  alterarConsultas(id: string, dados) {
    console.log('Consultas-Alterar :' + this.url + this.api_consultas + id, dados)
    return this.http.put(this.url + this.api_consultas + id, dados)
  }
  excluirConsultas(id: string, dados?) {
    console.log('Consultas-Excluir :' + this.url + this.api_consultas + id)
    return this.http.delete(this.url + this.api_consultas + id)
    // return this.http.put(this.url + this.api_consultas + id, dados)
  }

  //**************************END Consultas  **********************************


}
