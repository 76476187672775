import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PacienteService } from 'src/app/service/paciente.service';
import { CadastroPessoaForm, FormPaciente } from 'src/app/cadastro-paciente/model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import { SenhaAtendimentoForm } from '../senha-atendimento/model';
import { MensagemService } from 'src/app/service/mensagem.service';

@Component({
  selector: 'app-pesquisar-paciente',
  templateUrl: './pesquisar-paciente.component.html',
  styleUrls: ['./pesquisar-paciente.component.scss']
})
export class PesquisarPacienteComponent implements OnInit {
  @Output() event = new EventEmitter
  @Output() getRegistro = new EventEmitter
  @Output() cpfPaciente = new EventEmitter
  @Output() tipoPaciente = new EventEmitter
  @Input() outCpf: string
  formulario: FormGroup
  nullRegistro: boolean;
  loading: boolean;
  cpf: string;
  tPaciente
  registro: CadastroPessoaForm;
  constructor(private service: PacienteService, private _mensagem: MensagemService, private form: FormBuilder, private validateBrService: ValidateBrService) {

  }

  ngOnInit() {
    this.formulario = this.form.group({ cpf: new FormControl('', [this.validateBrService.cpf]) })
    if (this.outCpf) {
      this.cpf = this.outCpf
      this.buscarPaciente()
    }
  }
  buscarPaciente() {
    if (!this.cpf) return
    this.loading = true
    this.nullRegistro = false
    this.service.listarPaciente().toPromise()
      .then(
        reg => {
          if (!reg.result) {
            this._mensagem.creat('Não foi possível buscar paciente', { erro: true })
            return
          }
          const cpf = this.cpf
          let filtrar = reg.result.filter((r) => {
            return r.cpf == cpf
          })
          if (filtrar.length > 0) {
            let registro = filtrar[0]
            this.getRegistro.emit(registro)
            this.registro = registro
          }
          else {
            this.nullRegistro = true
          }
          this.loading = false
        }
      )
  }
  close() {
    console.log('saiu')
    this.event.emit(false)
  }
  CadastroPaciente() {
    this.cpfPaciente.emit(this.cpf)
    this.tipoPaciente.emit(this.tPaciente)
    this.getRegistro.emit(this.registro)
    this.event.emit(false)
  }


}
