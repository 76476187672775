import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CadastroCartaoCreditoModel } from '../ajustes/cadastros-mestres/cadastro-cartao-credito/cadastro-cartao-credito.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})


export class CadastroCartaoCreditoService {

    url = new environment().api
    api = 'CadastroCartaoCredito/'
    jsonPais = './assets/json/json-paises.json'

    constructor(private http: HttpClient, private data: BuscarDataService) { }

    listar() {
        return this.http.get<CadastroCartaoCreditoModel[]>(this.url + this.api)
    }

    enviar(registro: CadastroCartaoCreditoModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: CadastroCartaoCreditoModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    getPaises(): Observable<any> {
        return this.http.get(this.jsonPais)
    }

    async desativar(registro: CadastroCartaoCreditoModel, ativo) {

        if (ativo) {
            registro.status = 'SDO0060'
        } else {
            registro.status = 'SDA0002'
        }


        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })
        return resposta

    }
}