import { Component, OnInit, ViewChild } from '@angular/core';
import { FichaContabilFornecedorService } from '../service/cadastro-fornecedor.service';
import { MensagemService } from '../service/mensagem.service';
import { dadosCadastroFichaGeral, dadosCadastrarFichaContabil, dadosCondicaoPagamentoFornecedor, dadosFichaTecnicaFornecedor } from './CadastroFornecedorModels';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { CamposFichaGeralEmpresas } from '../ajustes/posicao-numeracao-nfe/campos-posicaoNfe';
import { SessaoUser } from '../user.reg';
import { FichaTecnicaFornecedorService } from '../service/ficha-tecnica-fornecedor.service';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { SistemaClassificacaoDadosService } from '../service/sistema-classificacao-dados.service';
import { PlanoContaContabeisService } from '../service/plano-conta-contabeis/plano-conta-contabeis.service';
import { FichaCondicaoPagamentoFornecedorService } from '../service/ficha-condicao-pagamento-fornecedor.sevice';
import { FichaGeralFornecedorService } from '../service/ficha-geral-fornecedor.service';
import { BuscarDataService } from '../service/buscar-data.service';
import { FichaDeAssinanteService } from '../service/ficha-de-assinante/ficha-de-assinante.service';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { CadastroOperadoraSaudeService } from '../service/cadastro-operadora-de-saudade.service';
import { ConteudoDados, ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { HubDevService } from '../service/hub-dev.service';

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-fornecedor',
  templateUrl: './cadastro-fornecedor.component.html',
  styleUrls: ['./cadastro-fornecedor.component.scss']
})
export class CadastroFornecedorComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'codigoFornecedor','nomeFornecedor', 'cnpj']

  codigoFornecedor: number
  listar: boolean
  viewCadastro: boolean
  listEmpresa = []
  mask_cep = "00000-000"
  mask_telefone = "(00) 00000-0000"
  mask_cnpj = "00.000.000/0000-00"
  listMoedas = []
  listaCheckBoxTable = []
  viewCampRequired: boolean
  filtroContaDebito = []
  filtroContaCredito = []
  dadosContaContabilDebito: any[];
  dadosContaContabilCredito: any[];
  botaoExcluir: boolean
  modoExibicao: boolean
  //FICHA GERAL FORNECEDOR

  //FICHA CONTABIL FORNECEDOR

  //FICHA CONDICAO PAGAMENTO FORNECEDOR

  GetDadosEmpresa = []

  conteudoMoedas: conteudoDeDado[];
  conteudoLIFOPA: conteudoDeDado[];
  conteudoAUTPAG: conteudoDeDado[];

  formulario: FormGroup
  resultadoAssinante: any;

  ///
  desableAlterar: boolean
  botaoModificar: boolean
  alteracao: boolean
  botaoSalvar: boolean
  botaoCancelar: boolean
  viewConfirmDelete: boolean
  loading: boolean;
  listRegistro: any;
  selectEmpresa: any;
  viewConfirmCancelar: boolean

  dataSource = new MatTableDataSource()
  listBancos: any;
  listBancos_Backup: any;
  listPaises: any;
  listFormaPagamento: ConteudoDados[];
  listAutorizacao: ConteudoDados[];

  constructor(
    private token: TokenDeAcessoService,
    private serviceCadOperadora: CadastroOperadoraSaudeService,
    private form: FormBuilder,
    private service: FichaGeralFornecedorService,
    private mensagem: MensagemService,
    private conteudoDeDados: ConteudoDeDadosService,
    private _hubDev: HubDevService,
    private _filtro: FiltrarDadosService

  ) { }

  async ngOnInit() {
   await this.getEmpresas()
    this.getBancos()
    this.getPaises()
    this.getConteudoDeDados()
    this.listar = true
    this.createForm()
    
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      codigoFornecedor: 0,
      codigoEmpresa: new FormControl(0, Validators.required),
      nomeFornecedor: new FormControl('', Validators.required),
      nomeEmpresa: '',
      cep: '',
      rua: '',
      cidade: '',
      bairro: '',
      estado: '',
      numeroEndereco: '',
      complementoEndereco: '',
      webSite: '',
      telefone: '',
      email: '',
      nomeContato: '',
      ativoDesde: '',
      inativoDesde: '',
      areaAtuacao: new FormControl('', Validators.required),
      servicos: new FormControl('', Validators.required),
      cnpj: new FormControl('', Validators.required),
      prazoPagamento: '',
      formaPagamento: '',
      banco:'',
      paisBanco: '',
      agencia: '',
      contaBancaria: '',
      autorizacaoPagamento: '',
      observacao: '',
      criadoPor: '',
      criadoEm: '',
      status: ''

    })
  }

  _valid(formControlName) {
    return this.formulario.get(formControlName).valid
  }

  async getEmpresas() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
    console.log('EMPRESAS ',this.listEmpresa)
    console.log('LISTEMPRESA',this.listEmpresa[0].empresa)
    console.log('V EMPRESA',empresa[0].empresa)

   await this.getRegistro(this.listEmpresa[0].empresa)
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  async openDetails(registro) {

    console.log('REGISTRO DETAILS', registro)
    this.formulario.patchValue(registro)  
    await this.formulario.disable()
    this.viewCadastro = true
    this.modoExibicao = true
    this.alteracao = true
    this.botaoSalvar = false
    this.viewCampRequired = false
    this.botaoCancelar = false
    this.botaoSalvar = false
    this.botaoExcluir = true
    this.botaoModificar = true
    
  }
  async inserir() {
    let registro = this.formulario.value
    delete registro.nomeEmpresa   

    if (this.formulario.invalid) {
      this.mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      let verific = this.verificarCamposValidos()
      console.log(verific)
      return
    }
    let cnpjValido =  await this.ValidarCnpj()
    if(cnpjValido == false)
    {
      console.log('ENTROU NO RAIO DO IF')
       this.mensagem.creat("Cnpj não encontrado, verifique se foi digitado corretamente.", { erro: true })
       return
    }

    Object.keys(registro).forEach(value => {
      if (!registro[value])
        delete registro[value]
    })

    registro.criadoPor = sessao.cpf
    registro.status = 'SCA0007'
    console.log('REGISTRO', JSON.stringify(registro))

    this.service.Post(registro).toPromise()
      .then(resp => {
        this.mensagem.creat(resp[0].mensagem, { sucesso: true })
        this.formulario.disable()
        this.Fechar()
      }
      ).catch(err => {
        console.log(err)
        let erro = err.error
        this.mensagem.creat(err.error, { erro: true })
      })
      
   
  }

  alterar(acao?) {
    let message = 'Registro alterado com sucesso.'
    let registro = this.formulario.value
    delete registro.nomeEmpresa
    if(acao == 'excluir')
    {
      message = 'Registro excluido com sucesso.'
      registro.status = 'SCA0008'
      this.viewConfirmDelete = false
    }
    console.log('JSON ALT', JSON.stringify(registro))
    this.service.Put(registro).toPromise()
      .then(() => {
        this.mensagem.creat(message, { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.botaoSalvar = false
        this.botaoCancelar = false
        this.Fechar()
      }).catch(error => {
        console.log(error)
        let erro = error.error
        this.mensagem.creat(erro, { erro: true })
      })
  }

 async Fechar()
  {
    let registro = this.formulario.value
    this.viewCadastro = false
    
    if(registro.codigoEmpresa != null)
    {
      await this.getRegistro(registro.codigoEmpresa)

    }
  }

  OpenCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.modoExibicao = false
    this.botaoExcluir = false
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.botaoModificar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SCA0007')
  }



  async getPaises() {
    this.serviceCadOperadora.getPaises().subscribe(response => {
      this.listPaises = response
    })
  }

  async getBancos() {
    this.serviceCadOperadora.getBancos().subscribe(response => {
      this.listBancos = response
      console.log('BANCOS: ',this.listBancos)
     let order = this.listBancos.sort(function (a, b) {
        let acodigoBanco = a.codigoBanco
        let bcodigoBanco = b.codigoBanco
        if (a == b) return
        return acodigoBanco < bcodigoBanco ? -1 : 1
      })
      this.listBancos = order
      this.listBancos_Backup = order
    })
  }

  

  async cancelarRegistro()
  {
    const id  = this.formulario.value.id
    let registro = await this.service.GetById(id)
    this.openDetails(registro)
    console.log('formulario', this.formulario.value)
    this.viewConfirmCancelar = false
  }

  async getRegistro(codigoEmpresa) {
    this.loading = true
   
    await this.service.GetFiltrarCodigoEmpresa(codigoEmpresa).toPromise()
      .then((registros:any) => {
        this.listRegistro = registros.filter(item => item.status == 'SCA0007')       
        this.dataSource = new MatTableDataSource(registros)
        this.dataSource.sort = this.sort
        this.loading = false
      }).catch(err => {
    this.loading = false 
    this.dataSource = new MatTableDataSource()})
  }

  async getConteudoDeDados() {
    let formaPagamento = await this.conteudoDeDados.get('LIFOPA')
    this.listFormaPagamento = formaPagamento
    let autorizacao = await this.conteudoDeDados.get('AUTPAG')
    this.listAutorizacao = autorizacao
  }

  Modificar()
  {
    this.desableAlterar = true
    this.modoExibicao = false
    this.botaoExcluir = false
    this.formulario.enable()
  }

  verificarCamposValidos() {
    const invalid = [];
    const controls = this.formulario.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getCEP() {
    if (this._control('cep').invalid) return
    let cep = this._control('cep').value
    this._hubDev.vCEP(cep).toPromise().then(
      (resp) => {
        let cep = resp.result
        console.log('CEP ', cep)
        this._control('rua').setValue(cep.logradouro)
        this._control('bairro').setValue(cep.bairro)
        this._control('cidade').setValue(cep.localidade)
        this._control('estado').setValue(cep.uf)
      }
    )
  }
  async ValidarCnpj() {
    let valido 
    if (this._control('cnpj').invalid) {

      this._control('nomeEmpresa').setValue('')
      this._control('cep').setValue('')
      return true
    }
    let cnpj = this._control('cnpj').value
   await this._hubDev.vCNPJ(cnpj).toPromise().then(({ status, result: empresa }) => {
      if (!status) {       
         this.mensagem.creat("Cnpj não encontrado, verifique se foi digitado corretamente.", { erro: true })
        valido =  false
      }
      else {
        this._control('nomeEmpresa').setValue(empresa.nome)
        if (empresa.fantasia) {
       //   this._control('nomeFantasia').setValue(empresa.fantasia)
        }
        this._control('cep').setValue(empresa.cep)
        this._control('numeroEndereco').setValue(empresa.numero)
        setTimeout(() => this.getCEP(), 100)
        valido =  true
      }
    })

    return valido
  }

  _control(formControlName) {
    return this.formulario.controls[formControlName]
  }

    
  filterBanco(value: string) {
    let registros = this.listBancos_Backup
    let result = registros.filter(item => item.NomeBanco.toUpperCase().includes(value.toUpperCase()))
    this.listBancos = result
  }


}
