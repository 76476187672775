import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort,MatSnackBar, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuariosLogadosService } from '../service/usuarios-logados.service';


@Component({
  selector: 'app-usuarios-logados',
  templateUrl: './usuarios-logados.component.html',
  styleUrls: ['./usuarios-logados.component.scss']
})
export class UsuariosLogadosComponent implements OnInit,OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

displayedColumns = ['detalhe','cpf','nomeCompleto','inicioSessao']
  
dataSource

viewRegistro
interval_login: NodeJS.Timer;
registro

  constructor(private mensagem: MatSnackBar,
    private _form: FormBuilder,
    private service: UsuariosLogadosService) { }
 

  ngOnInit() {
    this.GetRegistros();
    this.interval_login = setInterval(() => {
      this.GetRegistros()

    }, 9000)
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_login)
  }
  
 GetRegistros(){
   this.service.GetComNome().toPromise().then(
     (registros: any) => {
      this.dataSource = new MatTableDataSource(registros)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
      
     }
   ).catch((err) => {
     console.log(err.response)
   })
 } 

 filtro(reg: string) {
  this.dataSource.filter = reg.trim().toLowerCase();
} 

}
