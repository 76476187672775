import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";
import { formAtendimentoClinico } from "./model";
import { AtendimentoClinicoService } from "../service/atendimento-clinico.service";
import { ActivatedRoute } from "@angular/router";
import { BuscarUsuarioService } from "../service/buscar-usuario.service";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { async } from "@angular/core/testing";
import { SalaEsperaService } from "../service/sala-espera.service";
import { SalaEsperaForm } from "./sala-espera/model";
import { PacienteService } from "../service/paciente.service";
import { FormPaciente } from "../cadastro-paciente/model";
import { parse } from "querystring";
import { FormUsuario } from "../usuario/model";
import {
  UsuarioService,
  UsuarioQuadroDeSaudeService,
} from "../service/usuario.service";
import { BuscarDataService } from "../service/buscar-data.service";
import { FormPagamento } from "../cobrar-consulta/modal";
import { ValorConsultaService } from "../service/valor-consulta.service";

@Component({
  selector: "app-atendimento-clinico",
  templateUrl: "./atendimento-clinico.component.html",
  styleUrls: ["./atendimento-clinico.component.scss"],
})
export class AtendimentoClinicoComponent implements OnInit {
  @ViewChild("body", { static: false }) body: ElementRef;
  @Input() refUsuarioID: string;
  @Input() refPacienteID: string;
  @Input() pacientePesquisa: any;
  id;
  idade: number;
  companyId: string;
  abrirHistorico;
  abrirHistoricoSV;
  abrirHistoricoEX;
  abrirHistoricoRE;
  erro: boolean;
  erroMensagem: string;
  solicitado;
  leucocitos;
  Formulario: FormGroup;
  cpf_medico = sessionStorage.getItem("cpf");
  keyVideoChamda;
  registro = new formAtendimentoClinico();
  infoPagamento = new FormPagamento();
  //Historicos
  historico: formAtendimentoClinico[];

  //view historico
  vhAnammese;
  vhSinaisVitais;
  vhExame;
  vhResultadoExame;
  regHorario: any;
  formParcial = new formAtendimentoClinico();
  indexRef: any;
  index: any;
  pacienteId: string;
  paciente = new SalaEsperaForm();
  infoSalaEspera = new SalaEsperaForm();
  loading: boolean;
  Habitos: boolean;
  HistoricoDoenca: boolean;
  usuarioId: any;
  Deficiencia: boolean;
  registroQuadroSaude: any = {};
  Alergia: boolean;
  Tratamento: boolean;
  Exame: boolean;
  Periodicas: boolean;
  HistoricoInternacao: boolean;
  SinaisVitais: boolean;
  dadosSaude: boolean;
  DoencasFamilia: boolean;
  Lesoes: boolean;
  Cirurgias: boolean;
  detalheDadosSaude: boolean;
  loadingDetDadosSaude: boolean;
  videoChamada;
  registroConsultas: any;
  constructor(
    private form: FormBuilder,
    private service: AtendimentoClinicoService,
    private serviceSalaDeEspera: SalaEsperaService,
    private servicePaciente: PacienteService,
    private serviceUsuario: UsuarioService,
    private serviceQuadroDeSaude: UsuarioQuadroDeSaudeService,
    private servicePagamento: ValorConsultaService,
    private url: ActivatedRoute,
    private busarUsuario: BuscarUsuarioService,
    private buscarData: BuscarDataService,
    private mensagem: ToastrService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.keyVideoChamda = localStorage.getItem("keyVC");
    this.registroConsultas = JSON.parse(
      localStorage.getItem("registroConsultas")
    );
    console.log(this.registroConsultas);
    this.infoSalaEspera = await JSON.parse(
      localStorage.getItem("infoSalaEspera")
    );
    if (!this.infoSalaEspera) {
      this.infoSalaEspera = new SalaEsperaForm();
    }
    if (this.keyVideoChamda) {
      this.videoChamada = true;
      return;
    }
    setTimeout(() => {
      this.keyVideoChamda = localStorage.getItem("keyVC");
      this.videoChamada = true;
    }, 2000);

    this.regHorario = new Date().toLocaleTimeString().substr(0, 5);
    await this.urlID().finally(async () => {
      await this.buscarId();
      await this.buscarPaciente(this.pacienteId);
      await this.buscarPagamento();
      await this.creatFormulario();
    });
    await this.buscarAtendimento();
    this.buscarFormLog();
    this.loading = false;
    // await this.buscarQuadroSaude()
  }
  async buscarId() {
    if (this.companyId) return;
    await this.serviceUsuario
      .listar()
      .toPromise()
      .then(async (dados: Array<FormUsuario>) => {
        console.log("teste : " + this.id);
        let usuarioId = this.id;
        let filtro = await dados.filter(function (reg: FormUsuario) {
          // console.log(`usuarioID : ${reg.usuarioId} -- ${usuarioId} `)
          return reg.usuarioId.toUpperCase() == usuarioId.toUpperCase();
        });
        console.log("Seu filtro " + filtro.length);
        let reg = filtro[0];
        this.companyId = reg.companyId;
        localStorage.setItem("companyId", this.companyId);
      })
      .catch(() => {
        console.log("Company ID @ erro: " + this.companyId);

        this.erroMensagem = "Não foi possível localizar registro";
      });
  }

  async buscarPaciente(id) {
    if (this.refPacienteID && this.refUsuarioID) {
      this.paciente = this.pacientePesquisa;
      this.idade = this.buscarData.idade(this.pacientePesquisa.nascimento);
      console.log("PP : " + JSON.stringify(this.paciente));
      return;
    }
    // await this.serviceSalaDeEspera.listarSalaEspera().toPromise()
    //   .then(
    //     (dados: Array<SalaEsperaForm>) => {
    //       let filtro = dados.filter(function (reg) {
    //         return reg.pacienteId == id
    //       })
    //       if (!filtro.length) {
    //         this.mensagem.error('Não foi possivel vincular paciente!')
    //         this.paciente = new SalaEsperaForm
    //         console.log('pacienteId :' + id)
    //         return
    //       }
    //       this.paciente = filtro[0]
    //     }
    //   ).catch(
    //     error => {
    //       this.mensagem.error('Sem comunicação com servidor')
    //       if (this.pacienteId == '99') { return }
    //       this.erro = true,
    //         this.erroMensagem = `Não obteve Registro - ${error.status}`
    //     }
    //   )
    this.servicePaciente
      .listarPaciente()
      .toPromise()
      .catch((error) => {
        (this.erro = true), (this.erroMensagem = "Erro não obteve paciente!");
      });
  }
  buscarAtendimento() {
    this.service
      .listarAtendimentoClinico()
      .toPromise()
      .then((reg: formAtendimentoClinico[]) => {
        let usuarioId = this.id;
        let companyId = this.companyId;
        let pacienteId = this.pacienteId;
        console.log("FormAtendimento : " + reg.length);
        console.log("usuario id : " + usuarioId);
        console.log("companyId : " + companyId);
        console.log("pacienteId : " + pacienteId);
        let filtro = reg.filter(function (reg: formAtendimentoClinico) {
          // console.log('reg.pacienteId : ' + reg.pacienteId + ' / ' + pacienteId)
          // console.log('reg.companyId : ' + reg.pacienteId + ' / ' + companyId)
          // console.log('reg.usuarioId : ' + reg.usuarioId + ' / ' + usuarioId)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.companyId.toUpperCase() == companyId.toUpperCase() &&
            reg.pacienteId.toUpperCase() == pacienteId.toUpperCase()
          );
        });
        // console.log('Filtro : ' + filtro)

        if (filtro.length && this.refPacienteID && this.refUsuarioID) {
          this.mensagem.info("Registro encontrado");
        }
        if (!filtro.length && this.refPacienteID && this.refUsuarioID) {
          this.mensagem.warning("Sem Registro");
        }

        let ordenar = filtro.sort(function (a, b) {
          if (!a.data || !b.data) {
            return;
          }
          a.data.replace("/", "");
          b.data.replace("/", "");
          return parseInt(b.data) - parseInt(a.data);
        });
        this.historico = ordenar;
      });
  }
  buscarFormLog() {
    let registro = JSON.parse(localStorage.getItem("regParcial"));
    if (registro) this.registro = registro;
    else {
      this.registro = new formAtendimentoClinico();
    }
    console.log("Log : " + JSON.stringify(this.registro));
  }
  buscarPagamento() {
    this.servicePagamento
      .listarValorConsulta()
      .toPromise()
      .then((dados: Array<FormPagamento>) => {
        let usuarioId = this.id;
        let companyId = this.companyId;
        let pacienteId = this.pacienteId;
        let filtro = dados.filter(function (reg) {
          return (
            reg.usuarioId == usuarioId &&
            reg.pacienteId == pacienteId &&
            reg.companyId == companyId
          );
        });
        filtro[filtro.length - 1];
        let ordenar = filtro.sort(function (a, b) {
          console.log(
            `ordenar : ${b.dataEmitido.replace(
              /[^a-z0-9]/gi,
              ""
            )} -- ${a.dataEmitido.replace(/[^a-z0-9]/gi, "")}`
          );
          return (
            parseInt(b.createdAt.replace(/[^a-z0-9]/gi, "")) -
            parseInt(a.createdAt.replace(/[^a-z0-9]/gi, ""))
          );
        });
        console.table(ordenar);
        this.infoPagamento = ordenar[0];
      });
  }
  async salvar(ref) {
    console.log(localStorage.getItem("regParcial"));
    switch (ref) {
      case "anamnese":
        this.formParcial.anamnese = this.registro.anamnese;
        await localStorage.setItem(
          "regParcial",
          JSON.stringify(this.formParcial)
        );
        console.log(localStorage.getItem("regParcial"));
        break;

      case "sinaisVitais":
        this.formParcial.temperatura = this.registro.temperatura;
        this.formParcial.pressaoArterial = this.registro.pressaoArterial;
        this.formParcial.saturacao = this.registro.saturacao;
        this.formParcial.batCardiacos = this.registro.batCardiacos;
        await localStorage.setItem(
          "regParcial",
          JSON.stringify(this.formParcial)
        );
        console.log(localStorage.getItem("regParcial"));
        break;
      case "exameF":
        this.formParcial.exame = this.registro.exame;
        await localStorage.setItem(
          "regParcial",
          JSON.stringify(this.formParcial)
        );
        console.log(localStorage.getItem("regParcial"));
        break;

      case "resultadoExame":
        this.formParcial.solicitado = this.registro.solicitado;
        this.formParcial.crm = this.registro.crm;
        this.formParcial.leococitos = this.registro.leococitos;
        this.formParcial.plaquetas = this.registro.plaquetas;
        this.formParcial.hemoglobinaS = this.registro.hemoglobinaS;
        await localStorage.setItem(
          "regParcial",
          JSON.stringify(this.formParcial)
        );
        console.log(localStorage.getItem("regParcial"));
        break;
      // this.service.cadastrarAtendimentoClinico(this.Formulario.value).toPromise()
      // .then(
      //   ()=> {
      //     this.buscarAtendimento()
      //     this.mensagem.success('Salvo com sucesso')
      //   }
      // )
      // .catch(
      //   ()=> this.mensagem.error('Não foi possivel gravar')
      // )
    }
  }
  cadastrar() {
    this.registro.usuarioId = this.id;
    if (this.registro.usuarioId || this.pacienteId == "99") {
      this.service
        .cadastrarAtendimentoClinico(this.Formulario.value)
        .toPromise()
        .then(async () => {
          localStorage.setItem(
            "sintoma",
            this.Formulario.controls["anamnese"].value
          );
          await localStorage.setItem("indexRefAv", "true");
          console.log("Salvo com sucesso");
        });
    } else {
      console.log(
        "Registro : " +
          this.registro.usuarioId +
          "- Form : " +
          this.Formulario.controls["usuarioId"].value
      );
    }
  }
  creatFormulario() {
    this.Formulario = this.form.group({
      usuarioId: this.id,
      companyId: this.companyId,
      pacienteId: this.pacienteId,
      data: new Date().toLocaleDateString(),
      regHorario: new Date().toLocaleTimeString().substr(0, 5),
      anamnese: new FormControl("", Validators.required),
      temperatura: new FormControl("", Validators.required),
      pressaoArterial: new FormControl("", Validators.required),
      saturacao: new FormControl("", Validators.required),
      batCardiacos: new FormControl("", Validators.required),
      exame: new FormControl("", Validators.required),
      solicitado: new FormControl("", Validators.required),
      leococitos: new FormControl("", Validators.required),
      plaquetas: new FormControl("", Validators.required),
      hemoglobinaS: new FormControl("", Validators.required),
      crm: new FormControl(String, Validators.required),
    });
  }
  teste() {
    console.log(this.registroQuadroSaude);
  }
  public async buscarQuadroSaude() {
    this.loadingDetDadosSaude = true;
    //Limpar dados
    // this.registroQuadroSaude = {}
    this.Habitos = false;
    this.HistoricoDoenca = false;
    //END
    if (!this.companyId)
      this.companyId = "00000000-0000-0000-0000-000000000000";
    await this.serviceQuadroDeSaude
      .listarDeficiencia()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var companyId = this.companyId;
        var usuarioId = this.pacienteId;
        // console.log('Quadro de Saude : ' + dados.length)
        if (!dados) return;
        var filtro = dados.filter(function (reg: any) {
          // console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toLowerCase() == usuarioId.toLowerCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Deficiencia = true;
          let deficiencia = filtro.map((x) => x.natureza);
          this.registroQuadroSaude.deficiencia = deficiencia[0];
          console.log(this.registroQuadroSaude);
        } else {
        }
      });

    await this.serviceQuadroDeSaude
      .listarAlergia()
      .toPromise()
      .then((dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        if (!dados) return;
        var filtro = dados.filter(function (reg: any) {
          // console.log('2 - reg.companyId : ' + reg.companyId.toLowerCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Alergia = true;
          let alergia = filtro.map((x) => x.descricao);
          this.registroQuadroSaude.alergia = alergia[0];
          // console.log('Filtrado com sucesso ! ' + filtro.length)
        }
      });

    await this.serviceQuadroDeSaude
      .listarHabito()
      .toPromise()
      .then((dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('1 - reg.companyId HabitoID: ' + reg.companyId.toUpperCase() + ' : ' + id + ' ID------' + reg.id + ' UsuarioID-------' + reg.usuarioId)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Habitos = true;
          this.registroQuadroSaude.habitos = filtro[0];
          // console.log('Filtrado com  HabitoID! ' + this.registroQuadroSaude.habito[0].id)
        } else {
          // console.log('Filtrado com  HabitoID! ' + 'Não Passou - ' + filtro.length)
          this.Habitos = true;
          this.registroQuadroSaude.habitos = {
            fumante: false,
            drogas: false,
            alcoolica: false,
            fisica: false,
          };
        }
      });

    await this.serviceQuadroDeSaude
      .listarMedicacao()
      .toPromise()
      .then((dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('4 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Alergia = true;
          this.registroQuadroSaude.medicacao = filtro;
          // console.log('Filtrado com sucesso ! ' + filtro.length)
        }
      });

    await this.serviceQuadroDeSaude
      .listarHistoricoDoencas()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('5 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.HistoricoDoenca = true;
          let historicoDoenca = filtro;
          this.registroQuadroSaude.historicoDoenca = historicoDoenca;
          console.log(historicoDoenca);
          // console.log('Doença : ' + JSON.stringify(this.registroQuadroSaude.deficiencia))
        }
      });

    await this.serviceQuadroDeSaude
      .listarTratamento()
      .toPromise()
      .then((dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('6 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Tratamento = true;
          this.registroQuadroSaude.tratamento = filtro[filtro.length - 1];
          // console.log('Filtrado com sucesso ! ' + filtro.length)
        }
      });

    await this.serviceQuadroDeSaude
      .listarResultadoExame()
      .toPromise()
      .then((dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('7 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Exame = true;
          this.registroQuadroSaude.exame = filtro;
          // console.log('Filtrado com sucesso ! ' + filtro.length)
        }
      });

    await this.serviceQuadroDeSaude
      .listarAvaliacoesPeriodicas()
      .toPromise()
      .then((dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('8 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Periodicas = true;
          this.registroQuadroSaude.periodicas = filtro;
          // console.log('Filtrado com sucesso ! ' + filtro.length)
          // console.log('Deficiencia : ' + JSON.stringify(this.registroQuadroSaude))
        }
      });

    await this.serviceQuadroDeSaude
      .listarHistoricoInternacoes()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.HistoricoInternacao = true;
          this.registroQuadroSaude.historicoInternacoes =
            filtro[filtro.length - 1];
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.historicoInternacoes))
        }
      });

    await this.serviceQuadroDeSaude
      .listarSinaisVitais()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        if (!dados) dados = [];
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        console.log("Sinais Vitais : " + JSON.stringify(filtro));
        if (filtro.length >= 1) {
          this.SinaisVitais = true;
          this.registroQuadroSaude.sinaisVitais = filtro[filtro.length - 1];
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
        } else {
          this.registroQuadroSaude.sinaisVitais = {};
        }
      });

    await this.serviceQuadroDeSaude
      .listarDadosSaude()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        if (!dados) dados = [];
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        console.log("Sinais Vitais : " + JSON.stringify(filtro));
        if (filtro.length >= 1) {
          this.dadosSaude = true;
          this.registroQuadroSaude.dadosSaude = filtro[filtro.length - 1];
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
        } else {
          this.registroQuadroSaude.dadosSaude = {};
        }
      });

    await this.serviceQuadroDeSaude
      .listarDoencasFamilia()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.DoencasFamilia = true;
          this.registroQuadroSaude.doencasFamilia = filtro;
          console.log(filtro);
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.doencaFamilia))
        }
      });

    await this.serviceQuadroDeSaude
      .listarLesoes()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Lesoes = true;
          this.registroQuadroSaude.lesoes = filtro;
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.lesoes))
        }
      });

    await this.serviceQuadroDeSaude
      .listarCirurgias()
      .toPromise()
      .then(async (dados: Array<any>) => {
        var usuarioId = this.pacienteId;
        var filtro = dados.filter(function (reg: any) {
          // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return (
            reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() &&
            reg.ativo == true
          );
        });
        if (filtro.length >= 1) {
          this.Cirurgias = true;
          this.registroQuadroSaude.cirurgias = filtro;
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.cirurgias))
        }
      });
    console.log(this.registroQuadroSaude);
    this.detalheDadosSaude = true;
    this.loadingDetDadosSaude = false;
  }
  sessao(index) {
    this.index = index;
    localStorage.setItem("sintoma", this.Formulario.controls["anamnese"].value);

    let indexRef = localStorage.getItem("indexRefAv");
    if (index == 1) {
      if (indexRef != "true" || this.pacienteId == "99") {
        this.cadastrar(),
          console.log(
            "Cadastrou 1 !" + "indexRef: " + indexRef + " index : " + this.index
          );
      } else {
        console.log("Não passou");
      }
      return;
    }
  }
  descerScroll() {
    setTimeout(() => {
      this.body.nativeElement.scrollTop = 1000;
    }, 400);
  }
  async urlID() {
    if (this.refPacienteID && this.refUsuarioID) {
      this.id = this.refUsuarioID;
      this.pacienteId = this.refPacienteID;
      return;
    }
    await this.url.url.subscribe(async (reg) => {
      console.log("Filtro :" + reg[1].path);
      this.id = reg[1].path;
      this.pacienteId = reg[2].path;
      console.log("ID PACIENTE : " + this.pacienteId);
    });
  }
}
