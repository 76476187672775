import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
interface Bulario {
  id: string,
  usuarioId: string,
  companyId: string,
  medicamento: string,
  bula: string,
  createdAt: string,
  ativo: true
}
@Injectable({
  providedIn: 'root'
})
export class BularioService {
  url = new environment().api + 'BulasCadastradas/'
  constructor(private http: HttpClient) { }

  listar() {
    console.log(this.url)
    return this.http.get<Bulario[]>(this.url)

  }
  adicionar(dados: Bulario) {
    //console.log('Adicionar : ' + this.url+'MenuMaster/',dados)
    return this.http.post<Bulario>(this.url, dados)
  }

  editar(dados: Bulario, id: number) {
    console.log('Editar : ' + this.url + id, dados)
    return this.http.put<Bulario>(this.url + id, dados)
  }
  excluir(id: number) {
    //console.log('Deletar : ' + this.url + id)
    return this.http.delete<Bulario>(this.url + id)

  }
}
