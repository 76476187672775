export class dadosDefinirEmpresaMedica {
    id?: any;
    codigoEmpresa?: number;
    criadoPor?: string;
    nomeEmpresa?: string;
    tipoEmpresa?: string;
    webSiteEmpresa?: string;
    cabecalhoImpressao?: string;
    telefoneEmpresa?: string;
    email?: string;
    status?: string;
    timestamp?: string;
    observacao?: string;
    codigoAssinante?: number;
    cpfResponsavel?: string;
    crmResponsavel?: string;
    cnpj?: string;
    cpfProprietario?: string;
    razaoSocial?: string;
    cnpjMatriz?: string;
    codigoEmpresaMatriz?: string;
    moedaEmpresa?: string;
    rua?: string;
    bairro?: string;
    cidade?: string;
    estado?: string;
    cep?: string;
    numeroEndereco?: string;
    fusoHorario?: string;
    numeroDocumento?: string;
    nomeFantasia?: string;
    classificacaoEmpresa?: string;
    cpfAdministrador?: string;
}
