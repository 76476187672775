import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PedidoDeCompraService } from 'src/app/service/pedido-de-compra/pedido-de-compra.service';
import { FaturaDeAssinaturaService } from 'src/app/service/fatura-de-assinatura/fatura-de-assinatura.service';
import { cadastroUsuario } from 'src/app/admin-usuarios/model';
import { CabecalhoPedidoCompra } from 'src/app/service/pedido-de-compra/cabecalhoCompra.model';
import { PedidoCompraItem } from 'src/app/service/pedido-de-compra/itemCompra.model';
import { FaturaDeAssinatura } from 'src/app/service/fatura-de-assinatura/faturaAssinatura.model';
import { ClassificacaoDeDadosService } from 'src/app/service/classificacao-de-dados/classificacao-de-dados.service';
import { conteudoDeDado } from 'src/app/service/classificacao-de-dados/conteudo-de-dados.model';

@Component({
  selector: 'pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent implements OnInit {

  @Input() cadastroValido: boolean;
  @Input() cadastroUsuario: cadastroUsuario;
  @Output() pagamentoRealizado = new EventEmitter<Object>();
  formasDePagamento: conteudoDeDado[];
  cpfMask = "000.000.000-A0";
  formularioPagamento: FormGroup;
  camposObrigatorios: boolean;
  constructor(
    private construtorForm: FormBuilder,
    private servicePedidoCompra: PedidoDeCompraService,
    private serviceFatura: FaturaDeAssinaturaService,
    private serviceConteudo: ClassificacaoDeDadosService,
  ) { }

  async ngOnInit() {
    this.inicializarFormulario();
    this.formasDePagamento = await this.serviceConteudo.buscarConteudosDaCaracteristica({codigoCaracteristica: 'MEIOPG'});
    this.formularioPagamento.controls['formaDePagamento'].setValue(this.formasDePagamento[1].codigoReferencia)
  }

  inicializarFormulario() {
    this.formularioPagamento = this.construtorForm.group({
      formaDePagamento: ['MEIOPG2', Validators.required],
      cpfCartao: ['', Validators.required],
      numeroCartao: ['', Validators.required],
      nomeCartao: ['', Validators.required],
      validadeCartao: ['', Validators.required],
      codigoVerificador: ['', Validators.required],
    }, { updateOn: 'change' })
  }

  verificarCPF() {
    const cpf: string = this.formularioPagamento.get('cpfCartao').value
    this.cpfMask = cpf.length > 10 ? "000.000.000-00" : "000.000.000-A"
  }

  get formaDePagamento() {
    return this.formularioPagamento.get('formaDePagamento').value;
  }

  getError(campo: string) {
    if (!this.formularioPagamento.controls[campo].valid) {
      return 'Campo obrigatório'
    }
  }

  realizarPagamentoCredito() {
    //Aqui será implementada a lógica para realização de pagamento
    this.confirmarPagamento('Crédito');
  }

  realizarPagamentoMEIOPG1() {
    //Aqui será implementada a lógica para realização de pagamento
    this.confirmarPagamento('Débito');
  }

  buscarValorItem(){

  }

  confirmarPagamento(formaPagamento: string) {
    const pedidoCompra: { cabecalho: CabecalhoPedidoCompra, items: PedidoCompraItem[] } =
    {
      cabecalho: {
        cnpjEmpresaCliente: '',
        cpfCliente: this.cadastroUsuario.cpf,
        criadoPor: this.cadastroUsuario.cpf,
        moedaDeCompra: 'BRL', //Utilizar característica moeda de compra
        valorTotalDesconto: 0,
        valorTotalImposto: 0,
        dataDeEntrega: '',
        dataDoDocumento: '',
        dataLancamento: '',
        status: formaPagamento === 'MEIOPG1' ? 'SDO0090' : '' //MEIOPG1 => Boleto bancário
      },
      items: [{
        criadoPor: this.cadastroUsuario.cpf,
        aliquotaImposto: 0,
        imposto: 0,
        valorImpostoItem: 0,
      }]
    }

    const faturaDeAssinatura: FaturaDeAssinatura = {
      dataDoPagamento: formaPagamento === 'MEIOPG1' ? '' : '',
      dataDoVencimento: formaPagamento === 'MEIOPG1' ? '' : '',
      meioDePagamento: formaPagamento,
      moedaDaFatura: 'BRL',
      nrDocumentoReferencia: '',
      status: formaPagamento === 'MEIOPG1' ? 'SDO0090' : '',
      tipoFaturaAssinatura: 'TIPFAT1',
      valorTotalDesconto: 0,
      valorTotalFatura: 0,
      valorTotalImposto: 0,
      criadoPor: this.cadastroUsuario.cpf,
      dataDoDocumento: '',
      dataLancamento: '',
    }

    this.servicePedidoCompra.realizarPedidoDeCompra(pedidoCompra)
      .then(documentoReferencia => {
        faturaDeAssinatura.nrDocumentoReferencia = documentoReferencia;
        this.serviceFatura.gerarFatura(faturaDeAssinatura)
      }
      )
      .then(() => {
        this.pagamentoRealizado.emit({ pagamentoRealizado: true });
      })
  }
}
