import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { formLogAtendimento } from '../model';
import { SenhaAtendimentoService } from 'src/app/service/senha-atendimento.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { LogAtendimentoService } from 'src/app/service/log-atendimento.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CountupTimerService } from 'ngx-timer';
import { NgxSpinnerService } from 'ngx-spinner';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ToastrService } from 'ngx-toastr';
import { FormUsuario } from 'src/app/usuario/model';
import { ChekinPacienteForm } from '../chekin-paciente_old/model';
import { SessaoUser } from 'src/app/user.reg';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { TicketSenhaAtendimento } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
const sessao = new SessaoUser
@Component({
  selector: 'app-senha-atendimento',
  templateUrl: './senha-atendimento.component.html',
  styleUrls: ['./senha-atendimento.component.scss']
})
export class SenhaAtendimentoComponent implements OnInit {

  // @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild('ChamarPaciente', { static: true }) swal: SwalComponent
  @Input() OriginDrop: boolean
  @Input() form = new TicketSenhaAtendimento
  @Input() usuarioId: string
  @Output() event = new EventEmitter
  @Output() exit = new EventEmitter
  gerarSenha = false
  id
  companyId
  senha: any
  regCheckin: TicketSenhaAtendimento[];
  erro: boolean;
  erroMensagem: string;
  loading
  logAtendimento = new formLogAtendimento
  teste: any;
  _verificar: any;
  senhaGerada: boolean;
  cpf
  semRegistro: boolean;
  listMotivoConsulta: ConteudoDados[] = [];
  listPrioridade: ConteudoDados[] = [];
  listSituacao: ConteudoDados[] = [];
  registro: TicketSenhaAtendimento;

  constructor(
    private service: SenhaAtendimentoService,
    private serviceUsuario: UsuarioService,
    private serviceLog: LogAtendimentoService,
    private _conteudo: ConteudoDeDadosService,
    private _info: MatSnackBar,
    private url: ActivatedRoute,
    private time: CountupTimerService,
    private spinner: NgxSpinnerService,
    private nav: Router,
    private data: BuscarDataService,
    private mensagem: ToastrService
  ) {
    // this.urlID()
    this.time.startTimer()
    this.companyId = sessionStorage.getItem('companyId')
  }

  async ngOnInit() {
    this.getConteudo()
    this._verificar = setInterval(() => this.verificar(), 100)
    this._verificar
    this.buscarRegistro()
    this.cpf = sessionStorage.getItem('cpf')
    if (this.usuarioId) {
      this.id = this.usuarioId
    }
    this.spinner.show();
    this.erro = true


  }
  ngOnDestroy() {
    clearInterval(this._verificar)
  }
  async abrirRecepcao(senha: string, dados) {

    await localStorage.setItem('senha', senha)
    setTimeout(async () => {
      await this.nav.navigateByUrl(`home/recepcao/${this.id}`).then(
        () => this.registrarLogAtendimento()
      )
    }, 1000)
    this.removerSenha(dados.id, dados)

  }

  registrarLogAtendimento() {
    this.logAtendimento.usuarioId = this.id
    this.logAtendimento.companyId = this.companyId
    this.logAtendimento.data = this.data.hoje()
    this.logAtendimento.senha = this.senha
    this.logAtendimento.timeCheckin = this.data.horaAgora()
    this.serviceLog.cadastrarlogAtendimento(this.logAtendimento).toPromise()
      .then(
        () => { this.close() }
      )
  }
  removerSenha(id, dados) {
    this.service.alterarTicketSenhaAtendimento(id, dados).toPromise()
      .then(
        () => console.log('removido com sucesso!')
      ).catch(
        () => console.log('ERRO!!')
      )
  }
  verificar() {
    if (this.form.motivoConsulta == 'LIMOCO4' || this.form.motivoConsulta == 'LIMOCO5') {
      this.form.situacaoPaciente = 'LISSIT1'
      return
    } else if (this.form.prioridadeAtendimento == 'LISPRI1' || this.form.prioridadeAtendimento == 'LISPRI2' || this.form.prioridadeAtendimento == 'LISPRI3') {
      this.form.situacaoPaciente = 'LISSIT2'
    } else {
      this.form.situacaoPaciente = 'LISSIT3'
    }
  }

  urlID() {
    this.url.url.subscribe(async reg => {
      console.log('Filtro :' + reg[1].path)
      this.id = reg[1].path
    });
  }
  reset() {
    this.buscarRegistro()
    this.form = new TicketSenhaAtendimento
    this.gerarSenha = false
  }
  async buscarId() {
    if (this.companyId) return
    await this.serviceUsuario.listar().toPromise()
      .then(
        async (dados: Array<FormUsuario>) => {
          let id = this.id
          let filtro = await dados.filter(function (reg: FormUsuario) {
            return reg.usuarioId == id
          })
          let reg = filtro[0]
          this.companyId = reg.companyId
          this.erro = false
        }
      )
      .catch(
        () => {
          this.erro = true
          this.erroMensagem = "Não foi possível localizar registro"
        }
      )
  }
  async buscarRegistro() {
    let dados = await this.service.listarTicketSenhaAtendimento(sessao.codigoEmpresa)
    let filtro = await dados.filter(function (reg) {
      return reg.codigoEmpresa == sessao.codigoEmpresa && reg.status == 'SDO0012'
    })
    if (filtro.length < 1) {
      return
    }
    let orderna = await filtro.sort(function (a, b) {
      return parseInt(a.senha) - parseInt(b.senha)
    })
    this.regCheckin = orderna
    console.log('regCheckin', this.regCheckin)
    this.senha = this.regCheckin.slice().length
  }
  async cadastrar() {
    this.loading = true
    // if (this.regCheckin) {
    //   let i = this.regCheckin.length - 1
    //   this.form.senha = `${parseInt(this.regCheckin[i].senha ? this.regCheckin[i].senha : "0") + 1}`
    // }
    // else {
    //   this.form.senha = `1`
    // }
    this.form.codigoEmpresa = sessao.codigoEmpresa
    this.form.dataInclusaoDocumento = this.data.getDataInclusao()
    this.form.criadoPor = sessao.cpf
    this.form.status = 'SDO0012'
    this.form.tipoDoc = 'TICSEN'
    await this.service.cadastrarTicketSenhaAtendimento(this.form).toPromise()
      .then(
        async (reg) => {
          if (this.OriginDrop) {
            let key = reg[0].key
            this.registro = await this.service.getTicket(key)
          }
          this.gerarSenha = true
          this.close()
        }
      ).catch(
        (error) => {
          this.mensagem.error('Algo deu errado')
          console.log(error)
        }
      )
    this.loading = false
  }
  async getConteudo() {

    this.listMotivoConsulta = await this._conteudo.get('LIMOCO')
    this.listPrioridade = await this._conteudo.get('LISPRI')
    this.listSituacao = await this._conteudo.get('LISSIT')
  }
  info() {
    this._info.open('Salvo com sucesso', '', {
      duration: 2000
    })
  }
  close() {
    if (this.OriginDrop) {
      this.event.emit(this.registro)
      return
    }
    this.event.emit(false)
  }

}
