import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { ProtocoloClinico, ProtocoloClinicoService } from 'src/app/service/protocolo-clinico.service';

@Component({
  templateUrl: './protocolo-clinico.component.html',
  styleUrls: ['./protocolo-clinico.component.scss']
})
export class ProtocoloClinicoComponent implements OnInit {
  listDiagPrincipal: ConteudoDados[];
  displayedColumns = ['detalhe', 'diagnostico', 'tratamento']
  dataSource
  viewCadastro: boolean;
  registro = new ProtocoloClinico
  'SCL0001'
  alteracao: boolean;
  excluir: boolean;

  constructor(
    private _conteudo: ConteudoDeDadosService,
    private service: ProtocoloClinicoService,
    private _date : BuscarDataService,
    private _mensagem: MensagemService) { }

  ngOnInit() {
    this.getConteudo()
    this.getRegistro()
  }

  getRegistro(index?) {
    this.service.get().toPromise()
      .then(res => {
        let filter = res.filter(value => value.status == 'SCL0001')
        filter.forEach(async item => {
          let desc = await this._conteudo.get('DIAGMED', true, item.diagnostico)
          item.descDiagnostico = desc[0]
        });
        if (index) {
          let id = this.registro.id
          filter = res.filter(value => value.status == 'SCL0001' && value.id == id)
          this.registro = filter[0]
          return
        }
        this.dataSource = new MatTableDataSource(filter)
      })
  }
  async getConteudo() {
    this.listDiagPrincipal = await this._conteudo.get('DIAGMED')
  }
  openCadastro(item) {
    this.registro = item
    this.viewCadastro = true
    this.alteracao = true
  }
  inserir() {
    this.registro.status = "SCL0001"
    this.registro.timestamp = this._date.getTIMESTAMP()
    console.log(this.registro)
    this.service.cadastrar(this.registro).toPromise()
      .then(res => {
        this._mensagem.creat('Salvo com sucesso', { sucesso: true })
        this.viewCadastro = false
        this.registro = new ProtocoloClinico
        this.getRegistro()
      })
      .catch(error => {
        this._mensagem.creat('Falha ao gravar documento', { erro: true })
      })
  }
  alterar() {
    delete this.registro.descDiagnostico
    this.service.alterar(this.registro).toPromise()
      .then(res => {
        this._mensagem.creat('Alterado com sucesso ', { sucesso: true })
        this.getRegistro()
      })
      .catch(error => {
        console.log(error)
        this._mensagem.creat('Falha ao gravar documento', { erro: true })
      })
  }
  excluirRegistro() {
    this.registro.status = "SCL0002"
    delete this.registro.descDiagnostico
    this.service.alterar(this.registro).toPromise()
      .then(res => {
        this._mensagem.creat('Excluido com sucesso ', { sucesso: true })
        this.viewCadastro = false
        this.registro = new ProtocoloClinico
        this.getRegistro()
      })
      .catch(error => {
        this._mensagem.creat('Falha ao gravar documento', { erro: true })
      })
  }
  cancelarRegistro() {
    if (this.registro.id) {
      this.getRegistro(true)
    }
  }
  fecharCadastro() {
    this.registro = new ProtocoloClinico
    this.getRegistro()
    this.viewCadastro = false
  }
  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

}
