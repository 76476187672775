export class AtribuicaoDeAcessoCabecalho {
  id: string
  codigo: number
  codigoEmpresa: number
  criadoPor: string
  criadoEm: string
  status: string
  cpf: string
  nomeEmpresa?: string
  fotoPerfil?: string
  backgroud?: any
}

export class AtribuicaoDeAcessoItem {
  id: string
  criadoPor: string
  codigoEmpresa: number
  criadoEm: string
  codigoAtribuicaoAcesso: number
  nrItem: string
  tipoAcesso: string
  valorDeAcesso: string
  status: string
}

export class MenuMaster {
  menuID: string;
  menuName: string;
  parent_MenuID: string;
  menuFileName?: string;
  menuURL?: string;
  aplicacao: string;
  tipoObjeto: string;
  tipoAcao?: string;


}

export class ItemPerfil {
  codigoCaracteristica?: string;
  codigoReferencia: string;
  valor: string;

}
