export class DicionarioDeDadosModel{
    
        id?:  string;
        codigoTabela ?:  string;
        descricao?:  string;
        tipoDeTabela?: string;
        classificacaoDeTabela?:  string;
        status?:  string 
      
}
