import { Component, OnInit } from '@angular/core';
import { ConversasService } from 'src/app/service/conversas.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormConversaHistorico } from '../modal';

@Component({
  selector: 'app-historico-conversas',
  templateUrl: './historico-conversas.component.html',
  styleUrls: ['./historico-conversas.component.scss']
})
export class HistoricoConversasComponent implements OnInit {
  historico
  viewChat: boolean
  cpf = sessionStorage.getItem('cpf')
  conversas: any;
  nome_convidado: any;
  constructor(public _chat?: ConversasService) { }

  ngOnInit() {
    this.buscarHistorico()
  }
  buscarHistorico() {
    this._chat.getHistorico().toPromise()
      .then(
        (reg: Array<FormConversaHistorico>) => {
          let cpf = this.cpf
          let filtro = reg.filter(function (ref) {
            return ref.de == cpf && ref.ativo
          })
          let historico = []
          filtro.forEach(item => {
            var duplicidade = historico.findIndex(res => {
              return item.data == res.data
            }) > -1
            if (!duplicidade) {
              historico.push(item)
            }
          });
          this.historico = historico
        }
      )
  }
  abrirHistorico(ref) {
    this._chat.getHistorico_id(ref).subscribe(
      (reg) => {
        let ordenar = reg.sort(function (a, b) {
          let A = parseInt(a.hora.split(':').join(''))
          let B = parseInt(b.hora.split(':').join(''))
          return A - B
        })
        this.conversas = ordenar
        this.nome_convidado = this.conversas[0].nome_receptor
        this.viewChat = true
      }
    )
  }

}
