import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UploadArquivosService, UploadArquivo } from '../service/upload-arquivos.service';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap, finalize } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { formAnexoExame } from './modal';
import { BuscarDataService } from '../service/buscar-data.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Output('res') res = new EventEmitter
  @Output('close') close = new EventEmitter
  @Input('arquivo') file: any
  loading: boolean;
  sucess: boolean;
  confirmFile: boolean;
  fileToUpload: any;
  pdf: any
  name_arquivo: string
  form = new formAnexoExame
  image: any;
  new_file: any;
  porcentagem: Observable<number>
  arquivo_url: string
  task: AngularFireUploadTask;
  constructor(private uploadService: UploadArquivosService, private data: BuscarDataService, private toast: ToastrService, private storage: AngularFireStorage) { }

  ngOnInit() {
    if (this.file) {
      this.arquivo(this.file)
      console.log('ARQUIVO : ', this.file)
    }
  }
  arquivo(files) {

    if (files) {
      console.log(files)
      this.confirmFile = true
      const file = files[0]
      this.new_file = file
      this.name_arquivo = file.name
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (files[0].type == 'image/png' || files[0].type == 'image/jpeg') {
          this.image = reader.result
          this.pdf = null
          return
        }
        this.pdf = reader.result
      };
    }
  }
  async enviar() {
    this.loading = true
    const file = this.new_file
    const path = `arquivos/${file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, file);
    // let storage =  this.uploadService.uploadStorage(this.new_file)

    //  this.task = this.storage.upload(path, file);

    //  // Progress monitoring
    this.porcentagem = this.task.percentageChanges();

    console.log('porcentagem', this.porcentagem)
    this.task.snapshotChanges().toPromise().then(async () => {
      this.new_file.file = await ref.getDownloadURL().toPromise();
      this.form.cpf = sessionStorage.getItem('cpf')
      this.form.anexo = this.new_file.file
      this.form.titulo = this.name_arquivo
      this.form.usuarioId = sessionStorage.getItem('tokken')
      this.form.data = this.data.hojeBrasil()
      console.log(this.form)
      this.uploadService.upload(new FormData, new UploadArquivo).toPromise()
        .then(
          async (reg) => {
            if (reg) {

              console.log('FILEEE : ', this.new_file)
              this.res.emit(this.new_file)
              // this.toast.success('Arquivo enviado com sucesso')
            }
            else {
              this.toast.error('Algo deu errado')
            }
            this.loading = false
            this.pdf = null
            this.image = null
            this.name_arquivo = null
          }
        ).catch(() => { this.toast.error('ERRO!'); this.loading = false })
    })
    // .pipe(
    //   tap(console.log),
    //   // The file's download URL
    //   finalize(async () => {
    //     let downloadURL = await ref.getDownloadURL().toPromise();
    //     console.log('FIM : ',downloadURL)

    //     // this.db.collection('files').add({ downloadURL: this.downloadURL, path });
    //   }),
    // );
    return
    // var file = this.new_file.file
    var base64str = file.split(',')[1]
    var decoded = atob(base64str);
    console.log("FileSize: " + decoded.length);
    if (base64str.length > 1048487) {
      this.toast.error('Falha ao enviar arquivo ,Arquivo ultrapassou o tamanho permido')
      return
    }

  }

  abrirPDF() {
    const pdf = new Uint8Array(this.pdf)
    console.log(pdf)

  }
  downloadPDF() {
    const linkSource = this.pdf
    const downloadLink = document.createElement("a");
    const fileName = "sample.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  uploadFile(file) {
    this.loading = true
    const formData = new FormData();
    formData.append('file', file, file.name);
    file.inProgress = true;
    console.log(file)
    this.uploadService.upload(file, new UploadArquivo).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            console.log(file.progress)
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          console.log(event.body);
          if (event.body.success) {
            this.loading = false
            this.sucess = true
          }
          else {
            this.sucess = false
          }
        }
      });
  }
  teste() {
    this.res.emit(true)
  }
  exit() {
    this.name_arquivo = null
    this.close.emit(true)
  }

}
