import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

const url= `${new environment().api}ConsultarTabelas`;

@Injectable({
  providedIn: 'root'
})

export class TabelasDoSistemaService {


  constructor(
    private http: HttpClient,
    private mensagem: ToastrService,
  ) { }

  async listarTabelas():Promise<string[]>{
    let tabelas:string[]
    await this.http.get<string[]>(`${url}`, {observe:'body'}).toPromise()
    .then(res => tabelas = res)
    .catch((error:HttpErrorResponse) =>this.mensagem.error(`Erro ao listar tabelas: ${error.message}`,`Erro: ${error.status || 'Listar Tabelas'}`)
    )
    return tabelas;
  }

  async listarCamposTabela({nomeTabela}:{nomeTabela:string}):Promise<string[]>{
    let campos:string[]
    await this.http.get<string[]>(`${url}/ConsultarTabela/${nomeTabela}`, {observe:'body'}).toPromise()
    .then(res => campos = res)
    .catch((error:HttpErrorResponse) =>this.mensagem.error(`Erro ao listar campos de tabela: ${error.message}`,`Erro: ${error.status || 'Listar campos de tabela'}`)
    )
    return campos;
  }
}
