import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CadastroLocalArmazenamentoService {
    url = new environment().api
    apiCadastroLocalArmazenamento = "FichaGeralLocalDeArmazenamento/"

    constructor(private http: HttpClient) {

    }

    getLocalArmazenamentoCodigoEmpresa(codEmpresa: any): Observable<Object> {
        return this.http.get(this.url + this.apiCadastroLocalArmazenamento + "FiltrarCodigoEmpresa/" + codEmpresa)
    }

    postCadastroLocalArmazenamento(dados: any): Observable<Object> {
        return this.http.post(this.url + this.apiCadastroLocalArmazenamento, dados)
    }

    getCadastroLocalArmazenamento(): Observable<Object> {
        return this.http.get(this.url + this.apiCadastroLocalArmazenamento)
    }

    getCadastroLocalArmazenamentoID(id: any): Observable<Object> {
        return this.http.get(this.url + this.apiCadastroLocalArmazenamento + id)
    }

    PutCadastroLocalArmazenamento(id: any, userId: any, data: any, dados): Observable<Object> {
        return this.http.put(`${this.url}${this.apiCadastroLocalArmazenamento}${id}/${userId}/${data}`, dados)
    }

}


