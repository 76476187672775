import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../service/usuario.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormulariosService } from '../../service/formularios.service';
import { DatePipe } from '@angular/common';
import { FormUsuario } from '../../usuario/model';
import { enable, destroy } from 'splash-screen';
import { PacienteService } from 'src/app/service/paciente.service';
import { SalaEsperaService } from 'src/app/service/sala-espera.service';
import { SalaEsperaForm } from '../sala-espera/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FormPaciente } from 'src/app/cadastro-paciente/model';
import { AgendaService } from 'src/app/service/agenda.service';
import { AgendaForm } from 'src/app/agenda/agenda/model';
import { FotoPerfilService } from 'src/app/service/foto-perfil.service';
import { FormConexoesPaciente } from '../recepcao/model';

@Component({
  selector: 'app-recepcao-old',
  templateUrl: './recepcao.component_old.html',
  styleUrls: ['./recepcao.component_old.scss']
})
export class RecepcaoComponent_old implements OnInit {
  id
  companyId: string;
  senha
  pagamento
  tipoPesquisa = true
  mensagemErro = "PESQUISAR PACIENTE"
  abrirProntuario = false
  cpf: any = ''
  idade: any
  registroPaciente: FormPaciente | FormUsuario
  registro: FormUsuario | FormPaciente;
  paciente
  loading: boolean
  pesquisarForm: FormGroup;
  abrirCadatro: boolean;
  verificar: boolean;
  cobrar: boolean = false
  salaEspera = new SalaEsperaForm
  verificarConexao: string
  conexoes = new FormConexoesPaciente
  registroConexao: FormConexoesPaciente;
  consulta: AgendaForm;
  abrirListaHorarios: boolean
  consultaAll: AgendaForm[];
  loadingConsulta: boolean;
  CPFpaciente: string;
  loadingImage: boolean;
  fotoPerfil: string;
  refAgenda: AgendaForm;

  constructor(
    private url: ActivatedRoute,
    private service: UsuarioService,
    private serviceSalaEspera: SalaEsperaService,
    private servicePaciente: PacienteService,
    private serviceUsuario: UsuarioService,
    private serviceAgenda: AgendaService,
    private serviceData: BuscarDataService,
    private form: FormBuilder,
    private nav: Router,
    private data: BuscarDataService,
    private mensagem: ToastrService,
    private serviceFotoPerfil: FotoPerfilService
  ) {
    this.senha = localStorage.getItem('senha')
    this.companyId = sessionStorage.getItem('companyId')
    this.CPFpaciente = localStorage.getItem('CPFpaciente') // CPF Recebido da agenda
    const refAgenda = localStorage.getItem('refAgenda') // Dados do Paciente via Agenda
    this.refAgenda = JSON.parse(refAgenda)

  }

  async ngOnInit() {
    this.id = sessionStorage.getItem('tokken')
    await this.createForm()
    // this.loading = true
    await this.verificarCPFpaciente()
    // this.loading = false


  }

  verificarCPFpaciente() {
    if (this.CPFpaciente) {
      this.cpf = this.CPFpaciente
      this.pesquisarPaciente().finally(
        () => localStorage.removeItem('CPFpaciente')
      )
    }
  }
  pesquisarUsuario() {
    this.abrirCadatro = false
    this.loading = true
    if (this.tipoPesquisa) {
      let cpf = this.cpf
      this.service.listar().toPromise().then(
        (dados: FormUsuario[]) => {
          if (!dados) {
            this.loading = false
            this.mensagemErro = 'ERRO! Não obteve registro '
            console.log('ERRO :' + JSON.stringify(dados))
            console.log('Sem registro')
            return
          }
          let filtrar = dados.filter(function (reg: FormUsuario) {
            return reg.cpf == cpf
          })
          this.registro = filtrar[0]
          this.registroPaciente = this.registro
          if (!this.registroPaciente) {
            this.registroPaciente = {}
            this.registroPaciente = new FormPaciente
            this.registroPaciente.cpf = this.cpf
          }
          if (filtrar.length) {
            this.calcularIdade()
            this.loading = false
            this.verificar = true
            this.pesquisarConexao(this.registroPaciente.cpf)
            this.pesquisarConsultaMarcada()
            this.buscarFotoPerfil()
            // this.pesquisarPaciente()
            return
          }
          this.loading = false
          this.verificar = false
          this.mensagemErro = `CPF : "${cpf}" não encontrado`
        }
      )
    }
    else {
      let paciente = this.paciente
      this.service.listar().toPromise().then(
        (dados: FormUsuario[]) => {
          let filtrar = dados.filter(function (reg: FormUsuario) {
            return reg.nome.toUpperCase() == paciente.toUpperCase()
          })
          console.log(JSON.stringify(filtrar))
          this.registro = filtrar[0]
          if (filtrar.length) {
            this.calcularIdade()
            this.loading = false
            return
          }
          this.loading = false
          this.mensagemErro = `Paciente "${paciente}" não encontrado`


        }
      ).catch(
        () => { this.loading = false, this.mensagemErro = "Paciente não encontrado" }
      )
    }
  }
  async pesquisarPaciente() {
    this.fotoPerfil = null
    this.abrirCadatro = false
    this.loading = true

    this.abrirCadatro = true
  }
  pesquisarConexao(cpf) {
    this.cpf = cpf
    console.log(JSON.stringify(this.registroPaciente))
    this.servicePaciente.listarConexoesPaciente().toPromise()
      .then(
        (dados: Array<FormConexoesPaciente>) => {
          let cpf = this.cpf
          let companyId = this.companyId
          let filtro = dados.filter(function (reg) {
            return reg.cpf == cpf && reg.companyId == companyId && reg.ativo
          })
          if (filtro.length > 0) {
            this.verificarConexao = filtro[0].companyId
            this.registroConexao = filtro[0]
          }
          else {
            this.verificarConexao = '00'
          }
          console.log('TEM conexão ? ' + JSON.stringify(filtro))
        }
      )
  }
  pesquisarConsultaMarcada() {
    this.loadingConsulta = true
    this.serviceAgenda.listarAgenda().toPromise()
      .then(
        (dados: Array<AgendaForm>) => {

          let dataHoje = this.serviceData.hoje()
          let cpf = this.registro.cpf
          let filtro = dados.filter(function (reg) {
            if(!reg.date){return}
            return reg.date.substr(0, 10) >= dataHoje && reg.cpf == cpf
            // return reg.cpf == cpf
          })
          if (filtro.length > 1) {
            let ordernar = filtro.sort(function (a, b) {
              return parseInt(a.date.replace(/[^a-z0-9]/gi, '')) - parseInt(b.date.replace(/[^a-z0-9]/gi, ''))
            })
            this.consulta = ordernar[0]
            this.consultaAll = ordernar
          }
          else{
            this.consulta = filtro[0]
            this.consultaAll = []
          }

          this.loadingConsulta = false
          console.log('OLHAAAAAAAA - ' + JSON.stringify(this.consulta))
        }
      )
      .catch(
        () => {
          this.mensagemErro = 'Sem conexão com servidor '
        }
      )
  }
  conectarPaciente(reg) {
    this.conexoes.companyId = this.companyId
    this.conexoes.cpf = reg.cpf
    this.conexoes.usuarioId = reg.usuarioId
    this.conexoes.pacienteId = reg.id
    this.conexoes.ativo = true
    this.servicePaciente.cadastrarConexoesPaciente(this.conexoes).toPromise()
      .then(
        (dados: FormConexoesPaciente) => {
          this.mensagem.success('Paciente conectado com sucesso')
          this.verificarConexao = this.companyId
          this.registroConexao = dados
        }
      )
  }
  removerConexao() {
    this.registroConexao.ativo = false
    let id = this.registroConexao.id
    let registro = this.registroConexao
    this.servicePaciente.alterarConexoesPaciente(id, registro)
      .toPromise().then(
        () => this.verificarConexao = '00'
      )
  }
  createForm() {
    this.pesquisarForm = this.form.group({
      tipoPesquisa: this.tipoPesquisa,
      cpf: new FormControl('', Validators.required),
      nome: new FormControl('', [Validators.required, Validators.minLength(5)])
    })
  }
  calcularIdade() {
    this.idade = Math.floor(
      Math.ceil(
        Math.abs(
          new Date(this.registro.nascimento).getTime() - new Date().getTime())
        / (1000 * 3600 * 24)) / 365.25);
  }
  verificarCadastro(event) {
    console.log('Passou')
    this.registro = event
    this.calcularIdade()
    this.verificar = true
  }
  async abrirSalaDeEspera() {
    if (!this.companyId) {
      await this.mensagem.error('Não foi possivel realizar procedimento')
      await setTimeout(() => this.mensagem.info('Corrigindo....'), 1000)
      this.loading = true
      if (this.companyId) await this.mensagem.success('Correção realizada , tente novamente')
      this.loading = false
      console.log('Erro! companyID ' + this.companyId)
      return
    }

    let gerarSenha = Math.floor(Math.random() * 99)
    let pagamento = localStorage.getItem('formaPagamento')
    console.log(this.refAgenda)
    this.salaEspera.usuarioId = this.id
    this.salaEspera.pacienteId = this.registro.id
    this.salaEspera.companyId = this.companyId
    this.salaEspera.paciente = this.registro.nome
    this.salaEspera.senha = `P${gerarSenha}${this.senha ? this.senha : Math.floor(Math.random() * 99)}`
    this.salaEspera.processo = 1
    this.salaEspera.tipoConsulta = this.refAgenda.videoChamada ? 100 : 1
    this.salaEspera.idade = this.idade == 0 ? 20 : this.idade
    this.salaEspera.pagamento = pagamento ? pagamento : 'Indefinido'
    this.salaEspera.prioridade = 3
    this.salaEspera.hora = this.data.horaAgora()
    this.salaEspera.status = "em espera"
    this.salaEspera.tipo = 3
    this.salaEspera.cpf = this.registro.cpf

    this.serviceSalaEspera.cadastrarSalaEspera(this.salaEspera).toPromise()
      .then(
        () => {
          this.nav.navigateByUrl(`home/SalaEspera/${this.id}`)
        }
      )
  }
  buscarFotoPerfil() {
    let cpf = this.registro.cpf
    this.loadingImage = true

  }
}
