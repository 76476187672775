import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formMedicamento } from './model';
import { ToastrService } from 'ngx-toastr';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-pesquisar-medicamento',
  templateUrl: './pesquisar-medicamento.component.html',
  styleUrls: ['./pesquisar-medicamento.component.scss']
})
export class PesquisarMedicamentoComponent implements OnInit {
  options
  loading
  text = 'teste'
  constructor(
    private http: HttpClient,
    private mensagem: ToastrService,
    private _FileSaverService: FileSaverService) { }

  async ngOnInit() {

    await this.pesquisar()


  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  salvar() {
    const fileType = this._FileSaverService.genType('save.json');
    const txtBlob = new Blob([this.options], { type: fileType });
    this._FileSaverService.save(txtBlob, 'save.json')
  }
  pesquisar() {
    this.loading = true
    this.http.get('http://localhost:3000/medicamentos').toPromise()
      .then(
        async (dados: formMedicamento[]) => {
          this.options = dados
          this.mensagem.success('Carregado com sucesso')

          for (let i = 0; i <= dados.length; i++) {
            this.options[i].id = i
            console.log('ID : ' + dados[i].id)
          }
          this.loading = false
        }
      ).catch(
        () => console.log('ERRO')
      )
  }

}
