import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { dadosCadastroCentroCusto } from '../cadastro-centro-de-custo-completo/modelsCadastroCentroDeCustoCompleto';
import { CadastroFuncionarioModel } from '../cadastro-funcionario/cadastro-funcionario.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { MensagemService } from './mensagem.service';
import { TokenDeAcessoService } from './token-de-acesso.service';

const sessao = new SessaoUser
const url = `${new environment().api}CadastroFuncionario/`

@Injectable({
  providedIn: 'root'
})
export class CadastroFuncionarioService {
  url = new environment().api
  api = "CadastroFuncionario/"
  jsonPais = './assets/json/json-paises.json'
  jsonBancos = './assets/json/bancos-de-todo-brasil.json'
  apiCusto = "CadastroCentroCusto/"


  constructor(
    private http: HttpClient,
    private _mensagem: MensagemService,
    private _empresa: TokenDeAcessoService,
    private data: BuscarDataService
  ) { }


  getPaises(): Observable<any> {
    return this.http.get(this.jsonPais)
  }

  getBancosService(): Observable<any> {
    return this.http.get(this.jsonBancos)
  }

  listar() {
    return this.http.get<CadastroFuncionarioModel[]>(this.url + this.api)
  }

  enviar(registro: CadastroFuncionarioModel) {
    return this.http.post(url, registro)
  }

  alterar(registro: CadastroFuncionarioModel) {
    return this.http.put(url + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
  }

  async desativar(registro: CadastroFuncionarioModel, ativo) {
    if (ativo) registro.status = 'SCA0001'
    else registro.status = 'SCA0002'

    let resposta

    await this.alterar(registro).toPromise()
      .then(res => { resposta = res })
      .catch(error => console.log(error.error))

    return resposta
  }

  listarCentroCusto(codigoEmpresa) {
    return this.http.get<dadosCadastroCentroCusto[]>(this.url + this.apiCusto + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
  }


  //////////////////


  async get(codigoEmpresa) {
    let result: CadastroFuncionarioModel[] = []
    await this.http.get<CadastroFuncionarioModel[]>(this.url + this.api).toPromise()
      .then(res => {
        let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa && value.status == 'SDA0001')
        if (filter.length > 0) {
          filter.forEach(async value => {
            let empresa = await this._empresa.getEmpresasToken(value.codigoEmpresa)
            if (empresa.length > 0) {
              value.nomeEmpresa = empresa[0].nomeEmpresa
            }
          });
          result = filter
        }
      }).catch(error => console.log('get FichaContabilFornecedor', error))
    return result
  }
  async reset(id) {
    let result = new CadastroFuncionarioModel

    await this.http.get<CadastroFuncionarioModel[]>(this.url + this.api).toPromise()
      .then(res => {
        let filter = res.filter(value => value.nrRegistro == id)
        if (filter.length > 0) {
          result = filter[0]
        }
      }).catch(error => console.log('get FichaContabilFornecedor', error))

    return result
  }
  async getRegistroFuncionario(cpf) {
    let result
    await this.http.get(this.url + "CadastroPessoa/FiltrarCpf/" + cpf).toPromise()
      .then(res => {
        result = res
      }).catch(error => {
        console.log('getRegistroFuncionario ERRO ', error)
        result = null
        this._mensagem.creat('Não existe registro com esse CPF', { erro: true })
      })
    return result
  }
  async getCadastroFuncionario(cpf: string, codigoEmpresa: number) {
    console.log('cadastroFuncionado', cpf, codigoEmpresa)
    let result = new CadastroFuncionarioModel
    await this.http.get<CadastroFuncionarioModel[]>(this.url + "CadastroFuncionario/").toPromise()
      .then(res => {
        if (typeof res == 'object') {
          let get = res.filter(value => value.cpf == cpf && value.codigoEmpresa == codigoEmpresa && value.status == 'SCA0001')
          console.log('cadastroFuncionado filter', get)

          if (get.length > 0) {
            result = get[0]
          }
        }
      }).catch(error => {
        console.log('getRegistroFuncionario ERRO ', error)
        this._mensagem.creat('Não existe registro com esse CPF', { erro: true })
      })
    return result
  }

  async getCPFResponsavel(codigoEmpresa) {
    let result
    await this.http.get(this.apiCusto + `FiltrarCodigoEmpresa/${codigoEmpresa}`).toPromise()
      .then(res => {
        result = res
      }).catch(error => {
        result = null
        console.log('erro no cpf do responsavel', error)
      })
  }

  async cadastrar(dado: CadastroFuncionarioModel) {
    let result = false
    dado.status = 'SDA0001'
    await this.http.post(this.url + this.api, dado).toPromise()
      .then(res => {
        if (res) {
          result = true
        }
      }).catch(error => {
        console.log('erro', error)
        result = false
      })
    return result
  }
  // async alterar(dado: CadastroFuncionarioModel) {
  //   let result = false
  //   await this.http.put(this.url + this.api + dado.nrRegistro + `/${sessao.sessao_id}/${new Date().toISOString()}`, dado).toPromise()
  //     .then(res => {
  //       console.log(res)
  //       result = true
  //     })
  //     .catch(error => { console.log('ERRO delete', error) })
  //   return result
  // }
  async delete(dado: CadastroFuncionarioModel) {
    let result = false
    dado.status = 'SDA0002'
    await this.http.put(this.url + this.api + dado.nrRegistro + `/${sessao.sessao_id}/${new Date().toISOString()}`, dado).toPromise()
      .then(() => { result = true })
      .catch(error => { console.log('ERRO delete', error) })
    return result
  }
}
