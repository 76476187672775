import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CadastroDadosProfissionais } from '../admin-usuarios/model';
import { SessaoUser } from '../user.reg';
const sessao = new SessaoUser
@Injectable({
    providedIn: 'root'
})
export class CadastroDadosProfissionaisService {
    url = new environment().api
    cadPro = "CadastroDadosProfissionais/"
    FichaConexoes = "FichaConexoesProfissionais/ListarMedicos/"

    constructor(private http: HttpClient) {

    }
    async cadastrar(form: CadastroDadosProfissionais) {
        let result = {
            status: false,
            mensage: ''
        }
        form.criadoPor = sessao.cpf
        form.status = 'SDO0113'
        form.cpf = sessao.cpf

        if (!form.id) {
            await this.http.post(this.url + this.cadPro, form).toPromise()
                .then(res => {
                    result.status = true
                    console.log('insert', result)
                }).catch((error: HttpErrorResponse) => {
                    result.status = false
                    result.mensage = error.error[0]
                    console.log('error', error)
                })
            return result
        } else {
            await this.http.put(this.url + this.cadPro + `${form.id}/${sessao.sessao_id}/${new Date().toISOString()}`, form).toPromise()
                .then(res => {
                    result.status = true
                    console.log('insert', result)
                }).catch((error: HttpErrorResponse) => {
                    result.status = false
                    result.mensage = error.error[0]
                    console.log('error', error)
                })
            return result
        }

    }
    async delete(form: CadastroDadosProfissionais) {
        let result = {
            status: false,
            mensage: ''
        }
        form.status = "SDO0114"
        await this.http.put(this.url + this.cadPro + `${form.id}/${sessao.sessao_id}/${new Date().toISOString()}`, form).toPromise()
            .then(res => {
                result.status = true
                console.log('insert', result)
            }).catch((error: HttpErrorResponse) => {
                result.status = false
                result.mensage = error.error[0]
                console.log('error', error)
            })
        return result
    }

    getConexoes(codEmpresa): Observable<Object> {
        return this.http.get(this.url + this.FichaConexoes + codEmpresa)
    }

    async getCadastroDadosProfissionaisCpf(cpf: any) {
        let result: CadastroDadosProfissionais[] = []
        await this.http.get<any>(this.url + this.cadPro + "Join/" + cpf).toPromise()
            .then(res => {
                if (res.success) {
                    result = res.result.filter(value => value.status == "SDO0113")
                }
            })
        return result
    }

    getDadosProfissionaisID(id: any) {
        return this.http.get(this.url + this.cadPro + id)
    }

    PutDadosProfissionais(id: any, dados) {
        return this.http.put(this.url + this.cadPro + id, dados)
    }
}


