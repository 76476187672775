import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CadastroItem } from '../cadastro-item/campos-cadastroItem';
import { cadastroUsuario } from '../admin-usuarios/model';
import { BuscarDataService } from './buscar-data.service';
import { SessaoUser } from '../user.reg';
import { PlanoContaContabeisModel, SaldoContaContabil } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { RegistroService } from './registro.service';
import { FiltrarDadosService } from './filtrar-dados.service';
const sessao = new SessaoUser

const url = `${new environment().api}PlanoContaContabeis`

const user = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class CadastroItemService {

  url = new environment().api
  apiFichaContabi = "FichaContabil/"
  apiFichaResponsavel = "FichaResponsavelClinico/"
  apiAreaAtuacao = "AreaAtuacao/"
  apiCadastroItem = "CadastroItem/"
  api = "PlanoContaContabeis/"

  constructor(
    private http: HttpClient,
    private data: BuscarDataService,
    private _filter: FiltrarDadosService
  ) { }

  // getCadastroItem() {
  //   return this.http.get<CadastroItem[]>(this.url + this.apiCadastroItem)
  // }

  async getID(id) {
    let result
    await this.http.get(this.url + this.apiCadastroItem + id).toPromise()
      .then(res => result = res)
    return result
  }

  enviar(registro: CadastroItem) {
    registro.status = "SCA0001"
    return this.http.post(this.url + this.apiCadastroItem, registro)
  }

  putCadastroItem(registro: CadastroItem) {
    return this.http.put(this.url + this.apiCadastroItem + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
  }

  alterar(registro: CadastroItem) {
    return this.http.put(this.url + this.api + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
  }

  async deleteCadastroItem(registro: CadastroItem, ativo) {
    if (ativo) {
      registro.status = "SCA0001"
    }
    else {
      registro.status = "SCA0002"
    }
    let resposta
    await this.putCadastroItem(registro).toPromise()
      .then(res => { resposta = res })
      .catch(res => { console.log("erro ao desativar", res) })
    return resposta
  }
  async getCadastroItem(codigoEmpresa?: number) {
    let result = []
    if (codigoEmpresa > 0) {
      console.log('passou', codigoEmpresa)
      await this.http.get<Array<any>>(this.url + this.apiCadastroItem + `FiltrarCodigoEmpresa/${codigoEmpresa}/`).toPromise()
        .then(res => {
          try {
            if (res[0].cadastroItem.length > 0) {
              result = res[0].cadastroItem.filter(value=>value.status == 'SCA0001')
            }
          } catch {
            result = []
          }

        }).catch(error => console.log('erro getCadastroItem', error))
    } else {
      await this.http.get<Array<any>>(this.url + this.apiCadastroItem).toPromise()
        .then(res => {
          let filter = res.filter(v => v.status == 'SCA0001')
          let empresas = JSON.parse(sessao.listEmpresas)
          let registros = this._filter.filterTodos(filter, empresas)
          console.log('registros', registros)
          result = [{ cadastroItem: registros }]



        }).catch(error => console.log('erro getCadastroItem', error))
    }


    return result
  }

  listarContaContabil() {
    return this.http.get<PlanoContaContabeisModel[]>(this.url + this.api + 'FiltrarEmpresas/' + user.cpf)
  }

  // postFichaContabil(dados: Object) {
  //   return this.http.post(this.url + this.apiFichaContabi, dados)
  // }

  // postFichaResponsavel(dados: Object) {
  //   return this.http.post(this.url + this.apiFichaResponsavel, dados)
  // }

  // postAreaAtuacao(dados: Object) {
  //   return this.http.post(this.url + this.apiAreaAtuacao, dados)
  // }

  // getAreaAtuacao() {
  //   return this.http.get(this.url + this.apiAreaAtuacao)
  // }

  // postCadastroItem(dados: Object) {
  //   return this.http.post(this.url + this.apiCadastroItem, dados)
  // }

  // getCadastroItem(codigoEmpresa: number, classificacao: string) {
  //   return this.http.get(this.url + this.apiCadastroItem + "ConsultarCodigoEmpresa/" + `${codigoEmpresa}/` + classificacao)
  // }

  // getCadastroItemCodigoSku(codigoSku: number) {
  //   return this.http.get(this.url + this.apiCadastroItem + "FiltrarCodigoItemSKU/" + codigoSku)
  // }

  // putCadastroItem(id: any, userId: any, data?: any, dados?: any) {
  //   return this.http.put(`${this.url}${this.apiCadastroItem}${id}/${userId}/${data}`, dados)
  // }

  // getCadastroItemID(id: any) {
  //   return this.http.get(this.url + this.apiCadastroItem + id)
  // }

  // deleteCadastroItem(id: any) {
  //   return this.http.delete(id)
  // }

  getCadastroItemCodigoEmpresa(codigoEmpresa: any) {
    return this.http.get(this.url + this.apiCadastroItem + "FiltrarCodigoEmpresa/" + codigoEmpresa)
  }
}