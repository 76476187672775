import { Component, OnInit, Input } from '@angular/core';
import { CarteiraVacinacaoService } from 'src/app/service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FormCarteiraVacinacao } from '../model';
import { MensagemService } from 'src/app/service/mensagem.service';
import { MatTableDataSource } from '@angular/material';
import { SessaoUser } from 'src/app/user.reg';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
const sessao = new SessaoUser
@Component({
  selector: 'app-carteira-vacina',
  templateUrl: './carteira-vacina.component.html',
  styleUrls: ['./carteira-vacina.component.scss']
})
export class CarteiraVacinaComponent implements OnInit {
  @Input('noTitle') noTitle: boolean
  @Input('cpf') cpf: string
  displayedColumns = ['detalhe', 'codigoVacina', 'doseVacina', 'publicoAlvo', 'validadeVacina', 'fabricanteVacina', 'observacao']
  dataSource = new MatTableDataSource()
  formulario: FormGroup
  viewCadastro: boolean = false
  loading: boolean = false
  listVacina: ConteudoDados[] = [];
  listLocalVacina: ConteudoDados[] = [];
  listPeriodo: ConteudoDados[] = [];
  listPublicAlvo: ConteudoDados[] = [];
  constructor(
    private service: CarteiraVacinacaoService,
    private mensagem: MensagemService,
    private _conteudo: ConteudoDeDadosService,
    private form: FormBuilder
  ) {

  }

  async ngOnInit() {
    this.createFormulario()
    await this.getConteudo()
    this.buscarRegistro()
  }
  async getConteudo() {
    this.listVacina = await this._conteudo.get('LISVAC')
    this.listLocalVacina = await this._conteudo.get('LOCALVAC')
    this.listPeriodo = await this._conteudo.get('PERVAC')
    this.listPublicAlvo = await this._conteudo.get('PUBVAC')
  }

  async buscarRegistro() {
    this.loading = true
    await this.service.listarVacinacaos()
      .toPromise()
      .then(
        async (dados: Array<FormCarteiraVacinacao>) => {
          const cpf = this.cpf
          let filter = dados.filter(v => v.cpfPaciente == cpf && v.status == 'SDA0001')
          this.dataSource = new MatTableDataSource(filter)

        }
      ).catch(
        error => { } //this.mensagem.error('Não foi possivel encontrar registros do Ficha Clinica')
      )
    this.loading = false
  }
  createFormulario() {
    this.formulario = this.form.group({
      id: new FormControl(''),
      cpfPaciente: new FormControl(''),
      codigoVacina: new FormControl(''),
      dataAplicacao: new FormControl(''),
      fabricanteVacina: new FormControl(''),
      lote: new FormControl(''),
      validadeVacina: new FormControl(''),
      doseVacina: new FormControl('', Validators.required),
      proximaDose: new FormControl(''),
      publicoAlvo: new FormControl(''),
      dataProximaDose: new FormControl(''),
      tipoLocalVacina: new FormControl('', Validators.required),
      codigoEmpresa: new FormControl(''),
      CPFProfissionalAplicador: new FormControl(''),
      observacao: new FormControl(''),
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl(''),
    })
  }
  openNewCadastro() {
    this.viewCadastro = true
    this.formulario.reset()
  }
  openCadastro(register) {
    this.formulario.patchValue(register)
    this.formulario.disable()
    this.viewCadastro = true
  }
  async cancelarRegistro() {
    const register = this.formulario.getRawValue()
    await this.service.listarVacinacaos()
      .toPromise()
      .then(
        async (dados: Array<FormCarteiraVacinacao>) => {
          console.log(dados)
          let get = dados.filter(v => v.id == register.id)[0]
          if (get) {
            await this.formulario.patchValue(get)
            await this.formulario.disable()
          } else {
            this.mensagem.creat('Não foi possivel realizar procedimento', { erro: true })
          }

        }
      ).catch(
        error => { } //this.mensagem.error('Não foi possivel encontrar registros do Ficha Clinica')
      )
  }
  inserir() {
    let dados = this.formulario.getRawValue()
    dados.codigoEmpresa = sessao.codigoEmpresa
    dados.status = "SDA0001"
    dados.cpfPaciente = this.cpf
    dados.proximaDose = dados.proximaDose ? dados.proximaDose : '--'
    if (dados.id) {
      this.service.alterarVacinacaos(dados).toPromise().then(
        () => {
          this.mensagem.creat('Alterado com sucesso!', { sucesso: true })
        }
      ).catch(error => {
        console.log('erro!', error)
        this.mensagem.creat('Não foi possivel realizar alteração', { erro: true })
      })
    }
    else {
      delete dados.id
      dados.criadoPor = sessao.cpf
      dados.criadoEm = new Date().toISOString()
      this.service.cadastrarVacinacaos(dados).toPromise().then(
        () => {
          this.mensagem.creat('Cadastrado com sucesso!', { sucesso: true })
          this.viewCadastro = false
          this.buscarRegistro()
        }
      ).catch(error => {
        console.log('erro!', error)
        this.mensagem.creat('Erro ao realizar cadastro', { erro: true })
      })
    }

  }
  getVacinaCod(cod) {
    let get = this.listVacina.filter(v => v.codigoReferencia == cod)[0]
    if (get) {
      return get.valor
    } else {
      return cod
    }
  }
  getFaixaEtariaCod(cod) {
    let get = this.listPublicAlvo.filter(v => v.codigoReferencia == cod)[0]
    if (get) {
      return get.valor
    } else {
      return cod
    }
  }
  modificar() {
    this.formulario.enable()
  }
  _valid(nameControl: string) {
    try {
      return this.formulario.get(nameControl).invalid
    }
    catch {
      return false
    }
  }
  async excluirRegistro() {
    let dados = this.formulario.getRawValue()
    dados.status = "SDA0002"
    await this.service.alterarVacinacaos(dados).toPromise().then(
      () => {
        this.mensagem.creat('Registro deleteado com sucesso', { sucesso: true })
        this.viewCadastro = false
        this.buscarRegistro()
      }
    )
  }
}
