import { HttpClient, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Input } from '@angular/core';
import { Component, ElementRef, OnInit, Output, VERSION, ViewChild } from '@angular/core';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { NotaFiscalService } from 'src/app/service/emitir-recibo-nota-fiscal.service';
import { ParametrosDoSistema, ParametrosSistemaService } from 'src/app/service/parametros-sistema.service';
import { UploadArquivo, UploadArquivosService } from 'src/app/service/upload-arquivos.service';
import { SessaoUser } from 'src/app/user.reg';
import { FileCertificado } from './model';

const sessao = new SessaoUser
@Component({
  selector: 'app-certificado-nfse',
  templateUrl: './certificado-nfse.component.html',
  styleUrls: ['./certificado-nfse.component.scss']
})
export class CertificadoNfseComponent implements OnInit {
  @ViewChild('divID', { static: true }) divID: ElementRef;
  @ViewChild('Input', { static: true }) input: ElementRef;
  @Output() response = new EventEmitter
  @Output() path = new EventEmitter
  @Output() listRemove = new EventEmitter
  @Input() codigoEmpresa: number
  @Input() disabled: boolean


  name = "Angular " + VERSION.major;
  form = {
    id: "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAMMGzFy7JQ+JQFdUBcv06bnoAfLb/W2RI6ssFB5D8b7ml3MCNPIOaP2Q4nOjwZ+OkJV7LSqC2GPcOJwNK456Wz4uy5Se9DC9q0zyIta6Jb18YY5fv/rNSlpgRw1new6uniD8iEOjT5aGT/ddLiWbwikSggFmZgnilLkFZdeCpkvTAgMBAAECgYBVKL3J7CoVq5/9wgG5+pqog+d97ZOu6UaC8v9h69c+vZ/6J0eJPsOXnVFUn3fnvS/jJeMmAfaNPFc3xQR09XarYBjinNRS8kr2Hn6WMlPV9+aNPwgoCFHo4OVOkaNaDLPjk7wzgGeyeDc2wRgcHP8tUFBvlEeTHRWgD6KA+yaGaQJBAOoHNe/NiECRkkgrdkZE9N9B108iqY6IpHNqoCFIzglt4hKV6qAO8CmvGzKZfoOsLas/B/3bkAwB1TkTqyC/4u0CQQDVVjJjMYCcrgTUM6Wc5NnvYA94aIKYMAunZ5v9QzCIlJ+RIGFc0wn24eea7CGT/ZfcHO3TgO+u8AXFjY7+i1G/AkEAz46Nm5zh1sJExpUlVLUbqFYwqxLG5o7xqxtJ4bs8YMh/1JP5fvNAtPeEW6AewJEX/+TrJY5xdvS5MuHqyCTnEQJABlCq5SzN9dVg5zcwK9jKar+ZNaNCWmbxsV7ryy91/FUJ9C3VM/47SUeep4Nd6xeSqz1f/ObQMYmBYjuyg+5z2QJBAJ8XGCDeQWMh3tlk/eVV9SIEVb1WGPzBxDKhKGf4NImxKIxy4Dn0iv3SpeRunKK1xDTmRM686WG3g9MLe0mNLpc=",
    nome: "SISTEMA PADRAO INICIAL",
    retorno: "http://myclinics.com.br/dev"
  };
  erro
  fileList: FileCertificado[] = []
  listRegistro = []
  fileActive: any;
  porcentagem = 1
  uploadSuccess: boolean;

  constructor(
    private http: HttpClient,
    private service: UploadArquivosService,
    private _nfc: NotaFiscalService,
    private _conteudo: ConteudoDeDadosService,
    private parametro: ParametrosSistemaService) { }
  ngOnInit() {
    this.getParametro()
    this.getRegistro()
  }

  open() {
    this.input.nativeElement.click()
  }
  async getRegistro() {
    let result = await this.service.get(JSON.stringify(this.codigoEmpresa))
    this.listRegistro = result
  }
  getFile(file) {
    file[0].id = this.fileList.length + 1
    this.fileList.push({ id: '', success: false, message: '', progress: 0, file: file[0], password: '032921' })
    this.response.emit(this.fileList)

  }
  async getParametro() {
    let result: ParametrosDoSistema = await this.parametro.get('path_certificado_digital_nfse')
    this.path.emit(result.valorDoParametro)

  }


  async inserir(arquivo) {
    let doc = new UploadArquivo
    // doc.path = this.path
    doc.tipoAnexo = 'TIPANE12'
    doc.tipoDocumento = 'TIPANE12'
    doc.nrDocumento = sessao.cpf
    doc.criadoPor = sessao.cpf
    console.log(doc, this.fileList)
    for (let item of this.fileList) {
      let file = new FormData()
      if (item.password) {
        file.append('senha', item.password)
        file.append('arquivo', item.file)
        let result = await this._nfc.cadastrarCertificado(file)
        if (item.success) {
          item.success = result.success
          item.id = result.data.id
          item.message = result.message
          item.progress = 100
        } else {
          // item.
        }

      } else {
        item.success = false
        item.message = 'Informa a senha do certificado'
      }


    }

    return
    let getFile = arquivo[0]


    doc.nomeArquivo = getFile.name

    let file = new FormData()
    file.append('arquivo', getFile)
    // let result = this._nfc.cadastrarCertificado(file, '')
    this.uploadSuccess = false
    return
    this.uploadSuccess = false
    this.service.upload(file, doc).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.porcentagem = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          setTimeout(() => this.uploadSuccess = true, 2000)
        }
      }
    )
  }

  addChecked(checked, value) {
    console.log(checked, value)
    if (checked) {
      this.fileActive = value.file.id
    } else {
      this.fileActive = null
    }
    console.log(this.fileActive)
  }
  removeChecked() {
    let id = this.fileActive

    if (typeof id == 'string') {
      this.listRegistro.filter((value, index) => {
        if (value.id == id) {
          this.listRemove.emit(value)
          this.listRegistro.splice(index, 1)
        }
      })

    }

    this.fileList.filter((value, index) => {
      if (value.id == id) {
        this.fileList.splice(index, 1)
      }
    })

    this.fileActive = null
  }

  onSubimit() {
    let form = this.form;
    let params = new HttpParams()
    let id = encodeURIComponent(form.id)
    this.http
      .get(
        `https://autenticador.certisign.com.br/CertisignLogin/certificado/login?
id=${id}
&nome=${form.nome}
&retorno=${form.retorno}`
      )
      .toPromise()
      .then(res => console.log('sucesso', res))
      .catch(error => {
        console.log('erro', error)
        console.log('erro', error.error.text)
        var parser = new DOMParser();
        // this.erro = parser.parseFromString(error.error.text, 'text/html'); 
        this.divID.nativeElement.innerHTML = error.error.text
        let doc = document.getElementById('cb').hidden
        console.log('doc', doc)
      });
  }

}
