import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormUsuario, FormDependente, FormHabitos, FormHistoricoInternacoes, FormHistoricoDoencas } from '../usuario/model';
import { CadastroDadosProfissionais, CadastroPessoa, cadastroUsuario } from '../admin-usuarios/model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { CadastroPessoaForm, GetDependentes } from '../cadastro-paciente/model';
import { _MatTabGroupBase } from '@angular/material';
import { FichaGeralEmpresa } from './ficha-geral-empresa/FichaGeralEmpresa.model';
import { MensagemService } from './mensagem.service';
import { REQUEST } from './modal';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  url: string = new environment().api

  api: string = "Usuarios/"
  api_cad: string = 'CadastroUsuario/'
  api_pessoa = 'CadastroPessoa/'
  apiEmpresa = 'FichaGeralEmpresa/'
  constructor(private http: HttpClient, private _date: BuscarDataService, private mensagem: MensagemService) {

  }
  listar(id?: string) {
    if (id) {
      //console.log('Usuario :' + this.url + this.api);
      return this.http.get(this.url + this.api_cad + id)
    }
    else {
      //console.log('Usuario :' + this.url + this.api)
      return this.http.get(this.url + this.api_cad)
    }
  }
  // cadastrar(dados : FormUsuario){
  //   //console.log('Usuario-Cadastrar :' + this.url+this.api,dados)
  //   return this.http.post(this.url+this.api,dados)
  // }
  alterar(id: string, dados: any) {
    //console.log('Usuario-Alterar :' + this.url + this.api + id, dados)
    let date = this._date.horaAgora(true)
    let userid = sessao.sessao_id
    console.log(this.url + this.api_cad + `${id}/${date}/${userid}`, dados)
    return this.http.put(this.url + this.api_cad + `${id}/${id}/${date}`, dados)
  }

  async getRegistro(cpf) {
    let registro = new cadastroUsuario
    await this.http.get<any>(this.url + 'CadastroUsuario/(filtrarCpf)/' + cpf).toPromise()
      .then((res) => {
        if (res.success) {
          registro = res.result[0]
        }
      })
    return registro
  }


  async getRegistroPessoa(cpf) {
    let registro = new CadastroPessoa
    await this.http.get<CadastroPessoa>(this.url + 'CadastroPessoa/FiltrarCpf/' + cpf).toPromise()
      .then((res) => {
        registro = res
      }).catch(error => {
        return
        console.log('erro', error)
        this.mensagem.creat("O CPF informado é inválido. Por favor, insira um CPF cadastrado.", { erro: true })
      })
    return registro
  }

  async getRegistroProfissional(cpf, medico?: boolean) {
    let registro = new CadastroDadosProfissionais
    await this.http.get<any>(this.url + 'CadastroDadosProfissionais/FiltrarCpf/' + cpf).toPromise()
      .then((res) => {
        if (res.success) {
          res.result = res.result.filter(value => value.status == 'SDO0113')
          if (medico) {
            registro = res.result.filter(value => value.profissao == 'CADPRO1')[0]
          } else {
            registro = res.result[0]
          }
        }
      })
    return registro
  }


  get() {
    return this.http.get(this.url + this.api_cad)
  }
  async getID(id: string) {
    let result = new cadastroUsuario
    await this.http.get<any>(this.url + this.api_cad + id).toPromise()
      .then(res => {
        result = res
      }).catch(() => result = new cadastroUsuario)
    return result
  }
  async getIDCadPessoa(id: string) {
    let result = new CadastroPessoaForm
    await this.http.get<any>(this.url + this.api_pessoa + id).toPromise()
      .then(res => {
        result = res
      }).catch(() => result = new CadastroPessoaForm)
    return result
  }
  async getCadastroPessoa(cpf: string) {
    let result = new CadastroPessoaForm
    await this.http.get<any>(this.url + this.api_pessoa + 'JoinFiltrarCpf/' + cpf).toPromise()
      .then(res => {
        console.log('getCadastroPessoa',cpf, res)
        if (res.success) {
          if (res.result.length > 0) {
            result = res.result[0]
          }
        }
      }).catch(() => result = new CadastroPessoaForm)
    return result
  }
  async getDependentes(): Promise<GetDependentes[]> {
    return new Promise(async (resolve, reject) => {
      await this.http.get<REQUEST<GetDependentes>>(this.url + 'CadastroPessoa/BuscarDependentesCpf/' + sessao.cpf).toPromise()
        .then(res => {
          if (res.success) {
            resolve(res.result)
          } else {
            resolve(res.result)
          }
        }).catch(error => {
          console.log('ERRO CadastroPessoa/BuscarDependentesCpf ===>', error)
        })
    })
  }
  cadastrarPessoa(registro: CadastroPessoaForm) {
    console.log(this.url + this.api_pessoa + `${registro.id}/${new Date().toISOString()}/${sessao.sessao_id}`, JSON.stringify(registro))
    if (registro.id) {
      return this.http.put(this.url + this.api_pessoa + `${registro.id}/${new Date().toISOString()}/${sessao.sessao_id}`, registro)
    }
    return this.http.post(this.url + this.api_pessoa, registro)
  }
  cadastrarUsuario(registro: cadastroUsuario) {
    console.log(this.url + this.api_cad + `${registro}`, JSON.stringify(registro))
    if (registro.id) {
      return this.http.put(this.url + this.api_cad + `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)
    }
    registro.criadoPor = sessao.cpf
    return this.http.post(this.url + this.api_cad, registro)
  }

  GetRegistroPessoa(cpf) {
    // return this.http.get(this.url + this.api_pessoa + "ConsultaGeralCpf" + cpf);
    return this.http.get("https://www.myclinics.com.br/api/api/CadastroPessoa/ConsultaGeralCpf/" + cpf)
  }

  GetRegistroProfissional(cpf) {
    return this.http.get("https://www.myclinics.com.br/api/api/CadastroDadosProfissionais/Join/" + cpf)
  }
  GetConsultaGeral(cpf) {
    return this.http.get("https://www.myclinics.com.br/api/api/CadastroUsuario/ConsultaGeral/" + cpf)

  }


}

///////////USUARIO QUADRO DE SAUDE ///////////////////////////////////////////

export class UsuarioQuadroDeSaudeService {
  url: string = new environment().api
  url_local = new environment().api_local
  api_FichaPreNatal: string = "FichaPreNatal/"
  api_Deficiencia: string = "Deficiencia/"
  api_Alergia: string = "Alergia/"
  api_Habitos: string = "Habitos/"
  api_Medicacao: string = "Medicacao/"
  api_HistoricoDoencas: string = "HistoricoDoencas/"
  api_Tratamento: string = "Tratamentos/"
  api_ResultadoExame: string = "ResultadoExame/"
  api_AvaliacoesPeriodicas: string = "AvaliacoesPeriodicas/"
  api_HistoricoInternacoes: string = "HistoricoInternacoes/"
  api_SinaisVitais: string = "SinaisVitais/"
  api_DadosSaude: string = "DadosSaude/"
  api_DoencasFamilia: string = "DoencasFamilia/"
  api_Lesoes: string = "Lesoes/"
  api_Cirurgias: string = "Cirurgias/"

  constructor(private http: HttpClient) {

  }
  //************************** Deficiencia **********************************
  listarDeficiencia(id?) {
    //console.log('Deficiencia :' + this.url + this.api_Deficiencia)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Deficiencia + id)
    }
    else {
      //console.log('Listar Deficiencia sem id')
      return this.http.get(this.url + this.api_Deficiencia)
    }

  }
  cadastrarDeficiencia(dados: any) {
    //console.log('Deficiencia-Cadastrar :' + this.url + this.api_Deficiencia, dados)
    //console.log('Deficiencia-Cadastrar :' + JSON.stringify(dados))
    return this.http.post(this.url + this.api_Deficiencia, dados)
  }
  alterarDeficiencia(id: string, dados) {
    //console.log('Deficiencia-Alterar :' + this.url + this.api_Deficiencia + id, dados)
    return this.http.put(this.url + this.api_Deficiencia + id, dados)
  }
  excluirDeficiencia(id: string) {
    //console.log('Deficiencia-Excluir :' + this.url + this.api_Deficiencia + id)
    return this.http.delete(this.url + this.api_Deficiencia + id)
  }
  //**************************END Deficiencia **********************************


  //************************** Alergia **********************************
  listarAlergia(id?) {
    //console.log('Alergia :' + this.url + this.api_Alergia)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Alergia + id)
    }
    else {
      //console.log('Listar Alergia sem id')
      return this.http.get(this.url + this.api_Alergia)
    }

  }
  cadastrarAlergia(dados: any) {
    //console.log('Alergia-Cadastrar :' + this.url + this.api_Alergia, dados)
    return this.http.post(this.url + this.api_Alergia, dados)
  }
  alterarAlergia(id: string, dados) {
    //console.log('Alergia-Alterar :' + this.url + this.api_Alergia + id, dados)
    return this.http.put(this.url + this.api_Alergia + id, dados)
  }
  excluirAlergia(id: string) {
    //console.log('Alergia-Excluir :' + this.url + this.api_Alergia + id)
    return this.http.delete(this.url + this.api_Alergia + id)
  }
  //**************************END Alergia **********************************



  //************************** HABITO **********************************
  listarHabito(id?) {
    //console.log('Habito :' + this.url + this.api_Habitos)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Habitos + id)
    }
    else {
      //console.log('Listar Habito sem id')
      return this.http.get(this.url + this.api_Habitos)
    }

  }
  cadastrarHabito(dados: any) {
    //console.log('Habito-Cadastrar :' + this.url + this.api_Habitos, dados)
    return this.http.post(this.url + this.api_Habitos, dados)
  }
  alterarHabito(id: string, dados) {
    //console.log('Habito-Alterar :' + this.url + this.api_Habitos + id, dados)
    return this.http.put(this.url + this.api_Habitos + id, dados)
  }
  excluirHabito(id: string) {
    //console.log('Habito-Excluir :' + this.url + this.api_Habitos + id)
    return this.http.delete(this.url + this.api_Habitos + id)
  }
  //**************************END HABITO **********************************

  //************************** Medicação **********************************
  listarMedicacao(id?) {
    //console.log('Medicacao :' + this.url + this.api_Medicacao)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Medicacao + id)
    }
    else {
      //console.log('Listar Medicacao sem id')
      return this.http.get(this.url + this.api_Medicacao)
    }

  }
  cadastrarMedicacao(dados: any) {
    delete dados.id
    //console.log('Medicacao-Cadastrar :' + this.url + this.api_Medicacao, dados)
    return this.http.post(this.url + this.api_Medicacao, dados)
  }
  alterarMedicacao(id: string, dados) {

    //console.log('Medicacao-Alterar :' + this.url + this.api_Medicacao + id, dados)
    return this.http.put(this.url + this.api_Medicacao + id, dados)
  }
  excluirMedicacao(id: string) {
    //console.log('Medicacao-Excluir :' + this.url + this.api_Medicacao + id)
    return this.http.delete(this.url + this.api_Medicacao + id)
  }
  //**************************END Medicação **********************************

  //**************************Historico de doenca **********************************
  listarHistoricoDoencas(id?) {
    //console.log('HistoricoDoencas :' + this.url + this.api_HistoricoDoencas)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_HistoricoDoencas + id)
    }
    else {
      //console.log('Listar HistoricoDoencas sem id')
      return this.http.get(this.url + this.api_HistoricoDoencas)
    }

  }
  cadastrarHistoricoDoencas(dados: any) {
    //console.log('HistoricoDoencas-Cadastrar :' + this.url + this.api_HistoricoDoencas, dados)
    return this.http.post(this.url + this.api_HistoricoDoencas, dados)
  }
  alterarHistoricoDoencas(id: string, dados) {
    //console.log('HistoricoDoencas-Alterar :' + this.url + this.api_HistoricoDoencas + id, dados)
    return this.http.put(this.url + this.api_HistoricoDoencas + id, dados)
  }
  excluirHistoricoDoencas(id: string) {
    //console.log('HistoricoDoencas-Excluir :' + this.url + this.api_HistoricoDoencas + id)
    return this.http.delete(this.url + this.api_HistoricoDoencas + id)
  }
  //**************************END Historico de doenca **********************************

  //**************************Tratamento **********************************
  listarTratamento(id?) {
    //console.log('Tratamento :' + this.url + this.api_Tratamento)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Tratamento + id)
    }
    else {
      //console.log('Listar Tratamento sem id')
      return this.http.get(this.url + this.api_Tratamento)
    }

  }
  cadastrarTratamento(dados: any) {
    //console.log('Tratamento-Cadastrar :' + this.url + this.api_Tratamento, dados)
    return this.http.post(this.url + this.api_Tratamento, dados)
  }
  alterarTratamento(id: string, dados) {
    //console.log('Tratamento-Alterar :' + this.url + this.api_Tratamento + id, dados)
    return this.http.put(this.url + this.api_Tratamento + id, dados)
  }
  excluirTratamento(id: string) {
    //console.log('Tratamento-Excluir :' + this.url + this.api_Tratamento + id)
    return this.http.delete(this.url + this.api_Tratamento + id)
  }
  //**************************END Tratamento**********************************


  //**************************ResultadoExame **********************************
  listarResultadoExame(id?) {
    //console.log('ResultadoExame :' + this.url + this.api_ResultadoExame)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_ResultadoExame + id)
    }
    else {
      //console.log('Listar ResultadoExame sem id')
      return this.http.get(this.url + this.api_ResultadoExame)
    }

  }
  cadastrarResultadoExame(dados: any) {
    //console.log('ResultadoExame-Cadastrar :' + this.url + this.api_ResultadoExame, dados)
    return this.http.post(this.url + this.api_ResultadoExame, dados)
  }
  alterarResultadoExame(id: string, dados) {
    //console.log('ResultadoExame-Alterar :' + this.url + this.api_ResultadoExame + id, dados)
    return this.http.put(this.url + this.api_ResultadoExame + id, dados)
  }
  excluirResultadoExame(id: string) {
    //console.log('ResultadoExame-Excluir :' + this.url + this.api_ResultadoExame + id)
    return this.http.delete(this.url + this.api_ResultadoExame + id)
  }
  //**************************END ResultadoExame**********************************

  //**************************AvaliacoesPeriodicas**********************************
  listarAvaliacoesPeriodicas(id?) {
    //console.log('AvaliacoesPeriodicas :' + this.url + this.api_AvaliacoesPeriodicas)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_AvaliacoesPeriodicas + id)
    }
    else {
      //console.log('Listar AvaliacoesPeriodicas sem id')
      return this.http.get(this.url + this.api_AvaliacoesPeriodicas)
    }

  }
  cadastrarAvaliacoesPeriodicas(dados: any) {
    //console.log('AvaliacoesPeriodicas-Cadastrar :' + this.url + this.api_AvaliacoesPeriodicas, dados)
    return this.http.post(this.url + this.api_AvaliacoesPeriodicas, dados)
  }
  alterarAvaliacoesPeriodicas(id: string, dados) {
    //console.log('AvaliacoesPeriodicas-Alterar :' + this.url + this.api_AvaliacoesPeriodicas + id, dados)
    return this.http.put(this.url + this.api_AvaliacoesPeriodicas + id, dados)
  }
  excluirAvaliacoesPeriodicas(id: string) {
    //console.log('AvaliacoesPeriodicas-Excluir :' + this.url + this.api_AvaliacoesPeriodicas + id)
    return this.http.delete(this.url + this.api_AvaliacoesPeriodicas + id)
  }
  //**************************END AvaliacoesPeriodicas**********************************



  //**************************HistoricoInternacoes**********************************
  listarHistoricoInternacoes(id?) {
    //console.log('HistoricoInternacoes :' + this.url + this.api_HistoricoInternacoes)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_HistoricoInternacoes + id)
    }
    else {
      //console.log('Listar HistoricoInternacoes sem id')
      return this.http.get(this.url + this.api_HistoricoInternacoes)
    }

  }
  cadastrarHistoricoInternacoes(dados: any) {
    //console.log('HistoricoInternacoes-Cadastrar :' + this.url + this.api_HistoricoInternacoes, dados)
    return this.http.post(this.url + this.api_HistoricoInternacoes, dados)
  }
  alterarHistoricoInternacoes(id: string, dados) {
    //console.log('HistoricoInternacoes-Alterar :' + this.url + this.api_HistoricoInternacoes + id, dados)
    return this.http.put(this.url + this.api_HistoricoInternacoes + id, dados)
  }
  excluirHistoricoInternacoes(id: string) {
    //console.log('HistoricoInternacoes-Excluir :' + this.url + this.api_HistoricoInternacoes + id)
    return this.http.delete(this.url + this.api_HistoricoInternacoes + id)
  }
  //**************************END HistoricoInternacoes********************************** 

  //************************** SinaisVitais ********************************** 
  listarSinaisVitais(id?) {
    //console.log('SinaisVitais :' + this.url + this.api_SinaisVitais)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_SinaisVitais + id)
    }
    else {
      //console.log('Listar SinaisVitais sem id')
      return this.http.get(this.url + this.api_SinaisVitais)
    }

  }
  cadastrarSinaisVitais(dados: any) {
    if (delete dados['id']) {
      //console.log(delete dados['id'])
      //console.log('SinaisVitais-Cadastrar :' + this.url + this.api_SinaisVitais, dados)
      return this.http.post(this.url + this.api_SinaisVitais, dados)
    }

  }
  alterarSinaisVitais(id: string, dados) {
    //console.log('SinaisVitais-Alterar :' + this.url + this.api_SinaisVitais + id, dados)
    return this.http.put(this.url + this.api_SinaisVitais + id, dados)
  }
  excluirSinaisVitais(id: string) {
    //console.log('SinaisVitais-Excluir :' + this.url + this.api_SinaisVitais + id)
    return this.http.delete(this.url + this.api_SinaisVitais + id)
  }
  //**************************END SinaisVitais **********************************

  //************************** DadosSaude ********************************** 
  listarDadosSaude(id?) {
    //console.log('DadosSaude :' + this.url + this.api_DadosSaude)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_DadosSaude + id)
    }
    else {
      //console.log('Listar DadosSaude sem id')
      return this.http.get(this.url + this.api_DadosSaude)
    }

  }
  cadastrarDadosSaude(dados: any) {

    //console.log(dados)
    //console.log('DadosSaude-Cadastrar :' + this.url + this.api_DadosSaude, dados)
    return this.http.post(this.url + this.api_DadosSaude, dados)


  }
  alterarDadosSaude(id: string, dados) {
    //console.log('DadosSaude-Alterar :' + this.url + this.api_DadosSaude + id, dados)
    return this.http.put(this.url + this.api_DadosSaude + id, dados)
  }
  excluirDadosSaude(id: string) {
    //console.log('DadosSaude-Excluir :' + this.url + this.api_DadosSaude + id)
    return this.http.delete(this.url + this.api_DadosSaude + id)
  }
  //**************************END DadosSaude **********************************

  //************************** DoencasFamilia ********************************** 
  listarDoencasFamilia(id?) {
    //console.log('DoencasFamilia :' + this.url + this.api_DoencasFamilia)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_DoencasFamilia + id)
    }
    else {
      //console.log('Listar DoencasFamilia sem id')
      return this.http.get(this.url + this.api_DoencasFamilia)
    }

  }
  cadastrarDoencasFamilia(dados: any) {
    //console.log('DoencasFamilia-Cadastrar :' + this.url + this.api_DoencasFamilia, dados)
    return this.http.post(this.url + this.api_DoencasFamilia, dados)
  }
  alterarDoencasFamilia(id: string, dados) {
    //console.log('DoencasFamilia-Alterar :' + this.url + this.api_DoencasFamilia + id, dados)
    return this.http.put(this.url + this.api_DoencasFamilia + id, dados)
  }
  excluirDoencasFamilia(id: string) {
    //console.log('DoencasFamilia-Excluir :' + this.url + this.api_DoencasFamilia + id)
    return this.http.delete(this.url + this.api_DoencasFamilia + id)
  }
  //**************************END DoencasFamilia **********************************

  //************************** Lesoes ********************************** 
  listarLesoes(id?) {
    //console.log('Lesoes :' + this.url + this.api_Lesoes)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Lesoes + id)
    }
    else {
      //console.log('Listar Lesoes sem id')
      return this.http.get(this.url + this.api_Lesoes)
    }

  }
  cadastrarLesoes(dados: any) {
    //console.log('Lesoes-Cadastrar :' + this.url + this.api_Lesoes, dados)
    return this.http.post(this.url + this.api_Lesoes, dados)
  }
  alterarLesoes(id: string, dados) {
    //console.log('Lesoes-Alterar :' + this.url + this.api_Lesoes + id, dados)
    return this.http.put(this.url + this.api_Lesoes + id, dados)
  }
  excluirLesoes(id: string) {
    //console.log('Lesoes-Excluir :' + this.url + this.api_Lesoes + id)
    return this.http.delete(this.url + this.api_Lesoes + id)
  }
  //**************************END Lesoes **********************************

  //************************** Cirurgias ********************************** 
  listarCirurgias(id?) {
    //console.log('Cirurgias :' + this.url + this.api_Cirurgias)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_Cirurgias + id)
    }
    else {
      //console.log('Listar Cirurgias sem id')
      return this.http.get(this.url + this.api_Cirurgias)
    }

  }
  cadastrarCirurgias(dados: any) {
    //console.log('Cirurgias-Cadastrar :' + this.url + this.api_Cirurgias, dados)
    return this.http.post(this.url + this.api_Cirurgias, dados)
  }
  alterarCirurgias(id: string, dados) {
    //console.log('Cirurgias-Alterar :' + this.url + this.api_Cirurgias + id, dados)
    return this.http.put(this.url + this.api_Cirurgias + id, dados)
  }
  excluirCirurgias(id: string) {
    //console.log('Cirurgias-Excluir :' + this.url + this.api_Cirurgias + id)
    return this.http.delete(this.url + this.api_Cirurgias + id)
  }
  //**************************END Cirurgias **********************************


  //**************************FichaPreNata********************************** 
  listarFichaPreNatal(id?) {
    //console.log('FichaPreNatal :' + this.url + this.api_FichaPreNatal)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_FichaPreNatal + id)
    }
    else {
      //console.log('Listar FichaPreNatal sem id')
      return this.http.get(this.url + this.api_FichaPreNatal)
    }

  }

  cadastrarFichaPreNatal(dados: FormDependente) {
    //console.log('FichaPreNatal-Cadastrar :' + this.url + this.api_FichaPreNatal, dados)
    return this.http.post(this.url + this.api_FichaPreNatal, dados)
  }
  alterarFichaPreNatal(id: string, dados) {
    //console.log('FichaPreNatal-Alterar :' + this.url + this.api_FichaPreNatal + id, dados)
    return this.http.put(this.url + this.api_FichaPreNatal + id, dados)
  }
  excluirFichaPreNatal(id: string) {
    //console.log('FichaPreNatal-Excluir :' + this.url + this.api_FichaPreNatal + id)
    return this.http.delete(this.url + this.api_FichaPreNatal + id)
  }
  //**************************END FichaPreNata********************************** 
}

///////////Dependente///////////////////////////////////////////
export class UsuarioDependenteService {
  url: string = new environment().api
  api: string = "Pacientes/"
  constructor(private http: HttpClient) {

  }
  listarDependente(id?) {
    //console.log('Dependente :' + this.url + this.api)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api + id)
    }
    else {
      //console.log('Listar Dependente sem id')
      return this.http.get(this.url + this.api)
    }

  }
  cadastrarDependente(dados: FormDependente) {
    //console.log('Dependente-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarDependente(id: string, dados) {
    //console.log('Dependente-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirDependente(id: string) {
    //console.log('Dependente-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
  }
}

/////////////Pré Natal/////////////////////////////////////////////////
export class UsuarioPreNatalService {
  url: string = new environment().api
  api_fichaClinica: string = "FichaClinica/"
  api_preNatal: string = "FichaPreNatal/"
  api_ultrassom: string = "Ultrassom/"

  constructor(private http: HttpClient) { }

  //************************** FichaClinica **********************************
  listarFichaClinica() {
    //console.log('FichaClinica :' + this.url + this.api_fichaClinica)
    return this.http.get(this.url + this.api_fichaClinica)
  }
  cadastrarFichaClinica(dados: any) {
    //console.log('FichaClinica-Cadastrar :' + this.url + this.api_fichaClinica, dados)
    return this.http.post(this.url + this.api_fichaClinica, dados)
  }
  alterarFichaClinica(id: string, dados) {
    //console.log('FichaClinica-Alterar :' + this.url + this.api_fichaClinica + id, dados)
    return this.http.put(this.url + this.api_fichaClinica + id, dados)
  }
  excluirFichaClinica(id: string) {
    //console.log('FichaClinica-Excluir :' + this.url + this.api_fichaClinica + id)
    return this.http.delete(this.url + this.api_fichaClinica + id)
  }
  //**************************END FichaClinica **********************************

  //************************** FichaPreNatal **********************************
  listarFichaPreNatal(id?) {
    //console.log('FichaPreNatal :' + this.url + this.api_preNatal)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_preNatal + id)
    }
    else {
      //console.log('Listar FichaPreNatal sem id')
      return this.http.get(this.url + this.api_preNatal)
    }

  }
  cadastrarFichaPreNatal(dados: any) {
    //console.log('FichaPreNatal-Cadastrar :' + this.url + this.api_preNatal, dados)
    return this.http.post(this.url + this.api_preNatal, dados)
  }
  alterarFichaPreNatal(id: string, dados) {
    //console.log('FichaPreNatal-Alterar :' + this.url + this.api_preNatal + id, dados)
    return this.http.put(this.url + this.api_preNatal + id, dados)
  }
  excluirFichaPreNatal(id: string, dados: any) {
    //console.log('FichaPreNatal-Excluir :' + this.url + this.api_preNatal + id, dados)
    return this.http.put(this.url + this.api_preNatal + id, dados)
  }
  //**************************END FichaPreNatal **********************************

  //************************** Ultrassom **********************************
  listarUltrassom(id?) {
    //console.log('Ultrassom :' + this.url + this.api_ultrassom)
    if (id) {
      //console.log('ID true')
      return this.http.get(this.url + this.api_ultrassom + id)
    }
    else {
      //console.log('Listar Ultrassom sem id')
      return this.http.get(this.url + this.api_ultrassom)
    }

  }
  cadastrarUltrassom(dados: any) {
    //console.log('Ultrassom-Cadastrar :' + this.url + this.api_ultrassom, dados)
    return this.http.post(this.url + this.api_ultrassom, dados)
  }
  alterarUltrassom(id: string, dados) {
    //console.log('Ultrassom-Alterar :' + this.url + this.api_ultrassom + id, dados)
    return this.http.put(this.url + this.api_ultrassom + id, dados)
  }
  excluirUltrassom(id: string, dados: any) {
    //console.log('Ultrassom-Excluir :' + this.url + this.api_ultrassom + id, dados)
    return this.http.put(this.url + this.api_ultrassom + id, dados)
  }
  //**************************END Ultrassom **********************************
}


export class CarteiraVacinacaoService {
  url: string = new environment().api
  api: string = "CarteiraVacinacao/"
  constructor(private http: HttpClient) { }

  //************************** Vacinacaos **********************************
  listarVacinacaos() {
    //console.log('Vacinacaos :' + this.url + this.api)
    return this.http.get(this.url + this.api)
  }
  cadastrarVacinacaos(dados: any) {
    console.log('Vacinacaos-Cadastrar :' + this.url + this.api, JSON.stringify(dados))
    return this.http.post(this.url + this.api, dados)
  }
  alterarVacinacaos(dados) {
    //console.log('Vacinacaos-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + `${dados.id}/${sessao.sessao_id}/${new Date().toISOString()}`, dados)
  }
  excluirVacinacaos(dados) {
    //console.log('Vacinacaos-Excluir :' + this.url + this.api + id)
    return this.http.put(this.url + this.api + `${dados.id}/${sessao.sessao_id}/${new Date().toISOString()}`, dados)
  }
  //**************************END Vacinacaos **********************************
}

