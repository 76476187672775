export class ClassificacaoDeReceituarioModal{
    id: string;
    criadoPor: string;
    tipoDeNotificacao: string;
    lista: string;
    cor: string;
    abrangencia: string;
    qtdPorPeriodo: string;
    qtdPorReceita: string;
    validade: string;
    talaoDeNotificacao: string;
    medicamentos: string;
    criadoEm: string;
    status: string
}