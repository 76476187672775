import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var google;
@Injectable({
  providedIn: 'root'
})
export class CoordenadaService {

  constructor(private http: HttpClient) { }
  async get() {
    let coordenada
    coordenada = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(res => {
        resolve(`${JSON.stringify(res.coords.latitude)},${JSON.stringify(res.coords.longitude)}`)
        reject(null)
      })
    })
    return coordenada
  }
  async getEndereco(endereco, coordenada?) {
    let result
    await this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${endereco}&key=AIzaSyCiHsNUWnXD6eDukfF4VIRBHWWsm76I4BQ`).toPromise()
      .then(res => {
        if (res.status == 'OK') {
          if (coordenada) {
            let coord = res.results[0].geometry.location
            result = `${coord.lat},${coord.lng}`
          } else {
            result = res.results[0].formatted_address

          }
        }
      })
    return result
  }

}