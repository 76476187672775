import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { AtendimentoClinicoService } from 'src/app/service/atendimento-clinico.service';
import { formParecerMedico } from './model';
import { ThrowStmt } from '@angular/compiler';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { formURL } from '../model';

@Component({
  selector: 'app-parecer-medico',
  templateUrl: './parecer-medico.component.html',
  styleUrls: ['./parecer-medico.component.scss']
})
export class ParecerMedicoComponent implements OnInit {
  @ViewChild('body', { static: true }) body: ElementRef
  @Input('index') index
  @Input('id') id : formURL
  abrirHistoricoHipotese
  abrirHistoricoDiagnostico

  registro = new formParecerMedico
  formParcial = new formParecerMedico

  historico : formParecerMedico[]
  //Historico
  //Views de Historico
  vh
  dataHoje: any;
  verificarIndex

  constructor(
    private service: AtendimentoClinicoService,
    private data : BuscarDataService) {
    this.dataHoje = this.data.hoje()
    this.verificarIndex =  setInterval(() => this.sessao(), 1000)
  }
  
  async ngOnInit() {
    await this.buscarHistorico()
    await this.buscarFormLog()
    this.registro.companyId =  this.id.companyId
    this.registro.usuarioId = this.id.usuarioId
    this.registro.pacienteId =  this.id.pacienteId
  }
  
  async salvar(ref) {
    switch (ref) {
      case 'hipoteseDiagnostica':
        this.formParcial.hipoteseDiagnostica = this.registro.hipoteseDiagnostica
        localStorage.setItem('regPM', JSON.stringify(this.formParcial))
        break
      case 'diagnostico':
        this.formParcial.diagnostico = this.registro.diagnostico
        localStorage.setItem('regPM', JSON.stringify(this.formParcial))
        break
      case 'prognostico':
        this.formParcial.prognostico = this.registro.prognostico
        localStorage.setItem('regPM', JSON.stringify(this.formParcial))
        break
      case 'sequelas':
        this.formParcial.sequelas = this.registro.sequelas
        localStorage.setItem('regPM', JSON.stringify(this.formParcial))
        break
      case 'causasMortais':
        this.formParcial.causasMortais = this.registro.causasMortais
        localStorage.setItem('regPM', JSON.stringify(this.formParcial))
        break
    }
  }
  sessao() {
    let indexRef = localStorage.getItem('indexRefPM')
    localStorage.setItem('diagnostico',this.registro.diagnostico)
    if (this.index == 3) {
      if (indexRef != "true" || this.id.pacienteId == '99'){
        this.cadastrar(),
        console.log('Cadastrou 2 !' + 'indexRef: '+indexRef + ' index : '+this.index) 
        clearInterval(this.verificarIndex)
      }
      return
    }
  }
  buscarHistorico() {
    this.service.listarParecerMedico().toPromise()
      .then(
        (dados: formParecerMedico[]) => {
          let usuarioId = this.id.usuarioId
          let companyId = this.id.companyId
          let pacienteId = this.id.pacienteId
          let filtro = dados.filter(function (reg: formParecerMedico) {
            return reg.usuarioId == usuarioId && reg.companyId == companyId && reg.pacienteId == pacienteId
          })
          let ordenar = filtro.sort(function (a, b) {
            a.data.replace('/', ''); b.data.replace('/', '')
            return parseInt(b.data) - parseInt(a.data)
          })
          this.historico = ordenar
        }
      )
  }
  buscarFormLog() {
    if (JSON.parse(localStorage.getItem('regPM'))) {
      this.registro = JSON.parse(localStorage.getItem('regPM'))
    }
  }
  descerScroll() {
    setTimeout(() => this.body.nativeElement.scrollTop = 200, 400)
  }
  cadastrar() {
    this.registro.data = this.dataHoje
    this.service.cadastrarParecerMedico(this.registro).toPromise()
      .then(
        () => {localStorage.setItem('indexRefPM', 'true'), this.id.pacienteId = '00'}
      )
  }

}
