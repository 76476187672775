import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormRegistrarUsuario } from '../portal/inscricao-paciente/modal';
import { SessaoUser } from '../user.reg';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class RegistroService {
  url = new environment().api
  api = "Auth/Nova-Conta"
  api_usuario = "CadastroUsuario/"
  api_pessoas = "CadastroPessoa/";
  api_enviarEmail = "EnviaEmail"
  api_paciente = "Pacientes/"
  token: any;

  constructor(private http: HttpClient) {

  }

  async registrar(dados: FormRegistrarUsuario) {
    let result = {
      status: false,
      message: ''
    }
    let dados_novaConta = {
      usuario: dados.cpf,
      email: dados.email,
      password: dados.password,
      confirmPassword: dados.confirmPassword
    }
    let dados_usuario = {
      nomeCompleto: dados.usuario,
      nomeSocial: dados.nomeSocial ? dados.nomeSocial : '--',
      email: dados.email,
      cpf: dados.cpf,
      dataNascimento: dados.nascimento,
      termosDeAceite: true,
      tipoUsuario: 'TIPUSU5',
      status: 'SDO0066',
      criadoPor: dados.cpf
    }
    // let dados_pessoas = {
    //   nome: dados.usuario,
    //   email: dados.email,
    //   rg: "00000000000",
    //   cpf: dados.cpf,
    //   dataNascimento: dados.nascimento,
    //   cep: '--',
    //   Bairro: '--',
    //   Cidade: '--',
    //   Estado: '--',
    //   Numero: '--',
    //   Status: 'SCA0001',
    //   Endereco: '--',
    //   Telefone: '--',
    //   TipoPessoa: 'TIPPES2',
    //   ativo: true
    // }


    //Registro na tabela Nova conta
    await this.http.post(this.url + this.api, dados_novaConta).toPromise().then(

      async ok => {
        ///Registrando na tabela Pessoas
        //Registrando na tabela dados_usuario
        await this.http.post(this.url + this.api_usuario, dados_usuario).toPromise().then(
          async () => {
            //Verificar se ja possui registor na tabela paciente
            result.status = true
            result.message = 'Cadastro realizado com sucesso'
          },
        ).catch((error: HttpErrorResponse) => {
          result.status = false
          result.message = error.error.errors[0]
            console.log('erro cad', error)
        })
        console.log('Usuario registrado com sucesso!')
      },
    ).catch(
      (error) => {
        result.status = false
        console.log('error nova-conta', error)
      }
    )

    return result
  }
}
