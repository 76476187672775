import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { timezone } from './timezone.model';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

const url = `${new environment().api}Timezones`;

@Injectable({
  providedIn: 'root'
})

export class TimezonesService {

  constructor(
    private http: HttpClient,
    private mensagem: ToastrService,
  ) { }

  async listarTimezones(): Promise<timezone[]> {
    let timezones: timezone[];
    await this.http.get<timezone[]>(url, { observe: 'body' }).toPromise()
      .then(res => {
        timezones = res.sort(function (a, b) {
          return a.displayName.split(' ')[1] < b.displayName.split(' ')[1] ? -1 : a.displayName.split(' ')[1] > b.displayName.split(' ')[1] ? 1 : 0;
        })
      })
      .catch((error: HttpErrorResponse) => {
        this.mensagem.error(`Erro ao listar timezones: ${error.message}`, `Erro: ${error.status || 'Listar timezones'}`)
      })
    return timezones
  }

  async buscarTimezone(id: string): Promise<timezone> {
    let timezone: timezone;
    await this.http.get<timezone>(`${url}/${id}`, { observe: 'body' }).toPromise()
      .then(res => timezone = res)
      .catch((error: HttpErrorResponse) => {
        this.mensagem.error(`Erro ao buscar timezone: ${error.message}`, `Erro: ${error.status || 'Buscar timezone'}`)
      })
    return timezone
  }

  async criarTimezone(timezone: timezone) {
    await this.http.post(url, timezone).toPromise()
      .then(() => this.mensagem.success(`Timezone ${timezone.displayName} criado com sucesso`))
      .catch((error: HttpErrorResponse) => {
        this.mensagem.error(`Erro ao listar timezones: ${error.message}`, `Erro: ${error.status || 'Listar timezones'}`)
      })
  }

  async alterarTimezone(timezone: timezone) {
    await this.http.put(`${url}/${timezone.id}`, timezone).toPromise()
      .then(() => this.mensagem.success(`Timezone ${timezone.displayName} alterado com sucesso`))
      .catch((error: HttpErrorResponse) => {
        this.mensagem.error(`Erro ao alterar timezone: ${error.message}`, `Erro: ${error.status || 'Listar timezones'}`)
      })
  }

  async deletarTimezone(id: string) {
    await this.http.delete(`${url}/${id}`).toPromise()
      .then(() => this.mensagem.success(`Timezone deletado com sucesso`))
      .catch((error: HttpErrorResponse) => {
        this.mensagem.error(`Erro ao deletar timezone: ${error.message}`, `Erro: ${error.status || 'deletar timezone'}`)
      })
  }

}
