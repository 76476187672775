import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CadastroLocalArmazenamentoService } from '../service/cadastro-local-armazenamento.service';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { MensagemService } from '../service/mensagem.service';
import { PlanoContaContabeisService } from '../service/plano-conta-contabeis/plano-conta-contabeis.service';
import { BuscarDataService } from '../service/buscar-data.service';
import { FichaGeralEmpresa } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { SessaoUser } from '../user.reg';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FichaGeralLocalDeArmazenamento, PosicaoDeArmazenamento } from './modelsCadastroLocalArmazenamento';
import { SistemaClassificacaoDadosService } from '../service/sistema-classificacao-dados.service';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { CadastroItemService } from '../service/cadastro-item-service.service';
import { CadastroItem } from '../cadastro-item/campos-cadastroItem';
import { PosicaoDeArmazenamentoService } from '../service/posicao-de-armazenamento.service';
import { FichaDeAssinanteService } from '../service/ficha-de-assinante/ficha-de-assinante.service';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-local-armazenamento',
  templateUrl: './cadastro-local-armazenamento.component.html',
  styleUrls: ['./cadastro-local-armazenamento.component.scss']
})
export class CadastroLocalArmazenamentoComponent implements OnInit {

  displayedColumns = ['acao', 'nomeEmpresa', 'codigoLocalArmazenamento', 'nomeLocalDeArmazenamento', 'ativoDesde']

  ///
  dataDataEmpresa = []

  dadosCadastroLocalArmazenamento: FichaGeralLocalDeArmazenamento[]

  listaCheckBoxTable = []
  formulario: FormGroup

  dadosPosicaoDeArmazenamento: PosicaoDeArmazenamento[]
  ListaDadosPosicaoArmazenamento = []
  ListaDadosPosicaoArmazenamentoEditar = []

  dadosContaContabil = []
  dataLocalArmazenamentoID: FichaGeralLocalDeArmazenamento
  dadosLocalArmazenamentoCadastrar: FichaGeralLocalDeArmazenamento = new FichaGeralLocalDeArmazenamento

  CadastroItem: CadastroItem[]
  viewCadastro: boolean
  CaracteristicaALOCON = []
  listEmpresa = []
  loadings: boolean
  LocalArmazenamento: any
  listar: boolean
  resultadoAssinante: any;

  alteracao: boolean
  botaoSalvar: boolean
  botaoCancelar: boolean
  viewConfirmDelete: boolean

  constructor(
    private serviceCadastroLocalArmazenamento: CadastroLocalArmazenamentoService,
    private fichaGeralService: FichaGeralService,
    private mensagem: MensagemService,
    private serviceContaContabil: PlanoContaContabeisService,
    private serviceData: BuscarDataService,
    private serviceSistemaClassificacaoDados: SistemaClassificacaoDadosService,
    private serviceCadastroItem: CadastroItemService,
    private servicePosicaoDeArmazenamento: PosicaoDeArmazenamentoService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
  ) { }

  ngOnInit() {
    this.buscarDadosEmpresa()
    this.buscarLocalArmazenamentoCodigoEmpresa()
    this.getCaracteristicasALOCON()
    this.creatForm()
    this.getEmpresa()
    this.listar = true
  }

  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: '',
      criadoPor: '',
      nomeEmpresa: new FormControl('', Validators.required),
      nomeLocalDeArmazenamento: new FormControl('', Validators.required),
      descricao: new FormControl('', Validators.required),
      metodoDeAlocacaoEConsumo: new FormControl('', Validators.required),
      quantidadeMaximaDeArmazenamento: new FormControl('', Validators.required),
      pesoMaximoArmazenamento: new FormControl('', Validators.required),
      contaContabilDespesas: new FormControl('', Validators.required),
      contaContabilLancamentoEntradaEstoque: new FormControl('', Validators.required),
      contaContabilLancamentoSaidaEstoque: new FormControl('', Validators.required),
      contaContabilLancamentoCompras: new FormControl('', Validators.required),
      contaContabilLancamentoVendas: new FormControl('', Validators.required),
      contaContabilLancamentoManual: new FormControl('', Validators.required),
    })
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  adicionarCadastro(){
    this.alteracao = true
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
  }

  excluirRegistro(confirm?){
    if(confirm){
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
  }


  async buscarDadosEmpresa() {
    var cpf = sessao.cpf
    this.resultadoAssinante = await this.serviceFichaAssinante.buscarAssinante({ cpf })
    this.fichaGeralService.buscarEmpresasCodigoAssinante(this.resultadoAssinante.codigoAssinante).subscribe(
      (resultado: Array<any>) => {
        let filtro = resultado.filter(function (reg: FichaGeralEmpresa) {
          return reg.status === "SDO0017"
        })
        this.dataDataEmpresa = filtro
      }
    ), erro => {
      this.mensagem.creat("Não foi possível buscar os dados da Empresa no momento, tente novamente mais tarde.", { erro: true })
    }
  }

  async buscarLocalArmazenamentoCodigoEmpresa() {
    this.loadings = true
    this.serviceCadastroLocalArmazenamento.getLocalArmazenamentoCodigoEmpresa(sessao.codigoEmpresa).subscribe(
      (resultado: Array<FichaGeralLocalDeArmazenamento>) => {
        let filtro = resultado.filter(function (reg: FichaGeralLocalDeArmazenamento) {
          return reg.status === "SDO0017"
        })
        this.dadosCadastroLocalArmazenamento = filtro
        this.loadings = false
      }), erro => {
        this.loadings = false
        this.mensagem.creat("Não foi possível buscar os dados do Local de Armazenamento específica no momente, tente novamente mais tarde.", { aviso: true })
      }
  }

  async getCadastroItem(codigoEmpresa: any) {
    this.serviceCadastroItem.getCadastroItemCodigoEmpresa(codigoEmpresa).subscribe(
      (resultado: Array<CadastroItem>) => {
        let filtro = resultado[0].cadastroItem.filter(function (reg: CadastroItem) {
          return reg.status === "Ativo"
        })
        this.CadastroItem = filtro
        console.log(this.CadastroItem)
        this.CadastroItem.sort(function (a: CadastroItem, b: CadastroItem) {
          let aDescricao = a.descricao.trim().toLowerCase();
          let bDescricao = b.descricao.trim().toLowerCase();
          if (a == b) return 0;
          return aDescricao < bDescricao ? -1 : 1;
        })
      }
    )
  }

  async getCaracteristicasALOCON() {
    var Caracteristica = "ALOCON"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado: Array<conteudoDeDado>) => {
        this.CaracteristicaALOCON = resultado
      })
  }

  buscarContaContabil(codigoEmpresa: any) {
    this.loadings = true
    this.serviceContaContabil.getContaContabilCodigoEmpresa(codigoEmpresa).subscribe(
      (resultado: Array<PlanoContaContabeisModel>) => {
        let filtroConta = resultado.filter(function (reg: PlanoContaContabeisModel) {
          return reg.status === "SDO0017"
        })
        this.dadosContaContabil = filtroConta
        this.dadosContaContabil.sort(function (a: PlanoContaContabeisModel, b: PlanoContaContabeisModel) {
          let aDescricao = a.descricao.trim().toLowerCase();
          let bDescricao = b.descricao.trim().toLowerCase();
          if (a == b) return 0;
          return aDescricao < bDescricao ? -1 : 1;
        })
        this.loadings = false
      }), erro => {
        this.mensagem.creat("Não foi possível buscar os dados da Conta Contábil no momento, por favor tente novamente mais tarde", { erro: true })
      }
  }

  async buscarLocalArmazenamentoID() {
    this.serviceCadastroLocalArmazenamento.getCadastroLocalArmazenamentoID(this.LocalArmazenamento).subscribe(
      (resultado => {
        this.dataLocalArmazenamentoID = resultado
        this.buscarPosicaoDeArmazenamentoID(this.dataLocalArmazenamentoID.codigoLocalArmazenamento)
        this.buscarContaContabil(this.dataLocalArmazenamentoID.codigoEmpresa)
        this.listar = null
      }), erro => {
        this.mensagem.creat("Impossível buscar os dados do Local de Armazenamento específica no momente, tente novamente mais tarde.", { aviso: true })
      })
  }

  async buscarPosicaoDeArmazenamentoID(codigoLocalArmazenamento: any) {
    this.servicePosicaoDeArmazenamento.getPosicaoDeArmazenamentoCodigoLocalDeArmazenamento(codigoLocalArmazenamento).subscribe(
      (resultado: Array<any>) => {
        this.ListaDadosPosicaoArmazenamentoEditar = resultado
      }
    )
  }

  async gravarLocalArmazenamentoCompleto() {
    this.loadings = true
    this.dadosLocalArmazenamentoCadastrar.status = "SDO0017"
    this.dadosLocalArmazenamentoCadastrar.criadoPor = sessao.cpf
    this.dadosLocalArmazenamentoCadastrar.ativoDesde = this.serviceData.hojeBrasil()
    this.serviceCadastroLocalArmazenamento.postCadastroLocalArmazenamento(this.dadosLocalArmazenamentoCadastrar).toPromise().then(
      (sucesso => {
        var variavelID = sucesso

        if (this.ListaDadosPosicaoArmazenamento.length > 0) {
          this.ListaDadosPosicaoArmazenamento.forEach((reg, i) => {
            this.ListaDadosPosicaoArmazenamento[i].codigoLocalDeArmazenamento = variavelID[0].key
            this.ListaDadosPosicaoArmazenamento[i].criadoPor = sessao.cpf
            this.ListaDadosPosicaoArmazenamento[i].status = "SDO0017"
          });
        }

        this.casdastrarPosicaoArmazenamento()
        this.ListaDadosPosicaoArmazenamento = []
        this.listar = true
        this.buscarLocalArmazenamentoCodigoEmpresa()
        this.loadings = false
        this.mensagem.creat('Gravado com Sucesso!', { sucesso: true })
      }), erro => {
        this.loadings = false
        this.mensagem.creat('Não foi possível cadastrar os dados do Local De Armazenamento no momento, por favo tente novamente mais tarde.', { erro: true })
      })
    this.dadosLocalArmazenamentoCadastrar = new FichaGeralLocalDeArmazenamento()
  }

  async AlterarLocalArmazenamento() {
    this.loadings = true
    this.dataLocalArmazenamentoID.criadoPor = sessao.cpf
    this.serviceCadastroLocalArmazenamento.PutCadastroLocalArmazenamento(this.LocalArmazenamento, sessao.cpf, this.serviceData.horaAgora(true), this.dataLocalArmazenamentoID).subscribe(
      (resultado => {

        var variavelID = resultado

        if (this.ListaDadosPosicaoArmazenamentoEditar.length > 0) {
          this.ListaDadosPosicaoArmazenamentoEditar.forEach((reg, i) => {
            this.ListaDadosPosicaoArmazenamentoEditar[i].codigoLocalDeArmazenamento = variavelID[0].key
            this.ListaDadosPosicaoArmazenamentoEditar[i].criadoPor = sessao.cpf
            this.ListaDadosPosicaoArmazenamentoEditar[i].status = "SDO0017"
          });
        }

        this.mensagem.creat('Dados do Local de Armazenamento atualizado com sucesso!', { sucesso: true })
        this.listar = true
        this.buscarLocalArmazenamentoCodigoEmpresa()
        this.loadings = false
      }), erro => {
        this.loadings = false
        this.mensagem.creat("Não foi possível atualizar os dados do Local de Armazenamento no momento, tente novamente mais tarde!", { erro: true })
      })
  }

  async addDadosLocalArmazenamento(event: Boolean, item: FichaGeralLocalDeArmazenamento) {
    if (event) {
      this.listaCheckBoxTable.push(item)
    } else {
      let index = this.listaCheckBoxTable.map((x, i) => { return { index: i, cod: x.codigoLocalArmazenamento } }).filter((x) => { return x.cod == item.codigoLocalArmazenamento })[0].index
      this.listaCheckBoxTable.splice(index, 1)
    }
  }

  async ExcluirLocalArmazenamento() {
    this.loadings = true
    this.listaCheckBoxTable.forEach(reg => {
      console.log()
      reg.status = "SDO0066"
      this.serviceCadastroLocalArmazenamento.PutCadastroLocalArmazenamento(reg.codigoLocalArmazenamento, sessao.sessao_id, this.serviceData.horaAgora(true), reg).subscribe(
        (resultado => {
          this.mensagem.creat("Local de Armazenamento excluído com sucesso.", { sucesso: true })
          this.buscarLocalArmazenamentoCodigoEmpresa()
          this.loadings = false
          this.listaCheckBoxTable = []
        }), error => {
          this.loadings = false
          this.mensagem.creat("Não foi possível excluir os dados do Local de Armazenamento no momento, tente novamente mais tarde.", { erro: true })
        }
      )
    })
  }

  adicionarLocalArmazenamento(codigoItem?: any, descricao?: any, metodoDeAlocacaoEConsumo?: any, quantidadeMaximaDeArmazenamento?: any, pesoMaximoArmazenamento?: any) {
    this.ListaDadosPosicaoArmazenamento.push(
      {
        codigoDeItemSKU: codigoItem,
        descricao: descricao,
        metodoDeAlocacaoEConsumo: metodoDeAlocacaoEConsumo,
        quantidadeMaximaDeArmazenamento: quantidadeMaximaDeArmazenamento,
        pesoMaximoArmazenamento: pesoMaximoArmazenamento,
        cpfAutor: sessao.cpf
      }
    )
  }

  adicionarLocalArmazenamentoEditar(codigoItem?: any, descricao?: any, metodoDeAlocacaoEConsumo?: any, quantidadeMaximaDeArmazenamento?: any, pesoMaximoArmazenamento?: any) {
    this.ListaDadosPosicaoArmazenamentoEditar.push(
      {
        codigoItem: codigoItem,
        descricao: descricao,
        metodoDeAlocacaoEConsumo: metodoDeAlocacaoEConsumo,
        quantidadeMaximaDeArmazenamento: quantidadeMaximaDeArmazenamento,
        pesoMaximoArmazenamento: pesoMaximoArmazenamento
      }
    )
  }

  removerLocalArmazenamento(result) {
    const index: number = this.ListaDadosPosicaoArmazenamento.indexOf(result);
    if (result !== 1) {
      this.ListaDadosPosicaoArmazenamento.splice(index, 1)
    }
  }

  removerLocalArmazenamentoEditar(result) {
    const index: number = this.ListaDadosPosicaoArmazenamentoEditar.indexOf(result);
    if (result !== 1) {
      this.ListaDadosPosicaoArmazenamentoEditar.splice(index, 1)
    }
  }

  casdastrarPosicaoArmazenamento() {
    this.ListaDadosPosicaoArmazenamento.forEach(reg => {
      this.servicePosicaoDeArmazenamento.postPosicaoDeArmazenamento(reg).subscribe(
        () => {
        }
      );
    });
  }

}
