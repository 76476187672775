import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class BuscarDataService {

  constructor() { }
  hoje() {
    let hoje = new Date().toLocaleString().split(' ')[0].split('/').reverse().join('-')
    return hoje
  }

  hojeBrasil(ref?, barra?) {
    let buscarData = new Date()
    let hoje
    if (ref) {
      hoje = buscarData.toLocaleDateString().split('/').join('.')
    }
    else {
      hoje = buscarData.toLocaleDateString()
    }
    if (barra) {

    }
    return hoje
  }
  amanha() {
    var buscarData = new Date();
    let d = JSON.stringify(buscarData.getDate() + 1);
    let m = JSON.stringify(buscarData.getMonth() + 1);
    if (m.length < 2) {
      m = `0${m}`
    }
    if (d.length < 2) {
      d = `0${d}`
    }
    let y = buscarData.getFullYear();
    return `${y}-${m}-${d}`;
  }
  ontem() {
    var buscarData = new Date();
    let d = JSON.stringify(buscarData.getDate() - 1);
    let m = JSON.stringify(buscarData.getMonth() - 1);
    if (m.length < 2) {
      m = `0${m}`
    }
    if (d.length < 2) {
      d = `0${d}`
    }
    let y = buscarData.getFullYear();
    return `${y}-${m}-${d}`;
  }

  horaAgora(datetime?) {
    let buscarHora = new Date()
    let hora = buscarHora.toTimeString().substr(0, 5)
    if (datetime) {
      hora = `${this.hoje()}T${this.horaAgoraSegundos()}`
      return hora
    }
    return hora
  }
  horaAgoraSegundos() {
    let buscarHora = new Date()
    let hora = buscarHora.toTimeString().substr(0, 8)
    return hora
  }
  idade(data) {
    let idade = Math.floor(
      Math.ceil(
        Math.abs(
          new Date(data).getTime() - new Date().getTime())
        / (1000 * 3600 * 24)) / 365.25);

    if (!data) { return 0 }

    return idade

  }

  getMeses(mes: number) {
    const meses = [
      { name: 'Janeiro' },
      { name: 'Fevereiro' },
      { name: 'Março' },
      { name: 'Abril' },
      { name: 'Maio' },
      { name: 'Junho' },
      { name: 'Julho' },
      { name: 'Agosto' },
      { name: 'Setembro' },
      { name: 'Outubro' },
      { name: 'Novembro' },
      { name: 'Dezembro' }
    ]
    // console.log('mes ' +mes)
    return meses[mes]
  }
  getMesesAll() {
    const meses = [
      { name: 'Janeiro' },
      { name: 'Fevereiro' },
      { name: 'Março' },
      { name: 'Abril' },
      { name: 'Maio' },
      { name: 'Junho' },
      { name: 'Julho' },
      { name: 'Agosto' },
      { name: 'Setembro' },
      { name: 'Outubro' },
      { name: 'Novembro' },
      { name: 'Dezembro' }
    ]
    // console.log('mes ' +mes)
    return meses
  }
  convertBR(date) {
    if (!date) return
    const d = date.split('-')[2]
    const m = date.split('-')[1]
    const a = date.split('-')[0]
    const data = `${d}/${m}/${a}`
    return data
  }
  convertEUA(date) {
    if (!date) return
    try {
      const d = date.split('/')[0]
      const m = date.split('/')[1]
      const a = date.split('/')[2]
      const data = `${a}-${m}-${d}`
      return data
    } catch {
      return null
    }

  }
  convertTIMESTAMP(dado: string) {

    if (!dado) return
    try {
      const d = dado.slice(0, 2)
      const m = dado.slice(2, 4)
      const ano = dado.slice(4, 8)

      return `${d}/${m}/${ano}`
    } catch {
      return '00/00/0000'
    }

  }
  getSemanas(dia) {
    const semanas: { name: string }[] = [
      { name: 'Domingo' },
      { name: 'Segunda-Feira' },
      { name: 'Terça-Feira' },
      { name: 'Quarta-Feira' },
      { name: 'Quinta-Feira' },
      { name: 'Sexta-Feira' },
      { name: 'Sábado' }]
    try {
      if (dia > 6) return semanas[0].name
      if (dia) return semanas[dia].name
      if (dia == undefined) return ''
    } catch {
      return 'Indefinido'
    }


  }

  getTIMESTAMP() {
    let date
    try {
      date = new Date().toLocaleDateString().split('/').join('')
    } catch {
      date = new Date().toLocaleDateString().split('-').join('')
    }
    let time = new Date().toLocaleTimeString().split(':').join('')
    return `${date}${time}`
  }

  getDataInclusao() {
    return `${this.hoje()}T${this.horaAgoraSegundos()}`
  }
  genereteRangeDatas(start: Date, end: Date) {
    for (var dates = [], dt = new Date(start); dt < end; dt.setDate(dt.getDate() + 1)) {
      let d = new Date(dt).toISOString().slice(0, 10)
      dates.push(d);
    }
    let getDates = dates
    return getDates;
  }
  somarHora(hora: Date, acrescentarMinuto: number) {
    return moment(hora, 'HH:mm').add(acrescentarMinuto, 'minutes').format('HH:mm')
  }
  convertCriadoEm(criadoEM: string, revert?: boolean) {
    try {
      let timestamp = criadoEM
      let ano = timestamp.slice(0, 4)
      let mes = timestamp.slice(4, 6)
      let dia = timestamp.slice(6, 8)
      let timeH = timestamp.slice(8, 10)
      let timeM = timestamp.slice(10, 12)
      if(revert){
        dia = timestamp.slice(0, 2)
        mes = timestamp.slice(2,4)
        ano = timestamp.slice(4,8)
      }
      let date
      if (timeH && timeM) {
        date = new Date(`${ano}-${mes}-${dia}T${timeH}:${timeM}`).toISOString()
      } else {
        date = `${ano}-${mes}-${dia}`
      }
      return date
    } catch {
      return '0000-00-00'
    }
  }
}
