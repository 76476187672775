import { FormControl } from "@angular/forms";
import { DiarioDeAcessoService } from "../service/diario-de-acesso/diario-de-acesso.service";
import { StatusDeSessao } from "../service/diario-de-acesso/statusDeSessao.class";
import { Acesso } from "../service/diario-de-acesso/diario-de-acesso.model";
import { MatTableDataSource, MatSnackBar, MatSort } from "@angular/material";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { Component, OnInit, ViewChild, Pipe, TemplateRef } from "@angular/core";
import { MensagemService } from "src/app/service/mensagem.service";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-diario-de-acesso",
  templateUrl: "./diario-de-acesso.component.html",
  styleUrls: ["./diario-de-acesso.component.scss"],
})
export class DiarioDeAcessoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  panelOpenState: boolean = false;
  StatusControl = new FormControl();
  statusDeSessao = new StatusDeSessao();
  status: String[] = Object.values(this.statusDeSessao.status);
  acessos: Acesso[];
  filtro: Acesso = {
    cpf: "",
    ipLocal: "",
    inicioDaSessao: "",
    localDeSessao: "",
    status: "",
    coordenadaGps: "",
    tempoDeSessao: "",
  };

  dataSource;

  displayedColumns = [
    "detalhe",
    "cpf",
    "nomeCompleto",
    "inicioDaSessao",
    "fimDaSessao",
    "tempoDeSessao",
    "status",
    "mapa",
  ];

  registro;

  viewRegistro: boolean;

  registrosTabela;
  cordenadas: any;
  loading: boolean;
  viewMap_1: boolean;
  viewMap_2: boolean;
  viewMap_3: boolean;

  constructor(
    private diarioDeAcesso: DiarioDeAcessoService,
    private message: MensagemService,
    private http: HttpClient,
    private _form: FormBuilder,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // this.buscarSessoes();
    this.GetJoin();
    this.getCoordenada();
  }
  openMap(dado) {
    if (dado.cpf == "10131122020") {
      this.viewMap_1 = true;
      this.viewMap_2 = false;

      this.viewMap_3 = false;
    }
    if (dado.cpf == "46096593879") {
      this.viewMap_2 = true;
      this.viewMap_1 = false;
      this.viewMap_3 = false;
    }
    if (dado.cpf == "05926169836") {
      this.viewMap_3 = true;
      this.viewMap_2 = false;
      this.viewMap_1 = false;
    }
  }
  getCoordenada() {
    this.http
      .get(
        "https://myclinics.com.br/api/api/DiarioAcessoUsuario/B149FB75-4ECE-4038-A3BC-08D85F1C311E"
      )
      .toPromise()
      .then((res: any) => {
        let registro = res;
        let url = `https://maps.google.com/maps?q=${res.coordenadaGps}&hl=es;z=14&amp;output=embed`;
        // let url = `http://localhost:5000/?${this.autor ? 'medico' : 'paciente'}&${this.id}${this.contato ? '&contato' : ''}`
        this.cordenadas = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log("TESTE56", url);
      });
  }
  GetJoin() {
    this.loading = true;
    this.diarioDeAcesso
      .GetJoin()
      .toPromise()
      .then((registros: any) => {
        this.dataSource = new MatTableDataSource(registros);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.registrosTabela = registros;
        this.loading = false;
      });
  }

  filter(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }

  async buscarSessoes() {
    return await this.diarioDeAcesso
      .todasAsSessoes()
      .then((res) => (this.acessos = res.reverse()));
  }

  //async desconectarSessao(id: String) {
  //  await this.diarioDeAcesso.MudarStatusSessao({ id, status: this.statusDeSessao.status.desconectadoPorUsuario })
  //  this.buscarSessoes();
  //}

  async filtrarResultados(filtro: string) {
    this.acessos = await this.buscarSessoes();
    let quantidadeFiltros = Object.values(this.filtro).filter(
      (filtro) => filtro != ""
    ).length;
    // let resultados = this.acessos.filter(acesso => acesso[filtro].includes(this.filtro[filtro]) && this.filtro[filtro] !== '' || acesso.status === this.filtro.status);
    let resultados = this.registrosTabela.filter(
      (acesso) =>
        (acesso[filtro].includes(this.filtro[filtro]) &&
          this.filtro[filtro] !== "") ||
        acesso.status === this.filtro.status
    );
    console.log(this.filtro);
    this.registrosTabela = quantidadeFiltros > 0 ? resultados : this.GetJoin();
    this.dataSource = new MatTableDataSource(this.registrosTabela);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (quantidadeFiltros > 0) {
      this.GetJoin();
    }
  }
}
