import { ListaDePrecoService } from '../../service/determinar-preco-venda/lista-de-preco.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CadastroItem } from 'src/app/cadastro-item/campos-cadastroItem';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegrasDeImpostoService } from 'src/app/service/regras-de-imposto.service';
import { CadastroItemService } from 'src/app/service/cadastro-item-service.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { MatTableDataSource } from '@angular/material';
import { dadosRegrasDeImposto } from 'src/app/configuracao-inicial/regras-de-imposto/model';
import { AJ_LISTPRECO_CAB, AJ_LISTPRECO_ITM_ACRESCIMO, AJ_LISTPRECO_ITM_DESCONTO, AJ_LISTPRECO_ITM_IMPOSTO, AJ_LISTPRECO_ITM_PRAZO, AJ_LISTPRECO_ITM_PRECO } from 'src/app/service/determinar-preco-venda/model';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { ValidateBrService } from 'angular-validate-br';
import { CadastroOperadoraSaudeService } from 'src/app/service/cadastro-operadora-de-saudade.service';
import { CadastroOperadoraSaude } from 'src/app/cadastro-operadora-saude/modelsCadastroOperadoraSaude';



const sessao = new SessaoUser

@Component({
  selector: 'app-lista-precos-venda',
  templateUrl: './lista-precos-venda.component.html',
  styleUrls: ['./lista-precos-venda.component.scss']
})
export class ListaPrecosVendaComponent implements OnInit {

  displayedColumns = ['detalhe', 'nomeEmpresa', 'codigoListaDePreco', 'descricao', 'vigenciaInicio', 'vigenciaFim']
  displayedColumnsItem = ['codigoSKU', 'descricao', 'precoLista', 'acao']
  displayedColumnsDeterminarImposto = ['codigoImposto', 'descricao', 'jurisdicao', 'impostoEfetivoDesde', 'acao']
  displayedColumnsPrazoPagamento = ['descricao', 'tipoPrazoPag', 'condicaoPrazo', 'meioPagamento', 'prazoPagamentoDias', 'numeroParcelas', 'acao']
  displayedColumnsDesconto = ['descricao', 'tipoDesconto', 'condicaoDesconto', 'desconto', 'acao']
  loading: boolean;
  alteracao: boolean;
  viewCadastro: boolean;
  descontoPercentual: boolean

  selectEmpresa = '0'

  dataSourceItem = new MatTableDataSource()
  dataSourceRegaImposto = new MatTableDataSource()
  dataSource = new MatTableDataSource()
  dataSourcePrazoPagamento = new MatTableDataSource()
  dataSourceFichaDesconto = new MatTableDataSource()
  dataSourceFichaAcrescimo = new MatTableDataSource()

  activeITEM = new CadastroItem
  activePrazoPagamento = new AJ_LISTPRECO_ITM_PRAZO
  activeRegraImposto = new dadosRegrasDeImposto;

  tablePrecoItem: AJ_LISTPRECO_ITM_PRECO[] = []
  tableRegraImposto: any[] = []
  tablePrazoPagamento: AJ_LISTPRECO_ITM_PRAZO[] = [];
  tableFichaDesconto: AJ_LISTPRECO_ITM_DESCONTO[] = []
  tableFichaAcrescimo: AJ_LISTPRECO_ITM_ACRESCIMO[] = []

  formDeterminarImposto: FormGroup;
  formFichaDesconto: FormGroup;
  formFichaAcrescimo: FormGroup;
  formFichaGeral: FormGroup
  formFichaPreco: FormGroup;
  formPrazoPagamento: FormGroup;

  listRegraImposto: dadosRegrasDeImposto[] = [];
  listPrazoPagamento = []
  listEmpresa = []
  listItens = []

  conteudoTipoDesconto: ConteudoDados[] = [];
  conteudoMeioPagamento: ConteudoDados[] = [];
  conteudoMeioOperadoraSaude: CadastroOperadoraSaude[] = [];
  nomePacienteDesc: string;
  nomePacienteAcres: string;
  conteudoTipoAcrescimo: ConteudoDados[];
  conteudoTipoPrazo: ConteudoDados[];
  nomePacientePrazo: string;
  noConvenio: boolean;


  constructor(
    private _mensagem: MensagemService,
    private service: ListaDePrecoService,
    private serviceCadastroItem: CadastroItemService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private _regraImposto: RegrasDeImpostoService,
    private _conteudo: ConteudoDeDadosService,
    private _usuario: UsuarioService,
    private ValidBr: ValidateBrService,
    private _convenio: CadastroOperadoraSaudeService
  ) { }

  ngOnInit() {

    this.creatForm()
    this.getRegistro(0)
    this.getEmpresa()
    this.getConteudo()
  }
  async SelectEmpresa(codigoEmpresa) {
    this.getCadastroItem(codigoEmpresa)
    this.getRegraImposto(codigoEmpresa)
    this.conteudoMeioOperadoraSaude = await this._convenio.get(codigoEmpresa)
    if (this.conteudoMeioOperadoraSaude.length < 1) {
      this.noConvenio = true
    } else {
      this.noConvenio = false
    }

  }
  async getRegistro(codigoEmpresa) {
    codigoEmpresa = parseInt(codigoEmpresa)
    let registro: any = await this.service.getCAB()
    if (codigoEmpresa > 0) {
      registro = registro.filter(value => value.codigoEmpresa == codigoEmpresa)
    }
    this.dataSource = new MatTableDataSource(registro)
    console.log('REGISTRO0', registro, codigoEmpresa)
  }

  async getCadastroItem(codigoEmpresa) {

    let getItem = await this.serviceCadastroItem.getCadastroItem(codigoEmpresa)
    console.log('getCadastro',getItem)
    try {
      this.listItens = getItem.filter(value => value.categoria == 'CGITEM4' && value.status == 'SCA0001')
    } catch {

    }

  }
  async getRegraImposto(codigoEmprsa) {
    let get = await this._regraImposto.getRegraDeImpostoCodigoEmpresa(codigoEmprsa)
    this.listRegraImposto = get
    console.log('listRegraImposto', this.listRegraImposto)
  }
  async getConteudo() {

    this.conteudoTipoPrazo = await this._conteudo.get('TIPPRAZO')
    this.conteudoTipoDesconto = await this._conteudo.get('TIPDESC')
    this.conteudoTipoAcrescimo = await this._conteudo.get('TIPACRES')
    this.conteudoMeioPagamento = await this._conteudo.get('MEIOPG')


  }


  addItem() {
    const codigoSKU = this.activeITEM.codigoSKU
    const item = this.activeITEM.descricao
    const preco = this.formFichaPreco.controls['precoLista'].value
    console.log(codigoSKU, this.formFichaPreco.value)
    if (!preco || !codigoSKU) {
      this._mensagem.creat('Informe o item e preço', { erro: true })
      return
    }

    let verific = this.tablePrecoItem.findIndex(value => value.codigoSku == codigoSKU)
    if (verific >= 0) {
      delete this.tablePrecoItem[verific]
      this.tablePrecoItem = this.tablePrecoItem.filter(value => value)
    }

    this.tablePrecoItem.push({ id: this.tablePrecoItem.length, codigoSku: codigoSKU, descricao: item, precoLista: preco })
    this.dataSourceItem = new MatTableDataSource(this.tablePrecoItem)
    this.formFichaPreco.get('codigoSku').reset()
    this.formFichaPreco.get('precoLista').reset()

    this.activeITEM = new CadastroItem
    console.log(this.dataSourceItem.data)
  }

  editPrecoItem(codigoSKU) {
    let get = this.tablePrecoItem.filter(value => value.codigoSku == codigoSKU)
    let item = get[0]
    this.formFichaPreco.get('codigoSku').setValue(item.codigoSku)
    this.formFichaPreco.get('precoLista').setValue(item.precoLista)
    this.selectItem(codigoSKU, 'item')
  }

  selectItem(item, tipo?) {
    console.log('item', item)
    let get
    switch (tipo) {
      case 'item':
        get = this.listItens.filter(value => value.codigoSKU == item)
        if (get.length > 0) {
          this.activeITEM = get[0]
          this.formFichaDesconto.get('descItem').setValue(this.activeITEM.descricao)
        }
        break;

      case 'operadora':
        get = this.conteudoMeioOperadoraSaude.filter(value => value.codigo == item)
        this.formFichaDesconto.get('descOperadora').setValue(get[0].valor)
        break;

      default:
        break;
    }





    switch (item) {
      ////PRAZO
      case 'TIPPRAZO1':
        if (this.tablePrecoItem.length > 0) {
          this.formPrazoPagamento.get('codigoItem').setValue(this.tablePrecoItem[0].codigoSku)

        }
        break
      case 'TIPPRAZO2':
        if (this.conteudoMeioOperadoraSaude.length > 0) {
          this.formPrazoPagamento.get('codigoOperadora').setValue(this.conteudoMeioOperadoraSaude[0].codigo)
        }
        break
      case 'TIPPRAZO3':
        this.formPrazoPagamento.get('cpfPaciente').setValidators([Validators.required, this.ValidBr.cpf])
        this.formPrazoPagamento.updateValueAndValidity()
        break

      /// DESCONTO
      case 'TIPDESC1':
        if (this.tablePrecoItem.length > 0) {
          this.formFichaDesconto.get('codigoItem').setValue(this.tablePrecoItem[0].codigoSku)

        }
        break
      case 'TIPDESC2':
        if (this.conteudoMeioOperadoraSaude.length > 0) {
          this.formFichaDesconto.get('codigoOperadora').setValue(this.conteudoMeioOperadoraSaude[0].codigo)
        }
        break
      case 'TIPDESC3':
        this.formFichaDesconto.get('cpfPaciente').setValidators([Validators.required, this.ValidBr.cpf])
        this.formFichaDesconto.updateValueAndValidity()
        break

      /// ACRESCIMO
      case 'TIPACRES1':
        if (this.tablePrecoItem.length > 0) {
          this.formFichaAcrescimo.get('codigoItem').setValue(this.tablePrecoItem[0].codigoSku)

        }
        break
      case 'TIPACRES2':
        if (this.conteudoMeioOperadoraSaude.length > 0) {
          this.formFichaAcrescimo.get('codigoOperadora').setValue(this.conteudoMeioOperadoraSaude[0].codigo)
        }
        break
      case 'TIPACRES3':
        this.formFichaAcrescimo.get('cpfPaciente').setValidators([Validators.required, this.ValidBr.cpf])
        this.formFichaAcrescimo.updateValueAndValidity()
        break
    }

    this.formPrazoPagamento.get('cpfPaciente').clearValidators()
    this.formFichaDesconto.get('cpfPaciente').clearValidators()
    this.formFichaAcrescimo.get('cpfPaciente').clearValidators()
    this.formPrazoPagamento.get('cpfPaciente').reset()
    this.formFichaDesconto.get('cpfPaciente').reset()
    this.formFichaAcrescimo.get('cpfPaciente').reset()

    this.formPrazoPagamento.updateValueAndValidity()
    this.formFichaDesconto.updateValueAndValidity()
    this.formFichaAcrescimo.updateValueAndValidity()

    console.log(this.formPrazoPagamento.get('cpfPaciente').valid)
    console.log(this.formFichaDesconto.get('cpfPaciente').valid)
    console.log(this.formFichaAcrescimo.get('cpfPaciente').valid)

  }

  removePrecoItem(id) {
    let index = this.tablePrecoItem.findIndex(value => value.id == id)
    delete this.tablePrecoItem[index]
    this.tablePrecoItem = this.tablePrecoItem.filter(value => value)
    this.dataSourceItem = new MatTableDataSource(this.tablePrecoItem)
  }

  //*************************REGRA IMPOSTO  **************************************************/
  addRegraImposto() {
    const codigoImposto = this.activeRegraImposto.codigoImposto
    const regraImposto = this.formDeterminarImposto.controls['regraImposto'].value

    if (!regraImposto) {
      this._mensagem.creat('Informe uma regra de imposto', { erro: true })
      return
    }

    let verific = this.tableRegraImposto.findIndex(value => value.codigoImposto == codigoImposto)
    if (verific >= 0) {
      delete this.tableRegraImposto[verific]
      this.tableRegraImposto = this.tableRegraImposto.filter(value => value)
    }

    this.tableRegraImposto.push(this.activeRegraImposto)
    this.dataSourceRegaImposto = new MatTableDataSource(this.tableRegraImposto)
    this.formDeterminarImposto.get('regraImposto').reset()
    // this.activeITEM = new CadastroItem
    console.log(this.dataSourceRegaImposto.data)
  }

  editRegraImposto(codigoImposto) {
    let get = this.tableRegraImposto.filter(value => value.codigoImposto == codigoImposto)
    let item = get[0]
    this.formDeterminarImposto.get('regraImposto').setValue(item.codigoImposto)
    this.selectItem(codigoImposto)
  }

  selectRegraImposto(item) {
    let get = this.listRegraImposto.filter(value => value.codigoImposto == item)
    if (get.length > 0) {
      this.activeRegraImposto = get[0]
    }
  }
  removeRegraImposto(codigoImposto) {
    let index = this.tableRegraImposto.findIndex(value => value.codigoImposto == codigoImposto)
    delete this.tableRegraImposto[index]
    this.tableRegraImposto = this.tableRegraImposto.filter(value => value)
    this.dataSourceRegaImposto = new MatTableDataSource(this.tableRegraImposto)
  }

  //************************* END REGRA IMPOSTO  **************************************************/


  //*************************PRAZO PAGAMENTO  **************************************************/
  addPrazoPagamento() {

    let registro = this.formPrazoPagamento.value
    let verifc = this.tablePrazoPagamento.findIndex(value => value.id == registro.id)
    console.log('prazo', verifc)

    if (verifc >= 0) {
      delete this.tablePrazoPagamento[verifc]
      this.tablePrazoPagamento = this.tablePrazoPagamento.filter(value => value)
    }
    registro.id = this.tablePrazoPagamento.length
    let descMeioPagamento = this.conteudoMeioPagamento.filter(value => value.codigoReferencia == registro.meioPagamento)
    registro.descMeioPagamento = descMeioPagamento[0].valor
    this.tablePrazoPagamento.push(registro)
    this.dataSourcePrazoPagamento = new MatTableDataSource(this.tablePrazoPagamento)
    this.formPrazoPagamento.reset()
    // this.activeITEM = new CadastroItem
    console.log(this.dataSourcePrazoPagamento.data)
  }

  editPrazoPagamento(id) {
    let get = this.tablePrazoPagamento.filter(value => value.id == id)
    let item = get[0]
    this.formPrazoPagamento.patchValue(item)

  }

  selectPrazoPagamento(item) {
    let get = this.listPrazoPagamento.filter(value => value.codigoImposto == item)
    if (get.length > 0) {
      this.activePrazoPagamento = get[0]
    }
  }
  removePrazoPagamento(id) {
    let index = this.tablePrazoPagamento.findIndex(value => value.id == id)
    delete this.tablePrazoPagamento[index]
    this.tablePrazoPagamento = this.tablePrazoPagamento.filter(value => value)
    this.dataSourcePrazoPagamento = new MatTableDataSource(this.tablePrazoPagamento)
  }

  //*************************END PRAZO PAGAMENTO  **************************************************/

  //*************************FICHA DESCONTO**************************************************/
  addFichaDesconto() {

    let registro = this.formFichaDesconto.value
    let verifc = this.tableFichaDesconto.findIndex(value => value.id == registro.id)
    console.log('prazo', verifc)

    if (verifc >= 0) {
      delete this.tableFichaDesconto[verifc]
      this.tableFichaDesconto = this.tableFichaDesconto.filter(value => value)
    }
    registro.id = this.tableFichaDesconto.length
    let descTipoDesconto = this.conteudoTipoDesconto.filter(value => value.codigoReferencia == registro.tipoDesconto)
    registro.descTipoDesconto = descTipoDesconto[0].valor
    this.tableFichaDesconto.push(registro)
    this.dataSourceFichaDesconto = new MatTableDataSource(this.tableFichaDesconto)
    let guardTIppo = this.formFichaDesconto.get('formaDesconto').value
    this.formFichaDesconto.reset()
    this.formFichaDesconto.get('formaDesconto').setValue(guardTIppo)
    this.formFichaDesconto.get('cpfPaciente').clearValidators()


    // this.activeITEM = new CadastroItem
    console.log(this.dataSourceFichaDesconto.data)

  }

  editFichaDesconto(id) {
    let get = this.tableFichaDesconto.filter(value => value.id == id)
    let item = get[0]
    this.formFichaDesconto.patchValue(item)

  }

  selectFichaDesconto(item) {
    // let get = this.listFichaDesconto.filter(value => value.codigoImposto == item)
    // if (get.length > 0) {
    //   this.activeFichaDesconto = get[0]
    // }
  }
  removeFichaDesconto(id) {
    let index = this.tableFichaDesconto.findIndex(value => value.id == id)
    delete this.tableFichaDesconto[index]
    this.tableFichaDesconto = this.tableFichaDesconto.filter(value => value)
    this.dataSourceFichaDesconto = new MatTableDataSource(this.tableFichaDesconto)
  }

  //*************************END FICHA DESCONTO  **************************************************/

  //*************************FICHA ACRESCIMO**************************************************/
  addFichaAcrescimo() {

    let registro = this.formFichaAcrescimo.value
    let verifc = this.tableFichaAcrescimo.findIndex(value => value.id == registro.id)
    console.log('prazo', verifc)

    if (verifc >= 0) {
      delete this.tableFichaAcrescimo[verifc]
      this.tableFichaAcrescimo = this.tableFichaAcrescimo.filter(value => value)
    }
    registro.id = this.tableFichaAcrescimo.length
    let descTipoAcrescimo = this.conteudoTipoAcrescimo.filter(value => value.codigoReferencia == registro.tipoAcrescimo)
    registro.descTipoAcrescimo = descTipoAcrescimo[0].valor
    this.tableFichaAcrescimo.push(registro)
    this.dataSourceFichaAcrescimo = new MatTableDataSource(this.tableFichaAcrescimo)

    let guardTIppo = this.formFichaAcrescimo.get('formaAcrescimo').value
    this.formFichaAcrescimo.reset()
    this.formFichaAcrescimo.get('formaAcrescimo').setValue(guardTIppo)
    this.formFichaAcrescimo.get('cpfPaciente').clearValidators()

    // this.activeITEM = new CadastroItem
    console.log(this.dataSourceFichaAcrescimo.data)
  }

  editFichaAcrescimo(id) {
    let get = this.tableFichaAcrescimo.filter(value => value.id == id)
    let item = get[0]
    this.formFichaAcrescimo.patchValue(item)
  }

  selectFichaAcrescimo(item) {
    // let get = this.listFichaAcrescimo.filter(value => value.codigoImposto == item)
    // if (get.length > 0) {
    //   this.activeFichaAcrescimo = get[0]
    // }
  }
  removeFichaAcrescimo(id) {
    let index = this.tableFichaAcrescimo.findIndex(value => value.id == id)
    delete this.tableFichaAcrescimo[index]
    this.tableFichaAcrescimo = this.tableFichaAcrescimo.filter(value => value)
    this.dataSourceFichaAcrescimo = new MatTableDataSource(this.tableFichaAcrescimo)
  }
  selectItemAcrescimo(item, tipo?) {
    let get
    switch (tipo) {
      case 'item':
        get = this.listItens.filter(value => value.codigoSKU == item)
        if (get.length > 0) {
          this.activeITEM = get[0]
          this.formFichaAcrescimo.get('descItem').setValue(this.activeITEM.descricao)
        }
        break;

      case 'operadora':
        get = this.conteudoMeioOperadoraSaude.filter(value => value.codigo == item)
        this.formFichaAcrescimo.get('descOperadora').setValue(get[0].valor)
        break;

      default:
        break;
    }

  }

  //*************************END FICHA ACRESCIMO  **************************************************/


  async inserir() {
    this.loading = true

    console.log('tablePrecoItem', this.tablePrecoItem)
    console.log('tableRegraImposto', this.tableRegraImposto)
    console.log('tablePrazoPagamento', this.tablePrazoPagamento)
    console.log('tableFichaDesconto', this.tableFichaDesconto)
    console.log('tableFichaAcrescimo', this.tableFichaAcrescimo)
    let registro = this.formFichaGeral.value
    Object.keys(registro).forEach(value => { if (!registro[value]) { delete registro[value] } })
    let result = await this.service.postCAB(registro)
    let codigoEmpresa = this.formFichaGeral.get('codigoEmpresa').value

    this.tablePrecoItem.forEach(value => value.codigoEmpresa = codigoEmpresa)
    this.tableRegraImposto.forEach(value => value.codigoEmpresa = codigoEmpresa)
    this.tablePrazoPagamento.forEach(value => value.codigoEmpresa = codigoEmpresa)
    this.tableFichaDesconto.forEach(value => value.codigoEmpresa = codigoEmpresa)
    this.tableFichaAcrescimo.forEach(value => value.codigoEmpresa = codigoEmpresa)

    if (result) {
      let codigo = result[0].codigoListaDePreco
      if (this.tablePrecoItem.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tablePrecoItem.length; i++) {
          let item: AJ_LISTPRECO_ITM_PRECO = this.tablePrecoItem[i]
          item.codigoListaDePreco = codigo
          console.log(item)
          let response = await this.service.postPreco(item)
          if (response) cont++
        }
        if (cont == this.tablePrecoItem.length) {
          console.log('OK PRECO', cont, this.tablePrecoItem.length)
        }
      }

      // if (this.tableRegraImposto.length > 0) {
      //   let cont = 0
      //   for (let i = 0; i < this.tableRegraImposto.length; i++) {
      //     let item = new AJ_LISTPRECO_ITM_IMPOSTO
      //     let registro = this.tableRegraImposto[i]
      //     item.codigoListaDePreco = codigo
      //     item.regraImposto = registro.codigoImposto.toString()
      //     item.codigoEmpresa = registro.codigoEmpresa
      //     console.log(item)
      //     let response = await this.service.postImposto(item)
      //     if (response) cont++
      //   }
      //   if (cont == this.tableRegraImposto.length) {
      //     console.log('OK IMPOSTO', cont, this.tableRegraImposto.length)
      //   }
      // }

      if (this.tablePrazoPagamento.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tablePrazoPagamento.length; i++) {
          let item: AJ_LISTPRECO_ITM_PRAZO = this.tablePrazoPagamento[i]
          item.codigoListaDePreco = codigo
          delete item.descMeioPagamento
          delete item.codigoPrazoPagamento
          console.log(item)
          let response = await this.service.postPrazo(item)
          if (response) cont++
        }
        if (cont == this.tablePrazoPagamento.length) {
          console.log('OK PRAZO', cont, this.tablePrazoPagamento.length)
        }
      }

      if (this.tableFichaDesconto.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tableFichaDesconto.length; i++) {
          let item: AJ_LISTPRECO_ITM_DESCONTO = this.tableFichaDesconto[i]
          item.codigoListaDePreco = codigo
          delete item.descOperadora
          delete item.descItem
          delete item.descTipoDesconto
          Object.keys(item).forEach(value => { if (!item[value]) delete item[value] })
          console.log(item)
          let response = await this.service.postDesconto(item)
          if (response) cont++
        }
        if (cont == this.tableFichaDesconto.length) {
          console.log('OK DESCONTO', cont, this.tableFichaDesconto.length)
        }
      }

      if (this.tableFichaAcrescimo.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tableFichaAcrescimo.length; i++) {
          let item: AJ_LISTPRECO_ITM_ACRESCIMO = this.tableFichaAcrescimo[i]
          item.codigoListaDePreco = codigo
          delete item.descOperadora
          delete item.descItem
          delete item.descTipoDesconto
          Object.keys(item).forEach(value => { if (!item[value]) delete item[value] })
          console.log(item)
          let response = await this.service.postAcrescimo(item)
          if (response) cont++
        }
        if (cont == this.tableFichaAcrescimo.length) {
          console.log('OK ACRESCIMO', cont, this.tableFichaAcrescimo.length)
        }
      }
      this.getRegistro(this.selectEmpresa)
      this.desabledForms(true)
      this.loading = false
      this._mensagem.creat('Cadastrado com sucesso', { sucesso: true })

    }

  }
  async cancelarRegistro() {
    let id = this.formFichaGeral.controls['codigoListaDePreco'].value
    if (id) {
      let get = await this.service.getCABID(id)
      this.formFichaGeral.patchValue(get)
    }

  }
  async openCadastro(item: AJ_LISTPRECO_CAB) {

    await this.SelectEmpresa(item.codigoEmpresa)
    this.alteracao = true
    this.formFichaGeral.patchValue(item)
    this.formFichaGeral.disable()
    this.tablePrecoItem = await this.service.getPreco(item.codigoEmpresa, item.codigoListaDePreco)
    this.tableRegraImposto = await this.service.getImposto(item.codigoEmpresa, item.codigoListaDePreco)
    this.tablePrazoPagamento = await this.service.getPrazo(item.codigoEmpresa, item.codigoListaDePreco)
    this.tableFichaDesconto = await this.service.getDesconto(item.codigoEmpresa, item.codigoListaDePreco)
    this.tableFichaAcrescimo = await this.service.getAcrescimo(item.codigoEmpresa, item.codigoListaDePreco)
    this.conteudoMeioOperadoraSaude = await this._convenio.get(item.codigoEmpresa)

    this.tablePrazoPagamento.forEach(registro => {
      let descMeioPagamento = this.conteudoMeioPagamento.filter(value => value.codigoReferencia == registro.meioPagamento)
      registro.descMeioPagamento = descMeioPagamento[0].valor
    });
    this.tableFichaAcrescimo.forEach(registro => {
      let descTipoAcrescimo = this.conteudoTipoAcrescimo.filter(value => value.codigoReferencia == registro.tipoAcrescimo)
      registro.descTipoAcrescimo = descTipoAcrescimo[0].valor
    });
    this.tableFichaDesconto.forEach(registro => {
      let descTipoDesconto = this.conteudoTipoDesconto.filter(value => value.codigoReferencia == registro.tipoDesconto)
      registro.descTipoDesconto = descTipoDesconto[0].valor
    });




    await this.getRegraImposto(item.codigoEmpresa)

    let tabelaRegra = []

    await this.tableRegraImposto.forEach(v => {
      let get = this.listRegraImposto.filter(value => value.codigoImposto == parseInt(v.regraImposto))
      if (get.length > 0) tabelaRegra.push(...get)
    })
    // this.tableFichaDesconto.forEach(value=>{
    //   value.descTipoDesconto =   this.conteudoTipoDesconto.filter(value=>value.)
    // })
    this.tablePrecoItem.forEach(item => {
      let get = this.listItens.filter(value => {
        console.log('********', value.codigoSKU, item.codigoSku)
        return value.codigoSKU == item.codigoSku
      })[0]
      if (get) item.descricao = get.descricao

    })

    console.log(this.tablePrecoItem)
    this.dataSourceItem = new MatTableDataSource(this.tablePrecoItem)
    this.dataSourceRegaImposto = new MatTableDataSource(tabelaRegra)
    this.dataSourcePrazoPagamento = new MatTableDataSource(this.tablePrazoPagamento)
    this.dataSourceFichaDesconto = new MatTableDataSource(this.tableFichaDesconto)
    this.dataSourceFichaAcrescimo = new MatTableDataSource(this.tableFichaAcrescimo)

    this.tableFichaDesconto.forEach(async value => {
      if (value.cpfPaciente) {
        value.namePaciente = await this.getNameUser(value.cpfPaciente)
      }
    })
    this.tableFichaAcrescimo.forEach(async value => {
      if (value.cpfPaciente) {
        value.namePaciente = await this.getNameUser(value.cpfPaciente)
      }
    })

    this.viewCadastro = true

  }
  async openNewCadastro() {
    this.viewCadastro = true;
    this.formDeterminarImposto.reset()
    this.formFichaDesconto.reset()
    this.formFichaAcrescimo.reset()
    this.formFichaGeral.reset()
    this.formFichaPreco.reset()
    this.formPrazoPagamento.reset()

    this.formFichaDesconto.get('formaDesconto').setValue('Percentual')
    this.formFichaAcrescimo.get('formaAcrescimo').setValue('Percentual')

    this.dataSourceItem = new MatTableDataSource()
    this.dataSourcePrazoPagamento = new MatTableDataSource()
    this.dataSourceFichaDesconto = new MatTableDataSource()
    this.dataSourceFichaAcrescimo = new MatTableDataSource()
    this.tablePrecoItem = []
    this.tableRegraImposto = []
    this.tablePrazoPagamento = [];
    this.tableFichaDesconto = []
    this.tableFichaAcrescimo = []

    this.alteracao = false
  }
  async alterar() {
    this.loading = true
    let registro = this.formFichaGeral.value
    let response = await this.service.alterCAB(registro)
    if (response) {
      ///////************EXCLUIR REGISTRO
      let listPrecoDelete = await this.service.getPreco(registro.codigoEmpresa, registro.codigoListaDePreco)
      if (listPrecoDelete.length > 0) {
        let cont = 0
        for (let i = 0; i < listPrecoDelete.length; i++) {
          let item: AJ_LISTPRECO_ITM_PRECO = listPrecoDelete[i]
          item.status = "SCA0002"
          if (item.id) {
            let response = await this.service.alterPreco(item)
            if (response) cont++
          } else { cont++ }

        }
        if (cont == listPrecoDelete.length) {
          console.log('OK PRECO', cont, listPrecoDelete.length)
        }
      }

      // if (this.tableRegraImposto.length > 0) {
      //   let cont = 0
      //   for (let i = 0; i < this.tableRegraImposto.length; i++) {
      //     let item = new AJ_LISTPRECO_ITM_IMPOSTO
      //     let registro = this.tableRegraImposto[i]
      //     item.regraImposto = registro.codigoImposto.toString()
      //     item.codigoEmpresa = registro.codigoEmpresa
      //     item.status = "SCA0002"
      //     let response = await this.service.alterImposto(item)
      //     if (response) cont++
      //   }
      //   if (cont == this.tableRegraImposto.length) {
      //     console.log('OK IMPOSTO', cont, this.tableRegraImposto.length)
      //   }
      // }
      let listPrazoPagamentoDelete = await this.service.getPrazo(registro.codigoEmpresa, registro.codigoListaDePreco)

      if (listPrazoPagamentoDelete.length > 0) {
        let cont = 0
        for (let i = 0; i < listPrazoPagamentoDelete.length; i++) {
          let item: AJ_LISTPRECO_ITM_PRAZO = listPrazoPagamentoDelete[i]
          delete item.descMeioPagamento
          delete item.codigoPrazoPagamento
          item.status = "SCA0002"
          if (item.id) {
            let response = await this.service.alterPrazo(item)
            if (response) cont++
          } else {
            cont++
          }
        }
        if (cont == listPrazoPagamentoDelete.length) {
          console.log('OK PRAZO', cont, listPrazoPagamentoDelete.length)
        }
      }

      let listFichaDescontoDelete = await this.service.getDesconto(registro.codigoEmpresa, registro.codigoListaDePreco)

      if (listFichaDescontoDelete.length > 0) {
        let cont = 0
        for (let i = 0; i < listFichaDescontoDelete.length; i++) {
          let item: AJ_LISTPRECO_ITM_DESCONTO = listFichaDescontoDelete[i]
          delete item.descOperadora
          delete item.descItem
          delete item.descTipoDesconto
          Object.keys(item).forEach(value => { if (!item[value]) delete item[value] })
          console.log(item)
          item.status = "SCA0002"
          if (item.id) {
            let response = await this.service.alterDesconto(item)
            if (response) cont++
          } else {
            cont++
          }

        }
        if (cont == listFichaDescontoDelete.length) {
          console.log('OK DESCONTO', cont, listFichaDescontoDelete.length)
        }
      }

      let listFichaAcrescimoDelete = await this.service.getAcrescimo(registro.codigoEmpresa, registro.codigoListaDePreco)

      if (listFichaAcrescimoDelete.length > 0) {
        let cont = 0
        for (let i = 0; i < listFichaAcrescimoDelete.length; i++) {
          let item: AJ_LISTPRECO_ITM_ACRESCIMO = listFichaAcrescimoDelete[i]
          delete item.descOperadora
          delete item.descItem
          delete item.descTipoDesconto
          Object.keys(item).forEach(value => { if (!item[value]) delete item[value] })
          console.log(item)
          item.status = "SCA0002"
          if (item.id) {
            let response = await this.service.alterAcrescimo(item)
            if (response) cont++
          } else {
            cont++
          }
        }
        if (cont == listFichaAcrescimoDelete.length) {
          console.log('OK ACRESCIMO', cont, listFichaAcrescimoDelete.length)
        }
      }
      ////////////********** END EXCLUIR */

      ///////******** ADICIONAR ********/
      const codigo = this.formFichaGeral.get('codigoListaDePreco').value
      if (this.tablePrecoItem.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tablePrecoItem.length; i++) {
          let item: AJ_LISTPRECO_ITM_PRECO = this.tablePrecoItem[i]
          item.codigoListaDePreco = codigo
          item.codigoEmpresa = this.formFichaGeral.get('codigoEmpresa').value
          console.log(item)
          let response = await this.service.postPreco(item)
          if (response) cont++
        }
        if (cont == this.tablePrecoItem.length) {
          console.log('OK PRECO', cont, this.tablePrecoItem.length)
        }
      }

      // if (this.tableRegraImposto.length > 0) {
      //   let cont = 0
      //   for (let i = 0; i < this.tableRegraImposto.length; i++) {
      //     let item = new AJ_LISTPRECO_ITM_IMPOSTO
      //     let registro = this.tableRegraImposto[i]
      //     item.codigoListaDePreco = codigo
      //     item.regraImposto = registro.codigoImposto.toString()
      //     item.codigoEmpresa = registro.codigoEmpresa
      //     console.log(item)
      //     let response = await this.service.postImposto(item)
      //     if (response) cont++
      //   }
      //   if (cont == this.tableRegraImposto.length) {
      //     console.log('OK IMPOSTO', cont, this.tableRegraImposto.length)
      //   }
      // }

      if (this.tablePrazoPagamento.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tablePrazoPagamento.length; i++) {
          let item: AJ_LISTPRECO_ITM_PRAZO = this.tablePrazoPagamento[i]
          item.codigoListaDePreco = codigo
          delete item.descMeioPagamento
          delete item.codigoPrazoPagamento
          item.codigoEmpresa = this.formFichaGeral.get('codigoEmpresa').value
          let response = await this.service.postPrazo(item)
          if (response) cont++
        }
        if (cont == this.tablePrazoPagamento.length) {
          console.log('OK PRAZO', cont, this.tablePrazoPagamento.length)
        }
      }

      if (this.tableFichaDesconto.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tableFichaDesconto.length; i++) {
          let item: AJ_LISTPRECO_ITM_DESCONTO = this.tableFichaDesconto[i]
          item.codigoListaDePreco = codigo
          delete item.descOperadora
          delete item.descItem
          delete item.descTipoDesconto
          item.codigoEmpresa = this.formFichaGeral.get('codigoEmpresa').value
          Object.keys(item).forEach(value => { if (!item[value]) delete item[value] })
          console.log(item)
          let response = await this.service.postDesconto(item)
          if (response) cont++
        }
        if (cont == this.tableFichaDesconto.length) {
          console.log('OK DESCONTO', cont, this.tableFichaDesconto.length)
        }
      }

      if (this.tableFichaAcrescimo.length > 0) {
        let cont = 0
        for (let i = 0; i < this.tableFichaAcrescimo.length; i++) {
          let item: AJ_LISTPRECO_ITM_ACRESCIMO = this.tableFichaAcrescimo[i]
          item.codigoListaDePreco = codigo
          delete item.descOperadora
          delete item.descItem
          delete item.descTipoDesconto
          item.codigoEmpresa = this.formFichaGeral.get('codigoEmpresa').value
          Object.keys(item).forEach(value => { if (!item[value]) delete item[value] })
          console.log(item)
          let response = await this.service.postAcrescimo(item)
          if (response) cont++
        }
        if (cont == this.tableFichaAcrescimo.length) {
          console.log('OK ACRESCIMO', cont, this.tableFichaAcrescimo.length)
        }
      }
      ///////********END ADICIONAR ********/

      this.desabledForms(true)
      this.loading = false
      this._mensagem.creat('Alterado com sucesso', { sucesso: true })
    } else {
      this._mensagem.creat('Algo deu errado', { erro: true })
    }
    this.getRegistro(this.selectEmpresa)
    this.loading = false
  }
  async excluirRegistro() {
    this.loading = true
    let registro = this.formFichaGeral.value
    registro.status = 'SCA0002'
    let result = await this.service.alterCAB(registro)
    if (result) {
      this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
      this.viewCadastro = false
      this.formFichaGeral.reset()
    } else {
      this._mensagem.creat('Erro ao excluir registro ', { erro: true })
    }
    this.loading = false
    this.getRegistro(this.selectEmpresa)
  }


  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }
  async getNameUser(cpf) {
    let get = await this._usuario.getRegistro(cpf)
    if (get) {
      return `${cpf} ${get.nomeCompleto}`
    } else {
      return 'Paciente não encontrado!'
    }
  }
  async getPaciente(tipo) {
    let cpf
    let valid
    switch (tipo) {
      case 'desc':
        this.nomePacienteDesc = null
        cpf = this.formFichaDesconto.get('cpfPaciente').value
        valid = this.formFichaDesconto.get('cpfPaciente').valid
        if (valid) {
          this.nomePacienteDesc = 'Carregando....'
          let get = await this._usuario.getRegistro(cpf)
          if (get) {
            this.nomePacienteDesc = get.nomeCompleto
          } else {
            this.nomePacienteDesc = 'Paciente não encontrado!'
          }
        }
        break;
      case 'acres':
        this.nomePacienteAcres = null
        cpf = this.formFichaAcrescimo.get('cpfPaciente').value
        valid = this.formFichaAcrescimo.get('cpfPaciente').valid
        if (valid) {
          this.nomePacienteAcres = 'Carregando....'
          let get = await this._usuario.getRegistro(cpf)
          if (get) {
            this.nomePacienteAcres = get.nomeCompleto
          } else {
            this.nomePacienteAcres = 'Paciente não encontrado!'
          }
        }
        break;
      case 'prazo':
        this.nomePacientePrazo = null
        cpf = this.formPrazoPagamento.get('cpfPaciente').value
        valid = this.formPrazoPagamento.get('cpfPaciente').valid
        if (valid) {
          this.nomePacientePrazo = 'Carregando....'
          let get = await this._usuario.getRegistro(cpf)
          if (get) {
            this.nomePacientePrazo = get.nomeCompleto
          } else {
            this.nomePacientePrazo = 'Paciente não encontrado!'
          }
        }
        break;
    }
  }
  creatForm() {
    this.formFichaGeral = this.form.group({
      id: '',
      codigoEmpresa: new FormControl(0, Validators.required),
      codigoListaDePreco: 0,
      codigoItem: 0,
      criadoEm: '',
      status: '',
      descricao: new FormControl('', [Validators.required]),
      vigenciaInicio: new FormControl('', [Validators.required]),
      vigenciaFim: new FormControl('', [Validators.required]),
      criadoPor: ''
    })
    this.formFichaPreco = this.form.group({
      id: '',
      codigoEmpresa: 0,
      codigoListaDePreco: 0,
      codigoSku: new FormControl('', Validators.required),
      precoLista: new FormControl('', Validators.required),
      moeda: new FormControl('', Validators.required),
      criadoPor: '',
      criadoEm: '',
      status: ''
    })
    this.formDeterminarImposto = this.form.group({
      id: '',
      codigoEmpresa: 0,
      codigoListaDePreco: 0,
      regraImposto: new FormControl('', Validators.required),
      criadoPor: '',
      criadoEm: '',
      status: ''
    })
    this.formPrazoPagamento = this.form.group({
      id: '',
      codigoListaDePreco: 0,
      codigoEmpresa: 0,
      codigoPrazoPagamento: '',
      codigoOperadora: '',
      descricao: new FormControl('', Validators.required),
      meioPagamento: new FormControl('', Validators.required),
      prazoPagamentoDias: new FormControl('', Validators.required),
      numeroParcelas: 0,
      criadoPor: new FormControl(''),
      criadoEm: '',
      tipoPrazoPag: new FormControl('', Validators.required),
      codigoItem: 0,
      cpfPaciente: '',
      status: '',
    })
    this.formFichaDesconto = this.form.group({
      id: '',
      codigoListaDePreco: 0,
      codigoEmpresa: 0,
      codigoDesconto: 0,
      descricao: new FormControl('', Validators.required),
      descontoPorItem: new FormControl(''),
      codigoItem: new FormControl(),
      descontoPorOperadora: '',
      codigoOperadora: '',
      descontoPorPaciente: 0,

      cpfPaciente: new FormControl(''),
      desconto: new FormControl(0, Validators.required),
      criadoPor: '',
      criadoEm: '',
      status: '',
      tipoDesconto: new FormControl('', Validators.required),
      formaDesconto: 'Percentual',
      descOperadora: '',
      descItem: ''
    })

    this.formFichaAcrescimo = this.form.group({
      id: '',
      codigoListaDePreco: 0,
      codigoEmpresa: 0,
      codigoAcrescimo: 0,
      descricao: new FormControl('', Validators.required),
      tipoAcrescimo: new FormControl('', Validators.required),
      acrescimoPorItem: 0,
      codigoItem: 0,
      acrescimoPorOperadora: 0,
      codigoOperadora: new FormControl(''),
      acrescimoPorPaciente: 0,
      cpfPaciente: new FormControl(''),
      acrescimo: new FormControl(0, Validators.required),
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl(''),
      formaAcrescimo: 'Percentual',
      descOperadora: '',
      descItem: '',
    })

  }
  desabledForms(disable) {
    if (disable) {
      this.formFichaGeral.disable()
      this.formFichaPreco.disable()
      this.formDeterminarImposto.disable()
      this.formPrazoPagamento.disable()
      this.formFichaDesconto.disable()
      this.formFichaAcrescimo.disable()
    } else {
      this.formFichaGeral.enable()
      this.formFichaPreco.enable()
      this.formDeterminarImposto.enable()
      this.formPrazoPagamento.enable()
      this.formFichaDesconto.enable()
      this.formFichaAcrescimo.enable()
    }
  }
  getInfoItem(codigoItem, type) {
    let get
    let result = ''
    switch (type) {
      case 'item':
        get = this.tablePrecoItem.filter(value => value.codigoSku == codigoItem)[0]
        if (get) {
          result = `${get.codigoSku} - ${get.descricao}`
        }
        break
      case 'operadora':
        get = this.conteudoMeioOperadoraSaude.filter(value => value.codigo == codigoItem)[0]
        if (get) {
          result = `${get.codigo} - ${get.razaoSocial}`
        }
        break
      case 'acrescimo':
        break
    }
    return result

  }
  _control(name) {
    return this.formFichaGeral.get(name).hasError('required')
  }
  validTipo(value) {
    if (value == 'TIPPRAZO1' && this.tablePrecoItem.length < 1) {
      return false
    }
    if (value == 'TIPDESC1' && this.tablePrecoItem.length < 1) {
      return false
    }
    if (value == 'TIPACRES1' && this.tablePrecoItem.length < 1) {
      return false
    }
    if (value == 'TIPPRAZO2' && this.noConvenio) {
      return false
    }
    return true
  }
  filtrar(value: string) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }


}

