import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeterminarListaPrecoServiceService {

  url = new environment().api
  apiFichaGeral = "FichaGeralEmpresa/"
  apiFichaContabi = "FichaContabil/"
  apiFichaResponsavel = "FichaResponsavelClinico/"
  apiAreaAtuacao = "AreaAtuacao/"
  apiCadastroItem = "CadastroItem/"
  apiListaDePreco = "AJ_LISTPRECO_CAB/"
  apiFichaGeralEmpresa = "FichaGeralEmpresa/"

  constructor(
    private http: HttpClient
  ) { }

  getListaPreco() {
    return this.http.get(this.url + this.apiListaDePreco)
  }

  postListaPreco(dados: Object) {
    return this.http.post(this.url + this.apiListaDePreco, dados)
  }

  getListaPrecoCodigoEmpresa(codigoEmpresa) {
    return this.http.get(this.url + this.apiListaDePreco + "FiltrarCodigoEmpresa/" + codigoEmpresa)
  }

  getFichaGeralEmpresa() {
    return this.http.get(this.url + this.apiFichaGeralEmpresa)
  }

  postFichaResponsavel(dados: Object) {
    return this.http.post(this.url + this.apiFichaResponsavel, dados)
  }

  postAreaAtuacao(dados: Object) {
    return this.http.post(this.url + this.apiAreaAtuacao, dados)
  }

  getAreaAtuacao() {
    return this.http.get(this.url + this.apiAreaAtuacao)
  }

  postCadastroItem(dados: Object) {
    return this.http.post(this.url + this.apiCadastroItem, dados)
  }

  getCadastroItem() {
    return this.http.get(this.url + this.apiCadastroItem)
  }

  deleteCadastroItem(id: number) {
    return this.http.delete(this.url + this.apiCadastroItem + id);
  }
}
