import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RegraAprovacaoDocumentoService } from 'src/app/service/regra-aprovacao-documento.service';
import { RegraAprovacaoDocumento } from './model';
import { SessaoUser } from 'src/app/user.reg';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { DocumentosService } from 'src/app/service/documentos/documentos.service';
import { RegistroService } from 'src/app/service/registro.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';

const user = new SessaoUser

@Component({
  selector: 'app-regra-aprovacao-documento',
  templateUrl: './regra-aprovacao-documento.component.html',
  styleUrls: ['./regra-aprovacao-documento.component.scss']
})
export class RegraAprovacaoDocumentoComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'tipoDocumento', 'condicaoLimiteValor', 'cpf', 'nivelAprovacao', 'status']
  mask_cpf = "000.000.000-00"
  formulario: FormGroup;
  listNivelAprovacao = []
  listEmpresa = []
  listTipoDocumento = []
  nome: string
  listRegistro: RegraAprovacaoDocumento[];
  dataSource = new MatTableDataSource()
  viewCadastro = false
  alteracao: boolean = false;
  loading: boolean;
  empresaSelec: any
  botaoSalvar = false
  botaoCancelar = false
  viewConfirmDelete: boolean
  selectEmpresa = 0

  constructor(
    private form: FormBuilder,
    private service: RegraAprovacaoDocumentoService,
    private conteudo: ConteudoDeDadosService,
    private usuario: UsuarioService,
    private token: TokenDeAcessoService,
    private mensagem: MensagemService,
    private status: StatusSistemaService,
    private documento: DocumentosService,
    private _filtro: FiltrarDadosService
  ) { }

  async ngOnInit() {
    this.creatForm()
    this.getConteudo()
    this.getEmpresa()
    this.getDocumento()
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  creatForm() {
    this.formulario = this.form.group({
      id: '',
      timestamp: '',
      empresa: '',
      tipoDocumento: '',
      condicaoLimiteValor: 0,
      cpf: new FormControl('', Validators.required),
      nivelAprovacao: '',
      status: '',
      nome: new FormControl({ value: '', disabled: true }),
      codigoEmpresa: 0,
      criadoPor: user.cpf,
      descNivelAprovacao: '',
      descStatus: '',
      nomeEmpresa: ''
    })
  }

  async getConteudo() {
    let get = await this.conteudo.get('NIVAPR')

    this.listNivelAprovacao = get
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getDocumento() {
    let documento = await this.documento.listarDocumentos()
    this.listTipoDocumento = documento.filter(item => item.controleDeAprovacao)
  }

  adicionarDocumento() {
    this.viewCadastro = true
    this.alteracao = false
    this.botaoSalvar = true
    this.nome = null
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getRegistro() {
    this.loading = true

    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async element => {
            let empresa = await this.token.getEmpresasToken(element.codigoEmpresa)
            let getStatus = await this.status.getStatus(false, true, element.status)
            let getConteudo = await this.conteudo.get('NIVAPR', true, element.nivelAprovacao)

            if (empresa.length > 0) {
              element.nomeEmpresa = empresa[0].nomeEmpresa
            }

            element.descStatus = getStatus.toString()
            element.descNivelAprovacao = getConteudo.toString()
          
          })
          this.dataSource.sort = this.sort
          this.loading = false
        }
      )
  }

  inserir() {
    let registro = this.formulario.value

    delete registro.nome
    delete registro.id
    delete registro.timestamp
    delete registro.descNivelAprovacao
    delete registro.descStatus
    delete registro.nomeEmpresa

    registro.criadoPor = user.cpf

    this.service.enviar(registro).toPromise()
      .then(
        res => {
          this.mensagem.creat(res[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  alterar() {
    let registro = this.formulario.value

    if (!registro.id) return

    localStorage.setItem('backup_registro', JSON.stringify(registro))
    delete registro.nome
    delete registro.descNivelAprovacao
    delete registro.descStatus
    // delete registro.condicaoLimiteValor

    this.service.alterar(registro).toPromise()
      .then(
        res => {
          this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  async openCadastro(registro) {
    registro.nome = ''

    this.formulario.setValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.buscarUsuario(registro.cpf, true)
    this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    await this.formulario.setValue(registro)
    await this.buscarUsuario(registro.cpf, true)
    await this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    delete registro.nome
    delete registro.descNivelAprovacao
    delete registro.descStatus
    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
    .finally(() => this.filtrarEmpresa())

  }

  async buscarUsuario(cpf, openCadastro?) {
    let valid = this.formulario.controls['cpf'].valid

    if (valid || openCadastro) {
      let res = await this.usuario.getRegistro(cpf)
      this.nome = res[0].nomeCompleto
    }
    else {
      this.nome = null
    }
  }

  async fecharRegistro(){
		this.viewCadastro = false
		await this.getRegistro()
			.finally(() => this.filtrarEmpresa())
	}

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }
    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }
}
