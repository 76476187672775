import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ValidateBrService } from 'angular-validate-br';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { CoordenadaService } from 'src/app/service/coordenada.service';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { AJ_PLAMEDEMP, FichaGeralEmpresa } from 'src/app/service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { HubDevService } from 'src/app/service/hub-dev.service';
import { ListarMedicosService } from 'src/app/service/listar-medicos.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { timezone } from 'src/app/service/timezones/timezone.model';
import { TimezonesService } from 'src/app/service/timezones/timezones.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { UploadArquivo, UploadArquivosService } from 'src/app/service/upload-arquivos.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser

@Component({
  selector: 'app-definir-empresa-medica',
  templateUrl: './definir-empresa-medica.component.html',
  styleUrls: ['./definir-empresa-medica.component.scss']
})
export class DefinirEmpresaMedicaComponent implements OnInit {

  displayedColumns = ['detalhe', 'nomeEmpresa', 'valor', 'areaAtuacao']
  displayedColumnsPlanoSaude = ['operadora', 'plano', 'menu']
  dataSource = new MatTableDataSource()
  dataSourcePlanoSaude = new MatTableDataSource()
  viewCadastro: boolean
  pessoaJuridica: boolean
  formulario: FormGroup
  registro: FichaGeralEmpresa
  timezones: timezone[];
  areasAtuacaoAns: ConteudoDados[];
  servicosMedicos: ConteudoDados[];
  noMedico: boolean;
  tiposEmpresa: ConteudoDados[];
  matrizFilial: ConteudoDados[];
  moedas: ConteudoDados[];
  list_areaAtuacao = []
  list_servicosMedicos = []
  loading: boolean
  alteracao: boolean;
  excluir: boolean;
  registro_areaAtuacao = [];
  registro_servicosMedicos = [];
  tipoSociedade: ConteudoDados[];
  regimeTributario: ConteudoDados[];
  cnae: ConteudoDados[];
  tipoRegistro: ConteudoDados[];
  menu2 = 1
  loadingOpenCad: boolean;
  viewConfirmDelete: boolean;
  listCertificados = []
  listRemoveCertificado = []
  pathCertificado: any;
  porcentagem: number;
  uploadSuccess: boolean;
  loadingGetMedico: boolean;
  cpfRespClinicoInvalid: boolean;
  noMedicoMensage: string;
  selectTipoRegistro: string;
  crmInvalido: boolean;
  loadingGetRegistro: boolean;
  viewConfirmCancelar: boolean;
  operadoraSaude: ConteudoDados[];
  planoSaude: ConteudoDados[];
  planoSaude_backup: ConteudoDados[];
  viewConfirmFecha: boolean;
  cnae_backup: ConteudoDados[];
  loading_cnae: boolean;
  areasAtuacaoAns_backup: ConteudoDados[];
  viewCampRequired = false
  tablePlanoSaude = []
  operadoraSaude_bakcup: ConteudoDados[];
  codigoEmpresaDoc: string;
  imprimir: boolean;
  constructor(
    private fichaGeralService: FichaGeralService,
    private timezonesService: TimezonesService,
    private _mensagem: MensagemService,
    private _data: BuscarDataService,
    private _hubDev: HubDevService,
    private _usuario: UsuarioService,
    private _token: TokenDeAcessoService,
    private _conteudo: ConteudoDeDadosService,
    private form: FormBuilder,
    private validateBrService: ValidateBrService,
    private _medicos: ListarMedicosService,
    private _upload: UploadArquivosService,
    private _coordenada: CoordenadaService
  ) { }

  async ngOnInit() {
    await this.creatForm()
    this.getRegistro()
    this.timezones = await this.timezonesService.listarTimezones();

    this.getCaracteristicas('ATUMED')
    this.getCaracteristicas('SERMED')
    this.getCaracteristicas('TIPEMP')
    this.getCaracteristicas('EMPFIS')
    this.getCaracteristicas('MOEDAS')
    this.getCaracteristicas('TPSOCMED')
    this.getCaracteristicas('REGTRIB')
    this.getCaracteristicas('CNAE')
    this.getCaracteristicas('TIPREP')
    this.getCaracteristicas('OPESAU')
    this.getCaracteristicas('PLANOMED')
  }
  teste(t?) {
    this.listRemoveCertificado.push(t)
    console.log('emite , ', this.listRemoveCertificado)
  }
  clickCNAE(t?) {
    this.loading_cnae = true
    setTimeout(() => this.loading_cnae = false, 600)
  }
  async getRegistro() {
    this.loading = true
    await this.fichaGeralService.getFichaGeral(sessao.assinante).toPromise()
      .then(res => {
        if (!res.success) return
        let filter = res.result.filter(value => value.status == "SDO0017")
        filter.forEach(async element => {
          if (element.codigoEmpresa) {
            let item = await this.getAreaAtuacao(element.codigoEmpresa)
            if (item.toString() != 'undefined') {
              element.areasAtuacao = item
            }
          }
        });
        let order = filter.sort((a: any, b: any) => {
          return a.codigoEmpresa < b.codigoEmpresa ? -1 : a.codigoEmpresa > b.codigoEmpresa ? 1 : 0
        })
        this.dataSource = new MatTableDataSource(order)
      })
    this.loading = false
  }
  getCEP() {
    if (this._control('cep').invalid) return
    let cep = this._control('cep').value
    this._hubDev.vCEP(cep).toPromise().then(
      (resp) => {
        console.log('cep', resp)
        if (!resp.status) return
        let cep = resp.result
        this._control('rua').setValue(cep.logradouro)
        this._control('bairro').setValue(cep.bairro)
        this._control('cidade').setValue(cep.localidade)
        this._control('estado').setValue(cep.uf)
      }
    )
  }

  async getCaracteristicas(codigo) {
    switch (codigo) {
      case 'ESPPRO':
        this.areasAtuacaoAns = await this._conteudo.get(codigo)
        this.areasAtuacaoAns_backup = await this._conteudo.get(codigo)
        this.areasAtuacaoAns.forEach(element => {
          element.select = false
        });
        this.areasAtuacaoAns_backup.forEach(element => {
          element.select = false
        });
        break;
      case 'SERMED':
        this.servicosMedicos = await this._conteudo.get(codigo)
        this.servicosMedicos.forEach(element => {
          element.select = false
        });
        break;
      case 'TIPEMP':
        this.tiposEmpresa = await this._conteudo.get(codigo)
        break;
      case 'EMPFIS':
        this.matrizFilial = await this._conteudo.get(codigo)
        break;
      case 'MOEDAS':
        this.moedas = await this._conteudo.get(codigo)
        break;
      case 'TPSOCMED':
        this.tipoSociedade = await this._conteudo.get(codigo)
        break;
      case 'REGTRIB':
        this.regimeTributario = await this._conteudo.get(codigo)
        break;
      case 'CNAE':
        this.loading_cnae = true
        this.cnae = await this._conteudo.get(codigo)
        this.cnae_backup = await this._conteudo.get(codigo)
        this.loading_cnae = false
        break;
      case 'TIPREP':
        let get = await this._conteudo.get(codigo)
        this.tipoRegistro = get.filter(value => value.codigoDeNegocio)
        break;
      case 'OPESAU':
        this.operadoraSaude = await this._conteudo.get(codigo)
        this.operadoraSaude_bakcup = await this._conteudo.get(codigo)
        break;
      case 'PLANOMED':
        this.planoSaude = await this._conteudo.get(codigo)
        this.planoSaude_backup = await this._conteudo.get(codigo)
        break;
      default:
        break;
    }
  }
  async getUsuario(autonomo?) {
    this.noMedico = false
    console.log('---')
    if (!autonomo && this._control('cpfRespClinico').invalid) {
      this._control('crmResponsavel').setValue('')
      this._control('nomeResponsavelClinico').setValue('')
      this._control('especialidadeResponsavelClinico').setValue('')
      return
    } else if (this._control('cpfRespClinico').valid) {
      this._control('crmResponsavel').setValue('')
      this._control('nomeResponsavelClinico').setValue('')
      this._control('especialidadeResponsavelClinico').setValue('')
    }

    if (autonomo && this._control('cpfAutonomo').invalid) {
      return
    }

    this.loadingGetRegistro = true
    let cpf = autonomo ? this._control('cpfAutonomo').value : this._control('cpfRespClinico').value

    let response = await this._usuario.getRegistro(cpf)
    console.log(response)
    if (autonomo) {
      if (response.id) {
        this._control('nomeAutonomo').setValue(response.nomeCompleto)
      }
      this.loadingGetRegistro = false
      return
    }
    if (response.id) {
      this._control('nomeResponsavelClinico').setValue(response.nomeCompleto)
      this.noMedico = false
    }
    else {
      this.noMedico = true
      this.noMedicoMensage = 'CPF não encontrado'
      this._control('nomeResponsavelClinico').setValue('')
    }


    this.getMedico('TIPREP1')

    this.loadingGetRegistro = false
  }

  async getMedico(type?: string) {
    this.selectTipoRegistro = type
    this.loadingGetMedico = true
    if (this._control('cpfRespClinico').invalid) {
      this._control('crmResponsavel').setValue('')
      this._control('nomeResponsavelClinico').setValue('')
      this._control('especialidadeResponsavelClinico').setValue('')
      return
    }

    let cpf = this._control('cpfRespClinico').value
    this.crmInvalido = false
    let response = await this._medicos.getMedico(cpf)
    console.log('medico', response)
    if (response.id) {
      let dadosMedico = response
      if (dadosMedico.profissao == 'CADPRO1' || dadosMedico.profissao == 'CADPRO31') {
        this.noMedico = false
        this._control('crmResponsavel').setValue(dadosMedico.nr_Registro)
        this._control('nomeResponsavelClinico').setValue(dadosMedico.nomeCompleto)
        this._control('especialidadeResponsavelClinico').setValue(dadosMedico.valorEspecialidade)
        this._control('tipoRegistro').setValue(dadosMedico.tipoInscricao)
        this._control('tipoRegistro').disable()
        this.noMedico = false
        if (dadosMedico.profissao == 'CADPRO1') {
          let validCRM = await this._medicos.ValidarCRM(dadosMedico.nr_Registro, dadosMedico.unidadeFederacao, dadosMedico.cpf, dadosMedico.dataNascimento)
          if (!validCRM.result.resultadoConsulta) {
            this._mensagem.creat('Registro profissional inválido', { erro: true })
            this.crmInvalido = true
          }
          else {
            this._mensagem.creat('CRM Valido', { sucesso: true })
            this.crmInvalido = false
          }
        }

      } else {
        this.noMedico = true
        this.noMedicoMensage = 'CPF informado não é um médico'
        this._mensagem.creat('CPF informado não é um médico', { erro: true })
        this._control('crmResponsavel').setValue('')
        this._control('nomeResponsavelClinico').setValue('')
        this._control('especialidadeResponsavelClinico').setValue('')
      }
    } else {
      this._control('crmResponsavel').setValue('')
      this._control('nomeResponsavelClinico').setValue('')
      this._control('especialidadeResponsavelClinico').setValue('')
      this.noMedico = true
      this.noMedicoMensage = 'CPF informado não é um médico'
    }

    this.loadingGetMedico = false

  }
  async getAreaAtuacao(cod) {
    let response = []
    await this.fichaGeralService.getAreaAtuacao(cod).toPromise()
      .then((res: any) => {
        if (res.success) {
          let get = res.result.filter(x => x.status == 'SCA0001').map(value => value.valor)
          for (let index = 0; index < 2; index++) {
            if (get[index]) {
              response.push(get[index]);
            }
          }
        } else {
          response = []
        }
      }).catch(error => { console.log(error), response = [] })
    return response
  }
  async openNewCadastro() {
    this.loading = false
    this.viewCadastro = true;
    this.viewCampRequired = false
    this.formulario.reset()
    await this.getCaracteristicas('ESPPRO')
    await this.getCaracteristicas('SERMED')
    this._control('pessoaJuridica').setValue(false)
  }
  async openCadastro(registro) {
    this.viewCampRequired = false
    await this.formulario.patchValue(registro)
    if (this._control('cpfAutonomo').value) {
      await this.getUsuario(true)
    }

    this.codigoEmpresaDoc = registro.codigoEmpresa.toString()
    await this.getUsuario()
    this.loadingOpenCad = true
    this.excluir = false
    this.alteracao = true
    this.list_areaAtuacao = []
    this.list_servicosMedicos = []
    this.registro_areaAtuacao = []
    this.registro_servicosMedicos = []
    await this.getCaracteristicas('ESPPRO')
    await this.getCaracteristicas('SERMED')




    await this.fichaGeralService.getAreaAtuacao(registro.codigoEmpresa).toPromise()
      .then((res: any) => {
        if (!res.success) return

        res.result.forEach(async item => {
          if (item.status != 'SCA0001') return


          this.registro_areaAtuacao.push(item)

          this.list_areaAtuacao.push(item.areaDeAtuacaoAns)
          this.areasAtuacaoAns.forEach(element => {
            if (element.codigoReferencia == item.areaDeAtuacaoAns) {
              element.select = true
            }
          });
        });
      })
      .catch(error => { console.log(error) })

    await this.fichaGeralService.getServicosMedicos().toPromise()
      .then(
        (res: Array<any>) => {
          if (!res) return
          res = res.filter(value => value.codigoEmpresa == registro.codigoEmpresa)
          res.forEach(async item => {

            item.select = false

            if (item.status != 'SCA0001') return

            this.list_servicosMedicos.push(item.nomeServico)

            this.registro_servicosMedicos.push(item)

            await this.servicosMedicos.forEach(element => {
              if (element.codigoReferencia == item.nomeServico) {
                element.select = true
              }
            });
          });
        }).catch(error => { console.log('erro servico', error) })
    let planosSaude = await this.fichaGeralService.getPlanoSaude(registro.codigoEmpresa)
    if (planosSaude.length > 0) {
      this.tablePlanoSaude = []
      for (let i = 0; i < planosSaude.length; i++) {
        let item = planosSaude[i]
        let descPlano = await this._conteudo.get('PLANOMED', true, item.plano)
        let descOperadora = await this._conteudo.get('OPESAU', true, item.operadoraSaude)
        item.descPlano = descPlano.toString()
        item.descOperadora = descOperadora.toString()
        item.id = i
        this.tablePlanoSaude.push(item)
      }

      this.dataSourcePlanoSaude = new MatTableDataSource(this.tablePlanoSaude)
    }


    this.formulario.disable()
    this.viewCadastro = true
    this.loadingOpenCad = false


  }
  async creatForm() {
    this.formulario = this.form.group({
      id: new FormControl(''),//remover 
      codigoEmpresa: new FormControl(''),//remover 
      nomeEmpresa: new FormControl('', Validators.required),
      numeroDocumento: new FormControl(''),
      tipoEmpresa: new FormControl('', Validators.required),
      webSiteEmpresa: new FormControl(''),
      cabecalhoImpressao: new FormControl('', Validators.required),
      telefoneEmpresa: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      observacao: new FormControl(''),
      cpfRespClinico: new FormControl('', [Validators.required, this.validateBrService.cpf]),
      cnpj: new FormControl('', [this.validateBrService.cnpj, Validators.required]),
      cpfAutonomo: new FormControl('', [this.validateBrService.cpf, Validators.required]),
      nrDepRPA: new FormControl(''),
      razaoSocial: new FormControl(''),
      matriz: new FormControl('', Validators.required),
      cnpjMatriz: new FormControl(''),
      codigoEmpresaMatriz: new FormControl(''),
      moedaEmpresa: new FormControl('', Validators.required),
      cep: new FormControl('', Validators.required),
      rua: new FormControl('', Validators.required),
      numeroEndereco: new FormControl('', Validators.required),
      bairro: new FormControl('', Validators.required),
      cidade: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      fusoHorario: new FormControl('', Validators.required),
      nomeFantasia: new FormControl(''),
      classificacaoEmpresa: new FormControl(''),
      codigoAssinante: new FormControl(''),
      pessoaJuridica: false,
      regimeTributario: new FormControl(''),
      tipoSociedadeMedica: new FormControl(''),
      cnae: new FormControl(''),
      inscricaoMunicipal: new FormControl(''),
      inscricaoEstadual: new FormControl(''),
      coordenadaGps: '',
      criadoPor: new FormControl(''),
      status: new FormControl(''),
      criadoEm: new FormControl(''),
      crmResponsavel: '', //remover 
      nomeAutonomo: '', //remover 
      especialidadeResponsavelClinico: '', //remover 
      nomeResponsavelClinico: '', //remover 
      plano: '', //remover 
      operadora: '', //remover 
      tipoRegistro: new FormControl({ disabled: true }), //remover 
    })

  }
  removeCampos() {
    this.formulario.removeControl('id')
    this.formulario.removeControl('codigoEmpresa')
    this.formulario.removeControl('crmResponsavel')
    this.formulario.removeControl('nomeAutonomo')
    this.formulario.removeControl('especialidadeResponsavelClinico')
    this.formulario.removeControl('nomeResponsavelClinico')
    this.formulario.removeControl('plano')
    this.formulario.removeControl('operadora')
    this.formulario.removeControl('tipoRegistro')
  }
  verificarCamposValidos() {
    const invalid = [];
    const controls = this.formulario.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  getForm(control: string) {
    return this.formulario.get(control)
  }




  insertList(lista, checked, item) {
    switch (lista) {

      case 'areaAtuacao':
        if (checked) {
          this.list_areaAtuacao.push(item)
        } else {
          this.list_areaAtuacao.filter((value, index) => {
            if (value == item) {
              this.list_areaAtuacao.splice(index, 1)
            }
          })
        }
        break;

      case 'servicosMedicos':
        if (checked) {
          this.list_servicosMedicos.push(item)
        } else {
          this.list_servicosMedicos.filter((value, index) => {
            if (value == item) {
              this.list_servicosMedicos.splice(index, 1)
            }
          })
        }
        break;

      default:
        break;
    }

  }

  async inserir() {
    let endereco = `${this._control('rua').value} ${this._control('bairro').value}  ${this._control('cidade').value} - ${this._control('estado').value}`
    let coordenada = await this._coordenada.getEndereco(endereco, true)
    console.log('coordenada', coordenada, endereco)

    this.formulario.get('codigoAssinante').setValue(sessao.assinante)
    this.formulario.get('classificacaoEmpresa').setValue('CLAEMP1')
    if (!this._control('pessoaJuridica').value) {
      this._control('cnpj').clearValidators()
      this._control('matriz').clearValidators()
      this._control('cnpj').updateValueAndValidity()
      this._control('matriz').updateValueAndValidity()
    } else {
      this._control('cpfAutonomo').clearValidators()
      this._control('cpfAutonomo').updateValueAndValidity()
    }
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      let verific = this.verificarCamposValidos()
      console.log(this._control('pessoaJuridica').value, verific)
      return
    }
    if (this.crmInvalido) {
      this._mensagem.creat('CRM do responsável clínico inválido', { erro: true })
      return
    }
    this.loading = true



    let registro: FichaGeralEmpresa = this.formulario.value
    registro.status = 'SDO0017'
    registro.criadoPor = sessao.cpf
    registro.codigoAssinante = sessao.assinante


    registro.coordenadaGps = coordenada

    Object.keys(registro).forEach(key => { if (!registro[key]) delete registro[key] })
    await this.fichaGeralService.postFichaGeralEmpresa(registro).toPromise()
      .then(async res => {
        let codigoEmpresa
        try {
          codigoEmpresa = res[0].codigoEmpresa
        } catch {
          this._mensagem.creat('Erro ao gravar registro', { erro: true })
          this.loading = false
          return
        }
        let responseA = await this._token.criarToken(codigoEmpresa, 'SDO0100', 'TPHABI1', sessao.cpf)
        if (!responseA) {
          this._mensagem.creat('Falha ao gerar token , por favor contatar o suporte', { erro: true })
          this.loading = false
          return
        }
        let responseB = this._token.criarToken(codigoEmpresa, 'SDO0100', 'TPHABI4', registro.cpfRespClinico)
        if (!responseB) {
          this._mensagem.creat('Falha ao gerar token para o respónsavel clínico, por favor contatar o suporte', { erro: true })
          this.loading = false
          return
        }
        if (this.list_areaAtuacao.length > 0) {
          for (let i = 0; i < this.list_areaAtuacao.length; i++) {
            let item = {
              codigoEmpresa: 0,
              areaDeAtuacaoAns: '',
              criadoPor: '',
              status: '',
            }
            item.codigoEmpresa = codigoEmpresa
            item.areaDeAtuacaoAns = this.list_areaAtuacao[i]
            item.criadoPor = sessao.cpf
            item.status = "SCA0001"
            await this.fichaGeralService.postAreaAtuacao(item).toPromise().then()
          }
          for (let i = 0; i < this.list_servicosMedicos.length; i++) {
            let item = {
              codigoEmpresa: 0,
              nomeServico: '',
              criadoPor: '',
              status: '',
            }
            item.codigoEmpresa = codigoEmpresa
            item.nomeServico = this.list_servicosMedicos[i]
            item.criadoPor = sessao.cpf
            item.status = "SCA0001"
            await this.fichaGeralService.postServicosMedicos(item).toPromise().then()
          }
          if (this.listCertificados.length > 0) {
            for (let i = 0; i < this.listCertificados.length; i++) {
              const element = this.listCertificados[i];
              this.inserirCertificado(element, codigoEmpresa)
            }
            setTimeout(() => {
              this.loading = false
              this.viewCadastro = false
              this.getRegistro()
            }, this.listCertificados.length * 1000)
          }
          if (this.tablePlanoSaude.length > 0) {
            let cont = 0
            for (let i = 0; i < this.tablePlanoSaude.length; i++) {
              let item = this.tablePlanoSaude[i]
              item.codigoEmpresa = registro.codigoEmpresa
              let planoMedico = new AJ_PLAMEDEMP
              planoMedico.codigoEmpresa = registro.codigoEmpresa
              planoMedico.operadoraSaude = item.operadoraSaude
              planoMedico.plano = item.plano
              delete planoMedico.id
              item.codigoEmpresa = codigoEmpresa
              let response = await this.fichaGeralService.postPlanoSaude(item)
              if (response) {
                cont++
              }
            }
            if (cont < this.tablePlanoSaude.length) {
              setTimeout(() => {
                this._mensagem.creat('Planos de Saúde não foram gravados corretamente', { erro: true })
              }, 2000)
            }
          }


        }
        this._mensagem.creat('Cadastro com sucesso!', { sucesso: true })
        this.formulario.disable()
        this.excluir = false
        this.alteracao = true

      }).catch(error => {
        console.log('erro ao gravar', error)
        this._mensagem.creat('Não foi possível cadastrar empresa', { erro: true })
        this.loading = false
      })
    this.loading = false
    this.getRegistro()

  }
  inserirCertificado(arquivo, codigoEmpresa) {
    let doc = new UploadArquivo
    doc.path = this.pathCertificado
    doc.tipoAnexo = 'TIPANE12'
    doc.tipoDocumento = 'TIPANE12'
    doc.nrDocumento = codigoEmpresa
    doc.criadoPor = sessao.cpf


    doc.nomeArquivo = arquivo.name

    let file = new FormData()
    file.append('arquivo', arquivo)
    this._upload.upload(file, doc).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.porcentagem += Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
        }
      }
    )
  }
  removerCertificado(arquivo) {
    if (!arquivo) return
    this._upload.removeFile(arquivo).toPromise().then(res => { console.log('file delete') })
  }
  async alterar() {
    let endereco = `${this._control('rua').value} ${this._control('bairro').value}  ${this._control('cidade').value} - ${this._control('estado').value}`
    let coordenada = await this._coordenada.getEndereco(endereco, true)
    this._control('coordenadaGps').setValue(coordenada)
    if (!this._control('pessoaJuridica').value) {
      this._control('cnpj').clearValidators()
      this._control('matriz').clearValidators()
      this._control('cnpj').updateValueAndValidity()
      this._control('matriz').updateValueAndValidity()
    } else {
      this._control('cpfAutonomo').clearValidators()
      this._control('cpfAutonomo').updateValueAndValidity()
    }
    if (this.formulario.invalid) {
      let verific = this.verificarCamposValidos()
      console.log(verific)
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      return
    }
    this.loading = true
    let registro: FichaGeralEmpresa = this.formulario.value
    // console.log("Alterar registro", registro)


    this.fichaGeralService.AlterarFichaGeralEmpresa(registro.id, sessao.sessao_id, this._data.hoje(), registro).toPromise()
      .then(async res => {
        // console.log('alterado!', res)

        let token = await this._token.getToken(registro.cpfRespClinico, registro.codigoEmpresa)
        console.log('------------TOKEN 1------------', token)
        if (!token) {
          let response = await this._token.criarToken(registro.codigoEmpresa, 'SDO0100', 'TPHABI4', registro.cpfRespClinico)
          console.log('------------TOKEN------------', token, response)
          if (!response) {
            this._mensagem.creat('Falha ao gerar token para o respónsavel clínico, por favor contatar o suporte', { erro: true })
            this.loading = false
            return
          }
        }
        if (this.registro_areaAtuacao.length > 0) {

          for (let index = 0; index < this.registro_areaAtuacao.length; index++) {
            let value = this.registro_areaAtuacao[index]
            try {
              value.status = 'SCA0002'
              delete value.valor
              this.fichaGeralService.excluirAreaAtuacao(value).toPromise().then()
            } catch {
              console.log('erro! value', value)
            }

          }

          for (let index = 0; index < this.list_areaAtuacao.length; index++) {
            let item = this.list_areaAtuacao[index]
            let novo_registro = {
              codigoEmpresa: 0,
              areaDeAtuacaoAns: '',
              criadoPor: '',
              status: '',
            }
            novo_registro.codigoEmpresa = registro.codigoEmpresa
            novo_registro.areaDeAtuacaoAns = item
            novo_registro.criadoPor = sessao.cpf
            novo_registro.status = 'SCA0001'
            // console.log('adicionar >', registro)
            await this.fichaGeralService.postAreaAtuacao(novo_registro).toPromise().then()

          }

        }
        else {
          for (let index = 0; index < this.list_areaAtuacao.length; index++) {
            let item = this.list_areaAtuacao[index]
            let novo_registro = {
              codigoEmpresa: 0,
              areaDeAtuacaoAns: '',
              criadoPor: '',
              status: '',
            }
            novo_registro.codigoEmpresa = registro.codigoEmpresa
            novo_registro.areaDeAtuacaoAns = item
            novo_registro.criadoPor = sessao.cpf
            novo_registro.status = 'SCA0001'
            // console.log('adicionar >', registro)
            await this.fichaGeralService.postAreaAtuacao(novo_registro).toPromise().then()

          }
        }

        if (this.registro_servicosMedicos.length > 0) {

          for (let index = 0; index < this.registro_servicosMedicos.length; index++) {
            let value = this.registro_servicosMedicos[index]

            delete value.valor
            value.status = "SCA0002"
            try {
              await this.fichaGeralService.excluirServicosMedicos(value).toPromise().then()
                .catch(error => console.log('ERROR  ALTERAR ', error, value))
            } catch { }


          }

          for (let index = 0; index < this.list_servicosMedicos.length; index++) {
            let item = this.list_servicosMedicos[index]
            let novo_registro = {
              codigoEmpresa: registro.codigoEmpresa,
              nomeServico: '',
              criadoPor: '',
              status: '',
            }
            novo_registro.nomeServico = item
            novo_registro.criadoPor = sessao.cpf
            novo_registro.status = 'SCA0001'

            console.log('olha:>', novo_registro)
            await this.fichaGeralService.postServicosMedicos(novo_registro).toPromise().then()
              .catch(error => console.log('ERROR  INSERIR 1-', error, novo_registro))

          }

        } else {
          for (let index = 0; index < this.list_servicosMedicos.length; index++) {
            let item = this.list_servicosMedicos[index]
            let novo_registro = {
              codigoEmpresa: registro.codigoEmpresa,
              nomeServico: '',
              criadoPor: '',
              status: '',
            }
            novo_registro.nomeServico = item
            novo_registro.criadoPor = sessao.cpf
            novo_registro.status = 'SCA0001'
            this.fichaGeralService.postServicosMedicos(novo_registro).toPromise().then()
              .catch(error => console.log('ERROR   INSERIR 2 -', error, novo_registro))


          }
        }


        if (this.listRemoveCertificado.length > 0) {
          for (let i = 0; i < this.listRemoveCertificado.length; i++) {
            const element = this.listRemoveCertificado[i];
            this.removerCertificado(element)
          }
          this.listRemoveCertificado = []
        }

        if (this.listCertificados.length > 0) {

          for (let i = 0; i < this.listCertificados.length; i++) {
            const element = this.listCertificados[i];
            this.inserirCertificado(element, registro.codigoEmpresa)
          }
          this.uploadSuccess = true
          setTimeout(() => {
            this.loading = false
            this.alteracao = true
            if (this.menu2 == 7) {
              this.menu2 = null
              setTimeout(() => this.menu2 = 7, 200)
            }
            this.formulario.disable()

            this._mensagem.creat('Documento Alterado com sucesso', { sucesso: true })
            this.listCertificados = []

          }, this.listCertificados.length * 1000)
        } else {

          setTimeout(() => this.loading = false, 1000)
          this.alteracao = true
          this.formulario.disable()
          this.getRegistro()
          this._mensagem.creat('Documento Alterado com sucesso', { sucesso: true })
        }
        let validPlanoSaude = this.fichaGeralService.disabledPlanoSaude(registro.codigoEmpresa)
        if (validPlanoSaude) {
          if (this.tablePlanoSaude.length > 0) {
            let cont = 0
            for (let i = 0; i < this.tablePlanoSaude.length; i++) {
              let item = this.tablePlanoSaude[i]
              item.codigoEmpresa = registro.codigoEmpresa
              let planoMedico = new AJ_PLAMEDEMP
              planoMedico.codigoEmpresa = registro.codigoEmpresa
              planoMedico.operadoraSaude = item.operadoraSaude
              planoMedico.plano = item.plano
              delete planoMedico.id
              let response = this.fichaGeralService.postPlanoSaude(planoMedico)
              if (response) cont++
            }
            if (cont == this.tablePlanoSaude.length) {
              console.log('PLANO DE SAUDE CADASTRADO COM SUCESSO!', cont, this.tablePlanoSaude)
            } else {
              console.log('PLANO DE SAUDE ERRO!', cont, this.tablePlanoSaude)
            }
          }
        }

      }).catch(error => {
        console.log('erro!', Object.keys(error.error).toString())
        // this._mensagem.creat('Erro ao realizar alteração ', { erro: true })
        this._mensagem.creat('Não foi possivel realizar alteração', { erro: true })
        this.loading = false
      })
    // console.log('formulario : ', this.formulario.value)
    // console.log('list_areaAtuacao : ', this.list_areaAtuacao)
    // console.log('list_servicosMedicos : ', this.list_servicosMedicos)
  }
  async cancelarRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmCancelar = true
      return
    }
    const id = this.formulario.value.id
    let registro = await this.fichaGeralService.reset(id)
    this.openCadastro(registro)
    console.log('formulario', this.formulario.value)
    this.excluir = false
    this.viewConfirmCancelar = false

  }
  async addPlanoSaude(all?) {
    let operadora = this._control('operadora').value
    let plano = this._control('plano').value

    if (all) {
      if (!operadora) {
        this._mensagem.creat('Informe uma operadora e plano de saúde', { erro: true })
        return
      }
      if (this.planoSaude.length > 0) {
        this.tablePlanoSaude.forEach((value, i) => {
          if (value.operadoraSaude == operadora) {
            delete this.tablePlanoSaude[i]
          }
        })

        for (let i = 0; i < this.planoSaude.length; i++) {
          let item = this.planoSaude[i]

          let descOperadora = await this._conteudo.get('OPESAU', true, operadora)
          let descPlano = await this._conteudo.get('PLANOMED', true, item.codigoReferencia)
          this.tablePlanoSaude.push(
            {
              id: this.tablePlanoSaude.length,
              plano: item.codigoReferencia,
              operadoraSaude: item.dependencia,
              descPlano: descPlano.toString(),
              descOperadora: descOperadora.toString()
            })
        }
      }
    } else {
      if (!operadora || !plano) {
        this._mensagem.creat('Informe uma operadora e plano de saúde', { erro: true })
        return
      }
      let descOperadora = await this._conteudo.get('OPESAU', true, operadora)
      let descPlano = await this._conteudo.get('PLANOMED', true, plano)

      this.tablePlanoSaude.forEach((value, i) => {
        if (value.plano == plano && value.operadoraSaude == operadora) {
          delete this.tablePlanoSaude[i]
        }
      })

      this.tablePlanoSaude.push(
        {
          id: this.tablePlanoSaude.length,
          plano: plano,
          operadoraSaude: operadora,
          descPlano: descPlano.toString(),
          descOperadora: descOperadora.toString()
        })

    }



    this.tablePlanoSaude = this.tablePlanoSaude.filter(value => value)
    this.dataSourcePlanoSaude = new MatTableDataSource(this.tablePlanoSaude)
    this._control('operadora').reset()
    this._control('plano').reset()
  }
  removePlanoSaude(id) {
    if (this.tablePlanoSaude.length == 1) {
      this.tablePlanoSaude = []
      this.dataSourcePlanoSaude = new MatTableDataSource()
      return
    }
    this.tablePlanoSaude.forEach((value, i) => { if (value.id == id) { delete this.tablePlanoSaude[i] } })
    this.tablePlanoSaude = this.tablePlanoSaude.filter(value => value)
    console.log('deletado', this.tablePlanoSaude)
    this.dataSourcePlanoSaude = new MatTableDataSource(this.tablePlanoSaude)

  }
  excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    this.loading = true
    let registro = this.formulario.value
    registro.status = "SDO0063"
    this.fichaGeralService.AlterarFichaGeralEmpresa(registro.id, sessao.sessao_id, this._data.hoje(), registro).toPromise()
      .then(res => {
        this.loading = false
        this.viewCadastro = false;
        this.getRegistro()
        this.fecharCadastro()
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
  }
  selectOperadoraSaude(item) {
    let filter = this.planoSaude_backup.filter(value => value.dependencia == item)
    this.planoSaude = filter
  }
  filterCNAE(value: string) {
    let registros = this.cnae_backup
    let result = registros.filter(item => item.codigoDeNegocio.includes(value))
    if (result.length < 1) {
      result = registros.filter(item => item.valor.toUpperCase().includes(value.toUpperCase()))
    }
    this.cnae = result
  }
  filterPlanoSaude(value: string) {
    let registros = this.operadoraSaude_bakcup
    let result = registros.filter(item => item.valor.toUpperCase().includes(value.toUpperCase()))
    this.operadoraSaude = result
  }
  filterANS(value: string) {
    let registros = this.areasAtuacaoAns_backup
    let result = registros.filter(item => {
      item.select = false;
      return item.valor.toUpperCase().includes(value.toUpperCase())
    })

    if (this.list_areaAtuacao.length > 0) {
      this.list_areaAtuacao.forEach(value => {
        this.areasAtuacaoAns_backup.filter(item => {
          if (item.codigoReferencia == value) {
            item.select = true
          }
        })
      })
    }

    this.areasAtuacaoAns = result

  }
  async ValidarCnpj() {
    if (this._control('cnpj').invalid) {

      this._control('nomeEmpresa').setValue('')
      this._control('cep').setValue('')
      return
    }
    let cnpj = this._control('cnpj').value
    this._hubDev.vCNPJ(cnpj).subscribe(({ status, result: empresa }) => {
      if (!status) {
        throw this._mensagem.creat("Cnpj não encontrado, verifique se foi digitado corretamente.", { erro: true })
      }
      else {
        this._control('nomeEmpresa').setValue(empresa.nome)
        if (empresa.fantasia) {
          this._control('nomeFantasia').setValue(empresa.fantasia)
        }
        this._control('cep').setValue(empresa.cep)
        this._control('numeroEndereco').setValue(empresa.numero)
        setTimeout(() => this.getCEP(), 100)
      }
    })
  }
  async imprimirEmpresa() {
    this.imprimir = true

    setTimeout(() => window.print(), 200)
    // setTimeout(()=>   this.imprimir = false,1000)


  }
  resetFichaContabil() {
    this._control('cnpj').reset()
    this._control('nomeEmpresa').reset()
    this._control('nomeEmpresa').reset()
    this._control('tipoSociedadeMedica').reset()
    this._control('cep').reset()
    this._control('rua').reset()
    this._control('numeroEndereco').reset()
    this._control('bairro').reset()
    this._control('cidade').reset()
    this._control('estado').reset()
    this._control('matriz').reset()
    this._control('cnpjMatriz').reset()
    this._control('moedaEmpresa').reset()
    this._control('observacao').reset()
  }
  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }
  fecharCadastro(confirm?) {
    if (!this._control('id').value && confirm) {
      let keys = this.formulario.getRawValue()
      let verific = Object.keys(keys).filter(value => keys[value])
      if (verific.length > 0) {
        this.viewConfirmFecha = true
        return
      }
    }
    this.formulario.reset()
    this.formulario.enable()
    this._control('pessoaJuridica').setValue(true)
    this._control('codigoAssinante').setValue(0)
    this.alteracao = false
    this.excluir = false
    this.list_areaAtuacao = []
    this.list_servicosMedicos = []
    this.getCaracteristicas('ATUMED')
    this.getCaracteristicas('SERMED')
    this.viewCadastro = false
    this.viewConfirmFecha = false

  }
  _control(formControlName) {
    return this.formulario.controls[formControlName]
  }
  _required(formControlName) {
    return this.formulario.controls[formControlName].hasError('required')
  }
  _valid(formControlName) {
    return this.formulario.get(formControlName).valid
  }
}