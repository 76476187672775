import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SalaEsperaService } from '../../service/sala-espera.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SalaEsperaForm, ConsultaForm } from './model';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { UsuarioService } from '../../service/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { async } from '@angular/core/testing';
import { FormUsuario } from 'src/app/usuario/model';
import { AtendimentoClinicoService } from 'src/app/service/atendimento-clinico.service';
import { Observable, zip, timer, range } from 'rxjs';
import { CronometroService } from 'src/app/service/cronometro.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConversasService } from 'src/app/service/conversas.service';
import { VideoChamadaService } from 'src/app/service/video-chamada.service';
import { formFichaConsultaMedica } from '../model';
import { FichaConsultaMedicaService } from 'src/app/service/ficha-consulta-medica/ficha-consulta-medica.service';
import { SessaoUser } from 'src/app/user.reg';
import { MensagemService } from 'src/app/service/mensagem.service';
import { AgendaService } from 'src/app/service/agenda.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { TicketConsulta, TicketConsultaJOIN, TicketReserva } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
const sessao = new SessaoUser
const url = new environment().api
@Component({
  selector: 'app-sala-espera',
  templateUrl: './sala-espera.component.html',
  styleUrls: ['./sala-espera.component.scss']
})
export class SalaEsperaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() usuarioId: string
  @Output('popUp') popUp = new EventEmitter()
  displayedColumns: string[] = ['ordem', 'paciente', 'consultorio', 'medico', 'especialidade', 'motivoConsulta', 'prioridade', 'situacaoPaciente', 'temp', 'detalhe'];
  displayedColumnsHistorico: string[] = ['date', 'ordem', 'paciente', 'consultorio', 'medico', 'especialidade', 'motivoConsulta', 'acao'];
  dataSourceLog
  id
  companyId
  imgPerfil = './assets/imgUser.png'
  chamarPaciente: TicketConsultaJOIN;
  cpf_medico = sessionStorage.getItem('cpf')
  registroSala: TicketConsultaJOIN[] = [];
  registroConsultas: ConsultaForm[];
  diagnostico: any
  idPaciente
  tituloTabela;
  erro: boolean
  erroMensagem: string
  loading: boolean
  infoSalaEspera: TicketConsultaJOIN;
  timerValue: { h: number; m: number; s: number; };
  dataSource: MatTableDataSource<SalaEsperaForm>;
  posicaoFila: number
  excluir: boolean
  viewDesistencia: boolean;
  viewNotaCredito: boolean;
  viewProntuario: boolean;
  viewProntuarioVidaPaciente: boolean;
  viewPrintNotaCredito: boolean;
  typeCredito
  userName
  avCliAnamnese
  pesquisar = new FormControl()
  result: Observable<any>
  loadingHistorico: boolean
  loadingHistoricoH: boolean
  rangeDates: any[];
  viewSalaEspera: boolean;
  mySala = true
  viewFichaConsulta: boolean;
  viewAgenda: boolean;
  ticketReserva: TicketReserva;
  constructor(
    private dataPipe: DatePipe,
    private form: FormBuilder,
    private service: SalaEsperaService,
    private consulta: FichaConsultaMedicaService,
    private serviceUsuario: UsuarioService,
    private serviceAtendimento: AtendimentoClinicoService,
    private mensagem: ToastrService,
    private _mensagem: MensagemService,
    private url: ActivatedRoute,
    private nav: Router,
    private _cronometro: CronometroService,
    private data: BuscarDataService,
    private router: Router,
    private _videoChamada: VideoChamadaService,
    private agenda: AgendaService,
    private http: HttpClient,
    private empresa: TokenDeAcessoService,
    private _filter: FiltrarDadosService
  ) {

    this.urlID()
  }

  async ngOnInit() {
    this.pesquisar.valueChanges.pipe(
      map(value => value.trim()),
      filter(value => value.length > 1),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(() => this.http.get(url + 'SalaEspera'))
    ).subscribe((res: any) => {
      res.map(value => value.paciente)
      console.log(res)
    })

    this.userName = sessionStorage.getItem('userName')

    localStorage.removeItem('videoChamda')
    this.mySala = true
    await this.BuscarRegistro(false, true)

    this.getHistorio15()
    this.tituloTabela = [
      { nome: 'Senha', campo: 'senha' }, { nome: 'Paciente', campo: 'paciente' }, { nome: 'Idade', campo: 'idade' },
      { nome: 'Tipo de consulta', campo: 'tipoConsulta' }, { nome: 'Horário', campo: 'horario' }, { nome: 'Prioridade', campo: 'prioridade' },
      { nome: 'Primeira consulta ?', campo: 'primeiraConsulta' }, { nome: 'Forma de Pagamento', campo: 'pagamento' }, { nome: 'Status', campo: 'status' },
    ]
  }

  getHistorio15() {
    var data = new Date();
    data.setDate(data.getDate() - 15);

    const start = data.toISOString().split('T')[0]
    const end = this.data.hoje()

    console.log(start, end)

    this.rangeDates = this.agenda.genereteRangeDatas(new Date(start), new Date(end))
    this.BuscarRegistro(true, this.mySala)
  }
  getHistoricoHoje() {
    this.BuscarRegistro()
  }

  urlID() {
    this.url.url.subscribe(async reg => {
      console.log('Filtro :' + reg.toString())
      if (reg[0].toString() == 'VerSalaEspera') {
        this.viewSalaEspera = true
      } else {
        this.viewSalaEspera = false
      }
      this.id = reg[1].path
    });
  }
  parar() {
    let partida = `${this.registroSala[0].dataConsulta} ${this.registroSala[0].horaConsulta}:00`
    let agora = `${this.data.hoje()} ${this.data.horaAgoraSegundos()}`
    this._cronometro.diferencaHorario(partida, agora)
  }
  cronometrar(ref?) {
    let seconds = 0
    let time
    setInterval(() => {
      let registro = []

      this.registroSala.forEach(r => {
        let partida = `${r.dataConsulta.split('T')[0]} ${r.horaConsulta}:00`
        let agora = `${this.data.hoje()} ${this.data.horaAgoraSegundos()}`
        r.time = this._cronometro.diferencaHorario(partida, agora)
        registro.push(r)
      });
      this.dataSource = new MatTableDataSource(registro)
    }, 1000)


  }
  go() {
    this.router.navigateByUrl(`home/chekin-paciente/${this.usuarioId}`)
  }

  async BuscarRegistro(dias?, medico?) {

    let hoje = new Date().toISOString().split('T')[0]
    let get = await this.service.listarSalaEspera(sessao.codigoEmpresa)
    let registro =  get.filter((reg: TicketConsulta) => reg.status == 'SDO0050' || reg.status == 'SDO0152')
    console.log('**********************', get.filter((reg: TicketConsulta) =>  reg.status == 'SDO0152'))
    if (medico) {
      registro = registro.filter(reg => reg.cpfMedico == sessao.cpf)
    }

    this._filter.inserirFotoPerfil('cpfPaciente', registro)
    this.dataSource = new MatTableDataSource(registro)
    this.registroSala = registro
    if (registro.length > 0) {
      this.cronometrar()
    }
    this.loading = false

    let getLog = []
    this.loadingHistoricoH = true
    getLog = await registro.filter((reg: TicketConsulta) => {
      return reg.codigoEmpresa == sessao.codigoEmpresa
        && reg.status != 'SDO0050'
      //  && reg.dataConsulta == hoje
    })
    this.loadingHistoricoH = false
    console.log('historico', getLog)
    this.dataSourceLog = new MatTableDataSource(getLog)
    return
    // if (dias) {
    //   this.loadingHistorico = true
    //   await this.rangeDates.forEach(data => {
    //     let itens = registro.filter((reg: TicketConsulta) => {
    //       return reg.dataConsulta == data
    //     })
    //     itens.forEach(element => {
    //       getLog.push(element)
    //     });
    //   });
    //   setInterval(() => { this.loadingHistorico = false }, 2000)
    // } else {
    //   this.loadingHistoricoH = true
    //   let hoje = this.data.hoje()

    //   console.log('RESULLT', hoje)
    //   getLog = await registro.filter((reg: TicketConsulta) => {
    //     return reg.codigoEmpresa == sessao.codigoEmpresa && reg.status == 'SDO0050'
    //     //  && reg.dataConsulta == hoje
    //   })
    //   this.loadingHistoricoH = false
    // }

    // await this.service.listarSalaEspera(sessao.codigoEmpresa).toPromise().then(
    //   async (dados: any) => {
    //     if (!dados.success) return
    //     dados = dados.result
    //     console.log('#@#@', dados)
    //     let filtro = await dados.result.filter(reg => reg.status == 'SDO0050')

    //     let orderna = await filtro
    //     // .sort(function (a: TicketConsulta, b: TicketConsulta) {
    //     //   return parseInt(a.senha) - parseInt(b.senha)
    //     //   // return a.senha - b.senha && parseInt(a.hora) - parseInt(b.hora)
    //     // })



    //     let getLog = []
    //     if (dias) {
    //       this.loadingHistorico = true
    //       await this.rangeDates.forEach(data => {
    //         let itens = dados.filter((reg: TicketConsulta) => {
    //           return reg.dataConsulta == data
    //         })
    //         itens.forEach(element => {
    //           getLog.push(element)
    //         });
    //         console.log('RODRIGO', itens)

    //       });
    //       setInterval(() => { this.loadingHistorico = false }, 2000)
    //     } else {
    //       console.log('passou', dados)
    //       this.loadingHistoricoH = true
    //       let hoje = this.data.hoje()

    //       console.log('RESULLT', hoje)
    //       getLog = await dados.filter((reg: TicketConsulta) => {
    //         return reg.codigoEmpresa == sessao.codigoEmpresa && reg.status == 'SDO0050' && reg.dataConsulta == hoje
    //       })

    //     }

    //     console.log('HISTORICO!', getLog)
    //     console.log('HISTORICO!@@', this.rangeDates)

    //     orderna.forEach(async value => {
    //       let empresa = await this.empresa.getEmpresasToken(value.codigoEmpresa)
    //       value.empresa = empresa[0].nomeEmpresa
    //     });

    //     getLog.forEach(async value => {
    //       let empresa = await this.empresa.getEmpresasToken(value.codigoEmpresa)
    //       value.empresa = empresa[0].nomeEmpresa
    //     });

    //     this.dataSource = new MatTableDataSource(orderna)
    //     this.dataSourceLog = new MatTableDataSource(getLog)

    //     setInterval(() => { this.loadingHistoricoH = false }, 1000)
    //     console.log(orderna)
    //     this.registroSala = orderna
    //     if (this.registroSala.length > 0) {
    //       this.cronometrar()
    //     }
    //     if (filtro.length == 0 && !this.erro) {
    //       this.erro = true
    //       this.erroMensagem = "Sala de espera vazia "
    //     }
    //   }

    // ).catch(
    //   () => {
    //     this.erro = true
    //     this.erroMensagem = "Não foi possível localizar registro"
    //   }
    // )
  }

  async Paciente(id) {
    this.idPaciente = id
    this.registroConsultas = null
    /////Ultimas consulta
    await this.service.listarConsultas().toPromise().then(
      async (dados: ConsultaForm[]) => {
        let idPaciente = this.idPaciente
        let filtro = dados.filter(function (reg: ConsultaForm) {
          return reg.pacienteId == idPaciente
        })
        if (filtro.length >= 1) {
          this.registroConsultas = filtro
        }
        else {
          this.registroConsultas = null
        }
      }
    )
    /////Ultimas END

    /// Parecer Medico (Diagnostico)
    await this.serviceAtendimento.listarParecerMedico().toPromise().then(
      async (dados: ConsultaForm[]) => {
        let idPaciente = this.idPaciente
        let filtro = dados.filter(function (reg: ConsultaForm) {
          return reg.usuarioId == idPaciente
        })
        if (filtro.length >= 1) {
          this.diagnostico = filtro
        }
        else {
          this.diagnostico = null
        }
      }
    )
    console.log(this.registroConsultas)
    console.log(this.diagnostico)
    ///END Parecer Medico (Diagnostico)

  }

  async ChamarPaciente(registro: TicketConsultaJOIN, ref?) {

    this.loading = true
    this.infoSalaEspera = registro
    this.chamarPaciente = registro
    let dados = registro

    if (ref) { dados['processo'] = 0 }
    else { dados['processo'] = 2 }

    if (ref != 'excluir') await this.encaminharAtendimento()
    if (registro.formaDeConsulta == 'FORMACON2') {
      localStorage.setItem('videoChamada', 'true')
      this.adicionarVideoChamada()
    }
    else {
      localStorage.removeItem('videoChamada')
    }
    this.popUp.emit(false)
  }
  async adicionarVideoChamada() {
    let key = this.cpf_medico
    if (key.length < 20) {
      for (let i = 0; key.length < 20; i++) {
        let num = Math.floor(Math.random() * 9 + 1)
        key = `${key}${num}`
      }
      console.log(key, key.length)
    }
    if (key.length == 20) {
      console.log(this.chamarPaciente)
      this._videoChamada.criarSala(this.chamarPaciente).then(
        async (reg) => {
          await localStorage.setItem('keyVC', reg.id)
          const id = reg.id
          let id_info = null
          const registro = {
            codigoUnico: id,
            remetente: this.cpf_medico,
            destinatario: this.chamarPaciente.cpfPaciente,
            data: this.data.hoje(),
            status: 'pendente',
            transacao: 'AC10'
          }
          this._videoChamada.banco().doc(id).collection('info').add({ nome: this.chamarPaciente.nomePaciente, medico: this.userName, registro: registro }).then(
            (res) => { id_info = res.id }
          )

          setTimeout(() => {
            this._videoChamada.registrar(registro).toPromise().then(
              (reg: any) => {
                this._videoChamada.banco().doc(id).collection('info').doc(id_info).update({ registro: reg })

              } //this.mensagem.info('Seu paciente esta sendo chamado para video consulta')
            )
          }, 15000)
          setTimeout(
            () => {
              this.encaminharAtendimento()
            }
          ), 3000
        }
      ).catch(
        (erro) => {
          this.mensagem.error('Desculpe algo deu errado')
          console.log('Seu erro : ', erro)
        }
      ).finally(() => console.log('fim'))
    }


  }

  async encaminharAtendimento() {
    await localStorage.setItem('registroConsultas', JSON.stringify(this.registroConsultas))

    console.log('registroConsultas', this.infoSalaEspera)

    await localStorage.setItem('infoSalaEspera', JSON.stringify(this.infoSalaEspera))

    await this.consulta.start(this.infoSalaEspera, this.cpf_medico).toPromise()
      .then(async (res) => {
        await this.atualizarSalaEspera()
        await sessionStorage.setItem('cabecalhoFichaConsultaMedica', JSON.stringify(res[0]))
        // this.nav.navigateByUrl('home/FichaConsultaMedica')
        this.viewFichaConsulta = true
      }).catch((res) => {
        console.log(res)
        this._mensagem.creat('Falha ao criar Ficha de consulta', { erro: true })
      })
    setTimeout(() => { this.loading = false }, 2000)
  }
  async atualizarSalaEspera() {
    let registro = await this.service.getTIcketID(this.infoSalaEspera.id)
    registro.status = "SDO0046"
    this.service.alterarSalaEspera(registro).toPromise()
      .then(res => {
        console.log('atualiza SalaEspera!', res)
        this.BuscarRegistro()

      })
  }
  async atualizarTicket() {
    let registro = await this.service.getTIcketID(this.chamarPaciente.id)
    registro.status = "SDO0010"
    console.log('////////', registro)
    this.service.alterarSalaEspera(registro).toPromise()
      .then(res => {
        console.log('atualiza SalaEspera!', res)
        this.BuscarRegistro()

      })
  }
  abrirCheckin() {
    this.nav.navigateByUrl(`home/chekin-paciente/${this.id}`)
  }
  confirmDesistencia() {
    this.viewDesistencia = false
    if (this.typeCredito == 1) {
      this.viewNotaCredito = true
    }
    if (this.typeCredito == 2) {
      this.viewAgenda = true
      this.chamarPaciente
      // this.ticketReserva = new TicketReserva
      // this.ticketReserva.cpfPaciente = this.chamarPaciente.cpfPaciente
      // this.ticketReserva.cpfMedico =
      // this.nav.navigateByUrl(`home/Agenda/${this.chamarPaciente.nrDoc}`)
    }
  }
  reduzirNome(nome: string) {
    try {
      let format = `${nome.split(' ', 2)[0]} ${nome.split(' ', 2)[1]}`
      return format
    } catch {
      return nome
    }
  }
}
