import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ItemMyClinics } from './item-my-clinics.model';
import { MensagemService } from '../mensagem.service';

const url = ``
@Injectable({
  providedIn: 'root'
})
export class MyClinicsItemVendaService {

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
  ) { }

  async criarItem(item: ItemMyClinics) {
    await this.http.post(url, item, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Item criado com sucesso`, {sucesso: true}))
      .catch()
  }

  async listarItem(): Promise<ItemMyClinics[]> {
    let items: ItemMyClinics[]
    await this.http.get<ItemMyClinics[]>(url, { observe: 'body' }).toPromise()
      .then(res => {
        items = res.filter(item => item.status === 'SDO')
      })
      .catch(err => this.mensagem.creat(`Não foi possível listar os items`, {erro: true}))
    return items
  }

  async modificarItem(item: ItemMyClinics) {
    await this.http.put(url, item, { observe: 'body' }).toPromise()
      .then(()=> this.mensagem.creat(`Item alterado com sucesso`, {sucesso: true}))
      .catch(err => this.mensagem.creat(`Não foi possível modificar o item`, {erro: true}))
  }

  async desativarItem(item: ItemMyClinics) {
    item.status === 'SDO0063'
    this.modificarItem(item);
  }

}
