export class dadosRegrasDeImposto {
  codigoImposto: number // id
  criadoPor: string //ok
  codigoEmpresa: number // Empresa
  codigoReferencia: string //nome do imposto
  incidenciaSobreVendas: true //checkbox vendas
  incidenciaSobreRenda: true //checkbox renda
  incidenciaSobreCompras: true // checkbox compras
  jurisdicao: string // Qual a jurisdição do imposto?:
  pais: string
  municipio: string
  impostoEfetivoDesde: string // Imposto efetivo desde:
  taxaImposto: number // Qual a alíquota de imposto?:
  impostoRetidoNaFonte: true // Imposto sobre venda retido na fonte?:
  aliquotaRetencao: number
  valorMinimoRetencao: number
  deducao: number // Porcentagem de dedução:
  isencao: number // Porcentagem de isenção:
  retencao: number // Porcentagem de retenção
  contaContabilContasReceber: string // Contas a receber (comentado)
  contaContabilContasPagar: string // Contas a pagar (comentado)
  observacao: string // Observação
  estado: string
  status: string // ok
  outraIncidencia: string // input Outras Incidências 
  valorDeducao: number // Valor de dedução de imposto:
  baseCalculo: string // Base de cálculo de imposto:
  periodoArrecadacao: string // Período de arrecadação do imposto:
  diaRecolhimento: string // Dia do recolhimento do imposto:
  limiteMaximoBC: number // Limite máximo para base de cálculo:
  proporcaoPercentualBC: number
  criadoEm: string
  ///
  nomeEmpresa: string
  descImposto?: string
  descJurisdicao?: string
}

export class dadosOutrasIncidencias {
  identificador?: number;
  codigoImposto?: string | number;
  codigoEmpresa?: number;
  descricao?: string;
}

export class DadosFichaGeralEmpresa {
  codigoEmpresa?: number;
  nomeEmpresa?: string;
  tipoEmpresa?: string;
  enderecoEmpresa?: string;
  webSiteEmpresa?: string;
  cabecalhoImpressao?: string;
  telefoneEmpresa?: string;
  email?: string;
  status?: string;
  timestamp?: string;
  observacao?: string;
  codigoAssinante?: string;
}
