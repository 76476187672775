import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { environment } from 'src/environments/environment';
import { UsuariosLogadosModal } from '../usuarios-logados/usariosLogadosModal';


const user = new SessaoUser


@Injectable({
    providedIn: 'root'
})

export class UsuariosLogadosService{
   api = new environment().api + 'UsuariosLogados/'
  //  api = 'https://localhost:5001/api/UsuariosLogados/'

    registro = new UsuariosLogadosModal

    constructor(private http: HttpClient, private data: BuscarDataService){}

Get(){
    return this.http.get(this.api)
}

GetComNome(){
    return this.http.get(this.api + 'ComNome')
}

Post(cpf){
    this.registro.cpf = cpf
    delete this.registro.id
    this.registro.logado = true
    this.registro.inicioSessao = this.data.getDataInclusao()
   // console.log("REG USUARIOS LOGADOS: ", JSON.stringify(this.registro))
    return this.http.post(this.api, this.registro)
}

Put(registro, id){
    registro.ultimoContato = this.data.getDataInclusao()
 return this.http.put(this.api + id, registro)
}

Delete(id)
{
    return this.http.delete(this.api + id)
}

ConsultarLimiteOciosidade(){
    console.log(this.api + "ConsultarLimiteOciosidade")
    return this.http.get(this.api + "ConsultarLimiteOciosidade")
}


}