export class AJ_LISTPRECO_CAB {
    id: string
    codigoListaDePreco: number
    codigoEmpresa: number
    vigenciaInicio: string
    vigenciaFim: string
    descricao: string
    criadoPor: string
    criadoEm: string
    status: string
}
export class AJ_LISTPRECO_ITM_IMPOSTO {
    id: string
    codigoEmpresa: number
    codigoListaDePreco: number
    regraImposto: string
    criadoPor: string
    criadoEm: string
    status: string
}
export class AJ_LISTPRECO_ITM_PRAZO {
    id: string
    codigoListaDePreco: number
    codigoEmpresa: number
    codigoPrazoPagamento: number
    descricao: string
    tipoPrazoPag: string
    codigoItem: number
    meioPagamento: string
    prazoPagamentoDias: string
    numeroParcelas: number
    criadoPor: string
    criadoEm: string
    status: string
    descMeioPagamento?: string
}
export class AJ_LISTPRECO_ITM_PRECO {
    id?: any
    codigoEmpresa?: number
    codigoListaDePreco?: number
    codigoSku?: number
    precoLista?: number
    moeda?: string
    criadoPor?: string
    criadoEm?: string
    status?: string
    descricao?: string
}
export class AJ_LISTPRECO_ITM_DESCONTO {
    id: string
    codigoListaDePreco: number
    codigoEmpresa: number
    codigoDesconto: string
    descricao: string
    descontoPorItem: number
    codigoItem: string
    descontoPorOperadora: number
    codigoOperadora: string
    descontoPorPaciente: number
    cpfPaciente: string
    desconto: number
    criadoPor: string
    criadoEm: string
    status: string
    descOperadora?: string
    descItem?: string
    descTipoDesconto?: string
    formaDesconto: string
    tipoDesconto?: string
    namePaciente?: string
}
export class AJ_LISTPRECO_ITM_ACRESCIMO {
    id: string
    codigoListaDePreco: number
    codigoEmpresa: number
    codigoAcrescimo: number
    descricao: string
    tipoAcrescimo: string
    acrescimoPorItem: number
    codigoItem: number
    acrescimoPorOperadora: number
    codigoOperadora: string
    acrescimoPorPaciente: number
    cpfPaciente: string
    acrescimo: number
    criadoPor: string
    criadoEm: string
    status: string
    formaAcrescimo : string
    descOperadora?: string
    descItem?: string
    descTipoDesconto?: string
    descTipoAcrescimo ?: string
    namePaciente ?: string
}