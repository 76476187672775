import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { GrupoItemHierarquia, HierarquiaCustoELucroModel } from '../hierarquia-de-custo-e-lucro/modelsHierarquiaCustoELucro';
import { dadosCadastroCentroCusto } from '../cadastro-centro-de-custo-completo/modelsCadastroCentroDeCustoCompleto';
import { DadosCadastroCentroDeLucro } from '../cadastro-centro-de-lucro/modelsCadastroCentroLucro';


const user = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class HierarquiaCentroLucroECentroCustoService {
    url = new environment().api
    api = "HierarquiaCentroCustoECentroLucro/"
    apiCusto = "CadastroCentroCusto/"
    apiLucro = "CadastroCentroLucro/"
    apiItem = "GrupoItemHierarquia/"

    constructor(private http: HttpClient, private data: BuscarDataService) { }

    listar() {
        return this.http.get<HierarquiaCustoELucroModel[]>(this.url + this.api)
    }

    enviar(registro: HierarquiaCustoELucroModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: HierarquiaCustoELucroModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async enviarItem(registro: GrupoItemHierarquia) {
        let result
        await this.http.post(this.url + this.apiItem, registro).toPromise()
            .then(res => result = true)
            .catch(error => {
                result = false
                console.log('erro -> enviarItem', error)
            })
        return result
    }
    async listarItem(codigoEmpresa, codigoHierarquia) {
        let result: GrupoItemHierarquia[] = []
        await this.http.get<GrupoItemHierarquia[]>(this.url + this.apiItem).toPromise()
            .then(res => {
                let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa && value.codigoHierarquia == codigoHierarquia)
                if (filter.length > 0) {
                    result = filter
                }
            })
            .catch(error => {
                console.log('erro -> enviarItem', error)
            })
        return result
    }
    async desativarItem(id) {
        let result
        try {
            await this.http.delete(this.url + this.apiItem + id).toPromise()
                .then(res => result = true)
        } catch (error) {
            result = false; console.log('erro delete', error)
        }

        return result
    }

    async desativar(registro: HierarquiaCustoELucroModel, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
            registro.status = 'SDA0002'
        } else {

        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }

    listarCentroCusto(codigoEmpresa) {
        return this.http.get<dadosCadastroCentroCusto[]>(this.url + this.apiCusto + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    listarCentroLucro(codigoEmpresa) {
        return this.http.get<DadosCadastroCentroDeLucro[]>(this.url + this.apiLucro + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    // async listarCentroCustoAtivo(registro: dadosCadastroCentroCusto, ativo) {
    //     if (ativo) {
    //         registro.status = 'SDA0001'
    //     } else {
    //         registro.status = 'SDA0002'
    //     }

    //     let resposta
    //     await this.listarCentroCusto(registro)
    //     return resposta
    // }



    // getHierarquiaCentroCustoECentroLucro(): Observable<Object> {
    //     return this.http.get(`${this.url}${this.HierarquiaCentroCustoECentroLucro}`)
    // }

    // CadastrarHierarquiaCentroCustoECentroLucro(dados) {
    //     console.log("api: " + `${this.url}${this.HierarquiaCentroCustoECentroLucro}`, dados)
    //     return this.http.post(`${this.url}${this.HierarquiaCentroCustoECentroLucro}`, dados)
    // }

    // getHierarquiaCentroCustoECentroLucroID(id) {
    //     return this.http.get(`${this.url}${this.HierarquiaCentroCustoECentroLucro}/${id}`)
    // }

    // getHierarquiaCentroCustoECentroLucroCodigoEmpresa(codigoEmpresa: any): Observable<Object> {
    //     return this.http.get(`${this.url}${this.HierarquiaCentroCustoECentroLucro}/FiltrarCodigoEmpresa/${codigoEmpresa}`)
    // }

    // putHierarquiaCentroCustoECentroLucro(id: any, userId: any, data: any, dados) {
    //     return this.http.put(`${this.url}${this.HierarquiaCentroCustoECentroLucro}/${id}/${userId}/${data}`, dados)
    // }

    // deleteHieraquiaCentroCustoECentroLucro(id: any) {
    //     return this.http.delete(`${this.url}${this.HierarquiaCentroCustoECentroLucro}/${id}`)
    // }
}

