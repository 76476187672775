import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormPaciente, CadastroPessoaForm } from '../cadastro-paciente/model';
import { FormConexoesPaciente } from '../atendimento-clinico/recepcao/model';
import { REQUEST } from './modal';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {
  url: string = new environment().api
  url_local: string = new environment().api_local
  api: string = "CadastroPessoa/"
  api_ConexoesPaciente: string = "ConexoesPaciente/"
  constructor(private http: HttpClient) {

  }
  ///////////////////////////// Listar Paciente //////////////////////
  listarPaciente(id?) {
    if (id) {
      return this.http.get<REQUEST<CadastroPessoaForm>>(this.url + this.api + id)
    }
    else {
      return this.http.get<REQUEST<CadastroPessoaForm>>(this.url + this.api + 'join')
    }
  }
  cadastrarPaciente(dados: CadastroPessoaForm) {
    console.log('Paciente-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarPaciente(id: string, dados) {
    console.log('Paciente-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirPaciente(id: string) {
    console.log('Paciente-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
  }
  ///////////////////////////// Listar Paciente END //////////////////////

  ///////////////////////////// Listar Conexões //////////////////////
  listarConexoesPaciente(id?) {
    console.log('ConexoesPaciente :' + this.url + this.api_ConexoesPaciente)
    if (id) {
      console.log('ID true')
      return this.http.get(this.url + this.api_ConexoesPaciente + id)
    }
    else {
      console.log('Listar ConexoesPaciente sem id')
      return this.http.get(this.url + this.api_ConexoesPaciente)
    }

  }
  cadastrarConexoesPaciente(dados: FormConexoesPaciente) {
    console.log('ConexoesPaciente-Cadastrar :' + this.url + this.api_ConexoesPaciente, dados)
    return this.http.post(this.url + this.api_ConexoesPaciente, dados)
  }
  alterarConexoesPaciente(id: string, dados) {
    console.log('ConexoesPaciente-Alterar :' + this.url + this.api_ConexoesPaciente + id, dados)
    return this.http.put(this.url + this.api_ConexoesPaciente + id, dados)
  }
  excluirConexoesPaciente(id: string) {
    console.log('ConexoesPaciente-Excluir :' + this.url + this.api_ConexoesPaciente + id)
    return this.http.delete(this.url + this.api_ConexoesPaciente + id)
  }
  ///////////////////////////// Listar Conexões END //////////////////////

  ///////////////////////////// Listar CadastroPessoa //////////////////////
  listarCadastroPessoa(id?) {
    console.log('CadastroPessoa :' + this.url + this.api)
    if (id) {
      console.log('ID true')
      return this.http.get(this.url + this.api + id)
    }
    else {
      console.log('Listar CadastroPessoa sem id')
      return this.http.get(this.url + this.api)
    }

  }
  cadastrarCadastroPessoa(dados: CadastroPessoaForm) {
    console.log('CadastroPessoa-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarCadastroPessoa(id: string, dados) {
    console.log('CadastroPessoa-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirCadastroPessoa(id: string) {
    console.log('CadastroPessoa-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
  }
  ///////////////////////////// Listar CadastroPessoa END //////////////////////

}

