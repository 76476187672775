import { Component, OnInit, ViewChild, Input, OnDestroy, Output, OnChanges, SimpleChanges } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
// import agendaWeek from '@fullcalendar/agendaWeek'
import interactionPlugin from '@fullcalendar/interaction';
// // import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import bootstrapPlugin, { BootstrapTheme } from '@fullcalendar/bootstrap';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { toMoment } from '@fullcalendar/moment';
import { FullCalendarComponent } from '@fullcalendar/angular';
import listPlugin from '@fullcalendar/list';
import { AgendaConfigForm, AgendaForm } from '../agenda/agenda/model';
import { BuscarDataService } from '../service/buscar-data.service';
import { AgendaService } from '../service/agenda.service';
import { MensagemService } from '../service/mensagem.service';
import { ListarMedicos } from '../service/listar-medicos.service';
import { SessaoUser } from '../user.reg';
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { EventCalender } from './model';
const sessao = new SessaoUser


@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})

export class CalendarioComponent implements OnDestroy, OnChanges {
  @Input('registro') registro = []
  @Input('feriados') feriados = []
  @Input('config') config = new AgendaConfigForm
  @Input('view') view
  @Input('navMes') navMes
  @Input('navAno') navAno
  @Input('onlyView') onlyView: boolean
  @Input('medico') medico: ListarMedicos
  @Output('change') change = new EventEmitter
  @Input('activeHoraiosFechados') activeHoraiosFechados: { medico?: string; registro?: [] } = {};
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGrigPlugin, listPlugin];
  verificar: any;
  mes: string;
  ano: number;
  listEvents = []
  refresh: boolean;
  eventCoord: { top: string; left: string; };
  viewMenu: boolean;
  viewMenuList: boolean;
  viewFormTime: boolean;
  selectDay = new EventCalender
  horarios: { h: string; m: string; }[] = [];
  horariosEnd: { h: string; m: string; }[] = [];
  viewCalendar = 'mes'
  infoViewCanelder = 'Mês'
  viewPeriodo: boolean;
  formFecharPeriodo = { de: new Date(), ate: new Date() }
  formAddHorario = { de: '', ate: '' }
  infoPeriodo: string;
  loadingReg: boolean;
  validRange: { start: string; end: string; };
  abrirAgenda: boolean;
  typeView: string;
  selecTypeEdicao: any;
  mensagemInfo = 'Selecione um dia na agenda para personalizar um horário'
  openDescricao: boolean;



  constructor(
    private buscarData: BuscarDataService,
    private _agenda: AgendaService,
    private _mensagem: MensagemService) {
  }


  async ngOnInit() {
    if (this.onlyView) this.mensagemInfo = null
    setTimeout(() => { this.getDataCalendario(true) }, 1000)
  }

  async ngOnChanges() {
    const api = this.calendarComponent.getApi()
    console.log('ano : ' + this.navAno, 'mes : ' + this.navMes)
    // this.refresh = true
    if (this.navAno && this.navMes) {
      let date = `${this.navAno}-${this.navMes}-1`
      console.log('go',new Date(date).toISOString())
      api.gotoDate(new Date(date))
      this.getDataCalendario()
    }
    try {
      this.validRange = { start: this.config.start, end: this.config.end }
      this.registro = this.activeHoraiosFechados.registro ? this.activeHoraiosFechados.registro : []
    }
    catch {

    }

    if (this.medico) {
      this.getConfigAgenda(this.medico.cpfProfissional)
    }
    this.viewMenu = false
    this.selectDay = new EventCalender

    // console.log('ngOnChanges', this.registro, this.medico)
    // this.calendarComponent.getApi().refetchEvents()
    // this.registro = this.registro.filter(value => !value.overlap)
    // this.registro.push(...this.feriados)
    // setTimeout(() => this.refresh = false, 500)
  }


  ngOnDestroy() {
    clearInterval(this.verificar)
  }

  async getHorarios() {
    let start = this.config.timeStart
    let end = this.config.timeEnd
    if (start && end) {
      let listhorarios = this._agenda.listarHorarios(start, end)
      this.horarios = listhorarios
    } else {
      let listhorarios = this._agenda.listarHorarios('01:00', '23:00')
      this.horarios = listhorarios
    }
    let de = `${this.horarios[0].h}:${this.horarios[0].m}`
    let ate = `${this.horarios[1].h}:${this.horarios[1].m}`
    this.formAddHorario.de = de
    this.formAddHorario.ate = ate
    this.validHorario()

  }

  async getConfigAgenda(cpfMedico) {
    this.loadingReg = true
    if (!cpfMedico) {
      cpfMedico = sessao.codigoEmpresa
    }

    this.config = await this._agenda.getConfigMedico(cpfMedico)
    if (!this.config.id) {
      this.config = await this._agenda.getConfigMedico(sessao.codigoEmpresa)
    }
    this.loadingReg = false
    this.getHorarios()
  }



  navLinkDayClick(ref) {
    console.log('navLinkDayClick', ref)
  }
  next(ref) {
    const calender = this.calendarComponent.getApi()
    if (ref) {
      calender.next()
    } else {
      calender.prev()
    }
  }
  async exibirCalendario(ref?) {
    if (!ref) ref = this.view
    this.viewCalendar = ref
    let api = this.calendarComponent.getApi()
    let date = new Date()
    if (this.selectDay.start) {
      date = new Date(this.selectDay.start)
    }
    switch (ref) {
      case 'hoje':
        api.today()
        this.closeMenu()
        break;
      case 'mes':
        api.changeView('dayGridMonth')
        api.gotoDate(date)
        this.closeMenu()
        break;
      case 'semana':
        api.changeView('timeGridWeek')
        api.gotoDate(date)
        this.closeMenu()
        break
      case 'dia':
        api.changeView('timeGridDay')
        api.gotoDate(date)
        this.closeMenu()
        break
      case 'tarefa':
        api.changeView('listWeek')
        break
      case 'netx':
        await api.next()
        this.getDataCalendario()
        this.closeMenu()
        break
      case 'return':
        await api.prev()
        await this.getDataCalendario()
        this.closeMenu()
        break
      case 'teste':
        console.log(api.view)
        break
    }


  }
  typeEdicao(type) {
    this.closeMenu()
    this.selecTypeEdicao = type
    let mensagem = null
    switch (type) {
      case 'personalizar':
        mensagem = 'Selecione um dia na agenda para personalizar um horário'
        break
      case 'abrirPeriodo':
        this.viewPeriodo = true
        this.abrirAgenda = true
        break
      case 'fecharPeriodo':
        this.viewPeriodo = true
        this.abrirAgenda = false
        break
      case 'abrir':
        mensagem = 'Selecione um ou horário dia na agenda para abrir-la'
        break
      case 'fechar':
        mensagem = 'Selecione um dia ou horário na agenda para fecha-la'
        break
      case 'remover':
        mensagem = 'Selecione um dia ou horário na agenda para reabri-la'
        break
    }

    this.mensagemInfo = mensagem
  }

  abrir(event) {
    const type = this.selecTypeEdicao
    console.log('abri', event, JSON.stringify(this.registro))

    if (type == 'remover') {
      try {
        if (event.allDay) {
          let date = event.dateStr
          let filter = this.registro.filter(value => value.start != date)
          this.registro = filter
        } else {
          const id = event.event.id.toString()
          let filter = this.registro.filter(value => value.id.toString() != id)
          this.registro = filter
        }
      }
      catch { }
      this.change.emit(this.registro)
    }
  }

  dateClick(event) {
    this.typeView = event.view.type
    let dado = new EventCalender
    dado.start = event.dateStr
    dado.end = event.dateStr
    dado.id = this.listEvents.length
    this.selectDay = dado
    this.selectDay.backgroundColor = '#fff0'
    this.selectDay.rendering = 'background'
    this.eventCoord = {
      'top': `${event.jsEvent.y}px`,
      'left': `${event.jsEvent.x}px`,
    }
    this.addEvent()
  }
  addEvent(registro?: any) {
    // this.refresh = true
    let event = this.selectDay
    console.log(event)
    if (registro) {
      this.validEvent(registro)
      this.registro = [...this.registro, registro]
    } else {
      event.allDay = true
      event.rendering = 'background'
      event.color = '#fff0'
      this.validEvent(event)
      this.registro = [...this.registro, event]
    }
    this.listEvents.push(event)
    this.calendarComponent.getApi().refetchEvents()
    this.change.emit(this.registro)
    console.log(this.registro)
  }

  openMenu(abrir) {
    this.viewMenu = false

    this.abrirAgenda = abrir
    this.viewMenuList = true
  }
  validEvent(event: EventCalender) {
    let result = this.registro.filter(v => v.start == event.start);
    result.forEach(item => {
      let getIndex = this.registro.findIndex(v => v.id == item.id);
      if (getIndex >= 0) {
        delete this.registro[getIndex]
      }
    });
    this.registro = this.registro.filter(x => x)
  }
  addPeriodo() {

    let periodo = this.formFecharPeriodo
    let validRange = new Date(periodo.de) > new Date(periodo.ate)
    let validDate = new Date(periodo.de).toISOString().split('T')[0] < new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]
    let validPeriodo = this._agenda.genereteRangeDatas(new Date(periodo.de), new Date(periodo.ate)).length

    if (!periodo.de || !periodo.ate) return

    if (validRange) {
      this.infoPeriodo = 'informe uma data coerente, data de inicio não pode ser maior que a data do termino'
      return
    }
    if (validDate) {
      this.infoPeriodo = 'Data de inicio não pode ser inferior a data de hoje'
      return
    }
    if (validPeriodo > 360) {
      this.infoPeriodo = 'Data de termino muito longa , informe um período de até 12 meses '
      return
    }
    this.infoPeriodo = null
    let addDay = new Date(periodo.ate).getDate() + 1
    let getDate = new Date().setDate(addDay)
    let newDate = new Date(getDate).toISOString()
    let genereteRange = this._agenda.genereteRangeDatas(new Date(periodo.de), new Date(newDate))
    genereteRange.forEach(element => {
      let registro = new EventCalender
      registro.id = this.registro.length
      registro.allDay = true
      registro.rendering = 'background'
      registro.start = element
      registro.end = element
      registro.color = this.abrirAgenda ? '#fff0' : "#b3b3b3c9",
        registro.textColor = "#939393"
      registro.title = this.abrirAgenda ? '- Horário Aberto ' : `- Horário fechado `
      this.addEvent(registro)
    });
    console.log(periodo.de, periodo.ate, genereteRange)

    this.viewPeriodo = false
    this.formFecharPeriodo = { de: new Date(), ate: new Date() }


  }
  addHorario() {

    let horario = this.formAddHorario
    let date = new Date(this.selectDay.start).toISOString().split('T')[0]
    let start = `${date}T${horario.de}:00.00Z`
    let end = `${date}T${horario.ate}:00.000Z`

    let registro = new EventCalender
    registro.id = this.registro.length
    registro.start = start
    registro.end = end
    registro.color = this.abrirAgenda ? '#fff0' : "#b3b3b3c9",
      registro.textColor = "#939393"
    registro.title = this.abrirAgenda ? '- Horário Aberto ' : `- Horário fechado `
    this.addEvent(registro)
    this.closeMenu()


  }
  validHorario() {
    const start = this.formAddHorario.de
    let timeStart = parseInt(start.split(':').join(''))
    let getTimes = this.horarios.filter(value => {
      let time = parseInt(`${value.h}${value.m}`)
      return timeStart < time
    })
    this.horariosEnd = getTimes
  }
  alterarAgendaDrop(event) {
    console.log('drop', event)
  }

  clickHouver(event) { console.log(event) }

  MouseOver(event, active) {
    // console.log(event)
  }

  atualizarEvent(item) {
    let event = item.event
    let getReg = this.registro.filter(value => value.id == event.id)[0]
    try {
      if (getReg) {
        getReg.end = new Date(event.end).toISOString()
      }
    } catch {
      this._mensagem.creat('Erro ao atualizar envento', { erro: true })
    }

  }

  getDataCalendario(start?) {
    try {
      const calender = this.calendarComponent.getApi()
      let index = calender.getDate().getMonth() + 1
      this.ano = calender.getDate().getFullYear()
      this.mes = this.buscarData.getMeses(index).name
      if (start) {
        this.mes = this.buscarData.getMeses(index - 1).name
      }

      console.log(this.mes, index)
    } catch {

    }


  }

  eventRender(e) {
    const registro = e.event.extendedProps
    const feriado = e.event.extendedProps.feriado
    const diaBloqueado = e.event.extendedProps.fechado
    const consulta = e.event.extendedProps.cpf
    const start = new Date(e.event.start).toISOString().split('T')[1].slice(0, 5)
    const end = new Date(e.event.end).toISOString().split('T')[1].slice(0, 5)
    let html

    if (feriado) {
      html =
        `<ul class="list-group" style="overflow: hidden;">
        <li class="list-group-item p-1 " style="background: none"><i class="fas fa-certificate text-warning mr-1"></i>${e.event.title}</li>
      </ul>`

    }
    if (consulta) {
      const status = registro.status
      html =
        `<ul class="list-group border p-1 m-0" style="overflow: hidden;border-radius: 5px;">
          <li class="list-group-item p-0 text-dark" style="background: #fefefe3d">
          <i class="${status == 'SDO0008' ? 'mr-1 fas fa-check-circle text-success' : ''} "></i> 
          <i class="${status == 'SDO0010' ? 'mr-1 fas fa-user-clock text-info' : ''} "></i> 
          <i class="${status == 'SDO0022' ? 'mr-1 fas fa-user-times text-danger' : ''} "></i> 
          <i class="${status == 'SD00011' ? 'mr-1 fas fa-medal text-warning' : ''} "></i> 
          <i class="${status == 'SDO0028' ? 'mr-1 fas fa-star text-success' : ''} "></i> 
          <i class="${status == 'SDO0098' ? 'mr-1 fas fa-user-times text-danger' : ''} "></i> 
          ${start}h - ${end}h 
         ( ${e.event.title.split(' ')[0]} )</li>
        </ul>`

    }
    if (diaBloqueado) {
      console.log('*******', e.event, diaBloqueado)

      html =
        `<ul class="list-group" style="overflow: hidden;">
      <li class="list-group-item p-1" style="background : none"><i class="fas fa-ban mr-1 text-danger"></i>
      <i style="color: #ff0000!important">${e.event.title}</i> 
      </li>
    </ul>`
    }
    e.el.innerHTML = html
  }
  closeMenu() {
    this.viewFormTime = false;
    this.viewPeriodo = false
    this.viewMenu = false
    this.viewMenuList = false
  }
}
