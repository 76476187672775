export class Menu {
    constructor() {
        let menu = [
            { id: 'avClinica', title: 'Avaliação clinica', icon: 'fas fa-file-medical-alt' },
            { id: 'pareMedico', title: 'Parecer médico', icon: 'fas fa-stethoscope' },
            { id: 'condMedico', title: 'Conduta médica', icon: 'fas fa-book-medical' },
            { id: 'procAdd', title: 'Procedimento adicional', icon: 'fas fa-plus' },
            { id: 'enceAten', title: 'Encerrar atendimento', icon: 'fas fa-star-of-life' },
            { id: 'vgConsulta', title: 'Visão geral consulta', icon: 'fas fa-tasks' },
            { id: 'prontMedPac', title: 'Prontuário médico paciente', icon: 'fas fa-user-md' },
            { id: 'prontSaudPac', title: 'Prontuário saúde paciente', icon: 'fas fa-id-card-alt' },
            { id: 'atDadoSaude', title: 'Atualizar dados saúde', icon: 'fas fa-sync-alt' },
        ]
        return menu
    }

}
export class SubMenu {
    constructor() {
        let subMenu = {

            avClinica: [
                { id: 'anamnese', title: 'Anamnese' },
                { id: 'exame', title: ' Exames físicos e visuais' },
                { id: 'afericaoSV', title: 'Aferição dos sinais vitais' },
                { id: 'descrEstadoMental', title: 'Descrição estado mental' },
                { id: 'resultadoExame', title: 'Resultados de exames' },
                { id: 'docClinic', title: 'Documentos clínicos' },
            ],
            pareMedico: [
                { id: 'hipoDiagnostica', title: 'Hipótese diagnóstica' },
                { id: 'diagnostico', title: 'Diagnóstico' },
                { id: 'quadroCirurgico', title: 'Quadro pós cirúrgico' },
                { id: 'sequelas', title: 'Sequelas' },
                { id: 'causasMortais', title: 'Causa mortis' },
            ],
            condMedico: [
                { id: 'solicExame', title: 'Soliticação de exames' },
                { id: 'receituario', title: 'Receituário' },
                { id: 'indiTratamento', title: 'Indicação de tratamento' },
                { id: 'indiCirurgia', title: 'Indicação de cirurgia' },
            ],
            procAdd: [
                { id: 'procAdicional', title: 'Procedimento Adicional' },
            ],
            enceAten: [
                { id: 'atAtestado', title: 'Atestado' },
                { id: 'atEncaminhamento', title: 'Encaminhamento' },
                { id: 'solicRetorno', title: 'Solicitação de retorno' },
                { id: 'encerrar', title: 'Encerrar atendimento' },
            ],
            vgConsulta: [
                { id: 'vgConsulta', title: ' Visão geral consulta' }
            ],
            prontMedPac: [
                { id: 'prontMedPac', title: 'Histórico de diagnósticos' },
                // { id: 'b', title: 'Histórico de sequelas' },
                // { id: 'c', title: 'Exames realizados' },
                // { id: 'd', title: 'Histórico de consultas' },
                // { id: 'f', title: 'Histórico de encaminhamentos médicos' },
            ],
            prontSaudPac: [
                { id: 'prontSaudPac', title: 'Prontuário saúde paciente' },
            ],
            atDadoSaude: [
                { id: 'atDadoSaude', title: 'Atualizar dados saúde' },
            ]
        }
        return subMenu
    }
}