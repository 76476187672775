export class CertificadoDigital {
    id: string
    emissor: string
    serial: string
    status: string
    ativoDesde: string
    inativoDesde: string
    codigoEmpresa: number
    criadoPor: string
    cpf: string
}