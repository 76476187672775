import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BuscarDataService } from './buscar-data.service';
import { CamposPosicaoNfe } from '../ajustes/posicao-numeracao-nfe/campos-posicaoNfe';
import { SessaoUser } from '../user.reg';

const sessao = new SessaoUser


@Injectable({
  providedIn: 'root'
})
export class PosicaoNumeracaoNfeService {

  url = new environment().api
  apiPosicao = "PosicaoNfe/"
  apiFicha = "FichaGeralEmpresa/"

  constructor(private http: HttpClient, private data: BuscarDataService) { }

  listar() {
    return this.http.get<CamposPosicaoNfe[]>(this.url + this.apiPosicao)
  }

  enviar(registro: CamposPosicaoNfe) {
    return this.http.post(this.url + this.apiPosicao, registro)
  }

  alterar(registro: CamposPosicaoNfe) {
    return this.http.put(this.url + this.apiPosicao + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
  }

  async desativar(registro: CamposPosicaoNfe, ativo) {
    if (ativo) registro.status = 'SDA0001'
    else registro.status = 'SDA0002'

    let resposta
    await this.alterar(registro).toPromise()
      .then(res => { resposta = res })
      .catch(error => { console.log('error', error) })

    return resposta
  }




  // -------> TABELA POSIÇÃO NFe - START <------->
  // postPosicaoNfe(dados: Object) {
  //   console.log("Link Api: " + this.url + this.apiPosicaoNfe)
  //   return this.http.post(this.url + this.apiPosicaoNfe, dados)
  // }

  // getPosicaoNfe() {
  //   console.log("Link API: " + this.url + this.apiPosicaoNfe)
  //   return this.http.get(this.url + this.apiPosicaoNfe)
  // }

  // deletePosicaoNfe(id: number) {
  //   console.log("Link Api: " + this.url + this.apiPosicaoNfe + id)
  //   return this.http.delete(this.url + this.apiPosicaoNfe + id);
  // }

  // getPosicaoId(id: number) {
  //   console.log("Link Api: " + this.url + this.apiPosicaoNfe + id)
  //   return this.http.get(this.url + this.apiPosicaoNfe + id)
  // }

  // putPosicao(id:number,dados: object) {
  //   console.log("Link Api: " + this.url + this.apiPosicaoNfe)
  //   return this.http.put(this.url + this.apiPosicaoNfe + id, dados)
  // }
  // // -------> TABELA TABELA POSIÇÃO NFe - END <------->

  // // -------> TABELA FICHA GERAL EMPRESAS - START <------->
  // getFichaGeralEmpresas() {
  //   console.log("Link API: " + this.url + this.apiFichaGeral)
  //   return this.http.get(this.url + this.apiFichaGeral)
  // }
  // -------> TABELA FICHA GERAL EMPRESAS - END <------->

}
