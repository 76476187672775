export class PlanoContaContabeisModel {

    codigo: string // id
    criadoPor: string
    codigoContaContabil: string
    controleLivroCaixa: string
    controlePagamento?: boolean = false
    dreNivelnumber: string
    dreNivel1: string
    codigoEmpresa: number
    descricao: string
    balancoPatrimonialZero: string
    balancoPatrimonialUm: string
    balancoPatrimonialDois: string
    balancoPatrimonialTres: string
    tipo: string
    natureza: string
    saldo: string
    bp?: boolean = false
    dre?: boolean = false
    dlpa?: boolean = false
    dmpl?: boolean = false
    dfcNivelZero: string
    dfcNivelUm: string
    dfcNivelDois: string
    dfcNivelTres: string
    dfcNivelQuatro: string
    classificacao: string
    status: string
    lancamentoLivroCaixa: string
    ////
    timestamp: string
    nomeEmpresa: string
    children?: any
    dataLancamento?: any
    periodo?: any
    registroSaldo?: SaldoContaContabil
    fatura?: any[]
    // id: string

    orcamento?: boolean = false
    carneLeao?: boolean = false
    recebimento?: boolean = false
    valor: string
}
export class SaldoContaContabil {
    id: string
    codigoEmpresa: number
    nomeEmpresa?: string
    valorTotalFatura?: number
    dataDeLancamento: string
    contaContabil: string
    montanteSaldo: number
    periodoContabil: string
    exercicioContabil: string
    criadoPor: string
    criadoEm: string
    status: string
    saldo?: number
    saldoAc?: number
    saida?: number
    valorTotalFaturaEntrada?: number
    entrada?: number
    descricao?: string
    total?: number
}
export class FaturaContasAPagar {
    id: string
    codigoEmpresa: number
    nomeEmpresa?: string
    numeroDocumento: string
    nrDocumentoReferencia: string
    dataLancamento: string
    dataDocumento: string
    dataVencimento: string
    dataPagamento: string
    itemPagamento: string
    cedente: string
    tipoDocumento: string
    valorTotalFatura: number
    moedaFatura: string
    meioPagamento: string
    contaBancaria: string
    cartaoCredito: string
    valorTotalMora: number
    valorTotalDesconto: number
    valorTotalDeducoes: number
    valorPagamento: number
    observacao: string
    criadoPor: string
    criadoEm: string
    status: string
    realizado?: number
    previsao?: number
    total_realizado?: number
    total_previsao?: number
    total?: number
    contaD: string
    contaC: string
}
export class FaturaDeMedico {
    id: string
    codigoEmpresa: number
    numeroDocumento: string
    nrDocumentoReferencia: string
    dataLancamento: string
    dataDoDocumento: string
    dataDoVencimento: string
    dataDoPagamento: string
    meioDePagamento: string
    moedaDaFatura: string
    valorTotalFatura: number
    valorTotalImposto: number
    valorTotalDesconto: number
    status: string
    numeroParcelas: number
    valorParcelas: number
    operadoraCartao: string
    valorLiquidoDaFatura: number
    valorTaxaCartao: number
    taxaCartao: string
    nsu: string
    criadoPor: string
    bancoTransferencia: string
    agenciaTransferencia: string
    contaTransferencia: string
    identificacaoTransferencia: string
    criadoEm: string
    contaContabilCredora: string
    contaContabilDevedora: string
}

export class GS_AM_RECMAT_CAB {
    id: string
    codigoEmpresa: number
    numeroDocumento: string
    nrDocumentoReferencia: string
    dataLancamento: string
    dataDocumento: string
    dataRecebimento: string
    criadoPor: string
    criadoEm: string
    status: string
    valorTotalFatura?: number
    codigoContaContabil?: string
}
export class GS_AM_RECMAT_ITM {
    id: string
    numeroDocumento: string
    numeroItem: number
    itemSKU: number
    precoUnitLiq: number
    vlTotalImpostoItem: number
    vlTotalDescontoItem: number
    vlTotalAcrescimoitem: number
    precoUnitBruto: number
    qtdEntregaItm: number
    valorTotalItem: number
    divergenciaQuantidade: boolean
    divergenciaPrecoItm: boolean
    contaD: string
    contaC: string
    criadoPor: string
    criadoEm: string
    status: string
    codigoEmpresa: number
}
