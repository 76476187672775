import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  AtribuicaoDeAcessoCabecalho,
  AtribuicaoDeAcessoItem,
} from "../ajustes/atribuicao-de-acesso/model";
import { environment } from "../../environments/environment";
import {
  ItemPerfil,
  MenuMaster,
} from "../ajustes/perfil-de-acesso-usuario/model";
import { FiltrarDadosService } from "./filtrar-dados.service";
import { SessaoUser } from "../user.reg";

const sessao = new SessaoUser();
@Injectable({
  providedIn: "root",
})
export class AtribuicaoDeAcessoService {
  getMenuMaster(codigo: any): any {
    throw new Error("Method not implemented.");
  }
  listarPerfilCabecalho(selectEmpresa: any) {
    throw new Error("Method not implemented.");
  }
  url = new environment().api;
  apiAtribuicaoCabecalho = "AtribuicaoDeAcessoCabecalho/";
  apiAtribuicaoItem = "AtribuicaoDeAcessoItem/";
  apiMenuMaster = "MenuMaster/";

  constructor(private http: HttpClient, private _filter: FiltrarDadosService) {}

  async listarCabecalho(codigoEmpresa) {
    let result: AtribuicaoDeAcessoCabecalho[] = [];
    await this.http
      .get<any>(
        this.url +
          this.apiAtribuicaoCabecalho +
          "JoinFiltrarCodigoEmpresa/" +
          codigoEmpresa
      )
      .toPromise()
      .then((res) => {
        if (res.success) {
          let registros = res.result.filter(
            (value) => value.status == "SDA0001"
          );
          if (codigoEmpresa > 0) {
            result = this._filter.filterTodos(registros);
          } else {
            result = this._filter.inserirNomeEmpresa(registros);
          }
        }
      });
    return result;
  }

  async listarAtribuicaoDeAcessoItem(codigoEmpresa, codigoAtribuicaoAcesso) {
    let result: AtribuicaoDeAcessoItem[] = [];
    await this.http
      .get<AtribuicaoDeAcessoItem[]>(this.url + this.apiAtribuicaoItem)
      .toPromise()
      .then((res) => {
        let registros = res.filter(
          (value) =>
            value.status == "SDA0001" &&
            value.codigoAtribuicaoAcesso == codigoAtribuicaoAcesso &&
            value.codigoEmpresa == codigoEmpresa
        );
        result = registros;
      });
    return result;
  }

  listarMenuMaster() {
    return this.http.get<MenuMaster[]>(this.url + this.apiMenuMaster);
  }

  cadastrarCAB(registro: AtribuicaoDeAcessoCabecalho) {
    registro.criadoPor = sessao.cpf;
    registro.status = "SDA0001";
    Object.keys(registro).forEach((value) => {
      if (!registro[value]) delete registro[value];
    });
    console.log("inserir", registro);
    return this.http.post(this.url + this.apiAtribuicaoCabecalho, registro);
  }

  async cadastrarITEM(registro: AtribuicaoDeAcessoItem) {
    let result = false;
    registro.criadoPor = sessao.cpf;
    registro.status = "SDA0001";
    Object.keys(registro).forEach((value) => {
      if (!registro[value]) delete registro[value];
    });

    await this.http
      .post(this.url + this.apiAtribuicaoItem, registro)
      .toPromise()
      .then((res) => {
        result = true;
      });
    return result;
  }

  alterCAB(registro: AtribuicaoDeAcessoCabecalho) {
    Object.keys(registro).forEach((value) => {
      if (!registro[value]) delete registro[value];
    });
    console.log("inserir", registro);
    return this.http.put(
      this.url +
        this.apiAtribuicaoCabecalho +
        `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
      registro
    );
  }
  async desativeITEM(registro: AtribuicaoDeAcessoItem) {
    let result = false;
    registro.status = "SDA0002";

    Object.keys(registro).forEach((value) => {
      if (!registro[value]) delete registro[value];
    });

    await this.http
      .put(
        this.url +
          this.apiAtribuicaoItem +
          `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
        registro
      )
      .toPromise()
      .then((res) => {
        result = true;
      });
    return result;
  }
  async resetCAB(registro: AtribuicaoDeAcessoCabecalho) {
    let result = new AtribuicaoDeAcessoCabecalho();
    await this.http
      .get<AtribuicaoDeAcessoCabecalho>(
        this.url + this.apiAtribuicaoCabecalho + registro.id
      )
      .toPromise()
      .then((res) => {
        result = res;
      });
    return result;
  }
}
