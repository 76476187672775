import { Component, OnInit } from '@angular/core';
import { FormPaciente } from '../cadastro-paciente/model';
import { FormUsuario } from '../usuario/model';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PacienteService } from '../service/paciente.service';
import { UsuarioService } from '../service/usuario.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-atendimento-clinico-pesquisa',
  templateUrl: './atendimento-clinico-pesquisa.component.html',
  styleUrls: ['./atendimento-clinico-pesquisa.component.scss']
})
export class AtendimentoClinicoPesquisaComponent implements OnInit {
  tipoPesquisa = true
  abrirCadatro: boolean;
  loading: boolean;
  cpf: any;
  mensagemErro: string;
  registro: FormUsuario;
  registroPaciente: any;
  verificar: boolean;
  paciente: any;
  companyId: any;
  pesquisarForm: FormGroup;
  usuarioId: string;
  atendimento = false
  id: string;
  pacienteId: string
  erro: boolean;
  block = false;
  constructor(
    private form: FormBuilder,
    private servicePaciente: PacienteService,
    private service: UsuarioService,
    private url: ActivatedRoute) { }

  async ngOnInit() {
    await this.urlID()
    await this.createForm()
    if (!this.usuarioId || this.usuarioId.length < 20) {
      this.erro = true
      this.block = true
      this.atendimento = false
      this.mensagemErro = 'SEM ID'

    }
  }
  async urlID() {
    await this.url.url.subscribe(async reg => {
      console.log('Filtro :' + reg[1].path)
      this.usuarioId = reg[1].path
    });
  }
  async pesquisarPaciente() {
    this.atendimento = false
    this.abrirCadatro = false
    this.loading = true
    this.servicePaciente.listarPaciente().toPromise()
      .then(
        async dados => {
          if (!dados.success) return
          // if(!this.registro.cpf) return
          let cpf = this.cpf
          let companyId = this.companyId
          let filtro = await dados.result.filter(function (reg) {
            // console.log('SEU CPF : ' + cpf + ' -- '+reg.cpf)
            return reg.cpf == cpf
          })
          if (filtro.length) {
            this.registro = filtro[0]
            this.loading = false
            this.verificar = true
            this.registroPaciente = filtro[0]
            this.pacienteId = this.registroPaciente.id
            this.atendimento = true
            this.erro = false
            localStorage.setItem('indexRefAv', "false")
            console.log('PacienteID : ' + this.pacienteId)
            console.log('UsuarioID : ' + this.usuarioId)
          }
          else {
            this.pesquisarUsuario()
          }
          // console.log('PACIENTE legth ' + JSON.stringify(filtro) + ' CPF : ' + this.registro.cpf)

          console.log('registroPaciente : ' + JSON.stringify(this.registroPaciente))
    
          //  this.verificarConexao = conexoes[0].companyId
          console.log('DADOS PACIENTE ' + JSON.stringify(this.registroPaciente))
        }
      )
    this.abrirCadatro = true

  }
  pesquisarUsuario() {
    this.abrirCadatro = false
    this.loading = true
    if (this.tipoPesquisa) {
      let cpf = this.cpf
      this.service.listar().toPromise().then(
        (dados: FormUsuario[]) => {
          if (!dados) {
            this.loading = false
            this.erro = true
            this.mensagemErro = 'ERRO! Não obteve registro '
            console.log('ERRO :' + JSON.stringify(dados))
            console.log('Sem registro')
            this.atendimento = true
            return
          }
          let filtrar = dados.filter(function (reg: FormUsuario) {
            return reg.cpf == cpf
          })
          if (!filtrar.length) {
            this.loading = false
            this.verificar = false
            this.loading = false
            this.verificar = false
            this.erro = true
            this.mensagemErro = `CPF : "${cpf}" não encontrado`
            // this.pesquisarPaciente()
            return
          }
          this.registro = filtrar[0]
          this.usuarioId = this.registro.usuarioId
          this.pacienteId = this.registro.id
          localStorage.setItem('indexRefAv', "false")
          this.erro = false
          console.log('SEU ID : ' + this.usuarioId)
          this.atendimento = true
          this.loading = false


        }
      )
    }
    else {
      let paciente = this.paciente
      this.service.listar().toPromise().then(
        (dados: FormUsuario[]) => {
          let filtrar = dados.filter(function (reg: FormUsuario) {
            return reg.nome.toUpperCase() == paciente.toUpperCase()
          })
          console.log(JSON.stringify(filtrar))
          this.registro = filtrar[0]
          this.usuarioId = this.registro.usuarioId
          console.log('SEU ID : ' + this.usuarioId)
          if (filtrar.length) {
            this.loading = false
            return
          }
          this.loading = false
          this.mensagemErro = `Paciente "${paciente}" não encontrado`


        }
      ).catch(
        () => { this.loading = false, this.mensagemErro = "Paciente não encontrado" }
      )
    }
    this.abrirCadatro = true
  }

  createForm() {
    this.pesquisarForm = this.form.group({
      tipoPesquisa: this.tipoPesquisa,
      cpf: new FormControl('', Validators.required),
      nome: new FormControl('', [Validators.required, Validators.minLength(5)])
    })
  }

}
