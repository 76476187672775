import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import { cadastroUsuario } from 'src/app/admin-usuarios/model';
import { CadastroPessoaForm, GetDependentes } from 'src/app/cadastro-paciente/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { FotoPerfilService } from 'src/app/service/foto-perfil.service';
import { HubDevService } from 'src/app/service/hub-dev.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { SessaoUser } from 'src/app/user.reg';

const sessao = new SessaoUser
const JsonPais = './assets/json/json-paises.json'
const JsonEstados = './assets/json/estados-cidades.json'
@Component({
  selector: 'app-cadatro-dependente',
  templateUrl: './cadatro-dependente.component.html',
  styleUrls: ['./cadatro-dependente.component.scss']
})
export class CadatroDependenteComponent implements OnInit {
  formulario: FormGroup
  formularioUsuario: FormGroup;

  loadingConteudo: boolean;
  listProfissao: ConteudoDados[] = [];
  listEspecialidade: ConteudoDados[] = [];
  listTipoTratamento: ConteudoDados[] = [];
  listTipoInscricao: ConteudoDados[] = [];
  listTipoSituacao: ConteudoDados[] = [];
  operadoraSaude: ConteudoDados[] = [];
  planoSaude_backup: ConteudoDados[] = [];
  planoSaude: ConteudoDados[] = [];
  listCorDePele: ConteudoDados[] = [];
  listDeficiencia: ConteudoDados[] = [];
  listEstadoCivil: ConteudoDados[] = [];
  operadoraSaude_bakcup: ConteudoDados[] = [];
  listGrauInstrucao: ConteudoDados[] = [];
  listCertidao: ConteudoDados[] = [];
  listOrgEmissor: ConteudoDados[] = [];
  listTipDoc: ConteudoDados[] = [];

  listEstados = [];
  listCidades = [];
  listCidadesBackup = [];
  listCidadesVT = [];
  listCidadesVTBackup = [];
  modificar: boolean;
  loading: boolean;
  viewCampRequired: boolean;
  viewCadastro: boolean;
  fotoPerfil: any;
  listPaises: any;
  dependentes: GetDependentes[] = [];
  old = false
  viewConfirmDelete: boolean;
  loadingValidForm: boolean;
  viewFotoPerfil: boolean;


  constructor(
    private form: FormBuilder,
    private _conteudo: ConteudoDeDadosService,
    private _mensagem: MensagemService,
    private http: HttpClient,
    private _hub: HubDevService,
    private _filter: FiltrarDadosService,
    private _usuario: UsuarioService,
    private _data: BuscarDataService,
    private service_foto: FotoPerfilService,
    private validateBrService: ValidateBrService,) {

  }

  async ngOnInit() {
    await this.createForm()
    await this.getConteudo()
    await this.getRegistro()


  }
  async getRegistro() {
    this.loading = true
    let getDependentes = await this._usuario.getDependentes()
    this.dependentes = getDependentes.filter(v => v.dadosDependente.status == 'SCA0001')
    this.dependentes.forEach((value) => {
      let nasc = this._filter.convertCriadoEm(value.cadastroUsuario.dataNascimento)
      let idade = this._data.idade(nasc)
      value.dadosDependente.idade = idade
      this._filter.inserirFotoPerfilEach('cpf', value.cadastroUsuario)
    })
    console.log('/---/', this.dependentes)
    this.loading = false
  }

  verificCadastro(item: GetDependentes) {
    if (item.dadosDependente.id) {
      let d = item.dadosDependente
      if (d.cep && d.numeroEndereco) {
        if (d.cartaoSUS && !d.possuiPlanoDeSaude) {
          return true
        }
        if (!d.cartaoSUS && d.possuiPlanoDeSaude && d.operadoraConvenio && d.operadoraConvenio && d.operadoraConvenio) {
          return true
        }
        if (d.cartaoSUS && d.possuiPlanoDeSaude && d.operadoraConvenio && d.operadoraConvenio && d.operadoraConvenio) {
          return true
        }
        return false
      }
      else return false

    }
  }
  async getConteudo() {
    this.formulario.disable()
    await this.http.get(JsonEstados).toPromise().then((res: any) => this.listEstados = res.estados)
    this.loadingConteudo = true
    this.listCorDePele = await this._conteudo.get('CORPELE')
    this.listEstadoCivil = await this._conteudo.get('ESTCIVIL')
    this.listDeficiencia = await this._conteudo.get('TIPDEF')
    this.http.get(JsonPais).toPromise().then((res: any) => this.listPaises = res)
    this.http.get(JsonEstados).toPromise().then((res: any) => this.listEstados = res.estados)
    this.operadoraSaude = await this._conteudo.get('OPESAU')
    this.operadoraSaude_bakcup = await this._conteudo.get('OPESAU')
    this.planoSaude = await this._conteudo.get('PLANOMED')
    this.planoSaude_backup = await this._conteudo.get('PLANOMED')
    this.listGrauInstrucao = await this._conteudo.get('GRINST')
    this.listCertidao = await this._conteudo.get('TPCERTID')
    this.listOrgEmissor = await this._conteudo.get('ORGEMI')
    this.listTipDoc = await this._conteudo.get('TPDOCID')
    this.loadingConteudo = false
    this.formulario.enable()

  }
  async getFotoPerfil() {
    this.fotoPerfil = await this.service_foto.buscarFoto(this.formularioUsuario.get('cpf').value)
    //console.log('fotoPerfil', this.fotoPerfil)
    // 
  }

  getCidade(estado) {
    let getCidades = this.listEstados.filter(value => value.sigla == estado)
    if (getCidades.length < 1) return
    this.listCidades = getCidades[0].cidades
    this.listCidadesBackup = getCidades[0].cidades

  }
  getCidadeVotacao(estado) {
    let getCidades = this.listEstados.filter(value => value.sigla == estado)
    if (getCidades.length < 1) return
    this.listCidadesVT = getCidades[0].cidades
    this.listCidadesVTBackup = getCidades[0].cidades

  }
  getCep() {
    if (this._control('cep').valid) {
      this._hub.vCEP(this._control('cep').value).toPromise()
        .then(res => {
          if (res.status) {
            this._control('bairro').setValue(res.result.bairro)
            this._control('endereco').setValue(res.result.logradouro)
            this._control('cidade').setValue(res.result.localidade)
            this._control('complemento').setValue(res.result.complemento)
            this._control('estado').setValue(res.result.uf)

          } else {
            this._control('bairro').setValue('')
            this._control('endereco').setValue('')
            this._control('cidade').setValue('')
            this._control('complemento').setValue('')
            this._control('estado').setValue('')
          }
        })
    }
  }

  async inserir() {

    this.viewCampRequired = false

    let registro: CadastroPessoaForm = this.formulario.getRawValue()
    let registroUser: cadastroUsuario = this.formularioUsuario.getRawValue()


    if (!registro.criadoPor) registro.criadoPor = sessao.cpf

    if (this.formularioUsuario.invalid) {
      this._mensagem.creat('Para salvar é necessario preencher todos os campos obrigátorios da sessão Dados de usuário', { erro: true })
      this.loading = false
      return
    }

    registro.cpf = registroUser.cpf
    registro.status = 'SCA0001'
    registro.cpfResponsavelTitular = sessao.cpf
    registro.tipoPessoa = 'TIPPES2'

    registroUser.status = 'SDO0066'
    registroUser.tipoUsuario = 'TIPUSU5'
    registroUser.dataNascimento = this._filter.formatDate(registroUser.dataNascimento)

    Object.keys(registro).forEach(value => { if (!registro[value]) delete registro[value] })
    Object.keys(registroUser).forEach(value => { if (!registroUser[value]) delete registroUser[value] })

    let validForm = await this.validarCPF()
    if (!validForm.status) {
      this._mensagem.creat(validForm.message, { erro: true })
      return
    }

    this.loading = true
    await this._usuario.cadastrarPessoa(registro).toPromise()
      .then(async res => {
        if (registro.id) {
          this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
        } else {
          this._mensagem.creat('Dependente criado com sucesso', { sucesso: true })
        }
        await this._usuario.cadastrarUsuario(registroUser).toPromise()
          .then(res => {
            this.formularioUsuario.disable()
          })
          .catch(error => {
            this._mensagem.creat('Não foi possivel alterar usuario', { erro: true })
            console.log('error', error)
          })
        this.formulario.disable()
      })
      .catch(error => {
        this._mensagem.creat('Não foi possivel alterar registro', { erro: true })
        console.log('error', error)
      })
    this.loading = false

  }
  async desativarDependente(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    let registro = this.formulario.getRawValue() as CadastroPessoaForm
    registro.status = 'SDO00151'

    await this._usuario.cadastrarPessoa(registro).toPromise()
      .then(res => {
        this._mensagem.creat('Dependente desvinculado com sceusso', { sucesso: true })
        this.viewCadastro = false
        this.ngOnInit()
      }).catch(error => {
        this._mensagem.creat('Não foi possivel desvinculado dependente', { erro: true })
        console.log('erro = >', error)

      })
  }

  async openCadastro(item: GetDependentes) {
    await this.formulario.reset()
    await this.formulario.enable()
    await this.formularioUsuario.reset()
    await this.formularioUsuario.enable()
    await this.formulario.patchValue(item.dadosDependente)
    await this.formularioUsuario.patchValue(item.cadastroUsuario)
    await this.formulario.disable()
    await this.formularioUsuario.disable()
    console.log('formulario', this.formularioUsuario.getRawValue())
    this.viewCadastro = true
    this.fotoPerfil = item.cadastroUsuario.fotoPerfil
  }

  async openNewCadastro() {
    this.viewCadastro = true
    this.formulario.reset()
    this.formularioUsuario.reset()
    this.formulario.enable()
    this.formularioUsuario.enable()
    // let getRegistroTitular = await this._usuario.getCadastroPessoa(sessao.cpf)
  }
  async cancelarRegistro() {
    try {
      const idUser = this.formularioUsuario.get('id').value
      const idPessoa = this.formulario.get('id').value
      let getUser = await this._usuario.getID(idUser)
      let getPessoa = await this._usuario.getIDCadPessoa(idPessoa)
      await this.formulario.enable()
      await this.formularioUsuario.enable()
      await this.formularioUsuario.patchValue(getUser)
      await this.formulario.patchValue(getPessoa)
      await this.formulario.disable()
      await this.formularioUsuario.disable()
    } catch {
      this._mensagem.creat('Falha ao buscar registro', { erro: true })
    }

  }
  fecharCadastro() {
    this.viewCadastro = false
    this.getRegistro()
  }
  converConteudo(type, value) {
    let result = ''
    switch (type) {
      case 'CORPELE':
        result = this.listCorDePele.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
      case 'TIPDEF':
        result = this.listDeficiencia.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
      case 'ESTCIVIL':
        result = this.listEstadoCivil.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
    }
    return result
  }
  createForm() {
    this.formulario = this.form.group({
      id: '',
      cpf: new FormControl(''),
      nome: new FormControl(''),
      nomeMae: new FormControl('', Validators.required),
      nomePai: new FormControl(''),
      cpfMae: new FormControl(''),
      cpfPai: new FormControl(''),
      cor: new FormControl('', Validators.required),
      deficiente: new FormControl('', Validators.required),
      tipoDeficiencia: new FormControl(''),
      sexo: new FormControl('', Validators.required),
      estadoCivil: new FormControl('', Validators.required),
      grauInstrucao: new FormControl(''),
      nacionalidade: new FormControl('', Validators.required),
      dataChegadaBrasil: new FormControl(''),
      naturalidade: new FormControl('', Validators.required),
      estadoNaturalidade: new FormControl('', Validators.required),

      rg: new FormControl('', Validators.required),
      dataEmissao_RG: new FormControl('', Validators.required),
      tipoDocID: new FormControl(''),
      orgao: new FormControl('', Validators.required),
      habilitacao: new FormControl(''),
      categoria: new FormControl(''),
      validade: new FormControl(''),
      carteiraReservista: new FormControl(''),
      tituloDeEleitor: new FormControl(''),
      tituloEleitor: new FormControl(''), ////////////Verificar
      cidadeVotacao: new FormControl(''),
      estado_TE: new FormControl(''),
      zona: new FormControl(''),
      sessao: new FormControl(''),
      ric: new FormControl(''),
      passaPorte: new FormControl(''),
      certidaoTipo: new FormControl(''),
      certidaoLivro: new FormControl(''),
      certidaoFolha: new FormControl(''),
      certidaoMatricula: new FormControl(''),

      telefone: new FormControl(''), // remover
      telefoneFixo: new FormControl(''),
      telefoneComercial: new FormControl(''),

      cep: new FormControl('', Validators.required),
      endereco: new FormControl('', Validators.required),
      numeroEndereco: new FormControl('', Validators.required),
      complemento: new FormControl(''),
      bairro: new FormControl('', Validators.required),
      cidade: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),

      cartaoSUS: new FormControl(''),
      possuiPlanoDeSaude: new FormControl(false),
      planoDeSaude: new FormControl(''),
      numeroBeneficiario: new FormControl(''),
      operadoraConvenio: new FormControl(''),
      nrCarteiraTrabalho: new FormControl(''),
      serie: new FormControl(''),
      digito: new FormControl(''),
      dataEmissao_CT: new FormControl(''),
      uf_CT: new FormControl(''),
      pis: new FormControl(''),
      bancoPIS: new FormControl(''),
      contaFGTS: new FormControl(''),
      dataOpcao: new FormControl(''),

      cpfResponsavelTitular: new FormControl(''),
      cpfTutorLegal: new FormControl(''),
      cpfConjuge: new FormControl(''),
      grauParentescoSecundario: new FormControl(''),
      grauParentescoTitular: new FormControl(''),

      ativo: new FormControl(true), //Reomover
      tipoPessoa: new FormControl(''),
      usaMyClinics: new FormControl(true), //remover
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl(''),
    })
    this.formularioUsuario = this.form.group({
      id: '',
      nomeCompleto: new FormControl('', [Validators.required, Validators.minLength(5)]),
      nomeSocial: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      dataNascimento: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, this.validateBrService.cpf]),
      telefone: new FormControl(''),
      status: 'SDO0066',
      tipoUsuario: 'TIPUSU5',
      usuarioId: '',
      numeroDocumento: '',
      dataInclusaoDocumento: '',
      termosDeAceite: '',
      criadoEm: '',
      criadoPor: sessao.cpf,
    })
  }
  async useEnderecoTitular(checked) {
    if (checked) {
      let getRegistroTitular = await this._usuario.getCadastroPessoa(sessao.cpf)
      this.formulario.get('cep').setValue(getRegistroTitular.cep)
      this.formulario.get('numeroEndereco').setValue(getRegistroTitular.numeroEndereco)
      this.getCep()
    } else {
      this.formulario.get('cep').reset()
      this.formulario.get('endereco').reset()
      this.formulario.get('bairro').reset()
      this.formulario.get('numeroEndereco').reset()
      this.formulario.get('complemento').reset()
      this.formulario.get('cidade').reset()
      this.formulario.get('estado').reset()
    }
  }
  async useEmailTitular(checked) {
    if (checked) {
      let getRegistroTitular = await this._usuario.getRegistro(sessao.cpf)
      console.log('-->', getRegistroTitular)
      this.formularioUsuario.get('email').setValue(getRegistroTitular.email)
    } else {
      this.formularioUsuario.get('email').reset()
    }
  }

  async validarCPF() {
    this.loadingValidForm = true
    const cpf = this.formularioUsuario.controls['cpf'].value
    let nascimento: string = this.formularioUsuario.controls['dataNascimento'].value
    nascimento = this._filter.formatDateRev(nascimento)
    console.log('nascimento!', nascimento)
    const nome = this.formularioUsuario.controls['nomeCompleto'].value
    let registro = this.formulario.value
    let n = registro.nascimento
    let response = await this._hub.vCPF(cpf, nascimento, nome, true)
    this.loadingValidForm = false
    console.log('response', response)
    return response
  }
  ConverterData(data) {
    return this._filter.convertCriadoEm(data)
  }
  _control(control) {
    return this.formulario.controls[control]
  }
  _valid(control) {
    return this.formulario.get(control).hasError('required')
  }
  _validUser(control) {
    return this.formularioUsuario.get(control)
  }
  _validControl(control) {
    return this.formulario.get(control).valid
  }
  selectOperadoraSaude(item) {
    let filter = this.planoSaude_backup.filter(value => value.dependencia == item)
    this.planoSaude = filter
  }
  filterPlanoSaude(value: string) {
    let registros = this.operadoraSaude_bakcup
    let result = registros.filter(item => item.valor.toUpperCase().includes(value.toUpperCase()))
    this.operadoraSaude = result
  }
  filtrarCidade(value: string) {
    let registros = this.listCidadesBackup
    let result = registros.filter(item => item.toUpperCase().includes(value.toUpperCase()))
    this.listCidades = result
  }
  filtrarCidadeVT(value: string) {
    let registros = this.listCidadesVTBackup
    let result = registros.filter(item => item.toUpperCase().includes(value.toUpperCase()))
    this.listCidadesVT = result
  }
}
