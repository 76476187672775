import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DicionarioDeDadosService {
  url = new environment().api
  api = "MenuMaster"
  constructor(private http : HttpClient) { }
  
  buscarRegistro(){
    return this.http.get(this.url+this.api)
  }
}
