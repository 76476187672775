import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { FichaGeralFornecedor } from '../cadastro-fornecedor/CadastroFornecedorModels';

const user = new SessaoUser


@Injectable({
    providedIn: 'root'
})
export class FichaGeralFornecedorService {
    url = new environment().api +  'AJ_CADFORN/'
   // url = 'https://localhost:5001/api/AJ_CADFORN/'

    constructor(private http: HttpClient,private data: BuscarDataService) {
    }

    Post(registro)
    {
        return this.http.post(this.url,registro)
    }
    async get(codigoEmpresa) {
        let result: FichaGeralFornecedor[] = []
        await this.http.get<FichaGeralFornecedor[]>(`${this.url}`).toPromise()
            .then(res => {
                result = res.filter(value => value.codigoEmpresa == codigoEmpresa)
            })
        return result
    }

    Get()
    {
        return this.http.get(this.url)
    }

    GetFiltrarCodigoEmpresa(codigoEmpresa)
    {
        return this.http.get(`${this.url}FiltrarCodigoEmpresa/${codigoEmpresa}`) 
    }

   async GetById(id)
    {
      let registro = new AJ_CADFORNmodel

        await this.http.get<AJ_CADFORNmodel>(this.url + id).toPromise()
        .then(result => registro = result).catch(err => console.log(err))
        return registro
    }

    GetJoinFiltrarCodigoEmpresa(codigoEmpresa)
    {
        return this.http.get(`${this.url}JoinFiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    Put(registro)
    {
        return this.http.put(`${this.url}${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}`, registro)
    }

}

export class AJ_CADFORNmodel
{
    id?: string
    codigoFornecedor?: number
    codigoEmpresa?: number
    nomeFornecedor?: string
    cep?: string
    rua?: string
    cidade?: string
    bairro?: string
    estado?: string
    numeroEndereco?: string
    complementoEndereco?: string
    webSite?: string
    telefone?: string
    email?: string
    nomeContato?: string
    ativoDesde?: string
    inativoDesde?: string
    areaAtuacao?: string
    servicos?: string
    cnpj?: string
    prazoPagamento?: string
    formaPagamento?: string
    banco?: string
    paisBanco?: string
    agencia?: string
    contaBancaria?: string
    autorizacaoPagamento?: string
    observacao?: string
    criadoPor?: string
    criadoEm?: string
    status?: string
}


