import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BuscarDataService } from '../buscar-data.service';
import { SaldoContaContabil } from './modal';

@Injectable({
  providedIn: 'root'
})
export class SaldoContaContabilService {

  url = new environment().api
  api = "SaldoContaContabil/"

  constructor(private http: HttpClient, private data: BuscarDataService) { }

  async get(codigoEmpresa) {
    let result = []
    await this.http.get<SaldoContaContabil[]>(this.url + this.api + 'FiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
      .then(res => result = res)
      .catch(error => { console.log('erro SaldoContaContabil/FiltrarCodigoEmpresa', error) })
    return result
  }
}
