import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder,  } from '@angular/forms';
import {  FormControl, Validators } from '@angular/forms';
import { SessaoUser } from 'src/app/user.reg';
import { camposConteudosDados } from './model.camposConteudo';
import { camposCaracteristicas } from '../CaracteristicasDados/caracteristicas-de-dados.model';
import { SistemaClassificacaoDadosService } from '../../service/sistema-classificacao-dados.service';
import { MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import { MensagemService } from 'src/app/service/mensagem.service';

const user = new SessaoUser


@Component({
  selector: 'app-sistema-classificacao-dados',
  templateUrl: './sistema-classificacao-dados.component.html',
  styleUrls: ['./sistema-classificacao-dados.component.scss']
})
@Pipe({ name: 'groupBy' })

export class SistemaClassificacaoDadosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  id: number

  IdConteudoDados: string

  ConteudoDados: Array<camposConteudosDados>

  ConteudoID: camposConteudosDados

  Caracteristias: camposCaracteristicas

  detalhesConteudo: camposConteudosDados = new camposConteudosDados

  detalhes = false

  alterar = false
  loadingOpenCad: boolean;

  formulario: FormGroup

  viewCadastro: boolean;

  alteracao: boolean;

  buttonAdicionar = false
  detalhesDados
  registroIcon
  galeriaIcon
  loading
  detalhe
  selecionado
  applyFilter
  constructor(
    private mensagem: ToastrService,
    private routerCtrl: Router,
    private SistemaClassificacaoService: SistemaClassificacaoDadosService,
    private message: MensagemService,
    private form: FormBuilder
  ) {

  }
  ngOnInit() {


    setInterval(() => {
      this.buscarConteudoDados()
    }, 2000)

    this.buscarCaractetisticas()
  }

  buscarCaractetisticas() {
    this.SistemaClassificacaoService.GetCaracteristicas().subscribe(
      (resultado:any) => {
        this.Caracteristias = resultado
        console.log("Sucesso: " + JSON.stringify(this.Caracteristias))
      }), (erro => {
        console.log("Erro: " + erro)
      })
  }

  async AlterarConteudoDados() {
    this.detalhesConteudo.status = 2
    await this.SistemaClassificacaoService.putConteudoOld(this.id, this.detalhesConteudo.status)
      .subscribe(
        (data => {
          console.log("Alterado com Sucesso: " + JSON.stringify(data))
          this.mensagem.error('Registro Desativado!')
        })
        , error => console.log("Deu Erro: " + JSON.stringify(error))
      );
  }

  gravarDados() {
    this.detalhesConteudo.status = 1
    this.SistemaClassificacaoService.postConteudo(this.detalhesConteudo).subscribe(
      (sucesso => {
        console.log("Salvo com sucesso: " + JSON.stringify(sucesso))
        this.mensagem.success('Gravado com Sucesso!')
      }), erro => {
        (console.log("DEu erro: " + erro))
      })

    this.detalhesConteudo = new camposConteudosDados()
  }

  buscarConteudoDados() {
    this.SistemaClassificacaoService.getConteudo().subscribe(
      (resultado: Array<camposConteudosDados>) => {
        let filtro = resultado.filter(function (reg: camposConteudosDados) {
          return reg.status == 1
        })
        this.ConteudoDados = filtro
        //console.log("Filtro Conteudo Dados: " + JSON.stringify(this.ConteudoDados));
      })
  }

  buscarConteudoDadosID() {
    console.log("ID: " + this.IdConteudoDados)
    this.SistemaClassificacaoService.getConteudoID(this.IdConteudoDados).subscribe(
      (sucesso => {
        this.ConteudoID = sucesso
        console.log("Dados Api: " + JSON.stringify(sucesso))
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }

  async OpenAlterar(registro){
    this.alteracao = true
    this.formulario.setValue(registro)
    this.viewCadastro = true
    
  }

  async openCadastro(registro) {
    this.loadingOpenCad = true
    this.alteracao = true

   // await this.getCaracteristicas('ATUMED')
    //await this.getCaracteristicas('SERMED')

    this.creatForm()

    this.formulario.patchValue(registro)

    this.formulario.disable()
    this.viewCadastro = true
    this.loadingOpenCad = false
    setTimeout(() => {
    
    }, 2000)

  }

  fecharCadastro() {
    this.formulario.reset()
    this.formulario.enable()
    this.alteracao = false
    this.viewCadastro = false

  }

  creatForm() {   
    this.formulario = this.form.group({
      id: '',
      criadoPor: '',
      codigoDaCaracteristica: new FormControl('', Validators.required),
      codValor: '',
      codigoReferencia: '',
      valor: '',
      status: '',
      codigoDeNegocio: '',
      descricao: '',
      dependencia:''
    })
  }

  Inserir() {
    let registro = this.formulario.value
    registro.criadoPor = user.cpf
    delete registro.id
    console.log('Registro: ', JSON.stringify(registro))
    this.SistemaClassificacaoService.postConteudo(registro).subscribe(
      (resultado => {
        console.log("Salvo com sucesso: " + JSON.stringify(resultado))
        this.mensagem.success('Gravado com Sucesso!')
      }), erro => {
        (console.log("DEu erro: " + erro.error))
      })

  }

  AlterarRegistro(){
    let registro  = this.formulario.value

    if(!registro.id) return

    this.SistemaClassificacaoService.PutConteudoDeDados(registro).toPromise().then(
      res => {
        console.log(res)
        this.message.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
        this.buscarCaractetisticas()
        this.buscarConteudoDados()
        this.alteracao = false
        this.viewCadastro = false
      }
    ).catch(
      error => console.log(error.error)
      
    )
  }

}

