import { Component, OnInit } from '@angular/core';
import OrgChart from "@balkangraph/orgchart.js";
@Component({
  selector: 'app-organograma',
  templateUrl: './organograma.component.html',
  styleUrls: ['./organograma.component.scss']
})
export class OrganogramaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var chart = new OrgChart(document.getElementById('tree'), {
      enableSearch: false,
      layout: OrgChart.mixed,
      nodeBinding: {
        field_0: "name",
        field_1: "title",
        img_0: "img"
      },
    });
    

    chart.load([
      { id: 1, name: "Aurora TM", title: "Codigo empresa 4 ", img: "https://cdn.balkan.app/shared/2.jpg" },

      { id: 2, pid: 1, name: "Consultorios" },
      { id: 3, pid: 2, name: "Consultorio 2", img: "https://cdn.balkan.app/shared/4.jpg" },
      { id: 4, pid: 2, name: "Consultorio 3", title: "Sales", img: "https://cdn.balkan.app/shared/5.jpg" },

      { id: 6, pid: 1, name: "Itens de venda" },
      { id: 7, pid: 6, name: "Item de venda 1", },
      { id: 8, pid: 6, name: "Item de venda 2", },




      { id: 9, name: "MyClinics sandbox", title: "Codigo empresa 4 ", img: "https://cdn.balkan.app/shared/2.jpg" },

      { id: 10, pid: 9, name: "Consultorios" },
      { id: 11, pid: 10, name: "Consultorio 2", img: "https://cdn.balkan.app/shared/4.jpg" },
      { id: 12, pid: 10, name: "Consultorio 3", img: "https://cdn.balkan.app/shared/5.jpg" },

      { id: 13, pid: 9, name: "Itens de venda" },
      { id: 14, pid: 13, name: "Item de venda 1" },
      { id: 15, pid: 13, name: "Item de venda 2" },

    ]);
  }

  id() { return Math.floor(Math.random() * 100) }

}

