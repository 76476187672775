import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BuscarDataService } from 'src/app/service/buscar-data.service';

@Component({
  selector: 'app-convite',
  templateUrl: './convite.component.html',
  styleUrls: ['./convite.component.scss']
})
export class ConviteComponent implements OnInit {
  @Output('popUp') popUp = new EventEmitter()
  @Input() menu
  @Input() usuarioId
  formulario: FormGroup
  companyId = sessionStorage.getItem('companyId')
  data
  conviteOK: boolean;
  constructor(private form: FormBuilder, private _data: BuscarDataService) {
    this.data = _data.hoje()
  }

  ngOnInit() {
    this.creatForm()
  }
  creatForm() {
    this.formulario = this.form.group({
      usuarioId: this.usuarioId,
      companyId: this.companyId,
      convidado: new FormControl('', [Validators.required,Validators.minLength(5)]),
      medico: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      status: 'Pendente',
      data: this.data,
      ativo: true
    })
  }
  enviar(){
    this.conviteOK = true
  }
  reset(){
    this.conviteOK = false
    this.formulario.reset()
  }

}
