import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { MenuMaster } from "./modal";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Router } from "@angular/router";
import { UsuarioService } from "../../service/usuario.service";
import { FormUsuario } from "../../usuario/model";
import { BuscarDataService } from "../../service/buscar-data.service";
import { ToastrService } from "ngx-toastr";
import { MonitorClinicoService } from "../../service/monitor-clinico.service";
import { AgendaForm, HorariosForm } from "../../agenda/agenda/model";
import { AgendaService } from "../../service/agenda.service";
import { MatSnackBar } from "@angular/material";
import { environment } from "src/environments/environment";
import { VideoChamadaService } from "../../service/video-chamada.service";
import { videoChamadaFrom } from "../../atendimento-clinico/sala-espera/model";
import { MensagemService } from "../../service/mensagem.service";
import { LoginService } from "../../service/login.service";
import { DiarioDeAcessoService } from "../../service/diario-de-acesso/diario-de-acesso.service";
const url = new environment().api;
@Component({
  selector: "app-home",
  templateUrl: "./homeBusiness.component.html",
  styleUrls: ["./homeBusiness.component.scss"],
  host: { "window:beforeunload": "doSomething" },
})
export class HomeBusinessComponent implements OnDestroy {
  @ViewChild("videoElement", { static: false }) videoElement: ElementRef;
  video: any;
  estrutura_menu: MenuMaster[];
  sessao;
  esconderMenu: boolean = true;
  houver: boolean;
  houverInfo: boolean;
  houverUser: boolean;
  tituloPage: string;
  loading: boolean;
  hoje: any;
  horario: string;
  iconRotina: any;
  iconGS: any;
  iconAnalitico: any;
  iconRelacionamento: any;
  iconConfig: any;
  infoContato: any;
  popup = {
    salaEspera: false,
    senha: false,
    agenda: false,
    pesquisa: false,
    convidar: false,
  };
  modal: boolean;
  teste = "ola";
  cpf = sessionStorage.getItem("cpf");
  private _transformer = (node: MenuMaster, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      menuName: node.menuName,
      menuURL: node.menuURL,
      menuID: node.menuID,
      erp_paciente: node.erp_Paciente,
      erp_business: node.erp_Business,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<any>( //<------------------------------
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  codSessao: any;
  verificar: NodeJS.Timer;

  reservas = new AgendaForm();
  dataSelecionada: AgendaForm = {};

  verSolicitacoes: boolean;
  verHorariosLivres: boolean;
  reservaSelec: AgendaForm;
  horarios: any[];
  sugerirHorario: boolean;
  abrirCobranca: boolean;
  userName: string;
  codTransacao: string;
  usuarioId: string;
  menu: MenuMaster[];
  viewVideoChamada: boolean;
  infoVideoChamada: videoChamadaFrom;
  boxVideoChamada: boolean;
  verificarEncerramento: any;
  backgroud = {
    paciente: true,
    condutaMedica: false,
    servicos: false,
    saudeSuplementar: false,
    clienteCorporativo: false,
    SaudePopulacional: false,
    medicamentos: false,
    recursos: false,
    entidadesDeSaude: false,
    healthTechs: false,
    conversas: false,
  };
  mensagem: any;
  mensagemColor: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private service: LoginService,
    private data: BuscarDataService,
    private _videoChamada: VideoChamadaService,
    private toast: ToastrService,
    private _mensagem: MensagemService,
    private diarioDeAcessoService: DiarioDeAcessoService
  ) {
    this.hoje = this.data.hoje();
    setInterval(() => {
      this.horario = this.data.horaAgora();
    }, 1000);
    this.usuarioId = sessionStorage.getItem("tokken");
    this.userName = sessionStorage.getItem("userName");

    window.onbeforeunload = function (e) {};
    this._mensagem.clear();
  }

  async ngOnInit() {
    await this.construirMenu();
  }
  ngOnDestroy() {
    // this.diarioDeAcessoService.fazerLogout();
  }
  initCamera(config: any) {
    var browser = <any>navigator;

    browser.getUserMedia =
      browser.getUserMedia ||
      browser.webkitGetUserMedia ||
      browser.mozGetUserMedia ||
      browser.msGetUserMedia;

    browser.mediaDevices.getUserMedia(config).then((stream) => {
      this.video.src = window.URL.createObjectURL(stream);
      this.video.play();
    });
  }
  testeHouver(t) {
    console.log(t);
  }
  async construirMenu() {
    //<----------------------------------------------------------------
    this.http
      .get<Array<MenuMaster>>(`${url}MenuMaster`)
      .toPromise()
      .then((menu) => {
        const estrutura_menu = [];
        const filhos_submenu = [];
        let index = 0;
        let indexi = 0;
        let indexii = 0;
        let indexiii = 0;
        let contar = 0;
        // this.menu = menu.filter((r)=> r.tipoObjeto.substr(0, 8) == "Processo" || r.tipoObjeto.substr(0, 4) == "Menu" )
        this.menu = menu.filter((r) => r.tipoObjeto.substr(0, 4) == "Menu");
        menu.forEach((pai) => {
          console.log(pai);
          if (!pai.tipoObjeto) {
            return;
          }
          if (
            pai.parent_MenuID == "*" &&
            pai.tipoObjeto.substr(0, 8) == "Processo"
          ) {
            estrutura_menu.push({
              menuName: pai.menuName,
              menuID: pai.menuID,
              menuURL: pai.menuURL,
              ordem: pai.ordem,
              erp_Business: pai.erp_Business,
              erp_Paciente: pai.erp_Paciente,
              children: [],
            });
            menu.forEach((filho) => {
              if (!filho.tipoObjeto) {
                return;
              }
              if (
                pai.menuID == filho.parent_MenuID &&
                filho.tipoObjeto.substr(0, 4) == "Menu"
              ) {
                index = estrutura_menu.findIndex(
                  (x) => x.menuID == filho.parent_MenuID
                );
                if (index >= 0) {
                  estrutura_menu[index].children.push({
                    menuName: filho.menuName,
                    menuID: filho.menuID,
                    menuURL: filho.menuURL,
                    ordem: filho.ordem,
                    erp_Business: filho.erp_Business,
                    erp_Paciente: filho.erp_Paciente,
                    children: [],
                  });
                }
                menu.forEach((f2) => {
                  if (
                    filho.menuID == f2.parent_MenuID &&
                    f2.tipoObjeto.substr(0, 4) == "Menu"
                  ) {
                    indexi = estrutura_menu[index].children.findIndex(
                      (x) => x.menuID == f2.parent_MenuID
                    );
                    if (indexi >= 0) {
                      estrutura_menu[index].children[indexi].children.push({
                        menuName: f2.menuName,
                        menuID: f2.menuID,
                        menuURL: f2.menuURL,
                        ordem: f2.ordem,
                        children: [],
                      });
                    }
                  }
                  contar++;
                  // if(menu.length != contar){return}
                  // else{console.log('fim : ' +)}
                  menu.forEach((f3) => {
                    if (!f3.tipoObjeto) {
                      return;
                    }
                    try {
                      if (
                        f2.menuID == f3.parent_MenuID &&
                        f3.tipoObjeto.substr(0, 4) == "Menu"
                      ) {
                        if (
                          estrutura_menu[index].children[indexi].children
                            .length > 0
                        ) {
                          let indexii = estrutura_menu[index].children[
                            indexi
                          ].children.findIndex(
                            (x) => x.menuID == f3.parent_MenuID
                          );
                          // console.log('INDEX : ' + indexii)
                          if (indexii >= 0) {
                            // console.log('INDEX : ' + indexii)
                            var duplicated =
                              estrutura_menu[index].children[indexi].children[
                                indexii
                              ].children.findIndex((redItem) => {
                                return f3.menuName == redItem.menuName;
                              }) > -1;

                            if (!duplicated) {
                              estrutura_menu[index].children[indexi].children[
                                indexii
                              ].children.push({
                                menuName: f3.menuName,
                                menuID: f3.menuID,
                                menuURL: f3.menuURL,
                                ordem: f3.ordem,
                                children: [],
                              });
                            }
                          }
                        }
                        menu.forEach((f4) => {
                          if (!f4.tipoObjeto) {
                            return;
                          }
                          try {
                            if (
                              f3.menuID == f4.parent_MenuID &&
                              f4.tipoObjeto.substr(0, 4) == "Menu"
                            ) {
                              // console.log(estrutura_menu[index].children[indexi].children[indexii].children)
                              let indexiii = estrutura_menu[index].children[
                                indexi
                              ].children[indexii].children.findIndex(
                                (x) => x.menuID == f4.parent_MenuID
                              );
                              console.log("INDEX : " + indexiii);
                              if (indexiii >= 0) {
                                console.log("PASSOUUUUU!" + f4.menuName);
                                estrutura_menu[index].children[indexi].children[
                                  indexii
                                ].children[indexiii].children.push({
                                  menuName: f4.menuName,
                                  menuID: f4.menuID,
                                  menuURL: f4.menuURL,
                                  ordem: f4.ordem,
                                  children: [],
                                });

                                // var duplicated = estrutura_menu[index].children[indexi].children[indexii].children[indexiii].findIndex(x => {
                                //   return f4.menuName == x.menuName;
                                // }) ;
                                // console.log('duplicated : ', duplicated)
                                // if (duplicated < 0) {
                                //   console.log('TESSTE',estrutura_menu[index].children[indexi].children[indexii].children[indexiii].children)
                                // }
                              }
                            }
                          } catch (erro) {
                            console.log(erro);
                          }
                        });
                      }
                    } catch {
                      return;
                    }
                  });
                });

                // console.log(`Pai : ${pai.menuID} -- Filho : ${filho.parent_MenuID}`)
              }
            });
          }
        });

        console.log(estrutura_menu);
        const ordernar = estrutura_menu
          .sort(function (a, b) {
            console.log(a.ordem, b.ordem);
            return a.orderm - b.ordem;
          })
          .sort(function (a, b) {
            console.log(
              "Olha : " + a.children,
              b.children.sort(function (a, b) {
                return a.ordem - b.ordem;
              })
            );
            return a.children.ordem - b.children.ordem;
          });
        this.dataSource.data = ordernar.filter(
          (itemDeMenu) => itemDeMenu.erp_Business === true
        );
        this.estrutura_menu = ordernar.filter(
          (itemDeMenu) => itemDeMenu.erp_Business === true
        ); //<------------------------

        console.log("estrutura do menu V");
        console.log(estrutura_menu);
        // this.goTransacao('RC0000')
        // this.dataSource.data = menus
      });
  }

  goCOD(ref: string) {
    const cod = ref.toUpperCase();
    switch (cod) {
      case "RC10":
        this.router.navigateByUrl(`home/Agenda/${this.usuarioId}`);
        break;
      case "RC50":
        this.router.navigateByUrl(`home/Recepcao/${this.usuarioId}`);
        break;
      case "AC10":
        this.router.navigateByUrl(`home/SalaEspera/${this.usuarioId}`);
        break;
      case "AC60":
        this.router.navigateByUrl(`home/Consultorio/${this.usuarioId}`);
        break;
      case "AC60":
        this.router.navigateByUrl(`home/Consultorio/${this.usuarioId}`);
        break;

      default:
        this.toast.info(`Codigo ${cod} invalido`);
        break;
    }
  }
  goTransacao(cod, name: string) {
    this.resetBackGround();
    switch (cod) {
      case "IP0000":
        this.backgroud.paciente = true;
        break;
      case "CM0000":
        this.backgroud.condutaMedica = true;
        break;
      case "SS0000":
        this.backgroud.servicos = true;
        break;
      case "SU0000":
        this.backgroud.saudeSuplementar = true;
        break;
      case "CC0000":
        this.backgroud.clienteCorporativo = true;
        break;
      case "SP0000":
        this.backgroud.SaudePopulacional = true;
        break;
      case "MD0000":
        this.backgroud.medicamentos = true;
        break;
      case "ME0000":
        this.backgroud.recursos = true;
        break;
      case "ES0000":
        this.backgroud.entidadesDeSaude = true;
        break;
      case "HE0000":
        this.backgroud.healthTechs = true;
        break;
      case "CN00000":
        this.backgroud.conversas = true;
        break;

      default:
        break;
    }
    this.codSessao = cod;
    this.tituloPage = name;
    let index = this.estrutura_menu.map((x) => x.menuID).indexOf(cod);
    let ord = this.estrutura_menu[index].children.sort(function (a, b) {
      console.log(parseInt(b.ordem), parseInt(a.ordem));
      // if(!a.ordem || !b.ordem){return}
      return parseInt(a.ordem) - parseInt(b.ordem);
    });
    this.dataSource.data = ord;
    this.router.navigateByUrl("business");
    console.log(ord);
  }
  popUp(ref) {
    this.modal = !this.modal;
    switch (ref) {
      case "salaEspera":
        this.popup.salaEspera = !this.popup.salaEspera;

        break;
      case "senha":
        this.popup.senha = !this.popup.senha;

        break;
      case "agenda":
        this.popup.agenda = !this.popup.agenda;

        break;
      case "pesquisa":
        this.popup.pesquisa = !this.popup.pesquisa;

        break;
      case "convidar":
        this.popup.convidar = !this.popup.convidar;

        break;

      default:
        break;
    }
  }

  reagendar(ref) {
    this.toast.warning("Por favor , vá até agenda e realize essa operação");
    this.router.navigateByUrl(`home/Agenda/${this.usuarioId}`);
  }
  selectVideoChamada() {
    this._videoChamada
      .getVideoChamda()
      .toPromise()
      .then((reg: Array<videoChamadaFrom>) => {
        let cpf = this.cpf;
        // console.log(cpf)
        let verificar = reg.filter((x) => {
          return x.destinatario == cpf && x.status == "pendente";
        });
        // let verificar = reg.filter((x) => { return x.destinatario == cpf })
        if (verificar.length > 0) {
          if (this.viewVideoChamada) return;
          // setTimeout(()=>{this.viewVideoChamada = true},15000)
          this.viewVideoChamada = true;
          this.infoVideoChamada = verificar[verificar.length - 1];
          const id = this.infoVideoChamada.codigoUnico;
          this._videoChamada
            .banco()
            .doc(id)
            .collection("info")
            .get()
            .toPromise()
            .then((r) => {
              this.infoContato = r.docs[0].data();
              console.log("OLHA > ", this.infoContato);
            });
          this.infoVideoChamada.status = "aceito";
          this._videoChamada
            .putVideoChamada(this.infoVideoChamada)
            .toPromise()
            .then(() => {});
          return (this.verificarEncerramento = setInterval(() => {
            this._videoChamada
              .getVideoChamda()
              .toPromise()
              .then((ref: Array<videoChamadaFrom>) => {
                let getEncerramento = ref.filter((x) => {
                  return x.destinatario == cpf && x.status == "encerrado";
                });
                // console.log('encerramento >', getEncerramento)
                if (getEncerramento.length > 0) {
                  this.infoVideoChamada.status = "finalizado";
                  this._videoChamada
                    .putVideoChamada(this.infoVideoChamada)
                    .toPromise()
                    .then(() => (this.boxVideoChamada = false));
                }
              });
          }, 3000));
        } else {
        }
      });
  }
  abrirVideoChamada(aceitar) {
    const id = this.infoVideoChamada.codigoUnico;
    if (aceitar) {
      this.viewVideoChamada = false;
      this.boxVideoChamada = true;
      this.infoVideoChamada.status = "aceito";
      console.log("GGGGG , ", this.infoVideoChamada);
      this._videoChamada
        .banco()
        .doc(id)
        .update({ aceito: true })
        .then((res) => {
          this._videoChamada
            .banco()
            .doc(id)
            .collection("info")
            .get()
            .toPromise()
            .then((res) => {
              try {
                let id_info = res.docs[0].id;
                this._videoChamada
                  .banco()
                  .doc(id)
                  .collection("info")
                  .doc(id_info)
                  .update({ registro: this.infoVideoChamada });
              } catch {
                this._videoChamada
                  .banco()
                  .doc(id)
                  .collection("info")
                  .add({ registro: this.infoVideoChamada });
              }
            });
        })
        .catch(() => this.toast.error("Algo deu errado"));
    } else {
      this.viewVideoChamada = false;
      this.infoVideoChamada.status = "recusado";
      this._videoChamada.banco().doc(id).update({ aceito: false });
      this._videoChamada
        .banco()
        .doc(id)
        .collection("info")
        .get()
        .toPromise()
        .then((res) => {
          try {
            let id_info = res.docs[0].id;
            this._videoChamada
              .banco()
              .doc(id)
              .collection("info")
              .doc(id_info)
              .update({ registro: this.infoVideoChamada });
          } catch {
            this._videoChamada
              .banco()
              .doc(id)
              .collection("info")
              .add({ registro: this.infoVideoChamada });
          }
        });
    }
  }
  getVideoChamada() {
    this._videoChamada
      .banco()
      .snapshotChanges()
      .subscribe((r) => {
        setTimeout(() => {
          this.selectVideoChamada();
        }, 15000);
      });
  }
  nav(url: string, active, menuID?) {
    if (!active) {
      this.codSessao = menuID;
      this.router
        .navigateByUrl(`home/${url}/${this.usuarioId}`, {
          skipLocationChange: true,
        })
        .catch(() => this.router.navigateByUrl(`home/${url}`));
      console.log(url);
    }
  }
  resetBackGround(t?) {
    this.backgroud.SaudePopulacional = false;
    this.backgroud.clienteCorporativo = false;
    this.backgroud.condutaMedica = false;
    this.backgroud.conversas = false;
    this.backgroud.entidadesDeSaude = false;
    this.backgroud.healthTechs = false;
    this.backgroud.medicamentos = false;
    this.backgroud.paciente = false;
    this.backgroud.recursos = false;
    this.backgroud.saudeSuplementar = false;
    this.backgroud.servicos = false;
  }
  onActivate(t) {
    console.log(t);
  }
  getMensagem() {
    this.mensagem = localStorage.getItem("mensagem");
  }
  sair() {
    this.service.activeLogin(this.cpf, false);
    sessionStorage.removeItem("tokken");
    sessionStorage.clear();
    this.router.navigateByUrl("");
    clearInterval(this.verificar);
  }
}
