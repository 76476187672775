import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BuscarDataService } from '../service/buscar-data.service';
import { ContatosService } from '../service/contatos.service';
import { FormContatos } from './modal';
import { ToastrService } from 'ngx-toastr';
import { FormCadastroProfissao } from '../configuracao-inicial/cadastro-empresa/model';
import { FormUsuario } from '../usuario/model';
import { ConversasService } from '../service/conversas.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-conversas',
  templateUrl: './conversas.component.html',
  styleUrls: ['./conversas.component.scss']
})
export class ConversasComponent implements OnDestroy {
  checkRemover: boolean;
  adicionar: boolean;
  viewMensagem: boolean
  ferramenta = true
  filtro = 'all'
  form = new FormContatos
  cpf_medico = sessionStorage.getItem('cpf')
  userName = sessionStorage.getItem('userName')
  addCPF
  contatos: Array<FormContatos>
  listAz = []
  selectRemove: FormContatos[] = []
  loading: boolean;
  convites: FormContatos[] = [];
  chat: any;
  conversas = [];
  viewSolicitacao: boolean;
  viewChat: boolean;
  viewChatAll: boolean;
  constructor(private service: ContatosService, private data: BuscarDataService, private toast: ToastrService, public _chat?: ConversasService, ) {
    console.log('TTTAT')
  }

  async ngOnInit() {
    this.loading = true
    await this.listarContatos()
    await this.buscarConversa()
    await this.listarConvites()
    this.loading = false
  }

  ngOnDestroy() {
  }
  async listarContatos(filtro?) {
    this.service.listarCatalogoDeContatos().toPromise()
      .then(
        (reg: Array<FormContatos>) => {
          let cpf = this.cpf_medico
          this.contatos = reg.filter(function (ref) {
            if (filtro == 'all' || !filtro) {
              return ref.ativo && ref.cpfMedicoConvidador == cpf
            }
            if (filtro) {
              console.log(cpf)
              return ref.ativo && ref.tipoUsuario == filtro && ref.cpfMedicoConvidador == cpf
            }
          })
          let ListOriginal = []
          let ListAZ = []
          this.contatos.forEach(r => {
            ListOriginal.push({ a: r.nomeConvidado.substr(0, 1).toLocaleUpperCase() })
          });
          ListOriginal.forEach(item => {
            var duplicidade = ListAZ.findIndex(res => {
              return item.a == res.a
            }) > -1
            if (!duplicidade) {
              ListAZ.push(item)
            }
          });
          console.log(this.contatos)
          this.listAz = ListAZ.sort(function (a, b) {
            return (a.a > b.a) ? 1 : (b.a > a.a) ? -1 : 0
          })
        }
      )
  }
  async listarConvites() {
    this.service.listarCatalogoDeContatos().toPromise()
      .then(
        (reg: Array<FormContatos>) => {
          let cpf = this.cpf_medico
          let filtro = reg.filter(function (reg) {
            return reg.cpfConvidado == cpf && reg.ativo && reg.status == 'Pendente'
          })
          if (filtro.length > 0) {
            this.convites = filtro

          }
          else {
            this.convites = []
          }

        }
      )
  }
  buscarConversa() {
    this._chat.getHoje(this.cpf_medico).subscribe(
      (reg: any) => {
        console.log(reg)
        let cpf = this.cpf_medico
        let ordenar = reg.sort(function (a, b) {
          let A = parseInt(a.hora.split(':').join(''))
          let B = parseInt(b.hora.split(':').join(''))
          return A - B
        }).filter(function (reg) {
          return reg.para == cpf
        })
        this.conversas = ordenar
      }
    )
    console.log('conversas')
    console.log(this.conversas)

  }
  async adicionarContatos(nome, tipo) {
    this.form.cpfMedicoConvidador = this.cpf_medico
    if (this.form.status != 'Confirmado') { this.form.status = "Pendente" }
    this.form.tipoUsuario = tipo
    // this.form.numeroDocumento = '123'
    this.form.nomeConvidado = nome
    this.form.nomeConvidador = this.userName
    let verificar = this.contatos.filter(x => { return x.cpfConvidado == this.form.cpfConvidado })
    if (verificar.length > 0) {
      this.toast.info('Contato ja foi adicionado ou convidado')
      this.loading = false
      return
    }
    this.service.cadastrarCatalogoDeContatos(this.form).toPromise()
      .then(
        (res: FormContatos) => {
          if (res) {
            if (this.form.status != 'Confirmado') { this.toast.success('Convite enviado com sucesso') }
            if (this.form.status == 'Confirmado') { this.toast.success(`${res.nomeConvidado} , adicionado(a) na sua lista de contatos`) }
            this.form = new FormContatos
            this.listarContatos()
            this.loading = false
          }
          else {
            this.toast.error('Não foi possivel registra solicitação')
            this.loading = false
          }

        }
      )
  }
  excluirContatos() {
    this.loading = true
    if (this.selectRemove.length > 0) {
      this.selectRemove.forEach(reg => {
        reg.ativo = false
        this.service.alterarCatalogoDeContatos(reg.id, reg).toPromise()
          .then(
            () => {
              this.listarContatos()
              this.loading = false
              this.selectRemove = []
            }
          )
      })

    } else { this.loading = false }
  }
  remover(check, reg) {
    if (check) {
      this.selectRemove.push(reg)
    }
    else {
      let id = this.selectRemove.findIndex(x => x.id == reg.id)
      this.selectRemove.splice(id, 1)
    }

    console.log(this.selectRemove)
  }
  teste(event) {
    console.log(event)
  }
  buscarMedico() {
    this.loading = true
    if (this.form.cpfConvidado == this.cpf_medico) {
      this.toast.info('Não é impossível adicionar você mesmo!')
      this.loading = false
      return
    }
    this.service.buscarMedico().toPromise()
      .then(
        (reg: Array<FormCadastroProfissao>) => {
          let crm = this.form.cpfConvidado
          let cpf = this.form.cpfConvidado
          let filtro = reg.filter(function (r) {
            return r.crm == crm || r.cpf == cpf
          })
          if (filtro.length > 0) {
            this.adicionarContatos(filtro[0].nome, "Medico")
          }
          else {
            this.buscarPaciente()
          }
        }
      )
  }
  buscarPaciente() {
    this.service.buscarPaciente().toPromise()
      .then(
        (reg: Array<any>) => {
          let cpf = this.form.cpfConvidado
          let filtro = reg.filter(function (r) {
            return r.cpf == cpf
          })
          if (filtro.length > 0) {
            this.adicionarContatos(filtro[0].nomeCompleto, "Paciente")
          }
          else {
            this.toast.error(`Não existe nenhum registro relacionado a " ${this.form.cpfConvidado} ".`)
            this.loading = false
          }
        }
      )
  }
  abrirChat(reg: any, buscarRegistro?: boolean) {
    if (buscarRegistro) {
      console.log(reg)
      let registro = this.contatos.filter(function (x) {
        return x.cpfConvidado == reg.de
      })
      this.chat = registro[0]
      this.viewMensagem = true
      this.viewChat = true
      this.viewChatAll = false
      return
    }

    if (reg.status == 'Pendente') {
      this.toast.info('Contato Pendente')
      return
    }
    this.chat = reg
    this.viewMensagem = true
    this.viewChat = true
    this.viewChatAll = false
  }
  confirmSolicitacao(reg: FormContatos, confirm) {
    if (confirm) {
      reg.status = "Confirmado" 
      this.service.alterarCatalogoDeContatos(reg.id, reg).toPromise()
        .then(
          () => {
            this.toast.success('Registrado com sucesso!')
            this.form.cpfConvidado = reg.cpfMedicoConvidador
            this.form.status = 'Confirmado'
            this.buscarMedico()
            this.listarConvites()

          }
        )
    }
    else {
      reg.status = "Recusado"
      this.service.alterarCatalogoDeContatos(reg.id, reg).toPromise()
        .then(
          () => {
            this.toast.success('Registrado com sucesso!')
            this.listarConvites()
          }
        )
    }
  }

}
