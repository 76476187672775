import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CadastroPessoaForm } from 'src/app/cadastro-paciente/model';
import { TicketConsulta } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { TicketsService } from 'src/app/service/tickets.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { InfoEvent } from './modal'

@Component({
  selector: 'app-info-event',
  templateUrl: './info-event.component.html',
  styleUrls: ['./info-event.component.scss']
})
export class InfoEventComponent implements OnInit, OnChanges {
  @Input('infoEvent') infoEvent = new InfoEvent
  @Input('paciente') paciente: any
  @Input('position') position: any
  infoConsulta = new TicketConsulta;
  infoPaciente = new CadastroPessoaForm;
  loading: boolean;
  constructor(
    private _foto: FiltrarDadosService,
    private _consulta: TicketsService,
    private _conteudo: ConteudoDeDadosService,
    private _user: UsuarioService,
    private _status: StatusSistemaService
  ) { }


  ngOnInit() {
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    try {
      this.paciente = this.paciente.split('-')[0]
    } catch {
      this.paciente = this.paciente
    }
    this.loading = true
    this.getFoto()
    if (this.paciente) {
      this.getConsulta()
      this.getUsuario()


    }
    this.loading = false

  }
  async getFoto() {
    this.infoEvent = await this._foto.inserirFotoPerfilEach('cpf', this.infoEvent)
  }
  async getConsulta() {
    this.infoConsulta = await this._consulta.getRefConsulta(this.infoEvent.ticketConsultaMedica)
    this.infoConsulta.motivoConsulta = (await this._conteudo.get('LIMOCO', true, this.infoConsulta.motivoConsulta)).toString()
    this.infoConsulta.tipoAtendimento = (await this._conteudo.get('TIPATEND', true, this.infoConsulta.tipoAtendimento)).toString()
    this.infoConsulta.tipoMarcacao = (await this._conteudo.get('LIMOCO', true, this.infoConsulta.tipoMarcacao)).toString()
    this.infoConsulta.formaDeConsulta = (await this._conteudo.get('FORMACON', true, this.infoConsulta.formaDeConsulta)).toString()
    this.infoConsulta.tipoConsulta = (await this._conteudo.get('LITICO', true, this.infoConsulta.tipoConsulta)).toString()
    this.infoEvent.status = this.infoConsulta.status
    let getInfoProfissao = await this._user.getRegistroProfissional(this.infoConsulta.cpfMedico, true)
    this.infoConsulta.crm = `${getInfoProfissao.nr_Registro} - ${getInfoProfissao.unidadeFederacao}`
    try {
      let status = (await this._status.getStatus(false, true, this.infoConsulta.status)).toString()
      this.infoConsulta.desStatus = status
    } catch (error) {
      console.log('status error', error)
    }
  }
  async getUsuario() {
    this.infoPaciente = await this._user.getCadastroPessoa(this.infoEvent.cpf)


  }

}
