export class CodFichaMedica{
    Anamnese = "ACANAM1"
    ExamesFisicosVisuais = "ACEXAM1"
    AfericaoSinaisVitais_Temperatura = "ACSIVI1"
    AfericaoSinaisVitais_Saturacao= "ACSIVI2"
    AfericaoSinaisVitais_PressaoArterial = "ACSIVI3"
    AfericaoSinaisVitais_Glicemia = "ACSIVI4"
    AfericaoSinaisVitais_BatimentosCardiacos = "ACSIVI5"
    AfericaoSinaisVitais_BatimentosCardiacosFeto = "ACSIVI6"
    DescricaoEstadoMental = "ACESME1"
    hipoDiagnostica = "PMHIPO1"
    diagnostico_princical = "PMDIAG1"
    diagnostico_descricao = "PMDIAG2"
    quadroCirurgico = "PMQPCI1"
    sequelas_diagnostico = "PMSEQU1"
    sequelas_cirurgico = "PMSEQU2"
    causasMortais  = "PMCAMO1"
    solicitacaoExame = "CMSOEX1"
    receitaBranca = "CMRECB1"
    receitaEspecial = "CMRECE1"
    indicTratamento = "INDTRA1"
    indicCirurgia = "INDCIR"
    recomendacoes = "CMRECO"
    procedimentoAdicional = "PROADI1"
    atestadoAfastamento = 'EAATEN1'
    atestadoMedica = 'EAATEN2'
    atestadoDeAcompanhante = 'EAATEN3'
    encaminhamentoMedico = 'EAATEN4'
    marcaConsultaRetorno = 'EASORE1'
    marcaConsultaPeriódica = 'EASORE2'
    encerrar_solicitacaoExame = 'EADOCC1'
    encerrar_receituario = 'EADOCC2'
    encerrar_indicacaoTratamento = 'EADOCC4'
    encerrar_procedimentoAdicional = 'EADOCC9'
    encerrar_AtestaDoMedico = 'EADOCC7'
    encerrar_AtestaDoAcompanhante = 'EADOCC7'
    encerrar_AtestaDeAfastamento = 'EADOCC8'
    encerrar_encaminhamento = 'EADOCC10'


}
export class CodFichaMedica_avalicaoClinica {
    Anamnese = "ACANAM1"
    ExamesFisicosVisuais = "ACEXAM1"
    AfericaoSinaisVitais_Temperatura = "ACSIVI1"
    AfericaoSinaisVitais_Saturacao= "ACSIVI2"
    AfericaoSinaisVitais_PressaoArterial = "ACSIVI3"
    AfericaoSinaisVitais_Glicemia = "ACSIVI4"
    AfericaoSinaisVitais_BatimentosCardiacos = "ACSIVI5"
    AfericaoSinaisVitais_BatimentosCardiacosFeto = "ACSIVI6"
    DescricaoEstadoMental = "ACESME1"
}
export class CodFichaMedica_parecerMedico {
    hipoDiagnostica = "PMHIPO1"
    diagnostico_princical = "PMDIAG1"
    diagnostico_descricao = "PMDIAG2"
    quadroCirurgico = "PMQPCI1"
    sequelas_diagnostico = "PMSEQU1"
    sequelas_cirurgico = "PMSEQU2"
    causasMortais  = "PMCAMO1"
}
export class CodFichaMedica_condutaMedica{
    solicitacaoExame = "CMSOEX1"
}
export class CodFichaMedica_EncerrarAtendimento{
    atestadoAfastamento = 'EAATEN1'
    atestadoMedica = 'EAATEN2'
    atestadoDeAcompanhante = 'EAATEN3'
    encaminhamentoMedico = 'EAATEN4'
    marcaConsultaRetorno = 'EASORE1'
    marcaConsultaPeriódica = 'EASORE2'
    encerrar_solicitacaoExame = 'EADOCC1'
    encerrar_receituario = 'EADOCC2'
    encerrar_indicacaoTratamento = 'EADOCC4'
    encerrar_procedimentoAdicional = 'EADOCC9'
    encerrar_AtestaDoMedico = 'EADOCC7'
    encerrar_AtestaDoAcompanhante = 'EADOCC7'
    encerrar_AtestaDeAfastamento = 'EADOCC8'
    encerrar_encaminhamento = 'EADOCC10'

}