import { Component, OnInit, ViewChild } from '@angular/core';
import { ContaBancariaService } from '../service/cadastro-conta-bancaria.service';
import { MensagemService } from '../service/mensagem.service';
import { SessaoUser } from '../user.reg';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContaBancaria } from './modelsCadastroContaBancaria';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { PlanoContaContabeisService } from '../service/plano-conta-contabeis/plano-conta-contabeis.service';

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-conta-bancaria',
  templateUrl: './cadastro-conta-bancaria.component.html',
  styleUrls: ['./cadastro-conta-bancaria.component.scss']
})
export class CadastroContaBancariaComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['acao', 'empresa', 'banco', 'agencia', 'contaBancaria']
  //
  formulario: FormGroup
  viewCadastro: boolean
  listRegistro: ContaBancaria[]
  alteracao = false
  botaoSalvar = false
  botaoCancelar = false
  viewConfirmDelete: boolean
  listEmpresa = []
  loading: boolean
  listPlanoContas = []
  modoCriacao: boolean
  modoAlteracao: boolean
  modoExibicao: boolean
  viewConfirmCancelar: boolean
  selectEmpresa = sessao.codigoEmpresa
  dataSource = new MatTableDataSource()




  ContaBancaria: string;
  loadings: boolean;
  resultadoJsonPais: any
  listBancos: any

  dadosContaContabil = []

  listar: boolean
  listaCheckBoxTable = []
  resultadoAssinante: any;
  listPlanoContas_backup: PlanoContaContabeisModel[]
  banco: any;
  listBancos_Backup: any;

  constructor(
    private service: ContaBancariaService,
    private mensagem: MensagemService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private _filtro: FiltrarDadosService,
    private _contas: PlanoContaContabeisService
  ) { }

  async ngOnInit() {
    this.creatForm()
    this.getPaises()
    this.getEmpresa()
    this.getBancosDeTodoBrasil()
   
  }

  creatForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: sessao.cpf,
      codigoContaBancaria: '',
      pais: new FormControl('', Validators.required),
      banco: new FormControl('', Validators.required),
      agencia: new FormControl('', Validators.required),
      numeroConta: new FormControl('', Validators.required),
      endereco: '',
      contatoGerente: '',
      contaContabil: new FormControl('', Validators.required),
      codigoEmpresa: new FormControl(0, Validators.required),
      status: '',
      criadoEm: '',
      //
      nomeEmpresa: '',
      descBanco: '',
      codigoContaContabil: ''

    })
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
    console.log('EMPRESA:', empresa)
    this.getRegistro(empresa[0].codigoEmpresa)
  }

  async getPaises() {
    this.service.getPaises().subscribe(response => {
      this.resultadoJsonPais = response;
    });
  }

  async getContasContabeis(codigoEmpresa) {
    this.listPlanoContas = ['']
    this.listPlanoContas_backup = await this._contas.getContaContabil(codigoEmpresa)
    this.listPlanoContas_backup.sort((a, b) => {
      let aCodigo = a.codigoContaContabil
      let bCodigo = b.codigoContaContabil
      return aCodigo < bCodigo ? -1 : 1
    })
    this.filterContas('')
  }


  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.modoCriacao = true
    this.modoAlteracao = false
    this.modoExibicao = false
    this.formulario.controls['status'].setValue('SCA0001')
    this.formulario.enable()
    this.formulario.reset()
  }

  filterBanco(value: string) {
    let registros = this.listBancos_Backup
    let result = registros.filter(item => item.NomeBanco.toUpperCase().includes(value.toUpperCase()))
    this.listBancos = result
  }

  async getRegistro(codigoEmpresa) {
    this.loading = true

    await this.service.listar(codigoEmpresa).toPromise()
      .then(registro => {
        this.listRegistro = registro.filter(item => item.status == 'SCA0001')
        this.listRegistro.forEach(async item => {
          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
          let banco = this.service.getBancosDeTodoBrasil().subscribe(response => {
            response.filter(resp => {
              if (item.banco == resp.codigoBanco) item.descBanco = resp.NomeBanco
            })
          })

          if (empresa.length > 0) item.nomeEmpresa = empresa[0].nomeEmpresa
          item.descBanco = banco.toString()
        })
        this.dataSource = new MatTableDataSource(this.listRegistro)
        this.dataSource.sort = this.sort
        this.loading = false
        if (codigoEmpresa) {
          //this.filtrarEmpresa()
        }
        console.log('getRegistro: ', registro)
      })
  }

  async openCadastro(registro) {
    console.log('Open', registro)

    this.getContasContabeis(registro.codigoEmpresa)

    registro.codigoContaContabil = ''

    this.formulario.patchValue(registro)

    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.modoExibicao = true
    this.modoCriacao = false
    this.modoAlteracao = false
  }

  verificarCamposValidos() {
    const invalid = [];
    const controls = this.formulario.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  inserir() {
    let registro = this.formulario.value
    
    if (this.formulario.invalid) {
      this.mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      let verific = this.verificarCamposValidos()
      console.log(verific)
      return
    }
    Object.keys(registro).forEach(value => {
      if (!registro[value])
        delete registro[value]
    })

    registro.criadoPor = sessao.cpf
    registro.status = 'SCA0001'
    console.log('REGISTRO', JSON.stringify(registro))

    this.service.enviar(registro).toPromise()
      .then(resp => {
        this.mensagem.creat(resp[0].mensagem, { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.botaoSalvar = false
        this.botaoCancelar = false
        this.alteracao = false
      }
      ).catch(err => {
        console.log(err)
        let erro = err.error
        this.mensagem.creat(err.error, { erro: true })
      })
      this.fecharRegistro()
    console.log('Inserir: ', registro)
  }

  alterar() {
    let registro = this.formulario.value

    delete registro.nomeEmpresa

    this.service.alterar(registro).toPromise()
      .then(() => {
        this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.botaoSalvar = false
        this.botaoCancelar = false
        this.modoExibicao = true
        this.modoCriacao = false
        this.modoAlteracao = false

      }).catch(error => {
        let erro = error.error
        this.mensagem.creat(erro, { erro: true })
      })

    console.log('Alterar: ', registro)
  }

  modificarCadastro() {
    this.formulario.enable()
    this.botaoSalvar = true
    this.botaoCancelar = true
    this.modoAlteracao = true
    this.modoCriacao = false
    this.modoExibicao = false
  }

  cancelarRegistro(confirm?) {

    if (confirm) {
      this.viewConfirmCancelar = true
      return
    }

    this.viewConfirmCancelar = false

    // this.listPlanoContas.filter(item => item.codigoContaContabil = '')

    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
    this.botaoSalvar = false
    this.botaoCancelar = false

    this.modoExibicao = true
    this.modoAlteracao = false
    this.modoCriacao = false
  }

  async fecharRegistro() {
    this.viewCadastro = false
    this.getEmpresa()
      .finally(() => this.filtrarEmpresa())
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro(this.selectEmpresa)
      .finally(() => this.filtrarEmpresa())
    this.filtrar('')
    console.log("Excluir: ", registro)
  }


  async getBancosDeTodoBrasil() {
    this.service.getBancosDeTodoBrasil().subscribe(response => {
      
    let order =  response.sort(function (a, b) {
        let acodigoBanco = a.codigoBanco;
        let bcodigoBanco = b.codigoBanco;
        if (a == b) return 0;
        return acodigoBanco < bcodigoBanco ? -1 : 1;
      })
      this.listBancos = order
      this.listBancos_Backup = order
    });
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }
    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }

  filterContas(value) {
    value = value.toLocaleLowerCase().trim()
    let registro = this.listPlanoContas_backup
    const filter = registro.filter(item => item.descricao.toLocaleLowerCase().includes(value))
    if (filter.length > 0) {
      this.listPlanoContas = filter
    } else {
      this.listPlanoContas = registro
    }
  }



  //////////





  // async buscarContaBancariaCodigoEmpresa() {
  //   this.loadings = true
  //   this.serviceContaBancaria.getContaBancariaCodigoEmpresa(sessao.codigoEmpresa).subscribe(
  //     (resultado: Array<ContaBancaria>) => {
  //       let filtro = resultado.filter(function (reg: ContaBancaria) {
  //         return reg.status === "SDO0017"
  //       })
  //       this.dadosContaBancaria = filtro
  //       this.loadings = false
  //     }), erro => {
  //       this.loadings = false
  //       this.mensagem.creat("Não foi possível buscar os dados da Conta bancária específica no momente, tente novamente mais tarde.", { aviso: true })
  //     }
  // }

  // async gravarDadosContaBancaria() {
  //   this.loadings = true
  //   this.dadosContaBancariaCadastrar.status = "SDO0017"
  //   this.dadosContaBancariaCadastrar.criadoPor = sessao.cpf
  //   this.serviceContaBancaria.postContaBancaria(this.dadosContaBancariaCadastrar).toPromise().then(
  //     (sucesso => {
  //       this.listar = true
  //       this.buscarContaBancariaCodigoEmpresa()
  //       this.loadings = false
  //       this.mensagem.creat('Gravado com Sucesso!', { sucesso: true })
  //     }), erro => {
  //       this.loadings = false
  //       this.mensagem.creat('Não foi possível cadastro Conta Bancária no momento, por favo tente novamente mais tarde.', { erro: true })
  //     })
  //   this.dadosContaBancariaCadastrar = new ContaBancaria()
  // }

  // buscarContaContabil(codigoEmpresa) {
  //   this.loadings = true
  //   this.serviceContaContabil.getContaContabilCodigoEmpresa(codigoEmpresa).subscribe(
  //     (resultado: Array<PlanoContaContabeisModel>) => {
  //       let filtroContaDebito = resultado.filter(function (reg: PlanoContaContabeisModel) {
  //         return reg.status === "SDO0017"
  //       })
  //       this.dadosContaContabil = filtroContaDebito
  //       this.dadosContaContabil.sort(function (a: PlanoContaContabeisModel, b: PlanoContaContabeisModel) {
  //         let aDescricao = a.descricao.trim().toLowerCase();
  //         let bDescricao = b.descricao.trim().toLowerCase();
  //         if (a == b) return 0;
  //         return aDescricao < bDescricao ? -1 : 1;
  //       })
  //       this.loadings = false
  //     }), erro => {
  //       this.mensagem.creat("Não foi possível buscar os dados da Conta Contábil no momento, por favor tente novamente mais tarde", { erro: true })
  //     }
  // }

  // async buscarContaBancariaID() {
  //   this.serviceContaBancaria.getDadosContaBancariaID(this.ContaBancaria).subscribe(
  //     (resultado => {
  //       this.dataContaBancariaID = resultado
  //       this.buscarContaContabil(this.dataContaBancariaID.codigoEmpresa)
  //       this.listar = null
  //     }), erro => {
  //       this.mensagem.creat("Impossível buscar os dados da Conta Bancária específica no momente, tente novamente mais tarde.", { aviso: true })
  //     })
  // }

  // async AlterarContaBancaria() {
  //   this.loadings = true
  //   this.dataContaBancariaID.criadoPor = sessao.cpf
  //   this.serviceContaBancaria.PutDadosContaBancaria(this.ContaBancaria, sessao.cpf, this.serviceData.horaAgora(true), this.dataContaBancariaID).subscribe(
  //     (resultado => {
  //       this.mensagem.creat('Dados da Conta Bancária atualizado com sucesso!', { sucesso: true })
  //       this.listar = true
  //       this.buscarContaBancariaCodigoEmpresa()
  //       this.loadings = false
  //     }), erro => {
  //       this.loadings = false
  //       this.mensagem.creat("Não foi possível atualizar os dados da Conta Bancária no momento, tente novamente mais tarde!", { erro: true })
  //     })
  // }

  // async addDadosContaBancaria(event: Boolean, item: ContaBancaria) {
  //   if (event) {
  //     this.listaCheckBoxTable.push(item)
  //   } else {
  //     let index = this.listaCheckBoxTable.map((x, i) => { return { index: i, cod: x.id } }).filter((x) => { return x.cod == item.id })[0].index
  //     this.listaCheckBoxTable.splice(index, 1)
  //   }
  // }

  // async ExcluirContaBancaria() {
  //   this.loadings = true
  //   this.listaCheckBoxTable.forEach(reg => {
  //     reg.status = "SDO0066"
  //     this.serviceContaBancaria.PutDadosContaBancaria(reg.id, sessao.cpf, this.serviceData.horaAgora(true), reg).subscribe(
  //       (resultado => {
  //         this.mensagem.creat("Conta bancária excluída com sucesso.", { sucesso: true })
  //         this.buscarContaBancariaCodigoEmpresa()
  //         this.loadings = false
  //         this.listaCheckBoxTable = []
  //       }), error => {
  //         this.loadings = false
  //         this.mensagem.creat("Não foi possível excluir Conta Bancária no momento, tente novamente mais tarde.", { erro: true })
  //       }
  //     )
  //   })
  // }
}
