import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MensagemService } from '../mensagem.service';
import { Observable } from 'rxjs';
import { BuscarDataService } from '../buscar-data.service';
import { PlanoContaContabeisModel, SaldoContaContabil } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { SessaoUser } from 'src/app/user.reg';
import { SIS_LEGTRIB } from './modal';

const url = `${new environment().api}PlanoContaContabeis/`

const user = new SessaoUser

let objeto = new PlanoContaContabeisModel

@Injectable({
  providedIn: 'root'
})

export class PlanoContaContabeisService {

  
  constructor(private http: HttpClient, private data: BuscarDataService) { }

  listar(codigoEmpresa) {
    return this.http.get<PlanoContaContabeisModel[]>(url + 'FiltrarCodigoEmpresa/' + codigoEmpresa)
  }

  CopiarRegistros(deCodigoEmpresa, paraCodigoEmpresa)
  {
    return this.http.post(`${new environment().api}CopiarRegistros/${deCodigoEmpresa}/${paraCodigoEmpresa}/${user.cpf}/PlanoContaContabeis`,objeto,{reportProgress: true, observe:'events'})
  }

  enviar(registro: PlanoContaContabeisModel) {
    return this.http.post(url, registro)
  }

  alterar(registro: PlanoContaContabeisModel) {
    return this.http.put(url + `${registro.codigo}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
  }


  async desativar(registro: PlanoContaContabeisModel, ativo) {

    if (ativo) {
      registro.status = 'SDA0001'
    } else {
      registro.status = 'SDA0002'
    }

    let resposta
    await this.alterar(registro).toPromise()
      .then(res => { resposta = res })
      .catch(error => { console.log('error', error) })

    return resposta

  }

  getContaContabilCodigoEmpresa(codigoEmpresa: any) {
    return this.http.get(`${url}FiltrarCodigoEmpresa/${codigoEmpresa}`);
  }
  async getContaContabil(codigoEmpresa) {
    let result: PlanoContaContabeisModel[] = []
    await this.http.get(`${url}FiltrarCodigoEmpresa/${codigoEmpresa}`).toPromise()
      .then((res: Array<PlanoContaContabeisModel>) => {
        result = res
      })
    return result
  }
  async getContaContabilTipo(codigoEmpresa: number, tipo: string) {
    let result: PlanoContaContabeisModel[] = []
    await this.getContaContabilCodigoEmpresa(codigoEmpresa).toPromise()
      .then((res: Array<PlanoContaContabeisModel>) => {
        let filter = res.filter(value => value.tipo == tipo)
        result = filter
      })
    return result
  }

  async getSaldoContaContabil(codigoContaContabil) {
    let result = {
      dado: new SaldoContaContabil,
      saldo: null,
      dataLancamento: null,
      periodo: null
    }
    if (codigoContaContabil) {
      try {
        await this.http.get<SaldoContaContabil[]>(url+ 'SaldoContaContabil/FiltrarContaContabil/' + codigoContaContabil).toPromise()
          .then(res => {

            let item = res[0]
            result = {
              dado: item,
              saldo: item.montanteSaldo,
              dataLancamento: item.dataDeLancamento,
              periodo: item.periodoContabil
            }
          }).catch(error => {
            result = {
              dado: new SaldoContaContabil,
              saldo: null,
              dataLancamento: null,
              periodo: null
            }
          })
      } catch {

      }
    }


    return result
  }
  async alterarSaldoContaContabil(registro: SaldoContaContabil) {
    let response
    await this.http.put(url+ 'SaldoContaContabil/' + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro).toPromise()
      .then(res => {
        response = true
      })
      .catch(error => {
        console.log('erro pull SaldoContaContabil', error)
        response = false
      })
    return response
  }
  async getLegTributarai(regimeTributario) {
    let result: SIS_LEGTRIB[] = []
    await this.http.get<SIS_LEGTRIB[]>(new environment().api + 'SIS_LEGTRIB/').toPromise()
      .then(res => {
        let filter = res.filter(value => value.regimeTributario == regimeTributario)
        result = filter
      }).catch(error => console.log('erro getLegTributarai', error))
    return result
  }

}



//   listarContasContabeis(): PlanoDeContaContabil[] {
//     let planoContaContabeis: PlanoDeContaContabil[];
//     this.http.get<PlanoDeContaContabil[]>(url, { observe: 'body' }).subscribe(
//       res => { planoContaContabeis = res },
//       (erro: HttpErrorResponse) => {
//         if (erro.status === 400) this.mensagem.creat('Nenhuma conta contábil encontrada', { erro: true })
//         this.mensagem.creat(`Erro ao listar contas contabeis: ${erro.status}, ${erro.message}`, { erro: true })
//       }
//     )
//     return planoContaContabeis
//   }

//   async listarContasContabeisEmpresa({ codEmpresa }: { codEmpresa: number }): Promise<PlanoDeContaContabil[]> {
//     let planoContaContabeis: PlanoDeContaContabil[];
//     await this.http.get<PlanoDeContaContabil[]>(`${url}FiltrarCodigoEmpresa/${codEmpresa}`, { observe: 'body' }).toPromise()
//       .then(
//         res => {
//           planoContaContabeis = res.filter(plano => plano.status === 'SDO0017')
//         }
//       )
//       .catch((erro: HttpErrorResponse) => {
//         if (erro.status === 400) this.mensagem.creat('Nenhuma conta contábil encontrada', { erro: true })
//         this.mensagem.creat(`Erro ao listar contas contabeis: ${erro.status}, ${erro.message}`, { erro: true })
//       })
//     return planoContaContabeis
//   }

//   buscarContaContabil({ id }: { id: string }): PlanoDeContaContabil {
//     let planoContaContabil: PlanoDeContaContabil;
//     this.http.get<PlanoDeContaContabil>(`${url}${id}`, { observe: 'body' }).subscribe(
//       res => { planoContaContabil = res },
//       (erro: HttpErrorResponse) => { this.mensagem.creat(`Erro ao buscar conta contábil: ${erro.status}, ${erro.message}`, { erro: true }) }
//     )
//     return planoContaContabil
//   }

//   deletarContaContabil({ id }: { id: string }): void {
//     this.http.delete<PlanoDeContaContabil>(`${url}${id}`, { observe: 'body' }).subscribe(
//       () => { this.mensagem.creat('Conta contábil removida com sucesso', { sucesso: true }) },
//       (erro: HttpErrorResponse) => { this.mensagem.creat(`Erro ao deletar conta contábil: ${erro.status}, ${erro.message}`, { erro: true }) }
//     )
//   }

//   criarContaContabil(novaContaContabil: Omit<PlanoDeContaContabil, 'codigo'>) {
//     novaContaContabil.status = "SDO0017"
//     return this.http.post(url, novaContaContabil)
//   }

//   async modificarContaContabil(novaContaContabil: PlanoDeContaContabil) {
//     return this.http.put(`${url}${novaContaContabil.codigo}`, novaContaContabil, { observe: 'body' })
//   }
// }
