import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-especialidade-crm',
  templateUrl: './especialidade-crm.component.html',
  styleUrls: ['./especialidade-crm.component.scss']
})
export class EspecialidadeCrmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
