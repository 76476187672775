import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color } from 'ng2-charts';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { FormProntuarioVidaPaciente } from 'src/app/service/prontuario-vida-paciente/model';
import { ProntuarioDeVidaService } from 'src/app/service/prontuario-vida-paciente/prontuario-de-vida.service';
import { SessaoUser } from 'src/app/user.reg';
import { TypeProntuario } from '../model';
const sessao = new SessaoUser
@Component({
  selector: 'app-informacao-saude-paciente',
  templateUrl: './informacao-saude-paciente.component.html',
  styleUrls: ['./informacao-saude-paciente.component.scss']
})
export class InformacaoSaudePacienteComponent implements OnInit {
  @Input('cpf') cpf : string
  activeForm: TypeProntuario = '' 
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: true,
          maxTicksLimit: 3,
          maxRotation: 0,
          minRotation: 0
        }
      }], yAxes: [{
        ticks: {
          autoSkip: true,
          beginAtZero: true,
          maxTicksLimit: 5
        }, gridLines: {
          display: false
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartType: ChartType = 'line';
  public barChartLegend = false;

  public barChartData = []

  public barChartLabels = {
    pressao_arterial: [],
    temperatura: [],
    frequencia_cardiaca: [],
    frequencia_respiratoria: [],
    glicemia: [],
    saturacao: [],
    peso: [],
    cintura: [],
    altura: [],
    tipoSanguineo: []
  };



  chartData: any = {
    pressao_arterial: [{ data: [], label: '' }],
    temperatura: [{ data: [], label: '' }],
    frequencia_cardiaca: [{ data: [], label: '' }],
    frequencia_respiratoria: [{ data: [], label: '' }],
    saturacao: [{ data: [], label: '' }],
    glicemia: [{ data: [], label: '' }],
    peso: [{ data: [], label: '' }],
    cintura: [{ data: [], label: '' }],
    altura: [{ data: [], label: '' }],
    tipoSanguineo: [{ data: [], label: '' }],
  }


  // public barChartData: ChartDataSets[] = [
  //   { data: [80, 95, 91], label: 'Hipotensão' },
  //   { data: [120, 80, 90], label: 'Normotensão' },
  //   { data: [140, 135, 130], label: 'Hipertensão' }
  // ];
  public lineChartColors: Color[] = [
    {
      backgroundColor: '#f0f0f0d1',
      borderColor: 'rgb(72 202 229)',
      // pointBackgroundColor: 'rgb(72 202 229)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgb(230 230 230)'
    }
  ];
  register: FormProntuarioVidaPaciente[] = [];

  constructor(private service: ProntuarioDeVidaService,
    private _data: BuscarDataService,
    private _filter: FiltrarDadosService) { }

  ngOnInit() {
    this.getProntuario()
  }
  getProntuario() {
    this.cpf = sessao.cpf
    this.service.get(this.cpf).toPromise()
      .then((response: Array<FormProntuarioVidaPaciente>) => {

        if (!response.length) return

        response.forEach(value => {
          value.data = this._data.convertCriadoEm(value.criadoEm.length > 8 ? value.criadoEm.slice(0, 8) : value.criadoEm)
        })

        this._filter.ordernarCriadoEm(response)

        this.register = [...response]

        // let reduce = this.getUltimosValores(response, true)

        // response = reduce



        this.barChartLabels.pressao_arterial = response.filter(v => v.pressaoArterial).map(v => this._data.convertBR(v.data))
        this.barChartLabels.temperatura = response.filter(v => v.temperatura).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.frequencia_cardiaca = response.filter(v => v.pressaoSistolica).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.frequencia_respiratoria = response.filter(v => v.pressaoDiastolica).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.saturacao = response.filter(v => v.saturacaoOxigenio).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.glicemia = response.filter(v => v.glicemia).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.peso = response.filter(v => v.peso).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.cintura = response.filter(v => v.cintura).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.altura = response.filter(v => v.altura).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.tipoSanguineo = response.filter(v => v.tipoSanguineo).map(v => this._data.convertBR(v.data).slice(3))

        this.chartData.pressao_arterial = [{ data: response.filter(v => v.pressaoArterial).map(v => Number(v.pressaoArterial)), label: '' }]
        this.chartData.temperatura = [{ data: response.filter(v => v.temperatura).map(v => Number(v.temperatura)), label: '' }]
        this.chartData.frequencia_cardiaca = [{ data: response.filter(v => v.pressaoSistolica).map(v => Number(v.pressaoSistolica)), label: '' }]
        this.chartData.frequencia_respiratoria = [{ data: response.filter(v => v.pressaoDiastolica).map(v => Number(v.pressaoDiastolica)), label: '' }]
        this.chartData.saturacao = [{ data: response.filter(v => v.saturacaoOxigenio).map(v => Number(v.saturacaoOxigenio)), label: '' }]
        this.chartData.glicemia = [{ data: response.filter(v => v.glicemia).map(v => Number(v.glicemia)), label: '' }]
        this.chartData.peso = [{ data: response.filter(v => v.peso).map(v => Number(v.peso)), label: '' }]
        this.chartData.cintura = [{ data: response.filter(v => v.cintura).map(v => Number(v.cintura)), label: '' }]
        this.chartData.altura = [{ data: response.filter(v => v.altura).map(v => Number(v.altura)), label: '' }]
        this.chartData.tipoSanguineo = [{ data: response.filter(v => v.tipoSanguineo).map(v => v.tipoSanguineo), label: '' }]

        console.log('REDUCE----', response.map(v => v.data), response)
      })
  }
  getValueAtual(type: TypeProntuario) {
    try {
      return this.chartData[type][0].data[this.chartData[type][0].data.length > 0 ? this.chartData[type][0].data.length - 1 : 0]
    }
    catch {
      return '--'
    }
  }

}
