import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatTableDataSource, MatAccordion, MatOption } from "@angular/material";
import { VideoChamadaService } from "src/app/service/video-chamada.service";
import { SalaEsperaForm, videoChamadaFrom } from "../sala-espera/model";
import { FotoPerfilService } from "src/app/service/foto-perfil.service";
import { FormUsuario } from "src/app/usuario/model";
import { FormPaciente } from "src/app/cadastro-paciente/model";
import { AtendimentoClinicoService } from "src/app/service/atendimento-clinico.service";
import { DomSanitizer } from "@angular/platform-browser";
import { BuscarDataService } from "src/app/service/buscar-data.service";
import { AgendaService } from "src/app/service/agenda.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { FormContatos } from "src/app/conversas/modal";
import { ToastrService } from "ngx-toastr";
import { MensagemService } from "src/app/service/mensagem.service";
import { Router } from "@angular/router";
import {
  CodFichaMedica_avalicaoClinica,
  CodFichaMedica,
} from "./cod-ficha-consulta-medica";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
// import { TypePrint } from "src/app/documentos-pdf/TypePrint.modal";
import {
  AgendaFeriadosForm,
  AgendaConfigForm,
} from "src/app/agenda/agenda/model";
import {
  UploadArquivo,
  UploadArquivosService,
} from "src/app/service/upload-arquivos.service";
import { SessaoUser } from "src/app/user.reg";
import { formAnexoExame } from "src/app/upload/modal";
import {
  ConteudoDados,
  ConteudoDeDadosService,
} from "src/app/service/conteudo-de-dados.service";
import {
  ProtocoloClinico,
  ProtocoloClinicoService,
} from "src/app/service/protocolo-clinico.service";
import { FichaConsultaMedicaService } from "src/app/service/ficha-consulta-medica/ficha-consulta-medica.service";
import {
  CabecalhoFichaConsultaMedica,
  SolicitacaoDeExames,
  ReceituarioBranco,
  ReceituarioEspecial,
  IndicacaoTratamentos,
  IndicacaoCirurgia,
  ProcedimentoAdicional,
  EncaminhamentoMedico,
  Medicamentos,
  Receituario,
  ClassificacaoDeReceituarios,
} from "src/app/service/ficha-consulta-medica/model";
import { TicketConsultaJOIN } from "src/app/home/monitor-clinico/consultas/solicitacoes/modal";
import { Menu, SubMenu } from "./menu";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { TicketsService } from "src/app/service/tickets.service";
import { ReceituarioDoPaciente } from "../conduta-medica/model";
import { UsuarioService } from "src/app/service/usuario.service";

const sessao = new SessaoUser();

@Component({
  selector: "app-ficha-consulta-medica",
  templateUrl: `./ficha-consulta-medica.component.html`,
  styleUrls: ["./ficha-consulta-medica.component.scss"],
})
export class FichaConsultaMedicaComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild("FileUploading", { static: false }) fileUploading: ElementRef;
  @ViewChild("teste", { static: true }) teste: MatAccordion;
  @Output("emitPause") emitPause = new EventEmitter();
  code = "0000000";
  expand;
  imprimir;
  avClinica = {
    anamnese: true,
    exame: false,
    afericaoSV: false,
    descrEstadoMental: false,
    resultadoExame: false,
    docClinic: false,
  };
  parecerMedico = {
    hipoDiagnostica: true,
    diagnostico: false,
    quadroCirurgico: false,
    sequelas: false,
    causasMortais: false,
  };
  condutaMedica = {
    solicExame: true,
    receituario: false,
    indiTratamento: false,
    indiCirurgia: false,
  };
  encerrarAt = {
    atEncaminhamento: true,
    solicRetorno: false,
    encerrar: false,
  };
  atDadosSaude = {
    pcd: false,
    sinaisVitais: false,
    medUso: false,
    dataUtChekup: false,
    cartVacinacao: false,
    habVida: false,
    doencaCong: false,
    doencaPree: false,
    doencaCronicas: false,
    doencaContraidas: false,
    doencaFamilia: false,
    evolucaoSaude: false,
    cirurgiaRealizada: false,
    lesaoFratCont: false,
    alergiaMedic: false,
    outrasAlergias: false,
    tratRealizado: false,
    procAdicionais: false,
    interHosp: false,
    medReceitaEsp: false,
    medReceitaBranca: false,
  };
  visaoGC = {
    avClinica: true,
    parecerMed: false,
    condutaMed: false,
    procAdicional: false,
    encerrarAt: false,
  };
  prontMedPac = {
    diagnostico: false,
    sequelas: false,
    exames: false,
    consultas: false,
    encMedico: false,
  };
  form_avClinica = {
    anamnese: "",
    exame: "",
    afericaoSV: {
      temperatura: "",
      saturacao: "",
      pressaoArterial: "",
      glicemia: "",
      batCardiaco: "",
      batCardiacoFeto: "",
    },
    descrEstadoMental: "",
    resultadoExame: "",
  };
  form_parecerMedico = {
    hipoDiagnostica: "",
    diagnostico_princical: "",
    diagnostico_descricao: "",
    quadroCirurgico: "",
    sequelas_diagnostico: "",
    sequelas_cirurgico: "",
    causasMortais: "",
  };
  dataSource: MatTableDataSource<unknown>;
  viewComent: boolean;
  index: any;
  displayedColumns: string[] = ["item", "exame", "comentario"];
  displayedColumnsSolicMedico: string[] = ["item", "exame", "menu"];
  displayedColumnsSolicExame: string[] = [
    "remove",
    "index",
    "cid",
    "exame",
    "descricao",
  ];
  displayedColumnsReceitaBranca: string[] = [
    "remove",
    "index",
    "continuo",
    "superInscriUso",
    "inscriMedicamento",
    "subsQuantidade",
    "AdsOrientacao",
  ];
  displayedColumnsReceitaEspecial: string[] = [
    "remove",
    "index",
    "tipoNotificacao",
    "tipoReceita",
    "numero",
  ];
  displayedColumnsReceitaEletronica: string[] = [
    "remove",
    "index",
    "tipoNotificacao",
    "tipoReceita",
    "numero",
  ];
  displayedColumnsClassReceituario: string[] = [
    "medicamentos",
    "tipoDeNotificacao",
    "lista",
    "cor",
    "abrangencia",
    "mais",
  ];
  displayedColumnsIndTratamento: string[] = [
    "remove",
    "index",
    "nome",
    "descricao",
    "quantidade",
  ];
  displayedColumnsIndCirurgia: string[] = [
    "remove",
    "index",
    "cid",
    "tipo",
    "motivo",
  ];
  displayedColumnsProdAdicional: string[] = [
    "remove",
    "index",
    "nome",
    "quantidade",
    "cobrar",
  ];
  displayedColumnsEncMedico: string[] = [
    "remove",
    "index",
    "especialidade",
    "cid",
    "motivo",
    "parecer",
  ];
  dataSolicExame = new MatTableDataSource([]);
  dataReceitaBranca: MatTableDataSource<any>;
  dataReceitaEspecial: MatTableDataSource<any>;
  dataReceitaEletronica: MatTableDataSource<any>;
  dataIndtratamento: MatTableDataSource<any>;
  dataProdAdicional: MatTableDataSource<any>;
  dataEncMedico: MatTableDataSource<any>;
  dataIndCirurgia: MatTableDataSource<any>;
  dataClassReceituario: MatTableDataSource<any>;
  datasDisponiveis = [];
  menu = new Menu();
  subMenu = new SubMenu();
  menuEncerramento = 1;
  activeSessao: string;
  activeSubSessao: string;
  painelEncActive = 1;

  configEdit: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    placeholder: "Digite aqui....",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      [
        "subscript",
        "superscript",
        "heading",
        "customClasses",
        "unlink",
        "insertVideo",
        "insertImage",
        "toggleEditorMode",
        "insertHorizontalRule",
        "removeFormat",
      ],
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  itens8 = [
    { index: 0, cid: "000000", tipo: "teste", motivo: "teste" },
    { index: 1, cid: "000000", tipo: "teste", motivo: "teste" },
    { index: 2, cid: "000000", tipo: "teste", motivo: "teste" },
  ];
  itens = [
    { index: 0, checked: false, exame: "Raio X", comentario: "" },
    { index: 1, checked: false, exame: "Ressonância", comentario: "" },
    { index: 2, checked: false, exame: "Hemograma completo", comentario: "" },
  ];

  itens2 = [
    {
      index: 0,
      cid: "000000000",
      nome: "Raixo-X",
      descricao: "Pulmão esquedo",
      checked: false,
    },
    {
      index: 1,
      cid: "000000000",
      nome: "Raixo-X",
      descricao: "Pulmão esquedo",
      checked: false,
    },
    {
      index: 2,
      cid: "000000000",
      nome: "Raixo-X",
      descricao: "Pulmão esquedo",
      checked: false,
    },
    {
      index: 3,
      cid: "000000000",
      nome: "Raixo-X",
      descricao: "Pulmão esquedo",
      checked: false,
    },
    {
      index: 4,
      cid: "000000000",
      nome: "Raixo-X",
      descricao: "Pulmão esquedo",
      checked: false,
    },
    {
      index: 5,
      cid: "000000000",
      nome: "Raixo-X",
      descricao: "Pulmão esquedo",
      checked: false,
    },
  ];
  itens3 = [
    {
      index: 0,
      checked: false,
      continuo: false,
      superInscriUso: "Uso externo: Via superficial",
      inscriMedicamento: " pantoprazol 5 gm",
      subsQuantidade: "Tomar 1 comprimido ao dia por 4 meses",
      AdsOrientacao: "Suspender no caso de indisposição no estomago ou nauseas",
    },
    {
      index: 1,
      checked: false,
      continuo: true,
      superInscriUso: "teste",
      inscriMedicamento: "teste",
      subsQuantidade: "teste",
      AdsOrientacao: "teste",
    },
    {
      index: 2,
      checked: false,
      continuo: true,
      superInscriUso: "teste",
      inscriMedicamento: "teste",
      subsQuantidade: "teste",
      AdsOrientacao: "teste",
    },
    {
      index: 3,
      checked: false,
      continuo: false,
      superInscriUso: "teste",
      inscriMedicamento: "teste",
      subsQuantidade: "teste",
      AdsOrientacao: "teste",
    },
  ];
  itens4 = [
    {
      index: 0,
      checked: false,
      tipoNotificacao: "A",
      tipoReceita: "A2",
      numero: "000000000",
    },
    {
      index: 1,
      checked: false,
      tipoNotificacao: "B",
      tipoReceita: "B2",
      numero: "000000000",
    },
    {
      index: 2,
      checked: false,
      tipoNotificacao: "C",
      tipoReceita: "C3",
      numero: "000000000",
    },
  ];
  itens5 = [
    { index: 0, nome: "teste", descricao: "teste", quantidade: "teste" },
  ];

  DocEncAt: { nome: string; impressao: boolean }[] = [];
  infoSalaEspera: TicketConsultaJOIN = new TicketConsultaJOIN();
  keyVideoChamada: string;
  videoChamada: boolean;
  loadingImage: boolean;
  fotoPerfil: any;
  registro: FormUsuario | FormPaciente;
  listCheck_delete = [];
  listCheck_condutaMedica_solicExame = [];
  listCheck_condutaMedica_receituario = [];
  listCheck_condutaMedica_indiTratamento = [];
  viewDownload: boolean;
  listLaboratorios = [];
  url: any;
  viewIframeLab: boolean;
  infoLaboratorio = {
    nome: "",
    link: "",
  };
  viewAdd = false;
  viewReceitaBrancaAll = false;
  viewDelete = false;
  viewReceitaEletronica = false;
  procAdicional = true;
  historicoSaude = true;

  hoje: string;
  hoje_hora: string;
  periodoAtestado;
  hora = "00:00";
  nulo = "";
  listHorarios = [];
  dateEnd;
  duracaoDias;
  fim;
  painelFull: boolean;
  painelFull_2: boolean;
  verificar_chat: NodeJS.Timer;
  openChat: boolean;
  chat = new FormContatos();
  cpf: string;
  userName: string;
  verMais: boolean;
  chamarPaciente = new SalaEsperaForm();
  loadingVideo: boolean;
  new_video = false;
  openQRCODE: boolean;
  viewDocPDF: boolean = false;
  downloadPDF: boolean;
  cabecalho = new CabecalhoFichaConsultaMedica();
  codItem = new CodFichaMedica();
  loadingSave: boolean;
  autoSave;
  activeAmazonTrasncribe = false;
  condutaMedica_listSolicitacaoExame: Array<SolicitacaoDeExames> = [];
  condutaMedica_listReceitaBranca: Array<ReceituarioBranco> = [];
  condutaMedica_listReceitaEspecial: Array<ReceituarioEspecial> = [];
  condutaMedica_listReceitaEletronica: Array<ReceituarioDoPaciente> = [];
  condutaMedica_listIndicacaoTratamento: Array<IndicacaoTratamentos> = [];
  condutaMedica_listIndicacaoCirurgia: Array<IndicacaoCirurgia> = [];
  condutaMedica_listProcedimentoAdicional: Array<ProcedimentoAdicional> = [];
  encerrarAt_listEncaminhamentoMedico: Array<EncaminhamentoMedico> = [];

  CIDs: any[] = [];
  CIDs_all: any[] = [];
  EXAMES: any[] = [];
  EXAMES_all: any[] = [];
  MEDICAMENTOS: string[] = [];
  MEDICAMENTOS_all: Medicamentos[] = [];
  RECEITUARIO: string[] = [];
  RECEITUARIO_all: Receituario[] = [];

  form_condutaMedica_ReceitaBranca: FormGroup;
  form_condutaMedica_solicitacaoExame: FormGroup;
  form_condutaMedica_ReceitaEspecial: FormGroup;
  form_condutaMedica_ReceitaEletronica: FormGroup;
  form_condutaMedica_IndicacaoTratamentos: FormGroup;
  form_condutaMedica_IndicacaoCirurgia: FormGroup;
  form_condutaMedica_ProcedimentoAdicional: FormGroup;
  form_encerrarAt_AtestadoAfastamento: FormGroup;
  form_encerrarAt_AtestadoMedico: FormGroup;
  form_encerrarAt_AtestadoAcompanhante: FormGroup;
  form_encerrarAt_encaminhamentoMedico: FormGroup;
  form_encerrarAt_solicitacaoRetorno: FormGroup;
  form_encerrarAt_solicitacaoPeriodica: FormGroup;

  typeDoc: string;
  itensDoc: any;
  dateFeriados: any[] = [];
  configAgenda = new AgendaConfigForm();
  confgDataTermino: string;
  openUpload: boolean;
  info_file = new UploadArquivo();
  file: any;
  file_docClinics: any;
  listClassDeReceituarios: ClassificacaoDeReceituarios[] = [];
  itemClassDeReceituarios: ClassificacaoDeReceituarios;
  resultadosExames = new MatTableDataSource();
  viewIframeLabLoading: boolean;
  viewIframeLabBig: boolean;
  selectAnexo = new UploadArquivo();
  listDiagPrincipal: ConteudoDados[];
  viewListProtocolo: boolean;
  listProtocoloClinico: ProtocoloClinico[];
  selectProtocoloClinico = new ProtocoloClinico();
  openUpload_docClinic: boolean;
  registroDocClinic: UploadArquivo[] = [];
  viewConfirmPause: boolean;
  viewConfirmInterromper: boolean;
  paciente: import("../../cadastro-paciente/model").CadastroPessoaForm;

  openSequenas: 1 | 2 = 1;
  tipoNoticReceita: ConteudoDados[] = [];
  listTratamento: ConteudoDados[] = [];
  listnomeProcedimento: ConteudoDados[] = [];
  listEspecialidade: ConteudoDados[] = [];

  constructor(
    private _videoChamada: VideoChamadaService,
    private serviceFotoPerfil: FotoPerfilService,
    private service: AtendimentoClinicoService,
    private serviceConsulta: FichaConsultaMedicaService,
    public sanitizer: DomSanitizer,
    public data_service: BuscarDataService,
    private _horario: AgendaService,
    private mensagem: MensagemService,
    private router: Router,
    private agenda: AgendaService,
    private form: FormBuilder,
    private _foto: FotoPerfilService,
    private _anexos: UploadArquivosService,
    private _conteudo: ConteudoDeDadosService,
    private _protologo: ProtocoloClinicoService,
    private _ticket: TicketsService,
    private _user: UsuarioService
  ) {}

  async ngOnInit() {
    this.creatForm();
    let cabecalho = sessionStorage.getItem("cabecalhoFichaConsultaMedica");

    this.cabecalho = JSON.parse(cabecalho);

    if (!this.cabecalho.nrDoc) {
      this.router.navigate(["/home/SalaEspera/error"]);
      this.mensagem.creat("Não foi possivel iniciar consulta", { erro: true });
    }
    // this.getLog()
    this.getDados();
    this.hoje = this.data_service.hojeBrasil();
    this.hoje_hora = `${this.data_service.hojeBrasil()} ${this.data_service.horaAgora()}h`;

    // this.verificar_chat = setInterval(()=>{this.obsChat()},1000)
    // this.verificar_chat

    this.keyVideoChamada = localStorage.getItem("keyVC");
    this.cpf = sessionStorage.getItem("cpf");
    this.userName = sessionStorage.getItem("userName");
    this.infoSalaEspera = await JSON.parse(
      localStorage.getItem("infoSalaEspera")
    );
    console.log("infoSalaEspera >", this.infoSalaEspera);

    this.paciente = await this._user.getCadastroPessoa(
      this.infoSalaEspera.cpfPaciente
    );

    const converIdade = this.data_service.convertCriadoEm(
      this.paciente.dataNascimento
    );
    this.infoSalaEspera.idade = this.data_service.idade(converIdade);

    this.infoSalaEspera.descTipoAgendamento = (
      await this._conteudo.get(
        "TIPATEND",
        true,
        this.infoSalaEspera.tipoAtendimento
      )
    ).toString();

    if (this.infoSalaEspera.formaDeConsulta == "FORMACON2") {
      setTimeout(() => {
        this.keyVideoChamada = localStorage.getItem("keyVC");
        this.videoChamada = true;
      }, 3000);
    }
    this.rangeHora();
    this.getLaboratorios();
    this.buscarFotoPerfil();
    this.getClassificacaoDeReceituarios();
    setTimeout(() => (this.new_video = true), 20000);
    this.autoSave = setInterval(() => {
      this.salvar();
    }, 30000);
    this.autoSave;
    this.getAnexos();
    this.getConteudo();
    this.getProtoColoClinico();
    this.activeSessao = this.menu[0].id;
    this.activeSubSessao = this.subMenu[this.activeSessao][0].id;
  }

  ngOnDestroy() {
    clearInterval(this.autoSave);
    this.videoChamada = false;
  }

  getExameCID(e: MatOption) {
    return;
    let item = this.form_condutaMedica_solicitacaoExame;
    let cid = e.value.split(" ")[0];
    let exame = e.value.replace(e.value.split(" ")[0], "");
    item.controls["cid"].setValue(cid);
    item.controls["exame"].setValue(exame);
    // console.log(e,)
  }

  async getAnexos() {
    let registroExames = await this._anexos.get(this.cabecalho.nrDoc);
    registroExames = registroExames.filter(
      (value) => value.tipoAnexo == "TIPANE1"
    );
    this.resultadosExames = new MatTableDataSource(registroExames);

    console.log(this.resultadosExames);

    let registroDocClinic = await this._anexos.get(this.cabecalho.nrDoc);
    registroDocClinic = registroDocClinic.filter(
      (value) => value.tipoAnexo == "TIPANE13"
    );
    this.registroDocClinic = registroDocClinic;
    console.log(this.resultadosExames);
  }
  selectProtocolo(item) {
    let get;
    try {
      get = this.listProtocoloClinico.filter(
        (value) => value.diagnostico == item
      );
    } catch {}
    this.selectProtocoloClinico = get[0];
  }
  getProtoColoClinico() {
    this._protologo
      .get()
      .toPromise()
      .then((res) => {
        res.forEach(async (value) => {
          let desc = await this._conteudo.get(
            "DIAGMED",
            true,
            value.diagnostico
          );
          value.descDiagnostico = desc[0].toString();
        });
        this.listProtocoloClinico = res;
      });
  }
  async downloadAnexosExames(file: UploadArquivo) {
    this._anexos.download(file.nomeArquivo, file.path);
  }

  viewAnexo(file: UploadArquivo, imprimir?: boolean) {
    if (!file) return;
    this._anexos
      .getFile(file.nomeArquivo, file.path)
      .toPromise()
      .then((res) => {
        let type = file.nomeArquivo.split(".")[1];
        if (type == "pdf") {
          type = "application/pdf";
        } else {
          type = "image/png";
        }
        console.log("rodrigo response >", res);
        var blob = new Blob([res], { type: type });
        var arquivo: any = this.sanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(blob)
        );
        if (imprimir) {
          if (type == "pdf") {
            window.open(arquivo.changingThisBreaksApplicationSecurity, "_new");
            return;
          }
          var win = window.open("about:blank", "_new");
          win.document.open();
          win.document.write(
            [
              "<html>",
              "   <head>",
              "   </head>",
              '   <body onload="window.print()" onafterprint="window.close()">',
              '       <img src="' +
                arquivo.changingThisBreaksApplicationSecurity +
                '"/>',
              "   </body>",
              "</html>",
            ].join("")
          );
          win.document.close();
        } else {
          window.open(arquivo.changingThisBreaksApplicationSecurity);
        }
      });
  }
  removeAnexo(arquivo) {
    if (!arquivo) return;
    this._anexos
      .removeFile(arquivo)
      .toPromise()
      .then((res) => {
        this.getAnexos();
      })
      .catch((error) => console.log("erro", error));
  }

  filterCID() {
    let item: FormGroup = this.form_condutaMedica_solicitacaoExame;
    let listCIDs = this.CIDs_all;
    let value = item.controls["cid"].value.toLocaleUpperCase();
    let cids = listCIDs.map(
      (v) => `${v.codigo.toLocaleUpperCase()} ${v.descricao}`
    );
    let res = cids.filter((option) =>
      option.toLocaleUpperCase().includes(value)
    );
    if (res.length > 0) {
      this.CIDs = res;
    }
    // console.log('list > ', this.CIDs)
  }
  filterEXAME() {
    let item: FormGroup = this.form_condutaMedica_solicitacaoExame;
    let listExames = this.EXAMES_all;
    let value = item.controls["exame"].value.toLocaleUpperCase();
    let exames = listExames;
    let res = exames.filter((option) =>
      option.toLocaleUpperCase().includes(value)
    );
    if (res.length > 0) {
      this.EXAMES = res;
    }
  }
  filterMedicamento() {
    let item: FormGroup = this.form_condutaMedica_ReceitaBranca;
    let listMedicamento = this.MEDICAMENTOS_all;
    let value = item.controls["inscricaoMedicamento"].value.toLocaleUpperCase();
    let medicamento = listMedicamento.map(
      (v) => `${v.nomeProduto.toLocaleUpperCase()}`
    );

    let res = medicamento.filter((option) =>
      option.toLocaleUpperCase().includes(value)
    );
    if (res.length > 0) {
      this.MEDICAMENTOS = res;
    }
    if (res.length < 1 && value) {
      this.MEDICAMENTOS = [];
    }
  }
  filterReceituario() {
    let item: FormGroup = this.form_condutaMedica_ReceitaEspecial;
    let listReceituario = this.RECEITUARIO_all;
    let value = item.controls["tipoNotificacao"].value;
    console.log("teste ", value);
    let receituario = listReceituario.map((v) => `${v.tipoDeNotificacao}`);

    let res = receituario.filter((option) => option.includes(value));
    console.log(res);
    if (res.length > 0) {
      this.RECEITUARIO = res;
    }
    if (res.length < 1 && value) {
      this.RECEITUARIO = [];
    }
  }

  rangeHora() {
    let horaAgora = this.data_service.horaAgora();
    let minuto = parseInt(this.data_service.horaAgora().split(":")[1]);
    let hora = parseInt(this.data_service.horaAgora().split(":")[0]);
    if (minuto < 16) {
      minuto = 15;
    } else if (minuto < 31) {
      minuto = 30;
    } else if (minuto < 46) {
      minuto = 45;
    } else {
      hora = hora + 1;
      minuto = 0;
    }
    const time = `${hora}:${minuto > 0 ? minuto : "00"}`;
    this.listHorarios = this._horario.listarHorarios_personalizado(time, 4, 15);
    console.log(this.listHorarios);
  }
  rangeData() {
    let hoje = this.data_service.hoje();
    let dt = new Date(hoje);
    let termino = dt.setDate(dt.getDate() + parseInt(this.duracaoDias) + 1);
    console.log(dt.getDate());
    let getDates = this._horario.genereteRangeDatas(
      new Date(hoje),
      new Date(termino)
    );
    const date = getDates[getDates.length - 1];
    this.dateEnd = this.data_service.convertBR(date);
  }
  viewComentario(index) {
    this.index = index;
    this.viewComent = true;
  }
  async buscarFotoPerfil() {
    let cpf = this.infoSalaEspera.cpfPaciente;
    this.loadingImage = true;
    let getFoto = await this._foto.buscarFoto(cpf);
    this.fotoPerfil = getFoto.changingThisBreaksApplicationSecurity
      ? getFoto.changingThisBreaksApplicationSecurity
      : getFoto;
    this.loadingImage = false;
  }
  async viewPage(sessao, page, no_reset?) {
    if (!no_reset) {
      await this.reset();
      await this.resetCheck();
    }
    if (sessao == "avClinica") {
      switch (page) {
        case "anamnese":
          this.avClinica.anamnese = true;
          break;
        case "exame":
          this.avClinica.exame = true;
          break;
        case "afericaoSV":
          this.avClinica.afericaoSV = true;
          break;
        case "descrEstadoMental":
          this.avClinica.descrEstadoMental = true;
          break;
        case "resultadoExame":
          this.avClinica.resultadoExame = true;
          break;
        case "docClinic":
          this.avClinica.docClinic = true;
          break;

        default:
          break;
      }
    }
    if (sessao == "parecerMedico") {
      switch (page) {
        case "hipoDiagnostica":
          this.parecerMedico.hipoDiagnostica = true;
          break;
        case "diagnostico":
          this.parecerMedico.diagnostico = true;
          break;
        case "quadroCirurgico":
          this.parecerMedico.quadroCirurgico = true;
          break;
        case "sequelas":
          this.parecerMedico.sequelas = true;
          break;
        case "causasMortais":
          this.parecerMedico.causasMortais = true;
          break;
      }
    }
    if (sessao == "condutaMedica") {
      switch (page) {
        case "solicExame":
          this.condutaMedica.solicExame = true;
          break;
        case "receituario":
          this.condutaMedica.receituario = true;
          break;
        case "indiTratamento":
          this.condutaMedica.indiTratamento = true;
          break;
        case "indiCirurgia":
          this.condutaMedica.indiCirurgia = true;
          break;
      }
    }
    if (sessao == "encerrarAt") {
      switch (page) {
        case "atEncaminhamento":
          this.encerrarAt.atEncaminhamento = true;
          break;
        case "solicRetorno":
          this.encerrarAt.solicRetorno = true;
          break;
        case "encerrar":
          this.encerrarAt.encerrar = true;
          break;

        default:
          break;
      }
    }
    if (sessao == "atDadosSaude") {
      switch (page) {
        case "pcd":
          this.atDadosSaude.pcd = true;
          break;
        case "sinaisVitais":
          this.atDadosSaude.sinaisVitais = true;
          break;
        case "medUso":
          this.atDadosSaude.medUso = true;
          break;
        case "dataUtChekup":
          this.atDadosSaude.dataUtChekup = true;
          break;
        case "cartVacinacao":
          this.atDadosSaude.cartVacinacao = true;
          break;
        case "habVida":
          this.atDadosSaude.habVida = true;
          break;
        case "doencaCong":
          this.atDadosSaude.doencaCong = true;
          break;
        case "doencaPree":
          this.atDadosSaude.doencaPree = true;
          break;
        case "doencaCronicas":
          this.atDadosSaude.doencaCronicas = true;
          break;
        case "doencaContraidas":
          this.atDadosSaude.doencaContraidas = true;
          break;
        case "doencaFamilia":
          this.atDadosSaude.doencaFamilia = true;
          break;
        case "evolucaoSaude":
          this.atDadosSaude.evolucaoSaude = true;
          break;
        case "cirurgiaRealizada":
          this.atDadosSaude.cirurgiaRealizada = true;
          break;
        case "lesaoFratCont":
          this.atDadosSaude.lesaoFratCont = true;
          break;
        case "alergiaMedic":
          this.atDadosSaude.alergiaMedic = true;
          break;
        case "outrasAlergias":
          this.atDadosSaude.outrasAlergias = true;
          break;
        case "tratRealizado":
          this.atDadosSaude.tratRealizado = true;
          break;
        case "procAdicionais":
          this.atDadosSaude.procAdicionais = true;
          break;
        case "interHosp":
          this.atDadosSaude.interHosp = true;
          break;
        case "medReceitaEsp":
          this.atDadosSaude.medReceitaEsp = true;
          break;
        case "medReceitaBranca":
          this.atDadosSaude.medReceitaBranca = true;
          break;
      }
    }
    if (sessao == "visaoGC") {
      this.visaoGC = {
        avClinica: false,
        parecerMed: false,
        condutaMed: false,
        procAdicional: false,
        encerrarAt: false,
      };
      switch (page) {
        case "avClinica":
          this.visaoGC.avClinica = true;
          break;
        case "parecerMed":
          this.visaoGC.parecerMed = true;
          break;
        case "condutaMed":
          this.visaoGC.condutaMed = true;
          break;
        case "procAdicional":
          this.visaoGC.procAdicional = true;
          break;
        case "encerrarAt":
          this.visaoGC.encerrarAt = true;
          break;
      }
    }
    if (sessao == "prontMedPac") {
      switch (page) {
        case "diagnostico":
          this.prontMedPac.diagnostico = true;
          break;
        case "sequelas":
          this.prontMedPac.sequelas = true;
          break;
        case "exames":
          this.prontMedPac.exames = true;
          break;
        case "consultas":
          this.prontMedPac.consultas = true;
          break;
        case "diagnostico":
          this.prontMedPac.diagnostico = true;
          break;
        case "encMedico":
          this.prontMedPac.encMedico = true;
          break;
      }
    }
  }
  async addCheck(check, item) {
    if (check) {
      this.listCheck_delete.push(item);
      this.selectAnexo = item;
    } else {
      this.selectAnexo = null;
      let index = this.listCheck_delete.findIndex((x) => x.index == item.index);
      delete this.listCheck_delete[index];
      this.listCheck_delete = this.listCheck_delete.filter((x) => x);
    }
  }

  openDownload(file) {
    this.downloadAnexosExames(file);
    // this.viewDownload = true
  }
  openLaboratorio() {
    this.viewIframeLabLoading = true;
    // this.viewDownload = false
    this.viewIframeLab = true;
    const link = this.infoLaboratorio.link;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    setTimeout(() => {
      this.viewIframeLabLoading = false;
    }, 5000);
  }
  async checkLaboratorio(check, reg, index) {
    await this.listLaboratorios.forEach((element) => {
      element.checked = false;
    });
    console.log(this.listLaboratorios);
    if (check) {
      this.infoLaboratorio.nome = reg.nomeLaboratorio;
      this.infoLaboratorio.link = reg.linkLaboratorio;
      this.listLaboratorios[index].checked = true;
      this.openLaboratorio();
    } else {
      this.infoLaboratorio.nome = null;
      this.infoLaboratorio.link = null;
      this.listLaboratorios[index].check = false;
    }
  }
  async getLaboratorios() {
    let t = "";
    t.toLocaleUpperCase;
    this.service
      .getLaboratorios()
      .toPromise()
      .then((res) => {
        res = res.filter((x) => x.status.toLowerCase() == "ativo");
        res.forEach((list) => {
          list.checked = false;
          this.listLaboratorios.push(list);
        });
      });
  }
  async getConteudo() {
    this.listDiagPrincipal = await this._conteudo.get("DIAGMED");
    this.tipoNoticReceita = await this._conteudo.get("LITINO");
    this.listTratamento = await this._conteudo.get("LISTRA");
    this.listnomeProcedimento = await this._conteudo.get("TUSS");
    this.listEspecialidade = await this._conteudo.get("ESPPRO");
    console.log("listTratamento", this.listTratamento);
  }

  async startViewList() {
    await this.reset();
    this.avClinica.anamnese = true;
    this.parecerMedico.hipoDiagnostica = true;
    this.condutaMedica.solicExame = true;
    this.encerrarAt.atEncaminhamento = true;
    this.atDadosSaude.pcd = false;
    this.visaoGC.avClinica = true;
    this.painelFull = false;
    this.painelFull_2 = false;
  }

  reset() {
    this.viewAdd = false;
    this.viewReceitaEletronica = false;
    this.viewDelete = false;
    this.avClinica.anamnese = false;
    this.avClinica.exame = false;
    this.avClinica.afericaoSV = false;
    this.avClinica.descrEstadoMental = false;
    this.avClinica.resultadoExame = false;
    this.avClinica.docClinic = false;
    this.parecerMedico.hipoDiagnostica = false;
    this.parecerMedico.causasMortais = false;
    this.parecerMedico.diagnostico = false;
    this.parecerMedico.quadroCirurgico = false;
    this.parecerMedico.sequelas = false;
    this.condutaMedica.solicExame = false;
    this.condutaMedica.indiCirurgia = false;
    this.condutaMedica.indiTratamento = false;
    this.condutaMedica.receituario = false;
    this.encerrarAt.atEncaminhamento = false;
    this.encerrarAt.encerrar = false;
    this.encerrarAt.solicRetorno = false;
    this.atDadosSaude.pcd = false;
    this.atDadosSaude.sinaisVitais = false;
    this.atDadosSaude.medUso = false;
    this.atDadosSaude.dataUtChekup = false;
    this.atDadosSaude.cartVacinacao = false;
    this.atDadosSaude.evolucaoSaude = false;
    this.atDadosSaude.habVida = false;
    this.atDadosSaude.doencaCong = false;
    this.atDadosSaude.doencaPree = false;
    this.atDadosSaude.doencaCronicas = false;
    this.atDadosSaude.doencaContraidas = false;
    this.atDadosSaude.doencaFamilia = false;
    this.atDadosSaude.cirurgiaRealizada = false;
    this.atDadosSaude.lesaoFratCont = false;
    this.atDadosSaude.alergiaMedic = false;
    this.atDadosSaude.outrasAlergias = false;
    this.atDadosSaude.tratRealizado = false;
    this.atDadosSaude.procAdicionais = false;
    this.atDadosSaude.interHosp = false;
    this.atDadosSaude.medReceitaEsp = false;
    this.atDadosSaude.medReceitaBranca = false;
    this.visaoGC.avClinica = false;
    this.visaoGC.encerrarAt = false;
    this.visaoGC.parecerMed = false;
    this.visaoGC.procAdicional = false;
    this.visaoGC.condutaMed = false;
    this.prontMedPac.consultas = false;
    this.prontMedPac.diagnostico = false;
    this.prontMedPac.encMedico = false;
    this.prontMedPac.exames = false;
    this.prontMedPac.sequelas = false;
  }
  resetCheck() {
    this.viewAdd = false;
    this.viewDelete = false;
    this.listCheck_delete = [];
    this.itens.forEach((x) => {
      x.checked = false;
    });
    this.itens2.forEach((x) => {
      x.checked = false;
    });
    this.itens3.forEach((x) => {
      x.checked = false;
    });
    this.itens4.forEach((x) => {
      x.checked = false;
    });
  }
  resetListLaboratorio() {
    this.listLaboratorios.forEach((element) => {
      element.checked = false;
    });
    this.viewIframeLab = false;
    this.viewIframeLabBig = false;
  }
  painelOpenAll(painel_2?) {
    if (painel_2) {
      this.painelFull_2 = true;
      return;
    }
    this.painelFull = true;
  }
  painelCloseAll(painel_2?) {
    if (painel_2) {
      this.painelFull_2 = false;
      return;
    }
    this.painelFull = false;
  }
  obsChat() {
    let chat = localStorage.getItem("openChat");
    let info = JSON.parse(localStorage.getItem("infoChat"));
    if (chat) {
      this.chat.cpfMedicoConvidador = this.cpf;
      this.chat.cpfConvidado = info.destinatario;
      this.chat.nomeConvidado = "convidado";
      this.chat.nomeConvidador = this.userName;
      console.log(this.chat);
      this.openChat = true;
      clearInterval(this.verificar_chat);
    } else {
      this.openChat = false;
    }
  }
  newVideoChamada() {
    this.loadingVideo = true;
    this._videoChamada
      .banco()
      .doc(this.keyVideoChamada)
      .collection("info")
      .get()
      .toPromise()
      .then((r) => {
        if (r.docs[0].exists) {
          let info = r.docs[0].data();
          this.loadingVideo = false;
          this._videoChamada
            .banco()
            .add({ create: true })
            .then(async (r) => {
              const id_doc = r.id;
              await this._videoChamada.banco().doc(id_doc).set({ new: true });
              await this._videoChamada
                .banco()
                .doc(id_doc)
                .collection("info")
                .add(info)
                .then(() => {
                  this._videoChamada
                    .getVideoChamda()
                    .toPromise()
                    .then((reg: videoChamadaFrom[]) => {
                      const id = this.keyVideoChamada;
                      let info = reg.filter((r) => {
                        return r.codigoUnico.toLowerCase() == id.toLowerCase();
                      })[0];
                      console.log("NEWVIDEOCHAMADA", info);
                      if (info) {
                        info.status = "pendente";
                        info.codigoUnico = id_doc;
                        console.log("NEWVIDEOCHAMADA", info);
                        this.keyVideoChamada = id_doc;
                        (this.videoChamada = true), (this.loadingVideo = false);
                        setTimeout(
                          () =>
                            this._videoChamada
                              .putVideoChamada(info)
                              .toPromise()
                              .then(() => {}),
                          10000
                        );
                      } else {
                        this.mensagem.creat(
                          "Não foi possível registrar chamda!",
                          { erro: true }
                        );
                      }
                    });
                });
            });
          return;
          this._videoChamada
            .banco()
            .doc(this.keyVideoChamada)
            .set({ new: true });
        }
      });
  }
  viewQRCODE() {
    this.openQRCODE = !this.openQRCODE;
  }

  openViewDoc(doc, download?) {
    if (download) {
      this.viewDocPDF = true;
      this.downloadPDF = true;
    } else {
      this.viewDocPDF = true;
      this.downloadPDF = false;
    }

    return;
    this.router.navigate(["/home/documento/view"]);
  }
  async salvar(click?) {
    this.validEncerrarAtendimento();
    if (this.loadingSave) return;
    this.loadingSave = true;
    let numeroDocumento = this.cabecalho.nrDoc;
    let itens = [];

    /////Avaliação Clinica
    if (this.form_avClinica.anamnese) {
      let item: any = {};
      item.codigoCaracteristica = await this.codItem.Anamnese;
      item.valor = await this.form_avClinica.anamnese;
      await itens.push(item);
    }
    if (this.form_avClinica.exame) {
      let item: any = {};
      item.codigoCaracteristica = await this.codItem.ExamesFisicosVisuais;
      item.valor = await this.form_avClinica.exame;
      await itens.push(item);
    }
    if (this.form_avClinica.afericaoSV.saturacao) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.AfericaoSinaisVitais_Saturacao;

      item.valor = this.form_avClinica.afericaoSV.saturacao;
      itens.push(item);
    }
    if (this.form_avClinica.afericaoSV.temperatura) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.AfericaoSinaisVitais_Temperatura;
      item.valor = this.form_avClinica.afericaoSV.temperatura;
      itens.push(item);
    }
    if (this.form_avClinica.afericaoSV.pressaoArterial) {
      let item: any = {};
      item.codigoCaracteristica =
        this.codItem.AfericaoSinaisVitais_PressaoArterial;
      item.valor = this.form_avClinica.afericaoSV.pressaoArterial;
      itens.push(item);
    }
    if (this.form_avClinica.afericaoSV.glicemia) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.AfericaoSinaisVitais_Glicemia;
      item.valor = this.form_avClinica.afericaoSV.glicemia;
      itens.push(item);
    }
    if (this.form_avClinica.afericaoSV.batCardiaco) {
      let item: any = {};
      item.codigoCaracteristica =
        this.codItem.AfericaoSinaisVitais_BatimentosCardiacos;
      item.valor = this.form_avClinica.afericaoSV.batCardiaco;
      itens.push(item);
    }
    if (this.form_avClinica.afericaoSV.batCardiacoFeto) {
      let item: any = {};
      item.codigoCaracteristica =
        this.codItem.AfericaoSinaisVitais_BatimentosCardiacosFeto;
      item.valor = this.form_avClinica.afericaoSV.batCardiacoFeto;
      itens.push(item);
    }

    /////Parecer Medico
    if (this.form_parecerMedico.hipoDiagnostica) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.hipoDiagnostica;
      item.valor = this.form_parecerMedico.hipoDiagnostica;
      itens.push(item);
    }

    if (this.form_parecerMedico.diagnostico_princical) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.diagnostico_princical;
      item.valor = this.form_parecerMedico.diagnostico_princical;
      itens.push(item);
    }

    if (this.form_parecerMedico.diagnostico_descricao) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.diagnostico_descricao;
      item.valor = this.form_parecerMedico.diagnostico_descricao;
      itens.push(item);
    }

    if (this.form_parecerMedico.quadroCirurgico) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.quadroCirurgico;
      item.valor = this.form_parecerMedico.quadroCirurgico;
      itens.push(item);
    }

    if (this.form_parecerMedico.sequelas_diagnostico) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.sequelas_diagnostico;
      item.valor = this.form_parecerMedico.sequelas_diagnostico;
      itens.push(item);
    }

    if (this.form_parecerMedico.sequelas_cirurgico) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.sequelas_cirurgico;
      item.valor = this.form_parecerMedico.sequelas_cirurgico;
      itens.push(item);
    }

    if (this.form_parecerMedico.causasMortais) {
      let item: any = {};
      item.codigoCaracteristica = this.codItem.causasMortais;
      item.valor = this.form_parecerMedico.causasMortais;
      itens.push(item);
    }

    ///Conduta Médica
    if (this.condutaMedica_listSolicitacaoExame.length > 0) {
      this.salvarSubItem("condutaMedica_SolicitacaoExame", click);
    }
    if (this.condutaMedica_listReceitaBranca.length > 0) {
      this.salvarSubItem("condutaMedica_ReceitaBranca", click);
    }
    if (this.condutaMedica_listReceitaEspecial.length > 0) {
      this.salvarSubItem("condutaMedica_ReceitaEspecial", click);
    }
    if (this.condutaMedica_listIndicacaoTratamento.length > 0) {
      this.salvarSubItem("condutaMedica_IndicacaoTratamento", click);
    }
    if (this.condutaMedica_listIndicacaoCirurgia.length > 0) {
      this.salvarSubItem("condutaMedica_IndicacaoCirurgia", click);
    }
    if (this.condutaMedica_listProcedimentoAdicional.length > 0) {
      this.salvarSubItem("condutaMedica_ProcedimentoAdicional", click);
    }

    //Encerrar Atendimento
    if (this.form_encerrarAt_AtestadoAfastamento.valid) {
      this.salvarSubItem("encerrarAt_AtestadoAfastamento", click);
    }
    if (this.form_encerrarAt_AtestadoMedico.valid) {
      this.salvarSubItem("encerrarAt_AtestadoMedico", click);
    }
    if (this.form_encerrarAt_AtestadoAcompanhante.valid) {
      this.salvarSubItem("encerrarAt_AtestadoAcompanhante", click);
    }
    if (this.encerrarAt_listEncaminhamentoMedico.length > 0) {
      this.salvarSubItem("encerrarAt_encaminhamentoMedico", click);
    }
    if (this.form_encerrarAt_solicitacaoRetorno.valid) {
      this.salvarSubItem("encerrarAt_solicitacao", click);
    }
    if (this.form_encerrarAt_solicitacaoPeriodica.valid) {
      this.salvarSubItem("encerrarAt_solicitacao", click);
    }
    if (this.condutaMedica_listReceitaEspecial.length > 0) {
      this.salvarSubItem("condutaMedica_ReceitaEspecial", click);
    }
    if (this.condutaMedica_listIndicacaoTratamento.length > 0) {
      this.salvarSubItem("condutaMedica_IndicacaoTratamento", click);
    }
    if (this.condutaMedica_listIndicacaoCirurgia.length > 0) {
      this.salvarSubItem("condutaMedica_IndicacaoCirurgia", click);
    }
    if (this.condutaMedica_listProcedimentoAdicional.length > 0) {
      this.salvarSubItem("condutaMedica_ProcedimentoAdicional", click);
    }

    //Encerrar Atendimento
    if (this.form_encerrarAt_AtestadoAfastamento.valid) {
      this.salvarSubItem("encerrarAt_AtestadoAfastamento", click);
    }

    console.log(itens);
    this.serviceConsulta.registrar(itens, numeroDocumento).then((res) => {
      setTimeout(() => {
        this.loadingSave = false;
        if (res.status) {
          if (click) {
            this.mensagem.creat("Documento salvo com sucesso", {
              sucesso: true,
            });
          } else {
            this.mensagem.creat("Auto salvar realizado com sucesso", {
              sucesso: true,
            });
          }
        } else {
          if (!res.response) return;
          this.mensagem.creat("Falha ao Salvar registro! : " + res.response, {
            erro: true,
          });
        }
      }, 2000);
    });
    localStorage.setItem(
      "logFichaConsulta",
      JSON.stringify(this.form_avClinica)
    );
    return;
  }
  async salvarSubItem(type, click) {
    let numeroDocumento = this.cabecalho.nrDoc;
    let itens = [];
    let item: any = {};
    switch (type) {
      case "condutaMedica_SolicitacaoExame":
        console.log(this.condutaMedica_listSolicitacaoExame);

        await this.condutaMedica_listSolicitacaoExame.forEach((e, i) => {
          item = e;
          item.codigoCaracteristica = this.codItem.indicTratamento;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            this.condutaMedica_listSolicitacaoExame.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postSolicitacaoDeExames(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "condutaMedica_ReceitaBranca":
        await this.condutaMedica_listReceitaBranca.forEach((e, i) => {
          item = e;
          item.codigoCaracteristica = this.codItem.receitaBranca;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            this.condutaMedica_listReceitaBranca.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postReceituarioBranco(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "condutaMedica_ReceitaEspecial":
        await this.condutaMedica_listReceitaEspecial.forEach((e, i) => {
          item = e;
          item.codigoCaracteristica = this.codItem.receitaBranca;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            this.condutaMedica_listReceitaEspecial.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postReceituarioEspecial(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "condutaMedica_IndicacaoTratamento":
        await this.condutaMedica_listIndicacaoTratamento.forEach((e, i) => {
          item = e;
          item.codigoCaracteristica = this.codItem.receitaBranca;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            this.condutaMedica_listIndicacaoTratamento.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postIndicacaoTratamentos(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "condutaMedica_IndicacaoCirurgia":
        await this.condutaMedica_listIndicacaoCirurgia.forEach((e, i) => {
          item = e;
          item.codigoCaracteristica = this.codItem.receitaBranca;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            this.condutaMedica_listIndicacaoCirurgia.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postIndicacaoCirurgia(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "condutaMedica_ProcedimentoAdicional":
        await this.condutaMedica_listProcedimentoAdicional.forEach((e, i) => {
          item = e;
          item.codigoCaracteristica = this.codItem.procedimentoAdicional;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            this.condutaMedica_listProcedimentoAdicional.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postProcedimentoAdicional(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      ///////Encerrar Atendimento
      case "encerrarAt_AtestadoAfastamento":
        this.form_encerrarAt_AtestadoAfastamento.controls["cpf"].setValue(
          this.infoSalaEspera.cpfPaciente
        );
        this.form_encerrarAt_AtestadoAfastamento.controls["nome"].setValue(
          this.infoSalaEspera.nomePaciente
        );
        itens.push(this.form_encerrarAt_AtestadoAfastamento.value);
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            itens.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              // r.codigoUnico = this.codItem.atestadoAfastamento
              this.serviceConsulta.postAtestadoAfastamento(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "encerrarAt_AtestadoMedico":
        this.form_encerrarAt_AtestadoMedico.controls["cpfDoPaciente"].setValue(
          this.infoSalaEspera.cpfPaciente
        );
        this.form_encerrarAt_AtestadoMedico.controls["nomePaciente"].setValue(
          this.infoSalaEspera.nomePaciente
        );
        this.form_encerrarAt_AtestadoMedico.controls["codigoUnico"].setValue(
          this.codItem.atestadoMedica
        );
        itens.push(this.form_encerrarAt_AtestadoMedico.value);
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            itens.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postAtestadoMedico(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "encerrarAt_AtestadoAcompanhante":
        itens.push(this.form_encerrarAt_AtestadoAcompanhante.value);
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            itens.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postAtestadoAcompanhante(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;
      case "encerrarAt_encaminhamentoMedico":
        await this.encerrarAt_listEncaminhamentoMedico.forEach((e, i) => {
          //  item.codigoCaracteristica = this.codItem.procedimentoAdicional
          item = e;
          itens.push(item);
        });
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            itens.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postEncaminhamentoMedico(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      case "encerrarAt_solicitacao":
        if (this.form_encerrarAt_solicitacaoPeriodica.valid) {
        }
        if (this.form_encerrarAt_solicitacaoRetorno.valid) {
          itens.push(this.form_encerrarAt_solicitacaoPeriodica.value);
        }
        await this.serviceConsulta
          .registrar(itens, numeroDocumento, true)
          .then((res) => {
            itens.forEach((r, i) => {
              r.subItem = JSON.stringify(i + 1);
              r.item = 1;
              r.numeroFichaConsulta = numeroDocumento;
              this.serviceConsulta.postSolicitacaoRetorno(r).then((res) => {
                if (!res.status) {
                  if (!res.response) return;
                  this.mensagem.creat(
                    "Falha ao Salvar registro! : " + res.response,
                    { erro: true }
                  );
                }
              });
            });
            setTimeout(() => {
              this.loadingSave = false;
              if (res.status) {
                if (click) {
                  this.mensagem.creat("Documento salvo com sucesso", {
                    sucesso: true,
                  });
                } else {
                  this.mensagem.creat("Auto salvar realizado com sucesso", {
                    sucesso: true,
                  });
                }
              } else {
                if (!res.response) return;
                this.mensagem.creat(
                  "Falha ao Salvar registro! : " + res.response,
                  { erro: true }
                );
              }
            }, 2000);
          });
        break;

      default:
        break;
    }
  }
  validEncerrarAtendimento() {
    this.DocEncAt = [];
    if (this.condutaMedica_listSolicitacaoExame.length > 0) {
      this.DocEncAt.push({ nome: "Solicitações de exames", impressao: false });
    }
    if (this.condutaMedica_listIndicacaoTratamento.length > 0) {
      this.DocEncAt.push({ nome: "Indicação de tratamento", impressao: false });
    }
    if (this.condutaMedica_listIndicacaoCirurgia.length > 0) {
      this.DocEncAt.push({ nome: "Indicação de cirurgia", impressao: false });
    }
    if (this.form_encerrarAt_AtestadoAfastamento.valid) {
      this.DocEncAt.push({ nome: "Atestado de afastamento", impressao: false });
    }
    if (this.form_encerrarAt_AtestadoAcompanhante.valid) {
      this.DocEncAt.push({
        nome: "Atestado de acompanhante",
        impressao: false,
      });
    }
    if (this.form_encerrarAt_AtestadoMedico.valid) {
      this.DocEncAt.push({ nome: "Atestado médico", impressao: false });
    }
    if (this.encerrarAt_listEncaminhamentoMedico.length > 0) {
      this.DocEncAt.push({ nome: "Encaminhamento", impressao: false });
    }
    if (this.condutaMedica_listProcedimentoAdicional.length > 0) {
      this.DocEncAt.push({ nome: "Procedimento adicional", impressao: false });
    }
    if (
      this.condutaMedica_listReceitaBranca.length > 0 ||
      this.condutaMedica_listReceitaEspecial.length > 0
    ) {
      this.DocEncAt.push({ nome: "Receituário", impressao: false });
    }
    // { nome: 'Receituário', impressao: false }
  }
  creatForm() {
    this.form_condutaMedica_solicitacaoExame = this.form.group({
      cid: "",
      exame: "",
      descricaoExame: "",
    });

    this.form_condutaMedica_ReceitaBranca = this.form.group({
      inscricaoMedicamento: "",
      superInscricaoDoUso: "",
      continuo: false,
      subscricaoDaQuantidade: "",
      adscricaoDasOrientacoes: "",
    });

    this.form_condutaMedica_ReceitaEspecial = this.form.group({
      tipoNotificacao: "",
      tipoReceita: "",
      numeroTalonario: "",
      lista: "",
      abrangencia: "",
      qtdPorPeriodo: "",
      qtdPorReceita: "",
      validade: "",
      talaoDeNotificacao: "",
      medicamentos: "",
    });
    this.form_condutaMedica_ReceitaEletronica = this.form.group({
      id: new FormControl(""),
      criadoPor: new FormControl(""),
      dataHoraInclusaoDeTabela: new FormControl(""),
      cpfPaciente: new FormControl(""),
      nomePaciente: new FormControl(""),
      observacao: new FormControl(""),
      codigoUnico: new FormControl(""),
      codigoMedicamento: new FormControl(""),
      frequencia: new FormControl(""),
      dosagem: new FormControl(""),
      periodo: new FormControl(""),
      crmMedico: new FormControl(""),
      via: new FormControl(""),
      status: new FormControl(""),
      principioAtivo: new FormControl(""),
      nomeComercial: new FormControl(""),
      codigoDeMedicamento: new FormControl(""),
      codigoDeBulaDeRemedio: new FormControl(""),
    });
    this.form_condutaMedica_IndicacaoTratamentos = this.form.group({
      nomeTratamento: "",
      descricao: "",
      quantidade: "",
    });

    this.form_condutaMedica_IndicacaoCirurgia = this.form.group({
      cid: "",
      tipo: "",
      cirugia: "",
      motivo: "",
    });
    this.form_condutaMedica_ProcedimentoAdicional = this.form.group({
      nomeAdicional: "",
      cobrar: "",
      quantidade: "",
    });
    this.form_encerrarAt_AtestadoAfastamento = this.form.group({
      cid: new FormControl("", Validators.required),
      duracaoDias: new FormControl("", Validators.required),
      inicio: new FormControl("", Validators.required),
      fim: new FormControl("", Validators.required),
      cpf: "",
      nome: "",
    });
    this.form_encerrarAt_AtestadoMedico = this.form.group({
      cpfDoPaciente: this.infoSalaEspera.cpfPaciente,
      nomePaciente: this.infoSalaEspera.nomePaciente,
      inicio: new FormControl("", Validators.required),
      periodo: new FormControl("", Validators.required),
      fim: new FormControl("", Validators.required),
      cid: new FormControl("", Validators.required),
      codigoUnico: new FormControl(""),
    });
    this.form_encerrarAt_AtestadoAcompanhante = this.form.group({
      cpf: new FormControl("", Validators.required),
      nome: new FormControl("", Validators.required),
      periodo: new FormControl("", Validators.required),
      cid: new FormControl(""),
      inicio: new FormControl("", Validators.required),
      fim: new FormControl(""),
    });
    this.form_encerrarAt_encaminhamentoMedico = this.form.group({
      especialidade: "",
      cid: "",
      motivo: "",
      parecerMedico: "",
      numeroFichaConsulta: "",
    });
    this.form_encerrarAt_solicitacaoRetorno = this.form.group({
      tipoConsulta: new FormControl("", Validators.required),
      retorno: new FormControl("", Validators.required),
      tipoData: "Dia",
      periodo: "",
      periodica: false,
      aCada: "",
    });
    this.form_encerrarAt_solicitacaoPeriodica = this.form.group({
      tipoConsulta: new FormControl("", Validators.required),
      retorno: "",
      tipoData: "Dia",
      periodo: new FormControl("", Validators.required),
      periodica: true,
      aCada: new FormControl("", Validators.required),
    });
  }
  testeForm() {
    console.log(
      this.form_encerrarAt_AtestadoAcompanhante.value,
      this.form_encerrarAt_AtestadoAcompanhante.valid
    );
  }
  inserir(type, registro) {
    switch (type) {
      case "condutaMedica_SolicitacaoExame":
        registro.index = this.condutaMedica_listSolicitacaoExame.length;
        this.condutaMedica_listSolicitacaoExame.push(registro);
        console.log("passou", this.condutaMedica_listSolicitacaoExame);
        this.dataSolicExame.data = this.condutaMedica_listSolicitacaoExame;
        this.viewAdd = false;
        break;
      case "condutaMedica_ReceitaBranca":
        registro.item = this.condutaMedica_listReceitaBranca.length + 1;
        this.condutaMedica_listReceitaBranca.push(registro);
        console.log("passou ", this.condutaMedica_listReceitaBranca);
        this.dataReceitaBranca = new MatTableDataSource(
          this.condutaMedica_listReceitaBranca
        );
        this.viewAdd = false;

        break;
      case "condutaMedica_ReceitaEspecial":
        registro.item = this.condutaMedica_listReceitaEspecial.length + 1;
        this.condutaMedica_listReceitaEspecial.push(registro);
        console.log("passou ", this.condutaMedica_listReceitaEspecial);
        this.dataReceitaEspecial = new MatTableDataSource(
          this.condutaMedica_listReceitaEspecial
        );
        this.viewAdd = false;
        break;
      case "condutaMedica_ReceitaEletronica":
        registro.item = this.condutaMedica_listReceitaEletronica.length + 1;
        this.condutaMedica_listReceitaEletronica.push(registro);
        console.log("passou ", this.condutaMedica_listReceitaEletronica);
        this.dataReceitaEspecial = new MatTableDataSource(
          this.condutaMedica_listReceitaEletronica
        );
        this.viewAdd = false;
        break;
      case "condutaMedica_indicacaoTratamento":
        registro.item = this.condutaMedica_listIndicacaoTratamento.length + 1;
        this.condutaMedica_listIndicacaoTratamento.push(registro);
        console.log("passou ", this.condutaMedica_listIndicacaoTratamento);
        this.dataIndtratamento = new MatTableDataSource(
          this.condutaMedica_listIndicacaoTratamento
        );
        this.viewAdd = false;
        break;

      case "condutaMedica_indicacaoCirurgia":
        registro.item = this.condutaMedica_listIndicacaoCirurgia.length + 1;
        this.condutaMedica_listIndicacaoCirurgia.push(registro);
        console.log("passou ", this.condutaMedica_listIndicacaoCirurgia);
        this.dataIndCirurgia = new MatTableDataSource(
          this.condutaMedica_listIndicacaoCirurgia
        );
        this.viewAdd = false;

        break;
      case "condutaMedica_ProcedimentoAdicional":
        registro.item = this.condutaMedica_listProcedimentoAdicional.length + 1;
        this.condutaMedica_listProcedimentoAdicional.push(registro);
        console.log("passou ", this.condutaMedica_listProcedimentoAdicional);
        this.dataProdAdicional = new MatTableDataSource(
          this.condutaMedica_listProcedimentoAdicional
        );
        this.viewAdd = false;

        break;
      case "encerrarAt_EncMedico":
        registro.item = this.encerrarAt_listEncaminhamentoMedico.length + 1;
        this.encerrarAt_listEncaminhamentoMedico.push(registro);
        console.log("passou ", this.encerrarAt_listEncaminhamentoMedico);
        this.dataEncMedico = new MatTableDataSource(
          this.encerrarAt_listEncaminhamentoMedico
        );
        this.viewAdd = false;

        break;

      default:
        break;
    }
    this.resetForm();
  }

  async remover(type, form?: any) {
    switch (type) {
      case "condutaMedica_SolicExame":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listSolicitacaoExame.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listSolicitacaoExame.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_ReceitaBranca":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listReceitaBranca.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listReceitaBranca.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_ReceitaEspecial":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listReceitaEspecial.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listReceitaEspecial.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_ReceitaEletronica":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listReceitaEletronica.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listReceitaEletronica.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_IndicacaoTratamento":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listIndicacaoTratamento.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listIndicacaoTratamento.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_indicacaoCirurgia":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listIndicacaoCirurgia.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listIndicacaoCirurgia.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_ProcedimentoAdicional":
        await this.listCheck_delete.forEach((x) => {
          this.condutaMedica_listProcedimentoAdicional.filter((y, i) => {
            if (x.item == y.item) {
              this.condutaMedica_listProcedimentoAdicional.splice(i, 1);
            }
          });
        });
        break;
      case "encerrarAt_EncMedico":
        await this.listCheck_delete.forEach((x) => {
          this.encerrarAt_listEncaminhamentoMedico.filter((y, i) => {
            if (x.item == y.item) {
              this.encerrarAt_listEncaminhamentoMedico.splice(i, 1);
            }
          });
        });
        break;
      case "condutaMedica_SolicitacaoExame":
        console.log("--->", this.dataSolicExame, this.listCheck_delete);
        const register = this.dataSolicExame.data;
        let newList = [];
        register.forEach((x) => {
          let index = this.listCheck_delete
            .filter((v) => v)
            .findIndex((v) => v.index == x.index);
          if (index < 0) newList.push(x);
        });

        if (newList.length > 0) {
          this.dataSolicExame.data = newList;

          console.log("<<<<< PASSOU >>>>>", this.dataSolicExame, newList);
        } else this.dataSolicExame.data = [];
        break;
      default:
        break;
    }
    this.viewDelete = false;
    this.listCheck_delete = [];
    // this.dataSolicExame.data = this.condutaMedica_listSolicitacaoExame
    this.dataReceitaBranca = new MatTableDataSource(
      this.condutaMedica_listReceitaBranca
    );
    this.dataReceitaEspecial = new MatTableDataSource(
      this.condutaMedica_listReceitaEspecial
    );
    this.dataIndtratamento = new MatTableDataSource(
      this.condutaMedica_listIndicacaoTratamento
    );
    this.dataIndCirurgia = new MatTableDataSource(
      this.condutaMedica_listIndicacaoCirurgia
    );
    this.dataProdAdicional = new MatTableDataSource(
      this.condutaMedica_listProcedimentoAdicional
    );
    this.dataEncMedico = new MatTableDataSource(
      this.encerrarAt_listEncaminhamentoMedico
    );
  }
  printTable(el) {
    var restorepage = document.body.innerHTML;
    var printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  }
  resetForm() {
    this.form_condutaMedica_solicitacaoExame.reset();
    this.form_condutaMedica_ReceitaBranca.reset();
    this.form_condutaMedica_ReceitaEspecial.reset();
    this.form_condutaMedica_ReceitaEletronica.reset();
    this.form_condutaMedica_IndicacaoTratamentos.reset();
    this.form_condutaMedica_IndicacaoCirurgia.reset();
    this.form_condutaMedica_ProcedimentoAdicional.reset();
    this.form_encerrarAt_AtestadoAfastamento.reset();
    this.form_encerrarAt_encaminhamentoMedico.reset();
  }
  getLog() {
    let AvClinica = localStorage.getItem("logFichaConsulta");
    if (AvClinica) {
      this.form_avClinica = JSON.parse(AvClinica);
    }
  }
  getDados() {
    this.getCIDS();
    this.getExames();
    this.getMedicamento();
    this.getReceituario();
  }
  getMedicamento() {
    this.serviceConsulta
      .getMedicamentos()
      .toPromise()
      .then((m) => {
        this.MEDICAMENTOS = m.map((x) => x.nomeProduto);
        this.MEDICAMENTOS_all = m;
      });
  }

  getCIDS() {
    this.serviceConsulta.getCID().then((cids) => {
      let list: any = {};
      cids.forEach((e) => {
        list.descricao = e.valor;
        list.codigo = e.codigoDeNegocio;
        this.CIDs.push(`${list.codigo} ${list.descricao}`);
        this.CIDs_all.push(list);
      });
    });
  }
  getExames() {
    this.serviceConsulta.getExames().then((exames) => {
      exames.forEach((e) => {
        this.EXAMES.push(e.valor);
        this.EXAMES_all.push(e.valor);
      });
    });
  }
  getReceituario() {
    this.serviceConsulta
      .getReceituario()
      .toPromise()
      .then((receituario) => {
        receituario.forEach((e) => {
          this.RECEITUARIO.push(e.tipoDeNotificacao);
          this.RECEITUARIO_all.push(e);
        });
      });
  }
  viewImprimir(type: string, doc: any) {
    this.typeDoc = type;
    this.itensDoc = doc;
    this.imprimir = !this.imprimir;
    window.print();
  }
  start_obsChat() {
    this.verificar_chat = setInterval(() => {
      this.obsChat();
    }, 1000);
    this.verificar_chat;
  }
  validTempAtestadoMedico(time) {
    let form = this.form_encerrarAt_AtestadoMedico;
    if (form.controls["periodo"].value == "Dia todo") {
      form.controls["fim"].setValue(this.hoje);
      form.controls["fim"].disable();
    } else {
      form.controls["fim"].setValue(this.hoje);
      form.controls["fim"].enable();
    }
  }
  validTempAcompanhente(time) {
    let form = this.form_encerrarAt_AtestadoAcompanhante;
    if (form.controls["periodo"].value == "Dia todo") {
      form.controls["inicio"].setValue(this.hoje);
      form.controls["fim"].setValue(this.hoje);

      form.controls["fim"].disable();
      form.controls["inicio"].disable();
    } else {
      form.controls["fim"].setValue(this.hoje_hora);
      form.controls["inicio"].setValue(this.hoje_hora);
      form.controls["fim"].enable();
      form.controls["inicio"].enable();
    }
  }
  addTime(time, acompanhante?) {
    let dateTime = this.form_encerrarAt_AtestadoMedico.controls["fim"].value;
    if (acompanhante) {
      dateTime =
        this.form_encerrarAt_AtestadoAcompanhante.controls["fim"].value;
    }
    if (dateTime.split(" ")[1]) {
      dateTime = dateTime.split(" ")[0];
    }
    dateTime += ` ${time}`;
    if (acompanhante) {
      this.form_encerrarAt_AtestadoAcompanhante.controls["fim"].setValue(
        dateTime
      );
      return;
    }
    this.form_encerrarAt_AtestadoMedico.controls["fim"].setValue(dateTime);
  }
  salvarSolicitacao(periodica) {
    if (this.datasDisponiveis.length < 1) {
      this.mensagem.creat("Não a Datas dísponiveis", { erro: true });
      return;
    }
  }
  async gerarDatas() {
    function getDaysArray(start, end) {
      for (
        var dates = [], dt = new Date(start);
        dt < end;
        dt.setDate(dt.getDate() + 1)
      ) {
        const semana = dt.getDay();
        if (semana < 5) {
          dates.push(new Date(dt));
        }
      }
      return dates;
    }
    ///Redefinir alteração na end
    const start = this.configAgenda.start;
    let end = this.configAgenda.end.substr(0, 8);
    const valid_start = parseInt(this.configAgenda.end.substr(8, 2)) - 1;
    if (valid_start.toString().length < 2) {
      end = `${end}0${valid_start}`;
    } else {
      end = `${end}${valid_start}`;
    }
    ///end

    console.log(valid_start);
    console.log("DATA  : ", start, end);
    const hoje = this.data_service.hoje();
    var daylist = this.agenda.getDatasDisponiveis(
      new Date(start),
      new Date(end),
      this.dateFeriados
    );
    const dates = [];

    await daylist.forEach((v) => {
      let date = v;
      let d = date.split("-").join("");
      let h = hoje.split("-").join("");
      if (parseInt(d) >= parseInt(h)) {
        dates.push({
          date: date,
          semana: this.data_service.getSemanas(new Date(date).getDay()),
        });
      }
    });
    this.datasDisponiveis = dates;
  }

  uploadFile(file, type?) {
    switch (type) {
      case "docClinic":
        this.file_docClinics = file;
        this.info_file.path = "path_documento_clinico";
        this.info_file.criadoPor = sessao.cpf;
        this.info_file.nrDocumento = this.cabecalho.nrDoc;
        this.info_file.tipoAnexo = "TIPANE13";
        this.openUpload_docClinic = true;
        return;
        break;
      default:
        break;
    }
    console.log("passou", file);
    this.info_file.path = "path_resultado_exame";
    this.info_file.criadoPor = sessao.cpf;
    this.info_file.nrDocumento = this.cabecalho.nrDoc;
    this.info_file.tipoAnexo = "TIPANE1";
    this.file = file;
    this.openUpload = true;
  }
  openUploadSuccess(ok) {
    if (ok) {
      this.mensagem.creat("Arquivo enviado com sucesso", { sucesso: true });
    } else {
      this.mensagem.creat("Falha ao enviar arquivo", { erro: true });
    }
    this.openUpload = false;
    this.openUpload_docClinic = false;

    this.getAnexos();
    this.viewAdd = false;
  }
  exiteUpload() {
    this.file = null;
    this.openUpload = false;
    this.fileUploading.nativeElement.value = "";
  }
  getClassificacaoDeReceituarios() {
    this.serviceConsulta
      .getClassificacaoDeReceituarios()
      .toPromise()
      .then((reg) => {
        this.listClassDeReceituarios = reg;
        this.dataClassReceituario = new MatTableDataSource(reg);
      });
  }
  viewAllClassReceituario(item: ClassificacaoDeReceituarios) {
    this.itemClassDeReceituarios = item;
  }
  async pausar(confirm?) {
    if (confirm) {
      this.viewConfirmPause = true;
    } else {
      this.viewConfirmPause = false;
      let getTicket = await this._ticket.getTicketConsultaID(
        this.infoSalaEspera.id
      );
      getTicket.status = "SDO0152";
      await this._ticket.atualizarTicketConsulta(getTicket);
      this.viewConfirmInterromper = false;
      this.mensagem.creat("Consulta pausada com sucesso", { sucesso: true });
      this.emitPause.emit(true);
    }
  }
  async interromper(confirm?) {
    console.log("pause", this.infoSalaEspera);
    if (confirm) {
      this.viewConfirmInterromper = true;
    } else {
      let getTicket = await this._ticket.getTicketConsultaID(
        this.infoSalaEspera.id
      );
      getTicket.status = "SDO0087";
      await this._ticket.atualizarTicketConsulta(getTicket);
      this.viewConfirmInterromper = false;
      this.mensagem.creat("Consulta interrompida com sucesso", {
        sucesso: true,
      });
      this.emitPause.emit(true);
    }
  }
}
