import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RegraAprovacaoDocumento } from '../ajustes/regra-aprovacao-documento/model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const user = new SessaoUser

@Injectable({
  providedIn: 'root'
})

export class RegraAprovacaoDocumentoService {
  url = new environment().api
  api = 'RegraAprovacaoDeDocumento/'

  constructor(private http: HttpClient, private data: BuscarDataService) { }

  listar() {
    return this.http.get<RegraAprovacaoDocumento[]>(this.url + this.api)
  }

  enviar(registro: RegraAprovacaoDocumento) {
    return this.http.post(this.url + this.api, registro)
  }

  alterar(registro: RegraAprovacaoDocumento) {
    return this.http.put(this.url + this.api + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
  }

  async desativar(registro: RegraAprovacaoDocumento, ativo) {
    if (ativo) {
      registro.status = 'SDA0001'
    } else {
      registro.status = "SDA0002"
    }

    let resposta
    // parametro de funcão callback = precisa de uma outra função para tratar resposta
    await this.alterar(registro)
      .toPromise()
      .then(
        res => {
          resposta = res
        }
      ) //sucesso
      .catch(error => { console.log('error', error) }) //erro

    return resposta
  }

}
