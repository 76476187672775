import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { formIndicacaoTratamento, formSolicitacaoExames, formReceituario, formProcedimentoAdicional, formCirurgias } from './model';
import { Builder } from 'protractor';
import { AtendimentoClinicoService } from 'src/app/service/atendimento-clinico.service';
import { async } from 'rxjs/internal/scheduler/async';
import { formURL } from '../model';
import * as jsPDF from 'jspdf';
import { FormCadastroEmpresa } from 'src/app/configuracao-inicial/cadastro-empresa/model';
import { DatePipe } from '@angular/common';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { SalaEsperaForm } from '../sala-espera/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { ConsoleReporter } from 'jasmine';


@Component({
  selector: 'app-conduta-medica',
  templateUrl: './conduta-medica.component.html',
  styleUrls: ['./conduta-medica.component.scss']
})
export class CondutaMedicaComponent implements OnInit {
  @Input('index') index
  @Input('id') id: formURL
  datePipe = new DatePipe('en-US')
  abrirHistoricoIT
  abrirHistoricoExame
  abrirHistoricoReceituario
  abrirHistoricoProA
  comentarioInfo: string
  CIDs = new FormControl();
  nomeComercial = new FormControl();
  principioAtivo = new FormControl();

  optionsCID: string[] = [
    "A02 - Outras Infecções Por Salmonella",
    "A02.0 - Enterite Por Salmonela",
    "A02.1 - Septicemia Por Salmonela",
    "A02.2 - Infecções Localizadas Por Salmonela",
    "A02.8 - Outras Infecções Especificadas Por Salmonela",
    "A02.9 - Infecção Não Especificada Por Salmonela",
    "A04 - Outras Infecções Intestinais Bacterianas",
    "A04.0 - Infecção Por Escherichia Coli Enteropatogênica",
    "A04.1 - Infecção Por Escherichia Coli Enterotoxigênica",
    "A04.2 - Infecção Por Escherichia Coli Enteroinvasiva",
    "A04.3 - Infecção Por Escherichia Coli Enterohemorrágica",
    "A04.4 - Outras Infecções Intestinais Por Escherichia Coli",
    "A04.5 - Enterite Por Campylobacter",
    "A04.6 - Enterite Devida a Yersinia Enterocolítica",
    "A04.7 - Enterocolite Devida a Clostridium Difficile",
    "A04.8 - Outras Infecções Bacterianas Intestinais Especificadas",
    "A04.9 - Infecção Intestinal Bacteriana Não Especificada"];
  optionsNomeComercial: string[] = [
    "Miodon 50 mg / ml",
    "Miodon 100mg",
    "Miodon 200mg",
    "Miodaron 200mg",
    "Miosan 5mg"
  ]
  optionsPrincipioAtivo: string[] = [
    "Cloridrato de ciclobenzaprina 5 mg",
    "Cloridrato de Amiodarona 50 mg / ml",
    "Cloridrato de Amiodarona 100 mg",
    "Cloridrato de Amiodarona 200 mg",
    "Cloridrato de Amiodarona 200 mg"
  ]
  filtroCIDs: Observable<string[]>;
  fitroNomeComercial: Observable<string[]>;
  filtroPrincipioAtivo: Observable<string[]>;
  dataHoje
  registroIndicacaoTratamento = new formIndicacaoTratamento
  registroSolicitacaoExames = new formSolicitacaoExames
  registroReceituario = new formReceituario
  registroProcedimentoAdicional = new formProcedimentoAdicional
  registroCirurgias = new formCirurgias

  //Historico
  historicoIT: formIndicacaoTratamento[]
  historicoExame: formSolicitacaoExames[]
  historicoReceituario: formReceituario[]
  historicoProA: formProcedimentoAdicional[]
  historicoCirurgias: formCirurgias[]

  //Views de Historico
  vhIT
  vhExame
  vhReceituario
  vhProA
  vhCirurgias

  formIndicacaoTratamento: FormGroup
  formSolicitacaoExames: FormGroup
  formReceituario: FormGroup
  formProcedimentoAdicional: FormGroup
  formCirurgias: FormGroup
  verificarIndex
  empresa: any;
  info: SalaEsperaForm;
  comentario: boolean;
  loading: boolean;
  constructor(
    private data: BuscarDataService,
    private form: FormBuilder,
    private service: AtendimentoClinicoService,
    private serviceEmpresa: ConfiguracaoInicialService,
    private http: HttpClient) {
    this.dataHoje = this.data.hoje()
    this.registroSolicitacaoExames.data = this.dataHoje
    this.registroReceituario.data = this.dataHoje
    this.verificarIndex = setInterval(() => this.sessao(), 1000)
  }

  async ngOnInit() {
    this.info = JSON.parse(localStorage.getItem('infoSalaEspera'))
    await this.createFormulario()
    await this.buscarHistorico()
    await this.dropDownList()
    await this.buscarFormLog()
    await this.buscarEmpresa()
  }

  async buscarHistorico() {
    await this.service.listarIndicacaoTratamento().toPromise()
      .then(
        (dados: formIndicacaoTratamento[]) => {
          let usuarioId = this.id.usuarioId
          let companyId = this.id.companyId
          let pacienteId = this.id.pacienteId
          let filtro = dados.filter(function (reg: formIndicacaoTratamento) {
            return reg.usuarioId == usuarioId && reg.companyId == companyId && reg.pacienteId == pacienteId
          })
          let ordenar = filtro.sort(function (a, b) {
            if (!a.data || !b.data) { return }
            a.data.replace('/', ''); b.data.replace('/', '')
            return parseInt(b.data) - parseInt(a.data)
          })
          this.historicoIT = ordenar
        }
      )
    await this.service.listarSolicitacaoExames().toPromise()
      .then(
        (dados: formSolicitacaoExames[]) => {
          let usuarioId = this.id.usuarioId
          let companyId = this.id.companyId
          let pacienteId = this.id.pacienteId
          let filtro = dados.filter(function (reg: formSolicitacaoExames) {
            return reg.usuarioId == usuarioId && reg.companyId == companyId && reg.pacienteId == pacienteId
          })
          let ordenar = filtro.sort(function (a, b) {
            if (!a.data || !b.data) { return }
            a.data.replace('/', ''); b.data.replace('/', '')
            return parseInt(b.data) - parseInt(a.data)
          })
          this.historicoExame = ordenar
        }
      )

    await this.service.listarReceituario().toPromise()
      .then(
        (dados: formReceituario[]) => {
          let usuarioId = this.id.usuarioId
          let companyId = this.id.companyId
          let pacienteId = this.id.pacienteId
          let filtro = dados.filter(function (reg: formReceituario) {
            return reg.usuarioId == usuarioId && reg.companyId == companyId && reg.pacienteId == pacienteId
          })
          let ordenar = filtro.sort(function (a, b) {
            if (!a.data || !b.data) { return }
            a.data.replace('/', ''); b.data.replace('/', '')
            return parseInt(b.data) - parseInt(a.data)
          })
          this.historicoReceituario = ordenar
        }
      )

    await this.service.listarProcedimentoAdicional().toPromise()
      .then(
        (dados: formProcedimentoAdicional[]) => {
          let usuarioId = this.id.usuarioId
          let companyId = this.id.companyId
          let pacienteId = this.id.pacienteId
          let filtro = dados.filter(function (reg: formProcedimentoAdicional) {
            return reg.usuarioId == usuarioId && reg.companyId == companyId && reg.pacienteId == pacienteId
          })
          let ordenar = filtro.sort(function (a, b) {
            if (!a.data || !b.data) { return }
            a.data.replace('/', ''); b.data.replace('/', '')
            return parseInt(b.data) - parseInt(a.data)
          })
          this.historicoProA = ordenar
        }
      )

    await this.service.listarCirurgias().toPromise()
      .then(
        (dados: formCirurgias[]) => {
          let usuarioId = this.id.usuarioId
          let companyId = this.id.companyId
          let pacienteId = this.id.pacienteId
          let filtro = dados.filter(function (reg: formCirurgias) {
            return reg.usuarioId == usuarioId && reg.companyId == companyId && reg.pacienteId == pacienteId
          })
          let ordenar = filtro.sort(function (a, b) {
            if (!a.data || !b.data) { return }
            a.data.replace('-', ''); b.data.replace('-', '')
            return parseInt(b.data) - parseInt(a.data)
          })
          this.historicoCirurgias = ordenar
        }
      )
  }
  buscarFormLog() {
    if (JSON.parse(localStorage.getItem('regIT'))) {
      this.registroIndicacaoTratamento = JSON.parse(localStorage.getItem('regIT'))
    }

    if (JSON.parse(localStorage.getItem('regSE'))) {
      this.registroSolicitacaoExames = JSON.parse(localStorage.getItem('regSE'))
    }
    if (JSON.parse(localStorage.getItem('regReceituario'))) {
      this.registroReceituario = JSON.parse(localStorage.getItem('regReceituario'))
    }
    if (JSON.parse(localStorage.getItem('regPA'))) {
      this.registroProcedimentoAdicional = JSON.parse(localStorage.getItem('regPA'))
    }
    if (JSON.parse(localStorage.getItem('regCirurgias'))) {
      this.registroCirurgias = JSON.parse(localStorage.getItem('regCirurgias'))
    }
  }
  async  cadastrar() {
    await this.formIndicacaoTratamento.removeControl('id')
    await localStorage.setItem('condutaTerapeutica', JSON.stringify(this.registroReceituario))
    await localStorage.setItem('exame', this.registroSolicitacaoExames.nome)
    // alert(JSON.stringify(this.registroReceituario))
    if (this.formIndicacaoTratamento.controls['data'].invalid) { return }
    await this.service.cadastrarIndicacaoTratamento(this.formIndicacaoTratamento.value).toPromise()
      .then(
        async () => {
          await localStorage.setItem('indexRef2', 'true');
          this.id.pacienteId = '00'
          console.log('Salvo com sucesso')
        }
      )
    await this.formSolicitacaoExames.removeControl('id')
    if (this.formSolicitacaoExames.controls['data'].invalid) { return }
    await this.service.cadastrarSolicitacaoExames(this.formSolicitacaoExames.value).toPromise()
      .then(
        async () => {
          await localStorage.setItem('indexRef', 'true');
          console.log('Salvo com sucesso')
        }
      )
    await this.formReceituario.removeControl('id')
    if (this.formReceituario.controls['data'].invalid) { return }
    await this.service.cadastrarReceituario(this.formReceituario.value).toPromise()
      .then(
        async () => {
          await localStorage.setItem('indexRef', 'true');
          console.log('Salvo com sucesso')
        }
      )
    await this.formProcedimentoAdicional.removeControl('id')
    if (this.formProcedimentoAdicional.controls['data'].invalid) { return }
    await this.service.cadastrarProcedimentoAdicional(this.formProcedimentoAdicional.value).toPromise()
      .then(
        async () => {
          await localStorage.setItem('indexRef', 'true');
          console.log('Salvo com sucesso')
        }
      )
    await this.formCirurgias.removeControl('id')
    if (this.formCirurgias.controls['data'].invalid) { return }
    await this.service.cadastrarCirurgias(this.formCirurgias.value).toPromise()
      .then(
        async () => {
          await localStorage.setItem('indexRefCm', 'true');
          console.log('Salvo com sucesso')
        }
      )
  }
  sessao() {
    let indexRef = localStorage.getItem('indexRefCm')
    if (this.index == 2) {
      if (indexRef != "true" || this.id.pacienteId == '99') {
        clearInterval(this.verificarIndex)
        this.cadastrar()
        console.log('Cadastrou 2 !' + 'indexRef: ' + indexRef + ' index : ' + this.index)
      }
      return
    }
  }
  excluir(id) {
    this.service.excluirIndicacaoTratamento(id).subscribe(
      () => {
        console.log('excluido :')
        this.service.listarIndicacaoTratamento().toPromise()
          .then(
            (dados: any) => {
              this.historicoIT = dados
            }
          )
      },
      () => console.log('exlcuido-erro')
    )
  }
  async salvar(ref) {
    switch (ref) {
      case 'IT':
        await localStorage.setItem('regIT', JSON.stringify(this.registroIndicacaoTratamento))
        console.log(localStorage.getItem('regIT'))
        break

      case 'SE':
        await localStorage.setItem('regSE', JSON.stringify(this.registroSolicitacaoExames))
        console.log(localStorage.getItem('regSE'))
        break

      case 'Receituario':
        await localStorage.setItem('regReceituario', JSON.stringify(this.registroReceituario))
        console.log(localStorage.getItem('regReceituario'))
        break

      case 'PA':
        await localStorage.setItem('regPA', JSON.stringify(this.registroProcedimentoAdicional))
        console.log(localStorage.getItem('regPA'))
        break

      case 'cirurgias':
        await localStorage.setItem('regCirurgias', JSON.stringify(this.registroCirurgias))
        console.log(localStorage.getItem('regCirurgias'))
        break
    }
  }
  createFormulario() {
    this.formIndicacaoTratamento = this.form.group({
      id: new FormControl('', Validators.required),
      usuarioId: new FormControl(this.id.usuarioId),
      companyId: new FormControl(this.id.companyId),
      pacienteId: new FormControl(this.id.pacienteId),
      crmAtuante: new FormControl('', Validators.required),
      data: new FormControl(this.dataHoje),
      tratamento: new FormControl('', Validators.required),
      descricao: new FormControl('', Validators.required),
      frequencia: new FormControl('', Validators.required),
      duracao: new FormControl('', Validators.required)
    }),
      this.formSolicitacaoExames = this.form.group({
        id: new FormControl('', Validators.required),
        usuarioId: new FormControl(this.id.usuarioId),
        companyId: new FormControl(this.id.companyId),
        pacienteId: new FormControl(this.id.pacienteId),
        data: new FormControl(this.dataHoje, Validators.required),
        nome: new FormControl('', Validators.required),
        descricaoExame: new FormControl('', Validators.required),
        CIDs: new FormControl('', Validators.required)
      }),

      this.formReceituario = this.form.group({
        id: new FormControl('', Validators.required),
        usuarioId: new FormControl(this.id.usuarioId),
        companyId: new FormControl(this.id.companyId),
        pacienteId: new FormControl(this.id.pacienteId),
        data: new FormControl(this.dataHoje, Validators.required),
        nomeComercial: new FormControl('', Validators.required),
        dosagem: new FormControl('', Validators.required),
        tipoDosagem: new FormControl('', Validators.required),
        via: new FormControl('', Validators.required),
        formaAdministracao: new FormControl('', Validators.required),
        frequencia: new FormControl('', Validators.required),
        tipoFrequencia: new FormControl('', Validators.required),
        periodo: new FormControl('', Validators.required),
        tipoPeriodo: new FormControl('', Validators.required),
        principioAtivo: new FormControl('', Validators.required),
        numRecibo: new FormControl('', Validators.required),
        uf: new FormControl('', Validators.required),
        reTipo: new FormControl('', Validators.required),
        observacao: new FormControl('', Validators.required),
        usoContinuo: new FormControl('', Validators.required)
      }),

      this.formProcedimentoAdicional = this.form.group({
        id: new FormControl('', Validators.required),
        data: new FormControl(this.dataHoje, Validators.required),
        usuarioId: new FormControl(this.id.usuarioId),
        companyId: new FormControl(this.id.companyId),
        pacienteId: new FormControl(this.id.pacienteId),
        procedimentoAdicional: new FormControl('', Validators.required),
        cobrar: new FormControl('', Validators.required),
        quantidade: new FormControl('', Validators.required)
      }),

      this.formCirurgias = this.form.group({
        id: new FormControl('', Validators.required),
        usuarioId: new FormControl(this.id.usuarioId),
        companyId: new FormControl(this.id.companyId),
        pacienteId: new FormControl(this.id.pacienteId),
        data: new FormControl(this.dataHoje, Validators.required),
        tipoCirurgia: new FormControl('', Validators.required),
        motivo: new FormControl('', Validators.required),
        resultado: new FormControl('', Validators.required),
      })
  }
  async  downloadPDF(reg: formReceituario) {
    // var image = new Image
    // var img = image.src = "../../../../assets/img/logoDoctorJP.jpg"
    // var teste = 'data:image/jpeg;base64,' + btoa(img);
    var doc = new jsPDF();
    // await doc.addHTML(document.getElementById('content'), function(){
    //   console.log('ok')
    //   doc.output('dataurlnewwindow');
    // })
    doc.setFontSize(12);
    doc.text(5, 10, `${this.empresa.name ? this.empresa.name : '-'}`);
    doc.setFontSize(9);
    doc.text(5, 15, `${this.empresa.consultorio} - CRM ${this.empresa.crm ? this.empresa.crm : '___________________'} - CPF ${this.empresa.cpf ? this.empresa.cpf : '_______________'}`);
    doc.line(0, 25, 1000, 25);
    doc.setFontSize(22);
    doc.text(`RECEITA`, 80, 35);
    doc.setFontSize(13);
    doc.text(5, 45, `Paciente: ${this.info.paciente ? this.info.paciente : '_______________'} `);

    doc.setFontSize(14);
    if (reg.nomeComercial) doc.text(`Nome Comercial: ${reg.nomeComercial}`, 20, 60);
    if (reg.dosagem) doc.text(`Dosagem: 0 comprimido: ${reg.dosagem}`, 20, 70);
    if (reg.via) doc.text(`Via: ${reg.via}`, 20, 80);
    if (reg.formaAdministracao) doc.text(`Forma de Administração: ${reg.formaAdministracao}`, 20, 90);
    if (reg.frequencia) doc.text(`Frequência: ${reg.frequencia}`, 20, 100);
    if (reg.periodo) doc.text(`Período: ${reg.periodo}`, 20, 110);
    if (reg.principioAtivo) doc.text(`Princípio Ativo: ${reg.principioAtivo}`, 20, 120);
    if (reg.observacao) doc.text(`Observação: ${reg.observacao}`, 20, 130);
    if (reg.usoContinuo) doc.text(`Uso Continuo:${reg.usoContinuo ? 'SIM ' : 'Não'}`, 20, 140);
    doc.setFontSize(15);
    doc.text("_____________________________", 100, 160);
    doc.setFontSize(15);
    doc.text(`CRM : ${this.empresa.crm ? this.empresa.crm : ''}`, 100, 170);
    doc.setFontSize(15);
    doc.text(`São Paulo ${this.datePipe.transform(this.dataHoje, 'dd/MM/yyyy')}`, 10, 180);
    doc.setFontSize(11);
    if (reg.id) doc.text(`Copia - Data Original ${this.datePipe.transform(reg.data, 'dd/MM/yyyy')} `, 10, 280);
    doc.output('dataurlnewwindow');

    doc.autoPrint();

  }
  imprimir(dados) {
    this.downloadPDF(dados)
  }
  comentar() {
    let reg = {
      usuarioId: this.id.usuarioId,
      companyId: this.id.companyId,
      pacienteId: this.id.pacienteId,
      comentario: this.comentarioInfo,
      data: this.datePipe.transform(this.dataHoje, 'dd/MM/yyyy')
    }
    const api = new environment().api
    this.loading = true
    this.http.post(api + 'Reeimpressao/', reg).toPromise().then(
      () => {
        this.downloadPDF(this.vhReceituario)
        this.comentario = false
        this.loading = false
      }
    )


  }
  buscarEmpresa() {
    this.serviceEmpresa.listarEmpresa().toPromise()
      .then(
        (dados: Array<FormCadastroEmpresa>) => {
          let companyId = this.info.companyId
          let filtro = dados.filter((r) => r.id.toLocaleUpperCase() == companyId.toLocaleUpperCase())
          this.empresa = filtro[0]
        }
      )
  }
  test() {
    console.log("indicação tratamento:")
    console.log(this.formIndicacaoTratamento.value)
    console.log("solicitação de exames:")
    console.log(this.formSolicitacaoExames.value)
    console.log("receituario:")
    console.log(this.formReceituario.value)
    console.log("procedimento Adicional")
    console.log(this.formProcedimentoAdicional.value)
    console.log("cirurgias:")
    console.log(this.formCirurgias.value)
  }

  dropDownList() {
    this.filtroPrincipioAtivo = this.nomeComercial.valueChanges
      .pipe(
        startWith(''),
        map(value => this.optionsPrincipioAtivo.filter(option => option.toLowerCase().includes(value.toLowerCase())))
      );
    this.fitroNomeComercial = this.nomeComercial.valueChanges
      .pipe(
        startWith(''),
        map(value => this.optionsNomeComercial.filter(option => option.toLowerCase().includes(value.toLowerCase())))
      );
    this.filtroCIDs = this.CIDs.valueChanges
      .pipe(
        startWith(''),
        map(value => this.optionsCID.filter(option => option.toLowerCase().includes(value.toLowerCase())))
      );
  }

}



