export class FormProntuarioVidaPaciente {
  id: string
  criadoEm: string  = '' 
  criadoPor: string  = ''
  cpf: string  = ''
  altura: string  = '0'
  peso: string  = '0'
  imc: string  = '0'
  glicemia: string  = '0'
  pressaoDiastolica: string  = ''
  pressaoSistolica: string  = ''
  sedentario: boolean = false
  quantoExercicio: string  = ''
  fuma: boolean = false
  quantoFuma: string  = ''
  bebe: boolean = false
  quantoBebe: string  = ''
  tipoSanguineo: string  = ''
  cintura: string  = ''
  doencaCronica: boolean = false
  quaisDoencasCronicas: string  = ''
  possuiFraturas: boolean = false
  quaisFraturas: string  = ''
  antiConcepcional: boolean = false
  quantoTempoAnticoncepcional: string  = ''
  status: string  = ''
  ativo: boolean = true
  estadoPaciente: string  = ''
  situacaoPaciente: string  = ''
  pressaoArterial: string  = ''
  temperatura:string  = ''
  saturacaoOxigenio: string  = ''
  data?: string  = ''
  date: string | number | Date
}