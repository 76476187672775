import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TabelaMenu } from '../model';
import { CadastrarService } from 'src/app/service/tabela-menu.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-galeria-icone',
  templateUrl: './galeria-icone.component.html',
  styleUrls: ['./galeria-icone.component.scss']
})

export class GaleriaIconeComponent implements OnInit {
  @Input() registro : TabelaMenu
  @Output('confirm') confirm  = new EventEmitter()

  icon = [
{ title: 'calendar-ativo', url: './assets/icon/', img: 'calendar-ativo.svg' },
{ title: 'calendarNew', url: './assets/icon/', img: 'calendarNew.svg' },
{ title: 'care', url: './assets/icon/', img: 'care.svg' },
{ title: 'care-ativo', url: './assets/icon/', img: 'care-ativo.svg' },
{ title: 'dependentes', url: './assets/icon/', img: 'dependentes.svg' },
{ title: 'dependentes-ativo', url: './assets/icon/', img: 'dependentes-ativo.svg' },
{ title: 'hide', url: './assets/icon/', img: 'hide.svg' },
{ title: 'hide-ativo', url: './assets/icon/', img: 'hide-ativo.svg' },
{ title: 'historico_de_consultas', url: './assets/icon/', img: 'historico_de_consultas.svg' },
{ title: 'indicadores', url: './assets/icon/', img: 'indicadores.svg' },
{ title: 'inscricao', url: './assets/icon/', img: 'inscricao.svg' },
{ title: 'inscricao-ativo', url: './assets/icon/', img: 'inscricao-ativo.svg' },
{ title: 'invoice', url: './assets/icon/', img: 'invoice.svg' },
{ title: 'Logo', url: './assets/icon/', img: 'Logo.svg' },
{ title: 'medicacoes', url: './assets/icon/', img: 'medicacoes.svg' },
{ title: 'prontuarios', url: './assets/icon/', img: 'prontuarios.svg' },
{ title: 'ticket-ativo', url: './assets/icon/', img: 'ticket-ativo.svg' },
{ title: 'ticketNew', url: './assets/icon/', img: 'ticketNew.svg' },
{ title: 'flashNew', url: './assets/icon/', img: 'flashNew.svg' },
{ title: 'openbook', url: './assets/icon/', img: 'openbook.svg' },
{ title: 'question', url: './assets/icon/', img: 'question.svg' },
{ title: 'notification', url: './assets/icon/', img: 'notification.svg' },
{ title: 'home-run', url: './assets/icon/', img: 'home-run.svg' },
{ title: 'search', url: './assets/icon/', img: 'search.svg' },
{ title: 'heart', url: './assets/icon/', img: 'heart.svg' },
{ title: 'artificial-intelligence', url: './assets/icon/', img: 'artificial-intelligence.svg' },
{ title: 'listaMedicosFavoritos-ativo', url: './assets/icon/', img: 'listaMedicosFavoritos-ativo.png' },
{ title: 'listaMedicosFavoritosNew', url: './assets/icon/', img: 'listaMedicosFavoritosNew.png' },
{ title: 'Logo-medico', url: './assets/icon/', img: 'Logo-medico.png' },
{ title: 'medicos', url: './assets/icon/', img: 'medicos.png' },
{ title: 'flashblue', url: './assets/icon/', img: 'flashblue.png' },
{ title: 'medicos-ativo', url: './assets/icon/', img: 'medicos-ativo.png' },

  ]
  view: string = "./assets/icon/Logo.svg";
  selecionado :string 
  title: string;

  constructor(private service : CadastrarService , private mensagem : ToastrService ) {
  }

  ngOnInit() {
    this.selecionado = this.registro.imagem
    console.log(this.registro.imagem)
  }
  imgSelecionada(img:string,title:string){
    this.view = img
    this.title = title
  }
  cadastrar(img){

    this.registro.imagem = this.selecionado
    this.service.editar(this.registro,this.registro.menuIdentity).toPromise()
    .then(
      (reg)=> {
        console.log(JSON.stringify(reg))
        this.mensagem.success('Cadastrado com sucesso')
        this.confirm.emit(false)
      }
    )
    .catch(
      ()=>this.mensagem.error('Algo deu errado')
    )
    console.log(JSON.stringify(this.registro))
  }

}
