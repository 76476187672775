import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SessaoUser } from "../../user.reg";
import { FiltrarDadosService } from "../filtrar-dados.service";
import {
  AJ_LISTPRECO_CAB,
  AJ_LISTPRECO_ITM_ACRESCIMO,
  AJ_LISTPRECO_ITM_DESCONTO,
  AJ_LISTPRECO_ITM_IMPOSTO,
  AJ_LISTPRECO_ITM_PRAZO,
  AJ_LISTPRECO_ITM_PRECO,
} from "./model";
import { TokenDeAcessoService } from "../token-de-acesso.service";
import { filterEventStoreDefs } from "@fullcalendar/core";

const sessao = new SessaoUser();
@Injectable({
  providedIn: "root",
})
export class ListaDePrecoService {
  url = new environment().api;
  apiListaDePreco = "AJ_LISTPRECO_CAB/";
  api_preco = "AJ_LISTPRECO_ITM_PRECO/";
  api_imposto = "AJ_LISTPRECO_ITM_IMPOSTO/";
  api_prazo = "AJ_LISTPRECO_ITM_PRAZO/";
  api_desconto = "AJ_LISTPRECO_ITM_DESCONTO/";
  api_acrescimo = "AJ_LISTPRECO_ITM_ACRESCIMO/";

  constructor(
    private http: HttpClient,
    private _filter: FiltrarDadosService,
    private tokken: TokenDeAcessoService
  ) {}
  /////**************CABECARIO*********************/
  async getCAB() {
    let result: AJ_LISTPRECO_CAB[] = [];
    await this.http
      .get<Array<AJ_LISTPRECO_CAB>>(this.url + this.apiListaDePreco)
      .toPromise()
      .then((res) => {
        let filter = res.filter((value) => value.status == "SCA0001");

        if (filter.length > 0) {
          result = filter;
          let listEmpresas = JSON.parse(sessao.listEmpresas);
          result = this._filter.filterTodos(result, listEmpresas);
        }
      });
    return result;
  }
  async getCABID(id) {
    let result = new AJ_LISTPRECO_CAB();
    await this.http
      .get<AJ_LISTPRECO_CAB>(this.url + this.apiListaDePreco + id)
      .toPromise()
      .then((res) => {
        result = res;
      });
    return result;
  }
  async postCAB(dado: AJ_LISTPRECO_CAB) {
    let result;
    dado.criadoEm = new Date().toISOString();
    dado.criadoPor = sessao.cpf;
    dado.status = "SCA0001";
    delete dado.id;
    console.log(this.url + this.apiListaDePreco, dado);
    await this.http
      .post(this.url + this.apiListaDePreco, dado)
      .toPromise()
      .then((res) => {
        result = res;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  async alterCAB(dado: AJ_LISTPRECO_CAB) {
    let result = false;
    console.log(
      "alterCAB",
      this.url +
        this.apiListaDePreco +
        `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}/`,
      JSON.stringify(dado)
    );
    await this.http
      .put(
        this.url +
          this.apiListaDePreco +
          `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}/`,
        dado
      )
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  /////************** END CABECARIO*********************/

  /////************** PRECO *********************/
  async getPreco(codigoEmpresa, codigoListaDePreco) {
    let result: AJ_LISTPRECO_ITM_PRECO[] = [];
    await this.http
      .get<Array<AJ_LISTPRECO_ITM_PRECO>>(this.url + this.api_preco)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) =>
            value.codigoEmpresa == codigoEmpresa &&
            value.codigoListaDePreco == codigoListaDePreco &&
            value.status == "SCA0001"
        );
        if (filter.length > 0) result = filter;
      });
    return result;
  }
  async postPreco(dado: AJ_LISTPRECO_ITM_PRECO) {
    let result;
    dado.criadoPor = sessao.cpf;
    dado.criadoEm = new Date().toISOString();
    dado.status = "SCA0001";
    delete dado.id;
    await this.http
      .post(this.url + this.api_preco, dado)
      .toPromise()
      .then((res) => {
        result = res;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  async alterPreco(dado: AJ_LISTPRECO_ITM_PRECO) {
    let result = false;
    await this.http
      .put(
        this.url +
          this.api_preco +
          `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
        dado
      )
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  /////************** END PRECO *********************/

  /////************** IMPOSTO *********************/
  async getImposto(codigoEmpresa, codigoListaDePreco) {
    let result: AJ_LISTPRECO_ITM_IMPOSTO[] = [];
    await this.http
      .get<Array<AJ_LISTPRECO_ITM_IMPOSTO>>(this.url + this.api_imposto)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) =>
            value.codigoEmpresa == codigoEmpresa &&
            value.codigoListaDePreco == codigoListaDePreco &&
            value.status == "SCA0001"
        );
        if (filter.length > 0) result = filter;
      });

    return result;
  }
  async postImposto(dado: AJ_LISTPRECO_ITM_IMPOSTO) {
    let result;
    dado.criadoPor = sessao.cpf;
    dado.criadoEm = new Date().toISOString();
    dado.status = "SCA0001";
    delete dado.id;

    await this.http
      .post(this.url + this.api_imposto, dado)
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  async alterImposto(dado: AJ_LISTPRECO_ITM_IMPOSTO) {
    let result = false;
    await this.http
      .put(
        this.url +
          this.api_imposto +
          `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
        dado
      )
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  /////************** END IMPOSTO*********************/

  /////************** PRAZO *********************/
  async getPrazo(codigoEmpresa, codigoListaDePreco) {
    let result: AJ_LISTPRECO_ITM_PRAZO[] = [];
    await this.http
      .get<Array<AJ_LISTPRECO_ITM_PRAZO>>(this.url + this.api_prazo)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) =>
            value.codigoEmpresa == codigoEmpresa &&
            value.codigoListaDePreco == codigoListaDePreco &&
            value.status == "SCA0001"
        );
        if (filter.length > 0) result = filter;
      });

    return result;
  }
  async postPrazo(dado: AJ_LISTPRECO_ITM_PRAZO) {
    let result;
    dado.criadoPor = sessao.cpf;
    dado.criadoEm = new Date().toISOString();
    dado.status = "SCA0001";
    delete dado.id;
    this._filter.removeNull(dado);
    console.log("POST PRAZO", this.url + this.api_prazo, JSON.stringify(dado));
    await this.http
      .post(this.url + this.api_prazo, dado)
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO postPrazo", error);
      });
    return result;
  }
  async alterPrazo(dado: AJ_LISTPRECO_ITM_PRAZO) {
    let result = false;
    console.log("ALTER PRAZO", this.url + this.api_prazo, JSON.stringify(dado));
    await this.http
      .put(
        this.url +
          this.api_prazo +
          `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
        dado
      )
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST PRAZO", error);
      });
    return result;
  }
  /////************** END PRAZO*********************/

  /////************** DESCONTO *********************/
  async getDesconto(codigoEmpresa, codigoListaDePreco) {
    let result: AJ_LISTPRECO_ITM_DESCONTO[] = [];
    await this.http
      .get<Array<AJ_LISTPRECO_ITM_DESCONTO>>(this.url + this.api_desconto)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) =>
            value.codigoEmpresa == codigoEmpresa &&
            value.codigoListaDePreco == codigoListaDePreco &&
            value.status == "SCA0001"
        );
        if (filter.length > 0) result = filter;
      });

    return result;
  }
  async postDesconto(dado: AJ_LISTPRECO_ITM_DESCONTO) {
    let result;
    dado.criadoPor = sessao.cpf;
    dado.criadoEm = new Date().toISOString();
    dado.status = "SCA0001";
    delete dado.id;
    await this.http
      .post(this.url + this.api_desconto, dado)
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  async alterDesconto(dado: AJ_LISTPRECO_ITM_DESCONTO) {
    let result = false;
    await this.http
      .put(
        this.url +
          this.api_desconto +
          `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
        dado
      )
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  /////************** END DESCONTO*********************/

  /////************** ACRESCIMO *********************/
  async getAcrescimo(codigoEmpresa, codigoListaDePreco) {
    let result: AJ_LISTPRECO_ITM_ACRESCIMO[] = [];
    await this.http
      .get<Array<AJ_LISTPRECO_ITM_ACRESCIMO>>(this.url + this.api_acrescimo)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) =>
            value.codigoEmpresa == codigoEmpresa &&
            value.codigoListaDePreco == codigoListaDePreco &&
            value.status == "SCA0001"
        );
        if (filter.length > 0) result = filter;
      });

    return result;
  }
  async postAcrescimo(dado: AJ_LISTPRECO_ITM_ACRESCIMO) {
    let result;
    dado.criadoPor = sessao.cpf;
    dado.criadoEm = new Date().toISOString();
    dado.status = "SCA0001";
    delete dado.id;
    await this.http
      .post(this.url + this.api_acrescimo, dado)
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  async alterAcrescimo(dado: AJ_LISTPRECO_ITM_ACRESCIMO) {
    let result = false;
    await this.http
      .put(
        this.url +
          this.api_acrescimo +
          `${dado.id}/${sessao.sessao_id}/${new Date().toISOString()}`,
        dado
      )
      .toPromise()
      .then((res) => {
        result = true;
      })
      .catch((error) => {
        console.log("ERRO LIST", error);
      });
    return result;
  }
  /////************** END ACRESCIMO*********************/
}
