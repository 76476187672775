import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ConciliarOperadoraPlanoSaude } from './model';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';

import { DatePipe } from '@angular/common';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';

@Component({
  selector: 'app-conciliar-operadora-plano-saude',
  templateUrl: './conciliar-operadora-plano-saude.component.html',
  styleUrls: ['./conciliar-operadora-plano-saude.component.scss']
})
export class ConciliarOperadoraPlanoSaudeComponent implements OnInit {
  displayedColumns = ['select', 'empresa', 'lote', 'operadoraPlanoDeSaude', 'periodoDeConciliação', 'status']
  formData: FormGroup;
  listLote: ConciliarOperadoraPlanoSaude[]
  data: ConciliarOperadoraPlanoSaude[]

  viewAbrirLoteConciliacao: boolean = false
  viewIniciarConciliacao: boolean = false
  viewRetornoOperadora: boolean
  viewExtratoFaturamento: boolean

  listEmpresa = []
  alteracao: boolean = false;
  operadoras: { valor: string; cod: string; }[];
  pipe: any;
  selection: SelectionModel<any>;
  selected: any
  selectCheck: any

  ///

  viewCancelarGuia: boolean
  viewCancelarOperadora: boolean
  viewGlosadaOperadora: boolean
  viewRecursoGlosa: boolean
  viewPopUp: boolean
  viewPopUpJustificativa: boolean
  viewPopUpRecursoGlosa: boolean
  viewPopUpCorrecaoGlosa: boolean
  viewPopUpRespostaRecurso: boolean


  constructor(
    private form: FormBuilder,
    private _conteudo: ConteudoDeDadosService,
    private datePipe: DatePipe,
    private token: TokenDeAcessoService
  ) { }

  ngOnInit() {
    this.createdForm()
    this.getConteudoDados()
    this.getEmpresa()
    this.data = [
      { empresa: '84 - Aurora TM', lote: 1, operadoraPlanoDeSaude: 'amil', periodoDeConciliacao: 'de 20/07/2020 a 20/08/2020', status: 'aberto' },
      { empresa: '84 - Aurora TM', lote: 2, operadoraPlanoDeSaude: 'sulamerica', periodoDeConciliacao: ' de 20/07/2020 a 20/08/2020', status: 'fechado' },
      { empresa: '84 - Aurora TM' ,lote: 3, operadoraPlanoDeSaude: 'intermedica', periodoDeConciliacao: 'de 20/07/2020 a 20/08/2020', status: 'cancelado' },
      { empresa: '84 - Aurora TM', lote: 4, operadoraPlanoDeSaude: 'amil', periodoDeConciliacao: 'de 20/07/2020 a 20/08/2020', status: 'aberto' }
    ]
    this.listLote = this.data

    this.listEmpresa = ['amil', 'sulamerica', 'intermedica']

    const teste = this.formData.get('dataInicial').value
    console.log(teste)
    if (this) {
      const teste2 = this.datePipe.transform(teste, 'yyyy-MM-dd');
      console.log(teste2)

    }

    const initialSelection = [];
    const allowMultiSelect = false;
    this.selection = new SelectionModel<ConciliarOperadoraPlanoSaude>(allowMultiSelect, initialSelection);

  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getConteudoDados() {
    // let tipoTISS = await this._conteudo.get('TPTISS')
    // let classGuiaTISS = await this._conteudo.get('CLTISS')
    // let autTIIS = await this._conteudo.get('AUTISS')
    let operadoras = await this._conteudo.get('OPESAU')

    // this.tipoTISS = tipoTISS.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    // this.classGuiaTISS = classGuiaTISS.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    // this.autTIIS = autTIIS.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    this.operadoras = operadoras.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    console.log(this.operadoras)

  }

  createdForm() {
    this.formData = this.form.group({
      empresa: '',
      dataInicial: '',
      dataFinal: '',
      status: '',
    })
  }


  // selection = new SelectionModel(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listLote.length;
    return numSelected === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.listLote.forEach(row => {
        this.selection.select(row)
        const teste = this.selection.toggle(row)
        console.log(teste)

      });
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  itemSelecionado(row) {
    this.selected = row
  }


  abrirLoteConcilicao() {
    this.formData.reset()
    this.alteracao = false
    this.viewAbrirLoteConciliacao = true
  }


  iniciarConciliacao() {
    this.formData.reset()
    this.alteracao = false
    this.viewIniciarConciliacao = true
  }

  iniciarRetornoOperadora() {
    this.formData.reset()
    this.alteracao = false
    this.viewRetornoOperadora = true
  }

  iniciarExtratoFaturamento() {
    this.formData.reset()
    this.alteracao = false
    this.viewExtratoFaturamento = true
  }

  iniciarRecursoGlosa(){
    this.formData.reset()
    this.alteracao = false
    this.viewRecursoGlosa = true
  }

  // exibirLoteConcilicao() {
  //   this.alteracao = true
  //   this.viewAbrirLoteConciliacao = true
  //   this.formData.setValue(this.selected)
  // }

  exibirPopUp() {
    this.viewPopUp = true
  }

  exibirJustificativa(){
    this.viewPopUpJustificativa = true
  }

  exibirRecursoGlosa(){
    this.viewPopUpRecursoGlosa = true
  }

  exibirCorrecaoGlosa(){
    this.viewPopUpCorrecaoGlosa = true
  }

  exibirRespostaRecurso(){
    this.viewPopUpRespostaRecurso = true
  }
}






//   formulario: FormGroup
//   listRegistro: ConciliarOperadoraPlanoSaude[]
//   listEmpresa = []


//   constructor(
//     private form: FormBuilder,
//     private token: TokenDeAcessoService
//   ) { }

//   ngOnInit() {
//     this.createForm()
//     this.getEmpresa()

//   }

//   createForm() {
//     this.formulario = this.form.group({
//       empresa: '',
//       dataInicial: '',
//       dataFinal: '',
//       status: ''
//     })
//   }



// }

