import { Component, OnInit, TemplateRef, ViewChild, Pipe } from '@angular/core';
import { SistemaClassificacaoDadosService } from '../../service/sistema-classificacao-dados.service';
import { ToastrService } from 'ngx-toastr';
import { camposStatus } from '../status-do-sistema/Status.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MensagemService } from 'src/app/service/mensagem.service';
import { MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { SessaoUser } from 'src/app/user.reg';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { MatPaginator } from '@angular/material/paginator';
import { ClassificacaoDeDadosService } from 'src/app/service/classificacao-de-dados/classificacao-de-dados.service';

const user = new SessaoUser

@Component({
  selector: 'app-status',
  templateUrl: './status-do-sistema.component.html',
  styleUrls: ['./status-do-sistema.component.scss']
})
export class StatusDoSistemaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['detalhe', 'codigoStatus', 'tipo', 'descricaoDoStatus', 'faseStatus']

  dataSource

  formulario: FormGroup

  alteracao: boolean;

  viewCadastro: boolean;

  botaoModificar: boolean

  faseStatus

  status: any

  registrosStatus: any

  codigoStatus = 'O codigo é gerado automaticamente, selecione um tipo'
  codigoStatus_Backup = 'O codigo é gerado automaticamente, selecione um tipo'

  constructor(
    private SistemaClassificacaoService: SistemaClassificacaoDadosService,
    private classificacaoService: ClassificacaoDeDadosService,
    private _data: BuscarDataService,
    private mensagem: MensagemService,
    private form: FormBuilder
  ) { }

  ngOnInit() {
    this.GetRegistros()
    this.CreatForm()
    this.GetFaseStatus()
    
    // setInterval(() => {
    // this.BuscarStatus();
    // }, 2000)
  }

  GetRegistros() {
    this.SistemaClassificacaoService.getStatus().toPromise().then(
      (registros: any) => {
       
        registros.forEach(item => {
          if(item.tipo == "TIPOSTATUS3")
          {
            item.tipo = "Status de dados"
          }
          if(item.tipo == "TIPOSTATUS1")
          {
            item.tipo = "Status de Documento"
          }
          if(item.tipo == "TIPOSTATUS4")
          {
            item.tipo = "Status de Classificação de Dados"
          }
          if(item.tipo == "TIPOSTATUS2")
          {
            item.tipo = "Status de Cadastro"
          }
        });

        this.dataSource = new MatTableDataSource(registros)
        // this.dataSource.paginator = this.paginator
         this.dataSource.sort = this.sort

        let dados = registros
        let letra = registros.map(value => value.descricaoDoStatus.slice(0, 1).toUpperCase()).filter((value, i, index) => { return i == index.indexOf(value) }).sort()
        let result = []

        letra.forEach(value => {
          let ref = { letra: value, registro: [] }
          result.push(ref)
        });

        result.forEach(value => {
          dados.filter(v => {
            let L = v.descricaoDoStatus.slice(0,1).toUpperCase()
            if(L == value.letra) {
              value.registro.push(v)
            }
          })
        })
        this.status = result
        console.log("RESULT STATUS", this.status)
      }
    ).catch(err => console.log(err));
  }

  GetFaseStatus(){
     
    this.classificacaoService.GetFiltrarCodigoCaracteristica('FASESTATUS').toPromise().then(
      (registros: any) => {   
        let dados = registros
        let letra = registros.map(value => value.valor.slice(0, 1).toUpperCase()).filter((value, i, index) => { return i == index.indexOf(value) }).sort()
        let result = []

        letra.forEach(value => {
          let ref = { letra: value, registro: [] }
          result.push(ref)
        });

        result.forEach(value => {
          dados.filter(v => {
            let L = v.valor.slice(0,1).toUpperCase()
            if(L == value.letra) {
              value.registro.push(v)
            }
          })
        })
        this.faseStatus = result
        console.log("RESULT FASE", this.faseStatus)

      }).catch(err => console.log(err))        
  }

  CreatForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: '',
      codigoStatus: new FormControl({ value: '', disabled: true }),
      descricaoDoStatus: '',
      tipo: new FormControl('', Validators.required),
      proximoStatus: '',
      faseStatus: '',  
      alteracaoObjeto: '',
      eouProximo: '',
      controleAlteracaoStatus: true,
      criadoEm: '',
      statusRegistro: 'SDA0001'
    })
  }

  Inserir() {
    let registro = this.formulario.getRawValue()
    delete registro.id
    registro.criadoPor = user.cpf
    if(registro.codigoStatus == 'O codigo é gerado automaticamente, selecione um tipo')
    {
      this.mensagem.creat('Selecione um tipo', { erro: true })
      return
    }
    // registro.codigoStatus = this.codigoStatus
    console.log("Registro:", JSON.stringify(registro))

    this.SistemaClassificacaoService.postStatus(registro).subscribe(
      (response: any) => {
        console.log('RESPONSE: ', response)
        let message = response[0].mensagem + ' Status: ' + response[0].status
        this.mensagem.creat(message, { sucesso: true })
        this.GetRegistros()
      }
    ), erro => {
      (console.log("DEu erro: " + erro))
      this.mensagem.creat('Erro: ' + erro.error, { erro: true })

    }
    this.viewCadastro = false
    this.codigoStatus = this.codigoStatus_Backup
    this.formulario.reset()
    this.formulario.enable()
    this.GetRegistros()

  }


  AlterarRegistro() {
    let registro = this.formulario.value
    registro.codigoStatus = this.codigoStatus
    console.log("JSON STATUS", JSON.stringify(registro))
    if (!registro.id) return

    this.SistemaClassificacaoService.PutStatus(registro).toPromise().then(
      res => {
        this.mensagem.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
        this.GetRegistros()
        this.alteracao = false
        this.viewCadastro = false

      }
    ).catch(
      error => console.log(error.error)

    )
    this.viewCadastro = false
    this.formulario.reset()
    this.formulario.enable()
  }

  Excluir()
  {
    let element = this.formulario.getRawValue()
    element.statusRegistro = 'SDA0002'

    console.log("EXCLUIR: ", element)
    this.SistemaClassificacaoService.PutStatus(element).toPromise().then(
      (res:any) =>{ 
        this.mensagem.creat('Registro Desativado com sucesso', { sucesso: true })
        this.GetRegistros()

      }).catch(err => console.log(err.error))

      this.fecharCadastro()

  }

  

  ColocarStatus(){
    console.log("THIS registrosStatus ", this.registrosStatus)
    let dados = this.registrosStatus
    let letra = dados(value => value.valor.slice(0, 1).toUpperCase()).filter((value, i, index) => { return i == index.indexOf(value) }).sort()
    let result = []

    letra.forEach(value => {
      let ref = {letra: value, registros: []}
      result.push(ref)
    });

    result.forEach(value => {
      dados.filter(v => {
        let L = v.descricaoDoStatus.slice(0,1).toUpperCase()
        if(L == value.letra){
          value.registro.push(v)
        }
      })
    })
    this.status = result
    console.log("RESULT STATUS", this.status)


  }




  OpenCadastro() {
    this.formulario.enable()
    this.formulario.reset()
    this.CreatForm()
    this.botaoModificar = false
    this.viewCadastro = true
    this.codigoStatus = this.codigoStatus_Backup


  }

  OpenAlterar(registro) {

    if(registro.tipo == "Status de dados")
    {
      registro.tipo = "TIPOSTATUS3"
    }
    if(registro.tipo == "Status de Documento")
    {
      registro.tipo = "TIPOSTATUS1"
    }
    if(registro.tipo == "Status de Classificação de Dados")
    {
      registro.tipo = "TIPOSTATUS4" 
    }
    if(registro.tipo == "Status de Cadastro")
    {
      registro.tipo = "TIPOSTATUS2"
    }
    this.formulario.setValue(registro)
    this.viewCadastro = true
    this.codigoStatus = registro.codigoStatus
    this.formulario.disable()
    this.botaoModificar = true

  }
  
 


  fecharCadastro() {
    this.formulario.reset()
    this.formulario.enable()
    this.alteracao = false
    this.viewCadastro = false
    this.GetRegistros();

  }


  filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }

}

