import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormCadastroEmpresa, FormCadastroProfissao } from '../configuracao-inicial/cadastro-empresa/model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoInicialService {
  url = new environment().api
  empresa = "FichaGeralEmpresa/FiltrarCodigoEmpresa/"
  profissao = "DadosProfissionais/"
  constructor(private http: HttpClient) {

  }
  /////////////////////////////  Empresa //////////////////////
  listarEmpresa(id?) {
    console.log('Empresa :' + this.url + this.empresa + id)
    return this.http.get(this.url + this.empresa + id)
  }
  cadastrarEmpresa(dados: FormCadastroEmpresa) {
    console.log('Empresa-Cadastrar :' + this.url + this.empresa, dados)
    return this.http.post(this.url + this.empresa, dados)
  }
  cadastrarProfissao(dados: FormCadastroProfissao) {
    console.log('Empresa-Cadastrar :' + this.url + this.empresa, dados)
    return this.http.post(this.url + this.profissao, dados)
  }
  alterarEmpresa(id: string, dados) {
    console.log('Empresa-Alterar :' + this.url + this.empresa + id, dados)
    return this.http.put(this.url + this.empresa + id, dados)
  }
  excluirEmpresa(id: string) {
    console.log('Empresa-Excluir :' + this.url + this.empresa + id)
    return this.http.delete(this.url + this.empresa + id)
  }
  ///////////////////////////// Empresa END //////////////////////
}
