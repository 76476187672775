import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Consultorio } from './consultorio.model';
import { StatusConsultorio } from './StatusConsultorio.class';
import { BuscarDataService } from '../buscar-data.service'
import { environment } from 'src/environments/environment'
import { ToastrService } from 'ngx-toastr';
import { FichaGeralEmpresa } from 'src/app/service/ficha-geral-empresa/FichaGeralEmpresa.model'
import { Observable } from 'rxjs';
import { ConsultorioMedico } from 'src/app/definir-consultorios-medicos/modelsDefinirConsultorioMedico';

@Injectable({
  providedIn: 'root'
})
export class ConsultoriosMedicosService {
  url = new environment().api;
  ConsultoriosMedicos = "ConsultoriosMedicos/"

  constructor(
    private http: HttpClient,
    private buscarData: BuscarDataService,
    private mensagem: ToastrService,
  ) {
  }

  async listaConsultorio() {
    let result: ConsultorioMedico[] = []
    await this.http.get<any>(this.url + this.ConsultoriosMedicos + 'Join/').toPromise()
      .then(res => {
        if (res.success) {
          result = res.result.filter(value => value.status == 'SCA0001')

        }
      })
    return result
  }
  async listaConsultorioEmpresa(codigoEmpresa) {
    let result: ConsultorioMedico[] = []
    await this.http.get(this.url + this.ConsultoriosMedicos + 'FiltrarCodigoEmpresaJoin/' + codigoEmpresa).toPromise()
      .then((res: any) => {
        if (res.success) {
          result = res.result.filter(value => value.status == 'SCA0001')
        }
      })
    return result
  }
  async getConsultorioDoMedico(codigoEmpresa, cpfMedico) {
    let result = new ConsultorioMedico

    await this.http.get(this.url + this.ConsultoriosMedicos + `FiltrarCpf/${cpfMedico}/${codigoEmpresa}`).toPromise()
      .then((res: any) => {
        try {
          if (res[0].codigo) {
            result = res[0]
          }
        } catch { }
      })

    return result
  }

  postCadastrarConsultorio(dados) {
    return this.http.post(this.url + this.ConsultoriosMedicos, dados)
  }

  buscarConsultorioID(id: any) {
    return this.http.get(this.url + this.ConsultoriosMedicos + id)
  }

  EditarConsultorio(id: any, userId: any, data: any, dados: any) {
    return this.http.put(`${this.url}${this.ConsultoriosMedicos}${id}/${userId}/${data}`, dados)
  }
}
