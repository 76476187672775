import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessaoUser } from 'src/app/user.reg';
import { BuscarDataService } from './buscar-data.service';

const sessao = new SessaoUser

@Injectable({
  providedIn: 'root'
})
export class SistemaClassificacaoDadosService {

 url = new environment().api
  //url = "https://localhost:44322/api/"
  apiCaracteristicas = "Caracteristicas/"
  apiConteudoDados = "ConteudoDeDados/"
  apiFormulario = "FormulariosDoSistema/"
  apiDocumento = "Documentos/"
  apiReceituario = "ClassificacaoDeReceituarios/"
  apiStatus = "Status/"
  apiAtribuicao = "AtribuicaoDeStatus/"

  constructor(
    private http: HttpClient, private data: BuscarDataService
  ) { }

  // -------> TABELA CARACTERISTICAS - START <------->
  GetCaracteristicas() {
    return this.http.get(this.url + this.apiCaracteristicas)
  }

  postCaracteristicas(dados: Object): Observable<Object> {
    return this.http.post(this.url + this.apiCaracteristicas, dados)
  }

  PutCaracteristica(registro){
  const sessionId = sessao.sessao_id
    return this.http.put(this.url + this.apiCaracteristicas + `${registro.id}/${this.data.getTIMESTAMP()}/${sessionId}`, registro)
  }

  putCaracteristicasOld(id: string, dados: Object): Observable<Object> {
    return this.http.put(this.url + this.apiCaracteristicas + id, dados)
  }

  getCaracteristicasID(id: string) {
    return this.http.get(`${this.url}${this.apiCaracteristicas}${id}`)
  }
  // -------> TABELA CARACTERISTICAS - END <------->


  // -------> TABELA CONTEUDO DADOS - START <------->
  getConteudo(): Observable<Object> {
    return this.http.get(this.url + this.apiConteudoDados)
  }

  getCaracteristicasCodigoCaracteristica(codigoCaracteristica: string): Observable<Object> {
    return this.http.get(this.url + this.apiConteudoDados + "FiltrarCodigoCaracteristica/" + codigoCaracteristica)
  }

  postConteudo(dados: Object): Observable<Object> {
    return this.http.post(this.url + this.apiConteudoDados, dados)
  }

  putConteudoOld(id: number, dados: Object): Observable<Object> {
    return this.http.put(this.url + this.apiConteudoDados + id, dados)
  }
  PutConteudoDeDados(registro){
    const sessionId = sessao.sessao_id
      return this.http.put(this.url + this.apiConteudoDados + `${registro.id}/${this.data.getTIMESTAMP()}/${sessionId}/`, registro)
    }
  getConteudoID(id: string) {
    return this.http.get(`${this.url}${this.apiConteudoDados}${id}`)
  }
  // -------> TABELA CONTEUDO DADOS - END <------->


  // -------> TABELA FORMULARIO - START <------->
  getFormulario(): Observable<Object> {
    return this.http.get(this.url + this.apiFormulario)
  }

  postFormulario(dados: Object): Observable<Object> {
    return this.http.post(this.url + this.apiFormulario, dados)
  }

  putFormulario(id: string, dados: Object): Observable<Object> {
    return this.http.put(this.url + this.apiFormulario + id, dados)
  }

  getFormularioId(id: string) {
    return this.http.get(`${this.url}${this.apiFormulario}${id}`)
  }
  // -------> TABELA FORMULARIO - END <------->


  // -------> TABELA DOCUMENTOS - START <------->
  getDocumento(): Observable<Object> {
    return this.http.get(this.url + this.apiDocumento)
  }

  postDocumento(dados: Object) {
    return this.http.post(this.url + this.apiDocumento, dados)
  }

  putDocumento(id: string, dados: Object): Observable<Object> {
    return this.http.put(this.url + this.apiDocumento + id, dados)
  }

  getDocumentoId(id: string) {
    return this.http.get(`${this.url}${this.apiDocumento}${id}`)
  }
  // -------> TABELA DOCUMENTOS - END <------->

  // -------> TABELA RECEITUARIO - START <------->
  getReceituario(): Observable<Object> {
    return this.http.get(this.url + this.apiReceituario)
  }

  postReceituario(dados: Object) {
    return this.http.post(this.url + this.apiReceituario, dados)
  }

  putReceituario(id: string, dados: Object): Observable<Object> {
    return this.http.put(this.url + this.apiReceituario + id, dados)
  }

  getReceituarioId(id: string) {
    return this.http.get(`${this.url}${this.apiReceituario}${id}`)
  }
  // -------> TABELA DOCUMENTOS - END <------->

  // -------> TABELA STATUS - START <------->
  getStatus() {
    return this.http.get(this.url + this.apiStatus)
  }

  postStatus(dados) {
    return this.http.post(this.url + this.apiStatus, dados)
  }

  PutStatus(registro) {
    const sessionId = sessao.sessao_id
    return this.http.put(this.url + this.apiStatus +  `${registro.id}/${sessionId}/${this.data.getTIMESTAMP()}/`, registro)
  }

  getStatusId(id: string) {
    return this.http.get(`${this.url}${this.apiStatus}${id}`)
  }

  // -------> TABELA STATUS - END <------->

  // -------> TABELA ATRIBUICAO STATUS - START <------->
  getAtribuicao(): Observable<Object> {
    return this.http.get(this.url + this.apiAtribuicao)
  }

  deleteAtribuicao(id: string) {
    return this.http.delete(this.url + this.apiAtribuicao + id)
  }

  postAtribuicao(dados: Object) {
    return this.http.post(this.url + this.apiAtribuicao, dados)
  }

  putAtribuicao(id: string, dados: Object): Observable<Object> {
    return this.http.put(this.url + this.apiAtribuicao + id, dados)
  }

  getAtribuicaoID(id: string) {
    return this.http.get(`${this.url}${this.apiAtribuicao}${id}`)
  }
  // -------> TABELA ATRIBUICAO STATUS - END <------->

}
