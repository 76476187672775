import { Component, OnInit, ViewChild } from '@angular/core';
import { ClassificacaorReceituarioService} from '../service/classificacao-receituario.service';
import { MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import {  FormControl, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import { ClassificacaoDeReceituarioModal } from './modalClassificacaoReceituario';

@Component({
  selector: 'app-classificacao-receituario',
  templateUrl: './classificacao-receituario.component.html',
  styleUrls: ['./classificacao-receituario.component.scss']
})
export class ClassificacaoReceituarioComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

displayedColumns = ['detalhes','tipoDeNotificacao', 'cor', 'medicamentos', 'abrangencia']

dataSource

registrosTabela

_formulario : FormGroup

registroArray2 = []

dataSourceDetalhes

registroDetalhes

openDetalhe: boolean;

form = new ClassificacaoDeReceituarioModal
  
  constructor(
    private service: ClassificacaorReceituarioService,
    private mensagem: MatSnackBar,
    private _form: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.GetRegistros()
  }

GetRegistros(){
  this.service.Get().toPromise().then(
    (registro: any) => {
      this.dataSource = new MatTableDataSource(registro)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
    }
  ).catch(
    (err) => {
      console.log(err)
    }
  )
}

filtro(reg: string) {
  this.dataSource.filter = reg.trim().toLowerCase();
}

ViewDetalhe(registro){
  console.log("REGISTRO: " + registro)
  console.log("QUALQUER COISA: " + registro.grupo );
  this.dataSource = null  
  this.dataSourceDetalhes = new MatTableDataSource(registro)
  this.registroArray2.push(registro)
  this.registroDetalhes = registro
  console.log("REGISTRO ARRAY: " + this.registroDetalhes)
  console.log(this.registroArray2)
  this.openDetalhe = true

}

Fechar(){
  this.dataSourceDetalhes = null
  this.GetRegistros()
  this.openDetalhe = false

}
}
