import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss']
})
export class PoliticaPrivacidadeComponent implements OnInit {
  // pdf = "http://smartitsystems-001-site16.atempurl.com/Politica_de_Privacidade_APP_MyClinics.pdf"
  pdf = "assets/politica/Politica_de_Privacidade_APP_MyClinics.pdf"
  constructor() { }

  ngOnInit() {
    window.location.href = this.pdf
  }

}
