export class ReciboMedicoRpsCabecalho {
    id: string
    codigoEmpresa: number
    numeroDocumento: string
    nrDocumentoReferencia: string
    dataLancamento: any
    dataDoDocumento: any
    moedaDoRecibo: string
    valorTotalRecibo: number
    status: string
    criadoPor: string



}
export class ReciboMedicoRpsItem {

    id: string
    nrDoc: string
    skuItem: number
    numeroItem: number
    precoBrutoItem: number
    criadoPor: string
}

export class FaturaDeMedico {
    id: string
    codigoEmpresa: number
    numeroDocumento: string
    nrDocumentoReferencia: string
    dataLancamento: any
    dataDoDocumento: any
    dataDoVencimento: any
    dataDoPagamento: any
    meioDePagamento: string
    moedaDaFatura: string
    valorTotalFatura: any
    valorTotalImposto: any
    valorTotalDesconto: any
    status: string
    numeroParcelas: number
    valorParcelas: any
    operadoraCartao: string
    valorLiquidoDaFatura: any
    valorTaxaCartao: any
    taxaCartao: string
    nsu: string
    criadoPor: string
    bancoTransferencia: string
    agenciaTransferencia: string
    contaTransferencia: string
    identificacaoTransferencia: string
    descStatus?: any
}

export class OrdemDeVendaMedicaCabecalho {
    id: string
    codigoEmpresa: number
    nrDoc: string
    nrDocumentoReferencia: string
    dataLancamento: any
    dataDaEntrega: any
    dataDoDocumento: any
    moedaDeDocumento: string
    valorTotalDocumento: number
    valorTotalImposto: number
    valorTotalDesconto: number
    status: string
    criadoPor: string
}

export class OrdemDeVendaMedicaImpostoDoItem {
    id: string
    nrDoc: string
    numerorItem: number
    imposto: string
    aliquotaImposto: number
    valorImpostoItem: number
    tipoImposto?: any
    criadoPor: string
}

export class OrdemDeVendaMedicaItem {
    id: string
    numeroDocumento: string
    numeroItem: number
    skuItem: string
    precoLiquidoItem: number
    valorTotalImpostoItem: number
    valorTotalDescontoItem: number
    precoBrutoItem: number
}

export class FichaGuiaConsultaTissCabecalho {
    id: string
    codigoEmpresa: number
    numeroDocumento: string
    nrDocumentoReferencia: string
    nrGuiaTissPresencialPrestadorServicoSaude: string
    nrGuiaTissPresencialOperadora: string
    nrGuiaTissPrincipalPrestadorServicoSaude: string
    nrGuiaTissPrestadorPrincipalOperadora: string
    nrGuiaTissPrestadorServicoSaudeAssociada: string
    nrGuiaTissOperadoraAssociada: string
    dataLancamento: any
    dataDoDocumento: any
    statusDocumento: string
    nrBeneficiario: string
    operadora: string
    plano: string
    situacaoBeneficiario: string
    motivoDaNegativa: string
    tipoGuiaTiss: string
    classificacaoGuiaTiss: string
    solicitacaoAutorizacaoServicoSaude: string
    solicitacaoAutorizacaoItemAssistencial: string
}

export class FichaGuiaConsultaTissItem {
    id: string
    numeroDocumento: string
    numeroItem: number
    autorizacaoServico: string
    descricaoAutorizacao: string
    statusAutorizacao: string
    statusGuiaTiss: string
    solicitacaoAutorizacaoJustificativa: string
}

export class FaturaDeOperadoraCartao {
    id: string
    criadoPor: string
    timestamp: string
    codigoEmpresa: number
    numeroDocumento: string
    nrDocumentoReferencia: string
    dataLancamento: any
    dataDoDocumento: any
    dataDoVencimento: any
    dataDoPagamento: any
    numeroDaParcela: string
    moedaDaFatura: string
    valorTotal: number
    status: string
}

