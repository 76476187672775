import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReceituarioDoPaciente } from 'src/app/atendimento-clinico/conduta-medica/model';
import { MensagemService } from 'src/app/service/mensagem.service';
import { ReceituarioService } from 'src/app/service/receituario/receituario.service';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
@Component({
  selector: 'app-prontuario-medicamento',
  templateUrl: './prontuario-medicamento.component.html',
  styleUrls: ['./prontuario-medicamento.component.scss']
})
export class ProntuarioMedicamentoComponent implements OnInit {
  @Input('cpf') cpf: string
  @Output('close') exit = new EventEmitter
  @Input('register') register = new ReceituarioDoPaciente
  formMedicacao!: FormGroup
  loading: boolean = false
  constructor(
    private _receituario: ReceituarioService,
    private formBuilder: FormBuilder,
    private _mensagem: MensagemService,
  ) { }

  async ngOnInit() {
    await this.creatForm()
    if (this.register.id) {
      this.formMedicacao.patchValue(this.register)
      this.formMedicacao.disable()
    }
  }

  creatForm() {
    this.formMedicacao = this.formBuilder.group({
      id: new FormControl(''),
      criadoPor: new FormControl(''),
      dataHoraInclusaoDeTabela: new FormControl(''),
      cpfPaciente: new FormControl(''),
      nomePaciente: new FormControl(''),
      observacao: new FormControl(''),
      codigoUnico: new FormControl(''),
      codigoMedicamento: new FormControl(''),
      frequencia: new FormControl(''),
      dosagem: new FormControl(''),
      periodo: new FormControl(''),
      crmMedico: new FormControl(''),
      via: new FormControl(''),
      status: new FormControl(''),
      principioAtivo: new FormControl(''),
      nomeComercial: new FormControl(''),
      codigoDeMedicamento: new FormControl(''),
      codigoDeBulaDeRemedio: new FormControl(''),
    })
  }

  excluirRegistro() {
    let register = this.formMedicacao.getRawValue() as ReceituarioDoPaciente
    if (!register.id) {
      this._mensagem.creat('Não é possível excluir este registro', { erro: true })
      return
    }
    register.status = 'SCA0002'
    this._receituario.put(register).toPromise()
      .then(() => {
        this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
        this.close()
      })
      .catch(error => {
        console.log('erro!', error)
        this._mensagem.creat('Não foi possível alterar registro', { erro: true })
      })
  }
  cancelarRegistro() { 
    this.close()
  }
  fecharCadastro() { }

  salvar() {
    let register = this.formMedicacao.getRawValue() as ReceituarioDoPaciente

    if (!register.id) {
      delete register.id
      register.dataHoraInclusaoDeTabela = new Date().toISOString()
      register.criadoPor = sessao.cpf
      register.crmMedico = sessao.crm ? sessao.crm : '0000'
      register.codigoDeMedicamento = '0'
      register.codigoDeBulaDeRemedio = '0'
      register.codigoMedicamento = '0'
      register.codigoUnico = '0'
      register.cpfPaciente = this.cpf
      register.nomePaciente = sessao.name
      register.status = 'SCA0001'
      console.log(register)
      this._receituario.post(register).toPromise()
        .then(() => this.close())
        .catch(error => {
          this._mensagem.creat('Não foi possível gravar registro', { erro: true })
          console.log('erro!', error)
        })

    } else {
      console.log(register)
      this._receituario.put(register).toPromise()
        .then(() => {
          this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
          this.close()
        })
        .catch(error => {
          console.log('erro!', error)
          this._mensagem.creat('Não foi possível alterar registro', { erro: true })
        })
    }

  }
  _control(control: string) {
    return this.formMedicacao.get(control)
  }
  close() {
    this.exit.emit()
  }

}
