export class formLogAtendimento {
    id: string
    usuarioId: string
    companyId: string
    pacienteId: string
    senha: string
    data: any
    timeCheckin: string
    timeRecepcao: string
    timeSalaEspera: string
    timeConsulta: string
    timeEndConsulta: string
    ativo : boolean = true
}
export class formAtendimentoClinico{
    id: string
    usuarioId: string
    companyId:string
    pacienteId: string
    paciente : string
    data : string
    regHorario :  string
    anamnese : string
    temperatura: string
    pressaoArterial: string
    saturacao: string
    batCardiacos: string
    exame: string
    solicitado: number
    leococitos: string
    plaquetas: string
    hemoglobinaS: string
    crm: string
}
export class formURL{
    usuarioId?: string
    companyId?: string
    pacienteId?: string
}

export class formFichaConsultaMedica {
    id: string
    dataInclusaoDocumento: string
    numeroDocumento: string
    nomeMedico: string
    crmMedico: string
    especialidade: string
    cpfMedico: string
    nomePaciente: string
    cpfPaciente: string
    idadePaciente: string
    numeroProntuarioMedicoDoPaciente: string
    numeroTicektMarcacaoConsultaMedica: string
    numeroProntuarioVidaPaciente: string
    numeroDiarioDispenserMedicacoes: string
    anamnese: string
    codigoListaSintomas: string
    codigoUnicoSinaisVitais: string
    solicitacaoExamesLaudos: string
    peso: string
    altura: string
    exameFisicoOuVisual: string
    hipoteseDiagnostica: string
    diagnostico: string
    prognostico: string
    sequelas: string
    causasMortais: string
    codigoListaTratamentos: string
    codigoReceituario: string
    codigoListaProcedimentoAdicionais: string
    cirurgia: string
    cid: string
    estadoAtualPaciente: string
    situacaoPaciente: string
    descricaoCid: string
    condutaTerapeutica: string
    status: string
}