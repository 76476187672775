import { Component, OnInit, ViewChild, Input, OnDestroy, Output, OnChanges, SimpleChanges } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
// import agendaWeek from '@fullcalendar/agendaWeek'
import interactionPlugin from '@fullcalendar/interaction';
// // import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import bootstrapPlugin, { BootstrapTheme } from '@fullcalendar/bootstrap';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { toMoment } from '@fullcalendar/moment';
import { FullCalendarComponent } from '@fullcalendar/angular';
import listPlugin from '@fullcalendar/list';
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { AgendaConfigForm, AgendaFechadaForm } from 'src/app/agenda/agenda/model';
import { AgendaService } from 'src/app/service/agenda.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ListarMedicos } from 'src/app/service/listar-medicos.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { EventCalender } from '../model';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
const sessao = new SessaoUser
@Component({
  selector: 'app-calendario-config',
  templateUrl: './calendario-config.component.html',
  styleUrls: ['./calendario-config.component.scss']
})
export class CalendarioConfigComponent implements OnInit {
  @Input('registro') registro = []
  @Input('feriados') feriados = []
  @Input('config') config = new AgendaConfigForm
  @Input('view') view
  @Input('onlyView') onlyView: boolean
  @Input('registroAgenda') registroAgenda = []
  @Input('medico') medico: ListarMedicos
  @Input('useConfigEmpresa') useConfigEmpresa: boolean
  @Output('change') change = new EventEmitter
  @Input('block') block: boolean
  @Input('activeHoraiosFechados') activeHoraiosFechados: { medico?: string; registro?: [] } = {};
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGrigPlugin, listPlugin];
  verificar: any;
  mes: string;
  ano: number;
  listEvents = []
  refresh: boolean;
  eventCoord: { top: string; left: string; };
  viewMenu: boolean;
  viewMenuList: boolean;
  viewFormTime: boolean;
  selectDay = new EventCalender
  horarios: { h: string; m: string; }[] = [];
  horariosEnd: { h: string; m: string; }[] = [];
  viewCalendar = 'mes'
  infoViewCanelder = 'Mês'
  viewPeriodo: boolean;
  formFecharPeriodo = { de: new Date(), ate: new Date() }
  formAddHorario = { de: '', ate: '' }
  infoPeriodo: string;
  loadingReg: boolean;
  validRange: { start: string; end: string; };
  abrirAgenda: boolean;
  typeView: string;
  selecTypeEdicao: any;
  mensagemInfo = 'Selecione um dia na agenda para personalizar um horário'
  openDescricao: boolean;



  constructor(
    private buscarData: BuscarDataService,
    private _agenda: AgendaService,
    private _filter: FiltrarDadosService,
    private _mensagem: MensagemService) {
  }


  async ngOnInit() {
    this.registro = []
    if (this.onlyView) this.mensagemInfo = null
    setTimeout(() => { this.getDataCalendario(true) }, 1000)
    this.registroAgenda.forEach(v => v.consulta = true)
    console.log('++++++++++++++++++++++++++++++++++++++++++', this.useConfigEmpresa)
    this.registro = this.registroAgenda
    .filter(value => !value.openDay)
    .filter(value=> new Date(value.start) >= new Date())

    this.refresh = true

    try {
      this.validRange = { start: this.config.start, end: this.config.end }
      this.registro = this.activeHoraiosFechados.registro ? this.activeHoraiosFechados.registro : []
    }
    catch {

    }

    if (this.medico) {
      this.getConfigAgenda(this.medico.cpfProfissional)
      this.buscarListFecharAgenda(this.medico.cpfProfissional)
    }
    this.viewMenu = false
    this.selectDay = new EventCalender
    setTimeout(() => { this.refresh = false; this.inativeDays() }, 500)
  }

  async ngOnChanges() {
    this.ngOnInit()
  }


  ngOnDestroy() {
    clearInterval(this.verificar)
  }

  async getHorarios() {
    let start = this.config.timeStart
    let end = this.config.timeEnd
    if (start && end) {
      let listhorarios = this._agenda.listarHorarios(start, end)
      this.horarios = listhorarios
    } else {
      let listhorarios = this._agenda.listarHorarios('01:00', '23:00')
      this.horarios = listhorarios
    }
    let de = `${this.horarios[0].h}:${this.horarios[0].m}`
    let ate = `${this.horarios[1].h}:${this.horarios[1].m}`
    this.formAddHorario.de = de
    this.formAddHorario.ate = ate
    this.validHorario()

  }

  async getConfigAgenda(cpfMedico) {
    this.loadingReg = true
    if (!cpfMedico) {
      cpfMedico = sessao.codigoEmpresa
    }

    this.config = await this._agenda.getConfigMedico(cpfMedico)

    this.loadingReg = false
    this.getHorarios()
  }
  async buscarListFecharAgenda(cpfMedico) {
    this._agenda.listarAgendaFechada().toPromise()
      .then(
        (reg: Array<AgendaFechadaForm>) => {
          console.log('buscarListFecharAgenda', this.activeHoraiosFechados)
          let useConfigEmpresa = this.useConfigEmpresa
          let getRegistro = reg.filter((reg) => {
            return reg.codigoEmpresa == sessao.codigoEmpresa && useConfigEmpresa ? reg.cpfMedico == sessao.codigoEmpresa.toString() : reg.cpfMedico == cpfMedico
          })
          let registros = []
          getRegistro.forEach(value => {
            let item = new EventCalender
            item.start = value.start
            item.end = value.end
            item.title = value.descricao
            item.description = value.descricao
            item.status = value.status
            item.id = value.id
            item.allDay = value.diaTodo
            item.rendering = value.diaTodo ? 'background' : null
            item.color = value.status == 'SDO0149' ? '#fff0' : '#b3b3b3c9'
            registros.push(item)
          })
          this.registro.push(...registros)
          // try {
          //   this.activeHoraiosFechados.registro.push(...registros)
          // } catch {
          //   this.activeHoraiosFechados = { medico: cpfMedico, registro: [] }
          //   this.activeHoraiosFechados.registro.push(...registros)
          // }

        }
      )
  }


  inativeDays() {
    console.log('inativeDays!')
    let calendar = this.calendarComponent.getApi()
    let range = calendar.state.dateProfile.currentRange
    let dates = this._agenda.genereteRangeDatas(range.start, range.end)

    let getDatesActives = this.registro.map(value => value.start.split('T')[0])
    let validDates = []
    dates.forEach(value => {
      let index = getDatesActives.findIndex(date => date == value)
      if (index < 0) {
        validDates.push(value)
      }
    })
    validDates.forEach(date => {
      const event = {
        start: date,
        allDay: true,
        rendering: 'background',
        color: "#b3b3b3c9"
      }
      this.registro = [...this.registro, event]
    })
  }
  navLinkDayClick(ref) {
    console.log('navLinkDayClick', ref)
  }
  next(ref) {
    const calender = this.calendarComponent.getApi()
    if (ref) {
      calender.next()
    } else {
      calender.prev()
    }
  }
  async exibirCalendario(ref?) {
    this.openDescricao = false
    if (!ref) ref = this.view
    this.viewCalendar = ref
    let api = this.calendarComponent.getApi()
    let date = new Date()
    if (this.selectDay.start) {
      date = new Date(this.selectDay.start)
    }
    switch (ref) {
      case 'hoje':
        api.today()
        this.closeMenu()
        break;
      case 'mes':
        api.changeView('dayGridMonth')
        api.gotoDate(date)
        this.closeMenu()
        break;
      case 'semana':
        api.changeView('timeGridWeek')
        api.gotoDate(date)
        this.closeMenu()
        break
      case 'dia':
        api.changeView('timeGridDay')
        api.gotoDate(date)
        this.closeMenu()
        break
      case 'tarefa':
        api.changeView('listWeek')
        break
      case 'netx':
        await api.next()
        this.getDataCalendario()
        this.closeMenu()
        break
      case 'return':
        await api.prev()
        await this.getDataCalendario()
        this.closeMenu()
        break
      case 'teste':
        console.log(api.view)
        break
    }
    this.inativeDays()

  }
  typeEdicao(type, time) {
    this.closeMenu()
    this.selecTypeEdicao = type
    let mensagem = null
    if (time == 'openTime') {
      this.exibirCalendario('semana');
    } else {
      this.exibirCalendario('mes');

    }
    switch (type) {
      case 'personalizar':
        mensagem = 'Selecione um dia na agenda para personalizar um horário'
        break
      case 'abrirPeriodo':
        this.viewPeriodo = true
        this.abrirAgenda = true
        break
      case 'fecharPeriodo':
        this.viewPeriodo = true
        this.abrirAgenda = false
        break
      case 'abrir':
        mensagem = 'Selecione um ou horário dia na agenda para abrir-la'
        break
      case 'fechar':
        mensagem = 'Selecione um dia ou horário na agenda para fecha-la'
        break
      case 'remover':
        mensagem = 'Selecione um dia ou horário na agenda para reabri-la'
        break
    }

    this.mensagemInfo = mensagem
  }

  abrir(event) {


    try {
      if (event.allDay) {
        let date = event.dateStr
        let filter = this.registro.filter(value => value.start != date)
        this.registro = filter
      } else {
        const id = event.event.id.toString()
        const date = new Date(event.event.start).toISOString()
        let verific = this.registro.filter(v => v.id).filter(value => new Date(value.start).toISOString() == date)
        if (this.selecTypeEdicao == 'fecharTime') {
          let compare = verific.filter(v => v.status == "SDO0149")[0]
          if (compare) {
            this.mensagemInfo = 'Não é possível registrar em um dado ja existente'
            return
          }
        }
        if (this.selecTypeEdicao == 'abrirTime') {
          let compare = verific.filter(v => v.status == "SDO0150")[0]
          if (compare) {
            this.mensagemInfo = 'Não é possível registrar em um dado ja existente'
            return
          }
        }
        let filter = this.registro.filter(v => v.id).filter(value => value.id.toString() != id)
        this.registro = filter
        this.inativeDays()
      }
    }
    catch (error) {
      console.log('/-/-/-/', error)
    }

    this.change.emit(this.registro)
  }

  dateClick(event) {

    let validDate = this.validData(event.dateStr)
    if (!validDate) {
      this.viewMenu = false
      this.mensagemInfo = 'Não é possivel selecionar uma data passada'
      return
    }

    if (this.onlyView) return
    this.mensagemInfo = null

    this.typeView = event.view.type
    let dado = new EventCalender
    dado.start = event.dateStr
    dado.end = event.dateStr
    dado.id = this.listEvents.length

    this.eventCoord = {
      'top': `${event.jsEvent.y}px`,
      'left': `${event.jsEvent.x}px`,
    }

    const typeEdit = this.selecTypeEdicao
    console.log(typeEdit)

    if (typeEdit == 'addDay') {
      this.abrir(event)
      return
    }

    if (typeEdit == 'abrirPeriodo') {
      this.viewPeriodo = true
      this.abrirAgenda = true
      return
    }
    if (typeEdit == 'fecharPeriodo') {
      this.viewPeriodo = true
      this.abrirAgenda = false
      return
    }
    if (typeEdit == 'abrir' || typeEdit == 'abrirTime') {
      this.selectDay = dado
      this.selectDay.backgroundColor = '#ffff'
      this.selectDay.rendering = 'background'
      this.selectDay.diaFechado = false
      this.abrirAgenda = true
      console.log(this.selectDay)
      this.abrir(event)
      this.addEvent()
    }
    if (typeEdit == 'fechar' || typeEdit == 'fecharTime') {
      this.selectDay = dado
      this.selectDay.backgroundColor = '#b3b3b3c9'
      this.selectDay.rendering = 'background'
      this.selectDay.allDay = true
      this.abrirAgenda = false
      this.selectDay.diaFechado = !this.abrirAgenda
      this.openDescricao = true
      // this.addEvent()
    }
    if (this.typeView == 'timeGridWeek') {
      this.selectDay = dado
      this.selectDay.allDay = false
      this.selectDay.color = '#000'
      delete this.selectDay.rendering
      this.addEvent()
      this.openDescricao = false
    }

    if (this.typeView == 'dayGridMonth') {
      this.selectDay = dado
      // this.viewMenu = true
    }

  }
  openMenu(abrir) {
    this.viewMenu = false

    this.abrirAgenda = abrir
    this.viewMenuList = true
  }

  addEvent(registro?: any) {
    // this.refresh = true
    let event = this.selectDay
    if (registro) {
      this.validEvent(registro)
      this.registro = [...this.registro, registro]
    } else {

      if (this.typeView == 'timeGridWeek') {
        event.title = this.abrirAgenda ? 'Horário aberto' : "Horário bloqueado"
        event.color = this.abrirAgenda ? '#000' : "#e2e2e2c9",
          event.backgroundColor = '#e2e2e2c9'
        event.textColor = "#000"
      }


      if (this.typeView == 'dayGridMonth' && this.selecTypeEdicao != 'fechar') {
        event.title = this.abrirAgenda ? 'Dia Aberto' : 'Dia bloqueado'
        event.allDay = true
        event.rendering = 'background'
        // event.display = 'background'
        event.color = this.abrirAgenda ? '#ffff' : "#e2e2e2c9"
        event.textColor = "#000"
      }

      event.status = this.abrirAgenda ? 'SDO0149' : 'SDO0150'
      this.validEvent(event)
      if (event.descricao) {
        event.title = event.descricao;
        event.description = event.descricao
      }
      this.registro = [...this.registro, event]
    }
    this.listEvents.push(event)
    this.calendarComponent.getApi().refetchEvents()
    this.viewMenu = false
    this.viewMenuList = false
    this.change.emit(this.registro.filter(value => value.id))

    // console.log(this.registro)

    console.log('addEvent', event)
  }
  validEvent(event: EventCalender) {
    let result = this.registro.filter(v => v.start == event.start);
    console.log('validEvent', result, this.registro)
    result.forEach(item => {
      try {
        if (item.allDay) {
          let getIndex = this.registro.findIndex(v => v.start == item.start);
          if (getIndex >= 0) {
            delete this.registro[getIndex]
          }
        } else {
          let getIndex = this.registro.findIndex(v => v.id == item.id);
          if (getIndex >= 0) {
            delete this.registro[getIndex]

          }
        }
      } catch {

      }


    });
    this.registro = this.registro.filter(x => x)
  }
  addPeriodo() {
    let periodo = this.formFecharPeriodo
    let validRange = new Date(periodo.de) > new Date(periodo.ate)
    let validDate = this.validData(new Date(this.formFecharPeriodo.de).toISOString().split('T')[0])
    let validPeriodo = this._agenda.genereteRangeDatas(new Date(periodo.de), new Date(periodo.ate)).length

    if (!periodo.de || !periodo.ate) return

    if (validRange) {
      this.infoPeriodo = 'informe uma data coerente, data de inicio não pode ser maior que a data do termino'
      return
    }
    if (!validDate) {
      this.infoPeriodo = 'Data de inicio não pode ser inferior a data de hoje'
      return
    }
    if (validPeriodo > 360) {
      this.infoPeriodo = 'Data de termino muito longa , informe um período de até 12 meses '
      return
    }
    this.infoPeriodo = null
    let addDay = new Date(periodo.ate).getDate() + 1
    let getDate = new Date().setDate(addDay)
    let newDate = new Date(getDate).toISOString()
    let genereteRange = this._agenda.genereteRangeDatas(new Date(periodo.de), new Date(newDate))
    genereteRange.forEach(element => {
      let registro = new EventCalender
      registro.id = this.registro.length
      registro.allDay = true
      registro.rendering = 'background'
      registro.start = element
      registro.end = element
      registro.color = this.abrirAgenda ? '#fff0' : "#e2e2e2c9",
        registro.textColor = "#939393"
      registro.title = ''
      registro.status = this.abrirAgenda ? 'SDO0149' : 'SDO0150'
      this.addEvent(registro)
    });
    console.log(periodo.de, periodo.ate, genereteRange)

    this.viewPeriodo = false
    this.formFecharPeriodo = { de: new Date(), ate: new Date() }


  }
  addHorario() {

    let horario = this.formAddHorario
    let date = new Date(this.selectDay.start).toISOString().split('T')[0]
    let start = `${date}T${horario.de}:00.00Z`
    let end = `${date}T${horario.ate}:00.000Z`

    let registro = new EventCalender
    registro.id = this.registro.length
    registro.start = start
    registro.end = end
    registro.color = this.abrirAgenda ? '#fff0' : "#e2e2e2c9",
      registro.textColor = "#939393"
    registro.title = this.abrirAgenda ? '- Horário Aberto ' : `- Horário fechado `
    this.addEvent(registro)
    this.closeMenu()


  }
  validHorario() {
    const start = this.formAddHorario.de
    let timeStart = parseInt(start.split(':').join(''))
    let getTimes = this.horarios.filter(value => {
      let time = parseInt(`${value.h}${value.m}`)
      return timeStart < time
    })
    this.horariosEnd = getTimes
  }
  alterarAgendaDrop(event) {
    console.log('drop', event)
  }

  clickHouver(event) { console.log(event) }

  MouseOver(event, active) {
    // console.log(event)
  }

  atualizarEvent(item) {
    let event = item.event
    let getReg = this.registro.filter(value => value.id == event.id)[0]
    try {
      if (getReg) {
        getReg.end = new Date(event.end).toISOString()
      }
    } catch {
      this._mensagem.creat('Erro ao atualizar envento', { erro: true })
    }

  }

  getDataCalendario(start?) {
    try {
      const calender = this.calendarComponent.getApi()

      let index = calender.getDate().getMonth() + 1
      this.ano = calender.getDate().getFullYear()
      this.mes = this.buscarData.getMeses(index).name
      if (start) {
        this.mes = this.buscarData.getMeses(index - 1).name
      }

      console.log(this.mes, index)
    } catch {

    }

    this.inativeDays()

  }

  eventRender(e) {
    const event = e.event
    const feriado = event.extendedProps.feriado
    const allDay = event.allDay
    const diaFechado = event.extendedProps.diaFechado
    const consulta = event.extendedProps.consulta
    const diaFechadoResq = event.allDay && event.extendedProps.status == 'SDO0150' ? true : false
    let html = null
    if (consulta) {
      const start = new Date(e.event.start).toISOString().split('T')[1].slice(0, 5)
      const end = new Date(e.event.end).toISOString().split('T')[1].slice(0, 5)
      const status = event.extendedProps.status
      html =
        `<ul class="list-group border p-1 m-0" style="overflow: hidden;border-radius: 5px;">
        <li class="list-group-item p-0 text-dark" style="background: #fefefe3d">
        <i class="${status == 'SDO0008' ? 'mr-1 fas fa-check-circle text-success' : ''} "></i> 
        <i class="${status == 'SDO0010' ? 'mr-1 fas fa-user-clock text-info' : ''} "></i> 
        <i class="${status == 'SDO0022' ? 'mr-1 fas fa-user-times text-danger' : ''} "></i> 
        <i class="${status == 'SD00011' ? 'mr-1 fas fa-medal text-warning' : ''} "></i> 
        <i class="${status == 'SDO0028' ? 'mr-1 fas fa-star text-success' : ''} "></i> 
        <i class="${status == 'SDO0098' ? 'mr-1 fas fa-user-times text-danger' : ''} "></i> 
        ${start}h - ${end}h 
       ( ${e.event.title.split(' ')[0]} )</li>
      </ul>`
    }
    if (diaFechado || diaFechadoResq) {
      html =
        `<ul class="list-group" style="overflow: hidden;">
        <li class="list-group-item p-1" style="
            background: none!important;
            white-space: normal;
            border-radius: 0;
            color: #ea9d9d;">
        <i class="fas fa-ban mr-1"></i> <i>${e.event.title == 'null' || !e.event.title ? ' Dia Bloqueado ' : e.event.title}</i> 
         </li>
      </ul>`
    }
    if (feriado) {
      html =
        `<ul class="list-group" style="overflow: hidden;">
      <li class="list-group-item p-1" style="
          background: #ffffff00!important;
          white-space: normal;
          color: #000;">
       <i>${e.event.title}</i> 
       </li>
    </ul>`
    }

    if (event.extendedProps.status == 'SDO0149') {
      // event.source.calendar.state.viewType == 'timeGridWeek'
      if (this.getTime(e.event.start) != null) {
        html =
          `<ul class="list-group" style="overflow: hidden;">
      <li class="list-group-item" style="
      background: #fff;
      white-space: normal;
      border-radius: 0;
      padding: 1px 5px;
      color: #8c8888;"
      >
          <i class="fas fa-clock mr-1"></i>
          <i>${this.getTime(e.event.start)}</i> 
       </li>
    </ul>`
      }
    }
    if (event.extendedProps.status == 'SDO0150') {
      // event.source.calendar.state.viewType == 'timeGridWeek'
      if (this.getTime(e.event.start) != null) {
        html =
          `<ul class="list-group" style="overflow: hidden;">
      <li class="list-group-item" style="
      background: none;
      white-space: normal;
      border-radius: 3px;
      padding: 1px 5px;
      color: #ea9d9d;">
          <i class="fas fa-clock mr-1"></i>
          <i>${this.getTime(e.event.start)}</i> 
       </li>
    </ul>`
      }
    }

    e.el.innerHTML = html
  }
  validData(date: string) {
    let valid = false
    if (date.split('T').length == 1) {
      let set = new Date().toISOString().split('T')[0]
      const nowDate = new Date(set).valueOf()
      const compare = new Date(date).valueOf()
      if (nowDate <= compare) {
        valid = true
      }
    }
    if (date.split('T').length > 1) {
      let set = new Date().getTimezoneOffset() * 60000
      let gatDate = new Date(Date.now() - set).toISOString()
      const nowDate = new Date(gatDate).valueOf()
      const compare = new Date(date).valueOf()
      if (nowDate <= compare) {
        valid = true
      }
    }
    return valid
  }
  getTime(date: Date) {
    try {
      let iso = new Date(date).toISOString()
      let getTime = iso.split('T')[1].split('.')[0].slice(0, 5)
      return getTime != '00:00' ? getTime + 'h' : null
    } catch {
      return null
    }

  }
  closeMenu() {
    this.viewFormTime = false;
    this.viewPeriodo = false
    this.viewMenu = false
    this.viewMenuList = false
  }
}
