import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { FichaGeralEmpresa } from 'src/app/service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
@Component({
  selector: 'app-local-de-trabalho',
  templateUrl: './local-de-trabalho.component.html',
  styleUrls: ['./local-de-trabalho.component.scss']
})
export class LocalDeTrabalhoComponent implements OnInit, OnChanges {
  @Input('empresas') empresas: any[] = []
  @Output('close') close = new EventEmitter
  @Output('change') change = new EventEmitter
  zoom: number = 4;
  lat = -23.56279528829599
  lng = -46.65567186593021
  markers = []
  icon = "assets/icon/map.png"
  previous: any;
  viewInfo: boolean;
  constructor(private _empresa: FichaGeralService) { }

  ngOnInit() {

  }
  ngOnChanges() {
    this.getDados()
  }
  async getDados() {
    // let empresas = await this._emprea.getEmpresaAssiante(sessao.assinante)
    console.log('1 - empresas', this.empresas)
    setTimeout(() => console.log('empresas', this.empresas), 1000)
    let empresas = []
    for (let empresa of this.empresas) {
      await this._empresa.getFichaGeralEmpresaID(empresa.empresa).toPromise()
        .then((res: any) => {
          if (res.success) {
            empresas.push(...res.result)
          }
        })
    }


    empresas.forEach(item => {
      let lat = item.coordenadaGps.split(',')[0]
      let long = item.coordenadaGps.split(',')[1]
      this.lat = lat
      this.lng = long
      let cordenadas = {
        lat: parseFloat(lat),
        lng: parseFloat(long),
        draggable: false,
        empresa: item
      }
      console.log('EMPRESA', empresas)
      this.markers.push(cordenadas)

    });
  }
  mouseOut(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow
    this.previous.close();
    this.previous = null

  }
  async mouseOver(infowindow, item) {

    if (this.previous) {
      this.previous.close();
      console.log('close',)
    }
    this.previous = infowindow;
    this.previous.open()

    let empresa: FichaGeralEmpresa = item.empresa
    this.viewInfo = true

  }
  closeRegistro() {

    if (this.previous) {
      this.previous.close();
      this.previous = null

    }
  }
  mapClicked(select) {
    console.log(select)
  }
  selectMarker(select) {
    console.log('select ----', select.empresa)
    this.change.emit(select.empresa)
  }

  exit() {
    this.close.emit()
  }
}
