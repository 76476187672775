import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReceituarioDoPaciente } from 'src/app/atendimento-clinico/conduta-medica/model';
import { SessaoUser } from 'src/app/user.reg';
import { environment } from 'src/environments/environment';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class ReceituarioService {

  url = new environment().api
  api = 'ReceituarioDoPaciente/'
  constructor(private http: HttpClient) { }

  post(register: ReceituarioDoPaciente) {
    return this.http.post(this.url + this.api, register)
  }
  put(register){
    return this.http.put(`${this.url + this.api}${register.id}/${new Date().toISOString()}/${sessao.sessao_id}`, register)
  }
  get() {
    return this.http.get<ReceituarioDoPaciente[]>(this.url + this.api)
  }
}
