import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TabelaComponent } from './tabela/tabela.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { HomeComponent } from './home/home.component';
import { AgendaComponent } from './agenda/agenda/agenda.component';
import { SalaEsperaComponent } from './atendimento-clinico/sala-espera/sala-espera.component';
import { DocumentacaoComponent } from './documentacao/documentacao.component';
import { CadastroPacienteComponent } from './cadastro-paciente/cadastro-paciente.component';
import { RecepcaoComponent } from './atendimento-clinico/recepcao/recepcao.component';
import { ProntuarioPacienteComponent } from './prontuario-paciente/prontuario-paciente.component';
import { CobrarConsultaComponent } from './cobrar-consulta/cobrar-consulta.component';
import { EmitirReciboComponent } from './cobrar-consulta/emitir-recibo/emitir-recibo.component';
import { PesquisarMedicamentoComponent } from './pesquisar-medicamento/pesquisar-medicamento.component';
import { AtendimentoClinicoComponent } from './atendimento-clinico/atendimento-clinico.component';
import { MeuConsultorioComponent } from './meu-consultorio/meu-consultorio.component';
import { SalaEsperaSecretariaComponent } from './secretaria/sala-espera-secretaria/sala-espera-secretaria.component';
import { GaleriaIconeComponent } from './tabela/galeria-icone/galeria-icone.component';
import { AtendimentoClinicoPesquisaComponent } from './atendimento-clinico-pesquisa/atendimento-clinico-pesquisa.component';
import { TelasComponent } from './home/telas/telas.component';
import { CadastroEmpresaComponent } from './configuracao-inicial/cadastro-empresa/cadastro-empresa.component';
import { ConfiguracaoInicialComponent } from './configuracao-inicial/configuracao-inicial.component';
import { RegrasImpostoComponent } from './configuracao-inicial/regras-de-imposto/regras-de-imposto.component';
import { RegraConfirmacaoConsultaComponent } from './configuracao-inicial/regra-confirmacao-consulta/regra-confirmacao-consulta.component';
import { ControleSenhaAtendimentoComponent } from './configuracao-inicial/controle-senha-atendimento/controle-senha-atendimento.component';
import { ViewEmpresaComponent } from './configuracao-inicial/view-empresa/view-empresa.component';
import { EstruturaOrganizacionalComponent } from './configuracao-inicial/estrutura-organizacional/estrutura-organizacional.component';
import { CadastroEstruturaOrganizacionalComponent } from './configuracao-inicial/estrutura-organizacional/cadastro-estrutura-organizacional/cadastro-estrutura-organizacional.component';
import { EnciclopediaComponent } from './enciclopedia/enciclopedia.component';
import { PortalComponent } from './portal/portal.component';
import { BularioComponent } from './bulario/bulario.component';
import { InteracaoMedicamentosaComponent } from './interacao-medicamentosa/interacao-medicamentosa.component';
import { InformeProblemaComponent } from './ajuda/informe-problema/informe-problema.component';
import { ManualUsuarioComponent } from './ajuda/manual-usuario/manual-usuario.component';
import { SugestoesComponent } from './ajuda/sugestoes/sugestoes.component';
import { ConversasComponent } from './conversas/conversas.component';
import { PortalPrivadoComponent } from './portal/portal-privado/portal-privado.component';
import { AconteceSaudeComponent } from './portal/acontece-saude/acontece-saude.component';
import { TurismoComponent } from './portal/turismo/turismo.component';
import { SaoPedroComponent } from './portal/turismo/sao-pedro/sao-pedro.component';
import { SaibaMaisComponent } from './portal/saiba-mais/saiba-mais.component';
import { CobrancaComponent } from './financeiro/cobranca/cobranca.component';
import { DicionarioComponent } from './portal/dicionario/dicionario.component';
import { EmitirAtestadoComponent } from './atendimento-clinico/encerrar-atendimento/emitir-atestado/emitir-atestado.component';
import { CondutaMedicaComponent } from './atendimento-clinico/conduta-medica/conduta-medica.component';
import { InscricaoPacienteComponent } from './portal/inscricao-paciente/inscricao-paciente.component';
import { CoronaVirusComponent } from './portal/corona-virus/corona-virus.component';
import { TermaisPrataComponent } from './portal/turismo/termais-prata/termais-prata.component';
import { AguasQuentesComponent } from './portal/turismo/aguas-quentes/aguas-quentes.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { ConfirmacaoComponent } from './portal/confirmacao/confirmacao.component';
import { AuthRouterService } from './service/auth-router.service';
import { VideoChamadaComponent } from './atendimento-clinico/video-chamada/video-chamada.component';
import { AdminUsuariosComponent } from './admin-usuarios/admin-usuarios.component';
import { AdminTokenComponent } from './admin-usuarios/admin-token/admin-token.component';

import { ConviteComponent } from './home/convite/convite.component';
import { ConversasService } from './service/conversas.service';
import { HistoricoConversasComponent } from './conversas/historico-conversas/historico-conversas.component';
import { UploadComponent } from './upload/upload.component';
import { CadastroPacienteBasicoComponent } from './cadastro-paciente-basico/cadastro-paciente-basico.component';
import { SenhaAtendimentoComponent } from './atendimento-clinico/senha-atendimento/senha-atendimento.component';
import { FichaConsultaMedicaComponent } from './atendimento-clinico/ficha-consulta-medica/ficha-consulta-medica.component';
import { CaracteristicasComponent } from './ajustes/CaracteristicasDados/caracteristicas-de-dados.component';
import { DocumentosDoSistemaComponent } from './documentos-do-sistema/documentos-do-sistema.component';
import { FormulariosDoSistema } from './ajustes/FormularioSistema/formularios-do-sistema.component';
import { StatusDoSistemaComponent } from './ajustes/status-do-sistema/status-do-sistema.component';
import { DefinirEmpresaMedicaComponent } from './ajustes/definir-empresa-medica/definir-empresa-medica.component';
import { TesteComponent } from './financeiro/teste/teste.component';
import { DiarioDeAcessoComponent } from './diario-de-acesso/diario-de-acesso.component';
import { DefinirConsultoriosMedicosComponent } from './definir-consultorios-medicos/definir-consultorios-medicos.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { CadastroItemComponent } from './cadastro-item/cadastro-item.component';
import { PosicaoNumeracaoNfeComponent } from './ajustes/posicao-numeracao-nfe/posicao-numeracao-nfe.component';
import { ListaPrecosVendaComponent } from './ajustes/lista-precos-venda/lista-precos-venda.component';
import { TesteTesteComponent } from './ajustes/teste-teste/teste-teste.component';
import { MinhasConexoesProfissionaisComponent } from './minhas-conexoes-profissionais/minhas-conexoes-profissionais.component';
import { CadastroOperadoraSaudeComponent } from './cadastro-operadora-saude/cadastro-operadora-saude.component';
import { MensagensDoSistemaComponent } from './mensagens-do-sistema/mensagens-do-sistema.component';
import { LogsSistemaComponent } from './logs-sistema/logs-sistema.component';
import { RegraConfirmacaoConsultaService } from './service/regra-confirmacao-consulta.service';
import { DocumentosPdfComponent } from './documentos-pdf/documentos-pdf.component';
import { ConteudoDeDadosComponent } from './conteudo-de-dados/conteudo-de-dados.component';
import { AtribuicaoStatusComponent } from './ajustes/atribuicao-status/atribuicao-status.component';
import { HomeBusinessComponent } from './my-clinics-business/homeBusiness/homeBusiness.component';
import { PlanoContaContabeisComponent } from './plano-conta-contabeis/plano-conta-contabeis.component';
import { UploadConteudoDadosComponent } from './upload-conteudo-dados/upload-conteudo-dados.component';
import { AssinaturaComponent } from './portal/assinatura/assinatura.component';
import { CadastroAssinaturaComponent } from './portal/cadastro-assinatura/cadastro-assinatura.component';
import { LivroRazaoComponent } from './livro-razao/livro-razao.component'
import { EmpresaMyClinicsComponent } from './empresa-my-clinics/empresa-my-clinics.component';
import { AdministrarAssinanteComponent } from './ajustes/administrar-assinante/administrar-assinante.component';
import { UploadDocumentoComponent } from './upload-documento/upload-documento.component';
import { ConvenioComponent } from './cobrar-consulta/convenio/convenio.component';
import { CadastroFornecedorComponent } from './cadastro-fornecedor/cadastro-fornecedor.component';
import { CadastroContaBancariaComponent } from './cadastro-conta-bancaria/cadastro-conta-bancaria.component';
import { MinhasConexoesComponent } from './minhas-conexoes/minhas-conexoes.component';
import { CadastroLocalArmazenamentoComponent } from './cadastro-local-armazenamento/cadastro-local-armazenamento.component'
import { CadastroCentroDeCustoCompletoComponent } from './cadastro-centro-de-custo-completo/cadastro-centro-de-custo-completo.component'
import { CadastroCentroDeLucroComponent } from './cadastro-centro-de-lucro/cadastro-centro-de-lucro.component'
import { RegraAprovacaoDocumentoComponent } from './ajustes/regra-aprovacao-documento/regra-aprovacao-documento.component';
import { HierarquiaDeCustoELucroComponent } from './hierarquia-de-custo-e-lucro/hierarquia-de-custo-e-lucro.component';
import { ConciliarOperadoraPlanoSaudeComponent } from './gestao-simplificada/controle-financeiro/conciliar-operadora-plano-saude/conciliar-operadora-plano-saude.component';
import { AutorizacaoDeAcessoComponent } from "./ajustes/autorizacao-de-acesso/autorizacao-de-acesso.component";
import { TabelaTussComponent } from './tabela-tuss/tabela-tuss.component';
import { CatalogoTabelasComponent } from './ajustes/catalogo-tabelas/catalogo-tabelas.component';

import { PerfilDeAcessoUsuarioComponent } from "./ajustes/perfil-de-acesso-usuario/perfil-de-acesso-usuario.component";
import { ClassificacaoReceituarioComponent } from './classificacao-receituario/classificacao-receituario.component';
import { ListaExamesComponent } from './lista-exames/lista-exames.component';
import { ListaCidsComponent } from './lista-cids/lista-cids.component';
import { ProtocoloClinicoComponent } from './conteudo-setor-saude/protocolo-clinico/protocolo-clinico.component';
import { DevComponent } from './dev/dev/dev.component';
import { AtualizarDadosSaudeComponent } from './atendimento-clinico/ficha-consulta-medica/atualizar-dados-saude/atualizar-dados-saude.component';
import { AtribuicaoDeAcessoComponent } from "./ajustes/atribuicao-de-acesso/atribuicao-de-acesso.component";
import { CadastroOperadoraCartaoComponent } from './ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.component';
import { InformarSaldoContabilInicialComponent } from './ajustes/informar-saldo-contabil-inicial/informar-saldo-contabil-inicial.component';
import { TreePlanoContaComponent } from './plano-conta-contabeis/tree-plano-conta/tree-plano-conta.component';
import { LancamentoLivroCaixaComponent } from './gestao-simplificada/lancamento-livro-caixa/lancamento-livro-caixa.component';
import { ProntuarioVidaPacienteComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/prontuario-vida-paciente.component';
import { CadastroFuncionarioComponent } from './cadastro-funcionario/cadastro-funcionario.component';
import { EmitirReciboNotaFiscalComponent } from './gestao-simplificada/controle-financeiro/emitir-recibo-nota-fiscal/emitir-recibo-nota-fiscal.component';
import { RegrasOrcamentoComponent } from './ajustes/regras-orcamento/regras-orcamento.component';


import { RegistrarPagamentosComponent } from './gestao-simplificada/registrar-pagamentos/registrar-pagamentos.component';

import { ApurarImpostoComponent } from './apurar-imposto/apurar-imposto.component';
import { CertificadoDigital } from './service/certificado-digital/modal';
import { CertificadoDigitalComponent } from './certificado-digital/certificado-digital.component';
import { CertificadoNfseComponent } from './ajustes/definir-empresa-medica/certificado-nfse/certificado-nfse.component';
import { CadastroCartaoCreditoComponent } from './ajustes/cadastros-mestres/cadastro-cartao-credito/cadastro-cartao-credito.component';
import { FluxoCaixaComponent } from './gestao-simplificada/fluxo-caixa/fluxo-caixa.component';
import { TreeFluxoCaixaComponent } from './gestao-simplificada/fluxo-caixa/tree-fluxo-caixa/tree-fluxo-caixa.component';
import { UsuariosLogadosComponent } from './usuarios-logados/usuarios-logados.component';
import { AnaliticoComponent } from './analitico/analitico.component';
import { LegislacaoTributariaComponent } from './ajustes/legislacao-tributaria/legislacao-tributaria.component';
import { ParametrosDoSistemaComponent } from './parametros-do-sistema/parametros-do-sistema.component';
import { AnaliseSituacaoFiscalComponent } from './analitico/analise-situacao-fiscal/analise-situacao-fiscal.component';
import { ContasReceberComponent } from './gestao-simplificada/contas-receber/contas-receber.component';
import { ConciliacaoBancariaComponent } from './gestao-simplificada/conciliacao-bancaria/conciliacao-bancaria.component';
import { CadastroPrestadorServicoService } from './service/cadastro-prestador-servico.service';
import { CadastroPrestadorServicoComponent } from './gestao-simplificada/cadastro-prestador-servico/cadastro-prestador-servico.component';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';
import { ResetPasswordComponent } from './portal/reset-password/reset-password.component';
import { OrganogramaComponent } from './analitico/organograma/organograma.component';
import { DicasBemEstarComponent } from './portal/dicas-bem-estar/dicas-bem-estar.component';
import { SaudeAZComponent } from './portal/saude-az/saude-az.component';
import { ReceitaEletronicaComponent } from './atendimento-clinico/ficha-consulta-medica/receita-eletronica/receita-eletronica.component';
import { CarteiraVacinaComponent } from './usuario/carteira-vacina/carteira-vacina.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./home/home.component').then(m => m.HomeComponent)
  // },
  { path: '', component: PortalComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthRouterService], children: [
      { canActivate: [AuthRouterService], path: 'CadastroPrestador', component: CadastroPrestadorServicoComponent },
      { canActivate: [AuthRouterService], path: 'ControleBancario', component: ConciliacaoBancariaComponent },
      { canActivate: [AuthRouterService], path: 'Legislacaotributaria', component: LegislacaoTributariaComponent },
      { canActivate: [AuthRouterService], path: 'CadastroCartaoCredito', component: CadastroCartaoCreditoComponent },
      { canActivate: [AuthRouterService], path: 'LivroCaixaConsultorio', component: LancamentoLivroCaixaComponent },
      { canActivate: [AuthRouterService], path: 'RegrasDeOrcamento', component: RegrasOrcamentoComponent },
      { canActivate: [AuthRouterService], path: 'EmitirReciboNotaFiscal', component: EmitirReciboNotaFiscalComponent },
      { canActivate: [AuthRouterService], path: 'RegistrarPagamentos', component: RegistrarPagamentosComponent },
      { canActivate: [AuthRouterService], path: 'CadastroOperadoraCartao', component: CadastroOperadoraCartaoComponent },
      { canActivate: [AuthRouterService], path: 'Dicionarios', component: TabelaComponent },
      // { canActivate: [AuthRouterService], path: '', component: RecepcaoComponent },
      { canActivate: [AuthRouterService], path: 'usuario/:id', component: UsuarioComponent },
      { canActivate: [AuthRouterService], path: 'Agenda/:id', component: AgendaComponent },
      { canActivate: [AuthRouterService], path: 'SalaEspera/:id', component: SalaEsperaComponent },
      { canActivate: [AuthRouterService], path: 'VerSalaEspera', component: SalaEsperaComponent },
      { canActivate: [AuthRouterService], path: 'senha-atendimento/:id', component: SenhaAtendimentoComponent },
      { canActivate: [AuthRouterService], path: 'senha-atendimento/:id', component: SenhaAtendimentoComponent },
      { canActivate: [AuthRouterService], path: 'Recepcao/:id', component: RecepcaoComponent },
      { canActivate: [AuthRouterService], path: 'documentacao', component: DocumentacaoComponent },
      { canActivate: [AuthRouterService], path: 'cadastro-paciente', component: CadastroPacienteComponent },
      { canActivate: [AuthRouterService], path: 'prontuario-paciente', component: ProntuarioPacienteComponent },
      { canActivate: [AuthRouterService], path: 'cobrar-consulta', component: CobrarConsultaComponent },
      { canActivate: [AuthRouterService], path: 'emitir-recibo', component: EmitirReciboComponent },
      { canActivate: [AuthRouterService], path: 'pesquisar-medicamento', component: PesquisarMedicamentoComponent },
      { canActivate: [AuthRouterService], path: 'AtendimentoClinico/:id/:dados', component: AtendimentoClinicoComponent },
      { canActivate: [AuthRouterService], path: 'AtendimentoPesquisa/:id', component: AtendimentoClinicoPesquisaComponent },
      { canActivate: [AuthRouterService], path: 'FichaConsultaMedica', component: FichaConsultaMedicaComponent },
      { canActivate: [AuthRouterService], path: 'Consultorio/:id', component: MeuConsultorioComponent },
      { canActivate: [AuthRouterService], path: 'sala-espera-secretaria/:id', component: SalaEsperaSecretariaComponent },
      { canActivate: [AuthRouterService], path: 'galeria-icone', component: GaleriaIconeComponent },
      { canActivate: [AuthRouterService], path: 'configuracao-inicial', component: ConfiguracaoInicialComponent },
      { canActivate: [AuthRouterService], path: 'CadastroEmpresa/:id', component: ViewEmpresaComponent },
      { canActivate: [AuthRouterService], path: 'cadastro-empresa/:id', component: CadastroEmpresaComponent },
      { canActivate: [AuthRouterService], path: 'RegrasImposto/:id', component: RegrasImpostoComponent },
      { canActivate: [AuthRouterService], path: 'LogsDoSistema', component: LogsSistemaComponent },
      { canActivate: [AuthRouterService], path: 'RegraConfirmacaoConsulta/:id', component: RegraConfirmacaoConsultaComponent },
      { canActivate: [AuthRouterService], path: 'ControleSenhaAtendimento/:id', component: ControleSenhaAtendimentoComponent },
      { canActivate: [AuthRouterService], path: 'MinhasConexoesProfissionais/:id', component: MinhasConexoesProfissionaisComponent },
      { canActivate: [AuthRouterService], path: 'CadastroOperadoraPlanoDeSaude', component: CadastroOperadoraSaudeComponent },
      { canActivate: [AuthRouterService], path: 'MensagensDoSistema', component: MensagensDoSistemaComponent },
      // { path: 'view-empresa/:id', component: ViewEmpresaComponent },
      { canActivate: [AuthRouterService], path: 'estrutura-organizacional/:id', component: EstruturaOrganizacionalComponent },
      { canActivate: [AuthRouterService], path: 'cadastro-estrutura-organizacional/:id', component: CadastroEstruturaOrganizacionalComponent },
      { canActivate: [AuthRouterService], path: 'enciclopediaMedica', component: EnciclopediaComponent },
      { canActivate: [AuthRouterService], path: 'ConsultaAnvisa', component: BularioComponent },
      { canActivate: [AuthRouterService], path: 'interacao-medicamentosa', component: InteracaoMedicamentosaComponent },
      { canActivate: [AuthRouterService], path: 'ajuda/informeProblema', component: InformeProblemaComponent },
      { canActivate: [AuthRouterService], path: 'ajuda/manualUsuario', component: ManualUsuarioComponent },
      { canActivate: [AuthRouterService], path: 'ajuda/sugestoes', component: SugestoesComponent },
      { canActivate: [AuthRouterService], path: 'conversas', component: ConversasComponent },
      { canActivate: [AuthRouterService], path: 'cobranca', component: CobrancaComponent },
      { canActivate: [AuthRouterService], path: 'Noticias', component: DicionarioComponent },
      { canActivate: [AuthRouterService], path: 'AdminUsuarios', component: AdminUsuariosComponent },
      { canActivate: [AuthRouterService], path: 'convite', component: ConviteComponent },
      { canActivate: [AuthRouterService], path: 'CaracteristicasDeDados', component: CaracteristicasComponent },
      { canActivate: [AuthRouterService], path: 'ConteudoDeDados', component: ConteudoDeDadosComponent },
      { canActivate: [AuthRouterService], path: 'DocumentosDoSistema', component: DocumentosDoSistemaComponent },
      { canActivate: [AuthRouterService], path: 'FormulariosDoSistema', component: FormulariosDoSistema },
      { canActivate: [AuthRouterService], path: 'StatusDoSistema', component: StatusDoSistemaComponent },
      { canActivate: [AuthRouterService], path: 'DefinirEmpresaMedica', component: DefinirEmpresaMedicaComponent },
      { canActivate: [AuthRouterService], path: 'Update', component: UploadComponent },
      { canActivate: [AuthRouterService], path: 'Teste', component: TesteComponent },
      { canActivate: [AuthRouterService], path: 'DiarioDeAcesso', component: DiarioDeAcessoComponent },
      { canActivate: [AuthRouterService], path: 'DefinirConsultorioMedico', component: DefinirConsultoriosMedicosComponent },
      { canActivate: [AuthRouterService], path: 'CadastroDeItemVenda', component: CadastroItemComponent },
      { canActivate: [AuthRouterService], path: 'CadastroDeItem', component: CadastroItemComponent },
      { canActivate: [AuthRouterService], path: 'PosicaoNumeracaoNfe', component: PosicaoNumeracaoNfeComponent },
      { canActivate: [AuthRouterService], path: 'ListaPrecoVenda', component: ListaPrecosVendaComponent },
      { canActivate: [AuthRouterService], path: 'RegraConfirmacaoConsulta', component: TesteTesteComponent },
      { canActivate: [AuthRouterService], path: 'AtribuicaoDeStatus', component: AtribuicaoStatusComponent },
      { canActivate: [AuthRouterService], path: 'PlanoDeContasContabil', component: PlanoContaContabeisComponent },
      { canActivate: [AuthRouterService], path: 'DadosDeEmpresa', component: EmpresaMyClinicsComponent },
      { canActivate: [AuthRouterService], path: 'ContabilizarProcessoNegocio', component: LivroRazaoComponent },
      { canActivate: [AuthRouterService], path: 'AdministrarAssinante', component: AdministrarAssinanteComponent },
      { canActivate: [AuthRouterService], path: 'CadastroFornecedor', component: CadastroFornecedorComponent },
      { canActivate: [AuthRouterService], path: 'CadastroContaBancaria', component: CadastroContaBancariaComponent },
      { canActivate: [AuthRouterService], path: 'conexoes', component: MinhasConexoesComponent },
      { canActivate: [AuthRouterService], path: 'CadastroLocalArmazenamento', component: CadastroLocalArmazenamentoComponent },
      { canActivate: [AuthRouterService], path: 'ControlePorCentroCusto', component: CadastroCentroDeCustoCompletoComponent },
      { canActivate: [AuthRouterService], path: 'ControlePorCentroLucro', component: CadastroCentroDeLucroComponent },
      { canActivate: [AuthRouterService], path: 'RegraAprovacaoDocumento', component: RegraAprovacaoDocumentoComponent },
      { canActivate: [AuthRouterService], path: 'UploadConteudosDeDados', component: UploadConteudoDadosComponent },
      { canActivate: [AuthRouterService], path: 'HierarquiaCentroCustoLucro', component: HierarquiaDeCustoELucroComponent },
      { canActivate: [AuthRouterService], path: 'ConciliaçãoOperadoraPlanoSaude', component: ConciliarOperadoraPlanoSaudeComponent },
      { canActivate: [AuthRouterService], path: 'AutorizacaoDeAcesso', component: AutorizacaoDeAcessoComponent },
      { canActivate: [AuthRouterService], path: 'classificacaoreceitasmedicas', component: ClassificacaoReceituarioComponent },
      // { canActivate: [AuthRouterService], path: '', component: FichaConsultaMedicaComponent },
      { canActivate: [AuthRouterService], path: 'TabelaTuss', component: TabelaTussComponent },
      { canActivate: [AuthRouterService], path: 'PerfilDeAcessoDeUsuario', component: PerfilDeAcessoUsuarioComponent },
      { canActivate: [AuthRouterService], path: 'receituario', component: ClassificacaoReceituarioComponent },
      { canActivate: [AuthRouterService], path: 'ApurarImposto', component: ApurarImpostoComponent },
      // { canActivate: [AuthRouterService], path: '', component: FichaConsultaMedicaComponent },
      { canActivate: [AuthRouterService], path: 'TabelaCID', component: ListaCidsComponent },
      { canActivate: [AuthRouterService], path: 'examesmedicos', component: ListaExamesComponent },
      { canActivate: [AuthRouterService], path: 'ProtocoloClinico', component: ProtocoloClinicoComponent },
      { canActivate: [AuthRouterService], path: 'AtribuiçãoDeAcessoDeUsuario', component: AtribuicaoDeAcessoComponent },
      { canActivate: [AuthRouterService], path: 'AtribuiçãoDeAcessoDeUsuario/:cpf/:codigoEmpresa', component: AtribuicaoDeAcessoComponent },
      { canActivate: [AuthRouterService], path: 'CatalogoTabelas', component: CatalogoTabelasComponent },
      { canActivate: [AuthRouterService], path: 'Token', component: AdminTokenComponent },
      { canActivate: [AuthRouterService], path: 'UsuariosOnline', component: UsuariosLogadosComponent },
      { canActivate: [AuthRouterService], path: 'ParametrosDoSistema', component: ParametrosDoSistemaComponent },

      { canActivate: [AuthRouterService], path: 'InformarSaldoContabilInicial', component: InformarSaldoContabilInicialComponent },
      { canActivate: [AuthRouterService], path: 'ApurarImpostosARecolher', component: ApurarImpostoComponent },
      { canActivate: [AuthRouterService], path: 'FluxoDeCaixa', component: FluxoCaixaComponent },
      { canActivate: [AuthRouterService], path: 'PainelServicosOrganizacao', component: AnaliticoComponent },
      { canActivate: [AuthRouterService], path: 'ContasAReceber', component: ContasReceberComponent },
      { canActivate: [AuthRouterService], path: 'AnaliseSituacaoFiscal', component: AnaliseSituacaoFiscalComponent },
      { canActivate: [AuthRouterService], path: 'CadastroFuncionario', component: CadastroFuncionarioComponent },
      { canActivate: [AuthRouterService], path: 'MinhaConta', component: MinhaContaComponent },
      { canActivate: [AuthRouterService], path: 'MeuPronturarioVida', component: ProntuarioVidaPacienteComponent },
      { canActivate: [AuthRouterService], path: 'MeusProntuariosMedicos', component: ProntuarioPacienteComponent },
      { canActivate: [AuthRouterService], path: 'Organograma', component: OrganogramaComponent },
      // { canActivate: [AuthRouterService], path: '', component: FichaConsultaMedicaComponent },
      // { canActivate: [AuthRouterService], path: '', component: AnaliseSituacaoFiscalComponent },
      // { canActivate: [AuthRouterService], path: '', component: RegraAprovacaoDocumentoComponent },
      // { canActivate: [AuthRouterService], path: '', component: UploadConteudoDadosComponent },
      // { canActivate: [AuthRouterService], path: '', component: DocumentosPdfComponent },

      { path: 'documento', component: DocumentosPdfComponent },
      // { path: '', component: TreePlanoContaComponent },
      // { path: '', component: ConvenioComponent },
      // { canActivate: [AuthRouterService], path: '', component: DocumentosDoSistemaComponent },

      // { canActivate: [AuthRouterService], path: '', component: MinhasConexoesProfissionaisComponent },
    ]
  },
  // { path: '', component: PortalComponent},
  { path: 'portal/assinatura/compra', component: CadastroAssinaturaComponent },
  { path: 'portal/assinatura', component: AssinaturaComponent },
  { path: 'portal/privado', component: PortalPrivadoComponent },
  { path: 'portal/acontece-saude', component: AconteceSaudeComponent },
  { path: 'portal/turismo', component: TurismoComponent },
  { path: 'portal/turismo/sao-pedro', component: SaoPedroComponent },
  { path: 'portal/saiba-mais', component: SaibaMaisComponent },
  { path: 'portal/cadastro', component: InscricaoPacienteComponent },
  { path: 'portal/dicasBemEstar', component: DicasBemEstarComponent },
  { path: 'portal/saudeAZ', component: SaudeAZComponent },
  { path: 'portal/corona-virus', component: CoronaVirusComponent },
  { path: 'portal/turismo/termais-prata', component: TermaisPrataComponent },
  { path: 'portal/turismo/aguas-quentes', component: AguasQuentesComponent },
  { path: 'portal/confirmemail/:nome/:id', component: ConfirmacaoComponent },
  { path: 'resetpassword/:id', component: ResetPasswordComponent },
  { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
  { path: 'devteste', component: ConversasComponent },
  { path: 'business', component: HomeBusinessComponent },
  { path: 'admin', component: LoginAdminComponent },
  { path: 'dev', component: ReceitaEletronicaComponent },
  // {
  //   path: 'home',
  //   component: HomeComponent,
  //   children: [
  //     {
  //       path: 'agenda/:id',
  //       component: AgendaComponent
  //     }
  //   ]
  // },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
    let home = routes.filter(value => value.path == 'home')
    let getRouter = home[0].children.map(value => value.path.replace('/:id', ''))
    localStorage.setItem('routerActive', getRouter.toString())
  }

}

