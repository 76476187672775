import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessaoUser } from '../user.reg';
import { FichaGeralService } from './ficha-geral-empresa/ficha-geral-empresa.service';
import { FotoPerfilService } from './foto-perfil.service';

const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class FiltrarDadosService {

  constructor(private _foto: FotoPerfilService, private _empresa: FichaGeralService) { }

  filterTodos(registro: Array<any>, empresas?: Array<any>) {
    let response = []
    try {

      if (!empresas) empresas = JSON.parse(sessao.listEmpresas)

      empresas.forEach(value => {
        let get = registro.filter(item => item.codigoEmpresa == value.empresa).map(v => {
          v.nomeEmpresa = value.nomeEmpresa
          return v
        })
        if (get.length > 0) {
          response.push(...get)
        }
      })
    } catch {
      return response
    }
    // console.log('response', response)
    return response
  }
  inserirNomeEmpresa(registro) {
    let response = []
    const empresas = JSON.parse(sessao.listEmpresas)
    registro.forEach(element => {
      element.nomeEmpresa = null
    });
    empresas.forEach(emp => {
      registro.filter(value => {
        if (emp.empresa == value.codigoEmpresa) {
          value.nomeEmpresa = emp.nomeEmpresa
        }
      })
    });
    response = registro.filter(value => value.nomeEmpresa)
    return response
  }
  async inserirNomeEmpresaRequest(registro: Array<any>) {
    registro.forEach(async value => {
      let getName = await this._empresa.getNameEmpresa(value.codigoEmpresa)
      value.nomeEmpresa = getName
    });
    return registro
  }

  async inserirFotoPerfil(campoCPF: string, registro: any[]) {

    if (!campoCPF) return registro

    for (let i = 0; i < registro.length; i++) {
      let value = registro[i]
      value.backgroud = { 'background-image': `url('assets/imgUser.png')` }

      ///Script para verificar se ja existe uma foto com o cpf do item 
      let verificDownloadImg = registro.filter(v => v.fotoPerfil).filter(v => v[campoCPF] == value[campoCPF])
      if (verificDownloadImg.length > 0) {
        let cpf = verificDownloadImg[0][campoCPF]
        let background = verificDownloadImg.filter(v => v.backgroud['background-image'] != "url('assets/imgUser.png')")[0]
        let fotoPerfil = verificDownloadImg[0].fotoPerfil
        registro.forEach(item => {
          if (item[campoCPF] == cpf) {
            item.backgroud = background.backgroud
            item.fotoPerfil = fotoPerfil
            item.inserido = true
          }
        })
        continue
      }

      let foto = await this._foto.buscarFoto(value[campoCPF])

      if (foto == 'assets/imgUser.png') {
        value.backgroud = { 'background-image': `url('assets/imgUser.png')` }
        continue
      }
      try {
        let back = JSON.stringify(foto).split('"')[3]
        value.backgroud = { 'background-image': `url('${back}')` }
      } catch {
        value.backgroud = { 'background-image': `url('assets/imgUser.png')` }
      }
      value.fotoPerfil = foto

    }
    return registro


  }
  async inserirFotoPerfilEach(campoCPF: string, registro: any, only?: boolean) {
    try {
      if (!campoCPF) return registro

      if (typeof registro != 'object') return registro
      // let value = Object.assign({}, registro, { backgroud: '', fotoPerfil: '' })
      let value = registro
      value.backgroud = { 'background-image': `url('assets/imgUser.png')` }

      let foto = await this._foto.buscarFoto(value[campoCPF])
      if (foto == 'assets/imgUser.png') {
        value.backgroud = { 'background-image': `url('assets/imgUser.png')` }
        return
      }
      try {
        let back = JSON.stringify(foto).split('"')[3]
        value.backgroud = { 'background-image': `url('${back}')` }
      } catch {
        value.backgroud = { 'background-image': `url('assets/imgUser.png')` }
      }
      value.fotoPerfil = foto
      return only ? value.fotoPerfil : value
    } catch (error) {
      console.log(error)
      return only ? registro.fotoPerfil : registro
    }

  }
  convertCriadoEm(criadoEM: string) {
    if (!criadoEM) return ''
    if (criadoEM.indexOf('/') >= 0) {
      criadoEM = criadoEM.split('/').join('')
    }
    try {
      let timestamp = criadoEM
      let ano = timestamp.slice(0, 4)
      let mes = timestamp.slice(4, 6)
      let dia = timestamp.slice(6, 8)
      let timeH = timestamp.slice(8, 10)
      let timeM = timestamp.slice(10, 12)
      let date
      if (timeH && timeM) {
        date = new Date(`${ano}-${mes}-${dia}T${timeH}:${timeM}`).toISOString()
      } else {
        date = `${ano}-${mes}-${dia}`
      }
      return date
    } catch {
      return '0000-00-00'
    }
  }

  removeDublic(registro: Array<any>, ref) {
    let result = [];
    if (!registro) return registro
    registro.forEach((value) => {
      let res = result.findIndex(v => v[ref] == value[ref]);
      if (res < 0) {
        result.push(value);
      }
    });
    return result
  }
  removeDublicEntreArray(campoCompare: string, arrayPrincipal: Array<any>, arraySecundario: Array<any>) {
    let result = []
    arrayPrincipal.forEach(element => {
      let verifc = arraySecundario.findIndex(value => value[campoCompare] == element[campoCompare])
      if (verifc < 0) {
        result.push(element)
      }
    });
    return result
  }
  removeNull(dado: Object) {
    Object.keys(dado).forEach(v => { if (!dado[v]) { delete dado[v] } })
    return dado
  }
  formatDate(date: string) {
    if (!date) return ''
    if (date.indexOf('/')) {
      let v = date.split('/').join('');
      let format = v.slice(4, 8) + v.slice(2, 4) + v.slice(0, 2);
      return format
    }
    if (date.indexOf('-')) {
      let format = date.split('/').join('');
      return format
    }

    let format = date.slice(4, 8) + date.slice(2, 4) + date.slice(0, 2);
    return format

  }
  formatDateRev(date: string) {
    if (date.indexOf('/') >= 0) return date
    if (date.indexOf('-') >= 0) return date
    try {
      if (parseInt(date.slice(0, 4)) > 1300) {
        return `${date.substring(6, 8)}/${date.substring(4, 6)}/${date.substring(0, 4)}`
      }
      else {
        return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 8)}`
      }

    } catch (error) {
      console.log('ERRO', error)
      return `-${date}-`
    }
  }
  ordernar(campo, dado: Array<any>, orderDesc?: boolean) {
    dado.sort((a, b) => {
      if (typeof a[campo] == 'string') {
        let A = parseInt(a[campo])
        let B = parseInt(b[campo])
        return orderDesc ? B - A : A - B
      }
      if (typeof a[campo] == 'number') {
        let A = a[campo]
        let B = b[campo]
        return orderDesc ? B - A : A - B
      }
    })
  }
  ordernarCriadoEm(dado: Array<any>,orderDesc?: boolean) {
    dado.sort((a, b) => { 
      let A = Number(a.criadoEm)
      let B = Number(b.criadoEm)
      return orderDesc ? B - A : A - B
    })
  }
}
