import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { FichaAssinante } from './FichaAssinante.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MensagemService } from '../mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class FichaDeAssinanteService {
  urlFichaAssinante = `${new environment().api}FichaDeAssinante`

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
  ) { }

  async buscarAssinante({ cpf }: { cpf: string }): Promise<FichaAssinante> {
    let assinante: FichaAssinante;
    await this.http.get<FichaAssinante[]>(`${this.urlFichaAssinante}/Filtrocpf?cpf=${cpf}`, { observe: 'response' }).toPromise()
      .then(res => assinante = res.body.pop())
      .catch((err: HttpErrorResponse) => {
        if(err.status === 400) this.mensagem.creat(`Este cpf não possui assinatura`, {aviso:true})
        else{this.mensagem.creat(`Ocorreu um erro ao buscar assinante (${err.status}:${err.statusText}): ${err.message}`, {erro:true})}
      })
    return assinante;
  }

  async listarAssinantes(): Promise<FichaAssinante[]> {
    let assinantes: FichaAssinante[] = [];
    await this.http.get<FichaAssinante[]>(`${this.urlFichaAssinante}`, { observe: 'response' }).toPromise()
      .then(res => assinantes = res.body)
      .catch((err: HttpErrorResponse) => {
        if(err.status === 400) { this.mensagem.creat('Não existem assinantes cadastrados no sistema', {aviso:true}) }
        else {this.mensagem.creat(`Ocorreu ao listar assinantes (${err.status}:${err.statusText}): ${err.message}`, {erro:true})}
      })
    return assinantes
  }

  async criarAssinante(assinante:FichaAssinante){
    this.http.post(this.urlFichaAssinante, assinante, {observe:'body'}).toPromise()
    .then(() => console.log('Assinante criado com sucesso'))
    .catch((err:HttpErrorResponse) => console.error(`Não foi possível criar o assinante ${err.status}: ${err.message}`))
  }
}
