import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitar-retorno',
  templateUrl: './solicitar-retorno.component.html',
  styleUrls: ['./solicitar-retorno.component.scss']
})
export class SolicitarRetornoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
