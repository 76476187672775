import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MensagemService } from '../mensagem.service';
import { element } from 'protractor';
import { BuscarDataService } from '../buscar-data.service';
import { ConteudoDeDadosService } from '../conteudo-de-dados.service';
import { CabecalhoFichaConsultaMedica, ResponseFicha, itemFichaConsultaMedica, ConteudoFichaConsultaMedica, SolicitacaoDeExames, ReceituarioBranco, ReceituarioEspecial, IndicacaoTratamentos, IndicacaoCirurgia, ProcedimentoAdicional, AtestadoAfastamento, AtestadoMedico, AtestadoAcompanhante, EncaminhamentoMedico, SolicitacaoRetorno, Receituario, Medicamentos, ListaDeTratamentosPaciente, ClassificacaoDeReceituarios } from './model';
import { TicketConsultaJOIN } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser

@Injectable({
  providedIn: 'root'
})
export class FichaConsultaMedicaService {
  url = new environment().api
  url_local = new environment().api_local
  api = 'CabecalhoFichaConsultaMedica/';
  api_item = 'ItemFichaConsultaMedica/'
  api_conteudo = 'ConteudoFichaDeConsultaMedica/'
  api_solicitacaoExame = 'SolicitacaoDeExames/'
  api_receituarioBranco = 'ReceituarioBranco/'
  api_receituarioEspecial = 'ReceituarioEspecial/'
  api_indicacaoTratamentos = 'IndicacaoTratamento/'
  api_IndicacaoCirurgia = 'IndicacaoCirurgia/'
  api_procedimentoAdicional = 'ProcedimentoAdicional/'
  api_atestadoAfastamento = 'AtestadoAfastamento/'
  api_atestadoAcompanhante = 'AtestadoAcompanhante/'
  api_atestadoMedico = 'AtestadoMedico/'
  api_encaminhamentoMedico = 'EncaminhamentoMedico/'
  api_solicitacaoRetorno = 'SolicitacaoRetorno/'

  codigoEmpresa = sessao.codigoEmpresa

  constructor(private http: HttpClient, private mensagem: MensagemService, private getData: BuscarDataService, private dados: ConteudoDeDadosService) { }

  start(registro: TicketConsultaJOIN, cpf_medico) {

    if (!this.codigoEmpresa) { this.error(); return }
    let dados = new CabecalhoFichaConsultaMedica
    dados.criadoPor = cpf_medico
    dados.nrDocRef = registro.nrDoc
    dados.tipoNrDocRef = 'TICCON'
    dados.status = 'SDO0046'
    dados.codigoEmpresa = this.codigoEmpresa
    return this.http.post(this.url + this.api, dados)
  }
  async registrar(registro: any[], numeroDocumento, subItem?): Promise<ResponseFicha> {
    let item: itemFichaConsultaMedica[] = []
    let resp = new ResponseFicha
    await this.getItensFichaConsultaMedica().toPromise()
      .then(
        async (reg: Array<itemFichaConsultaMedica>) => {

          let verific = reg.filter((r) => { return r.nrDoc == numeroDocumento })

          if (verific.length > 0) {
            await verific.forEach(e => {
              this.excluir(e.id).toPromise().then(() => { resp.status = true }).catch((r) => {
                resp.status = false
                resp.response = 'Falha no fluxo de item do  documento'
              })
            });
          }
          await registro.forEach((e, i) => {
            let dado = new itemFichaConsultaMedica
            dado.codigoCaracteristica = e.codigoCaracteristica
            dado.nrDoc = numeroDocumento
            dado.status = "Ativo"
            dado.item = i + 1
            dado.codigoEmpresa = sessao.codigoEmpresa
            this.item(dado).toPromise().then(() => { resp.status = true }).catch((r) => {
              resp.status = false
              resp.response = 'Falha ao registrar item '
            })
          });
          await this.getConteudoFichaConsultaMedica().toPromise()
            .then(
              async (reg: Array<ConteudoFichaConsultaMedica>) => {
                let verific = reg.filter((r) => { return r.nrDoc == numeroDocumento })
                if (verific.length > 0) {
                  await verific.forEach(e => {
                    this.excluir(e.id, true).toPromise().then(() => { resp.status = true }).catch((r) => {
                      resp.status = false
                      resp.response = 'Falha no fluxo de conteudo do documento'
                    })
                  });
                }
                await registro.forEach((e, i) => {
                  let dado = new ConteudoFichaConsultaMedica
                  dado.codigoDeReferencia = e.codigoCaracteristica
                  dado.nrDoc = numeroDocumento
                  dado.subItem = '1'
                  dado.criadoEm = new Date().toISOString()
                  dado.criadoPor = sessao.cpf
                  dado.status = 'SDA0001'
                  if (subItem) {
                    dado.valor = null
                    dado.subItem = JSON.stringify(i + 1)
                  } else {
                    dado.valor = e.valor
                  }
                  dado.criadoEm = this.getData.horaAgora(true)

                  dado.item = i + 1
                  this.conteudo(dado).toPromise().then(() => { resp.status = true }).catch((r) => {
                    resp.status = false
                    resp.response = 'Falha ao registrar conteudo'
                  })
                });

              }
            )

        }
      )
    return resp

  }
  item(item: itemFichaConsultaMedica) {
    console.log(this.url + this.api_item, item)
    item.criadoEm = new Date().toISOString()
    item.criadoPor = sessao.cpf
    return this.http.post(this.url + this.api_item, item)
  }
  conteudo(conteudo: ConteudoFichaConsultaMedica) {
    console.log('Conteudo', this.url + this.api_conteudo, conteudo)
    return this.http.post(this.url + this.api_conteudo, conteudo)
  }

  async postSolicitacaoDeExames(registro: SolicitacaoDeExames) {
    let resp = new ResponseFicha
    await this.http.get<SolicitacaoDeExames[]>(this.url + this.api_solicitacaoExame).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_solicitacaoExame + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_solicitacaoExame, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }

  async postReceituarioBranco(registro: ReceituarioBranco) {
    let resp = new ResponseFicha
    await this.http.get<ReceituarioBranco[]>(this.url + this.api_receituarioBranco).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_receituarioBranco + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_receituarioBranco, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  getReceitaBranca() {
    return this.http.get<ReceituarioBranco[]>(this.url + this.api_receituarioBranco)
  }
  async postReceituarioEspecial(registro: ReceituarioEspecial) {
    let resp = new ResponseFicha
    await this.http.get<ReceituarioEspecial[]>(this.url + this.api_receituarioEspecial).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_receituarioEspecial + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_receituarioEspecial, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  getReceituarioEspecial() {
    return this.http.get<ReceituarioEspecial[]>(this.url + this.api_receituarioEspecial)
  }
  async postIndicacaoTratamentos(registro: IndicacaoTratamentos) {
    let resp = new ResponseFicha
    await this.http.get<IndicacaoTratamentos[]>(this.url + this.api_indicacaoTratamentos).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_indicacaoTratamentos + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_indicacaoTratamentos, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postIndicacaoCirurgia(registro: IndicacaoCirurgia) {
    let resp = new ResponseFicha
    await this.http.get<IndicacaoCirurgia[]>(this.url + this.api_IndicacaoCirurgia).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_IndicacaoCirurgia + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_IndicacaoCirurgia, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postProcedimentoAdicional(registro: ProcedimentoAdicional) {
    let resp = new ResponseFicha
    await this.http.get<ProcedimentoAdicional[]>(this.url + this.api_procedimentoAdicional).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_procedimentoAdicional + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_procedimentoAdicional, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postAtestadoAfastamento(registro: AtestadoAfastamento) {
    let resp = new ResponseFicha
    await this.http.get<AtestadoAfastamento[]>(this.url + this.api_atestadoAfastamento).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_atestadoAfastamento + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          console.log('ITENS > >> > ', registro)
          await this.http.post(this.url + this.api_atestadoAfastamento, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postAtestadoMedico(registro: AtestadoMedico) {
    let resp = new ResponseFicha
    await this.http.get<AtestadoMedico[]>(this.url + this.api_atestadoMedico).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_atestadoMedico + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_atestadoMedico, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postAtestadoAcompanhante(registro: AtestadoAcompanhante) {
    let resp = new ResponseFicha
    console.log('REGISTRO > ', registro)
    await this.http.get<AtestadoAcompanhante[]>(this.url + this.api_atestadoAcompanhante).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_atestadoAcompanhante + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_atestadoAcompanhante, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postEncaminhamentoMedico(registro: EncaminhamentoMedico) {
    let resp = new ResponseFicha
    console.log('REGISTRO > ', registro)
    await this.http.get<EncaminhamentoMedico[]>(this.url + this.api_encaminhamentoMedico).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_encaminhamentoMedico + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_encaminhamentoMedico, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }
  async postSolicitacaoRetorno(registro: SolicitacaoRetorno) {
    let resp = new ResponseFicha
    console.log('REGISTRO > ', registro)
    await this.http.get<SolicitacaoRetorno[]>(this.url + this.api_solicitacaoRetorno).toPromise()
      .then(
        async (r) => {
          let doc = registro.numeroFichaConsulta
          let itens = r.filter((r) => { return r.numeroFichaConsulta == doc })
          if (itens.length > 0) {
            await itens.forEach(async e => {
              await this.http.delete(this.url + this.api_solicitacaoRetorno + e.id).toPromise()
                .then(() => { resp.status = true })
                .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
            });
          }
          await this.http.post(this.url + this.api_solicitacaoRetorno, registro).toPromise()
            .then(() => { resp.status = true })
            .catch(() => { resp.status = false; resp.response = "Falha no fluxo de conteudo do documento" })
        }
      )
    return resp
  }

  excluir(id: string, conteudo?) {
    if (conteudo) {
      return this.http.delete(this.url + this.api_conteudo + id)
    }
    return this.http.delete(this.url + this.api_item + id)
  }

  getItensFichaConsultaMedica() {
    return this.http.get(this.url + this.api_item)
  }

  getConteudoFichaConsultaMedica() {
    return this.http.get(this.url + this.api_conteudo)
  }

  getReceituario() {
    return this.http.get<Receituario[]>(this.url + 'ClassificacaoDeReceituarios/')
  }

  getCID() {
    return this.dados.get('CID')
  }

  getExames() {
    return this.dados.get('EXAMES')
  }
  getMedicamentos() {
    return this.http.get<Medicamentos[]>(this.url + 'CadastroMedicamentos/')
  }
  getTratamentos() {
    return this.http.get<ListaDeTratamentosPaciente[]>(this.url + 'ListaDeTratamentosPaciente/')
  }
  getClassificacaoDeReceituarios() {
    return this.http.get<ClassificacaoDeReceituarios[]>(this.url + 'ClassificacaoDeReceituarios/')
  }

  error() {
    this.mensagem.creat('Não foi possivel realizar operação', { erro: true })
  }
}
