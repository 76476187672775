import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CirurgiasPaciente, FraturasPaciente, InternacaoPaciente, SequelasPaciente } from 'src/app/atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/model';
import { SessaoUser } from 'src/app/user.reg';
import { environment } from 'src/environments/environment';
import { REQUEST } from '../modal';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class DadosSaudePacienteService {
  url = new environment().api
  sequelas = 'SequelasPaciente/'
  fraturas = 'FraturasPaciente/'
  internacao = 'https://www.myclinics.com.br/webservice/api/InternacaoPaciente/'
  cirurgia = 'https://www.myclinics.com.br/webservice/api/CirurgiasPaciente/'
  constructor(private http: HttpClient) { }

  getSequenas() {
    return this.http.get<SequelasPaciente[]>(this.url + this.sequelas)
  }
  postSequenas(register: SequelasPaciente) {
    return this.http.post(this.url + this.sequelas, register)
  }
  putSequenas(register: SequelasPaciente) {
    return this.http.put(`${this.url + this.sequelas + register.id}/${sessao.sessao_id}/${new Date().toISOString()}`, register)
  }

  getFraturas() {
    return this.http.get<FraturasPaciente[]>(this.url + this.fraturas)
  }
  postFraturas(register: FraturasPaciente) {
    return this.http.post(this.url + this.fraturas, register)
  }
  putFraturas(register: FraturasPaciente) {
    return this.http.put(`${this.url + this.fraturas + register.id}/${sessao.sessao_id}/${new Date().toISOString()}`, register)
  }

  getCirurgia(cpf?: string) {
    let param
    if (cpf) param = new HttpParams().append('filtrarCpf', cpf)
    return this.http.get<REQUEST<CirurgiasPaciente>>(this.cirurgia, { params: param })
  }
  postCirurgia(register: CirurgiasPaciente) {
    return this.http.post(this.cirurgia, register)
  }
  putCirurgia(register: CirurgiasPaciente) {
    return this.http.put(`${this.cirurgia}${sessao.sessao_id}/${new Date().toISOString()}`, register)
  }

  getInternacao(cpf?: string) {
    let param
    if (cpf) param = new HttpParams().append('filtrarCpf', cpf)
    return this.http.get<REQUEST<InternacaoPaciente>>(this.internacao, { params: param })
  }
  postInternacao(register: InternacaoPaciente) {
    return this.http.post(this.cirurgia, register)
  }
  putInternacao(register: InternacaoPaciente) {
    return this.http.put(`${this.internacao}${sessao.sessao_id}/${new Date().toISOString()}`, register)
  }


}
