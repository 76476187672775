import { Component, OnInit, ViewChild } from '@angular/core';
import { SenhaAtendimentoService } from '../../service/senha-atendimento.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChekinPacienteForm } from './model';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../service/usuario.service';
import { CountdownComponent } from 'ngx-countdown';
import { CountupTimerService } from 'ngx-timer';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwalPortalComponent } from '@sweetalert2/ngx-sweetalert2/lib/swal-portal.component';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { LogAtendimentoService } from 'src/app/service/log-atendimento.service';
import { TransitionCheckState } from '@angular/material';
import { formLogAtendimento } from '../model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FormUsuario } from 'src/app/usuario/model';
import { async } from 'rxjs/internal/scheduler/async';


@Component({
  selector: 'app-chekin-paciente',
  templateUrl: './chekin-paciente.component.html',
  styleUrls: ['./chekin-paciente.component.scss']
})
export class ChekinPacienteComponent implements OnInit {
  // @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  // @ViewChild('ChamarPaciente',{static : true}) swal : SwalComponent
  //   gerarSenha = false
  //   id : string
  //   companyId
  //   senha: any
  //   form = new ChekinPacienteForm
  //   regCheckin: ChekinPacienteForm[];
  //   erro: boolean;
  //   erroMensagem: string;
  //   loading
  //   logAtendimento = new formLogAtendimento
  //   teste: any;
  //   constructor(
  //     private service: SenhaAtendimentoService,
  //     private serviceUsuario: UsuarioService,
  //     private serviceLog: LogAtendimentoService,
  //     private _info: MatSnackBar,
  //     private url: ActivatedRoute,
  //     private time: CountupTimerService,
  //     private spinner: NgxSpinnerService,
  //     private nav : Router,
  //     private data : BuscarDataService
  //   ) {
  //     this.urlID()
  //     this.time.startTimer()
  //     this.companyId = localStorage.getItem('companyId')
  //   }

  async ngOnInit() {
    // this.spinner.show();
    // this.erro = true
    // this.loading = true
    // await this.buscarId().then(
    //   () => {
    //     this.buscarRegistro(this.companyId)
    //     setInterval(() => this.buscarRegistro(this.companyId), 1000)
    //   }
    // )
    // this.loading = false

  }
  //   async abrirRecepcao(senha: string , dados) {
  //     console.log('teste :'+JSON.stringify(dados))
  //     console.log('Senha : '+this.senha)
  //    await localStorage.setItem('senha',senha)
  //     setTimeout(async()=>{
  //       await this.nav.navigateByUrl(`home/recepcao/${this.id}`).then(
  //         ()=> this.registrarLogAtendimento()
  //       )
  //     },1000)
  //     this.removerSenha(dados.id, dados)

  //   }

  //   registrarLogAtendimento(){
  //     this.logAtendimento.usuarioId = this.id
  //     this.logAtendimento.companyId = this.companyId
  //     this.logAtendimento.data = this.data.hoje()
  //     this.logAtendimento.senha = this.senha
  //     this.logAtendimento.timeCheckin = this.data.horaAgora()
  //     this.serviceLog.cadastrarlogAtendimento(this.logAtendimento).toPromise()
  //     .then(
  //       ()=> {}
  //     )
  //   }
  //   removerSenha(id, dados){
  //       dados.ativo = false
  //       this.service.alterarCheckinPaciente(id,dados).toPromise()
  //       .then(
  //         ()=> console.log('removido com sucesso!')
  //       ).catch(
  //         ()=> console.log('ERRO!!')
  //       )
  //   }
  //   verificar(){
  //     if(this.form.motivo >= 4){
  //       this.form.prioridade = 1
  //   }else{
  //     this.form.prioridade = 2
  //   }
  //   }

  //   urlID() {
  //     this.url.url.subscribe(async reg => {
  //       console.log('Filtro :' + reg[1].path)
  //       this.id = reg[1].path
  //     });
  //   }
  //   async buscarId() {
  //     if(this.companyId) return
  //     await this.serviceUsuario.listar().toPromise()
  //       .then(
  //       async  (dados: Array<FormUsuario>) => {
  //           console.log('SEU ID : ' + this.id)
  //           let id = this.id.toUpperCase()
  //           let filtro = await dados.filter(function(reg : FormUsuario){
  //             return reg.usuarioId.toUpperCase() ==  id
  //           })
  //           let reg = filtro[0]
  //           this.companyId = reg.companyId
  //           console.log('Company ID @ : ' + this.companyId )
  //           this.erro = false
  //         }
  //       )
  //       .catch(
  //         () => {
  //           this.erro = true
  //           this.erroMensagem = "Não foi possível localizar registro"
  //         }
  //       )
  //   }
  //   async buscarRegistro(companyId?) {

  //     // console.log('companyID : ' + companyId)
  //     if(!this.companyId) this.buscarId()
  //     else this.erro = false
  //     this.service.listarCheckinPaciente().toPromise()
  //       .then(
  //         async (dados: ChekinPacienteForm[]) => {
  //           let filtro = await dados
  //             .filter(function (reg: ChekinPacienteForm) {
  //               // console.log(reg.companyId +' --- '+ companyId)
  //               return reg.companyId == companyId && reg.ativo == true
  //             })
  //           let orderna = await filtro.sort(function (a: ChekinPacienteForm, b: ChekinPacienteForm) {
  //             return a.senha - b.senha
  //             // return a.senha - b.senha && parseInt(a.hora) - parseInt(b.hora)
  //           })
  //           this.regCheckin = orderna
  //           this.senha = this.regCheckin.slice().length
  //           // console.log('Registro : ' + companyId)

  //         }
  //       )
  //   }
  //   cadastrar() {
  //     let i = this.regCheckin.length -1
  //     this.form.senha = parseInt(this.regCheckin[i].senha) + 1
  //     this.form.prioridade = this.form.atendimento >= 4 ? 1 : 0
  //     this.form.usuarioId = this.id
  //     this.form.companyId = this.companyId
  //     let reg = JSON.stringify(this.form)
  //     this.service.cadastrarCheckinPaciente(JSON.parse(reg)).toPromise()
  //       .then(
  //         () => { this.info(), this.buscarRegistro(this.companyId) }
  //       )
  //   }
  //   info() {
  //     this._info.open('Salvo com sucesso', '', {
  //       duration: 2000
  //     })
  //   }

}
