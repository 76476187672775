export class DadosControleSenhaAtendimento {
    id?: string;
    codigoEmpresa?: string;
    frequenciaReinicializacaoDias?: number;
    dataUltimaReinicializacao?: string;
    status?: string;
    timeStamp?: string;
    ultimaSenhaGerada?: number;
}

export class DadosControleSenhaAtendimentoSelect {
    dadosSelect?: string
}