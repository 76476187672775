export type TypeProntuario =
   'pressao_arterial' |
   'temperatura' |
   'frequencia_cardiaca' |
   'frequencia_respiratoria' |
   'saturacao' |
   'glicemia' |
   'peso' |
   'cintura' |
   'altura' |
   'tipoSanguineo' |
   '';

export class SequelasPaciente {
   id: string
   lesao: string
   cpfPaciente: string
   cpfMedico: string
   data: string
   observacao: string
   criadoPor: string
   criadoEm: string
   status: string
}
export class FraturasPaciente {
   id: string
   fraturas: string
   cpfPaciente: string
   cpfMedico: string
   data: string
   observacao: string
   criadoPor: string
   criadoEm: string
   status: string
}
export class CirurgiasPaciente {
   cpfPaciente: string
   cirurgia: string
   motivo: string
   cid: string
   data: string
   id: string
   status: string
   criadoPor: string
   criadoEm: string
}
export class InternacaoPaciente {
   id: string
   hospital: string
   motivo: string
   periodo: string
   entrada: string
   saida: string
   cpfPaciente: string
   status: string
   criadoPor: string
   criadoEm: string
}