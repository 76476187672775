export class SalaEsperaForm {
    id: string
    usuarioId: string
    pacienteId: string
    codigoEmpresa: number
    senha: string
    paciente: string
    cpf: string
    cpfMedico: string
    hora: string
    tipoConsulta: number
    prioridade: number
    pagamento: string
    status: string
    idade: string
    tipo: number
    processo: number
    createdAt: any
    ultAvaliacao: string
    primeiraConsulta: boolean
    fimAtendimento: string
    ativo: boolean
    companyId?: string
    time?: string
    empresa?: string
    nomePaciente?: string
    nomeMedico?: string
    cpfPaciente?: string
    

}
export class ConsultaForm {
    id: string
    companyId: string
    usuarioId: string
    pacienteId: string
    dataConsulta: string
    paciente: string
    sintomas: string
    diagnostico: string
    exames: string
    condutaTerapeutica: string
    status: string
    ativo: true
}
export class videoChamadaFrom {
    id?: string
    codigoUnico: string
    remetente: string
    destinatario: string
    transacao: string
    data: string
    status: string
}