import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PosicaoDeArmazenamentoService {
    url = new environment().api
    apiPosicaoDeArmazenamento = "PosicaoDeArmazenamento/"

    constructor(private http: HttpClient) {

    }

    getPosicaoDeArmazenamentoCodigoLocalDeArmazenamento(codigoLocalArmazenamento): Observable<Object> {
        return this.http.get(this.url + this.apiPosicaoDeArmazenamento + "FiltrarCodigoLocalDeArmazenamento/" + codigoLocalArmazenamento)
    }

    postPosicaoDeArmazenamento(dados: any): Observable<Object> {
        return this.http.post(this.url + this.apiPosicaoDeArmazenamento, dados)
    }

    getPosicaoDeArmazenamento(): Observable<Object> {
        return this.http.get(this.url + this.apiPosicaoDeArmazenamento)
    }

    getPosicaoDeArmazenamentoID(id: any): Observable<Object> {
        return this.http.get(this.url + this.apiPosicaoDeArmazenamento + id)
    }

    PutPosicaoDeArmazenamento(id: any, userId: any, data: any, dados): Observable<Object> {
        return this.http.put(`${this.url}${this.apiPosicaoDeArmazenamento}${id}/${userId}/${data}`, dados)
    }

}


