import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { TabelaMenu } from './model';
import { CadastrarService } from '../service/tabela-menu.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { MensagemService } from '../service/mensagem.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { ConteudoDeDados } from '../service/status-sistema.service';
@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss']
})
@Pipe({ name: 'groupBy' })

export class TabelaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  adicionar
  status = true
  registro: TabelaMenu[]
  add = new TabelaMenu
  documento: TabelaMenu[]
  tipoDocumento: TabelaMenu[]
  classDocumento: TabelaMenu[]
  statusDocumento: TabelaMenu[]
  tipoAcao: TabelaMenu[];
  tipoObjeto = []
  celular: boolean
  selecionado
  check: boolean
  usE_YN   //Campo Pai
  value = true
  deleteID
  loading
  statusId: number;
  setupSelect
  displayedColumns: string[] = ['select', 'parent_MenuID', 'menuID', 'menuName', 'tipoObjeto', 'ordem', 'status'];
  dataSource: MatTableDataSource<TabelaMenu>;
  dataSourceDelete: MatTableDataSource<TabelaMenu>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  backup: TabelaMenu[];
  confimBackup: boolean = false;
  confDelete: boolean = false;
  filtroDelte: TabelaMenu[];
  registroIcon: TabelaMenu;
  galeriaIcon: boolean;
  indexChecked: any;
  reset: boolean;
  detalhe = false
  alterar = false
  novo: boolean;
  viewSelecionado: string;
  excluir: boolean;
  select_ERPPaciente: boolean; //Solução temporária para campo ERP_paciente - POG
  select_ERPBusiness: boolean; //Solução temporária para campo ERP_Business
  constructor(
    private service: CadastrarService,
     private mensagem: ToastrService,
      private msn: MensagemService,
      private _conteudo : ConteudoDeDadosService) {

  }
  ngOnInit() {
    this.listar();
    this.setupSelect = [
      { nome: 'SetupINI', ref: 0 },
      { nome: 'APP', ref: 1 },
      { nome: 'ERP', ref: 2 },
      { nome: 'Portal', ref: 3 },
      { nome: 'Medico', ref: 4 },
      { nome: 'Paciente', ref: 5 },
    ]
    this.getConteudo()
  }
  async getConteudo(){
    this.tipoObjeto = await this._conteudo.get('ITMPERFIL')
   
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  selecionar(check, index, dados) {
    this.viewSelecionado = dados.menuID
    if (check) {
      this.value = check
      this.indexChecked = index
      this.selecionado = dados
    }
    else {
      this.viewSelecionado = null
      this.value = false
      this.selecionado = null
    }
  }
  async Backup() {
    this.backup = this.registro
    await localStorage.setItem('backup', JSON.stringify(this.backup))
    let ob = JSON.parse(localStorage.getItem('backup'))
    console.log(ob.length)
    if (ob.length >= 200) {
      alert('Backup concluido')
      this.confimBackup = true
    }
    if (localStorage.getItem('backup')) {
      let b = JSON.parse(localStorage.getItem('backup'))
      console.log(b.length)
    }

  }
  // async deleteALL() {
  //   let t = this.registro.filter(function (reg: TabelaMenu) {
  //     return reg.erp != "true" && reg.parent_MenuID != "*"
  //   })
  //   console.log('Items para deletar : ' + t.length)
  //   this.filtroDelte = t
  //   this.loading = true
  //   this.dataSourceDelete = new MatTableDataSource(t)
  //   this.loading = false
  //   this.confDelete = true
  // }
  async ConfDelete() {
    for (let i = 0; i <= this.filtroDelte.length; i++) {
      console.log(i + ' Deletando , id : ' + this.filtroDelte[i].menuIdentity)
      this.service.excluir(this.filtroDelte[i].menuIdentity).toPromise()
        .then(
          () => this.mensagem.success(this.filtroDelte[i].menuIdentity + ' Deletado !')
        )
        .catch(
          () => this.mensagem.error(this.filtroDelte[i].menuIdentity + ' Erro !')
        )

    }
  }
  full() {
    window.open('http://smartitsystems-001-site6.gtempurl.com/#/tabela-menu')
  }
  app() {
    let app = this.registro.filter(function (reg) {
      return reg.app == "true"
    })
    this.dataSource = new MatTableDataSource(app);
  }
  applyFilterPai(filterValue: string) {
    this.dataSource.filter = filterValue
    console.log('teste : ' + filterValue.trim().toLowerCase())
  }
  abrirGaleria(reg: TabelaMenu) {
    this.galeriaIcon = true
    this.registroIcon = reg
  }
  async listar(ref?) {
    this.loading = true
    this.service.listar().toPromise().then(
      async (registro: TabelaMenu[]) => {
        try{
          await this.service.listarDocumento().toPromise().then((ok: TabelaMenu[]) => this.documento = ok);
          await this.service.listarTipoDocumento().toPromise().then((ok: TabelaMenu[]) => this.tipoDocumento = ok);
          await this.service.listarClassDocumento().toPromise().then((ok: TabelaMenu[]) => this.classDocumento = ok);
          // await this.service.listaStatusDocumento().toPromise().then((ok: TabelaMenu[]) => this.statusDocumento = ok.filter(value=>value.));
          await this.service.listaTipoAcao().toPromise().then((ok: TabelaMenu[]) => this.tipoAcao = ok);
        }catch{

        }
     
        this.loading = false
        registro.forEach((r, index) => {
          registro[index].checkbox = false
        });
        this.registro = registro
        this.dataSource = new MatTableDataSource(this.registro);
        this.dataSource.paginator = this.paginator
        const source = from(this.registro)
        this.dataSource.sort = this.sort
      }
    ).catch(
      erro => {
        this.mensagem.error('Erro ao carregar Tabela')
        console.log(JSON.stringify(erro))
      }
    )

  }
  teste() {
    console.log(this.sort)
  }
  addNovo() {

    let dados = [
      { descricao: "Aceite" }
    ]
    dados.forEach(reg => {
      console.log('TipoDocumento/' + reg.descricao)
      this.service.cadastrar('TipoDocumento/', reg).toPromise()
        .then(
          (ok: any) => this.mensagem.success('Cadastrado : ' + ok.descricao)
        )
    });
  }

  cadastrar(dados: TabelaMenu) {
    dados.erp_business = this.select_ERPBusiness, //Solução temporária para campo ERP_Business
      console.log("ERPBUSINESS")
    console.log(this.select_ERPBusiness)
    dados.erp_paciente = this.select_ERPPaciente, ////Solução temporária para campo ERP_Paciente
      delete dados.checkbox
    if (dados.menuIdentity) {
      this.registrar(dados, dados.menuIdentity)
      return
    }
    delete dados.menuIdentity
    console.log(dados)
    this.service.adicionar(dados).subscribe(
      ok => {
        this.msn.creat('Cadastrado com sucesso', { sucesso: true })
        this.selecionado = new TabelaMenu,
          this.listar(),
          this.detalhe = false,
          this.removeSelect()
      },
      erro => this.mensagem.error('Erro ao registrar alteração!')
    )
  }
  adicionarRegistro() {
    this.msn.clear()
    this.detalhe = true
    this.novo = true
    this.alterar = true

    if (this.selecionado) {
      this.excluir = false
      this.selecionado = this.selecionado
      this.selecionado.menuIdentity = null
      return
    }
    else {
      this.selecionado = new TabelaMenu
    }
  }

  removeSelect(reg?) {
    this.registro.forEach((r, i) => {
      this.registro[i].checkbox = false
    });
    this.viewSelecionado = null
    this.alterar = false
    this.excluir = false
    // this.indexChecked = null
    this.selecionado = null;
    this.value = false;
  }

  registrar(registro: TabelaMenu, id: number) {
    // this.statusId = id
    console.log(JSON.stringify(registro) + ' -- ' + id)
    this.service.editar(registro, id).toPromise().then(
      ok => {
        this.msn.creat('Alterado com sucesso', { sucesso: true })
        this.detalhe = false
        this.statusId = 0
        this.removeSelect()
      }
    ).catch(
      erro => {
        console.log('erro' + erro)
        this.status = false
        this.mensagem.error('Erro ao registrar alteração!')
      }
    )

  }

  deletar(id: number) {
    this.service.excluir(id).subscribe(
      ok => {
        this.detalhe = false
        this.statusId = 0
        this.listar()
        this.removeSelect()
      },
      erro => console.log('erro : ' + erro)
    )
  }
}
