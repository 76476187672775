import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { FormulariosService } from 'src/app/service/formularios.service';
import { FormDependente } from '../model';
import { UsuarioDependenteService, UsuarioQuadroDeSaudeService, UsuarioService } from 'src/app/service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { UsuarioComponent } from '../usuario.component';
import { FormPaciente } from 'src/app/cadastro-paciente/model';

@Component({
  selector: 'app-dependentes',
  templateUrl: './dependentes.component.html',
  styleUrls: ['./dependentes.component.scss']
})
export class DependentesComponent implements OnInit {
  @Input() registro: FormPaciente
  @Input() usuarioId: string
  @Input() companyId: string
  @Output() confirm = new EventEmitter()
  id
  idCompany
  loading: boolean;
  carregarForm = false
  estruturaFormularioDependente = [];
  estruturaFormularioQuadroSaude = []
  dadosDependente: FormDependente[] = []

  registroQuadroSaude: any = {}
  Formulario: FormGroup
  FormularioDependente: FormGroup;

  //Validar leyout
  Registro: Boolean
  Deficiencia: boolean;
  Alergia: boolean;
  Habitos: boolean;
  HistoricoDoenca: boolean;
  Tratamento: boolean;
  Exame: boolean;
  Periodicas: boolean;
  Medicacao: boolean;
  HistoricoInternacao: boolean;
  SinaisVitais: boolean;
  DoencasFamilia: boolean;
  Lesoes: boolean;
  Cirurgias: boolean;



  //temporarios
  tempDeficiencia: any = {}
  tempAlergia: any = {}
  tempHabitos: any = {}
  tempMedicacao: any = {}
  tempHistoricoDoenca: any = {}
  tempTratamento: any = {}
  tempExame: any = {}
  tempPeriodicas: any = {}
  tempHistoricoInternacao: any = {}
  tempSinaisVitais: any = {}
  tempDoencasFamilia: any = {}
  tempLesoes: any = {}
  tempCirurgias: any = {}

  //Forms
  formDeficiencia: FormGroup;
  formAlergia: FormGroup;
  formHabito: FormGroup
  formMedicacao: FormGroup;
  formDoenca: FormGroup;
  formTratamento: FormGroup;
  formExame: FormGroup;
  formPeriodicas: FormGroup;
  formInternacao: FormGroup;
  formSinaisVitais: FormGroup;
  formDoencasFamilia: FormGroup;
  formLesoes: FormGroup;
  formCirurgias: FormGroup;



  constructor(
    private form: FormBuilder,
    private formularios: FormulariosService,
    private serviceDependente: UsuarioDependenteService,
    private serviceQuadroDeSaude: UsuarioQuadroDeSaudeService,
    private mensagem: ToastrService,
    public UsuarioService: UsuarioService) {
    this.estruturaFormularioDependente = formularios.formularioDependente()
    this.estruturaFormularioQuadroSaude = formularios.formularioFichaMedica();
  }

  async ngOnInit() {

    await this.createFormulario()
    this.carregarForm = true
    this.createFormularios(this.companyId)
    if (this.registro) { await this.buscarQuadroSaude() }

  }
  ngOnDestroy(){
    this.confirm.emit(true)
  }


  async cadastrarDependente() {
    this.loading = true
    let dia = this.registro.nascimento.substr(0, 2)
    let mes = this.registro.nascimento.substr(3, 2)
    let ano = this.registro.nascimento.substr(6, 4)
    let nascimento = `${ano}-${mes}-${dia}`
    if (this.registro.id) {
      this.alterarDependente(this.registro.id)
    }
    else {
      this.serviceDependente.cadastrarDependente(this.FormularioDependente.value).subscribe(
        async (ok: FormDependente) => {
          // await this.abrirCadastro.ngOnDestroy()
          this.mensagem.success('Cadastrado com sucesso')
          this.loading = false
          this.confirm.emit(true)
        },
        erro => {
          this.mensagem.error('Não foi possível alterar usuario')
          this.loading = false
        }
      )
    }
  }
  async alterarDependente(id) {
    this.loading = true
    var registro = this.registro
    // Object.assign(registro, { id: id })
    this.serviceDependente.alterarDependente(id, registro).subscribe(
      async ok => {
        // await this.abrirCadastro.ngOnDestroy()
        this.mensagem.success('Usuario ' + this.registro.nome + ' Alterado com sucesso')
        this.loading = false

      },
      erro => {
        this.mensagem.error('Não foi possível alterar usuario')
        this.loading = false
        //console.log('Erro!' + JSON.stringify(erro))
      }
    )
  }
  public async buscarQuadroSaude() {
    this.loading = true
    //Limpar dados 
    // this.registroQuadroSaude = {}
    this.Habitos = false
    this.HistoricoDoenca = false
    //END
    try {
      if (!this.registro.id) return
      await this.serviceQuadroDeSaude.listarDeficiencia().toPromise().then(
        async (dados: Array<any>) => {
          var companyId = this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            //console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + companyId)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.Deficiencia = true
            this.registroQuadroSaude.deficiencia = await filtro
          }
        }
      )

      await this.serviceQuadroDeSaude.listarAlergia().toPromise()
        .then(
          (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('2 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {

              this.Alergia = true
              this.registroQuadroSaude.alergia = filtro
              //console.log('Filtrado com sucesso ! ' + filtro.length)
            }
          })

      await this.serviceQuadroDeSaude.listarHabito().toPromise()
        .then(
          (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('1 - reg.companyId HabitoID: ' + reg.companyId.toUpperCase() + ' : ' + id + ' ID------' + reg.id + ' UsuarioID-------' + reg.usuarioId)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Habitos = true
              this.registroQuadroSaude.habito = filtro
              //console.log('Filtrado com  HabitoID! ' + this.registroQuadroSaude.habito[0].id)
            }
            else {
              //console.log('Filtrado com  HabitoID! ' + 'Não Passou - ' + filtro.length)
              this.Habitos = true
              this.registroQuadroSaude.habito = [{
                fumante: false,
                drogas: false,
                alcoolica: false,
                fisica: false,
              }]
            }
          })

      await this.serviceQuadroDeSaude.listarMedicacao().toPromise()
        .then(
          (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('4 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {

              this.Alergia = true
              this.registroQuadroSaude.medicacao = filtro
              //console.log('Filtrado com sucesso ! ' + filtro.length)
            }
          })

      await this.serviceQuadroDeSaude.listarHistoricoDoencas().toPromise().then(
        async (dados: Array<any>) => {
          var companyId= this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            //console.log('5 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.HistoricoDoenca = true
            this.registroQuadroSaude.historicoDoenca = await filtro
            //console.log('Doença : ' + JSON.stringify(this.registroQuadroSaude.deficiencia))
          }
        }
      )

      await this.serviceQuadroDeSaude.listarTratamento().toPromise()
        .then(
          (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('6 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {

              this.Tratamento = true
              this.registroQuadroSaude.tratamento = filtro
              //console.log('Filtrado com sucesso ! ' + filtro.length)
            }
          })

      await this.serviceQuadroDeSaude.listarResultadoExame().toPromise()
        .then(
          (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('7 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {

              this.Exame = true
              this.registroQuadroSaude.exame = filtro
              //console.log('Filtrado com sucesso ! ' + filtro.length)
            }
          })

      await this.serviceQuadroDeSaude.listarAvaliacoesPeriodicas().toPromise()
        .then(
          (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('8 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {

              this.Periodicas = true
              this.registroQuadroSaude.periodicas = filtro
              //console.log('Filtrado com sucesso ! ' + filtro.length)
              //console.log('Deficiencia : ' + JSON.stringify(this.registroQuadroSaude))
            }
          })

      await this.serviceQuadroDeSaude.listarHistoricoInternacoes().toPromise().then(
        async (dados: Array<any>) => {
          var companyId= this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.HistoricoInternacao = true
            this.registroQuadroSaude.historicoInternacoes = await filtro
            //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.historicoInternacoes))
          }
          else {
            this.HistoricoInternacao = false
          }
        }
      )

      await this.serviceQuadroDeSaude.listarSinaisVitais().toPromise().then(
        async (dados: Array<any>) => {
          var companyId= this.companyId
          var usuarioId = this.usuarioId
          if (!dados) dados = []
          var filtro = dados.filter(function (reg: any) {
            //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.SinaisVitais = true
            this.registroQuadroSaude.sinaisVitais = await filtro
            //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
          }

        }
      )

      await this.serviceQuadroDeSaude.listarDoencasFamilia().toPromise().then(
        async (dados: Array<any>) => {
          var companyId= this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.DoencasFamilia = true
            this.registroQuadroSaude.doencasFamilia = await filtro
            //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.doencaFamilia))
          }
        }
      )

      await this.serviceQuadroDeSaude.listarLesoes().toPromise().then(
        async (dados: Array<any>) => {
          var companyId= this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.Lesoes = true
            this.registroQuadroSaude.lesoes = await filtro
            //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.lesoes))
          }
        }
      )

      await this.serviceQuadroDeSaude.listarCirurgias().toPromise().then(
        async (dados: Array<any>) => {
          var companyId= this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.Cirurgias = true
            this.registroQuadroSaude.cirurgias = await filtro
            //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.cirurgias))
          }
        }
      )
      this.loading = false
    } catch{
      this.loading = false
    }
  }
  async cadastroParcial(ref, dados?, id?) {
    delete dados.id
    ///////Adicionar e Alterar
    switch (ref) {
      case 'deficiencia':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarDeficiencia(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('deficiencia') },
            () => { this.mensagem.error('Erro alterar') }
          )
        }
        else {
          //console.log(JSON.stringify(dados))
          await this.serviceQuadroDeSaude.cadastrarDeficiencia(dados).toPromise().then(
            (ok) => { 
              if(!ok)this.mensagem.error('ERRO!')
              this.atualizarQuadroSaude('deficiencia') 
            },
            () => { this.mensagem.error('Erro ao cadastrar') }
          )
        }
        break

      case 'alergia':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarAlergia(id, dados).toPromise().then(
            () => {  console.log('Alterar Alergia OK'); this.atualizarQuadroSaude('alergia') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarAlergia(dados).toPromise().then(
            () => {  console.log('Cadastro Alergia OK'); this.atualizarQuadroSaude('alergia') }
          )
        }
        break

      case 'medicacao':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarMedicacao(id, dados).toPromise().then(
            () => {  console.log('Alterar Medicacao OK'); this.atualizarQuadroSaude('medicacao') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarMedicacao(dados).toPromise().then(
            () => {  console.log('Cadastro Medicacao OK'); this.atualizarQuadroSaude('medicacao') }
          )
        }
        break

      case 'tratamento':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarTratamento(id, dados).toPromise().then(
            () => {  console.log('Alterar Tratamento OK'); this.atualizarQuadroSaude('tratamento') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarTratamento(dados).toPromise().then(
            () => {  console.log('Cadastro Tratamento OK'); this.atualizarQuadroSaude('tratamento') }
          )
        }
        break

      case 'exame':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarResultadoExame(id, dados).toPromise().then(
            () => {  console.log('Alterar Exame OK'); this.atualizarQuadroSaude('exame') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarResultadoExame(dados).toPromise().then(
            () => {  console.log('Cadastro Exame OK'); this.atualizarQuadroSaude('exame') }
          )
        }
        break

      case 'doencas':
        //console.log('doencas : \n' + JSON.stringify(this.formDoenca.value))
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarHistoricoDoencas(id, dados).toPromise().then(
            () => {  console.log('Alterar Historico Internacoes OK'); this.atualizarQuadroSaude('doencas') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarHistoricoDoencas(dados).toPromise().then(
            () => {  console.log('Cadastro Historico Internacoes OK'); 
            this.atualizarQuadroSaude('doencas') }
          )
        }
        break

      case 'periodicas':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarAvaliacoesPeriodicas(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('periodicas') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarAvaliacoesPeriodicas(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('periodicas') }
          )
        }
        break

      case 'internacao':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarHistoricoInternacoes(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('internacao') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarHistoricoInternacoes(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('internacao') }
          )
        }
        break

      case 'sinaisVitais':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarSinaisVitais(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('sinaisVitais') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarSinaisVitais(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('sinaisVitais') }
          )
        }
        break

      case 'doencasFamilia':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarDoencasFamilia(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('doencasFamilia') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarDoencasFamilia(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('doencasFamilia') }
          )
        }
        break

      case 'lesoes':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarLesoes(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('lesoes') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarLesoes(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('lesoes') }
          )
        }
        break

      case 'cirurgias':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarCirurgias(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('cirurgias') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarCirurgias(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('cirurgias') }
          )
        }
        break
    }
    ///////Excluir
    dados.ativo = false
    dados.id = id
    switch (ref) {
      case 'excluir-deficiencia':
        //console.log('excluir ' + id)

        await this.serviceQuadroDeSaude.alterarDeficiencia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('deficiencia') }
        )
        break
      case 'excluir-alergia':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarAlergia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('alergia') }
        )
        break
      case 'excluir-medicacao':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarMedicacao(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('medicacao') }
        )
        break

      case 'excluir-doencas':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarHistoricoDoencas(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('doencas') }
        )
        break

      case 'excluir-tratamento':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarTratamento(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('tratamento') }
        )
        break
      case 'excluir-exame':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarResultadoExame(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('exame') }
        )
        break
      case 'excluir-deficiencia':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarDeficiencia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('deficiencia') }
        )
        break
      case 'excluir-periodicas':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarAvaliacoesPeriodicas(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('periodicas') }
        )
        break

      case 'excluir-internacao':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarHistoricoInternacoes(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('internacao') }
        )
        break

      case 'excluir-sinaisVitais':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarSinaisVitais(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('sinaisVitais') }
        )
        break

      case 'excluir-doencasFamilia':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarDoencasFamilia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('doencasFamilia') }
        )
        break

      case 'excluir-lesoes':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarLesoes(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('lesoes') }
        )
        break

      case 'excluir-cirurgias':
        //console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarCirurgias(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('cirurgias') }
        )
        break
    }

  }
  async cadastrarQuadroSaude(dados, id?) {
    //console.log('SaudeID : ' + id)
    this.loading = true

    if (id) {
      dados.id = id
      await this.serviceQuadroDeSaude.alterarHabito(id, dados).toPromise().then(
        async () => {
          //console.log('Habito alterado ' + dados)
          this.mensagem.success('Ficha Médica Alterada com sucesso!')
          this.loading = false
          this.atualizarQuadroSaude('habito')

        }
      )
    }
    else {
      dados.usuarioId = this.id
      dados.companyId = this.companyId
      await this.serviceQuadroDeSaude.cadastrarHabito(dados).toPromise().then(
        async () => {
          this.mensagem.success('Ficha Médica registrada com sucesso!')
          this.loading = false
          this.atualizarQuadroSaude('habito')
        }
      )
    }
  }
  async atualizarQuadroSaude(nome) {
    switch (nome) {
      case 'deficiencia':
        await this.serviceQuadroDeSaude.listarDeficiencia().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              //console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Deficiencia = true
              this.registroQuadroSaude.deficiencia = await filtro
            }
            else {
              this.Deficiencia = false
            }
          }
        )
        break

      case 'alergia':
        await this.serviceQuadroDeSaude.listarAlergia().toPromise()
          .then(
            (dados: Array<any>) => {
              var companyId= this.companyId
              var usuarioId = this.id
              var filtro = dados.filter(function (reg: any) {
                //console.log('2 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Alergia = true
                this.registroQuadroSaude.alergia = filtro
                //console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Alergia = false
              }
            })
        break

      case 'habito':
        await this.serviceQuadroDeSaude.listarHabito().toPromise()
          .then(
            (dados: Array<any>) => {
              var companyId= this.companyId
              var usuarioId = this.id
              var filtro = dados.filter(function (reg: any) {
                //console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                this.Habitos = true
                this.registroQuadroSaude.habito = filtro
                //console.log('Filtrado com  Habito! ' + this.registroQuadroSaude.habito[0].fumante)
              }
              else {
                this.Habitos = true
                this.registroQuadroSaude.habito = [{
                  fumante: false,
                  drogas: false,
                  alcoolica: false,
                  fisica: false,
                }]
              }
            })
        break

      case 'medicacao':
        await this.serviceQuadroDeSaude.listarMedicacao().toPromise()
          .then(
            (dados: Array<any>) => {
              var companyId= this.companyId
              var usuarioId = this.id
              var filtro = dados.filter(function (reg: any) {
                //console.log('4 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Alergia = true
                this.registroQuadroSaude.medicacao = filtro
                //console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Alergia = false
              }
            })
        break

      case 'doencas':
        await this.serviceQuadroDeSaude.listarHistoricoDoencas().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.id
            var filtro = dados.filter(function (reg: any) {
              //console.log('5 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.HistoricoDoenca = true
              this.registroQuadroSaude.historicoDoenca = await filtro
              //console.log('Doença : ' + JSON.stringify(this.registroQuadroSaude.deficiencia))
            }
            else {
              this.HistoricoDoenca = false
            }
          }
        )
        break

      case 'tratamento':
        await this.serviceQuadroDeSaude.listarTratamento().toPromise()
          .then(
            (dados: Array<any>) => {
              var companyId= this.companyId
              var usuarioId = this.id
              var filtro = dados.filter(function (reg: any) {
                //console.log('6 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Tratamento = true
                this.registroQuadroSaude.tratamento = filtro
                //console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Tratamento = false
              }
            })
        break
      case 'exame':

        await this.serviceQuadroDeSaude.listarResultadoExame().toPromise()
          .then(
            (dados: Array<any>) => {
              var companyId= this.companyId
              var usuarioId = this.id
              var filtro = dados.filter(function (reg: any) {
                //console.log('7 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Exame = true
                this.registroQuadroSaude.exame = filtro
                //console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Exame = false
              }
            })
        break

      case 'periodicas':
        await this.serviceQuadroDeSaude.listarAvaliacoesPeriodicas().toPromise()
          .then(
            (dados: Array<any>) => {
              var companyId= this.companyId
              var usuarioId = this.id
              var filtro = dados.filter(function (reg: any) {
                //console.log('8 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Periodicas = true
                this.registroQuadroSaude.periodicas = filtro
                //console.log('Filtrado com sucesso ! ' + filtro.length)
                //console.log('Deficiencia : ' + JSON.stringify(this.registroQuadroSaude))
              }
              else {
                this.Periodicas = false
              }
            })
        break

      case 'internacao':

        await this.serviceQuadroDeSaude.listarHistoricoInternacoes().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.id
            var filtro = dados.filter(function (reg: any) {
              //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.HistoricoInternacao = true
              this.registroQuadroSaude.historicoInternacoes = await filtro
              //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.historicoInternacoes))
            }
            else {
              this.HistoricoDoenca = false
            }
          }
        )
        break

      case 'sinaisVitais':
        await this.serviceQuadroDeSaude.listarSinaisVitais().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.id
            if (!dados) dados = []
            var filtro = dados.filter(function (reg: any) {
              //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.SinaisVitais = true
              this.registroQuadroSaude.sinaisVitais = await filtro
              //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
            }
            else {
              this.SinaisVitais = false
            }

          }
        )
        break

      case 'doencasFamilia':
        await this.serviceQuadroDeSaude.listarDoencasFamilia().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.id
            var filtro = dados.filter(function (reg: any) {
              //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.DoencasFamilia = true
              this.registroQuadroSaude.doencasFamilia = await filtro
              //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.doencaFamilia))
            }
            else {
              this.DoencasFamilia = false
            }
          }
        )
        break

      case 'lesoes':
        await this.serviceQuadroDeSaude.listarLesoes().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.id
            var filtro = dados.filter(function (reg: any) {
              //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Lesoes = true
              this.registroQuadroSaude.lesoes = await filtro
              //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.lesoes))
            }
            else {
              this.Lesoes = false
            }
          }
        )
        break


      case 'cirurgias':
        await this.serviceQuadroDeSaude.listarCirurgias().toPromise().then(
          async (dados: Array<any>) => {
            var companyId= this.companyId
            var usuarioId = this.id
            var filtro = dados.filter(function (reg: any) {
              //console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Cirurgias = true
              this.registroQuadroSaude.cirurgias = await filtro
              //console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.cirurgias))
            }
            else {
              this.Cirurgias = false
            }
          }
        )
        break

    }
  }

  calcularIMC(peso?: number, altura?: number) {
    var imc = peso / (altura * altura)
    this.tempSinaisVitais.imc = JSON.stringify(imc)

  }
  createFormulario() {
    this.FormularioDependente = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      nome: new FormControl(Validators.required),
      email: new FormControl("", Validators.required),
      cpf: new FormControl("", Validators.required),
      rg: new FormControl("", Validators.required),
      nascimento: new FormControl("", Validators.required),
      profissao: new FormControl("", Validators.required),
      sexo: new FormControl("", Validators.required),
      tratamento: new FormControl("", Validators.required),
      telefone: new FormControl("", Validators.required),
      telefone2: new FormControl("", Validators.required),
      celular: new FormControl("", Validators.required),
      convenio: new FormControl("", Validators.required),
      medico: new FormControl("", Validators.required),
      tipo: new FormControl("Dependente", Validators.required),
      cor: new FormControl("", Validators.required),
      civil: new FormControl("", Validators.required),
      especial: false,
      localNascimento: new FormControl("", Validators.required),
      formaPag: new FormControl("", Validators.required),
      situacao: new FormControl("", Validators.required),
      peso: new FormControl("", Validators.required),
      responsavel: new FormControl("", Validators.required),
      rgResponsavel: new FormControl("", Validators.required),
      cpfResponsavel: new FormControl("", Validators.required),
      indicacao: new FormControl("", Validators.required),
      pai: new FormControl("", Validators.required),
      mae: new FormControl("", Validators.required),
      atividadePai: new FormControl("", Validators.required),
      atividadeMae: new FormControl("", Validators.required),
      endereco: new FormControl("", Validators.required),
      numero: new FormControl("", Validators.required),
      bairro: new FormControl("", Validators.required),
      cidade: new FormControl("", Validators.required),
      estado: new FormControl("", Validators.required),
      cep: new FormControl("", Validators.required),
      ativo: true
    })
    this.Formulario = this.form.group({
      id: '',
      usuarioId: this.id,
      companyId: this.companyId,
      nome: new FormControl('', Validators.required),
      cpf: new FormControl('', Validators.required),
      rg: new FormControl('', [Validators.required, Validators.minLength(9)]),
      nascimento: new FormControl('', Validators.required),
      genero: new FormControl('', Validators.required),
      orientacao: new FormControl('', Validators.required),
      civil: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      telefoneCel: new FormControl('', Validators.required),
      telefoneRes: new FormControl('', Validators.required),
      telefoneCom: new FormControl('', Validators.required),
      ramal: new FormControl('', Validators.required),
      logradouro: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      complemento: new FormControl('', Validators.required),
      cep: new FormControl('', Validators.required),
      bairro: new FormControl('', Validators.required),
      cidade: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      cartaoBandeira: new FormControl('', Validators.required),
      cartaoNumero: new FormControl('', Validators.required),
      vencimento: new FormControl('', Validators.required),
      seguranca: new FormControl('', Validators.required),
      celular: new FormControl('', Validators.required),
      telefone: new FormControl('', Validators.required),
      cor: new FormControl('', Validators.required),
      especial: new FormControl(false, Validators.required),
      localNascimento: new FormControl('', Validators.required),
      codnome: new FormControl(''),
      userType: new FormControl(''),
      pago: new FormControl(''),
      assinante: new FormControl(''),
      status: new FormControl(''),
      createedAt: new FormControl(''),
      data_inicio: new FormControl(''),
      data_expiracao: new FormControl(''),
      ativo: new FormControl('')
    })
  }
  async createFormularios(idCompany) {
    this.companyId = idCompany


    this.formDeficiencia = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      natureza: new FormControl('')
    })
    this.formAlergia = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      natureza: new FormControl('')
    })
    this.formHabito = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: new FormControl(true),
      fumante: new FormControl(Boolean),
      drogas: new FormControl(false),
      alcoolica: new FormControl(false),
      fisica: new FormControl(false),
    })
    this.formMedicacao = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      remedio: new FormControl(''),
      periodo: new FormControl(''),
      motivo: new FormControl(''),
      status: new FormControl('')
    })
    this.formDoenca = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      doenca: '',
      periodo: '',
      status: '',
    })
    this.formTratamento = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      periodo: new FormControl(''),
      ano: new FormControl(''),
      status: new FormControl('')
    })
    this.formExame = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      dataExame: new FormControl(new Date()),
      anexo: new FormControl(''),
      status: new FormControl('')
    })
    this.formPeriodicas = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      dataExame: new FormControl(new Date()),
      anexo: new FormControl(''),
      status: new FormControl('')
    })
    this.formInternacao = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      dataHistorico: new FormControl(new Date()),
      local: new FormControl(''),
      motivo: new FormControl('')
    })
    this.formSinaisVitais = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      grupoSanguineo: new FormControl(),
      peso: new FormControl(''),
      altura: new FormControl(''),
      imc: new FormControl(''),
      temperatura: new FormControl(''),
      pressaoSitolica: new FormControl(''),
      pressaoDiastolica: new FormControl('')
    })
    this.formDoencasFamilia = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      grauParentesco: new FormControl(''),
      ano: new FormControl(''),
      statusDoenca: new FormControl('')
    })
    this.formLesoes = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      sequelas: new FormControl(''),
      ano: new FormControl(''),
      statusLesao: new FormControl('')
    })
    this.formCirurgias = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      ano: new FormControl(''),
      statusLesao: new FormControl('')
    })
  }

}
