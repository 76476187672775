import { Component, OnInit, Input } from '@angular/core';
import { SalaEsperaForm } from '../../sala-espera/model';
import { DatePipe } from '@angular/common';
import * as jsPDF from 'jspdf';
import { FormCadastroEmpresa } from 'src/app/configuracao-inicial/cadastro-empresa/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';

@Component({
  selector: 'app-emitir-atestado',
  templateUrl: './emitir-atestado.component.html',
  styleUrls: ['./emitir-atestado.component.scss']
})
export class EmitirAtestadoComponent implements OnInit {
  hojeDas
  hojeAs
  info: SalaEsperaForm
  dias
  datePipe = new DatePipe('en-US')
  dataHoje: string;
  empresa: FormCadastroEmpresa;
  especialidade
  servico
  start: string;
  hora: string;
  end: string;
  constructor(private hoje: BuscarDataService, private service: ConfiguracaoInicialService) {
    this.dataHoje = this.hoje.hoje()
    this.hora = this.hoje.horaAgora()
  }

  ngOnInit() {
    this.info = JSON.parse(localStorage.getItem('infoSalaEspera'))
    this.start = this.info.hora
    this.end  = this.hora
    this.buscarEmpresa()
  }

  async  downloadPDF() {
    // var image = new Image
    // var img = image.src = "../../../../assets/img/logoDoctorJP.jpg"
    // var teste = 'data:image/jpeg;base64,' + btoa(img);
    var doc = new jsPDF();
    // await doc.addHTML(document.getElementById('content'), function(){
    //   console.log('ok')
    //   doc.output('dataurlnewwindow');
    // })
    doc.setFontSize(12);
    doc.text(5, 10, `${this.empresa.name ? this.empresa.name : '-'}`);

    doc.setFontSize(9);
    doc.text(5, 15, `${this.empresa.consultorio} - CRM ${this.empresa.crm ? this.empresa.crm : '___________________'} - CPF ${this.empresa.cpf ? this.empresa.cpf : '_______________'}`);
    doc.line(0, 25, 1000, 25);
    doc.setFontSize(22);
    doc.text("ATESTADO", 80, 35);

    doc.setFontSize(14);
    doc.text(`Declaro que o (a) paciente  ${this.info.paciente} foi atendido(a),nessa clinica`, 20, 60);

    doc.setFontSize(14);
    doc.text(`dia ${this.datePipe.transform(this.dataHoje, 'dd/MM/yyyy')}  dás ${this.start}h até ás ${this.end.substr(0,2)}:${this.end.substr(2,2)}h`, 20, 70);

    doc.setFontSize(15);
    doc.text("_____________________________", 100, 90);
    doc.setFontSize(15);
    doc.text(`CRM : ${this.empresa.crm ? this.empresa.crm : ''}`, 100, 100);
    doc.setFontSize(15);
    doc.text(`São Paulo ${this.datePipe.transform(this.dataHoje, 'dd/MM/yyyy')}`, 10, 150);

    doc.output('dataurlnewwindow');

    doc.autoPrint();

  }
  buscarEmpresa() {
    this.service.listarEmpresa().toPromise()
      .then(
        (dados: Array<FormCadastroEmpresa>) => {
          let companyId = this.info.companyId
          let filtro = dados.filter((r) => r.id.toLocaleUpperCase() == companyId.toLocaleUpperCase())
          this.empresa = filtro[0]
        }
      )
  }

}
