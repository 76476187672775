import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UsuarioService, UsuarioDependenteService, UsuarioQuadroDeSaudeService } from 'src/app/service/usuario.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormulariosService } from 'src/app/service/formularios.service';
import { DatePipe } from '@angular/common';
import { FormUsuario } from '../model';

@Component({
  selector: 'app-dados-saude',
  templateUrl: './dados-saude.component.html',
  styleUrls: ['./dados-saude.component.scss']
})
export class DadosSaudeComponent implements OnInit {
  @Input('companyId') companyId
  @Input('usuarioId') usuarioId
  //temporarios
  tempDeficiencia: any = {}
  tempAlergia: any = {}
  tempHabitos: any = {}
  tempMedicacao: any = {}
  tempHistoricoDoenca: any = {}
  tempTratamento: any = {}
  tempExame: any = {}
  tempPeriodicas: any = {}
  tempHistoricoInternacao: any = {}
  tempSinaisVitais: any = {}
  tempDadosSaude: any = {}
  tempDoencasFamilia: any = {}
  tempLesoes: any = {}
  tempCirurgias: any = {}
  //Forms
  formDeficiencia: FormGroup;
  formAlergia: FormGroup;
  formHabito: FormGroup
  formMedicacao: FormGroup;
  formDoenca: FormGroup;
  formTratamento: FormGroup;
  formExame: FormGroup;
  formPeriodicas: FormGroup;
  formInternacao: FormGroup;
  formSinaisVitais: FormGroup;
  formDadosSaude: FormGroup;
  formDoencasFamilia: FormGroup;
  formLesoes: FormGroup;
  formCirurgias: FormGroup;

  registroQuadroSaude: any = {}
  estruturaFormulario: any
  estruturaFormularioQuadroSaude: any
  loading: boolean;

  //Validar leyout
  Registro: Boolean
  Deficiencia: boolean;
  Alergia: boolean;
  Habitos: boolean;
  HistoricoDoenca: boolean;
  Tratamento: boolean;
  Exame: boolean;
  Periodicas: boolean;
  Medicacao: boolean;
  HistoricoInternacao: boolean;
  SinaisVitais: boolean;
  DoencasFamilia: boolean;
  Lesoes: boolean;
  Cirurgias: boolean;
  dadosSaude: boolean;

  resultImc
  dadosUsuario: FormUsuario;
  erro: boolean;
  erroMensagem: string;




  constructor(
    private service: UsuarioService,
    private serviceDependente: UsuarioDependenteService,
    private serviceQuadroDeSaude: UsuarioQuadroDeSaudeService,
    private form: FormBuilder,
    private url: ActivatedRoute,
    private mensagem: ToastrService,
    private formularios: FormulariosService,
    private datePipe: DatePipe) {
    this.estruturaFormularioQuadroSaude = formularios.formularioFichaMedica()

  }

  ngOnInit() {
    if(!this.companyId)this.companyId = '00000000-0000-0000-0000-000000000000' 
    if(this.usuarioId){
      this.createFormularios()
      this.buscarQuadroSaude()
      console.log(`DadosSaude : ${this.companyId} -- ${this.usuarioId}`)
    }
   
  }

  ////////////////////////////////////QUADRO DE SAUDE///////////////////////////  

  public async buscarQuadroSaude() {
    this.loading = true
    //Limpar dados 
    // this.registroQuadroSaude = {}
    this.Habitos = false
    this.HistoricoDoenca = false
    //END
    
    await this.serviceQuadroDeSaude.listarDeficiencia().toPromise().then(
      async (dados: Array<any>) => {
        var companyId = this.companyId 
        var usuarioId = this.usuarioId
        // console.log('Quadro de Saude : ' + dados.length)
        if (!dados) return
        var filtro = dados.filter(function (reg: any) {
          // console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toLowerCase() == companyId.toLowerCase() &&
            reg.usuarioId.toLowerCase() == usuarioId.toLowerCase()
            && reg.ativo == true
        })
        if (filtro.length >= 1) {
          this.Deficiencia = true
          this.registroQuadroSaude.deficiencia = await filtro
        }
      }
    )

    await this.serviceQuadroDeSaude.listarAlergia().toPromise()
      .then(
        (dados: Array<any>) => {
          var id = this.companyId
          var usuarioId = this.usuarioId
          if (!dados) return
          var filtro = dados.filter(function (reg: any) {
            // console.log('2 - reg.companyId : ' + reg.companyId.toLowerCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {

            this.Alergia = true
            this.registroQuadroSaude.alergia = filtro
            // console.log('Filtrado com sucesso ! ' + filtro.length)
          }
        })

    await this.serviceQuadroDeSaude.listarHabito().toPromise()
      .then(
        (dados: Array<any>) => {
          var id = this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            // console.log('1 - reg.companyId HabitoID: ' + reg.companyId.toUpperCase() + ' : ' + id + ' ID------' + reg.id + ' UsuarioID-------' + reg.usuarioId)
            return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {
            this.Habitos = true
            this.registroQuadroSaude.habito = filtro
            // console.log('Filtrado com  HabitoID! ' + this.registroQuadroSaude.habito[0].id)
          }
          else {
            // console.log('Filtrado com  HabitoID! ' + 'Não Passou - ' + filtro.length)
            this.Habitos = true
            this.registroQuadroSaude.habito = [{
              fumante: false,
              drogas: false,
              alcoolica: false,
              fisica: false,
            }]
          }
        })

    await this.serviceQuadroDeSaude.listarMedicacao().toPromise()
      .then(
        (dados: Array<any>) => {
          var id = this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            // console.log('4 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {

            this.Alergia = true
            this.registroQuadroSaude.medicacao = filtro
            // console.log('Filtrado com sucesso ! ' + filtro.length)
          }
        })

    await this.serviceQuadroDeSaude.listarHistoricoDoencas().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        var filtro = dados.filter(function (reg: any) {
          // console.log('5 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        if (filtro.length >= 1) {
          this.HistoricoDoenca = true
          this.registroQuadroSaude.historicoDoenca = await filtro
          // console.log('Doença : ' + JSON.stringify(this.registroQuadroSaude.deficiencia))
        }
      }
    )

    await this.serviceQuadroDeSaude.listarTratamento().toPromise()
      .then(
        (dados: Array<any>) => {
          var id = this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            // console.log('6 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {

            this.Tratamento = true
            this.registroQuadroSaude.tratamento = filtro
            // console.log('Filtrado com sucesso ! ' + filtro.length)
          }
        })

    await this.serviceQuadroDeSaude.listarResultadoExame().toPromise()
      .then(
        (dados: Array<any>) => {
          var id = this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            // console.log('7 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {

            this.Exame = true
            this.registroQuadroSaude.exame = filtro
            // console.log('Filtrado com sucesso ! ' + filtro.length)
          }
        })

    await this.serviceQuadroDeSaude.listarAvaliacoesPeriodicas().toPromise()
      .then(
        (dados: Array<any>) => {
          var id = this.companyId
          var usuarioId = this.usuarioId
          var filtro = dados.filter(function (reg: any) {
            // console.log('8 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
            return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
          })
          if (filtro.length >= 1) {

            this.Periodicas = true
            this.registroQuadroSaude.periodicas = filtro
            // console.log('Filtrado com sucesso ! ' + filtro.length)
            // console.log('Deficiencia : ' + JSON.stringify(this.registroQuadroSaude))
          }
        })

    await this.serviceQuadroDeSaude.listarHistoricoInternacoes().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        var filtro = dados.filter(function (reg: any) {
          // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        if (filtro.length >= 1) {
          this.HistoricoInternacao = true
          this.registroQuadroSaude.historicoInternacoes = await filtro
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.historicoInternacoes))
        }
      }
    )

    await this.serviceQuadroDeSaude.listarSinaisVitais().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        if (!dados) dados = []
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        console.log('Sinais Vitais : ' + JSON.stringify(filtro))
        if (filtro.length >= 1) {
          this.SinaisVitais = true
          this.registroQuadroSaude.sinaisVitais = await filtro
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
        }

      }
    )

    await this.serviceQuadroDeSaude.listarDadosSaude().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        if (!dados) dados = []
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        console.log('Sinais Vitais : ' + JSON.stringify(filtro))
        if (filtro.length >= 1) {
          this.dadosSaude = true
          this.registroQuadroSaude.dadosSaude = await filtro
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
        }

      }
    )

    await this.serviceQuadroDeSaude.listarDoencasFamilia().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        if (filtro.length >= 1) {
          this.DoencasFamilia = true
          this.registroQuadroSaude.doencasFamilia = await filtro
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.doencaFamilia))
        }
      }
    )

    await this.serviceQuadroDeSaude.listarLesoes().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        var filtro = dados.filter(function (reg: any) {
          //  // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        if (filtro.length >= 1) {
          this.Lesoes = true
          this.registroQuadroSaude.lesoes = await filtro
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.lesoes))
        }
      }
    )

    await this.serviceQuadroDeSaude.listarCirurgias().toPromise().then(
      async (dados: Array<any>) => {
        var id = this.companyId
        var usuarioId = this.usuarioId
        var filtro = dados.filter(function (reg: any) {
          // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
          return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
        })
        if (filtro.length >= 1) {
          this.Cirurgias = true
          this.registroQuadroSaude.cirurgias = await filtro
          // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.cirurgias))
        }
      }
    )
    this.loading = false
  }
  async atualizarQuadroSaude(nome) {
    switch (nome) {
      case 'deficiencia':
        await this.serviceQuadroDeSaude.listarDeficiencia().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              // console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Deficiencia = true
              this.registroQuadroSaude.deficiencia = await filtro
            }
            else {
              this.Deficiencia = false
            }
          }
        )
        break

      case 'alergia':
        await this.serviceQuadroDeSaude.listarAlergia().toPromise()
          .then(
            (dados: Array<any>) => {
              var id = this.companyId
              var usuarioId = this.usuarioId
              var filtro = dados.filter(function (reg: any) {
                // console.log('2 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Alergia = true
                this.registroQuadroSaude.alergia = filtro
                // console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Alergia = false
              }
            })
        break

      case 'habito':
        await this.serviceQuadroDeSaude.listarHabito().toPromise()
          .then(
            (dados: Array<any>) => {
              var id = this.companyId
              var usuarioId = this.usuarioId
              var filtro = dados.filter(function (reg: any) {
                // console.log('1 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                this.Habitos = true
                this.registroQuadroSaude.habito = filtro
                // console.log('Filtrado com  Habito! ' + this.registroQuadroSaude.habito[0].fumante)
              }
              else {
                this.Habitos = true
                this.registroQuadroSaude.habito = [{
                  fumante: false,
                  drogas: false,
                  alcoolica: false,
                  fisica: false,
                }]
              }
            })
        break

      case 'medicacao':
        await this.serviceQuadroDeSaude.listarMedicacao().toPromise()
          .then(
            (dados: Array<any>) => {
              var id = this.companyId
              var usuarioId = this.usuarioId
              var filtro = dados.filter(function (reg: any) {
                // console.log('4 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Alergia = true
                this.registroQuadroSaude.medicacao = filtro
                // console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Alergia = false
              }
            })
        break

      case 'doencas':
        await this.serviceQuadroDeSaude.listarHistoricoDoencas().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              // console.log('5 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.HistoricoDoenca = true
              this.registroQuadroSaude.historicoDoenca = await filtro
              // console.log('Doença : ' + JSON.stringify(this.registroQuadroSaude.deficiencia))
            }
            else {
              this.HistoricoDoenca = false
            }
          }
        )
        break

      case 'tratamento':
        await this.serviceQuadroDeSaude.listarTratamento().toPromise()
          .then(
            (dados: Array<any>) => {
              var id = this.companyId
              var usuarioId = this.usuarioId
              var filtro = dados.filter(function (reg: any) {
                // console.log('6 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Tratamento = true
                this.registroQuadroSaude.tratamento = filtro
                // console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Tratamento = false
              }
            })
        break
      case 'exame':

        await this.serviceQuadroDeSaude.listarResultadoExame().toPromise()
          .then(
            (dados: Array<any>) => {
              var id = this.companyId
              var usuarioId = this.usuarioId
              var filtro = dados.filter(function (reg: any) {
                // console.log('7 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Exame = true
                this.registroQuadroSaude.exame = filtro
                // console.log('Filtrado com sucesso ! ' + filtro.length)
              }
              else {
                this.Exame = false
              }
            })
        break

      case 'periodicas':
        await this.serviceQuadroDeSaude.listarAvaliacoesPeriodicas().toPromise()
          .then(
            (dados: Array<any>) => {
              var id = this.companyId
              var usuarioId = this.usuarioId
              var filtro = dados.filter(function (reg: any) {
                // console.log('8 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {

                this.Periodicas = true
                this.registroQuadroSaude.periodicas = filtro
                // console.log('Filtrado com sucesso ! ' + filtro.length)
                // console.log('Deficiencia : ' + JSON.stringify(this.registroQuadroSaude))
              }
              else {
                this.Periodicas = false
              }
            })
        break

      case 'internacao':

        await this.serviceQuadroDeSaude.listarHistoricoInternacoes().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.HistoricoInternacao = true
              this.registroQuadroSaude.historicoInternacoes = await filtro
              // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.historicoInternacoes))
            }
            else {
              this.HistoricoDoenca = false
            }
          }
        )
        break

      case 'sinaisVitais':
        await this.serviceQuadroDeSaude.listarSinaisVitais().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            if (!dados) dados = []
            var filtro = dados.filter(function (reg: any) {
              // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.SinaisVitais = true
              this.registroQuadroSaude.sinaisVitais = await filtro
              // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
            }
            else {
              this.SinaisVitais = false
            }

          }
        )
        break

      case 'dadosSaude':
        await this.serviceQuadroDeSaude.listarDadosSaude().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            if (!dados) dados = []
            var filtro = dados.filter(function (reg: any) {
              // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.dadosSaude = true
              this.registroQuadroSaude.dadosSaude = await filtro
              // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.sinaisVitais))
            }
            else {
              this.dadosSaude = false
            }

          }
        )
        break

      case 'doencasFamilia':
        await this.serviceQuadroDeSaude.listarDoencasFamilia().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.DoencasFamilia = true
              this.registroQuadroSaude.doencasFamilia = await filtro
              // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.doencaFamilia))
            }
            else {
              this.DoencasFamilia = false
            }
          }
        )
        break

      case 'lesoes':
        await this.serviceQuadroDeSaude.listarLesoes().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Lesoes = true
              this.registroQuadroSaude.lesoes = await filtro
              // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.lesoes))
            }
            else {
              this.Lesoes = false
            }
          }
        )
        break


      case 'cirurgias':
        await this.serviceQuadroDeSaude.listarCirurgias().toPromise().then(
          async (dados: Array<any>) => {
            var id = this.companyId
            var usuarioId = this.usuarioId
            var filtro = dados.filter(function (reg: any) {
              // console.log('3 - reg.companyId : ' + reg.companyId.toUpperCase() + ' : ' + id)
              return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
            })
            if (filtro.length >= 1) {
              this.Cirurgias = true
              this.registroQuadroSaude.cirurgias = await filtro
              // console.log('Objeto inseriro : ' + JSON.stringify(this.registroQuadroSaude.cirurgias))
            }
            else {
              this.Cirurgias = false
            }
          }
        )
        break

    }
  }
  async cadastrarQuadroSaude(dados, id?) {
    // console.log('SaudeID : ' + id)
    this.loading = true

    if (id) {
      dados.id = id
      await this.serviceQuadroDeSaude.alterarHabito(id, dados).toPromise().then(
        async () => {
          // console.log('Habito alterado ' + dados)
          this.mensagem.success('Ficha Médica Alterada com sucesso!')
          this.loading = false
          this.atualizarQuadroSaude('habito')

        }
      )
    }
    else {
      dados.usuarioId = this.usuarioId
      dados.companyId = this.companyId
      await this.serviceQuadroDeSaude.cadastrarHabito(dados).toPromise().then(
        async () => {
          this.mensagem.success('Ficha Médica registrada com sucesso!')
          this.loading = false
          this.atualizarQuadroSaude('habito')
        }
      )
    }
  }

  async cadastroParcial(ref, dados?, id?) {

    dados.ativo = true
    ///////Adicionar e Alterar
    switch (ref) {
      case 'deficiencia':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarDeficiencia(id, dados).toPromise().then(
            () => { console.log('Alterar Deficiencia OK'); this.atualizarQuadroSaude('deficiencia') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarDeficiencia(dados).toPromise().then(
            () => { console.log('Cadastro Deficiencia OK'); this.atualizarQuadroSaude('deficiencia') }
          )
        }
        break

      case 'alergia':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarAlergia(id, dados).toPromise().then(
            () => { console.log('Alterar Alergia OK'); this.atualizarQuadroSaude('alergia') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarAlergia(dados).toPromise().then(
            () => { console.log('Cadastro Alergia OK'); this.atualizarQuadroSaude('alergia') }
          )
        }
        break

      case 'medicacao':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarMedicacao(id, dados).toPromise().then(
            () => { console.log('Alterar Medicacao OK'); this.atualizarQuadroSaude('medicacao') }
          )
        }
        else {
          delete dados.id
          await this.serviceQuadroDeSaude.cadastrarMedicacao(dados).toPromise().then(
            () => { console.log('Cadastro Medicacao OK'); this.atualizarQuadroSaude('medicacao') }
          )
        }
        break

      case 'tratamento':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarTratamento(id, dados).toPromise().then(
            () => { console.log('Alterar Tratamento OK'); this.atualizarQuadroSaude('tratamento') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarTratamento(dados).toPromise().then(
            () => { console.log('Cadastro Tratamento OK'); this.atualizarQuadroSaude('tratamento') }
          )
        }
        break

      case 'exame':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarResultadoExame(id, dados).toPromise().then(
            () => { console.log('Alterar Exame OK'); this.atualizarQuadroSaude('exame') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarResultadoExame(dados).toPromise().then(
            () => { console.log('Cadastro Exame OK'); this.atualizarQuadroSaude('exame') }
          )
        }
        break

      case 'doencas':
        // console.log('doencas : \n' + JSON.stringify(this.formDoenca.value))
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarHistoricoDoencas(id, dados).toPromise().then(
            () => { console.log('Alterar Historico Internacoes OK'); this.atualizarQuadroSaude('doencas') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarHistoricoDoencas(dados).toPromise().then(
            () => { console.log('Cadastro Historico Internacoes OK'); this.atualizarQuadroSaude('doencas') }
          )
        }
        break

      case 'periodicas':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarAvaliacoesPeriodicas(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('periodicas') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarAvaliacoesPeriodicas(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('periodicas') }
          )
        }
        break

      case 'internacao':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarHistoricoInternacoes(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('internacao') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarHistoricoInternacoes(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('internacao') }
          )
        }
        break

      case 'sinaisVitais':
        delete dados.id
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarSinaisVitais(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('sinaisVitais') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarSinaisVitais(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('sinaisVitais') }
          )
        }
        break

      case 'dadosSaude':

        if (id) {
          dados.id = id
          console.log('Alterar - ')
          console.log(dados)
          await this.serviceQuadroDeSaude.alterarDadosSaude(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('dadosSaude') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarDadosSaude(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('dadosSaude') })
        }
        console.log(dados)
        return
        break

      case 'doencasFamilia':
        if (id) {
          dados.id = id

          await this.serviceQuadroDeSaude.alterarDoencasFamilia(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('doencasFamilia') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarDoencasFamilia(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('doencasFamilia') }
          )
        }
        break

      case 'lesoes':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarLesoes(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('lesoes') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarLesoes(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('lesoes') }
          )
        }
        break

      case 'cirurgias':
        if (id) {
          dados.id = id
          await this.serviceQuadroDeSaude.alterarCirurgias(id, dados).toPromise().then(
            () => { this.atualizarQuadroSaude('cirurgias') }
          )
        }
        else {
          await this.serviceQuadroDeSaude.cadastrarCirurgias(dados).toPromise().then(
            () => { this.atualizarQuadroSaude('cirurgias') }
          )
        }
        break
    }
    ///////Excluir
    dados.ativo = false
    dados.id = id
    switch (ref) {
      case 'excluir-deficiencia':
        // console.log('excluir ' + id)

        await this.serviceQuadroDeSaude.alterarDeficiencia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('deficiencia') }
        )
        break
      case 'excluir-alergia':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarAlergia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('alergia') }
        )
        break
      case 'excluir-medicacao':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarMedicacao(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('medicacao') }
        )
        break

      case 'excluir-doencas':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarHistoricoDoencas(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('doencas') }
        )
        break

      case 'excluir-tratamento':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarTratamento(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('tratamento') }
        )
        break
      case 'excluir-exame':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarResultadoExame(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('exame') }
        )
        break
      case 'excluir-deficiencia':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarDeficiencia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('deficiencia') }
        )
        break
      case 'excluir-periodicas':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarAvaliacoesPeriodicas(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('periodicas') }
        )
        break

      case 'excluir-internacao':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarHistoricoInternacoes(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('internacao') }
        )
        break

      case 'excluir-sinaisVitais':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarSinaisVitais(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('sinaisVitais') }
        )
        break

        case 'excluir-dadosSaude':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarDadosSaude(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('dadosSaude') }
        )
        break

      case 'excluir-doencasFamilia':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarDoencasFamilia(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('doencasFamilia') }
        )
        break

      case 'excluir-lesoes':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarLesoes(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('lesoes') }
        )
        break

      case 'excluir-cirurgias':
        // console.log('excluir ' + id)
        await this.serviceQuadroDeSaude.alterarCirurgias(id, dados).toPromise().then(
          () => { this.atualizarQuadroSaude('cirurgias') }
        )
        break
    }

  }
  async createFormularios() {
    this.formDeficiencia = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      natureza: new FormControl('')
    })
    this.formAlergia = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      natureza: new FormControl('')
    })
    this.formHabito = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: new FormControl(true),
      fumante: new FormControl(Boolean),
      drogas: new FormControl(false),
      alcoolica: new FormControl(false),
      fisica: new FormControl(false),
    })
    this.formMedicacao = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      remedio: new FormControl(''),
      periodo: new FormControl(''),
      motivo: new FormControl(''),
      status: new FormControl('')
    })
    this.formDoenca = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      doenca: '',
      periodo: '',
      status: '',
    })
    this.formTratamento = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      periodo: new FormControl(''),
      ano: new FormControl(''),
      status: new FormControl('')
    })
    this.formExame = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      dataExame: new FormControl(new Date()),
      anexo: new FormControl(''),
      status: new FormControl('')
    })
    this.formPeriodicas = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      dataExame: new FormControl(new Date()),
      anexo: new FormControl(''),
      status: new FormControl('')
    })
    this.formInternacao = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      dataHistorico: new FormControl(new Date()),
      local: new FormControl(''),
      motivo: new FormControl('')
    })
    this.formSinaisVitais = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      temperatura: new FormControl(''),
      pressaoSitolica: new FormControl(''),
      pressaoDiastolica: new FormControl('')
    })
    this.formDadosSaude = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      pacienteId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      grupoSanguineo: new FormControl(),
      peso: new FormControl(''),
      altura: new FormControl(''),
      imc: new FormControl(''),
    })
    this.formDoencasFamilia = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      grauParentesco: new FormControl(''),
      ano: new FormControl(''),
      statusDoenca: new FormControl('')
    })
    this.formLesoes = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      sequelas: new FormControl(''),
      ano: new FormControl(''),
      statusLesao: new FormControl('')
    })
    this.formCirurgias = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId),
      ativo: true,
      descricao: new FormControl(''),
      ano: new FormControl(''),
      statusLesao: new FormControl('')
    })
  }
  calcularIMC(peso?: number, altura?: number) {
    var imc = peso / (altura * altura)
    this.tempDadosSaude.imc = JSON.stringify(imc)

  }
}
