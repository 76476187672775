import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UploadArquivosService } from './upload-arquivos.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FotoPerfilService {

  url = new environment().api
  api = "fotos/"
  constructor(private http: HttpClient, private _service: UploadArquivosService, private sanitizer: DomSanitizer) { }

  async buscarFoto(cpf) {
    let fotoPerfil
    let get = await this._service.get(cpf)
    let foto = get[get.length - 1]
    if (!foto) {
      fotoPerfil = 'assets/imgUser.png'
      return fotoPerfil
    }
    await this._service.getFile(foto.nomeArquivo, foto.path).toPromise()
      .then(res => {
        let base64data
        var blobimg = new Blob([res], { type: 'image/png' })
        var foto = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobimg))
        fotoPerfil = foto
        var reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = function () {
          base64data = reader.result;
        }
      }).catch(error => {
      })
    // assets/imgUser.png
    return fotoPerfil
  }

  async getFoto(foto) {
    let fotoPerfil
    await this._service.getFile(foto.nome, foto.path).toPromise()
      .then(res => {
        let base64data
        var blobimg = new Blob([res], { type: 'image/png' })
        var foto = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobimg))
        fotoPerfil = foto
        var reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = function () {
          base64data = reader.result;
        }
      })
    // assets/imgUser.png
    return fotoPerfil
  }

  gravarFoto(image) {
    console.log(this.url + this.api, image)
    return this.http.post(this.url + this.api, image)
  }
  alterar(image, id) {
    console.log(this.url + this.api + id, image)
    return this.http.put(this.url + this.api + id, image)
  }
  excluir(id) {
    return this.http.delete(this.url + this.api + id)
  }
}