import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { LegislacaoTributariaService } from 'src/app/service/legislacao-tributaria.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { MensagensDoSistemaService } from 'src/app/service/mensagens-do-sistema.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { LegislacaoTributariaModel } from './legislacao-tributaria.model';

const sessao = new SessaoUser

@Component({
  selector: 'app-legislacao-tributaria',
  templateUrl: './legislacao-tributaria.component.html',
  styleUrls: ['./legislacao-tributaria.component.scss']
})
export class LegislacaoTributariaComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'pais', 'regimeTributario', 'tabelaFiscal', 'faixaFiscal']
  listRegistro: LegislacaoTributariaModel[]
  viewCadastro: boolean
  formulario: FormGroup
  listEmpresa = []
  listPaises: any
  listRegimeTributario = []
  listTabelaFiscal = []
  listBaseReferencia = []
  listImpostoPorFora = []
  listMoedas = []
  listFaixaFiscal = []

  alteracao = false
  botaoSalvar = false
  botaoCancelar = false
  selectEmpresa = 0
  dataSource
  loading: boolean
  viewConfirmDelete: boolean


  constructor(
    private service: LegislacaoTributariaService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private conteudo: ConteudoDeDadosService,
    private mensagem: MensagemService,
    private _filtro: FiltrarDadosService
  ) { }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getPaises()
    this.getRegimeTributario()
    this.getTabelaFiscal()
    this.getBaseReferencia()
    this.getImpostoPorFora()
    this.getMoedas()
    this.getFaixaFiscal()
    this.getRegistro()
      // .finally(() => this.filtrarEmpresa())
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      pais: '',
      regimeTributario: '',
      tabelaFiscal: '',
      faixaFiscal: '',
      baseReferencia: '',
      limiteSuperior: 0,
      limiteInferior: 0,
      impostoPorFora: '',
      aliquota: 0,
      deducao: 0,
      moeda: '',
      valorExcedente: 0,
      aliquotaExcedente: 0,
      aliquotaPresuncao: 0,
      aliquotaFolhaPagamento: 0,
      criadoPor: sessao.cpf,
      criadoEm: '',
      status: '',
      ////

    })
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  
  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa;
  }

  async getPaises() {
    this.service.getPaises().subscribe(response => {
      this.listPaises = response
    })
  }

  async getFaixaFiscal(){
    let faixa = await this.conteudo.get('FAIFIS')
    this.listFaixaFiscal = faixa
  }

  async getRegimeTributario() {
    let regime = await this.conteudo.get('REGTRIB')
    this.listRegimeTributario = regime
    console.log("Regime: ", regime)
  }

  async getTabelaFiscal() {
    let tabela = await this.conteudo.get('TABFISC')
    this.listTabelaFiscal = tabela
  }

  async getBaseReferencia() {
    let base = await this.conteudo.get('BASREF')
    this.listBaseReferencia = base
  }

  async getImpostoPorFora() {
    let imposto = await this.conteudo.get('CADIMP')
    this.listImpostoPorFora = imposto
  }

  async getMoedas() {
    let moedas = await this.conteudo.get('MOEDAS')
    this.listMoedas = moedas
  }

  async getRegistro() {
    this.loading = true

    await this.service.listar().toPromise()
      .then(
        registro => {

          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let regime = await this.conteudo.get('REGTRIB', true, item.regimeTributario)
            let tabela = await this.conteudo.get('TABFISC', true, item.tabelaFiscal)
            let faixa = await this.conteudo.get('FAIFIS', true, item.faixaFiscal)
            this.service.getPaises()

            item.descRegime = regime.toString()
            item.descTabela = tabela.toString()
            item.descFaixa = faixa.toString()
            
          })


          this.dataSource = new MatTableDataSource(this.listRegistro)
          this.dataSource.sort = this.sort
          this.loading = false


          console.log("getRegistro(): ", registro)
          console.log(".data: ", this.dataSource.data)
        }
      )
  }

  async openCadastro(registro) {
    console.log("Open: ", registro)

    delete registro.descFaixa
    delete registro.descRegime
    delete registro.descTabela

    this.formulario.patchValue(registro)

    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  inserir() {
    let registro = this.formulario.value

    delete registro.id
    delete registro.criadoEm

    registro.criadoPor = sessao.cpf

    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false

        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
    console.log("Inserir registro: ", registro)
  }

  alterar(){
    let registro = this.formulario.value

    // delete registro.nomeEmpresa
    // delete registro.descBaseCalc
    // delete registro.descImposto
    
    this.service.alterar(registro).toPromise()
    .then(
      resposta => {
        this.mensagem.creat('Cadastro alterado com sucesso.', {sucesso: true})
        this.viewCadastro = true
        this.formulario.disable()
      }
    ).catch(
      error => {
        let erro = error.error
        this.mensagem.creat(erro, {erro: true})
      })
  }

  async excluirRegistro(confirm?) {
		if (confirm) {
			this.viewConfirmDelete = true
			return
		}

		this.viewConfirmDelete = false

		let registro = this.formulario.value

		if (!registro.id) return

		await this.service.desativar(registro, false)
		this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
		this.viewCadastro = false
    await this.getRegistro()
    // .finally(() => this.filtrarEmpresa())

		console.log("Excluir: ", registro)
	}


  cancelarRegistro() {
    
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
  }


  
  async fecharRegistro(){
		this.viewCadastro = false
		await this.getRegistro()
			// .finally(() => this.filtrarEmpresa())
	}
 

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }



}
