import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CronometroService {

  constructor() { }

  start(ref?) {
    let time
    let source$ = timer(1000, 1000)
    source$.toPromise().then(seconds => {
      // console.log(seconds)
      if (ref) seconds = ref
      let mins = parseInt("" + seconds / 60);
      let secs = seconds % 60;
      let hrs = parseInt("" + mins / 60);
      mins = mins % 60
      let res = {
        'hours': hrs,
        'minutes': mins,
        'seconds': secs
      }
      time = res
      return time
    })

  }

  diferencaHorario(chegada, partida) {
    //new Date(new Date().toLocaleString()
    
    var dtChegada = chegada;
    var dtPartida = partida;
    // console.log(dtChegada)
    // console.log(dtPartida)
    try {
      var ms = moment(dtPartida, "YYYY-MM-DD HH:mm:ss").diff(moment(dtChegada, "YYYY-MM-DD HH:mm:ss"));
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
      // console.log(s)
      return s
    } catch {
      return '0'
    }

  }

  calcularTime(times) {

    const z = (n) => (n < 10 ? '0' : '') + n

    let hour = 0
    let minute = 0
    let second = 0
    for (const time of times) {
      const splited = time.split(':');
      hour += parseInt(splited[0]);
      minute += parseInt(splited[1])
      // second += parseInt(splited[2])
    }
    const seconds = second % 60
    const minutes = (minute % 60) + (second / 60)
    console.log('minutes', minutes)
    const hours = Math.floor(hour + (minute / 60))
    return z(hours) + ':' + z(minutes)
  }

}
