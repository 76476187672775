import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmitirReciboNotaFiscalModel } from '../gestao-simplificada/controle-financeiro/emitir-recibo-nota-fiscal/emitir-recibo-nota-fiscal.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const sessao = new SessaoUser
const key = '2da392a6-79d2-4304-a8b7-959572c7e44d'
const api = 'https://api.sandbox.plugnotas.com.br/'
@Injectable({
    providedIn: 'root'
})

export class NotaFiscalService {

    url = new environment().api
    api = "RotaTeste/"


    constructor(private http: HttpClient, private data: BuscarDataService) { }

    async cadastrarCertificado(certificado: FormData) {

        let result = { success: false, data: { id: '' }, message: '' }

        await this.http.post(api + 'certificado', certificado, {
            headers: { 'x-api-key': key, }
        }).toPromise()
            .then((res: any) => {
                console.log('success', res)
                result.success = true
                result.data.id = res.data.id
                result.data = res.data
                result.message = res.message
            })
            .catch(error => {
                result.success = false
                console.log('erro', error)
            })

        return result
    }

    listar() {
        return this.http.get<EmitirReciboNotaFiscalModel[]>(this.url + this.api)
    }

    enviar(registro: EmitirReciboNotaFiscalModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: EmitirReciboNotaFiscalModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: EmitirReciboNotaFiscalModel, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        } else {
            registro.status = 'SDA0002'
        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }
}