import { CdkTreeModule } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormulariosDoSistema } from 'src/app/ajustes/FormularioSistema/formularios-do-sistema.component';
import { CadastroCentroCustoService } from 'src/app/service/cadastro-centro-de-custo.service';
import { CadastroCentroLucroService } from 'src/app/service/cadastro-centro-de-lucro.service';
import { CadastroPrestadorServicoService } from 'src/app/service/cadastro-prestador-servico.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { HubDevService } from 'src/app/service/hub-dev.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { SessaoUser } from 'src/app/user.reg';
import { CadastroPrestadorServicoModel } from './cadastro-prestador-servico.model';

interface DiaPagamento {
  value: number
  valueText: string
}

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-prestador-servico',
  templateUrl: './cadastro-prestador-servico.component.html',
  styleUrls: ['./cadastro-prestador-servico.component.scss']
})
export class CadastroPrestadorServicoComponent implements OnInit {

  displayedColumns = ['detalhe', 'nomeEmpresa', 'nomeAutonomo']
  listRegistro: CadastroPrestadorServicoModel[]
  viewCadastro: boolean
  formulario: FormGroup
  pessoaJuridica: boolean
  nomeAutonomo: string
  nomeMaeAutonomo: string

  cep: string
  enderecoComercial: string
  numeroEndereco: string
  complementoEndereco: string
  bairro: string
  cidade: string
  uf: string
  telefoneComercial: string
  email: string

  tipoRegistroJuridico: string
  nrRegJuridico: string
  razaoSocial: string
  cpfRespCCusto: string
  cpfRespCLucro: string
  nomeCentroCusto: string
  nrDependentesRPA: string
  inscricaoMunicipal: string
  especialidadeAutonomo: string

  inscricaoEstadual: string
  cpfRespJuridico: string
  especialidadeJuridico: string
  dataRescisaoContrato: string


  listEmpresa = []
  dadoEmpresa
  listRemuneracao = []
  listBancos: any
  listCentroCusto = []
  listCentroLucro = []
  listPaises = []
  nomeJuridico: string
  tipoRegistroAutonomo: string
  nrRegAutonomo: string
  dadosCentroCusto
  dadosCentroLucro

  modoCriacao: boolean
  modoAlteracao: boolean
  modoExibicao: boolean

  botaocancelar: boolean
  botaoSalvar: boolean
  botaoFechar: boolean
  botaoExcluir: boolean
  botaoModificar: boolean
  botaoCancelar: boolean
  novoCadastro: boolean;
  viewConfirmCancelar: boolean;
  viewConfirmDelete: boolean
  alteracao: boolean;
  dadosFichaGeral: any;
  dadosPessoa: any;
  loading: boolean
  remuneracaoFixa: any[];
  mostraValor: boolean = false;
  mostraPorcentagem: boolean = false;

  listPagamento: DiaPagamento[] = [
    { value: 1, valueText: '01' },
    { value: 2, valueText: '02' },
    { value: 3, valueText: '03' },
    { value: 4, valueText: '04' },
    { value: 5, valueText: '05' },
    { value: 6, valueText: '06' },
    { value: 7, valueText: '07' },
    { value: 8, valueText: '08' },
    { value: 9, valueText: '09' },
    { value: 10, valueText: '10' },
    { value: 11, valueText: '11' },
    { value: 12, valueText: '12' },
    { value: 13, valueText: '13' },
    { value: 14, valueText: '14' },
    { value: 15, valueText: '15' },
    { value: 16, valueText: '16' },
    { value: 17, valueText: '17' },
    { value: 18, valueText: '18' },
    { value: 19, valueText: '19' },
    { value: 20, valueText: '20' },
    { value: 21, valueText: '21' },
    { value: 22, valueText: '22' },
    { value: 23, valueText: '23' },
    { value: 24, valueText: '24' },
    { value: 25, valueText: '25' },
    { value: 26, valueText: '26' },
    { value: 27, valueText: '27' },
    { value: 28, valueText: '28' },
    { value: 29, valueText: '29' },
    { value: 30, valueText: '30' },
    { value: 31, valueText: '31' }
  ]
  cadastroOpen: boolean = false;
  centroCustoResp: string


  constructor(
    private form: FormBuilder,
    private service: CadastroPrestadorServicoService,
    private usuario: UsuarioService,
    private token: TokenDeAcessoService,
    private _hubDev: HubDevService,
    private mensagem: MensagemService,
    private conteudo: ConteudoDeDadosService,
    private _centroCusto: CadastroCentroCustoService,
    private _centroLucro: CadastroCentroLucroService

  ) { }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getRemuneracao()
    this.getBancos()
    this.getPaises()
    this.getRegistro()
    this.getCentroCusto()
  }

  createForm() {

    this.formulario = this.form.group({
      id: '',
      nrRegistro: 0,
      cpf: '',
      codigoEmpresa: 0,
      banco: '',
      agencia: '',
      contaBancaria: '',
      centroCustoResp: 0,
      observacao: '',
      centroLucroResp: 0,
      inicioContrato: '',
      terminoContrato: '',
      tipoRemuneraContrato: '',
      pessoaJuridica: true,
      descricaoServico: '',
      pais: '',
      cnpj: '',
      inscrEstadual: '',
      inscrMunicipal: '',
      diaPagamento: 0,
      cep: '',
      enderecoComercial: '',
      numeroEndereco: '',
      complementoEndereco: '',
      bairro: '',
      cidade: '',
      uf: '',
      telefoneComercial: '',
      email: '',
      dataRescisaoContrato: '',
      valorFixo: 0,
      valorComissao: 0,
      cpfRespCCusto: '',
      cpfRespCLucro: '',
      criadoPor: '',
      criadoEm: '',
      status: '',

      ////
      nomeEmpresa: '',
      nomeAutonomo: '',
      nomeMaeAutonomo: '',
      razaoSocial: '',
      cpfResponsavel: '',
      nomeJuridico: '',
      tipoRegistroJuridico: '',
      nrRegJuridico: '',
      tipoRegistroAutonomo: '',
      nrRegAutonomo: '',
      nomeCentroCusto: new FormControl({ value: '', disabled: true }),
      nomeCentroLucro: '',
      nrDependentesRPA: '',
      especialidadeAutonomo: '',
      especialidadeJuridico: '',
      contratoIndeterminado: '',
      descNome: ''
    })
  }



  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getRemuneracao() {
    let tipo = await this.conteudo.get('TIPREM')
    this.listRemuneracao = tipo

    console.log('listRemuneracao', this.listRemuneracao)

    this.listRemuneracao.sort((a, b) => {
      let aCodigo = a.valor
      let bCodigo = b.valor
      return aCodigo < bCodigo ? -1 : 1
    })
  }

  async chamaValor() {
    let registro = this.formulario.value
    if (registro.tipoRemuneraContrato == 'TIPREM1'
      || registro.tipoRemuneraContrato == 'TIPREM2'
      || registro.tipoRemuneraContrato == 'TIPREM5') {
      this.mostraValor = true
      this._control('valorComissao').reset()
      this.mostraPorcentagem = false
    } else {
      this.mostraValor = false
      this._control('valorFixo').reset()
      this.mostraPorcentagem = true
    }
  }

  async getBancos() {
    this.service.listarBancos().subscribe(response => {
      this.listBancos = response

      this.listBancos.sort((a, b) => {
        let aCodigo = a.codigoBanco
        let bCodigo = b.codigoBanco
        aCodigo < bCodigo ? -1 : 1
      })
    })
  }

  async getCentroCusto() {
    let codigoEmpresa = this.formulario.controls['codigoEmpresa'].value
    this.listCentroCusto = await this._centroCusto.get(codigoEmpresa)
    let centroCusto = this.listCentroCusto.filter(item => item.status == 'SDA0001')
    this.listCentroCusto = centroCusto
    let custo = this._control('centroCustoResp').value
    if (custo == 0) console.log('Valor zero')


    await this.getDadosCentroCusto(codigoEmpresa, true)
  }

  //busca cpf do responsável do Centro de Custo
  async getDadosCentroCusto(codigoEmpresa, openCadastro?) {
    let valid = this._control('centroCustoResp').value
    let codigo = this._control('codigoEmpresa').value

    if (valid || openCadastro) {
      console.log('Sou válido')
      codigoEmpresa = codigo
      let centro = await this.service.listarDadosCentroCusto(codigoEmpresa)
      this.dadosCentroCusto = centro
      let data = this.dadosCentroCusto.filter(item => item.status == 'SDA0001' && item.codigo == valid)
      this.cpfRespCCusto = data[0].responsavel

    }

    this.buscarNomeCusto()
  }

  //busca nome de responsável do Centro de Custo
  async buscarNomeCusto() {
    let cpf = this._control('cpfRespCCusto').value
    let cpfSlice = cpf.slice('')

    if (cpf && cpfSlice.length == 11) {
      await this._hubDev.vCPFParametro(cpf).toPromise()
        .then(resp => {
          let cpf = resp.result
          this._control('nomeCentroCusto').setValue(cpf.nome_da_pf)
          this._control('nomeCentroCusto').disable()
        }).catch(erro => { console.log('erro') })
    } else {
      this._control('nomeCentroCusto').setValue('')
    }
  }


  async getCentroLucro() {
    let codigoEmpresa = this._control('codigoEmpresa').value
    this.listCentroLucro = await this._centroLucro.get(codigoEmpresa)
    let centroLucro = this.listCentroLucro.filter(item => item.status == 'SDA0001')
    this.listCentroLucro = centroLucro

    await this.getDadosCentroLucro(codigoEmpresa, true)
  }

  //busca cpf do responsavel do Centro de Lucro
  async getDadosCentroLucro(codigoEmpresa, openCadastro?) {
    let valid = this._control('centroLucroResp').value
    let codigo = this._control('codigoEmpresa').value

    if (valid || openCadastro) {
      codigoEmpresa = codigo
      let centro = await this._centroLucro.get(codigoEmpresa)
      this.dadosCentroLucro = centro
      let data = this.dadosCentroLucro.filter(item => item.status == 'SDA0001' && item.codigo == valid)
      this.cpfRespCLucro = ''
      this.cpfRespCLucro = data[0].responsavel
      this._control('cpfRespCLucro').disable()
    }

    await this.buscarNomeLucro()
  }

  //busca nome do responsavel do Centro de Lucro
  async buscarNomeLucro() {
    let cpf = this.formulario.controls['cpfRespCLucro'].value
    await this._hubDev.vCPFParametro(cpf).toPromise()
      .then(resp => {
        let cpf = resp.result
        this._control('nomeCentroLucro').setValue(cpf.nome_da_pf)
        this._control('nomeCentroLucro').disable()

      })
  }

  async getPaises() {
    this.service.listarPaises().subscribe(response => {
      this.listPaises = response
    })
  }

  async changeCheckbox(item) {
    let registro = this._control('contratoIndeterminado').value
    if (item) {
      console.log('checked true')
      this._control('dataRescisaoContrato').setValue("9999-12-31")
      this._control('dataRescisaoContrato').disable()

    } else {
      console.log('checked false')
      this._control('dataRescisaoContrato').setValue('')
      this._control('dataRescisaoContrato').enable()
    }
  }


  adicionarCadastro() {
    this.formulario.reset()
    this.viewCadastro = true
    this.alteracao = false
    this._control('pessoaJuridica').setValue(false)
    this.formulario.disable()
    this._control('codigoEmpresa').enable()
    this._control('pessoaJuridica').enable()
    this._control('cpf').enable()
    this._control('cnpj').enable()
    this.novoCadastro = true
    this.cadastroOpen = false
    this.getModo(true, false, false)
  }

  fecharRegistro() {
    this.viewCadastro = false
    this.formulario.reset()
    this.getRegistro()
  }

  cancelarRegistro(confirm?) {
    let reg = this.formulario.getRawValue()
    if (confirm) {
      this.viewConfirmCancelar = true
      return
    }

    if (this.novoCadastro) {
      this.formulario.reset()
      this.formulario.disable()
      this.alteracao = false
      this.viewConfirmCancelar = false
      this.novoCadastro = false
      this.botaoFechar = true
      this.botaoCancelar = false
      this.botaoSalvar = false
      return
    }

    this.viewConfirmCancelar = false
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)

    // this.deleteCampos(reg)
    this.formulario.patchValue(registro)
    if (registro.cnpj) this.validaCNPJ()
    if (registro.cpf) this.validaCPF()
    // this.formulario.disable() 
    this.cadastroOpen = true
    this.alteracao = false
    this.getModo(false, false, true)
  }

  // valida se cpf é menor que 11 dígitos
  async validaCPF() {
    let cpf = this._control('cpf').value
    let cpfSlice = cpf.slice('')

    if (cpfSlice.length == 11) {

      // função valida se cpf existe.
      await this._hubDev.vCPFParametro(cpf).toPromise()
        .then(resp => {
          if (!resp.status) {
            return this.mensagem.creat('CPF Inválido', { erro: true })
          } else {
            this.getDadosEmpresa()
          }
        }).catch(error => console.log('error', error))
    }

    else {
      this.nomeAutonomo = ''
      this.nomeMaeAutonomo = ''
      this.nrDependentesRPA = ''
      this.inscricaoMunicipal = ''
      this.tipoRegistroAutonomo = ''
      this.nrRegAutonomo = ''
      this.especialidadeAutonomo = ''
      this.cep = ''
      this.enderecoComercial = ''
      this.numeroEndereco = ''
      this.complementoEndereco = ''
      this.bairro = ''
      this.cidade = ''
      this.uf = ''
      this.telefoneComercial = ''
      this.email = ''
      this._control('nrDependentesRPA').disable()
      this._control('inscrMunicipal').disable()
      this._control('cep').disable()
      this._control('enderecoComercial').disable()
      this._control('numeroEndereco').disable()
      this._control('complementoEndereco').disable()
      this._control('bairro').disable()
      this._control('cidade').disable()
      this._control('uf').disable()
      this._control('telefoneComercial').disable()
      this._control('email').disable()
      this._control('pessoaJuridica').enable()
    }
  }

  //procura dados da empresa em FichaGeralEmpresa()
  async getDadosEmpresa() {
    let valid = this._control('cpf').value


    if (valid) {
      let empresa = await this.service.getDadosEmpresa()
      let pessoa = await this.usuario.getRegistroPessoa(valid)
      this.dadosFichaGeral = empresa
      let data = this.dadosFichaGeral.filter(item => item.cpfAutonomo == valid)

      this.formulario.enable()

      if (data == false) {
        if (pessoa.cpf == valid) {
          this.mensagem.creat('O(a) usuário(a) não possui empresa cadastrada no MyClinics. Insira os dados da empresa.', { erro: true })
        } else {
          this.mensagem.creat('O(a) usuário(a) não possui cadastro de empresa ou pessoa no MyClinics. Por favor, insira um CPF cadastrado.', { erro: true })
        }
      }
      else {
        this.mensagem.creat('Este(a) usuário(a) já possui cadastro no MyClinics.', { sucesso: true })

        this.nrDependentesRPA = data[0].nrDepRPA
        this.inscricaoMunicipal = data[0].inscricaoMunicipal
        this.cep = data[0].cep
        this.enderecoComercial = data[0].rua
        this.numeroEndereco = data[0].numeroEndereco
        this.complementoEndereco = data[0].complemento
        this.bairro = data[0].bairro
        this.cidade = data[0].cidade
        this.uf = data[0].estado
        this.telefoneComercial = data[0].telefoneEmpresa
        this.email = data[0].email

        if (this.nrDependentesRPA == '0' || this.nrDependentesRPA == null) this._control('nrDependentesRPA').disable()
        if (this.inscricaoMunicipal == null) this._control('inscrMunicipal').disable()
        if (this.cep == null) this._control('cep').disable()
        if (this.enderecoComercial == null) this._control('enderecoComercial').disable()
        if (this.numeroEndereco == null) this._control('numeroEndereco').disable()
        if (this.complementoEndereco == null) this._control('complementoEndereco').disable()
        if (this.bairro == null) this._control('bairro').disable()
        if (this.cidade == null) this._control('cidade').disable()
        if (this.uf == null) this._control('uf').disable()
        if (this.telefoneComercial == null) this._control('telefoneComercial').disable()
        if (this.email == null) this._control('email').disable()

      }

      // em openCadastro(), esse trecho desabilita os botões
      if (this.cadastroOpen == true) this.formulario.disable()
      await this.buscarAssinante(valid)
    }
  }

  // procura dados da pessoa em CadastroPessoa() e CadastroDadosProfissionais() 
  async buscarAssinante(cpf, openCadastro?) {
    let validCPF = this.formulario.controls['cpf'].value
    let cutCPF = validCPF.slice('')

    let usuario = await this.usuario.getRegistro(cpf)
    let pessoa = await this.usuario.getRegistroPessoa(cpf)
    let profissional = await this.usuario.getRegistroProfissional(cpf)
    let conteudo = await this.conteudo.get('TIPREP', true, profissional.tipoInscricao)
    let conteudoEsp = await this.conteudo.get('ESPPRO', true, profissional.especialidadeProfissional)

    if (cutCPF.length == 11) {

      if (pessoa.cpf != validCPF || openCadastro) {
        this.formulario.disable()
        this._control('codigoEmpresa').enable()
        this._control('pessoaJuridica').enable()
        this._control('cpf').enable()
        this._control('cnpj').enable()
      }

      if (pessoa.cpf == validCPF || openCadastro) {
        this.nomeAutonomo = usuario.nomeCompleto
        this.nomeMaeAutonomo = pessoa.nomeMae
        this.nrRegAutonomo = profissional.nr_Registro
        this.tipoRegistroAutonomo = conteudo.toString()
        this.especialidadeAutonomo = conteudoEsp.toString()

        this._control('nomeAutonomo').disable()
        this._control('nomeMaeAutonomo').disable()
        this._control('nrRegAutonomo').disable()
        this._control('tipoRegistroAutonomo').disable()
        this._control('especialidadeAutonomo').disable()
        this._control('pessoaJuridica').disable()
        this._control('enderecoComercial').disable()
        this._control('bairro').disable()
        this._control('cidade').disable()
        this._control('uf').disable()

        if (this.nrDependentesRPA) this._control('nrDependentesRPA').disable()
        if (this.inscricaoMunicipal) this._control('inscrMunicipal').disable()
        if (this.cep) this._control('cep').disable()
        if (this.numeroEndereco) this._control('numeroEndereco').disable()
        if (this.complementoEndereco) this._control('complementoEndereco').disable()
        if (this.telefoneComercial) this._control('telefoneComercial').disable()
        if (this.email) this._control('email').disable()
      }
    }

  }

  // valida se cnpj tem 14 dígitos
  async validaCNPJ() {

    let cnpj = this._control('cnpj').value
    let cnpjSlice = cnpj.slice('')
    if (cnpjSlice.length == 14) {
      this._control('pessoaJuridica').disable()
      this.getDadosEmpresaJuridica()
    }

    else {
      this.razaoSocial = ''
      this.inscricaoEstadual = ''
      this.cpfRespJuridico = ''
      this.nomeJuridico = ''
      this.tipoRegistroJuridico = ''
      this.nrRegJuridico = ''
      this.especialidadeJuridico = ''
      this.cep = ''
      this.enderecoComercial = ''
      this.numeroEndereco = ''
      this.complementoEndereco = ''
      this.bairro = ''
      this.cidade = ''
      this.uf = ''
      this.telefoneComercial = ''
      this.email = ''

      this._control('inscrEstadual').disable()
      this._control('tipoRegistroJuridico').disable()
      this._control('nrRegJuridico').disable()
      this._control('especialidadeJuridico').disable()
      this._control('cep').disable()
      this._control('enderecoComercial').disable()
      this._control('numeroEndereco').disable()
      this._control('complementoEndereco').disable()
      this._control('bairro').disable()
      this._control('cidade').disable()
      this._control('uf').disable()
      this._control('telefoneComercial').disable()
      this._control('email').disable()
      this._control('cpfResponsavel').disable()
      this._control('pessoaJuridica').enable()
    }
  }

  // busca dados de empresa em FichaGeralEmpresa()
  async getDadosEmpresaJuridica() {
    let valid = this._control('cnpj').value
    let cpfValido = this._control('cpfResponsavel').value

    this.formulario.enable()

    if (valid) {
      let empresa = await this.service.getDadosEmpresa()
      this.dadosFichaGeral = empresa
      let data = this.dadosFichaGeral.filter(item => item.cnpj == valid)

      if (data == false) {
        return await this.buscaCNPJInvalido()
      } else {
        this.mensagem.creat('Este(a) usuário(a) já possui cadastro no MyClinics.', { sucesso: true })

        this.razaoSocial = data[0].nomeEmpresa
        this.inscricaoEstadual = data[0].inscricaoEstadual
        this.cpfRespJuridico = data[0].cpfRespClinico
        this.cep = data[0].cep
        this.enderecoComercial = data[0].rua
        this.numeroEndereco = data[0].numeroEndereco
        this.complementoEndereco = data[0].complemento
        this.bairro = data[0].bairro
        this.cidade = data[0].cidade
        this.uf = data[0].estado
        this.telefoneComercial = data[0].telefoneEmpresa
        this.email = data[0].email

        this._control('razaoSocial').disable()
        this._control('inscrEstadual').disable()
        this._control('cpfResponsavel').disable()
        this._control('nomeJuridico').disable()
        this._control('tipoRegistroJuridico').disable()
        this._control('nrRegJuridico').disable()
        this._control('especialidadeJuridico').disable()
        this._control('cep').disable()
        this._control('enderecoComercial').disable()
        this._control('numeroEndereco').disable()
        this._control('complementoEndereco').disable()
        this._control('bairro').disable()
        this._control('cidade').disable()
        this._control('uf').disable()
        this._control('telefoneComercial').disable()
        this._control('email').disable()
        this._control('pessoaJuridica').disable()
      }
      await this.buscarAssinanteJuridica(cpfValido)
    }
    if (this.cadastroOpen == true) this.formulario.disable()
  }

  // busca dados de pessoa em CadastroPessoa() e CadastroDadosProfissionais()
  async buscarAssinanteJuridica(cpf, openCadastro?) {
    let validCPF = this._control('cpfResponsavel').value
    let cutCNPJ = validCPF.slice('')

    let usuario = await this.usuario.getRegistro(cpf)
    let pessoa = await this.usuario.getRegistroPessoa(cpf)
    let profissional = await this.usuario.getRegistroProfissional(cpf)
    let conteudo = await this.conteudo.get('TIPREP', true, profissional.tipoInscricao)
    let conteudoEsp = await this.conteudo.get('ESPPRO', true, profissional.especialidadeProfissional)

    this.formulario.enable()

    if (cutCNPJ.length == 11) {
      if (pessoa.cpf != validCPF || openCadastro) {
        this._control('razaoSocial').disable()
        this._control('inscrEstadual').disable()
        this._control('cpfResponsavel').disable()
        this._control('nomeJuridico').disable()
        this._control('tipoRegistroJuridico').disable()
        this._control('nrRegJuridico').disable()
        this._control('especialidadeJuridico').disable()
        this._control('cep').disable()
        this._control('enderecoComercial').disable()
        this._control('numeroEndereco').disable()
        this._control('complementoEndereco').disable()
        this._control('bairro').disable()
        this._control('cidade').disable()
        this._control('uf').disable()
        this._control('telefoneComercial').disable()
        this._control('email').disable()
      }

      if (pessoa.cpf == validCPF || openCadastro) {
        this.nomeJuridico = usuario.nomeCompleto
        this.nrRegJuridico = profissional.nr_Registro
        this.tipoRegistroJuridico = conteudo.toString()
        this.especialidadeJuridico = conteudoEsp.toString()

        this._control('nomeJuridico').disable()
        this._control('nrRegJuridico').disable()
        this._control('tipoRegistroJuridico').disable()
        this._control('especialidadeJuridico').disable()
        this._control('pessoaJuridica').disable()

        if (this.razaoSocial) this._control('razaoSocial').disable()
        if (this.inscricaoEstadual) this._control('inscrEstadual').disable()
        if (this.cpfRespJuridico) this._control('cpfResponsavel').disable()
        if (this.cep) this._control('cep').disable()
        if (this.numeroEndereco) this._control('numeroEndereco').disable()
        if (this.complementoEndereco) this._control('complementoEndereco').disable()
        if (this.bairro) this._control('bairro').disable()
        if (this.cidade) this._control('cidade').disable()
        if (this.uf) this._control('uf').disable()
        if (this.telefoneComercial) this._control('telefoneComercial').disable()
        if (this.email) this._control('email').disable()
      }
    }

    if (this.cadastroOpen == true) this.formulario.disable()
  }



  //busca dados de empresa em HubDev caso não exista em FichaGeralEmpresa()
  async buscaCNPJInvalido() {
    if (this._control('cnpj').invalid) return

    let cnpj = this._control('cnpj').value

    this.formulario.enable()

    await this._hubDev.vCNPJ(cnpj).toPromise()
      .then(resp => {
        if (!resp.status) {
          this.formulario.disable()
          this._control('codigoEmpresa').enable()
          this._control('pessoaJuridica').enable()
          this._control('cpf').enable()
          this._control('cnpj').enable()
          this.mensagem.creat('CNPJ inválido.', { erro: true })
        } else {
          let cnpj = resp.result

          this.razaoSocial = cnpj.nome
          this.enderecoComercial = cnpj.logradouro
          this.numeroEndereco = cnpj.numero
          this.complementoEndereco = cnpj.complemento
          this.bairro = cnpj.bairro
          this.cep = cnpj.cep
          this.cidade = cnpj.municipio
          this.uf = cnpj.uf
          this.telefoneComercial = cnpj.telefone
          this.email = cnpj.email

          this._control('razaoSocial').disable()
          this._control('cep').disable()
          this._control('enderecoComercial').disable()
          this._control('numeroEndereco').disable()
          this._control('complementoEndereco').disable()
          this._control('bairro').disable()
          this._control('cidade').disable()
          this._control('uf').disable()
          this._control('telefoneComercial').disable()
          this._control('email').disable()
          this._control('pessoaJuridica').disable()
          this._control('nomeJuridico').disable()
          this._control('tipoRegistroJuridico').disable()
          this._control('nrRegJuridico').disable()
          this._control('especialidadeJuridico').disable()

          this.mensagem.creat('O(a) usuário(a) não possui empresa cadastrada no MyClinics. Por favor, insira os dados do responsável  .', { erro: true })
        }
      }).catch(error => console.log('error', error))
  }

  // busca o nome do responsável pela empresa em HubService (verificar)
  async buscaNomeRespInvalido() {
    let cpf = this._control('cpfResponsavel').value
    let usuario = await this.usuario.getRegistro(cpf)
    let profissional = await this.usuario.getRegistroProfissional(cpf)
    let conteudo = await this.conteudo.get('TIPREP', true, profissional.tipoInscricao)
    let conteudoEsp = await this.conteudo.get('ESPPRO', true, profissional.especialidadeProfissional)
    let cpfSlice = cpf.slice('')

    if (cpfSlice.length == 11) {
      if (cpf == usuario.cpf && cpf == profissional.cpf) {
        this._control('nomeJuridico').setValue(usuario.nomeCompleto)

        this._control('nrRegJuridico').setValue(profissional.nr_Registro)
        this._control('tipoRegistroJuridico').setValue(conteudo.toString())
        this._control('especialidadeJuridico').setValue(conteudoEsp.toString())
      }
      else {
        return this.mensagem.creat('Usuário(a) não cadastrado(a) no MyClinics. Por favor insira um CPF cadastrado.', { erro: true })
      }
    } else {
      this._control('nomeJuridico').reset()
      this._control('nrRegJuridico').reset()
      this._control('tipoRegistroJuridico').reset()
      this._control('especialidadeJuridico').reset()
    }
  }

  // busca o endereço de HubDev
  async buscarCEP() {
    if (this._control('cep').invalid) return

    let cep = this._control('cep').value

    this._hubDev.vCEP(cep).toPromise()
      .then(resp => {
        let cep = resp.result
        this._control('enderecoComercial').setValue(cep.logradouro)
        this._control('bairro').setValue(cep.bairro)
        this._control('cidade').setValue(cep.localidade)
        this._control('uf').setValue(cep.uf)
      })
  }

  async getRegistro() {
    this.loading = true

    await this.service.listar().toPromise()
      .then(response => {
        this.listRegistro = response.filter(item => item.status == 'SCA0005')
        this.listRegistro.forEach(async item => {
          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)

          if (empresa.length > 0) item.nomeEmpresa = empresa[0].nomeEmpresa

          console.log('getRegistro', response)
        })
        this.loading = false
      })
  }

  async inserir() {

    let registro = this.formulario.getRawValue()

    // função para deletar os parâmetros nulos.
    Object.keys(registro).forEach(
      value => {
        if (!registro[value]) delete registro[value]
      }
    )

    registro.criadoPor = sessao.cpf
    registro.status = 'SCA0005'
    // registro.centroCustoResp = this.centroCustoResp

    // this.deleteCampos(registro)

    this.service.enviar(registro).toPromise()
      .then(resposta => {
        this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.getModo(true, false, false)
        this.alteracao = false
        this.botaoSalvar = false
        this.botaoFechar = true
        this.botaoCancelar = false
        this.botaoModificar = false
        this.botaoExcluir = false
      }).catch(
        error => {
          let erro = error.error
          console.log('error', error)
          this.mensagem.creat(erro, { erro: true })
        }
      )
    console.log('Inserir: ', registro)
  }

  async openCadastro(registro) {

    this.formulario.patchValue(registro)

    if (registro.cpf) await this.validaCPF()
    if (registro.cnpj) await this.validaCNPJ()
    await this.getCentroCusto()
    await this.getCentroLucro()
    await this.chamaValor()
    // await this.changeCheckbox()


    this.formulario.disable()
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    this.cadastroOpen = true
    this.viewCadastro = true
    this.alteracao = false
    this.getModo(false, false, true)
    console.log('openCadastr', registro)
  }

  async modificarCadastro() {
    let registro = this.formulario.value
    this.alteracao = true
    this.cadastroOpen = false
    if (registro.cnpj) await this.validaCNPJ()
    if (registro.cpf) await this.validaCPF()
    this._control('cpfRespCCusto').disable()
    this._control('nomeCentroCusto').disable()
    this._control('cpfRespCLucro').disable()
    this._control('nomeCentroLucro').disable()
    this.getModo(false, true, false)
  }

  async alterar() {
    let registro = this.formulario.value

    // função para deletar os parâmetros nulos.
    Object.keys(registro).forEach(
      value => {
        if (!registro[value]) delete registro[value]
      }
    )

    this.deleteCampos(registro)

    this.service.alterar(registro).toPromise()
      .then(() => {
        this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.alteracao = false
        this.getModo(false, false, true)
      })

    console.log('alterar', registro)
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    // função para deletar os parâmetros nulos.
    Object.keys(registro).forEach(
      value => {
        if (!registro[value]) delete registro[value]
      }
    )

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
    this.formulario.reset()
  }


  _control(formControlName) {
    return this.formulario.controls[formControlName]
  }

  async getModo(criacao, alteracao, exibicao) {
    if (criacao) {
      this.modoCriacao = true
      this.modoAlteracao = false
      this.modoExibicao = false
      this.botaoCancelar = true
      this.botaoExcluir = false
      this.botaoModificar = false
      this.botaoFechar = false
      this.botaoSalvar = true
    } if (alteracao) {
      this.modoCriacao = false
      this.modoAlteracao = true
      this.modoExibicao = false
      this.botaoModificar = false
      this.botaoSalvar = true
      this.botaoExcluir = false
      this.botaoCancelar = true
      this.botaoFechar = false
    } if (exibicao) {
      this.modoCriacao = false
      this.modoAlteracao = false
      this.modoExibicao = true
      this.botaoModificar = true
      this.botaoExcluir = true
      this.botaoFechar = true
      this.botaoSalvar = false
      this.botaoCancelar = false
    }
  }

  // delete os campos que inseri manualmente no formulario
  deleteCampos(registro) {
    delete registro.razaoSocial
    delete registro.nomeJuridico
    delete registro.tipoRegistroJuridico
    delete registro.nrRegJuridico
    delete registro.especialidadeJuridico
    delete registro.nomeAutonomo
    delete registro.nomeMaeAutonomo
    delete registro.nrDependentesRPA
    delete registro.tipoRegistroAutonomo
    delete registro.nrRegAutonomo
    delete registro.especialidadeAutonomo
    delete registro.nomeCentroCusto
    delete registro.nomeCentroLucro
  }

}
