export class cadastroUsuario {
  id: string
  usuarioId: string
  numeroDocumento: string
  dataInclusaoDocumento: string
  nomeCompleto: string
  nomeSocial: string
  foto: string
  genero: string
  email: string
  dataNascimento: string
  cpf: string
  telefone: string
  termosDeAceite: true
  tipoUsuario: string
  acesso: string
  status: string
  confirmado: true
  companyId: string
  assinante: boolean
  criadoPor : string
}

export class CadastroPessoa {
  id: string
  usuarioId: string
  numeroDocumento: string
  dataInclusaoDocumento: string
  cpf: string
  nome: string
  email: string
  cpfPai: string
  cpfMae: string
  cartaoSUS: string
  possuiPlanoDeSaude: true
  planoDeSaude: string
  numeroBeneficiario: string
  cep: string
  endereco: string
  numeroEndereco: string
  bairro: string
  complemento: string
  cidade: string
  estado: string
  tipoPessoa: string
  usaMyClinics: true
  ativo: true
  operadoraConvenio: string
  dataNascimento: string
  sexo: string
  estadoCivil: string
  grauParentescoTitular: string
  grauParentescoSecundario: string
  telefone: string
  cpfConjuge: string
  cpfTutorLegal: string
  cpfResponsavelTitular: string
  rg: string
  registroProfissional: string
  tituloDeEleitor: string
  numeroPIS: string
  naturalidade: string
  telefoneFixo: string
  telefoneCelular: string
  nomePai: string
  nomeMae: string
  cor: string
  deficiente: true
  tipoDeficiencia: string
  nacionalidade: string
  dataChegadaBrasil: string
  dataEmissao_RG: string
  orgao: string
  habilitacao: string
  validade: string
  nrCarteiraTrabalho: string
  serie: string
  digito: string
  dataEmissao_CT: string
  uf_CT: string
  carteiraReservista: string
  tituloEleitor: string
  cidadeVotacao: string
  estado_TE: string
  zona: string
  sessao: string
  grauInstrucao: string
  ric: string
  passaPorte: string
  pis: string
  bancoPIS: string
  contaFGTS: string
  dataOpcao: string
  certidaoTipo: string
  certidaoLivro: string
  certidaoFolha: string
  certidaoMatricula: string
  rua: string
  criadoPor: string
  criadoEm: string
  status: string

}

export class CadastroDadosProfissionais {
  id: string
  criadoPor: string
  cpf: string
  profissao: string
  status: string
  tipoInscricao: string
  situacao: string
  especialidadeProfissional: string
  timeStamp: string
  unidadeFederacao: string
  nr_Registro: string
  formaDeTratamento: string
}

export class CadastroDadosProfissionaisJoin {
  id: string
  cpf: string
  nomeCompleto: string
  nomeSocial: string
  profissao: string
  status: string
  timeStamp: string
  especialidadeProfissional: string
  nr_Registro: string
  valor: string
  valorEspecialidade: string
  unidadeFederacao: string
  dataNascimento: string
  tipoInscricao: string
}
export class UsuarioConsultaGeral {
  dataInclusaoDocumento: string
  nomeCompleto: string
  nomeSocial: string
  foto: string
  email: string
  dataNascimento: string
  cpf: string
  telefone: string
  tipoUsuario: string
  descTipoUsuario: string
  status: string
  cpfPai: string
  nomePai: string
  cpfMae: string
  nomeMae: string
  cartaoSUS: string
  possuiPlanoDeSaude: true
  planoDeSaude: string
  numeroBeneficiario: string
  cep: string
  endereco: string
  numero: string
  bairro: string
  complemento: string
  cidade: string
  estado: string
  tipoPessoa: string
  descTipoPessoa: string
  usaMyClinics: true
  operadoraConvenio: string
  sexo: string
  estadoCivil: string
  grauParentescoTitular: string
  grauParentescoSecundario: string
  cpfConjuge: string
  nomeConjuge: string
  cpfTutorLegal: string
  nomeTutorLegal: string
  cpfResponsavelTitular: string
  nomeResponsavelTitular: string
  rg: string
  tituloDeEleitor: string
  numeroPIS: string
  naturalidade: string
  profissao: string
  descProfissao: string
  tipoInscricao: string
  descTipoInscricao: string
  situacao: string
  descSituacao: string
  especialidadeProfissional: string
  descEspecialidadeProfissional: string
  unidadeFederacao: string
  nr_Registro: string
  formaDeTratamento: string
  descFormaDeTratamento: string
  criadoEm: string
  criadoPor: string
  usuarioLogado: boolean
  dependentes: []
}