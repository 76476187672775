import { Component, Input, OnInit } from '@angular/core';
import { CadastroPessoaForm } from 'src/app/cadastro-paciente/model';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
@Component({
  selector: 'app-receita-eletronica',
  templateUrl: './receita-eletronica.component.html',
  styleUrls: ['./receita-eletronica.component.scss']
})
export class ReceitaEletronicaComponent implements OnInit {
  @Input('paciente') paciente = new CadastroPessoaForm
  code = 'rodrigo'
  user = sessao
  constructor() {

  }

  ngOnInit() {
  }

}
