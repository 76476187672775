import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SistemaClassificacaoDadosService } from '../service/sistema-classificacao-dados.service';
import { CamposFichaGeralEmpresas } from '../ajustes/posicao-numeracao-nfe/campos-posicaoNfe';
import { DeterminarListaPrecoServiceService } from '../service/determinar-lista-preco-service.service';
import { CamposListaPrecos } from '../ajustes/lista-precos-venda/Determinacao-Listaprecos';
import { BuscarDataService } from '../service/buscar-data.service';
import { AreaAtuacao } from '../service/area-atuacao/AreaAtuacao.model';
import { MensagemService } from '../service/mensagem.service';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { SessaoUser } from '../user.reg';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { DadosFichaGeralEmpresa } from '../configuracao-inicial/regras-de-imposto/model';
import { FichaDeAssinanteService } from '../service/ficha-de-assinante/ficha-de-assinante.service';
import { CadastroItemService } from '../service/cadastro-item-service.service';
import { FaturaMedicaService } from '../service/fatura-medica/fatura-medica.service';
import { OrdemDeVendaMedicaCabecalho } from '../service/fatura-medica/modal';
import { EmpresaCompletaService } from '../service/todos-dados-empresa/empresa-completa.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { FichaGeralEmpresa } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';

const sessao = new SessaoUser
@Component({
  selector: 'app-apurar-imposto',
  templateUrl: './apurar-imposto.component.html',
  styleUrls: ['./apurar-imposto.component.scss']
})
export class ApurarImpostoComponent implements OnInit {
  mes: any
  listEmpresa = []
  viewCadastro: boolean
  formulario: FormGroup
  cpf: string
  menu1 = 1
  menu2 = 1
  tipoEmpresa: string
  menuTabs = false
  alterar: false
  arquivo: any
  listar: Boolean
  listCheckCadastroItem = []
  // displayedColumns = ['detalhe', 'nomeEmpresa', 'imposto', 'aliquota', 'faturamentoBruto', 'montante','vencimento']
  displayedColumns = ['detalhe', 'numeroDocumento', 'dataLancamento', 'valorTotalDocumento', 'valorTotalImposto', 'moedaDeDocumento', 'noConsidera']
  displayedColumns_2 = ['demostrativo', 'imposto', 'aliquota', 'montanteBc', 'montanteIR', 'vencimento']
  id: any
  ProvisaoImposto
  GetDadosEmpresa = []

  ListaPrecoCodigoEmpresa: CamposListaPrecos

  registroAreaAtuacao: Array<AreaAtuacao>
  resultadoAssinante: any;

  loadings: boolean
  listOrdemVendas: OrdemDeVendaMedicaCabecalho[] = [];
  dataSource: MatTableDataSource<OrdemDeVendaMedicaCabecalho>;
  totalDocumento: number;
  totalImposto: number;
  registroEmpresa = new FichaGeralEmpresa
  autonomo: boolean;
  viewApurarImposto: boolean;
  regimeTributario: string;
  pageIndex: number;
  loadingDoc = {
    start: false,
    um: true,
    dois: true,
    end: false,
  }
  viewNota
  constructor(
    private form: FormBuilder,
    private mensagem: MensagemService,
    public _date: BuscarDataService,
    private service: FaturaMedicaService,
    private _conteudo: ConteudoDeDadosService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private fichaGeralService: FichaGeralService,
    private serviceCadastroItem: CadastroItemService
  ) {

  }

  ngOnInit() {
    this.creatForm()
    this.getDate()
    this.getEmpresa()
  }
  startLoading(end?) {
    this.viewNota = false
    if (end) {
      this.loadingDoc.start = false
      this.loadingDoc.um = true
      this.loadingDoc.dois = true
      this.loadingDoc.end = false
      return
    }
    this.loadingDoc.start = true
    setTimeout(() => { this.loadingDoc.um = false }, 2000)
    setTimeout(() => { this.loadingDoc.dois = false }, 3000)
    setTimeout(() => { this.loadingDoc.end = true }, 3100)
  }
  view(page) {
    this.pageIndex = page
  }
  getOrdemVendas(empresa) {
    this.service.getOrdemDeVendaMedicaCabecalho().toPromise()
      .then(res => {
        let filter = res.filter(value => value.codigoEmpresa == empresa)

        let getNumDocumento = filter.map(value => value.valorTotalDocumento)
        let getNumImposto = filter.map(value => value.valorTotalImposto)
        try {
          this.totalDocumento = getNumDocumento.reduce((n, total) => total + n)
          this.totalImposto = getNumImposto.reduce((n, total) => total + n)
        }
        catch {

        }


        this.ProvisaoImposto = [
          {
            imposto: 'ISS',
            aliquota: '2%',
            montanteBc: this.totalDocumento,
            montanteIR: Math.round((this.totalDocumento * 0.02) * 100) / 100,
            vencimento: '10/12/2020'
          },
          {
            imposto: 'COFINS',
            aliquota: '3,00%',
            montanteBc: this.totalDocumento,
            montanteIR: Math.round((this.totalDocumento * 0.03) * 100) / 100,
            vencimento: '25/12/2020'
          },
          {
            imposto: 'PIS',
            aliquota: '0,65%',
            montanteBc: this.totalDocumento,
            montanteIR: Math.round((this.totalDocumento * 0.0065) * 100) / 100,
            vencimento: '25/12/2020'
          },
          {
            imposto: 'IRPJ',
            aliquota: '15% S/R$20.000 e  25%  S/  Acima de R$20.000',
            montanteBc: this.totalDocumento,
            montanteIR: Math.round((this.totalDocumento * 0.15) * 100) / 100,
            vencimento: '30/12/2020'
          },
          {
            imposto: 'CSLL',
            aliquota: '9%',
            montanteBc: this.totalDocumento,
            montanteIR: Math.round((this.totalDocumento * 0.09) * 100) / 100,
            vencimento: '20/12/2020'
          },
          {
            imposto: 'IRRF s/ Pró-Labore',
            aliquota: '27.50%',
            montanteBc: '5.000',
            montanteIR: '1.375',
            vencimento: '20/12/2020'
          },
          {
            imposto: 'INSS s/ Pró-Labore',
            aliquota: '11%',
            montanteBc: '5.000',
            montanteIR: '5500',
            vencimento: '20/12/2020'
          },
        ]
        this.dataSource = new MatTableDataSource(filter)
        this.listOrdemVendas = filter
      })

    // this.fichaGeralService.getFichaGeralEmpresaID(empresa).toPromise()
    //   .then(async res => {
    //     let empresa: FichaGeralEmpresa = res[0]
    //     let desc = await this._conteudo.get('REGTRIB', true, empresa.regimeTributario)
    //     this.regimeTributario = empresa.regimeTributario

    //     if (desc[0]) {
    //       empresa.regimeTributario = desc[0].toString()
    //     }
    //     this.registroEmpresa = empresa
    //     this.formulario.controls['cnpj'].setValue(empresa.cnpj)
    //   })
  }
  getEmpresa() {
    this.listEmpresa = JSON.parse(sessao.listEmpresas)
    console.log('empresas', this.listEmpresa)
  }
  getDate() {
    let now = new Date().getMonth() 
    let ano = new Date().getFullYear()
    let mes = this._date.getMeses(now)
    this.mes = `${mes.name}/${ano}`
    console.log('MES', this.mes)
  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: '',
      criadoPor: '',
      nomeEmpresa: new FormControl('', Validators.required),
      aliquota: new FormControl('', Validators.required),
      faturamentoBruto: new FormControl('', Validators.required),
      montante: new FormControl('', Validators.required),
      vencimento: new FormControl('', Validators.required),
      periodo: new FormControl('', Validators.required),
      notasFiscais: new FormControl('', Validators.required),
      recibos: new FormControl('', Validators.required),
      aliquotaProvisao: new FormControl('', Validators.required),
      nomeImposto: new FormControl('', Validators.required),
      impostoPF: new FormControl('', Validators.required),
      cnpj: new FormControl({ disabled: true }),
      regraImposto: new FormControl('', Validators.required),
      dispesasDedutiveis: new FormControl('', Validators.required),
      receita: new FormControl('', Validators.required),
      faturamento: new FormControl('', Validators.required),
      impostoBruto: new FormControl('', Validators.required),
      receitaBruta: new FormControl('', Validators.required),
      receitaLiquida: new FormControl('', Validators.required),
      deducaoTabelaIRF: new FormControl('', Validators.required),
      ImpostoRecolher: new FormControl('', Validators.required),

    })
  }

}
