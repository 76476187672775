import { QRCodeModule } from 'angularx-qrcode';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { MatTableModule, MatInputModule, MatFormFieldModule, MatSortModule, MatSelectModule, MatTabsModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatMenuModule, MatExpansionModule, MatSnackBarModule, MatButtonToggleModule, MatRadioModule, MatAutocompleteModule, MatPaginatorModule, MatTreeModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDividerModule, MatListModule, MatCardModule, MatProgressBarModule, MatSidenavModule, MatDialogModule, MatSlideToggleModule, MatStepperModule, MatBadgeModule } from '@angular/material';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LoadingComponent } from './loading/loading.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { HomeComponent } from './home/home.component';
import { UsuarioService, UsuarioDependenteService, UsuarioQuadroDeSaudeService, UsuarioPreNatalService, CarteiraVacinacaoService } from './service/usuario.service';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { NgxImageUploadBase64Module } from 'ngx-image-upload-base';
import { DependentesComponent } from './usuario/dependentes/dependentes.component';
import { PreNatalComponent } from './usuario/pre-natal/pre-natal.component';
import { AgendaComponent } from './agenda/agenda/agenda.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CarteiraVacinaComponent } from './usuario/carteira-vacina/carteira-vacina.component';
import { AgendaService } from './service/agenda.service';
import { SalaEsperaComponent } from './atendimento-clinico/sala-espera/sala-espera.component';
import { SalaEsperaService } from './service/sala-espera.service';
import { SenhaAtendimentoService } from './service/senha-atendimento.service';
import { DocumentacaoComponent } from './documentacao/documentacao.component';
import { BuscarDataService } from './service/buscar-data.service';
import { CadastroPacienteComponent } from './cadastro-paciente/cadastro-paciente.component';
import { PacienteService } from './service/paciente.service';
import { ProntuarioPacienteComponent } from './prontuario-paciente/prontuario-paciente.component';
import { CobrarConsultaComponent } from './cobrar-consulta/cobrar-consulta.component';
import { EmitirReciboComponent } from './cobrar-consulta/emitir-recibo/emitir-recibo.component';
import { PesquisarMedicamentoComponent } from './pesquisar-medicamento/pesquisar-medicamento.component';
import { FileSaverModule } from 'ngx-filesaver';
import { AtendimentoClinicoComponent } from './atendimento-clinico/atendimento-clinico.component';
import { CountdownModule } from 'ngx-countdown';
import { NgxTimerModule } from 'ngx-timer';
import { LogAtendimentoService } from './service/log-atendimento.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { CondutaMedicaComponent } from './atendimento-clinico/conduta-medica/conduta-medica.component';
import { ParecerMedicoComponent } from './atendimento-clinico/parecer-medico/parecer-medico.component';
import { EncerrarAtendimentoComponent } from './atendimento-clinico/encerrar-atendimento/encerrar-atendimento.component';
import { EmitirAtestadoComponent } from './atendimento-clinico/encerrar-atendimento/emitir-atestado/emitir-atestado.component';
import { EmitirAfastamentoComponent } from './atendimento-clinico/encerrar-atendimento/emitir-afastamento/emitir-afastamento.component';
import { EmitirEncaminhamentoComponent } from './atendimento-clinico/encerrar-atendimento/emitir-encaminhamento/emitir-encaminhamento.component';
import { SolicitarRetornoComponent } from './atendimento-clinico/encerrar-atendimento/solicitar-retorno/solicitar-retorno.component';
import { SolicitarPeriodicaComponent } from './atendimento-clinico/encerrar-atendimento/solicitar-periodica/solicitar-periodica.component';
import { SolicitarCobrancaComponent } from './atendimento-clinico/encerrar-atendimento/solicitar-cobranca/solicitar-cobranca.component';
import { BuscarUsuarioService } from './service/buscar-usuario.service';
import { AtendimentoClinicoService } from './service/atendimento-clinico.service';
import { MeuConsultorioComponent } from './meu-consultorio/meu-consultorio.component';
import { LiberarAcessoProntuarioComponent } from './meu-consultorio/liberar-acesso-prontuario/liberar-acesso-prontuario.component';
import { LiberarAcessoService } from './service/liberar-acesso.service';
import { ProntuarioService } from './service/prontuario.service';
import { NgxCheckboxModule } from 'ngx-checkbox';
import { SalaEsperaSecretariaComponent } from './secretaria/sala-espera-secretaria/sala-espera-secretaria.component';
import { TelasComponent } from './home/telas/telas.component';
import { DicionarioDeDadosService } from './service/dicionario-de-dados.service';
import { AtendimentosFaturamentoComponent } from './meu-consultorio/atendimentos-faturamento/atendimentos-faturamento.component';
import { ValorVendaService } from './service/valor-venda.service';
import { GaleriaIconeComponent } from './tabela/galeria-icone/galeria-icone.component';
import { AtendimentoClinicoPesquisaComponent } from './atendimento-clinico-pesquisa/atendimento-clinico-pesquisa.component';
import { ValorConsultaService } from './service/valor-consulta.service';
import { CadastroEmpresaComponent } from './configuracao-inicial/cadastro-empresa/cadastro-empresa.component';
import { ConfiguracaoInicialComponent } from './configuracao-inicial/configuracao-inicial.component';
import { ViewEmpresaComponent } from './configuracao-inicial/view-empresa/view-empresa.component';
import { ConfiguracaoInicialService } from './service/configuracao-inicial.service';
import { EstruturaOrganizacionalComponent } from './configuracao-inicial/estrutura-organizacional/estrutura-organizacional.component';
import { CadastroEstruturaOrganizacionalComponent } from './configuracao-inicial/estrutura-organizacional/cadastro-estrutura-organizacional/cadastro-estrutura-organizacional.component';
import { FotoPerfilService } from './service/foto-perfil.service';
import { EnciclopediaComponent } from './enciclopedia/enciclopedia.component';
import { EnciclopediaService } from './service/enciclopedia.service';
import { DadosSaudeComponent } from './usuario/dados-saude/dados-saude.component';
import { InteracaoMedicamentosaComponent } from './interacao-medicamentosa/interacao-medicamentosa.component';
import { InteracaoMedicamentosaService } from './service/interacao-medicamentosa.service';
import { BularioComponent } from './bulario/bulario.component';
import { BularioService } from './service/bulario.service';
import { VideoChamadaComponent } from './atendimento-clinico/video-chamada/video-chamada.component';
import { PortalComponent } from './portal/portal.component';
import { ConversasComponent } from './conversas/conversas.component';
import { InformeProblemaComponent } from './ajuda/informe-problema/informe-problema.component';
import { ManualUsuarioComponent } from './ajuda/manual-usuario/manual-usuario.component';
import { SugestoesComponent } from './ajuda/sugestoes/sugestoes.component';
import { PortalPrivadoComponent } from './portal/portal-privado/portal-privado.component';
import { AconteceSaudeComponent } from './portal/acontece-saude/acontece-saude.component';
import { TurismoComponent } from './portal/turismo/turismo.component';
import { SaoPedroComponent } from './portal/turismo/sao-pedro/sao-pedro.component';
import { MonitorClinicoService } from './service/monitor-clinico.service';
import { FinanceiroComponent } from './financeiro/financeiro.component';
import { CobrancaComponent } from './financeiro/cobranca/cobranca.component';
import { SaibaMaisComponent } from './portal/saiba-mais/saiba-mais.component';
import { MonitorClinicoComponent } from './home/monitor-clinico/monitor-clinico.component';
import { DicionarioComponent } from './portal/dicionario/dicionario.component';
import { PortalDicionarioService } from './service/portal-dicionario.service';
import { InscricaoPacienteComponent } from './portal/inscricao-paciente/inscricao-paciente.component';
import { InicioComponent } from './portal/inicio/inicio.component';
import { RegistroService } from './service/registro.service';
import { TransacaoService } from './service/transacao.service';
import { CoronaVirusComponent } from './portal/corona-virus/corona-virus.component';
import { TermaisPrataComponent } from './portal/turismo/termais-prata/termais-prata.component';
import { AguasQuentesComponent } from './portal/turismo/aguas-quentes/aguas-quentes.component';
import { HubDevService } from './service/hub-dev.service';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { PesquisarComponent } from './home/pesquisar/pesquisar.component';
import { ConfirmacaoComponent } from './portal/confirmacao/confirmacao.component';
import { AdminUsuariosComponent } from './admin-usuarios/admin-usuarios.component';
import { ChartsModule } from 'ng2-charts';
import { ConviteComponent } from './home/convite/convite.component';
import { CronometroService } from './service/cronometro.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ConversasService } from './service/conversas.service';
import { ContatosService } from './service/contatos.service';
import { ChatComponent } from './conversas/chat/chat.component';
import { HistoricoConversasComponent } from './conversas/historico-conversas/historico-conversas.component';
import { VideoChamadaService } from './service/video-chamada.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UploadArquivosService } from './service/upload-arquivos.service';
import { UploadComponent } from './upload/upload.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AgendaConfigComponent } from './agenda/agenda-config/agenda-config.component';
import { CadastroPacienteBasicoComponent } from './cadastro-paciente-basico/cadastro-paciente-basico.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { RecepcaoComponent } from './atendimento-clinico/recepcao/recepcao.component';
import { PesquisarPacienteComponent } from './atendimento-clinico/pesquisar-paciente/pesquisar-paciente.component';
import { RecepcaoComponent_old } from './atendimento-clinico/recepcao_old/recepcao.component_old';
import { SenhaAtendimentoComponent } from './atendimento-clinico/senha-atendimento/senha-atendimento.component';
import { ChekinPacienteComponent } from './atendimento-clinico/chekin-paciente_old/chekin-paciente.component';
import { ChekinPacienteFlashComponent } from './atendimento-clinico/chekin-paciente_old/chekin-paciente-flash/chekin-paciente-flash.component';
import { AngularValidateBrLibModule } from 'angular-validate-br';
import { FichaConsultaMedicaComponent } from './atendimento-clinico/ficha-consulta-medica/ficha-consulta-medica.component';
import { RecuperarSenhaService } from './service/recuperar-senha.service';
import { SistemaClassificacaoDadosService } from './service/sistema-classificacao-dados.service';
import { CaracteristicasComponent } from './ajustes/CaracteristicasDados/caracteristicas-de-dados.component';
import { SistemaClassificacaoDadosComponent } from './ajustes/ConteudoDados/sistema-classificacao-dados.component';
import { FormulariosDoSistema } from './ajustes/FormularioSistema/formularios-do-sistema.component';
import { MensagemService } from './service/mensagem.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadChatComponent } from './conversas/upload-chat/upload-chat.component';
import { LoginService } from './service/login.service';
import { TesteComponent } from './financeiro/teste/teste.component';
import { TesteService } from './service/teste.service';
import { DefinirEmpresaMedicaComponent } from './ajustes/definir-empresa-medica/definir-empresa-medica.component';
import { StatusDoSistemaComponent } from './ajustes/status-do-sistema/status-do-sistema.component';
import { RegrasImpostoComponent } from './configuracao-inicial/regras-de-imposto/regras-de-imposto.component';
import { RegraConfirmacaoConsultaComponent } from './configuracao-inicial/regra-confirmacao-consulta/regra-confirmacao-consulta.component';
import { ControleSenhaAtendimentoComponent } from './configuracao-inicial/controle-senha-atendimento/controle-senha-atendimento.component';
import { TabelaComponent } from './tabela/tabela.component';
import { DiarioDeAcessoService } from './service/diario-de-acesso/diario-de-acesso.service';
import { DiarioDeAcessoComponent } from './diario-de-acesso/diario-de-acesso.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusDeSessao } from './service/diario-de-acesso/statusDeSessao.class';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { CadastroItemComponent } from './cadastro-item/cadastro-item.component';
import { PosicaoNumeracaoNfeComponent } from './ajustes/posicao-numeracao-nfe/posicao-numeracao-nfe.component';
import { ListaPrecosVendaComponent } from './ajustes/lista-precos-venda/lista-precos-venda.component';
import { TesteTesteComponent } from './ajustes/teste-teste/teste-teste.component';
import { MinhasConexoesProfissionaisComponent } from './minhas-conexoes-profissionais/minhas-conexoes-profissionais.component';
import { CadastroDadosProfissionaisComponent } from './cadastro-dados-profissionais/cadastro-dados-profissionais.component';
import { EspecialidadeCrmComponent } from './especialidade-crm/especialidade-crm.component';
import { DocumentosPdfComponent } from './documentos-pdf/documentos-pdf.component';
import { MyClinicsBusinessModule } from './my-clinics-business/my-clinics-business.module'
import { FichaGeralService } from './service/ficha-geral-empresa/ficha-geral-empresa.service'
import { DocumentosDoSistemaComponent, novoDocumentoDialog } from './documentos-do-sistema/documentos-do-sistema.component';
import { ControleSenhaAtendimentoService } from './service/controle-senha-atendimento.service';
import { MinhasConexoesProfissionaisService } from './service/minhas-conexoes-profissionais.service';
import { TabelasDoSistemaService } from './service/tabelas-do-sistema/tabelas-do-sistema.service';
import { ClassificacaoDeDadosService } from './service/classificacao-de-dados/classificacao-de-dados.service';
import { ConteudoDeDadosService } from './service/conteudo-de-dados.service';
import { ConteudoDeDadosComponent, novoConteudo } from './conteudo-de-dados/conteudo-de-dados.component';
import { FichaLegislacaoService } from './service/ficha-legislacao/ficha-legislacao.service';
import { ServicosMedicosDisponiveisService } from './service/servicos-medicos-disponiveis/servicos-medicos-disponiveis.service';
import { EmpresaCompletaService } from './service/todos-dados-empresa/empresa-completa.service';
import { AreaAtuacao } from './service/area-atuacao/AreaAtuacao.model';
import { AreaAtuacaoService } from './service/area-atuacao/area-atuacao.service';
import { CadastroOperadoraSaudeComponent } from './cadastro-operadora-saude/cadastro-operadora-saude.component';
import { AtribuicaoStatusComponent } from './ajustes/atribuicao-status/atribuicao-status.component';
import { UploadConteudoDadosComponent } from './upload-conteudo-dados/upload-conteudo-dados.component';
import { UploadDocumentoComponent } from './upload-documento/upload-documento.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MensagensDoSistemaComponent } from './mensagens-do-sistema/mensagens-do-sistema.component';
import { LogsSistemaComponent } from './logs-sistema/logs-sistema.component';
import { DefinirConsultoriosMedicosComponent } from './definir-consultorios-medicos/definir-consultorios-medicos.component';
import { PlanoContaContabeisComponent, } from './plano-conta-contabeis/plano-conta-contabeis.component';
import { StatusSistemaService } from './service/status-sistema.service';
import { ListarMedicosService } from './service/listar-medicos.service';
import { AssinaturaComponent } from './portal/assinatura/assinatura.component';
import { LayoutModule } from './layout/layout.module';
import { CadastroAssinaturaComponent } from './portal/cadastro-assinatura/cadastro-assinatura.component';
import { CadastroUsuarioComponent } from './portal/cadastro-assinatura/cadastro-usuario/cadastro-usuario.component'
import { PagamentoComponent } from './portal/cadastro-assinatura/pagamento/pagamento.component';
import { ItemsAdicionaisComponent } from './portal/cadastro-assinatura/items-adicionais/items-adicionais.component';
import { SolicitacoesComponent } from './home/monitor-clinico/consultas/solicitacoes/solicitacoes.component';
import { LivroRazaoComponent } from './livro-razao/livro-razao.component';
import { EmpresaMyClinicsComponent, PopUpEmpresaMyClinics } from './empresa-my-clinics/empresa-my-clinics.component';
import { AdministrarAssinanteComponent } from './ajustes/administrar-assinante/administrar-assinante.component';
import { ConvenioComponent } from './cobrar-consulta/convenio/convenio.component';
import { TicketsService } from './service/tickets.service';
import { ItemMyClinicsComponent } from './item-my-clinics/item-my-clinics.component';
import { MyClinicsItemVendaService } from './service/my-clinics-item-venda/my-clinics-item-venda.service';
import { CadastroFornecedorComponent } from './cadastro-fornecedor/cadastro-fornecedor.component';
import { CadastroContaBancariaComponent } from './cadastro-conta-bancaria/cadastro-conta-bancaria.component';
import { MinhasConexoesComponent } from './minhas-conexoes/minhas-conexoes.component';
import { ConversaComponent } from './minhas-conexoes/conversa/conversa.component';
import { CadastroLocalArmazenamentoComponent } from './cadastro-local-armazenamento/cadastro-local-armazenamento.component';
import { CadastroCentroDeCustoCompletoComponent } from './cadastro-centro-de-custo-completo/cadastro-centro-de-custo-completo.component';
import { CadastroCentroDeLucroComponent } from './cadastro-centro-de-lucro/cadastro-centro-de-lucro.component';
import { CertificadoDigitalService } from './service/certificado-digital/certificado-digital.service';
import { CertificadoDigitalComponent } from './certificado-digital/certificado-digital.component';
import { RegraAprovacaoDocumentoComponent } from './ajustes/regra-aprovacao-documento/regra-aprovacao-documento.component';
import { RegraAprovacaoDocumentoService } from './service/regra-aprovacao-documento.service';
import { FotoPerfilComponent } from './home/foto-perfil/foto-perfil.component';
import { HierarquiaDeCustoELucroComponent } from './hierarquia-de-custo-e-lucro/hierarquia-de-custo-e-lucro.component';
import { CatalogoTabelasComponent } from './ajustes/catalogo-tabelas/catalogo-tabelas.component';
import { ConsultarTabelasService } from './service/consultar-tabelas.service';
import { AdminTokenComponent } from './admin-usuarios/admin-token/admin-token.component';
import { CatalogoTabelasService } from './service/catalogo-tabelas.service';
import { CadastroOperadoraCartaoService } from './service/cadastro-operadora-cartao.service';
import { ConciliarOperadoraPlanoSaudeComponent } from './gestao-simplificada/controle-financeiro/conciliar-operadora-plano-saude/conciliar-operadora-plano-saude.component';
import { AutorizacaoDeAcessoComponent } from './ajustes/autorizacao-de-acesso/autorizacao-de-acesso.component';
import { ConvenioService } from './service/convenio/convenio.service';
import { CadastroOperadoraCartaoComponent } from './ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.component';
import { DefinirEmpresaMedicaComponentOld } from './ajustes/definir-empresa-medica_old/definir-empresa-medica.component';
import { TabelaTussComponent } from './tabela-tuss/tabela-tuss.component';
import { TabelaTussService } from './service/tabela-tuss.service';
import { PerfilDeAcessoUsuarioComponent } from './ajustes/perfil-de-acesso-usuario/perfil-de-acesso-usuario.component';
import { ClassificacaoReceituarioComponent } from './classificacao-receituario/classificacao-receituario.component';
import { ListaCidsComponent } from './lista-cids/lista-cids.component';
import { ListaExamesComponent } from './lista-exames/lista-exames.component';
import { ProtocoloClinicoComponent } from './conteudo-setor-saude/protocolo-clinico/protocolo-clinico.component';
import { ProtocoloClinicoService } from './service/protocolo-clinico.service';
import { ValidarCrmService } from './service/validar-crm.service';
import { DevComponent } from './dev/dev/dev.component';
import { AtualizarDadosSaudeComponent } from './atendimento-clinico/ficha-consulta-medica/atualizar-dados-saude/atualizar-dados-saude.component';
import { DadosDaSaudeService } from './service/dados-da-saude/dados-da-saude.service';
import { AtribuicaoDeAcessoComponent } from './ajustes/atribuicao-de-acesso/atribuicao-de-acesso.component';
import { InformarSaldoContabilInicialComponent } from './ajustes/informar-saldo-contabil-inicial/informar-saldo-contabil-inicial.component';
import { InformarSaldoContabilInicialService } from './service/informar-saldo-contabil-inicial.service';
import { TreePlanoContaComponent } from './plano-conta-contabeis/tree-plano-conta/tree-plano-conta.component';
import { LancamentoLivroCaixaComponent } from './gestao-simplificada/lancamento-livro-caixa/lancamento-livro-caixa.component';
import { LancamentoLivroCaixaService } from './service/lancamento-livro-caixa.service';
import { ProntuarioVidaPacienteComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/prontuario-vida-paciente.component';
import { CadastroFuncionarioComponent } from './cadastro-funcionario/cadastro-funcionario.component';
import { CadastroFuncionarioService } from './service/cadastro-funcionario.service';


import { EmitirReciboNotaFiscalComponent } from './gestao-simplificada/controle-financeiro/emitir-recibo-nota-fiscal/emitir-recibo-nota-fiscal.component';
import { NotaFiscalService } from './service/emitir-recibo-nota-fiscal.service';
import { RegistrarPagamentosService } from './service/registrar-pagamentos.service';
import { RegistrarPagamentosComponent } from './gestao-simplificada/registrar-pagamentos/registrar-pagamentos.component';
import { HistoricoSaudePacienteComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/historico-saude-paciente/historico-saude-paciente.component';
import { ApurarImpostoComponent } from './apurar-imposto/apurar-imposto.component';
import { HistoricoCaracteristicasFisicasComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/historico-caracteristicas-fisicas/historico-caracteristicas-fisicas.component';
import { RegrasOrcamentoComponent } from './ajustes/regras-orcamento/regras-orcamento.component';
import { RegrasOrcamentoService } from './service/regras-orcamento.service';
import { CertificadoNfseComponent } from './ajustes/definir-empresa-medica/certificado-nfse/certificado-nfse.component';
import { ParametrosSistemaService } from './service/parametros-sistema.service';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { BnNgIdleService } from 'bn-ng-idle';
import { UsuariosLogadosComponent } from './usuarios-logados/usuarios-logados.component';
import { CadastroCartaoCreditoComponent } from './ajustes/cadastros-mestres/cadastro-cartao-credito/cadastro-cartao-credito.component';

import { CadastroCartaoCreditoService } from './service/cadastro-cartao-credito.service';
import { FiltrarDadosService } from './service/filtrar-dados.service';
import { FluxoCaixaComponent } from './gestao-simplificada/fluxo-caixa/fluxo-caixa.component';
import { FluxoCaixaService } from './service/fluxo-caixa.service';
import { TreeFluxoCaixaComponent } from './gestao-simplificada/fluxo-caixa/tree-fluxo-caixa/tree-fluxo-caixa.component';
import { AnaliticoComponent } from './analitico/analitico.component';
import { AgmCoreModule } from '@agm/core';
import { LegislacaoTributariaComponent } from './ajustes/legislacao-tributaria/legislacao-tributaria.component';
import { LegislacaoTributariaService } from './service/legislacao-tributaria.service';
import { AgmDirectionModule } from 'agm-direction';
import { CadastroItemService } from './service/cadastro-item-service.service';
import { ConciliacaoBancariaComponent } from './gestao-simplificada/conciliacao-bancaria/conciliacao-bancaria.component';
import { ConciliacaoBancariaService } from './service/conciliacao-bancaria.service';
import { ContasReceberComponent } from './gestao-simplificada/contas-receber/contas-receber.component';
import { AnaliseSituacaoFiscalComponent } from './analitico/analise-situacao-fiscal/analise-situacao-fiscal.component';
import { SaldoContaContabilService } from './service/plano-conta-contabeis/saldo-conta-contabil.service';
import { ParametrosDoSistemaComponent } from './parametros-do-sistema/parametros-do-sistema.component';
import { ContaBancariaService } from './service/cadastro-conta-bancaria.service';
import { TreeMenuComponent } from './ajustes/perfil-de-acesso-usuario/tree-menu/tree-menu.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module, ReCaptchaV3Service, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { RecaptchaService } from './service/recaptcha.service';
import { PersonalizacaoService } from './service/personalizacao.service';
import { FichaConsultaMedicaService } from './service/ficha-consulta-medica/ficha-consulta-medica.service';
import { CadastroPrestadorServicoComponent } from './gestao-simplificada/cadastro-prestador-servico/cadastro-prestador-servico.component';
import { CadastroPrestadorServicoService } from './service/cadastro-prestador-servico.service';
import { MonitorPacienteComponent } from './home/monitor-paciente/monitor-paciente.component';
import { MeusContatosComponent } from './minhas-conexoes/meus-contatos/meus-contatos.component';
import { MonitorMinhaContaComponent } from './home/monitor-minha-conta/monitor-minha-conta.component';
import { ChatService } from './service/conversas/chat.service';
import { CoordenadaService } from './service/coordenada.service';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';
import { CadastroProfissionalComponent } from './minha-conta/cadastro-profissional/cadastro-profissional.component';
import { CadatroDependenteComponent } from './minha-conta/cadatro-dependente/cadatro-dependente.component';
import { ResetPasswordComponent } from './portal/reset-password/reset-password.component';
import { InfoEventComponent } from './agenda/agenda/info-event/info-event.component';
import { MarcarConsultaComponent } from './agenda/agenda/marcar-consulta/marcar-consulta.component';
import { OrganogramaComponent } from './analitico/organograma/organograma.component';
import { PersonalizarFrequenciaComponent } from './agenda/agenda/marcar-consulta/personalizar-frequencia/personalizar-frequencia.component';
import { CalendarioConfigComponent } from './calendario/calendario-config/calendario-config.component';
import { LocalDeTrabalhoComponent } from './portal/local-de-trabalho/local-de-trabalho.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DicasBemEstarComponent } from './portal/dicas-bem-estar/dicas-bem-estar.component';
import { SaudeAZComponent } from './portal/saude-az/saude-az.component';
import { SaudeAZService } from './service/saude-az/saude-az.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HistoricoConsultaComponent } from './agenda/agenda/historico-consulta/historico-consulta.component';
import {ConvertDataDirective} from './directives/convertData.directive';
import { InfoPacienteComponent } from './info-paciente/info-paciente.component';
import { ReceitaEletronicaComponent } from './atendimento-clinico/ficha-consulta-medica/receita-eletronica/receita-eletronica.component'
import { ProntuarioDeVidaService } from './service/prontuario-vida-paciente/prontuario-de-vida.service';
import { AtualizarProntuarioVidaPacienteComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/atualizar-prontuario-vida-paciente/atualizar-prontuario-vida-paciente.component';
import { ReceituarioService } from './service/receituario/receituario.service';
import { ProntuarioDadosSaudeComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/prontuario-dados-saude/prontuario-dados-saude.component';
import { ProntuarioMedicamentoComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/prontuario-dados-saude/prontuario-medicamento/prontuario-medicamento.component';
import { DadosSaudePacienteService } from './service/dados-saude-paciente/dados-saude-paciente.service';
import { ProntuarioSequelasComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/prontuario-dados-saude/prontuario-sequelas/prontuario-sequelas.component';
import { ProntuarioFraturaComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/prontuario-dados-saude/prontuario-fratura/prontuario-fratura.component';
import { HistoricoSaudeComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/historico-saude/historico-saude.component';
import { AtualizarHistoricoSaudeComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/historico-saude/atualizar-historico-saude/atualizar-historico-saude.component';
import { InformacaoSaudePacienteComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/informacao-saude-paciente/informacao-saude-paciente.component';
import { InternacoesHospitalarComponent } from './atendimento-clinico/ficha-consulta-medica/prontuario-vida-paciente/internacoes-hospitalar/internacoes-hospitalar.component';
import { AtestadoObitoComponent } from './meu-consultorio/atestado-obito/atestado-obito.component';
import { AtestadoObitoService } from './service/atestado-obito/atestado-obito.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};
const firebaseConfig = {
  apiKey: "AIzaSyBT-yJt8QHkHPpHfXvl6CX5BLsTMox9nlk",
  authDomain: "rtcmyclinics.firebaseapp.com",
  databaseURL: "https://rtcmyclinics.firebaseio.com",
  projectId: "rtcmyclinics",
  storageBucket: "rtcmyclinics.appspot.com",
  messagingSenderId: "548692134060",
  appId: "1:548692134060:web:7a4dccd29c2d4b4b4cf709",
  measurementId: "G-7NL1R7N8HC"
};
// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "right",
//   allowNegative: true,
//   decimal: ",",
//   precision: 2,
//   prefix: "R$ ",
//   suffix: "",
//   thousands: "."
// };
@NgModule({
  declarations: [
    ConvertDataDirective,
    DefinirEmpresaMedicaComponentOld,
    AppComponent,
    UploadDocumentoComponent,
    HomeComponent,
    CaracteristicasComponent,
    LoadingComponent,
    UsuarioComponent,
    DependentesComponent,
    PreNatalComponent,
    AgendaComponent,
    CarteiraVacinaComponent,
    SalaEsperaComponent,
    ChekinPacienteComponent,
    SistemaClassificacaoDadosComponent,
    FormulariosDoSistema,
    DocumentacaoComponent,
    RecepcaoComponent,
    CadastroPacienteComponent,
    ProntuarioPacienteComponent,
    CobrarConsultaComponent,
    EmitirReciboComponent,
    PesquisarMedicamentoComponent,
    AtendimentoClinicoComponent,
    PortalComponent,
    CondutaMedicaComponent,
    ParecerMedicoComponent,
    EncerrarAtendimentoComponent,
    TabelaTussComponent,
    CadastroOperadoraCartaoComponent,
    EmitirAtestadoComponent,
    EmitirAfastamentoComponent,
    EmitirEncaminhamentoComponent,
    SolicitarRetornoComponent,
    SolicitarPeriodicaComponent,
    SolicitarCobrancaComponent,
    MeuConsultorioComponent,
    LiberarAcessoProntuarioComponent,
    SalaEsperaSecretariaComponent,
    TelasComponent,
    AtribuicaoStatusComponent,
    AtendimentosFaturamentoComponent,
    GaleriaIconeComponent,
    AtendimentoClinicoPesquisaComponent,
    ChekinPacienteFlashComponent,
    CadastroEmpresaComponent,
    ConfiguracaoInicialComponent,
    ViewEmpresaComponent,
    EstruturaOrganizacionalComponent,
    CadastroEstruturaOrganizacionalComponent,
    EnciclopediaComponent,
    DadosSaudeComponent,
    InteracaoMedicamentosaComponent,
    BularioComponent,
    VideoChamadaComponent,
    ConversasComponent,
    InformeProblemaComponent,
    ManualUsuarioComponent,
    SugestoesComponent,
    PortalPrivadoComponent,
    AconteceSaudeComponent,
    TurismoComponent,
    SaoPedroComponent,
    ConvenioComponent,
    FinanceiroComponent,
    RecepcaoComponent_old,
    CobrancaComponent,
    SaibaMaisComponent,
    MonitorClinicoComponent,
    DicionarioComponent,
    InscricaoPacienteComponent,
    InicioComponent,
    CoronaVirusComponent,
    TermaisPrataComponent,
    AguasQuentesComponent,
    PoliticaPrivacidadeComponent,
    PesquisarComponent,
    ConfirmacaoComponent,
    AdminUsuariosComponent,
    ConviteComponent,
    ChatComponent,
    HistoricoConversasComponent,
    UploadComponent,
    AgendaConfigComponent,
    CadastroPacienteBasicoComponent,
    CalendarioComponent,
    PesquisarPacienteComponent,
    SenhaAtendimentoComponent,
    FichaConsultaMedicaComponent,
    UploadChatComponent,
    TesteComponent,
    DefinirEmpresaMedicaComponent,
    ClassificacaoReceituarioComponent,
    StatusDoSistemaComponent,
    RegrasImpostoComponent,
    RegraConfirmacaoConsultaComponent,
    ControleSenhaAtendimentoComponent,
    TabelaComponent,
    DiarioDeAcessoComponent,
    LoginAdminComponent,
    CadastroItemComponent,
    PosicaoNumeracaoNfeComponent,
    TesteTesteComponent,
    MinhasConexoesProfissionaisComponent,
    ListaCidsComponent,
    ListaExamesComponent,
    CadastroDadosProfissionaisComponent,
    EspecialidadeCrmComponent,
    DocumentosPdfComponent,
    DocumentosDoSistemaComponent,
    novoDocumentoDialog,
    ConteudoDeDadosComponent,
    novoConteudo,
    ListaPrecosVendaComponent,
    MinhasConexoesProfissionaisComponent,
    CadastroDadosProfissionaisComponent,
    EspecialidadeCrmComponent,
    CadastroOperadoraSaudeComponent,
    UploadConteudoDadosComponent,
    LogsSistemaComponent,
    MensagensDoSistemaComponent,
    DefinirConsultoriosMedicosComponent,
    PlanoContaContabeisComponent,
    // novoPlanoDeContas,
    AssinaturaComponent,
    CadastroAssinaturaComponent,
    CadastroUsuarioComponent,
    PagamentoComponent,
    ItemsAdicionaisComponent,
    SolicitacoesComponent,
    LivroRazaoComponent,
    AdministrarAssinanteComponent,
    EmpresaMyClinicsComponent,
    PopUpEmpresaMyClinics,
    AdministrarAssinanteComponent,
    ItemMyClinicsComponent,
    CadastroFornecedorComponent,
    CadastroContaBancariaComponent,
    MinhasConexoesComponent,
    ConversaComponent,
    CadastroLocalArmazenamentoComponent,
    CadastroCentroDeCustoCompletoComponent,
    CadastroCentroDeLucroComponent,
    CertificadoDigitalComponent,
    RegraAprovacaoDocumentoComponent,
    FotoPerfilComponent,
    HierarquiaDeCustoELucroComponent,
    CatalogoTabelasComponent,
    AdminTokenComponent,
    ConciliarOperadoraPlanoSaudeComponent,
    AutorizacaoDeAcessoComponent,
    PerfilDeAcessoUsuarioComponent,
    ProtocoloClinicoComponent,
    DevComponent,
    AtualizarDadosSaudeComponent,
    AtribuicaoDeAcessoComponent,
    InformarSaldoContabilInicialComponent,
    TreePlanoContaComponent,
    LancamentoLivroCaixaComponent,
    ProntuarioVidaPacienteComponent,
    CadastroFuncionarioComponent,
    EmitirReciboNotaFiscalComponent,
    RegistrarPagamentosComponent,
    HistoricoSaudePacienteComponent,
    ApurarImpostoComponent,
    HistoricoCaracteristicasFisicasComponent,
    EmitirReciboNotaFiscalComponent,
    RegrasOrcamentoComponent,
    CertificadoNfseComponent,
    UsuariosLogadosComponent,
    CadastroCartaoCreditoComponent,
    FluxoCaixaComponent,
    TreeFluxoCaixaComponent,
    AnaliticoComponent,
    LegislacaoTributariaComponent,
    ConciliacaoBancariaComponent,
    ContasReceberComponent,
    AnaliseSituacaoFiscalComponent,
    ParametrosDoSistemaComponent,
    TreeMenuComponent,
    CadastroPrestadorServicoComponent,
    MonitorPacienteComponent,
    MeusContatosComponent,
    MonitorMinhaContaComponent,
    MinhaContaComponent,
    CadastroProfissionalComponent,
    CadatroDependenteComponent,
    ResetPasswordComponent,
    InfoEventComponent,
    MarcarConsultaComponent,
    OrganogramaComponent,
    PersonalizarFrequenciaComponent,
    CalendarioConfigComponent,
    LocalDeTrabalhoComponent,
    DicasBemEstarComponent,
    SaudeAZComponent,
    HistoricoConsultaComponent,
    InfoPacienteComponent,
    ReceitaEletronicaComponent,
    AtualizarProntuarioVidaPacienteComponent,
    ProntuarioDadosSaudeComponent,
    ProntuarioMedicamentoComponent,
    ProntuarioSequelasComponent,
    ProntuarioFraturaComponent,
    HistoricoSaudeComponent,
    AtualizarHistoricoSaudeComponent,
    InformacaoSaudePacienteComponent,
    InternacoesHospitalarComponent,
    AtestadoObitoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FileSaverModule,
    MatProgressBarModule,
    AppRoutingModule,
    ChartsModule,
    FullCalendarModule,
    NgxCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTreeModule,
    DragDropModule,
    NgxSpinnerModule,
    PdfViewerModule,
    MatIconModule,
    NgxTimerModule,
    CarouselModule,
    RecaptchaV3Module,
    NgxMaskModule.forRoot(options),
    ToastrModule.forRoot({
      closeButton: true,
      disableTimeOut: true,
      tapToDismiss: false,
    }),
    MatMenuModule,
    MatSelectModule,
    MatButtonToggleModule,
    CountdownModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCiHsNUWnXD6eDukfF4VIRBHWWsm76I4BQ'
    }),
    AgmDirectionModule,
    AngularValidateBrLibModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSortModule,
    QRCodeModule,
    MatCardModule,
    MatSlideToggleModule,
    AngularEditorModule,
    MatPaginatorModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    NgxImageUploadBase64Module,
    SweetAlert2Module.forRoot(),
    MatSidenavModule,
    MatTooltipModule,
    ModalModule.forRoot(),
    MyClinicsBusinessModule,
    MatDialogModule,
    LayoutModule,
    MatStepperModule,
    MatBadgeModule,
    CurrencyMaskModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    BnNgIdleService,
    ToastrService,
    RegraAprovacaoDocumentoService,
    UsuarioService,
    DicionarioDeDadosService,
    LogAtendimentoService,
    CatalogoTabelasService,
    BularioService,
    AngularFireStorage,
    AtendimentoClinicoService,
    CadastroFuncionarioService,
    SalaEsperaService,
    LiberarAcessoService,
    PortalDicionarioService,
    ProntuarioDeVidaService,
    InteracaoMedicamentosaService,
    BuscarDataService,
    TabelasDoSistemaService,
    AtestadoObitoService,
    ConsultarTabelasService,
    AngularFirestore,
    SenhaAtendimentoService,
    SaudeAZService,
    TicketsService,
    ConversasService,
    SaldoContaContabilService,
    ProtocoloClinicoService,
    ReceituarioService,
    CronometroService,
    DadosDaSaudeService,
    SistemaClassificacaoDadosService,
    CertificadoDigitalService,
    PacienteService,
    UploadArquivosService,
    ConvenioService,
    UsuarioDependenteService,
    ConfiguracaoInicialService,
    CarteiraVacinacaoService,
    UsuarioQuadroDeSaudeService,
    UsuarioPreNatalService,
    DadosSaudePacienteService,
    BuscarUsuarioService,
    ProntuarioService,
    ControleSenhaAtendimentoService,
    ParametrosSistemaService,
    ValorVendaService,
    MinhasConexoesProfissionaisService,
    ValorConsultaService,
    ValidarCrmService,
    FotoPerfilService,
    EnciclopediaService,
    DatePipe,
    ContatosService,
    MonitorClinicoService,
    RecuperarSenhaService,
    RegistroService,
    TransacaoService,
    CoordenadaService,
    AgendaService,
    HubDevService,
    VideoChamadaService,
    LoginService,
    MensagemService,
    TesteService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfvRVIaAAAAAIT6ReCRW5GmMIXZm8tgihn-As-C" },
    ReCaptchaV3Service,
    RecaptchaService,
    DiarioDeAcessoService,
    StatusDeSessao,
    FichaGeralService,
    FiltrarDadosService,
    TabelasDoSistemaService,
    ClassificacaoDeDadosService,
    ChatService,
    ConteudoDeDadosService,
    FichaLegislacaoService,
    ServicosMedicosDisponiveisService,
    EmpresaCompletaService,
    AreaAtuacaoService,
    StatusSistemaService,
    ListarMedicosService,
    MyClinicsItemVendaService,
    TabelaTussService,
    CadastroOperadoraCartaoService,
    InformarSaldoContabilInicialService,
    LancamentoLivroCaixaService,
    // NotaFiscalService,
    RegistrarPagamentosService,
    RegrasOrcamentoService,
    CadastroCartaoCreditoService,
    FluxoCaixaService,
    LegislacaoTributariaService,
    CadastroItemService,
    ConciliacaoBancariaService,
    ContaBancariaService,
    PersonalizacaoService,
    FichaConsultaMedicaService,
    CadastroPrestadorServicoService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    novoDocumentoDialog,
    novoConteudo,
    // novoPlanoDeContas,
    PopUpEmpresaMyClinics
  ]
})
export class AppModule { }


