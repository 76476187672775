import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FichaConexaoProfissional, SIS_CONECTPES } from './ficha-conexao-profissional.model'
import { MensagemService } from '../mensagem.service';
import { environment } from 'src/environments/environment';
import { DiarioDeAcessoService } from '../diario-de-acesso/diario-de-acesso.service';
import { BuscarDataService } from '../buscar-data.service';
import { SessaoUser } from 'src/app/user.reg';
import { FiltrarDadosService } from '../filtrar-dados.service';
import { ConteudoDeDadosService } from '../conteudo-de-dados.service';
import { StatusSistemaService } from '../status-sistema.service';
import { cadastroUsuario } from 'src/app/admin-usuarios/model';
import { CadastroPessoaForm } from 'src/app/cadastro-paciente/model';
import { UsuarioService } from '../usuario.service';

const url = `${new environment().api}SIS_CONECTPES/`;

const user = new SessaoUser

@Injectable({
  providedIn: 'root'
})
export class FichaConexoesProfissionaisService {

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
    private _user: UsuarioService,
    private serviceDiarioAcesso: DiarioDeAcessoService,
    private serviceBuscarData: BuscarDataService,
    private _filter: FiltrarDadosService,
    private _conteudo: ConteudoDeDadosService,
    private _status: StatusSistemaService,
  ) { }

  async getConexoes() {
    let result = []
    await this.http.get<any>(url + 'Join').toPromise()
      .then(async res => {
        try {
          if (res.success) {
            console.log('END CONEXAO 2')
            result = res.result.filter(value => value.status)
          }
        } catch (error) {
          console.error('conexao join', error)
        }

      }
      )
    return result
  }
  async getConatos() {
    let getRegistro = await this.getConexoes()
    getRegistro.filter(v => v.tipoConexao == 'TIPCON6').filter(value => {
      return value.status == 'SDO0141' ||
        value.status == 'SDO0140' ||
        value.status == 'SDO0038' ||
        value.status == 'SDO0037' ||
        value.status == 'SDO0009'
    })
    return getRegistro
  }
  async getConecID(id) {
    let result = new SIS_CONECTPES
    await this.http.get<SIS_CONECTPES>(url + id).toPromise()
      .then(res => result = res)
    return result
  }
  async getConvites(cpf) {
    let result = []
    await this.http.get<any>(url + 'Join').toPromise()
      .then(async res => {
        if (res.success) {
          // result = res.result.filter(value => value.cpfPessoa == cpf && value.status == 'SDO0099' || value.status == 'SDO0101' || value.status == 'SDO0137' && value.criadoPor != user.cpf)
          result = res.result.filter(value => value.cpfPessoa == cpf)
        }
      }
      )
    return result
  }
  async getConvitesContatos(getRegistro) {
    let result = []
    result = getRegistro.filter(value => value.status == 'SDO0141' || value.status == 'SDO0037' || value.status == 'SDO0038' || value.status == 'SDO0140' && value.criadoPor != user.cpf)
    return result
  }
  async getConexoesEmpresas() {
    let result = []
    await this.http.get<any>(url + 'Join').toPromise()
      .then(async res => {
        if (res.success) {
          let registro = res.result.filter(value => {
            return value.status == 'SDO0100' || value.status == "SDO0102" || value.status == "SDO0136" || value.status == "SDO0101"
          }).filter(value => value.codigoEmpresa == user.codigoEmpresa)
          result = this._filter.filterTodos(registro)
        }
      }
      )
    return result
  }
  async getMinhasConexoes(cpf) {
    let result = []
    await this.http.get<any>(url + 'Join').toPromise()
      .then(async res => {
        if (res.success) {
          let registro = res.result.filter(value => {
            return value.status == 'SDO0100' || value.status == "SDO0102"
          }).filter(value => value.cpf == cpf)

          let empresas = JSON.parse(user.listEmpresas)

          result = this._filter.filterTodos(registro, empresas)
        }
      }
      )
    return result
  }
  async verificConexao(cpf) {
    let result = false
    await this.http.get<Array<SIS_CONECTPES>>(url).toPromise()
      .then(res => {
        let verifc = res.filter(value => value.cpfPessoa == cpf && value.codigoEmpresa == user.codigoEmpresa && value.status == 'SDO0100')
        if (verifc.length) result = true
      })
    return result
  }
  async verificConvite(cpf, codigoEmpresa) {
    let result = false
    await this.http.get<Array<SIS_CONECTPES>>(url).toPromise()
      .then(async res => {
        let verfic

        verfic = res.filter(value => value.cpfPessoa == cpf && value.codigoEmpresa == codigoEmpresa)

        if (verfic.length > 0) {
          let valid = res.filter(value => value.cpfPessoa == cpf && value.codigoEmpresa == codigoEmpresa)
            .filter(value => value.status == 'SDO0102' || value.status == 'SDO0101' || value.status == 'SDO0139')

          if (valid.length > 0) {
            result = true
          }

          let validActive = res.filter(value => value.cpfPessoa == cpf && value.codigoEmpresa == codigoEmpresa)
            .filter(value => value.status == 'SDO0099' || value.status == 'SDO0136')

          if (validActive.length > 0) result = false
          else result = true

          console.log('valid', valid)

        }
        else result = true
      })
    return result
  }
  async verificConviteContato(cpf) {
    let result = false
    await this.http.get<Array<SIS_CONECTPES>>(url).toPromise()
      .then(async res => {
        let verfic
        verfic = res.filter(value => value.cpfPessoa == cpf && value.tipoConexao == 'TIPCON6')

        if (verfic.length > 0) {
          let valid = res.filter(value => value.cpfPessoa == cpf && value.codigoEmpresa == user.codigoEmpresa)
            .filter(value => value.status == 'SDO0009' || value.status == 'SDO0140')

          if (valid.length > 0) {
            result = true
          }

          let validActive = res.filter(value => value.cpfPessoa == cpf && value.codigoEmpresa == user.codigoEmpresa)
            .filter(value => value.status == 'SDO00036')

          if (validActive.length > 0) result = false
          else result = true

          console.log('valid', valid)

        }
        else result = true
      })
    return result
  }

  async conectar(tipoConexao, reg: CadastroPessoaForm, codigoEmpresa) {

    let result
    let registro = new SIS_CONECTPES
    let getInfoUser = await this._user.getRegistro(reg.cpf)
    registro.codigoEmpresa = codigoEmpresa
    registro.cpfPessoa = reg.cpf
    registro.criadoPor = user.cpf
    registro.tipoConexao = tipoConexao
    registro.status = 'SDO0100'
    registro.nome = getInfoUser.nomeCompleto
    registro.email = getInfoUser.email
    registro.tipoColaborador = 'TPCOLAB4'
    console.log('conectar', registro)
    await this.http.post(url, registro).toPromise().then(res => result = true)
      .catch(erro => {
        console.log('error!', erro)
        result = false
      })

    return result
  }
  async enviarConvite(tipoConexao, tipoColaborador, cpf, codigoEmpresa, cadastro: boolean, dados: cadastroUsuario, contato?) {
    let result
    let registro = new SIS_CONECTPES
    registro.codigoEmpresa = codigoEmpresa
    registro.cpfPessoa = cpf
    registro.criadoPor = user.cpf
    registro.tipoConexao = tipoConexao
    registro.tipoColaborador = tipoColaborador
    registro.email = dados.email
    registro.nome = dados.nomeCompleto

    if (cadastro) {
      registro.status = 'SDO0137'
      if (contato) {
        registro.status = 'SDO0141'
      }
    } else {
      registro.status = 'SDO0099'
      if (contato) {
        registro.status = 'SDO0037'
      }
      // email de confirmação
    }
    if (dados.status == 'SDO0066' || dados.status == 'SDO0085') {
      registro.status = 'SDO0138'
      if (contato) {
        registro.status = 'SDO0038'
      }
    }

    console.log('convite', registro)
    await this.http.post(url, registro).toPromise().then(async res => {
      await this.http.get(`${new environment().api}SendEmail/Convite/${dados.email}/${dados.nomeCompleto}/${cpf}/${tipoConexao}`).toPromise()
        .then(
          () => {
            result = true
          }
        )
    }).catch(async erro => {
      console.log('error!', erro)
      result = false
    })

    return result
  }
  async criarContato(registro: SIS_CONECTPES) {
    let result = false
    registro.status = 'SDO0036'
    registro.tipoConexao = 'TIPCON6'
    registro.tipoColaborador = 'TPCOLAB3'
    delete registro.id
    console.log('generete contato', registro)
    await this.http.post(url, registro).toPromise().then(
      response => {
        result = true
        console.log('criarContato', response)
      }
    )
    return result
  }


  async conexoesDoProfissional(cpf: string): Promise<SIS_CONECTPES[]> {
    let conexoesProfissionais: SIS_CONECTPES[] = [];
    await this.http.get<any>(`${url}Join/`).toPromise()
      .then(res => {
        if (res.success) {
          conexoesProfissionais = res.result
          conexoesProfissionais = conexoesProfissionais.filter(value => value.criadoPor == cpf || value.cpfPessoa == cpf)
        }

      })
      .catch((err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.mensagem.creat(`Você não possui conexões profissionais`, { aviso: true })
        } else {
          this.mensagem.creat(`Erro ao listar conexoes: ${err.message}`, { erro: true })
        }
      })
    return conexoesProfissionais;
  }

  async alterarConexaoProfissional(conexao: SIS_CONECTPES) {
    // const userId = this.serviceDiarioAcesso.idDestaSessao();
    let result = false
    const data = new Date().toISOString()
    console.log(`${url}${conexao.id}/${user.sessao_id}/${data}`)
    await this.http.put(`${url}${conexao.id}/${user.sessao_id}/${data}`, conexao).toPromise()
      .then(() => {
        result = true
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err)
      })


    return result
  }
}
