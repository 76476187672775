import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessaoUser } from 'src/app/user.reg';
import { environment } from 'src/environments/environment';
import { BuscarDataService } from '../buscar-data.service';
import { CabecalhoProntuarioVidaPaciente, ProntuarioDeVidaConteudo, ProntuarioDeVidaItems, ProntuarioVidaPaciente, SinaisVitaisDoPaciente } from './model';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class DadosDaSaudeService {
  url = new environment().api
  cabecalho = 'cabecalhoProntuarioVidaPaciente/'
  sinaisVitais = 'SinaisVitaisDoPaciente/'
  constructor(private http: HttpClient, private date: BuscarDataService) { }


  async getNumeroProntuario(cpf) {
    let result

    await this.http.get<CabecalhoProntuarioVidaPaciente[]>(this.url + this.cabecalho).toPromise()
      .then(res => {
        let verifc = res.filter(value => value.cpf == cpf)
        if (verifc.length > 0) {
          result = verifc[0].numeroDocumento
        }
      }).catch(error => console.log('CabecalhoProntuarioVidaPaciente', error))

    return result
  }

  async creatProntuario(cpf) {
    let result
    let registro = {
      criadoPor: sessao.cpf,
      cpf: cpf,
      dataHoraCriacaoDocumento : this.date.getDataInclusao(),
      status : 'SDO0060'
    }
    console.log(this.url + this.cabecalho, registro)
    await this.http.post(this.url + this.cabecalho, registro).toPromise()
      .then(res => {
        console.log('Creat Prontuario', res)
        try {
          result = res[0].key
        } catch {
          result = null
        }
      }).catch(error => {
        console.log('Erro Creat Prontuario', error)
      })
    return result
  }
  async postConteudo(registro, numeroDocumento) {
    let itens = Object.keys(registro)
    let conteudo = Object.values(registro)
    let cont = 0
    let result = 0
    for (let i = 0; i < itens.length; i++) {
      let codigo = itens[i]
      let valor = conteudo[i]
      if (valor) {
        cont += 1

        let doc = {
          item: cont,
          codigoDeReferencia: codigo,
          valor: valor,
          numeroDocumento: numeroDocumento,
          subItem : 0
        }

        console.log(this.url + 'ProntuarioDeVidaConteudo/', doc)
        
        await this.http.post(this.url + 'ProntuarioDeVidaConteudo/', doc).toPromise()
          .then(res => {
            console.log(res)
            result += 1
          }).catch(error => console.log('ProntuarioDeVidaConteudo', error))

      }

    }
    return {
      doc: cont,
      emit: result,
      success: cont == result ? true : false
    }
  }

  postItem(codigoCaracteristica, numeroDocumento) {
    let registro = new ProntuarioDeVidaItems
    registro.codigoCaracteristica = codigoCaracteristica
    registro.criadoPor = sessao.cpf
    registro.criadoEm = new Date().toISOString()
    registro.numeroDocumento = numeroDocumento
    registro.status = "SDO0060"
    registro.item = 1
    return this.http.post(this.url + 'ProntuarioDeVidaItems/', registro)

  }




  async getSinaisVitais(codigoUnico) {
    let result = []
    await this.http.get<SinaisVitaisDoPaciente[]>(this.url + this.sinaisVitais).toPromise()
      .then(res => {
        let filter = res.filter(value => value.codigoUnico == codigoUnico)
        result = filter
      }).catch(
        error => {
          console.log('getSinaisVitais erro >', error)
        }
      )
    return result
  }

  async postSinaisVitais(registro: SinaisVitaisDoPaciente) {
    let emit: boolean
    registro.dataHoraInclusaoTabela = this.date.getDataInclusao()
    console.log(this.url + this.sinaisVitais, registro)
    await this.http.post(this.url + this.sinaisVitais, registro).toPromise()
      .then(res => {
        console.log(res)
        if (res) emit = true
        else emit = false
      })
      .catch(error => {
        console.log('postSinaisVitais erro >', error)
        emit = false
      })
    return emit
  }

}
