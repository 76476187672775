export class dadosMinhasConexoesProfissionais {
    id?: number;
    codigoEmpresa?: number;
    cpfProfissional?: string;
    ativoDesde?: string;
    inativoDesde?: string;
    status?: string;
    timeStamp?: string;
    cpfAutor?: string;
    profissao?: any;
    cpf?: string;
    setorAtuacao?: string;
    tipoConexao?: string;
}


