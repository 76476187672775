import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ReciboMedicoRpsCabecalho, ReciboMedicoRpsItem, FaturaDeMedico, OrdemDeVendaMedicaCabecalho, OrdemDeVendaMedicaImpostoDoItem, OrdemDeVendaMedicaItem, FichaGuiaConsultaTissCabecalho, FichaGuiaConsultaTissItem, FaturaDeOperadoraCartao } from './modal';
import { CadastroItem } from 'src/app/cadastro-item/campos-cadastroItem';
import { SessaoUser } from 'src/app/user.reg';
import { AJ_LISTPRECO_ITM_PRECO } from '../determinar-preco-venda/model';
const url = new environment().api
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class FaturaMedicaService {
  url_ReciboMedicoRpsCabecalho = url + 'ReciboMedicoRpsCabecalho/'
  url_ReciboMedicoRpsItem = url + 'ReciboMedicoRpsItem/'
  url_FaturaDeMedico = url + 'FaturaDeMedico/'
  url_OrdemDeVendaMedicaCabecalho = url + 'OrdemDeVendaMedicaCabecalho/'
  url_OrdemDeVendaMedicaImpostoDoItem = url + 'OrdemDeVendaMedicaImpostoDoItem/'
  url_OrdemDeVendaMedicaItem = url + 'OrdemDeVendaMedicaItem/'
  url_FichaGuiaConsultaTissCabecalho = url + 'FichaGuiaConsultaTissCabecalho/'
  url_FichaGuiaConsultaTissItem = url + 'FichaGuiaConsultaTissItem/'
  url_FaturaDeOperadoraCartao = url + 'FaturaDeOperadoraCartao/'
  url_servicoes = url + 'CadastroItem/'
  url_CadastroOperadora = url + 'CadastroOperadora/'
  url_preco = url + 'AJ_LISTPRECO_ITM_PRECO/'


  constructor(private http: HttpClient) { }

  postReciboMedicoRpsCabecalho(item: ReciboMedicoRpsCabecalho) {
    item.criadoPor = sessao.cpf
    console.log('postReciboMedicoRpsCabecalho', this.url_ReciboMedicoRpsCabecalho, item)
    return this.http.post(this.url_ReciboMedicoRpsCabecalho, item)
  }
  postReciboMedicoRpsItem(item: ReciboMedicoRpsItem) {
    console.log('----', this.url_ReciboMedicoRpsItem, item)
    return this.http.post(this.url_ReciboMedicoRpsItem, item)
  }
  postFaturaDeMedico(item: FaturaDeMedico) {
    return this.http.post(this.url_FaturaDeMedico, item)
  }
  postOrdemDeVendaMedicaCabecalho(item: OrdemDeVendaMedicaCabecalho) {
    item.criadoPor = sessao.cpf
    return this.http.post(this.url_OrdemDeVendaMedicaCabecalho, item)
  }
  postOrdemDeVendaMedicaImpostoDoItem(item: OrdemDeVendaMedicaImpostoDoItem) {
    return this.http.post(this.url_OrdemDeVendaMedicaImpostoDoItem, item)
  }
  postOrdemDeVendaMedicaItem(item: OrdemDeVendaMedicaImpostoDoItem) {
    return this.http.post(this.url_OrdemDeVendaMedicaItem, item)
  }
  postFichaGuiaConsultaTissCabecalho(item: FichaGuiaConsultaTissCabecalho) {
    console.log(this.url_FichaGuiaConsultaTissCabecalho, item)
    return this.http.post(this.url_FichaGuiaConsultaTissCabecalho, item)
  }
  postFichaGuiaConsultaTissItem(item: FichaGuiaConsultaTissItem) {
    console.log(this.url_FichaGuiaConsultaTissItem, item)
    return this.http.post(this.url_FichaGuiaConsultaTissItem, item)
  }
  postFaturaDeOperadoraCartao(item) {
    return this.http.post(this.url_FaturaDeOperadoraCartao, item)
  }

  async getServicos() {
    let servicos = []
    await this.http.get<CadastroItem[]>(this.url_servicoes).toPromise()
      .then(async res => {
        console.log('getServico', res)
        let get = res.filter((item) => { return item.codigoEmpresa == sessao.codigoEmpresa && item.classificacao == 'CLITEM2' })
        for (let element of get) {
          let getPreco = await this.getPrecos(element.codigoEmpresa, element.codigoSKU)
          element.preco = getPreco.precoLista
          servicos.push(element)
        }
      }).catch(error => console.log(error))
    return servicos
  }
  async getPrecos(codigoEmpresa, codigoSKU) {
    let result = new AJ_LISTPRECO_ITM_PRECO
    await this.http.get<Array<AJ_LISTPRECO_ITM_PRECO>>(this.url_preco).toPromise()
      .then(res => {
        let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)
        let getPreco = filter.filter(value => value.codigoSku == codigoSKU)
        if (getPreco.length > 0) {
          result = getPreco[0]
        }
      })
    return result
  }


  getReciboMedicoRpsCabecalho() {
    return this.http.get<ReciboMedicoRpsCabecalho[]>(this.url_ReciboMedicoRpsCabecalho)
  }

  getReciboMedicoRpsItem() {
    return this.http.get<ReciboMedicoRpsItem[]>(this.url_ReciboMedicoRpsItem)
  }

  async getFaturaDeMedico(codigoEmpresa) {
    let result: FaturaDeMedico[] = []
    await this.http.get<FaturaDeMedico[]>(this.url_FaturaDeMedico + 'JoinFiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
      .then(res => {
        result = res
      })
    return result
  }

  getOrdemDeVendaMedicaCabecalho() {
    return this.http.get<OrdemDeVendaMedicaCabecalho[]>(this.url_OrdemDeVendaMedicaCabecalho)
  }

  getOrdemDeVendaMedicaImpostoDoItem() {
    return this.http.get<OrdemDeVendaMedicaImpostoDoItem[]>(this.url_OrdemDeVendaMedicaImpostoDoItem)
  }

  getOrdemDeVendaMedicaItem() {
    return this.http.get<OrdemDeVendaMedicaItem[]>(this.url_OrdemDeVendaMedicaItem)
  }

  getFichaGuiaConsultaTissCabecalho() {
    return this.http.get<FichaGuiaConsultaTissCabecalho[]>(this.url_FichaGuiaConsultaTissCabecalho)
  }

  getFichaGuiaConsultaTissItem() {
    return this.http.get<FichaGuiaConsultaTissItem>(this.url_FichaGuiaConsultaTissItem)
  }

  getFaturaDeOperadoraCartao() {
    return this.http.get<FaturaDeOperadoraCartao>(this.url_FaturaDeOperadoraCartao)
  }

  getCadastroOperadora() {
    return this.http.get(this.url_CadastroOperadora)
  }
  postCadastroOperadora(registro: FaturaDeOperadoraCartao) {
    return this.http.post<FaturaDeOperadoraCartao>(this.url_FaturaDeOperadoraCartao, registro)
  }







}

