import { Injectable } from '@angular/core';
import { SessaoUser } from '../user.reg';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BuscarDataService } from './buscar-data.service';
import { TicketConsulta, TicketReserva, TicketSenhaAtendimento, TicketSenhaAtendimentoJoin } from '../home/monitor-clinico/consultas/solicitacoes/modal';
import { REQUEST } from './modal';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  url = new environment().api
  constructor(private http: HttpClient, private date: BuscarDataService) { }

  gerarTicketConsulta(ticket: TicketConsulta) {
    return this.http.post(this.url + 'TicketMarcacaoConsultaMedica/', ticket)
  }
  async getRefConsulta(codigoReferencia: string) {
    let result = new TicketConsulta
    await this.http.get<TicketConsulta[]>(this.url + 'TicketMarcacaoConsultaMedica/').toPromise()
      .then(res => {
        let filter = res.filter(value => value.nrDocRef == codigoReferencia)
        if (filter.length > 0) result = filter[0]
        else {
          let filter = res.filter(value => value.nrDoc == codigoReferencia)
          if (filter.length > 0) result = filter[0]
        }
      })
    return result
  }
  async getRefReserva(codigoReferencia: string) {
    let result = new TicketReserva
    await this.http.get<TicketReserva[]>(this.url + 'TicketReservaConsultaMedica/').toPromise()
      .then(res => {
        let filter = res.filter(value => value.nrDocRef == codigoReferencia)
        if (filter.length > 0) result = filter[0]
        else {
          let filter = res.filter(value => value.nrDoc == codigoReferencia)
          if (filter.length > 0) result = filter[0]
        }
      })
    return result
  }

  gerarTicketSenha(ticket: TicketSenhaAtendimento) {
    return this.http.post(this.url + 'TicketSenhaAtendimento/', ticket)
  }

  getTicketConsulta() {
    return this.http.get<TicketConsulta[]>(this.url + 'TicketMarcacaoConsultaMedica/')
  }
  getTicketConsultaJOIN(cpf: string, join?: boolean) {
    return this.http.get<REQUEST<TicketConsulta>>(`${this.url}TicketMarcacaoConsultaMedica/${join ? 'join/' : 'FiltrarCpfPaciente/'}${cpf}` )
  }
  async getConsultas(codigoEmpresa) {
    let result = []
    await this.http.get<any>(this.url + 'TicketMarcacaoConsultaMedica/FiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
      .then(res => {
        if (res.success) {
          result = res.result
        }
      })
    return result
  }
  async getAtendimentoConsultura(codigoConsultorio) {
    let result = []
    await this.http.get(this.url + 'TicketMarcacaoConsultaMedica/FiltrarCodigoConsultorio/' + codigoConsultorio).toPromise()
      .then((res: any) => {
        if (res.success) {
          result = res.result.filter(value => value.nrDoc == codigoConsultorio || value.nrDocRef == codigoConsultorio)
        }
      }).catch(() => { })
    return result
  }


  getTicketSenha() {
    return this.http.get(this.url + 'TicketSenhaAtendimento/')
  }
  async getTicketReservaID(id) {
    let result = new TicketReserva
    await this.http.get<TicketReserva>(this.url + 'TicketReservaConsultaMedica/' + id).toPromise()
      .then(res => {
        result = res
      })
    return result
  }
  async getTicketConsultaID(id) {
    let result = new TicketConsulta
    await this.http.get<TicketConsulta>(this.url + 'TicketMarcacaoConsultaMedica/' + id).toPromise()
      .then(res => {
        result = res
      })
    return result
  }
  async atualizarTicketReserva(dado: TicketReserva) {
    let result = false
    console.log('alter', this.url + 'TicketReservaConsultaMedica/' + `${dado.id}/${new Date().toISOString()}/${sessao.sessao_id}/`, dado)
    await this.http.put(this.url + 'TicketReservaConsultaMedica/' + `${dado.id}/${new Date().toISOString()}/${sessao.sessao_id}/`, dado)
      .toPromise().then(() => result = true).catch(error => console.log('ALTER TicketReservaConsultaMedica = >', error))
    return result
  }
  async getTicketSenhaJoin(numeroDocumento: string) {
    let result = new TicketSenhaAtendimentoJoin
    await this.http.get<Array<TicketSenhaAtendimentoJoin>>(this.url + 'TicketSenhaAtendimento/Join').toPromise()
      .then(res => {
        let get = res.filter(v => v.codigoEmpresa == sessao.codigoEmpresa)
          .filter(v => v.nrDoc == numeroDocumento || v.nrDocReferencia == numeroDocumento)[0]
        if (get) {
          result = get
        }
      })
    return result
  }

  async atualizarTicketConsulta(registro: TicketConsulta) {
    let result = false
    let changlog = `${registro.id}/${this.date.horaAgora(true)}/${sessao.sessao_id}`
    console.log('TicketMarcacaoConsultaMedica/' + changlog, JSON.stringify(registro))
    await this.http.put(this.url + 'TicketMarcacaoConsultaMedica/' + changlog, registro)
      .toPromise().then(() => result = true).catch(error => console.log('ALTER TicketReservaConsultaMedica = >', error))
    return result
  }
}
