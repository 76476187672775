import { ValueTransformer } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ValidateBrService } from 'angular-validate-br';
import { cadastroUsuario } from 'src/app/admin-usuarios/model';
import { SIS_CONECTPES } from 'src/app/service/ficha-conexoes-profissionais/ficha-conexao-profissional.model';
import { FichaConexoesProfissionaisService } from 'src/app/service/ficha-conexoes-profissionais/ficha-conexoes-profissionais.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
@Component({
  selector: 'app-meus-contatos',
  templateUrl: './meus-contatos.component.html',
  styleUrls: ['./meus-contatos.component.scss']
})
export class MeusContatosComponent implements OnInit {
  displayedColumns: string[] = ['status', 'fotoPerfil', 'nome', 'email', 'cpf', 'timestamp', 'detalhe'];
  displayedColumnsContatos: string[] = ['status', 'fotoPerfil', 'nome', 'email', 'detalhe'];
  dataSource = new MatTableDataSource()
  dataSourceConvites = new MatTableDataSource()
  loadingConvite: boolean;
  loading: boolean;
  formConvite: FormGroup;
  formEmail: FormGroup;
  blockEnvio: boolean;
  noUsuario: boolean;
  registroUsuario = new cadastroUsuario;
  noUsuario_message: string;
  viewCadastro: boolean;
  menu = 'pendente'
  invalidEmail: boolean;
  coviteEnviado: boolean;
  viewConvite: boolean;
  contatos: any[];
  dataSourceContatos = new MatTableDataSource()
  loadingContatos: boolean;
  loadingUser: boolean;
  cpf: string;

  constructor(
    private service: FichaConexoesProfissionaisService,
    private _filter: FiltrarDadosService,
    private form: FormBuilder,
    private _usuario: UsuarioService,
    private validBR: ValidateBrService,
    private _mensagem: MensagemService) {
    this.cpf = sessao.cpf
  }

  ngOnInit() {
    this.getConexoes()
    this.creatForm()
  }
  async getConexoes() {

    this.loading = true
    this.loadingConvite = true
    this.loadingContatos = true

    let getRegistro = await this.service.getConexoes()
    getRegistro.forEach(value => value.backgroud = { 'background-image': `url('assets/imgUser.png')` })
    console.log('registro', getRegistro)
    let myConvites = getRegistro.filter(v => v.tipoConexao == 'TIPCON6' && v.criadoPor == sessao.cpf).filter(value => {
      return value.status == 'SDO0141' ||
        value.status == 'SDO0140' ||
        value.status == 'SDO0038' ||
        value.status == 'SDO0037' ||
        value.status == 'SDO0009'
    }
    )
    //Quem você convidou
    this.dataSource = new MatTableDataSource(myConvites)
    myConvites.map(value => value.cpfFoto = value.cpfPessoa)
    console.log('convites', this.dataSource.data)
    this.loading = false



    let getConvites = await this.service.getConvitesContatos(getRegistro)
    console.log('me convidou', getConvites)
    //Quem convidou você
    getConvites = getConvites.filter(value => value.tipoConexao == 'TIPCON6').filter(value=> value.cpfPessoa == sessao.cpf || value.criadoPor == sessao.cpf)
    getConvites.map(value => value.cpfFoto = value.cpfPessoa != sessao.cpf ? value.cpfPessoa : value.criadoPor)

    this.dataSourceConvites = new MatTableDataSource(getConvites)


    this.loadingConvite = false

    this.contatos = getRegistro
      .filter(value=> value.criadoPor == sessao.cpf || value.cpfPessoa == sessao.cpf)
      .filter(value => value.tipoConexao == 'TIPCON6')
      .filter(value => value.status == 'SDO0036' || value.status == 'SDO0035')
    this.contatos.map(value => value.cpfFoto = value.cpfPessoa == sessao.cpf ? value.criadoPor : value.cpfPessoa)

    this.dataSourceContatos = new MatTableDataSource(this.contatos)
    console.log('contatos', this.contatos)

    this.loadingContatos = false

    let registros = [...myConvites, ...getConvites, ...this.contatos]

    for (let i = 0; i < registros.length; i++) {
      let value = registros[i]
      if (value.cpfPessoa == sessao.cpf) {
        let get = await this._usuario.getRegistro(value.criadoPor)
        value.email = get.email
      }
    }
    console.log('REGISTROS!**', registros)
    registros.forEach(value => value.timestamp = this._filter.convertCriadoEm(value.criadoEm))
    this._filter.inserirNomeEmpresaRequest(registros)
    this._filter.inserirNomeEmpresaRequest(getConvites)
    this._filter.inserirFotoPerfil('cpfFoto', registros)

  }
  async getUsuario() {

    this.blockEnvio = false
    this.noUsuario = false

    this.registroUsuario = new cadastroUsuario;

    if (this.formConvite.get('cpf').valid) {
      this.loadingUser = true
      this.noUsuario_message = "Carregando...."
      let verifConvite = await this.service.verificConviteContato(this.formConvite.get('cpf').value)
      this.loadingUser = false
      console.log('verifConvite', verifConvite)



      let result = await this._usuario.getRegistro(this.formConvite.get('cpf').value)
      if (result.id) {
        if (!verifConvite) {
          this._mensagem.creat(`Existe convite pendente para  ${result.nomeCompleto}, não é possivel convidar novamente`, { aviso: true })
          this.noUsuario = true
          this.noUsuario_message = `Existe convite pendente para  ${result.nomeCompleto},`
          this.blockEnvio = true
          return
        }
        let status = result.status
        if (status == 'SDO0060') {
          this.registroUsuario = result
        } else {
          this.noUsuario = true
        }
        if (status == 'SDO0042') {
          this.noUsuario_message = "Este CPF esta desabilitado no MyClinics,Não é possivel estabelecer conexão"
        }
        if (status == 'SDO0066') {
          this.noUsuario_message = "Este CPF ainda não confirmou sua inscrição no MyClinics, mas pode ser convidado."
          this.registroUsuario = result
        }
        this.viewCadastro = false
      } else {
        console.log('passou')
        this.noUsuario = true
        this.viewCadastro = true
      }
    }

  }


  async enviarConvite(cadastro?) {
    if (!this.registroUsuario.cpf && !cadastro) {
      this.viewCadastro = true
      return
    }
    if (sessao.cpf == this.registroUsuario.cpf) {
      this._mensagem.creat(`Não é possivel convidar a si mesmo`, { aviso: true })
      this.noUsuario = true
      this.noUsuario_message = `Não é possivel convidar a si mesmo`
      return
    }
    else {
      this.viewCadastro = false
    }
    if (this.registroUsuario.email) this.formEmail.get('email').setValue(this.registroUsuario.email)
    if (this.registroUsuario.nomeCompleto) this.formEmail.get('nomeCompleto').setValue(this.registroUsuario.nomeCompleto)
    if (this.formEmail.invalid && this.viewCadastro) {
      this.invalidEmail = true
      return
    } else {
      this.invalidEmail = false
    }
    let empresa = sessao.codigoEmpresa
    let cpf = this.formConvite.get('cpf').value
    console.log(this.formConvite.value)

    if (this.registroUsuario.id) cadastro = true
    else cadastro = false

    let result = this.service.enviarConvite('TIPCON6', 'TPCOLAB3', cpf, empresa, cadastro, this.registroUsuario, true)
    if (result) {
      this._mensagem.creat('Convite enviado com sucesso', { sucesso: true })
      this.coviteEnviado = true
    } else {
      this._mensagem.creat('Não foi possivel enviar convite', { sucesso: true })
    }
  }

  resetConvite() {
    this.coviteEnviado = false
    this.viewConvite = false
    this.viewCadastro = false
    this.formConvite.reset()
    this.registroUsuario = new cadastroUsuario
    this.getConexoes()
  }
  async removeConvite(registro: SIS_CONECTPES) {
    this.loading = true
    registro.status = 'SDO0009'
    let response = await this.service.alterarConexaoProfissional(registro)
    if (response) {
      this._mensagem.creat('Convite cancelado com sucesso', { sucesso: true })
      await this.getConexoes()
    } else {
      this._mensagem.creat('Não foi possivel cancelar convite', { erro: true })
      this.loading = false
    }
  }
  async aceitarConvite(aceitar, registro) {
    let result
    if (aceitar) {
      registro.status = 'SDO0036'
      result = await this.service.alterarConexaoProfissional(registro)
    } else {
      registro.status = 'SDO0140'
      result = await this.service.alterarConexaoProfissional(registro)
    }

    if (result) {
      this._mensagem.creat(aceitar ? 'Convite aceito com sucesso' : 'Convite rejeitado com sucesso', { sucesso: true })
    } else {
      this._mensagem.creat('Erro ao realizar procedimento', { erro: true })
    }
    this.getConexoes()
    console.log(registro)
  }
  async desconectar(registro) {

    console.log(registro)
    let result

    let conexao = await this.service.getConecID(registro.id)
    conexao.status = "SDO0035"
    result = await this.service.alterarConexaoProfissional(conexao)
    if (result) {
      this._mensagem.creat('Desconectado com sucesso', { sucesso: true })
    } else {
      this._mensagem.creat('Erro ao realizar procedimento', { erro: true })
    }
    this.getConexoes()
  }

  creatForm() {
    this.formConvite = this.form.group({
      cpf: new FormControl('', [Validators.required, this.validBR.cpf]),
    })
    this.formEmail = this.form.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      nomeCompleto: new FormControl('', Validators.required),
    })
  }
}
