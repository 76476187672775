export class CamposPosicaoNfe {
    id: string
    criadoPor: string
    codigoEmpresa: number
    posicaoInicial: number
    ultimaEmissaoValida: number
    ultimaCancelada: number
    proximaEmissao: number
    status: string
    timeStamp: string
    ///
    nomeEmpresa: string
}

export class CamposFichaGeralEmpresas {
    codigoEmpresa: number
    nomeEmpresa: string
    tipoEmpresa: string
    enderecoEmpresa: string
    webSiteEmpresa: string
    cabecalhoImpressao: string
    telefoneEmpresa: string
    email: string
    status: string
    timestamp: string
    observacao: string
    codigoAssinante: string
}
