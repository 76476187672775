import { Component, OnInit } from '@angular/core';
import { FichaGeralEmpresa } from '../../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { TimezonesService } from 'src/app/service/timezones/timezones.service'
import { timezone } from 'src/app/service/timezones/timezone.model'
import { conteudoDeDado } from 'src/app/service/classificacao-de-dados/conteudo-de-dados.model'
import { MensagemService } from 'src/app/service/mensagem.service';
import { AreaAtuacaoService } from 'src/app/service/area-atuacao/area-atuacao.service';
import { CPF, HubDevService } from 'src/app/service/hub-dev.service';
import { CadastroDadosProfissionaisService } from 'src/app/service/cadastro-dados-profissionais.service';
import { SistemaClassificacaoDadosService } from 'src/app/service/sistema-classificacao-dados.service';
import { dadosDefinirEmpresaMedica } from './modelsDefinirEmpresaMedica';
import { DiarioDeAcessoService } from 'src/app/service/diario-de-acesso/diario-de-acesso.service';
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service';
import { ServicosMedicosDisponiveisService } from 'src/app/service/servicos-medicos-disponiveis/servicos-medicos-disponiveis.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { SessaoUser } from 'src/app/user.reg';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';

const sessao = new SessaoUser

@Component({
  selector: 'app-definir-empresa-medica',
  templateUrl: './definir-empresa-medica.component.html',
  styleUrls: ['./definir-empresa-medica.component.scss']
})

export class DefinirEmpresaMedicaComponentOld implements OnInit {

  id: number
  listDoc: FichaGeralEmpresa[];
  viewAdd = false
  cpf: string
  menu = 1
  menu2 = 1
  tipoEmpresa: string
  menuTabs = true
  adicionadosFichaGeral: 1
  arquivo: any
  loadings: boolean;

  resultadoAssinante

  dataServicosMedicosDisponiveis: string[];
  servicoMedicoSelecionado: string;
  timezones: timezone[];
  conteudoAreasAtuacaoAns: conteudoDeDado[];
  tiposEmpresa: conteudoDeDado[];
  conteudoServicosMedicos: conteudoDeDado[];
  conteudoMoedas: conteudoDeDado[];
  conteudoMatrizFilial: conteudoDeDado[];
  alterarEmpresa: boolean;
  listar: boolean;
  view: boolean;
  listaAreaAtuacaoAns = []
  listaServicosMedicos = []
  listaLegislacaoVigente = []
  resultadoAreaAtuacaoCodigoEmpresa = []
  listaServicosMedicosID = []
  resultadoAreaAtuacao = []
  dadosFichaGeralEmpresaIDToken: dadosDefinirEmpresaMedica
  empresasDoAssinante: dadosDefinirEmpresaMedica[]
  dadosEmpresaDoAssinanteCadastrar: dadosDefinirEmpresaMedica = new dadosDefinirEmpresaMedica
  dadosEmpresaDoAssinanteCadastrarID: dadosDefinirEmpresaMedica
  variavelID: dadosDefinirEmpresaMedica
  listaCheckBox: dadosDefinirEmpresaMedica[] = []
  listaCheckBox2: dadosDefinirEmpresaMedica[] = []
  nomeResponsavelClinico
  especialidadeResponsavelClinico
  nomeResponsavelClinicoID
  especialidadeResponsavelClinicoID
  RazaoSocialEmpresa

  constructor(
    private fichaGeralService: FichaGeralService,
    private timezonesService: TimezonesService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private serviceSistemaClassificacaoDados: SistemaClassificacaoDadosService,
    private mensagem: MensagemService,
    private serviceAreaAtuacao: AreaAtuacaoService,
    private _cep: HubDevService,
    private serviceCadastroDadosProfissionais: CadastroDadosProfissionaisService,
    private serviceServicosMedicosDisponiveis: ServicosMedicosDisponiveisService,
    private serviceHora: BuscarDataService,
    private serviceHubDev: HubDevService,
    private serviceTokenAcesso: TokenDeAcessoService
  ) {
  }

  async ngOnInit() {
    this.buscarEmpresaMedica();
    this.getCaracteristicasATUMEDItem();
    this.getCaracteristicasMOEDASItem();
    this.getCaracteristicasSERMEDItem();
    this.getCaracteristicasEMPFISItem();
    this.getCaracteristicasTIPEMPItem();
    this.timezones = await this.timezonesService.listarTimezones();
    this.listar = true
    this.dadosEmpresaDoAssinanteCadastrar.cpfProprietario = sessao.cpf
    var cpf = sessao.cpf
    this.resultadoAssinante = await this.serviceFichaAssinante.buscarAssinante({ cpf })
  }

  //GET 

  async buscarEmpresaMedica() {
    this.loadings = true
    var cpf = sessao.cpf
    this.resultadoAssinante = await this.serviceFichaAssinante.buscarAssinante({ cpf })
    this.fichaGeralService.buscarEmpresasCodigoAssinante(this.resultadoAssinante.codigoAssinante).subscribe(
      (resultado: Array<dadosDefinirEmpresaMedica>) => {
        let filtro = resultado.filter(function (reg: dadosDefinirEmpresaMedica) {
          return reg.status === "SDO0017"
        })
        this.empresasDoAssinante = filtro
        this.loadings = false
        this.empresasDoAssinante.forEach(async element => {
          var resultadoEmpresa = this.buscarEmpresaMedicaCodigoEmpresa(element.codigoEmpresa)
            ; (await resultadoEmpresa).forEach(element => {
              this.resultadoAreaAtuacao.push(
                element,
                this.resultadoAreaAtuacao = []
              )
            });
        })
      }), erro => {
        this.loadings = false
        this.mensagem.creat("Não foi possível buscar as empresas vinculadas a está assinatura.", { erro: true })
      }
  }

  async buscarEmpresaMedicaCodigoEmpresa(id: any) {
    this.serviceAreaAtuacao.getAreaAtuacaoCodigoEmpresa(id).toPromise().then(
      (resultado: Array<dadosDefinirEmpresaMedica>) => {
        resultado.forEach(element => {
          this.resultadoAreaAtuacao.push(
            element
          )
        });
      })
    return this.resultadoAreaAtuacao
  }

  async buscarEmpresaMedicaCodigoEmpresaID(id: any) {
    this.serviceAreaAtuacao.getAreaAtuacaoCodigoEmpresa(id).toPromise().then(
      (resultado: Array<dadosDefinirEmpresaMedica>) => {
        this.listaCheckBox = []
        this.resultadoAreaAtuacaoCodigoEmpresa = resultado
      })
    return this.resultadoAreaAtuacao
  }

  async buscarDefinirEmpresaMedicaID() {
    this.fichaGeralService.getFichaGeralEmpresaID(this.id).subscribe(
      (resultado => {
        this.listaCheckBox = []
        this.dadosEmpresaDoAssinanteCadastrar = resultado[0]
        this.dadosEmpresaDoAssinanteCadastrar.cpfProprietario = this.cpf
        this.getInformacoesCRMID(this.dadosEmpresaDoAssinanteCadastrar.cpfResponsavel)
        this.buscarServicosMedicosCodigoEmpresa(this.dadosEmpresaDoAssinanteCadastrar.codigoEmpresa)
        this.buscarEmpresaMedicaCodigoEmpresaID(this.dadosEmpresaDoAssinanteCadastrar.codigoEmpresa)
        this.listar = false
      }), erro => {
        this.mensagem.creat("Impossível buscar os dados da Ficha de Prazo Pagamento específica no momente, tente novamente mais tarde.", { aviso: true })
      })
  }

  async buscarServicosMedicosCodigoEmpresa(id: any) {
    this.serviceServicosMedicosDisponiveis.getServicosMedicoCodigoEmpresa(id).subscribe(
      (resultado: Array<any>) => {
        this.listaCheckBox = []
        this.listaServicosMedicosID = resultado
      }), (erro => {
        console.log("Erro: " + erro)
      })
  }

  async getCaracteristicasATUMEDItem() {
    var Caracteristica = "ATUMED"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado: Array<conteudoDeDado>) => {
        this.conteudoAreasAtuacaoAns = resultado
      })
  }

  async getCaracteristicasMOEDASItem() {
    var Caracteristica = "MOEDAS"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado: Array<conteudoDeDado>) => {
        this.conteudoMoedas = resultado
      })
  }

  async getCaracteristicasSERMEDItem() {
    var Caracteristica = "SERMED"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado: Array<conteudoDeDado>) => {
        this.conteudoServicosMedicos = resultado
      })
  }

  async getCaracteristicasEMPFISItem() {
    var Caracteristica = "EMPFIS"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado: Array<conteudoDeDado>) => {
        this.conteudoMatrizFilial = resultado
      })
  }

  async getCaracteristicasTIPEMPItem() {
    var Caracteristica = "TIPEMP"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado: Array<conteudoDeDado>) => {
        this.tiposEmpresa = resultado
      })
  }

  async getCEP() {
    let DadosCep = this.dadosEmpresaDoAssinanteCadastrar.cep
    if (DadosCep.length < 8) return
    this._cep.vCEP(DadosCep).toPromise().then(
      (resp) => {
        let cep = resp.result
        this.dadosEmpresaDoAssinanteCadastrar.rua = cep.logradouro
        this.dadosEmpresaDoAssinanteCadastrar.bairro = cep.bairro
        this.dadosEmpresaDoAssinanteCadastrar.cidade = cep.localidade
        this.dadosEmpresaDoAssinanteCadastrar.estado = cep.uf
      }
    )
  }

  async getCEPID() {
    let DadosCep = this.dadosEmpresaDoAssinanteCadastrarID.cep
    this._cep.vCEP(DadosCep).toPromise().then(
      (resp) => {
        let cep = resp.result
        this.dadosEmpresaDoAssinanteCadastrarID.rua = cep.logradouro
        this.dadosEmpresaDoAssinanteCadastrarID.bairro = cep.bairro
        this.dadosEmpresaDoAssinanteCadastrarID.cidade = cep.localidade
        this.dadosEmpresaDoAssinanteCadastrarID.estado = cep.uf
      }
    )
  }

  async getInformacoesCRM(cpfResponsavel: any) {
    // this.dadosEmpresaDoAssinanteCadastrar.cpfResponsavel
    // this.serviceCadastroDadosProfissionais.getCadastroDadosProfissionaisCpf(cpfResponsavel).toPromise().then(
    //   (res => {
    //     if (res[0].nr_Registro != null || res[0].nr_Registro != undefined) {
    //       if (res[0].profissao === "CADPRO1") {
    //         this.dadosEmpresaDoAssinanteCadastrar.crmResponsavel = res[0].nr_Registro
    //         this.nomeResponsavelClinico = res[0].nomeCompleto
    //         this.especialidadeResponsavelClinico = res[0].valor
    //       }
    //       else {
    //         this.dadosEmpresaDoAssinanteCadastrar.crmResponsavel = ""
    //         this.nomeResponsavelClinico = ""
    //         this.especialidadeResponsavelClinico = ""

    //         this.mensagem.creat("Código da mensagem: E000017. CPF inválido, não pertence a profissional médico.", { erro: true })
    //       }

    //     }
    //     else {
    //       this.mensagem.creat("Não foi possível encontrar os dados do responsável clínico, digite novamente o CPF.", { aviso: true })
    //     }
    //   })
    // )
  }

  async getInformacoesCRMID(cpfResponsavel) {
    // this.nomeResponsavelClinicoID = null
    // this.serviceCadastroDadosProfissionais.getCadastroDadosProfissionaisCpf(cpfResponsavel).toPromise().then(
    //   (res => {
    //     if (res[0].nr_Registro != null || res[0].nr_Registro != undefined) {
    //       if (res[0].profissao === "CADPRO1") {
    //         this.dadosEmpresaDoAssinanteCadastrarID.crmResponsavel = res[0].nr_Registro
    //         this.nomeResponsavelClinicoID = res[0].nomeCompleto
    //         this.especialidadeResponsavelClinicoID = res[0].valor
    //       }
    //       else {
    //         this.dadosEmpresaDoAssinanteCadastrarID.crmResponsavel = ""
    //         this.nomeResponsavelClinicoID = ""
    //         this.especialidadeResponsavelClinicoID = ""
    //         this.mensagem.creat("Código da mensagem: E000017. CPF inválido, não pertence a profissional médico.", { erro: true })
    //       }
    //     }
    //     else {
    //       this.mensagem.creat("Não foi possível encontrar os dados do responsável clínico, digite novamente o CPF.", { aviso: true })
    //     }
    //   })
    // )
  }

  //PUT E POST

  async ValidarCpf(cpf) {
    if (cpf.length < 11) return
    await this.serviceHubDev.vCPFParametro(cpf).toPromise().then(({ status, result: cpf }) => {
      if (!status) {
        this.dadosEmpresaDoAssinanteCadastrar.crmResponsavel = ""
        this.nomeResponsavelClinico = ""
        this.especialidadeResponsavelClinico = ""
        this.mensagem.creat("CPF não encontrado, verifique se foi digitado corretamente.", { erro: true })
      }
      else {
        this.dadosEmpresaDoAssinanteCadastrar.crmResponsavel = ""
        this.nomeResponsavelClinico = ""
        this.especialidadeResponsavelClinico = ""
        this.mensagem.creat("Código da mensagem: E000017. CPF inválido, não pertence a profissional médico.", { erro: true })
      }
    })
  }

  async ValidarCpfID(cpf) {
    if (cpf.length < 11) return
    (await this.serviceHubDev.vCPFParametro(cpf)).toPromise().then(({ status, result: cpf }) => {
      if (!status) {
        this.dadosEmpresaDoAssinanteCadastrarID.crmResponsavel = ""
        this.nomeResponsavelClinicoID = ""
        this.especialidadeResponsavelClinicoID = ""
        alert("CPF Invalido")
        throw this.mensagem.creat("CPF não encontrado, verifique se foi digitado corretamente.", { erro: true })
      }
      else {
        this.dadosEmpresaDoAssinanteCadastrarID.crmResponsavel = ""
        this.nomeResponsavelClinicoID = ""
        this.especialidadeResponsavelClinicoID = ""
        this.mensagem.creat("Código da mensagem: E000017. CPF inválido, não pertence a profissional médico.", { erro: true })
      }
    })
  }

  async ValidarCnpj(cnpj) {
    if (cnpj.length < 14) return
    this.serviceHubDev.vCNPJ(cnpj).subscribe(({ status, result: empresa }) => {
      if (!status) {
        this.dadosEmpresaDoAssinanteCadastrar.cnpj = ""
        this.dadosEmpresaDoAssinanteCadastrar.nomeEmpresa = ""
        this.dadosEmpresaDoAssinanteCadastrar.nomeFantasia = ""
        this.dadosEmpresaDoAssinanteCadastrar.cep = ""
        throw this.mensagem.creat("Cnpj não encontrado, verifique se foi digitado corretamente.", { erro: true })
      }
      else {
        this.dadosEmpresaDoAssinanteCadastrar.nomeEmpresa = empresa.nome
        this.dadosEmpresaDoAssinanteCadastrar.nomeFantasia = empresa.fantasia
        this.dadosEmpresaDoAssinanteCadastrar.cep = empresa.cep
        setTimeout(() => this.getCEP(), 100)
      }
    })
  }

  async ValidarCnpjID(cnpj) {
    this.serviceHubDev.vCNPJ(cnpj).subscribe(({ status, result: empresa }) => {
      if (!status) {
        this.dadosEmpresaDoAssinanteCadastrarID.cnpj = ""
        this.dadosEmpresaDoAssinanteCadastrarID.nomeEmpresa = ""
        this.dadosEmpresaDoAssinanteCadastrarID.nomeFantasia = ""
        this.dadosEmpresaDoAssinanteCadastrarID.cep = ""
        throw this.mensagem.creat("Cnpj não encontrado, verifique se foi digitado corretamente.", { erro: true })
      }
      else {
        this.dadosEmpresaDoAssinanteCadastrarID.nomeEmpresa = empresa.nome
        this.dadosEmpresaDoAssinanteCadastrarID.nomeFantasia = empresa.fantasia
        this.dadosEmpresaDoAssinanteCadastrarID.cep = empresa.cep
      }
    })
  }

  async gravarDadosDefinirEmpresaMedica() {
    this.loadings = true
    if(this.dadosEmpresaDoAssinanteCadastrar.id){
      this.AlterarDefinirEmpresaMedica()
      return
    }
    if (sessao.cpf) {
      if (sessao.cpf === this.resultadoAssinante.cpfUsuario || sessao.cpf === this.resultadoAssinante.administradorAssinante) {
        this.fichaGeralService.getFichaGeralEmpresaID(sessao.codigoEmpresa).subscribe(
          (resultado => {
            var dadosFichaGeralCodigoAssinante = resultado
            this.dadosEmpresaDoAssinanteCadastrar.codigoAssinante = dadosFichaGeralCodigoAssinante[0].codigoAssinante
            this.dadosEmpresaDoAssinanteCadastrar.classificacaoEmpresa = "CLAEMP2"
            var codigoAssinante = this.dadosEmpresaDoAssinanteCadastrar.codigoAssinante
            var cpfProfissional = this.dadosEmpresaDoAssinanteCadastrar.cpfResponsavel
            var cpfAdministrador = this.dadosEmpresaDoAssinanteCadastrar.cpfProprietario = sessao.cpf
            this.dadosEmpresaDoAssinanteCadastrar.status = "SDO0017"

            this.fichaGeralService.postFichaGeralEmpresa(this.dadosEmpresaDoAssinanteCadastrar).subscribe(
              (sucesso => {
                this.variavelID = sucesso

                if (this.listaServicosMedicos.length > 0) {
                  this.listaServicosMedicos.forEach((reg, i) => {
                    this.listaServicosMedicos[i].codigoEmpresa = this.variavelID[0].codigoEmpresa
                    this.listaServicosMedicos[i].status = "SDO0017"
                  });
                }
                if (this.listaAreaAtuacaoAns.length > 0) {
                  this.listaAreaAtuacaoAns.forEach((reg, i) => {
                    this.listaAreaAtuacaoAns[i].codigoEmpresa = this.variavelID[0].codigoEmpresa
                    this.listaAreaAtuacaoAns[i].status = "SDO0017"
                    this.listaAreaAtuacaoAns[i].cpfAutor = sessao.cpf
                  });
                }
                this.casdastrarAreaAtuacao()
                this.casdastrarServico()

                // this.serviceTokenAcesso.criarToken(this.variavelID[0].codigoEmpresa, codigoAssinante, cpfAdministrador).toPromise().then(
                //   (resultado => {
                //     if (resultado[0] === "não possui assinatura vinculada") {
                //       this.fichaGeralService.getFichaGeralEmpresaID(this.variavelID[0].codigoEmpresa).subscribe(
                //         (resultado => {
                //           this.dadosFichaGeralEmpresaIDToken = resultado
                //           this.dadosFichaGeralEmpresaIDToken.status = "SDO0063"
                //           this.fichaGeralService.AlterarFichaGeralEmpresa(this.dadosFichaGeralEmpresaIDToken.id, sessao.sessao_id, this.serviceHora.horaAgora(true), this.dadosFichaGeralEmpresaIDToken).subscribe(
                //             (resultado => {
                //               this.mensagem.creat("Não é possível cadastrar a empresa no sistema pois o úsuario não tem Assinatura vinculada.", { erro: true })
                //               this.buscarEmpresaMedica()
                //               this.loadings = false
                //               this.listar = true
                //             })
                //           )
                //         })
                //       )
                //     } else {
                //       this.serviceTokenAcesso.criarToken(this.variavelID[0].codigoEmpresa, codigoAssinante, cpfProfissional).toPromise().then(
                //         (resultado => {
                //           this.mensagem.creat('Gravado com Sucesso!', { sucesso: true })
                //           this.buscarEmpresaMedica()
                //           this.loadings = false
                //           this.listar = true
                //         })
                //       )
                //     }
                //   })
                // ).catch(
                //   erro => console.log(erro)
                // )
              })), erro => {
                this.mensagem.creat("Não foi possível cadastrar uma empresa no momento, tente novamente mais tarde.", { erro: true })
                this.buscarEmpresaMedica()
                this.loadings = false
                this.listar = true
              }
            this.dadosEmpresaDoAssinanteCadastrar = new dadosDefinirEmpresaMedica()
          })
        )
      }
      else {
        this.mensagem.creat("Não é possível cadastrar empresa no momento, o CPF do usuário logado não é o mesmo CPF do Dono/Administrador da assinatura.", { erro: true })
      }
    }
    else {
      this.mensagem.creat("Não foi possível recuperar os dados do CPF.", { sucesso: true })
    }
  }

  async desativarEmpresaMedica() {
    this.loadings = true
    this.listaCheckBox.forEach(reg => {
      reg.status = "SDO0063"
      let data = this.serviceHora.horaAgora(true)
      var cpfProprietario = reg.cpfProprietario
      var cpfProfissional = reg.cpfResponsavel
      var codigoAssinante = reg.codigoAssinante
      this.fichaGeralService.AlterarFichaGeralEmpresa(reg.id, sessao.sessao_id, data.toString(), reg).subscribe(
        (resultado => {
          var variavelID = resultado
          // this.serviceTokenAcesso.criarToken(variavelID[0].key, codigoAssinante, cpfProprietario).toPromise().then(
          //   (resultado => {
          //     if (resultado[0] === "não possui assinatura vinculada") {
          //       this.fichaGeralService.getFichaGeralEmpresaID(this.variavelID[0].key).subscribe(
          //         (resultado => {
          //           this.dadosFichaGeralEmpresaIDToken = resultado
          //           this.dadosFichaGeralEmpresaIDToken.status = "SDO0063"
          //           this.fichaGeralService.AlterarFichaGeralEmpresa(this.dadosFichaGeralEmpresaIDToken.id, sessao.sessao_id, this.serviceHora.horaAgora(true), this.dadosFichaGeralEmpresaIDToken).subscribe(
          //             (resultado => {
          //               this.listaCheckBox = []
          //               this.loadings = false
          //               this.mensagem.creat("Não é possível cadastrar a empresa no sistema pois o úsuario não tem Assinatura vinculada.", { erro: true })
          //               this.buscarEmpresaMedica()
          //             })
          //           )
          //         })
          //       )
          //     } else {
          //       this.serviceTokenAcesso.criarToken(variavelID[0].key, codigoAssinante, cpfProfissional).toPromise().then(
          //         (resultado => {
          //           var dadosToken = resultado
          //           this.listaCheckBox = []
          //           this.loadings = false
          //           this.mensagem.creat("Empresa médica excluída com sucesso.", { sucesso: true })
          //           this.buscarEmpresaMedica()
          //         })
          //       )
          //     }
          //   })).catch(
          //     erro => console.log(erro)
          //   )
        }), error => {
          this.loadings = false
          this.listaCheckBox = []
          this.mensagem.creat("Impossível excluir a Empresa médica no momento, tente novamente mais tarde.", { erro: true })
        }
      )
    })
  }

  addItemExcluir(event: boolean, item) {
    if (event) {
      this.listaCheckBox.push(item)
    } else {
      let index = this.listaCheckBox.map((x, i) => { return { index: i, cod: x.id } }).filter((x) => { return x.cod == item.id })[0].index
      this.listaCheckBox.splice(index, 1)
    }
  }
  addItemExcluir2(event: boolean, item) {
    if (event) {
      this.listaCheckBox2.push(item)
    } else {
      let index = this.listaCheckBox2.map((x, i) => { return { index: i, cod: x.id } }).filter((x) => { return x.cod == item.id })[0].index
      this.listaCheckBox2.splice(index, 1)
    }
  }

  async AlterarDefinirEmpresaMedica() {
    this.loadings = true
    this.dadosEmpresaDoAssinanteCadastrar.classificacaoEmpresa = "CLAEMP2"
    this.dadosEmpresaDoAssinanteCadastrar.classificacaoEmpresa = "CLAEMP2"
    var cpfProprietario = this.dadosEmpresaDoAssinanteCadastrar.cpfProprietario
    var cpfProfissional = this.dadosEmpresaDoAssinanteCadastrar.cpfResponsavel
    var codigoAssinante = this.dadosEmpresaDoAssinanteCadastrar.codigoAssinante
    this.fichaGeralService.AlterarFichaGeralEmpresa(this.dadosEmpresaDoAssinanteCadastrar.id, sessao.sessao_id, this.serviceHora.horaAgora(true), this.dadosEmpresaDoAssinanteCadastrar).subscribe(
      (resultado => {
        this.variavelID = resultado

        if (this.listaServicosMedicosID.length > 0) {
          this.listaServicosMedicosID.forEach((reg, i) => {
            this.listaServicosMedicosID[i].codigoEmpresa = this.variavelID[0].key
            this.listaServicosMedicosID[i].status = "SDO0017"
          });
        }
        if (this.resultadoAreaAtuacaoCodigoEmpresa.length > 0) {
          this.resultadoAreaAtuacaoCodigoEmpresa.forEach((reg, i) => {
            this.resultadoAreaAtuacaoCodigoEmpresa[i].codigoEmpresa = this.variavelID[0].key
            this.resultadoAreaAtuacaoCodigoEmpresa[i].status = "SDO0017"
            this.resultadoAreaAtuacaoCodigoEmpresa[i].cpfAutor = sessao.cpf
          });
        }

        // this.serviceTokenAcesso.criarToken(this.variavelID[0].key, codigoAssinante, cpfProprietario).toPromise().then(
        //   (resultado => {
        //     if (resultado[0] === "não possui assinatura vinculada") {
        //       this.fichaGeralService.getFichaGeralEmpresaID(this.variavelID[0].key).subscribe(
        //         (resultado => {
        //           this.dadosFichaGeralEmpresaIDToken = resultado
        //           this.dadosFichaGeralEmpresaIDToken.status = "SDO0063"
        //           this.fichaGeralService.AlterarFichaGeralEmpresa(this.dadosFichaGeralEmpresaIDToken.id, sessao.sessao_id, this.serviceHora.horaAgora(true), this.dadosFichaGeralEmpresaIDToken).subscribe(
        //             (resultado => {
        //               this.resultadoAreaAtuacaoCodigoEmpresa = []
        //               this.listaServicosMedicosID = []
        //               this.resultadoAreaAtuacao = []
        //               this.loadings = false
        //               this.listar = true
        //               this.mensagem.creat("Não é possível cadastrar a empresa no sistema pois o úsuario não tem Assinatura vinculada.", { erro: true })
        //               this.buscarEmpresaMedica()
        //             })
        //           )
        //         })
        //       )
        //     } else {
        //       this.serviceTokenAcesso.criarToken(this.variavelID[0].key, codigoAssinante, cpfProfissional).toPromise().then(
        //         (resultado => {
        //           var dadosToken = resultado
        //           this.editarEmpresaMedica()
        //           this.editarServicosMedicos()
        //           this.resultadoAreaAtuacaoCodigoEmpresa = []
        //           this.listaServicosMedicosID = []
        //           this.resultadoAreaAtuacao = []
        //           this.loadings = false
        //           this.buscarEmpresaMedica()
        //           this.listar = true
        //           this.mensagem.creat('Empresa médica atualizada com sucesso!', { sucesso: true })
        //         })
        //       )
        //     }
        //   })).catch(
        //     erro => console.log(erro)
        //   )
      }), erro => {
        this.mensagem.creat("Não foi possível atualizar o Item no momento, tente novamente mais tarde!", { aviso: true })
      })
  }

  async editarEmpresaMedica() {
    this.resultadoAreaAtuacaoCodigoEmpresa.forEach(reg => {
      if (!reg.itemAreaAtuacao) {
        this.serviceAreaAtuacao.criarAreaAtuacao(reg).then(() => { })
        return
      }
      this.serviceAreaAtuacao.AlterarAreaAtuacao(reg.itemAreaAtuacao, reg).subscribe(
        () => {
        }
      )
    });
  }

  async editarServicosMedicos() {
    this.listaServicosMedicosID.forEach(reg => {
      if (!reg.codigoServico) {
        this.serviceServicosMedicosDisponiveis.criarServicosMedico(reg).then(() => { })
        return
      }
      this.serviceServicosMedicosDisponiveis.AlterarServicosMedico(reg.codigoServico, reg).subscribe(
        () => {
        }
      )
    });
  }

  async deletarEmpresaMedica(id: any) {
    this.serviceAreaAtuacao.removerAreaAtuacao(id).then(
      () => {
        this.buscarEmpresaMedicaCodigoEmpresaID(this.id)
      }
    )
  }
  
  async deletarServicosMedicos(id: any) {
    this.serviceServicosMedicosDisponiveis.removerServicoMedico(id).then(
      () => {
        this.buscarServicosMedicosCodigoEmpresa(this.id)
      }
    )
  }

  async adicionarAreaAtuacaoID(areaDeAtuacaoAns?: string, codigoEmpresa?: number, cpfAutor?: string, status?: string) {
    this.resultadoAreaAtuacaoCodigoEmpresa.push(
      {
        codigoEmpresa: codigoEmpresa,
        areaDeAtuacaoAns: areaDeAtuacaoAns,
        cpfAutor: cpfAutor,
        status: status
      }
    )
  }

  async adicionarAreaAtuacao(areaDeAtuacaoAns?: string, codigoEmpresa?: number, cpfAutor?: string, status?: string) {
    this.listaAreaAtuacaoAns.push(
      {
        codigoEmpresa: codigoEmpresa,
        areaDeAtuacaoAns: areaDeAtuacaoAns,
        cpfAutor: cpfAutor,
        status: status
      }
    )
  }

  exibirValorConteudo(codReferencia: string, listaConteudo: conteudoDeDado[]): string {
    if (!!listaConteudo.find(conteudo => conteudo.codigoReferencia === codReferencia)) {
      return listaConteudo.find(conteudo => conteudo.codigoReferencia === codReferencia).valor
    } else {
      return '?'
    }
  }

  async selecionarArquivo(fileList: File[]) {
    let file = new FormData()
    let porcentagem
    file.append('arquivo', fileList[0]);
  };

  async adicionarServicoID(nomeServico?: string, codigoEmpresa?: number, status?: string) {
    this.listaServicosMedicosID.push(
      {
        codigoEmpresa: codigoEmpresa,
        nomeServico: nomeServico,
        status: status
      }
    )
  }

  async adicionarServico(nomeServico?: string, codigoEmpresa?: number, status?: string) {
    this.listaServicosMedicos.push(
      {
        codigoEmpresa: codigoEmpresa,
        nomeServico: nomeServico,
        status: status
      }
    )
  }

  async removerAreaAtuacaoID(result) {
    const index: number = this.resultadoAreaAtuacaoCodigoEmpresa.indexOf(result);
    if (result !== 1) {
      this.resultadoAreaAtuacaoCodigoEmpresa.splice(index, 1)
    }
  }

  async removerServicoID(result) {
    const index: number = this.listaServicosMedicosID.indexOf(result);
    if (result !== 1) {
      this.listaServicosMedicosID.splice(index, 1)
    }
  }

  async removerAreaAtuacao(result) {
    const index: number = this.listaAreaAtuacaoAns.indexOf(result);
    if (result !== 1) {
      this.listaAreaAtuacaoAns.splice(index, 1)
      this.listaCheckBox2 = []
    }
  }

  
  // desativarControleSenhaAtendimento(id: any) {
  //   this.listaCheckBox.forEach(reg => {
  //     reg.status = "Inativo"
  //     this.serviceAreaAtuacao.removerAreaAtuacao(reg.id, reg).subscribe(
  //       (result => {
  //         this.mensagem.creat('Registro deletado com sucesso', { sucesso: true })
  //         this.buscarEmpresaMedicaCodigoEmpresaID()
  //         this.listaCheckBox = []
  //       }), error => {
  //         this.mensagem.creat('Impossivel excluir o controle de senha atendimento no momento, tente novamente mais tarde.', { erro: true })
  //       }
  //     )
  //   })
  // }


  async removerServico(result) {
    const index: number = this.listaServicosMedicos.indexOf(result);
    if (result !== 1) {
      this.listaServicosMedicos.splice(index, 1)
      this.listaCheckBox = []
    }
  }

  async casdastrarAreaAtuacao() {
    this.listaAreaAtuacaoAns.forEach(reg => {
      this.serviceAreaAtuacao.criarAreaAtuacao(reg).then(
        () => {
        }
      );
    });
  }

  async casdastrarServico() {
    this.listaServicosMedicos.forEach(reg => {
      this.serviceServicosMedicosDisponiveis.criarServicosMedico(reg).then(
        () => {
        }
      );
    });
  }

  async salvarArquivo(arquivo?: File, dataDeValidade?: string, dataDeRenovacao?: string, codigoEmpresa?: number) {
    this.listaLegislacaoVigente.push(
      {
        codigoEmpresa: codigoEmpresa,
        arquivo: arquivo,
        dataDeValidade: dataDeValidade,
        dataDeRenovacao: dataDeRenovacao
      }
    )
  }

  async addItem(event: Boolean, item: dadosDefinirEmpresaMedica) {
    if (event) {
      this.listaCheckBox.push(item)
    } else {
      let index = this.listaCheckBox.map((x, i) => { return { index: i, cod: x.codigoEmpresa } }).filter((x) => { return x.cod == item.codigoEmpresa })[0].index
      this.listaCheckBox.splice(index, 1)
    }
  }
  async addItem2(event: Boolean, item: dadosDefinirEmpresaMedica) {
    if (event) {
      this.listaCheckBox2.push(item)
    } else {
      let index = this.listaCheckBox2.map((x, i) => { return { index: i, cod: x.codigoEmpresa } }).filter((x) => { return x.cod == item.codigoEmpresa })[0].index
      this.listaCheckBox2.splice(index, 1)
    }
  }

}