import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Documento } from './documentos.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DiarioDeAcessoService } from 'src/app/service/diario-de-acesso/diario-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { getLocaleDateTimeFormat } from '@angular/common';
import { BuscarDataService } from '../buscar-data.service';

const urlDocumentos = `${new environment().api}Documentos/`;
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})

export class DocumentosService {
  constructor(
    private http:HttpClient,
    private mensagem: ToastrService,
    private diarioDeAcesso: DiarioDeAcessoService,
    ) {}

  async listarDocumentos():Promise<Documento[]>{
    let documentos: Documento[];
    await this.http.get<Documento[]>(`${urlDocumentos}`, {observe:'body'}).toPromise()
    .then(res =>{
      if(res.length <= 0) throw 'Não existem documentos cadastrados'
      res.sort((a,b) => {
        return a.codigoDocumento < b.codigoDocumento ? -1 : a.codigoDocumento > b.codigoDocumento ? 1 : 0;
      })
      documentos = res;
    })
    .catch((error:HttpErrorResponse) =>{
      this.mensagem.error(`Erro ao listar documentos: ${error.message}`,`Erro: ${error.status || 'Listar Documentos'}`)
    })
    return documentos
  }

  async buscarDocumento({id}:{id:String}):Promise<Documento>{
    let documento: Documento;
    await this.http.get<Documento>(`${urlDocumentos}/${id}`, {observe:'body'}).toPromise()
    .then(res =>{
      if(!res) throw 'Nenhum documento encontrado'
      documento = res;
    })
    .catch((error:HttpErrorResponse) =>{
      this.mensagem.error(`Erro ao buscar documento: ${error.message}`,`Erro: ${error.status || 'Buscar Documento'}`)
    })
    return documento
  }

  async criarDocumento(novoDocumento:Omit<Documento, 'id'>){
    await this.http.post<Documento>(`${urlDocumentos}`, novoDocumento,{observe:'body'}).toPromise()
    .then(() =>
      this.mensagem.success(`Documento ${novoDocumento.descricao} criado com sucesso`)
    )
    .catch((error:HttpErrorResponse) =>{
      this.mensagem.error(`Erro ao criar documento: ${error.message}`,`Erro: ${error.status || 'Criar Documento'}`)
    })
  }

 // async alterarDocumento(novoDocumento:Documento){
 //   const sessionId = sessao.sessao_id
 //   console.log(`${urlDocumentos}/${novoDocumento.id}/${sessionId}`, novoDocumento)
 //   await this.http.put<Documento>(`${urlDocumentos}/${novoDocumento.id}/${sessionId}`, novoDocumento,{observe:'body'}).toPromise()
 //   .then(() =>
 //     this.mensagem.success(`Documento ${novoDocumento.descricao} alterado com sucesso`)
 //   )
 //   .catch((error:HttpErrorResponse) =>{
 //     console.log('erro',error)
 //     this.mensagem.error(`Erro ao alterar documento: ${error.error}`,`Erro: ${error.status || 'Alterar Documento'}`)
 //   })
 // }

 async alterarDocumento(novoDocumento:Documento){
  const sessionId = sessao.sessao_id
  var a = new BuscarDataService;
  console.log("https://localhost:5001/api/Documentos/" + novoDocumento.id + "/" + sessionId + "/" + new Date().toDateString() )
  console.log("AAA: " + `${urlDocumentos}/${novoDocumento.id}/${sessionId}/${new Date().toString()}`)
 // await this.http.put<Documento>(`${urlDocumentos}/${novoDocumento.id}/${sessionId}/${new Date().toString()}` , novoDocumento,{observe:'body'}).toPromise()
 await this.http.put<Documento>(urlDocumentos + novoDocumento.id + "/" + sessionId + "/" + new Date().toDateString() , novoDocumento,{observe:'body'}).toPromise() 
 .then(() =>
    this.mensagem.success(`Documento ${novoDocumento.descricao} alterado com sucesso`)
  )
  .catch((error) =>{
    console.log('erro',error)
    this.mensagem.error(`Erro ao alterar documento: ${error.error}`,`Erro: ${error.status || 'Alterar Documento'}`)
  })
}

  async removerDocumento({id}:{id:String}){
    //const sessionId = await this.diarioDeAcesso.idDestaSessao();
    const sessionId = sessao.sessao_id
    await this.http.delete<Documento>(urlDocumentos + id + "/" + sessionId + "/" + new Date().toDateString()).toPromise()
    .then(() =>
      this.mensagem.success(`Documento removido com sucesso`)
    )
    .catch((error:HttpErrorResponse) =>{
      this.mensagem.error(`Erro ao remover documento: ${error.message}`,`Erro: ${error.status || 'Remover Documento'}`)
    })
  }
}
