import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DadosFichaGeralEmpresa } from '../regras-de-imposto/model';
import { DadosControleSenhaAtendimento, DadosControleSenhaAtendimentoSelect } from './modelsControleAtendimento';
import { ControleSenhaAtendimentoService } from 'src/app/service/controle-senha-atendimento.service';
import { getLocaleDateTimeFormat } from '@angular/common';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service';
import { SessaoUser } from 'src/app/user.reg';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MatSort, MatTableDataSource } from '@angular/material';


interface FrequenciaInicializacao {
  value: number;
  valueText: string;
}

const sessao = new SessaoUser

@Component({
  selector: 'app-controle-senha-atendimento',
  templateUrl: './controle-senha-atendimento.component.html',
  styleUrls: ['./controle-senha-atendimento.component.scss']
})

export class ControleSenhaAtendimentoComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  listEmpresa = []
  formulario: FormGroup
  displayedColumns: string[] = ['detalhe', 'codigoEmpresa', 'frequenciaReinicializacaoDias', 'ultimaSenhaGerada']

  dataDadosEmpresa: DadosFichaGeralEmpresa[]
  listar: boolean
  dataSource: MatTableDataSource<any>
  IdControleDeSenhaAtendimento: string
  listaCheckBox: DadosControleSenhaAtendimento[] = []
  selectEmpresa = 0
  resultadoAssinante: FichaAssinante;
  loading: boolean;
  viewCadastro: boolean;
  alteracao: boolean;
  viewConfirmReset: boolean;
  selectReg = new DadosControleSenhaAtendimento;
  lodingReset: boolean;
  constructor(
    private token: TokenDeAcessoService,
    private form: FormBuilder,
    private modalService: BsModalService,
    private service: ControleSenhaAtendimentoService,
    private serviceHora: BuscarDataService,
    private serviceEmpresa: FichaGeralService,
    private mensagem: MensagemService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private _empresa: FichaGeralService,
    private _filter: FiltrarDadosService

  ) { }
  ngOnInit() {
    this.creatForm()
    this.getEmpresa()
    this.getRegistro(0)
    this.listar = true
  }

  DadosFrequenciaReinicializacao: FrequenciaInicializacao[] = [
    { value: 1, valueText: 'Diariamente' },
    { value: 7, valueText: 'Semanalmente' },
    { value: 15, valueText: 'Quinzenalmente' },
    { value: 30, valueText: 'Mensalmente' },
    { value: 60, valueText: 'Bimestralmente' },
    { value: 90, valueText: 'Trimestralmente' },
    { value: 182, valueText: 'Semestralmente' },
    { value: 365, valueText: 'Anualmente' },
  ];



  async getRegistro(codigoEmpresa?) {
    this.loading = true
    let registros = await this.service.get()

    if (codigoEmpresa != 0) {
      registros = registros.filter(value => value.codigoEmpresa == codigoEmpresa)
    }
    this.dataSource = new MatTableDataSource(registros)
    this.dataSource.sort = this.sort
    console.log('listEmpresas', registros)
    this.loading = false

  }

  async buscarDadosEmpresa() {
    var cpf = sessao.cpf
    this.resultadoAssinante = await this.serviceFichaAssinante.buscarAssinante({ cpf })
    await this.serviceEmpresa.buscarEmpresasCodigoAssinante(this.resultadoAssinante.codigoAssinante).subscribe(
      (resultado: Array<any>) => {
        let filtro = resultado.filter(function (reg: DadosFichaGeralEmpresa) { return reg.status === "SDO0017" })
        this.dataDadosEmpresa = filtro
      }), (erro => {
        console.log("Erro: " + erro)
      })
  }



  cancelarRegistro() {
    let id = this.formulario.value.id
    this.service.getControleSenhaAtendimentoID(id).subscribe(
      (registro => {
        this.formulario.patchValue(registro)
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }


  excluirRegistro() {
    let registro = this.formulario.value
    registro.status = "SCA0002"
    this.service.AlterarControleSenhaAtendimento(registro.id, registro).subscribe(
      (result => {
        this.mensagem.creat('Registro deletado com sucesso', { sucesso: true })
        this.getRegistro(this.selectEmpresa)
        this.viewCadastro = false
      }), error => {
        this.mensagem.creat('Impossivel excluir o controle de senha atendimento no momento, tente novamente mais tarde.', { erro: true })
      }
    )
  }



  async openCadastro(item) {
    this.formulario.enable()
    this.formulario.patchValue(item)
    this.formulario.disable()

    console.log('openCadastro', this.formulario.value)
    this.listEmpresa = this._filter.removeDublicEntreArray('codigoEmpresa', this.listEmpresa, this.dataSource.data)
    this.listEmpresa = (await this._empresa.getEmpresaAssiante(sessao.assinante)).filter(value => value.codigoEmpresa == item.codigoEmpresa)
    this.alteracao = true
    this.viewCadastro = true
  }
  async inserir() {
    this.loading = true
    let registro = this.formulario.value
    registro.status = "SCA0001"
    registro.dataUltimaReinicializacao = this.serviceHora.hoje()

    registro.ultimaSenhaGerada = 0
    delete registro.id
    await this.service.PostControleSenhaAtendimento(registro)
      .toPromise().then(
        sucesso => {
          this.mensagem.creat('Controle senha atendimento cadastrado com sucesso!', { sucesso: true })
          this.getRegistro(this.selectEmpresa)
          this.viewCadastro = false
        })
      .catch(
        erro => {
          console.log("Deu erro" + erro)
        }
      )
    this.loading = false
  }
  async alterar() {
    this.loading = true
    let registro = this.formulario.value
    await this.service.AlterarControleSenhaAtendimento(registro.id, registro).toPromise().then(
      sucesso => {
        this.mensagem.creat('Controle senha atendimento editado com sucesso!', { sucesso: true })
        this.formulario.disable()

      }
    ).catch(error => {
      console.log('erro=>', error)
      this.mensagem.creat('Não foi possivel alterar registro', { erro: true })
    })
    this.getRegistro(this.selectEmpresa)
    this.loading = false
  }
  async getEmpresa() {
    let empresa = await this._empresa.getEmpresaAssiante(sessao.assinante)
    this.listEmpresa = empresa
  }
  async resetSenha(confirm, registro) {
    if (confirm) {
      this.viewConfirmReset = true
      this.selectReg = registro
      return
    } else {
      // this.viewConfirmReset = false
      this.lodingReset = true
      let result = await this.service.resetSenha(registro.codigoEmpresa)
      if (result) {
        this.getRegistro(this.selectEmpresa)
      } else {
        this.mensagem.creat('Não foi possível reinicar senha', { erro: true })
      }
      this.viewConfirmReset = false
      this.lodingReset = false
    }
  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: 0,
      criadoPor: '',
      status: '',
      frequenciaReinicializacaoDias: new FormControl(0, Validators.required),
      ultimaSenhaGerada: ''
    })
  }
  _control(name) {
    return this.formulario.get(name).hasError('required')
  }
  filtrar(value: string) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

}