import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DadosSaudePacienteService } from 'src/app/service/dados-saude-paciente/dados-saude-paciente.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { FraturasPaciente } from '../../model';
const sessao = new SessaoUser
@Component({
  selector: 'app-prontuario-fratura',
  templateUrl: './prontuario-fratura.component.html',
  styleUrls: ['./prontuario-fratura.component.scss']
})
export class ProntuarioFraturaComponent implements OnInit {
  @Input('cpf') cpf: string
  @Output('close') exit = new EventEmitter
  @Input('register') register = new FraturasPaciente
  formulario: FormGroup
  loading : boolean = false
  constructor(
    private formBuilder: FormBuilder,
    private _mensagem: MensagemService,
    private service : DadosSaudePacienteService) { }


  async ngOnInit() {
    await this.creatForm()
    if (this.register.id) {
      this.formulario.patchValue(this.register)
      this.formulario.disable()
    }
  }

  creatForm() {
    this.formulario = this.formBuilder.group({
      id: new FormControl(''),
      fraturas: new FormControl(''),
      cpfPaciente: new FormControl(''),
      cpfMedico: new FormControl(''),
      data: new FormControl(''),
      observacao: new FormControl(''),
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl('')
    })
  }

  excluirRegistro() {
    let register = this.formulario.getRawValue() as FraturasPaciente
    if (!register.id) {
      this._mensagem.creat('Não é possível excluir este registro', { erro: true })
      return
    }
    register.status = 'SCA0002'
    this.service.putFraturas(register).toPromise()
      .then(() => {
        this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
        this.close()
      })
      .catch(error => {
        console.log('erro!', error)
        this._mensagem.creat('Não foi possível alterar registro', { erro: true })
      })
  }
  cancelarRegistro() {
    this.close()
  }
  fecharCadastro() { }

  salvar() {
    let register = this.formulario.getRawValue() as FraturasPaciente

    if (!register.id) {
      delete register.id
      register.criadoPor = sessao.cpf
      register.cpfPaciente = this.cpf
      register.status = 'SCA0001'
      console.log(register)
      this.service.postFraturas(register).toPromise()
        .then(() => this.close())
        .catch(error => {
          this._mensagem.creat('Não foi possível gravar registro', { erro: true })
          console.log('erro!', error)
        })

    } else {
      console.log(register)
      this.service.putFraturas(register).toPromise()
        .then(() => {
          this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
          this.close()
        })
        .catch(error => {
          console.log('erro!', error)
          this._mensagem.creat('Não foi possível alterar registro', { erro: true })
        })
    }

  }
  _control(control: string) {
    return this.formulario.get(control)
  }
  close() {
    this.exit.emit()
  }


}
