import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RecuperarSenhaService {
  url: string = new environment().api
  api_senha = 'v1/resetarsenha/'
  api_email = 'v1/validaremail/'
  constructor(private http: HttpClient) { }

  resetSenha(usuario, senha) {
    let reg = {
      usuario: usuario,
      newPassword: senha
    }
    console.log(this.url + this.api_senha, reg)
    return this.http.post(this.url + this.api_senha, reg)
  }
  validEmail(email) {
    console.log(this.url + this.api_senha, email)
    return this.http.post(this.url + this.api_email, email)
  }
}
