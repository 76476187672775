import { Component, OnInit } from '@angular/core';
import { usuario } from 'src/app/service/cadastro-usuario/usuario.model';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FichaGeralEmpresa } from 'src/app/service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { CadastroUsuarioService } from 'src/app/service/cadastro-usuario/cadastro-usuario.service';

@Component({
  selector: 'app-cadastro-assinatura',
  templateUrl: './cadastro-assinatura.component.html',
  styleUrls: ['./cadastro-assinatura.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class CadastroAssinaturaComponent implements OnInit {

  cadastro: FormGroup;
  usuario: usuario;
  assinante: FichaAssinante;
  empresa: FichaGeralEmpresa;
  constructor(
    private serviceCadastroUsuario: CadastroUsuarioService,
    private construtorControle: FormBuilder,
  ) { }

  ngOnInit() {
    this.inicializarControle();
  }

  inicializarControle() {
    this.cadastro = this.construtorControle.group({
      cadastroAssinatura: [false, Validators.requiredTrue],
      cadastroPagamento: [false, Validators.requiredTrue],
    })
  }

  get cadastroAssinatura() {
    return this.cadastro.controls['cadastroAssinatura']
  }

  get cadastroPagamento() {
    return this.cadastro.controls['cadastroPagamento']
  }

  cadastrar() {
    this.serviceCadastroUsuario.criarUsuario(this.usuario)
  }

  novoCadastro({ novoUsuario, cadastroValido }) {
    this.usuario = novoUsuario;
    this.usuario.status = 'SDO0090';
    this.cadastro.controls['cadastroAssinatura'].setValue(cadastroValido);
  }

  confirmarPagamento({ pagamentoRealizado }) {
    this.cadastro.setValue(pagamentoRealizado);
  }
}
