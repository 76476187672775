export class dadosCadastroCentroCusto {
    id: string
    codigo: number
    criadoPor: string
    ativoApartirDe: string
    atribuicaoResposanvelDesde: string
    observacao: string
    tipo: string
    descricao: string
    atribuicaoResponsavelAte: string
    inativoApartirDe: any
    responsavel: string
    status: string
    codigoEmpresa: number
    criadoEm: string
    codigoAssinante: string
    ////
    cpf: string
    timestamp: string
    nomeEmpresa: string
}
