import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DicionarioPortal } from '../portal/dicionario/modal';
import { SessaoUser } from '../user.reg';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class PortalDicionarioService {

  url: string = new environment().api + "DicionarioPortal/"
  url_local: string = new environment().api_local
  constructor(private http: HttpClient) {

  }
  ///////////////////////////// Listar  //////////////////////
  async get() {
    let result: DicionarioPortal[] = []
    await this.http.get<DicionarioPortal[]>(this.url).toPromise()
      .then(res => { result = res })
    return result
  }
  async reset(id) {
    let result = new DicionarioPortal
    await this.http.get<DicionarioPortal>(this.url + id).toPromise()
      .then(res => { result = res })
    return result
  }
  listar(id?) {
    console.log(' :' + this.url)
    if (id) {
      console.log('ID true')
      return this.http.get(this.url + id)
    }
    else {
      console.log('Listar  sem id')
      return this.http.get(this.url)
    }

  }
  cadastrar(dados: DicionarioPortal) {
    dados.criadoPor = sessao.cpf
    dados.status = 'SDA0001'
    console.log('-Cadastrar :' + this.url, dados)
    return this.http.post<DicionarioPortal>(this.url, dados)
  }
  alterar(id: string, dados) {
    console.log('-Alterar :' + this.url + id, dados)
    return this.http.put<DicionarioPortal>(this.url + id, dados)
  }
  excluir(id: string) {
    console.log('-Excluir :' + this.url + id)
    return this.http.delete(this.url + id)
  }
}
