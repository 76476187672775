import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termais-prata',
  templateUrl: './termais-prata.component.html',
  styleUrls: ['./termais-prata.component.scss']
})
export class TermaisPrataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
