import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RegistroService } from 'src/app/service/registro.service';
import { FormRegistrarUsuario } from './modal';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HubDevService } from 'src/app/service/hub-dev.service';
import { ValidateBrService } from 'angular-validate-br';
import { environment } from 'src/environments/environment';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { RecaptchaService } from 'src/app/service/recaptcha.service';
const url = new environment().api
@Component({
  selector: 'app-inscricao-paciente',
  templateUrl: './inscricao-paciente.component.html',
  styleUrls: ['./inscricao-paciente.component.scss']
})
export class InscricaoPacienteComponent implements OnInit {
  @Input('') onlyCad: boolean
  formulario: FormGroup
  loading: boolean;
  email: boolean;
  viewPassword = false
  viewPasswordRepit = false
  idadeInvalid: boolean;
  mensagem: string;
  viewPassord
  viewPassord2
  constructor(
    private form: FormBuilder,
    private service: RegistroService,
    private toast: ToastrService,
    private http: HttpClient,
    private router: Router,
    private hub: HubDevService,
    private _date: BuscarDataService,
    private _usuario: UsuarioService,
    private validateBrService: ValidateBrService,
    private _recaptcha: RecaptchaService
  ) { }

  ngOnInit() {
    this.createForm()
  }
  createForm() {
    this.formulario = this.form.group({
      usuario: new FormControl('', [Validators.required, Validators.minLength(5)]),
      cpf: new FormControl('', [Validators.required, this.validateBrService.cpf]),
      nomeSocial: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      nascimento: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$#!%*?&])[A-Za-z\d$@$!%#*?&].{7,}')]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      status: new FormControl(''),
      captcha: new FormControl('', Validators.required)
    })
  }
  async validarCPF() {
    this.loading = true
    const cpf = this.formulario.controls['cpf'].value
    let nascimento: string = this.formulario.controls['nascimento'].value
    nascimento = `${nascimento.slice(0, 2)}/${nascimento.slice(2, 4)}/${nascimento.slice(4, 8)}`
    const nome = this.formulario.controls['usuario'].value
    let registro = this.formulario.value
    let n = registro.nascimento
    let convertNasc =`${n.slice(4,8)}${n.slice(3,5)}${n.slice(0,2)}`
    let response = await this.hub.vCPF(cpf, nascimento, nome, true)
    console.log(response)
    this.mensagem = response.message

    if (response.status) {
      let result = await this.service.registrar(this.formulario.value)
      if (result.status) {
        this.email = true
        this.enviarEmail();
      } else {
        this.mensagem = result.message ? result.message : 'Não foi possível realizar cadastro.'
        // this.formulario.reset()
      }
      console.log('**********************-Status: ', result)

    }
    this.loading = false
  }
  async validRecaptcha() {
    let result = await this._recaptcha.validToken()
    console.log('valid', result)
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  validNascimento(value) {
    if (!value) return
    let date = this._date.idade(new Date(value).toISOString())
    if (date < 18) {
      this.idadeInvalid = true
    }
    if (date > 18) {
      this.idadeInvalid = false
    }

  }

  async onSubmit(dados: FormRegistrarUsuario) {
    this.mensagem = null

    // let validacaoTel = this.formulario.controls['telefoneCel'].value
    // this.formulario.controls['telefoneCel'].setValue(validacaoTel.replace(/[^a-z0-9]/gi, ''))

    // if (this.formulario.valid) {

    console.log(this.formulario.controls['password'].value != this.formulario.controls['confirmPassword'].value)
    console.log(this.formulario.controls['password'].value, this.formulario.controls['confirmPassword'].value)
    console.log(this.formulario.invalid && this.formulario.controls['password'].value != this.formulario.controls['confirmPassword'].value)

    if (this.formulario.get('cpf').invalid) {
      this.mensagem = 'CPF invalido'
      return
    }
    if (this.formulario.get('captcha').invalid) {
      this.mensagem = 'Confirme que você não é um robô'
      return
    }
    if (this.formulario.controls['password'].value != this.formulario.controls['confirmPassword'].value) {
      this.mensagem = 'Senha divergente'
      return
    }
    if (this.idadeInvalid) {
      this.mensagem = 'Não é possivel cadastrar menores de 18 anos.'
      return
    }
    if (this.formulario.invalid) {
      this.mensagem = 'Por favor , preencha todos os campos obrigatórios corretamente'
      return
    }


    this.loading = true
    let verificCPF = await this._usuario.getRegistro(dados.cpf)
    if (verificCPF.id) {
      this.mensagem = 'Usuário já cadastrado'
      this.loading = false
      return
    }


    this.validarCPF()

  }

  async enviarEmail() {
    const email = this.formulario.controls['email'].value
    const nome = this.formulario.controls['usuario'].value
    const cpf = this.formulario.controls['cpf'].value
    await this.http.get(`${url}SendEmail/${email}/${nome}/${cpf}`).toPromise()
      .then(
        () => {
        }
      )
  }

}
