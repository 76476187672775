import { Component, OnInit, Input } from '@angular/core';
import { FormPaciente } from 'src/app/cadastro-paciente/model';
import { AtendimentoClinicoService } from 'src/app/service/atendimento-clinico.service';
import { FormCobranca, FormValorVenda } from './model';
import { filter } from 'rxjs/operators';
import { ValorVendaService } from 'src/app/service/valor-venda.service';
import { ToastrService } from 'ngx-toastr';
import { SalaEsperaForm } from '../../sala-espera/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { MonitorClinicoService } from 'src/app/service/monitor-clinico.service';

@Component({
  selector: 'app-solicitar-cobranca',
  templateUrl: './solicitar-cobranca.component.html',
  styleUrls: ['./solicitar-cobranca.component.scss']
})
export class SolicitarCobrancaComponent implements OnInit {
  @Input() paciente: SalaEsperaForm

  registro = new FormCobranca
  total
  servicos : FormValorVenda[]
  constructor(
    private service: AtendimentoClinicoService,
    private serviceValor : ValorVendaService,
    private data : BuscarDataService,
    private mensagem : ToastrService,
    private monitor : MonitorClinicoService) {
  }

  ngOnInit() {
    
    this.buscarValores()
    console.log('SEU PACIENTE : ' + JSON.stringify(this.paciente))
  }
  buscarValores(){
    this.serviceValor.listarValorVenda().toPromise()
    .then(
      (dados :  Array<FormValorVenda>)=>{
          let companyId =  this.paciente.companyId
          let filtro = dados.filter(function(ref){
            console.log(`${ref.companyId}  -- ${companyId}`)
            return ref.companyId == companyId && ref.ativo
          })
          this.servicos = filtro
      }
    )
  }
  cadastrar() {
    let idRef = this.registro.id
    let descricao = this.servicos.filter(function (reg) { return reg.id == idRef })
    let valor = descricao[0].valor
    let total = parseInt(valor) * parseInt(this.registro.quantidade)

    this.registro.companyId = this.paciente.companyId
    this.registro.usuarioId = this.paciente.usuarioId
    this.registro.descricao = descricao[0].descricao
    this.registro.valorUni = descricao[0].valor
    this.registro.cpf = this.paciente.cpf
    this.registro.pacienteId = this.paciente.id
    this.registro.paciente = this.paciente.paciente
    this.registro.total = JSON.stringify(total) 
    this.registro.dataSolicitacao = this.data.hoje()
    this.registro.ativo = true
    console.log(this.registro)
    this.service.cadastrarCobrancaAdicional(this.registro).toPromise()
    .then(
      ()=> {
        this.mensagem.success(`Total de R$ ${this.registro.total} reais acrescentado` )
        // this.monitor.gravarRegistro(1,'Consultas','Encerrar',true).toPromise().catch(()=>alert('algo deu errado'))
      }
    )
  

  }


}
