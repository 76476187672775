import { Component, OnInit } from '@angular/core';
import { CadastroItem, SistemaClassificacaoDados } from './campos-cadastroItem';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SistemaClassificacaoDadosService } from '../service/sistema-classificacao-dados.service';
import { CamposFichaGeralEmpresas } from '../ajustes/posicao-numeracao-nfe/campos-posicaoNfe';
import { DeterminarListaPrecoServiceService } from '../service/determinar-lista-preco-service.service';
import { CamposListaPrecos } from '../ajustes/lista-precos-venda/Determinacao-Listaprecos';
import { BuscarDataService } from '../service/buscar-data.service';
import { AreaAtuacao } from '../service/area-atuacao/AreaAtuacao.model';
import { MensagemService } from '../service/mensagem.service';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { SessaoUser } from '../user.reg';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { DadosFichaGeralEmpresa } from '../configuracao-inicial/regras-de-imposto/model';
import { FichaDeAssinanteService } from '../service/ficha-de-assinante/ficha-de-assinante.service';
import { CadastroItemService } from '../service/cadastro-item-service.service';
import { UsuarioService } from '../service/usuario.service';
import { PlanoContaContabeisService } from '../service/plano-conta-contabeis/plano-conta-contabeis.service';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { ConteudoDados, ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { LivroRazaoService } from '../service/livro-razao.service';
import { LivroRazaoModels } from '../livro-razao/modelsLivroRazao';
import { FichaGeralFornecedorService } from '../service/ficha-geral-fornecedor.service';
import { FichaGeralFornecedor } from '../cadastro-fornecedor/CadastroFornecedorModels';
import { Router } from '@angular/router';

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-item',
  templateUrl: './cadastro-item.component.html',
  styleUrls: ['./cadastro-item.component.scss']
})
export class CadastroItemComponent implements OnInit {


  listEmpresa = []
  viewCadastro: boolean
  formulario: FormGroup
  cpf: string
  menu1 = 1
  menu2 = 1
  selectEmpresa = 0
  tipoEmpresa: string
  menuTabs = false
  arquivo: any
  listar: Boolean
  listCheckCadastroItem = []
  displayedColumns = ['detalhe', 'nomeEmpresa', 'codigoItemSku', 'descricao', 'categoria']
  id: any
  botaoSalvar = false
  botaoCancelar = false
  dataCadastroItem: CadastroItem = new CadastroItem
  dataFichaEstoque: CadastroItem = new CadastroItem
  dataCadastroItemID: CadastroItem
  dataSource = new MatTableDataSource()
  GetSelectClassificacaoTPItem: SistemaClassificacaoDados
  GetSelectClassificacaoCGItem: ConteudoDados[] = []
  GetSelectClassificacaoClItem: SistemaClassificacaoDados
  GetSelectClassificacaoMEAVCUItem: SistemaClassificacaoDados
  GetSelectClassificacaoMEPLUSItem: SistemaClassificacaoDados
  GetSelectClassificacaoBLOUTIItem: SistemaClassificacaoDados
  GetSelectClassificacaoADMITEItem: SistemaClassificacaoDados
  GetSelectClassificacaoPRFOPAItem: SistemaClassificacaoDados
  GetSelectClassificacaoUNIMEDItem: SistemaClassificacaoDados
  GetDadosEmpresa = []

  ListaPrecoCodigoEmpresa: CamposListaPrecos[]

  registroAreaAtuacao: Array<AreaAtuacao>
  resultadoAssinante: any;
  nome: string
  loadings: boolean
  viewConfirmDelete: boolean
  loadingOpenCad: boolean;

  ///

  alteracao: boolean = false;
  loading: boolean;
  listContas: PlanoContaContabeisModel[] = [];
  listContas_backup: PlanoContaContabeisModel[] = [];
  listTipEmbalagem: ConteudoDados[] = [];
  listLivroRazao: LivroRazaoModels[] = []
  loadinGet: boolean;
  listLivroRazaoResultFilter: { compras: LivroRazaoModels[], estoque: LivroRazaoModels[], vendas: LivroRazaoModels[], dre: LivroRazaoModels[] } =
    { compras: [], estoque: [], vendas: [], dre: [] }

  infoConta =
    {
      compras: new LivroRazaoModels,
      estoque: new LivroRazaoModels,
      vendas: new LivroRazaoModels,
      dre: new LivroRazaoModels
    }
  listFornecedor: FichaGeralFornecedor[] = [];
  listFornecedorBackup: FichaGeralFornecedor[] = [];
  cadastroGeral: boolean;


  constructor(
    private token: TokenDeAcessoService,
    private form: FormBuilder,
    private mensagem: MensagemService,
    private serviceSistemaClassificacaoDados: SistemaClassificacaoDadosService,
    private serviceListaPreco: DeterminarListaPrecoServiceService,
    public serviceDataHora: BuscarDataService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private fichaGeralService: FichaGeralService,
    private usuario: UsuarioService,
    private service: CadastroItemService,
    private _contas: PlanoContaContabeisService,
    private _conteudo: ConteudoDeDadosService,
    private _livroRazao: LivroRazaoService,
    private _fornecedor: FichaGeralFornecedorService,
    private router: Router
  ) { }

  async ngOnInit() {
    let getRouter = this.router.url.split('/')
    let verificRouter = getRouter.indexOf('CadastroDeItem')
    if (verificRouter >= 0) {
      this.cadastroGeral = true
    } else {
      this.cadastroGeral = false
    }
    //this.getListaDePreco()
    await this.creatForm()
    await this.getCaracteristicasCgItem()
    await this.getCaracteristicasClItem()
    await this.getCaracteristicasTPItem()
    await this.getCaracteristicasUNIMEDItem()
    await this.getCaracteristicasMEPLUSItem()
    await this.getCaracteristicasBLOUTIItem()
    await this.getCaracteristicasADMITEIItem()
    await this.getCaracteristicasPRFOPAItem()
    await this.getCaracteristicasMEAVCUItem()
    await this.getConteudo()
    await this.getEmpresa()
    this.getRegistro(0)
  }
  async getConteudo() {
    this.listTipEmbalagem = await this._conteudo.get('TIPEMBALA')

  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: new FormControl('', Validators.required),
      descricao: new FormControl('', Validators.required),
      partNumber: new FormControl(0),
      tipo: new FormControl('', Validators.required),
      ativoPartirDe: new FormControl('', Validators.required),
      inativoPartirDe: '',
      categoria: new FormControl(''),
      volume: new FormControl(''),
      classificacao: new FormControl('', Validators.required),
      codigoFiscalServicoPrestado: new FormControl(''),
      codigoNCM: new FormControl(''),
      dimensoesNCM: new FormControl(''),
      peso: new FormControl(''),
      email: new FormControl('', Validators.required),
      unidadeCompra: new FormControl('', Validators.required),
      custoItem: new FormControl(''),
      fabricante: new FormControl(''),
      codigoFornecedorPadrao: new FormControl(0),
      codigoCatalogoFabricante: new FormControl('', Validators.required),
      tipoEmbalagemCompras: new FormControl(''),
      quantidadePorEmbalagemCompras: new FormControl('', Validators.required),
      codigoPlanejamentoUsoMaterial: new FormControl(''),
      unidadeMedidaEstoque: new FormControl(''),
      qtdMinimaEstoque: new FormControl(''),
      qtdMaximaEstoque: new FormControl(''),
      depositoPadrao: new FormControl(''),
      posicaoDeposito: new FormControl(''),
      cicloContagemInventario: new FormControl(0),
      bloqueioUtilizacao: new FormControl(''),
      motivoBloqueio: new FormControl(''),
      adminstrarItemPor: new FormControl(''),
      dataHoraUltimaContagemInventario: new FormControl(''),
      dataHoraProximoContagemInvetanrio: new FormControl(''),
      unidadeMedidaVenda: new FormControl('', Validators.required),
      margemLucro: new FormControl(''),
      listaDePreco: new FormControl('', Validators.required),
      vigenciaListaPreco: new FormControl('', Validators.required),
      precoUnitario: new FormControl(''),
      quandoPrecoForaPadrao: new FormControl('', Validators.required),
      tipoEmbalagemVendas: new FormControl(''),
      procContCompras: new FormControl(''),
      procContEstoque: new FormControl(''),
      procContVendas: new FormControl('', Validators.required),
      procContDRE: new FormControl('', Validators.required),
      // contaContabilDevedoraCompras: new FormControl(''),
      // contaContabilCredoraCompras: new FormControl(''),
      // contaContabilDevedoraEstoque: new FormControl(''),
      // contaContabilCredoraEstoque: new FormControl(''),
      // contaContabilDevedoraVendas: new FormControl('', Validators.required),
      // contaContabilCredoraVendas: new FormControl('', Validators.required),
      // contaContabilDevedoraDRE: new FormControl('', Validators.required),
      metodoAvaliacaoCustos: new FormControl(''),
      sujeitoIRRF: new FormControl(false, Validators.required),
      observacao: new FormControl('', Validators.required),
      criadoPor: sessao.cpf,
      criadoEm: '',
      numeroDocumento: '',
      status: '',
      codigoSKU: 0
    })
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SCA0001')
    this.formulario.get('categoria').setValue(this.GetSelectClassificacaoCGItem[0].codigoReferencia)
  }

  async getRegistro(codigoEmpresa) {
    let registro = []
    if (codigoEmpresa > 0) {
      let get = await this.service.getCadastroItem(codigoEmpresa)
      registro = get
    } else {
      for (let value of this.listEmpresa) {
        let get = await this.service.getCadastroItem(value.empresa)
        if (get.length > 0) {
          registro.push(...get)
        }
      }
    }
    if (!this.cadastroGeral) {
      registro = registro.filter(value => value.categoria == 'CGITEM4')
    }

    registro.forEach(item => {
      let getCategoria = this.GetSelectClassificacaoCGItem.filter(value => value.codigoReferencia == item.categoria)
      if (getCategoria.length > 0) {
        item.descCategoria = getCategoria[0].valor
      }
    })
    try { this.dataSource = new MatTableDataSource(registro) }
    catch { this.dataSource = new MatTableDataSource([]) }





  }

  inserir() {
    let registro = this.formulario.value
    delete registro.ativoApartirDe
    delete registro.id
    delete registro.inativoApartirDe
    delete registro.timestamp
    delete registro.nome
    delete registro.criadoEm
    delete registro.codigoAssinante

    registro.criadoPor = sessao.cpf

    Object.keys(registro).forEach(value => {
      let dado = registro[value];
      if (!dado) {
        delete registro[value]
      }
    });
    registro.criadoEm = new Date().toISOString()
    console.log("inserir item inicio", JSON.stringify(registro))
    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }
      ).catch(
        error => {
          let erro = error.error
          console.log(error)
          this.mensagem.creat(erro, { erro: true })
        }
      )
    console.log("inserir item", registro)
    this.getRegistro(this.selectEmpresa)
  }


  async openCadastro(registro: CadastroItem) {
    this.loadingOpenCad = true
    this.infoConta =
    {
      compras: new LivroRazaoModels,
      estoque: new LivroRazaoModels,
      vendas: new LivroRazaoModels,
      dre: new LivroRazaoModels
    }

    await this.getContasContabeis(registro.codigoEmpresa)
    console.log("teste open cadastro", registro)
    this.formulario.patchValue(registro)
    await this.getInfoConta(registro.procContCompras, 'compras')
    await this.getInfoConta(registro.procContEstoque, 'estoque')
    await this.getInfoConta(registro.procContVendas, 'vendas')
    await this.getInfoConta(registro.procContDRE, 'dre')
    localStorage.setItem('backupRegistro', JSON.stringify(registro))
    this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.loadingOpenCad = false
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }


  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    let registro = this.formulario.value

    if (!registro.id) return

    registro.status = "SCA0002"
    delete registro.nome
    delete registro.descNivelAprovacao
    delete registro.descStatus
    await this.service.deleteCadastroItem(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro(0)

  }

  async cancelarRegistro() {
    let id = this.formulario.controls['id'].value
    if (id) {
      let get = await this.service.getID(id)
      this.formulario.patchValue(get)
    }
  }

  async buscarUsuario(cpf, openCadastro?) {
    let valid = this.formulario.controls['cpf'].valid

    if (valid || openCadastro) {
      let res = await this.usuario.getRegistro(cpf)
      this.nome = res[0].nomeCompleto

    }
    else {
      this.nome = null
    }
  }
  async alterar() {
    this.loading = true
    let registro = this.formulario.getRawValue()
    Object.keys(registro).forEach(value => { if (!registro[value]) { delete registro[value] } })
    console.log(JSON.stringify(registro))
    await this.service.putCadastroItem(registro).toPromise()
      .then(res => {
        console.log(res)
        this.mensagem.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
      })
      .catch(error => {
        console.log(error)
        this.mensagem.creat('Erro ao alterar registro', { erro: true })
      })
    this.getRegistro(this.selectEmpresa)
    this.loading = false
  }
  async getContasContabeis(codigoEmpresa) {
    this.loadinGet = true
    this.listContas_backup = await this._contas.getContaContabil(codigoEmpresa)

    this.filterContas('')
    this.listLivroRazao = await this._livroRazao.getLivroRazao(codigoEmpresa)
    this.filterLivroRazao('', 'compras')
    this.filterLivroRazao('', 'dre')
    this.filterLivroRazao('', 'estoque')
    this.filterLivroRazao('', 'vendas')
    this.loadinGet = false
  }



  getListaDePreco(CodigoEmpresa) {
    this.serviceListaPreco.getListaPrecoCodigoEmpresa(CodigoEmpresa).subscribe(
      ((resultado: any) => {
        this.ListaPrecoCodigoEmpresa = resultado
        console.log('ListaPrecoCodigoEmpresa', this.ListaPrecoCodigoEmpresa)
      })
    )
  }
  async getForncedores(CodigoEmpresa) {
    this.listFornecedor = await this._fornecedor.get(CodigoEmpresa)
    this.listFornecedorBackup = await this._fornecedor.get(CodigoEmpresa)
  }
  addListaPreco(codigo) {
    let registro: any = this.ListaPrecoCodigoEmpresa.filter(value => value.codigoListaDePreco == codigo)
    this.formulario.controls['vigenciaListaPreco'].setValue(registro[0].vigenciaFim)
  }

  getCaracteristicasTPItem() {
    var Caracteristica = "TPITEM"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoTPItem = resultado
      })
    )
  }

  async getCaracteristicasCgItem() {
    let get = await this._conteudo.get('CGITEM')
    if (this.cadastroGeral) {
      this.GetSelectClassificacaoCGItem = get
    } else {
      this.GetSelectClassificacaoCGItem = get.filter(value => value.codigoDeNegocio == 'Venda')
    }
  }

  getCaracteristicasClItem() {
    var Caracteristica = "CLITEM"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoClItem = resultado
      })
    )
  }

  getCaracteristicasMEAVCUItem() {
    var Caracteristica = "MEAVCU"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoMEAVCUItem = resultado
      })
    )
  }

  getCaracteristicasMEPLUSItem() {
    var Caracteristica = "MEPLUS"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoMEPLUSItem = resultado
      })
    )
  }

  getCaracteristicasBLOUTIItem() {
    var Caracteristica = "BLOUTI"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoBLOUTIItem = resultado
      })
    )
  }

  getCaracteristicasADMITEIItem() {
    var Caracteristica = "ADMITE"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoADMITEItem = resultado
      })
    )
  }

  getCaracteristicasPRFOPAItem() {
    var Caracteristica = "PRFOPA"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoPRFOPAItem = resultado
      })
    )
  }

  getCaracteristicasUNIMEDItem() {
    var Caracteristica = "UNIMED"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoUNIMEDItem = resultado
      })
    )
  }
  filterContas(value: string) {
    value = value.toLocaleLowerCase()
    let registro = this.listContas_backup
    if (registro.length < 1) return
    const filter = registro.filter(item => item.descricao).filter(item => item.descricao.toLocaleLowerCase().includes(value))
    if (filter.length > 0) {
      this.listContas = filter
    } else {
      this.listContas = registro
    }
  }
  filterLivroRazao(value: string, type: "compras" | "estoque" | "vendas" | "dre" | "all", revert?: boolean) {

    value = value.toLocaleLowerCase()

    let registro = this.listLivroRazao.filter(item => item.descricaoProcesso.toLocaleLowerCase().includes(value))
    this.listLivroRazaoResultFilter[type] = registro
  }
  filterFornecedor(value: string) {
    value = value.toLocaleLowerCase()
    let registro = this.listFornecedorBackup.filter(item => item.descricao.toLocaleLowerCase().includes(value))
    this.listFornecedor = registro
  }

  async getInfoConta(value, type: "compras" | "estoque" | "vendas" | "dre") {
    if (!value) return
    let getLivroRazao = this.listLivroRazao.filter(item => item.codigoLancamento == value)[0]
    let getContaDeb = await this._livroRazao.getProcessoContabilizacao(this._controlValue('codigoEmpresa'), getLivroRazao.contaDebito)
    let getContaCred = await this._livroRazao.getProcessoContabilizacao(this._controlValue('codigoEmpresa'), getLivroRazao.contaCredito)
    console.log(getContaDeb, getContaCred)
    getLivroRazao.descContaDebito = getContaDeb.descricao ? getContaDeb.descricao : 'Não cadastrado'
    getLivroRazao.descContaCredito = getContaCred.descricao ? getContaCred.descricao : 'Não cadastrado'
    this.infoConta[type] = getLivroRazao
  }
  verifcLivroRazao(type: "compras" | "estoque" | "vendas" | "dre", codigo: number) {
    let verific = []
    let control = ["compras", "estoque", "vendas", "dre"]
    control.splice(control.indexOf(type), 1)
    control.forEach(value => {
      let valid = this.infoConta[value].codigoLancamento == codigo
      verific.push(valid)
    })
    try {
      return verific.filter(v => v).length > 0 ? true : false
    } catch {
      return false
    }
  }
  _controlValue(control) {
    try {
      return this.formulario.get(control).value
    } catch {
      return ''
    }
  }
  _valueConta(control) {

    let conta = this.formulario.get(control).value
    if (conta) { return '' }
    let get = this.listContas_backup.filter(value => value.codigoContaContabil == conta)[0]
    try {
      return `${get.codigoContaContabil} - ${get.descricao}`
    } catch {
      return ''
    }
  }
  _valid(controlName) {
    try {
      return this.formulario.get(controlName).valid
    } catch {
      // console.log('_valid() -  control not null', controlName)
      return false
    }
  }
  filtrar(value: string) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }


}