import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PacienteService } from '../service/paciente.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormulariosService } from '../service/formularios.service';
import { DatePipe } from '@angular/common';
import * as jsPDF from 'jspdf'
import { BuscarDataService } from '../service/buscar-data.service';
import { ValorVendaService } from '../service/valor-venda.service';
import { FormCobranca } from '../atendimento-clinico/encerrar-atendimento/solicitar-cobranca/model';
import { CadastroPessoaForm, FormPaciente } from '../cadastro-paciente/model';
import { AtendimentoClinicoService } from '../service/atendimento-clinico.service';
import { AgendaForm } from '../agenda/agenda/model';
import { ValorConsultaService } from '../service/valor-consulta.service';
import { FormPagamento } from './modal';
import { UsuarioService } from '../service/usuario.service';
import { FormUsuario } from '../usuario/model';
import { ConfiguracaoInicialService } from '../service/configuracao-inicial.service';
import { FormCadastroEmpresa } from '../configuracao-inicial/cadastro-empresa/model';
import { SessaoUser } from '../user.reg';
import { FichaGeralEmpresa } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { ConteudoDeDadosService, ConteudoDados } from '../service/conteudo-de-dados.service';
import { FaturaDeMedico, ReciboMedicoRpsCabecalho, ReciboMedicoRpsItem, OrdemDeVendaMedicaCabecalho, OrdemDeVendaMedicaImpostoDoItem, FichaGuiaConsultaTissCabecalho, FichaGuiaConsultaTissItem, FaturaDeOperadoraCartao } from '../service/fatura-medica/modal';
import { FaturaMedicaService } from '../service/fatura-medica/fatura-medica.service';
import { CadastroItem } from '../cadastro-item/campos-cadastroItem';
import { MensagemService } from '../service/mensagem.service';
import { SenhaAtendimentoForm } from '../atendimento-clinico/senha-atendimento/model';
import html2canvas from 'html2canvas';
import { CadastroOperadoraCartao } from '../ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.model';
import { CadastroOperadoraCartaoService } from '../service/cadastro-operadora-cartao.service';
import { ConvenioService } from '../service/convenio/convenio.service';
import { ListarMedicos, ListarMedicosService } from '../service/listar-medicos.service';
import { FotoPerfilService } from '../service/foto-perfil.service';
import { ConsultoriosMedicosService } from '../service/consultorios-medicos/consultorios-medicos.service';
import { ConsultorioMedico } from '../definir-consultorios-medicos/modelsDefinirConsultorioMedico';
import { CadastroDadosProfissionaisJoin } from '../admin-usuarios/model';
import { TicketsService } from '../service/tickets.service';
import { TicketJOIN, TicketSenhaAtendimentoJoin } from '../home/monitor-clinico/consultas/solicitacoes/modal';
import { VideoChamadaService } from '../service/video-chamada.service';
import { SalaEsperaForm } from '../atendimento-clinico/sala-espera/model';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
const sessao = new SessaoUser
@Component({
  selector: 'app-cobrar-consulta',
  templateUrl: './cobrar-consulta.component.html',
  styleUrls: ['./cobrar-consulta.component.scss']
})
export class CobrarConsultaComponent implements OnInit {
  @ViewChild('pdf', { static: true }) tester: ElementRef
  @Output('pagamento') pagamento = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Input('paciente') paciente = new CadastroPessoaForm
  @Input('infoAgenda') infoAgenda = new AgendaForm
  @Input('infoTitular') infoTitular: CadastroPessoaForm
  @Input('medico') cpfMedico: string
  @Input('ticket') ticket = new SenhaAtendimentoForm
  @Input() tipoConsulta
  cobrancaAdicional: FormCobranca[] = []
  nfce = "../../assets/NFSe_layout_novo.jpg"
  descontoConsulta = 0
  valorConsulta = 100
  totalAdicional = 0
  total
  autConvenio
  cobrarConsulta = new FormPagamento
  pagConvenio
  hoje
  addCredito
  cont = 0
  abrirRecibo = false
  id: string;
  companyId: string;
  erroMensagem: string;
  viewCadConvenio: boolean;
  viewAdicionarServico: boolean;
  viewEmitr: boolean;
  listServicos: CadastroItem[] = [];
  registroServicos: CadastroItem[] = [];
  listregistroServicos: CadastroItem[] = [];
  listregistroServicos_active: CadastroItem[] = [];
  tipoDesconto
  meioDePagamento = {
    LIFOPA1: false,
    LIFOPA2: false,
    LIFOPA3: false,
    LIFOPA4: false,
    LIFOPA5: false,
    LIFOPA6: false,
    LIFOPA7: false,
    LIFOPA11: false
  }
  formMeioPagamento = {
    LIFOPA1: [{ valor: 0, parcela: 1, valorParcela: 0 }],
    LIFOPA2: '',
    LIFOPA3: '',
    LIFOPA4: '',
    LIFOPA5: [{ operadora: 'Unimed', plano: 'Personal QC', codAutorizacao: '', numBeneficiario: '123456' }],
    LIFOPA6: '',
    LIFOPA7: '',
    LIFOPA9: [{ agencia: '', conta: '', banco: '', valor: 0 }]
  }
  totalValorAberto: number;
  infoEmpresa = new FichaGeralEmpresa;
  listPagamentos = [];
  horarioConsulta: string;
  listMeioPagamento: ConteudoDados[] = [];
  loadFormPag: boolean;
  viewCertificado: boolean
  loadingDoc = {
    start: false,
    rmc: false,
    rmi: false,
    ovm: false,
    fdm: false,
    tss: false,
    convenio: false,
    convenioCont: 0,
    credito: false,
    creditoCont: 0,
  }
  dadosConvenio = {
    ativo: false,
    cabecalho: new FichaGuiaConsultaTissCabecalho,
    item: [],
    tuss: []
  }
  loadingPrint: boolean;
  viewDescNota: boolean;
  tipoFormaEmissao
  openFormConvenio: boolean;
  mes: { name: string; };
  viewRecibo: boolean
  viewNota: boolean
  viewEmissor: boolean
  tipoEmissor
  tipoEmissao
  cnpj
  listOperadoras: CadastroOperadoraCartao[] = [];
  operadora = new CadastroOperadoraCartao;
  infoMedico = new CadastroDadosProfissionaisJoin
  medico: any[];
  backgroud = { 'background-image': `url('assets/imgUser.png')` }
  backgroudMedico = { 'background-image': `url('assets/imgUser.png')` }
  infoConsultorio = new ConsultorioMedico;
  info = new TicketJOIN
  loading: boolean;
  idChamada: any;
  contReload = 0
  constructor(
    private url: ActivatedRoute,
    private service: FaturaMedicaService,
    private serviceValorConsulta: ValorConsultaService,
    private serviceAtendimento: AtendimentoClinicoService,
    private serviceUsuario: UsuarioService,
    private form: FormBuilder,
    private mensagem: MensagemService,
    private formularios: FormulariosService,
    private datePipe: DatePipe,
    private data: BuscarDataService,
    private serviceEmpresa: ConfiguracaoInicialService,
    private _conteudo: ConteudoDeDadosService,
    private serviceOperadora: CadastroOperadoraCartaoService,
    private _convenio: ConvenioService,
    private _medico: ListarMedicosService,
    private service_foto: FotoPerfilService,
    private _consultorio: ConsultoriosMedicosService,
    private _videoChamada: VideoChamadaService,
    private _ticket: TicketsService,
    private _user: UsuarioService,
    private _filter : FiltrarDadosService
  ) {
    this.hoje = this.data.hoje()
    let mes = new Date().getMonth()
    this.mes = this.data.getMeses(mes)
    this.cobrarConsulta.creditoConsulta = '10'
  }

  async ngOnInit() {
    console.log('*************', this.ticket)
    this.getTicket()
    // this.paciente.idade = this.data.idade(this.paciente.dataNascimento)
    this.companyId = sessionStorage.getItem('companyId')
    this.id = sessionStorage.getItem('tokken')
    let descMotivo = await this._conteudo.get('LIMOCO', true, this.infoAgenda.motivoConsulta)
    this.infoAgenda.descMotivo = descMotivo.toString()
    if (this.infoAgenda.id) {
      this.horarioConsulta = this.infoAgenda.start.split('T')[1]
    }
    if (!this.infoTitular) {
      this.infoTitular = new CadastroPessoaForm
    }
    this.inseirValores()
    // this.buscarCobrancaAdicionar()
    this.getEmpresa()
    this.getFormasPagamentos()
    this.getServicos()
    this.getOperadoras()
    this.getFoto()
    this.getConsultorio()
    if (this.cpfMedico) {
      let getMedico = await this._medico.getMedico(this.cpfMedico)
      if (getMedico.id) {
        this.infoMedico = getMedico
      }
    } else {
      let getMedico = await this._medico.getMedico(this.ticket.cpfMedico)
      if (getMedico.id) {
        this.infoMedico = getMedico[0]
      }
    }
  }


  async getEmpresa() {

    this.serviceEmpresa.listarEmpresa(sessao.codigoEmpresa).toPromise()
      .then(
        (empresa: any) => {
          console.log('info empresa', empresa)
          if (empresa.success) {
            this.infoEmpresa = empresa.result[0]
          }
        }
      )
  }
  async getTicket() {
    if (this.contReload > 3) {
      this.loading = false
      // this.close()
      this.mensagem.creat('Falha ao buscar informações de consulta', { erro: true })
      return
    }
    this.loading = true
    let ticket = await this._ticket.getTicketSenhaJoin(this.ticket.nrDoc)
    let infoMedico = await this._medico.getMedico(ticket.cpfMedico)
    let infoPaciente = await this._user.getCadastroPessoa(ticket.cpfPaciente)
    let getTicketConsulta = await this._ticket.getConsultas(sessao.codigoEmpresa)

    let ticketConsulta = getTicketConsulta.filter(v => v.nrDoc == ticket.nrDoc || v.nrDoc == ticket.nrDocReferencia || v.nrDocRef == ticket.nrDocReferencia)[0]

    console.log("============>TICKET", ticketConsulta)

    let info: any = Object.assign({}, ticket, infoMedico, infoPaciente, ticketConsulta ? ticketConsulta : {})


    this.info = info
  
    await this._filter.inserirFotoPerfilEach('cpf',this.info)
    console.log('------------>',this.info)

    try {
      const nasc = infoPaciente.dataNascimento
      const idade = `${nasc.slice(0, 4)}-${nasc.slice(4, 6)}-${nasc.slice(6, 8)}`

      this.info.dataNascimento = this.data.idade(idade).toString()
      let descMotivoConsulta = await this._conteudo.get('LITICO', true, this.info.motivoConsulta)
      let descFormaDeConsulta = await this._conteudo.get('FORMACON', true, this.info.formaDeConsulta)
      let descTipoAtendimento = await this._conteudo.get('TIPATEND', true, this.info.tipoAtendimento)
      this.info.descMotivoConsulta = descMotivoConsulta[0] ? descMotivoConsulta.toString() : this.info.motivoConsulta
      this.info.descFormaDeConsulta = descFormaDeConsulta[0] ? descFormaDeConsulta.toString() : this.info.formaDeConsulta
      this.info.descTipoAtendimento = descTipoAtendimento[0] ? descTipoAtendimento.toString() : this.info.tipoAtendimento
      if (this.info.formaDeConsulta == 'FORMACON2') {
        this.adicionarVideoChamada()
      }
      this.loading = false
    } catch {
      this.contReload++
      this.getTicket()

    }


    this.getConsultorio()

  }
  async getFoto() {
    let foto = await this.service_foto.buscarFoto(this.ticket.cpfPaciente ? this.ticket.cpfPaciente : this.info.cpfPaciente)
    let fotoMedico = await this.service_foto.buscarFoto(this.ticket.cpfMedico ? this.ticket.cpfMedico : this.info.cpfMedico)
    console.log('MINHAFOTO', foto)
    if (foto) {
      this.backgroud = { 'background-image': `url(${foto.changingThisBreaksApplicationSecurity})` }
      this.backgroudMedico = { 'background-image': `url(${fotoMedico.changingThisBreaksApplicationSecurity})` }
    }
  }
  async getConsultorio() {
    let result = await this._consultorio.getConsultorioDoMedico(sessao.codigoEmpresa, this.ticket.cpfMedico)
    if (result.codigo) {
      this.infoConsultorio = result[0]
    }
  }
  async getServicos() {
    this.registroServicos = await this.service.getServicos()
  }
  async getOperadoras() {
    this.serviceOperadora.listar().toPromise()
      .then((res: CadastroOperadoraCartao[]) => {
        try {
          let filter = res.filter(x => {
            return x.codigoEmpresa == sessao.codigoEmpresa
          }
          )
          if (filter.length > 0) {
            this.listOperadoras = filter
            this.listOperadoras.forEach(async item => {
              let operadora = await this._conteudo.get('OPERCARD', true, item.operadora)
              item.operadoraDesc = operadora.toString()
            })
          } else {
            this.listOperadoras = []
          }
        } catch {
          this.listOperadoras = []
        }

      })
  }

  async getFormasPagamentos() {
    this.loadFormPag = true
    let get = await this._conteudo.get('LIFOPA')
    this.listMeioPagamento = get.sort((a, b) => {
      if (a.valor < b.valor) return -1
      if (a.valor > b.valor) return 1
      return 0;
    })
    console.log(this.meioDePagamento['credito'])
    this.loadFormPag = false

  }
  async adicionarVideoChamada() {
    console.log('adicionarVideoChamada')
    let key = this.info.cpfMedico
    console.log('KEY - ', key)
    if (key.length < 20) {
      for (let i = 0; key.length < 20; i++) {
        let num = Math.floor(Math.random() * 9 + 1)
        key = `${key}${num}`
      }
      console.log(key, key.length)
    }
    if (key.length == 20) {
      let dado = new SalaEsperaForm
      dado.cpfMedico = this.info.cpfMedico
      dado.cpfPaciente = this.info.cpfPaciente
      dado.nomeMedico = this.info.nomeMedico
      dado.nomePaciente = this.info.nomeMedico
      this._videoChamada.criarSala(dado).then(
        async (reg) => {

          await localStorage.setItem('keyVC', reg.id)
          const id = reg.id

          let id_info = null
          const registro = {
            codigoUnico: id,
            remetente: this.info.cpfMedico,
            destinatario: this.info.cpfPaciente,
            data: this.data.hoje(),
            status: 'pendente',
            transacao: 'AC10'
          }
          this._videoChamada.banco().doc(id).collection('info').add({ nome: this.info.nomePaciente, medico: this.info.nomeMedico, registro: registro }).then(
            (res) => { id_info = res.id }
          )

          setTimeout(() => {
            this._videoChamada.registrar(registro).toPromise().then(
              (reg: any) => {
                this._videoChamada.banco().doc(id).collection('info').doc(id_info).update({ registro: reg })
                this.idChamada = id
              } //this.mensagem.info('Seu paciente esta sendo chamado para video consulta')
            )
          }, 15000)

        }
      ).catch(
        (erro) => {
          this.mensagem.creat('Desculpe algo deu errado', { erro: true })
          console.log('Seu erro : ', erro)
        }
      ).finally(() => console.log('fim'))
    }


  }
  //////Calculo 
  calcularTotal() {
    try {
      let total = (total, valor) => total + valor
      let valores = this.listServicos.map((x) => x.preco)
      this.total = valores.reduce(total)
    }
    catch {
      this.total = 0.00
    }
  }
  adicionarValor(ref, valor) {
    if (ref.checked) {
      this.listServicos.push(valor)
    }
    else {
      const index = this.listServicos.findIndex(value => value.id == valor.id)
      delete this.listServicos[index]
      this.listServicos = this.listServicos.filter(v => v)
    }
    this.calcularTotal()
    this.calcularDesconto()
    this.calcularValorAberto()
  }
  calcularValorAberto(convenio?) {
    if (convenio) {
      this.totalValorAberto = 0
      this.meioDePagamento.LIFOPA7 = false
      this.meioDePagamento.LIFOPA6 = false
      this.meioDePagamento.LIFOPA3 = false
      this.meioDePagamento.LIFOPA2 = false
      this.meioDePagamento.LIFOPA1 = false
      this.meioDePagamento.LIFOPA4 = false
      this.meioDePagamento.LIFOPA11 = false
      if (this.meioDePagamento.LIFOPA5) {
        this.listPagamentos = [{ nome: 'Convênio' }]
      }
      return
    }
    let list_valores = [
      {
        valor: this.formMeioPagamento.LIFOPA1[0].valor,
        ativo: this.meioDePagamento.LIFOPA1,
        nome: 'Crédito'
      },
      {
        valor: parseFloat(this.formMeioPagamento.LIFOPA2 ? this.formMeioPagamento.LIFOPA2 : '0'),
        ativo: this.meioDePagamento.LIFOPA2,
        nome: 'Débito'
      },
      {
        valor: parseFloat(this.formMeioPagamento.LIFOPA3 ? this.formMeioPagamento.LIFOPA3 : '0'),
        ativo: this.meioDePagamento.LIFOPA3,
        nome: 'Dinheiro'
      },
      {
        valor: parseFloat(this.formMeioPagamento.LIFOPA4 ? this.formMeioPagamento.LIFOPA4 : '0'),
        ativo: this.meioDePagamento.LIFOPA4,
        nome: 'Cheque'
      },
      {
        valor: parseFloat(this.formMeioPagamento.LIFOPA6 ? this.formMeioPagamento.LIFOPA6 : '0'),
        ativo: this.meioDePagamento.LIFOPA6,
        nome: 'Nota de Crédito'
      },
      {
        valor: parseFloat(this.formMeioPagamento.LIFOPA7 ? this.formMeioPagamento.LIFOPA7 : '0'),
        ativo: this.meioDePagamento.LIFOPA7,
        nome: 'Pagamento futuro'

      }
    ]
    this.listPagamentos = list_valores.filter((r) => { return r.ativo })
    let getValores = list_valores.map((v) => v.valor)
    try {
      let total = (total, valor) => total + valor
      this.totalValorAberto = getValores.reduce(total)
      this.totalValorAberto = this.total - this.totalValorAberto
    }
    catch {
      this.total = 0
    }


  }
  async calcularDesconto() {
    await this.calcularTotal()
    if (this.descontoConsulta < 1) return
    if (this.tipoDesconto == 1) {
      this.total = this.total - (this.total * this.descontoConsulta) / 100
    }
    if (this.tipoDesconto == 2) {
      this.total = this.total - this.descontoConsulta
    }
    await this.calcularValorAberto()
  }
  captureScreen(view?) {
    this.loadingPrint = true
    var data = document.getElementById('pdf');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

      if (view) {
        window.print()
        setTimeout(() => {
          this.loadingPrint = false
        }, 4000)

        // Generated PDF
      } else {
        pdf.save('doc.pdf')
      }
      this.loadingPrint = false
    });
  }
  ///end Calculo


  async Pagamento() {

    this.cobrarConsulta.total = JSON.stringify(this.total)
    this.cobrarConsulta.descontoConsulta = `${this.descontoConsulta}`
    this.cobrarConsulta.valorConsulta = JSON.stringify(this.valorConsulta)
    this.cobrarConsulta.autConvenio = this.autConvenio
    this.cobrarConsulta.pacienteId = this.paciente.id
    this.cobrarConsulta.companyId = this.companyId
    this.cobrarConsulta.usuarioId = this.id
    this.cobrarConsulta.dataEmitido = this.hoje
    console.log('Cobrar Consulta : ' + JSON.stringify(this.cobrarConsulta))
    this.serviceValorConsulta.cadastrarValorConsulta(this.cobrarConsulta).toPromise()
      .then()
      .catch()
    if (this.cobrarConsulta.pagConvenio == 'pAto') {
      localStorage.setItem('formaPagamento', this.cobrarConsulta.tipoPagamento)
    } else {
      localStorage.setItem('formaPagamento', this.cobrarConsulta.pagConvenio)
    }
    this.pagamento.emit(true)
  }

  buscarCobrancaAdicionar() {
    this.serviceAtendimento.listarCobrancaAdicional(this.companyId).toPromise().then(
      (dados: Array<FormCobranca>) => {
        let cpf = this.paciente.cpf
        let filtro = dados.filter(function (reg) {
          return reg.cpf == cpf
        })
        if (filtro.length) {
          this.cobrancaAdicional = filtro
          this.cobrancaAdicional.forEach(ref => {
            this.totalAdicional = this.totalAdicional + parseInt(ref.total)
          });
        }
      }
    )
  }


  inseirValores() {

  }
  emitRecibo() {
    console.log('passou')
    if (this.tipoEmissao == 1) {
      this.viewDescNota = true
      this.viewRecibo = false
    }
    if (this.tipoEmissao == 2) {
      this.viewNota = false
      this.viewDescNota = false
      this.viewEmissor = false
      this.viewRecibo = true
    }
  }
  pdf(nfc?) {
    let doc = new jsPDF();
    let html = {
      "#editor": function (element, renderer) {
        return true
      }
    }
    let content = this.nfce;
    doc.output('dataurlnewwindow');
  }
  teste(event) {
    console.log(event)

    console.log(this.total)
  }
  adicionarPagamento(event, ref) {
    switch (ref) {
      case 'LIFOPA1':
        if (event) { this.meioDePagamento.LIFOPA1 = true; this.calcularCredito() }
        else { this.meioDePagamento.LIFOPA1 = false; this.formMeioPagamento.LIFOPA1[0].valor = 0 }
        break;
      case 'LIFOPA2':
        if (event) { this.meioDePagamento.LIFOPA2 = true }
        else { this.meioDePagamento.LIFOPA2 = false; this.formMeioPagamento.LIFOPA2 = '0' }
        break;
      case 'LIFOPA3':
        if (event) { this.meioDePagamento.LIFOPA3 = true }
        else { this.meioDePagamento.LIFOPA3 = false; this.formMeioPagamento.LIFOPA3 = '0' }
        break;
      case 'LIFOPA4':
        if (event) { this.meioDePagamento.LIFOPA4 = true }
        else { this.meioDePagamento.LIFOPA4 = false; this.formMeioPagamento.LIFOPA4 = '0' }
        break;
      case 'LIFOPA5':
        if (event) {
          this.meioDePagamento.LIFOPA5 = true;
          this.openFormConvenio = true;
          this.calcularValorAberto(true)
        }
        else {
          this.meioDePagamento.LIFOPA5 = false;
          this.openFormConvenio = false;
          this.dadosConvenio.ativo = false;
          this.listPagamentos = []
        }
        break;
      case 'LIFOPA6':
        if (event) { this.meioDePagamento.LIFOPA6 = true }
        else { this.meioDePagamento.LIFOPA6 = false; this.formMeioPagamento.LIFOPA6 = '0' }
        break;
      case 'LIFOPA7':
        if (event) { this.meioDePagamento.LIFOPA7 = true }
        else { this.meioDePagamento.LIFOPA7 = false; this.formMeioPagamento.LIFOPA7 = '0' }
        break;
      case 'LIFOPA11':
        if (event) { this.meioDePagamento.LIFOPA11 = true }
        else { this.meioDePagamento.LIFOPA11 = false; this.formMeioPagamento.LIFOPA7 = '0' }
        break;

      default:
        break;
    }
    console.log(this.meioDePagamento, ref)
    if (ref == 'LIFOPA5') { return }
    this.calcularValorAberto()
  }
  // calcularTotal() {
  //   this.total = this.descontoConsulta ? this.valorConsulta - (this.valorConsulta * this.descontoConsulta / 100) : this.valorConsulta
  // }
  addServico(insert, item?: CadastroItem, checked?) {
    if (checked) {
      this.listregistroServicos.push(item)
    } else if (item) {
      let index = this.listregistroServicos.filter((r) => { return r.codigoSKU == item.codigoSKU }).map((x, index) => { return index })[0]
      this.listregistroServicos.splice(index, 1)
    }
    if (insert) {
      this.listregistroServicos_active = []
      this.listregistroServicos.forEach(element => {
        this.listregistroServicos_active.push(element)
      });
      this.viewAdicionarServico = false
      console.log('listregistroServicos_active', this.listregistroServicos_active)
    }
    console.log(this.listregistroServicos)
  }
  inserirCredito(event) {

    setTimeout(() => {
      console.log(this.addCredito)
      if (this.addCredito == undefined) { this.addCredito = false; return }
      if (this.addCredito) {
        this.total = this.total - parseInt(this.cobrarConsulta.creditoConsulta);
        this.addCredito = true
        this.valorConsulta = parseInt(this.cobrarConsulta.valorConsulta)
        return
      }
      if (!this.addCredito) {
        console.log('false')
        this.total = this.total + parseInt(this.cobrarConsulta.creditoConsulta);
        this.addCredito = false
        this.valorConsulta = parseInt(this.cobrarConsulta.valorConsulta)
      }
    }, 500)


  }
  close() {
    this.exit.emit(false)
  }
  registroConvenio(registro) {
    this.dadosConvenio = registro
    this.dadosConvenio.ativo = true
    this.meioDePagamento.LIFOPA5 = true
    this.openFormConvenio = false
    console.log('convenio :', this.dadosConvenio)
  }
  calcularCredito() {
    try {
      let valor = this.formMeioPagamento.LIFOPA1[0].valor
      let parcela = this.formMeioPagamento.LIFOPA1[0].parcela
      this.formMeioPagamento.LIFOPA1[0].valorParcela = valor / parcela
    } catch {
      this.formMeioPagamento.LIFOPA1[0].valorParcela = 0
    }


  }
  async Inserir() {
    console.log('###$$$$', this.ticket)
    this.loadingDoc.start = true
    this.loadingDoc.rmc = true
    this.loadingDoc.rmi = true
    this.loadingDoc.fdm = true
    this.loadingDoc.ovm = true
    let numDocumento: any

    let total = parseInt(this.total ? this.total : '0')

    let item = new ReciboMedicoRpsCabecalho
    if (!this.ticket.nrDocReferencia) {
      this.mensagem.creat('Não foi encontrado um ticket de consulta', { erro: true })
    }
    item.codigoEmpresa = sessao.codigoEmpresa
    item.dataDoDocumento = this.data.horaAgora(true)
    item.dataLancamento = this.data.horaAgora(true)
    item.moedaDoRecibo = 'Real'
    item.status = "SDO0016"
    item.valorTotalRecibo = total
    item.nrDocumentoReferencia = this.ticket.nrDocReferencia
    item.criadoPor = sessao.cpf
    await this.service.postReciboMedicoRpsCabecalho(item).toPromise()
      .then(async (res) => {
        numDocumento = res[0].key
        console.log('numDocumento', res)
        this.loadingDoc.rmc = false
        for (let i = 0; i < this.listregistroServicos_active.length; i++) {
          const element = this.listregistroServicos_active[i]
          let item = new ReciboMedicoRpsItem
          item.nrDoc = numDocumento
          item.numeroItem = this.listregistroServicos_active.length
          item.precoBrutoItem = total
          item.skuItem = element.codigoSKU
          item.criadoPor = sessao.cpf
          await this.service.postReciboMedicoRpsItem(item).toPromise()
            .then(res => { console.log(res) })
            .catch(error => console.log('postReciboMedicoRpsItem erro', error))
        }
        this.loadingDoc.rmi = false

        // if (this.dadosConvenio.ativo) {
        //   console.log(this.dadosConvenio)
        //   this.dadosConvenio[0].cabecalho.nrDocumentoReferencia = numDocumento
        //   this.service.postFichaGuiaConsultaTissCabecalho(this.dadosConvenio[0].cabecalho).toPromise()
        //     .then(res => {
        //       this.dadosConvenio[0].item.forEach(async item => {
        //         item.numeroDocumento = res[0].key
        //         await this.service.postFichaGuiaConsultaTissItem(item).toPromise()
        //           .then(res => this.loadingDoc.tss = false)
        //           .catch(error => { console.log('error', error) })
        //       });
        //     })
        //     .catch(error => { console.log('error', error); this.loadingDoc.tss = false })
        //   return
        // }
        let item = new OrdemDeVendaMedicaCabecalho
        item.dataDoDocumento = this.data.horaAgora(true)
        item.dataLancamento = this.data.horaAgora(true)
        item.nrDocumentoReferencia = this.ticket.nrDocReferencia
        item.nrDoc = numDocumento
        item.status = "SDO0016"
        item.valorTotalDesconto = this.descontoConsulta
        item.valorTotalImposto = 0
        item.codigoEmpresa = sessao.codigoEmpresa
        item.dataDaEntrega = this.data.horaAgora(true)
        item.moedaDeDocumento = "Real"
        item.valorTotalDesconto = 0
        item.valorTotalDocumento = total
        item.valorTotalImposto = 0
        item.criadoPor = sessao.cpf
        await this.service.postOrdemDeVendaMedicaCabecalho(item).toPromise()
          .then(async res => {
            for (let i = 0; i < this.listregistroServicos_active.length; i++) {
              const element = this.listregistroServicos_active[i]

              let item = new OrdemDeVendaMedicaImpostoDoItem
              item.aliquotaImposto = 0
              item.numerorItem = i + 1
              item.valorImpostoItem = 0
              item.nrDoc = numDocumento
              item.imposto = element.tipoImposto
              item.criadoPor = sessao.cpf
              await this.service.postOrdemDeVendaMedicaImpostoDoItem(item).toPromise()
                .then(res => { })
                .catch(error => { console.log(error) })
            }
            this.loadingDoc.ovm = false

            let item = new FaturaDeMedico

            item.dataDoPagamento = this.data.horaAgora(true)
            item.dataDoVencimento = this.data.horaAgora(true)
            item.dataDoDocumento = this.data.horaAgora(true)
            item.dataLancamento = this.data.horaAgora(true)
            item.meioDePagamento = this.listPagamentos.map(r => r.nome).toString()
            item.moedaDaFatura = this.listPagamentos.map(r => r.nome).toString()
            item.nrDocumentoReferencia = this.ticket.nrDocReferencia
            item.numeroDocumento = numDocumento
            item.status = "SDO0016"
            item.valorTotalDesconto = this.descontoConsulta
            item.valorTotalFatura = total
            item.valorTotalImposto = 0
            item.valorTaxaCartao = this.operadora.taxaCreditoAprazo
            item.codigoEmpresa = sessao.codigoEmpresa
            item.criadoPor = sessao.cpf
            item.bancoTransferencia = this.formMeioPagamento.LIFOPA9[0].banco
            item.agenciaTransferencia = this.formMeioPagamento.LIFOPA9[0].agencia
            item.contaTransferencia = this.formMeioPagamento.LIFOPA9[0].conta


            await this.service.postFaturaDeMedico(item).toPromise()
              .then(res => {
                console.log('fatura', res);
                this.loadingDoc.fdm = false
              })
              .catch(error => { console.log('error', error) })


          })

        if (this.meioDePagamento.LIFOPA1 == true) {
          this.loadingDoc.credito = true
          let registro = new FaturaDeOperadoraCartao
          registro.dataDoDocumento = this.data.getDataInclusao()
          registro.dataDoPagamento = this.data.getDataInclusao()
          registro.dataDoVencimento = this.data.getDataInclusao()
          registro.dataLancamento = this.data.getDataInclusao()
          registro.moedaDaFatura = "Real"
          registro.nrDocumentoReferencia = numDocumento
          registro.numeroDaParcela = this.formMeioPagamento.LIFOPA1[0].parcela.toString()
          registro.status = "SDO0056"
          registro.numeroDocumento = '0000'
          registro.valorTotal = this.formMeioPagamento.LIFOPA1[0].valor / this.formMeioPagamento.LIFOPA1[0].parcela
          registro.codigoEmpresa = sessao.codigoEmpresa
          registro.criadoPor = sessao.cpf

          const parcelas = this.formMeioPagamento.LIFOPA1[0].parcela
          for (let i = 0; i < parcelas; i++) {
            console.log('sucesso credito', i)
            await this.service.postFaturaDeOperadoraCartao(registro).toPromise()
              .then(sucesso => {
                console.log('suc', sucesso)
                this.loadingDoc.creditoCont = this.loadingDoc.creditoCont + 1
              })
              .catch(error => console.log('erro credito', registro, error))
          }
          this.loadingDoc.credito = false
          return
        }
        if (this.dadosConvenio.ativo) {
          this.loadingDoc.tss = true
          this.loadingDoc.convenio = true
          let cabecalho = this.dadosConvenio.cabecalho
          cabecalho.nrDocumentoReferencia = numDocumento
          let item: FichaGuiaConsultaTissItem[] = this.dadosConvenio.item
          this.service.postFichaGuiaConsultaTissCabecalho(cabecalho).toPromise()
            .then(async (res) => {
              console.log('rodrigo', res[0].key, item)
              for (let i = 0; i <= item.length; i++) {
                item[i - 1].numeroDocumento = res[0].key
                await this.service.postFichaGuiaConsultaTissItem(item[i - 1])
                  .toPromise()
                  .then(() => { this.loadingDoc.convenioCont = this.loadingDoc.convenioCont + 1 })
                  .catch(error => {
                    console.log('Falha ao cadastrar TISS ITEM', error);
                    this.mensagem.creat('Falha ao cadastrar TISS ITEM', { erro: true })
                  })
              }
              console.log('this.loadingDoc.convenioCont', this.loadingDoc.convenioCont)
              this.loadingDoc.convenio = false
              // setTimeout(() => this.confirm.emit(true), 2000)
            })
            .catch(error => {
              console.log('Falha ao cadastrar TISS', error);
              this.mensagem.creat('Falha ao cadastrar TISS', { erro: true })
            })
          return
        } else {
          this.loadingDoc.convenio = false
        }
        if (this.dadosConvenio.tuss) {
          // let tuss = new TabelaTUSS
          // this.service
        }


      })
      .catch(erros => { console.log(erros) })


  }
  insertOperadora(event) {
    let get = this.listOperadoras.filter(value => value.id == event)[0]
    this.operadora = get
  }

  encaminharSalaEspera() {
    this.confirm.emit(true)
  }


}
