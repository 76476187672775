import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthRouterService implements CanActivate {

  constructor() { 
  }

  canActivate(){
    if (sessionStorage.getItem('tokken')) {
      return true
    } else {
      return false
    }
  }
}
