import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AgendaConfigForm, HorariosForm, AgendaFechadaForm, AgendaFeriadosForm, AgendaHorarioDiferenteForm, AgendaForm } from '../agenda/model';
import { AgendaService } from 'src/app/service/agenda.service';
import { ToastrService } from 'ngx-toastr';
import { FullCalendarComponent } from '@fullcalendar/angular';
import bootstrapPlugin, { BootstrapTheme } from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { async } from '@angular/core/testing';
import { SessaoUser } from 'src/app/user.reg';
import { ListarMedicos } from 'src/app/service/listar-medicos.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { EventCalender } from 'src/app/calendario/model';
const sessao = new SessaoUser
@Component({
  selector: 'app-agenda-config',
  templateUrl: './agenda-config.component.html',
  styleUrls: ['./agenda-config.component.scss']
})
export class AgendaConfigComponent implements OnInit {
  @Output('close') close = new EventEmitter
  @Input('getHorarios') getHorarios: HorariosForm[] = []
  @Input('agenda') registroAgenda: Array<AgendaForm>
  @Input('medicos') medicos: ListarMedicos[] = []
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;

  displayedColumns: string[] = ['detalhe', 'data', 'de', 'ate', 'diaTodo'];
  dataSource

  calendarPlugins = [dayGridPlugin, bootstrapPlugin];
  agendaConfigForm: FormGroup;
  id: string
  companyId: string;
  listarHorarios: { h: string; m: string; }[];
  loading: boolean;
  contHorarios: number;
  erroConfig: boolean;
  configAgenda: { configEmpresa?: boolean, medico?: string, registro?: AgendaConfigForm }[] = []
  validaHS: boolean;
  validaHT: boolean;
  editar: boolean;
  listFechaAgenda: Array<AgendaFechadaForm> = []
  formFecharAgenda: FormGroup
  listHorarioDiferente: Array<AgendaHorarioDiferenteForm> = []
  formFeriados = new AgendaFeriadosForm
  regFeriados = []
  salvar: boolean;
  select_removeFechaAgenda: boolean;
  select_removeHorarioDiferente: boolean;
  listDelete_FecharAgenda = []
  listDelete_HorarioDiferente = []
  cpf: string;
  processo = {
    cadAgendaConfig: false,
    listAgenda: false,
    rangeTime: false,
    cadAF: false
  }
  viewCadFeriado: boolean;
  listDelete_Feriados = [];
  listFeriados = [];
  select_Feriados: boolean;

  viewCadFecharAgenda: boolean
  viewConfimDeleteFecharAgenda: boolean;
  viewConfimDeleteHorarioDiferente: boolean;
  id_deleteFecharAgenda: any
  id_deleteHorarioDiferente: any
  dataFeriados: any[];
  rangeMeses: { name: string; }[];
  i = 0
  formHorarioDiferente: AgendaHorarioDiferenteForm;
  viewCadHorarioDiferente: boolean;
  configAgendaOld = new AgendaConfigForm;
  horarios: { h: string; m: string; }[] = [];
  horariosEnd: { h: string; m: string; }[] = [];
  horariosAlmoco = { start: [], end: [] }
  validarFechamentoMensage: string;
  nomeEmpresa: string;
  medicoSelecionado = new ListarMedicos
  useConfigEmpresa = true;
  loadingReg: boolean;
  tipoSelectFechamento: any;
  configCalendario: { medico?: string, registro?: any[] }[] = []
  activeHoraiosFechados: { medico?: string; registro?: any[] }
  empresa: number;
  loadingSalve: boolean;
  feriados: any[] = [];
  viewConfirmSave: boolean;
  confirmExit: boolean;
  verificConfiEmpresa: boolean;


  constructor(
    private form: FormBuilder,
    private service: AgendaService,
    private mensagem: ToastrService,
    private _mensagem: MensagemService,
    private meses: BuscarDataService,
    private _foto: FiltrarDadosService) {
   

  }

  async ngOnInit() {
    this.nomeEmpresa = sessao.nameEmpresa
    this.horarios = this.service.listarHorarios('00:00', '23:00')
    await this.creatFormulario()
    await this.getConfigAgenda(sessao.codigoEmpresa, true)
    await this.buscarFeriados()
    if (this.medicos.length > 0) {
      this._foto.inserirFotoPerfil('cpfProfissional', this.medicos)
    }
    this.medicoSelecionado.cpfProfissional = sessao.codigoEmpresa.toString()

    this.selectMedic(true, this.medicoSelecionado)

    this.empresa = sessao.codigoEmpresa
    let getFeriados = await this.service.getFeriados(new Date().getFullYear())
    getFeriados = getFeriados.filter((r) => { return r.type != 'Dia Convencional' })
    this.feriados = getFeriados
  }

  async cadastrarConfigAgenda(dados: AgendaConfigForm, id?) {
    dados.codigoEmpresa = sessao.codigoEmpresa

    this.gerenciarProcesso()
    this.loading = true

    this.processo.listAgenda = true
    setTimeout(async () => {

      dados.ativo = true
      dados.id = id
      dados.almocoStart = dados.start
      dados.almocoEnd = dados.end
      if (id) {
        console.log('ALterar ', dados, id)
        await this.service.alterarConfigAgenda(dados).toPromise()
          .then(
            () => {
              //this.ngOnInit()
            }
          ).catch(
            erro => this.mensagem.error('Não foi possivel alterar configurações, STATUS : ' + erro.status)
          )
        this.processo.cadAgendaConfig = true
        return
      }


      await this.service.cadastrarConfigAgenda(dados).toPromise()
        .then(
          () => {
            this.processo.cadAgendaConfig = true
          }
        )
        .catch(
          () => console.log('config erro')
        )

    }, 2000)
    // await this.cadastrarFecharAgenda()
    // await this.cadastrarHorarioDiferente()
  }

  changeHorariosFechado(registro) {
    let cpfMedico = this.medicoSelecionado.cpfProfissional
    // if (this.useConfigEmpresa) {
    //   cpfMedico = sessao.codigoEmpresa.toString()
    // }
    let getIndex = this.configCalendario.findIndex(value => value.medico == cpfMedico)
    if (getIndex < 0) {
      this.configCalendario.push({ medico: cpfMedico, registro: registro })
    } else {
      this.configCalendario[getIndex].registro = registro
    }
    console.log('changeHorariosFechado', this.configCalendario)
  }

  async getConfigAgenda(cpfMedico, configEmpresa?, noDisabled?) {

    this.loadingReg = true
    let getRegistro

    if (configEmpresa) {
      getRegistro = await this.service.getConfigMedico(sessao.codigoEmpresa.toString())
      if (getRegistro.id) {

        this.verificConfiEmpresa = true
      } else {
        this.verificConfiEmpresa = false
      }
      console.log('verificConfiEmpresa', this.verificConfiEmpresa, getRegistro)

      // delete getRegistro.id
    } else {
      getRegistro = await this.service.getConfigMedico(cpfMedico)
    }

    let getIndex = this.configAgenda.findIndex(value => value.medico == cpfMedico)

    if (getIndex < 0) {
      this.configAgenda.push({ configEmpresa: configEmpresa ? true : false, medico: cpfMedico, registro: getRegistro })
    } else {
      this.configAgenda[getIndex].registro = getRegistro
      this.configAgenda[getIndex].configEmpresa = configEmpresa ? true : false
    }

    this.agendaConfigForm.enable()
    this.agendaConfigForm.reset()
    this.agendaConfigForm.patchValue(getRegistro)
    if (!noDisabled) {
      this.agendaConfigForm.disable()
    }
    if (getRegistro.id) {
      this.getHorariosEnd(getRegistro.timeStart)
    }
    this.loadingReg = false



  }

  async buscarFeriados() {
    this.service.getFeriadosOld(new Date().getFullYear()).toPromise()
      .then(
        async (dates: any[]) => {
          let getData = []
          let getMeses = []
          dates = dates.filter((r) => { return r.type != 'Dia Convencional' })
          await dates.forEach((d) => {
            const m = parseInt(d.date.split('/')[1]);
            const mes = this.meses.getMeses(m - 1);
            const convert = `${d.date.split('/')[2]}-${d.date.split('/')[1]}-${d.date.split('/')[0]}`
            let date = {
              mes: mes.name,
              data: d.date,
              feriado: d.name,
              semana: this.meses.getSemanas(new Date(convert).getDay()),
              custom: d.custom ? true : false
            };
            let compare = getData.findIndex((x) => x.data == date.data);
            if (compare < 0) {
              getData.push(date);
            }
            let duplic = getMeses.findIndex((x) => x.name == mes.name);
            if (duplic < 0) {
              getMeses.push(mes);
            }
          })
          this.dataFeriados = getData
          this.rangeMeses = getMeses


        }
      )
    return

  }
  addFechamento() {
    let registro = this.formFecharAgenda.getRawValue()
    this.listFechaAgenda.push(registro)
  }
  getHorariosEnd(time) {
    if (!time) return
    let h = time.split(':')[0]
    let m = time.split(':')[1]
    let getIndex = this.horarios.findIndex(v => v.h == h && v.m == m)
    this.horariosEnd = this.horarios.filter((v, i) => i > getIndex)
    this.getHorariosAlmoco()
    this.getHorariosAlmoco(true)
  }
  getHorariosAlmoco(endAlmoco?) {
    let time = this.agendaConfigForm.get('timeEnd').value
    if (!time) return
    let h = time.split(':')[0]
    let m = time.split(':')[1]
    let getIndex = this.horariosEnd.findIndex(v => v.h == h && v.m == m)
    let getAlmoco = this.horariosEnd.filter((v, i) => i <= getIndex)

    this.horariosAlmoco.start = getAlmoco

    if (endAlmoco) {
      let time = this.agendaConfigForm.get('almocoTimeStart').value
      if (!time) return
      let h = time.split(':')[0]
      let m = time.split(':')[1]
      let getIndex = this.horariosEnd.findIndex(v => v.h == h && v.m == m)
      let getAlmoco = this.horariosEnd.filter((v, i) => i > getIndex)

      this.horariosAlmoco.end = getAlmoco
    }
  }
  async salvarConfiguracao() {

    let indexConfig = this.configAgenda.findIndex(v => v.medico == this.medicoSelecionado.cpfProfissional)
    this.configAgenda[indexConfig].registro = this.agendaConfigForm.getRawValue()
  
    this.loadingSalve = true
    try {
      let result = await this.service.cadastrarConfiguracaoAgenda(this.configAgenda, this.configCalendario)
      if (result.statusConfig && result.statusHorarios) {
        this._mensagem.creat('Configuração salva com sucesso', { sucesso: true })
      } else {
        this._mensagem.creat('Configuração salva com sucesso', { aviso: true })
      }

      this.ngOnInit()
    } catch {
      this._mensagem.creat('Não foi possivel realizar procedimento', { erro: true })

    }

    this.loadingSalve = false
    return
    let validar = this.validarFechamento()
    console.log(validar)
    if (!validar) return
    const id = this.formFecharAgenda.get('id').value
    const reg = this.formFecharAgenda.getRawValue()
    if (id) {
      this.service.alterarAgendaFechada(id, reg).toPromise().then((res) => {
        this.processo.cadAF = true; this.viewCadFecharAgenda = false
      })
    }
    else {
      if (this.medicoSelecionado.cpfProfissional) {
        reg.cpfMedico = this.medicoSelecionado.cpfProfissional
      }
      if (this.useConfigEmpresa) {
        reg.cpfMedico = sessao.codigoEmpresa.toString()
      }

      this.service.cadastrarAgendaFechada(reg).toPromise()
        .then((res) => {
          this.processo.cadAF = true; this.viewCadFecharAgenda = false
          this.listFechaAgenda.push(reg)
        })
        .catch(() => { this.processo.cadAF = false })
    }

  }
  async cadastrarHorarioDiferente() {
    const id = this.formHorarioDiferente.id
    const reg = this.formHorarioDiferente
    if (id) {
      this.service.alterarAgendaHorarioDiferente(id, reg).toPromise().then((res) => {
        this.processo.cadAF = true; this.viewCadHorarioDiferente = false

      })

    }
    else {
      reg.cpfMedico = this.cpf
      this.service.cadastrarAgendaHorarioDiferente(reg).toPromise()
        .then((res) => {
          this.processo.cadAF = true; this.viewCadHorarioDiferente = false
          this.listHorarioDiferente.push(reg)
        })
        .catch(() => { this.processo.cadAF = false })
    }
  }
  // cadastrarFeriado() {
  //   this.formFeriados.cpfMedico = sessionStorage.getItem('cpf')
  //   this.service.cadastrarAgendaFeriados(this.formFeriados).toPromise()
  //     .then(
  //       (reg) => { this.buscarFeriados(); this.viewCadFeriado = false }
  //     )
  // }

  async buscarListHorarioDiferente(cpfMedico) {
    this.service.listarAgendaHorarioDiferente().toPromise()
      .then(
        (reg: Array<any>) => {
          let filtro = reg.filter((reg) => { return reg.cpfMedico == cpfMedico && reg.codigoEmpresa == sessao.codigoEmpresa && reg.status == 'SDO0150' })
          filtro.forEach((reg, i) => {
            filtro[i].index = i
          });
          if (filtro.length > 0) {
            let registro = []
            filtro.forEach((reg) => {
              reg.id_doc = reg.id
              registro.push(reg)
            })
            this.listHorarioDiferente.shift()
            this.listHorarioDiferente = registro.sort((a, b) => {
              let A = a.start.split('-').join('')
              let B = b.start.split('-').join('')
              return parseInt(A) - parseInt(B)
            })
            this.changeHorariosFechado(registro)
          }
        }
      )
  }
  validarHorario() {
    try {
      let hS = 0
      let mS = 0
      let hT = 0
      let mT = 0
      // hS = parseInt(this.configAgenda.timeStart.substr(0, 2))
      // mS = parseInt(this.configAgenda.timeStart.substr(3, 2))
      // hT = parseInt(this.configAgenda.timeEnd.substr(0, 2))
      // mT = parseInt(this.configAgenda.timeEnd.substr(3, 2))
      if (hS > 24 || mS > 59) {
        console.log('Invalido 0')
        this.validaHS = false
        return
      } else {
        this.validaHS = true

      }
      if (hT > 24 || mT > 59) {
        console.log('Invalido 1')
        this.validaHT = false
        return
      } else {
        this.validaHT = true
      }
      if (hS > hT) {
        // console.log('Invalido 2 ' + 'hs :' + hS + '  hT : ' + hT)
        this.validaHT = false
        return
      } else {
        this.validaHT = true
      }
      if (hT < hS) {
        console.log('Invalido 3')
        this.validaHT = false
        return
      } else {
        this.validaHT = true
      }
      // console.log(hS + '-' + mS + '-' + hT + '-' + mT)
    }
    catch {
      return
    }
  }
  validarFechamento() {
    let registro = this.formFecharAgenda.value
    if (!registro.start) return false
    if (!registro.timeStart) return false
    if (!registro.end) return false
    if (!registro.timeEnd) return false
    if (!registro.localDeTrabalho) return false
    let response
    function getDaysArray(start, end) {
      for (var arr = [], dt = new Date(start); dt < end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toISOString().slice(0, 10));
      }
      return arr;
    };
    const start = registro.start
    const end = registro.end
    let getDatas = getDaysArray(new Date(start), new Date(end))

    console.log(getDatas)
    let getConsultas = this.registroAgenda.map((h) => h.date)
    let datas = []
    getDatas.forEach(d => {
      let v = getConsultas.findIndex((x) => x == d)
      if (v > 0) {
        datas.push(d)
      }
    });

    if (datas.length > 0) {
      this.validarFechamentoMensage = 'consultas agendadas nesse período'
      response = false
    } else {
      response = true
    }
    return response

  }
  async selectMedic(useConfigEmpresa: boolean, medico?: ListarMedicos) {

    if (!this.verificConfiEmpresa) {
      this._mensagem.creat('Para configurar à agenda de um médico é necessario primeiro configurar à agenda da empresa', { aviso: true })
      return
    }
    console.log('medico', useConfigEmpresa, medico)
    // if (confirm) {
    //   this.viewConfirmSave = true
    //   return
    // }
    this.useConfigEmpresa = useConfigEmpresa
    this.medicoSelecionado = medico
    if (useConfigEmpresa) {
      await this.getConfigAgenda(sessao.codigoEmpresa, true, this.agendaConfigForm.enabled)
    } else {
      await this.getConfigAgenda(this.medicoSelecionado.cpfProfissional, false)
    }
  }

  selectEmpresa() {
    this.medicoSelecionado = new ListarMedicos
    this.medicoSelecionado.cpfProfissional = sessao.codigoEmpresa.toString()
    this.selectMedic(false, this.medicoSelecionado)
  }
  testando(t) {
    console.log(t)
  }
  useConfiEmpresa(use: boolean, med?: ListarMedicos) {

    this.useConfigEmpresa = use
    med.userConfigEmpresa = use
    this.getConfigAgenda(this.medicoSelecionado.cpfProfissional, use, true)
    if (use) {
      this.selectMedic(true, med)
    }
    //  else {
    //   this.selectMedic(true, false, med)
    // }
    this.activeHoraiosFechados = this.configCalendario.filter(value => value.medico == sessao.codigoEmpresa.toString())[0]


  }

  creatFormulario() {
    this.agendaConfigForm = this.form.group({
      id: '',
      status: '',
      criadoPor: '',
      codigoEmpresa: sessao.codigoEmpresa,
      userConfigEmpresa: false,
      // end: new FormControl('', [Validators.required, Validators.minLength(10)]),
      // start: new FormControl('', [Validators.required, Validators.minLength(10)]),
      timeStart: new FormControl('', Validators.required),
      timeEnd: new FormControl('', Validators.required),
      almocoStart: new FormControl('', Validators.required),
      almocoEnd: new FormControl('', Validators.required),
      almocoTimeStart: new FormControl('', Validators.required),
      almocoTimeEnd: new FormControl('', Validators.required)
    })
    this.agendaConfigForm.disable()
    this.formFecharAgenda = this.form.group({
      id: '',
      cpfMedico: '',
      codigoEmpresa: 0,
      localDeTrabalho: '',
      start: new FormControl(''),
      end: new FormControl(''),
      timeStart: new FormControl(''),
      timeEnd: new FormControl(''),
      criadoPor: sessao.cpf
    })
  }
  tipoFechamento(tipo) {
    this.tipoSelectFechamento = tipo
    switch (tipo) {
      case 'horario':
        break
      case 'dia':
        break
      case 'semana':
        break
      case 'mes':
        break
    }
  }
  addFechaAgenda() {
    // const index = this.listFechaAgenda.length
    // this.listFechaAgenda.push({
    //   index: index,
    //   codigoEmpresa: sessao.codigoEmpresa,
    //   localDeTrabalho: '',
    //   start: '',
    //   end: '',
    //   timeStart: '',
    //   timeEnd: '',
    //   cpfMedico: this.cpf
    // })
  }
  // removeFechaAgenda() {
  //   const index = this.listFechaAgenda.length - 1
  //   if (index > 0) {
  //     this.listFechaAgenda.splice(index)
  //   }
  // }
  addHorarioDiferente() {
    const index = this.listHorarioDiferente.length

    // if (index > 2) return
    this.listHorarioDiferente.push({
      index: index,
      cnpjClinica: '',
      localDeTrabalho: '',
      start: '',
      end: '',
      timeStart: '',
      timeEnd: '',
      cpfMedico: this.cpf
    })
    console.log(this.listHorarioDiferente)
  }
  removeHorarioDiferente() {
    const index = this.listHorarioDiferente.length - 1
    if (index > 0) {
      this.listHorarioDiferente.splice(index)
    }
  }
  addFeriado() {
    this.viewCadFeriado = true
  }


  exit(confirm?: boolean) {
    if (confirm) {
      this.confirmExit = true
      return
    }
    this.confirmExit = false
    this.close.emit()
  }

  push_removeFecharAgenda(ref, index) {
    if (ref) {
      this.listDelete_FecharAgenda.push(this.listFechaAgenda[index])
    }
    else {
      this.listDelete_FecharAgenda.splice(index)
    }
    console.log(this.listDelete_FecharAgenda)
  }

  async delete_removeFecharAgenda(id) {
    if (!id) return
    // this.service.excluirAgendaFechada(id).toPromise().then((res) => this.buscarListFecharAgenda(this.medicoSelecionado.cpfProfissional))
    return

  }
  push_removeHorarioDiferente(ref, index) {
    if (ref) {
      this.listDelete_HorarioDiferente.push(this.listHorarioDiferente[index])
    }
    else {
      this.listDelete_HorarioDiferente.splice(index, 1)
    }
    console.log(this.listDelete_HorarioDiferente)
  }
  delete_removeHorarioDiferente(id) {
    if (!id) return
    this.service.excluirAgendaHorarioDiferente(id).toPromise().then((res) => this.buscarListHorarioDiferente(this.medicoSelecionado.cpfProfissional))
    return

    if (this.listDelete_HorarioDiferente.length > 0) {
      this.listDelete_HorarioDiferente.forEach((element, i) => {
        const getIndex = this.listHorarioDiferente.filter((r) => { return r.index == element.index }).map((i) => i.index)
        const index = getIndex[0]
        this.listHorarioDiferente.splice(index, 1)
        // this.service.excluirAgendaHorarioDiferente(element.id_doc).toPromise().then((res) => { this.buscarListHorarioDiferente() })
      });
      this.listDelete_HorarioDiferente.splice(0)
      this.select_removeHorarioDiferente = false
    }
  }
  push_removeFeriados(ref, reg, index) {
    if (ref) {
      this.listDelete_Feriados.push(reg)
    }
    else {
      const getIndex = this.listDelete_Feriados.filter((r) => { return r.index == index }).map((i) => i.index)
      this.listDelete_Feriados.splice(getIndex[0])
    }
    console.log(this.listDelete_Feriados)
  }

  // async delete_removeFeriados() {

  //   if (this.listDelete_Feriados.length > 0) {
  //     await this.listDelete_Feriados.forEach((element, i) => {
  //       const getIndex = this.listFeriados.filter((r) => { return r.index == element.index }).map((i) => i.index)
  //       const index = getIndex[0]
  //       this.listFeriados.splice(index, 1)
  //       this.service.excluirAgendaFeriados(element.id_doc).toPromise().then((res) => { this.buscarFeriados() })
  //     });
  //     this.listDelete_Feriados.splice(0)
  //     this.select_Feriados = false
  //   }
  // }

  ViewCadFecharAgenda() {
    this.viewCadFecharAgenda = true
    this.formFecharAgenda.reset()
  }

  ViewCadHorarioDiferente() {
    this.viewCadHorarioDiferente = true
    this.formHorarioDiferente = new AgendaHorarioDiferenteForm
  }

  _valueFechamento(control) {
    return this.formFecharAgenda.get(control).value
  }

  _validPeriodo() {
    try {
      if (this.agendaConfigForm.controls['start'].valid && this.agendaConfigForm.controls['end'].valid) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }

  gerenciarProcesso() {
    this.processo.cadAF = false
    this.processo.cadAgendaConfig = false
    this.processo.rangeTime = false
    let verificar = setInterval(() => {
      if (this.processo.cadAF && this.processo.cadAgendaConfig && this.processo.rangeTime) {
        this.loading = false
        clearInterval(verificar)
      }
    }, 1000)
    verificar
  }

}
