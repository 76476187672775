import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { FaturaContasAPagar, FaturaDeMedico, GS_AM_RECMAT_CAB, GS_AM_RECMAT_ITM } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { TokenDeAcessoService } from './token-de-acesso.service';

@Injectable({
  providedIn: 'root'
})
export class FluxoCaixaService {
  url = new environment().api
  api = 'FaturaContasAPagar/'
  api_faturaDeMedico = 'FaturaDeMedico/'
  api_recebimento_cab = 'GS_AM_RECMAT_CAB/'
  api_recebimento_item = 'GS_AM_RECMAT_ITM/'
  constructor(private http: HttpClient, private token: TokenDeAcessoService) { }

  async getFluxoCaixa(
    type: { receber?: boolean, saldo?: boolean, totalSaida?: boolean, totalEntradaSaida?: boolean, saldoFinal?: boolean },
    codigoEmpresa, mes?: number, ano?: number) {
    let result: FaturaContasAPagar[] = []

    await this.http.get<FaturaContasAPagar[]>(this.url + this.api + 'JoinFiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
      .then(async response => {
        let previssao = []
        let revisado = []
        if (type.saldo) {
          previssao = response.filter(v => v.status == 'SDO0121')
          revisado = response.filter(v => v.status == 'SDO0122')
        }



        let registro: FaturaContasAPagar[] = []
        revisado.forEach(item => {
          let get = previssao.filter(v => v.nrDocumentoReferencia == item.numeroDocumento)
          get.forEach(element => {
            element.realizado = item.valorTotalFatura
            element.previsao = element.valorTotalFatura
            registro.push(element)
          });
        });

        if (registro.length > 0) {

          result = registro
        }

      })

    if (mes >= 0) {
      result = result
        .filter(value => new Date(value.dataLancamento).getMonth() == mes)
        .filter(value => new Date(value.dataLancamento).getFullYear() == ano)


      result.forEach(async value => {
        console.log(value.dataLancamento, new Date(value.dataLancamento).getMonth(), mes)
        let empresa = await this.token.getEmpresasToken(value.codigoEmpresa)

        value.nomeEmpresa = empresa[0].nomeEmpresa
      });
      try {
        let item = new FaturaContasAPagar
        item.total_previsao = result.map(v => v.previsao).reduce((total, valor) => total + valor)
        item.total_realizado = result.map(v => v.realizado).reduce((total, valor) => total + valor)
        item.total = item.total_previsao + item.total_realizado
        console.log('RODRIGO', result)
        result.push(item)
      } catch {

      }


      // let saldo
      // let saida
      // let entrada
      // let saldoAc_saida
      // let saldoAc_entrada

      // try {
      //   saldo = result.map(v => v.montanteSaldo ? v.montanteSaldo : 0).reduce((total, valor) => total + valor)
      //   saida = result.map(v => v.valorTotalFatura ? v.valorTotalFatura : 0).reduce((total, valor) => total + valor)
      //   entrada = result.map(v => v.valorTotalFaturaEntrada ? v.valorTotalFaturaEntrada : 0).reduce((total, valor) => total + valor)
      //   saldoAc_saida = saldo
      //   saldoAc_entrada = saldo
      //   result.forEach((value, i) => {
      //     let valor = value.valorTotalFatura ? value.valorTotalFatura : 0
      //     let total = saldoAc_saida + valor
      //     saldoAc_saida = total
      //     if (value.valorTotalFatura) {
      //       value.saldoAc = saldoAc_saida
      //     }
      //   })
      //   result.forEach((value, i) => {
      //     let valor = value.valorTotalFaturaEntrada ? value.valorTotalFaturaEntrada : 0
      //     let total = saldoAc_saida + valor
      //     saldoAc_saida = total
      //     if (value.valorTotalFaturaEntrada) {
      //       value.saldoAc = saldoAc_saida
      //     }
      //   })

      // } catch {
      //   saldo = 0
      //   saida = 0
      //   entrada = 0
      // }

      // result.forEach(v => { v.saldo = saldo })
      // let total = new FaturaContasAPagar
      // total.total = saida + entrada
      // total.saida = saida
      // total.entrada = entrada
      // result.push(total)

    }

    return result

  }
  async getContasApagar(codigoEmpresa) {
    let result: FaturaContasAPagar[] = []
    await this.http.get<FaturaContasAPagar[]>(this.url + this.api + 'JoinFiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
      .then(res => {
        result = res
      })
    return result
  }

  async getFaturaMedica(codigoEmpresa) {
    let result: FaturaDeMedico[] = []
    await this.http.get<FaturaDeMedico[]>(this.url + this.api_faturaDeMedico).toPromise()
      .then(res => {
        let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)
        result = filter
      })
    return result
  }
  async getRecebimentoMaterial(codigoEmpresa) {
    let result: GS_AM_RECMAT_CAB[] = []

    await this.http.get<GS_AM_RECMAT_CAB[]>(this.url + this.api_recebimento_cab).toPromise()
      .then(async res => {
        let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)
        let cab = filter
        await this.http.get<GS_AM_RECMAT_ITM[]>(this.url + this.api_recebimento_item).toPromise()
          .then(registro => {
            cab.forEach(item => {
              let filter = registro.filter(value => value.numeroDocumento == item.numeroDocumento)
              if (filter.length > 0) {
                filter.forEach(element => {
                  let reg = item
                  reg.valorTotalFatura = element.valorTotalItem
                  reg.codigoContaContabil = element.contaD ? element.contaD : element.contaC
                  result.push(reg)
                });
              }
            });
          })
      })

    return result

  }
}
