import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { BuscarDataService } from './buscar-data.service';
import { dadosRegrasDeImposto } from '../configuracao-inicial/regras-de-imposto/model';
import { SessaoUser } from '../user.reg';
import { ConteudoDeDadosService } from './conteudo-de-dados.service';

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})
export class RegrasDeImpostoService {
    url = new environment().api
    api = "RegraDeImposto/"
    JsonPais = './assets/json/json-paises.json'

    constructor(private http: HttpClient, private data: BuscarDataService, private _conteudo: ConteudoDeDadosService) { }

    listar() {
        return this.http.get<dadosRegrasDeImposto[]>(this.url + this.api)
    }

    enviar(registro: dadosRegrasDeImposto) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: dadosRegrasDeImposto) {
        return this.http.put(this.url + this.api + `${registro.codigoImposto}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: dadosRegrasDeImposto, ativo) {
        if (ativo) registro.status = 'SDA0001'
        else registro.status = 'SDA0002'

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })
        return resposta
    }

    getPaises(): Observable<any> {
        return this.http.get(this.JsonPais)
    }


    //// Dados antigos ↓ 



    getDadosRegraDeImposto(): Observable<Object> {
        return this.http.get(this.url + this.api + "Join")
    }
    async getPlanoDeContas(codigoEmpresa) {
        let result = []
        await this.http.get<PlanoContaContabeisModel[]>(this.url + 'PlanoContaContabeis/FiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
            .then(res => {
                if (res.length > 0) {
                    result = res
                } else {
                    result = []
                }
            })
            .catch(error => console.log('erro', error))
        return result
    }

    getRegraDeImpostoID(id: any): Observable<Object> {
        return this.http.get(this.url + this.api + id)
    }

    getRegraDeImpostoIDCodigoReferencia(id: any): Observable<Object> {
        return this.http.get(this.url + this.api + "JoinPorId/" + id)
    }

    async getRegraDeImpostoCodigoEmpresa(codigoEmpresa: number) {
        let result: dadosRegrasDeImposto[] = []
        await this.http.get<dadosRegrasDeImposto[]>(this.url + this.api).toPromise()
            .then(async res => {
                result = res.filter(value => value.codigoEmpresa == codigoEmpresa)
                if (result.length > 0) {
                    for (let i = 0; i < result.length; i++) {
                        let item = result[i]
                        let descImposto = await this._conteudo.get('CADIMP', true, item.codigoReferencia)
                        let descJurisdicao = await this._conteudo.get('JURIMP', true, item.jurisdicao)
                        item.descImposto = descImposto.toString()
                        item.descJurisdicao = descJurisdicao.toString()
                    }
                }
            })
            .catch(error => console.log('erro', error))
        return result
    }

    PostCadastrarRegraDeImposto(dados: Object): Observable<Object> {
        return this.http.post(this.url + this.api, dados)
    }

    DeleteRegraDeImposto(id: any): Observable<Object> {
        return this.http.delete(this.url + this.api + id)
    }

    AlterarRegrasDeImposto(id: any, userId, data?, dados?): Observable<Object> {
        return this.http.put(`${this.url}${this.api}${id}/${userId}/${data}`, dados)
    }


}


