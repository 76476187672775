import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SalaEsperaForm, ConsultaForm } from '../sala-espera/model';
import { SalaEsperaService } from 'src/app/service/sala-espera.service';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AtendimentoClinicoService } from 'src/app/service/atendimento-clinico.service';
import { ActivatedRoute } from '@angular/router';
import { ReceituarioDoPaciente } from '../conduta-medica/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';

@Component({
  selector: 'app-encerrar-atendimento',
  templateUrl: './encerrar-atendimento.component.html',
  styleUrls: ['./encerrar-atendimento.component.scss']
})
export class EncerrarAtendimentoComponent implements OnInit {
  @ViewChild('body', { static: true }) body: ElementRef
  @Input() paciente: SalaEsperaForm
  tipoEmitir
  tipoSolicitar
  consulta = new ConsultaForm
  id: string;
  pacienteId: string;
  return: any;
  receituario = new ReceituarioDoPaciente
  constructor(
    private serviceSalaEspera: SalaEsperaService,
    private mensagem: ToastrService,
    private serviceAtendimento: AtendimentoClinicoService,
    private location: Location,
    private url: ActivatedRoute,
    private hora: BuscarDataService) {
    this.url.url.subscribe(async reg => {
      console.log('Filtro :' + reg[1].path)
      this.id = reg[1].path
      this.pacienteId = reg[2].path
    });
  }

  ngOnInit() {
    console.log('Encerrar : ' + JSON.stringify(this.paciente))
  }
  descerScroll() {
    setTimeout(() => this.body.nativeElement.scrollTop = 200, 400)
  }
  async encerarAtendimento() {
    localStorage.removeItem('videoChamda')
    this.receituario = JSON.parse(localStorage.getItem('condutaTerapeutica'))
    if (!this.receituario) {
      this.receituario = new ReceituarioDoPaciente
    }
    console.log('Conduta médica : ' + JSON.stringify(this.receituario))
    this.consulta.condutaTerapeutica = await
      `${this.receituario.nomeComercial ? this.receituario.nomeComercial : ''} de 
     ${this.receituario.frequencia ? this.receituario.frequencia : '0'}/${this.receituario.frequencia ? this.receituario.frequencia : ''} 
     ${this.receituario.periodo ? this.receituario.periodo : ''} `
    this.consulta.paciente = this.paciente.paciente
    this.consulta.sintomas = localStorage.getItem('sintoma')
    this.consulta.diagnostico = localStorage.getItem('diagnostico')
    this.consulta.exames = localStorage.getItem('exame')
    this.consulta.pacienteId = this.pacienteId
    this.consulta.usuarioId = this.id
    this.consulta.companyId = this.paciente.companyId
    this.consulta.dataConsulta = new Date().toISOString()
    await this.serviceSalaEspera.cadastrarConsultas(this.consulta).toPromise()
      .then()

    this.paciente.processo = 3
    this.paciente.fimAtendimento = this.hora.horaAgora()
    await this.serviceSalaEspera.alterarSalaEspera(this.paciente).toPromise()
      .then(
        () => {
          this.mensagem.info(`Atendimento finalizado`)
          this.location.back();
        }
      )
  }

}
