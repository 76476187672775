import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { error } from 'protractor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  chave = new environment().tokenRecaptchat
  constructor(private http: HttpClient, private recaptchaV3Service: ReCaptchaV3Service) { }

  async validToken() {
    let result
    this.recaptchaV3Service.execute('importantAction')
      .subscribe(async (token) => {
        await this.http.post('https://www.google.com/recaptcha/api/siteverify', { secret: this.chave, response: token }).toPromise()
          .then(res => result = res)
          .catch(error => {
            console.log(error)
          })
      });
  }
  async teste(token){
    let result
    await this.http.post('https://www.google.com/recaptcha/api/siteverify', { secret: this.chave, response: token }).toPromise()
    .then(res => result = res)
    .catch(error => {
      console.log(error)
    })
  }
}
