import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FaturaDeAssinatura } from './faturaAssinatura.model';
import { environment } from 'src/environments/environment';

const url = `${new environment().api}FaturaDeAssinatura`;
@Injectable({
  providedIn: 'root'
})
export class FaturaDeAssinaturaService {

  constructor(
    private http: HttpClient,
  ) {}

  gerarFatura(faturaDeAssinatura:FaturaDeAssinatura){
    this.http.post(url, faturaDeAssinatura).toPromise()
    .then(() => console.log('Fatura gerada com sucesso'))
    .catch((err: HttpErrorResponse) => console.error(`Erro: ${err.status}, ${err.statusText}`, err.message))
  }

  async listarFaturas(){
    let faturas:FaturaDeAssinatura[];
    await this.http.get<FaturaDeAssinatura[]>(url, {observe:'body'}).toPromise()
    .then(res => faturas = res)
    .catch((err: HttpErrorResponse) => console.error(`Erro: ${err.status}, ${err.statusText}`, err.message))
  }
}
