import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/login.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../service/usuario.service';
import { DiarioDeAcessoService } from '../service/diario-de-acesso/diario-de-acesso.service';
const url = new environment().api
@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {
  reg = {
    usuario: '',
    password: ''
  }
  loading: boolean;
  logado: boolean;
  noAssinante: boolean;
  cpf: any;
  userName: any;
  viewEmpresa: boolean;
  erroEmail: boolean;
  emailEnviado: boolean;
  password: boolean
  empresas = [];
  e
  selectEmpresa: number;
  constructor(
    private service: LoginService,
    private _user: UsuarioService,
    private _sessao: DiarioDeAcessoService,
    private http: HttpClient,
    private router: Router,
    private toast: ToastrService) { }

  ngOnInit() {
  }
  async login() {
    this.loading = true
    this._user.get().toPromise().then(
      async (reg: Array<any>) => {
        const cpf = this.reg.usuario
        let verificar = reg.filter((x) => {
          if (x.cpf == cpf) {
            if (x.tipoUsuario == 'TIPUSU1' || x.tipoUsuario == 'TIPUSU2' || x.tipoUsuario == 'TIPUSU3' || x.tipoUsuario == 'TIPUSU4') {
              return x
            }
          }
        })
        if (verificar.length > 0) {
          await this.http.post(`${url}v1/entrar/PRO`, this.reg).toPromise()
            .then(
              async (dados: any) => {
                console.log(dados)
                if (dados.data.statusUsuario == 'Indefinido') {
                }
                else {
                  this.noAssinante = false
                }
                if (dados.data.emailConfirmado) {
                  this.erroEmail = false
                //  await this._sessao.criarSessao({ cpf: this.reg.usuario, localDeSessao: 'MASTER' })
                  this.cpf = dados.data.userToken.usuario
                  this.userName = dados.data.userName
                  this.empresas = dados.data.tokenDeAcesso.filter((res) => { return res.statusHabilitacao == "SDO0096" })
                  let removeDuplicidade = []
                  console.log(dados.data.tokenDeAcesso)
                  this.empresas.forEach((empresa) => {
                    let valid = removeDuplicidade.findIndex((reg) => { return reg.empresa == empresa.empresa }) < 0
                    if (valid) {
                      removeDuplicidade.push(empresa)
                    }
                  })
                  this.empresas = removeDuplicidade
                  this.viewEmpresa = true
                  await sessionStorage.setItem('listEmpresa', JSON.stringify(this.empresas))
                  await sessionStorage.setItem('tokken', dados.data.userToken.id)
                  await sessionStorage.setItem('companyId', dados.data.userCompanyId)
                  await sessionStorage.setItem('crm', dados.data.crm)
                  await sessionStorage.setItem('cnpj', dados.data.cnpj)
                  await sessionStorage.setItem('formaTratamento', dados.data.formaTratamento)
                  await sessionStorage.setItem('unidadeFederativa', dados.data.unidadeFederativa)
                  await sessionStorage.setItem('especialidade', dados.data.especialidade)

                  if (dados.data.userToken.cpf) {
                    await sessionStorage.setItem('cpf', dados.data.userToken.cpf)
                  }
                  if (dados.data.userToken.usuario) {
                    await sessionStorage.setItem('cpf', dados.data.userToken.usuario)
                  }
                  await sessionStorage.setItem('userName', dados.data.userName)


                  if (sessionStorage.getItem('tokken')) {


                  }

                }
                else {
                  this.erroEmail = true
                  this.loading = false
                  console.log(`${url}SendEmail/${dados.data.emailUsuario}/${dados.data.userName}/${dados.data.userToken.usuario}`)
                  this.http.get(`${url}SendEmail/${dados.data.emailUsuario}/${dados.data.userName}/${dados.data.userToken.usuario}`).toPromise()
                    .then(
                      () => this.emailEnviado = true
                    ).catch(
                      () => this.toast.error('Email não enviado!')
                    )

                }

                // this.router.navigateByUrl('home')
              }
            ).catch(
              (erro) => {
                try {
                  this.toast.error(erro.error.errors[0])
                } catch {
                  console.log(erro)
                }
                this.loading = false
              }

            )
        } else {
          this.toast.error('usuario não possui permissão!')
          this.loading = false
        }
      }
    )

  }
  reset() {
    this.viewEmpresa = false
    this.loading = false
    this.reg.password = null
    this.reg.usuario = null
  }
  async go() {
    const codigoEmpresa = this.selectEmpresa
    sessionStorage.setItem('codEmpresa', codigoEmpresa.toString())
    const nameEmpresa = this.empresas.filter((res) => { return res.empresa == codigoEmpresa })[0]
    const codAssinante = this.empresas.filter((reg) => { return reg.empresa == codigoEmpresa }).map((name) => name.assinatura)[0]
    try {
      await sessionStorage.setItem('assinante', codAssinante.toString())
    } catch {
      await sessionStorage.setItem('assinante', '00')
    }
    sessionStorage.setItem('nomeEmpresa', nameEmpresa.nomeEmpresa)
    // await this._sessao.criarSessao({ cpf: this.reg.usuario, localDeSessao: 'MASTER' })
    window.open('#/home');
    this.loading = false
    this.reg.password = null
    this.reg.usuario = null
    this.viewEmpresa = false
  }

}
