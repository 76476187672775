export class FormContatos {
    id: string
    numeroDocumento?: string
    dataInclusaoDocumento?: any
    cpfMedicoConvidador: string
    nomeConvidador: string
    cpfConvidado: string
    tipoUsuario: string
    status: string
    nomeConvidado: string
    ativo?: boolean
    online?: boolean
}
export class FormConversaHistorico {
    id: string
    de: string
    data : string
    para: string
    ativo: true
    mensagem: string
    hora: string
    nome: string
}