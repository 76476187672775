import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, identity } from 'rxjs';
import { SessaoUser } from '../user.reg';
import { FiltrarDadosService } from './filtrar-dados.service';

const sessao = new SessaoUser
@Injectable({
    providedIn: 'root'
})
export class RegraConfirmacaoConsultaService {

    url = new environment().api
    empresa = "Companies/"
    profissao = "DadosProfissionais/"
    regraConfirmacaoConsulta = "RegraConfirmacaoConsulta/"

    constructor(private http: HttpClient, private filter: FiltrarDadosService) { }

    async get(codigoEmpresa?) {
        let result = []
        let empresas = JSON.parse(sessao.listEmpresas)
        await this.http.get<Array<any>>(this.url + this.regraConfirmacaoConsulta).toPromise()
            .then(res => {
                let resgistro = res.filter(value => value.status == "SCA0001")
                if (codigoEmpresa) {
                    result = resgistro.filter(value => value.codigoEmpresa == codigoEmpresa)
                } else {
                    result = this.filter.filterTodos(resgistro, empresas)
                }
                result.forEach(value => {
                    let nomeEmpresa = empresas.filter(v => v.empresa == value.codigoEmpresa).map(v => v.nomeEmpresa)[0]
                    value.nomeEmpresa = nomeEmpresa
                });
            })
        return result
    }
    async getID(id) {
        let result
        await this.http.get<Array<any>>(this.url + this.regraConfirmacaoConsulta + id).toPromise()
            .then(res => {
                result = res
            }).catch(error => {
                console.log('erro >' + this.url + this.regraConfirmacaoConsulta)
                result = {}
            })
        return result
    }

    put(id: string, dados) {
        delete dados.nomeEmpresa
        console.log('alter', JSON.stringify(dados))
        return this.http.put(this.url + this.regraConfirmacaoConsulta +`${id}/${sessao.sessao_id}/${new Date().toISOString()}`, dados)
    }
    delete(dados) {
        dados.status = "SCA0002"
        return this.http.put(this.url + this.regraConfirmacaoConsulta +`${dados.id}/${sessao.sessao_id}/${new Date().toISOString()}`, dados)
    }

    post(dados): Observable<Object> {
        dados.criadoPor = sessao.cpf
        dados.status = "SCA0001"
        return this.http.post(this.url + this.regraConfirmacaoConsulta, dados)
    }

}


