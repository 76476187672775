import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FaturaContasAPagar } from '../cadastro-fornecedor/CadastroFornecedorModels';
import { LancamentoLivroCaixaModel } from '../gestao-simplificada/lancamento-livro-caixa/lancamento-livro-caixa.model';
import { PlanoContaContabeisModel, SaldoContaContabil } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { FaturaDeMedico } from './fatura-medica/modal';
import { TokenDeAcessoService } from './token-de-acesso.service';

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class LancamentoLivroCaixaService {

    url = new environment().api
    api = "LancamentoCaixaManual/"
    api_planoDeContas = 'PlanoContaContabeis/'
    api_saldo = 'SaldoContaContabil/'
    api_contasApagar = 'FaturaContasAPagar/'
    api_faturaDeMedico = 'FaturaDeMedico/'

    constructor(private http: HttpClient, private data: BuscarDataService, private token: TokenDeAcessoService) { }

    listar() {
        return this.http.get<LancamentoLivroCaixaModel[]>(this.url + this.api)
    }
    async getSaldoContabio(codigoEmpresa, mes?: number, ano?: number) {
        let result: SaldoContaContabil[] = []
        await this.http.get<SaldoContaContabil[]>(this.url + this.api_saldo + 'JoinFiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
            .then(async registros => {
                // console.log('codigo', registros, codigoEmpresa)
                // registros.forEach(v => { v.valorTotalFatura = 0; v.valorTotalFaturaEntrada = 0 })
                if (registros.length > 0) {
                    result = registros
                }
            })

        await this.http.get<FaturaContasAPagar[]>(this.url + this.api_contasApagar + 'JoinFiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
            .then(async response => {
                response = response.filter(v => v.status == 'SDO0122')
                if (response.length > 0) {
                    response.forEach(value => {
                        let item = new SaldoContaContabil
                        item.descricao = value.descricao
                        item.valorTotalFatura = value.valorTotalFatura
                        item.dataDeLancamento = value.dataLancamento
                        item.codigoEmpresa = value.codigoEmpresa
                        result.push(item)
                    });
                }
            })
        await this.http.get<FaturaDeMedico[]>(this.url + this.api_faturaDeMedico).toPromise()
            .then(async response => {
                response = response.filter(v => v.status == 'SDO0125' && v.codigoEmpresa == codigoEmpresa)
                if (response.length > 0) {
                    response.forEach(value => {
                        let item = new SaldoContaContabil
                        item.descricao = 'Vendas'
                        item.valorTotalFaturaEntrada = value.valorTotalFatura
                        item.dataDeLancamento = value.dataLancamento
                        item.codigoEmpresa = value.codigoEmpresa
                        result.push(item)
                        console.log('FaturaDeMedico', item)
                    });

                }
            })

        if (mes >= 0) {
            result = result
                .filter(value => new Date(value.dataDeLancamento).getMonth() == mes)
                .filter(value => new Date(value.dataDeLancamento).getFullYear() == ano)

            result.forEach(async value => {
                console.log(value.dataDeLancamento, new Date(value.dataDeLancamento).getMonth(), mes)
                let empresa = await this.token.getEmpresasToken(value.codigoEmpresa)

                value.nomeEmpresa = empresa[0].nomeEmpresa
            });

            let saldo
            let saida
            let entrada
            let saldoAc_saida
            let saldoAc_entrada

            try {
                saldo = result.map(v => v.montanteSaldo ? v.montanteSaldo : 0).reduce((total, valor) => total + valor)
                saida = result.map(v => v.valorTotalFatura ? v.valorTotalFatura : 0).reduce((total, valor) => total + valor)
                entrada = result.map(v => v.valorTotalFaturaEntrada ? v.valorTotalFaturaEntrada : 0).reduce((total, valor) => total + valor)
                saldoAc_saida = saldo
                saldoAc_entrada = saldo
                result.forEach((value, i) => {
                    let valor = value.valorTotalFatura ? value.valorTotalFatura : 0
                    let total = saldoAc_saida + valor
                    saldoAc_saida = total
                    if (value.valorTotalFatura) {
                        value.saldoAc = saldoAc_saida
                    }
                })
                result.forEach((value, i) => {
                    let valor = value.valorTotalFaturaEntrada ? value.valorTotalFaturaEntrada : 0
                    let total = saldoAc_saida + valor
                    saldoAc_saida = total
                    if (value.valorTotalFaturaEntrada) {
                        value.saldoAc = saldoAc_saida
                    }
                })

            } catch {
                saldo = 0
                saida = 0
                entrada = 0
            }

            result.forEach(v => { v.saldo = saldo })
            let total = new SaldoContaContabil
            total.total = saida + entrada
            total.saida = saida
            total.entrada = entrada
            result.push(total)

        }

        return result

    }


    enviar(registro: LancamentoLivroCaixaModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: LancamentoLivroCaixaModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: LancamentoLivroCaixaModel, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        }
        else {
            registro.status = 'SDA0002'
        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }

}