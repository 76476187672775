import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormulariosService {

  constructor() { }

  formularioUsuario() {
    return [
      ///Dados Pessoais
      {
        cards: [
          { titulo: 'Dados Pessoais', grupo: 'dp' },
          { titulo: 'Contato', grupo: 'contato' },
          { titulo: 'Endereço', grupo: 'endereco' },
          { titulo: 'Dados de Cartão de Crédito', grupo: 'DBancario' },
          { titulo: 'Outros', grupo: 'outros' }
        ]
      },
      { grupo: 'dp', nome: 'nome', label: 'Nome :*', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'cpf', label: 'CPF :*', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'rg', label: 'RG :*', type: 'text', mask: '00.000.000-A', input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'nascimento', label: 'Data de Nascimento :*', type: 'text', mask: '00/00/0000', noMaskDrop: true, input: true, select: false, col: 3 },
      { grupo: 'dp', nome: 'genero', label: 'Sexo :*', type: 'text', mask: null, input: true, select: true, selecOp: [{ op: 'Masculino' }, { op: "Feminino" }], col: 3 },
      // { grupo: 'dp', nome: 'orientacao', label: 'Orientação Sexual :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Hétero' }, { op: 'Homoafetivo ' }, { op: 'Outros' }], col: 3 },
      { grupo: 'dp', nome: 'civil', label: 'Estado Civil :*', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Solteiro' }, { op: "Casado" }, { op: "Divorciado" }, { op: "Amasiado" }], col: 3 },
      { grupo: 'dp', nome: 'profissao', label: 'Profissão :*', type: 'text', mask: null, input: true, select: false, col: 3 },

      //Contato
      { grupo: 'contato', nome: 'email', label: 'Email :*', type: 'email', mask: null, input: true, select: false, col: 12 },

      { grupo: 'contato', nome: 'telefoneCel', label: 'Telefone Celular :*', type: 'text', mask: '(00) 00000-0000', input: true, select: false, col: 3 },
      { grupo: 'contato', nome: 'telefoneRes', label: 'Telefone Fixo :', type: 'text', mask: '(00) 00000-0000', input: true, select: false, col: 3 },
      { grupo: 'contato', nome: 'telefoneCom', label: 'Telefone Comercial *:', type: 'text', mask: '(00) 00000-0000', input: true, select: false, col: 3 },
      { grupo: 'contato', nome: 'ramal', label: 'Ramal :', type: 'text', mask: '(00) 0000-0000', noMaskDrop: true, input: true, select: false, col: 3 },

      //Endereço
      { grupo: 'endereco', nome: 'logradouro', label: 'Logradouro :', type: 'text', mask: null, input: true, select: false, col: 5 },
      { grupo: 'endereco', nome: 'numero', label: 'Numero :', type: 'text', mask: null, input: true, select: false, col: 2 },
      { grupo: 'endereco', nome: 'complemento', label: 'Complemento :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'cep', label: 'CEP :', type: 'text', mask: '00000-000', noMaskDrop: true, input: true, select: false, col: 2 },

      { grupo: 'endereco', nome: 'bairro', label: 'Bairro :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'cidade', label: 'Cidade :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'estado', label: 'Estado :', type: 'text', mask: "AA", input: true, select: false, col: 2 },

      //Dados Bancario
      { grupo: 'DBancario', nome: 'cartaoBandeira', label: 'Bandeira :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'cartaoNumero', label: 'Agencia :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'vencimento', label: 'Data de Vencimento :', type: 'text', mask: '00/0000', input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'seguranca', label: 'Cod. Segurança :', type: 'text', mask: '000', input: true, select: false, col: 3 },

      //Outros
      // { grupo: 'outros', nome: 'codnome', label: 'Apelido :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'outros', nome: 'pago', label: 'Status Pagamento :', type: 'text', mask: null, input: false, select: false, col: 3 },
      { grupo: 'outros', nome: 'assinante', label: 'Assinante :', type: 'text', mask: null, input: false, select: false, col: 3 },
      { grupo: 'outros', nome: 'status', label: 'Status :', type: 'text', mask: null, input: false, select: false, col: 3 },

    ]
  }
  formularioFichaMedica() {
    return [
      ///Dados Pessoais
      {
        cards: [
          { titulo: 'Deficiência', grupo: 'deficiencia' },
          { titulo: 'Alergia', grupo: 'alergia' },
          { titulo: 'Habítos', grupo: 'habitos' },
          { titulo: 'Medicação em uso', grupo: 'medicacao' },
          { titulo: 'Historico de doenças adquiridas', grupo: 'doencas' },
          { titulo: 'Tratamento Realizados', grupo: 'tratamento' },
          { titulo: 'Resultado Ultimos Exames', grupo: 'exame' },
          { titulo: 'Avaliações Periódicas ', grupo: 'periodica' },
          { titulo: 'Historico de Internações', grupo: 'internacao' },
          { titulo: 'Sinais Vitais', grupo: 'sinaisVitais' },
          { titulo: 'Dados de Saúde', grupo: 'dadosSaude' },
          { titulo: 'Doenças na familia', grupo: 'doencasFamilia' },
          { titulo: 'Lesões', grupo: 'lesoes' },
          { titulo: 'Cirurgias', grupo: 'cirurgias' }
        ]
      },
      { grupo: 'habitos', nome: 'fumante', label: 'Fumante :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
      { grupo: 'habitos', nome: 'drogas', label: 'Drogas :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
      { grupo: 'habitos', nome: 'alcoolica', label: 'Alcoólica :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
      { grupo: 'habitos', nome: 'fisica', label: 'Física :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },

      {
        grupo: 'doencas', col: 12,
        tabela: [
          { grupo: 'doencas', nome: 'doenca', label: 'Doença', type: 'text', mask: null, input: true, select: false, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
          { grupo: 'doencas', nome: 'periodo', label: 'Periodo', type: 'date', mask: null, input: true, select: false, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
        ]
      },
      {
        grupo: 'internacao', col: 12,
        tabela: [
          { grupo: 'internacao', nome: 'descricao', label: 'Descrição :', type: 'text', mask: null, input: true, select: false, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
          { grupo: 'internacao', nome: 'dataHistorico', label: 'Data :', type: 'Date', mask: '0000-00-00', input: true, select: false, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
          { grupo: 'internacao', nome: 'local', label: 'Local :', type: 'text', mask: null, input: true, select: false, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
          { grupo: 'internacao', nome: 'motivo', label: 'Motivo :', type: 'text', mask: null, input: true, select: false, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 3 },
        ]
      }

    ]
  }

  formularioDependente() {
    return [
      {
        cards: [
          { titulo: 'Dados Pessoais', grupo: 'dp' },
          { titulo: 'Complementar', grupo: 'complementar' },
          { titulo: 'Responsavel', grupo: 'responsavel' },
          { titulo: 'Contato', grupo: 'contato' },
          { titulo: 'Endereço', grupo: 'endereco' }
        ]
      },
      ///Dados Pessoais
      { grupo: 'dp', nome: 'nome', label: 'Nome :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'cpf', label: 'CPF :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'rg', label: 'RG :', type: 'text', mask: '00.000.000-00', input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'nascimento', label: 'Data de Nascimento :', type: 'text', mask: '00/00/0000', input: true, select: false, col: 3 },
      { grupo: 'dp', nome: 'sexo', label: 'Sexo :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Masculino' }, { op: "Feminino" }], col: 3 },
      { grupo: 'dp', nome: 'civil', label: 'Estado Civil :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Solteiro' }, { op: "Casado" }, { op: "Divorciado" }, { op: "Amasiado" }], col: 3 },
      { grupo: 'dp', nome: 'profissao', label: 'Profissão :*', type: 'text', mask: null, input: true, select: false, col: 3 },
      //Complementar 
      { grupo: 'complementar', nome: 'convenio', label: 'Convenio :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'complementar', nome: 'cor', label: 'Cor :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'complementar', nome: 'localNascimento', label: 'Cidade Natal :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'complementar', nome: 'peso', label: 'Peso :', type: 'text', mask: '0*,00', input: true, select: false, col: 3 },
      { grupo: 'complementar', nome: 'especial', label: 'Paciente especial ? :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'SIM' }, { op: 'NÃO' }], col: 3 },
      { grupo: 'complementar', nome: 'medico', label: 'Medico :', type: 'text', mask: null, input: true, select: false, col: 3 },

      //Responsaveis 
      { grupo: 'responsavel', nome: 'responsavel', label: 'Nome :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'responsavel', nome: 'rgResponsavel', label: 'RG :', type: 'text', mask: '00.000.000-A', input: true, select: false, col: 4 },
      { grupo: 'responsavel', nome: 'cpfResponsavel', label: 'CPF :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },

      { grupo: 'responsavel', nome: 'pai', label: 'Nome do Pai :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'responsavel', nome: 'mae', label: 'Nome da Mãe :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'responsavel', nome: 'atividadePai', label: 'Atividade do Pai :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'responsavel', nome: 'atividadeMae', label: 'Atividade da Mãe :', type: 'text', mask: null, input: true, select: false, col: 3 },


      //Contato
      { grupo: 'contato', nome: 'email', label: 'Email :', type: 'email', mask: null, input: true, select: false, col: 3 },

      { grupo: 'contato', nome: 'celular', label: 'Telefone Celular :', type: 'text', mask: '(00) 00000-0000', input: true, select: false, col: 3 },
      { grupo: 'contato', nome: 'telefone', label: 'Telefone Fixo :', type: 'text', mask: '(00) 00000-0000', input: true, select: false, col: 3 },
      { grupo: 'contato', nome: 'telefone2', label: 'Telefone Secundario :', type: 'text', mask: '(00) 00000-0000', input: true, select: false, col: 3 },

      //Endereço
      { grupo: 'endereco', nome: 'endereco', label: 'Logradouro :', type: 'text', mask: null, input: true, select: false, col: 5 },
      { grupo: 'endereco', nome: 'numero', label: 'Numero :', type: 'text', mask: null, input: true, select: false, col: 2 },
      { grupo: 'endereco', nome: 'cep', label: 'CEP :', type: 'text', mask: '00000-000', input: true, select: false, col: 2 },

      { grupo: 'endereco', nome: 'bairro', label: 'Bairro :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'cidade', label: 'Cidade :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'estado', label: 'Estado :', type: 'text', mask: "AA", input: true, select: false, col: 2 },
    ]
  }
  formularioPreNatal() {
    return [
      ///Dados Pessoais
      { grupo: 'dp', nome: 'gestante', label: 'Gestante :', type: 'text', mask: null, input: true, select: false, col: 4, disabled: true },
      { grupo: 'dp', nome: 'idade', label: 'Idade :', type: 'text', mask: '000', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'gestacao', label: 'Gestação :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'paridade', label: 'Paridade :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'abortos', label: 'Abortos :', type: 'text', mask: '000', input: true, select: false, col: 4 },


      { grupo: 'dp', nome: 'partoNormal', label: 'Parto Normal :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: "Sim" }, { op: 'Não', value: "Não" }], col: 4 },
      { grupo: 'dp', nome: 'partoForceps', label: 'Parto Fórceps :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: "Sim" }, { op: 'Não', value: "Não" }], col: 4 },
      { grupo: 'dp', nome: 'cesaria', label: 'Parto Cesárea :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: "Sim" }, { op: 'Não', value: "Não" }], col: 4 },

      { grupo: 'dp', nome: 'ultimoParto', label: 'Último Parto:', type: 'date', mask: '0000-00-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'numeroFilhos', label: 'Número de filhos:', type: 'number', mask: '00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'risco', label: 'Gestação de alto risco:', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Sim', value: true }, { op: 'Não', value: false }], col: 4 },

      { grupo: 'dp', nome: 'ultimaMenstruacao', label: 'Data da última menstruação:', type: 'date', mask: '0000-00-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'dataParto', label: 'Data provável do parto:', type: 'date', mask: '0000-00-00', input: true, select: false, col: 4 },

    ]
  }

  ///////////Dependente 

  formularioPaciente() {
    return [
      ///Dados Pessoais
      {
        cards: [
          { titulo: 'Dados Pessoais', grupo: 'dp' },
          { titulo: 'Endereço', grupo: 'endereco' },
          { titulo: 'Dados plano Saúde', grupo: 'saude' },
          { titulo: 'Dados Pagamento', grupo: 'DBancario' }
        ]
      },
      { grupo: 'dp', nome: 'nome', label: 'Nome * :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'mae', label: 'Nome da Mãe :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'pai', label: 'Nome do Pai :', type: 'text', mask: null, input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'nascimento', label: 'Data de nascimento *:', type: 'date', mask: null, noMaskDrop: false, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'rg', label: 'RG * :', type: 'text', mask: '00.000.000-A', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'cpf', label: 'CPF * :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'pis', label: 'PIS :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'cartaosus', label: 'Cartão do SUS :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'rne', label: 'RNE :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'nacionalidade', label: 'Nacionalidade :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'naturalidade', label: 'Naturalidade :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'nomeConjuge', label: 'Nome Conjugue :', type: 'text', mask: null, input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'civil', label: 'Estado Civil :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Solteiro' }, { op: "Casado" }, { op: "Divorciado" }, { op: "Amasiado" }], col: 4 },

      { grupo: 'endereco', nome: 'cep', label: 'CEP * :', type: 'text', mask: '00000-000', input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'endereco', label: 'Endereço :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'numero', label: 'N° :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'bairro', label: 'Bairro :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'cidade', label: 'Cidade :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'estado', label: 'Estado :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'telefone', label: 'Telefone :', type: 'text', mask: "(00) 0000-0000", input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'celular', label: 'Celular :', type: 'text', mask: "(00) 00000-0000", input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'telefone2', label: 'Contato de emergência :', type: 'text', mask: "(00) 0000-0000", input: true, select: false, col: 3 },
      { grupo: 'endereco', nome: 'email', label: 'E-mail :', type: 'email', mask: null, input: true, select: false, col: 3 },

      { grupo: 'saude', nome: 'convenio', label: 'Plano de saúde :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'saude', nome: 'operadora', label: 'Operadora :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'saude', nome: 'numeroBeneficiario', label: 'Número do Beneficiário :', type: 'text', mask: '0*', input: true, select: false, col: 3 },
      { grupo: 'saude', nome: 'modalidade', label: 'Modalidade :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'saude', nome: 'numeroCarteirinha', label: 'Numero da Carteirinha :', type: 'text', mask: '0*', input: true, select: false, col: 3 },

      { grupo: 'DBancario', nome: 'formaPag', label: 'Forma de Pagamento :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'bandeira', label: 'Bandeira :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'numerocartao', label: 'Número do Cartão :', type: 'text', mask: '0000 0000 0000 0000', input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'validade', label: 'Validade :', type: 'text', mask: '00/00', input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'cvv', label: 'CVV :', type: 'text', mask: '000', input: true, select: false, col: 3 },
      { grupo: 'DBancario', nome: 'pais', label: 'País :', type: 'text', mask: null, input: true, select: false, col: 3 },

    ]
  }
  formularioPacienteBasico() {
    return [
      ///Dados Pessoais
      {
        cards: [
          { titulo: 'Dados de usuário', grupo: 'dp' },
          { titulo: 'Dados de convênio', grupo: 'cv' },
        ]
      },
      { grupo: 'dp', nome: 'cpfTitular', label: 'CPF titular:', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'nomeCompleto', label: 'Nome * :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'grauParentescoTitular', label: 'Grau Parentesco:', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Pai' }, { op: 'Mãe' }, { op: 'Tio(a)' }, { op: 'Irmão(a)' },{op :'Responsável legal'}], col: 4 },
      { grupo: 'dp', nome: 'cpf', label: 'CPF * :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'dataNascimento', label: 'Data de nascimento *:', type: 'date', mask: '0000-00-00', noMaskDrop: true, input: true, select: false, col: 2 },
      { grupo: 'dp', nome: 'genero', label: 'Sexo :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Masculino' }, { op: 'Feminino' }], col: 2 },

      { grupo: 'dp', nome: 'telefone', label: 'Celular :', type: 'text', mask: "(00) 00000-0000", input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'email', label: 'E-mail :', type: 'email', mask: null, input: true, select: false, col: 4 },

      { grupo: 'cv', nome: 'operadora', label: 'Operadora de saúde :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'cv', nome: 'convenio', label: 'Plano :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'cv', nome: 'numeroCarteirinha', label: 'N° da carteirinha :', type: 'text', mask: '00000*', input: true, select: false, col: 4 },

    ]
  }

  ////////////Cadastro empresa 
  formularioCadastroEmpresa() {
    return [
      {
        cards: [
          { titulo: 'Dados Empresa', grupo: 'de' },
          { titulo: 'Dados Pessoais', grupo: 'dp' },
          { titulo: 'Endereço', grupo: 'endereco' }
        ]
      },
      { grupo: 'de', nome: 'cnpj', label: 'CNPJ :', type: 'text', mask: '00.000.000/0000-00', input: true, select: false, col: 4 },
      { grupo: 'de', nome: 'consultorio', label: 'Nome do Estabelecimento:', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'de', nome: 'contrato', label: 'Contrato :', type: 'text', mask: null, input: true, select: false, col: 4 },

      { grupo: 'de', nome: 'logradouro', label: 'Logradouro:', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'de', nome: 'unidade', label: 'Unidade :', type: 'text', mask: null, input: true, select: false, col: 3 },
      { grupo: 'de', nome: 'numero', label: 'Numero :', type: 'text', mask: null, input: true, select: false, col: 2 },
      { grupo: 'de', nome: 'cep', label: 'CEP :', type: 'text', mask: '0000-0000', input: true, select: false, col: 3 },
      { grupo: 'de', nome: 'bairro', label: 'Bairro :', type: 'text', mask: null, input: true, select: false, col: 2 },
      { grupo: 'de', nome: 'cidade', label: 'Cidade :', type: 'text', mask: null, input: true, select: false, col: 2 },
      { grupo: 'de', nome: 'estado', label: 'Estado :', type: 'text', mask: null, input: true, select: false, col: 2 },

      { grupo: 'dp', nome: 'nomeResponsavel', label: 'Nome do Responsável:', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'cpf', label: 'CPF :', type: 'text', mask: '000.000.000-00', input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'especialidade', label: 'Especialidade :', type: 'text', mask: null, input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'crm', label: 'CRM', type: 'number', mask: null, input: true, select: false, col: 4 },
      { grupo: 'dp', nome: 'celular', label: 'Celular : ', type: 'text', mask: '(00)00000-0000', input: true, select: false, col: 4 },

      { grupo: 'endereco', nome: 'logradouroRes', label: 'Endereço :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'endereco', nome: 'numeroRes', label: 'Numero :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'endereco', nome: 'cepRes', label: 'CEP :', type: 'text', mask: '0000-0000', input: true, select: false, col: 4 },
      { grupo: 'endereco', nome: 'bairroRes', label: 'Bairro :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'endereco', nome: 'cidadeRes', label: 'Cidade :', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'endereco', nome: 'estadoRes', label: 'Estado :', type: 'text', mask: null, input: true, select: false, col: 4 },

    ]
  }
  /////////// Cadastro Estrutura Organizacional
  formularioEstruturaOrganizacional() {
    return [
      {
        cards: [
          { titulo: 'Cadastro', grupo: 'cad' },
        ]
      },
      { grupo: 'cad', nome: 'consultorio', label: 'Nome do Estabelecimento:', type: 'text', mask: null, input: true, select: false, col: 4 },
      { grupo: 'cad', nome: 'cnpj', label: 'CNPJ :', type: 'text', mask: '00.000.000/0000-00', input: true, select: false, col: 4 },
      { grupo: 'cad', nome: 'contrato', label: 'Contrato :', type: 'text', mask: '(00) 0000-0000', input: true, select: false, col: 4 },

      { grupo: 'dp', nome: 'civil', label: 'Estado Civil :', type: 'text', mask: null, input: false, select: true, selecOp: [{ op: 'Solteiro' }, { op: "Casado" }, { op: "Divorciado" }, { op: "Amasiado" }], col: 4 },

    ]
  }
  formularioDefinirEmpresa_fichaGeral() {

  }
}
