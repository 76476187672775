import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EspecialidadeCrm {
    url = new environment().api
    EspecialidadeCrm = "EspecialidadeCrm/"

    constructor(private http: HttpClient) {
    }

    getEspecialidadeCrmFiltrarCpf(cpf: any): Observable<Object> {
        console.log("Dados API Especialidade CRM :" + this.url + this.EspecialidadeCrm + 'FiltrarCpf' + cpf)
        return this.http.get(this.url + this.EspecialidadeCrm + 'FiltrarCpf/' + cpf)
    }

}


