import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CadastroOperadoraCartao } from '../ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { Injectable } from '@angular/core';
import { getLocaleDateTimeFormat } from '@angular/common';

const user = new SessaoUser
const url = `${new environment().api}ClassificacaoDeReceituarios/`;

@Injectable({
    providedIn: 'root'
})

export class ClassificacaorReceituarioService{
  
    api =  new environment().api + "ClassificacaoDeReceituarios"

    constructor(private http: HttpClient) {}

Get(){
    return this.http.get(this.api)
}

Post(registro){
    return this.http.post(this.api, registro)
}

Alterar(registro){
    return this.http.put(this.api+ `${registro.id}/${user.sessao_id}/${getLocaleDateTimeFormat}/`, registro)
}

}