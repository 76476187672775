import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SessaoUser } from 'src/app/user.reg';
import { environment } from 'src/environments/environment';
import { CHAT, CN_CONVERSAS, CN_CONVERSAS_JOIN, CN_MENSAGENS } from './model';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  cab = `${new environment().api}CN_CONVERSAS/`
  item = `${new environment().api}CN_MENSAGENS/`
  collectionFirebase: any;

  constructor(private http: HttpClient, private fireBase: AngularFirestore,) {
    this.collectionFirebase = this.fireBase.collection('conversas');
  }

  async getConversas(cpf) {
    let result: CN_CONVERSAS_JOIN[] = []
    await this.http.get<any>(this.cab + 'JoinFiltrarCpf/' + cpf).toPromise()
      .then(res => {
        if (res.success) {
          let registro: CN_CONVERSAS_JOIN[] = res.result.filter(value => value.status == 'SDA0001')
          result = registro
        }
      })
    return result
  }
  async getConversasID(id: string) {
    let result = new CN_CONVERSAS
    await this.http.get<CN_CONVERSAS>(this.cab + id).toPromise()
      .then(res => result = res)
      .catch()
    return result
  }
  async getMensagens(IdConversa: string) {
    let result: CN_MENSAGENS[] = []
    await this.http.get<any>(this.item + 'FiltrarConversaId/' + IdConversa).toPromise()
      .then(res => {
        if (res.success) {
          result = res.result.filter(value => value.status != 'SDA0002')
        }
      })
    return result
  }
  async get() {
    let result: CN_MENSAGENS[] = []
    await this.http.get<CN_MENSAGENS[]>(this.item).toPromise()
      .then(res => {
        result = res

      })
    return result
  }

  upload(file: FormData, conversaID) {
    return this.http.post(this.item + `SubirAnexo/TIPANE10/${conversaID}/${sessao.cpf}`, file, { reportProgress: true, observe: 'events' })
  }

  async creatConversa(registro: CN_CONVERSAS) {
    let result
    registro.status = 'SDA0001'
    await this.http.post(this.cab, registro).toPromise()
      .then(res => {
        try {
          result = res[0].key
        } catch (error) {
          console.log('error creatConversa', error)
        }
      })
    return result
  }
  async creatMensagem(mensagem: CN_MENSAGENS) {
    let result
    await this.http.post(this.item, mensagem).toPromise()
      .then(async res => {
        try {
          result = res[0].key
          const dataHoje = new Date().toISOString().split('T')[0]
          console.log(mensagem)
          await this.collectionFirebase.doc(mensagem.conversaId).collection(dataHoje).add({ ...mensagem });
          await this.collectionFirebase.doc(`ATUALIZAR-${Math.floor(Math.random() * 1000 + 1000)}`).collection(dataHoje).add({ ...mensagem });
          // let verifc = await this.collectionFirebase.doc(`ATUALIZAR-${mensagem.conversaId}`).delete()
          // console.log('delete', verifc)

        } catch (error) {
          console.log('error creatConversa', error)
        }
      })
    return result
  }
  async editMensagem(mensagem: CN_MENSAGENS, remover?: boolean) {
    let result = false
    console.log(this.item + `${mensagem.id}/${sessao.sessao_id}/${sessao.cpf}`, mensagem)
    await this.http.put(this.item + `${mensagem.id}/${sessao.sessao_id}/${sessao.cpf}`, mensagem).toPromise()
      .then(async () => {
        const dataHoje = new Date().toISOString().split('T')[0]
        result = true
        if (remover) return

        await this.collectionFirebase.doc(mensagem.conversaId).collection(dataHoje).add({ ...mensagem });

      })
    return result
  }
  async excluirConversa(mensagem: { contato: CN_CONVERSAS_JOIN, mensagens: CN_MENSAGENS[], cont?: number }) {
    let result = false
    let contato = mensagem.contato
    let conversa = new CN_CONVERSAS
    conversa.id = contato.conversaId
    conversa.criadoEm = contato.criadoEm
    conversa.criadoPor = contato.criadoPor
    conversa.destinatario = contato.destinatario
    conversa.status = 'SDA0002'
    await this.http.put(this.cab + `${conversa.id}/${sessao.sessao_id}/${sessao.cpf}`, conversa).toPromise()
      .then(async () => {
        const dataHoje = new Date().toISOString().split('T')[0]
        result = true
        try {
          if (mensagem.mensagens.length > 0) {
            for (let item of mensagem.mensagens) {
              item.status = 'SDA0002'
              await this.editMensagem(item, true)
            }
          }
        } catch { }
        await this.collectionFirebase.doc(contato.conversaId).delete();
      })


    return result
  }
  observarFirebase(idCatalogo: string) {
    const dataHoje = new Date().toISOString().split('T')[0]
    return this.collectionFirebase.doc(idCatalogo).collection(dataHoje).valueChanges();
  }
  verificarMensagens() {
    return this.collectionFirebase.valueChanges()

  }

}



