import { Component, OnInit, Input } from '@angular/core';
import { TesteService } from 'src/app/service/teste.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.scss']
})
export class TesteComponent implements OnInit {
  @Input('teste') teste: string

  form = {
    id: null,
    nome: '',
    telefone: '11 ',
    cpf: ''
  }
  list = []


  constructor(private service: TesteService, private toast: ToastrService) {

  }

  ngOnInit() {
    this.buscar()
  }

  enviar() {
    if (this.form.id) {
      this.service.alterar(this.form.id, this.form).toPromise().then(
        res => {
          this.reset()
          this.buscar()
        }
      )
    }
    else {
      this.service.criar(this.form).toPromise().then(
        res => {
          this.list.push(res)
          this.reset()
        }
      )
    }
  }

  buscar() {
    this.service.ler().toPromise().then(
      (res: any[]) => {
        this.list = res
      }
    )
  }

  excluir(id) {
    this.service.excluir(id).toPromise().then(
      res => {
        this.toast.success('Excluido com sucesso!')
        this.reset()
        this.buscar()
      }
    )
  }
  reset() {
    this.form.id = null
    this.form.cpf = null
    this.form.nome = null
    this.form.telefone = null
  }
}
