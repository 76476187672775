export class formAnexoExame{
        id: string
        usuarioId: string
        companyId: string
        cpf: string
        titulo: string
        data: string
        paciente: string
        anexo: string
        agendaId: string
        createdAt: string
        ativo: true
}