// verificar se é necessário chamar esse aquivo em outro lugar 

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CadastroOperadoraCartao } from '../ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { Injectable } from '@angular/core';

const user = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class CadastroOperadoraCartaoService {

    url = new environment().api
    api = 'CadastroOperadoraDeCartao/'

    constructor(private http: HttpClient, private data: BuscarDataService) {

    }

    listar() {
        return this.http.get<CadastroOperadoraCartao[]>(this.url + this.api)
    }

    enviar(registro: CadastroOperadoraCartao) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: CadastroOperadoraCartao) {
        return this.http.put(this.url + this.api + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: CadastroOperadoraCartao, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        } else {
            registro.status = 'SDA0002'
        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }
}