import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: `./app.component.html`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  url = "https://www.myclinics.com.br/parametros/global.json"

  constructor(private http: HttpClient) {
    this.getParametros()
    setInterval(() => { this.getParametros() }, 2000)
    setInterval(() => { localStorage.removeItem('companyId') }, 5000)
  }

  async ngOnInit() {

  }

  async getParametros() {
    if (localStorage.getItem('api')) return
    try {
      await this.http.get(this.url).toPromise()
        .then((reg: any) => {
          if (reg.parametros[0].url_api) {
            console.log(reg.parametros[0].url_api)
            // document.location.reload(true)
            localStorage.setItem('api', reg.parametros[0].url_api)
            return
          }
        }
        )
        .catch(
          (erro) => {
            // alert('Não foi possivel carregar configuração! (Configuração interna termporaria)')
            localStorage.setItem('api', 'https://myclinics.com.br/api/api/')
            // document.location.reload(true)
            return
            console.log(erro)
          }
        )
    }
    catch {
      alert('erro')
    }
  }
}
