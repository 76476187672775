import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { ProntuarioVidaPaciente } from 'src/app/service/dados-da-saude/model';
import { FichaConsultaMedicaService } from 'src/app/service/ficha-consulta-medica/ficha-consulta-medica.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { FormProntuarioVidaPaciente } from 'src/app/service/prontuario-vida-paciente/model';
import { ProntuarioDeVidaService } from 'src/app/service/prontuario-vida-paciente/prontuario-de-vida.service';
import { ReceituarioService } from 'src/app/service/receituario/receituario.service';
import { SessaoUser } from 'src/app/user.reg';
import { CheckedAtDadosSaude, ViewSubItem } from '../atualizar-dados-saude/model';
import { TypeProntuario } from './model';
const sessao = new SessaoUser
@Component({
  selector: 'app-prontuario-vida-paciente',
  templateUrl: './prontuario-vida-paciente.component.html',
  styleUrls: ['./prontuario-vida-paciente.component.scss']
})
export class ProntuarioVidaPacienteComponent implements OnInit {
  @Input('cpf') cpf: string
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: true,
          maxTicksLimit: 3,
          maxRotation: 0,
          minRotation: 0
        }
      }], yAxes: [{
        ticks: {
          autoSkip: true,
          beginAtZero: true,
          maxTicksLimit: 5
        }, gridLines: {
          display: false
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartType: ChartType = 'line';
  public barChartLegend = false;

  public barChartData = []

  public barChartLabels = {
    pressao_arterial: [],
    temperatura: [],
    frequencia_cardiaca: [],
    frequencia_respiratoria: [],
    glicemia: [],
    saturacao: [],
    peso: [],
    cintura: [],
    altura: [],
    tipoSanguineo: []
  };



  chartData: any = {
    pressao_arterial: [{ data: [], label: '' }],
    temperatura: [{ data: [], label: '' }],
    frequencia_cardiaca: [{ data: [], label: '' }],
    frequencia_respiratoria: [{ data: [], label: '' }],
    saturacao: [{ data: [], label: '' }],
    glicemia: [{ data: [], label: '' }],
    peso: [{ data: [], label: '' }],
    cintura: [{ data: [], label: '' }],
    altura: [{ data: [], label: '' }],
    tipoSanguineo: [{ data: [], label: '' }],
  }


  // public barChartData: ChartDataSets[] = [
  //   { data: [80, 95, 91], label: 'Hipotensão' },
  //   { data: [120, 80, 90], label: 'Normotensão' },
  //   { data: [140, 135, 130], label: 'Hipertensão' }
  // ];
  public lineChartColors: Color[] = [
    {
      backgroundColor: '#f0f0f0d1',
      borderColor: 'rgb(72 202 229)',
      // pointBackgroundColor: 'rgb(72 202 229)',
      // pointBorderColor: '#fff',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgb(230 230 230)'
    }
  ];



  atDadosSaude = new CheckedAtDadosSaude
  subItem = new ViewSubItem

  typeChart: string;
  openHistorico: string;
  viewChart = 'Peso'
  register: FormProntuarioVidaPaciente[] = [];
  activeForm: TypeProntuario = ''

  dataSourceReceitaEspecial = new MatTableDataSource()
  displayedColumnsReceitaEspecial = ['tipoNotificacao', 'numeroTalonario', 'tipoReceita']
  
  dataSourceReceitaBranca = new MatTableDataSource()
  displayedColumnsReceitaBranca =  ['adscricaoDasOrientacoes', 'inscricaoMedicamento', 'subscricaoDaQuantidade', 'continuo','descUso']
  constructor(
    private service: ProntuarioDeVidaService,
    private _data: BuscarDataService,
    private _filter: FiltrarDadosService,
    private _ficha: FichaConsultaMedicaService,
    private _conteudo: ConteudoDeDadosService) { }

  ngOnInit() {
    this.atDadosSaude.ultimaMedSinaisVitaisBasic = true
    this.viewHistorico('pressaoArterial')
    this.getProntuario()
    this.getReceitaMedica()
  }
  getProntuario() {
    this.activeForm = ''
    // this.cpf =  sessao.cpf
    this.service.get(this.cpf).toPromise()
      .then((response: Array<FormProntuarioVidaPaciente>) => {

        if (!response.length) return

        response.forEach(value => {
          value.data = this._data.convertCriadoEm(value.criadoEm.length > 8 ? value.criadoEm.slice(0, 8) : value.criadoEm)
        })

        this._filter.ordernarCriadoEm(response)

        this.register = [...response]

        // let reduce = this.getUltimosValores(response, true)

        // response = reduce



        this.barChartLabels.pressao_arterial = response.filter(v => v.pressaoArterial).map(v => this._data.convertBR(v.data))
        this.barChartLabels.temperatura = response.filter(v => v.temperatura).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.frequencia_cardiaca = response.filter(v => v.pressaoSistolica).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.frequencia_respiratoria = response.filter(v => v.pressaoDiastolica).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.saturacao = response.filter(v => v.saturacaoOxigenio).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.glicemia = response.filter(v => v.glicemia).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.peso = response.filter(v => v.peso).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.cintura = response.filter(v => v.cintura).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.altura = response.filter(v => v.altura).map(v => this._data.convertBR(v.data).slice(3))
        this.barChartLabels.tipoSanguineo = response.filter(v => v.tipoSanguineo).map(v => this._data.convertBR(v.data).slice(3))

        this.chartData.pressao_arterial = [{ data: response.filter(v => v.pressaoArterial).map(v => Number(v.pressaoArterial)), label: '' }]
        this.chartData.temperatura = [{ data: response.filter(v => v.temperatura).map(v => Number(v.temperatura)), label: '' }]
        this.chartData.frequencia_cardiaca = [{ data: response.filter(v => v.pressaoSistolica).map(v => Number(v.pressaoSistolica)), label: '' }]
        this.chartData.frequencia_respiratoria = [{ data: response.filter(v => v.pressaoDiastolica).map(v => Number(v.pressaoDiastolica)), label: '' }]
        this.chartData.saturacao = [{ data: response.filter(v => v.saturacaoOxigenio).map(v => Number(v.saturacaoOxigenio)), label: '' }]
        this.chartData.glicemia = [{ data: response.filter(v => v.glicemia).map(v => Number(v.glicemia)), label: '' }]
        this.chartData.peso = [{ data: response.filter(v => v.peso).map(v => Number(v.peso)), label: '' }]
        this.chartData.cintura = [{ data: response.filter(v => v.cintura).map(v => Number(v.cintura)), label: '' }]
        this.chartData.altura = [{ data: response.filter(v => v.altura).map(v => Number(v.altura)), label: '' }]
        this.chartData.tipoSanguineo = [{ data: response.filter(v => v.tipoSanguineo).map(v => v.tipoSanguineo), label: '' }]

        console.log('REDUCE----', response.map(v => v.data), response)
      })
  }
  getReceitaMedica() {
    console.log('Receita -> start')
    this._ficha.getReceitaBranca().toPromise()
      .then(res => {
        if (!res.length) return
        const cpf = this.cpf
        let filter = res.filter(v => v.cpfPaciente == cpf)
        if (filter.length > 0) {
          filter.forEach(async value => {
            let getDesc = await this._conteudo.get('INSMED', true , value.superInscricaoDoUso)
            value.descUso = getDesc.toString()
          })
        }

        this.dataSourceReceitaBranca = new MatTableDataSource(filter)
       
      })
    this._ficha.getReceituarioEspecial().toPromise()
      .then(res => {
        if (!res.length) return
        const cpf = this.cpf
        let filter = res.filter(v => v.cpfPaciente == cpf)
        if (filter.length > 0) {
       
        }
        console.log('Receita ->', filter)
        this.dataSourceReceitaEspecial = new MatTableDataSource(filter)



        console.log('Receita ->', filter)
      })
  }

  getValueAtual(type: TypeProntuario) {
    try {
      return this.chartData[type][0].data[this.chartData[type][0].data.length > 0 ? this.chartData[type][0].data.length - 1 : 0]
    }
    catch {
      return '--'
    }
  }
  getUltimosValores(register: Array<any>, label?: boolean) {
    try {

      let t = label ? register : register[0].data
      let reduce = []

      for (let i = 0; i < 7; i++) {
        let value = t[t.length - i]
        if (value) reduce.push(value)
      }

      if (label) return reduce

      return [{ data: reduce, label: register[0].label }]

    } catch (error) {
      console.log('getUltimosValores-ERRO!', error)
      return register
    }

  }
  viewHistorico(sessao: string) {
    this.openHistorico = sessao
    switch (sessao) {
      case 'pressaoArterial':
        this.barChartData = [
          { data: [80, 95, 91], label: 'Hipotensão' }
        ]
        break
      case 'temperatura':
        this.barChartData = [
          { data: [35, 40, 37], label: 'Hipotermia' },
          { data: [33, 37.5, 33], label: 'Normotermia' },
          { data: [37, 37.6, 35.5], label: 'Hipertermia:' }
        ]
        break
      case 'frequenciaCardiaca':
        this.barChartData = [
          { data: [14, 13, 13], label: 'Bradicardia' },
          { data: [14, 15, 17], label: 'Normocardia' },
          { data: [22, 21, 21], label: 'Taquipnéia:' }
        ]
        break
      case 'frequenciaRespiratoria':
        this.barChartData = [
          { data: [59, 58, 56], label: 'Hipotermia' },
          { data: [60, 80, 83], label: 'Normotermia' },
          { data: [100, 100, 110], label: 'Hipertermia:' }
        ]
        break
      case 'saturacao':
        this.barChartData = [
          { data: [59, 58, 56], label: 'Hipotermia' },
          { data: [60, 80, 83], label: 'Normotermia' },
          { data: [1000, 1000, 1010], label: 'Hipertermia:' }
        ]
        break
      case 'glicemia':
        this.barChartData = [
          { data: [35, 40, 37], label: 'Hipotermia' },
          { data: [33, 37.5, 33], label: 'Normotermia' },
          { data: [37, 37.6, 35.5], label: 'Hipertermia:' }
        ]
        break
      case 'sanguinio':
        this.viewChart = null
        break
      case 'pcd':
        this.viewChart = null
        break
      case 'peso':
        this.openViewChart('Peso')
        break
      case 'altura':
        this.openViewChart('Altura')
        break
      case 'cintura':
        this.openViewChart('Cintura')
        break

    }
    // this.validColor(sessao)

  }
  async viewPage(page, no_reset?) {
    this.atDadosSaude = new CheckedAtDadosSaude
    this.subItem = new ViewSubItem
    console.log('page', page)
    switch (page) {
      case 'ultimaMedSinaisVitaisBasic':
        this.atDadosSaude.ultimaMedSinaisVitaisBasic = true
        this.subItem.ultimaMedSinaisVitaisBasic = true
        this.viewHistorico('pressaoArterial')
        break;
      case 'ultimaMedSinaisVitais':
        this.atDadosSaude.ultimaMedSinaisVitais = true
        this.subItem.ultimaMedSinaisVitais = true
        this.viewHistorico('saturacao')
        break;
      case 'caracteristicasFisicas':
        this.atDadosSaude.caracteristicasFisicas = true
        this.openHistorico = 'peso'
        break;
      case 'dadosSaude':
        this.atDadosSaude.dadosSaude = true
        this.openHistorico = 'medicacao'
        break;
      case 'historicoSaude':
        this.atDadosSaude.historicoSaude = true
        this.openHistorico = 'medicacao'
        break;

      case 'cirugia':
        this.atDadosSaude.cirugia = true
        break;
      case 'tratamentoRealizado':
        this.atDadosSaude.tratamentoRealizado = true
        break;
      case 'procedimentoAdicionais':
        this.atDadosSaude.procedimentoAdicionais = true
        break;
      case 'internacaoHospitalares':
        this.atDadosSaude.internacaoHospitalares = true
        break;
      case 'medicacaoReceitaEspecial':
        this.atDadosSaude.medicacaoReceitaEspecial = true
        break;
      case 'medicacaoReceitaBranca':
        this.atDadosSaude.medicacaoReceitaBranca = true
        break;

      case 'sinaisVitais':
        this.atDadosSaude.sinaisVitais = true
        break;
      case 'medUso':
        this.atDadosSaude.medUso = true
        break;
      case 'dataUtChekup':
        this.atDadosSaude.dataUtChekup = true
        break;
      case 'cartVacinacao':
        this.atDadosSaude.cartVacinacao = true
        break;
      case 'habVida':
        this.atDadosSaude.habVida = true
        break;
      case 'doencaCong':
        this.atDadosSaude.doencaCong = true
        break;
      case 'doencaPree':
        this.atDadosSaude.doencaPree = true
        break;
      case 'doencaCronicas':
        this.atDadosSaude.doencaCronicas = true
        break;
      case 'doencaContraidas':
        this.atDadosSaude.doencaContraidas = true
        break;
      case 'doencaFamilia':
        this.atDadosSaude.doencaFamilia = true
        break;
      case 'evolucaoSaude':
        this.atDadosSaude.evolucaoSaude = true
        break;
      case 'cirurgiaRealizada':
        this.atDadosSaude.cirurgiaRealizada = true
        break;
      case 'lesaoFratCont':
        this.atDadosSaude.lesaoFratCont = true
        break;
      case 'alergiaMedic':
        this.atDadosSaude.alergiaMedic = true
        break;
      case 'outrasAlergias':
        this.atDadosSaude.outrasAlergias = true
        break;
      case 'tratRealizado':
        this.atDadosSaude.tratRealizado = true
        break;
      case 'procAdicionais':
        this.atDadosSaude.procAdicionais = true
        break;
      case 'interHosp':
        this.atDadosSaude.interHosp = true
        break;
      case 'medReceitaEsp':
        this.atDadosSaude.medReceitaEsp = true
        break;
      case 'medReceitaBranca':
        this.atDadosSaude.medReceitaBranca = true
        break;
    }
  }
  openViewChart(sessao) {
    this.viewChart = null
    setTimeout(() => this.viewChart = sessao, 100)

  }
  validColor(sessao) {
    let indic = {
      hard: [],
      normal: [],
      low: []
    }

    let date = new Date().getFullYear()
    // this.barChartLabels = []
    // this.barChartLabels.push(date.toString())

    switch (sessao) {
      case 'pressaoArterial':

        this.barChartData.forEach(item => {

          item.data.forEach(value => {

            if (value == 60 || value == 90) {
              indic.hard.push(value)
              indic.normal.push(1)
              indic.low.push(1)
            }
            if (value == 120 || value == 80) {
              indic.normal.push(value)
              indic.hard.push(1)
              indic.low.push(1)
            }
            if (value == 140) {
              indic.low.push(value)
              indic.normal.push(1)
              indic.hard.push(1)
            }
          })

        })
        this.barChartData[0].data = indic.hard
        this.barChartData[1].data = indic.normal
        this.barChartData[2].data = indic.low

        this.barChartData[0].label = 'Hipotermia'
        this.barChartData[1].label = 'Normotermia'
        this.barChartData[2].label = 'Hipertermia'

        break
      case 'temperatura':
        this.barChartData.forEach(item => {

          item.data.forEach(value => {

            if (value < 35) {
              indic.hard.push(value)
              indic.normal.push(1)
              indic.low.push(1)
            }
            if (value > 35 && value < 38) {
              indic.normal.push(value)
              indic.hard.push(1)
              indic.low.push(1)
            }
            if (value > 38) {
              indic.low.push(value)
              indic.normal.push(1)
              indic.hard.push(1)
            }
          })

        })
        this.barChartData[0].data = indic.hard
        this.barChartData[1].data = indic.normal
        this.barChartData[2].data = indic.low

        this.barChartData[0].label = 'Hipotermia'
        this.barChartData[1].label = 'Normotermia'
        this.barChartData[2].label = 'Hipertermia'
        break
      case 'frequenciaCardiaca':
        this.barChartData.forEach(item => {

          item.data.forEach(value => {

            if (value < 14) {
              indic.hard.push(value)
              indic.normal.push(1)
              indic.low.push(1)
            }
            if (value > 14 && value < 20) {
              indic.normal.push(value)
              indic.hard.push(1)
              indic.low.push(1)
            }
            if (value > 38) {
              indic.low.push(value)
              indic.normal.push(1)
              indic.hard.push(1)
            }
          })

        })
        this.barChartData[0].data = indic.hard
        this.barChartData[1].data = indic.normal
        this.barChartData[2].data = indic.low

        this.barChartData[0].label = 'Bradicardia'
        this.barChartData[1].label = 'Normocardia:'
        this.barChartData[2].label = 'Taquipnéia'
        break
      case 'frequenciaRespiratoria':
        this.barChartData.forEach(item => {

          item.data.forEach(value => {

            if (value < 60) {
              indic.hard.push(value)
              indic.normal.push(1)
              indic.low.push(1)
            }
            if (value > 60 && value <= 100) {
              indic.normal.push(value)
              indic.hard.push(1)
              indic.low.push(1)
            }
            if (value > 101) {
              indic.low.push(value)
              indic.normal.push(1)
              indic.hard.push(1)
            }
          })

        })
        this.barChartData[0].data = indic.hard
        this.barChartData[1].data = indic.normal
        this.barChartData[2].data = indic.low

        this.barChartData[0].label = 'Bradicardia'
        this.barChartData[1].label = 'Normocardia:'
        this.barChartData[2].label = 'Taquipnéia'
        break
      case 'saturacao':

        break
      case 'glicemia':

        break


    }
    // for (let i = 0; i < this.barChartData[0].data.length; i++) {
    //   let getDate = this.barChartLabels[this.barChartLabels.length - 1] - 1
    //   this.barChartLabels.push(getDate)
    // }
  }

}
