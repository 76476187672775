import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CadastroPessoaForm, FormPaciente } from 'src/app/cadastro-paciente/model';
import { AgendaService } from 'src/app/service/agenda.service';
import { AgendaHorarioDiferenteForm, HorariosForm, AgendaForm } from 'src/app/agenda/agenda/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { SenhaAtendimentoService } from 'src/app/service/senha-atendimento.service';
import { SalaEsperaService } from 'src/app/service/sala-espera.service';
import { SalaEsperaForm } from '../sala-espera/model';
import { SenhaAtendimentoForm } from '../senha-atendimento/model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SessaoUser } from 'src/app/user.reg';
import { MensagemService } from 'src/app/service/mensagem.service';
import { TicketReserva, TicketConsulta, TicketSenhaAtendimento, TicketSenhaAtendimentoJoin, TicketConsultaJOIN, TicketConsultaJOIN_recpcao } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
import { filter } from 'rxjs/operators';
import { tick } from '@angular/core/testing';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { HttpClient } from '@angular/common/http';
import { PacienteService } from 'src/app/service/paciente.service';
import { ListarMedicosService } from 'src/app/service/listar-medicos.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FichaConexaoProfissional } from 'src/app/service/ficha-conexoes-profissionais/ficha-conexao-profissional.model';
import { FichaConexoesProfissionaisService } from 'src/app/service/ficha-conexoes-profissionais/ficha-conexoes-profissionais.service';
import { cadastroUsuario } from 'src/app/admin-usuarios/model';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { EventCalender } from 'src/app/calendario/model';
import { InfoEvent } from 'src/app/agenda/agenda/info-event/modal';
import { TicketsService } from 'src/app/service/tickets.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UsuarioService } from 'src/app/service/usuario.service';
const sessao = new SessaoUser
@Component({
  selector: 'app-recepcao',
  templateUrl: './recepcao.component.html',
  styleUrls: ['./recepcao.component.scss']
})
export class RecepcaoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumnsConsulta: string[] = ['nomePaciente', 'descTipoAtendimento', 'descTipoConsulta', 'destMotivoConsulta', 'dataConsulta', 'acao'];
  displayedColumns: string[] = ['ordem', 'senha', 'nomePaciente', 'descPrioridade', 'motivoConsulta', 'situacaoPaciente', 'acao'];
  displayedColumnsHistorico: string[] = ['date', 'ordem', 'senha', 'nomePaciente', 'descPrioridade', 'motivoConsulta', 'situacaoPaciente', 'acao'];
  dataSource = new MatTableDataSource()
  dataSourceLog: MatTableDataSource<any>;
  viewGerarSenha: boolean
  viewInfoCPF: boolean
  viewCadastroPaciente: boolean
  loading: boolean
  getPaciente = new CadastroPessoaForm
  viewCobranca: boolean;
  viewCadEncaixe: boolean;
  registroPaciente = new CadastroPessoaForm;
  cpf
  companyId
  formSalaEspera = new SalaEsperaForm
  legendaHorarioDiferente: string;
  selectSenhaAtendimento: TicketSenhaAtendimento = new TicketSenhaAtendimento
  horarios: HorariosForm[];
  tipoConsulta
  log = {
    id: "1a486e64-733e-42e9-a9ec-51974591475a",
    usuarioId: "00000000-0000-0000-0000-000000000000",
    companyId: "00000000-0000-0000-0000-000000000000",
    nome: "Oscar da silva",
    email: null,
    cpf: "12888770806",
    rg: null,
    profissao: null,
    nascimento: "1936-05-29",
    sexo: null,
    tratamento: null,
    tipoDocumento: null,
    pis: null,
    cartaosus: null,
    rne: null,
    nacionalidade: null,
    naturalidade: null,
    nomeConjuge: null,
    tipoEndereco: null,
    complemento: null,
    operadora: null,
    numeroBeneficiario: null,
    numeroCarteirinha: null,
    bandeira: null,
    numerocartao: null,
    validade: null,
    cvv: null,
    pais: null,
    modalidade: null,
    telefone: null,
    telefone2: null,
    celular: null,
    convenio: null,
    medico: null,
    tipo: null,
    cor: null,
    civil: null,
    especial: false,
    localNascimento: null,
    formaPag: null,
    situacao: null,
    peso: null,
    responsavel: null,
    rgResponsavel: null,
    cpfResponsavel: null,
    indicacao: null,
    pai: null,
    mae: null,
    atividadePai: null,
    atividadeMae: null,
    endereco: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    cep: null,
    createdAt: "2020-05-12T12:08:45.4735848",
    ativo: true
  }
  semRegistro: boolean;
  viewMotivoDesistencia: boolean;
  viewDesistencia: boolean;
  viewDesistencia_2: boolean;
  infoAgenda = new AgendaForm;
  ticket = new TicketReserva
  regRecepcao: any;
  itemRecepcao
  tipoPaciente
  semRegistroLog: boolean;
  viewAgenda: boolean;
  rangeDates: any[];
  loadingHistorico: boolean;
  loadingH: boolean;
  creatTicket: boolean;
  pacienteDependente: boolean;
  listTitulares: CadastroPessoaForm[];
  loadingTitular: boolean;
  cpfResponsavel: string
  cpfResponsavelOutro: string
  infoTitular: any;
  outroAcompanhante: boolean
  loadingTitularOutro: boolean;
  listTitularesOutros: CadastroPessoaForm[];
  mensagemInfo: string;
  semCadastro: boolean;
  acompanhante: boolean;
  horariosAgenda: AgendaForm[];
  viewHorariosAgenda: boolean;
  selecHorario = new AgendaForm
  selecHorarioNull: boolean;
  listMedicos = []
  medicoSelec
  viewConexao: boolean;
  infoConsulta = new InfoEvent;
  formaConsulta = 'FORMACON1';
  listFormaConsulta: ConteudoDados[] = [];
  listConsultas: TicketSenhaAtendimento[] = [];
  dataSourceConsultas = new MatTableDataSource();
  openFormSenha: boolean;
  dropItemSelect = new TicketConsultaJOIN_recpcao;
  formSenhaActive = new TicketSenhaAtendimento;
  ticketConsultaSelect: TicketConsultaJOIN_recpcao;
  loadingConsultas: boolean;
  constructor(
    private agendaService: AgendaService,
    private buscarData: BuscarDataService,
    private serviceSalaEspera: SalaEsperaService,
    private service: SenhaAtendimentoService,
    private toast: ToastrService,
    private _mensagem: MensagemService,
    private _filter: FiltrarDadosService,
    private _status: StatusSistemaService,
    private _medicos: ListarMedicosService,
    private servicePaciente: PacienteService,
    private _conteudo: ConteudoDeDadosService,
    private _user: UsuarioService,
    private _conexao: FichaConexoesProfissionaisService,
    private _ticket: TicketsService,
    private _senhaAtendimento: SenhaAtendimentoService,
    private router: Router) { }

  async ngOnInit() {
    this.cpf = sessao.cpf
    this.companyId = sessionStorage.getItem('companyId')
    this.getSenhaAtendimento()
    this.getMedicos()
    this.getConsultaMarcada()
    this.getHistorio15()
    this.listFormaConsulta = await this._conteudo.get('FORMACON')
  }

  async getSenhaAtendimento(dias?) {
    this.loading = true
    let hoje = new Date().toISOString().split('T')[0]

    if (dias) this.loadingHistorico = true

    let getRegistro = await this.service.listarTicketSenhaAtendimento(sessao.codigoEmpresa)
    let get = []

    let convert = this._filter.convertCriadoEm

    if (getRegistro.length > 0) {

      getRegistro.forEach(async (value: TicketSenhaAtendimento) => {
        let descPrioridade = await this._conteudo.get('LISPRI', true, value.prioridadeAtendimento)
        let descMotivo = await this._conteudo.get('LIMOCO', true, value.motivoConsulta)
        let destSituacao = await this._conteudo.get('LISSIT', true, value.situacaoPaciente)
        value.descPrioridade = descPrioridade.toString()
        value.descMotivo = descMotivo.toString()
        value.destSituacao = destSituacao.toString()
      })

      get = getRegistro.filter(value => {
        return convert(value.dataInclusaoDocumento).split('T')[0] == hoje && value.codigoEmpresa == sessao.codigoEmpresa
      }).filter((value) => value.status == "SDO0012" || value.status == 'SDO0055')

      this.loading = false
    }


    let getLog = []
    if (dias) {

      await this.rangeDates.forEach(item => {
        let itens = getRegistro.filter(reg => {
          return reg.codigoEmpresa == sessao.codigoEmpresa && convert(reg.dataInclusaoDocumento).split('T')[0] == item && reg.status != "SDO0012"
        })
        itens.forEach(element => {
          getLog.push(element)
        });
      });
      setInterval(() => { this.loadingHistorico = false }, 2000)


    } else {
      this.loadingH = true
      getLog = getRegistro.filter(reg => {
        return convert(reg.dataInclusaoDocumento).split('T')[0] == hoje && reg.codigoEmpresa == sessao.codigoEmpresa && reg.status != "SDO0012"
      })
      setInterval(() => { this.loadingH = false }, 2000)
    }

    getLog.forEach(async item => {
      item.status = (await this._status.getStatus(false, true, item.status)).toString()
    });
    this.loading = false
    this._filter.inserirFotoPerfil('cpfPaciente', get)
    this.dataSource = new MatTableDataSource(get)
    // this.dataSourceConsultas = new MatTableDataSource(get)
    this.dataSourceLog = new MatTableDataSource(getLog)

    if (get.length < 1) {
      this.semRegistro = true

    } else {
      this.semRegistro = false
    }

    if (getLog.length < 1) {
      this.semRegistroLog = true

    } else {

      this.semRegistroLog = false
    }

  }
  async getConsultaMarcada() {
    this.loadingConsultas = true
    this.openFormSenha = false;
    let getRegistro = await this._ticket.getConsultas(sessao.codigoEmpresa)



    getRegistro = getRegistro.filter(value => value.status == 'SDO0008')
      .filter(value => value.dataConsulta)
      .filter(value => {
        let hoje = new Date().toISOString().split('T')[0]
        let date = value.dataConsulta.split('T')[0]
        return new Date(date).valueOf() <= new Date(hoje).valueOf()
      })
      .sort((a, b) => {
        return new Date(a.dataConsulta) > new Date(b.dataConsulta) ? -1 : new Date(b.dataConsulta) > new Date(a.dataConsulta) ? 1 : 0
      })

    console.log('getConsultaMarcada', getRegistro)


    this._filter.ordernar('criadoEm', getRegistro, true)
    this.listConsultas = getRegistro
    this.dataSourceConsultas = new MatTableDataSource(getRegistro)
    this.dataSourceConsultas.sort = this.sort
    this._filter.inserirFotoPerfil('cpfPaciente', getRegistro)

    this.loadingConsultas = false

    getRegistro.forEach(async value => {
      let descTipoAtendimento = await this._conteudo.get('TIPATEND', true, value.tipoAtendimento)
      let descTipoConsulta = await this._conteudo.get('LITICO', true, value.tipoConsulta)
      let destMotivoConsulta = await this._conteudo.get('LITICO', true, value.motivoConsulta)
      value.descTipoAtendimento = descTipoAtendimento.toString()
      value.descTipoConsulta = descTipoConsulta[0] ? descTipoConsulta.toString() : value.motivoConsulta
      value.destMotivoConsulta = destMotivoConsulta[0] ? destMotivoConsulta.toString() : value.motivoConsulta
      console.log('destMotivoConsulta', descTipoConsulta[0] ? descTipoConsulta.toString() : value.motivoConsulta)
    })

  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // this.dataSource = new MatTableDataSource(event.container.data)

      let removeItem = this.dataSourceConsultas.data.filter((v, i) => i != event.previousIndex)

      let getItem: any = this.dataSourceConsultas.data[event.previousIndex]
      let item: TicketConsultaJOIN_recpcao = getItem
      this.ticketConsultaSelect = item
      this.dropItemSelect = item

      this.criarSenhaAtendimento(item)

      this.dataSourceConsultas = new MatTableDataSource(removeItem)

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

  }
  async criarSenhaAtendimento(ticketConsulta: TicketConsultaJOIN_recpcao) {
    let form = new TicketSenhaAtendimento
    let ticketSenha = new TicketSenhaAtendimento

    let getUser = await this._user.getCadastroPessoa(ticketConsulta.cpfPaciente)
    let getIdade = await this.buscarData.idade(this._filter.convertCriadoEm(getUser.dataNascimento))

    let verificPrioridade = getIdade > 65 ? 'LISPRI1' : getUser.deficiente ? 'LISPRI3' : 'LISPRI5'


    form.codigoEmpresa = sessao.codigoEmpresa
    form.dataInclusaoDocumento = new Date().toISOString()
    form.criadoPor = sessao.cpf
    form.status = 'SDO0012'
    form.tipoDoc = 'TICSEN'
    form.motivoConsulta = ticketConsulta.motivoConsulta
    form.prioridadeAtendimento = verificPrioridade
    form.nrDocReferencia = ticketConsulta.nrDoc
    this.formSenhaActive = form
    this.openFormSenha = true

    console.log('formCreat', ticketConsulta, ticketSenha, form)

    this.loading = false


    // this.atualizarSenha()
  }
  insertConsultaMarcada(item) {
    this.selectSenhaAtendimento = item
    this.openFormSenha = false
    this.atualizarSenha(true)
  }
  testeDrop(teste) {
    console.log(teste)
  }
  getHistorio15() {
    var data = new Date();
    data.setDate(data.getDate() - 15);

    const start = data.toISOString().split('T')[0]
    const end = this.buscarData.hoje()

    console.log(start, end)

    this.rangeDates = this.agendaService.genereteRangeDatas(new Date(start), new Date(end))
    this.getSenhaAtendimento(true)
  }
  async getMedicos() {
    this.listMedicos = await this._medicos.getList(sessao.codigoEmpresa)
  }
  chamarPaciente(reg: TicketSenhaAtendimentoJoin | any) {
    this.selectSenhaAtendimento = new TicketSenhaAtendimento
    this.selectSenhaAtendimento = reg
    if (reg.cpfPaciente) {
      this.viewInfoCPF = false
      this.viewCobranca = true
    } else {
      this.viewCobranca = false
      this.viewInfoCPF = true
    }


  }
  gerarSenha(registro) {
    let form = new TicketSenhaAtendimento
    form.codigoEmpresa = sessao.codigoEmpresa
  }
  async registro(reg: CadastroPessoaForm, preview?, desistencia?) {
    console.log('-------------REGITRO-------------', reg)
    let conexao = await this._conexao.verificConexao(reg.cpf)
    if (!conexao) {
      this.viewConexao = true
      this.viewInfoCPF = false
      this.registroPaciente = reg

      return
    }
    if (reg.tipoPessoa == 'TIPPES2') {
      this.pacienteDependente = true
      this.loadingTitular = true
      this.servicePaciente.listarCadastroPessoa().toPromise()
        .then((res: CadastroPessoaForm[]) => {

          let getTitulares = res.filter(item => { return item.cpf == item.cpfResponsavelTitular })

          if (getTitulares.length > 0) {
            this.listTitulares = getTitulares
          } else {
            this.listTitulares = []
          }
          this.loadingTitular = false
        })
    } else {
      this.pacienteDependente = false
    }
    this.registroPaciente = reg
    const consultaMarcada = await this.verificarAgenda(reg, preview)


    this.selectSenhaAtendimento.nomePaciente = reg.nomeCompleto
    const registro = this.selectSenhaAtendimento
    // this.service.alterarTicketSenhaAtendimento(id, registro).toPromise().catch((r) => this.toast.error('Falha de registro'))
    this.registroPaciente = reg
    this.viewInfoCPF = false

    if (desistencia) {

      return
    }



    if (this.registroPaciente.cpf) {

      if (this.pacienteDependente) return

      if (consultaMarcada) {
        this.viewHorariosAgenda = true
        this._filter.inserirFotoPerfilEach('cpf', this.registroPaciente)
        console.log('viewHorariosAgenda', this.horariosAgenda, this.registroPaciente)
        // this.viewCobranca = true
        // this.viewCadEncaixe = false
      } else {
        this.viewCadEncaixe = true
      }
    }
    // await this.atualizarSenha(this.selectSenhaAtendimento)
  }
  async conectarPaciente(cpf) {
    let result = await this._conexao.conectar('TIPCON5', cpf, sessao.codigoEmpresa)
    if (result) {
      this._mensagem.creat('Paciente conectado com sucesso', { sucesso: true })
      this.viewConexao = false
      this.registro(this.registroPaciente)
    } else {
      this._mensagem.creat('Não foi possivel conectar paciente ', { erro: true })

    }
  }
  getInfoConsulta(registro: TicketSenhaAtendimentoJoin) {
    this.infoConsulta.codigoEmpresa = registro.codigoEmpresa
    this.infoConsulta.cpf = registro.cpfPaciente
    this.infoConsulta.medico = registro.nomeMedico
    this.infoConsulta.cpfMedico = registro.cpfMedico
    this.infoConsulta.id = registro.id
    this.infoConsulta.nomePaciente = registro.nomePaciente
    this.infoConsulta.ticketConsultaMedica = registro.nrDocReferencia
    console.log(this.infoConsulta, registro)
  }
  getInfoConsultaClose() {
    this.infoConsulta = new InfoEvent
  }
  checkedHorario(registro) {
    let reg = registro.source.value

    if (reg == 'null') {
      if (registro.checked) {
        this.selecHorarioNull = true
      }
      else {
        this.selecHorarioNull = false
      }
      this.selecHorario = new AgendaForm

      return
    }

    if (registro.checked) {
      this.selecHorario = reg
    } else {
      this.selecHorario = new AgendaForm
    }
    this.selecHorarioNull = false
    this.infoAgenda = this.selecHorario

  }
  confirmHorario() {
    console.log(this.selecHorarioNull, this.selecHorario.id)
    if (!this.selecHorarioNull && !this.selecHorario.id) {
      return
    }
    if (this.selecHorarioNull) {
      this.viewCadEncaixe = true
    } else if (this.selecHorario.id) {
      this.viewCobranca = true
      this.viewCadEncaixe = false
      this.atualizarSenha()
    }
    this.viewHorariosAgenda = false
    this.selecHorario = new AgendaForm
    this.selecHorarioNull = false
  }



  async atualizarSenha(insertConsulta?) {

    let ticketSenha: TicketSenhaAtendimento = this.selectSenhaAtendimento


    // console.log(this.selecHorario)
    // console.log(this.registroPaciente)

    await this.agendaService.getTicketConsulta().toPromise()
      .then(async (reg: Array<TicketConsulta>) => {

        let ticketConsulta = reg.filter((doc) => {
          return doc.nrDocRef == ticketSenha.nrDocReferencia || doc.nrDoc == ticketSenha.nrDocReferencia
        })[0]
        // .filter(doc => doc.status == "SDO0008" || doc.status == 'SDO0027')[0]
        console.log('rodrigo', ticketConsulta, ticketSenha)
        if (insertConsulta && ticketConsulta) {
          ticketConsulta.status = "SDO0050"
          await this.atualizarTicketConsulta(ticketConsulta)
          this.viewCobranca = false;
          this.viewCadEncaixe = false;
          this.getSenhaAtendimento()
          return
        }

        if (ticketConsulta) {
          console.log('PASSOU TICKETCONSULTA', ticketConsulta)
          ticketSenha.nrDocReferencia = ticketConsulta.nrDoc
          ticketSenha.motivoConsulta = ticketConsulta.motivoConsulta

          if (this.pacienteDependente) {
            let cpf = this.cpfResponsavel
            this.infoTitular = this.listTitulares.filter(x => x.cpfResponsavelTitular == cpf)[0]
            ticketConsulta.cpfAcompanhante = this.cpfResponsavel
          }




          await this.atualizarTicketConsulta(ticketConsulta)

          this.viewCobranca = true;
          this.viewCadEncaixe = false;

        } else {
          if (!this.medicoSelec) {
            this.viewCobranca = false
            this.viewCadEncaixe = insertConsulta ? false : true
            this._mensagem.creat('Não foi possível identificar o Médico desta consulta.', { erro: true })
            return
          }
          let ticket = new TicketConsulta
          ticket.codigoEmpresa = sessao.codigoEmpresa
          ticket.cpfPaciente = this.registroPaciente.cpf
          ticket.criadoPor = sessao.cpf
          ticket.motivoConsulta = `${this.tipoConsulta == 1 ? 'LITICO4' : 'LITICO5'}`
          ticket.tipoAtendimento = this.selectSenhaAtendimento.motivoConsulta
          ticket.tipoMarcacao = `${this.tipoConsulta == 1 ? 'LITICO4' : 'LITICO5'}`
          ticket.dataInclusaoDocumento = this.buscarData.getDataInclusao()
          ticket.nrDocRef = this.selectSenhaAtendimento.nrDoc ? this.selectSenhaAtendimento.nrDoc : '00000000000'
          ticket.nrDoc = this.selectSenhaAtendimento.nrDoc ? this.selectSenhaAtendimento.nrDoc : '00000000000'
          ticket.cpfMedico = this.medicoSelec
          ticket.dataConsulta = new Date().toISOString().split('T')[0]
          ticket.horaConsulta = new Date().toLocaleTimeString().slice(0, 5)
          ticket.status = "SDO0008"
          ticket.ativo = true
          ticket.tipoConsulta = this.selectSenhaAtendimento.motivoConsulta
          ticket.tipoDoc = 'TICCON'
          ticket.tipoDocRef = 'TICRES'
          ticket.formaDeConsulta = this.formaConsulta
          console.log('registro novo', ticket)
          if (this.pacienteDependente) {
            ticket.cpfAcompanhante = this.cpfResponsavel
          }


          await this.agendaService.gerarTicketConsulta(ticket).toPromise()
            .then((res: any) => {
              console.log('$$$$$$$$', res)
              this._mensagem.creat('Ticket de consulta gerado', { aviso: true })
              // ticketSenha.numeroDocumentoReferencia = res.numeroDocumento
              ticketSenha.status = "SDO0055"

              console.log('RODRIIGO!', res)
              ticketSenha.nrDocReferencia = res[0].numeroDocumento
              this.atualizarTicketSenha(ticketSenha)
              this.viewCobranca = true;
              this.viewCadEncaixe = false;
            })
          return
        }

        ticketSenha.status = "SDO0055"
        await this.atualizarTicketSenha(ticketSenha)
        this.pacienteDependente = false;


      })
    this.getSenhaAtendimento()
  }
  atualizarTicketSenha(item: TicketSenhaAtendimento) {
    Object.keys(item).forEach(value => { if (!item[value]) { delete item[value] } })

    delete item.nomePaciente
    delete item.descPrioridade
    delete item.descTipoDoc
    delete item.descMotivo
    delete item.destSituacao

    let extra = item as any

    delete extra.backgroud
    delete extra.fotoPerfil
    delete extra.cpfPaciente
    delete extra.cpfMedico
    delete extra.nomeMedico


    this.service.alterarTicketSenhaAtendimento(item.id, item).toPromise()
      .then(() => {
        this.getSenhaAtendimento()
      })
      .catch(error => {
        console.log('error', error)
        this._mensagem.creat('Erro de registro', { erro: true })
      })
  }
  atualizarTicketConsulta(item, salaEspera?) {

    this.agendaService.atualizarTicketConsulta(item.id, item).toPromise()
      .then(() => {
        if (salaEspera) {
          this._mensagem.creat('Paciente enviado para sala de espera!', { sucesso: true })
        }
      })
      .catch(() => { this._mensagem.creat('Erro de registro', { erro: true }) })
  }

  cadastrarReagenda(registro: TicketReserva) {
    this.selectSenhaAtendimento.status = 'SDO0077'
    this.selectSenhaAtendimento.motivoConsulta = '1'
    this.viewDesistencia = false
    this.viewDesistencia_2 = false
    this.viewAgenda = false
    this.atualizarTicketSenha(this.selectSenhaAtendimento)

  }

  atualizarRegistro(registro, salaEspera?) {
    let ticketSenha: TicketSenhaAtendimento = registro
    this.agendaService.getTicketConsulta().toPromise()
      .then(async (reg: Array<TicketConsulta>) => {
        let ticketConsulta = reg.filter((doc) => { return doc.cpfPaciente == registro.cpfPaciente && doc.status == "SDO0045" || doc.status == "SDO0010" })[0]

        if (ticketConsulta) {
          ticketSenha.nrDocReferencia = ticketConsulta.nrDoc
          ticketSenha.criadoPor = sessao.cpf
          ticketSenha.horarioEncaminhamentoSalaEspera = this.buscarData.horaAgora()
          ticketSenha.status = "SDO0050"

          await this.atualizarTicketSenha(ticketSenha)

          ticketConsulta.status = "SDO0050"

          await this.getSenhaAtendimento()


          await this.atualizarTicketConsulta(ticketConsulta, salaEspera)


        } else {
          this._mensagem.creat('Paciente não possui ticket de consulta', { erro: true })
          this.viewCadEncaixe = false
          this.viewCobranca = false
        }
        this.loading = false
      })
  }
  async verificarAgenda(registro, previw?) {
    let res
    this.getPaciente = registro

    await this.agendaService.listarAgenda().toPromise()
      .then(
        (reg: Array<AgendaForm>) => {
          const cpf = registro.cpf
          const hoje = this.buscarData.hoje()

          let verificar = reg.filter((r) => {
            return r.cpf == cpf && r.date == hoje && r.codigoEmpresa == sessao.codigoEmpresa
          })
          console.log("AGGGENDA > ", verificar, cpf, hoje)
          if (verificar.length > 0) {
            this.infoAgenda = verificar[0]
            this.horariosAgenda = verificar
            console.log("AGGGENDA > ", this.infoAgenda)
            this.viewInfoCPF = false
            if (previw) return
            this.viewCadastroPaciente = false
            res = true
          }
          else {
            res = false
          }
        }
      )
    return res
  }
  encaminharSalaEspera() {
    this.viewCadEncaixe = false
    this.loading = true
    this.viewCobranca = false
    let registro = this.selectSenhaAtendimento
    this.itemRecepcao = new TicketSenhaAtendimento;
    this.atualizarRegistro(registro, true)



  }
  verificarHorariosPersonalizados() {
    this.agendaService.listarAgendaHorarioDiferente().toPromise()
      .then(
        (reg: Array<AgendaHorarioDiferenteForm>) => {
          const cpf = this.cpf
          let date = this.buscarData.hoje()
          let filtro = reg.filter((r) => { return r.cpfMedico == cpf && r.start == date })
          if (filtro.length > 0) {
            let getTime = filtro[0]
            this.legendaHorarioDiferente = getTime.localDeTrabalho
            const start = getTime.timeStart
            const end = getTime.timeEnd
            let getRangeTime = this.agendaService.listarHorarios(start, end)
            console.log(getRangeTime)
            const times = []
            getRangeTime.forEach(t => {
              let time = {
                timeStart: `${t.h}:${t.m}`
              }
              times.push(time)
            });
            this.horarios = times
          } else {
            this.legendaHorarioDiferente = null
            this.verificarHorarios()
          }

        }
      )
  }
  verificarHorariosDisponiveis(agenda, horarios) {
    //  Comparar horarios da Agenda com os Horarios da Clinica , 
    // descartando horarios que ja existem na agenda 
    let horasAgenda = agenda.map(item => item.h);
    console.log('horarioAgenda 1  : ' + JSON.stringify(horasAgenda))

    let listaHorarios = horarios.map(item => item.timeStart);

    console.log('horarioAgenda : ' + JSON.stringify(listaHorarios))
    let disponiveis = listaHorarios.map((hora, index) => {
      if (horasAgenda.indexOf(hora) < 0) {
        return horarios[index]
      }
    }).filter(item => item != undefined).sort(function (a, b) { return a.timeStart - b.timeStart });
    // console.log('Disponiveis : ' + JSON.stringify(disponiveis));
    let ordenaHorarios = disponiveis.sort(function (a: HorariosForm, b: HorariosForm) {
      return parseInt(a.timeStart.replace(/[^a-z0-9]/gi, '')) - parseInt(b.timeStart.replace(/[^a-z0-9]/gi, ''))
    })
    return ordenaHorarios

  }
  verificarHorarios() {
    this.agendaService.buscarHorarios().toPromise()
      .then(
        (dados: Array<HorariosForm>) => {
          let companyId = this.companyId
          if (!companyId) {

            return
          }
          let filtro = dados.filter(function (reg) {
            // console.log(`Hora : ${reg.companyId} -- ${companyId} -- ${reg.block} -- ${reg.status}`)
            return reg.companyId == companyId && reg.block == false &&
              reg.status == true
          })
          filtro.sort(function (a, b) {
            return parseInt(a.timeStart.substr(0, 2)) - parseInt(b.timeStart.substr(0, 2))
          })
          console.log('SEU FILTRO : ' + filtro.length)
          if (filtro.length < 1) {
            // this.erroConfig = true
            // this.erroConfiMensagem = "Sua clínica não possui horários, para abertura de novos horários acesse a área de configuração"
            return
          }
          else {

          }
          this.horarios = filtro
        }
      )

  }
  desistencia(dados) {
    this.itemRecepcao = dados
    if (this.itemRecepcao.cpfPaciente) {
      this.viewDesistencia = true
    }
    else {
      this._mensagem.creat('Por favor , informe o paciente', { erro: true })
    }
  }
  cadDesistencia() {
    const id = this.itemRecepcao.id
    let registro = this.itemRecepcao
    registro.status = 'SDO0020'
    this.service.alterarTicketSenhaAtendimento(id, registro).toPromise()
      .then((r) => { this.getSenhaAtendimento(); this.viewMotivoDesistencia = false })
  }
  verificAcompanhante(event) {
    console.log(event)
    if (!event) {
      this.outroAcompanhante = true
    } else {
      this.outroAcompanhante = false
    }
  }
  buscarTitular() {
    this.loadingTitularOutro = true
    this.mensagemInfo = null
    this.semCadastro = false
    console.log(this.cpfResponsavelOutro.length)
    if (this.cpfResponsavelOutro.length == 11) {
      this.servicePaciente.listarCadastroPessoa()
        .toPromise()
        .then((res: CadastroPessoaForm[]) => {
          let cpf = this.cpfResponsavelOutro
          let getRegistro = res.filter(x => x.cpf == cpf)

          if (getRegistro.length > 0) {

            let getTitular = getRegistro.filter((x => x.tipoPessoa == 'TIPPES1'))

            if (getTitular.length > 0) {

              getTitular.forEach(element => {
                this.listTitulares.push(element)
              });

              let removeDublicate = []

              this.listTitulares.forEach((item) => {
                let verific = removeDublicate.findIndex(x => x.cpf == item.cpf)
                if (verific < 0) {
                  removeDublicate.push(item)
                }
              })

              this.listTitulares = removeDublicate

            } else {
              this.mensagemInfo = "CPF informado não pode ser um acompanhante"
              return
            }

            this.outroAcompanhante = false
            this.cpfResponsavelOutro = null

          } else {
            this.mensagemInfo = "CPF não cadastrado"
            this.semCadastro = true
          }
          this.loadingTitularOutro = false
        })
    } else {
      setTimeout(() => { this.loadingTitularOutro = false }, 1500)
    }
  }
  openNewCadastro() {
    console.log(this.registroPaciente)
    this.getPaciente = new CadastroPessoaForm
    this.getPaciente.cpf = this.cpfResponsavelOutro
    this.viewCadastroPaciente = true
    this.acompanhante = true
  }
  async goAgenda() {
    console.log(this.infoAgenda)
    this.ticket.cpfMedico = this.infoAgenda.medico ? this.infoAgenda.medico : '00000000000'
    this.ticket.cpfPaciente = this.itemRecepcao.cpfPaciente
    this.ticket.dataReserva = this.infoAgenda.date ? this.infoAgenda.date : this.buscarData.hoje()
    this.ticket.id = 'reagenda'
    this.viewDesistencia_2 = false
    this.viewCadEncaixe = false
    this.viewAgenda = true
  }
  teste() { }
  formatData(date: string) {
    try {
      date = date.split('T')[0]
      let hoje = new Date().toISOString().split('T')[0]
      let verifcHoje = new Date(date).valueOf() == new Date(hoje).valueOf()
      if (verifcHoje) {
        return 'Hoje'
      } else {
        return date
      }
    } catch {
      return date
    }

  }
}
