export class FichaGeralEmpresa {
    id: string // obrigatorio
    codigoEmpresa: number
    nomeEmpresa: string
    numeroDocumento: string
    tipoEmpresa: string
    webSiteEmpresa: string
    cabecalhoImpressao: string
    telefoneEmpresa: string
    email: string
    observacao: string
    cpfRespClinico: string
    cnpj: string
    cpfAutonomo: string
    nrDepRPA: number
    razaoSocial: string
    matriz: string
    cnpjMatriz: string
    codigoEmpresaMatriz: number
    moedaEmpresa: string
    cep: string
    rua: string
    numeroEndereco: string
    complemento: string
    bairro: string
    cidade: string
    estado: string
    fusoHorario: string
    nomeFantasia: string
    classificacaoEmpresa: string
    codigoAssinante: string
    pessoaJuridica: boolean
    regimeTributario: string
    tipoSociedadeMedica: string
    cnae: string
    inscricaoMunicipal: string
    inscricaoEstadual: string
    coordenadaGps: string
    criadoPor: string
    status: string
    timestamp: string
    areasAtuacao?: any
    responsavel?: any
}
export class resultDadosProf {
    id: string
    cpf: number
    nomeCompleto: string
    nomeSocial: string
    profissao: string
    status: string
    timeStamp: string
    nr_Registro: string
    classificacaoRegistro: string
    especialidadeProfissional: string
}
export class AJ_PLAMEDEMP {
    id: number
    codigoEmpresa: number
    plano: string
    criadoPor: string
    criadoEm: string
    status: string
    operadoraSaude: string
    descPlano?: string
    descOperadora?: string
}
