export class CadastroItem {
    id: string
    codigoSKU: number
    criadoPor: string
    numeroDocumento: string
    codigoEmpresa: number
    descricao: string
    especialidade: string
    listaDePreco: number
    partNumber: number
    classificacao: string
    ativoPartirDe: string
    inativoPartirDe: string
    procContCompras: string
    procContEstoque: string
    procContVendas: string
    procContDRE: string
    codigoFiscalServicoPrestado: string
    codigoNCM: string
    dimensoesNCM: string
    peso: string
    volume: string
    foto: string
    video: string
    unidadeCompra: string
    custoItem: number
    fabricante: string
    codigoFornecedorPadrao: number
    codigoCatalogoFabricante: string
    tipoEmbalagemCompras: string
    quantidadePorEmbalagemCompras: number
    codigoPlanejamentoUsoMaterial: string
    unidadeMedidaEstoque: string
    depositoPadrao: string
    posicaoDeposito: string
    qtdMinimaEstoque: number
    qtdMaximaEstoque: number
    cicloContagemInventario: number
    recorrenciaDoInventario: string
    dataHoraInicial: string
    dataHoraUltimaContagemInventario: string
    dataHoraProximaContagemInventario: string
    bloqueioUtilizacao: string
    adminstrarItemPor: string
    unidadeMedidaVenda: string
    quandoPrecoForaPadrao: string
    tipoEmbalagemVendas: string
    metodoAvaliacaoCustos: string
    sujeitoIRRF: boolean
    grupoAlfandegario: string
    observacao: string
    tipo: string
    categoria: string
    motivoBloqueio: string
    criadoEm: string
    status: string
    cadastroItem?:any
    tipoImposto?:any
    preco?:any
}

export class SistemaClassificacaoDados {
    id?: string;
    codigoCaracteristica?: string;
    codValor?: number;
    codigoReferencia?: string;
    valor?: string;
    status?: string;
    codigoDeNegocio?: any;
    descricao?: string;
}