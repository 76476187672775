import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cadastroUsuario } from '../admin-usuarios/model';
import { CadastroPessoaForm } from '../cadastro-paciente/model';
import { BuscarDataService } from '../service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { FotoPerfilService } from '../service/foto-perfil.service';
import { HubDevService } from '../service/hub-dev.service';
import { MensagemService } from '../service/mensagem.service';
import { UsuarioService } from '../service/usuario.service';
import { SessaoUser } from '../user.reg';

const sessao = new SessaoUser
const JsonPais = './assets/json/json-paises.json'
const JsonEstados = './assets/json/estados-cidades.json'

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.scss']
})
export class MinhaContaComponent implements OnInit {
  selecionado = 'Dados pessoais'
  user = new cadastroUsuario
  fotoPerfil: any;
  formulario: FormGroup;
  listCorDePele: ConteudoDados[] = [];
  listEstadoCivil: ConteudoDados[] = [];
  listDeficiencia: ConteudoDados[] = [];
  operadoraSaude_bakcup: ConteudoDados[] = [];
  operadoraSaude: ConteudoDados[] = [];

  listPaises = []
  listEstados = []
  planoSaude: ConteudoDados[];
  planoSaude_backup: ConteudoDados[];
  viewDocProfisional = "PRINCIPAL"
  viewCadDocProf: boolean
  loading: boolean;
  modificar: boolean;
  viewCampRequired: boolean;
  editNomeSocial: boolean;
  loadingUser: boolean;
  dataNascimento: number;
  idade: number;
  listGrauInstrucao: ConteudoDados[];
  listCidades = []
  listCidadesBackup = []
  listCidadesVT = []
  listCidadesVTBackup = []
  listCertidao: ConteudoDados[] = [];
  listOrgEmissor: ConteudoDados[] = [];
  listTipDoc: ConteudoDados[] = [];
  constructor(
    private _usuario: UsuarioService,
    private service_foto: FotoPerfilService,
    private formBuild: FormBuilder,
    private _conteudo: ConteudoDeDadosService,
    private http: HttpClient,
    private _hub: HubDevService,
    private _mensagem: MensagemService,
    private _filter: FiltrarDadosService,
    private _data: BuscarDataService) { }

  async ngOnInit() {
    this.createForm()

    await this.getConteudo()
    this.getInfoUsuario()
    this.getFotoPerfil()
    this._valid('nomeMae')
  }

  ConverterData(data) {
    return this._filter.convertCriadoEm(data)
  }

  async getInfoUsuario() {
    this.user = await this._usuario.getRegistro(sessao.cpf)
    let nasc = this._filter.convertCriadoEm(this.user.dataNascimento)
    console.log('nasc', nasc)
    this.idade = this._data.idade(nasc)
    this.dataNascimento = nasc
    let getRegistroPessoa = await this._usuario.getCadastroPessoa(sessao.cpf)
    if (getRegistroPessoa) {
      this.formulario.patchValue(getRegistroPessoa)
      this.getCidade(getRegistroPessoa.estadoNaturalidade)
      if (getRegistroPessoa.id) {
        this.modificar = true
        this.formulario.disable()
      }

    }
  }
  async getFotoPerfil() {
    this.fotoPerfil = await this.service_foto.buscarFoto(sessao.cpf)
    console.log('fotoPerfil', this.fotoPerfil)
  }
  async getConteudo() {
    this.listCorDePele = await this._conteudo.get('CORPELE')
    this.listEstadoCivil = await this._conteudo.get('ESTCIVIL')
    this.listDeficiencia = await this._conteudo.get('TIPDEF')
    this.http.get(JsonPais).toPromise().then((res: any) => this.listPaises = res)
    this.http.get(JsonEstados).toPromise().then((res: any) => this.listEstados = res.estados)
    this.operadoraSaude = await this._conteudo.get('OPESAU')
    this.operadoraSaude_bakcup = await this._conteudo.get('OPESAU')
    this.planoSaude = await this._conteudo.get('PLANOMED')
    this.planoSaude_backup = await this._conteudo.get('PLANOMED')
    this.listGrauInstrucao = await this._conteudo.get('GRINST')
    this.listCertidao = await this._conteudo.get('TPCERTID')
    this.listOrgEmissor = await this._conteudo.get('ORGEMI')
    this.listTipDoc = await this._conteudo.get('TPDOCID')
  }
  converConteudo(type, value) {
    let result = ''
    switch (type) {
      case 'CORPELE':
        result = this.listCorDePele.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
      case 'TIPDEF':
        result = this.listDeficiencia.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
      case 'ESTCIVIL':
        result = this.listEstadoCivil.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
      case 'OPESAU':
        result = this.operadoraSaude_bakcup.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
      case 'PLANOMED':
        result = this.planoSaude_backup.filter(v => v.codigoReferencia == value).map(v => v.valor)[0]
        break
    }
    return result ? result : ''
  }
  getCidade(estado) {
    let getCidades = this.listEstados.filter(value => value.sigla == estado)
    if (getCidades.length < 1) return
    this.listCidades = getCidades[0].cidades
    this.listCidadesBackup = getCidades[0].cidades

  }
  getCidadeVotacao(estado) {
    let getCidades = this.listEstados.filter(value => value.sigla == estado)
    if (getCidades.length < 1) return
    this.listCidadesVT = getCidades[0].cidades
    this.listCidadesVTBackup = getCidades[0].cidades

  }
  getCep() {
    if (this._control('cep').valid) {
      this._hub.vCEP(this._control('cep').value).toPromise()
        .then(res => {
          if (res.status) {
            this._control('bairro').setValue(res.result.bairro)
            this._control('endereco').setValue(res.result.logradouro)
            this._control('cidade').setValue(res.result.localidade)
            this._control('complemento').setValue(res.result.complemento)
            this._control('estado').setValue(res.result.uf)

          } else {
            this._control('bairro').setValue('')
            this._control('endereco').setValue('')
            this._control('cidade').setValue('')
            this._control('complemento').setValue('')
            this._control('estado').setValue('')
          }
        })
    }
  }
  async inserir() {


    if (this.formulario.invalid) {
      this.viewCampRequired = true
      // let reg = this.formulario.getRawValue()
      // Object.keys(reg).forEach(
      //   v => {
      //     if (this.formulario.get(v).invalid) { 
      //       console.log('--->',v)
      //     }
      //   }
      // )
      this._mensagem.creat('Preencha todos os campos obrigátorios', { erro: true })
      return
    }
    this.loading = true
    this.viewCampRequired = false

    let registro: CadastroPessoaForm = this.formulario.getRawValue()

    this.formatDate(registro)

    if (!registro.criadoPor) registro.criadoPor = sessao.cpf

    Object.keys(registro).forEach(value => { if (!registro[value]) delete registro[value] })

    registro.cpf = sessao.cpf
    registro.status = 'SCA0001'
    await this._usuario.cadastrarPessoa(registro).toPromise()
      .then(res => {
        this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
        this.modificar = true
        this.formulario.disable()
      })
      .catch(error => {
        this._mensagem.creat('Não foi possivel alterar registro', { erro: true })
        console.log('error', error)
      })

    this.loading = false

  }
  formatDate(v: CadastroPessoaForm) {
    v.dataEmissao_CT = this._filter.formatDate(v.dataEmissao_CT)
    v.validade = this._filter.formatDate(v.validade)
    v.dataEmissao_RG = this._filter.formatDate(v.dataEmissao_RG)
    v.dataOpcao = this._filter.formatDate(v.dataOpcao)
    console.log('valide',
      v.dataEmissao_CT,
      v.validade,
      v.dataEmissao_RG,
      v.dataOpcao)
  }
  cancelarRegistro() {
    this.modificar = true
    this.formulario.disable()
    this.getInfoUsuario()
  }
  async alterNomeSocial() {
    this.editNomeSocial = false
    this.loadingUser = true
    console.log(this.user)
    await this._usuario.alterar(this.user.id, this.user).toPromise()
      .then(res => {
        this._mensagem.creat('Nome social alterado com sucesso', { sucesso: true })
      }).catch(error => {
        this._mensagem.creat('Erro ao alterar registro', { erro: true })
        console.log('erro', error)
      })

    this.loadingUser = false

  }
  createForm() {
    this.formulario = this.formBuild.group({
      id: '',
      cpf: new FormControl(''),
      nome: new FormControl(''),
      nomeMae: new FormControl('', Validators.required), //ok
      nomePai: new FormControl(''),
      cpfMae: new FormControl(''),
      cpfPai: new FormControl(''),
      cor: new FormControl('', Validators.required), //ok
      deficiente: new FormControl(''), //ok
      tipoDeficiencia: new FormControl(''),
      sexo: new FormControl('', Validators.required), //ok
      estadoCivil: new FormControl('', Validators.required), //ok
      grauInstrucao: new FormControl(''),
      nacionalidade: new FormControl('', Validators.required), //ok
      dataChegadaBrasil: new FormControl(''),
      naturalidade: new FormControl('', Validators.required), //ok
      estadoNaturalidade: new FormControl('', Validators.required), //ok

      rg: new FormControl('', Validators.required), //ok
      dataEmissao_RG: new FormControl('', Validators.required), //ok
      tipoDocID: new FormControl(''),
      orgao: new FormControl('', Validators.required), //ok
      habilitacao: new FormControl(''),
      categoria: new FormControl(''),
      validade: new FormControl(''),
      carteiraReservista: new FormControl(''),
      tituloDeEleitor: new FormControl(''),
      tituloEleitor: new FormControl(''), ////////////Verificar
      cidadeVotacao: new FormControl(''),
      estado_TE: new FormControl(''),
      zona: new FormControl(''),
      sessao: new FormControl(''),
      ric: new FormControl(''),
      passaPorte: new FormControl(''),
      certidaoTipo: new FormControl(''),
      certidaoLivro: new FormControl(''),
      certidaoFolha: new FormControl(''),
      certidaoMatricula: new FormControl(''),

      telefone: new FormControl(''), // remover
      telefoneFixo: new FormControl(''),
      telefoneComercial: new FormControl(''),

      cep: new FormControl('', Validators.required), //ok
      endereco: new FormControl('', Validators.required),//ok
      numeroEndereco: new FormControl('', Validators.required),//ok
      complemento: new FormControl(''),
      bairro: new FormControl('', Validators.required),//ok
      cidade: new FormControl('', Validators.required),//ok
      estado: new FormControl('', Validators.required),//ok

      cartaoSUS: new FormControl(''),
      possuiPlanoDeSaude: new FormControl(false),
      planoDeSaude: new FormControl(''),
      numeroBeneficiario: new FormControl(''),
      operadoraConvenio: new FormControl(''),
      nrCarteiraTrabalho: new FormControl(''),
      serie: new FormControl(''),
      digito: new FormControl(''),
      dataEmissao_CT: new FormControl(''),
      uf_CT: new FormControl(''),
      pis: new FormControl(''),
      bancoPIS: new FormControl(''),
      contaFGTS: new FormControl(''),
      dataOpcao: new FormControl(''),

      cpfResponsavelTitular: new FormControl(''),
      cpfTutorLegal: new FormControl(''),
      cpfConjuge: new FormControl(''),
      grauParentescoSecundario: new FormControl(''),
      grauParentescoTitular: new FormControl(''),

      ativo: new FormControl(true), //Reomover
      tipoPessoa: new FormControl(''),
      usaMyClinics: new FormControl(true), //remover
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl(''),
    })
  }


  _control(control) {
    return this.formulario.controls[control]
  }
  _valid(formControlName) {
    if (formControlName == 'deficiente') {
      return true
    }

    return this.formulario.get(formControlName).value
  }
  _validControl(control) {
    return this.formulario.get(control).valid
  }
  selectOperadoraSaude(item) {
    let filter = this.planoSaude_backup.filter(value => value.dependencia == item)
    this.planoSaude = filter
  }
  filterPlanoSaude(value: string) {
    let registros = this.operadoraSaude_bakcup
    let result = registros.filter(item => item.valor.toUpperCase().includes(value.toUpperCase()))
    this.operadoraSaude = result
  }
  filtrarCidade(value: string) {
    let registros = this.listCidadesBackup
    let result = registros.filter(item => item.toUpperCase().includes(value.toUpperCase()))
    this.listCidades = result
  }
  filtrarCidadeVT(value: string) {
    let registros = this.listCidadesVTBackup
    let result = registros.filter(item => item.toUpperCase().includes(value.toUpperCase()))
    this.listCidadesVT = result
  }

}
