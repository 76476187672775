import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input('fechar') fechar: boolean
  @Input('excluir') excluir: boolean
  @Output('confirm') confirm = new EventEmitter
  @Output('exit') exit = new EventEmitter
  constructor() { }

  ngOnInit() {
  }
  Confirm() {
    this.confirm.emit()
  }
  Cancel() {
    this.exit.emit()
  }
}
