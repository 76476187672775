import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manual-usuario',
  templateUrl: './manual-usuario.component.html',
  styleUrls: ['./manual-usuario.component.scss']
})
export class ManualUsuarioComponent implements OnInit {
  text1 : boolean
  text2 : boolean
  constructor() { }

  ngOnInit() {
  }

}
