

export class AgendaForm {
     id?: string
     companyId?: string
     usuarioId?: string
     tipoAgenda?: string
     date?: string
     title?: string
     start?: string
     end?: string
     paciente?: string
     medico?: string
     confirm?= false
     cpf?: string
     block?: boolean
     videoChamada?: boolean
     confirmReserva?: boolean
     backgroundColor?: string
     createdAt?
     especialidade?: string
     motivoConsulta?: string
     color?: string
     textColor?: string
     status?: string
     codigoEmpresa?: number
     feriado?: boolean
     cpfMedico?: string
     descMotivo?: string
     ticketConsultaMedica?: string
     criadoPor?: string
}
export class MarcaConsultaForm {
     title: string
     date: string
     end: string
     start: string
     paciente: string
     medico: string
     cpf: string
     cpfMedico: string
     confirm: boolean
     confirmReserva: boolean
     backgroundColor: string
     especialidade: string
     motivoConsulta: string
     tipoConsulta: string
     formaDeConsulta: string
     tipoAtendimento: string
     crm: string
     repeticao: string
     status: string
     idTicketConsulta:string
     idTicketReserva:string
     idAgenda:string
}
export class AgendaConfigForm {
     id: string
     usuarioId: string
     timeStart: string
     timeEnd: string
     sabDom: boolean
     createdAt: string
     ativo: boolean
     cpfMedico: string
     cnpjClinica: string
     descricao: string
     start: string
     end: string
     almocoStart: string
     almocoEnd: string
     almocoTimeStart: string
     almocoTimeEnd: string
     codigoEmpresa: number
     userConfigEmpresa: boolean
     criadoEm: string
     criadoPor : string
     status : string
}
export class AgendaFeriadosForm {
     id?: string
     cpfMedico: string
     codigoEmpresa: number
     localDeTrabalho: string
     nomeFeriado: string
     dataFeriado: string
}
export class AgendaFechadaForm {
     id?: string
     index?: number
     cpfMedico: string
     codigoEmpresa?: number
     descricao: string
     start: string
     end: string
     timeStart: string
     timeEnd: string
     diaTodo: boolean
     status: string
     criadoEm: string
     criadoPor: string
}
export class AgendaHorarioDiferenteForm {
     id?: string
     index?: number
     cpfMedico: string
     cnpjClinica?: string
     localDeTrabalho: string
     start: string
     end: string
     timeStart: string
     timeEnd: string
}
export class HorariosForm {
     id?: string
     start?: string
     timeStart?: string
     timeEnd?: string
     usuarioId?: string
     companyId?: string
     pacienteId?: string
     cpf?: string
     data?: string
     h?: string
     status?: boolean
     block?: boolean
}