import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTableDataSource } from '@angular/material';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
interface FoodNode {
  name: string;
  cont?: number;
  valor?: any
  children?: FoodNode[];
}
let teste: FoodNode

let TREE_DATA: any[]

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: 'app-atendimentos-faturamento',
  templateUrl: './atendimentos-faturamento.component.html',
  styleUrls: ['./atendimentos-faturamento.component.scss']
})
export class AtendimentosFaturamentoComponent implements OnInit {
  children = {}
  TREE_DATA: any[]
  consultas = []
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      cont: node.cont,
      valor: node.valor,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  // dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  consultasFull: any[]
  encaixeFull: any[]
  encaixe: any[]
  ProntoAtendimentoFull: any[]
  ProntoAtendimento: any[]
  SAconsultasFull: any[]
  SAconsultas: any[]
  SAencaixeFull: any[]
  SAencaixe: any[]
  SAProntoAtendimentoFull: any[]
  SAProntoAtendimento: any[]
  horaMarcadaFull: any[]
  horaMarcada: any[]
  SAhoraMarcadaFull: any[]
  SAhoraMarcada: any[]
  totalConvenio: any[];
  SA: any[];
  loading: boolean
  dataHoje: string;
  data
  displayedColumns = ['servico', 'faturamento', 'atendimento']
  dataSource = new MatTableDataSource()
  constructor(private hoje: BuscarDataService) {
    this.dataHoje = this.hoje.hoje()
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  async ngOnInit() {
    this.loading = true
    await this.calcularValor('')

    let reg = [
      {servico : 'Consulta hora marcada ',faturamento : 'R$ 1.800',atendimento : '4',total : false},
      {servico : 'Consulta encaixe',faturamento : 'R$ 900',atendimento : '2',total : false},
      {servico : 'Consulta pronto atendimento',faturamento : 'R$ 450',atendimento : '1',total : false},
      {servico : 'Serviço adicional',faturamento : 'R$ 400',atendimento : '1',total : false},
      {servico : 'Total do dia',faturamento : 'R$ 3.550', atendimento : '8',total : true},
    ]
    this.dataSource = new MatTableDataSource(reg)
    // await this.treeView()

  }

  async treeView() {
    try {
      this.children =
      {
        name: 'Fruit',
        valor: '',
        children: [
          { name: 'Apple', valor: ' 5200' },
          { name: 'Banana' },
          { name: 'Fruit loops' },
        ]
      }
      this.consultasFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
        { name: "Amil", cont: '22', valor: '354' },
        { name: "Unimed", cont: '50', valor: '100' },
        { name: "Amico", cont: '10', valor: '658' },
        { name: "Golden Cross", cont: '15', valor: '32' },
        { name: "Prevent Senior", cont: '22', valor: '63' },
        { name: "Green Line", cont: '10', valor: '2010' }
      ]
      this.consultas = [
        { name: 'Dinheiro', valor: '770,00' },
        { name: 'Débito', valor: '110' },
        { name: 'Crédito', valor: '110' },
        { name: 'Convênio', cont: this.consultasFull.length, valor: this.calcularValor(''), children: this.consultasFull },
      ]
      this.SA = [
        { name: 'Dinheiro', valor: '30' },
        { name: 'Débito', valor: '50' },
        { name: 'Crédito', valor: '20' },
        { name: 'Convênio', cont: this.consultasFull.length, valor: this.calcularValor(''), children: this.consultasFull },
      ]
      this.horaMarcadaFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
        { name: "Amil", cont: '22', valor: '354' },
        { name: "Unimed", cont: '50', valor: '100' },
        { name: "Amico", cont: '10', valor: '658' },
        { name: "Golden Cross", cont: '15', valor: '32' },
        { name: "Prevent Senior", cont: '22', valor: '63' },
        { name: "Green Line", cont: '10', valor: '2010' }
      ]
      this.horaMarcada = [
        { name: 'Dinheiro', valor: '100' },
        { name: 'Débito', valor: '200' },
        { name: 'Crédito', valor: '200' },
        { name: 'Convênio', cont: this.consultasFull.length, valor: this.calcularValor(''), children: this.horaMarcadaFull },
      ]

      this.encaixeFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
      ]
      this.encaixe = [
        { name: 'Débito', valor: '110' },
        { name: 'Convenio', valor: '110', children: this.encaixeFull },
      ]

      this.ProntoAtendimentoFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
      ]
      this.ProntoAtendimento = [
        { name: 'Débito', valor: '110' },
        { name: 'Convenio', valor: '110', children: this.ProntoAtendimentoFull },
      ]

      this.SAconsultasFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
        { name: "Amil", cont: '22', valor: '354' },
        { name: "Unimed", cont: '50', valor: '100' },
        { name: "Amico", cont: '10', valor: '658' },
        { name: "Golden Cross", cont: '15', valor: '32' },
        { name: "Prevent Senior", cont: '22', valor: '63' },
        { name: "Green Line", cont: '10', valor: '2010' }
      ]
      this.SAconsultas = [
        { name: 'Dinheiro', valor: '770,00' },
        { name: 'Débito', valor: '110' },
        { name: 'Crédito', valor: '110' },
        { name: 'Convênio', cont: this.consultasFull.length, valor: '110', children: this.consultasFull },

      ]
      this.SAhoraMarcadaFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
        { name: "Amil", cont: '22', valor: '354' },
        { name: "Unimed", cont: '50', valor: '100' },
        { name: "Amico", cont: '10', valor: '658' },
        { name: "Golden Cross", cont: '15', valor: '32' },
        { name: "Prevent Senior", cont: '22', valor: '63' },
        { name: "Green Line", cont: '10', valor: '2010' }
      ]
      this.SAhoraMarcada = [
        { name: 'Dinheiro', valor: '770,00' },
        { name: 'Débito', valor: '110' },
        { name: 'Crédito', valor: '110' },
        { name: 'Convênio', cont: this.SAhoraMarcadaFull.length, valor: '110', children: this.SAhoraMarcadaFull },
      ]

      this.SAencaixeFull = [
        { name: "Bradesco", cont: '1', valor: '2354' },
        { name: "Bradesco", cont: '1', valor: '2354' },
      ]
      this.SAencaixe = [
        { name: 'Débito', valor: '110' },
        { name: 'Convenio', valor: '110', children: this.encaixeFull },
      ]

      this.SAProntoAtendimentoFull = [
        { name: "Bradesco", cont: '1', valor: '110' },
      ]
      this.SAProntoAtendimento = [
        { name: 'Débito', valor: '110' },
        { name: 'Convenio', valor: '110', children: this.ProntoAtendimentoFull },
      ]



      this.TREE_DATA = [
        {
          name: 'Consultas:',
          cont: this.consultas.length,
          valor: this.calcularValor('consulta'),
          children: [
            {
              name: 'Hora marcada:',
              cont: this.horaMarcada.length,
              valor: this.calcularValor('horaMarcada'),
              children: this.horaMarcada
            },
            {
              name: 'Encaixe:',
              cont: this.encaixe.length,
              valor: this.calcularValor('encaixe'),
              children: this.encaixe
            },
            {
              name: 'Pronto Atendimento:',
              cont: this.ProntoAtendimento.length,
              valor: this.calcularValor('prontoAtendimento'),
              children: this.ProntoAtendimento
            },
          ]
        }, {
          name: 'Serviços Adicionais: ',
          cont: this.SA.length,
          valor: 1540,
          children: [
            {
              name: 'Hora marcada:',
              cont: this.SAhoraMarcada.length,
              valor: this.calcularValor('saHoraMarcada'),
              children: this.SAhoraMarcada
            },
            {
              name: 'Encaixe:',
              cont: this.SAencaixe.length,
              valor: this.calcularValor('saEncaixe'),
              children: this.SAencaixe
            },
            {
              name: 'Pronto Atendimento:',
              cont: this.SAProntoAtendimento.length,
              valor: this.calcularValor('saProntoAtendimento'),
              children: this.SAProntoAtendimento
            },
          ]
        },
      ];
      this.dataSource.data = this.TREE_DATA;
      this.dataSource.data.length
      // this.calcularValor()
    }
    catch {

    }

  }
  calcularValor(ref) {
    let valores = []
    let total = 0
    switch (ref) {
      case 'consulta':
        this.consultas.forEach(v => {
          valores.push(v.valor)
        });
        break
      case 'horaMarcada':
        this.horaMarcada.forEach(v => {
          valores.push(v.valor)
        });
        break
      case 'encaixe':
        this.encaixe.forEach((v: any) => {
          valores.push(v.valor)
        });
        break
      case 'convenio':
        this.encaixeFull.forEach((v: any) => {
          valores.push(v.valor)
        });
        break
      case 'prontoAtendimento':
        this.ProntoAtendimento.forEach((v: any) => {
          valores.push(v.valor)
        });
        break
      case 'saProntoAtendimento':
        this.SAProntoAtendimento.forEach((v: any) => {
          valores.push(v.valor)
        });
        break
      case 'saEncaixe':
        this.SAencaixe.forEach((v: any) => {
          valores.push(v.valor)
        });
        break
      case 'saHoraMarcada':
        this.SAhoraMarcada.forEach((v: any) => {
          valores.push(v.valor)
        });
        break
      case 'SA':
        this.SA.forEach((v: any) => {
          valores.push(v.valor)
        });

        break
    }
    for (let i = 0; i < valores.length; i++) {
      total += parseInt(valores[i])
    }

    return total
  }

}



