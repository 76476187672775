export class FormCobranca {
    id?: string
    usuarioId?: string
    companyId?: string
    pacienteId ?: string
    paciente ?: string
    quantidade ?: string
    valorUni ?: string
    descricao ?: string
    valor ?: string
    total ?: string
    cpf ?: string
    dataSolicitacao? : string
    ativo?: boolean
}
export class FormValorVenda {
    id?: string
    usuarioId?:string
    companyId?: string
    descricao?: string
    valor?: string
    createdAt?: string
    ativo?: boolean
}