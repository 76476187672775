import { Component, OnInit, ViewChild } from '@angular/core';
import { ProntuarioService } from 'src/app/service/prontuario.service';
import { formLiberarProntuario } from './model';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/service/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormUsuario } from 'src/app/usuario/model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FotoPerfilService } from 'src/app/service/foto-perfil.service';
import { CadastroPessoaForm } from 'src/app/cadastro-paciente/model';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MatTableDataSource } from '@angular/material';
import { MensagemService } from 'src/app/service/mensagem.service';


@Component({
  selector: 'app-liberar-acesso-prontuario',
  templateUrl: './liberar-acesso-prontuario.component.html',
  styleUrls: ['./liberar-acesso-prontuario.component.scss']
})
export class LiberarAcessoProntuarioComponent implements OnInit {

  displayedColumns: string[] = ['detalhe', 'dataLiberacao', 'cpf', 'nome', 'parcial'];
  displayedColumnsProntuario: string[] = ['checked', 'nomeMedico', 'especialidadeProfissional', 'dataInclusaoDocumento'];
  dataSource = new MatTableDataSource()
  dataSourceProntuario = new MatTableDataSource()
  loading: boolean;
  formulario: FormGroup
  viewCadastro: boolean;
  pacienteLoading: boolean;
  fotoPerfilPaciente: any;
  paciente = new CadastroPessoaForm;
  pacienteSemCPF: boolean;
  constructor(
    private service: ProntuarioService,
    private _usuario: UsuarioService,
    private service_foto: FotoPerfilService,
    private formBuilder: FormBuilder,
    private _filter: FiltrarDadosService,
    private _massage: MensagemService) {
  }

  async ngOnInit() {
    this.creatForm()
    this.getRegistro()
  }
  getRegistro() {
    this.service.listarLiberarProntuario().toPromise()
      .then(res => {
        console.log('---', res)
        this.dataSource = new MatTableDataSource(res as any)
      })
  }

  async getPaciente() {
    this.pacienteLoading = true
    if (this._control('acesso').invalid || this._control('acesso').disabled && this._controlValue('acesso')) {
      this.fotoPerfilPaciente = null
      this._control('paciente').reset()
      return
    }
    try {
      this.paciente = await this._usuario.getCadastroPessoa(this._controlValue('acesso'))

      if (this.paciente.cpf) {
        this.pacienteSemCPF = false
        this._control('paciente').setValue(this.paciente.nomeCompleto)
        this.getProntuarios(this.paciente.cpf)
      } else {
        this.pacienteSemCPF = true
      }
    } catch { }
    this.fotoPerfilPaciente = await this.service_foto.buscarFoto(this.paciente.cpf)
  }
  async getProntuarios(cpf) {
    const data = await this.service.get(cpf)
    this.dataSourceProntuario = new MatTableDataSource(data)

  }
  openCadastro(){

  }
  inserir() {
    const registro = this.formulario.getRawValue()
    if (this.formulario.invalid) {
      this._massage.creat('Preencha todos os campos obrigatórios antes de salvar', { aviso: true })
      return
    }
    delete registro.paciente
    delete registro.id

    this.service.cadastrarLiberarProntuario(registro)
      .toPromise()
      .then(res => {
        this._massage.creat('Cadastro Realizado com sucesso', { sucesso: true })
        this.viewCadastro = false
        this.getRegistro()
      })
      .catch((error) => {
        console.log('---', error)
        this._massage.creat('Não foi possível realizar cadastro', { erro: true })
      })


  }
  alterar() { }
  selectProntuario(checked: boolean, item: any) {
    if (checked) {
      this._control('prontuarioId').setValue(item.id)
    } else {
      this._control('prontuarioId').reset()
    }
  }
  creatForm() {
    this.formulario = this.formBuilder.group({
      id: new FormControl(''),
      prontuarioId: new FormControl(''),
      dataSolicitacao: new FormControl(''),
      dataLiberacao: new FormControl(''),
      acesso: new FormControl(''),
      view: new FormControl(false),
      parcial: new FormControl(false),
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl(''),
      paciente: ''
    })
  }
  _control(value) {
    try {
      return this.formulario.get(value)
    } catch {
      return null
    }
  }
  _controlValue(value) {
    let keys = this.formulario.getRawValue()
    try {
      return keys[value]
    }
    catch {
      return ''
    }
  }
  fecharCadastro() {
    this.viewCadastro = false
  }
  openNewCadastro() {
    this.viewCadastro = true
  }
}
