export class formParecerMedico {
    id?: string
    usuarioId: string
    companyId: string
    pacienteId: string
    data: string
    hipoteseDiagnostica: string
    diagnostico: string
    prognostico: string
    sequelas: string
    causasMortais: string
}