import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { dadosCadastroCentroCusto } from '../cadastro-centro-de-custo-completo/modelsCadastroCentroDeCustoCompleto';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { CadastroOperadoraCartaoComponent } from '../ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.component';
import { CadastroOperadoraCartao } from '../ajustes/cadastros-mestres/cadastro-operadora-cartao/cadastro-operadora-cartao.model';


const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})
export class CadastroCentroCustoService {
    url = new environment().api
    api = "CadastroCentroCusto/"

    constructor(private http: HttpClient, private data: BuscarDataService) { }

    listar() {
        return this.http.get<dadosCadastroCentroCusto[]>(this.url + this.api)
    }
    async get(codigoEmpresa) {
        let result = []
        await this.http.get<dadosCadastroCentroCusto[]>(this.url + this.api + 'FiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
            .then(res => {
                if (res.length > 0) {
                    result = res
                }
            })
        return result
    }

    enviar(registro: dadosCadastroCentroCusto) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: dadosCadastroCentroCusto) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: dadosCadastroCentroCusto, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        }
        else {
            registro.status = 'SDA0002'
        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }


    /*
    getCadastroCentroCustoCodigoEmpresa(codigoEmpresa: any): Observable<Object> {
        return this.http.get(this.url + this.api + "FiltrarCodigoEmpresa/" + codigoEmpresa)
    
    }

      postCadastroCentroCusto(dados: any): Observable<Object> {
         return this.http.post(this.url + this.apiCadastroCentroCusto, dados)
     }
 
}
 
     getCadastroCentroCustoID(id: any): Observable<Object> {
         return this.http.get(this.url + this.apiCadastroCentroCusto + id)
     }
 
     PutCadastroCentroCusto(id: any, userId: any, data: string, dados) {
         console.log("Link api: " + `${this.url}${this.apiCadastroCentroCusto}${id}/${userId}/${data}`, dados)
         return this.http.put(`${this.url}${this.apiCadastroCentroCusto}${id}/${userId}/${data}`, dados);
     }*/
}


