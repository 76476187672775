import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AgendaService } from "./agenda.service";
import { AgendaForm, HorariosForm } from "../agenda/agenda/model";
import { SalaEsperaService } from "./sala-espera.service";
import { SalaEsperaForm } from "../atendimento-clinico/sala-espera/model";
import { AtendimentoClinicoService } from "./atendimento-clinico.service";
import { formProcedimentoAdicional } from "../atendimento-clinico/conduta-medica/model";
import { ProntuarioService } from "./prontuario.service";
import { formLiberarProntuario } from "../meu-consultorio/liberar-acesso-prontuario/model";
import { BuscarDataService } from "./buscar-data.service";
import { Observable } from "rxjs";
import { async } from "@angular/core/testing";
import * as moment from "moment";
import { SessaoUser } from "../user.reg";
import { TicketReserva } from "../home/monitor-clinico/consultas/solicitacoes/modal";
import { REQUEST } from "./modal";

const sessao = new SessaoUser();
const emp = sessao.codigoEmpresa;
type MonitorClinico =
  | "solicitacoes"
  | "encerramento"
  | "solicitacaoDeAcesso"
  | "aConfirmar"
  | "aConfirmadas"
  | "canceladas"
  | "proximasConsultas"
  | "conectAguardandoResposta"
  | "conectAguardandoAcesso";

@Injectable({
  providedIn: "root",
})
export class MonitorClinicoService {
  url = new environment().api;
  api = "MonitorClinico/";
  monitorClinico = {
    solicitacoes: `Solicitacoes`,
    encerramento: `Encerramento`,
    solicitacaoDeAcesso: `SolicitacaoDeAcesso`,
    aConfirmar: `AConfirmar`,
    aConfirmadas: `Confirmadas`,
    canceladas: `Canceladas`,
    proximasConsultas: `ProximasConsultas`,
    conectAguardandoResposta: `ConectAguardandoResposta`,
    conectAguardandoAcesso: `ConectAguardandoAcesso`,
  };

  constructor(private http: HttpClient, private _data: BuscarDataService) {}
  get(type: MonitorClinico): Promise<Array<any>> {
    return new Promise((result, reject) => {
      this.http
        .get<REQUEST<any>>(
          `${this.url}${this.api}${this.monitorClinico[type]}/${sessao.codigoEmpresa}`
        )
        .subscribe(
          (res) => {
            if (res.success) result(res.result);
            else result([]);
          },
          (error) => {
            reject([]);
          }
        );
    });
  }

  getTicket() {
    return this.http.get<TicketReserva[]>(
      this.url + "TicketReservaConsultaMedica"
    );
  }
}
