import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SessaoUser } from '../user.reg';
import { MensagemService } from './mensagem.service';

const sessao = new SessaoUser
@Injectable({
    providedIn: 'root'
})
export class ControleSenhaAtendimentoService {
    url = new environment().api
    controleSenhaAtendimento = "ControleSenhaAtendimento/"
    constructor(private http: HttpClient, private mensagem: MensagemService) {
    }

    getDadosControleSenhaAtendimento(): Observable<Object> {
        return this.http.get(this.url + this.controleSenhaAtendimento + "join")
    }
    async get() {
        let result = []
        await this.http.get<any>(this.url + this.controleSenhaAtendimento + "joinSenha/" + sessao.assinante).toPromise()
            .then(res => {
                try {
                    if (res.success) {
                        result = res.result
                    }
                } catch {
                    this.mensagem.creat("Impossível trazer os dados do Controle de Senha no momento, por favor, tente novamente mais tarde.", { aviso: true })
                }

            }).catch(error => {
                console.log(error)
                this.mensagem.creat("Impossível trazer os dados do Controle de Senha no momento, por favor, tente novamente mais tarde.", { aviso: true })
            })
        return result
    }
    async resetSenha(codigoEmpresa: number) {
        let result = false
        await this.http.get<any>(this.url + this.controleSenhaAtendimento + 'ReiniciarSenha/' + codigoEmpresa).toPromise()
            .then(res => {
                if (res.success) {
                    result = true
                }
            })
        return result
    }

    AlterarControleSenhaAtendimento(id: string, dados): Observable<Object> {
        return this.http.put(this.url + this.controleSenhaAtendimento + `${id}/${sessao.sessao_id}/${new Date().toISOString()}`, dados)
    }

    getControleSenhaAtendimentoID(id: string): Observable<Object> {
        return this.http.get(this.url + this.controleSenhaAtendimento + id)
    }

    PostControleSenhaAtendimento(dados): Observable<Object> {
        dados.criadoPor = sessao.cpf
        console.log(this.url + this.controleSenhaAtendimento, dados)
        return this.http.post(this.url + this.controleSenhaAtendimento, dados)
    }

}


