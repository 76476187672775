import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidateBrService } from 'angular-validate-br';
import { UsuarioService } from 'src/app/service/usuario.service';
import { environment } from 'src/environments/environment';
const url = new environment().api
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  formulario: FormGroup
  passwordError: string;
  mensagemError: string;
  key: string;
  error: boolean;
  errorForm: boolean;
  viewpassword: boolean;
  registro: {
    id: string,
    cpf: string,
    senhaGerada: string,
    ip: string,
    logradouro: string,
    criadoEm: string,
    status: string
  };
  nome : string
  erroAll: boolean;
  loading: boolean;
  success: boolean;
  emailConfirmado : boolean
  constructor(
    private form: FormBuilder,
    private ValidBr: ValidateBrService,
    private http: HttpClient,
    private router: ActivatedRoute,
    private _user: UsuarioService,
    private nav: Router) { }

  async ngOnInit() {
    this.creatForm()
    this.key = this.router.snapshot.params.id
    try {
      await this.http.get<any>(url + 'ResetPassword/' + this.key).toPromise()
        .then(res => {
          this.registro = res
        }).catch()
    } catch {
      this.nav.navigateByUrl('')
    }
    console.log('registro', this.registro)

  }
  creatForm() {
    this.formulario = this.form.group({
      codigo: new FormControl('', Validators.required),
      cpf: new FormControl('', [Validators.required, this.ValidBr.cpf]),
      password: new FormControl('', Validators.required),
      repassword: new FormControl('', Validators.required)
    })
  }
  async confirm() {
    this.success = false
    this.errorForm = true
    if (this.formulario.get('cpf').invalid && this.formulario.get('cpf').value) {
      this.mensagemError = "CPF Inválido"
      return
    }
    if (this.formulario.invalid) {
      this.mensagemError = "Por favor preencha todos os campos antes de continuar"
      return
    }
    let pass = this.formulario.get('password').value
    let repass = this.formulario.get('repassword').value
    if (pass != repass) {
      this.errorForm = true
      this.mensagemError = "Repita sua senha corretamente"
      return
    }

    const form: { codigo: string, cpf: string, password: string, repassword: string } = this.formulario.value

    if (form.codigo != this.registro.senhaGerada || form.cpf != this.registro.cpf) {
      this.mensagemError = 'Não foi possivel realizar procedimento. Dado informado inválido'
      return
    }

    this.loading = true
    await this.http.post(url + 'v1/resetarsenha/', { usuario: form.cpf, newPassword: form.password }).toPromise()
      .then(async () => {
        this.success = true
        let regUser = await this._user.getRegistro(form.cpf)
        regUser.status = 'SDO0060'
        await this._user.alterar(regUser.id, regUser).toPromise().then()
      }).catch((error) => {
      })
    await this.http.delete(url + 'ResetPassword/' + this.key).toPromise().then().catch(() => { })
    this.success = true
    this.errorForm = false
    this.loading = false

  }
  validPassword(value) {
    this.passwordError = null
    if (!/[A-Z]/gm.test(value)) {
      this.passwordError = 'Sua senha deve contar ao menos uma letra maiúscula'
      return
    }
    if (!/[0-9]/gm.test(value)) {
      this.passwordError = 'Sua senha deve contar ao menos um numero'
      return
    }
    if (!/[!@#$%*()_+^&{}}:;?.]/gm.test(value)) {
      this.passwordError = 'Sua senha deve contar ao menos um caracter especial'
      return
    }

  }
  verificRePassword() {
    let result = false
    this.errorForm = false
    let pass = this.formulario.get('password').value
    let repass = this.formulario.get('repassword').value
    if (pass != repass) {
      this.errorForm = true
      this.mensagemError = "Repita sua senha corretamente"
      result = true
    }
    return result
  }
  _valid(control) {
    return this.formulario.get(control).hasError('required')
  }

}
