import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { formFichaConsultaMedica } from '../atendimento-clinico/model';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoClinicoService {
  url = new environment().api
  url_local = new environment().api_local
  api = 'AtendimentoClinico/';
  api_IndicacaoTratamento = "IndicacaoTratamento/";
  api_SolicitacaoExames = "SolicitacaoExames/";
  api_Receituario = "Receituario/"
  api_ProcedimentoAdicional = "ProcedimentoAdicional/"
  api_Cirurgias = "Cirurgias/"
  api_ParecerMedico = "ParecerMedico/"
  api_CobrancaAdicional = "CobrancaAdicional/"
  api_Laboratorios = "Laboratorios/"
  api_ficha = "FichaConsultaMedica/"
  constructor(private http: HttpClient) { }

  //************************** FichaConsultaMedica **********************************
  listarFichaConsultaMedica(id?) {
    console.log('FichaConsultaMedica :' + this.url + this.api_ficha)
    if (id) {
      return this.http.get(this.url + this.api_ficha + id)
    }
    return this.http.get(this.url + this.api_ficha)
  }
  cadastrarFichaConsultaMedica(dados: formFichaConsultaMedica) {
    console.log('FichaConsultaMedica-Cadastrar :' + this.url + this.api_ficha, dados)
    return this.http.post(this.url + this.api_ficha, dados)

  }
  alterarFichaConsultaMedica(id: string, dados) {
    console.log('FichaConsultaMedica-Alterar :' + this.url + this.api_ficha + id, dados)
    return this.http.put(this.url + this.api_ficha + id, dados)
  }
  excluirFichaConsultaMedica(id: string, dados?) {
    console.log('FichaConsultaMedica-Excluir :' + this.url + this.api_ficha + id)
    return this.http.delete(this.url + this.api_ficha + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END FichaConsultaMedica **********************************  


  //************************** AtendimentoClinico **********************************
  listarAtendimentoClinico(id?) {
    console.log('AtendimentoClinico :' + this.url + this.api)
    if (id) {
      return this.http.get(this.url + this.api + id)
    }
    return this.http.get(this.url + this.api)
  }
  cadastrarAtendimentoClinico(dados: any) {
    console.log('AtendimentoClinico-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)

  }
  alterarAtendimentoClinico(id: string, dados) {
    console.log('AtendimentoClinico-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirAtendimentoClinico(id: string, dados?) {
    console.log('AtendimentoClinico-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END AtendimentoClinico **********************************  

  //************************** IndicacaoTratamento **********************************
  listarIndicacaoTratamento(id?) {
    console.log('IndicacaoTratamento :' + this.url + this.api_IndicacaoTratamento)
    if (id) {
      return this.http.get(this.url + this.api_IndicacaoTratamento + id)
    }
    return this.http.get(this.url + this.api_IndicacaoTratamento)
  }
  cadastrarIndicacaoTratamento(dados: any) {
    console.log('IndicacaoTratamento-Cadastrar :' + this.url + this.api_IndicacaoTratamento, dados)
    return this.http.post(this.url + this.api_IndicacaoTratamento, dados)

  }
  alterarIndicacaoTratamento(id: string, dados) {
    console.log('IndicacaoTratamento-Alterar :' + this.url + this.api_IndicacaoTratamento + id, dados)
    return this.http.put(this.url + this.api_IndicacaoTratamento + id, dados)
  }
  excluirIndicacaoTratamento(id: string, dados?) {
    console.log('IndicacaoTratamento-Excluir :' + this.url + this.api_IndicacaoTratamento + id)
    return this.http.delete(this.url + this.api_IndicacaoTratamento + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END IndicacaoTratamento **********************************

  //************************** SolicitacaoExames **********************************
  listarSolicitacaoExames(id?) {
    console.log('SolicitacaoExames :' + this.url + this.api_SolicitacaoExames)
    if (id) {
      return this.http.get(this.url + this.api_SolicitacaoExames + id)
    }
    return this.http.get(this.url + this.api_SolicitacaoExames)
  }
  cadastrarSolicitacaoExames(dados: any) {
    console.log('SolicitacaoExames-Cadastrar :' + this.url + this.api_SolicitacaoExames, dados)
    return this.http.post(this.url + this.api_SolicitacaoExames, dados)

  }
  alterarSolicitacaoExames(id: string, dados) {
    console.log('SolicitacaoExames-Alterar :' + this.url + this.api_SolicitacaoExames + id, dados)
    return this.http.put(this.url + this.api_SolicitacaoExames + id, dados)
  }
  excluirSolicitacaoExames(id: string, dados?) {
    console.log('SolicitacaoExames-Excluir :' + this.url + this.api_SolicitacaoExames + id)
    return this.http.delete(this.url + this.api_SolicitacaoExames + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END SolicitacaoExames **********************************

  //************************** Receituario **********************************
  listarReceituario(id?) {
    console.log('Receituario :' + this.url + this.api_Receituario)
    if (id) {
      return this.http.get(this.url + this.api_Receituario + id)
    }
    return this.http.get(this.url + this.api_Receituario)
  }
  cadastrarReceituario(dados: any) {
    console.log('Receituario-Cadastrar :' + this.url + this.api_Receituario, dados)
    return this.http.post(this.url + this.api_Receituario, dados)

  }
  alterarReceituario(id: string, dados) {
    console.log('Receituario-Alterar :' + this.url + this.api_Receituario + id, dados)
    return this.http.put(this.url + this.api_Receituario + id, dados)
  }
  excluirReceituario(id: string, dados?) {
    console.log('Receituario-Excluir :' + this.url + this.api_Receituario + id)
    return this.http.delete(this.url + this.api_Receituario + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END Receituario **********************************

  //************************** ProcedimentoAdicional **********************************
  listarProcedimentoAdicional(id?) {
    console.log('ProcedimentoAdicional :' + this.url + this.api_ProcedimentoAdicional)
    if (id) {
      return this.http.get(this.url + this.api_ProcedimentoAdicional + id)
    }
    return this.http.get(this.url + this.api_ProcedimentoAdicional)
  }
  cadastrarProcedimentoAdicional(dados: any) {
    console.log('ProcedimentoAdicional-Cadastrar :' + this.url + this.api_ProcedimentoAdicional, dados)
    return this.http.post(this.url + this.api_ProcedimentoAdicional, dados)

  }
  alterarProcedimentoAdicional(id: string, dados) {
    console.log('ProcedimentoAdicional-Alterar :' + this.url + this.api_ProcedimentoAdicional + id, dados)
    return this.http.put(this.url + this.api_ProcedimentoAdicional + id, dados)
  }
  excluirProcedimentoAdicional(id: string, dados?) {
    console.log('ProcedimentoAdicional-Excluir :' + this.url + this.api_ProcedimentoAdicional + id)
    return this.http.delete(this.url + this.api_ProcedimentoAdicional + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END ProcedimentoAdicional **********************************


  //************************** Cirurgias **********************************
  listarCirurgias(id?) {
    console.log('Cirurgias :' + this.url + this.api_Cirurgias)
    if (id) {
      return this.http.get(this.url + this.api_Cirurgias + id)
    }
    return this.http.get(this.url + this.api_Cirurgias)
  }
  cadastrarCirurgias(dados: any) {
    console.log('Cirurgias-Cadastrar :' + this.url + this.api_Cirurgias, dados)
    return this.http.post(this.url + this.api_Cirurgias, dados)

  }
  alterarCirurgias(id: string, dados) {
    console.log('Cirurgias-Alterar :' + this.url + this.api_Cirurgias + id, dados)
    return this.http.put(this.url + this.api_Cirurgias + id, dados)
  }
  excluirCirurgias(id: string, dados?) {
    console.log('Cirurgias-Excluir :' + this.url + this.api_Cirurgias + id)
    return this.http.delete(this.url + this.api_Cirurgias + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END Cirurgias **********************************

  //************************** ParecerMedico **********************************
  listarParecerMedico(id?) {
    console.log('ParecerMedico :' + this.url + this.api_ParecerMedico)
    if (id) {
      return this.http.get(this.url + this.api_ParecerMedico + id)
    }
    return this.http.get(this.url + this.api_ParecerMedico)
  }
  cadastrarParecerMedico(dados: any) {
    console.log('ParecerMedico-Cadastrar :' + this.url + this.api_ParecerMedico, dados)
    return this.http.post(this.url + this.api_ParecerMedico, dados)

  }
  alterarParecerMedico(id: string, dados) {
    console.log('ParecerMedico-Alterar :' + this.url + this.api_ParecerMedico + id, dados)
    return this.http.put(this.url + this.api_ParecerMedico + id, dados)
  }
  excluirParecerMedico(id: string, dados?) {
    console.log('ParecerMedico-Excluir :' + this.url + this.api_ParecerMedico + id)
    return this.http.delete(this.url + this.api_ParecerMedico + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END ParecerMedico **********************************


  //************************** CobrancaAdicional **********************************
  listarCobrancaAdicional(companyId, id?) {
    // console.log('CobrancaAdicional :' + this.url + this.api_CobrancaAdicional)
    if (id) {
      return this.http.get(this.url + this.api_CobrancaAdicional + id)
    }
    // console.log('CobrancaAdicional :' + this.url + this.api_CobrancaAdicional + `${companyId}/0`)
    return this.http.get(this.url + this.api_CobrancaAdicional + `${companyId}/0`)
  }
  cadastrarCobrancaAdicional(dados: any) {
    console.log('CobrancaAdicional-Cadastrar :' + this.url + this.api_CobrancaAdicional, dados)
    return this.http.post(this.url + this.api_CobrancaAdicional, dados)

  }
  alterarCobrancaAdicional(id: string, dados) {
    console.log('CobrancaAdicional-Alterar :' + this.url + this.api_CobrancaAdicional + id, dados)
    return this.http.put(this.url + this.api_CobrancaAdicional + id, dados)
  }
  excluirCobrancaAdicional(id: string, dados?) {
    console.log('CobrancaAdicional-Excluir :' + this.url + this.api_CobrancaAdicional + id)
    return this.http.delete(this.url + this.api_CobrancaAdicional + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END CobrancaAdicional **********************************

  //************************** Laboratorio **********************************
  getLaboratorios() {
    return this.http.get<any[]>(this.url + this.api_Laboratorios)
  }
  //************************** END Laboratorio **********************************
}
