import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CadastroDadosProfissionais } from 'src/app/admin-usuarios/model';
import { CadastroDadosProfissionaisService } from 'src/app/service/cadastro-dados-profissionais.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
const JsonEstados = './assets/json/json-estados.json'
@Component({
  selector: 'app-cadastro-profissional',
  templateUrl: './cadastro-profissional.component.html',
  styleUrls: ['./cadastro-profissional.component.scss']
})
export class CadastroProfissionalComponent implements OnInit {
  viewCadastro: boolean;
  listTipoInscricao: ConteudoDados[] = []
  listProfissao: ConteudoDados[] = []
  listEspecialidade: ConteudoDados[] = []
  listTipoTratamento: ConteudoDados[] = []
  listTipoSituacao: ConteudoDados[] = []
  formulario: FormGroup
  listEstados = []
  loadingConteudo: boolean;
  dataSource: CadastroDadosProfissionais[] = [];
  alteracao: boolean;
  loading: boolean;
  constructor(
    private _conteudo: ConteudoDeDadosService,
    private http: HttpClient,
    private form: FormBuilder,
    private _service: CadastroDadosProfissionaisService,
    private _mensagem: MensagemService) { }

  async ngOnInit() {
    await this.creatForm()
    await this.getConteudo()
    await this.getRegistro()
  }
  async getConteudo() {
    this.formulario.disable()
    this.loadingConteudo = true
    this.listProfissao = await this._conteudo.get('CADPRO')
    this.listEspecialidade = await this._conteudo.get('ESPPRO')
    this.listTipoTratamento = await this._conteudo.get('FORTRA')
    this.listTipoInscricao = await this._conteudo.get('TIPREP')
    this.listTipoSituacao = await this._conteudo.get('CLAREG')
    await this.http.get(JsonEstados).toPromise().then((res: any) => this.listEstados = res)
    this.loadingConteudo = false
    this.formulario.enable()
  }
  async getRegistro() {
    this.dataSource = await this._service.getCadastroDadosProfissionaisCpf(sessao.cpf)
  }

  async inserir() {
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigátorios ', { erro: true })
      return
    }
    this.loading = true
    let registro: CadastroDadosProfissionais = this.formulario.getRawValue()
    Object.keys(registro).forEach(v => { if (!registro[v]) delete registro[v] })

    let result = await this._service.cadastrar(registro)

    if (result) {
      if (registro.id) {
        this._mensagem.creat('Dado Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
        this.alteracao = true
      }
      else {
        this._mensagem.creat('Dado criado com sucesso', { sucesso: true })
      }
    } else {
      this._mensagem.creat('Não foi possível realizar cadastro: ' + result.mensage, { erro: true })
    }
    this.loading = false

  }
  openNewCadastro() {
    this.viewCadastro = true
    this.alteracao = false
    this.formulario.enable()
    this.formulario.reset()
  }
  fecharCadastro() {
    this.viewCadastro = false
    this.getRegistro()
  }
  openCadastro(registro) {
    this.formulario.patchValue(registro)
    this.viewCadastro = true
    this.formulario.disable()
    this.alteracao = true

  }
  async excluirRegistro() {
    let registro = this.formulario.value
    await this._service.delete(registro)
    this._mensagem.creat('Dado removido com sucesso', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
  }
  cancelarRegistro() {
    this._service.getDadosProfissionaisID(this._control('id').value).toPromise()
      .then(res => {
        this.formulario.patchValue(res)
        this.formulario.disable()
      })
  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: new FormControl(''),
      cpf: new FormControl(''),
      profissao: new FormControl('', Validators.required),
      especialidadeProfissional: new FormControl(''),
      nr_Registro: new FormControl(''),
      tipoInscricao: new FormControl('', Validators.required),
      situacao: new FormControl('', Validators.required),
      formaDeTratamento: new FormControl('', Validators.required),
      unidadeFederacao: new FormControl('', Validators.required),
      status: new FormControl('SDO0113'),
    })
  }
  _control(control) {
    return this.formulario.controls[control]
  }
  _valid(control) {
    return this.formulario.get(control).hasError('required')
  }
}
