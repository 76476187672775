export class FormPaciente {
  id: string
  usuarioId: string
  companyId: string
  pacienteId?: string
  nome: string
  paciente?: string
  email: string
  cpf: string
  rg: string
  nascimento: string
  sexo: string
  tratamento: string
  telefone: string
  telefone2: string
  celular: string
  convenio: string
  medico: string
  tipo: string
  cor: string
  pis: string
  cartaoSus: string
  rne: string
  nacionalidade: string
  naturalidade: string
  nomeConjugue: string
  civil: string
  especial: boolean
  localNascimento: string
  formaPag: string
  situacao: string
  peso: string
  responsavel: string
  rgResponsavel: string
  cpfResponsavel: string
  indicacao: string
  pai: string
  mae: string
  atividadePai: string
  atividadeMae: string
  endereco: string
  numero: string
  bairro: string
  cidade: string
  estado: string
  cep: string

  planoSaude: string
  operadora: string
  numeroBenificiario: string
  modalidade: string
  numeroCarteirinha: string

  Bandeira: string
  numeroCartao: string
  validade: string
  cvv: string
  pais: string
  createdAt: string
  ativo: boolean
}
export class CadastroPessoaForm {
  id: string
  usuarioId: string
  numeroDocumento: string
  dataInclusaoDocumento: string
  cpf: string
  nomeCompleto?: string
  dataNascimento?: string
  cpfPai: string
  cpfMae: string
  cartaoSUS: string
  possuiPlanoDeSaude: boolean
  planoDeSaude: string
  numeroBeneficiario: string
  cep: string
  endereco: string
  numeroEndereco: string
  bairro: string
  complemento: string
  cidade: string
  estado: string
  tipoPessoa: string
  usaMyClinics: boolean
  tipoDocID: boolean
  ativo: boolean
  operadoraConvenio: string
  sexo: string
  estadoCivil: string
  grauParentescoTitular: string
  grauParentescoSecundario: string
  telefone: string
  cpfConjuge: string
  cpfTutorLegal: string
  cpfResponsavelTitular: string
  rg: string
  tituloDeEleitor: string
  numeroPIS: string
  naturalidade: string
  estadoNaturalidade: string
  telefoneFixo: string
  telefoneCelular: string
  telefoneComercial: string
  nomePai: string
  nomeMae: string
  cor: string
  deficiente: boolean
  tipoDeficiencia: string
  nacionalidade: string
  dataChegadaBrasil: string
  dataEmissao_RG: string
  orgao: string
  habilitacao: string
  categoria: string
  validade: string
  nrCarteiraTrabalho: string
  serie: string
  digito: string
  dataEmissao_CT: string
  uf_CT: string
  carteiraReservista: string
  tituloEleitor: string
  cidadeVotacao: string
  estado_TE: string
  zona: string
  sessao: string
  grauInstrucao: string
  ric: string
  passaPorte: string
  pis: string
  bancoPIS: string
  contaFGTS: string
  dataOpcao: string
  certidaoTipo: string
  certidaoLivro: string
  certidaoFolha: string
  certidaoMatricula: string
  criadoPor: string
  criadoEm: string
  status: string
  idade? : any
  backgroud?: any
  consulta?: any
  prontuario?: any
}
export class GetDependentes {
  dadosDependente: {
    bairro: string
    cartaoSUS: string
    categoria: string
    tipoDocID: string
    descTipoDocID: string
    tipoPessoa: string
    descTipoPessoa: string
    cep: string
    numeroEndereco: string
    cidade: string
    complemento: string
    cpf: string
    cpfConjuge: string
    cpfMae: string
    cpfPai: string
    cpfResponsavelTitular: string
    cpfTutorLegal: string
    dataInclusaoDocumento: string
    endereco: string
    estado: string
    estadoCivil: string
    descEstadoCivil: string
    grauParentescoSecundario: string
    grauParentescoTitular: string
    id: string
    nacionalidade: string
    naturalidade: string
    nomePai: string
    numeroBeneficiario: string
    nomeMae: string
    telefoneComercial: string
    telefoneFixo: string
    estadoNaturalidade: string
    numeroDocumento: string
    operadoraConvenio: string
    descOperadoraConvenio: string
    planoDeSaude: string
    descPlanoDeSaude: string
    possuiPlanoDeSaude: boolean
    rg: string
    sexo: string
    status: string
    descStatus: string
    idade?: number
  }
  cadastroUsuario: {

    id: string
    usuarioId: string
    numeroDocumento: string
    dataInclusaoDocumento: string
    nomeCompleto: string
    nomeSocial: string
    email: string
    dataNascimento: string
    cpf: string
    termosDeAceite: boolean
    tipoUsuario: string
    status: string
    criadoPor: string
    criadoEm: string
    telefone: string
    fotoPerfil?: string
  }
}