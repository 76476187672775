import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ContaBancaria } from 'src/app/cadastro-conta-bancaria/modelsCadastroContaBancaria';
import { FaturaContasAPagar, PlanoContaContabeisModel } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ContaBancariaService } from 'src/app/service/cadastro-conta-bancaria.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FaturaDeMedico } from 'src/app/service/fatura-medica/modal';
import { MensagemService } from 'src/app/service/mensagem.service';
import { PlanoContaContabeisService } from 'src/app/service/plano-conta-contabeis/plano-conta-contabeis.service';
import { RegistrarPagamentosService } from 'src/app/service/registrar-pagamentos.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { RegistrarPagamentosModel } from './registrar-pagamentos.model';

const sessao = new SessaoUser

@Component({
  selector: 'app-registrar-pagamentos',
  templateUrl: './registrar-pagamentos.component.html',
  styleUrls: ['./registrar-pagamentos.component.scss']
})
export class RegistrarPagamentosComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'numeroDocumento', 'cedente', 'dataLancamento', 'valorTotalFatura', 'status']
  displayedColumnsPag = ['datalhe', 'pagamento', 'beneficiario', 'numeroDoc', 'valor', 'vencimento']
  listRegistro: FaturaContasAPagar[]
  formulario: FormGroup
  listEmpresa = []
  listItemPagamento = []
  listTipoDocumento = []
  listPagamentos = [{
    datalhe: '',
    pagamento: '',
    beneficiario: '',
    numeroDoc: '',
    valor: '',
    vencimento: ''
  }]
  listBeneficiario = []
  listMeioPagamento = []


  viewCadastro = false
  alteracao = false
  botaoSalvar = false
  botaoCancelar = false
  loading: boolean
  empresaSelect: any
  dataSource
  listTransacao: PlanoContaContabeisModel[] = [];
  backup_listTransacao: PlanoContaContabeisModel[] = [];
  transacao
  codigoEmpresa: any;
  viewConaBancaria: boolean;
  listContaBancaria: ContaBancaria[];
  selectEmpresa
  listCartaoCredito: any[];
  viewCartaoCredito: boolean;
  mes: { name: string; };
  indexMes: number;
  indexAno: number;

  constructor(
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private _conteudo: ConteudoDeDadosService,
    private _status: StatusSistemaService,
    private service: RegistrarPagamentosService,
    private mensagem: MensagemService,
    private _conta: ContaBancariaService,
    private _planoContas: PlanoContaContabeisService,
    private _data: BuscarDataService
  ) {
  }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    // this.getItemPagamento()
    this.getTipoDocumento()
    this.getMeioPagamento()
    this.indexMes = new Date().getMonth()
    this.indexAno = new Date().getFullYear()
    this.mes = this._data.getMeses(this.indexMes)
    console.log('MES', this.mes.name)
  }

  async getRegistro(codigoEmpresa, date?) {

    this.loading = true

    let registro = await this.service.getContasApagar(codigoEmpresa)
    registro.forEach(async item => {
      let desc = await this._status.getStatus(false, true, item.status)
      let filterBeneficiario = this.listBeneficiario.filter(x => x.id == item.cedente)
      item.descStatus = desc
      if (filterBeneficiario.length > 0) {
        item.descBeneficiario = filterBeneficiario[0].razaoSocial
      } else {
        item.descBeneficiario = 'Não identificado'
      }
    });
    console.log('REGISTROOOO', this.listBeneficiario, registro)
    if (date >= 0) {
      let ano = this.indexAno
      console.log('ANO', ano)
      registro = registro
        .filter(value => new Date(value.dataDocumento).getMonth() == date)
        .filter(value => new Date(value.dataDocumento).getFullYear() == ano)
    }
    this.dataSource = new MatTableDataSource(registro)
    this.dataSource.sort = this.sort
    this.loading = false
  }
  filterCalendario(execut, index?) {
    switch (execut) {
      case 'next':
        if (index == 11) { index = 0; this.indexAno += +1 }
        else index += + 1
        this.mes = this._data.getMeses(index)
        break;
      case 'return':
        if (index == 0) { index = 11; this.indexAno += -1 }
        else index += - 1
        this.mes = this._data.getMeses(index)
        break;
      case 'hoje':
        let mes = new Date().getMonth()
        this.indexAno = new Date().getFullYear()
        index = mes
        this.mes = this._data.getMeses(index)
        break;

      default:
        break;

    }
    this.getRegistro(this.selectEmpresa, index)
    console.log(execut, index)
    this.indexMes = index

  }

  async getPlanoDeContas(empresa) {
    await this._planoContas.listar(empresa).toPromise()
      .then(res => {
        let filter = res.filter(value => {
          return value.controleLivroCaixa == 'CONCAI1' && value.codigoEmpresa == empresa ||
            value.controleLivroCaixa == 'CONCAI2' && value.codigoEmpresa == empresa
        }).filter(v => v.controlePagamento)
        if (filter.length > 0) {
          this.listTransacao = filter
          this.backup_listTransacao = filter
        }
      })
  }
  async getContaBancaria(codigoEmpresa) {
    await this._conta.getContaBancaria().toPromise()
      .then((res: any) => {
        let filter = res.filter(x => x.codigoEmpresa == codigoEmpresa)
        this.listContaBancaria = filter


      })
  }
  validMeioPagamento(item) {
    console.log(item)
    if (item == 'MEIOPG2' || item == 'MEIOPG3') {
      this.viewConaBancaria = false
    } else {
      this.viewConaBancaria = true
    }
    if (item == 'MEIOPG2') {
      this.viewCartaoCredito = true
    } else {
      this.viewCartaoCredito = false
    }

  }
  createForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: '',
      numeroDocumento: '',
      nrDocumentoReferencia: '',
      dataLancamento: '',
      dataDocumento: '',
      dataVencimento: '',
      dataDoPagamento: '',
      itemPagamento: '',
      cedente: '',
      tipoDocumento: '',
      valorTotalFatura: 0,
      moedaFatura: '',
      meioPagamento: '',
      contaBancaria: '',
      cartaoCredito: '',
      valorTotalMora: 0,
      valorTotalDesconto: 0,
      valorTotalDeducoes: 0,
      observacao: '',
      criadoPor: '',
      criadoEm: '',
      status: '',

    })
  }
  teste(value) {
    this.formulario.controls['valorPagamento'].setValue(value)
  }


  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getItemPagamento() {
    let itemPagamento = await this._conteudo.get('ITMPAG')
    this.listItemPagamento = itemPagamento
  }

  async getMeioPagamento() {
    let meioPagamento = await this._conteudo.get('MEIOPG')
    this.listMeioPagamento = meioPagamento
    console.log('Meio pagamento: ', this.listMeioPagamento)
  }

  async getTipoDocumento() {
    let tipoDocumento = await this._conteudo.get('TPDOCC')
    this.listTipoDocumento = tipoDocumento
    console.log('tipoDocumento: ', this.listTipoDocumento)
  }

  testando(codigo) {
    console.log('passou!', codigo)

  }

  async getBeneficiario(codigo, date?) {

    let item = await this.service.getGeralFornecedor(codigo)
    console.log(item)
    this.listBeneficiario = item
    this.getRegistro(codigo, date)
  }
  async getCartaoCredito(codigo) {
    this.listCartaoCredito = await this.service.getCartaoCredito(codigo)
  }
  async getDados(codigo) {
    await this.getBeneficiario(codigo);
    await this.getPlanoDeContas(codigo);
    await this.getContaBancaria(codigo)
    await this.getCartaoCredito(codigo)

  }


  inserir() {
    let registro = this.formulario.getRawValue()
    console.log(registro)
    delete registro.id
    registro.criadoPor = sessao.cpf
    if (typeof registro.valorTotalFatura == 'string') {
      registro.valorTotalFatura = parseFloat(registro.valorTotalFatura)
    }
    console.log(JSON.stringify(registro))
    let keys = Object.keys(registro);
    let getResult;
    keys.forEach(key => {
      let dado = registro;
      dado[key];
      if (!dado[key]) {
        delete dado[key];
      } else {
      }
      getResult = dado;
    });
    console.log('getResult', getResult)
    this.service.cadastrarContasApagar(getResult).toPromise()
      .then(
        (resposta: any) => {
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          // this.viewCadastro = false
          // this.formulario.disable()
          // this.botaoSalvar = false
          // this.botaoCancelar = false
          this.getRegistro(registro.codigoEmpresa)
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  alterar() {

    let registro = this.formulario.value

    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  async openCadastro(registro) {
    console.log(registro)
    this.formulario.patchValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    await this.formulario.disable()
  }

  async excluirRegistro() {
    let registro = this.formulario.value

    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro(this.selectEmpresa)
  }
  filterTransacao(key) {
    console.log(this.backup_listTransacao)
    let registro = this.backup_listTransacao
    let filter = registro.filter(value => value.descricao.toUpperCase().includes(key.toUpperCase()))
    this.listTransacao = filter
  }
  filtrar(value) {
    this.empresaSelect = value
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa(codigoEmpresa) {
    this.loading = true

    this.dataSource.data = this.listRegistro

    if (!codigoEmpresa) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
      return
    }

    let filter = this.dataSource.data.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.empresaSelect = this.dataSource.data


    setTimeout(() => {
      this.loading = false
    }, 2000)
  }


}
