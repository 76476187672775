import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
export class UploadArquivo {
  nrDocumento: string
  tipoDocumento: string
  tipoAnexo: string
  path: string
  arquivo: string
  criadoPor: string
  nomeArquivo: string
  status: string
}
@Injectable({
  providedIn: 'root'
})
export class UploadArquivosService {
  url = new environment().api
  api = 'UploadArquivo/'
  db = this.firestore.collection('conversas')
  percentage: Observable<number>;
  constructor(private http: HttpClient, private firestore: AngularFirestore) { }

  upload(formData: FormData, info?: UploadArquivo) {
    return this.http.post<any>(this.url + this.api + `${info.nrDocumento}/${info.tipoDocumento}/${info.tipoAnexo}/${info.path}/${info.criadoPor}/`, formData, { reportProgress: true, observe: 'events' });
  }
  async get(numDoc: string) {
    let response: UploadArquivo[] = []
    await this.http.get<any>(this.url + 'AnexosDoSistema/BuscarNrDocumento/' + numDoc).toPromise()
      .then(res => {
        if (!res.success) return
        let filter = res.result.filter(x => {
          if (x.status) {
            return x.status.toLowerCase() == "ativo"
          }
        })
        response = filter
      })
      .catch(error => { console.log(error) })
    return response
  }
  getFile(nomeArquivo: string, path: string) {
    // let file = encodeURIComponent(path)
    let param = new HttpParams().append('nomeArquivo',nomeArquivo).append('path',path)
    return this.http.get(this.url + `TransferenciaArquivos/DownloadArquivo`, { responseType: 'blob',params : param })
  }
  download(nomeArquivo: string, path: string) {
    let parametro = new HttpParams()
    parametro
      .set('nomeArquivo', nomeArquivo)
      .set('path', path)
    // encodeURIComponent('D:/web/localuser/myclinics/www/path_resultado_exame/402902092020163513228')
    let file = encodeURIComponent(path)
    console.log(file)
    // return this.http.get(this.url + `TransferenciaArquivos/DownloadArquivo/`, { params: parametro , responseType : 'blob'})
    return this.http.get(this.url + `TransferenciaArquivos/Download/${nomeArquivo}/${file}`, { responseType: 'blob' }).toPromise()
      .then((response) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        downloadLink.setAttribute('download', nomeArquivo);
        document.body.appendChild(downloadLink);
        downloadLink.click();

      })
      .catch(erro => console.log('file', erro))
  }

  removeFile(file) {
    file.status = "Inativo"
    return this.http.put(this.url + `AnexosDoSistema/${file.id}`, file)
  }
}
