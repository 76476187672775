import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CadastroDadosProfissionais, CadastroDadosProfissionaisJoin } from '../admin-usuarios/model';
import { REQUEST } from './modal';
export class ListarMedicos {
  id: number
  codigoEmpresa: number
  nomeEmpresa: string
  cpfProfissional: string
  tipoRegistro: string
  tipoConexao: string
  valorTipoConexao: string
  especialidadeProfissional: string
  valorEspecialidadeProfissional: string
  codigoProfissao: string
  profissao: string
  nomeCompleto: string
  nr_Registro: string
  crmResponsavel: string
  statusConexoesProfissionais: string
  statusDadosProfissionais: string
  statusRegistroProfissional: string
  unidadeFederacao?: string
  valorEspecialidade?: string
  userConfigEmpresa?: boolean
}
@Injectable({
  providedIn: 'root'
})
export class ListarMedicosService {
  url = new environment().api
  constructor(private http: HttpClient) { }

  async getList(codigoEmpresa) {
    let result = []
    await this.http.get<REQUEST<ListarMedicos>>(`${this.url}ListarMedicos/${codigoEmpresa}`).toPromise()
      .then((medicos) => {
        console.log('getList', medicos)
        let verificarDuplicidade = []
        if (medicos.success) {
          for (let i = 0; i < medicos.result.length; i++) {
            let med = medicos.result[i]
            let valid = verificarDuplicidade.findIndex(v => { return v.nomeCompleto == med.nomeCompleto }) < 0
            if (valid) {
              verificarDuplicidade.push(med)
            }
          }
          result = verificarDuplicidade
        }
      }).catch((res) => console.log('RESULTMEDICO', res))
    return result
  }
  async getMedico(cpf) {
    let result = new CadastroDadosProfissionaisJoin
    console.log('getMedico', `${this.url}CadastroDadosProfissionais/ConsultarMedico/` + cpf)
    await this.http.get(`${this.url}CadastroDadosProfissionais/ConsultarMedico/` + cpf).toPromise()
      .then((res: any) => {
        if (res.success) {
          result = res.result[0]
        }
      }).catch(error => {
        console.log('erro', error)
      })
    return result
  }
  async ValidarCRM(crm, uf, cpf, dataNascimento) {
    let result: any
    dataNascimento = new Date(dataNascimento).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).split('/').join('')
    console.log(`${this.url}CfmService/ValidarCrm/${crm}/${uf}/${cpf}/${dataNascimento}`)
    await this.http.get(`${this.url}CfmService/ValidarCrm/${crm}/${uf}/${cpf}/${dataNascimento}`).toPromise()
      .then(res => {
        console.log('result validCRM', res)
        result = res
      }).catch(error => {

        result = { result: { resultadoConsulta: false } }
        console.log('error validCRM!', error)
      })
    return result
  }
  async getMedicosEmpresa(codigoEmpresa) {
    let result: ListarMedicos[] = []

    await this.http.get<REQUEST<ListarMedicos>>(`${this.url}ListarMedicos/${codigoEmpresa}`).toPromise()
      .then((res) => {
        result = res.result
      }).catch(error => {
        console.log('erro', error)
      })
    return result

  }
}
