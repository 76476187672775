import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AreaAtuacao } from './AreaAtuacao.model';
import { MensagemService } from 'src/app/service/mensagem.service'
import { DiarioDeAcessoService } from '../diario-de-acesso/diario-de-acesso.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AreaAtuacaoService {
  url = `${new environment().api}AreaAtuacao`

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
    private serviceDiarioAcesso: DiarioDeAcessoService,
  ) { }

  getAreaAtuacaoCodigoEmpresa(codigoempresa: any): Observable<Object> {
    return this.http.get(`${this.url}/FiltrarCodigoEmpresa/${codigoempresa}`)
  }

  AlterarAreaAtuacao(id: any, dados): Observable<Object> {
    return this.http.put(`${this.url}/${id}`, dados)
  }

  async criarAreaAtuacao(novaAreaAtuacao: Omit<AreaAtuacao, 'itemAreaAtuacao' | 'timeStamp' | 'status'>) {
    novaAreaAtuacao.cpfAutor = await this.serviceDiarioAcesso.cpfDestaSessao();
    await this.http.post(this.url, novaAreaAtuacao).toPromise()
      .then(() => `Área atuação ${novaAreaAtuacao.areaDeAtuacaoAns} criada com sucesso`)
      .catch((err: HttpErrorResponse) => console.log(`Não foi possível criar a nova área de atuação: ${err.message}`))
  }

  async alterarAreaAtuacao(novaAreaAtuacao: Omit<AreaAtuacao, 'timeStamp'>) {
    novaAreaAtuacao.cpfAutor = await this.serviceDiarioAcesso.cpfDestaSessao();
    await this.http.post(`${this.url}/${novaAreaAtuacao.itemAreaAtuacao}`, novaAreaAtuacao).toPromise()
      .then(() => `Área atuação ${novaAreaAtuacao.areaDeAtuacaoAns} alterada com sucesso`)
      .catch((err: HttpErrorResponse) => this.mensagem.creat(`Não foi possível alterar a área de atuação ${novaAreaAtuacao.areaDeAtuacaoAns}: ${err.message}`, { erro: true }))
  }

  async listarAreasAtuacao(): Promise<AreaAtuacao[]> {
    let areasAtuacao: AreaAtuacao[]
    await this.http.get<AreaAtuacao[]>(this.url, { observe: 'body' }).toPromise()
      .then(res => areasAtuacao = res)
      .catch(err => this.mensagem.creat(`Erro ao listar todas as áreas de atuacao: ${err}`, { erro: true }))
    return areasAtuacao;
  }

  async removerAreaAtuacao(id: any) {
    await this.http.delete(`${this.url}/${id}`).toPromise()
      .then(() => this.mensagem.creat('Área de atuação deletada com sucesso', { sucesso: true }))
      .catch(err => this.mensagem.creat(`Não foi possível remover a área de atuação: ${err}`, { erro: true }));
  }

  async listarAreasAtuacaoEmpresa({ codEmpresa }: { codEmpresa: number }): Promise<AreaAtuacao[]> {
    let areasAtuacao: AreaAtuacao[]
    await this.http.get<AreaAtuacao[]>(`${this.url}/FiltrarCodigoEmpresa/${codEmpresa}`, { observe: 'body' }).toPromise()
      .then(res => areasAtuacao = res)
      .catch((err: HttpErrorResponse) => this.mensagem.creat(`Erro ao listar as áreas de atuação da empresa ${codEmpresa}: ${err.message}`, { erro: true }));
    return areasAtuacao;
  }
}