import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from '../service/usuario.service';
import { FormUsuario } from '../usuario/model';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ChartOptions, ChartType } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, ThemeService } from 'ng2-charts';
import { StatusSistemaService, Status } from '../service/status-sistema.service';
import { CadastroDadosProfissionais, CadastroDadosProfissionaisJoin, CadastroPessoa, cadastroUsuario, UsuarioConsultaGeral } from './model';
import { MensagemService } from '../service/mensagem.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { CadastroUsuarioService } from '../service/cadastro-usuario/cadastro-usuario.service';
import { FotoPerfilService } from '../service/foto-perfil.service';

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.scss']
})
export class AdminUsuariosComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  fotoPerfil: any;
  novaSenha = false
  statusUsuario
  desabilitar: boolean
  registro = new cadastroUsuario
  registroPessoa = new CadastroPessoa
  registroProfissional = new CadastroDadosProfissionaisJoin
  usuarioconsultaGeral = new UsuarioConsultaGeral
  viewRegistro: boolean
  viewEdit: boolean
  dataSource: MatTableDataSource<FormUsuario>;
  displayedColumns: string[] = ['detalhe', 'usuarioLogado','nomeCompleto', 'cpf',  'tipoUsuario','icones', 'status' ];
  loading: boolean;
  contPaciente: number;
  contAssinante: number;
  grafico: boolean;
  gerarSenha: boolean;
  
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: { labels: { usePointStyle: true } }
  };
  public pieChartLabels: Label[] = ['Ativos', 'Inativos', 'Assinantes', 'Pacientes'];
  public pieChartLabelsAtivos: Label[] = ['Assiantes', 'Pacientes'];
  public pieChartColors = [
    {
      backgroundColor: ['#28a745', '#dc3545', '#fecb3d', '#20a8d8'],
    },
  ];
  public pieChartColorsAtivos = [
    {
      backgroundColor: ['#fecb3d', '#20a8d8'],
    },
  ];
  public pieChartData: SingleDataSet = [10, 10, 10, 10]
  public pieChartDataAtivos: SingleDataSet = [10, 10]
  public pieChartDataInativos: SingleDataSet = [10, 10]
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  contAtivos: number;
  contInativos: number;
  contAssinanteAtivo: number;
  contPacienteAtivo: number;
  contAssinanteInativo: number;
  contPacienteInativo: number;
  status: Status[] = []
  constructor(
    private service: UsuarioService,
    private cadService: CadastroUsuarioService,
    private themeService: ThemeService,
    private _status: StatusSistemaService,
    private mensagem: MensagemService,
    private _conteudo: ConteudoDeDadosService,
    private fotoService: FotoPerfilService) {
    // monkeyPatchChartJsTooltip();
    // monkeyPatchChartJsLegend();
  }

  async ngOnInit() {
    //this.getUsuarios()
    this.GetStatusUsuarios()
    this.GetJoinUsuario()
  

  }

  GetStatusUsuarios()
  {
    this.cadService.GetStatus().toPromise().then(
      (registros: any) => {
        this.statusUsuario = registros;
      }
    ).catch((err) => {
      this.mensagem.creat('Falha ao buscar Status', {erro: true})
    })

  }

  async GetFotoPerfil(cpf) {
    this.fotoPerfil = await this.fotoService.buscarFoto(cpf)
    console.log('fotoPerfil', this.fotoPerfil)
    // 
  }

  GetGeral(cpf){
    this.service.GetConsultaGeral(cpf).toPromise().then(
      async (reg: UsuarioConsultaGeral) => {
        this.GetFotoPerfil(cpf)
        this.usuarioconsultaGeral = reg;  

        console.log('REGISTRO: ', this.registro)
        console.log('Variavel: ')
        console.log(this.usuarioconsultaGeral)
        console.log('API: ')
        console.log(reg)
      }
    ).catch((error) => {
      this.mensagem.creat('Falha na consulta do registro', { erro: true })
        console.log('error', error); 
    })
  }

  GetPessoa(cpf){

    this.service.GetRegistroPessoa(cpf).toPromise().then(
      async (reg: CadastroPessoa[]) => {
        this.registroPessoa = reg[0];
        console.log('PESSOA: ')
        console.log(this.registroPessoa)
        console.log('API: ')
        console.log(reg)

      }    
    ).catch((error) => {
      this.mensagem.creat('Falha na consulta do registro', { erro: true })
        console.log('error', error); 
    })

    this.service.GetRegistroProfissional(cpf).toPromise().then(
      async (reg:CadastroDadosProfissionaisJoin[]) => {
        this.registroProfissional = reg[0];
        console.log('Profissional: ')
        console.log(this.registroPessoa)
        console.log('API: ')
        console.log(reg)
      }     
    ).catch((err) => {
      this.mensagem.creat('Falha na consulta do registro Profissional', { erro: true })
      console.log('error', err); 

    })
  }

  DesabilitarUsuario(cpf:string)
  {
    this.cadService.DesabilitarUsuario(cpf).toPromise().then(
      (result: any) => {
        this.mensagem.creat('Usuário Desabilitado', {sucesso: true})
        console.log('DesabilitarUsuario ', result)      
        this.GetJoinUsuario()
        this.FecharView()
      }).catch(err => {
        console.log(err.error)
        this.mensagem.creat('Falha ao desabilitar Usuário',{erro: true})
      })
  }

 async AlterarRegistro(registro, acao){

    if (!registro.id) return

    if(acao == 'bloq')
    {
      registro.status = 'SDO0019'
      console.log('ENTROU NO BLOQ')
    }
    if(acao == 'desbloq')
    {
      registro.status = 'SDO0060'
      console.log('ENTROU NO DESBLOQ')
    }

    this.registro = registro
    console.log('Registro ALTERAR',JSON.stringify(this.registro))
  await this.cadService.PutCadUsuario(this.registro).then(
      res => {   
        this.mensagem.creat('Alterado com sucesso', { sucesso: true })
      }
    ).catch(
      (res) => 
      {      
        console.log(res)
        this.mensagem.creat('Falha ao alterar', { erro: true })
      })
      this.GetJoinUsuario()
      this.FecharView()
  }

  GetJoinUsuario(){
    this.loading = true

    this.cadService.GetJoin().then(
      (reg : any) =>{
        this.dataSource = new MatTableDataSource(reg);
        this.dataSource.sort = this.sort
      //  this.dataSource.paginator = this.paginator
        let pacientes = reg.map(p => p.assinante).filter((r) => !r)
        let assiante = reg.map(p => p.assinante).filter((r) => r)
        let assianteAtivo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => r.use && r.ativo)
        let assianteInativo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => r.use && !r.ativo)
        let pacienteAtivo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => !r.use && r.ativo)
        let pacienteInativo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => !r.use && !r.ativo)
        let ativos = reg.map(p => p.ativo).filter((r) => r)
        let inativos = reg.map(p => p.ativo).filter((r) => !r)
        this.contPaciente = pacientes.length
        this.contAssinante = assiante.length
        this.contAssinanteAtivo = assianteAtivo.length
        this.contAssinanteInativo = assianteInativo.length
        this.contPacienteAtivo = pacienteAtivo.length
        this.contPacienteInativo = pacienteInativo.length
        this.contAtivos = ativos.length
        this.contInativos = inativos.length
        this.pieChartData = [ativos.length, inativos.length, assiante.length, pacientes.length]
        this.pieChartDataAtivos = [assianteAtivo.length, pacienteAtivo.length]
        this.pieChartDataInativos = [assianteInativo.length, pacienteInativo.length]
        this.loading = false
      })  
  }

 async FecharView(){

   // await this.GetJoinUsuario()
    this.viewRegistro = false;
    this.viewEdit = false;
    this.usuarioconsultaGeral = new UsuarioConsultaGeral;
    this.desabilitar = false
      
  }

  GerarSenha(cpf)
  {
    this.cadService.GerarSenha(cpf).toPromise().then(
      res => this.mensagem.creat('Senha gerada com Sucesso',{sucesso: true})
    ).catch(
      err => this.mensagem.creat('Falha ao gerar Senha', {erro: true})
    )
    this.gerarSenha = false;
    this.GetJoinUsuario()
    this.FecharView()
  }

  getUsuarios() {
    this.loading = true
    this.service.listar().toPromise().then(
      async (reg: Array<any>) => {
        let get = reg.filter((r) => { return r.tipoUsuario != 'TIPUSU4' })

        get.forEach(async registro => {
          let desc = await this._status.getStatus(false, true, registro.status)
          let userDesc = await this._conteudo.get('TIPUSU', true, registro.tipoUsuario)
          console.log('userDesc[0].valor', userDesc)
          registro.statusDesc = desc
          registro.userDesc = userDesc[0]
        });

        for (let i = 0; i <= get.length - 1; i++) {
          console.log('index', i, get.length)
          let desc = await this._status.getStatus(false, true, get[i].status)
          let userDesc = await this._conteudo.get('TIPUSU', true, get[i].tipoUsuario)
          console.log('userDesc[0].valor', userDesc)
          get[i].statusDesc = desc
          get[i].userDesc = userDesc[0]
        }

        this.dataSource = new MatTableDataSource(get);
        this.dataSource.paginator = this.paginator
        let pacientes = reg.map(p => p.assinante).filter((r) => !r)
        let assiante = reg.map(p => p.assinante).filter((r) => r)
        let assianteAtivo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => r.use && r.ativo)
        let assianteInativo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => r.use && !r.ativo)
        let pacienteAtivo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => !r.use && r.ativo)
        let pacienteInativo = reg.map(p => { return { use: p.assinante, ativo: p.ativo } }).filter((r) => !r.use && !r.ativo)
        let ativos = reg.map(p => p.ativo).filter((r) => r)
        let inativos = reg.map(p => p.ativo).filter((r) => !r)
        this.contPaciente = pacientes.length
        this.contAssinante = assiante.length
        this.contAssinanteAtivo = assianteAtivo.length
        this.contAssinanteInativo = assianteInativo.length
        this.contPacienteAtivo = pacienteAtivo.length
        this.contPacienteInativo = pacienteInativo.length
        this.contAtivos = ativos.length
        this.contInativos = inativos.length
        this.pieChartData = [ativos.length, inativos.length, assiante.length, pacientes.length]
        this.pieChartDataAtivos = [assianteAtivo.length, pacienteAtivo.length]
        this.pieChartDataInativos = [assianteInativo.length, pacienteInativo.length]
        this.loading = false
      }
    )
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  alterar(registro) {
    this.loading = true
    delete registro.statusDesc
    delete registro.userDesc
    this.service.alterar(registro.id, registro).toPromise()
      .then((res) => {
        console.log('Response', res)
        this.mensagem.creat('Alterado com sucesso', { sucesso: true })
        this.getUsuarios()
        this.viewEdit = false
      })
      .catch((error) => {
        this.mensagem.creat('Falha ao alterar documento', { erro: true })
        console.log('error', error); this.loading = false
      })
      
  }

}
