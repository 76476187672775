import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
class type {
  sucesso?= 'sucesso'
  erro?= 'erro'
  aviso?= 'aviso'
}
class mensagemSistema {
  codigo: string
  descricao: string
  id: string
  status: string
  tipoMensagem: string
}

@Injectable({
  providedIn: 'root'
})

export class MensagemService {
  url = new environment().api
  constructor(private http: HttpClient) { }

  creat(mensagem, tipo: { sucesso?: boolean, erro?: boolean, aviso?: boolean }) {
    let color = null
    let icon = null
    if (tipo.sucesso) {
      color = 'text-success'
      icon = '<i class="far fa-check-circle mr-1"></i>'
    }
    if (tipo.erro) {
      color = 'text-danger'
      icon = '<i class="far fa-times-circle mr-1"></i>'
    }
    if (tipo.aviso) {
      color = 'text-info'
      icon = '<i class="far fa-check-circle mr-1"></i>'
    }

    let m = `<div class="${color} mensagem-sistema-info">${icon} ${mensagem}</div>`
    localStorage.setItem('mensagem', m)

    setTimeout(() => this.clear(), 5000)
  }
  getErro(codigo) {
    this.http.get<mensagemSistema>(`MensagensDoSistema/FiltrarCodigo/${codigo}`).toPromise()
      .then(res => {
        try {
          let text = res.tipoMensagem
          switch (text) {
            case 'Erro':
              this.creat(text, { erro: true })
              break;
            case 'Sistema':
              this.creat(text, { aviso: true })
              break;
            case 'Informativa':
              this.creat(text, { aviso: true })
              break;
            case 'Alerta':
              this.creat(text, { aviso: true })
              break;

            default:
              break;
          }
        } catch {

        }


      })
  }
  clear() {
    localStorage.removeItem('mensagem')
  }
}
