import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';

import { SalaEsperaForm } from '../atendimento-clinico/sala-espera/model';
import { BuscarDataService } from '../service/buscar-data.service';


@Component({
  selector: 'app-documentos-pdf',
  templateUrl: './documentos-pdf.component.html',
  styleUrls: ['./documentos-pdf.component.scss']
})
export class DocumentosPdfComponent implements OnInit {
  @Input('doc') doc: Array<any>
  @Input('imprimir') imprimir: string
  @Input('itens') itens: any
  @Input('info') info: SalaEsperaForm
  @Input('type') type: any
  @Output('close') close = new EventEmitter
  code = '1212354562'
  hoje: string
  hora: string
  horaFinal: string
  cnpj: string
  loading
  crm
  constructor(private route: ActivatedRoute, private getDate: BuscarDataService) { }

  ngOnInit() {
    this.hoje = this.getDate.hojeBrasil()
    this.hora = this.getDate.horaAgora()
    console.log('ITEEENS > ', this.itens)
    this.cnpj = sessionStorage.getItem('cnpj')
    this.crm = sessionStorage.getItem('crm')
    if (this.imprimir) {
      this.captureScreen(true)
    }
    if (this.type == 'atestado_medico') {
      try {
        this.horaFinal = this.itens.fim.split(' ')[1]
        console.log('horaFinal', this.itens.fim, this.horaFinal)
      } catch {
        this.horaFinal = "00:00"
      }
    }
    if (this.type == 'atestado_acompanhante') {
      try {
        this.horaFinal = this.itens.fim.split(' ')[1]
        console.log('horaFinal', this.itens.fim, this.horaFinal)
      } catch {
        this.horaFinal = "00:00"
      }
    }
  }
  getParametro() {
    this.route.params.subscribe((p) => {
      if (p.type == 'view') {
      }
    })
  }
  captureScreen(view?) {
    this.loading = true
    var data = document.getElementById('pdf');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

      if (view) {
        this.sair()
        window.print()
        setTimeout(() => {
          this.loading = false
        }, 4000)

        // Generated PDF
      } else {
        pdf.save('doc.pdf')
      }
      this.loading = false
    });
  }
  sair() {
    this.close.emit(false)
  }
}
