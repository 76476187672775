export class HierarquiaCustoELucroModel {
    id: string
    codigo: number
    descricao: string
    observacao: string
    tipoHierarquia: string
    codigoEmpresa: number
    criadoPor: string
    ///
    // timestamp: string
    status: string
    nomeEmpresa: string
    criadoEm: string
    descTipoHierarquia?: string
    itemGrupo?: boolean
}

export class GrupoItemHierarquia {
    id?: string;
    codigoGrupoHierarquia?: number;
    descricaoHierarquia?: string;
    codigoEmpresa?: number;
    codigoHierarquia?: number;
    criadoPor?: string;
}

export class ItemGrupoCentroDeLucro {
    id?: string;
    codigoGrupoHierarquia?: number;
    codigoCentroLucroOuCusto?: number;
    codigoEmpresa?: number;
    codigoHierarquia?: number;
    criadoPor?: string;
}
