import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessaoUser } from '../user.reg';
import { REQUEST } from './modal';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class ProntuarioService {
  url: string = new environment().api
  liberarProntuario: string = "liberarProntuario/"
  solicitaProntuario: string = "solicitaProntuario/"
  prontuarioVidaPaciente : string = 'ProntuarioVidaPaciente'

  prontuarioMedico = 'ProntuarioMedicoDoPaciente/'
  constructor(private http: HttpClient) {

  }
  async validProtuarioMedico() {
    let result
    this.http.get(this.url + this.prontuarioMedico)
  }
  async get(cpf) {
    let result = []
    await this.http.get(this.url + this.prontuarioMedico + 'ConsultarCpfPaciente/' + cpf).toPromise()
      .then((res: any) => {
        if (typeof res == 'object') {
          result = res
        }
      })
    return result
  }

  getProntuarioVida(cpf) {
    return this.http.get<REQUEST<any>>(this.url + 'TicketMarcacaoConsultaMedica/Join/' + cpf)
  }

  getEvolucaoSaude(cpf) {
    return this.http.get(this.url + 'ProntuarioVidaPaciente/' + `${cpf}/${cpf}`)
  }
  //************************** solicitaProntuario **********************************
  listarSolicitaProntuario(id?) {
    console.log('SolicitaProntuario :' + this.url + this.solicitaProntuario)
    if (id) {
      return this.http.get(this.url + this.solicitaProntuario + id)
    }
    return this.http.get(this.url + this.solicitaProntuario)
  }
  cadastrarSolicitaProntuario(dados: any) {
    console.log('SolicitaProntuario-Cadastrar :' + this.url + this.solicitaProntuario, dados)
    return this.http.post(this.url + this.solicitaProntuario, dados)
  }
  alterarSolicitaProntuario(id: string, dados) {
    console.log('SolicitaProntuario-Alterar :' + this.url + this.solicitaProntuario + id, dados)
    return this.http.put(this.url + this.solicitaProntuario + id, dados)
  }
  excluirSolicitaProntuario(id: string, dados?) {
    console.log('SolicitaProntuario-Excluir :' + this.url + this.solicitaProntuario + id)
    return this.http.delete(this.url + this.solicitaProntuario + id)
    // return this.http.put(this.url + this.solicitaProntuario + id, dados)
  }
  //************************** end solicitaProntuario **********************************
  //************************** LiberarProntuario **********************************
  listarLiberarProntuario(id?) {
    console.log('LiberarProntuario :' + this.url + this.liberarProntuario)
    if (id) {
      return this.http.get(this.url + this.liberarProntuario + id)
    }
    return this.http.get(this.url + this.liberarProntuario)
  }
  cadastrarLiberarProntuario(dados: any) {
    dados.status = "SDO0147"
    dados.criadoEm = new Date().toISOString()
    dados.criadoPor = sessao.cpf
    console.log('LiberarProntuario-Cadastrar :' + this.url + this.liberarProntuario, dados)
    return this.http.post(this.url + this.liberarProntuario, dados)
  }
  alterarLiberarProntuario(id: string, dados) {
    console.log('LiberarProntuario-Alterar :' + this.url + this.liberarProntuario + id, dados)
    return this.http.put(this.url + this.liberarProntuario + id, dados)
  }
  excluirLiberarProntuario(id: string, dados?) {
    console.log('LiberarProntuario-Excluir :' + this.url + this.liberarProntuario + id)
    return this.http.delete(this.url + this.liberarProntuario + id)
    // return this.http.put(this.url + this.liberarProntuario + id, dados)
  }
  //************************** end LiberarProntuario **********************************

}
