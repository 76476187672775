import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { SessaoUser } from 'src/app/user.reg';
import { ConteudoDeDadosService, ConteudoDados } from 'src/app/service/conteudo-de-dados.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { FormPaciente, CadastroPessoaForm } from 'src/app/cadastro-paciente/model';
import { MensagemService } from 'src/app/service/mensagem.service';
import { MatCheckboxChange, MatTableDataSource } from '@angular/material';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { FaturaMedicaService } from 'src/app/service/fatura-medica/fatura-medica.service';
import { ConvenioService } from 'src/app/service/convenio/convenio.service';
import { TabelaTUSS } from 'src/app/service/convenio/modal';
const sessao = new SessaoUser
@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.scss']
})
export class ConvenioComponent implements OnInit {
  @Output() close = new EventEmitter
  @Output() registro = new EventEmitter
  @Input() paciente = new FormPaciente

  situacaoBeneficiario = null
  autServico = 0
  form: FormGroup
  formItem: FormGroup
  displayedColumns: string[] = ['tipoGuiaTiss', 'classificacaoGuiaTiss', 'nrGuiaTissPrestadorServicoSaudeAssociada', 'nrGuiaTissPrestadorPrincipalOperadora', 'acao'];
  displayedColumnsSolic: string[] = ['autorizacaoServico', 'descricaoAutorizacao', 'solicitacaoAutorizacaoJustificativa', 'statusAutorizacao', 'delete'];
  displayedColumnsTUSS = ['tab22TussCodigo', 'tab22TussTerminologia', 'correlacao', 'resolucaoNormativa', 'capitulo', 'grupo', 'subGrupo']
  dataSource
  dataSourceTISS
  reigstroTISS = []
  viewAddTISS
  viewADD_TUSS
  tipoTISS: { valor: string; cod: string; }[];
  classGuiaTISS: { valor: string; cod: string; }[];
  autTIIS: { valor: string; cod: string; }[];
  operadoras: { valor: string; cod: string; }[];

  infoConvenio = {
    operadora: '',
    plano: '',
    nrBeneficiario: ''
  }
  semRegistroConvenio: boolean;
  viewCad: boolean;
  registroPaciente = new CadastroPessoaForm;
  listAutorizacao = [];
  formTISS: FormGroup;
  listTISS = {
    id: null,
    registro: {},
    item: []
  };
  editTISSID: boolean;
  selectItem: any;
  indexItem: any;
  tipoTUSS = []
  listTUSS = []
  tableTuss = [];
  tableTussFilter = [];
  table_TUSS
  constructor(
    private formBuilder: FormBuilder,
    private service: FaturaMedicaService,
    private _conteudo: ConteudoDeDadosService,
    private _usuario: UsuarioService,
    private _mensagem: MensagemService,
    private _status: StatusSistemaService,
    private _tuss: ConvenioService
  ) { }

  ngOnInit() {
    console.log('pessoa', this.paciente.cpf)
    this.creatForm()
    this.getConteudoDados()
    this.getInfoConvenio()
    this.getTUSS()
  }
  creatForm() {
    this.form = this.formBuilder.group({
      codigoEmpresa: sessao.codigoEmpresa,
      nrGuiaTissPresencialPrestadorServicoSaude: new FormControl(),
      nrGuiaTissPresencialOperadora: new FormControl(),
      nrGuiaTissPrincipalPrestadorServicoSaude: new FormControl(),
      nrGuiaTissPrestadorPrincipalOperadora: new FormControl(),
      nrGuiaTissPrestadorServicoSaudeAssociada: new FormControl(),
      nrGuiaTissOperadoraAssociada: new FormControl(),
      dataLancamento: new Date().toISOString(),
      dataDoDocumento: new Date().toISOString(),
      statusDocumento: 'SDO0093',
      nrBeneficiario: new FormControl(),
      operadora: new FormControl(),
      plano: new FormControl(),
      situacaoBeneficiario: new FormControl(),
      motivoDaNegativa: new FormControl(),
      tipoGuiaTiss: new FormControl(),
      classificacaoGuiaTiss: new FormControl(),
      statusGuiaTiss: new FormControl(),
    })
    this.formTISS = this.formBuilder.group({
      tipoGuiaTiss: new FormControl(),
      classificacaoGuiaTiss: new FormControl(),
      nrGuiaTissPrestadorServicoSaudeAssociada: new FormControl(),
      nrGuiaTissPrestadorPrincipalOperadora: new FormControl(),
    })
    this.formItem = this.formBuilder.group({
      numeroDocumento: new FormControl(),
      numeroItem: new FormControl(),
      autorizacaoServico: 'AUTISS1',
      descricaoAutorizacao: new FormControl(),
      statusAutorizacao: new FormControl(),
      solicitacaoAutorizacaoJustificativa: new FormControl(),
    })
  }
  async getConteudoDados() {
    let tipoTISS = await this._conteudo.get('TPTISS')
    let classGuiaTISS = await this._conteudo.get('CLTISS')
    let autTIIS = await this._conteudo.get('AUTISS')
    let operadoras = await this._conteudo.get('OPESAU')
    let tuss = await this._conteudo.get('SPSADT')

    this.tipoTISS = tipoTISS.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    this.classGuiaTISS = classGuiaTISS.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    this.autTIIS = autTIIS.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    this.operadoras = operadoras.map(r => { return { valor: r.valor, cod: r.codigoReferencia } })
    this.tipoTUSS = tuss.map(r => { return { id: r.id, valor: r.valor, cod: r.codigoReferencia, codNeogico: r.codigoDeNegocio } })

  }
  getInfoConvenio() {
    // this._usuario.getCadastroPessoa().toPromise()
    //   .then((res: Array<any>) => {
    //     const cpf = this.paciente.cpf
    //     let get = res.filter(item => { return item.cpf == cpf })[0]
    //     console.log('paciente :', get)
    //     console.log('paciente CPF:', cpf)
    //     if (get) {
    //       this.registroPaciente = get
    //       this.infoConvenio.nrBeneficiario = get.numeroBeneficiario
    //       this.infoConvenio.operadora = get.operadoraConvenio
    //       this.infoConvenio.plano = get.operadoraSaude

    //       if (!get.numeroBeneficiario || !get.operadoraConvenio || !get.operadoraSaude) {
    //         this.semRegistroConvenio = true
    //         console.log('passou ')
    //       } else {
    //         this.semRegistroConvenio = false
    //         console.log('não passou ')
    //       }
    //     } else {
    //       this.semRegistroConvenio = true
    //     }
    //   })
  }
  getTUSS() {
    this._tuss.getTabelaTuss().toPromise()
      .then((res: any) => {
        this.tableTuss = res
      })
  }
  async convertCod(cod, ref) {
    let res = await this._conteudo.get(cod, true, ref)
    console.log('convertCod', res[0])
    return res[0]
  }

  addTUSS(check: MatCheckboxChange, value) {
    if (check.checked) {
      this.listTUSS.push(value)
    } else {
      this.listTUSS.filter((x, i) => {
        if (x.id == value.id) {
          this.listTUSS.splice(i, 1)
        }
      })
    }
  }
  addAutorizacao(item) {
    this.listAutorizacao.push(item)

    this.dataSource = new MatTableDataSource(this.listAutorizacao)
    console.log(this.listAutorizacao)
    this.formItem.reset()
  }
  abrirAddTISS() {
    this.viewAddTISS = true
    this.listAutorizacao = []
    this.formTISS.reset()
    this.formItem.reset()
  }
  async addTISS() {
    let list: any = { id: 0, registro: {}, item: [] }

    list.registro = this.formTISS.value
    list.item = this.listAutorizacao

    let tipoGuiaTiss = await this._conteudo.get('TPTISS', true, list.registro.tipoGuiaTiss)

    let classificacaoGuiaTiss = await this._conteudo.get('CLTISS', true, list.registro.classificacaoGuiaTiss)


    list.registro.descTipoGuiaTiss = tipoGuiaTiss.toString()
    list.registro.descClassificacaoGuiaTiss = classificacaoGuiaTiss.toString()
    if (this.editTISSID) {
      let index = this.indexItem
      list.id = this.selectItem.id
      this.reigstroTISS[index] = list
    } else {
      list.id = Math.floor((1000 - 1) * Math.random())
      this.reigstroTISS.push(list)
    }


    this.dataSourceTISS = new MatTableDataSource(this.reigstroTISS)
    console.log('reigstroTISS', this.reigstroTISS)
    this.listAutorizacao = []
    this.viewAddTISS = false
    this.formTISS.reset()
    this.editTISSID = false
  }
  infoRegistroTISS(dados, index) {
    this.editTISSID = true
    this.selectItem = dados
    this.indexItem = index
    this.viewAddTISS = true
    delete dados.registro.descTipoGuiaTiss
    delete dados.registro.descClassificacaoGuiaTiss
    delete dados.item.statusAutorizacao
    this.formTISS.setValue(dados.registro)
    this.listAutorizacao = dados.item
    console.log(dados.item)
    this.dataSource = new MatTableDataSource(this.listAutorizacao)

  }
  deleteItemAutorizacao(index) {
    this.reigstroTISS.splice(index, 1)
    console.log(this.listAutorizacao)
    console.log(this.reigstroTISS)
    this.dataSource = new MatTableDataSource(this.reigstroTISS)
  }
  async convertStatus(cod) {
    let status = await this._status.getStatus(false, true, cod)
    console.log('cod', status)
    return status
  }
  convertConteudo(cod) {
    let desc = this.autTIIS.filter(res => { return res.cod == cod }).map(x => x.valor)
    return desc
  }
  cadastrar() {
    console.log('passou')
    this.registroPaciente.numeroBeneficiario = this.infoConvenio.nrBeneficiario
    this.registroPaciente.operadoraConvenio = this.infoConvenio.operadora
    this.registroPaciente.planoDeSaude = this.infoConvenio.plano
    if (this.registroPaciente.id) {
      this._usuario.cadastrarPessoa(this.registroPaciente).toPromise()
        .then(res => {
          this.viewCad = false
          this._mensagem.creat('Cadastrado com sucesso', { sucesso: true })
          console.log(res)
        })
        .catch(error => { console.log(error) })
    } else {
      this.registroPaciente.cpf = this.paciente.cpf
      // this.registroPaciente.registroProfissional = 'Não informado'
      this.registroPaciente.possuiPlanoDeSaude = true
      this.registroPaciente.cep = '000000'
      this.registroPaciente.endereco = 'Não informado'
      this.registroPaciente.numeroEndereco = '0'
      this.registroPaciente.bairro = 'Não informado'
      this.registroPaciente.cidade = 'Não informado'
      this.registroPaciente.estado = 'Não informado'
      this.registroPaciente.tipoPessoa = 'Não informado'
      this.registroPaciente.status = 'SCA0001'
      this.registroPaciente.nomeCompleto = this.paciente.nome
      this._usuario.cadastrarPessoa(this.registroPaciente).toPromise()
        .then(res => {
          this.viewCad = false
          this._mensagem.creat('Cadastrado com sucesso', { sucesso: true })
          console.log(res)

        })
        .catch(error => { console.log(error) })
    }
  }
  teste() {
    let item = this.formItem.value
    console.log(item)
  }
  pesquisarTuss(valor) {
    let filter = this.tableTuss.filter((value: TabelaTUSS) => value.tab22TussCodigo.includes(valor))
    this.tableTussFilter = filter
  }
  insertTUSS(registro) {
    let item = []
    item.push(registro)
    this.table_TUSS = new MatTableDataSource(item)
    this.viewADD_TUSS = false
  }
  tipoGuia(tipo, indice) {
    this.autServico = indice
    this.form.controls['tipoGuiaTiss'].setValue(tipo)
    this.formItem.controls['autorizacaoServico'].setValue(tipo)
  }
  async salvar() {
    let registro = [{
      cabecalho: [],
      item: [],
      tuss: []
    }]
    await this.reigstroTISS.forEach(reg => {
      registro[0].cabecalho.push(reg.registro)
      reg.item.forEach(item => {
        registro[0].item.push(item)
      });
    });
    registro[0].tuss.push(this.table_TUSS.data[0])
    console.log(registro)
    this.registro.emit(registro)

  }
  closeAutorizacao() {
    this.formItem.reset()
  }
  Close() {
    this.close.emit(false)
  }

}
