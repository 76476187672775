import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogDoSistemaService {
    url = new environment().api
    apiLogDoSistema = "LogDoSistema/"

    constructor(private http: HttpClient) {

    }

    getLogDoSistema(): Observable<Object> {
        return this.http.get(this.url + this.apiLogDoSistema + "Join")
    }

    getLogDoSistemaID(id: string): Observable<Object> {
        return this.http.get(this.url + this.apiLogDoSistema + id)
    }

    getDataLocalTrabalhoFiltro(dataInicial: Date, dataFinal: Date): Observable<Object> {
        return this.http.get(this.url + this.apiLogDoSistema + "Between/" + `${dataInicial}/` + `${dataFinal}`)
    }
}


