import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { ClassificacaoDeDadosService } from '../service/classificacao-de-dados/classificacao-de-dados.service';
import { timezone } from '../service/timezones/timezone.model';
import { TimezonesService } from '../service/timezones/timezones.service';
import { MyClinicsPessoaJuridicaService } from '../service/my-clinics-pessoa-juridica/my-clinics-pessoa-juridica.service';
import { EmpresaMyClinics } from '../service/my-clinics-pessoa-juridica/empresaMyClinics.model';
import { HubDevService } from '../service/hub-dev.service';
import { MensagemService } from '../service/mensagem.service';
import { ValidateBrService } from 'angular-validate-br';

@Component({
  selector: 'app-empresa-my-clinics',
  templateUrl: './empresa-my-clinics.component.html',
  styleUrls: ['./empresa-my-clinics.component.scss']
})

export class EmpresaMyClinicsComponent implements OnInit {

  fichaGeralEmpresa: EmpresaMyClinics[];
  itemsSelecionados: number[] = [];
  displayedColumns = ['checkbox', 'codEmpresa', 'cnpjEmpresa', 'nomeEmpresa', 'ver'];
  dataSource = new MatTableDataSource(this.fichaGeralEmpresa);
  constructor(
    public dialog: MatDialog,
    private serviceEmpresasMyClinics: MyClinicsPessoaJuridicaService,
  ) { }

  async ngOnInit() {
    this.listarEmpresas()
  }

  async listarEmpresas() {
    this.fichaGeralEmpresa = await this.serviceEmpresasMyClinics.listarEmpresas()
      .then(empresas => empresas.filter(empresa => empresa.status !== 'SDO0063'));
    this.dataSource.data = this.fichaGeralEmpresa;
  }

  novaEmpresa(novaEmpresa = <EmpresaMyClinics>{
    id: 0,
    nomeEmpresa: '',
    tipoEmpresa: '',
    webSiteEmpresa: '',
    cabecalhoImpressao: '',
    telefoneEmpresa: '',
    email: '',
    status: '',
    timestamp: '',
    observacao: '',
    cnpj: '',
    razaoSocial: '',
    cnpjMatriz: '',
    idMatriz: '',
    moedaEmpresa: '',
    rua: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    numeroEndereco: '',
    fusoHorario: '',
    criadoPor: '',
    codigoEmpresaMatriz: '',
  }) {
    const dialogRef = this.dialog.open(PopUpEmpresaMyClinics, {
      width: '75vw',
      height: '83vh',

      data: novaEmpresa,
    })

    dialogRef.beforeClosed().subscribe(() => {
      this.listarEmpresas();
    })
  }

  async desativarEmpresas() {
    for (let i = 0; i < this.itemsSelecionados.length; i++) {
      const empresa = this.fichaGeralEmpresa.find(empresa => empresa.id === this.itemsSelecionados[i]);
      this.dataSource.data = this.dataSource.data.filter(empresa => empresa.id !== this.itemsSelecionados[i])
      await this.serviceEmpresasMyClinics.desativarEmpresa(empresa);
      this.itemsSelecionados.splice(i, 1);
    }
    await this.listarEmpresas();
    this.itemsSelecionados = [];
  }

  mudarCheckBox(inserir: boolean, codigo: number) {
    if (inserir) {
      this.itemsSelecionados.push(codigo)
    } else {
      this.itemsSelecionados = this.itemsSelecionados.filter(item => item !== codigo)
    }
  }
}

/*--------------------NOVA EMPRESA-----------------------------*/
@Component({
  selector: 'pop-up-empresa-my-clinics',
  templateUrl: './pop-up-empresa-my-clinics.component.html',
  styleUrls: ['./empresa-my-clinics.component.scss']
})

export class PopUpEmpresaMyClinics implements OnInit {

  tiposEmpresa: conteudoDeDado[];
  moedas: conteudoDeDado[];
  fusosHorarios: timezone[];
  formEmpresa: FormGroup;
  matrizOuFilial = "Matriz";
  novaEmpresa = !this.data.id;
  alterarEmpresa = false;
  telMask = "(00)000000000"
  constructor(
    private contrutorForm: FormBuilder,
    private serviceClassificacao: ClassificacaoDeDadosService,
    private serviceTimeZone: TimezonesService,
    private serviceHubDev: HubDevService,
    public dialoRef: MatDialogRef<PopUpEmpresaMyClinics>,
    private serviceEmpresasMyClinics: MyClinicsPessoaJuridicaService,
    private serviceMensagem: MensagemService,
    private serviceValidatorBR: ValidateBrService,

    @Inject(MAT_DIALOG_DATA) public data: EmpresaMyClinics
  ) { }

  async ngOnInit() {

    this.inicializarFormulario()
    this.moedas = await this.serviceClassificacao.buscarConteudosDaCaracteristica({ codigoCaracteristica: 'MOEDAS' });
    this.tiposEmpresa = await this.serviceClassificacao.buscarConteudosDaCaracteristica({ codigoCaracteristica: 'TIPEMP' });
    this.fusosHorarios = await this.serviceTimeZone.listarTimezones();
    this.formEmpresa.controls['cep'].valueChanges.subscribe(cep => {
      this.serviceHubDev.vCEP(cep).subscribe(({ status, result: endereco }) => {
        if (!status) throw 'CEP não encontrado';
        this.formEmpresa.controls['rua'].setValue(endereco.logradouro);
        this.formEmpresa.controls['bairro'].setValue(endereco.bairro);
        this.formEmpresa.controls['estado'].setValue(endereco.uf);
        this.formEmpresa.controls['cidade'].setValue(endereco.localidade);
      }, () => this.serviceMensagem.creat('CEP não encontrado', { erro: true }))
    })

    this.formEmpresa.controls['cnpj'].valueChanges
      .subscribe(cnpj => {
        this.serviceHubDev.vCNPJ(cnpj).subscribe(({ status, result: empresa }) => {
          if (!status) throw 'Cnpj não encontrado'
          this.formEmpresa.controls['razaoSocial'].setValue(empresa.nome)
          this.formEmpresa.controls['nomeEmpresa'].setValue(empresa.fantasia)
          this.formEmpresa.controls['telefoneEmpresa'].setValue(empresa.telefone)
          this.formEmpresa.controls['email'].setValue(empresa.email)
          this.formEmpresa.controls['rua'].setValue(empresa.logradouro);
          this.formEmpresa.controls['bairro'].setValue(empresa.bairro);
          this.formEmpresa.controls['estado'].setValue(empresa.uf);
          this.formEmpresa.controls['cidade'].setValue(empresa.municipio);
          this.formEmpresa.controls['numeroEndereco'].setValue(empresa.numero);
          this.formEmpresa.controls['cep'].setValue(empresa.cep.replace('-', '').replace('.', ''));
        }, () => this.serviceMensagem.creat('Cnpj não encontrado na receita federal', { erro: true })
        )
      })
  }

  inicializarFormulario() {
    this.formEmpresa = this.contrutorForm.group({
      cnpj: [this.data.cnpj, [Validators.required, this.serviceValidatorBR.cnpj]],
      nomeEmpresa: [this.data.nomeEmpresa, Validators.required],
      fusoHorario: [this.data.fusoHorario, Validators.required],
      cep: [this.data.cep, Validators.required],
      rua: [this.data.rua, Validators.required],
      bairro: [this.data.bairro, Validators.required],
      cidade: [this.data.cidade, Validators.required],
      estado: [this.data.estado, Validators.required],
      email: [this.data.email, [Validators.required, Validators.email]],
      cabecalhoImpressao: [this.data.cabecalhoImpressao, Validators.required],
      telefoneEmpresa: [this.data.telefoneEmpresa, Validators.required],
      razaoSocial: [this.data.razaoSocial, Validators.required],
      tipoEmpresa: [this.data.tipoEmpresa, Validators.required],
      moedaEmpresa: [this.data.moedaEmpresa, Validators.required],
      cnpjMatriz: [this.data.cnpjMatriz, this.serviceValidatorBR.cnpj],
      status: [this.data.status],
      timestamp: [this.data.timestamp],
      observacao: [this.data.observacao],
      idMatriz: [this.data.codigoEmpresaMatriz],
      numeroEndereco: [this.data.numeroEndereco, Validators.required],
      webSiteEmpresa: [this.data.webSiteEmpresa],
      criadoPor: [this.data.criadoPor],
      id: [this.data.id],
    }, { updateOn: 'blur' })
  }

  campoObrigatorio() {
    const campos = Object.keys(this.formEmpresa.controls);
    const camposInvalidos = campos.filter(campo => this.formEmpresa.controls[campo].invalid)
      .map(campo => {
        return campo.replace(/[A-Z]/g, " $&").trim().toLowerCase();
      });
    return `* Verifique o campo: ${camposInvalidos[0]}`;
  }

  verificarMascara() {
    const telefone: string = this.formEmpresa.get('telefoneEmpresa').value
    this.telMask = telefone.length > 10 ? "(00) 0 0000-0000" : "(00) 0000-0000"
  }

  salvar() {
    const empresa: EmpresaMyClinics = this.formEmpresa.value;
    if (this.novaEmpresa) {
      delete empresa.id;
      this.serviceEmpresasMyClinics.criarEmpresa(empresa)
        .then(() => this.dialoRef.close());

    } else {
      this.serviceEmpresasMyClinics.modificarEmpresa(empresa)
        .then(() => {
          this.dialoRef.close();
        })
    }
  }

  cancelar() {
    this.dialoRef.close();
  }
}


