import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const url = new environment().api
const user = new SessaoUser

export class ParametrosDoSistema {
  id: string
  parametro: string
  aplicacao: string
  valorDoParametro: string
  limiteInferior: string
  limiteSuperior: string
  valorPadrao: string
  valorRecomendado: string
  descricao: string
  status: string
}

@Injectable({
  providedIn: 'root'
})
export class ParametrosSistemaService {

  api = new environment().api + 'ParametrosDoSistema/'

  constructor(private http: HttpClient,  private data: BuscarDataService) { }

  async get(parametro) {
    let result = new ParametrosDoSistema
    await this.http.get<ParametrosDoSistema[]>(this.api).toPromise()
      .then(res => {

        let filter = res.filter(value => value.parametro == parametro && value.status == 'SDO0016')

        result = filter[0]
      })
    return result
  }

   GetNormal() {
   return this.http.get(this.api)
  }
  
   Post(registro)
   {
     return this.http.post(this.api, registro)
   }

   Put(registro)
   {
    return this.http.put(this.api + registro.id + "/" + user.sessao_id + "/"+ this.data.getTIMESTAMP(), registro )
   }
   //SDO0062

   Delete(registro)
   {
     registro.status = 'SDO0062'
    return this.http.put(this.api + registro.id + "/" + user.sessao_id + "/"+ this.data.getTIMESTAMP(), registro )

   }




}
