import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { MatPaginator, MatSort,MatSnackBar, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-lista-cids',
  templateUrl: './lista-cids.component.html',
  styleUrls: ['./lista-cids.component.scss']
})
export class ListaCidsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

displayedColumns = ['codigoDeNegocio', 'valor']

datasource

registrosTabela

  constructor(  
    private mensagem: MatSnackBar,
    private _form: FormBuilder,
    private service: ConteudoDeDadosService) { }

  ngOnInit() {
    this.GetRegistros()
  }

 GetRegistros(){
   this.service.get('CID').then(
     (registros: any) => {
       this.datasource = new MatTableDataSource(registros)
       this.datasource.paginator = this.paginator;
       this.datasource.sort = this.sort
       this.registrosTabela = registros
     }
   ).catch((err) => {
     console.log(err.reponse)
   })
 }
 
 filtro(reg: string) {
  this.datasource.filter = reg.trim().toLowerCase();
}


}
