import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CertificadoDigitalService } from '../service/certificado-digital/certificado-digital.service';
import { CertificadoDigital } from '../service/certificado-digital/modal';

@Component({
  selector: 'app-certificado-digital',
  templateUrl: './certificado-digital.component.html',
  styleUrls: ['./certificado-digital.component.scss']
})
export class CertificadoDigitalComponent implements OnInit {
  @Output() exit = new EventEmitter
  @Output() emit = new EventEmitter
  displayedColumns = ['assinante', 'emissor', 'seiral']
  loadingCertificado: boolean
  cpfCertificado: string;
  regCertificado: CertificadoDigital[] = [];
  itemCertificado = new CertificadoDigital;
  constructor(private certificado: CertificadoDigitalService) { }

  ngOnInit() {
  }
  getCertificado() {
    this.loadingCertificado = true
    this.certificado.get().toPromise()
      .then(res => {
        let cpf = this.cpfCertificado
        let get = res.filter(item => { return item.cpf == cpf })
        if (get.length > 0) {
          this.regCertificado = get
        } else {
          this.regCertificado = []
        }
        this.loadingCertificado = false
      })
  }
  selectCertificado(checket, item: CertificadoDigital) {

    if (checket) {
      this.itemCertificado = item
    } else {
      this.itemCertificado = new CertificadoDigital
    }
  }
  confirmCertificado() {
    this.emit.emit(this.regCertificado)
  }
  close(){
    this.exit.emit(false)
  }
}
