import { FlatTreeControl, TreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTree } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { PlanoContaContabeisService } from 'src/app/service/plano-conta-contabeis/plano-conta-contabeis.service';
import { ValidCRM, ValidarCrmService } from 'src/app/service/validar-crm.service';
import { PlanoContaContabeisModel, SaldoContaContabil } from '../plano-contato-contabeis.model';

@Component({
  selector: 'app-tree-plano-conta',
  templateUrl: './tree-plano-conta.component.html',
  styleUrls: ['./tree-plano-conta.component.scss']
})
export class TreePlanoContaComponent implements OnInit {
  @Input() codigoEmpresa: number
  @Input() livroCaixa: PlanoContaContabeisModel[] = [];
  @Output() item = new EventEmitter
  @ViewChild('tree', { static: false }) tree: MatTree<any>
  loading: boolean;
  nivel_1: ConteudoDados[];
  nivel_2: ConteudoDados[];
  nivel_3: ConteudoDados[];
  nivel_4: ConteudoDados[];
  contas: PlanoContaContabeisModel[]

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      caracteristica: `${node.balancoPatrimonialZero ? node.balancoPatrimonialZero : '0'}/
      ${node.balancoPatrimonialUm ? node.balancoPatrimonialUm : '0'}/
      ${node.balancoPatrimonialDois ? node.balancoPatrimonialDois : '0'}/
      ${node.balancoPatrimonialTres ? node.balancoPatrimonialTres : '0'} `,
      name: node.descricao,
      codigo: node.codigo,
      conta: node.codigoContaContabil,
      saldo: node.saldo,
      dadoSaldo: node.dadoSaldo,
      total: node.total,
      prefix: node.codigoDeNegocio,
      level: level,
      filter: node.filter
    };
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  openAll: boolean;
  backup_registro: any;
  selectDado: SaldoContaContabil;
  viewConfirm: boolean;
  progress: string
  constructor(
    private _conteudo: ConteudoDeDadosService,
    private _conta: PlanoContaContabeisService) {

  }
  ngOnInit() {
    this.ContaContabil()
  }
  getSaldoInsert(dado: SaldoContaContabil, value) {
    dado.montanteSaldo = value
    let filter = this.contas.filter(value => value.codigoContaContabil == dado.contaContabil)
    console.log(filter)
  }
  inserirDado(confirm, dado?: SaldoContaContabil, value?) {
    dado.montanteSaldo = value
    this.selectDado = dado
    if (confirm) {
      let item = this.selectDado
      let filter = this.contas.filter(value => value.codigoContaContabil == item.contaContabil)
      console.log(filter)
    } else {
      this.viewConfirm = true
    }

  }
  emitItem(dado) {
    this.item.emit(dado.codigo)
  }

  async getConteudo() {
    // this.nivel_2 = await this._conteudo.get('BPNIV1')
    // this.nivel_2.forEach(element => {
    //   console.log('nivel ' , element.codigoDeNegocio)
    // });
    // return
    this.progress = "Construindo estrutura de dados"
    this.nivel_1 = await this._conteudo.get('BPNIV0')
    this.nivel_2 = await this._conteudo.get('BPNIV1')
    this.nivel_3 = await this._conteudo.get('BPNIV2')
    this.nivel_4 = await this._conteudo.get('BPNIV3')


    if (this.livroCaixa.length > 0) {

      let n1 = this.livroCaixa.map(value => value.balancoPatrimonialZero).filter((item, i, self) => self.indexOf(item) == i)
      let nivel_1 = []
      n1.forEach(value => {
        this.nivel_1.forEach(element => {
          if (element.codigoReferencia == value) {
            nivel_1.push(element)
          }
        });
      });
      let n2 = this.livroCaixa.map(value => value.balancoPatrimonialUm).filter((item, i, self) => self.indexOf(item) == i)
      let nivel_2 = []
      n2.forEach(value => {
        this.nivel_2.forEach(element => {
          if (element.codigoReferencia == value) {
            nivel_2.push(element)
          }
        });
      });
      let n3 = this.livroCaixa.map(value => value.balancoPatrimonialDois).filter((item, i, self) => self.indexOf(item) == i)
      let nivel_3 = []
      n3.forEach(value => {
        this.nivel_3.forEach(element => {
          if (element.codigoReferencia == value) {
            nivel_3.push(element)
          }
        });
      });
      let n4 = this.livroCaixa.map(value => value.balancoPatrimonialTres).filter((item, i, self) => self.indexOf(item) == i)
      let nivel_4 = []
      n4.forEach(value => {
        this.nivel_4.forEach(element => {
          if (element.codigoReferencia == value) {
            nivel_4.push(element)
          }
        });
      });
      this.nivel_1 = nivel_1
      this.nivel_2 = nivel_2
      this.nivel_3 = nivel_3
      this.nivel_4 = nivel_4
    }

    let tree = []

    this.nivel_1.forEach((nivel, index) => {
      let item = {
        descricao: nivel.valor,
        index: index,
        codigo: nivel.codigoReferencia,
        codigoDeNegocio: nivel.codigoDeNegocio,
        children: []
      }
      tree.push(item)
    });

    this.nivel_2.forEach((nivel, index) => {
      tree.forEach(value => {
        let cod
        let indec
        try {
          cod = nivel.codigoDeNegocio.slice(0, 1)
          indec = value.codigoDeNegocio.slice(0, 1)
          if (cod == indec) {
            let item = {
              descricao: nivel.valor,
              index: index,
              codigo: nivel.codigoReferencia,
              codigoDeNegocio: nivel.codigoDeNegocio,
              children: []
            }
            value.children.push(item)
          }
        }
        catch {

        }
      })
    })

    tree.forEach(value => {
      let order = value.children.sort((a, b) => {
        return parseFloat(a.codigoDeNegocio) < parseFloat(b.codigoDeNegocio) ? -1 : parseFloat(a.codigoDeNegocio) > parseFloat(b.codigoDeNegocio) ? 1 : 0
      })
      value.children = order
    })

    this.nivel_3.forEach((nivel, index) => {
      tree.forEach(conta => {
        conta.children.forEach(value => {
          let cod
          let indec
          try {
            cod = nivel.codigoDeNegocio.slice(0, 4)
            indec = value.codigoDeNegocio.slice(0, 4)
            if (cod == indec) {
              let item = {
                descricao: nivel.valor,
                index: index,
                codigo: nivel.codigoReferencia,
                codigoDeNegocio: nivel.codigoDeNegocio,
                children: []
              }
              value.children.push(item)
            }
          }
          catch {

          }
        });
      })
    })

    tree.forEach(conta => {
      conta.children.forEach(value => {

        let order = value.children.sort((a, b) => {
          let ac = a.codigoDeNegocio.split('.').join('')
          let bc = b.codigoDeNegocio.split('.').join('')
          return parseInt(ac) < parseInt(bc) ? -1 : parseInt(ac) > parseInt(bc) ? 1 : 0
        })
        value.children = order
      });

    })

    this.nivel_4.forEach((nivel, index) => {
      tree.forEach(conta => {
        conta.children.forEach(contaFinal => {
          contaFinal.children.forEach(value => {
            let cod
            let indec
            try {
              cod = nivel.codigoDeNegocio.slice(0, 7)
              indec = value.codigoDeNegocio.slice(0, 7)
              if (cod == indec) {
                let item = {
                  descricao: nivel.valor,
                  index: index,
                  codigo: nivel.codigoReferencia,
                  codigoDeNegocio: nivel.codigoDeNegocio,
                  children: []
                }

                value.children.push(item)
              }
            }
            catch {

            }
          });
        });
      })
    })

    tree.forEach(conta => {
      conta.children.forEach(contafinal => {
        contafinal.children.forEach(value => {
          let order = value.children.sort((a, b) => {
            let ac = a.codigoDeNegocio.split('.').join('')
            let bc = b.codigoDeNegocio.split('.').join('')
            return parseInt(ac) < parseInt(bc) ? -1 : parseInt(ac) > parseInt(bc) ? 1 : 0
          })
          value.children = order
        });
      });

    })

    tree.forEach(value => {
      value.children.forEach(value_2 => {
        value_2.children.forEach((conta, index) => {
          this.contas.forEach(async item => {

            if (item.balancoPatrimonialDois == conta.codigo && item.balancoPatrimonialTres == '0') {
              let registro = {
                descricao: item.descricao,
                index: index,
                saldo: item.saldo,
                dadoSaldo: item.registroSaldo,
                dataLancamento: item.dataLancamento,
                periodo: item.periodo,
                codigoContaContabil: item.codigoContaContabil,
                balancoPatrimonialUm: item.balancoPatrimonialUm,
                children: []
              }
              if (conta.codigoContaContabil == '11010100001') {
                console.log('PASSOU!RODRIGO 1', conta)
              }
              conta.children.push(registro)
            }
          })
        });
      });
    });

    tree.forEach(n1 => {
      n1.children.forEach(n2 => {
        n2.children.forEach(value => {
          let order = value.children.sort((a, b) => {
            let ac = a.codigoContaContabil
            let bc = b.codigoContaContabil
            return parseInt(ac) < parseInt(bc) ? -1 : parseInt(ac) > parseInt(bc) ? 1 : 0
          })
          value.children = order
        });
      });
    })

    tree.forEach(value => {
      value.children.forEach(value_2 => {
        value_2.children.forEach(value_3 => {
          value_3.children.forEach(value_4 => {
            this.contas.forEach(async (conta, index) => {
              if (conta.balancoPatrimonialTres == value_4.codigo) {
                let registro = {
                  descricao: conta.descricao,
                  index: index,
                  codigoContaContabil: conta.codigoContaContabil,
                  saldo: conta.saldo,
                  dadoSaldo: conta.registroSaldo,
                  dataLancamento: conta.dataLancamento,
                  periodo: conta.periodo,
                  balancoPatrimonialUm: conta.balancoPatrimonialUm,
                  children: []
                }
                if (conta.codigoContaContabil == '11010100001') {
                  console.log('PASSOU!RODRIGO 2', conta)
                }
                value_4.children.push(registro)
              }
            })
          });

        });
      });
    })

    console.log(tree)
    this.calcularTotal(tree)
    // this.dataSource.data = tree
  }
  async ContaContabil() {

    this.loading = true
    this.codigoEmpresa = 1
    this.progress = "Colantando conta contábil da empresa...."
    await this._conta.getContaContabilCodigoEmpresa(this.codigoEmpresa).toPromise()
      .then(async (res: PlanoContaContabeisModel[]) => {
        if (this.livroCaixa.length > 0) {
          this.contas = res.filter(value => value.controleLivroCaixa == 'CONCAI1' || value.controleLivroCaixa == 'CONCAI2')
        } else {
          this.contas = res
        }
        for (let i = 0; i < this.contas.length; i++) {
          let conta = this.contas[i];
          if (conta.codigoContaContabil == '11010100001') {
            console.log('PASSOU!RODRIGO start', conta)
          }
          this.progress = "Colantando saldo contábil...."
          let saldoConta = await this._conta.getSaldoContaContabil(conta.codigoContaContabil)
          conta.saldo = saldoConta.saldo,
            conta.dataLancamento = saldoConta.dataLancamento,
            conta.periodo = saldoConta.periodo
          conta.registroSaldo = saldoConta.dado
        }

        this.loading = false
        this.getConteudo()
      })
  }
  calcularTotal(registro) {
    this.progress = "Calculando...."

    registro.forEach(n1 => {
      n1.children.forEach(n2 => {
        n2.children.forEach(n3 => {
          n3.children.forEach(n4 => {
            try {
              let total = n4.children.map(x => x.saldo ? x.saldo : 0).reduce((total, valor) => total + valor)
              // console.log(total,n4)
              n4.total = total
            } catch { }

          });
        });
      });
    });

    registro.forEach(n1 => {
      n1.children.forEach(n2 => {
        n2.children.forEach(n3 => {
          try {
            let total = n3.children.map(x => x.total ? x.total : 0).reduce((total, valor) => total + valor)
            let saldo = n3.children.map(x => x.saldo ? x.saldo : 0).reduce((total, valor) => total + valor)

            n3.total = total + saldo

          } catch { }
        });
      });
    });

    registro.forEach(n1 => {
      n1.children.forEach(n2 => {
        try {
          let total = n2.children.map(x => x.total ? x.total : 0).reduce((total, valor) => total + valor)
          let saldo = n2.children.map(x => x.saldo ? x.saldo : 0).reduce((total, valor) => total + valor)

          n2.total = total + saldo

        } catch { }
      });
    });

    registro.forEach(n1 => {
      try {
        let total = n1.children.map(x => x.total ? x.total : 0).reduce((total, valor) => total + valor)
        let saldo = n1.children.map(x => x.saldo ? x.saldo : 0).reduce((total, valor) => total + valor)

        n1.total = total + saldo

        console.log(total, n1)

      } catch { }
    });
    this.backup_registro = registro
    this.dataSource.data = registro
  }
  openTree() {
    if (this.openAll) {
      this.tree.treeControl.collapseAll()
      this.openAll = false
    } else {
      this.openAll = true
      this.tree.treeControl.expandAll();
    }

  }
  async filter(value: string) {
    // let filter = this.backup_registro.filter(option => option.descricao.toLocaleUpperCase().includes(value.toLocaleUpperCase()))
    this.dataSource.data = this.backup_registro
    console.log(value)
    let itens = []
    for (let i = 0; i < this.backup_registro.length; i++) {
      let nivel_1 = this.backup_registro[i];
      let verific = nivel_1.descricao.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
      if (verific) {
        itens.push(nivel_1)
        // console.log(nivel_1)
      } else {
        for (let ii = 0; ii < nivel_1.children.length; ii++) {
          let n1 = nivel_1.children[ii]
          // console.log(n1)
          let verific = n1.descricao.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
          if (verific) {
            itens.push(n1)
          } else {
            for (let iii = 0; iii < n1.children.length; iii++) {
              let n2 = n1.children[iii]
              // console.log(n1)
              let verific = n2.descricao.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
              if (verific) {
                itens.push(n1)
              } else {
                for (let iiii = 0; iiii < n2.children.length; iiii++) {
                  let n3: any = n2.children[iiii]
                  let verific = n3.descricao.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
                  if (verific) {
                    let duplicad = itens.findIndex(x => x.descricao == n2.descricao)
                    if (duplicad < 0) {
                      n3.filter = true
                      itens.push(n1)
                    }
                  } else {
                    n3.filter = false
                    for (let iiiii = 0; iiiii < n3.children.length; iiiii++) {
                      let n4: any = n3.children[iiii]
                      try {
                        let verific = n4.descricao.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
                        if (verific) {
                          let duplicad = itens.findIndex(x => x.descricao == n4.descricao)
                          if (duplicad < 0) {
                            n4.filter = true
                            console.log(n4)
                            itens.push(n1)
                          }
                        } else {
                          n4.filter = false
                          // console.log('não passou', n1.descricao)
                        }
                      } catch { }

                    }
                  }
                }
              }
            }
          }
        }
      }
      // d.children.forEach(element => {
      //   let verific = element.descricao.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1

      // });
    }
    // console.log('result', itens)
    this.dataSource.data = itens
    try {
      this.tree.treeControl.expandAll();
      if (!value) {

        this.tree.treeControl.collapseAll()
      }
    } catch { }
    return
    // if (filter.length > 0) {
    //   this.dataSource.data = filter
    //   this.tree.treeControl.expandAll();
    //   console.log('passou')
    // } else {
    //   // setTimeout(() => {
    //   //   this.tree.treeControl.collapseAll()
    //   // })
    //   this.dataSource.data = this.backup_registro
    // }
    // // this.tree.
  }

  hasChild = (_: number, node: any) => node.expandable;

}
