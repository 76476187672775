import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Component } from '@fullcalendar/core';
import { environment } from 'src/environments/environment';
import { InformarSaldoContabilInicialModel } from '../ajustes/informar-saldo-contabil-inicial/informar-saldo-contabil-inicial.model';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';


const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class InformarSaldoContabilInicialService {

    url = new environment().api
    api = "SaldoContaContabil/"
    apiPlano = "PlanoContaContabeis/"


    constructor(private http: HttpClient, private data: BuscarDataService) { }

    listar() {
        return this.http.get<InformarSaldoContabilInicialModel[]>(this.url + this.api)
    }

    listarPlanoDeContas(codigoEmpresa) {
        return this.http.get<PlanoContaContabeisModel[]>(this.url + this.apiPlano + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    enviar(registro: InformarSaldoContabilInicialModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: InformarSaldoContabilInicialModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: InformarSaldoContabilInicialModel, ativo) {
        if (ativo) registro.status = 'SDA0001'
        else registro.status = 'SDA0002'

        let resposta

        await this.alterar(registro).toPromise()
            .then(
                res => {
                    resposta = res
                })
            .catch(
                error => {
                    console.log('error', error)
                })

        return resposta
    }
}