import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { RegrasOrcamentoService } from 'src/app/service/regras-orcamento.service';
import { ConteudoDeDados } from 'src/app/service/status-sistema.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { RegrasOrcamentoModel } from './regras-orcamento.model';

const sessao = new SessaoUser

@Component({
  selector: 'app-regras-orcamento',
  templateUrl: './regras-orcamento.component.html',
  styleUrls: ['./regras-orcamento.component.scss']
})
export class RegrasOrcamentoComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'tipo', 'permissao']
  listRegistro: RegrasOrcamentoModel[]
  formulario: FormGroup
  listEmpresa = []
  listRegra = []
  viewCadastro = false
  alteracao: boolean = false;
  listTipo = []
  listItensPermissao = []
  listItensSolicitacao = []
  listItensPedido = []
  dataSource = new MatTableDataSource()
  loading: boolean
  empresaSelec: any
  botaoSalvar = false
  botaoCancelar = false
  desativar = false
  viewConfirmDelete: boolean
  selectEmpresa = 0

  constructor(
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private service: RegrasOrcamentoService,
    private mensagem: MensagemService,
    private conteudo: ConteudoDeDadosService,
    private _filtro: FiltrarDadosService
  ) { }

  async ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getMaterialOuServico()
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      status: '',
      criadoPor: sessao.cpf,
      nomeEmpresa: '',
      codigoEmpresa: 0,
      tipo: '',
      permissao: '',
      criadoEm: ''
    })
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getMaterialOuServico() {
    let regra = await this.conteudo.get('REGOR')
    this.listRegra = regra.filter(item => item.codigoReferencia == 'REGOR1' || item.codigoReferencia == 'REGOR3')
    this.listItensPermissao = this.listRegra
    this.getItensPermissao()
  }

  async getItensPermissao() {

    let registro = this.formulario.value
    if (!registro.tipo) return
    let regra = await this.conteudo.get('REGOR')

    if (registro.tipo == 'REGOR1') {
      console.log("solicitacao ok")
      this.listItensPermissao = regra.filter(item => item.codigoReferencia == 'REGOR1' || item.codigoReferencia == 'REGOR2')
    } else {
      console.log("pedido ok")
      this.listItensPermissao = regra.filter(item => item.codigoReferencia == 'REGOR3' || item.codigoReferencia == 'REGOR4')
    }
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getRegistro() {
    this.loading = true

    await this.service.listar().toPromise()
      .then(registro => {
        this.listRegistro = registro.filter(item => item.status == 'SDA0001')
        this.listRegistro.forEach(async item => {
          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
          let permissao = await this.conteudo.get('REGOR', true, item.permissao)
          let tipo = await this.conteudo.get('REGOR')


          if (empresa.length > 0) {
            item.nomeEmpresa = empresa[0].nomeEmpresa
          }

          item.descPermissao = permissao.toString()
          item.descTipo = tipo.filter(value => value.codigoReferencia == item.tipo).map(v => v.codigoDeNegocio)[0]

        })
        this.dataSource.sort = this.sort
        this.loading = false
      })
  }

  inserir() {
    let registro = this.formulario.value

    delete registro.id
    delete registro.criadoEm
    delete registro.nomeEmpresa

    registro.criadoPor = sessao.cpf

    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          console.log('sucesso', resposta[0].mensagem)
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          }
        )
    console.log("Inserir registro: ", registro)
  }

  async openCadastro(registro) {

    delete registro.descTipo

    this.formulario.patchValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false

    console.log("OpenCadastro: ", registro)
  }

  alterar() {
    let registro = this.formulario.value
    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Alterado com sucesso!', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
    }

    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.viewCadastro = false
    this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  async fecharRegistro() {
    this.viewCadastro = false
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }
    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }
}
