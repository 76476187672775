import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'items-adicionais',
  templateUrl: './items-adicionais.component.html',
  styleUrls: ['./items-adicionais.component.scss']
})
export class ItemsAdicionaisComponent implements OnInit {

  @Input() usuarioJaExiste: boolean;
  constructor() { }

  ngOnInit() {
  }

}
