import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MensagensDoSistemaService {
    url = new environment().api
    apiMensagensDoSistema = "MensagensDoSistema/"

    constructor(private http: HttpClient) {

    }

    getMensagensDoSistema(): Observable<Object> {
        return this.http.get(this.url + this.apiMensagensDoSistema)
    }

    getMensagensDoSistemaID(id: string): Observable<Object> {
        return this.http.get(this.url + this.apiMensagensDoSistema + id)
    }

    PostMensagensDoSistema(dados: Object): Observable<Object> {
        console.log("Cadastrar Regras de imposto: " + this.url + this.apiMensagensDoSistema)
        return this.http.post(this.url + this.apiMensagensDoSistema, dados)
    }

    DeleteMensagensDoSistema(id: string): Observable<Object> {
        return this.http.delete(this.url + this.apiMensagensDoSistema + id)
    }

    AlterarMensagensDoSistema(id: string, dados): Observable<Object> {
        return this.http.put(this.url + this.apiMensagensDoSistema + id, dados)
    }
}


