import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { PlanoContaContabeisModel } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { LancamentoLivroCaixaService } from 'src/app/service/lancamento-livro-caixa.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { PlanoContaContabeisService } from 'src/app/service/plano-conta-contabeis/plano-conta-contabeis.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { LancamentoLivroCaixaModel } from './lancamento-livro-caixa.model';

const sessao = new SessaoUser


@Component({
  selector: 'app-lancamento-livro-caixa',
  templateUrl: './lancamento-livro-caixa.component.html',
  styleUrls: ['./lancamento-livro-caixa.component.scss']
})
export class LancamentoLivroCaixaComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['data', 'transacao', 'entrada', 'saida', 'saldo']
  listRegistro = []
  listEmpresa = []
  listTipoLancamento = []
  listTipoMovimento = []
  formulario: FormGroup

  alteracao = false
  viewCadastro = false
  empresaSelect: any
  dataSource
  botaoSalvar = false
  botaoCancelar = false
  loading: boolean
  listTransacao: PlanoContaContabeisModel[] = [];
  backup_listTransacao: PlanoContaContabeisModel[] = [];

  seletcTransacao
  tipoMovimento
  transacao
  codigoEmpresa: any;
  indexMes: number;
  indexAno: number;
  mes: { name: string; };
  selectEmpresa: any;
  constructor(
    private service: LancamentoLivroCaixaService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private mensagem: MensagemService,
    private conteudo: ConteudoDeDadosService,
    private _planoContas: PlanoContaContabeisService,
    private _data: BuscarDataService

  ) { }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getTipoLancamento()
    this.getTipoMovimento()
    this.indexMes = new Date().getMonth()
    this.indexAno = new Date().getFullYear()
    this.mes = this._data.getMeses(this.indexMes)
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }
  async getTipoLancamento() {
    let tipoLancamento = await this.conteudo.get('TPLANCX')
    this.listTipoLancamento = tipoLancamento
    console.log('tipoLancamento', tipoLancamento)
  }
  async getPlanoDeContas(empresa) {
    this.codigoEmpresa = empresa
    this._planoContas.listar(empresa).toPromise()
      .then(res => {
        let filter = res.filter(value => {
          return value.controleLivroCaixa == 'CONCAI1' && value.codigoEmpresa == empresa ||
            value.controleLivroCaixa == 'CONCAI2' && value.codigoEmpresa == empresa
        }).filter(v => v.controlePagamento)
        if (filter.length > 0) {
          this.listTransacao = filter
          this.backup_listTransacao = filter
        }
      })
  }

  async getTipoMovimento() {
    let tipoMovimento = await this.conteudo.get('TPMOVCAIXA')
    this.listTipoMovimento = tipoMovimento
    console.log('tipoMovimento', tipoMovimento)
  }

  async getRegistro(codigoEmpresa, mes) {
    this.loading = true
    console.log('codigo empresa', mes)
    if (!codigoEmpresa) return
    let result = await this.service.getSaldoContabio(codigoEmpresa, mes, this.indexAno)
    console.log('RESULT!', result)
    this.listRegistro = result

    this.dataSource = new MatTableDataSource(result)
    this.dataSource.sort = this.sort
    this.loading = false
    this.loading = false
    return
    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
            let getTipoLancamento = this.conteudo.get('TPLANCX', true, item.tipoLancamento)
            let getTipoMovimento = this.conteudo.get('TPMOVCAIXA', true, item.tipoMovimento)
            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
            }

            item.descLancamento = getTipoLancamento.toString()
            item.descMovimento = getTipoMovimento.toString()
          })
          this.dataSource = new MatTableDataSource(this.listRegistro)
          this.dataSource.sort = this.sort
          this.loading = false
        })
  }
  filterCalendario(execut, index?) {
    switch (execut) {
      case 'next':
        if (index == 11) { index = 0; this.indexAno += +1 }
        else index += + 1
        this.mes = this._data.getMeses(index)
        break;
      case 'return':
        if (index == 0) { index = 11; this.indexAno += -1 }
        else index += - 1
        this.mes = this._data.getMeses(index)
        break;
      case 'hoje':
        let mes = new Date().getMonth()
        this.indexAno = new Date().getFullYear()
        index = mes
        this.mes = this._data.getMeses(index)
        break;

      default:
        break;

    }
    this.getRegistro(this.selectEmpresa, index)
    console.log(execut, index)
    this.indexMes = index

  }
  async selectPlanoConta(item) {
    console.log('emitido', item)
    let caracteristica = item.slice(0, 6)

    let get = await this.conteudo.get(caracteristica, true, item)
    this.transacao = get[0]
    console.log(get)
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: 0,
      numeroDocumento: '',
      dataLancamento: '',
      transacao: '',
      tipoLancamento: '',
      tipoMovimento: '',
      montanteLancamentoCaixa: 0,
      criadoPor: sessao.cpf,
      criadoEm: '',
      status: '',
      ////
      descLancamento: '',
      descMovimento: ''
    })
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }
  getItemTransacao(value: string, type) {
    console.log(value, type)
    let filter = this.backup_listTransacao.filter(x => x.descricao == value)
    this.seletcTransacao = filter
    this.tipoMovimento = this.seletcTransacao.controleLivroCaixa == 'CONCAI1' ? true : false
    console.log('GET!', this.seletcTransacao)
  }




  async openCadastro(registro) {
    console.log('openCadastro()', registro)
    delete registro.nomeEmpresa
    registro.dataLancamento = ""
    // registro.tipoLancamento = ""
    // registro.tipoMovimento = ""
    registro.transacao = ""

    this.formulario.setValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  inserir() {
    let registro = this.formulario.value

    delete registro.criadoEm
    delete registro.dataLancamento
    delete registro.id
    delete registro.montanteLancamentoCaixa
    delete registro.numeroDocumento
    // delete registro.tipoLancamento
    // delete registro.tipoMovimento
    delete registro.transacao


    registro.criadoPor = sessao.cpf

    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )

    console.log("Inserir(): ", registro)
  }

  alterar() {
    let registro = this.formulario.value

    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          })
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro() {
    let registro = this.formulario.value

    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro(this.selectEmpresa, this.indexMes)
  }
  filterTransacao(key) {
    console.log(key)
    let registro = this.backup_listTransacao
    let filter = registro.filter(value => value.descricao.toUpperCase().includes(key.toUpperCase()))
    this.listTransacao = filter
  }

  filtrar(value) {
    this.empresaSelect = value
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }


  filtrarEmpresa(codigoEmpresa) {
    this.loading = true

    this.dataSource.data = this.listRegistro

    if (!codigoEmpresa) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
      return
    }

    let filter = this.dataSource.data.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.empresaSelect = this.dataSource.data


    setTimeout(() => {
      this.loading = false
    }, 2000)
  }

}
