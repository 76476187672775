export class TicketReserva {
    id: string
    criadoPor: string
    nrDoc: string
    tipoDoc: string
    nrDocRef: string
    tipoDocRef: string
    tipoMarcacao: string
    dataInclusaoDocumento: string
    cpfPaciente: string
    dataReserva: string
    horarioReserva: string
    tipoConsulta: string
    ativo: boolean
    cpfMedico: string
    codigoEmpresa: number
    cpfAcompanhante: string
    motivoConsulta: string
    tipoAtendimento: string
    criadoEm: string
    status: string
    nomePaciente?: string
    nomeMedico?: string
    especialidade?: string
    desTipoAgendamento?: string
    descTIpoConsulta?: string
}
export class TicketConsulta {
    id: string
    criadoPor: string
    nrDoc: string
    tipoDoc: string
    tipoDocRef: string
    dataInclusaoDocumento: string
    numeroTabelaTexto: string
    nrDocRef: string
    cpfPaciente: string
    motivoConsulta: string
    status: string
    ativo: boolean
    codigoEmpresa: number
    cpfAcompanhante: string
    tipoMarcacao: string
    tipoAtendimento: string
    tipoConsulta: string
    dataConsulta: string
    horaConsulta: string
    cpfMedico: string
    codigoConsultorio: number
    criadoEm: string
    formaDeConsulta: string
    time?: any
    desStatus?: string
    crm?: string
}
export class TicketConsultaJOIN {
    id: string
    ativo: boolean
    codigoConsultorio: number
    identificacaoConsultorio: string
    codigoEmpresa: number
    cpfAcompanhante: string
    cpfMedico: string
    nomeMedico: string
    cpfPaciente: string
    nomePaciente: string
    dataConsulta: string
    horaConsulta: string
    motivoConsulta: string
    nrDoc: string
    nrDocRef: string
    status: string
    tipoAtendimento: string
    formaDeConsulta: string
    tipoConsulta: string
    descTipoConsulta: string
    tipoMarcacao: string
    especialidade: string
    descEspecialidade: string
    prioridadeAtendimento: string
    descPrioridadeAtendimento: string
    situacaoPaciente: string
    descSituacaoPaciente: string
    dataInclusaoDocumento : string
    time?: any
    idade?:number
    descTipoAgendamento?:string
}
export class TicketConsultaJOIN_recpcao {
    id: any
    criadoPor: any
    codigoEmpresa: any
    cpfMedico: any
    nomeMedico: any
    especialidade: any
    descEspecialidade: any
    crm: any
    nrDoc: any
    tipoDoc: any
    tipoDocRef: any
    dataInclusaoDocumento: any
    numeroTabelaTexto: any
    nrDocRef: any
    cpfPaciente: any
    nomePaciente: any
    motivoConsulta: any
    status: any
    ativo: any
    cpfAcompanhante: any
    formaDeConsulta: any
    tipoAtendimento: any
    tipoConsulta: any
    dataConsulta: any
    horaConsulta: any
    codigoConsultorio: any
    avaliacaoConsulta: any
    comentarioConsulta: any
    criadoEm: any
    backgroud: any
    fotoPerfil: any
    descTipoAtendimento: any
    descTipoConsulta: any
    destMotivoConsulta
}
export class TicketSenhaAtendimento {

    id: string
    criadoPor: string
    nrDoc: string
    dataInclusaoDocumento: string
    motivoConsulta: string
    prioridadeAtendimento: string
    situacaoPaciente: string
    senha: string
    horarioEncaminhamentoSalaEspera: string
    status: string
    ativo: boolean
    codigoEmpresa: number
    nrDocReferencia: string
    tipoDocReferencia: string
    criadoEm: string
    descPrioridade?: string
    nomePaciente?: string
    tipoDoc?: string
    descTipoDoc?: string
    descMotivo?: string
    destSituacao?: string


}
export class TicketSenhaAtendimentoJoin {
    codigoEmpresa: number
    nomeEmpresa: string
    ativo: boolean
    criadoPor: string
    dataInclusaoDocumento: string
    horarioEncaminhamentoSalaEspera: string
    id: string
    motivoConsulta: string
    nrDoc: string
    nrDocReferencia: string
    prioridadeAtendimento: string
    senha: string
    situacaoPaciente: string
    status: string
    tipoDocReferencia: string
    descTipoDocReferencia: string
    cpfAcompanhante: string
    cpfPaciente: string
    nomePaciente: string
    cpfMedico: string
    nomeMedico: string
    tipoDoc: string
    descTipoDoc: string
    criadoEm: string
}

export class TicketJOIN {

    codigoEmpresa: number
    nomeEmpresa: string
    ativo: boolean
    criadoPor: string
    dataInclusaoDocumento: string
    horarioEncaminhamentoSalaEspera: string
    id: string
    motivoConsulta: string
    numeroDocumento: string
    numeroDocumentoReferencia: string
    prioridadeAtendimento: string
    senha: 14
    situacaoPaciente: string
    status: string
    tipoDocReferencia: string
    descTipoDocReferencia: string
    cpfAcompanhante: string
    cpfPaciente: string
    nomePaciente: string
    cpfMedico: string
    nomeMedico: string
    tipoDoc: string
    descTipoDoc: string
    criadoEm: string
    cpf: string
    nomeCompleto: string
    nomeSocial: string
    dataNascimento: string
    profissao: string
    tipoInscricao: string
    descTipoInscricao: string
    situacao: string
    especialidadeProfissional: string
    valorEspecialidade: string
    timeStamp: string
    unidadeFederacao: string
    nr_Registro: 21
    formaDeTratamento: string
    bairro: string
    bancoPIS: string
    cartaoSUS: string
    carteiraReservista: string
    categoria: string
    cep: string
    certidaoFolha: string
    certidaoLivro: string
    certidaoMatricula: string
    certidaoTipo: string
    cidade: string
    cidadeVotacao: string
    complemento: string
    contaFGTS: string
    cor: string
    cpfConjuge: string
    cpfMae: string
    cpfPai: string
    cpfResponsavelTitular: string
    cpfTutorLegal: string
    dataChegadaBrasil: string
    dataEmissao_CT: string
    dataEmissao_RG: string
    dataOpcao: string
    deficiente: false
    digito: string
    endereco: string
    estado: string
    estadoCivil: string
    estadoNaturalidade: string
    estado_TE: string
    grauInstrucao: string
    grauParentescoSecundario: string
    grauParentescoTitular: string
    habilitacao: string
    nacionalidade: string
    naturalidade: string
    nomeMae: string
    nomePai: string
    nrCarteiraTrabalho: string
    numeroBeneficiario: string
    numeroEndereco: number
    operadoraConvenio: string
    orgao: string
    passaPorte: string
    pis: string
    planoDeSaude: string
    possuiPlanoDeSaude: false
    rg: string
    ric: string
    serie: string
    sessao: string
    sexo: string
    telefoneComercial: string
    telefoneFixo: string
    tipoDeficiencia: string
    tipoDocID: string
    tipoPessoa: string
    tituloEleitor: string
    uf_CT: string
    validade: string
    zona: string
    descMotivoConsulta?: string
    descFormaDeConsulta?: string
    descTipoAtendimento?: string
    avaliacaoConsulta: number
    comentarioConsulta: null
    codigoConsultorio: number
    identificacaoConsultorio: string
    dataConsulta: string
    horaConsulta: string
    nrDoc: string
    nrDocRef: string
    numeroTabelaTexto: string
    tipoAtendimento: string
    tipoConsulta: string
    tipoDocRef: string
    formaDeConsulta: string
}