import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracao-inicial',
  templateUrl: './configuracao-inicial.component.html',
  styleUrls: ['./configuracao-inicial.component.scss']
})
export class ConfiguracaoInicialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
