export class CamposAtribuicaoStatus {
    forEach(arg0: (element: any) => void) {
      throw new Error("Method not implemented.");
    }
    id?: string;
    codigoDoDocumento?: string;
    codigoDeStatus?: string;
    evento?: string;
    acao?: string;
  }
  