import { Component, OnInit } from '@angular/core';
import { caracteristicaDeDado } from '../service/classificacao-de-dados/caracteristica-de-dados.model';
import { ClassificacaoDeDadosService } from '../service/classificacao-de-dados/classificacao-de-dados.service';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { fromEvent } from 'rxjs';
import { read, utils } from 'xlsx';
import { FormControl } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { ConteudoDados } from '../service/conteudo-de-dados.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-upload-conteudo-dados',
  templateUrl: './upload-conteudo-dados.component.html',
  styleUrls: ['./upload-conteudo-dados.component.scss']
})
export class UploadConteudoDadosComponent implements OnInit {
  caracteristicas: caracteristicaDeDado[];
  caracteristica: string;
  porcentagemConversao: number = 0;
  porcentagemSalvar: number = 0;
  conteudosDeDados: conteudoDeDado[] = [];
  usarCodigoDeNegocio: boolean;
  usarPrimeiraLinha: boolean;
  desabilitarSalvar: boolean = false;
  desabilitarCancelar: boolean = false;
  salvandoDados: boolean = false;
  registroAtual: string;
  arquivo: File;
  metodoEnvio: string = '';
  constructor(
    private ServiceClassificacaoDeDados: ClassificacaoDeDadosService,
  ) { }

  async ngOnInit() {
    this.caracteristicas = await this.ServiceClassificacaoDeDados.listarCaracteristicas();
  }

  converterArquivo() {
    this.porcentagemConversao = 0;

    if (!!this.arquivo) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.arquivo);

      fromEvent(fileReader, 'progress').subscribe((event: ProgressEvent) => {
        this.porcentagemConversao = Math.round(100 * event.loaded / event.total)
      })

      fromEvent(fileReader, 'loadend').subscribe(() => {
        let workbook = read(fileReader.result, { type: 'binary' });
        const pastaXlsx = workbook.SheetNames[0];
        const objetoPlanilha = utils.sheet_to_json(workbook.Sheets[pastaXlsx], { header: ['codigoDeNegocio', 'valor'], blankrows: false })
        const linhas = this.usarPrimeiraLinha ? objetoPlanilha as any[] : objetoPlanilha.slice(1, objetoPlanilha.length) as any[]
        this.conteudosDeDados = linhas.map(linha => {
          return this.usarCodigoDeNegocio ? {
            codigoCaracteristica: this.caracteristica,
            codigoDeNegocio: linha.codigoDeNegocio as string,
            valor: linha.valor as string,
          } : {
              codigoCaracteristica: this.caracteristica,
              codigoDeNegocio: '',
              valor: linha.valor as string,
            }
        })
      })
    } else {
      this.conteudosDeDados = [];
    }
  }

  async uploadPlanilha(arquivo: File) {
    this.arquivo = arquivo;
    this.converterArquivo()
  }

  cancelar() {
    this.porcentagemConversao = 0;
    this.conteudosDeDados = [];
    this.usarCodigoDeNegocio = false;
    this.usarPrimeiraLinha = false;
    delete this.arquivo;
  }

  async obterValoresConteudosAtuais(){
    const conteudosDeDados:conteudoDeDado[] = await this.ServiceClassificacaoDeDados.buscarConteudosDaCaracteristica({codigoCaracteristica: this.caracteristica});
    const valores:string[] = conteudosDeDados.map(conteudo => conteudo.valor);
    return valores
  }

  async acompanharRegistro() {
    const valoresAtuais = await this.obterValoresConteudosAtuais();
    for (let i = 0; i < this.conteudosDeDados.length; i++) {
      if(!valoresAtuais.includes(this.conteudosDeDados[i].valor)){
        this.porcentagemSalvar = Math.round(100 * (i + 1) / this.conteudosDeDados.length)
        this.registroAtual = `Salvando ${this.conteudosDeDados[i].valor.slice(0, 10)}... Sair desta tela cancelará esta ação, mas é possível retomá-la posteriormente.`
        await this.ServiceClassificacaoDeDados.criarConteudo(this.conteudosDeDados[i])  
      }else{
        this.porcentagemSalvar = Math.round(100 * (i + 1) / this.conteudosDeDados.length)
        this.registroAtual = `Conteúdo ${this.conteudosDeDados[i].valor.slice(0, 10)} já salvo, salvando próximo`;
        await new Promise(terminou =>{
          setTimeout(terminou ,500)
        })
      }
    }
  }

  async enviarTodosRegistros() {
    this.salvandoDados = true;
    await this.ServiceClassificacaoDeDados.gravarListaConteudo({ listaConteudos: this.conteudosDeDados })
      .then(() => {
        this.salvandoDados = false;
      })
  }

  async salvar() {
    this.desabilitarCancelar = true;
    this.desabilitarSalvar = true;
    if (this.metodoEnvio === 'acompanhar') {
      await this.acompanharRegistro();
    } else if (this.metodoEnvio === 'enviarTodos') {
      await this.enviarTodosRegistros();
    } else {
      this.registroAtual = 'Por favor selecione um método de envio';
      this.desabilitarCancelar = false;
      this.desabilitarSalvar = false;
      return
    }
    this.registroAtual = 'Registros salvos com sucesso';
    this.desabilitarCancelar = false;
    this.desabilitarSalvar = false;
  }
}
