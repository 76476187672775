import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BularioService } from '../service/bulario.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
interface Bulario {
  id: string,
  usuarioId: string,
  companyId: string,
  medicamento: string,
  bula: string,
  createdAt: string,
  ativo: true
}
@Component({
  selector: 'app-bulario',
  templateUrl: './bulario.component.html',
  styleUrls: ['./bulario.component.scss']
})
export class BularioComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ['id', 'medicamento'];
  myControl = new FormControl();
  filteredOptions: Observable<Bulario[]>;
  dataSource: MatTableDataSource<Bulario>;
  detalhe: Bulario;
  viewDetalhe: boolean;

  constructor(private service: BularioService) {
    window.open('https://consultas.anvisa.gov.br/#/')

  }

  async ngOnInit() {
    return
    await this.listar().finally(
      () => {
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.dataSource.data.slice())
          );
      }
    )
    this.viewDetalhe = true
    this.detalhe = this.dataSource.data[0]
  }
  async listar() {
    await this.service.listar().toPromise()
      .then(
        (dados) => {
          this.dataSource = new MatTableDataSource(dados)
          this.dataSource.paginator = this.paginator

        }
      )
  }
  displayFn(user: Bulario): string {
    return user && user.medicamento ? user.medicamento : '';
  }
  abrirDetalhe(dados: Bulario) {
    this.viewDetalhe = true
    this.detalhe = dados
  }
  private _filter(name: string): Bulario[] {
    const filterValue = name.toLowerCase();

    return this.dataSource.data.filter(option => option.medicamento.toLowerCase().indexOf(filterValue) === 0);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
