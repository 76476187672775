import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { usuario } from './usuario.model';
import { SessaoUser } from 'src/app/user.reg';
import { BuscarDataService } from '../buscar-data.service';

const sessao = new SessaoUser

const url = `${new environment().api}CadastroUsuario/`
@Injectable({
  providedIn: 'root'
})
export class CadastroUsuarioService {

  constructor(
    private http: HttpClient,
    private data: BuscarDataService
    ) { }

  GetStatus()
  {
    return this.http.get(url + 'StatusUsuario')
  }  

  GerarSenha(cpf)
  {
    return this.http.get(new environment().api + 'GerarSenha/' + cpf);
  }

  DesabilitarUsuario(cpf:string)
  {
    return this.http.get(new environment().api + 'DesabilitarUsuario/' + cpf + '/' + sessao.cpf)
  }

  async GetJoin(){
   
    let result = await this.http.get(url + 'Join').toPromise()
   
    return result
  }

  async PutCadUsuario(registro){

    console.log("ROTA ALTERAR ", url + + registro.id + '/'+ sessao.sessao_id + '/' + this.data.getTIMESTAMP(), registro)
   await this.http.put(url + registro.id + '/'+ sessao.sessao_id + '/' + this.data.getTIMESTAMP(), registro).toPromise()
   return true
   
  }



  async criarUsuario(novoUsuario:Omit<usuario, 'id' | 'usuarioId' | 'numeroDocumento' | 'dataInclusaoDocumento' | 'confirmado'>){
    this.http.post(url, novoUsuario, {observe: 'body'}).toPromise()
    .then(() => console.log('Usuário criado com sucesso'))
    .catch((err:HttpErrorResponse) => console.log(`Não foi possível criar o usuário ${err.status}: ${err.message}`))
  }

  async listarUsuarios():Promise<usuario[]>{
    let usuarios:usuario[] = [];
    this.http.get<usuario[]>(url, {observe: 'body'}).toPromise()
    .then(res => usuarios = res)
    .catch((err:HttpErrorResponse) => console.log(`Não foi possível criar o usuário ${err.status}: ${err.message}`))
    return usuarios;
  }

  async buscarUsuario({id}:{id:string}):Promise<usuario>{
    let usuario: usuario;
    this.http.get<usuario>(`${url}/${id}`, {observe: 'body'}).toPromise()
    .then(res => usuario = res)
    .catch((err:HttpErrorResponse) => {
      if(err.status === 404){
        console.log('Usuário não encontrado')
      }
      console.log(`Não foi possível buscar o usuário ${err.status}: ${err.message}`)
    })
    return usuario;
  }


  async buscarUsuarioCpf(cpf:string):Promise<usuario>{
    let usuario: usuario;
    await this.http.get<usuario[]>(`${url}/(filtrarCpf)/${cpf}`, {observe: 'body'}).toPromise()
    .then(([res]) => {
      console.log(res);
      usuario = res
    })
    .catch((err:HttpErrorResponse) => {
      if(err.status === 404){
        console.error('Usuário não encontrado')
      }
      console.error(`Não foi possível buscar o usuário ${err.status}: ${err.message}`)
    })
    console.log(usuario)
    return usuario;
  } 

  async buscarUsuarioEmail(email:string):Promise<usuario>{
    let usuario: usuario
    this.http.get<usuario>(`${url}/FiltrarEmail/${email}`, {observe: 'body'}).toPromise()
    .then(res => usuario = res)
    .catch((err:HttpErrorResponse) => {
      if(err.status === 404) { return false}
      else {console.error(err)}
    })
    return usuario
  }

  async confirmarEmailUsuario({cpf}:{cpf:string}){
    let usuario: usuario;
    this.http.get<usuario>(`${url}/ConfirmarEmail/${cpf}`, {observe: 'body'}).toPromise()
    .then(res => usuario = res)
    .catch((err:HttpErrorResponse) => {
      if(err.status === 404){
        console.log('Usuário não encontrado')
      }
      console.log(`Não foi possível confirmar o usuário ${err.status}: ${err.message}`)
    })
    console.log(usuario)
    return usuario;
  }

  async enviarEmailConfirmacao({email, name, cpf}:{email:string, name:string, cpf:string}){
    this.http.get(`${new environment().api}SendEmail/${email}/${name}/${cpf}`).toPromise()
    .then(() => console.log('Email confirmado com sucesso'))
    .catch((err:HttpErrorResponse) => console.error(`Não foi possível confirmar o email ${err.status}: ${err.message}`))
  }
}
