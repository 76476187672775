import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { PortalDicionarioService } from 'src/app/service/portal-dicionario.service';
import { DicionarioPortal } from '../dicionario/modal';

@Component({
  selector: 'app-turismo',
  templateUrl: './turismo.component.html',
  styleUrls: ['./turismo.component.scss']
})
export class TurismoComponent implements OnInit {
  @ViewChild('owlElement', { static: true }) owlElement: CarouselComponent;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
    nav: false,
    responsiveRefreshRate: 2000
  }
  noticias: DicionarioPortal[] = [];
  destaque = {
    n1: new DicionarioPortal,
    n2: new DicionarioPortal,
    n3: new DicionarioPortal,
    n4: new DicionarioPortal,
    n5: new DicionarioPortal,
  }
  constructor(
    private _portal: PortalDicionarioService,
    private _filter: FiltrarDadosService,) {
    window.scrollTo(0, 0)
  }

  async ngOnInit() {
    this.getNoticias()
  }
  async getNoticias() {
    let get = await this._portal.get()
    let noticias = get.filter(value => value.tipoConteudo == 'TIPNOTIC8')//TIPNOTIC2    
    this.noticias = noticias.filter((v, i) => i > 4)
    console.log(noticias.length, this.noticias.length)
    if (noticias.length > 0) {
      this.destaque.n1 = noticias[0] ? noticias[0] : new DicionarioPortal
      this.destaque.n2 = noticias[1] ? noticias[1] : new DicionarioPortal
      this.destaque.n3 = noticias[2] ? noticias[2] : new DicionarioPortal
      this.destaque.n4 = noticias[3] ? noticias[3] : new DicionarioPortal
      this.destaque.n5 = noticias[4] ? noticias[4] : new DicionarioPortal
    } else {
    }



    // if(this.noticias.length > 5){
    //   this.customOptions.loop = true
    // }
  }
  background(image) {
    return {
      'background-size': 'cover',
      'background-position': 'top',
      'background-repeat': 'no-repeat',
      'background-image': `url(${image.fotoLink})`
    }
  }
  next() {
    this.owlElement.next()
  }
  back() {
    this.owlElement.prev()
  }
  navLink(link) {
    window.open(link, '_blank');
  }
  convertDate(criadoEm) {
    let convert = this._filter.convertCriadoEm(criadoEm)
    return convert
  }
}
