export class SessaoUser {
    codigoEmpresa = parseInt(sessionStorage.getItem('codEmpresa'))
    nameEmpresa = sessionStorage.getItem('nomeEmpresa')
    cpf = sessionStorage.getItem('cpf')
    cnpj = sessionStorage.getItem('cnpj')
    crm = sessionStorage.getItem('crm')
    name = sessionStorage.getItem('userName')
    sessao_id = sessionStorage.getItem('tokken')
    assinante = sessionStorage.getItem('assinante')
    listEmpresas = sessionStorage.getItem('listEmpresa')
    formaTratamento = sessionStorage.getItem('formaTratamento')
    unidadeFederativa = sessionStorage.getItem('unidadeFederativa')
    especialidade = sessionStorage.getItem('especialidade')
    tipoUsuario = sessionStorage.getItem('tipoUsuario')
    routerActive = localStorage.getItem('routerActive') ? localStorage.getItem('routerActive').split(',') : []
}