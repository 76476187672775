import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DadosSaudePacienteService } from 'src/app/service/dados-saude-paciente/dados-saude-paciente.service';
import { FichaConsultaMedicaService } from 'src/app/service/ficha-consulta-medica/ficha-consulta-medica.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { CirurgiasPaciente } from '../../model';
const sessao = new SessaoUser
@Component({
  selector: 'app-atualizar-historico-saude',
  templateUrl: './atualizar-historico-saude.component.html',
  styleUrls: ['./atualizar-historico-saude.component.scss']
})
export class AtualizarHistoricoSaudeComponent implements OnInit {

  @Input('cpf') cpf: string
  @Input('type') type: 'cirurgia' | 'h-doenca' | '' = ''
  @Output('close') exit = new EventEmitter
  @Input('register') register = new CirurgiasPaciente

  formulario: FormGroup
  loading: boolean = false

  CIDs: any[] = [];
  CIDs_all: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private _mensagem: MensagemService,
    private service: DadosSaudePacienteService,
    private serviceConsulta: FichaConsultaMedicaService) { }


  async ngOnInit() {
    await this.creatForm()
    this.getCIDS()
    if (this.register.id) {
      this.formulario.patchValue(this.register)
      this.formulario.disable()
    }
   
  }
  getCIDS() {
    this.serviceConsulta.getCID().then(
      (cids) => {
        let list: any = {}
        cids.forEach(e => {
          list.descricao = e.valor
          list.codigo = e.codigoDeNegocio
          this.CIDs.push(`${list.codigo} ${list.descricao}`)
          this.CIDs_all.push(list)
        });
      }
    )
  }

  creatForm() {
    if (this.type == 'cirurgia') {
      this.formulario = this.formBuilder.group({
        cpfPaciente: new FormControl(''),
        cirurgia: new FormControl(''),
        motivo: new FormControl(''),
        cid: new FormControl(''),
        data: new FormControl(''),
        id: new FormControl(''),
        status: new FormControl(''),
        criadoPor: new FormControl(''),
        criadoEm: new FormControl(''),
      })
    }
  }
  getExameCID(type) {
    console.log('!', type)
  }
  excluirRegistro() {
    let register = this.formulario.getRawValue() as CirurgiasPaciente
    if (!register.id) {
      this._mensagem.creat('Não é possível excluir este registro', { erro: true })
      return
    }
    register.status = 'SCA0002'
    this.service.putCirurgia(register).toPromise()
      .then(() => {
        this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
        this.close()
      })
      .catch(error => {
        console.log('erro!', error)
        this._mensagem.creat('Não foi possível alterar registro', { erro: true })
      })
  }
  cancelarRegistro() {
    this.close()
  }
  fecharCadastro() { }

  salvar() {
    let register = this.formulario.getRawValue() as CirurgiasPaciente

    if (!register.id) {
      delete register.id
      register.criadoPor = sessao.cpf
      register.cpfPaciente = this.cpf
      register.status = 'SCA0001'
      console.log(register)
      this.service.postCirurgia(register).toPromise()
        .then(() => this.close())
        .catch(error => {
          this._mensagem.creat('Não foi possível gravar registro', { erro: true })
          console.log('erro!', error)
        })

    } else {
      console.log(register)
      this.service.putCirurgia(register).toPromise()
        .then(() => {
          this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
          this.close()
        })
        .catch(error => {
          console.log('erro!', error)
          this._mensagem.creat('Não foi possível alterar registro', { erro: true })
        })
    }

  }
  _control(control: string) {
    return this.formulario.get(control)
  }
  close() {
    this.exit.emit()
  }

}
