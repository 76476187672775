import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MinhasConexoesProfissionaisService {
    url = new environment().api
    FichaConexoesProfissionais = "FichaConexoesProfissionais/"

    constructor(private http: HttpClient) {
    }

    getMinhasConexoesProfissionaisID(cpf: any): Observable<Object> {
        console.log("Dados API Id(FichaConexoesProfissionais):" + this.url + this.FichaConexoesProfissionais + "FiltrarCpf/" + cpf)
        return this.http.get(this.url + this.FichaConexoesProfissionais + "FiltrarCpf/" + cpf)
    }

    PostCadastrarConexoesProfissionais(dados) {
        console.log("Cadastrar Minhas conexões profissionais(FichaConexoesProfissionais): " + this.url + this.FichaConexoesProfissionais)
        return this.http.post(this.url + this.FichaConexoesProfissionais, dados)
    }

    AlterarConexoesProfissionais(id: any, dados): Observable<Object> {
        console.log("Alterar Minhas conexões profissionais(FichaConexoesProfissionais): " + this.url + this.FichaConexoesProfissionais + id, dados)
        return this.http.put(this.url + this.FichaConexoesProfissionais + id, dados)
    }

}


