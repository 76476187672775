type API =
  | "apimyclinicsdev/api/"
  | "apimyclinicsprd/api/"
  | "apimyclinicsqas/api/"
  | "api/api/";

export class environment {
  readonly production: false;
  readonly url = "https://myclinics.com.br/";
  host: API;
  api = "";
  // api: localStorage.getItem('api') //https://myclinics.com.br/api/api/
  api_local = "http://192.168.15.20:3000/";
  tokenRecaptchat = "6LfvRVIaAAAAAAQJ-7vaoi90tgS0vEe1pJNfO_py";
  constructor() {
    let getValue = window.location.href.split("/#/")[0].split("/");
    let environment = getValue[getValue.length - 1].toLowerCase();
    switch (environment) {
      case "myclinics.com.br":
        this.host = "apimyclinicsprd/api/";
        break;
      case "dev":
        this.host = "apimyclinicsdev/api/";
        break;
      case "qas":
        this.host = "apimyclinicsqas/api/";
        break;
      default:
        // this.host = 'api/api/'
        this.host = "apimyclinicsprd/api/";
        break;
    }

    this.api = this.url + this.host;
  }
}
