import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValorConsultaService {

  url = new environment().api
  url_local = new environment().api_local
  api = 'ValorConsulta/';

  constructor(private http: HttpClient) { }

  //************************** ValorConsulta **********************************
  listarValorConsulta(id?) {
    console.log('ValorConsulta :' + this.url + this.api)
    if (id) {
      return this.http.get(this.url + this.api + id)
    }
    return this.http.get(this.url + this.api)
  }
  cadastrarValorConsulta(dados: any) {
    console.log('ValorConsulta-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)

  }
  alterarValorConsulta(id: string, dados) {
    console.log('ValorConsulta-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirValorConsulta(id: string, dados?) {
    console.log('ValorConsulta-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END ValorConsulta **********************************  

}

