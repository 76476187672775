export class TabelaMenu {
  id : ''
  menuIdentity: 0
  menuID: string
  menuName: string
  parent_MenuID: string
  user_Roll: string
  menuFileName: string
  menuURL: string
  tipoObjeto: string
  tipoAcao: string
  ordem: string
  transacao: string
  descricao: string
  documento: string
  tipoDocumento: string
  classDocumento: string
  statusDocumento: string
  documentoId: string
  usE_YN: string
  app: string
  erp: string
  portal: string
  medico: string
  imagem: string
  setupInicial : string
  checkbox? : boolean
  erp_paciente: boolean
  erp_business:boolean
}