import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { FormCadastroEmpresa } from '../cadastro-empresa/model';
import { ToastrService } from 'ngx-toastr';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];
@Component({
  selector: 'app-view-empresa',
  templateUrl: './view-empresa.component.html',
  styleUrls: ['./view-empresa.component.scss']
})
export class ViewEmpresaComponent implements OnInit {
  usuarioId: string;
  displayedColumns: string[] = ['consultorio', 'cnpj','unidade','acao'];
  dataSource 
  empresas : FormCadastroEmpresa
  constructor(
    private router : Router,
     private url : ActivatedRoute, 
     private service : ConfiguracaoInicialService,
     private mensagem : ToastrService) {

    this.usuarioId = sessionStorage.getItem('tokken')
   }

  ngOnInit() {
    this.buscarEmpresas()
  }
  buscarEmpresas(){
    this.service.listarEmpresa().toPromise()
    .then(
      (dados : Array<FormCadastroEmpresa>)=>{
        let usuarioId = this.usuarioId.toUpperCase()
          let filtro = dados.filter(function(reg){
            return reg.usuarioId.toUpperCase() == usuarioId && reg.ativo == true
          })
          if(filtro.length > 0){
            this.dataSource = filtro
          }
          console.log(filtro)
      }
    )
  }
  excluir(dados : FormCadastroEmpresa){
    if(confirm(`Deseja mesmo deletar empresa ${dados.consultorio} ? \n CNPJ : ${dados.cnpj}`)){
      dados.ativo = false
      this.service.alterarEmpresa(dados.id,dados).subscribe(
        ()=> {
          this.mensagem.success('Deletado com sucesso')
          this.buscarEmpresas()
        }
      )
    }
  }
  cadastrar(){
    localStorage.removeItem('alterar-empresa')
    this.router.navigate([`home/cadastro-empresa/${this.usuarioId}`])
  }
  editar(id : string){
    localStorage.setItem('alterar-empresa',id)
    this.router.navigate([`home/cadastro-empresa/${this.usuarioId}`])

  }

}
