export class SenhaAtendimentoForm{
    id: string
    nrDoc: string
    dataInclusaoDocumento: string
    numeroTabelaTexto: string
    nomePaciente: string
    nrDocReferencia: string
    cpfPaciente: string
    cpfMedico?: string
    motivoConsulta: string
    prioridadeAtendimento: string
    situacaoPaciente: string
    senha: string
    horarioEncaminhamentoSalaEspera: string
    status: string
    ativo: boolean
    crmMedico: string
    codigoEmpresa: number
    criadoPor: string
}