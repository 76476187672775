import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { MensagemService } from "./mensagem.service";
const tokken = "79310505DjwManKRek143192712";
export class CEP {
  status?: boolean;
  return?: string;
  result?: {
    cep?: string;
    logradouro?: string;
    complemento?: string;
    bairro?: string;
    localidade?: string;
    uf?: string;
  };
}
export class CPF {
  status?: boolean;
  return?: string;
  message?: string;
  result?: {
    numero_de_cpf?: string;
    nome_da_pf?: string;
    data_nascimento?: string;
    situacao_cadastral?: string;
    data_inscricao?: string;
    digito_verificador?: string;
    comprovante_emitido?: string;
    comprovante_emitido_data?: string;
  };
}
export class CNPJ {
  status: true;
  return: string;
  result: {
    numero_de_inscricao: string;
    tipo: string;
    abertura: string;
    nome: string;
    fantasia: string;
    porte: string;
    atividade_principal: {
      text: string;
      code: string;
    };
    atividades_secundarias: [
      {
        text: string;
        code: string;
      }
    ];
    natureza_juridica: string;
    logradouro: string;
    numero: string;
    complemento: string;
    cep: string;
    bairro: string;
    municipio: string;
    uf: string;
    email: string;
    telefone: string;
    entidade_federativo_responsavel: string;
    situacao: string;
    motivo_situacao_cadastral: string;
    dt_situacao_cadastral: string;
    situacao_especial: string;
    data_situacao_especial: string;
    capital_social: string;
    quadro_socios: string[];
    quadro_de_socios: string[];
  };
}
@Injectable({
  providedIn: "root",
})
export class HubDevService {
  constructor(private http: HttpClient, private mensagem: MensagemService) {}

  async vCPF(cpf, dataNascimento, name, noConvertDate?) {
    let result = {
      status: false,
      message: null,
    };
    // console.log(`https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${dataNascimento}&token=${tokken}`)
    await this.http
      .get<CPF>(
        `https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${dataNascimento}&token=${tokken}`
      )
      .toPromise()
      .then((res) => {
        console.log(res, dataNascimento);
        if (!res.status) {
          this.mensagem.creat(res.message, { erro: true });
          result.status = false;
          result.message = res.message;
          return;
        }
        try {
          if (!noConvertDate) {
            const data = new Date(dataNascimento).toLocaleDateString("pt-BR", {
              timeZone: "UTC",
            });
          }
          const vNasc = res.result.data_nascimento.replace(/\D+/g, "");
          const Nasc = dataNascimento.replace(/\D+/g, "");
          const resNome = res.result.nome_da_pf.toLocaleUpperCase();
          const nome = name.toLocaleUpperCase();
          if (vNasc == Nasc) {
            if (resNome == nome) {
              result.status = true;
            } else {
              result.message = "Nome invalido";
              this.mensagem.creat("Nome invalido", { erro: true });
            }
          } else {
            result.status = false;
            result.message =
              "Data de nascimento incoerente com dados informado";
            this.mensagem.creat("Data de Nascimento invalida", { erro: true });
          }
        } catch {
          result.status = false;
          result.message = "Não foi possivel validar registro";
        }
      });

    return result;
  }

  vCPFParametro(cpf) {
    return this.http.get<CPF>(
      `https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&token=${tokken}`,
      { reportProgress: true }
    );
  }

  vCNPJ(cnpj) {
    console.log(cnpj);
    return this.http.get<CNPJ>(
      `https://ws.hubdodesenvolvedor.com.br/v2/cnpj/?cnpj=${cnpj}&token=${tokken}`,
      { reportProgress: true }
    );
  }

  vCEP(cep) {
    return this.http.get<CEP>(
      `https://ws.hubdodesenvolvedor.com.br/v2/cep3/?cep=${cep}&token=${tokken}`
    );
  }
}
