export class CN_CONVERSAS {
    id: string
    destinatario: string
    criadoPor: string
    criadoEm: string
    status: string
}
export class CN_CONVERSAS_JOIN {
    backgroud: any
    conversaId: string
    criadoEm: string
    criadoPor: string
    cpfFoto: string
    destinatario: string
    destinatarioEmail: string
    destinatarioNome: string
    fotoPerfil: any
    remetenteEmail: string
    remetenteNome: string
    status: string
    naoLidas: number
    temMensagem: boolean
}
export class CN_MENSAGENS {
    id: string
    remetente: string
    destinatario: string
    mensagem: string
    anexo: string
    nomeArquivo: string
    conversaId: string
    criadoPor: string
    criadoEm: string
    status: string
}
export class CHAT {
    contato = new CN_CONVERSAS
    mensagens: CN_MENSAGENS[] = []
}