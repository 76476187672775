import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ReceituarioDoPaciente } from 'src/app/atendimento-clinico/conduta-medica/model';
import { DadosSaudePacienteService } from 'src/app/service/dados-saude-paciente/dados-saude-paciente.service';
import { ReceituarioService } from 'src/app/service/receituario/receituario.service';
import { FraturasPaciente, SequelasPaciente } from '../model';

@Component({
  selector: 'app-prontuario-dados-saude',
  templateUrl: './prontuario-dados-saude.component.html',
  styleUrls: ['./prontuario-dados-saude.component.scss']
})
export class ProntuarioDadosSaudeComponent implements OnInit {
  @Input('cpf') cpf: string
  openHistorico: 'medicacao' | 'lesao' | 'fratura' = 'medicacao'
  viewForm: 'medicacao' | 'lesao' | 'fratura' | '' = ''

  loading: boolean

  dataSourceMedicamento = new MatTableDataSource()
  displayedColumnsMedicamento = ['detalhe', 'nomeComercial', 'frequencia', 'dosagem', 'via', 'periodo', 'observacao']

  dataSourceLesao = new MatTableDataSource()
  displayedColumnsLesao = ['detalhe', 'lesao', 'observacao', 'data']

  dataSourceFratura = new MatTableDataSource()
  displayedColumnsFratura = ['detalhe', 'fraturas', 'observacao', 'data']

  register: ReceituarioDoPaciente | SequelasPaciente | FraturasPaciente

  constructor(
    private _receituario: ReceituarioService,
    private _dadosSaude: DadosSaudePacienteService
  ) { }

  ngOnInit() {
    this.getRegistro()
  }

  getRegistro() {
    this._receituario.get().toPromise()
      .then(res => {

        if (!res.length) return

        const cpf = this.cpf

        let filter = res
          .filter(value => value.cpfPaciente == cpf && value.status == 'SCA0001')
        this.dataSourceMedicamento = new MatTableDataSource(filter)

      })

    this._dadosSaude.getSequenas().toPromise()
      .then(res => {

        if (!res.length) return

        const cpf = this.cpf

        let filter = res
          .filter(value => value.cpfPaciente == cpf && value.status == 'SCA0001')
        this.dataSourceLesao = new MatTableDataSource(filter)

      })

    this._dadosSaude.getFraturas().toPromise()
      .then(res => {

        if (!res.length) return

        const cpf = this.cpf

        let filter = res
          .filter(value => value.cpfPaciente == cpf && value.status == 'SCA0001')
        this.dataSourceFratura = new MatTableDataSource(filter)

      })

  }
  reset() {
    switch (this.openHistorico) {
      case 'fratura': this.register = new FraturasPaciente; break;
      case 'lesao': this.register = new SequelasPaciente; break;
      case 'medicacao': this.register = new ReceituarioDoPaciente; break;
    }

  }
  openCadastro(register: any) {
    this.register = register
    this.viewForm = this.openHistorico
  }
  viewHistorico(type: any) {
    this.openHistorico = type
  }
}
