import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { DicionarioPortal } from '../dicionario/modal';
const url = new environment().api
@Component({
  selector: 'app-corona-virus',
  templateUrl: './corona-virus.component.html',
  styleUrls: ['./corona-virus.component.scss']
})
export class CoronaVirusComponent implements OnInit {
  noticias: DicionarioPortal[] = [];

  public innerWidth: any;
  public mostraNoticias: boolean;
  colapsarMenu: boolean;
  mostraSintomas: boolean;
  mostraPrevencao: boolean;
  loading: boolean;

  constructor(private http: HttpClient, private _filter: FiltrarDadosService) { }


  ngOnInit() {
    window.addEventListener('resize', () => {
      this.innerWidth = window.outerWidth;
    });
    this.mostraNoticias = true;
    this.mostraPrevencao = true;
    this.mostraSintomas = true;
    setInterval(() => this.buscarNoticias(), 5000)
  }
  buscarNoticias() {

    this.http.get(`${url}DicionarioPortal`).toPromise()
      .then(
        (dados: Array<any>) => {
          this.noticias = dados.filter(v => v.tipoConteudo == 'TIPNOTIC3')
          this._filter.ordernar('criadoEm', this.noticias)
        }
      )
  }
  go(link) {
    window.open(link)
  }

}
