import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitor-paciente',
  templateUrl: './monitor-paciente.component.html',
  styleUrls: ['./monitor-paciente.component.scss']
})
export class MonitorPacienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
