import { Injectable } from '@angular/core';
import { Estados } from './estados';

@Injectable({
  providedIn: 'root'
})
export class SaudeAZService {

  constructor() { }

  getEstados() {
    let estados = new Estados
    return estados.estados()
  }
}
