import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

const url= `${new environment().api}ConsultarTabelas/`;

@Injectable({
  providedIn: 'root'
})
export class ConsultarTabelasService {

  constructor(
    private http: HttpClient,
    private mensagem: ToastrService,
    ) { }

  async listarTabelas():Promise<string[]>{
    let tabelas:string[]
    await this.http.get<string[]>(`${url}`, {observe:'body'}).toPromise()
    .then(res => tabelas = res)
    .catch((error:HttpErrorResponse) =>this.mensagem.error(`Erro ao listar tabelas: ${error.message}`,`Erro: ${error.status || 'Listar Tabelas'}`)
    )
    return tabelas;
  }
  
  async ListarCampos(tabela):Promise<string[]>{
    let campos: string[]
    await this.http.get<string[]>(`${url}ConsultarTabela/`+ tabela, {observe: 'body'}).toPromise()
    .then(res => campos = res)
    .catch((error:HttpErrorResponse) => this.mensagem.error(error.message, error.statusText))
    return campos
  }
  async ListarTabelasNaoCadastradas():Promise<string[]>{
    let tabelas: string[]
    await this.http.get<string[]>(`${url}aindaNaoCadastradas`, {observe: 'body'}).toPromise()
    .then(res => tabelas = res)
    .catch((error:HttpErrorResponse) => this.mensagem.error(error.message, error.statusText))
    return tabelas
  }
  
}
