import { Component, OnInit, ViewChild } from '@angular/core';
import { CadastroCentroLucroService } from '../service/cadastro-centro-de-lucro.service';
import { DadosCadastroCentroDeLucro } from './modelsCadastroCentroLucro';
import { SessaoUser } from '../user.reg';
import { MensagemService } from '../service/mensagem.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { UsuarioService } from '../service/usuario.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FiltrarDadosService } from '../service/filtrar-dados.service';

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-centro-de-lucro',
  templateUrl: './cadastro-centro-de-lucro.component.html',
  styleUrls: ['./cadastro-centro-de-lucro.component.scss']
})
export class CadastroCentroDeLucroComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'codCentroLucro', 'descricao']
  mask_cpf = "000.000.000-00"
  mask_codigo = "000000000"
  formulario: FormGroup
  listRegistro: DadosCadastroCentroDeLucro[]
  viewCadastro = false
  listEmpresa = []
  alteracao: boolean = false;
  listTipo = []
  nome: string
  dataSource = new MatTableDataSource()
  loading: boolean
  botaoSalvar = false
  botaoCancelar = false
  selectEmpresa = 0
  viewConfirmDelete: boolean

  constructor(
    private service: CadastroCentroLucroService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private conteudo: ConteudoDeDadosService,
    private usuario: UsuarioService,
    private mensagem: MensagemService,
    private _filtro: FiltrarDadosService
  ) { }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getTipo()
    this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: sessao.cpf,
      timestamp: '',
      codigo: 0,
      ativoApartirDe: '',
      atribuicaoResposanvelDesde: '',
      observacao: '',
      tipo: '',
      descricao: '',
      atribuicaoResponsavelAte: '',
      inativoApartirDe: '',
      responsavel: '',
      status: '',
      codigoEmpresa: 0,
      ////
      nome: new FormControl({ disabled: true }),
      criadoEm: '',
      codigoAssinante: ''
    })
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getTipo() {
    let get = await this.conteudo.get('TPLUCR')
    this.listTipo = get
  }

  async buscarUsuario(cpf, openCadastro?) {
    let valid = this.formulario.controls['responsavel'].valid

    if (valid || openCadastro) {
      let resposta = await this.usuario.getRegistro(cpf)
      this.nome = resposta[0].nomeCompleto
    } else this.nome = null
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.nome = null
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  inserir() {
    let registro = this.formulario.value

    delete registro.ativoApartirDe
    delete registro.id
    delete registro.inativoApartirDe
    delete registro.timestamp
    delete registro.nome
    delete registro.criadoEm
    delete registro.codigoAssinante
    delete registro.nomeEmpresa

    registro.criadoPor = sessao.cpf

    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  async getRegistro() {
    this.loading = true
    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
            }
          })
          this.dataSource.sort = this.sort
          this.loading = false
        })
  }

  async openCadastro(registro) {
    // registro.timestamp = ''
    // delete registro.nomeEmpresa
    // registro.nome = ""
    // registro.criadoEm = ""
    // registro.codigoAssinante = ""

    this.formulario.patchValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.buscarUsuario(registro.responsavel, true)
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  async alterar() {
    let registro = this.formulario.value

    delete registro.nome
    delete registro.nomeEmpresa

    await this.service.alterar(registro).toPromise()
      .then(resposta => {
        this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
        this.viewCadastro = true
        this.getRegistro()
          .finally(() => this.filtrarEmpresa())
        this.formulario.disable()
      }).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    await this.buscarUsuario(registro.cpf, true)
    await this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.id) return

    delete registro.nome
    delete registro.timestamp

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  async fecharCadastro() {
    this.viewCadastro = false
    // await this.getRegistro()
    //   .finally(() => this.filtrarEmpresa())
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }
    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }
}
