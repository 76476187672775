import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LegislacaoTributariaModel } from "../ajustes/legislacao-tributaria/legislacao-tributaria.model";
import { SessaoUser } from "../user.reg";
import { BuscarDataService } from "./buscar-data.service";

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class LegislacaoTributariaService {

    url = new environment().api
    api = 'SIS_LEGTRIB_CAB/'
    jsonPais = './assets/json/json-paises.json'

    constructor(private http: HttpClient, private data: BuscarDataService){
    }

    listar(){
        return this.http.get<LegislacaoTributariaModel[]>(this.url + this.api)
    }

    enviar(registro: LegislacaoTributariaModel){
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: LegislacaoTributariaModel){
        return this.http.put(this.url + this.api + `${registro.id}/ ${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: LegislacaoTributariaModel, ativo){
        if(ativo) {
            registro.status = 'SDA0001'
        }
        else {
            registro.status = 'SDA0002'
        }

        let resposta

        await this.alterar(registro).toPromise()
        .then(res => { resposta = res })
        .catch(error => {console.log('error', error)})

        return resposta
    }

    getPaises(): Observable<any>{
        return this.http.get(this.jsonPais)
    }

}