import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Arquivo } from './arquivo.model';
import { environment } from 'src/environments/environment';
import { DiarioDeAcessoService } from '../diario-de-acesso/diario-de-acesso.service';

const urlUpload = `${new environment().api}UploadArquivo`;
const urlDownload = `${new environment().api}TransferenciaArquivos`;

@Injectable({
  providedIn: 'root'
})
export class ArquivosService {

  constructor(
    private http: HttpClient,
    private serviceDiarioAcesso: DiarioDeAcessoService,
  ) { }

   /**
     * @description 
     * Cria um FormData e envia arquivos para o ftp.
     * 
     * @usageNotes
     * - tipoDocumento: Tipo de documento da tabela documentos,
     * - arquivo: Arquivo no formato File,
     * - nrDocumento: Número do documento que está sendo utiliado,
     * - tipoAnexo: Tipo do documento de anexo. (Não é a extensão),
     * - criadoPor: Cpf usuário conectado,
     * - path: caminho onde o arquivo será salvo
     * 
     * @returns
     * Retorna um objeto com nome e caminho do arquivo 
     * {nomeArquivo:string, caminho:string}
    */
  async uploadArquivo(arquivo: Arquivo):Promise<{nomeArquivo:string, caminho:string}>{
    let nomeECaminho = {nomeArquivo:'', caminho:''}

    const formData = new FormData();
    formData.append('arquivo', arquivo.arquivo, arquivo.arquivo.name)
    const cpf = await this.serviceDiarioAcesso.cpfDestaSessao();
    arquivo.criadoPor = cpf;
    const _url = `${urlUpload}/${arquivo.nrDocumento}/${arquivo.tipoDocumento}/${arquivo.tipoAnexo}/${arquivo.path}/${cpf}`;

    await this.http.post<{nomeArquivo:string, caminho:string}[]>(_url, formData, {observe:'body'}).toPromise()
    .then(res => {
      nomeECaminho = res[0]
    })
    .catch(e => console.error(e, e.error))

    return nomeECaminho;
  }

  downloadArquivo({ nomeArquivo, path }):string{
    const _path = (path + '\\').replace(':', '%3A').replace(/[\\]/g, '%5C').replace(nomeArquivo, '');
    const _url = `${urlDownload}/Download/${nomeArquivo}/${_path}`
    return _url;
  }
}
