import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Acesso } from "./diario-de-acesso.model";
import { BuscarDataService } from "../buscar-data.service";
import { BnNgIdleService } from "bn-ng-idle";
import { Router } from "@angular/router";
import { Usuario } from "./usuario.model";
import { MensagemService } from "src/app/service/mensagem.service";
import { StatusDeSessao } from "./statusDeSessao.class";
import { SessaoUser } from "src/app/user.reg";
import { CronometroService } from "../cronometro.service";

const sessao = new SessaoUser();

@Injectable({
  providedIn: "root",
})
export class DiarioDeAcessoService {
  urlDiarioAcesso = `${new environment().api}DiarioAcessoUsuario/`;
  // urlDiarioAcesso = `https://localhost:5001/api/DiarioAcessoUsuario/`;
  urlUsuario = `${new environment().api}CadastroUsuario/(filtrarCpf)`;
  quantidadeSessoesMultiplas = 0;

  constructor(
    private http: HttpClient,
    private data: BuscarDataService,
    private router: Router,
    private avisoOciosidade: BnNgIdleService,
    private statusDeSessao: StatusDeSessao,
    private mensagem: MensagemService,
    private cronometro: CronometroService
  ) {}

  diarioAcesso = new Acesso();

  teste: any;

  cpfDestaSessao() {
    return sessao.cpf;
  }

  GetJoin() {
    return this.http.get(this.urlDiarioAcesso + "/Join");
  }

  async PostDiarioAcesso(status) {
    let diario = new Acesso();

    let coordenada;
    await new Promise((res) => {
      navigator.geolocation.getCurrentPosition(async (res) => {
        coordenada = `${JSON.stringify(res.coords.latitude)},${JSON.stringify(
          res.coords.longitude
        )}`;
        console.log("Coordenada: ", coordenada);
        console.log("LATIDUDE: ", res.coords);

        delete diario.id;
        diario.coordenadaGps = coordenada;
        diario.codigoEmpresa = sessao.codigoEmpresa;
        diario.inicioDaSessao = this.data.getDataInclusao();
        diario.fimDaSessao = "";
        diario.horarioLocal = diario.inicioDaSessao;
        diario.cpf = sessao.cpf;
        diario.tempoDeSessao = "";
        diario.localDeSessao = "ERP";
        diario.status = status;
        diario.dispositivoDeAcesso = "";
        diario.ipLocal = await this.pegarIp();

        // console.log('JSON ACESSO:', JSON.stringify(diario))
        //console.log(this.urlDiarioAcesso, JSON.stringify(diario))
        let result;
        this.http
          .post(this.urlDiarioAcesso, diario)
          .toPromise()
          .then((res: Acesso) => {
            result = res;
            sessionStorage.setItem("idDiario", res.id);
          })
          .catch((err: any) => {
            console.log("DIARIO ACESSO ERRO", err);
          });
        return result;
      });
    });
  }

  async getRegistro(codigoEmpresa: number, resumido?: boolean) {
    let result = [];
    console.log("historico!!");
    await this.http
      .get<Array<any>>(
        this.urlDiarioAcesso + "JoinFiltrarEmpresa/" + codigoEmpresa
      )
      .toPromise()
      .then((res) => {
        if (res.length > 0) {
          let registro = [];
          res.forEach((value) => {
            value.diarioAcesso.forEach((item) => {
              item.nome = value.nome;
              item.nrRegistro = value.nrRegistro;
              item.especialidadeDesc = value.especialidadeDesc;
              registro.push(item);
            });
          });
          if (resumido) {
            let registro = [];
            res.forEach((value) => {
              let time = [];
              value.diarioAcesso.forEach((item) => {
                time.push({
                  inicioDaSessao: item.inicioDaSessao,
                  fimDaSessao: item.fimDaSessao,
                });
              });
              value.time = time;
              registro.push(value);
            });
            result = registro;
          } else result = registro;
        }
      })
      .catch((error) => console.log("=>", error));
    return result;
  }

  PutDiario(id, status) {
    let diario = new Acesso();
    this.http
      .get(this.urlDiarioAcesso + id)
      .toPromise()
      .then((res: Acesso) => {
        diario = res;
        console.log("GET DIARIO", res);
        diario.fimDaSessao = this.data.getDataInclusao();
        diario.status = status;
        diario.tempoDeSessao = this.cronometro.diferencaHorario(
          diario.inicioDaSessao,
          diario.fimDaSessao
        );

        console.log("JSON DIARIO PUT ", JSON.stringify(diario));
        this.http
          .put(
            this.urlDiarioAcesso +
              id +
              "/" +
              diario.fimDaSessao +
              "/" +
              sessao.sessao_id,
            diario
          )
          .toPromise()
          .then((result: any) => console.log("PUT DIARIO", result))
          .catch((err: any) => console.log("PUT ERRO DIARIO", err.error));
      })
      .catch((err) => console.log(err));
  }

  async buscarUsuario({ cpf }: { cpf: String }): Promise<Usuario> {
    let usuario: Usuario;
    await this.http
      .get<[Usuario]>(`${this.urlUsuario}/${cpf}`, { observe: "response" })
      .toPromise()
      .then((res) => {
        usuario = res.body.pop();
      })
      .catch((erro) =>
        this.mensagem.creat(`Erro ao buscar usuário ${JSON.stringify(erro)}`, {
          erro: true,
        })
      );
    return usuario;
  }

  async pegarIp() {
    let ip: String = "000.000.0.000";
    await this.http
      .get<{ ip: String }>("https://api.ipify.org/?format=json", {
        observe: "response",
      })
      .toPromise()
      .then((res) => {
        ip = res.body.ip || "ip nao disponivel";
      })
      .catch((error) => {});
    return ip;
  }
  async getUltimoRegistro(cpf) {
    let result = new Acesso();
    await this.http
      .get<any>(this.urlDiarioAcesso + "UltimoRegistro/" + cpf)
      .toPromise()
      .then((res) => {
        if (res.success) {
          result = res.result[0];
        }
      });
    return result;
  }

  //async idDestaSessao(): Promise<String> {
  //  let id: String;
  //  try {
  //    id = window.sessionStorage.getItem('SessionId');
  //    if (id === null) {
  //      throw "Erro ao recuperar o id desta sessão " + id
  //    }
  //    return id;
  //  } catch (err) {
  //    this.mensagem.creat(err, { erro: true });
  //  }
  //}

  //async usuarioDestaSessao(): Promise<Usuario> {
  //  let cpf: String = sessao.cpf
  //  let usuario: Usuario = await this.buscarUsuario({ cpf });
  //  return usuario;
  //}

  async todasAsSessoes(): Promise<Acesso[]> {
    let acessos: Acesso[];
    await this.http
      .get<Acesso[]>(this.urlDiarioAcesso, { observe: "response" })
      .toPromise()
      .then((res) => (acessos = res.body))
      .catch((error) =>
        this.mensagem.creat(
          `Erro ao buscar todas sessões ${JSON.stringify(error)}`,
          { erro: true }
        )
      );
    return acessos;
  }

  //async DesconectarSessoesAtivas({ cpf }): Promise<void> {
  //  let sessoesUsuario: Acesso[];
  //  await this.http.get<Acesso[]>(`${this.urlDiarioAcesso}/(filtrarCpf)/${cpf}`, { observe: 'response' }).toPromise()
  //    .then(res => sessoesUsuario = res.body)
  //    .catch(error => console.log(error));
  //  let sessoesAtivas = sessoesUsuario.filter(sessao => sessao.status === this.statusDeSessao.status.conectada);
  //  if (sessoesAtivas.length > this.quantidadeSessoesMultiplas) {
  //    sessoesAtivas.map(async sessao => {
  //      this.mensagem.creat('Desconectando por sessão múltipla', { erro: true })
  //      await this.MudarStatusSessao({ id: sessao.id, status: this.statusDeSessao.status.sessaoMultiplaLogout });
  //    })
  //  }
  //}

  //async criarSessao({ cpf, localDeSessao }: { cpf: String, localDeSessao: String }) {
  //  try {
  //
  //    await this.DesconectarSessoesAtivas({ cpf });
  //
  //    let acesso: Acesso = {
  //      cpf,
  //      localDeSessao,
  //      inicioDaSessao: this.data.getDataInclusao(),
  //      ipLocal: await this.pegarIp(),
  //      status: this.statusDeSessao.status.conectada,
  //      codigoEmpresa: sessao.codigoEmpresa,
  //      coordenadaGps: '',
  //      tempoDeSessao: '',
  //
  //    }
  //
  //    console.log('JSON ACESSO:', JSON.stringify(acesso))
  //
  //    await this.http.post<Acesso>(this.urlDiarioAcesso, acesso, { observe: 'response' }).toPromise()
  //      .then(res => {
  //        window.sessionStorage.setItem('SessionId', res.body.id);
  //      })
  //      .catch(error => this.mensagem.creat(`${error}`, { erro: true }));
  //  } catch (err) {
  //    console.log(err)
  //  }
  //}

  async BuscarSessao({ id }: { id: String }): Promise<Acesso> {
    let acesso: Acesso;
    await this.http
      .get<Acesso>(`${this.urlDiarioAcesso}/${id}`, { observe: "response" })
      .toPromise()
      .then((res) => (acesso = res.body))
      .catch((error) =>
        this.mensagem.creat(`Erro ao buscar sessão ${JSON.stringify(error)}`, {
          erro: true,
        })
      );
    return acesso;
  }

  //  async StatusDestaSessao(): Promise<String> {
  //    let status: String
  //    let id: String = await this.idDestaSessao();
  //    await this.BuscarSessao({ id })
  //      .then(acesso => status = acesso.status)
  //      .catch(error => this.mensagem.creat(`Erro ao buscar o status dessa sessão ${JSON.stringify(error)}`, { erro: true }));
  //    return status
  //  }
  //
  // async MonitorarSessao({ tempo }: { tempo: number }) {
  //   let intervalo: number = tempo * 1000;
  //   let monitorar = setInterval(async () => {
  //     let status: String = await this.StatusDestaSessao();
  //     if (status !== this.statusDeSessao.status.conectada) {
  //       this.mensagem.creat('A sessão será desconectada', { erro: true })
  //       window.localStorage.removeItem('SessionId');
  //       this.router.navigateByUrl('');
  //       clearInterval(monitorar);
  //     }
  //   }, intervalo);
  // }

  //private async AtualizarSessao({ acesso, id }: { acesso: Acesso, id: String }): Promise<Acesso> {
  //  let sessao: Acesso = acesso;
  //  let usuario = await this.usuarioDestaSessao();
  //  let dataHoje: String = this.data.getDataInclusao();
  //  await this.http.put<Acesso>(`${this.urlDiarioAcesso}/${id}/${dataHoje}/${usuario.id}`, sessao, { observe: 'response' }).toPromise()
  //    .then(res => sessao = res.body)
  //    .catch(error => this.mensagem.creat(`Erro ao atualizar sessão ${JSON.stringify(error)}`, { erro: true }))
  //  return sessao;
  //}

  //async MudarStatusSessao({ id, status }: { id: String, status: String }) {
  //  let acesso: Acesso = await this.BuscarSessao({ id });
  //  acesso.status = status;
  //  acesso.fimDaSessao = status !== this.statusDeSessao.status.conectada ? this.data.getDataInclusao() : '';
  //  acesso = await this.AtualizarSessao({ acesso, id });
  //  return acesso;
  //}

  //async fazerLogout() {
  //  let idSessao: String = await this.idDestaSessao();
  //  this.MudarStatusSessao({ id: idSessao, status: this.statusDeSessao.status.normalLogout });
  //}

  //async MonitorarOciosidade({ tempoLogout }: { tempoLogout: number }) {
  //  let idSessao: String = await this.idDestaSessao();
  //  const currentRout = this.router.url;
  //  this.avisoOciosidade.startWatching(tempoLogout).subscribe(async usuarioOcioso => {
  //    if (usuarioOcioso) {
  //      if (currentRout !== '/') {
  //        this.mensagem.creat('Sessão desconectada por ociosidade', { erro: true });
  //        await this.MudarStatusSessao({ id: idSessao, status: this.statusDeSessao.status.ociosidadeLogout });
  //        this.avisoOciosidade.resetTimer();
  //      }
  //    }
  //  })
  //}
}
