import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface form  {
  tabela: ''
  campo: ''
  codigo: ''
  descricao: ''
}

@Component({
  selector: 'app-documentacao',
  templateUrl: './documentacao.component.html',
  styleUrls: ['./documentacao.component.scss']
})

export class DocumentacaoComponent implements OnInit {
  documentos : form
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get('http://192.168.15.20:3000/descricao').subscribe(
      (registro :form)=>{
        this.documentos = registro
      }
    )
  }

}
