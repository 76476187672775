import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
export class ValidCRM {
  crm: string
  uf: string
  nome:string
  type: string
  status: string
}
@Injectable({
  providedIn: 'root'
})
export class ValidarCrmService {
  url = 'assets/CRMS/'
  constructor(private http: HttpClient) { }

  async get(crm: string, uf: string) {
    let result = new ValidCRM
    console.log(this.url + `${uf.toUpperCase()}.txt`)
    await this.http.get(this.url + `${uf.toUpperCase()}.txt`, { responseType: 'text' }).toPromise()
      .then(res => {
        let convert = res.split('\n')
        let registros = []
        convert.forEach(value => {
          let item = {
            crm: value.split('!')[0],
            uf: value.split('!')[1],
            nome: value.split('!')[2],
            type: value.split('!')[3],
            status: value.split('!')[4]
          }
          registros.push(item)
        });
        let filter = registros.filter(x => x.crm == crm)
        if(filter.length > 0){
          result = filter[0]
        }
      })
    return result
  }

}
