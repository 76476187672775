import { Component, OnInit, NgModule, TemplateRef, ViewChild } from '@angular/core';
import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DadosFichaGeralEmpresa } from '../regras-de-imposto/model';
import { dadosRegraConfirmacaoConsulta } from './modelsRegraConfirmacaoConsulta';
import { RegraConfirmacaoConsultaService } from 'src/app/service/regra-confirmacao-consulta.service';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service';
import { SessaoUser } from 'src/app/user.reg';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { MatSort, MatTableDataSource } from '@angular/material';

const sessao = new SessaoUser
@Component({
  selector: 'app-regra-confirmacao-consulta',
  templateUrl: './regra-confirmacao-consulta.component.html',
  styleUrls: ['./regra-confirmacao-consulta.component.scss'],
})

export class RegraConfirmacaoConsultaComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns: string[] = ['detalhe', 'codigoEmpresa', 'prazoDeConfirmacao']
  viewCadastro = false
  alteracao: boolean = false;
  loadings: boolean
  formulario: FormGroup
  listEmpresa = []
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  selectEmpresa = '0'

  constructor(
    public serviceDataHora: BuscarDataService,
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private service: RegraConfirmacaoConsultaService,
    private _mensagem: MensagemService
  ) { }

  ngOnInit() {
    this.creatForm()
    this.getEmpresa()
    this.getRegistro()
  }
  async getRegistro(codigoEmpresa?) {
    codigoEmpresa = parseInt(codigoEmpresa)
    let registro: any = await this.service.get(codigoEmpresa)
    this.dataSource = new MatTableDataSource(registro)
    this.dataSource.sort = this.sort
    console.log(registro, codigoEmpresa)
  }
  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }
  async inserir() {
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      return
    }
    this.loading = true
    let registro = this.formulario.value
    Object.keys(registro).forEach(value => {
      if (!registro[value]) {
        delete registro[value]
      }
    });
    await this.service.post(registro).toPromise()
      .then(res => {
        this._mensagem.creat('Dado cadastrado com sucesso', { sucesso: true })
        this.viewCadastro = false
      }
      ).catch(error => {
        console.log('erro=>', error)
        this._mensagem.creat('Falha ao cadastrar registro', { erro: true })
      })

    this.loading = false
    this.formulario.disable()
    this.getRegistro(this.selectEmpresa)
  }
  async cancelarRegistro() {
    let id = this.formulario.controls['id'].value
    if (id) {
      let get = await this.service.getID(id)
      this.formulario.patchValue(get)
    }

  }
  openCadastro(item) {
    this.alteracao = true
    this.formulario.patchValue(item)
    this.formulario.disable()
    this.viewCadastro = true
    console.log('formulairo', this.formulario.value)
  }
  async alterar() {
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      return
    }
    this.loading = true
    let registro = this.formulario.value
    await this.service.put(registro.id, registro).toPromise()
      .then(res => {
        this._mensagem.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
      })
      .catch(error => {
        this._mensagem.creat('Erro ao alterar registro', { erro: true })
      })
    this.getRegistro(this.selectEmpresa)
    this.loading = false
  }
  async excluirRegistro() {
    this.loading = true
    let registro = this.formulario.value
    await this.service.delete(registro).toPromise()
      .then(res => {
        this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
        this.viewCadastro = false
        this.formulario.reset()
      }).catch(error => {
        this._mensagem.creat('Não foi possivel excluir registro', { erro: true })
      })
    this.loading = false
    this.getRegistro(this.selectEmpresa)
  }


  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: new FormControl('', Validators.required),
      criadoPor: '',
      timeStamp: '',
      status: '',
      prazoDeConfirmacao: new FormControl('', Validators.required),
    })
  }
  _control(name) {
    return this.formulario.get(name).hasError('required')
  }
  filtrar(value: string) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

}