import { FlatTreeControl } from '@angular/cdk/tree';
import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTree } from '@angular/material';
import { SaldoContaContabil, PlanoContaContabeisModel } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FluxoCaixaService } from 'src/app/service/fluxo-caixa.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { PlanoContaContabeisService } from 'src/app/service/plano-conta-contabeis/plano-conta-contabeis.service';
export class tree {
  descricao: string
  index: number
  codigo: string
  codigoDeNegocio: string
  children: []
}
@Component({
  selector: 'app-tree-fluxo-caixa',
  templateUrl: './tree-fluxo-caixa.component.html',
  styleUrls: ['./tree-fluxo-caixa.component.scss']
})
export class TreeFluxoCaixaComponent implements OnInit {
  @Input() codigoEmpresa: number
  @Input() dataFilter: { ano: number; mes: any; };
  @ViewChild('tree', { static: false }) tree: MatTree<any>
  private _transformer = (node: any, level: number) => {
    console.log('NODE!', node)
    return {
      expandable: !!node.children && node.children.length > 0,
      caracteristica: `${node.balancoPatrimonialZero ? node.balancoPatrimonialZero : '0'}/
      ${node.balancoPatrimonialUm ? node.balancoPatrimonialUm : '0'}/
      ${node.balancoPatrimonialDois ? node.balancoPatrimonialDois : '0'}/
      ${node.balancoPatrimonialTres ? node.balancoPatrimonialTres : '0'} `,
      name: node.descricao,
      codigo: node.codigo,
      conta: node.codigoContaContabil,
      saldo: node.saldo,
      dadoSaldo: node.dadoSaldo,
      total: node.total,
      totalApagar: node.total_faturaContasApagar ? node.total_faturaContasApagar : 0,
      level: level,
      filter: node.filter,
      faturaMedica: node.faturaMedica,
      faturaContasApagar: node.faturaContasApagar,
    };
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  openAll: boolean;
  backup_registro: any;
  selectDado: SaldoContaContabil;
  viewConfirm: boolean;
  nivel_0: ConteudoDados[] = []
  nivel_1: ConteudoDados[] = []
  nivel_2: ConteudoDados[] = []
  nivel_3: ConteudoDados[] = []
  nivel_4: ConteudoDados[] = []
  loading: boolean;
  conta: PlanoContaContabeisModel[] = [];
  contas = [];
  listDates = []
  constructor(
    private _conteudo: ConteudoDeDadosService,
    private _conta: PlanoContaContabeisService,
    private _mensagem: MensagemService,
    private _fatura: FluxoCaixaService,
    private _data: BuscarDataService) {
  }

  ngOnInit() {
    console.log('FILTER DATE', this.dataFilter)
    this.ContaContabil()
    this.getDates()

  }

  async inserirConta(tree: Array<any>, conta: PlanoContaContabeisModel[]) {
    console.log('RODRIGO', conta)
    let total_faturaMedica = []
    let total_faturaContasApagar = []

    if (tree.length < 1) return
    //Nivel 0
    tree.forEach(nivel_1 => {
      nivel_1.children.forEach(nivel_2 => {
        nivel_2.valores = []
        console.log('NIVEL 2', nivel_2)
        nivel_2.children.forEach(nivel_3 => {

          nivel_3.faturaMedica = []
          let filter = conta.filter(value => value.codigoContaContabil == nivel_3.codigoContaContabil)
          if (filter.length > 0) {
            filter.forEach((nivel, index) => {

              console.log('CONTA 4', nivel.fatura)
              let total = nivel.fatura.map(value => value.valorTotalFatura).reduce((total, value) => total + value)
              nivel_3.faturaMedica = total
              nivel_2.valores.push(total)


            });
          }
        });
      });
    });
    tree.forEach(nivel_1 => {
      let total = 0
      nivel_1.children.forEach(nivel_2 => {
        try {
          nivel_2.total = nivel_2.valores.reduce((total, valor) => total + valor)
          total += nivel_2.total
        } catch {
          nivel_2.total = 0
        }

      })
      nivel_1.total = total
    })


    this.dataSource.data = tree
    setTimeout(() => {
      this.tree.treeControl.expandAll()
      let fluxoCaixa = document.getElementById('fluxo-caixa')
      let scroll = (fluxoCaixa.scrollWidth / 2) - 440
      fluxoCaixa.scrollLeft = - scroll
        ; 1000
    })
    this.loading = false
    return

  }
  getDates() {
    let filterDate = `${this.dataFilter.ano}-${this.dataFilter.mes}-02`
    let date = new Date(filterDate);
    this.listDates = []
    for (let i = -3; i < 4; i++) {
      let getDate = new Date(date.getFullYear(), date.getMonth() + (i+1)).toISOString().split("T")[0];;
      let getMes = new Date(date.getFullYear(), date.getMonth() + (i+1)).getMonth();
      let mes = this._data.getMeses(getMes)
      this.listDates.push({ date: getDate, mes: mes.name, active: false })
    }
    this.listDates.filter((value,i,self) => { 
      let m1= parseInt(value.date.split('-')[1])
      let m2 = parseInt(filterDate.split('-')[1])
      if (m1 == m2) {
        self[i+1].active = true
       } 
    })
    this.listDates.reverse()
    console.log('listDates', this.listDates)

  }
  teste() {
    let cont = 10131122024
    let registro = []
    for (let i = 24; i <= 50; i++) {
      let item = {
        criadoPor: "46096593879",
        codigoEmpresa: 84,
        cpfProfissional: (cont + 1).toString(),
        status: "SDO0100",
        tipoConexao: null,
        timeStamp: "30072020162950642",
        cpfAutor: "10203814851"
      }
      cont++
      registro.push(item)
    }
    console.log(JSON.stringify(registro))
  }



  async getConteudo() {

    this.nivel_0 = await this._conteudo.get('FCNIV0')
    this.nivel_1 = await this._conteudo.get('FCNIV1')
    // this.nivel_2 = await this._conteudo.get('FCNIV2')
    // this.nivel_3 = await this._conteudo.get('FCNIV3')
    // this.nivel_4 = await this._conteudo.get('FCNIV4')

    this.nivel_0 = this.nivel_0.filter(value => value.codigoReferencia == 'FCNIV01' || 'FCNIV03' || 'FCNIV02')
    this.nivel_1 = this.nivel_1.filter(value => value.dependencia == 'FCNIV01' || value.dependencia == 'FCNIV02' || value.dependencia == 'FCNIV03')

    console.log('NIIVEL', this.nivel_0, this.nivel_1)

    let tree = []

    this.nivel_0.forEach((nivel, index) => {
      let item = {
        descricao: nivel.valor,
        index: index,
        codigo: nivel.codigoReferencia,
        codigoDeNegocio: nivel.codigoDeNegocio,
        children: []
      }
      tree.push(item)
    });

    ///Nivel 1 
    this.nivel_1.forEach((nivel, index) => {


      tree.forEach(value => {
        if (value.codigo == nivel.dependencia) {
          let item = {
            descricao: nivel.valor,
            index: index,
            codigo: nivel.codigoReferencia,
            codigoDeNegocio: nivel.codigoDeNegocio,
            children: []
          }
          value.children.push(item)
        }
      })
    })
    console.log(tree)
    tree.forEach(value => {
      value.children.forEach((value_2, index) => {
        console.log('value children', value_2)
        this.conta.forEach(async item => {
          console.log('CONTAS>>>', item.dfcNivelUm, value_2.codigo)
          if (item.dfcNivelUm == value_2.codigo) {
            let registro = {
              descricao: item.descricao,
              index: index,
              saldo: item.saldo,
              dadoSaldo: item.registroSaldo,
              dataLancamento: item.dataLancamento,
              periodo: item.periodo,
              codigoContaContabil: item.codigoContaContabil,
              balancoPatrimonialUm: item.balancoPatrimonialUm,
              children: []
            }
            console.log('registro end', registro)
            value_2.children.push(registro)
          }
        })
      });
    });

    console.log('tree end', tree)
    this.inserirConta(tree, this.conta)
  }

  ContaContabil() {
    this.loading = true
    let empresa = this.codigoEmpresa
    this._conta.getContaContabilCodigoEmpresa(empresa).toPromise()
      .then(async (res: PlanoContaContabeisModel[]) => {

        let filter = res
          .filter(value => value.dfcNivelZero == 'FCNIV01' || value.dfcNivelZero == 'FCNIV02'|| value.dfcNivelZero == 'FCNIV03')
          .filter(value => value.dfcNivelUm == 'FCNIV11' || value.dfcNivelUm == 'FCNIV12' || value.dfcNivelUm == 'FCNIV13')

        this.conta = filter
        let faturaMedica = []
        let faturaContasApagar = []
        let faturaRecebimentoMaterial = []
        faturaMedica = await this._fatura.getFaturaMedica(empresa)
        faturaContasApagar = await this._fatura.getContasApagar(empresa)
        faturaRecebimentoMaterial = await this._fatura.getRecebimentoMaterial(empresa)
        console.log('faturaContasApagar', faturaContasApagar)

        let listContas = []
        let listContasDate = []
        let data = this.dataFilter
        if (!data) { this.loading = false; return }
        try {
          this.conta.forEach(conta => {
            let item = faturaMedica
              .filter(v => v.status == 'SDO0125' || v.status == 'SDO0124')
              .filter(v => v.contaContabilCredora == conta.codigoContaContabil)
              .filter(v => {
                return new Date(v.dataLancamento).getMonth() == data.mes && new Date(v.dataLancamento).getFullYear() == data.ano
              })
            if (item.length > 0) {
              conta.fatura = item
              listContas.push(conta)
              // list
            }

          });
          this.conta.forEach(conta => {
            let item = faturaRecebimentoMaterial
              .filter(v => v.codigoContaContabil == conta.codigoContaContabil)

            if (item.length > 0) {
              conta.fatura = item
              listContas.push(conta)
            } else {
            }

          });
          this.conta.forEach(conta => {
            let item = faturaContasApagar
              .filter(v => v.contaD == conta.codigoContaContabil || v.contaC == conta.codigoContaContabil
              )
            if (item.length > 0) {
              conta.fatura = item
              console.table(item)
              listContas.push(conta)
            }

          });
          this.conta = listContas
          console.log('FATURAAA', listContas)
          this.getConteudo()
          this._mensagem.clear()
        } catch (error) {
          this.loading = false
          this._mensagem.creat('Falha ao buscar registro', { erro: true })
          console.log('ERRO!@', error)
        }




        // console.log('COonta', listContas)
        // this.conta = listContas
      })
  }
  hasChild = (_: number, node: any) => node.expandable;
}
