import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UploadArquivosService, UploadArquivo } from 'src/app/service/upload-arquivos.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { SessaoUser } from 'src/app/user.reg';
import { ParametrosDoSistema, ParametrosSistemaService } from 'src/app/service/parametros-sistema.service';
const sessao = new SessaoUser
@Component({
  selector: 'app-foto-perfil',
  templateUrl: './foto-perfil.component.html',
  styleUrls: ['./foto-perfil.component.scss']
})
export class FotoPerfilComponent implements OnInit {
  @Output() close = new EventEmitter
  @Output() response = new EventEmitter
  @Input() foto: any
  @Input() cpf: string
  image: string | ArrayBuffer;
  file: FormData;
  porcentagem: number;
  uploadSuccess: boolean;
  viewUpload: boolean;
  path: string;
  constructor(private service: UploadArquivosService, private date: BuscarDataService, private _parametro: ParametrosSistemaService) { }

  ngOnInit() {
    if (this.foto) {
      this.image = this.foto
    }
  }
  async getParametro() {
    let param: ParametrosDoSistema = await this._parametro.get('path_foto_usuario')
    this.path = param.valorDoParametro
  }
  uploading() {
    var formData = new FormData();
    formData.append('arquivo', this.file[0])
    console.log(formData)

    let info = new UploadArquivo
    info.path = this.path
    if (this.cpf) {
      info.nrDocumento = this.cpf
      info.criadoPor = this.cpf
    }else{
      info.nrDocumento = sessao.cpf
      info.criadoPor = sessao.cpf
    }

    info.tipoAnexo = "TIPANE9"
    info.tipoDocumento = "MSGUSU"
    info.arquivo = this.file[0].name
    this.porcentagem = 1
    this.service.upload(formData, info).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.porcentagem = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.uploadSuccess = true;
          this.response.emit(true)
          setTimeout(() => {
            this.uploadSuccess = false
            this.porcentagem = 0
          }, 3000)
        }
      },
      error => {
        console.log('ERRO!', error)
      }
    );
  }
  exit() {
    this.close.emit(false)
  }
  viewFile(file) {
    this.file = file
    //***** Visualizar documento
    const files = file[0]
    const reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = () => {
      this.image = reader.result
    };
    ////**** END Visualizar documento
    this.viewUpload = true
    console.log(file)
  }


}
