import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContatosService } from 'src/app/service/contatos.service';
import { FormContatos } from 'src/app/conversas/modal';
import { ToastrService } from 'ngx-toastr';
import { VideoChamadaService } from 'src/app/service/video-chamada.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { videoChamadaFrom } from '../sala-espera/model';
import { LoginService } from 'src/app/service/login.service';
import { DiarioLogin } from 'src/app/home/modal';
import { on } from 'process';
// declare var RTCMultiConnection
// const connection = new RTCMultiConnection()
@Component({
  selector: 'app-video-chamada',
  templateUrl: './video-chamada.component.html',
  styleUrls: ['./video-chamada.component.scss']
})
export class VideoChamadaComponent implements OnInit {
  @ViewChild("me", { static: true }) me: ElementRef;
  @ViewChild("remote", { static: false }) remote: any;
  @Input('id') id: string
  @Input('autor') autor: boolean
  @Input('contato') contato: boolean
  @Input('noHeader') noHeader: boolean
  @Output('close') exit = new EventEmitter
  @Output('close2') exit_2 = new EventEmitter
  @Input('destinatario') destinatario: boolean
  fullscreen: boolean
  fullscreen2: boolean
  viewListaContatos: boolean
  pc: any
  localStream: any;
  url: SafeResourceUrl;
  contatos: Array<FormContatos>
  cpf_medico = sessionStorage.getItem('cpf')
  listAz = []
  sair: boolean
  sair_2: boolean
  videoConvidado: boolean
  selecContato = new FormContatos
  url_2: SafeResourceUrl;
  id_videoContato: any;
  chat = new FormContatos
  chat_contato = new FormContatos
  openChat: boolean;
  userName: string;
  cpf: string;
  openChat_contato: boolean;
  id_contato: any;
  medico
  listOnline: any[];
  constructor(
    private renderer: Renderer2,
    public sanitizer: DomSanitizer,
    private service: ContatosService,
    private toast: ToastrService,
    private _videoChamada: VideoChamadaService,
    private _login: LoginService,
    private data: BuscarDataService) { }

  ngOnInit() {
    this.sair = false
    let url = `https://rtcmyclinics.web.app/?${this.autor ? 'medico' : 'paciente'}&${this.id}${this.contato ? '&contato' : ''}`
    // let url = `http://localhost:5000/?${this.autor ? 'medico' : 'paciente'}&${this.id}${this.contato ? '&contato' : ''}`
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    if (this.contato) {
      this.url_2 = this.url
    }
    this.userName = sessionStorage.getItem('userName')
    this.cpf = sessionStorage.getItem('cpf')
    if (this.destinatario) {
      setInterval(() => { this.obsMensage() }, 3000)
    } else {
      setInterval(() => { this.obsMensage() }, 1000)
    }
    this.getLogin()
    this.obsChamada()
  }

  adicionarMedico() {
    // if (this.contatos.length < 1) {
    //   this.toast.info('Não a medicos em sua lista de contatos para adicionar a chamada')
    //   return
    // }
    this.viewListaContatos = !this.viewListaContatos;
  }
  async listarContatos(filtro?) {

    this.service.listarCatalogoDeContatos().toPromise()
      .then(
        async (reg: Array<FormContatos>) => {
          let cpf = this.cpf_medico
          let listContatos = reg.filter(function (ref) {
            if (filtro == 'all' || !filtro) {
              return ref.ativo && ref.cpfMedicoConvidador == cpf
            }
            if (filtro) {
              console.log(cpf)
              return ref.ativo && ref.tipoUsuario == filtro && ref.cpfMedicoConvidador == cpf
            }
          })
          if (listContatos) {
            await this.listOnline.forEach(on => {
              listContatos.findIndex((v, i) => {
                if (v.cpfConvidado == on.cpf) {
                  listContatos[i].online = true
                }
              })
            });
            this.contatos = listContatos

          }
          if (listContatos.length < 1) {
            this.contatos = []
          }
          let ListOriginal = []
          let ListAZ = []
          listContatos.forEach(r => {
            ListOriginal.push({ a: r.nomeConvidado.substr(0, 1).toLocaleUpperCase() })
          });
          ListOriginal.forEach(item => {
            var duplicidade = ListAZ.findIndex(res => {
              return item.a == res.a
            }) > -1
            if (!duplicidade) {
              ListAZ.push(item)
            }
          });
          console.log(this.contatos)
          // this.listAz = ListAZ.sort(function (a, b) {
          //   return (a.a > b.a) ? 1 : (b.a > a.a) ? -1 : 0
          // })
        }
      )
  }
  getLogin() {
    this._login.get().toPromise()
      .then(
        (res: DiarioLogin[]) => {
          let cpf = this.cpf
          this.listOnline = res.map((r) => { return { cpf: r.cpf, status: r.status } })
          console.log('LIST > ', this.listOnline)
          this.listarContatos()
        }
      )
  }
  confirmContato(info) {
    if (!info.online) return
    this.selecContato = info
    this.adicionarVideoChamada()
  }
  async adicionarVideoChamada() {
    this.sair_2 = false
    this._videoChamada.addContato(this.id, true)
    this._videoChamada.criarSala(this.selecContato, true).then(
      async (reg) => {
        const id = reg.id
        this._videoChamada.banco().doc(id).collection('info').add(this.selecContato)

        this._videoChamada.banco().doc(id).collection('info').get().toPromise()
          .then((r) => {
            let info_id = r.docs[0].id
            this._videoChamada.banco().doc(id).collection('info').doc(info_id).update(this.selecContato)
          })
        this.id_contato = reg.id
        setTimeout(() => this.obsChamada(true), 2000)
        this.id_videoContato = reg.id
        let url = `https://rtcmyclinics.web.app/?medico&${id}&contato`
        // let url = `http://localhost:5000/?medico&${id}&contato`
        this.url_2 = this.sanitizer.bypassSecurityTrustResourceUrl(url)
        const registro = {
          codigoUnico: reg.id,
          remetente: this.cpf_medico,
          destinatario: this.selecContato.cpfConvidado,
          data: this.data.hoje(),
          status: 'pendente'
        }
        console.log(registro)
        this._videoChamada.registrar(registro).toPromise().then(
          (reg: videoChamadaFrom) => {
            // this.toast.info(`Convite enviado para ${this.selecContato.nomeConvidado}`)
            this.videoConvidado = true
            this.viewListaContatos = false
            this.selecContato = new FormContatos
            return
            let getClose = setInterval(() => {
              this._videoChamada.getVideoChamda().toPromise()
                .then(
                  (ref: Array<videoChamadaFrom>) => {
                    const cpf = this.cpf_medico
                    const cod = this.id_videoContato
                    let getEncerramento = ref.filter((x) => { return x.remetente == cpf && x.status == 'encerrado' && x.codigoUnico == cod })

                    if (getEncerramento.length > 0) {
                      reg.status = 'finalizado'
                      this._videoChamada.putVideoChamada(reg).toPromise()
                        .then(() => this.videoConvidado = false)
                      clearInterval(getClose)
                    }
                  }
                )
            }, 1000)
          }
        )
      }
    ).catch(
      () => {
        this.toast.error('Desculpe algo deu errado')

      }
    )



  }
  voltar() {
    this.selecContato = new FormContatos
  }
  obsMensage() {
    this._videoChamada.getVideoChamda().toPromise()
      .then(
        (reg: Array<videoChamadaFrom>) => {
          const cpf = this.cpf_medico
          let getInfo = reg.filter((r: videoChamadaFrom) => { return r.remetente == cpf && r.status == 'chat-pendente' })[0]
          let getInfo_contato
          if (this.destinatario) {
            getInfo_contato = reg.filter((r: videoChamadaFrom) => { return r.destinatario == cpf && r.status == 'chat-confirmado' })[0]

          } else {
            getInfo_contato = reg.filter((r: videoChamadaFrom) => { return r.remetente == cpf && r.status == 'chat-pendente-contato' })[0]

          }


          if (getInfo) {
            getInfo.status = 'chat-confirmado'
            localStorage.setItem('openChat', 'true')
            localStorage.setItem('infoChat', JSON.stringify(getInfo))
            this._videoChamada.putVideoChamada(getInfo).toPromise().then(
              () => {
                this.openChat = true
                this.chat.cpfMedicoConvidador = this.cpf
                this.chat.cpfConvidado = getInfo.destinatario
                this.chat.nomeConvidado = 'convidado'
                this.chat.nomeConvidador = this.userName
              }
            )
          }

          if (getInfo_contato) {
            getInfo_contato.status = 'chat-confirmado'
            if (this.destinatario) {
              console.log('passou destinatario')
              getInfo_contato.status = 'chat-confirmado-2'
            }
            localStorage.setItem('openChat', 'true')
            localStorage.setItem('infoChat', JSON.stringify(getInfo_contato))
            console.log('infoChat', getInfo_contato)
            this._videoChamada.putVideoChamada(getInfo_contato).toPromise().then(
              () => {
                if (this.destinatario) {
                  this.openChat = true
                  this.chat.cpfMedicoConvidador = getInfo_contato.destinatario
                  this.chat.cpfConvidado = getInfo_contato.remetente
                  this.chat.nomeConvidado = 'convidado'
                  this.chat.nomeConvidador = this.userName
                }
                this.openChat_contato = true
                this.chat_contato.cpfMedicoConvidador = getInfo_contato.remetente
                this.chat_contato.cpfConvidado = getInfo_contato.destinatario
                this.chat_contato.nomeConvidado = 'convidado'
                this.chat_contato.nomeConvidador = this.userName

              }
            )
          }
        }
      )
  }
  obsChat() {
    let chat = localStorage.getItem('openChat')
    let info = JSON.parse(localStorage.getItem('infoChat'))
    if (chat) {
      this.chat.cpfMedicoConvidador = this.cpf
      this.chat.cpfConvidado = info.destinatario
      this.chat.nomeConvidado = 'convidado'
      this.chat.nomeConvidador = this.userName
      console.log(this.chat)
      this.openChat = true
    }

    else {
      this.openChat = false

    }
  }
  obsChamada(contato?) {
    console.log('CHAMaDA@@@')
    this._videoChamada.banco().doc(this.id).snapshotChanges().subscribe(
      (r: any) => {
        try {
          const sair = r.payload.data().sair
          console.log('CHAMaDA@@@ TY', r.payload.data())
          if (sair) {
            this.close()
          }
        } catch{

        }
      }
    )
    if (contato) {
      this._videoChamada.banco().doc(this.id_contato).snapshotChanges().subscribe(
        (r: any) => {
          try {
            const sair = r.payload.data().sair
            console.log('CHAMaDA@@ contato > ', sair)
            if (sair) {
              this.close(true)
            }
          } catch{

          }
        }
      )
    }
  }
  start_obsChat() {
    // this.verificar_chat = setInterval(()=>{this.obsChat()},1000)
    // this.verificar_chat
  }
  close(contato?) {
    this.exit_2.emit(false)
    if (contato) {
      this._videoChamada.addContato(this.id, false)
      this.sair_2 = true
      return
    }
    this.exit.emit(false)
    this.sair = true
  }

}
