import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TabelaMenu } from '../tabela/model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CadastrarService {
  url: string = new environment().api 
  constructor(private http : HttpClient) { }

  listar(){
    //console.log('Lista ' + this.url+'MenuMaster/')
    return this.http.get(this.url+'MenuMaster/')
  }
  listarDocumento(){
    //console.log('Documento ' + this.url+'Documentos/')
    return this.http.get(this.url+'Documentos/')
  }

  listarTipoDocumento(){
    //console.log('TipoDocumento ' + this.url+'TipoDocumentos/')
    return this.http.get(this.url+'TipoDocumento/')
  }
  cadastrar(api,reg){
    //console.log('TipoDocumento ' + this.url+'TipoDocumentos/')
    console.log(this.url+api,reg)
    return this.http.post(this.url+api,reg)
  }
  
  listarClassDocumento(){
    //console.log('ClassDocumentos ' + this.url+'ClassDocumentos/')
    return this.http.get(this.url +'ClassDocumento/')
  }
  listaStatusDocumento(){
    //console.log('StatusDocumentos ' + this.url+'StatusDocumentos/')
    return this.http.get(this.url + 'Status/')
  }
  listaTipoAcao(){
    return this.http.get(this.url + 'TipoAcao')
  }
  listaTipoObjeto(){
    return this.http.get(this.url + 'TipoObjeto')
  }

  adicionar(dados : TabelaMenu){
    //console.log('Adicionar : ' + this.url+'MenuMaster/',dados)
    return this.http.post(this.url+'MenuMaster/',dados)
  }

  editar(dados : TabelaMenu, id : number){
    //console.log('Editar : ' + this.url + id, dados)
    return this.http.put(this.url+'MenuMaster/' + id, dados)
  }
  excluir(id : number){
    //console.log('Deletar : ' + this.url + id)
    return this.http.delete(this.url+'MenuMaster/' + id)

  }

}
