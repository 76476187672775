import { Component, OnInit } from '@angular/core';
import { AgendaForm, HorariosForm } from 'src/app/agenda/agenda/model';
import { MonitorClinicoService } from 'src/app/service/monitor-clinico.service';
import { AgendaService } from 'src/app/service/agenda.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
class Consultas {
  encerrada: number
  retorno: number
  solicitacoes: number
}
class Financeiro {
  CAD: number
  DP: number
  CF: number
}
class Prontuario {
  SDA: number
}
class AgendaHoje {
  agendadas: number
  confirmar: number
  confirmado: number
  canceladas: number
  situacao: any
  tempoMedico: any
  confirmadasPorcento: number
  confirmarPorcento: number
}
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
  ////backup
  verHorariosLivres
  reagendar
  dataSelecionada
  reservas
  abrirCobranca
  ////END
  monitorConsultas = new Consultas;
  monitorFinanceiro = new Financeiro;
  monitorProntuario = new Prontuario;
  monitorAgenda: AgendaHoje;
  monitorProxConsulta: AgendaForm;
  monitorHorariosLivres: AgendaForm;
  monitorHoraroLivresAmanha: AgendaForm;
  loadingMonitor: boolean;
  verSolicitacoes: boolean
  horarios: any[];
  sugerirHorario: boolean;
  reservaSelec: AgendaForm;
  constructor(
    private monitor: MonitorClinicoService,
    private serviceAgenda: AgendaService,
    private toast: ToastrService,
    private _info: MatSnackBar,
    private data: BuscarDataService
  ) { }

  ngOnInit() {
    this.monitorAgenda = new AgendaHoje;
    this.monitorConsultas = new Consultas;
    this.monitorFinanceiro = new Financeiro;
    this.monitorProntuario = new Prontuario;
    this.getMonitorClinico()
    setInterval(() => {
      if (localStorage.getItem('atualizarMonitor')) {
        this.getMonitorClinico()
      }
    }, 1000)
  }
  async  getMonitorClinico() {
    localStorage.removeItem('atualizarMonitor')
    let companyId = sessionStorage.getItem('companyId')

  }

  async cacheMonitorClinico() {
    this.monitorConsultas = JSON.parse(localStorage.getItem('monitorConsultas'))
    this.monitorFinanceiro = JSON.parse(localStorage.getItem('monitorFinanceiro'))
    this.monitorProntuario = JSON.parse(localStorage.getItem('monitorProntuario'))
    this.monitorProxConsulta = JSON.parse(localStorage.getItem('monitorProxConsulta'))
    this.monitorHorariosLivres = JSON.parse(localStorage.getItem('monitorHorariosLivres'))
    this.monitorHoraroLivresAmanha = JSON.parse(localStorage.getItem('monitorHoraroLivresAmanha'))
    this.monitorAgenda = JSON.parse(localStorage.getItem('monitorAgenda'))
    if (
      !this.monitorConsultas ||
      !this.monitorFinanceiro ||
      !this.monitorProntuario ||
      !this.monitorProxConsulta ||
      !this.monitorHorariosLivres ||
      !this.monitorAgenda ||
      !this.monitorHoraroLivresAmanha
    ) {
      this.loadingMonitor = true
      await this.getMonitorClinico()
      await localStorage.setItem('monitorAgenda', JSON.stringify(this.monitorAgenda))
      await localStorage.setItem('monitorConsultas', JSON.stringify(this.monitorConsultas))
      await localStorage.setItem('monitorFinanceiro', JSON.stringify(this.monitorFinanceiro))
      await localStorage.setItem('monitorProntuario', JSON.stringify(this.monitorProntuario))
      await localStorage.setItem('monitorProxConsulta', JSON.stringify(this.monitorProxConsulta))
      await localStorage.setItem('monitorHorariosLivres', JSON.stringify(this.monitorHorariosLivres))
      await localStorage.setItem('monitorHoraroLivresAmanha', JSON.stringify(this.monitorHoraroLivresAmanha))

    }


  }
  alterarAgenda(dados: AgendaForm, id, reserva?, ref?) {
    console.log(dados)
    let horario
    horario = this.horarios.filter(function (reg) {
      if (reserva) {
        console.log('reg.timeStart ' + reg.timeStart + ' --- ' + dados.start.substr(11, 5))
        return reg.timeStart == dados.start.substr(11, 5)
      }
      console.log('reg.timeStart ' + reg.timeStart + ' --- ' + dados.start.substr(0, 11))
      return reg.timeStart == dados.start.substr(0, 11)

    })

    if (!horario[0]) { this.toast.error('Algo deu errado, Horário não diponivel '); return }


    console.log('/*/ - ' + JSON.stringify(horario))


    try {
      horario[0].timeEnd = dados.end.substr(11, 5)
    }
    catch{
      horario[0].timeEnd = '00:00'
    }


    // let start = parseInt(horario[0].timeStart)
    // let end = parseInt(horario[0].timeEnd)

    // console.log(start + ' ' + end)
    // console.log(horario[0])
    // for (let i = 0; start < end; i++) {
    //   start++
    //   console.log('Start - ' + i)
    // }
    // horario[0].status = false
    // horario[0].timeEnd = dados.end.substr(0, 11)
    console.log(JSON.stringify(dados))


    JSON.stringify(dados)

    //Alterar dados do registro para enviar a API
    dados.start = reserva ? `${dados.start}` : `${dados.date}T${dados.start}`,
      dados.end = reserva ? `${dados.end}` : `${dados.date}T${dados.end}`,
      dados.confirmReserva = false
    //////

    /// Se o usuario selecionou SIM , alterar tipoAgenda para 'Reserva-1'
    if (ref) {
      dados.tipoAgenda = 'Reserva-1'
      // if(!this.sugerirHorario){
      //   dados.tipoAgenda = "Recusado"
      // }
    }
    if (ref == 'SolicNao') {
      this.sugerirHorario = true
      this.reservaSelec = dados
      //alert('Olha')
      return
    }
    if (ref == 'nao') {
      dados.tipoAgenda = 'Recusado'
    }

    /// END
    this.serviceAgenda.alterarAgenda(id, dados).subscribe(
      async () => {
        // this.buscarReservar()
        this.serviceAgenda.alterarHorarios(horario[0].id, horario[0]).toPromise()
          .then(
            () => {
              this.info()
              if (ref == 'nao') {
                return
              }
              if (ref) {
                this.toast.success('Solicitação encaminhada para o paciente!')
              }
            }
          ).catch(
            () => this.toast.error('Não foi possivel registrar horario')
          )
        if (ref) { }
      },
      error => { this.toast.error('Erro! não foi possivel alterar registro') }
    )
  }
  verificarHorarios() {
    this.serviceAgenda.buscarHorarios().toPromise()
      .then(
        (dados: Array<HorariosForm>) => {
          let companyId = sessionStorage.getItem('companyId')
          // if (!companyId) {
          //   this.toast.error('Clinica não encontrada')
          //   // this.erro = true
          //   return
          // }
          let filtro = dados.filter(function (reg) {
            // console.log(`Hora : ${reg.companyId} -- ${companyId} -- ${reg.block} -- ${reg.status}`)
            return reg.companyId == companyId && reg.block == false &&
              reg.status == true
          })
          filtro.sort(function (a, b) {
            return parseInt(a.timeStart.substr(0, 2)) - parseInt(b.timeStart.substr(0, 2))
          })
          console.log('SEU FILTRO : ' + filtro.length)
          if (filtro.length < 1) {
            // this.erroConfig = true
            // this.erroConfiMensagem = "Sua clínica não possui horários, para abertura de novos horários acesse a área de configuração"
            return
          }
          else {
            // this.erroConfig = false
          }
          this.horarios = filtro
        }
      )

  }
  info() {
    this._info.open('Salvo com sucesso', '', {
      duration: 2000
    })
  }

}
