import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DadosSaudePacienteService } from 'src/app/service/dados-saude-paciente/dados-saude-paciente.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { InternacaoPaciente } from '../model';
const sessao = new SessaoUser
@Component({
  selector: 'app-internacoes-hospitalar',
  templateUrl: './internacoes-hospitalar.component.html',
  styleUrls: ['./internacoes-hospitalar.component.scss']
})
export class InternacoesHospitalarComponent implements OnInit {
  @Input('cpf') cpf: string
  formulario: FormGroup
  loading: boolean = false
  viewCadastro: boolean = false
  dataSource = new MatTableDataSource()
  displayedColumns = ['hospital', 'motivo', 'periodo', 'entrada', 'saida']
  constructor(
    private formBuilder: FormBuilder,
    private _mensagem: MensagemService,
    private service: DadosSaudePacienteService) { }


  async ngOnInit() {
    await this.creatForm()
    this.getRegistro()

  }

  getRegistro() {
    this.service.getInternacao().toPromise()
      .then(res => {
        if (!res.success) return
        this.dataSource = new MatTableDataSource(res.result)
      })
  }
  openCadastro(register?: InternacaoPaciente) {
    if (register) this.formulario.patchValue(register)
    else this.formulario.reset()
    this.viewCadastro = true
  }
  creatForm() {
    this.formulario = this.formBuilder.group({
      id: new FormControl(''),
      hospital: new FormControl(''),
      motivo: new FormControl(''),
      periodo: new FormControl(''),
      entrada: new FormControl(''),
      saida: new FormControl(''),
      cpfPaciente: new FormControl(''),
      status: new FormControl(''),
      criadoPor: new FormControl(''),
      criadoEm: new FormControl('')
    })
  }
  excluirRegistro() {
    let register = this.formulario.getRawValue() as InternacaoPaciente
    if (!register.id) {
      this._mensagem.creat('Não é possível excluir este registro', { erro: true })
      return
    }
    register.status = 'SCA0002'
    this.service.putInternacao(register).toPromise()
      .then(() => {
        this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
        this.close()
      })
      .catch(error => {
        console.log('erro!', error)
        this._mensagem.creat('Não foi possível alterar registro', { erro: true })
      })
  }
  cancelarRegistro() {
    this.close()
  }
  fecharCadastro() { }

  salvar() {
    let register = this.formulario.getRawValue() as InternacaoPaciente

    if (!register.id) {
      delete register.id
      register.criadoPor = sessao.cpf
      register.cpfPaciente = this.cpf
      register.status = 'SCA0001'
      register.criadoEm = new Date().toISOString()
      console.log(register)
      this.service.postInternacao(register).toPromise()
        .then(() => {this.close()})
        .catch(error => {
          this._mensagem.creat('Não foi possível gravar registro', { erro: true })
          console.log('erro!', error)
        })

    } else {
      console.log(register)
      this.service.putInternacao(register).toPromise()
        .then(() => {
          this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
          this.close()
        })
        .catch(error => {
          console.log('erro!', error)
          this._mensagem.creat('Não foi possível alterar registro', { erro: true })
        })
    }

  }
  _control(control: string) {
    return this.formulario.get(control)
  }
  close() {
    this.viewCadastro = false
    this.getRegistro()
  }
}
