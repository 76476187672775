import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { stringify } from '@angular/compiler/src/util';
import { isEmpty } from 'rxjs/operators';
import { SessaoUser } from '../user.reg';
import { TokenDeAcessoModel } from '../admin-usuarios/admin-token/modalTokenDeAcesso';
import { UsuarioService } from './usuario.service';
import { FiltrarDadosService } from './filtrar-dados.service';
const sessao = new SessaoUser
@Injectable({
    providedIn: 'root'
})
export class TokenDeAcessoService {
    url = new environment().api
    api = "TokenDeAcesso/"

    constructor(private http: HttpClient, private _usuairo: UsuarioService, private _filter: FiltrarDadosService) {

    }

    async criarToken(codigoEmpresa, statusConexao, tipoHabilitacao, cpf) {
        let key = null
        try {
            await this.http.post(`${this.url}TokenDeAcesso/Inserir/${codigoEmpresa}/${statusConexao}/${tipoHabilitacao}/${cpf}/${sessao.cpf}`, cpf)
                .toPromise()
                .then((res: any) => {
                    key = res.key
                })
            return key
        } catch {
            return null
        }

    }

    async getEmpresas(cpf) {
        let empresa = []
        await this.http.get(this.url + this.api + `ConsultarEmpresas/${cpf}`).toPromise()
            .then((res: any) => {
                if (res.success) {
                    empresa = this._filter.removeDublic(res.result, 'empresa')
                }
            })

        return empresa
    }
    async getEmpresasToken(codigoEmpresa?) {
        let empresa = sessao.listEmpresas
        if (codigoEmpresa) {
            let registro = JSON.parse(empresa)
            let filter = registro.filter((value) => value.empresa == codigoEmpresa)
            if (filter.length > 0) {
                return filter
            } else {
                return []
            }
        }
        try {
            return JSON.parse(empresa)
        } catch {
            let get = sessionStorage.getItem('listEmpresa')
            return JSON.parse(get)
        }
    }
    async getToken(cpf, codigoEmpresa) {
        let result = null
        await this.http.get<any>(this.url + this.api + `FiltrarCpf/${cpf}`).toPromise()
            .then(res => {
                if (typeof res[0] == 'object') {
                    let verifc = res.filter(value => value.empresa == codigoEmpresa)[0]
                    if (verifc) {
                        result = verifc
                    }
                }
            })
        return result
    }
    async consultarEmpresa(codigoEmpresa) {
        let result = []
        await this.http.get<any>(this.url + this.api + 'JoinFiltrarEmpresa/' + codigoEmpresa).toPromise()
            .then(async res => {
                if (res.success) {
                    result = res.result
                }
                // let registros = res.filter(value => value.empresa == codigoEmpresa && value.assinatura == sessao.assinante)
                // for (let i = 0; i < registros.length; i++) {
                //     let reg = registros[i]
                //     let user = await this._usuairo.getRegistro(reg.cpf)

                //     if (user.id) result.push(user)
                // }
            })

        return result
    }


    async ConsultarNome(cpf) {
        let nome = ''
        await this.http.get('https://www.myclinics.com.br/api/api/CadastroUsuario/(filtrarCpf)/' + cpf).toPromise()
            .then((res) => nome = res[0].nomeCompleto)
        return nome;
    }
    async TraduzirStatus(codigoStatus) {
        let descricao = ''
        if (codigoStatus == null || codigoStatus == undefined) {


        }
        else {

            await this.http.get('https://www.myclinics.com.br/api/api/Status/ConsultarCodigoStatus/' + codigoStatus).toPromise()
                .then((res: string) => { descricao = res })
        }
        return descricao
    }

    Get() {

        return this.http.get(this.url + this.api)
    }

    Post(registro) {
        return this.http.post(this.url + this.api, registro)
    }
    Put(registro) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)
    }





}


