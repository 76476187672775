import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
@Component({
  selector: 'app-historico-saude-paciente',
  templateUrl: './historico-saude-paciente.component.html',
  styleUrls: ['./historico-saude-paciente.component.scss']
})
export class HistoricoSaudePacienteComponent implements OnInit {
  @Input() sessao
  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    // responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgb(230 230 230)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgb(72 202 229 / 55%)',
      borderColor: 'rgb(72 202 229)',
      pointBackgroundColor: 'rgb(72 202 229)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(230 230 230)'
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  constructor() { }

  ngOnInit() {
    this.getRegistro()
  }
  getRegistro() {
    switch (this.sessao) {
      case 'Peso':
      this.lineChartData = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Peso' },
      ]
        break;
      case 'Cintura':
        this.lineChartData = [
          { data: [30, 40, 45, 60, 55, 55, 40], label: 'Cintura' },
        ]
        break;
      case 'IMC':
        this.lineChartData = [
          { data: [12, 13, 14, 15, 16, 17, 18], label: 'IMC' },
        ]
        break;
      case 'Pressão arterial':
        this.lineChartData = [
          { data: [30, 33, 45, 40, 46, 35, 40], label: 'Pressão arterial' },
        ]
        break;
      case 'Temperatura':
        this.lineChartData = [
          { data: [65, 59, 80, 81, 56, 55, 40], label: 'Temperatura' },
        ]
        break;
      case 'Frequencia cardiaca':
        this.lineChartData = [
          { data: [10, 20, 35, 40, 36, 25, 50], label: 'Frequencia cardiaca' },
        ]
        break;
      case 'Frequencia respiratoria':
        this.lineChartData = [
          { data: [65, 59, 80, 81, 56, 55, 40], label: 'Cintura' },
        ]
        break;

      default:
        break;
    }
  }

}
