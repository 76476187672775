import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MensagensDoSistemaService } from '../service/mensagens-do-sistema.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MensagensDoSistema } from './modalsMensagemDoSistema';
import { MatSort, MatTableDataSource } from '@angular/material';

interface TipoMensagemDoSistema {
  value: string
  valueText: string
}

@Component({
  selector: 'app-mensagens-do-sistema',
  templateUrl: './mensagens-do-sistema.component.html',
  styleUrls: ['./mensagens-do-sistema.component.scss']
})
export class MensagensDoSistemaComponent implements OnInit {
  @ViewChild('templateEditar', { static: false }) templateEditar
  @ViewChild(MatSort, { static: false }) sort: MatSort
  displayedColumns = ['tipoMensagem', 'codigo', 'descricao', 'status', 'acao']
  dataSource
  TipoMensagemDoSistema: TipoMensagemDoSistema[] = [
    { value: '1', valueText: 'Alerta' },
    { value: '2', valueText: 'Erro' },
    { value: '3', valueText: 'Informativa' },
    { value: '4', valueText: 'Sistema' },
    { value: '5', valueText: 'Notificação' }
  ];

  modalRef: BsModalRef;
  idMensagemDoSistema: string;

  MensagensDoSistemaDados: Array<MensagensDoSistema>
  CadastroMensagensDoSistemaDados: MensagensDoSistema = new MensagensDoSistema
  dadosMensagensDoSistemaID: MensagensDoSistema
  variavelID: MensagensDoSistema

  constructor(
    private mensagem: ToastrService,
    private serviceMensagensDoSistema: MensagensDoSistemaService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.buscarMensagensDoSistema()
  }

  modalCadastrar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  modalEditar(templateEditar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateEditar);
  }

  async buscarMensagensDoSistema() {
    this.serviceMensagensDoSistema.getMensagensDoSistema().subscribe(
      (resultado: Array<MensagensDoSistema>) => {
        let filtro = resultado.filter(function (reg: MensagensDoSistema) {
          return reg.status === "Ativo"
        })
        this.dataSource = new MatTableDataSource(filtro)
        this.dataSource.sort = this.sort
        this.MensagensDoSistemaDados = filtro
      }), erro => {
        this.mensagem.warning("Impossível buscar as Mensagens do sistema no momento, tente novamente mais tarde.")
      }
  }

  BuscarMensagensDoSistemaID() {
    this.serviceMensagensDoSistema.getMensagensDoSistemaID(this.idMensagemDoSistema).subscribe(
      (sucesso => {
        this.dadosMensagensDoSistemaID = sucesso
        this.buscarMensagensDoSistema()
        this.modalEditar(this.templateEditar)
      }), (erro => {
        this.mensagem.warning("Impossível atualizar os dados ")
      }))
  }

  editarMensagensDoSistema() {
    this.serviceMensagensDoSistema.AlterarMensagensDoSistema(this.idMensagemDoSistema, this.dadosMensagensDoSistemaID).subscribe(
      (sucesso => {
        this.mensagem.success('Mensagem do sistema editada com sucesso!')
        this.buscarMensagensDoSistema()
        this.modalRef.hide()
      }),
      error => console.log("Deu erro: ", error)
    );
  }

  cadastrarMensagensDoSistema() {
    this.CadastroMensagensDoSistemaDados.status = "Ativo"
    this.serviceMensagensDoSistema.PostMensagensDoSistema(this.CadastroMensagensDoSistemaDados)
      .subscribe(
        (sucesso => {
          this.variavelID = sucesso
          this.mensagem.success('Mensagem cadastrada com sucesso!')
          this.buscarMensagensDoSistema()
          this.modalRef.hide()
        }), erro => {
          console.log("Deu erro" + erro)
        })
    this.CadastroMensagensDoSistemaDados = new MensagensDoSistema()
  }

  excluirMensagensDoSistema(dados: MensagensDoSistema) {
    if (confirm(`Deseja mesmo remover a Mensagem do Sistema ?`)) {
      dados.status = "Inativo"
      this.serviceMensagensDoSistema.AlterarMensagensDoSistema(dados.id, dados).subscribe(
        () => {
          this.buscarMensagensDoSistema();
          this.mensagem.success('Registro deletado com sucesso.')
        }
      )
    }
  }

}
