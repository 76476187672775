import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTab, MatTableDataSource } from '@angular/material';
import { PlanoContaContabeisModel } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { SaldoContaContabil, SIS_LEGTRIB } from 'src/app/service/plano-conta-contabeis/modal';
import { PlanoContaContabeisService } from 'src/app/service/plano-conta-contabeis/plano-conta-contabeis.service';
import { SaldoContaContabilService } from 'src/app/service/plano-conta-contabeis/saldo-conta-contabil.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';

const sessao = new SessaoUser
@Component({
  selector: 'app-analise-situacao-fiscal',
  templateUrl: './analise-situacao-fiscal.component.html',
  styleUrls: ['./analise-situacao-fiscal.component.scss']
})
export class AnaliseSituacaoFiscalComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort
  displayedColumns: string[] = ['nomeEmpresa', 'cnpj', 'regimeTributario']
  dataSource: any
  listSituacaoFiscal = {
    faturamento: {
      saldoPeriodo: 0,
      saldoAcumuladoExercicio: 0,
      mediaAnual: 0
    },
    folha: {
      totalPagamento: 0,
      totalDespesas: 0,
      totalImpostos: 0
    },
    situacaoFiscal: {
      limiteInferior: 0,
      faturamentoLiquido: 0,
      limiteSuperior: 0,
      aliquota: 0,
      deducao: 0,
      baseReferencia: '...',
      faixaFiscal: '...',
      tabelaFiscal: '...'
    }
  }
  indexMes: number;
  indexAno: number;
  mes: any;
  listEmpresa: any;
  despesas: PlanoContaContabeisModel[] = []
  listSaldos: SaldoContaContabil[] = [];
  selectEmpresa
  regimeTributario: string;
  baseSalario: number;
  InfobaseSalario = new SIS_LEGTRIB;
  listAliquota = []
  constructor(
    private _data: BuscarDataService,
    private token: TokenDeAcessoService,
    private service: SaldoContaContabilService,
    private _empresa: FichaGeralService,
    private _conteudo: ConteudoDeDadosService,
    private _contas: PlanoContaContabeisService,
  ) {
    this.selectEmpresa = sessao.codigoEmpresa
  }

  ngOnInit() {
    this.getEmpresa()
    this.indexMes = new Date().getMonth()
    this.indexAno = new Date().getFullYear()
    this.mes = this._data.getMeses(this.indexMes)
    this.getRegistro(this.selectEmpresa, this.indexMes)
  }


  async getRegistro(selectEmpresa: any, index: any) {
    this.listAliquota = []
    this.listSituacaoFiscal = {
      faturamento: {
        saldoPeriodo: 0,
        saldoAcumuladoExercicio: 0,
        mediaAnual: 0
      },
      folha: {
        totalPagamento: 0,
        totalDespesas: 0,
        totalImpostos: 0
      },
      situacaoFiscal: {
        limiteInferior: 0,
        faturamentoLiquido: 0,
        limiteSuperior: 0,
        aliquota: 0,
        deducao: 0,
        baseReferencia: '...',
        faixaFiscal: '...',
        tabelaFiscal: '...'
      }
    }
    let date = `${this.indexAno}${this.indexMes + 1}`
    console.log('OLHA DATA', `${this.indexAno}-${this.indexMes}`)

    let result = await this.service.get(selectEmpresa)

    this.listSaldos = result

    let faturamento = result.filter(value => value.contaContabil == '31010100001' || value.contaContabil == '31010100002')





    this.calcularSaldo(faturamento, 'faturamento')

    // console.log('result'result)
    this._empresa.getFichaGeralEmpresaID(selectEmpresa).toPromise()
      .then(async (empresa: Array<any>) => {
        let desc = await this._conteudo.get('REGTRIB', true, empresa[0].regimeTributario)
        let moeda = await this._conteudo.get('MOEDAS', true, empresa[0].moedaEmpresa)
        empresa[0].descRegime = desc[0]
        this.dataSource = new MatTableDataSource(empresa)
        this.regimeTributario = empresa[0].regimeTributario
      })

    await this.getPlanoContas(selectEmpresa, 'despesas')
    await this.getPlanoContas(selectEmpresa, 'folhaPagamento')
    await this.getPlanoContas(selectEmpresa, 'totalImpostos')
    await this.getSituacaoFiscal()
    this.baseSalario = (this.listSituacaoFiscal.folha.totalPagamento / this.listSituacaoFiscal.faturamento.mediaAnual) * 100
    if (this.baseSalario < 28) {
      let get = await this._contas.getLegTributarai('REGTRIB1')
      let filter = get.filter(value => value.tabelaFiscal == 'TABFISC2')
      filter.forEach(async value => {
        if (this.listSituacaoFiscal.faturamento.mediaAnual > value.limiteInferior &&
          this.listSituacaoFiscal.faturamento.mediaAnual < value.limiteSuperior) {
          let getFaixaFiscal = await this._conteudo.get('FAIFIS', true, value.faixaFiscal)
          let getTabelaFiscal = await this._conteudo.get('TABFISC', true, value.tabelaFiscal)
          let getRegime = await this._conteudo.get('REGTRIB', true, value.regimeTributario)
          value.faixaFiscal = getFaixaFiscal.toString()
          value.tabelaFiscal = getTabelaFiscal.toString()
          value.regimeTributario = getRegime.toString()
          this.InfobaseSalario = value
          this.listAliquota.push(value)
        }
      });
    } else {
      let get = await this._contas.getLegTributarai('REGTRIB1')
      let filter = get.filter(value => value.tabelaFiscal == 'TABFISC1')
      filter.forEach(async value => {
        if (this.listSituacaoFiscal.faturamento.mediaAnual > value.limiteInferior &&
          this.listSituacaoFiscal.faturamento.mediaAnual < value.limiteSuperior) {
          let getFaixaFiscal = await this._conteudo.get('FAIFIS', true, value.faixaFiscal)
          let getTabelaFiscal = await this._conteudo.get('TABFISC', true, value.tabelaFiscal)
          let getRegime = await this._conteudo.get('REGTRIB', true, value.regimeTributario)
          value.faixaFiscal = getFaixaFiscal.toString()
          value.tabelaFiscal = getTabelaFiscal.toString()
          value.regimeTributario = getRegime.toString()
          this.InfobaseSalario = value
          this.listAliquota.push(value)
        }
      });
    }
    this.listAliquota.sort((a, b) => {
      return b.aliquota - a.aliquota 
    })

  }
  async getPlanoContas(selectEmpresa, type) {

    let registros = []
    let get
    let contas

    switch (type) {

      case 'despesas':
        get = await this._contas.getContaContabilTipo(selectEmpresa, 'CCTIPO4')
        get.forEach(conta => {
          let get = this.listSaldos.filter(value => value.contaContabil == conta.codigoContaContabil)
          registros.push(...get)
        });
        this.calcularSaldo(registros, 'folhaDespesas')

        break;

      case 'folhaPagamento':
        contas = await this._contas.getContaContabil(selectEmpresa)
        get = contas.filter(value => value.balancoPatrimonialDois == 'BPNIV220')
        get.forEach(conta => {
          let get = this.listSaldos.filter(value => value.contaContabil == conta.codigoContaContabil)
          registros.push(...get)
        });
        this.calcularSaldo(registros, 'folhaPagamento')
        break;

      case 'totalImpostos':
        contas = await this._contas.getContaContabil(selectEmpresa)
        get = contas.filter(value => value.balancoPatrimonialTres == 'BPNIV37')
        get.forEach(conta => {
          let get = this.listSaldos.filter(value => value.contaContabil == conta.codigoContaContabil)
          registros.push(...get)
        });
        console.log('REGISTRO', registros)
        this.calcularSaldo(registros, 'totalImpostos')
        break;
      default:
        break;
    }

  }
  async getSituacaoFiscal() {

    let saldo = this.listSituacaoFiscal.faturamento.saldoPeriodo
    let totalDespesas = this.listSituacaoFiscal.folha.totalDespesas
    let faturamentoLiquido = saldo - totalDespesas
    this.listSituacaoFiscal.situacaoFiscal.faturamentoLiquido = faturamentoLiquido

    let getList = await this._contas.getLegTributarai(this.regimeTributario)
    console.log('getList', getList)
    getList.forEach(async item => {
      if (faturamentoLiquido > item.limiteInferior && faturamentoLiquido < item.limiteSuperior) {
        let getBaseReferencia = await this._conteudo.get('BASREF', true, item.baseReferencia)
        let getFaixaFiscal = await this._conteudo.get('FAIFIS', true, item.faixaFiscal)
        let getTabelaFiscal = await this._conteudo.get('TABFISC', true, item.tabelaFiscal)
        let getRegime = await this._conteudo.get('REGTRIB', true, item.regimeTributario)
        this.listSituacaoFiscal.situacaoFiscal.baseReferencia = getBaseReferencia.toString()
        this.listSituacaoFiscal.situacaoFiscal.faixaFiscal = getFaixaFiscal.toString()
        this.listSituacaoFiscal.situacaoFiscal.tabelaFiscal = getTabelaFiscal.toString()
        this.listSituacaoFiscal.situacaoFiscal.limiteSuperior = item.limiteSuperior
        this.listSituacaoFiscal.situacaoFiscal.limiteInferior = item.limiteInferior
        this.listSituacaoFiscal.situacaoFiscal.aliquota = item.aliquota
        this.listSituacaoFiscal.situacaoFiscal.deducao = item.deducao
        item.baseReferencia = getBaseReferencia.toString()
        item.faixaFiscal = getFaixaFiscal.toString()
        item.tabelaFiscal = getTabelaFiscal.toString()
        item.regimeTributario = getRegime.toString()
        this.listAliquota.push(item)
      }
    });
  }


  calcularSaldo(registro: SaldoContaContabil[], type: string) {
    console.log('ROODRIGO', registro)
    let listPeriodos = []
    let date = new Date(`${this.indexAno}-${this.indexMes > 0 ? this.indexMes + 1 : 1}-02`)
    let periodo = date.toISOString().slice(0, 7).replace('-', '')

    for (let i = -12; i < 0; i++) {
      try {
        let getDate = new Date(date.getFullYear(), date.getMonth() + (i)).toISOString().split("T")[0];
        let getPeriodo = getDate.slice(0, 7).replace('-', '')
        listPeriodos.push(getPeriodo)
      } catch { }
    }
    listPeriodos.push(periodo)
    if (registro.length < 0) return

    try {
      let getResult = []
      listPeriodos.forEach(date => {
        let get = registro.filter(value => value.periodoFiscal == date)
        getResult.push(...get)
      });
      console.log('calcular', periodo, registro.map(value => value.periodoFiscal))
      let resultadoAnual = getResult.map(value => value.saldoPeriodo).reduce((total, value) => total + value)
      let getPeriodoFiscal = registro.filter(value => value.periodoFiscal == periodo)
      let resultado = 0
      if (getPeriodoFiscal.length > 0) {
        resultado = getPeriodoFiscal.map(value => value.saldoPeriodo).reduce((total, value) => total + value)
      }
      console.log(`somar ${type}`, resultado, registro.filter(value => value.periodoFiscal == periodo))
      switch (type) {
        case 'faturamento':
          console.log('RESULTRG', getPeriodoFiscal)
          this.listSituacaoFiscal.faturamento.mediaAnual = resultadoAnual
          this.listSituacaoFiscal.faturamento.saldoPeriodo = resultado
          this.listSituacaoFiscal.faturamento.saldoAcumuladoExercicio = getPeriodoFiscal[0].saldoAcumuladoExercicio
          break;
        case 'folhaPagamento':
          this.listSituacaoFiscal.folha.totalPagamento = resultadoAnual
          break;
        case 'folhaDespesas':
          this.listSituacaoFiscal.folha.totalDespesas = resultado
          break;
        case 'totalImpostos':
          console.log('totalImpostos', registro, type)
          this.listSituacaoFiscal.folha.totalImpostos = resultado
          break;
      }

    } catch (error) { console.log('calcularSaldo falha', error) }
    console.log('VERIFIC', this.listSituacaoFiscal.faturamento)
  }


  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
    console.log('EMPRESAS', empresa)
  }
  async getRegistroEmpresa() {
    const codigoEmpresa = this.selectEmpresa

  }
  filterCalendario(execut, index?) {
    switch (execut) {
      case 'next':
        if (index == 11) { index = 0; this.indexAno += +1 }
        else index += + 1
        this.mes = this._data.getMeses(index)
        break;
      case 'return':
        if (index == 0) { index = 11; this.indexAno += -1 }
        else index += - 1
        this.mes = this._data.getMeses(index)
        break;
      case 'hoje':
        let mes = new Date().getMonth()
        this.indexAno = new Date().getFullYear()
        index = mes
        this.mes = this._data.getMeses(index)
        break;

      default:
        break;

    }

    this.indexMes = index
    this.getRegistro(this.selectEmpresa, index)
    console.log(execut, index)

  }

}
