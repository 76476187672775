import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { DicionarioPortal } from './modal';
import { PortalDicionarioService } from 'src/app/service/portal-dicionario.service';
import { ToastrService } from 'ngx-toastr';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];
@Component({
  selector: 'app-dicionario',
  templateUrl: './dicionario.component.html',
  styleUrls: ['./dicionario.component.scss']
})
export class DicionarioComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['detalhe', 'fotoLink', 'titulo', 'subtitulo', 'link', 'tipoConteudo',];
  viewCadastro: boolean;
  form = new DicionarioPortal
  loading: boolean;
  data: string
  listTipoConteudo: ConteudoDados[] = [];
  disabled: boolean;
  registros: DicionarioPortal[] = [];
  registrosBackup: DicionarioPortal[] = [];

  constructor(
    private service: PortalDicionarioService,
    private _mensagem: MensagemService,
    private _filter: FiltrarDadosService,
    private _data: BuscarDataService,
    private _conteudo: ConteudoDeDadosService) {
    this.data = `${this._data.hoje()}T${this._data.horaAgora()}`
  }

  async ngOnInit() {
    this.buscarRegistro()
    this.listTipoConteudo = await this._conteudo.get('TIPNOTIC')
  }
  async buscarRegistro() {
    this.loading = true
    let getRegistro = await this.service.get()
    this._filter.ordernar('criadoEm', getRegistro)

    getRegistro.forEach(async value => {
      let desc = (await this._conteudo.get('TIPNOTIC', true, value.tipoConteudo)).toString()
      value.descTipoConteudo = desc
    })
    this.registros = getRegistro
    this.registrosBackup = getRegistro
    this.dataSource = new MatTableDataSource(getRegistro)
    this.loading = false

  }
  openCadastro() {
    this.form = new DicionarioPortal
    this.viewCadastro = true
    this.disabled = false
  }
  activeTipoConteudo(tipo) {
    this.form.tipoConteudo = tipo
  }

  applyFilter(filterValue: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    let registro = this.registrosBackup.filter(v => v.titulo.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))
    this.registros = registro
  }
  filterTipo(tipo) {
    if (tipo == 'all') {
      this.registros = this.registrosBackup
      return
    }
    let registro = this.registrosBackup.filter(v => v.tipoConteudo == tipo)
    this.registros = registro
  }
  adicionarRegistro(dados: DicionarioPortal) {

    if (dados.id) {
      this.service.alterar(dados.id, dados).toPromise()
        .then(
          () => {
            this._mensagem.creat('Alterado com sucesso', { sucesso: true })
            this.buscarRegistro()
            this.viewCadastro = false
          }
        ).catch(() => {
          this._mensagem.creat('Não foi possivel alterar conteudo', { erro: true })

        })
      return
    }
    this.service.cadastrar(dados).toPromise()
      .then(
        (result) => {
          console.log(result)
          this.buscarRegistro()
          this.viewCadastro = false
          this._mensagem.creat('Cadastrado com sucesso', { sucesso: true })
        }
      )
      .catch(
        error => {
          console.log('erro', error)
          this._mensagem.creat('Algo deu errado', { erro: true })
        }
      )
  }
  excluirRegistro() {
    this.service.excluir(this.form.id).toPromise()
      .then(
        () => {
          this._mensagem.creat('Deletado com sucesso', { erro: true })
          this.buscarRegistro()
          this.viewCadastro = false
        }
      )
      .catch(
        () => this._mensagem.creat('Algo deu errado', { erro: true })
      )
  }
  async cancelarRegistro() {
    this.form = await this.service.reset(this.form.id)
    this.disabled = true
  }
  convertDate(criadoEm) {
    return this._filter.convertCriadoEm(criadoEm)
  }
  cancelar() {
    this.viewCadastro = false
    this.form = new DicionarioPortal
  }
}
