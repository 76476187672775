import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { LogDoSistemaService } from '../service/logs-do-sistema.service';
import { DadosLogsDoSistema } from './modelsLogsDoSistema';
import { MatTableDataSource, MatSort, MatPaginator, MatCheckboxChange } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-logs-sistema',
  templateUrl: './logs-sistema.component.html',
  styleUrls: ['./logs-sistema.component.scss']
})

export class LogsSistemaComponent implements OnInit {

  dataSources: MatTableDataSource<DadosLogsDoSistema>
  LogDoSistemaDados: Array<DadosLogsDoSistema>
  displayedColumns: string[] = ['DataHoraLocalTrabalho', 'CpfUsuario', 'ProcessoRaiz', 'menuId', 'NomeProcesso', 'CodMensagem', 'DescricaoMensagem'];
  filterCheckboxes: any;
  mensagensStatus = [{ checked: false, status: 'Erro' }, { checked: false, status: 'Alerta' }, { checked: false, status: 'Informativa' }, { checked: false, status: "Sistema" }]
  filterArr = [];
  TipoDeMensagem: boolean;
  dataSource

  constructor(
    private serviceLogDoSistemaService: LogDoSistemaService,
    private mensagem: ToastrService
  ) { }

  ngOnInit() {
    this.buscarLogDoSistemaDados()
  }

  buscarLogDoSistemaDados() {
    this.serviceLogDoSistemaService.getLogDoSistema().subscribe(
      (resultado: Array<DadosLogsDoSistema>) => {
        this.LogDoSistemaDados = resultado
        this.dataSource = new MatTableDataSource(this.LogDoSistemaDados);
      }), (erro => {
        console.log("Erro: " + erro)
      })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addFilter(change: MatCheckboxChange) {
    if (this.filterCheckboxes.value.some((a: any) => a === change.source.value)) {
      this.filterCheckboxes.next(this.filterCheckboxes.value.filter((a: any) => a !== change.source.value));
    } else {
      this.filterCheckboxes.next(this.filterCheckboxes.value.concat(change.source.value));
    }
  }

  updateFilter(appt) {
    if (appt.checked) {
      this.filterArr.push(appt.status);

      this.dataSource = this.LogDoSistemaDados.filter(a => {
        console.log("dados data source:", this.dataSource)
        return this.filterArr.length
          ? this.filterArr.indexOf(a.tipoMensagem) != -1
          : this.LogDoSistemaDados;
      });
    } else {
      let index = this.filterArr.indexOf(appt.status);
      this.filterArr.splice(index, 1);
      this.buscarLogDoSistemaDados()
    }

  }

  buscarPorDataLogSistema(dataIncial?, dataFinal?) {

    dataIncial = dataIncial.split("/").join("-")
    dataFinal = dataFinal.split("/").join("-")

    if (dataIncial != "" && dataFinal != "") {
      this.serviceLogDoSistemaService.getDataLocalTrabalhoFiltro(dataIncial, dataFinal).subscribe(
        (resultado: Array<DadosLogsDoSistema>) => {
          this.LogDoSistemaDados = resultado
          this.dataSource = new MatTableDataSource(this.LogDoSistemaDados);
        }), (erro => {
          this.mensagem.warning("Data inválida")
        })
    }
    else {
      this.mensagem.warning("Selecione uma data válida.")
    }
  }
}
