import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AutorizacaoAcessoService } from "../../service/autorizacao-acesso.service";
import { AtividadeCaracteristica, AutorizacaoDeAcesso, ConsultarObjeto, InfoObjeto } from "./model";
import { TokenDeAcessoService } from "../../service/token-de-acesso.service";
import { SessaoUser } from "../../user.reg";
import { MensagemService } from "../../service/mensagem.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { UsuarioService } from "../../service/usuario.service";
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';

const user = new SessaoUser();

@Component({
  selector: 'app-autorizacao-de-acesso',
  templateUrl: './autorizacao-de-acesso.component.html',
  styleUrls: ['./autorizacao-de-acesso.component.scss']
})

export class AutorizacaoDeAcessoComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns = ['acao', 'nomeEmpresa', 'id', 'descricao']
  listaAutorizacaoDeAcesso: AutorizacaoDeAcesso[];
  listaInfoObjeto: InfoObjeto[];
  atividades: AtividadeCaracteristica[];
  listaDeConsultaObjetos: ConsultarObjeto[] = [];
  listaAtividades = [];
  listaValoresDados = [];
  viewCadastro = false;
  alteracao = false;
  habilitarBtnAlterar = false;
  habilitarBtnSalvar = false;
  desativarCheckbox = false;
  desativarSelect = false;
  formulario: FormGroup;
  dataSource;
  codigoEmpresaSelectResult: number;
  codigoInfoObjetoSelectResult: number;
  atividadesFilter = [];
  valorDeDadosFilter = [];
  valorDeDadosSelecionados = [];
  listEmpresa = [];
  filter
  checked
  valorDado: any;
  listCode: any;
  listaAtividades1: any[];
  loadingInfo: boolean;
  selectEmpresa = user.codigoEmpresa
  btnExcluir: boolean;

  constructor(
    private form: FormBuilder,
    private autorizacaoAcessoService: AutorizacaoAcessoService,
    private tokenService: TokenDeAcessoService,
    private mensagemService: MensagemService,
    private usuarioService: UsuarioService,
    private _filtrar: FiltrarDadosService
  ) {
  }

  ngOnInit() {
    this.getEmpresa();
    this.criarFormulario();
    this.listarRegistros(user.codigoEmpresa);
    this.listarRegistrosInfoObjeto();
    this.listarAtividades();
    this.listarItensPerfis();
  }


  adicionarFichaDeAutorizacao() {
    this.viewCadastro = true;
    this.alteracao = false;
    this.habilitarBtnAlterar = false;
    this.habilitarBtnSalvar = true;
    this.desativarCheckbox = false;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.controls['status'].setValue('SDA0001');
    this.valorDeDadosFilter = []
    this.listaValoresDados = []
    this.listarAtividades();

    console.log("this.valorDeDadosFilter", this.valorDeDadosFilter)
    console.log("this.valorDeDadosFilter", this.valorDeDadosFilter)// vem como undefined e length:0
  }

  criarFormulario() {
    this.formulario = this.form.group({
      id: '',
      codigoAutorizacao: 0,
      codigoEmpresa: new FormControl(0, Validators.required),
      empresa: '',
      descricao: new FormControl('', Validators.required),
      infoObjeto: new FormControl('', Validators.required),
      valorDado: '',
      atividade: '',
      status: '',
      criadoEm: '',
      criadoPor: user.cpf,
    });
  }

  listarRegistros(codigoEmpresa: number) {
    this.autorizacaoAcessoService.listar().toPromise()
      .then(value => {
        value = value.filter(item => item.status == 'SDA0001')

        if (codigoEmpresa > 0) {
          this.listaAutorizacaoDeAcesso = value.filter(value => value.codigoEmpresa == codigoEmpresa)
          this.listaAutorizacaoDeAcesso.forEach(async item => {
            let empresa = await this.tokenService.getEmpresasToken(item.codigoEmpresa)
            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
              console.log(item.nomeEmpresa);
            }
          })
        } else {
          this.listaAutorizacaoDeAcesso = this._filtrar.filterTodos(this.listaAutorizacaoDeAcesso)
        }


        this.dataSource = new MatTableDataSource(this.listaAutorizacaoDeAcesso);
        this.dataSource.sort = this.sort;

        console.log(this.dataSource)
      })
  }

  listarRegistrosInfoObjeto() {
    this.autorizacaoAcessoService.listarInfoObjeto()
      .subscribe(dados => {
        this.listaInfoObjeto = dados;
      })
  }

  pegaDadosDoObjetosDeConsulta(valor) {
    if (this.listaDeConsultaObjetos != null) {
      this.valorDeDadosFilter = this.listaDeConsultaObjetos.filter(x => x.descricao.includes(valor));
    }
  }

  async listarObjetosDeConsulta(inserir?) {
    //api > ObjetoDeInformacao e ConsultarObjeto 
    this.listaValoresDados = []
    await this.autorizacaoAcessoService.listarCampoDeDadoInfoObjeto(this.codigoInfoObjetoSelectResult, this.codigoEmpresaSelectResult)
      .toPromise().then(dados => {
        this.listaDeConsultaObjetos = dados;

        if (inserir) {
          let getValores = this.formulario.get('valorDado').value
          let listValores = getValores.split(',')

          listValores.forEach(codigo => {
            this.listaDeConsultaObjetos.forEach(item => {
              if (item.codigo == codigo) {
                item.select = true
                let verifc = this.listaValoresDados.findIndex(value => value.codigo == codigo)
                if (verifc < 0) {
                  this.listaValoresDados.push(item)
                }
              }
            })
          });

          console.log('LISTVALORES', listValores)
        }

        console.log('teste', this.listaValoresDados)
        console.log("Lista de ConsultaObjetos:", this.listaDeConsultaObjetos);
        console.log(dados)
        try {
          if (this.listaDeConsultaObjetos.length < 1) {
            this.mensagemService.creat("Escolha outra opção", { erro: true })
            this.habilitarBtnSalvar = false
          } else {
            this.habilitarBtnSalvar = true
            this.mensagemService.clear()
          }
        }
        catch {
          if (!this.listaDeConsultaObjetos) {
            this.mensagemService.creat("Escolha outra opção", { erro: true })
            this.habilitarBtnSalvar = false;
          } else {
            this.mensagemService.clear()
            this.habilitarBtnSalvar = true
          }
        }
        this.filtrarCheckboxValorDado("");//limpa keyup pesquisar
      })
  }

  async getInfoObjetoSelect(e, value) {
    this.loadingInfo = true
    this.codigoInfoObjetoSelectResult = e;
    await this.listarObjetosDeConsulta();
    console.log('Codigo Info objeto Select: ', this.codigoInfoObjetoSelectResult);
    if (this.codigoInfoObjetoSelectResult < 1) {
      this.habilitarBtnAlterar = true;
    } else {
      this.habilitarBtnAlterar = false;
      this.habilitarBtnSalvar = true;
    }
    this.loadingInfo = false
  }

  getCodigoEmpresaSelect(e) {
    this.codigoEmpresaSelectResult = e;
    // this.listarObjetosDeConsulta()//traz a lista do dados disponiveis
    // this.valorDado = []
    // this.listaValoresDados = []
    console.log('Codigo Empresa Select: ', this.codigoEmpresaSelectResult);
  }

  // async listarAtividades() {
  //   this.autorizacaoAcessoService.listarCaracteristicas()
  //     .toPromise().then(dados => {
  //       this.atividades = dados;
  //       this.atividades.sort((a, b) => {
  //         return (a.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '') >
  //           b.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) ? 1 :
  //           (b.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '') >
  //             a.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) ? -1 : 0;
  //       });
  //       this.filtrarCheckbox("");
  //     })

  // }

  async listarAtividades() {
    this.autorizacaoAcessoService.listarCaracteristicas()
      .toPromise().then(dados => {
        this.atividades = dados;
        this.atividades.sort((a, b) => {
          return (a.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '') >
            b.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) ? 1 :
            (b.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '') >
              a.valor.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) ? -1 : 0;
        });
        this.filtrarCheckbox("");
      })
    console.log("listarAtividades", this.atividades)
  }

  async getEmpresa() {
    let empresa = JSON.parse(user.listEmpresas)
    this.listEmpresa = empresa;
    console.log('EMPRESAS', empresa)
  }

  async cancelarRegistro() {
    let registroFormulario = this.formulario.value;
    let filterId = this.listaAutorizacaoDeAcesso.filter(x => x.id == registroFormulario.id);
    await this.openCadastro(filterId[0]);
  }

  async openCadastro(registro?) {
    this.valorDeDadosFilter = []
    this.listaValoresDados = []
    localStorage.setItem('backup_registro', JSON.stringify(registro))

    console.log("Open Cadastro registro, formulario >>>>>>>>>>>>>>", registro)
    console.log("Open Cadastro valorDado, formulario >>>>>>>>>>>>>>", this.valorDado)

    this.formulario.patchValue(registro);
    this.valorDado = registro.valorDado
    this.formulario.disable();
    this.viewCadastro = true;
    this.alteracao = true;
    this.habilitarBtnSalvar = false;
    this.desativarSelect = false;
    this.desativarCheckbox = true;
    this.codigoInfoObjetoSelectResult = this.formulario.controls['infoObjeto'].value
    this.codigoEmpresaSelectResult = this.formulario.controls['codigoEmpresa'].value

    await this.listarObjetosDeConsulta(true);

    this.listarItensPerfis(registro.atividade)

    console.log("this.atividades", this.atividades)
    console.log("registro.atividade", registro.atividade)

  }

  listarItensPerfis(active?) {//do tipo de acesso que é a ConteudoDeDados/FiltrarCodigoCaracteristica/'
    this.autorizacaoAcessoService.listarCaracteristicas().toPromise()
      .then(dados => {
        this.atividades = dados;
        if (active) {
          let listValores = active.split(',')
          listValores.forEach(codigo => {
            this.atividades.forEach(item => {
              if (item.codigoReferencia == codigo) {
                item.select = true
                console.log('RODRIGOO ,', item.codigoReferencia, codigo)
              }
            })
          });
          console.log('LISTVALORES', listValores)
        }
        this.atividadesFilter = this.atividades
        console.log('listarItensPerfis', this.atividades);
      }).catch(error => {
        console.log('Error', error);
      })
  }

  async buscarUsuario(cpf, openCadastro?) {
    let valid = await this.formulario.value;
    if (valid || openCadastro) {
      let res = await this.usuarioService.getRegistro(cpf);
    } else {
      console.log('não passou', valid);
    }
  }

  async detalhes(registro) {
    this.alteracao = true;
    this.habilitarBtnAlterar = true;
    this.habilitarBtnSalvar = false;
  }

  //
  inserir() {
    if (this.formulario.invalid) {
      this.mensagemService.creat('*Preencha todos os campos obrigatorios', { erro: true })
      return
    }
    if (this.atividadesFilter.filter(value => value.select).length < 1) {
      this.mensagemService.creat('*Preencha pelo menos uma atividade', { erro: true })
      return
    }
    let registroFormulario = this.formulario.value;


    delete registroFormulario.empresa;
    delete registroFormulario.id;
    delete registroFormulario.codigoAutorizacao
    delete registroFormulario.criadoEm
    registroFormulario.criadoPor = user.cpf;
    registroFormulario.atividade = this.listaAtividades.map(valor => valor.codigoReferencia).toString();
    registroFormulario.valorDado = this.listaValoresDados.map(value => value.codigo).toString();
    // TODO => usar esse trecho de codigo para cadastrar
    console.log(registroFormulario)
    this.autorizacaoAcessoService.cadastrar(registroFormulario).subscribe(
      success => {
        this.mensagemService.creat('Dado salvo com sucesso', { sucesso: true });
        this.formulario.disable()
        this.alteracao = true;
        this.habilitarBtnSalvar = true;
        this.desativarSelect = false;
        this.desativarCheckbox = true;
        this.listarRegistros(this.selectEmpresa);
      },
      error => {
        this.mensagemService.creat('Não foi possivel gravar registro', { erro: true })
        console.log('Error > ', error)
      }
    );

    console.log('autorizacaoAcessoService', this.autorizacaoAcessoService);
  }

  alterar() {
    if (this.formulario.invalid) {
      this.mensagemService.creat('*Preencha todos os campos obrigatorios', { erro: true })
      return
    }
    if (this.atividadesFilter.filter(value => value.select).length < 1) {
      this.mensagemService.creat('*Preencha pelo menos uma atividade', { erro: true })
      return
    }
    this.desativarSelect = true;
    let registroFormulario = this.formulario.value;
    if (!registroFormulario.id) return
    delete registroFormulario.empresa;
    registroFormulario.atividade = this.atividadesFilter.filter(value => value.select).map(value => value.codigoReferencia).toString()
    registroFormulario.valorDado = this.listaValoresDados.map(value => value.codigo).toString();
    console.log('alter', registroFormulario)
    this.autorizacaoAcessoService.alterar(registroFormulario).subscribe(
      succss => {
        this.mensagemService.creat("Dado salvo com sucesso", { sucesso: true })
        this.habilitarBtnSalvar = false;
        this.desativarSelect = false;
        this.desativarCheckbox = true;
        this.btnExcluir = false
        this.formulario.disable()
        this.listarRegistros(this.selectEmpresa);

      },
      error => {
        this.mensagemService.creat("Escolha outra opção1", { erro: true })
        this.viewCadastro = false;
        console.log('Erro > ', error);
      }
    )

    console.log(registroFormulario);

  }

  async excluirRegistro() {
    let registroFormulario = this.formulario.value;
    if (!registroFormulario.id) return
    await this.autorizacaoAcessoService.desativar(registroFormulario, false);
    this.viewCadastro = false;
    this.listarRegistros(this.selectEmpresa);
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim();
  }

  filtrarCheckbox(value) {
    let filter = this.atividades.filter(x => x.valor.includes(value));
    this.atividadesFilter = filter;
    console.log('atividadesFilter', this.atividadesFilter);
  }


  //codigoReferencia: "AUTHATIV6"
  onChangeCheckboxAtividades(e, atividade) {
    // let codigoEmpresa = this.codigoEmpresaSelectResult;
    // let key = this.formulario.controls['id'].value
    this.listaAtividades = [];
    atividade.select = true
    if (e.checked) {
      this.listaAtividades.push(atividade);

      if (atividade.codigoReferencia == "AUTHATIV6") {
        this.atividadesFilter.forEach(x => {
          x.select = true;
        });
      }
    } else {
      this.atividadesFilter.forEach(x => {
        x.select = false;
      });
      this.listaAtividades.filter((value, index) => {
        if (value.id == atividade.id) {
          this.listaAtividades.splice(index, 1)
        }
      })
    }

  }

  //traz a lista ja pronta pro checkbox no change
  onChangeCheckboxValorDado(e, valorDado) {
    var desc = [];
    this.valorDado = []
    // this.listaValoresDados = []
    if (e.checked) {
      valorDado.select = true
      this.listaValoresDados.push(valorDado);
    } else {
      this.listaValoresDados.filter((value, index) => {
        if (value.codigo == valorDado.codigo) {
          this.listaValoresDados.splice(index, 1);
        }
      });
    }
    console.log('OnChange Checkbox e', e);
    console.log('OnChange Checkbox valorDado', valorDado);
    // pegando os valores dos checkbox selecionadoss1
  }

  removeCheckBox(e, valorDado) {
    console.log("clicou", this.listaValoresDados)
    this.listaValoresDados.filter((valor, index) => {
      if (valor.codigo == valorDado.codigo) {
        this.listaValoresDados.splice(index, 1);
        console.log("escondeu botao", this.listaValoresDados)
      }
    });
    valorDado.select = true
    this.valorDeDadosFilter.filter((valor) => {
      if (valor.codigo == valorDado.codigo) {
        valor.select = false
      }
    })

  }

  filtrarCheckboxValorDado(value) {
    if (this.listaDeConsultaObjetos != null) {
      this.valorDeDadosFilter = this.listaDeConsultaObjetos.filter(x => x.descricao.toLocaleLowerCase().includes(value.toLocaleLowerCase()));

    }
  }
  filtrarCheckboxValorDadoSelecionado(value) {
    if (this.listaDeConsultaObjetos != null) {
      this.valorDeDadosFilter = this.listaDeConsultaObjetos.filter(x => x.descricao.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      console.log("passando no filtrar de atividades", this.valorDeDadosFilter)
    }
  }
}
