import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PerfilAcessoUsuarioService } from "../../service/perfil-acesso-usuario.service";
import {
  FichaDeAutorizacaoDeAcesso,
  ItemPerfil,
  MenuMaster,
  PerfilDeAcessoDeUsuarioCAB,
  PerfilDeAcessoDeUsuarioITEM,
  Perfis,
  TipoPerfilDeAcesso
} from "./model";
import { SessaoUser } from "../../user.reg";
import { MensagemService } from "../../service/mensagem.service";
import { TokenDeAcessoService } from "../../service/token-de-acesso.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { unpromisify } from '@fullcalendar/core';
import { AutorizacaoAcessoService } from 'src/app/service/autorizacao-acesso.service';

const user = new SessaoUser();

@Component({
  selector: 'app-perfil-de-acesso-usuario',
  templateUrl: './perfil-de-acesso-usuario.component.html',
  styleUrls: ['./perfil-de-acesso-usuario.component.scss']
})
export class PerfilDeAcessoUsuarioComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns = ['acao', 'empresa', 'descricao', 'tipoPerfil']
  dataSource

  formulario: FormGroup;
  listEmpresas: any;
  viewCadastro: boolean;
  listTipoPerfil: ConteudoDados[] = [];
  listTipoAcesso: ConteudoDados[] = [];
  listMenu: MenuMaster[] = [];
  tableAcessos = []
  checkedMenu = []
  loadingListMenu: boolean;
  selectEmpresa = user.codigoEmpresa
  viewAutoricao: boolean;
  viewMenu: boolean;
  viewPerfil: boolean;
  listAutorizacao = []
  loadingLisAutorizacao: boolean;
  tableAutorizacao = []
  loadingLisPerfil: boolean;
  listPerfil = []
  tablePerfil = []
  loadingCadastro: boolean;
  registrosPerfilItem: PerfilDeAcessoDeUsuarioITEM[] = []
  alteracao: boolean;
  loading: boolean;
  viewTreeMenu: boolean;
  nameTipoAcesso: string;
  constructor(
    private form: FormBuilder,
    private service: PerfilAcessoUsuarioService,
    private _mensagem: MensagemService,
    private tokenService: TokenDeAcessoService,
    private _conteudo: ConteudoDeDadosService,
    private _autorizacao: AutorizacaoAcessoService
  ) {
  }

  async ngOnInit() {
    this.creatForm();
    await this.getConteudo();
    await this.getEmpresa();
    await this.getRegistro()

  }
  async getEmpresa() {
    let empresa = JSON.parse(user.listEmpresas)
    this.listEmpresas = empresa;
  }

  async getConteudo() {
    this.listTipoPerfil = await this._conteudo.get('PERFIL')
    this.listTipoAcesso = await this._conteudo.get('ITMPERFIL')
    this.listTipoAcesso = this.listTipoAcesso.filter(value => value.codigoDeNegocio)
    console.log('listTipoAcesso', this.listTipoAcesso)
  }

  async getMenuMaster(codigo) {
    this.viewTreeMenu = false
    if (codigo == 'ITMPERFIL4') {
      this.getAutorizacao()
      this.viewAutoricao = true
      this.viewMenu = false
      this.viewPerfil = false
      return
    }
    if (codigo == 'ITMPERFIL5') {
      this.getPerfil()
      this.viewPerfil = true
      this.viewMenu = false

      this.viewAutoricao = false
      return
    }
    this.viewAutoricao = false
    this.viewPerfil = false
    this.checkedMenu = []
    this.loadingListMenu = true
    this.listMenu = await this.service.getMenuMaster(codigo)
    this.listMenu.forEach(value => value.select = false)
    this.loadingListMenu = false
    if (codigo == 'ITMPERFIL2' || codigo == 'ITMPERFIL3') {
      this.viewTreeMenu = true
      this.viewMenu = false
    } else {
      this.viewMenu = true
      this.viewTreeMenu = false
    }
    if (codigo) {
      let getName = this.listTipoAcesso.filter(value => value.codigoReferencia == codigo)[0]
      this.nameTipoAcesso = getName.valor
    }
  }

  async getAutorizacao() {
    this.loadingLisAutorizacao = true
    let get = await this._autorizacao.getAutorizacao(this.selectEmpresa)
    this.listAutorizacao = get
    this.listAutorizacao.forEach(value => value.select = false)
    this.loadingLisAutorizacao = false
  }
  async getPerfil() {
    this.loadingLisPerfil = true
    let get = await this.service.listarPerfilCabecalho(this.selectEmpresa)
    this.listPerfil = get
    this.listPerfil.forEach(value => value.select = false)
    this.loadingLisPerfil = false
  }

  async getRegistro() {
    let get = await this.service.listarPerfilCabecalho(this.selectEmpresa)
    get.forEach(value => {
      let desc = this.listTipoPerfil.filter(v => v.codigoReferencia == value.tipoPerfil)
      if (desc.length > 0) {
        value.descPerfil = desc[0].valor
      }
    })
    this.dataSource = new MatTableDataSource(get)
  }
  async inserir() {
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      return
    }
    this.loading = true
    let registro: PerfilDeAcessoDeUsuarioCAB = this.formulario.value
    registro.status = 'SDA0001'
    delete registro.id
    delete registro.descPerfil


    Object.keys(registro).forEach(key => { if (!registro[key]) { delete registro[key] } });

    await this.service.cadastrarPerfilCabecalho(registro).toPromise()
      .then(async res => {
        const codigoPerfil = res[0].codigoPerfil
        this.getID(res[0].key)
        await this.inserirItems(codigoPerfil, registro)

        this._mensagem.creat('Dado cadastrado com sucesso', { sucesso: true })
        this.loading = false
      })
    this.formulario.disable()
    this.alteracao = true
    this.getRegistro()

  }
  async cancelarRegistro() {
    let get = await this.service.resetRegistro(this.formulario.get('id').value)
    if (get) {
      this.formulario.patchValue(get)
      this.openCadastro(get)
    } else {
      this._mensagem.creat('Erro ao realizar processo', { erro: true })
    }
  }
  async getID(id) {
    let get = await this.service.resetRegistro(id)
    if (get) {
      this.formulario.patchValue(get)
    }
  }
  async inserirItems(codigoPerfil, registro) {
    if (this.tableAcessos.length > 0) {
      for (let i = 0; i < this.tableAcessos.length; i++) {
        let dados = this.tableAcessos[i]
        for (let index = 0; index < dados.registros.length; index++) {
          let item = new PerfilDeAcessoDeUsuarioITEM
          let dado = dados.registros[index]
          item.codigoEmpresa = registro.codigoEmpresa
          item.tipoAcesso = dados.tipoAcesso
          item.valorDeAcesso = dado.menuID
          item.codigoPerfil = codigoPerfil
          item.nrItem = (i + 1).toString()
          let result = await this.service.cadastrarPerfilItem(item)
          if (result) {
            dado.result = true
          } else {
            dado.result = false
          }
        }
      }
    }
    if (this.tableAutorizacao.length > 0) {
      for (let i = 0; i < this.tableAutorizacao.length; i++) {
        let dados = this.tableAutorizacao[i]
        for (let index = 0; index < dados.registros.length; index++) {
          let item = new PerfilDeAcessoDeUsuarioITEM
          let dado = dados.registros[index]
          item.codigoEmpresa = registro.codigoEmpresa
          item.tipoAcesso = dados.tipoAcesso
          item.valorDeAcesso = dado.codigoAutorizacao.toString()
          item.codigoPerfil = codigoPerfil
          item.nrItem = (i + 1).toString()
          let result = await this.service.cadastrarPerfilItem(item)
          if (result) {
            dado.result = true
          } else {
            dado.result = false
          }
        }
      }
    }
    if (this.tablePerfil.length > 0) {
      for (let i = 0; i < this.tablePerfil.length; i++) {
        let dados = this.tablePerfil[i]
        for (let index = 0; index < dados.registros.length; index++) {
          let item = new PerfilDeAcessoDeUsuarioITEM
          let dado = dados.registros[index]
          item.codigoEmpresa = registro.codigoEmpresa
          item.tipoAcesso = dados.tipoAcesso
          item.valorDeAcesso = dado.codigoPerfil.toString()
          item.codigoPerfil = codigoPerfil
          item.nrItem = (i + 1).toString()
          let result = await this.service.cadastrarPerfilItem(item)
          if (result) {
            dado.result = true
          } else {
            dado.result = false
          }
        }
      }
    }
  }
  alterar() {
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      return
    }
    let registro: PerfilDeAcessoDeUsuarioCAB = this.formulario.value
    delete registro.descPerfil
    this.service.alterarCAB(registro).toPromise()
      .then(async res => {
        let cont = 0
        for (let i = 0; i < this.registrosPerfilItem.length; i++) {
          const item = this.registrosPerfilItem[i];
          item.status = "SDA0002"
          let result = this.service.alterarITEM(item)
          if (result) cont++
        }
        if (cont == this.registrosPerfilItem.length) {
          await this.inserirItems(registro.codigoPerfil, registro)
          this._mensagem.creat('Dado Alterado com sucesso', { sucesso: true })
          this.formulario.disable()
          this.getRegistro()
        } else {
          this._mensagem.creat('Não foi possivel alterar itens de perfil', { erro: true })
        }

      })
  }
  excluirRegistro() {
    let registro: PerfilDeAcessoDeUsuarioCAB = this.formulario.value
    registro.status = "SDA0002"
    this.service.alterarCAB(registro).toPromise()
      .then(res => {
        this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
        this.getRegistro()
        this.viewCadastro = false
      })
  }
  async openCadastro(registro: PerfilDeAcessoDeUsuarioCAB) {
    this.alteracao = true
    this.formulario.patchValue(registro)
    this.loadingCadastro = true
    this.tableAcessos = []
    this.tableAutorizacao = []
    this.tablePerfil = []
    this.registrosPerfilItem = await this.service.listarPerfilItem(registro.codigoEmpresa, registro.codigoPerfil)
    console.log('registro', this.registrosPerfilItem)


    await this.getAutorizacao()
    await this.getPerfil()
    await this.getMenuMaster('')

    for (let i = 0; i < this.registrosPerfilItem.length; i++) {
      const registro = this.registrosPerfilItem[i];
      if (registro.tipoAcesso == 'ITMPERFIL4') {
        let menusCheked = this.listAutorizacao.filter(value => value.codigoAutorizacao == registro.valorDeAcesso)
        let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == registro.tipoAcesso)[0]
        let verific = this.tableAutorizacao.findIndex(value => value.codigo == getConteudo.codigoReferencia)
        if (verific >= 0) {
          this.tableAutorizacao.filter(value => {
            if (value.codigo == getConteudo.codigoReferencia) {
              value.registros.push(...menusCheked)
            }
          })
        } else {
          let item = {
            id: this.tableAutorizacao.length,
            codigo: getConteudo.codigoReferencia,
            name: getConteudo.valor,
            tipoAcesso: registro.tipoAcesso,
            registros: menusCheked
          }
          this.tableAutorizacao.push(item)
        }
      }
      if (registro.tipoAcesso == 'ITMPERFIL5') {
        let menusCheked = this.listPerfil.filter(value => value.codigoPerfil == registro.valorDeAcesso)
        let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == registro.tipoAcesso)[0]
        let verific = this.tablePerfil.findIndex(value => value.codigo == getConteudo.codigoReferencia)

        if (verific >= 0) {
          this.tablePerfil.filter(value => {
            if (value.codigo == getConteudo.codigoReferencia) {
              value.registros.push(...menusCheked)
            }
          })
        } else {
          let item = {
            id: this.tablePerfil.length,
            codigo: getConteudo.codigoReferencia,
            name: getConteudo.valor,
            tipoAcesso: registro.tipoAcesso,
            registros: menusCheked
          }
          this.tablePerfil.push(item)
        }
      }
      if (registro.tipoAcesso == 'ITMPERFIL1' || registro.tipoAcesso == 'ITMPERFIL2' || registro.tipoAcesso == 'ITMPERFIL3') {
        let menusCheked = this.listMenu.filter(value => value.menuID == registro.valorDeAcesso)
        let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == registro.tipoAcesso)[0]
        let verific = this.tableAcessos.findIndex(value => value.tipoAcesso == registro.tipoAcesso)
        if (verific >= 0) {
          this.tableAcessos.filter(value => {
            if (value.codigo == getConteudo.codigoReferencia) {
              value.registros.push(...menusCheked)
            }
          })
        } else {
          let item = {
            id: this.tableAcessos.length,
            codigo: getConteudo.codigoReferencia,
            name: getConteudo.valor,
            tipoAcesso: registro.tipoAcesso,
            registros: menusCheked
          }
          this.tableAcessos.push(item)
        }
      }
    }

    this.listMenu = []
    this.listPerfil = []
    this.listAutorizacao = []
    this.viewMenu = false
    this.viewAutoricao = false
    this.viewPerfil = false
    this.formulario.disable()
    this.viewCadastro = true
    this.loadingCadastro = false
    await this.getMenuMaster(this.registrosPerfilItem[0].tipoAcesso)
  }
  addMenus(checked, value) {
    if (checked) value.select = true
    else value.select = false
  }

  insertMenus(menus?) {
    console.log('MENU!', menus)
    let acesso = this.formulario.get('tipoAcesso').value
    let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == acesso)[0]
    let menusCheked
    if (menus) {
      menusCheked = menus
    } else {
      menusCheked = this.listMenu.filter(value => value.select)
    }
    if (menusCheked.length < 1) return

    let verific = this.tableAcessos.findIndex(value => value.codigo == getConteudo.codigoReferencia)
    if (verific >= 0) {
      this.tableAcessos.filter((value, i) => {
        if (value.codigo == getConteudo.codigoReferencia) {
          value.registros = []
          value.registros.push(...menusCheked)
        }
      })
    } else {
      let item = {
        id: this.tableAcessos.length,
        codigo: getConteudo.codigoReferencia,
        name: getConteudo.valor,
        tipoAcesso: acesso,
        registros: menusCheked
      }
      this.tableAcessos.push(item)
    }

    console.log('tableAcesso', this.tableAcessos)
  }
  insertAutorizacao() {
    let acesso = this.formulario.get('tipoAcesso').value
    let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == acesso)[0]
    let menusCheked = this.listAutorizacao.filter(value => value.select)
    if (menusCheked.length < 1) return

    let verific = this.tableAutorizacao.findIndex(value => value.codigo == getConteudo.codigoReferencia)
    if (verific >= 0) {
      this.tableAutorizacao.filter(value => {
        if (value.codigo == getConteudo.codigoReferencia) {
          value.registros = []
          value.registros.push(...menusCheked)
        }
      })
    } else {
      let item = {
        id: this.tableAutorizacao.length,
        codigo: getConteudo.codigoReferencia,
        name: getConteudo.valor,
        tipoAcesso: acesso,
        registros: menusCheked
      }
      this.tableAutorizacao.push(item)
    }
  }
  insertPerfil() {
    let acesso = this.formulario.get('tipoAcesso').value
    let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == acesso)[0]
    let menusCheked = this.listPerfil.filter(value => value.select)
    if (menusCheked.length < 1) return
    let verific = this.tablePerfil.findIndex(value => value.codigo == getConteudo.codigoReferencia)
    if (verific >= 0) {
      this.tablePerfil.filter(value => {
        if (value.codigo == getConteudo.codigoReferencia) {
          value.registros = []
          value.registros.push(...menusCheked)
        }
      })
    } else {
      let item = {
        id: this.tablePerfil.length,
        codigo: getConteudo.codigoReferencia,
        name: getConteudo.valor,
        tipoAcesso: acesso,
        registros: menusCheked
      }
      this.tablePerfil.push(item)
    }
  }
  removeTable(type, item) {
    let index
    switch (type) {
      case 'acesso':
        index = this.tableAcessos.findIndex(value => value.id == item.id)
        if (index >= 0) delete this.tableAcessos[index]
        this.tableAcessos = this.tableAcessos.filter(value => value)
        break;
      case 'auth':
        index = this.tableAutorizacao.findIndex(value => value.id == item.id)
        if (index >= 0) delete this.tableAutorizacao[index]
        this.tableAutorizacao = this.tableAutorizacao.filter(value => value)
        break;
      case 'perfil':
        index = this.tablePerfil.findIndex(value => value.id == item.id)
        if (index >= 0) delete this.tablePerfil[index]
        this.tablePerfil = this.tablePerfil.filter(value => value)
        break;

      default:
        break;
    }
  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigoPerfil: 0,
      descricao: new FormControl('', Validators.required),
      tipoPerfil: new FormControl('', Validators.required),
      codigoEmpresa: new FormControl(0, Validators.required),
      criadoPor: '',
      criadoEm: '',
      status: '',
      tipoAcesso: new FormControl('', Validators.required),
    });
  }
  filtrar() {

  }
  async vericPerfil(type) {
    if (type == "PERFIL1") {
      let index = this.listTipoAcesso.findIndex((value, i) => value.codigoReferencia == "ITMPERFIL5")
      delete this.listTipoAcesso[index]
      this.listTipoAcesso = this.listTipoAcesso.filter(value => value)
    } else {
      this.listTipoAcesso = await this._conteudo.get('ITMPERFIL')
      this.listTipoAcesso = this.listTipoAcesso.filter(value => value.codigoDeNegocio)
    }
  }
  newRegistro() {
    this.listMenu = []
    this.listPerfil = []
    this.listAutorizacao = []
    this.tableAcessos = []
    this.tableAutorizacao = []
    this.tablePerfil = []
    this.alteracao = false
    this.formulario.reset()
    this.formulario.enable()
    this.viewCadastro = true
    this.viewPerfil = false
    this.viewMenu = false
    this.viewAutoricao = false
  }



}
