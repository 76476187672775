import { Component, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FichaGeralEmpresa } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatSort, Sort, ThemePalette } from '@angular/material';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { ClassificacaoDeDadosService } from '../service/classificacao-de-dados/classificacao-de-dados.service';
import { PlanoContaContabeisService } from '../service/plano-conta-contabeis/plano-conta-contabeis.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MensagemService } from '../service/mensagem.service';
import { SessaoUser } from '../user.reg';
import { FichaDeAssinanteService } from '../service/ficha-de-assinante/ficha-de-assinante.service';
import { PlanoContaContabeisModel } from './plano-contato-contabeis.model';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ConteudoDados, ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

const sessao = new SessaoUser

@Component({
  selector: 'app-plano-conta-contabeis',
  templateUrl: './plano-conta-contabeis.component.html',
  styleUrls: ['./plano-conta-contabeis.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanoContaContabeisComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'codigoContaContabil', 'descricao']
  listRegistro: PlanoContaContabeisModel[]
  formato_codigo = "0.0.00.000.0000"
  formulario: FormGroup
  formCopy: FormGroup
  viewCadastro = false
  viewCopia = false
  alteracao = false
  dataSource = new MatTableDataSource()
  loading: boolean;
  checked: boolean;
  botaoSalvar = false
  botaoCancelar = false
  viewConfirmDelete: boolean
  selectEmpresa = sessao.codigoEmpresa
  popUpFiltro = false

  listEmpresa = []
  listTipo = []
  listNatureza = []
  listSaldo = []
  listClassificacao = []
  listControleLivroCaixa = []
  listLancamentoLivroCaixa = []
  listBalancoNv0 = []
  listBalancoNv1 = []
  listBalancoNv2 = []
  listBalancoNv3 = []
  listDFCNv0 = []
  listDFCNv1 = []
  listDFCNv2 = []
  listDFCNv3 = []
  listDFCNv4 = []
  listDRENv0 = []
  listDRENv1 = []
  empresaSelec: any;
  listConteudo: ConteudoDados[] = [];
  valorDeDadosFilter = []
  desativarCheckbox = false

  valorDado: any
  listaValoresDados = []
  listControlePagamento = []
  listBP = []
  listDRE = []
  listDLPA = []
  listDMPL = []
  listOrcamento = []
  listCarneLeao = []
  listRecebimento = []
  selectFiltro = 0
  porcentagem: number;
  uploadSuccess: boolean;
  response: any;

  constructor(
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private conteudo: ConteudoDeDadosService,
    private service: PlanoContaContabeisService,
    private mensagem: MensagemService,
    private _filtro: FiltrarDadosService
  ) { }

  async ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getTipo()
    this.getNatureza()
    this.getSaldo()
    this.getClassificacao()
    this.getControleLivroCaixa()
    this.getLancamentoLivroCaixa()
    this.getBalancoNv0()
    this.getBalancoNv1()
    this.getBalancoNv2()
    this.getBalancoNv3()
    this.getDFCNv0()
    this.getDFCNv1()
    this.getDFCNv2()
    this.getDFCNv3()
    this.getDFCNv4()
    this.getDRENv0()
    this.getDRENv1()
    this.getControlePagamento()
    this.getBP()
    this.getDRE()
    this.getDLPA()
    this.getDMPL()
    this.getOrcamento()
    this.getCarneLeao()
    this.getRecebimento()
    this.getRegistro(this.selectEmpresa)
  }

  CreateCopyForm(){
    this.formCopy = this.form.group({
      deCodigoEmpresa: '',
      paraCodigoEmpresa: ''
    })
  }

  OpenCopyView()
  {
    this.viewCopia = true
    this.getEmpresa()

    this.CreateCopyForm()
  }


 CopiarRegistros()
  {
    let form  = this.formCopy.value;

 

   this.service.CopiarRegistros(form.deCodigoEmpresa, form.paraCodigoEmpresa).subscribe(
    event => {
     // console.log(event)
      
      if (event.type === HttpEventType.UploadProgress) {
        this.porcentagem = Math.round(100 * event.loaded / event.total);

      } else if (event instanceof HttpResponse) {
        this.uploadSuccess = true;
        setTimeout(() => {
          this.uploadSuccess = false
          this.porcentagem = 0
          this.fecharRegistro()
        }, 3000)

        this.mensagem.creat('Registros salvos com Sucesso',{sucesso:true})
        
       // console.log('Response :', event.body[0])
      }
    },
    error => {
      console.log('ERRO!', error)
    }
  );

  }


  
  createForm() {
    this.formulario = this.form.group({

      codigo: '',
      criadoPor: sessao.cpf,
      codigoContaContabil: '',
      controleLivroCaixa: '',
      controlePagamento: false,
      dreNivel0: '',
      dreNivel1: '',
      codigoEmpresa: 0,
      descricao: '',
      balancoPatrimonialZero: '',
      balancoPatrimonialUm: '',
      balancoPatrimonialDois: '',
      balancoPatrimonialTres: '',
      tipo: '',
      natureza: '',
      saldo: '',
      bp: new FormControl(false),
      dre: new FormControl(false),
      dlpa: new FormControl(false),
      dmpl: new FormControl(false),
      dfcNivelZero: '',
      dfcNivelUm: '',
      dfcNivelDois: '',
      dfcNivelTres: '',
      dfcNivelQuatro: '',
      classificacao: '',
      status: '',
      lancamentoLivroCaixa: '',
      ///
      timestamp: '',
      nomeEmpresa: '',
      orcamento: false,
      carneLeao: false,
      recebimento: false

    })
  }

  onChangeCheckboxValorDado(e, valorDado) {

    this.valorDado = []
    if (e.checked) {
      valorDado.select = true
      this.listaValoresDados.push(valorDado)
    } else {

      this.listaValoresDados.filter((value, index) => {
        if (value.codigoReferencia == valorDado.codigoReferencia) {
          this.listaValoresDados.splice(index, 1);
        }
      })
    }
    console.log("OnChangeCheck e", e);
    console.log("OnChangeCheck", valorDado)
  }

  removeCheckbox(e, valorDado) {

    console.log("clicou", this.listaValoresDados)
    this.listaValoresDados.filter((valor, index) => {
      if (valor.codigoReferencia == valorDado.codigoReferencia) {
        this.listaValoresDados.splice(index, 1)
        console.log('escondeu botao', this.listaValoresDados)
      }
    })
    valorDado.select = true
    this.valorDeDadosFilter.filter((valor) => {
      if (valor.codigoReferencia == valorDado.codigoReferencia) {
        valor.select = false
      }
    })
  }


  // async getItens() {
  //   let itens = [{
  //     controlePagamento: 'Controle de Pagamento',
  //     bp: 'BP',
  //     dre: 'DRE',
  //     dlpa: 'DLPA',
  //     dmpl: 'DMPL',
  //     orcamento: 'Orçamento',
  //     carneLeao: 'Carnê Leão',
  //     recebimento: 'Recebimento'
  //   }]
  //   this.listCheckbox = itens
  // }

  async getControlePagamento() {
    let controlePagamento = [
      { valor: 'Controle de Pagamento' }]
    this.listControlePagamento = controlePagamento
    this.valorDeDadosFilter = this.listBP
  }

  async getBP() {
    let bp = [{
      valor: 'BP'
    }]
    this.listBP = bp
    this.valorDeDadosFilter = this.listBP
  }

  async getDRE() {
    let dre = [{
      valor: 'DRE'
    }]
    this.listDRE = dre
  }

  async getDLPA() {
    let dlpa = [{
      valor: 'DLPA'
    }]
    this.listDLPA = dlpa
  }

  async getDMPL() {
    let dmpl = [{
      valor: 'DMPL'
    }]
    this.listDMPL = dmpl
  }

  async getOrcamento() {
    let orcamento = [{
      valor: 'Orçamento'
    }]
    this.listOrcamento = orcamento
  }

  async getCarneLeao() {
    let carne = [{
      valor: 'Carnê Leão'
    }]
    this.listCarneLeao = carne
  }

  async getRecebimento() {
    let recebimento = [{
      valor: 'Recebimento'
    }]
    this.listRecebimento = recebimento
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }
  async getConteudo(codigo) {
    this.listConteudo = await this.conteudo.get(codigo)
  }

  async getTipo() {
    let tipo = await this.conteudo.get('CCTIPO')
    this.listTipo = tipo

  }

  async getNatureza() {
    let natureza = await this.conteudo.get('CCNATU')
    this.listNatureza = natureza
  }

  async getSaldo() {
    let saldo = await this.conteudo.get('CCSALD')
    this.listSaldo = saldo
  }

  async getClassificacao() {
    let classificacao = await this.conteudo.get('CCCLAS')
    this.listClassificacao = classificacao
  }

  async getControleLivroCaixa() {
    let controleLivroCaixa = await this.conteudo.get('CONCAI')
    this.listControleLivroCaixa = controleLivroCaixa
  }

  async getLancamentoLivroCaixa() {
    let lancamentoLivroCaixa = await this.conteudo.get('LANCAI')
    this.listLancamentoLivroCaixa = lancamentoLivroCaixa
  }

  async getBalancoNv0() {
    let balancoNv0 = await this.conteudo.get('BPNIV0')
    this.listBalancoNv0 = balancoNv0
  }

  async getBalancoNv1() {
    let balancoNv1 = await this.conteudo.get('BPNIV1')
    this.listBalancoNv1 = balancoNv1
  }

  async getBalancoNv2() {
    let balancoNv2 = await this.conteudo.get('BPNIV2')
    this.listBalancoNv2 = balancoNv2
  }

  async getBalancoNv3() {
    let balancoNv3 = await this.conteudo.get('BPNIV3')
    this.listBalancoNv3 = balancoNv3
  }

  async getDFCNv0() {
    let dfcNv0 = await this.conteudo.get('FCNIV0')
    this.listDFCNv0 = dfcNv0
  }

  async getDFCNv1() {
    let dfcNv1 = await this.conteudo.get('FCNIV1')
    this.listDFCNv1 = dfcNv1
  }

  async getDFCNv2() {
    let dfcNv2 = await this.conteudo.get('FCNIV2')
    this.listDFCNv2 = dfcNv2
  }

  async getDFCNv3() {
    let dfcNv3 = await this.conteudo.get('FCNIV3')
    this.listDFCNv3 = dfcNv3
  }

  async getDFCNv4() {
    let dfcNv4 = await this.conteudo.get('FCNIV4')
    this.listDFCNv4 = dfcNv4
  }

  async getDRENv0() {
    let dreNv0 = await this.conteudo.get('DRNIV0')
    this.listDRENv0 = dreNv0
  }

  async getDRENv1() {
    let dreNv1 = await this.conteudo.get('DRNIV1')
    this.listDRENv1 = dreNv1
  }

  async exibirFiltro() {
    this.desativarCheckbox = false
    this.popUpFiltro = true
  }

  adicionarPlano() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  inserir() {
    let registro = this.formulario.value

    delete registro.codigo
    delete registro.timestamp
    delete registro.nomeEmpresa

    // torna os checkbuttons não-clicados como false
    if (registro.bp == null) registro.bp = false
    if (registro.controlePagamento == null) registro.controlePagamento = false
    if (registro.dlpa == null) registro.dlpa = false
    if (registro.dre == null) registro.dre = false
    if (registro.dmpl == null) registro.dmpl = false
    if (registro.orcamento == null) registro.orcamento = false
    if (registro.carneLeao == null) registro.carneLeao = false
    if (registro.recebimento == null) registro.recebimento = false

    if (registro.lancamentoLivroCaixa == null) registro.lancamentoLivroCaixa = ""
    if (registro.balancoPatrimonialTres == null) registro.balancoPatrimonialTres = ""
    if (registro.dfcNivelQuatro == null) registro.dfcNivelQuatro = ""
    if (registro.dreNivel0 == null) registro.dreNivel0 = ""
    if (registro.dreNivel1 == null) registro.dreNivel1 = ""

    registro.criadoPor = sessao.cpf
    // console.log('Cadastro', JSON.stringify(registro))

    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          console.log('sucesso', resposta[0].mensagem)
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
          this.alteracao = true
        }
      ).catch(
        error => {
          let erro = error.error
          console.log('erro', error)
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  async getRegistro(codigoEmpresa) {

    this.loading = true

    await this.service.listar(codigoEmpresa)
      .toPromise()
      .then(
        registro => {
          // console.log('Registro: ', registro)
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)

            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
            }

          })

          this.dataSource.sort = this.sort
          this.loading = false
          if (codigoEmpresa) {
            this.filtrarEmpresa()
          }
        }
      )
  }

  async openCadastro(registro) {
    // console.log('OPENCADASTRO', registro)
    this.formulario.patchValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  alterar() {
    let registro = this.formulario.value

    delete registro.timestamp
    delete registro.nomeEmpresa

    if (registro.bp == null) registro.bp = false
    if (registro.controlePagamento == null) registro.controlePagamento = false
    if (registro.dlpa == null) registro.dlpa = false
    if (registro.dre == null) registro.dre = false
    if (registro.dmpl == null) registro.dmpl = false
    if (registro.orcamento == null) registro.orcamento = false
    if (registro.carneLeao == null) registro.carneLeao = false
    if (registro.recebimento == null) registro.recebimento = false

    if (registro.lancamentoLivroCaixa == null) registro.lancamentoLivroCaixa = ""
    if (registro.balancoPatrimonialTres == null) registro.balancoPatrimonialTres = ""
    if (registro.dfcNivelQuatro == null) registro.dfcNivelQuatro = ""
    if (registro.dreNivel0 == null) registro.dreNivel0 = ""
    if (registro.dreNivel1 == null) registro.dreNivel1 = ""

    registro.criadoPor = sessao.cpf

    console.log('Alteração: ', registro)
    console.log("Codigo alteração: ", registro.codigo)
    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
          console.log('error!', error.error)
        }
      )
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.patchValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.codigo) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro(true)
    this.filtrar('')
  }

  async fecharRegistro() {
    this.viewCadastro = false
    this.viewCopia = false
    await this.getRegistro(this.selectEmpresa)
      .finally(() => this.filtrarEmpresa())
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }
    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }
}

