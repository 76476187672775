import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FichaLegislacao } from './FichaLegislacao.model';
import { MensagemService } from '../mensagem.service';
@Injectable({
  providedIn: 'root'
})

export class FichaLegislacaoService {

  url = `${new environment().api}FichaLegislacao`

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
  ) { }

  async criarFichaLegislacao(novaFichaLegislacao:Omit<FichaLegislacao, 'codigoItem' | 'timestamp' | 'id'>) {
    novaFichaLegislacao.status = 'SDO0017'
    console.log(novaFichaLegislacao)
    const documento = novaFichaLegislacao.arquivo;
    console.log(documento)
    const formData = new FormData();
    formData.append('arquivo',documento)
    console.log(formData)
    await this.http.post<{nomeArquivo:string, caminho:string}[]>(`${this.url}/UploadVideo`, formData, {observe:'body', reportProgress:true}).toPromise()
    .then(async ([res]) => {
      novaFichaLegislacao.nomeArquivo = res.nomeArquivo
      novaFichaLegislacao.caminho = res.caminho
      this.mensagem.creat(`Upload ${novaFichaLegislacao.nomeArquivo} realizado com sucesso`, {sucesso:true})
      
      await this.http.post(this.url, novaFichaLegislacao).toPromise()
      .then(() => this.mensagem.creat(`Ficha Legislação ${novaFichaLegislacao.nomeArquivo} criada com sucesso`,{sucesso:true}))
      .catch(err => this.mensagem.creat(`Erro ao criar a ficha legislação ${novaFichaLegislacao.nomeArquivo}: ${err}`,{erro:true}));
    })
  }

  async alterarFichaLegislacao(novaFichaLegislacao:Omit<FichaLegislacao, 'codEmpresa' | 'timestamp'>) {
    await this.http.post(`${this.url}/${novaFichaLegislacao.id}`, novaFichaLegislacao).toPromise()
    .then(() => this.mensagem.creat(`Ficha Legislação ${novaFichaLegislacao.nomeArquivo} criada com sucesso`,{sucesso:true}))
    .catch(err => this.mensagem.creat(`Erro ao criar a ficha legislação ${novaFichaLegislacao.nomeArquivo}: ${err}`,{erro:true}));
  }

  async listarFichasLegislacao():Promise<FichaLegislacao[]> {
    let fichasLegislacao:FichaLegislacao[]
    await this.http.get<FichaLegislacao[]>(this.url, { observe: 'body' }).toPromise()
    .then(res => fichasLegislacao = res)
    .catch(err => this.mensagem.creat(`Erro ao listar as todas fichas legislação : ${err}`, {erro: true}))
    return fichasLegislacao;
  }

  async apagarFichaLegislacao({id}:{id:string}){
    await this.http.delete(`${this.url}/${id}`, { observe: 'body' }).toPromise()
    .then(() => this.mensagem.creat(`Ficha legislação apagada com sucesso`, {sucesso: true}))
    .catch(err => this.mensagem.creat(`Erro ao apagar a ficha legislação ${id}: ${err}`, {erro: true}))
  }

  async listarFichasLegislacaoEmpresa({codEmpresa}:{codEmpresa:number}):Promise<FichaLegislacao[]> {
    let fichasLegislacao:FichaLegislacao[]
    await this.http.get<FichaLegislacao[]>(`${this.url}/FiltrarCodigoEmpresa/${codEmpresa}`, { observe: 'body' }).toPromise()
    .then(res => fichasLegislacao = res)
    .catch(err => this.mensagem.creat(`Erro ao listar as fichas legislação da empresa ${codEmpresa}: ${err}`, {erro: true}))
    return fichasLegislacao;
  }
}
