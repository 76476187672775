import { Component, OnInit, Inject, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ContatosService } from '../service/contatos/contatos.service';
import { HistoricoDeConversasService } from '../service/historico-de-conversas/historico-de-conversas.service';
import { FichaConexoesProfissionaisService } from '../service/ficha-conexoes-profissionais/ficha-conexoes-profissionais.service';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { Contato } from '../service/contatos/contato.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import { CadastroUsuarioService } from '../service/cadastro-usuario/cadastro-usuario.service';
import { DiarioDeAcessoService } from '../service/diario-de-acesso/diario-de-acesso.service';
import { usuario } from '../service/cadastro-usuario/usuario.model';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { SessaoUser } from '../user.reg';
import { UsuarioService } from '../service/usuario.service';
import { cadastroUsuario } from '../admin-usuarios/model';
import { MensagemService } from '../service/mensagem.service';
import { ConteudoDados, ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { Router } from '@angular/router';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { PersonalizacaoService, SIS_PERSONALIZACAO } from '../service/personalizacao.service';
import { FotoPerfilService } from '../service/foto-perfil.service';
import { SIS_CONECTPES } from '../service/ficha-conexoes-profissionais/ficha-conexao-profissional.model';

const sessao = new SessaoUser
@Component({
  selector: 'app-minhas-conexoes',
  templateUrl: './minhas-conexoes.component.html',
  styleUrls: ['./minhas-conexoes.component.scss']
})
export class MinhasConexoesComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['status', 'codigoEmpresa', 'fotoPerfil', 'nome', 'email', 'cpf', 'timestamp', 'detalhe'];
  displayedColumnsProfissional: string[] = ['detalhe', 'codigoEmpresa', 'fotoPerfil', 'nome', 'especialidade', 'crm', 'situacaoRegistro', 'cpf', 'timestamp', 'status'];
  dataSource = new MatTableDataSource()
  dataSourceConvites = new MatTableDataSource()
  dataSourceProfissional = new MatTableDataSource()
  qtdNovosConvites = 0;
  qtdNovasConversas = 0;
  qtdNovasConexoes = 0;
  conversas: { contato: Contato, novasMensagens: 0 }[] = [];
  contatos: SIS_CONECTPES[] | any = [];
  contatosBackup: SIS_CONECTPES[] | any = [];
  novaMensagem: Observable<any>;
  empresas: Object[] = [];
  solicitacoesDeEmpresas: Object[] = [];
  solicitacoesDeContato: Contato[];
  selecionado = "Conversas";
  cpfUsuario = sessao.cpf
  contatoSelecionado: Contato;
  listEmpresas = []
  formConvite: FormGroup
  registroUsuario = new cadastroUsuario;
  menu = 'listConexao'
  noUsuario: boolean;
  coviteEnviado: boolean;
  viewConvite: boolean;
  listMinhasConexoes: any[];
  time
  viewCadastro: boolean;
  checkedConvite: boolean;
  formEmail: FormGroup;
  invalidEmail: boolean;
  noUsuario_message: any;
  listFormComunic = [];
  loadingFormComunic: boolean;
  viewEdit: boolean;
  loading: boolean;
  blockEnvio: boolean;
  listTipConexao: ConteudoDados[] = [];
  loadingConexaoEmpresa: boolean;
  viewCadastroFuncionario: boolean;
  viewAtribuicao: boolean;
  infoAtribuicao: any;
  loadingConversas: boolean;
  loadingConvites: boolean;
  loadingContato: boolean;
  dataSourceProfissionalAll = []

  constructor(
    private serviceConexoes: FichaConexoesProfissionaisService,
    private serviceEmpresa: FichaGeralService,
    private serviceHistoricoConversas: HistoricoDeConversasService,
    public dialog: MatDialog,
    private _tokken: TokenDeAcessoService,
    private _usuario: UsuarioService,
    private _conteudo: ConteudoDeDadosService,
    private _mensagem: MensagemService,
    private _filter: FiltrarDadosService,
    private _person: PersonalizacaoService,
    private _foto: FotoPerfilService,
    private validBR: ValidateBrService,
    private form: FormBuilder,
    private router: Router,
  ) { }



  async ngOnInit() {

    this.getRegistro()


    // .then(() => this.serviceHistoricoConversas.ObservarContatos(this.contatos))
    // .then(notificarMensagens => {
    //   this.listarConversas();
    //   notificarMensagens.subscribe(async () => {
    //     this.notificarQtdMensagens();
    //   })
    // });
    // await this.listarConexoesProfissionais();
    this.getEmpresas()
    this.creatForm()
    this.getConteudo()

    // this.time = setInterval(() => { this.getRegistro() }, 3000)
  }

  ngOnDestroy() {
    clearInterval(this.time)
  }

  async getRegistro() {
    try {

      this.loadingConversas = true
      this.loadingConexaoEmpresa = true
      this.loadingContato = true
      let getRegistro = await this.serviceConexoes.getConexoes()

      let getProfissional = getRegistro.filter(v => v.tipoConexao != 'TIPCON6').filter(value =>
        value.status == 'SDO0100' ||
        value.status == 'SDO0102' ||
        value.status == 'SDO0136'
      )
      getProfissional = getProfissional.filter(value => value.cpfPessoa == sessao.cpf || value.criadoPor == sessao.cpf)

      //Conexões da empresa
      this.dataSourceProfissional = new MatTableDataSource(getProfissional)
      this.dataSourceProfissionalAll = getProfissional

      getProfissional.forEach(value => {
        this.serviceEmpresa.getFichaGeralEmpresaID(value.codigoEmpresa).toPromise()
          .then(res => {
            if (typeof res[0] == 'object') {
              let registro = res[0]
              value.cnpj = registro.cnpj
            }
          })
      })

      this.loadingConexaoEmpresa = false

      getProfissional.forEach(async value => {
        await this._filter.inserirFotoPerfilEach(value.criadoPor == sessao.cpf ? 'cpfPessoa' : 'criadoPor', value)
      });
      console.log('getProfissional', getProfissional)

      this.listarContatos(getRegistro)
      this.loading = true



      console.log('registro', getRegistro)

      let quemConvidou = getRegistro
        .filter(value => value.criadoPor == sessao.cpf)
        .filter(value => {
          return value.status == 'SDO0099' ||
            value.status == 'SDO0101' ||
            value.status == 'SDO0137' ||
            value.status == 'SDO0138' ||
            value.status == 'SDO0139'
        }

        )
      if (quemConvidou.length > 0) {
        //Quem você convidou 
        this.dataSource = new MatTableDataSource(quemConvidou)
        console.log('*************************** END CONEXAO')
        quemConvidou.forEach(async value => {
          await this._filter.inserirFotoPerfilEach('cpfPessoa', value)
        });
      }
      this.loading = false

      this.loadingConvites = true
      let getConvites = getRegistro.filter(value => value.cpfPessoa == sessao.cpf)
      //Quem convidou você
      this.dataSourceConvites = new MatTableDataSource(getConvites)
      this.loadingConvites = false

      getConvites.forEach(async value => {
        await this._filter.inserirFotoPerfilEach(value.criadoPor == sessao.cpf ? 'cpfPessoa' : 'criadoPor', value)
      });

      this.listMinhasConexoes = getRegistro.filter(value => {
        return value.status == 'SDO0100' || value.status == "SDO0102"
      }).filter(value => value.cpf == sessao.cpf)

      let registros = [...this.listMinhasConexoes, ...getConvites, ...quemConvidou, ...getProfissional]
      getConvites.forEach(async value => {
        // if (value.criadoPor == sessao.cpf) {
        //   value.cpfFoto = value.cpfPessoa

        // } else {
        //   value.cpfFoto = value.criadoPor
        // }
        if (value.cpfPessoa == sessao.cpf) {
          let get = await this._usuario.getRegistro(value.criadoPor)
          value.email = get.email
        }
      })

      registros.forEach(value => value.timestamp = this._filter.convertCriadoEm(value.criadoEm))
      registros.forEach(value => value.cpfActive = value.criadoPor != sessao.cpf ? value.criadoPor : value.cpfPessoa)
      console.log('registros', registros)

      this._filter.inserirNomeEmpresaRequest(registros)

    } catch (error) {
      console.log('error get', error)
    }


  }

  async getEmpresas() {
    this.listEmpresas = await this._tokken.getEmpresas(sessao.cpf)
  }
  async getUsuario() {
    if (!this.formConvite.get('empresa').value) {
      this.noUsuario = true
      this.noUsuario_message = "Informe uma empresa"
      this.formConvite.get('cpf').setValue('')
      return
    }
    this.blockEnvio = false
    this.noUsuario = false

    this.registroUsuario = new cadastroUsuario;

    if (this.formConvite.get('cpf').valid) {
      let verifConvite = await this.serviceConexoes.verificConvite(this.formConvite.get('cpf').value, this.formConvite.get('empresa').value)
      console.log('verifConvite', verifConvite)



      let result = await this._usuario.getRegistro(this.formConvite.get('cpf').value)
      if (result.id) {
        if (!verifConvite) {
          this._mensagem.creat(`Existe convite pendente para  ${result.nomeCompleto}, não é possivel convidar novamente`, { aviso: true })
          this.noUsuario = true
          this.noUsuario_message = `Existe convite pendente para  ${result.nomeCompleto},`
          this.blockEnvio = true
          return
        }
        let status = result.status
        if (status == 'SDO0060' || status == 'SDO0142') {
          this.registroUsuario = result
        } else {
          this.noUsuario = true
        }
        if (status == 'SDO0042') {
          this.noUsuario_message = "Este CPF esta desabilitado no MyClinics,Não é possivel estabelecer conexão"
        }
        if (status == 'SDO0066') {
          this.noUsuario_message = "Este CPF ainda não confirmou sua inscrição no MyClinics, mas pode ser convidado."
          this.registroUsuario = result
        }
        this.viewCadastro = false
      } else {
        console.log('passou')
        this.noUsuario = true
        this.viewCadastro = true
      }
    }

  }
  async getConteudo() {
    this.listFormComunic = await this._conteudo.get('FORMACOM')
    this.listTipConexao = await this._conteudo.get('TPCOLAB')
    this.listTipConexao = this.listTipConexao.filter(value => value.codigoReferencia != 'TPCOLAB3')
    this.listFormComunic.forEach(value => value.select = false)
  }
  async aceitarConvite(aceitar, registro) {
    let result
    if (aceitar) {
      registro.status = 'SDO0100'
      result = await this.serviceConexoes.alterarConexaoProfissional(registro)

      await this._tokken.criarToken(registro.codigoEmpresa, 'SDO0100', 'TPHABI3', registro.cpfPessoa)

    } else {
      registro.status = 'SDO0101'
      result = await this.serviceConexoes.alterarConexaoProfissional(registro)
    }

    if (result) {
      await this.serviceConexoes.criarContato(registro)
      this._mensagem.creat(aceitar ? 'Convite aceito com sucesso' : 'Convite rejeitado com sucesso', { sucesso: true })
    } else {
      this._mensagem.creat('Erro ao realizar procedimento', { erro: true })
    }
    this.getRegistro()
    console.log(registro)
  }
  async desconectar(registro) {
    let result
    result.status = "SDO0102"
    result = await this.serviceConexoes.alterarConexaoProfissional(registro)
    if (result) {
      this._mensagem.creat('Profissional desconectado com sucesso', { sucesso: true })
    } else {
      this._mensagem.creat('Erro ao realizar procedimento', { erro: true })
    }
    this.getRegistro()
    console.log(registro)
  }
  async notificarQtdMensagens() {
    this.qtdNovasConversas = await this.serviceHistoricoConversas.filtrarNovasMensagens(this.contatos);
  }

  async inserirFormaComunic() {
    this.loadingFormComunic = true
    let registro: ConteudoDados[] = this.listFormComunic.filter(value => value.select)
    let item = new SIS_PERSONALIZACAO
    item.status = 'SDA0001'
    item.aplicacao = 'CN40'
    item.cpf = sessao.cpf
    item.criadoPor = sessao.cpf
    item.valorParametro = registro.map(value => value.codigoReferencia).toString()
    item.descricao = "Formas de comunicação"
    let response = await this._person.cadastrar(item)
    if (response) {
      this._mensagem.creat('Salvo com sucesso', { sucesso: true })
    } else {
      this._mensagem.creat('Algo deu errado', { erro: true })
    }
    setTimeout(() => this.loadingFormComunic = false, 1500)
    console.log('response', response)
  }
  async listarConexoesProfissionais() {
    this.empresas = [];
    this.solicitacoesDeEmpresas = [];
    const conexoes = await this.serviceConexoes.conexoesDoProfissional(this.cpfUsuario);
    for (let i = 0; i < conexoes.length; i++) {
      const empresa = await this.serviceEmpresa.buscarEmpresa(conexoes[i].codigoEmpresa)
      if (conexoes[i].status === 'SDO0100') {
        this.empresas.push({ empresa, conexao: conexoes[i] });
      } else if (conexoes[i].status === 'SDO0099') {
        this.solicitacoesDeEmpresas.push({ empresa, conexao: conexoes[i] })
      }
    }
  }

  async listarContatos(contatos) {
    this.contatos = contatos.filter(contato => contato.status === 'SDO0136' || contato.status == 'SDO0036' || contato.status == "SDO0100")
    this.contatosBackup = contatos.filter(contato => contato.status === 'SDO0136' || contato.status == 'SDO0036' || contato.status == "SDO0100")
    this.solicitacoesDeContato = []
    this.qtdNovosConvites = this.solicitacoesDeContato.length;
    console.log('CONTATOS', contatos, this.contatos)
    this.loadingContato = false
  }



  abrirConversa(contato: Contato) {
    this.contatoSelecionado = contato;
  }

  async enviarConvite(cadastro?) {
    if (!this.registroUsuario.cpf && !cadastro) {
      this.viewCadastro = true
      return
    }
    if (sessao.cpf == this.registroUsuario.cpf) {
      this._mensagem.creat(`Não é possivel convidar a si mesmo`, { aviso: true })
      this.noUsuario = true
      this.noUsuario_message = `Não é possivel convidar a si mesmo`
      return
    } else {
      this.viewCadastro = false
    }
    if (this.registroUsuario.email) this.formEmail.get('email').setValue(this.registroUsuario.email)
    if (this.registroUsuario.nomeCompleto) this.formEmail.get('nomeCompleto').setValue(this.registroUsuario.nomeCompleto)
    if (this.formEmail.invalid && this.viewCadastro) {
      this.invalidEmail = true
      return
    } else {
      this.invalidEmail = false
    }
    let empresa = this.formConvite.get('empresa').value
    let cpf = this.formConvite.get('cpf').value
    console.log(this.formConvite.value)

    let result = this.serviceConexoes.enviarConvite('TIPCON4', this.formConvite.get('tipoColaborador').value, cpf, empresa, cadastro, this.registroUsuario)
    if (result) {
      this._mensagem.creat('Convite enviado com sucesso', { sucesso: true })
      this.coviteEnviado = true
    } else {
      this._mensagem.creat('Não foi possivel enviar convite', { sucesso: true })
    }
  }
  async removeConvite(registro: SIS_CONECTPES) {
    this.loading = true
    registro.status = 'SDO0139'
    let response = await this.serviceConexoes.alterarConexaoProfissional(registro)
    if (response) {
      this._mensagem.creat('Convite cancelado com sucesso', { sucesso: true })
      await this.getRegistro()
    } else {
      this._mensagem.creat('Não foi possivel cancelar convite', { erro: true })
      this.loading = false
    }
  }
  async goAtribuicao(registro) {
    // let response = await this._tokken.criarToken(registro.codigoEmpresa, 'SDO0100', 'TPHABI3', registro.cpfPessoa)
    // if (response) {
    //   this.infoAtribuicao = registro
    //   this.viewAtribuicao = true
    // }
    this.infoAtribuicao = registro
    this.viewAtribuicao = true
    console.log(this.viewAtribuicao)
  }
  goCadastroFuncionario(registro) {
    this.viewCadastroFuncionario = true
  }

  resetConvite() {
    this.coviteEnviado = false
    this.viewConvite = false
    this.viewCadastro = false
    this.formConvite.reset()
    this.registroUsuario = new cadastroUsuario
    this.getRegistro()
  }
  checkedFormComunic(item, checked) {
    if (checked) {
      item.select = true
    } else {
      item.select = false
    }
  }
  checkedTipColab(item) {
    if (item.checked) {
      this.formConvite.get('tipoColaborador').setValue(item.source.value)
    } else {
      this.formConvite.get('tipoColaborador').reset()
    }
  }
  creatForm() {
    this.formConvite = this.form.group({
      empresa: new FormControl(0, [Validators.required]),
      cpf: new FormControl('', [Validators.required, this.validBR.cpf]),
      tipoColaborador: new FormControl('', [Validators.required]),
    })
    this.formEmail = this.form.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      nomeCompleto: new FormControl('', Validators.required),
    })
  }
  filter(key) {

  }
  filterConexaoEmpresa(codigoEmpresa) {
    if (codigoEmpresa == 0) {
      this.dataSourceProfissional.data = this.dataSourceProfissionalAll

    } else {
      this.dataSourceProfissional.data = this.dataSourceProfissionalAll.filter(value => value.codigoEmpresa == codigoEmpresa)

    }
  }
}