import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { PortalDicionarioService } from 'src/app/service/portal-dicionario.service';
import { DicionarioPortal } from '../dicionario/modal';

@Component({
  selector: 'app-acontece-saude',
  templateUrl: './acontece-saude.component.html',
  styleUrls: ['./acontece-saude.component.scss']
})
export class AconteceSaudeComponent implements OnInit {
  @ViewChild('owlElement', { static: true }) owlElement: CarouselComponent;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
    nav: false,
    responsiveRefreshRate: 2000
  }
  noticias: DicionarioPortal[] = []
  links: DicionarioPortal[] = [];
  refresh: boolean;
  loading: boolean;
  constructor(
    private _portal: PortalDicionarioService,
    private _filter: FiltrarDadosService,
    private router: Router
  ) {
    window.scrollTo(0,0)
   }

  ngOnInit() {
    this.getNoticias()
  }
  async getNoticias() {
    let get = await this._portal.get()
    this.noticias = get.filter(value => value.tipoConteudo == 'TIPNOTIC4')//TIPNOTIC2
    this.links = get.filter(value => value.tipoConteudo == 'TIPNOTIC2')

    this._filter.ordernar('criadoEm', this.noticias.splice(6,this.noticias.length))
    // if(this.noticias.length > 5){
    //   this.customOptions.loop = true
    // }
    console.log(this.noticias)
  }
  next() {
    this.owlElement.next()
  }
  back() {
    this.owlElement.prev()
  }
  navLink(link) {
    window.open(link, '_blank');
  }
  convertDate(criadoEm) {
    let convert = this._filter.convertCriadoEm(criadoEm)
    return convert
  }
}
