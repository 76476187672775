export class SIS_CONECTPES {
  id: string
  cpfPessoa: string
  tipoConexao: string
  codigoEmpresa: number
  criadoPor: string
  criadoEm: string
  status: string
  nome: string
  tipoColaborador: string
  email: string
}
export interface FichaConexaoProfissional {
  id: number,
  criadoPor: string,
  codigoEmpresa: number,
  cpfProfissional: string,
  status: string,
  tipoConexao: string,
  timeStamp: string,
  cpfAutor: string
}

export class FichaConexaoProfissionalJOIN {
  codigoEmpresa: number
  nomeEmpresa: string
  cpfProfissional: string
  tipoRegistro: string
  tipoConexao: string
  valorTipoConexao: string
  especialidadeProfissional: string
  valorEspecialidadeProfissional: string
  codigoProfissao: string
  profissao: string
  nomeCompleto: string
  nr_Registro: string
  crmResponsavel: string
  statusConexoesProfissionais: string
  statusDadosProfissionais: string
  statusRegistroProfissional: string
  unidadeFederacao: string
  fotoPath: string
  inicioSessao: string
  timeLogin: string
  usuarioLogado: boolean
}

