import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DiarioDeAcessoService } from '../diario-de-acesso/diario-de-acesso.service';
import { BuscarDataService } from '../buscar-data.service'
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Mensagem } from './mensagem.model';
import { MensagemService } from '../mensagem.service';
import { environment } from 'src/environments/environment';
import { Contato } from '../contatos/contato.model';
import { combineLatest, Observable } from 'rxjs';

const url = `${new environment().api}HistoricoConversas/`;


@Injectable({
  providedIn: 'root'
})
export class HistoricoDeConversasService {

  constructor(
    private http: HttpClient,
    private serviceMensagem: MensagemService,
    private serviceFirestone: AngularFirestore,
    private serviceData: BuscarDataService,
    private serviceAcesso: DiarioDeAcessoService,
  ) { }

  collectionFirebase = this.serviceFirestone.collection('conversas');

  async listarMensagens(cpf: string): Promise<Mensagem[]> {
    let mensagemsEnviadas: Mensagem[] = [];
    await this.http.get<Mensagem[]>(`${url}`, { observe: 'body' }).toPromise()
      .then(res => mensagemsEnviadas = res.filter(value => value.de == cpf || value.para == cpf))
      .catch((err: HttpErrorResponse) => {
        if (err.status !== 404) this.serviceMensagem.creat(err.message, { erro: true })
      })
    return mensagemsEnviadas;
  }

  /* Veja a documentação do firebase em 
  https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#constructor */

  salvarMensagemHistorico(mensagem: Mensagem) {
    return this.http.post(url, mensagem).toPromise()
  }

  novaMensagemFirebase(mensagem: Omit<Mensagem, 'mensagem' | 'nome' | 'hora' | 'anexo' | 'nomeArquivo'>) {
    const dataHoje = this.serviceData.hoje();
    return this.collectionFirebase.doc(mensagem.idCatalogo).collection(dataHoje).add(mensagem);
  }

  async mensagemLida(mensagem: Mensagem) {
    const dataHoje = this.serviceData.hoje();
    mensagem.mensagemLida = true;
    await this.http.put(`${url}/${mensagem.id}`, mensagem).toPromise()
      .then(() => {
      })
      .catch(err => console.error(err))
  }

  observarFirebase(idCatalogo: string) {
    const dataHoje = this.serviceData.hoje();
    return this.collectionFirebase.doc(idCatalogo).collection(dataHoje).valueChanges();
  }

  //Use os serviços abaixos combinados para obter um notificador de mensagens

  ObservarContatos(listaContatos: Contato[]): Observable<firebase.firestore.DocumentData[][]> {
    const observablesFirebase = listaContatos.map(contato => this.observarFirebase(contato.id));
    /*CombineLatest, função rxjs que combina um array de observables notificando quando um deles emite uma resposta,
    saiba mais em https://rxjs-dev.firebaseapp.com/api/operators/combineLatest */
    const observableContatos = combineLatest(observablesFirebase);
    return observableContatos;
  }

  async filtrarNovasMensagens(contatos: Contato[]) {
    const cpfUsuario = await this.serviceAcesso.cpfDestaSessao();
    let qtdMensagens = 0;
    for (const contato of contatos) {
      const mensagensContato = await this.listarMensagens(contato.id);
      const novasMensagens = mensagensContato.filter((mensagem: Mensagem) => !mensagem.mensagemLida && mensagem.ativo && (mensagem.de !== cpfUsuario))
      qtdMensagens += novasMensagens.length;
    }
    return qtdMensagens;
  }
}
