import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saiba-mais',
  templateUrl: './saiba-mais.component.html',
  styleUrls: ['./saiba-mais.component.scss']
})
export class SaibaMaisComponent implements OnInit {
  exibirMaisDetalhes: boolean;
  constructor() { }

  ngOnInit() {
  }

}
