import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { AgendaService } from 'src/app/service/agenda.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
@Component({
  selector: 'app-historico-consulta',
  templateUrl: './historico-consulta.component.html',
  styleUrls: ['./historico-consulta.component.scss']
})
export class HistoricoConsultaComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Output('close') close = new EventEmitter
  dataSource = new MatTableDataSource()
  displayedColumns =
    ['dataConsulta', 'horaConsulta', 'status', 'nomeMedico', 'nomePaciente', 'descTipoConsulta', 'motivoConsulta', 'identificacaoConsultorio']
  loading = false
  constructor(private _agenda: AgendaService, private _filter: FiltrarDadosService, private _status: StatusSistemaService) { }

  ngOnInit() {
    this.getHistorico()
  }
  async getHistorico() {
    this.loading = true
    await this._agenda.getHistoricoAgenda(sessao.codigoEmpresa)
      .then(res => {
        this.dataSource = new MatTableDataSource(res)
        this.dataSource.sort = this.sort
        console.log('-----', res)
        this._filter.inserirFotoPerfil('cpfMedico', res)
        res.forEach(async value => {
          let desc = await this._status.getStatus(false, true, value.status)
          value.status = desc.toString()
        })
      })
    this.loading = false
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }
  fechar(){
    this.close.emit()
  }
}
