import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessaoUser } from '../user.reg';
const sessao = new SessaoUser
export class ProtocoloClinico {
  id: string
  tratamento: string
  diagnostico: string
  descDiagnostico?: any
  criadoPor: string
  timestamp: string
  status: string
}
@Injectable({
  providedIn: 'root'
})
export class ProtocoloClinicoService {
  url = new environment().api
  api = 'ProtocoloClinico/'
  constructor(private http: HttpClient) { }

  get() {
    return this.http.get<ProtocoloClinico[]>(this.url + this.api)
  }

   cadastrar(registro: ProtocoloClinico) {
     registro.criadoPor = sessao.cpf
    return this.http.post(this.url + this.api, registro)
  }
  alterar(registro: ProtocoloClinico) {
    console.log(registro)

    return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)
  }
  delete(registro: ProtocoloClinico) {
    registro.status = 'SCL0002'
    console.log(registro)
    return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)
  }

}
