import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { async } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import { ConfiguracaoInicialService } from '../service/configuracao-inicial.service';
import { FormCadastroEmpresa } from '../configuracao-inicial/cadastro-empresa/model';
import { LoginService } from '../service/login.service';
import { DiarioDeAcessoService } from '../service/diario-de-acesso/diario-de-acesso.service'
import { DicionarioDeDadosService } from '../service/dicionario-de-dados.service';
import { UsuarioService } from '../service/usuario.service';
import { CertificadoDigitalService } from '../service/certificado-digital/certificado-digital.service';
import { CertificadoDigital } from '../service/certificado-digital/modal';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { CoordenadaService } from '../service/coordenada.service';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
const url = new environment().api
@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  displayedColumns = ['assinante', 'emissor', 'seiral']
  cpfCertificado: string
  reg = {
    usuario: '',
    password: ''
  }
  carrocel = 1
  loading: boolean
  UserPaciente = 1
  logado: boolean;
  erroEmail = false;
  emailEnviado: boolean;
  SelectEmpresa = true
  noticias: any[];
  password: boolean = true
  userName: any;
  cpf: any;
  confirmReset = false
  noAssinante: boolean;
  empresas = [];
  localDeTrabalho: number
  mensagemView: string
  regCertificado: CertificadoDigital[] = [];
  itemCertificado = new CertificadoDigital
  loadingCertificado: boolean;
  viewCertificado: boolean;
  viewLogin = false
  viewResetSenha: boolean;
  cpfRecSenha: string;
  formulario: any;
  emitSenha: boolean;
  emailUser: string;
  erroGerarSenha: boolean;
  erro142: boolean;
  coordenada: any;
  erroStatus: boolean;
  loadingLogin: boolean;
  comentarios: any[] = [];
  viewCook: boolean;
  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastrService,
    private serviceEmpresa: ConfiguracaoInicialService,
    private service: LoginService,
    private _user: UsuarioService,
    private _filter: FiltrarDadosService,
    private _coordenada: CoordenadaService,
    private diarioDeAcessoService: DiarioDeAcessoService,
    private certificado: CertificadoDigitalService,
    private form: FormBuilder,
    private ValidBR: ValidateBrService
  ) { }


  async ngOnInit() {
    this.verificCoock()
    // this.teste()
    this.coordenada = await this._coordenada.get()
    this.buscarNoticias()
    setInterval(() => {
      this.carrocel++
      if (this.carrocel > 3) {
        this.carrocel = 1
      }
    }, 5000)
    this.creatForm()
  }
  buscarNoticias() {

    this.http.get(`${url}DicionarioPortal`).toPromise()
      .then(
        (dados: Array<any>) => {
          this.noticias = dados.filter(v => v.tipoConteudo == 'TIPNOTIC1')
          this.comentarios = dados.filter(v => v.tipoConteudo == 'TIPNOTIC5')
          this.comentarios.forEach(async value => {
            var img = new Image();
            img.onload = () => console.log('IMAGE LOADING');
            img.onerror = () => { value.fotoLink = 'assets/imgUser.png' };
            img.src = value.fotoLink;
          })
          this._filter.ordernar('criadoEm', this.noticias)
          this._filter.ordernar('criadoEm', this.comentarios)
        }
      )
  }
  goNoticia(link) {
    window.open(link)
  }
  getCertificado() {
    this.loadingCertificado = true
    this.certificado.get().toPromise()
      .then(res => {
        let cpf = this.cpfCertificado
        let get = res.filter(item => { return item.cpf == cpf })
        if (get.length > 0) {
          this.regCertificado = get
        } else {
          this.regCertificado = []
        }
        this.loadingCertificado = false
      })
  }

  selectCertificado(checket, item: CertificadoDigital) {

    if (checket) {
      this.itemCertificado = item
    } else {
      this.itemCertificado = new CertificadoDigital
    }
  }
  confirmCertificado() {
    this.reg.usuario = this.cpfCertificado
    this.reg.password = "Myclinics@2000"
    this.viewCertificado = false
    this.login()
  }
  async login() {
    this.mensagemView = null
    this.password = true
    localStorage.removeItem('tokkenRemove')


    // if (sessionStorage.getItem('tokken')) {
    //   this.logado = true
    //   return
    // } else {
    //   this.loading = false
    // }
    this.loading = true
    if (!url) {
      document.location.reload(true);
      return
    }

    // await this.http.post(`${url}v1/entrar/PRO`, this.reg).toPromise()
    await this.http.post(`${url}Auth/entrar/PRO`, this.reg).toPromise()
      .then(
        async (dados: any) => {
          if (!dados.success) {
            console.log('erro',dados)
            this.mensagemView = dados.data
            this.loading = false
            return
          }
          console.log('login =>', dados);

          const tipoUsuario = dados.data.tipoUsuario
          // console.log('LOGIN > ', dados, tipoUsuario)
          // console.log('passou', tipoUsuario)
          if (dados.statusUsuario == 'statusUsuario') {
            this.logado = true
            return
          }
          // if (!dados.data.assinante) {
          //   this.mensagemView = "CPF não possui permissão de acesso.Área destinada somente para assinantes e profissionais credenciados."
          //   this.loading = false
          //   return
          // }
          this.service.activeLogin(this.reg.usuario, true, tipoUsuario)
          if (dados.data.statusUsuario == 'Indefinido') {
            // this.service.activeLogin(this.reg.usuario, true)
          }

          else {
            this.noAssinante = false
          }
          if (dados.data.emailConfirmado) {
            this.cpf = dados.data.userToken.usuario
            this.userName = dados.data.userName
            if (sessionStorage.getItem('tokken')) {
              this.logado = true
              return
            }
            else {
              if (tipoUsuario == 'TIPUSU5' && !dados.data.colaborador) {
                if (dados.data.userToken.usuario) {
                  await sessionStorage.setItem('cpf', dados.data.userToken.usuario)
                }
                await sessionStorage.setItem('tokken', dados.data.userToken.id)
                await sessionStorage.setItem('cpf', dados.data.userToken.usuario)
                await sessionStorage.setItem('userName', dados.data.userName)
                await sessionStorage.setItem('tipoUsuario', 'TIPUSU5')
                await sessionStorage.setItem('companyId', dados.data.userCompanyId)
                await sessionStorage.setItem('codEmpresa', '7')
                this.go()
                return
              } else {
                await this.getEmpresa()
              }

            }

            await sessionStorage.setItem('tokken', dados.data.userToken.id)

            this.empresas = dados.data.tokenDeAcesso.filter((res) => { return res.statusHabilitacao == "SDO0096" && res.empresa != 7 })

            if (this.empresas.length < 1 && this.UserPaciente != 2) {
              this.mensagemView = "Habilitação de acesso invalida"
              this.SelectEmpresa = false
              this.loading = false
            }
            let removeDuplicidade = []
            this.empresas.forEach((empresa) => {
              let valid = removeDuplicidade.findIndex((reg) => { return reg.empresa == empresa.empresa }) < 0
              if (valid) {
                removeDuplicidade.push(empresa)
              }
            })
            this.empresas = removeDuplicidade
            this.SelectEmpresa = true
            await sessionStorage.setItem('listEmpresa', JSON.stringify(this.empresas))
            await sessionStorage.setItem('companyId', dados.data.userCompanyId)
            await sessionStorage.setItem('crm', dados.data.nr_Registro)
            await sessionStorage.setItem('cnpj', dados.data.cnpj)
            await sessionStorage.setItem('formaTratamento', dados.data.formaTratamento)
            await sessionStorage.setItem('unidadeFederativa', dados.data.unidadeFederativa)
            await sessionStorage.setItem('especialidade', dados.data.especialidade)
            if (dados.data.userToken.cpf) {
              await sessionStorage.setItem('cpf', dados.data.userToken.cpf)
            }
            if (dados.data.userToken.usuario) {
              await sessionStorage.setItem('cpf', dados.data.userToken.usuario)
            }
            await sessionStorage.setItem('cpf', dados.data.userToken.usuario)
            await sessionStorage.setItem('userName', dados.data.userName)
            if (dados.data.codigoStatus == 'SDO0031') {
              this.SelectEmpresa = false
              this.localDeTrabalho = 0
              this.go()
            }
            if (this.empresas.length == 1) {
              this.SelectEmpresa = false
              this.localDeTrabalho = this.empresas[0].empresa
              this.go()
            }
            // if (sessionStorage.getItem('tokken')) {
            //   this.SelectEmpresa = true
            //   return
            //   window.open('#/home')
            // }

          }
          else {
            this.erroEmail = true
            this.loading = false
            this.mensagemView = "Sua inscrição ainda não esta confirmada.Verifique sua caixa de email."
            this.http.get(`${url}SendEmail/${dados.data.emailUsuario}/${dados.data.userName}/${dados.data.userToken.usuario}`).toPromise()
              .then(
                () => this.emailEnviado = true
              ).catch(
                () => {
                  this.mensagemView = 'Email não enviado!'
                }
              )
          }

          // this.router.navigateByUrl('home')
        }
      ).catch(
        (erro) => {
          this.loading = false
          try {
            this.mensagemView = erro.error.errors[0]
          } catch {
            console.log(erro)
          }
        }
      )
    setTimeout(() => {
      this.mensagemView = null
    }, 3000)
    // this.mensagemView = 'Usuario não possui permissão de acesso'
    // this.loading = false




  }

  async go(primeiroAcesso?) {
    this.loadingLogin = true
    try {
      await sessionStorage.setItem('codEmpresa', this.localDeTrabalho.toString())
    }
    catch {
      await sessionStorage.setItem('codEmpresa', '0')
    }
    const codEmpresa = this.localDeTrabalho
    const nameEmpresa = this.empresas.filter((reg) => { return reg.empresa == codEmpresa }).map((name) => name.nomeEmpresa)[0]
    const codAssinante = this.empresas.filter((reg) => { return reg.empresa == codEmpresa }).map((name) => name.assinatura)[0]
    await sessionStorage.setItem('nomeEmpresa', nameEmpresa)
    try {
      await sessionStorage.setItem('assinante', codAssinante.toString())
    } catch {
      await sessionStorage.setItem('assinante', '00')
    }
    // try {
    //   await this.diarioDeAcessoService.criarSessao({ cpf: this.reg.usuario, localDeSessao: this.localDeTrabalho.toString() });

    // }
    // catch {
    //   await this.diarioDeAcessoService.criarSessao({ cpf: this.reg.usuario, localDeSessao: '00' });

    //  }
    console.log(this.reg.usuario);
    // if (this.UserPaciente == 1) {
    //   window.open("#/home")
    // }
    // else if (this.UserPaciente == 2) {
    //   window.open("#/business")
    // }
    window.open("#/home")
    this.reg.password = ''
    this.reg.usuario = ''
    this.loadingLogin = false
    this.loading = false
  }
  async confirmLogin() {
    if (this.confirmReset) {
      await this.sair()
      await this.login()
    }
    else {
      this.SelectEmpresa = false
      this.logado = false
      this.noAssinante = false
      this.reg.password = ''
      this.reg.usuario = ''
      this.loading = false
    }

  }
  openResetSenha() {
    this.viewResetSenha = true
  }
  async gerarSenha() {

    let endereço = await this._coordenada.getEndereco(this.coordenada)
    let ip = await this.diarioDeAcessoService.pegarIp()
    this.loading = true
    this.erroGerarSenha = false
    const cpf = this.formulario.get('cpf').value
    let getUser = await this._user.getRegistro(cpf)
    if (!getUser.email) {
      this.erroEmail = true
      this.loading = false
      return
    }
    if (getUser.status == 'SDO0142') {
      this.erro142 = true
      this.loading = false
      return
    } else {
      this.erro142 = false
    }
    if (getUser.status != 'SDO0060') {
      this.erroStatus = true
      this.loading = false
      return
    } else {
      this.erroStatus = false
    }

    let email = getUser.email
    let fragEmail = email.split("@")[0];
    fragEmail = fragEmail.slice(fragEmail.length / 3, fragEmail.length);
    for (let text of fragEmail) {
      email = email.replace(text, "*");
    }
    this.emailUser = email
    await this.http.get(url + 'GerarSenha/CodigoSenha/' + `${cpf}/${ip}/${endereço}`).toPromise()
      .then(res => {
        if (res) {
          this.emitSenha = true
        } else {
          this.erroGerarSenha = true
        }
      })
    this.loading = false
  }

  creatForm() {
    this.formulario = this.form.group({
      cpf: new FormControl('', [Validators.required, this.ValidBR.cpf])
    })
  }
  async getEmpresa() {
    return
    this.serviceEmpresa.listarEmpresa().toPromise()
      .then(
        (reg: Array<FormCadastroEmpresa>) => {
          const cpf = this.cpf
          console.log(cpf)
          let filter = reg.filter((r) => {
            console.log(r.cpf, cpf)
            return r.cpf == cpf
          })
          console.log(filter)
          if (filter.length > 0) {
            this.empresas = filter
            localStorage.setItem('infoEmpresa', JSON.stringify(this.empresas[0]))
          }
        }
      )
  }
  sair() {
    localStorage.setItem('tokkenRemove', 'true')
    sessionStorage.removeItem('tokken')
    sessionStorage.clear()
    this.empresas = []
    this.cpf = null
    this.userName = null
    this.logado = false
    this.SelectEmpresa = false
    this.loading = false
    this.mensagemView = null
    this.reg.password = ''
    this.reg.usuario = ''
  }
  verificCoock() {
    let get = localStorage.getItem('coock')
    if (get) {
      this.viewCook = false
    } else {
      this.viewCook = true
    }
  }
  aceiteCoock() {
    localStorage.setItem('coock', 'true')
    this.viewCook = false
  }

}
