export class SinaisVitaisDoPaciente {
    id: string
    criadoPor: string
    dataHoraInclusaoTabela: any
    codigoUnico: string
    temperatura: string
    pressaoDiastolica: string
    pressaoSistolica: string
    saturacao: string
    batimentosCardiacos: string
    frequenciaRespiratoria: string
}

export class ProntuarioVidaPaciente {
    id: string
    numeroDocumento: string
    dataInclusaoDocumento: any
    criadoPor: string
    cpf: string
    altura: string
    peso: string
    imc: string
    glicemia: string
    pressaoDiastolica: string
    pressaoSistolica: string
    sedentario: boolean
    quantoExercicio: string
    fuma: boolean
    quantoFuma: string
    bebe: boolean
    quantoBebe: string
    tipoSanguineo: string
    cintura: string
    doencaCronica: boolean
    quaisDoencasCronicas: string
    possuiFraturas: boolean
    quaisFraturas: string
    antiConcepcional: boolean
    quantoTempoAnticoncepcional: string
    status: string
    ativo: boolean
    estadoPaciente: string
    situacaoPaciente: string
}

export class CabecalhoProntuarioVidaPaciente {
    id: string
    criadoPor: string
    numeroDocumento: string
    cpf: string
    dataHoraCriacaoDocumento: string
    status: number
}


export class ProntuarioDeVidaItems {
    id: string
    criadoPor: string
    criadoEm : string
    numeroDocumento: string
    item: number
    codigoCaracteristica: string
    status: string
}

export class ProntuarioDeVidaConteudo {
    id: string
    criadoPor: string
    numeroDocumento: string
    item: number
    subItem: number
    valor: string
    criadoEm: string
    status: string
    codigoDeReferencia: string
    documentoReferencia: string
}