import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material';
import { EstruturaMenu } from 'src/app/home/home.component';
import { MenuMaster } from 'src/app/home/modal';
import { PerfilAcessoUsuarioService } from 'src/app/service/perfil-acesso-usuario.service';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser
@Component({
  selector: 'app-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss']
})
export class TreeMenuComponent implements OnInit {
  @Input() listMenu: MenuMaster[] = []
  @Input() tipoAcesso: string
  @Input() nameTipoAcesso: string
  @Output('response') response = new EventEmitter
  @ViewChild('tree', { static: false }) tree;

  menu_sessoes = [
    { cod: 'RC10', titulo: 'Rotina Clínica', icon: 'fas fa-laptop-medical', icon_hover: 'fas fa-laptop-medical text-info', iconCheck: false, iconCheckOuver: false },
    { cod: 'GS20', titulo: 'Gestão Simplificada', icon: 'fas fa-calculator', icon_hover: 'fas fa-calculator text-info', iconCheck: false, iconCheckOuver: false },
    { cod: 'AN30', titulo: 'Analíticos', icon: 'far fa-chart-bar font-20', icon_hover: 'far fa-chart-bar text-info', iconCheck: false },
    { cod: 'CN40', titulo: 'Conversas', icon: 'far fa-comments', icon_hover: 'far fa-comments text-info', iconCheck: false, iconCheckOuver: false },
    { cod: 'AJ50', titulo: 'Ajustes', icon: 'fas fa-cogs', icon_hover: 'fas fa-cogs text-info', iconCheck: false },
  ]
  private _transformer = (node: MenuMaster, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      menuName: node.menuName,
      menuURL: node.menuURL,
      menuID: node.menuID,
      level: level,
      active: node.routerActive,
      checked: node.checked,
    };
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  estrutura_menu: EstruturaMenu[] = []
  listCheckedMenu: any[] = []
  menuMaster: MenuMaster[] = [];
  codSessao = 'RC10'

  constructor(private service: PerfilAcessoUsuarioService) { }

  async ngOnInit() {
    console.log('TIPOOBJETO!@', this.tipoAcesso)
    this.menuMaster = await this.service.getMenuMaster()

    this.menuMaster.forEach(v => { v.routerActive = false; v.checked = false })
    this.construirMenu('RC10')
  }
  async construirMenu(codigo) {
    this.codSessao = codigo
    let menuMaster = this.menuMaster
    console.log('menu!', this.menuMaster.filter(v => v.checked))
    let routerActive = sessao.routerActive

    if (routerActive.length > 0) {
      routerActive.forEach(router => {
        menuMaster.filter((value) => {
          if (value.menuURL == router) {
            value.routerActive = true
          }
        })
      });
    } else {
      setTimeout(() => {
        routerActive.forEach(router => {
          menuMaster.filter((value) => {
            if (value.menuURL == router) {
              value.routerActive = true
            }
          })
        });
      }, 3000)
    }

    ///////////////////////////////
    let estrutura_menu: EstruturaMenu[] = [];
    //PAI
    menuMaster.forEach(pai => {
      if (pai.parent_MenuID == '*' && pai.menuID == codigo) {
        estrutura_menu.push({
          tipo: 1,
          menuName: pai.menuName,
          menuID: pai.menuID,
          menuURL: pai.menuURL,
          ordem: pai.ordem,
          children: []
        });
      }
    });
    // if (this.tipoAcesso == 'ITMPERFIL3') {
    //   this.tipoAcesso = 'ITMPERFIL2'
    // }

    menuMaster = menuMaster.filter(
      value => value.tipoObjeto == this.tipoAcesso
    );

    // console.log('RESULT MENU', menuMaster, this.tipoAcesso)
    // if (this.tipoAcesso == 'ITMPERFIL2') {
    //   menuMaster = menuMaster.filter(
    //     value => value.tipoObjeto == "ITMPERFIL2"
    //   );
    //   console.log('MENU!', menuMaster)
    // } else {
    //   menuMaster = menuMaster.filter(
    //     value => value.tipoObjeto == "ITMPERFIL3" || value.tipoObjeto == "ITMPERFIL2"
    //   );
    // }

    estrutura_menu.sort(function (a, b) {
      return parseInt(a.ordem ? a.ordem : '0') - parseInt(b.ordem ? b.ordem : '0')
    })

    //END PAI

    // FILHO 1
    estrutura_menu.forEach(nivel_0 => {
      menuMaster.forEach(menu => {
        if (nivel_0.menuID == menu.parent_MenuID) {
          let item = new EstruturaMenu();
          item.menuID = menu.menuID;
          item.menuName = menu.menuName;
          item.menuURL = menu.menuURL;
          item.ordem = menu.ordem;
          item.tipo = 2;
          item.children = [];
          item.checked = menu.checked
          nivel_0.children.push(item);
        }
      });
      nivel_0.children.sort(function (a, b) {
        return parseInt(a.ordem ? a.ordem : '0') - parseInt(b.ordem ? b.ordem : '0')
      })
    }
    );

    //END FILHO 1
    // FILHO 2
    estrutura_menu.forEach(nivel_0 => {
      nivel_0.children.forEach(nivel_1 => {
        menuMaster.forEach(menu => {
          if (nivel_1.menuID == menu.parent_MenuID) {
            let item = new EstruturaMenu();
            item.menuID = menu.menuID;
            item.menuName = menu.menuName;
            item.menuURL = menu.menuURL;
            item.ordem = menu.ordem;
            item.tipo = 2;
            item.children = [];
            item.checked = menu.checked
            item.routerActive = menu.routerActive;
            nivel_1.children.push(item);
          }
        });
        nivel_1.children.sort(function (a, b) {
          return parseInt(a.ordem ? a.ordem : '0') - parseInt(b.ordem ? b.ordem : '0')
        })
      });
    });
    //END FILHO 3
    estrutura_menu.forEach(nivel_0 => {
      nivel_0.children.forEach(nivel_1 => {
        nivel_1.children.forEach(nivel_2 => {
          menuMaster.forEach(menu => {
            if (nivel_2.menuID == menu.parent_MenuID) {
              let item = new EstruturaMenu();
              item.menuID = menu.menuID;
              item.menuName = menu.menuName;
              item.menuURL = menu.menuURL;
              item.ordem = menu.ordem;
              item.tipo = 2;
              item.children = [];
              item.checked = menu.checked
              item.routerActive = menu.routerActive;
              nivel_2.children.push(item);
            }
          });
          nivel_2.children.sort(function (a, b) {
            return parseInt(a.ordem ? a.ordem : '0') - parseInt(b.ordem ? b.ordem : '0')
          })
        });
      });
    });
    //END FILHO 3
    //END FILHO 4
    estrutura_menu.forEach(nivel_0 => {
      nivel_0.children.forEach(nivel_1 => {
        nivel_1.children.forEach(nivel_2 => {
          nivel_2.children.forEach(nivel_3 => {
            menuMaster.forEach(menu => {
              if (nivel_3.menuID == menu.parent_MenuID) {
                let item = new EstruturaMenu();
                item.menuID = menu.menuID;
                item.menuName = menu.menuName;
                item.menuURL = menu.menuURL;
                item.ordem = menu.ordem;
                item.tipo = 2;
                item.children = [];
                item.checked = menu.checked
                item.routerActive = menu.routerActive;
                nivel_3.children.push(item);
              }
            });
            nivel_3.children.sort(function (a, b) {
              return parseInt(a.ordem ? a.ordem : '0') - parseInt(b.ordem ? b.ordem : '0')
            })
          });
        });
      });
    });
    //END FILHO 4
    console.log("********END************", estrutura_menu);
    console.log('estrutura_menu', estrutura_menu)
    this.dataSource.data = estrutura_menu[0].children
    this.estrutura_menu = estrutura_menu[0].children
    this.tree.treeControl.expandAll();
    // this.goTransacao('RC10')
    ;

    // this.dataSource.data = menus

  }
  checkedMenu(checked, item) {
    if (checked) {
      item.checked = true
      this.menuMaster.filter(value => { if (value.menuID == item.menuID) { value.checked = true } })
      this.listCheckedMenu.push(item.menuID)
    } else {
      item.checked = false
      this.menuMaster.filter(value => { if (value.menuID == item.menuID) { value.checked = false } })
      let index = this.listCheckedMenu.findIndex(value => value == item.menuID)
      if (index >= 0) delete this.listCheckedMenu[index]
      this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
      console.log('index', index, this.listCheckedMenu)
    }
    this.activeALL(checked, item.level, item.menuID)
  }
  activeALL(active, level, menuID) {
    console.log(active, level, menuID)

    if (level == 0) {
      this.estrutura_menu.forEach(nivel_0 => {
        if (nivel_0.menuID == menuID) {
          nivel_0.checked = active
          if (active) {
            this.listCheckedMenu.push(nivel_0.menuID)
          } else {
            let index = this.listCheckedMenu.findIndex(value => value == nivel_0.menuID)
            if (index >= 0) delete this.listCheckedMenu[index]
            this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
          }
          nivel_0.children.forEach(nivel_1 => {
            nivel_1.checked = active
            if (active) {
              this.listCheckedMenu.push(nivel_1.menuID)
            } else {
              let index = this.listCheckedMenu.findIndex(value => value == nivel_1.menuID)
              if (index >= 0) delete this.listCheckedMenu[index]
              this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
            }
            nivel_1.children.forEach(nivel_2 => {
              nivel_2.checked = active
              if (active) {
                this.listCheckedMenu.push(nivel_2.menuID)
              } else {
                let index = this.listCheckedMenu.findIndex(value => value == nivel_2.menuID)
                if (index >= 0) delete this.listCheckedMenu[index]
                this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
              }
              nivel_2.children.forEach(nivel_3 => {
                nivel_3.checked = active
                if (active) {
                  this.listCheckedMenu.push(nivel_3.menuID)
                } else {
                  let index = this.listCheckedMenu.findIndex(value => value == nivel_3.menuID)
                  if (index >= 0) delete this.listCheckedMenu[index]
                  this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
                }
              });
            });
          });
        }
      });
    }
    if (level == 1) {
      this.estrutura_menu.forEach(nivel_0 => {
        nivel_0.children.forEach(nivel_1 => {
          if (nivel_1.menuID == menuID) {

            nivel_1.checked = active
            if (active) {
              this.listCheckedMenu.push(nivel_1.menuID)
            } else {
              let index = this.listCheckedMenu.findIndex(value => value == nivel_1.menuID)
              if (index >= 0) delete this.listCheckedMenu[index]
              this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
            }
            nivel_1.children.forEach(nivel_2 => {
              nivel_2.checked = active
              if (active) {
                this.listCheckedMenu.push(nivel_2.menuID)
              } else {
                let index = this.listCheckedMenu.findIndex(value => value == nivel_2.menuID)
                if (index >= 0) delete this.listCheckedMenu[index]
                this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
              }
              nivel_2.children.forEach(nivel_3 => {
                nivel_3.checked = active
                if (active) {
                  this.listCheckedMenu.push(nivel_3.menuID)
                } else {
                  let index = this.listCheckedMenu.findIndex(value => value == nivel_3.menuID)
                  if (index >= 0) delete this.listCheckedMenu[index]
                  this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
                }
              });
            });
          }
        });
      });
    }
    if (level == 2) {
      this.estrutura_menu.forEach(nivel_0 => {
        nivel_0.children.forEach(nivel_1 => {
          nivel_1.children.forEach(nivel_2 => {
            if (nivel_2.menuID == menuID) {
              nivel_2.checked = active
              if (active) {
                this.listCheckedMenu.push(nivel_2.menuID)
              } else {
                let index = this.listCheckedMenu.findIndex(value => value == nivel_2.menuID)
                if (index >= 0) delete this.listCheckedMenu[index]
                this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
              }
              nivel_2.children.forEach(nivel_3 => {
                nivel_3.checked = active
                if (active) {
                  this.listCheckedMenu.push(nivel_3.menuID)
                } else {
                  let index = this.listCheckedMenu.findIndex(value => value == nivel_3.menuID)
                  if (index >= 0) delete this.listCheckedMenu[index]
                  this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
                }
              });
            }
          });
        });
      });
    }
    if (level == 3) {
      this.estrutura_menu.forEach(nivel_0 => {
        nivel_0.children.forEach(nivel_1 => {
          nivel_1.children.forEach(nivel_2 => {
            nivel_2.children.forEach(nivel_3 => {
              if (nivel_3.menuID == menuID) {
                nivel_3.checked = active
                if (active) {
                  this.listCheckedMenu.push(nivel_3.menuID)
                } else {
                  let index = this.listCheckedMenu.findIndex(value => value == nivel_3.menuID)
                  if (index >= 0) delete this.listCheckedMenu[index]
                  this.listCheckedMenu = this.listCheckedMenu.filter(value => value)
                }
              }
            });

          });
        });
      });
    }


    this.dataSource.data = this.estrutura_menu
    this.tree.treeControl.expandAll();
    console.log(this.listCheckedMenu)
  }
  emitList() {
    let list = []
    this.listCheckedMenu = this.listCheckedMenu.filter(function (item, pos,self) {
      return self.indexOf(item) == pos;
    })
    this.listCheckedMenu.forEach(item => {
      let get = this.menuMaster.filter(value => value.menuID == item)
      list.push(...get)
    });
    this.response.emit(list)
  }
  getMenuMaster() { }

}
