import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DadosSaudePacienteService } from 'src/app/service/dados-saude-paciente/dados-saude-paciente.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { SequelasPaciente } from '../../model';
const sessao = new SessaoUser
@Component({
  selector: 'app-prontuario-sequelas',
  templateUrl: './prontuario-sequelas.component.html',
  styleUrls: ['./prontuario-sequelas.component.scss']
})
export class ProntuarioSequelasComponent implements OnInit {
  @Input('cpf') cpf: string
  @Output('close') exit = new EventEmitter
  @Input('register') register = new SequelasPaciente
  formulario: FormGroup
  loading : boolean = false
  constructor(
    private formBuilder: FormBuilder,
    private _mensagem: MensagemService,
    private service : DadosSaudePacienteService) { }


    async ngOnInit() {
      await this.creatForm()
      if (this.register.id) {
        this.formulario.patchValue(this.register)
        this.formulario.disable()
      }
    }
  
    creatForm() {
      this.formulario = this.formBuilder.group({
        id: new FormControl(''),
        lesao: new FormControl(''),
        cpfPaciente: new FormControl(''),
        cpfMedico: new FormControl(''),
        data: new FormControl(''),
        observacao: new FormControl(''),
        criadoPor: new FormControl(''),
        criadoEm: new FormControl(''),
        status: new FormControl('')
      })
    }
  
    excluirRegistro() {
      let register = this.formulario.getRawValue() as SequelasPaciente
      if (!register.id) {
        this._mensagem.creat('Não é possível excluir este registro', { erro: true })
        return
      }
      register.status = 'SCA0002'
      this.service.putSequenas(register).toPromise()
        .then(() => {
          this._mensagem.creat('Registro excluido com sucesso', { sucesso: true })
          this.close()
        })
        .catch(error => {
          console.log('erro!', error)
          this._mensagem.creat('Não foi possível alterar registro', { erro: true })
        })
    }
    cancelarRegistro() { 
      this.close()
    }
    fecharCadastro() { }
  
    salvar() {
      let register = this.formulario.getRawValue() as SequelasPaciente
  
      if (!register.id) {
        delete register.id
        register.criadoPor = sessao.cpf
        register.cpfMedico = sessao.cpf
        register.cpfPaciente = this.cpf
        register.status = 'SCA0001'
        console.log(register)
        this.service.postSequenas(register).toPromise()
          .then(() => this.close())
          .catch(error => {
            this._mensagem.creat('Não foi possível gravar registro', { erro: true })
            console.log('erro!', error)
          })
  
      } else {
        console.log(register)
        this.service.putSequenas(register).toPromise()
          .then(() => {
            this._mensagem.creat('Registro alterado com sucesso', { sucesso: true })
            this.close()
          })
          .catch(error => {
            console.log('erro!', error)
            this._mensagem.creat('Não foi possível alterar registro', { erro: true })
          })
      }
  
    }
    _control(control: string) {
      return this.formulario.get(control)
    }
    close() {
      this.exit.emit()
    }
  
}
