import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { TicketReserva, TicketConsulta } from './modal';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { AgendaForm, MarcaConsultaForm } from 'src/app/agenda/agenda/model';
import { AgendaService } from 'src/app/service/agenda.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { SessaoUser } from 'src/app/user.reg';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MonitorClinicoService } from 'src/app/service/monitor-clinico.service';
import { TicketsService } from 'src/app/service/tickets.service';
const sessao = new SessaoUser
@Component({
  selector: 'app-solicitacoes',
  templateUrl: './solicitacoes.component.html',
  styleUrls: ['./solicitacoes.component.scss']
})
export class SolicitacoesComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() registros: TicketReserva[]
  @Input() historico: TicketReserva[] | any
  @Output() close = new EventEmitter
  displayedColumns = ['criadoEm', 'paciente', 'medico', 'especialidade', 'tipoAgendamento', 'tipoConsulta', 'dataReserva', 'horarioReserva', 'acao']
  displayedColumnsHistorico = ['status', 'criadoEm', 'paciente', 'medico', 'especialidade', 'tipoAgendamento', 'tipoConsulta', 'dataReserva', 'horarioReserva']
  dataSource: MatTableDataSource<TicketReserva>;
  dataSourceHistorico: MatTableDataSource<TicketReserva>;
  ticket = new TicketReserva
  viewAgenda: boolean
  loading: boolean;
  viewNewHorario: boolean;
  formNewHorario = new MarcaConsultaForm
  constructor(
    private agenda: AgendaService,
    private mensagem: MensagemService,
    private _date: BuscarDataService,
    private _filter: FiltrarDadosService,
    private service: AgendaService,
    private monitor: MonitorClinicoService,
    private _conteudo: ConteudoDeDadosService,
    private _ticket: TicketsService,
    private _status: StatusSistemaService) {

  }

  async ngOnInit() {
    this.getRegistro()

  }
  async getRegistro() {
    this.loading = true
    await this.registros.forEach(async item => {
      let getMedico = await this.agenda.getMedico(item.cpfMedico)
      let paciente = await this.agenda.getPaciente(item.cpfPaciente)
      item.nomeMedico = getMedico.nomeCompleto
      item.nomePaciente = paciente.nomeCompleto
      item.especialidade = getMedico.valorEspecialidade

    });
    await this.historico.forEach(async item => {
      let getMedico = await this.agenda.getMedico(item.cpfMedico)
      let paciente = await this.agenda.getPaciente(item.cpfPaciente)
      item.nomeMedico = getMedico.nomeCompleto
      item.nomePaciente = paciente.nomeCompleto
      item.especialidade = getMedico.valorEspecialidade
    });

    let dados = [...this.registros, ...this.historico]

    dados.forEach(async item => {
      item.desTipoAgendamento = (await this._conteudo.get('LIMOCO', true, item.motivoConsulta)).toString()
      item.desStatus = (await this._status.getStatus(false, true, item.status)).toString()
      item.descTIpoConsulta = (await this._conteudo.get('LITICO', true, item.tipoConsulta)).toLocaleString()
    })

    this.dataSource = new MatTableDataSource(this.registros)
    this.dataSource.sort = this.sort
    this.dataSourceHistorico = new MatTableDataSource(this.historico)
    this.loading = false
    this._filter.inserirFotoPerfil('cpfPaciente', this.registros)

    console.log('REGISTRO ATUALI', this.registros)
  }
  async aceitar(registro) {
    console.log('ACEITAR!', registro)

    this.loading = true
    let agenda = new AgendaForm
    agenda.criadoPor = sessao.cpf
    agenda.codigoEmpresa = registro.codigoEmpresa
    agenda.cpf = registro.cpfPaciente
    agenda.cpfMedico = registro.cpfMedico
    agenda.date = registro.dataReserva
    agenda.start = `${registro.dataReserva.split('T')[0]}T${registro.horarioReserva}`
    // agenda.title = registro.nomePaciente
    // agenda.medico = registro.nomeMedico
    agenda.status = "SDO0008"
    agenda.backgroundColor = "#ffffff00"
    // agenda.paciente = registro.nomePaciente
    console.log('registro > ', agenda)

    await this.agenda.cadastrarAgenda(agenda).toPromise()
      .then(() => {
        try {
          let tconsulta = new TicketConsulta
          tconsulta.codigoEmpresa = registro.codigoEmpresa
          tconsulta.cpfPaciente = registro.cpfPaciente
          tconsulta.motivoConsulta = 'indefinido'
          tconsulta.criadoPor = sessao.cpf
          tconsulta.status = 'SDO0010'
          tconsulta.dataInclusaoDocumento = this._date.horaAgora(true)
          tconsulta.ativo = true
          tconsulta.nrDocRef = 'TICRES'
          tconsulta.horaConsulta = registro.horarioReserva
          tconsulta.tipoConsulta = registro.tipoConsulta
          tconsulta.tipoAtendimento = registro.tipoAtendimento
          tconsulta.formaDeConsulta = registro.tipoMarcacao
          tconsulta.tipoDoc = 'TICCON'
          tconsulta.tipoDocRef = 'TICRES'
          tconsulta.cpfMedico = registro.cpfMedico
          this.agenda.gerarTicketConsulta(tconsulta).toPromise().then((res) => {
            this.loading = false
            if (!res) {
              this.mensagem.creat('Falha ao realizar operação', { erro: true })
              return
            }
            this.mensagem.creat('Solicitação confirmada com sucesso', { sucesso: true })


            this.agenda.atualizarTicket(registro).toPromise()
              .then((res) => {

              })

          })
        } catch {
          this.loading = false
          this.mensagem.creat('Falha ao realizar operação', { erro: true })
        }

      }).catch(error => console.log('erro!', error))
    registro.status = "SDO0027"
    this.atualizar()
  }
  sugerirHorario(item) {
    this.ticket = item
    console.log('ticket!', item)
    this.viewAgenda = true
  }
  async cancelar(item) {
    this.loading = true
    item.status = "SDO0080"
    await this.agenda.atualizarTicket(item).toPromise()
      .then(
        () => {
          this.mensagem.creat('Reserva cancelada com sucesso', { sucesso: true })
          this.atualizar()

        }
      ).catch(
        error => {
          console.log('erro', error)
          this.mensagem.creat('Algo deu errado', { erro: true })
        }
      )
    this.loading = false
  }
  atualizarTicket(registro) {
    registro.status = "SDO0075"
    this.service.atualizarTicket(registro).toPromise()
      .then((reg) => {
        this.mensagem.creat('Sugestão de novo horário enviado com sucesso', { sucesso: true })
        this.viewAgenda = false
      })
  }
  sortData(sort: Sort) {
    const data = this.dataSourceHistorico.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourceHistorico.data = data;
      return;
    }

    this.dataSourceHistorico.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'paciente': return compare(a.nomePaciente, b.nomePaciente, isAsc);
        case 'medico': return compare(a.nomeMedico, b.nomeMedico, isAsc);
        case 'especialidade': return compare(a.especialidade, b.especialidade, isAsc);
        case 'tipoAgendamento': return compare(a.desTipoAgendamento, b.desTipoAgendamento, isAsc);
        case 'tipoConsulta': return compare(a.descTIpoConsulta, b.descTIpoConsulta, isAsc);
        case 'dataReserva': return compare(a.horarioReserva, b.horarioReserva, isAsc);
        case 'horarioReserva': return compare(a.dataReserva, b.dataReserva, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
  convertDate(date) {
    return this._filter.convertCriadoEm(date)
  }
  Close() {
    this.close.emit(false)
  }
  openNewHorario(form) {
    console.log('openNewHorario => ', form)
    if (form) {
      this.viewNewHorario = true
      this.formNewHorario = form
    }
  }
  async confirmSugestao() {
    console.log(this.ticket)
    let getTicket = await this._ticket.getTicketReservaID(this.ticket.id)
    if (getTicket) {
      getTicket.dataReserva = this.formNewHorario.date
      getTicket.horarioReserva = this.formNewHorario.start
      getTicket.status = 'SDO0075'
      let response = await this._ticket.atualizarTicketReserva(getTicket)
      if (response) {
        this.mensagem.creat(`Sugestão de horário encaminhada para o paciente ${this.ticket.nomePaciente}`, { sucesso: true })
        this.viewNewHorario = false
        this.atualizar()
      } else {
        this.mensagem.creat(`Falha ao realizar procedimento`, { erro: true })
      }
    }
  }
  async atualizar() {
    this.dataSource.data = this.registros.filter(v => v.status == 'SDO0045')
    console.log('REGISTRO ATUALIZADO', this.registros)
    let verific = this.registros.filter((v: any) => v.backgroud)
    console.log('verific', verific)
    if (verific.length < 1) {
      this.ngOnInit()
    }
    return
    this.registros = await (await this.monitor.get('solicitacoes') as any).ativos
    this.historico = await (await this.monitor.get('solicitacoes') as any).historico
    this.getRegistro()
  }
  filter(value) {
    this.dataSource.filter = value
  }
  filterHistorico(status) {
    this.dataSourceHistorico.filter = status
  }
}
