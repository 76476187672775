import { Component, OnInit, ViewChild, Input, Output, EventEmitter, HostListener } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventInput, OptionsInput } from '@fullcalendar/core';
import bootstrapPlugin, { BootstrapTheme } from '@fullcalendar/bootstrap';
import timeGrigPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { DatePipe, JsonPipe } from '@angular/common';
import { AgendaForm, AgendaConfigForm, HorariosForm, AgendaFechadaForm, AgendaFeriadosForm, AgendaHorarioDiferenteForm, MarcaConsultaForm } from './model';

import { AgendaService } from 'src/app/service/agenda.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { ListarMedicos, ListarMedicosService } from 'src/app/service/listar-medicos.service';
import { SessaoUser } from 'src/app/user.reg';
import { ConteudoDeDadosService, ConteudoDados } from 'src/app/service/conteudo-de-dados.service';
import { TicketReserva, TicketConsulta } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FormGroup } from '@angular/forms';
import { TicketsService } from 'src/app/service/tickets.service';
const sessao = new SessaoUser

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  @Input('usuarioId') usuarioId
  @Output() sair = new EventEmitter
  @Output('popUp') popUp = new EventEmitter()
  @Output('viewMenu') viewMenu = new EventEmitter()
  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Input() solicitacao = new TicketReserva
  @Output() resp_solicitacao = new EventEmitter

  cpf: string
  id: string
  calendarPlugins = [dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGrigPlugin, listPlugin];
  eventos: EventInput = []
  options: OptionsInput;
  editarTabela = false
  dataSelecionada: AgendaForm = {}
  registroAgenda: AgendaForm[] = []
  registroReserva: AgendaForm[] = []
  agendaConfigForm: FormGroup
  alterar: boolean;
  Cardleft: any;
  CardTop: any;
  medico_selecionado = new ListarMedicos
  medico_selecView = null
  viewCalendario = null
  viewCalendarioS = null
  validRange = {
    start: '2019-11-01',
    end: '2019-11-15'
  }
  // hiddenDays = [7,8]
  CardDisplay: any = 'none';
  CardOpc: number;
  blockHouver = false
  time = 0
  secretaria: boolean;
  companyId: any;
  medicos: ListarMedicos[] = [];
  medico_info: any
  pacientes: any;
  idMedico: any
  configAgenda = new AgendaConfigForm;
  erro: boolean
  erroMensagem: string
  loading: boolean
  reservas: any;
  Hoje: string;
  teste: boolean
  listarHorarios: { h: string; m: string; }[];
  horarios: HorariosForm[] = []
  horariosTermino: HorariosForm[] = []
  validaHS: boolean;
  validaHT: boolean;
  erroConfig: boolean;
  erroConfiMensagem: string;
  contHorarios: number;
  horariosDoDia = [];
  block: boolean;
  blockDia: boolean;
  confgDataTermino: string;
  medico_usuarioId: string
  sugerirHorario: boolean;
  reservaSelec: AgendaForm
  abrirSolicReserva: boolean = false
  pacienteLoading: boolean;
  pacienteSemCPF: boolean;
  abrirCadPaciente: boolean;
  viewDadosPaciente: boolean;
  openAgendaConfig: boolean;
  editar: boolean;
  salvar: boolean;

  old = true
  viewCalendario_Secundario: any
  mes: string;
  ano: number;
  confirm: boolean;
  confirm_cancelar: boolean;
  confirm_delete: boolean;
  legendaHorarioDiferente: string;
  dateFeriados = [];
  loadingHorarios: boolean;
  semHorario: boolean;
  horariosIDs: { id: string; }[];
  viewMarcaConsulta: boolean;
  viewAtribuirConsultorio: boolean
  viewHistorico: boolean
  erroConfigAgenda: boolean = false;
  displayEventTime: any;
  codEmpresa: number;

  tipoConsulta: ConteudoDados[];
  tipoAtendimento: ConteudoDados[];
  motivoConsulta: ConteudoDados[];
  formConsulta: ConteudoDados[];

  viewTodos: boolean;
  existfechamento: boolean;
  eventCoord = { 'top': null, 'left': null }
  infoEvent: any;
  fotoPerfil: any;

  infoEventPaciente: any;
  dia: number;
  formularioAgenda = new MarcaConsultaForm
  diasDisponiveis: AgendaFechadaForm[] = [];
  ticketConsulta = new TicketConsulta;
  dateActive: string;
  ticketReserva: TicketReserva;
  constructor(
    private dataPipe: DatePipe,
    private service: AgendaService,
    private serviceUsuario: UsuarioService,
    private mensagem: ToastrService,
    private _info: MatSnackBar,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private buscarData: BuscarDataService,
    private _conteudoDados: ConteudoDeDadosService,
    private _mensagem: MensagemService,
    private _listMedicos: ListarMedicosService,
    private _ticket: TicketsService
  ) {

    this.Hoje = this.buscarData.hoje()

  }
  async ngOnInit() {
    await this.activeRouter.params.subscribe(param => {
      if (param.id) {
        this.reagendar(param.id)
      }
    })

    console.log('ticket agenda', this.solicitacao)

    this.loading = true
    let usuario = sessao.sessao_id
    this.cpf = sessao.cpf

    if (this.usuarioId) {
      this.id = this.usuarioId
    }

    if (usuario) {
      this.id = usuario
    }

    setTimeout(() => {
      this.calendarComponent.getApi().next()
      this.calendarComponent.getApi().prev()
      this.calendarComponent.getApi().today()
      this.getDataCalendario();
    }, 1000)
    await this.getMedicos()

    this.calendarComponent.getApi().updateSize()
    setTimeout(() => this.calendarComponent.getApi().updateSize(), 500)
    setTimeout(() => this.calendarComponent.getApi().updateSize(), 1000)

  }

  async buscarAgendaMedica(todos, medico?: ListarMedicos) {
    this.loading = true
    this.viewTodos = todos
    if (medico) {
      this.medico_selecView = medico.nomeCompleto + ' - CRM ' + medico.nr_Registro + '/ ' + medico.unidadeFederacao + ' ' + medico.valorEspecialidadeProfissional
      this.medico_selecionado = medico
      this.medico_selecionado.nr_Registro
    }
    await this.service.listarAgenda().subscribe(
      async (dados: AgendaForm[]) => {

        let solicitacao = this.solicitacao
        const filtrar = dados.filter(function (reg: AgendaForm) {

          if (todos) {
            if (reg.title) {
              reg.title = `${reg.title.split(' ', 2).join(' ')} - Dr(a)${reg.medico}`
            }
            return reg.codigoEmpresa == sessao.codigoEmpresa
          }

          return reg.cpfMedico == medico.cpfProfissional && reg.codigoEmpresa == sessao.codigoEmpresa

        })

        let registros = []

        let hoje = this.buscarData.hoje().split('-').join('')

        filtrar.forEach(x => {
          let color = x.color
          let h = parseInt(hoje)
          let date = parseInt(x.date.split('-').join(''))
          delete x.color
          x.textColor = color
          if (date < h)
            x.textColor = '#989c98'
          registros.push(x)
        });

        if (!todos) {
          let getDiasDisponiveis = this.diasDisponiveis.filter(value => value.cpfMedico == medico.cpfProfissional)
          console.log('getDiasDisponiveis', getDiasDisponiveis)
          getDiasDisponiveis.forEach(value => {
            let item = {
              start: value.start,
              end: value.end,
              rendering: "background",
              textColor: '#000',
              color: '#ffffff',
              openDay: true
            }
            registros.push(item)
          })
        }

        let ano = new Date().getFullYear()
        let feriados = await this.service.getFeriados(ano)
        console.log('feriado', feriados)
        registros.push(...feriados)
        await registros.forEach(async value => {
          let infoAgenda = await this._ticket.getRefConsulta(value.ticketConsultaMedica)
          console.log('registro agenda :'+value.status,' registro consulta : ',infoAgenda.status)

          if (infoAgenda) value.status = infoAgenda.status

        })

        this.registroAgenda = registros
    
        // this.getFeriados()
        this.loading = false
        this.getDiasDisponiveis(medico.cpfProfissional)
      }
    )
  }

  async getFeriados() {
    let ano = new Date().getFullYear()
    let feriados = await this.service.getFeriados(ano)

    this.registroAgenda.push(...feriados)
    console.log(JSON.stringify(this.registroAgenda))
  }
  async getDiasDisponiveis(cpf: string) {

    let config = await this.service.getConfig()

    this.configAgenda = config.filter(v => v.cpfMedico == cpf)[0] || new AgendaConfigForm
    console.log('configAgenda', this.configAgenda)
    if (this.configAgenda) {
      this.erroConfigAgenda = false
    } else {
      this.erroConfigAgenda = true
    }

    if (this.configAgenda.userConfigEmpresa) cpf = sessao.codigoEmpresa.toString()

    await this.service.listarAgendaFechada().toPromise()
      .then(
        (reg: Array<AgendaFechadaForm>) => {

          let filtro = reg.filter((r) => { return r.codigoEmpresa == sessao.codigoEmpresa && r.status == 'SDO0149' && r.cpfMedico == cpf })
          this.diasDisponiveis = filtro
          console.log('Dias abertos', this.diasDisponiveis)
        }

      )
    console.log('CONFIG! >>>>>', this.configAgenda, this.diasDisponiveis)
    this.loading = false
  }
  eventRender(e) {
    const registro = e.event.extendedProps
    const feriado = e.event.extendedProps.feriado
    const diaAberto = e.event.extendedProps.openDay
    const consulta = e.event.extendedProps.cpf
    const start = new Date(e.event.start).toISOString().split('T')[1].slice(0, 5)
    const end = new Date(e.event.end).toISOString().split('T')[1].slice(0, 5)

    let html

    if (feriado) {
      html =
        `<ul class="list-group" style="overflow: hidden;">
        <li class="list-group-item p-1 " style="background: none"><i class="fas fa-certificate text-warning mr-1"></i>${e.event.title}</li>
      </ul>`

    }
    if (consulta) {
      const status = registro.status
      html =
        `<ul class="list-group p-1 m-0" style="overflow: hidden;border-radius: 5px;">
          <li class="list-group-item p-0 text-dark" style="background: none">
          <i class="${status == 'SDO0008' ? 'mr-1 fas fa-check-circle text-success' : ''} "></i> 
          <i class="${status == 'SDO0010' ? 'mr-1 fas fa-user-clock text-info' : ''} "></i> 
          <i class="${status == 'SDO0022' ? 'mr-1 fas fa-user-times text-danger' : ''} "></i> 
          <i class="${status == 'SD00011' ? 'mr-1 fas fa-medal text-warning' : ''} "></i> 
          <i class="${status == 'SDO0028' ? 'mr-1 fas fa-star text-success' : ''} "></i> 
          <i class="${status == 'SDO0098' ? 'mr-1 fas fa-user-times text-danger' : ''} "></i> 
          ${start}h - ${end}h</li>
        </ul>`

    }
    if (diaAberto) {

      html =
        `<ul class="list-group" style="overflow: hidden;">
      <li class="list-group-item p-1" style="background : none">
      </li>
    </ul>`
    }
    e.el.innerHTML = html
  }
  dayRender(ev) {
    let day = new Date(ev.date).toISOString().split('T')[0].split('-').join('')
    let today = new Date().toISOString().split('T')[0].split('-').join('')

    ev.el.classList.add('dayBlock')


    let getDates

    let elements = document.getElementsByClassName('fc-day-top')

    this.diasDisponiveis.forEach(value => {
      let dateOpen = new Date(value.start).toISOString().split('T')[0].split('-').join('')
      if (dateOpen == day) {
        ev.el.classList.remove('dayBlock')
        ev.el.classList.add('dayOpen')


        for (let i = 0; i < elements.length; i++) {
          let date = elements[i].getAttribute('data-date').split('-').join('')
          if (date == dateOpen) {
            elements[i].classList.remove('dayBlock')
            elements[i].classList.add('dayOpen')
          }
        }
      }
    })


    if (day < today) {
      ev.el.classList.remove('dayOpen')
      ev.el.classList.add('dayBlock')
    }





  }



  infoTeste(v) {
    console.log('$$$$$')
  }
  MouseOver(event, active) {

    if (!active) {
      this.infoEvent = null
      return
    }
    let item = event.event._def.extendedProps
    if (item.feriado) return
    this.eventCoord = {
      'top': `${event.jsEvent.y - 260}px`,
      'left': `${event.jsEvent.x - 350}px`,
    }

    this.infoEvent = Object.assign({}, item, { id: event.event.id })

    this.infoEventPaciente = event.event.title
  }


  getDataCalendario() {
    try {
      const calender = this.calendarComponent.getApi()
      // let getValueDay = new Date(calender.getDate().toISOString().split('T')[0])
      let index = this.calendarComponent.getApi().getDate().toISOString().split('T')[0].split('-')[1]
      this.ano = calender.getDate().getFullYear()
      this.mes = this.buscarData.getMeses(parseInt(index) - 1).name
      this.dateActive = calender.getDate().toISOString().split('T')[0]
    } catch (error) {
      console.log('ERRO!', error)
    }
  }

  async abrirEvento(event) {
    const id = event.event.id
    console.log('evento', event.event)
    if (id == 'feriado') return
    let agenda
    await this.service.listarAgenda(id).toPromise()
      .then(async (res: any) => {
        this.ticketConsulta = await this._ticket.getRefConsulta(res.ticketConsultaMedica)
        this.ticketReserva = await this._ticket.getRefReserva(this.ticketConsulta.nrDocRef)
        this.formularioAgenda.start = this.ticketConsulta.horaConsulta
        this.formularioAgenda.date = this.ticketConsulta.dataConsulta.split('T')[0]
        this.formularioAgenda.formaDeConsulta = this.ticketConsulta.formaDeConsulta
        this.formularioAgenda.motivoConsulta = this.ticketConsulta.motivoConsulta
        this.formularioAgenda.tipoAtendimento = this.ticketConsulta.tipoAtendimento
        this.formularioAgenda.tipoConsulta = this.ticketConsulta.tipoConsulta
        this.formularioAgenda.end = (res.end.split('T')[1].replace(':', '') - res.start.split('T')[1].replace(':', '')).toString()
        this.formularioAgenda.cpf = this.ticketConsulta.cpfPaciente
        this.formularioAgenda.idTicketConsulta = this.ticketConsulta.id
        this.formularioAgenda.idTicketReserva = this.ticketReserva.id
        this.formularioAgenda.idAgenda = id
        this.formularioAgenda.status = this.ticketConsulta.status
        this.viewMarcaConsulta = true

      })
    return
  }
  excluir(id: any) {
    this.service.excluirAgenda(id).toPromise()
      .then(
        () => {

          if (this.medico_selecionado) {
            this.buscarAgendaMedica(false, this.medico_selecionado)
          } else {
            this.buscarAgendaMedica(true)
          }
        }
      ).catch(
        () => this.mensagem.error('erro')
      )
  }


  async getMedicos() {
    this.medicos = []
    const codEmpresa = sessao.codigoEmpresa
    this.medicos = await this._listMedicos.getList(codEmpresa)
    let getMedicoSessao = this.medicos.filter(v => v.cpfProfissional == sessao.cpf)[0]
    console.log('getMedicoSessao', this.medicos)
    if (getMedicoSessao) {
      this.buscarAgendaMedica(false, getMedicoSessao)
    } else {
      this._mensagem.creat('Não foi encontrado nenhum médico na sua clínica', { erro: true })
      this.loading = false
    }

  }



  async clickHouver(ref) {
    this.time++
    this.dataSelecionada.date = this.dataPipe.transform(ref.event.start, 'yyyy-MM-dd')
    this.dataSelecionada.title = ref.event.title
    this.dataSelecionada.id = ref.event.id
  }

  info() {
    this._info.open('Salvo com sucesso', '', {
      duration: 2000
    })
  }




  async abrirNovaConsulta(event?) {


    this.formularioAgenda = new MarcaConsultaForm
    if (!this.medico_selecionado.cpfProfissional) {
      this._mensagem.creat('Selecione o Médico antes de marca uma consulta ', { erro: true })
      return
    }
    if (event) {
      const validBlockDay = JSON.stringify(event.dayEl.className).indexOf('dayBlock')
      if (validBlockDay > 0) return

      const date = event.dateStr
      this.formularioAgenda.date = date
      // let verificFimDeSamana = new Date(date).getDay() == 0 ? true : new Date(date).getDay() == 6 ? true : false
      let verificDay = new Date(date).valueOf() < new Date(new Date().toISOString().split('T')[0]).valueOf()
      // if (verificFimDeSamana) return
      if (verificDay) return

      if (this.solicitacao.id) {
        this.formularioAgenda.cpf = this.solicitacao.cpfPaciente
        this.formularioAgenda.motivoConsulta = this.solicitacao.motivoConsulta
        this.formularioAgenda.tipoAtendimento = this.solicitacao.tipoAtendimento
        this.formularioAgenda.tipoConsulta = this.solicitacao.tipoConsulta
        this.formularioAgenda.formaDeConsulta = this.solicitacao.tipoMarcacao
        this.formularioAgenda.date = this.solicitacao.dataReserva

      }
    }
    this.viewMarcaConsulta = true


  }

  async encaminharRecepcao() {
    this.popUp.emit(false)
    await localStorage.setItem('CPFpaciente', this.dataSelecionada.cpf)
    await localStorage.setItem('refAgenda', JSON.stringify(this.dataSelecionada))
    this.router.navigateByUrl(`home/Recepcao/${this.id}`)
  }

  reagendar(dados) {
    this.dataSelecionada = dados
    // this.dataSelecionada.start = ''
  }

  async exibirCalendario(ref, secundario?) {
    if (secundario) {
      this.viewCalendario_Secundario = ref
      return
    }
    let api = this.calendarComponent.getApi()
    this.displayEventTime = false
    switch (ref) {
      case 'hoje':
        api.today()
        await this.getDataCalendario()
        break;
      case 'mes':
        api.changeView('dayGridMonth')
        this.displayEventTime = true
        break;
      case 'semana':
        api.changeView('timeGridWeek')
        break
      case 'dia':
        api.changeView('timeGridDay')
        api.today()
        break
      case 'tarefa':
        api.changeView('listWeek')
        break
      case 'netx':
        await api.next()
        await this.getDataCalendario()
        break
      case 'return':
        await api.prev()
        await this.getDataCalendario()
        break
      case 'teste':
        console.log(api.view)
        break
    }


  }

  async getTodos(active) {
    this.viewTodos = true
    this.medico_selecView = null
    if (active) {
      await this.buscarAgendaMedica(true)
    } else {
      if (this.medico_selecionado) {
        this.buscarAgendaMedica(false, this.medico_selecionado)
      } else {
        this.registroAgenda = []
      }
    }
    console.log(active, this.registroAgenda)
  }

  sugerirNovoHorario(event) {
    if (this.solicitacao.id) {
      this.sair.emit(event)
    }
  }


  exit() {
    this.sair.emit(false)
  }

}
