import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Contato } from '../../service/contatos/contato.model';
import { Mensagem } from '../../service/historico-de-conversas/mensagem.model';
import { HistoricoDeConversasService } from '../../service/historico-de-conversas/historico-de-conversas.service';
import { DiarioDeAcessoService } from 'src/app/service/diario-de-acesso/diario-de-acesso.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { Observable, BehaviorSubject, fromEvent, } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { SalaEsperaForm } from 'src/app/atendimento-clinico/sala-espera/model';
import { VideoChamadaService } from 'src/app/service/video-chamada.service';
import { ArquivosService } from 'src/app/service/arquivos/arquivos.service';
import { CadastroUsuarioService } from 'src/app/service/cadastro-usuario/cadastro-usuario.service';
import { SIS_CONECTPES } from 'src/app/service/ficha-conexoes-profissionais/ficha-conexao-profissional.model';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { SessaoUser } from 'src/app/user.reg';
import { FichaConexoesProfissionaisService } from 'src/app/service/ficha-conexoes-profissionais/ficha-conexoes-profissionais.service';
import { ChatService } from 'src/app/service/conversas/chat.service';
import { CN_CONVERSAS, CN_CONVERSAS_JOIN, CN_MENSAGENS } from 'src/app/service/conversas/model';
import { MensagemService } from 'src/app/service/mensagem.service';
import { UploadArquivosService } from 'src/app/service/upload-arquivos.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
const sessao = new SessaoUser
@Component({
  selector: 'conversa',
  templateUrl: './conversa.component.html',
  styleUrls: ['./conversa.component.scss']
})
export class ConversaComponent implements OnInit, OnChanges {
  @Input() contatos: SIS_CONECTPES[] | any[] = [];
  @Input() contatosBackup: SIS_CONECTPES[] | any[] = [];
  @Input() loading: boolean;
  @ViewChild("ChatBody", { static: false }) ChatBody: ElementRef;
  cpfUsuario: string;
  destinatario = { cpf: '', nome: '' };
  historicoDeMensagens: CN_MENSAGENS[] = [];
  textoMensagem = "";
  arquivo: File;
  previewImagem: string | ArrayBuffer;
  chamada = false;
  infoVideoChamda;
  fotoUsuario = 'assets/imgUser.png'
  fotoConvidado = 'assets/imgUser.png'
  contatoSelecionado = new CN_CONVERSAS_JOIN

  viewListContato: boolean;
  conversas: [{ contato: CN_CONVERSAS_JOIN, mensagens: CN_MENSAGENS[], cont?: number }] = [{ contato: new CN_CONVERSAS_JOIN, mensagens: [] }];
  conversas_backup: [{ contato: CN_CONVERSAS_JOIN, mensagens: CN_MENSAGENS[], cont?: number }] = [{ contato: new CN_CONVERSAS_JOIN, mensagens: [] }];

  loadingConversas: boolean;
  porcentagemAnexo: number = 0;
  loadingCreatMensage: boolean;
  loadingDelete: boolean;
  contatosList = []
  constructor(
    private serviceConversas: HistoricoDeConversasService,
    private serviceDiarioAcesso: DiarioDeAcessoService,
    private serviceData: BuscarDataService,
    private serviceVideoChamada: VideoChamadaService,
    private serviceArquivos: ArquivosService,
    private serviceUsuario: CadastroUsuarioService,
    private _filter: FiltrarDadosService,
    private serviceConexoes: FichaConexoesProfissionaisService,
    private _chat: ChatService,
    private _mensagem: MensagemService,
    private _anexos: UploadArquivosService,
  ) { }

  async ngOnInit() {
    this.cpfUsuario = await this.serviceDiarioAcesso.cpfDestaSessao();
    this.getConversas()
  }

  ngOnChanges() {

  }




  async getConversas(select?) {
    this.conversas = [{ contato: new CN_CONVERSAS_JOIN, mensagens: [] }];
    this.conversas_backup = [{ contato: new CN_CONVERSAS_JOIN, mensagens: [] }];
    this.loadingConversas = true
    let conversas = await this._chat.getConversas(sessao.cpf)

    conversas.forEach(async conversa => {
      conversa.cpfFoto = conversa.criadoPor == sessao.cpf ? conversa.destinatario : conversa.criadoPor
      await this._filter.inserirFotoPerfilEach('cpfFoto', conversa)
      this.conversas.push({ contato: conversa, mensagens: await this.getMensagens(conversa, conversa) })
      this.conversas_backup.push({ contato: conversa, mensagens: await this.getMensagens(conversa) })

      if (select) {
        let getContato = this.conversas.filter(value => value.contato.conversaId == select)[0]
        if (getContato) {
          this.openChat(getContato)
        }
      }
    })


    this.loadingConversas = false
  }

  async getMensagens(mensagem: CN_CONVERSAS_JOIN, contato?) {
    let get = await this._chat.getMensagens(mensagem.conversaId)
    let getNaoLidas = get.filter(value => (value.status == 'SDO0143' && value.criadoPor != sessao.cpf))
    mensagem.naoLidas = getNaoLidas.length

    get = get.sort((a, b) => {
      let h1 = parseInt(a.criadoEm)
      let h2 = parseInt(b.criadoEm)
      return h1 < h2 ? 1 : 0
    })
    this.ordernar()




    // this.historicoDeMensagens = get.sort((a, b) => {
    //   let h1 = parseInt(a.criadoEm)
    //   let h2 = parseInt(b.criadoEm)
    //   return h1 < h2 ? 1 : 0
    // })

    return get
    // console.log(this.historicoDeMensagens)
    // this.scrollMax() 
  }
  
  ordernar() {
    this.conversas = this.conversas.sort((a, b) => {
      a.cont = a.contato.naoLidas
      b.cont = b.contato.naoLidas
      return b.cont - a.cont
    })
  }

  async creatConversas(contato: SIS_CONECTPES) {
    let conversa = new CN_CONVERSAS
    conversa.criadoPor = sessao.cpf
    conversa.destinatario = contato.cpfPessoa == sessao.cpf ? contato.criadoPor : contato.cpfPessoa
    console.log(contato, conversa)
    let result = await this._chat.creatConversa(conversa)
    this.viewListContato = false
    this.getConversas(result)
    console.log('key conversa', result)
  }

  async creatMensagem(textoMensagem: string, arquivo?: File) {

    if (!textoMensagem && !arquivo) {
      this._mensagem.creat('Digite uma mensagem antes de enviar', { aviso: true })
      return
    }
    let mensagem = new CN_MENSAGENS
    this.loadingCreatMensage = true
    mensagem.conversaId = this.contatoSelecionado.conversaId
    mensagem.criadoPor = sessao.cpf
    mensagem.remetente = sessao.cpf
    mensagem.destinatario = this.contatoSelecionado.criadoPor == sessao.cpf ? this.contatoSelecionado.destinatario : this.contatoSelecionado.criadoPor
    mensagem.mensagem = textoMensagem
    mensagem.status = 'SDO0143'

    let keyMensage = await this._chat.creatMensagem(mensagem)
    if (arquivo) {
      this.porcentagemAnexo = 10
      console.log('ENVIAR ARQUIVO', arquivo, this.arquivo)
      let formData = new FormData()
      formData.append('arquivo', arquivo[0])
      this._chat.upload(formData, keyMensage).subscribe(
        async event => {

          if (event.type === HttpEventType.UploadProgress) {
            this.porcentagemAnexo = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            let caminho = event.body[0].caminho
            let nameArquivo = event.body[0].nomeArquivo
            if (!mensagem.mensagem) mensagem.mensagem = nameArquivo
            mensagem.id = keyMensage
            mensagem.anexo = caminho
            mensagem.nomeArquivo = nameArquivo
            await this._chat.editMensagem(mensagem)

            this.porcentagemAnexo = 0
            this.cancelarEnvio()
          }
        },
        error => {
          console.log('ERRO!', error)
        }
      );
      // delete this.arquivo;
      // delete this.previewImagem;
    }
    this.loadingCreatMensage = false
    this.textoMensagem = null
    // this.getMensagens(this.contatoSelecionado)
  }
  async excluirConversa(mensagem: { contato: CN_CONVERSAS_JOIN, mensagens: CN_MENSAGENS[], cont?: number }) {
    console.log('excluir', mensagem)
    this.loadingDelete = true
    let response = await this._chat.excluirConversa(mensagem)
    this.loadingDelete = false
    if (response) {
      console.log('return', response)
      this.getConversas()

      this.contatoSelecionado = new CN_CONVERSAS_JOIN
    }
  }
  baixarAnexo(mensagem: CN_MENSAGENS) {
    console.log('baixar',mensagem)
    return this._anexos.download(mensagem.nomeArquivo, mensagem.anexo)
  }

  verifificarTipoImagem(nomeArquivo: string): boolean {
    if (!nomeArquivo) return false
    return nomeArquivo.includes('png') || nomeArquivo.includes('jpg') || nomeArquivo.includes('jpeg');
  }

  receberArquivo(arquivo) {
    this.arquivo = arquivo;
    console.log('arquivo', this.arquivo[0])
    const reader = new FileReader();
    reader.readAsDataURL(arquivo[0]);
    reader.onload = e => this.previewImagem = reader.result;
  }

  cancelarEnvio() {
    delete this.arquivo;
    delete this.previewImagem;
  }
  openListContatos() {
    console.log('*********CONTATO',this.contatosBackup,this.conversas)
    this.viewListContato = true
    if (this.contatos.length > 0 && this.conversas.length > 0) {
      let getContatos = []
      this.contatos = this.contatosBackup
      this.contatos.forEach(contato => {
        let verifc = this.conversas.findIndex(value => {
          return  contato.cpfActive == value.contato.cpfFoto
        })
        if (verifc < 0) {
          getContatos.push(contato)
        }
      })
      this.contatosList = getContatos.filter(value=> value.cpfFoto != sessao.cpf)
    }

    console.log(this.contatos.length, this.conversas.length)
  }

  async openChat(mensagem) {
    this.contatoSelecionado = mensagem.contato;
    this.historicoDeMensagens = mensagem.mensagens;
    this.ObservarNovaMensagem()
    this.fotoUsuario = await this._filter.inserirFotoPerfilEach('cpf', { cpf: sessao.cpf }, true)
    this.fotoConvidado = await this._filter.inserirFotoPerfilEach(mensagem.contato.criadoPor == sessao.cpf ? 'destinatario' : 'criadoPor', mensagem.contato, true)

    this.marcarMensagensLidas()
    this.scrollMax()
  }

  ObservarNovaMensagem() {
    const notificacoesFireBase = this.serviceConversas.observarFirebase(this.contatoSelecionado.conversaId);
    notificacoesFireBase.subscribe(async () => {
      this.historicoDeMensagens = await this.getMensagens(this.contatoSelecionado);
      this.marcarMensagensLidas()
      this.scrollMax()
    })
  }

  marcarMensagensLidas() {
    let mensagensNaoLidas = this.historicoDeMensagens.filter(value => value.status == 'SDO0143' && value.destinatario == sessao.cpf)
    if (mensagensNaoLidas.length < 1) return
    mensagensNaoLidas.forEach(async mensagem => {
      mensagem.status = 'SDO0144'
      this._chat.editMensagem(mensagem)
    });
  }

  videochamada() {
    const formSalaDeEspera = new SalaEsperaForm();
    formSalaDeEspera.cpf = this.cpfUsuario;
    formSalaDeEspera.paciente = this.destinatario.nome;
    this.serviceVideoChamada.criarSala(formSalaDeEspera).then(
      (reg) => {
        localStorage.setItem('keyVC', reg.id)
        const registro = {
          codigoUnico: reg.id,
          remetente: this.cpfUsuario,
          destinatario: this.destinatario.cpf,
          data: this.serviceData.hoje(),
          status: 'pendente',
          transacao: 'AJ00000'
        }
        this.chamada = true
        this.infoVideoChamda = registro
        this.serviceVideoChamada.registrar(registro).toPromise().then(
          () => { } //this.toast.info('Convite para video chamada enviado')
        )
        // this.loading = false
      }
    )
  }

  async enviarArquivo(arquivo: File) {
    const dadosArquivo = arquivo ? await this.serviceArquivos.uploadArquivo({
      arquivo: arquivo,
      nrDocumento: this.contatoSelecionado.conversaId,
      path: 'path_conversa_usuario',
      tipoAnexo: 'TIPANE10',
      tipoDocumento: 'MSGUSU',
    }) : { nomeArquivo: '', caminho: '' };
    return dadosArquivo;
  }


  notificarQtdMensagens() {

  }
  filterContato(value: string) {
    this.contatos = this.contatosBackup
    let filter = this.contatos.filter(item => {
      return item.criadoPorNome.toLowerCase().includes(value.toLowerCase()) ? item.criadoPorNome.toLowerCase().includes(value.toLowerCase()) :
        item.nome.toLowerCase().includes(value.toLowerCase())
    })
    this.contatos = filter
  }
  filterConversa(value: string) {
    this.conversas = this.conversas_backup
    let filter = this.conversas.filter(item => {
      return item.contato.remetenteNome.toLowerCase().includes(value.toLowerCase()) ? item.contato.remetenteNome.toLowerCase().includes(value.toLowerCase()) :
        item.contato.destinatarioNome.toLowerCase().includes(value.toLowerCase())
    })
    this.contatos = filter
  }
  scrollMax() {
    let get = this.ChatBody.nativeElement
    get.scrollTop = get.scrollHeight
  }
}
