import { Component, OnInit } from '@angular/core';
import { FormulariosService } from 'src/app/service/formularios.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UsuarioService } from 'src/app/service/usuario.service';
import { FormUsuario } from 'src/app/usuario/model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { ToastrService } from 'ngx-toastr';
import { FormCadastroEmpresa } from './model';
import { HubDevService } from 'src/app/service/hub-dev.service';

@Component({
  selector: 'app-cadastro-empresa',
  templateUrl: './cadastro-empresa.component.html',
  styleUrls: ['./cadastro-empresa.component.scss']
})
export class CadastroEmpresaComponent implements OnInit {
  estruturaFormulario: any;
  Formulario: FormGroup;
  usuarioId: any;
  companyId = localStorage.getItem('companyId')
  id: string;
  erroMensagem: string;
  loading: boolean;
  registro = new FormCadastroEmpresa
  constructor(
    private formularios: FormulariosService,
    private form: FormBuilder,
    private serviceUsuario: UsuarioService,
    private _location: Location,
    private router: Router,
    private url: ActivatedRoute,
    private serivceEmpresa: ConfiguracaoInicialService,
    private mensagem: ToastrService,
    private hub: HubDevService) {
    this.estruturaFormulario = this.formularios.formularioCadastroEmpresa()
    this.url.url.subscribe(async reg => {
      console.log('Filtro :' + reg[1].path)
      this.usuarioId = reg[1].path
    });
    this.id = localStorage.getItem('alterar-empresa')
    this.usuarioId = sessionStorage.getItem('tokken')
  }

  async  ngOnInit() {
    if (this.id) {
      this.serivceEmpresa.listarEmpresa(this.id).subscribe(
        (reg: FormCadastroEmpresa) => this.registro = reg
      )
    }
    this.loading = true
    await this.createForm()
    this.loading = false
  }
  voltar() {
    this.router.navigateByUrl(`home/CadastroEmpresa/${this.usuarioId}`)
  }
  cadastrar() {
    if (this.id) {
      console.log(this.Formulario.value)
      this.alterar()
      return
    }
    this.Formulario.controls['name'].setValue(this.Formulario.controls['consultorio'].value)
    console.log(this.Formulario.value)
    this.serivceEmpresa.cadastrarEmpresa(this.Formulario.value).toPromise()
      .then(
        (retorno) => {
          if (retorno) {
            this.mensagem.success('Cadastrado com sucesso!')
            this.router.navigateByUrl(`home/view-empresa/${this.usuarioId}`)
          }
          else {
            this.mensagem.error('Algo deu errado!')
          }
        }
      )
  }
  alterar() {
    this.serivceEmpresa.alterarEmpresa(this.id, this.registro).toPromise()
      .then(
        () => {
          this.mensagem.success('Alterado com sucesso!')
          this.router.navigateByUrl(`home/view-empresa/${this.usuarioId}`)
          localStorage.removeItem('alterar-empresa')
        }
      )
  }
  buscarCPNJ() {
    const cpnj = this.Formulario.controls['cnpj'].value
    this.hub.vCNPJ(cpnj).toPromise().then(
      (reg) => {
        console.log(reg.result.nome)
        this.registro['consultorio'] = reg.result.nome
        this.registro['logradouro'] = reg.result.logradouro
        this.registro['cep'] = reg.result.cep
        this.registro['bairro'] = reg.result.bairro
        this.registro['cidade'] = reg.result.municipio 
      }
    )
  }

  async createForm() {
    this.Formulario = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      name: new FormControl('', Validators.required),
      nomeResponsavel: new FormControl('', Validators.required),
      unidade: new FormControl('', Validators.required),
      consultorio: new FormControl('teste', Validators.required),
      companyType: new FormControl('', Validators.required),
      ativo: true,
      cnpj: new FormControl('', Validators.required),
      contrato: new FormControl('', Validators.required),
      logradouro: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      complemento: new FormControl('', Validators.required),
      cep: new FormControl('', Validators.required),
      bairro: new FormControl('', Validators.required),
      cidade: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      tipo: new FormControl('', Validators.required),
      planoContas: new FormControl('', Validators.required),
      areaCustos: new FormControl('', Validators.required),
      centroCustos: new FormControl('', Validators.required),
      organizaVendas: new FormControl('', Validators.required),
      centroVendas: new FormControl('', Validators.required),
      recepcao: new FormControl('', Validators.required),
      especialidade: new FormControl('', Validators.required),
      cpf: new FormControl('', Validators.required),
      crm: new FormControl('', Validators.required),
      celular: new FormControl('', Validators.required),
      logradouroRes: new FormControl('', Validators.required),
      numeroRes: new FormControl('', Validators.required),
      complementoRes: new FormControl('', Validators.required),
      cepRes: new FormControl('', Validators.required),
      bairroRes: new FormControl('', Validators.required),
      cidadeRes: new FormControl('', Validators.required),
      estadoRes: new FormControl('', Validators.required),
      endereco: new FormControl('', Validators.required),
    })

  }

}
