import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TesteService {
  url = 'http://localhost:3000/empresa/'
  constructor(private http: HttpClient) { }

  ler() {
    return this.http.get(this.url)
  }

  criar(registro) {
    return this.http.post(this.url, registro)
  }

  alterar(id, registro) {
    return this.http.put(this.url + id, registro)
  }

  excluir(id) {
    return this.http.delete(this.url + id)
  }
}
