import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { TabelaMenu } from 'src/app/tabela/model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pesquisar',
  templateUrl: './pesquisar.component.html',
  styleUrls: ['./pesquisar.component.scss']
})
export class PesquisarComponent implements OnInit {
  @Input() menu
  @Input() usuarioId
  @Output('popUp') popUp = new EventEmitter()
  displayedColumns: string[] = ['menuName'];
  dataSource: MatTableDataSource<TabelaMenu>;
  constructor(private router: Router, private toast : ToastrService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.menu);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  async go(url) {
    let semRota = false
    await this.router.navigateByUrl(`home/${url}/${this.usuarioId}`).catch(
      () => {
        semRota = true
        this.toast.info('Rota não definida , por favor ajuste no Dicionário de Dados')
      }
    )
    if(!semRota){
      this.popUp.emit(false)
    }
   
  }
}
