import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BuscarDataService } from './buscar-data.service';
import { TicketSenhaAtendimento } from '../home/monitor-clinico/consultas/solicitacoes/modal';
import { SessaoUser } from '../user.reg';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class SenhaAtendimentoService {
  getConsultas(codigoEmpresa: number) {
    throw new Error('Method not implemented.');
  }

  url = new environment().api
  api = 'TicketSenhaAtendimento/'
  constructor(private http: HttpClient) { }
  //************************** Sala de Espera **********************************
  async listarTicketSenhaAtendimento(codigoEmpresa) {
    let result: TicketSenhaAtendimento[] = []
    await this.http.get<TicketSenhaAtendimento[]>(this.url + this.api + 'JoinFiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
      .then(res => result = res ? res : [])
    return result
  }
  async getTicket(id) {
    let result = new TicketSenhaAtendimento
    await this.http.get<TicketSenhaAtendimento>(this.url + this.api + id).toPromise()
      .then(res => {
        result = res
      })
    return result
  }
  cadastrarTicketSenhaAtendimento(dados: TicketSenhaAtendimento) {
    console.log('TicketSenhaAtendimento-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarTicketSenhaAtendimento(id: string, dados) {
    console.log('TicketSenhaAtendimento-Alterar :', this.url + this.api + `${id}/${sessao.sessao_id}/${new Date().toISOString()}`, JSON.stringify(dados))
    return this.http.put(this.url + this.api + `${id}/${sessao.sessao_id}/${new Date().toISOString()}`, dados)
  }
  excluirTicketSenhaAtendimento(id: string, dados?) {
    console.log('TicketSenhaAtendimento-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
}
