import { camposFormularioSistema } from './FormulariosDoSistema.model';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SistemaClassificacaoDadosService } from '../../service/sistema-classificacao-dados.service';

@Component({
  selector: 'app-formularios-do-sistema',
  templateUrl: './formularios-do-sistema.component.html',
  styleUrls: ['./formularios-do-sistema.component.scss']
})
export class FormulariosDoSistema implements OnInit {

  idFormulario: string

  detalhes = false

  Formularios: camposFormularioSistema

  FormularioId: camposFormularioSistema

  camposFormulario: camposFormularioSistema = new camposFormularioSistema

  registroIcon
  galeriaIcon
  loading
  detalhe
  selecionado
  applyFilter
  buttonAdicionar
  id
  AlterarConteudoDados

  detalhesDados = {
    "id": String,
    "nomeFormulario": String,
    "ordemDeExibicao": Number,
    "sessao": String,
    "rotulo": String,
    "tipoFormulario": String
  }

  constructor(
    private mensagem: ToastrService,
    private routerCtrl: Router,
    private SistemaClassificacaoService: SistemaClassificacaoDadosService
  ) {

  }

  ngOnInit() {

    setInterval(() => {
      this.buscarFormulario()
    }, 2000)

  }

  buscarFormulario() {
    this.SistemaClassificacaoService.getFormulario().subscribe(
      (resultado => {
        this.Formularios = resultado
        //console.log("Sucesso: " + JSON.stringify(this.Formularios))
      }), (erro => {
        console.log("Erro: " + erro)
      }))
  }

  gravarDados() {
    this.SistemaClassificacaoService.postFormulario(this.camposFormulario).subscribe(
      (sucesso => {
        console.log("Salvo com sucesso: " + JSON.stringify(sucesso))
        this.mensagem.success('Gravado com Sucesso!')
      }), erro => {
        (console.log("Deu erro: " + erro))
      })

    this.camposFormulario = new camposFormularioSistema()
  }

  alterarFormulario() {
    this.SistemaClassificacaoService.putFormulario(this.idFormulario, this.FormularioId).subscribe(
      (sucesso => {
        console.log("ALTERADO COM SUCESSO: " + sucesso)
        this.mensagem.success('Alterado com Sucesso!')
        this.detalhes = false
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }

  buscarFormularioID() {
    console.log("ID: " + this.idFormulario)
    this.SistemaClassificacaoService.getFormularioId(this.idFormulario).subscribe(
      (sucesso => {
        this.FormularioId = sucesso
        console.log("Dados Api: " + JSON.stringify(sucesso))
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }

}

