import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assinatura',
  templateUrl: './assinatura.component.html',
  styleUrls: ['./assinatura.component.scss']
})
export class AssinaturaComponent implements OnInit {
  qtdRetangulos = 7;
  constructor() { }
  ngOnInit() {
  }

  retangulos(qtd, intervaloAngulo){
    const retangulos = [];
    let angulo = 0;
    for(let i = 0; i < qtd; i++){
      angulo = angulo + intervaloAngulo
      retangulos.push(angulo)
    }
    return retangulos;
  }
}
