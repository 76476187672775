import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatPaginator, MatSort,MatSnackBar, MatTableDataSource } from '@angular/material';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-lista-exames',
  templateUrl: './lista-exames.component.html',
  styleUrls: ['./lista-exames.component.scss']
})
export class ListaExamesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


displayedColumns = ['codigoReferencia', 'valor']

datasource
  
registrosTabela 

  constructor(
    private mensagem: MatSnackBar,
    private _form: FormBuilder,
    private service: ConteudoDeDadosService) { }

  ngOnInit() {
    this.GetRegistros()
  }

GetRegistros(){ 
    this.service.get('EXAMES').then(
      (registros: any) => {
        this.datasource = new MatTableDataSource(registros)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort
        this.registrosTabela = registros
      }
    ).catch((err) => {
      console.log(err.reponse)
    })
}
  
filtro(reg: string) {
   this.datasource.filter = reg.trim().toLowerCase();
 }  
}
