import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
const url = new environment().api
@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.scss']
})
export class ConfirmacaoComponent implements OnInit {

  public innerWidth: any;
  public mostraNoticias: boolean;
  colapsarMenu: boolean;
  mostraSintomas: boolean;
  mostraPrevencao: boolean;
  confirmEmail = true
  emailConfirmado = false

  nome: string;
  id: string;
  error: boolean;
  loading: boolean;


  constructor(private router: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {
    this.nome = this.router.snapshot.params.nome
    this.id = this.router.snapshot.params.id
    this.innerWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth;
    });
    this.mostraNoticias = true;
    console.log('KEY', this.nome, this.id)
  }
  async confirmarEmail() {
    this.loading = true
    this.error = false
    try {
      await this.http.get(url + 'Portal/ConfirmarEmail/' + this.id).toPromise()
        .then(res => {
          if (res) {
            this.confirmEmail = false
            this.emailConfirmado = true
          } else {
            this.error = true
          }
        }).catch(error => {
          this.error = true
          console.log(error)
        })
    } catch (error) {
      this.error = true
      console.log(error)
    }

    this.loading = false

  }


}
