import { Component, OnInit, NgModule, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { ConsultoriosMedicosService } from 'src/app/service/consultorios-medicos/consultorios-medicos.service';
import { StatusConsultorio } from 'src/app/service/consultorios-medicos/StatusConsultorio.class';
import { Consultorio } from 'src/app/service/consultorios-medicos/consultorio.model';
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model'
import { AreaAtuacaoEspecialidadeService } from 'src/app/service/area-atuacao-especialidade/area-atuacao-especialidade.service'
import { AreaAtuacao } from 'src/app/service/area-atuacao-especialidade/AreaAtuacao.model'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FichaGeralEmpresa } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { AreaAtuacaoService } from '../service/area-atuacao/area-atuacao.service';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { MensagemService } from '../service/mensagem.service';
import { SessaoUser } from '../user.reg';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { SistemaClassificacaoDadosService } from '../service/sistema-classificacao-dados.service';
import { BuscarDataService } from '../service/buscar-data.service';
import { ConsultorioMedico } from './modelsDefinirConsultorioMedico';
import { ListarMedicos, ListarMedicosService } from '../service/listar-medicos.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { FiltrarDadosService } from '../service/filtrar-dados.service';

const sessao = new SessaoUser

@NgModule({
  imports: [ModalModule.forRoot()],
})

@Component({
  selector: 'app-definir-consultorios-medicos',
  templateUrl: './definir-consultorios-medicos.component.html',
  styleUrls: ['./definir-consultorios-medicos.component.scss']
})
export class DefinirConsultoriosMedicosComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort
  @Input() atribuicao: boolean
  @Output() exit = new EventEmitter
  displayedColumns = ['detalhe', 'nomeEmpresa', 'identificacaoConsultorio', 'nomeCompleto', 'especialidadeProfissional', 'nr_Registro']
  displayedColumnsMedico = ['select', 'nomeCompleto', 'nr_Registro', 'valorEspecialidadeProfissional']
  dataSource = new MatTableDataSource()
  dataSourceMedicos = new MatTableDataSource()
  formulario: FormGroup
  registro = new ListarMedicos
  registro_doc = new ConsultorioMedico
  loadingOpenCad: boolean
  loading: boolean
  loadingReg: boolean
  viewCadastro: boolean

  empresas = []
  selecCheck: any;
  consultorio: string;
  selectEmpresa = 0
  listConsultorio: ConsultorioMedico[] = []
  alteracao: boolean;
  modificar: boolean;
  excluir: boolean;
  viewConfirmDelete: boolean;
  viewConfirmCancelar: boolean;
  loading_medico: boolean;
  listMedicos: ListarMedicos[];
  allMedicos: any[];
  viewWarningMedico: boolean;
  warningMedico: any
  confirmRealocar: boolean;
  constructor(
    private form: FormBuilder,
    private service: ConsultoriosMedicosService,
    private _medicos: ListarMedicosService,
    private _mensagem: MensagemService,
    private token: TokenDeAcessoService,
    private _conteudo: ConteudoDeDadosService,
    private _filter: FiltrarDadosService,
    private _date: BuscarDataService
  ) { }

  async ngOnInit() {
    this.getEmpresas()
    this.getRegistro()

  }
  openCadastro(registro: ConsultorioMedico, viewDetalhe?) {

    let codigoEmpresa = registro.codigoEmpresa
    console.log('empresa selc', registro)

    this.registro_doc = registro

    this.consultorio = this.registro_doc.identificacaoConsultorio

    this.getMedico(codigoEmpresa, registro.cpfMedico, viewDetalhe)

    this.alteracao = true
    this.excluir = false
    this.modificar = false
    this.viewCadastro = true
    this.confirmRealocar = false
    this.viewWarningMedico = false
    this.warningMedico = null
  }
  openNewCadastro() {
    this.modificar = true
  }
  async getRegistro() {
    this.loadingReg = true
    let getEmpresas = []
    if (this.selectEmpresa > 0) {
      getEmpresas = await this.service.listaConsultorioEmpresa(this.selectEmpresa)
    } else {
      getEmpresas = await this.service.listaConsultorio()
      let listEmpresas = JSON.parse(sessao.listEmpresas)
      getEmpresas = this._filter.filterTodos(getEmpresas, listEmpresas)
    }
    console.log('GETEMPRESA', getEmpresas)
    this.dataSource.data = getEmpresas
    this.dataSource.sort = this.sort
    this.loadingReg = false
    this._filter.inserirFotoPerfil('cpfMedico', getEmpresas)

  }
  async getEmpresas() {
    let empresas = sessao.listEmpresas
    this.empresas = JSON.parse(empresas)
    if (this.atribuicao) {
      this.registro_doc.codigoEmpresa = sessao.codigoEmpresa
      this.getMedico(this.registro_doc.codigoEmpresa)
      let filter = this.empresas.filter(value => value.empresa == sessao.codigoEmpresa)
      this.empresas = filter
      this.alteracao = true
      console.log("empresa atribuicao", filter)
    }
    this.allMedicos = []
    for (let i = 0; i < this.empresas.length; i++) {
      let value = this.empresas[i]
      let get = await this.getMedicosTable(value.empresa)
      this.allMedicos.push(...get)
    }
    console.log('ALLMEDICOS', this.allMedicos)
  }
  async getMedico(codigoEmpresa, cpf?, viewDetalhe?) {
    this.loading_medico = true
    this.selecCheck = null
    this.registro = new ListarMedicos
    if (codigoEmpresa) {
      let result = await this._medicos.getMedicosEmpresa(codigoEmpresa)
      console.log(result)
      result.forEach((value, i) => value.id = i + 1)
      result.sort((a: any, b: any) => a.id - b.id)
      this.listMedicos = result
      if (!cpf && viewDetalhe) {
        this.loading_medico = false
        this.selectMedico(new ListarMedicos, false)
        return
      }
      if (cpf) {
        let filter = result.filter(x => x.cpfProfissional == cpf)[0]
        if (!filter) {
          this.loading_medico = false;
          this.dataSourceMedicos = new MatTableDataSource(this.listMedicos)
          console.log('PASSOU AQUI')
          return
        }
        console.log('CPF', result)
        this.dataSourceMedicos = new MatTableDataSource(result.filter(value => value.id != filter.id))
        this.selectMedico(filter, true)
      } else {
        let verficMedicos = []
        await result.forEach((data: any, i, self) => {
          let verificar = this.dataSource.data.findIndex((item: any) => item.cpfMedico == data.cpfProfissional)
          if (verificar < 0) {
            let valid = verficMedicos.findIndex(item => item.cpfProfissional == data.cpfProfissional)
            if (valid < 0) {
              verficMedicos.push(data)
            }
          }
        })
        if (this.dataSource.data.length < 1) {
          verficMedicos = result
        }
        this.dataSourceMedicos = new MatTableDataSource(verficMedicos)
      }
      this.loading_medico = false
    }
  }
  async getMedicosTable(codigoEmpresa) {
    let result = await this._medicos.getMedicosEmpresa(codigoEmpresa)
    result.forEach((value, i) => value.id = i + 1)
    result.sort((a: any, b: any) => a.id - b.id)
    return result
  }

  selectMedico(registro: ListarMedicos, checked) {
    console.log('SelectMedico', registro, checked)
    if (checked) {
      this.selecCheck = registro.id
      this.registro = registro
      this.dataSourceMedicos.data = this.dataSourceMedicos.data.filter((value: any) => value.id != registro.id)
      this.validarConsultorio()
    } else {
      this.selecCheck = null
      this.dataSourceMedicos = new MatTableDataSource(this.listMedicos)
      this.registro = new ListarMedicos
      this.viewWarningMedico = false
    }

    this.dataSourceMedicos.data.sort((a: any, b: any) => a.id - b.id)
    console.log(this.dataSourceMedicos.data.length)
  }
  validarConsultorio() {
    let compare = this.registro_doc
    let registro = this.registro
    let get = this.dataSource.data.filter((value: ConsultorioMedico) => value.codigoEmpresa == registro.codigoEmpresa && value.cpfMedico == registro.cpfProfissional)
    if (get.length > 0) {

      this.warningMedico = get[0]
      if (compare.cpfMedico != this.warningMedico.cpfMedico) {
        this.viewWarningMedico = true
      }
    } else {
      this.viewWarningMedico = false
    }
    console.log('validarConsultorio', get, compare)
  }
  async realocar(confirm) {
    console.log(this.warningMedico)
    let old: ConsultorioMedico = this.warningMedico
    let novo = this.registro_doc
    novo.cpfMedico = this.registro.cpfProfissional
    novo.status = "SCA0001"
    novo.alocadoDesde = this._date.getTIMESTAMP()

    old.status = 'SCA0002'
    old.alocadoAte = this._date.getTIMESTAMP()
    if (confirm) {
      console.log(this.warningMedico, this.registro_doc)
      this.confirmRealocar = true
      return
    }
    console.log('REALOCARBBB', old,)
    await this.service.EditarConsultorio(old.codConsultorio, sessao.sessao_id, new Date().toISOString(), old).toPromise()
      .then(() => {
        this.service.EditarConsultorio(novo.codConsultorio, sessao.sessao_id, new Date().toISOString(), novo).toPromise()
          .then(() => {
            this._mensagem.creat('Dado alterado com sucesso', { sucesso: true })
            this.alteracao = false
            this.modificar = false
            this.getRegistro()
          })

      })

    console.log(this.registro_doc)
  }
  noRealocar() {
    this.viewWarningMedico = false
    this.warningMedico = null
    this.confirmRealocar = false
    this.cancelarRegistro()
  }
  async inserir() {
    if (!this.consultorio) {
      this._mensagem.creat('Informe o nome do consultório', { erro: true })
      return
    }

    let dado = new ConsultorioMedico
    dado.codigoEmpresa = this.registro_doc.codigoEmpresa
    dado.status = 'SCA0001'
    dado.cpfMedico = this.registro.cpfProfissional
    dado.identificacaoConsultorio = this.consultorio
    console.log(dado)
    await this.service.postCadastrarConsultorio(dado).toPromise()
      .then((res: any) => {
        console.log('res!', res)
        // this.token.criarToken()
        this.reset()
        this._mensagem.creat('Dado Salvo com sucesso', { sucesso: true })
      })
      .catch(error => console.log(error))

    this.getRegistro()
  }
  alterar() {
    if (this.confirmRealocar) {
      this.realocar(false)
    }
    if (this.viewWarningMedico && !this.confirmRealocar) {
      this._mensagem.creat('Informe se deseja ralocar o Médico', { aviso: true })
      return
    }
    var registro: ConsultorioMedico = this.registro_doc
    registro.identificacaoConsultorio = this.consultorio
    registro.codigoEmpresa = this.registro_doc.codigoEmpresa
    // registro.status = 'SDO0017'
    registro.cpfMedico = this.registro.cpfProfissional
    console.log(registro.codConsultorio, sessao.sessao_id, new Date().toISOString(), registro)
    this.service.EditarConsultorio(registro.codConsultorio, sessao.sessao_id, new Date().toISOString(), registro).toPromise()
      .then(res => {
        this._mensagem.creat('Dado alterado com sucesso', { sucesso: true })
        this.alteracao = false
        this.modificar = false
        this.getRegistro()
      })
      .catch(erro => console.log('erro', erro))
  }
  alterarTable(value) {
    let registros: any = this.dataSource.data
    let item = this.allMedicos.filter(v => v.id = value)[0]

    registros.array.forEach(value => {
      if (item.cpfMedico == value && item.codigoEmpresa == item.codigoEmpresa) {
        registros[index].cpfMedico = value.cpfMedico
        registros[index].nomeCompleto = value.nomeCompleto
        registros[index].especialidadeProfissional = value.especialidadeProfissional
        registros[index].nr_Registro = value.nr_Registro
        console.log(registros)
      }

    })

    let index = registros.findIndex(item => item.cpfMedico == value && item.codigoEmpresa == item.codigoEmpresa)

    if (index >= 0) {
      registros[index].cpfMedico = null
      registros[index].nomeCompleto = null
      registros[index].especialidadeProfissional = null
      registros[index].nr_Registro = null
    }
    this.dataSource.data = registros
    console.log('verifc', this.dataSource.data)
  }
  excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    var registro = this.registro_doc
    registro.status = "SDO0063"
    this.service.EditarConsultorio(registro.codConsultorio, sessao.sessao_id, new Date().toISOString(), registro).toPromise()
      .then(res => {
        this._mensagem.creat('Dado excluido com sucesso', { sucesso: true })
        this.alteracao = false
        this.modificar = false
        this.reset()
        this.getRegistro()
      })
      .catch(erro => console.log('erro', erro))
  }
  cancelarRegistro(confirm?) {
    console.log('confirm', confirm)
    if (confirm) {
      this.viewConfirmCancelar = true
      return
    }
    let id = this.registro_doc.codConsultorio
    this.service.buscarConsultorioID(id).toPromise()
      .then((res: any) => {
        this.registro_doc = res
        this.openCadastro(this.registro_doc)
        this.viewConfirmCancelar = false
      }).catch(error => {
        console.log('ERRO', error)
        this._mensagem.creat('Falha ao recuperar registro', { erro: true })
      })
  }

  fecharCadastro() {
    this.viewCadastro = false
    this.reset()
  }
  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }
  reset(open?) {
    this.registro_doc = new ConsultorioMedico
    this.registro = new ListarMedicos
    this.selecCheck = null
    this.viewCadastro = false
    this.dataSourceMedicos.data = []
    this.consultorio = null
    this.alteracao = false
    if (open) {
      this.viewCadastro = true
      this.modificar = true
    }
  }
}
