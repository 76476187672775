import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-privado',
  templateUrl: './portal-privado.component.html',
  styleUrls: ['./portal-privado.component.scss']
})
export class PortalPrivadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
