import { ViewChild } from '@angular/core';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import * as Chart from 'chart.js';
import { ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { reduce } from 'rxjs/operators';
import { ConsultorioMedico } from '../definir-consultorios-medicos/modelsDefinirConsultorioMedico';
import { BuscarDataService } from '../service/buscar-data.service';
import { ConsultoriosMedicosService } from '../service/consultorios-medicos/consultorios-medicos.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { CronometroService } from '../service/cronometro.service';
import { DiarioDeAcessoService } from '../service/diario-de-acesso/diario-de-acesso.service';
import { FichaConexaoProfissionalJOIN } from '../service/ficha-conexoes-profissionais/ficha-conexao-profissional.model';
import { FichaConexoesProfissionaisService } from '../service/ficha-conexoes-profissionais/ficha-conexoes-profissionais.service';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { FichaGeralEmpresa } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { FotoPerfilService } from '../service/foto-perfil.service';
import { TicketsService } from '../service/tickets.service';
import { SessaoUser } from '../user.reg';
// declare const google: any;
// declare var google;
const sessao = new SessaoUser
@Component({
  selector: 'app-analitico',
  templateUrl: './analitico.component.html',
  styleUrls: ['./analitico.component.scss']
})
export class AnaliticoComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  public lineChartData = [
    { data: [], label: 'Series A' },
  ];
  public lineChartLabels: Label[]
  public lineChartOptions: (Chart.ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [],
      yAxes: [{
        type: 'linear',
        ticks: {
          beginAtZero: true
        },
      }]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgb(255 255 255 / 0%)',
      borderColor: 'rgb(72 202 229)',
      pointBackgroundColor: 'rgb(72 202 229)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(230 230 230)'
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  displayedColumns: string[] = ['nome', 'especialidade', 'crm', 'cpf', 'inicioDaSessao', 'fimDaSessao', 'tempoDeSessao', 'status'];
  displayedColumnsJornada: string[] = ['detalhe', 'nome', 'especialidade', 'crm', 'cpf', 'inicioDaSessao', 'fimDaSessao', 'tempoDeSessao'];
  // google maps zoom level
  zoom: number = 8;
  lat = -23.56279528829599
  lng = -46.65567186593021
  // initial center position for the map
  icon = "assets/icon/map.png"
  markers = []
  renderOptions = {
    suppressMarkers: true,
  }
  markerOptions = {
    origin: {
      icon: 'assets/icon/map.png',
    },
    destination: {
      icon: 'assets/icon/map_user.png',
    },
  }
  registroEmpresa = new FichaGeralEmpresa
  listConexoes: FichaConexaoProfissionalJOIN[] = [];
  listConexoes_backup: FichaConexaoProfissionalJOIN[] = [];
  previous: any;
  loading: boolean;
  filterNull: boolean;
  verific: any;

  hoje = new Date().toISOString()

  viewHistorico: boolean
  viewJornada: boolean;
  viewDetalheJornada: boolean;

  listHistorico = [];
  listJornada = [];
  listJornada_all = [];

  dataSource
  loadingHistorico: boolean;

  loadingJornada: boolean;
  diaSemana
  dia
  detalheJornada: any = [];
  detalheJornada_backup: any = [];
  indexMes: number;
  indexAno: number;
  indexDia: number;
  mes: { name: string; } = { name: null };
  mes_jornada: { name: string; };
  indexAno_jornada: number;
  indexMes_jornada: any;
  coordenadaEmpresa: string;
  docLog
  docLogID: string;
  viewLocalLogin: boolean;
  viewInfo: boolean;
  listConexoes_contador = { todos: 0, alocados: 0, desligados: 0, conectados: 0 };
  infoEmpresa = []
  loading_doc: boolean;
  viewChart: boolean;
  mediaJorinada: any;
  fotoPerfilJornada: any;
  tableConsultorios: ConsultorioMedico[] = [];
  viewAtendimento: boolean;
  loadingAtendimento: boolean;
  cpf = sessao.cpf
  constructor(
    private _empresa: FichaGeralService,
    private _conexoes: FichaConexoesProfissionaisService,
    private _cronometro: CronometroService,
    private _conteudo: ConteudoDeDadosService,
    private _diario: DiarioDeAcessoService,
    private _data: BuscarDataService,
    private _filter: FiltrarDadosService,
    private _consultorio: ConsultoriosMedicosService,
    private _ticket: TicketsService) { }

  async ngOnInit() {

    this.indexMes = new Date().getMonth()
    this.indexAno = new Date().getFullYear()
    this.indexDia = new Date().getDay()
    this.indexMes_jornada = new Date().getMonth()
    this.indexAno_jornada = new Date().getFullYear()
    this.mes_jornada = this._data.getMeses(this.indexMes_jornada)
    this.diaSemana = this._data.getSemanas(this.indexDia)
    this.dia = new Date().getDate()
    this.mes = this._data.getMeses(this.indexMes)
    this.loading = true
    let registro = await this._conexoes.getConexoes()
    this.getDados()
    console.log('CONEXOES', registro)
    this.infoEmpresa = registro
    this.loading = false
  }
  ngOnDestroy() {
    clearInterval(this.verific)
  }
  async getDados() {
    // let empresas = await this._empresa.getEmpresaAssiante(sessao.assinante)
    let empresas = await this._empresa.getEmpresaAssiante(sessao.assinante)
    empresas.forEach(item => {
      let lat = item.coordenadaGps.split(',')[0]
      let long = item.coordenadaGps.split(',')[1]
      let cordenadas = {
        lat: parseFloat(lat),
        lng: parseFloat(long),
        draggable: false,
        empresa: item
      }
      console.log('EMPRESA', empresas)
      this.markers.push(cordenadas)

    });
  }
  async filterJornada(data) {
    let registro = []
    this.listJornada_all.forEach(value => {
      let filter = value.time.filter(item => {
        return new Date(item.inicioDaSessao).toLocaleDateString() == new Date(data).toLocaleDateString()
      })
      if (filter.length > 0) {
        value.inicioDaSessao = filter[0].inicioDaSessao
        value.fimDaSessao = filter[0].fimDaSessao
        value.tempoDeSessao = this._cronometro.diferencaHorario(value.inicioDaSessao, value.fimDaSessao)
      } else {
        value.inicioDaSessao = null
        value.fimDaSessao = null
        value.tempoDeSessao = null
      }
      registro.push(value)
    })
    console.log('registro > ', registro)
    this.listJornada = registro
    this.dataSource.data = registro
    this.coordenadaEmpresa = registro[0].coordenadaEmpresa
  }
  async filterJornadaUsuario(todos, date) {
    let getRegistro
    this.detalheJornada_backup = JSON.parse(sessionStorage.getItem('detalheJornada_backup'))
    if (todos) {
      // this.detalheJornada = this.detalheJornada_backup
      // this.detalheJornada.fotoPerfil = this.fotoPerfilJornada
    } else {
      // filter = this.detalheJornada_backup.diarioAcesso.filter(value => {
      //   return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) == new Date(value.inicioDaSessao).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      // })
      let cont = []
      let acumulado = '00:00'
      getRegistro = this.detalheJornada_backup.diarioAcesso
        .filter(value => new Date(date).getFullYear() == new Date(value.inicioDaSessao).getFullYear())
        .filter(value => new Date(date).getMonth() == new Date(value.inicioDaSessao).getMonth())
        .sort((a, b) => { return <any>new Date(a.inicioDaSessao) - <any>new Date(b.inicioDaSessao) })
        .filter((value, i, self) => {
          let date = self.map(v => v.inicioDaSessao.split("T")[0]);
          // console.log(date);
          if (i != date.indexOf(value.inicioDaSessao.split("T")[0])) {
            cont.push(value);
            cont.push(self[i - 1]);
            let total;
            cont.forEach((value, index, self) => {
              let max = self.length - 1;
              let inicio = value.tempoDeSessao
              let fim = self[max].tempoDeSessao
              total = this._cronometro.calcularTime([inicio, fim]);
              // console.log('inicio', inicio, self, index)
              // console.log('fim', fim)
              if (index < 1) {
                self[index].total = total
                self[index].totalDia = true
                // console.log('SELF', self[index - 1], max)
              } else {
                self[index].totalDia = false
              }
              // console.log('total >', total, index)
            });
            cont = []
          } else {
            value.total = value.tempoDeSessao
            value.totalDia = true
          }
          return value
        })
        .filter(value => {
          let getTotal = cont.filter(v => v.id == value.id)
          if (getTotal.length > 0) {
            value = getTotal[0]
          }
          return value
        })
        .map(value => {
          let inicio = value.tempoDeSessao
          value.totalPeriodo = this._cronometro.calcularTime([inicio, acumulado]);
          acumulado = value.totalPeriodo
          // console.log('acumulado', acumulado, inicio)
          return value
        }).map(value => {
          try {

            let getLat_empresa = this.coordenadaEmpresa.split(',')[0].split('-').join('').split('.').join('')
            let getLong_empresa = this.coordenadaEmpresa.split(',')[1].split('-').join('').split('.').join('')

            let getLat_user = value.coordenadaGps.split(',')[0].split('-').join('').split('.').join('')
            let getLong_user = value.coordenadaGps.split(',')[1].split('-').join('').split('.').join('')

            const vlatitude = parseFloat(getLat_empresa) - parseFloat(getLat_user)
            const vlongitude = parseFloat(getLong_empresa) - parseFloat(getLong_user)
            if (vlatitude < 10000 && vlongitude < 10000 || vlatitude < -10000 && vlongitude < 10000) {
              console.log('passou', vlatitude, vlongitude)
              value.statusCoo = true
            } else {
              console.log('não passou', vlatitude, vlongitude)
              value.statusCoo = false
            }
            value.cooUser = { lat: parseFloat(value.coordenadaGps.split(',')[0]), lng: parseFloat(value.coordenadaGps.split(',')[1]) };
            value.coorEmpresa = { lat: parseFloat(this.coordenadaEmpresa.split(',')[0]), lng: parseFloat(this.coordenadaEmpresa.split(',')[1]) };
            console.log('coordenadas empresa >', value)

          } catch {

          }





          return value
        })
      // console.log('RESULT!', filter)
      // console.log('CONT', cont)
      if (getRegistro.length > 0) {
        let totalJornada = getRegistro[getRegistro.length - 1].totalPeriodo.replace(':', '.')
        let totalDias = getRegistro.map(value => new Date(value.inicioDaSessao).toLocaleDateString()).filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        }).length
        this.mediaJorinada = parseFloat(totalJornada) / totalDias
        console.log('mediaJorinada', this.mediaJorinada, totalJornada, totalDias)
      } else {
        this.mediaJorinada = 0
      }
      this.detalheJornada.diarioAcesso = getRegistro


      if (this.viewChart) {
        this.openChart(true)
      }
    }

    console.log('detalheJornada_backup', this.detalheJornada)
  }

  cronometro() {
    this.verific = setInterval(() => {
      let agora = new Date().toISOString()
      if (this.listConexoes.length < 1) return
      this.listConexoes.forEach(value => {
        let diferc = this._cronometro.diferencaHorario(value.inicioSessao, agora)
        value.timeLogin = diferc.toString()
      });
    }, 1000)

    this.verific
  }
  mouseOut(infowindow) {
    if (this.listConexoes.length > 0) return
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow
    this.previous.close();
    this.previous = null

  }
  async mouseOver(infowindow, item) {
    if (this.loading) return


    if (this.previous) {
      this.previous.close();
      console.log('close',)
    }
    this.previous = infowindow;
    this.previous.open()

    let empresa: FichaGeralEmpresa = item.empresa
    this.viewInfo = true


  }

  async selectMarker(item) {
    this.registroEmpresa = item.empresa
    this.listConexoes = []
    this.loading_doc = true
    this.viewHistorico = false
    this.viewJornada = false
    let registro = this.infoEmpresa.filter(value => value.codigoEmpresa == item.empresa.codigoEmpresa)
    console.log('------------', item)
    this._filter.inserirFotoPerfil('cpfPessoa', registro)
    this.listConexoes = registro
    this.listConexoes_backup = registro

    this.listConexoes_contador = {
      todos: registro.length,
      alocados: registro.filter(value => value.statusConexoesProfissionais == 'SDO0100' && !value.usuarioLogado).length,
      desligados: registro.filter(value => value.statusConexoesProfissionais == 'SDO0102' && !value.usuarioLogado).length,
      conectados: registro.filter(value => value.usuarioLogado).length
    }

    this.cronometro()
    console.log('registro end >', this.listConexoes)
    this.loading_doc = false
  }

  closeRegistro() {
    // this.viewHistorico = false
    // this.viewJornada = false
    if (this.previous) {
      this.previous.close();
      this.previous = null
      console.log(this.previous)
      this.registroEmpresa = new FichaGeralEmpresa
      this.loadingHistorico = false
      this.loadingJornada = false
      this.loadingAtendimento = false
    }
    clearInterval(this.verific)
  }

  closeDetalheJornada() {
    this.viewDetalheJornada = false
    this.listConexoes = [];
    setTimeout(() => this.viewLocalLogin = false, 1000)
  }



  mapClicked($event: any) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
  }

  markerDragEnd(m, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  onMouseOver(infoWindow, gm) {
    infoWindow.close()
  }

  testeSorte() {
    console.log(this.dataSource.sort)
  }

  async openHistorico(codigoEmpresa) {
    this.loadingHistorico = true
    this.listHistorico = await this._diario.getRegistro(codigoEmpresa)
    this.dataSource = new MatTableDataSource(this.listHistorico)
    this.viewHistorico = true
    this.loadingHistorico = false

    setTimeout(() => this.dataSource.sort = this.sort, 200)
  }

  async openJornada(codigoEmpresa, jornada?) {
    this.loadingJornada = true
    let registro = await this._diario.getRegistro(codigoEmpresa, true)
    this.listJornada_all = registro

    this.listJornada = registro
    console.log(this.listJornada)
    this.listJornada.forEach(item => {
      let getRegistro: any = this.listConexoes_backup.filter(value => value.cpfProfissional == item.cpf)
      if (getRegistro.length > 0) {
        item.fotoPerfil = getRegistro[0].fotoPerfil
      }
      if (item.inicioDaSessao && item.fimDaSessao) {
        item.totalTime = this._cronometro.diferencaHorario(item.inicioDaSessao, item.fimDaSessao)
      }
    });
    this.dataSource = new MatTableDataSource(this.listJornada)
    this.viewJornada = true
    this.loadingJornada = false

    this.filterJornada(new Date().toISOString())

    setTimeout(() => this.dataSource.sort = this.sort, 200)
  }

  async openAtendimento(codigoEmpresa) {
    this.tableConsultorios = []
    this.loadingAtendimento = true
    this.viewAtendimento = true
    this.tableConsultorios = await this._consultorio.listaConsultorioEmpresa(codigoEmpresa)
    if (this.tableConsultorios.length > 0) {
      for (let i = 0; i < this.tableConsultorios.length; i++) {
        let item = this.tableConsultorios[i]
        let ticketConsuta = await this._ticket.getAtendimentoConsultura(item.codigo)

        if (ticketConsuta.length > 0) {
          item.atendimento = true
          item.nomePaciente = ticketConsuta[0].nomeCompleto
          item.cpfPaciente = ticketConsuta[0].cpfPaciente
        } else {
          item.atendimento = false
        }
      }
      this.tableConsultorios = this.tableConsultorios.sort((a, b) => Number(a.atendimento) - Number(b.atendimento))
    }

    console.log('viewAtendimento', this.tableConsultorios)
    this.loadingAtendimento = false


  }

  openDetalheJornada(item) {
    this.viewDetalheJornada = true
    this.detalheJornada = item
    sessionStorage.setItem('detalheJornada_backup', JSON.stringify(item))
    console.log('jornada', this.detalheJornada)
    this.fotoPerfilJornada = item.fotoPerfil
    this.filterCalnedarioJornada('hoje')
  }
  openLocalLogin(item) {
    this.docLog = item
    this.docLogID = item.id
    this.viewLocalLogin = true
    console.log(this.docLog)
  }
  openChart(filter?) {
    if (this.viewChart && !filter) {
      this.viewChart = false
      return
    }
    this.lineChartLabels = []
    this.lineChartData = [{
      data: [], label: 'Horário'
    }]
    let registro = this.detalheJornada.diarioAcesso
    registro.filter(value => value.totalDia).forEach(item => {
      try {
        let time = item.total.replace(':', '.')
        this.lineChartLabels.push(new Date(item.inicioDaSessao).toLocaleDateString())
        this.lineChartData[0].data.push({
          x: new Date(item.inicioDaSessao),
          y: time
        })
      } catch { }

    });
    console.log('CHART', this.lineChartLabels, this.lineChartData)
    this.viewChart = true
  }

  teste(date) { console.log(date) }

  filterRegistro(key) {
    key = key.toLocaleLowerCase()
    let registro = this.listConexoes_backup
    let filter =
      registro.filter(value => value.nomeCompleto.toLocaleLowerCase().includes(key))
    if (filter.length > 0) {
      this.listConexoes = filter
    } else {
      this.listConexoes = this.listConexoes_backup
    }
  }
  filterCalendario(execut, index?) {

    let numMes = this.indexMes

    let ultimoDia = new Date(2020, numMes + 1, 0).getDate()

    console.log('MES', 2020, numMes, this.indexMes)
    switch (execut) {
      case 'next':
        if (index >= 7) { index = 1 }
        else index += + 1
        this.dia += 1
        // this.mes = this._data.getMeses(index)
        break;
      case 'return':
        if (index == 1) { index = 7 }
        else index += - 1
        this.dia += - 1

        // this.mes = this._data.getMeses(index)
        break;
      case 'hoje':
        this.indexAno = new Date().getFullYear()
        index = new Date().getDay()
        this.dia = new Date().getDate()
        this.indexMes = new Date().getMonth()
        this.mes = this._data.getMeses(this.indexMes)
        // index = 1
        // this.dia = 1
        // this.indexMes = new Date().getMonth()
        break;

      default:
        break;

    }
    if (this.dia == 0 || this.dia > ultimoDia) {

      if (this.dia < 1) {
        if (this.indexMes <= 1) this.indexMes = 11
        else {
          this.indexMes += -1

        }
        ultimoDia = new Date(2020, this.indexMes + 1, 0).getDate()
        this.dia = ultimoDia;
        this.mes = this._data.getMeses(this.indexMes)
      }
      if (this.dia > ultimoDia) {
        this.dia = 1
        if (this.indexMes >= 11) this.indexMes = 0
        else { this.indexMes += +1 }
        this.mes = this._data.getMeses(this.indexMes)
      }
      let semana = new Date(2020, numMes, ultimoDia).getDay()
      this.diaSemana = this._data.getSemanas(semana)
      this.indexDia = semana

    } else {
      console.log('não passou!', index)
      this.diaSemana = this._data.getSemanas(index)
      this.indexDia = index
    }

    let date = `${new Date().getFullYear()}-${this.indexMes + 1}-${this.dia}`

    this.filterJornada(date)

  }
  filterCalnedarioJornada(execut, index?) {
    switch (execut) {
      case 'next':
        if (index == 11) { index = 0; this.indexAno_jornada += +1 }
        else index += + 1
        this.mes_jornada = this._data.getMeses(index)
        break;
      case 'return':
        if (index == 0) { index = 11; this.indexAno_jornada += -1 }
        else index += - 1
        this.mes_jornada = this._data.getMeses(index)
        break;
      case 'hoje':
        let mes = new Date().getMonth()
        this.indexAno_jornada = new Date().getFullYear()
        index = mes
        this.mes_jornada = this._data.getMeses(index)
        break;

      default:
        break;

    }

    this.indexMes_jornada = index
    let date = `${this.indexAno_jornada}-${this.indexMes_jornada + 1}-1`
    this.filterJornadaUsuario(false, date)

  }

  filterStatus(type) {
    this.listConexoes = this.listConexoes_backup
    switch (type) {
      case 'SDO0100':
        this.listConexoes = this.listConexoes.filter(value => value.statusConexoesProfissionais == type && !value.usuarioLogado)
        break;
      case 'SDO0102':
        this.listConexoes = this.listConexoes.filter(value => value.statusConexoesProfissionais == type && !value.usuarioLogado)
        break;
      case 'logado':
        this.listConexoes = this.listConexoes.filter(value => value.usuarioLogado)
        break;
      default:
        break;
    }
    if (this.listConexoes.length < 1) {
      this.listConexoes = this.listConexoes_backup
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
// just an interface for type safety.


