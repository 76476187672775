import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { CadastroCartaoCreditoService } from 'src/app/service/cadastro-cartao-credito.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { CadastroCartaoCreditoModel } from './cadastro-cartao-credito.model';

const sessao = new SessaoUser

@Component({
	selector: 'app-cadastro-cartao-credito',
	templateUrl: './cadastro-cartao-credito.component.html',
	styleUrls: ['./cadastro-cartao-credito.component.scss']
})
export class CadastroCartaoCreditoComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort

	displayedColumns = ['acao', 'nomeEmpresa', 'nomeTitular', 'operadora', 'numeroCartao', 'bandeira']
	viewCadastro: boolean
	formulario: FormGroup
	mask_validade = "00/0000"
	mask_numeroCartao = "0000 0000 0000 0000"
	listEmpresa = []
	listOperadora = []
	listBandeira = []
	listPaises: any
	listRegistro: CadastroCartaoCreditoModel[]
	alteracao = false
	botaoSalvar = false
	botaoCancelar = false
	selectEmpresa = 0
	dataSource = new MatTableDataSource()
	loading: boolean
	viewConfirmDelete: boolean
	mask_dinheiro = "0*,00"

	constructor(
		private service: CadastroCartaoCreditoService,
		private form: FormBuilder,
		private token: TokenDeAcessoService,
		private conteudo: ConteudoDeDadosService,
		private mensagem: MensagemService,
		private _filtro: FiltrarDadosService
	) { }

	async ngOnInit() {
		this.createForm()
		this.getEmpresa()
		this.getOperadora()
		this.getBandeira()
		this.getPaises()
		await this.getRegistro()
			.finally(() => this.filtrarEmpresa())
	}

	createForm() {
		this.formulario = this.form.group({
			id: '',
			pais: '',
			nomeTitular: '',
			bandeira: '',
			numeroCartao: '',
			dataVencimento: '',
			operadora: '',
			criadoPor: sessao.cpf,
			criadoEm: '',
			status: '',
			codigoEmpresa: 0,
			limiteCartao: ''
		})
	}

	adicionarCadastro() {
		this.alteracao = false
		this.viewCadastro = true
		this.botaoSalvar = true
		this.botaoCancelar = false
		this.formulario.enable()
		this.formulario.reset()
		this.formulario.controls['status'].setValue('SDO0060')
	}

	async getRegistro() {

		this.loading = true

		await this.service.listar().toPromise()
			.then(
				registro => {

					this.listRegistro = registro.filter(item => item.status == 'SDO0060')
					this.listRegistro.forEach(async item => {
						let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
						let bandeira = await this.conteudo.get('BANDCARD', true, item.bandeira)

						if (empresa.length > 0) {
							item.nomeEmpresa = empresa[0].nomeEmpresa
						}

						item.descBandeira = bandeira.toString()

					})
					this.dataSource.sort = this.sort
					this.loading = false

					console.log("getRegistro: ", registro)
				})
	}

	async openCadastro(registro) {
		console.log("Open: ", registro)

		// delete registro.nomeEmpresa
		delete registro.descBandeira
		this.formulario.patchValue(registro)

		localStorage.setItem('backup_registro', JSON.stringify(registro))
		await this.formulario.disable()
		this.viewCadastro = true
		this.alteracao = true
		this.botaoSalvar = false
		this.botaoCancelar = false
	}

	async getEmpresa() {
		let empresa = await this.token.getEmpresasToken()
		this.listEmpresa = empresa
	}

	async getOperadora() {
		let getOperadora = await this.conteudo.get('OPERCARD')
		this.listOperadora = getOperadora
	}

	async getBandeira() {
		let getBandeira = await this.conteudo.get('BANDCARD')
		this.listBandeira = getBandeira
	}

	async getPaises() {
		this.service.getPaises().subscribe(response => {
			this.listPaises = response
		})
	}

	inserir() {
		let registro = this.formulario.value

		delete registro.id
		delete registro.criadoEm

		registro.criadoPor = sessao.cpf

		this.service.enviar(registro).toPromise()
			.then(
				resposta => {
					this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
					this.viewCadastro = true
					this.formulario.disable()
					this.botaoSalvar = false
					this.botaoCancelar = false
				}
			).catch(
				error => {
					let erro = error.error
					this.mensagem.creat(erro, { erro: true })
				}
			)
	}

	alterar() {
		let registro = this.formulario.value

		this.service.alterar(registro).toPromise()
			.then(
				resposta => {
					this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
					this.viewCadastro = true
					this.formulario.disable()
				}).catch(
					error => {
						let erro = error.error
						this.mensagem.creat(erro, { erro: true })
					})
	}

	cancelarRegistro() {
		let get = localStorage.getItem('backup_registro')
		let registro = JSON.parse(get)
		this.formulario.setValue(registro)
		this.formulario.disable()
	}

	async excluirRegistro(confirm?) {
		if (confirm) {
			this.viewConfirmDelete = true
			return
		}

		this.viewConfirmDelete = false
		let registro = this.formulario.value
		if (!registro.id) return

		await this.service.desativar(registro, false)
		this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
		this.viewCadastro = false
		this.getRegistro()
			.finally(() => this.filtrarEmpresa())

	}

	async fecharRegistro() {
		this.viewCadastro = false
		await this.getRegistro()
			.finally(() => this.filtrarEmpresa())
	}

	filtrar(value) {
		this.dataSource.filter = value.toLocaleUpperCase().trim()
	}

	filtrarEmpresa() {
		if (this.selectEmpresa == 0) {
			let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
			this.dataSource.data = filter
			return
		}
		let codigoEmpresa = this.selectEmpresa
		let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
		this.dataSource.data = filter
	}
}
