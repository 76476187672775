import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContatosService {
  url = new environment().api
  api = 'CatalogoDeContatos/'
  constructor(private http: HttpClient) { }

  //************************** CatalogoDeContatos **********************************
  listarCatalogoDeContatos(id?) {
    console.log('CatalogoDeContatos :' + this.url + this.api)
    if (id) {
      return this.http.get(this.url + this.api + id)
    }
    return this.http.get(this.url + this.api)
  }
  cadastrarCatalogoDeContatos(dados: any) {
    console.log('CatalogoDeContatos-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarCatalogoDeContatos(id: string, dados) {
    console.log('CatalogoDeContatos-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirCatalogoDeContatos(id: string, dados?) {
    console.log('CatalogoDeContatos-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }

  //**************************END CatalogoDeContatos  **********************************

  buscarMedico() {
    return this.http.get(this.url+'DadosProfissionais/')
  }
  buscarPaciente() { 
    return this.http.get(this.url+'CadastroUsuario/')
  }
}
