import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CamposFichaGeralEmpresa } from "../ajustes/lista-precos-venda/Determinacao-Listaprecos";
import { CamposFichaGeralEmpresas } from "../ajustes/posicao-numeracao-nfe/campos-posicaoNfe";
import { dadosCadastroCentroCusto } from "../cadastro-centro-de-custo-completo/modelsCadastroCentroDeCustoCompleto";
import { DadosFichaGeralEmpresa } from "../configuracao-inicial/regras-de-imposto/model";
import { CadastroPrestadorServicoModel } from "../gestao-simplificada/cadastro-prestador-servico/cadastro-prestador-servico.model";
import { SessaoUser } from "../user.reg";
import { BuscarDataService } from "./buscar-data.service";
import { FichaGeralEmpresa } from "./ficha-geral-empresa/FichaGeralEmpresa.model";
import { MensagemService } from "./mensagem.service";


const sessao = new SessaoUser
const url = `${new environment().api}GS_CADPRESTSERV/`
@Injectable({
	providedIn: 'root'
})

export class CadastroPrestadorServicoService {

	url = new environment().api
	api = "GS_CADPRESTSERV/"
	apiCusto = "CadastroCentroCusto/"
	apiFicha = "FichaGeralEmpresa/"
	jsonBancos = './assets/json/bancos-de-todo-brasil.json'
	jsonPais = './assets/json/json-paises.json'

	constructor(
		private http: HttpClient,
		private data: BuscarDataService,
		private mensagem: MensagemService
	) { }

	listar() {
		return this.http.get<CadastroPrestadorServicoModel[]>(this.url + this.api)
	}

	enviar(registro: CadastroPrestadorServicoModel) {
		return this.http.post(this.url + this.api, registro)
	}

	alterar(registro: CadastroPrestadorServicoModel) {
		return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
	}

	async desativar(registro: CadastroPrestadorServicoModel, ativo?) {

		if (ativo) registro.status = 'SCA0005'
		else registro.status = 'SCA0006'

		let resposta
		await this.alterar(registro).toPromise()
			.then(res => { resposta = res })
			.catch(error => { console.log('error', error) })

		return resposta
	}


	listarCentroCusto(codigoEmpresa) {
		return this.http.get<dadosCadastroCentroCusto[]>(this.url + this.apiCusto + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
	}

	listarBancos(): Observable<any> {
		return this.http.get(this.jsonBancos)
	}

	listarPaises(): Observable<any> {
		return this.http.get(this.jsonPais)
	}

	async listarDadosCentroCusto(codigoEmpresa) {
		let registro = new dadosCadastroCentroCusto
		await this.http.get<dadosCadastroCentroCusto>(this.url + this.apiCusto + 'FiltrarCodigoEmpresa/' + codigoEmpresa).toPromise()
			.then((res) => {
				registro = res
			})
		return registro
	}



	async listarDadosEmpresa(codigoEmpresa) {
		let registro = new FichaGeralEmpresa
		await this.http.get<FichaGeralEmpresa>(this.url + this.apiFicha + `FiltrarCodigoEmpresa/${codigoEmpresa}`).toPromise()
			.then((res) => {
				registro = res
			})
		return registro
	}

	async getDadosEmpresa() {
		let registro = new FichaGeralEmpresa
		await this.http.get<FichaGeralEmpresa>(this.url + this.apiFicha).toPromise()
			.then((res) => {
				registro = res
			})
		return registro
	}


}