export class ConciliacaoBancariaModel {
    id: string
    criadoPor: string
    codigoContaBancaria: number
    pais: string
    banco: string
    agencia: string
    numeroConta: string
    endereco: string
    contatoGerente: string
    contaContabil: string
    codigoEmpresa: number
    status: string
    criadoEm: string
    ////
    nomeEmpresa?: string
    descBanco?: string
}
// periodo
export class FichaControleBancarioCabecalhoModel {
    id: string
    criadoPor: string
    codigoEmpresa: number
    codigoContaBancaria: number
    numeroDocumento: string
    periodoConciliacao: string
    dataDocumento: string
    criadoEm: string
    status: string
    // 
    descStatus?: string
    nomeEmpresa?: string
}
// extratos
export class FichaControleBancarioItemModel {
    id: string
    criadoPor: string
    codigoEmpresa: number
    diaConciliacao: string
    criadoEm: string
    status: string
    tipoLancamentoBancario: string
    descricaoLancamento: string
    valorLancamento: number
    numeroDocumento: string
    nrItem: number
    descTipo?: string
    nomeEmpresa?: string
}