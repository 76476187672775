import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import {  FormControl, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import {MatPaginator} from '@angular/material/paginator';
import {  ViewChild } from '@angular/core';
import { Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { CatalogoTabelasService } from 'src/app/service/catalogo-tabelas.service';
import { ConsultarTabelasService } from 'src/app/service/consultar-tabelas.service';
import { DicionarioDeDadosModel } from './modalDocionarioDeDados';
import { ClassificacaoDeDadosService } from 'src/app/service/classificacao-de-dados/classificacao-de-dados.service';

interface DetalheTabela {
 
  codigoTabela?:  string;
  descricao?:  string;
  tipoDeTabela?: string;
  classificacaoDeTabela?:  string;
  status?:  string 
  campos: string[]
}

@Component({
  selector: 'app-catalogo-tabelas',
  templateUrl: './catalogo-tabelas.component.html',
  styleUrls: ['./catalogo-tabelas.component.scss']
})
export class CatalogoTabelasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

displayedColumns = ['detalhe','codigoTabela', 'descricao', 'tipoDeTabela', 'classificacaoDeTabela', 'status']

tabelas: string[];

viewDetalhe: boolean;

detalhesTabela: DetalheTabela

modalRef: BsModalRef;

formulario : FormGroup

form = new DicionarioDeDadosModel

dataSource  


alteracao: boolean;

viewCadastro: boolean;

botaoModificar: boolean

modalAberto: boolean

registro

campos: string[];

registroTabela = []

valoresStatus

tabelasNaoCadastradas = []

tipoCarac = []

classificacaoCarac = []

dicionarioDeDados: Array<DicionarioDeDadosModel>

  constructor(
    private modalService: BsModalService,
    private consultarTabelaService: ConsultarTabelasService,
    private classificacaoService: ClassificacaoDeDadosService,
    private service: CatalogoTabelasService,
    private mensagem: MatSnackBar,
    private _form: FormBuilder,
    public dialog: MatDialog

  ) { }

  
  ngOnInit() {
    this.AtualizarRegistro()
    this.CreateForm()
    this.viewDetalhe = false
    this.TabelasNaoCadastradas()
    this.GetCaracteristica()
  }

  CreateForm(){
    this.formulario = this._form.group({  
     codigoTabela: new FormControl(''),
      descricao: new FormControl('' ),
      tipoDeTabela: new FormControl(''),
      status: new FormControl(''),
      classificacaoDeTabela: new FormControl('')
      

    })
  }

  AtualizarRegistro(){
    this.service.get().toPromise().then(
      (registro: any) => {
        this.dataSource = new MatTableDataSource(registro)
        this.dataSource.sort = this.sort
        this.registroTabela = registro
      }
    ).catch(
      (err) => {
        console.log(err.response)       
      }
    )
    this.TabelasNaoCadastradas()
    
  }

  GetCaracteristica(){
    this.classificacaoService.GetFiltrarCodigoCaracteristica('TIPTAB').toPromise().then(
      (res : any) =>{
        this.tipoCarac = res
      }
    )
    this.classificacaoService.GetFiltrarCodigoCaracteristica('CLATAB').toPromise().then(
      (res : any) =>{
        this.classificacaoCarac = res
      }       
    )
  }

  OpenCadastro() {
    this.formulario.enable()
    this.formulario.reset()
    this.CreateForm()
    this.botaoModificar = false
    this.viewCadastro = true



  }

  OpenAlterar(registro) {

    this.formulario.setValue(registro)
    this.viewCadastro = true
    this.formulario.disable()
    this.botaoModificar = true

  }


  fecharCadastro() {
    this.formulario.reset()
    this.formulario.enable()
    this.alteracao = false
    this.viewCadastro = false


  }

  filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }

  async listarCampos(tabela){     
    this.campos = await this.consultarTabelaService.ListarCampos(tabela);
}

async TabelasNaoCadastradas(){
  this.tabelasNaoCadastradas = await this.consultarTabelaService.ListarTabelasNaoCadastradas();
}
  Alterar(registro, id)
  {
    this.modalAberto = true
    this.form = registro
    this.form.id = id 
  }

  async AbrirDetalhe(form)
  {
    this.viewDetalhe = true
    this.detalhesTabela = form
    this.detalhesTabela.campos = await this.consultarTabelaService.ListarCampos(form.codigoTabela);
  }

  FecharDetalhes()
  {
    this.viewDetalhe = false
    this.detalhesTabela = null
  }

  AlterarRegistro(form)
  {
    console.log(this.form.id)
    console.log(form.id)
    console.log(this.form)
    this.service.put(this.form.id, this.form).toPromise().then(
      (res) => {
        console.log(res)
        this.mensagem.open('Registro alterado com sucesso', 'OK')
      }
    ).catch((err) => {
      console.log('nao foi')
      console.log(err.response)
    

      this.mensagem.open(err.response, 'OK')})
  }

  InserirDados(form){
    console.log(form.id)
    console.log(this.valoresStatus)
    if(this.formulario.invalid) return this.mensagem.open('formulario invalido', 'OK')
    if(this.form.id)
    {
      this.service.put(this.form.id, this.form ).toPromise().then(
        () => {
          this.mensagem.open('Registro alterado com Sucesso', 'OK')
          this.AtualizarRegistro()
       
        }
      ).catch((err)=> {this.mensagem.open(err.response, 'OK')})
    }
    else
    {
      this.service.post(form.value).toPromise().then(
        (res) => {

          this.mensagem.open('Cadastrado com Sucesso', 'OK')
          this.AtualizarRegistro()
        }
      ).catch(
        (e) => {this.mensagem.open('Algo deu errado', 'OK')
              console.log(e);
              }
      )
    }
    this.formulario.reset()
    this.form = new DicionarioDeDadosModel
    this.AtualizarRegistro()
    
  }
  
 

  async ListarTabelasSelect() {
    this.tabelas = await this.consultarTabelaService.listarTabelas();  
    
    let filter2 = []
    this.tabelas.forEach((tf) =>{
      this.registroTabela.forEach(rf => {
        console.log('Antes rf.codigotabela: ' + rf.codigotabela)
        console.log('Antes tf: ' + tf)
            
        if(rf.codigoTabela =! tf)
        {
          filter2.push(tf)
      console.log('rf.codigotabela: ' + rf.codigotabela)
      console.log('tf: ' + tf)
          
        }
      })
    })
    this.tabelas.forEach((item)=> {
    let resultado =  this.registroTabela.findIndex((item2) => {    
      //console.log(item2.codigoTabela, item)
       item2.codigoTabela == item
      })
     // console.log(resultado, item)
      if(resultado < 0)
      {
        filter2.push(item)
      }
    })
    this.tabelas = filter2
  }

  openAdicionar(Adicionar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(Adicionar);
    this.formulario.reset();
    this.form = new DicionarioDeDadosModel
   
  }

}
export interface PeriodicElement {
  codigoTabela: string;
  descricao: string;
  tipoDeTabela: string;
  classificacaoDeTabela: string;
}


  


