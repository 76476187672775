import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ContaBancaria } from '../cadastro-conta-bancaria/modelsCadastroContaBancaria';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const sessao = new SessaoUser
const url = `${new environment().api}CadastroContaBancaria/`

@Injectable({
    providedIn: 'root'
})
export class ContaBancariaService {
    url = new environment().api
    api = "CadastroContaBancaria/"
    apiContas = 'PlanoContaContabeis/'
    JsonPais = './assets/json/json-paises.json'
    JsonBancos = './assets/json/bancos-de-todo-brasil.json'


    constructor(private http: HttpClient, private data: BuscarDataService) {

    }

    listar(codigoEmpresa) {
        return this.http.get<ContaBancaria[]>(url + 'FiltrarCodigoEmpresa/' + codigoEmpresa)
    }

    enviar(registro: ContaBancaria) {
        return this.http.post(url, registro)
    }

    alterar(registro: ContaBancaria) {
        return this.http.put(url + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: ContaBancaria, ativo) {

        if (ativo) {
            registro.status = 'SCA0001'
        } else {
            registro.status = 'SCA0002'
        }

        let resposta

        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => console.log(error.error))
        return resposta
    }


    listarContas(codigoEmpresa) {
        return this.http.get(this.url + this.apiContas + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    getPaises(): Observable<any> {
        return this.http.get(this.JsonPais)
    }

    getBancosDeTodoBrasil(): Observable<any> {
        return this.http.get(this.JsonBancos)
    }

    getContaBancaria() {
        return this.http.get<ContaBancaria[]>(this.url + this.api)
    }
}


