import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HubDevService } from 'src/app/service/hub-dev.service';
import { Output, EventEmitter } from '@angular/core'
import { ValidateBrService } from 'angular-validate-br';

@Component({
  selector: 'cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.scss']
})

export class CadastroUsuarioComponent implements OnInit {
  @Output() nextEvent = new EventEmitter<Object>();
  cadastroUsuario: FormGroup;
  telMask = "00000000000";
  cpfMask = "000.000.000-A0";
  verificacaoCPF: Boolean;
  constructor(
    private formBuilder: FormBuilder,
    private HubDev: HubDevService,
    private validateBr: ValidateBrService,
  ) { }

  ngOnInit() {
    this.inicializarFormularios();

    this.cadastroUsuario.valueChanges.subscribe(() => {
      this.enviarParaPagamento()
    })

    this.cadastroUsuario.statusChanges.subscribe(async valor => {
      if (this.cadastroUsuario.controls['cpf'].valid) {
        let response = await this.HubDev.vCPF(
          this.cadastroUsuario.controls['cpf'].value,
          this.cadastroUsuario.controls['dataNascimento'].value,
          this.cadastroUsuario.controls['nomeCompleto'].value,
          true,
        )
        this.verificacaoCPF = response.status
      }
    })
  }

  verificarMascara() {
    const telefone: string = this.cadastroUsuario.get('telefone').value
    this.telMask = telefone.length > 10 ? "(00) 0 0000-0000" : "(00) 0000-0000"
  }

  verificarCPF() {
    const cpf: string = this.cadastroUsuario.get('cpf').value
    this.cpfMask = cpf.length > 10 ? "000.000.000-00" : "000.000.000-A"
  }

  inicializarFormularios() {
    this.cadastroUsuario = this.formBuilder.group({
      nomeCompleto: ['', Validators.required],
      nomeSocial: [''],
      genero: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      dataNascimento: ['', Validators.required],
      cpf: ['', [Validators.required, this.validateBr.cpf]],
      telefone: ['', Validators.required],
      termosDeAceite: [false, Validators.requiredTrue],
      tipoUsuario: [],
      assinante: [true],
    }, { updateOn: 'change' });
  }

  getErrorUsuario(campo: string) {
    if (!this.cadastroUsuario.controls[campo].valid) {
      return 'Campo obrigatório'
    }
  }

  enviarParaPagamento() {
    const novoUsuario = this.cadastroUsuario.value;
    this.nextEvent.emit({ novoUsuario, cadastroValido: this.cadastroUsuario.valid });
  }
}
