export class CabecalhoFichaConsultaMedica {
  id: string
  nrDocRef: string
  tipoNrDocRef: string
  nrDoc: string
  tipoNrDoc: string = "TICCON"
  criadoPor: string
  codigoEmpresa: number
  criadoEm: string
  status: string
}

export class itemFichaConsultaMedica {
  id: string
  nrDoc: string
  item: number
  codigoCaracteristica: string
  status: string
  criadoEm: string
  criadoPor: string
  codigoEmpresa : number
}
export class ConteudoFichaConsultaMedica {
  // id: string
  // numeroDoDocumento: string
  // item: number
  // codigoDeReferencia: string
  // valor: string
  // timeStamp: string
  // subItem: string

  id: string
  codigoEmpresa:number
  nrDoc: string
  item: number
  codigoDeReferencia: string
  valor: string
  subItem: string
  criadoPor: string
  criadoEm: string
  status: string

}
export class ResponseFicha {
  status: boolean
  response: string
}
export class SolicitacaoDeExames {
  id: string
  numeroFichaConsulta: string
  item: number
  subItem: string
  cid: string
  descricaoExame: string
}
export class ReceituarioBranco {
  id: string
  numeroFichaConsulta: string
  item: number
  subItem: string
  inscricaoMedicamento: string
  superInscricaoDoUso: string
  continuo: string
  cpfPaciente: string
  subscricaoDaQuantidade: string
  adscricaoDasOrientacoes: string
  descUso?: string
}
export class ReceituarioEspecial {
  id: string
  numeroFichaConsulta: string
  item: number
  subItem: string
  tipoNotificacao: string
  cpfPaciente: string
  tipoReceita: string
  numeroTalonario: string
}

export class CID {
  id: number
  codigo: string
  descricao: string
  createdAt: string
  ativo: boolean
}
export class Medicamentos {
  id: string
  nomeProduto: string
  principioAtivo: string
  nomeDaEmpresa: string
  status: string
  tarja: string
  codigoMedicamento: string
}
export class Receituario {
  id: string
  tipoDeNotificacao: string
  lista: string
  cor: string
  abrangencia: string
  qtdPorPeriodo: string
  qtdPorReceita: string
  validade: string
  talaoDeNotificacao: string
  medicamentos: string
}
export class IndicacaoTratamentos {
  id: string
  numeroFichaConsulta: string
  item: number
  subItem: string
  nomeTratamento: string
  descricao: string
  quantidade: string
}
export class IndicacaoCirurgia {
  id: string
  item: number
  subItem: string
  cid: string
  cirugia: string
  motivo: string
  numeroFichaConsulta: string
}
export class ListaDeTratamentosPaciente {
  id: string
  dataHoraInclusaoDeTabela: any
  codigoUnico: string
  codigoTratamento: string
}
export class ProcedimentoAdicional {
  id: string
  numeroFichaConsulta: string
  item: number
  subItem: string
  nomeAdicional: string
  cobrar: string
  quantidade: string
}
export class AtestadoAfastamento {
  id: string
  item: number
  subItem: string
  cid: string
  duracaoDias: string
  inicio: string
  numeroFichaConsulta: string
  fim: string
}
export class AtestadoMedico {
  id: string
  numeroFichaConsulta: string
  dataInclusaoDaTabela: any
  codigoUnico: string
  cpfDoPaciente: string
  nomePaciente: string
  periodo: string
  inicio: string
  fim: string
  cid: string
}
export class AtestadoAcompanhante {
  id: string
  item: number
  cpf: string
  nome: string
  periodo: string
  subItem: string
  cid: string
  inicio: string
  fim: string
  numeroFichaConsulta: string
}
export class EncaminhamentoMedico {
  id: string
  item: number
  especialidade: string
  cid: string
  motivo: string
  parecerMedico: string
  subItem: string
  fim: string
  numeroFichaConsulta: string
}
export class SolicitacaoRetorno {
  id: string
  item: number
  tipoConsulta: string
  retorno: string
  tipoData: string
  periodo: string
  subItem: string
  periodica: boolean
  aCada: string
  numeroFichaConsulta: string
}
export class ClassificacaoDeReceituarios {
  id: string
  tipoDeNotificacao: string
  lista: string
  cor: string
  abrangencia: string
  qtdPorPeriodo: string
  qtdPorReceita: string
  validade: string
  talaoDeNotificacao: string
  medicamentos: string
}
