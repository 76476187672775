import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeBusinessComponent } from './homeBusiness/homeBusiness.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { MatProgressBarModule, MatTreeModule, MatIconModule, MatButtonModule, MatTooltipModule } from '@angular/material';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HomeBusinessComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatTreeModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  exports: [
    HomeBusinessComponent,
  ]
})
export class MyClinicsBusinessModule { }
