export class EventCalender {
    id: any;
    title: string;
    start: any;
    end: any;
    allDay: boolean;
    color: '#fff0' | '#b3b3b3c9' | '#ffffff00' | '#000' | '#ffff' | '#e2e2e2c9'; //  #fff0 - aberto , #ff2a34 - fechado
    rendering?: 'background';
    backgroundColor?: '#b3b3b3c9' | '#fff0' | '#ffff' | '#e2e2e2c9';
    textColor?: string;
    descricao: string;
    description: string;
    status?: string | 'SDO0149' | 'SDO0150'; // SDO0149 - horario aberto , SDO0150 - horario fechado 
    diaFechado: boolean
    consulta: boolean
}
