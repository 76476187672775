import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AJ_PLAMEDEMP, FichaGeralEmpresa, resultDadosProf } from './FichaGeralEmpresa.model'
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service'
import { DiarioDeAcessoService } from 'src/app/service/diario-de-acesso/diario-de-acesso.service'
import { MensagemService } from 'src/app/service/mensagem.service';
import { ToastrService } from 'ngx-toastr';
import { SessaoUser } from 'src/app/user.reg';
import { Observable } from 'rxjs';
import { cadastroUsuario } from 'src/app/admin-usuarios/model';

const sessao = new SessaoUser

@Injectable({
  providedIn: 'root'
})
export class FichaGeralService {

  url = `${new environment().api}FichaGeralEmpresa/`
  url_servicosMedicos = `${new environment().api}ServicosMedicosDisponiveis/`
  url_areaAtuacao = `${new environment().api}AreaAtuacao/`
  url_usuario = `${new environment().api}CadastroUsuario/`
  url_planoSaude = `${new environment().api}AJ_PLAMEDEMP/`

  constructor(
    private http: HttpClient,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private diarioAcessoService: DiarioDeAcessoService,
    private mensagem: MensagemService,
  ) { }

  async getEmpresas() {
    let result: FichaGeralEmpresa[] = []
    await this.http.get<FichaGeralEmpresa[]>(this.url).toPromise()
      .then(async res => {
        result = res.filter(value => value.coordenadaGps)
        for (let i = 0; i < result.length; i++) {
          let reg = result[i]
          let infoUser = await this.getResponsavel(reg.cpfRespClinico)
          reg.responsavel = infoUser.nomeCompleto
        }
      })
    return result
  }
  async getEmpresaAssiante(assiante) {
    let result = []
    await this.http.get<any>(this.url + assiante).toPromise()
      .then(async res => {
        if (res.success) {
          result = res.result.filter(value => value.codigoEmpresa != 7)
        }
      })
    return result
  }
  async reset(id) {
    let registro: FichaGeralEmpresa[] = []
    console.log(this.url + id)
    await this.http.get<FichaGeralEmpresa[]>(this.url + 'FiltrarId/' + id).toPromise()
      .then(async result => {
        console.log('registro id', result)
        for (let i = 0; i < result.length; i++) {
          let reg = result[i]
          let infoUser = await this.getResponsavel(reg.cpfRespClinico)
          reg.responsavel = infoUser.nomeCompleto
        }
        registro = result
      })
    return registro
  }
  async getResponsavel(cpf) {
    let result = new cadastroUsuario
    await this.http.get<cadastroUsuario[]>(this.url_usuario).toPromise()
      .then(res => {
        let filter = res.filter(v => v.cpf == cpf)
        if (filter.length > 0) {
          result = filter[0]
        }
      })
    return result
  }
  buscarEmpresasCodigoAssinante(codigoAssinante): Observable<Object> {
    return this.http.get(`${this.url}${codigoAssinante}`)
  }

  postFichaGeralEmpresa(dados) {
    return this.http.post(this.url, dados)
  }

  postServicosMedicos(dados) {
    return this.http.post(this.url_servicosMedicos, dados)
  }
  postAreaAtuacao(dados) {
    console.log('postAreaAtuacao', this.url_areaAtuacao, dados)
    return this.http.post(this.url_areaAtuacao, dados)
  }
  getServicosMedicos() {
    return this.http.get(`${this.url_servicosMedicos}`)
  }
  getAreaAtuacao(codigoEmpresa) {
    return this.http.get(`${this.url_areaAtuacao}FiltrarCodigoEmpresa/${codigoEmpresa}`)
  }
  alterarServicosMedicos(registro) {
    return this.http.put(`${this.url_servicosMedicos}${registro.codigoServico}`, registro)

  }
  aleterarAreaAtuacao(registro) {
    if (registro.itemAreaAtuacao) {
      return this.http.delete(`${this.url_areaAtuacao}${registro.itemAreaAtuacao}`)
    }
  }
  excluirServicosMedicos(registro) {
    return this.http.put(`${this.url_servicosMedicos}${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)

  }
  excluirAreaAtuacao(registro) {
    if (registro.itemAreaAtuacao) {
      return this.http.put(`${this.url_areaAtuacao}${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)
    }
  }

  AlterarFichaGeralEmpresa(id: string, userId: any, data: string, dados) {
    console.log(`${this.url}${id}/${userId}/${data}`, dados)
    return this.http.put(`${this.url}${id}/${userId}/${data}`, dados);
  }

  getFichaGeralEmpresaID(id: any) {
    return this.http.get(`${this.url}FiltrarCodigoEmpresa/${id}`)
  }

  //Método mantido apenas enquanto a manutenção das telas que o utilizam não ocorre
  getFichaGeral(assinante?) {
    console.log('getFichaGeral', this.url + assinante)
    return this.http.get<any>(this.url + assinante)
  }

  async listarFichaGerais(): Promise<FichaGeralEmpresa[]> {
    let fichasGerais: FichaGeralEmpresa[];
    await this.http.get<FichaGeralEmpresa[]>(this.url, { observe: 'body' }).toPromise()
      .then(res => fichasGerais = res)
      .catch(err => this.mensagem.creat(`Não foi possível listar todas as empresas: ${err}`, { erro: true }))
    return fichasGerais;
  }

  async criarFichaGeral(novaFichaGeral: Omit<FichaGeralEmpresa, 'codigoEmpresa' | 'timestamp'>): Promise<FichaGeralEmpresa> {
    let fichaGeral: FichaGeralEmpresa;
    novaFichaGeral.status = novaFichaGeral.status === '' ? 'SDO0017' : novaFichaGeral.status;
    const cpf = sessao.cpf;
    const assinante = await this.serviceFichaAssinante.buscarAssinante({ cpf });
    novaFichaGeral.codigoAssinante = assinante.codigoAssinante;
    await this.http.post<FichaGeralEmpresa>(this.url, novaFichaGeral, { observe: 'body' }).toPromise()
      .then(res => {
        fichaGeral = res
      })
      .catch(err => this.mensagem.creat(`Não foi possível criar a empresa ${novaFichaGeral.nomeEmpresa}: ${err}`, { erro: true }))
    return fichaGeral;
  }

  async alterarFichaGeral(novaFichaGeral: Omit<FichaGeralEmpresa, | 'timestamp'>): Promise<FichaGeralEmpresa> {
    let fichaGeral: FichaGeralEmpresa;
    novaFichaGeral.status = 'SDO0017'
    const cpf = sessao.cpf
    const assinante = await this.serviceFichaAssinante.buscarAssinante({ cpf });
    novaFichaGeral.codigoAssinante = assinante.codigoAssinante;
    await this.http.put<FichaGeralEmpresa>(`${this.url}${novaFichaGeral.codigoEmpresa}`, novaFichaGeral, { observe: 'body' }).toPromise()
      .then(res => {
        fichaGeral = res
      })
      .catch(err => this.mensagem.creat(`Não foi possível alterar a empresa ${novaFichaGeral.nomeEmpresa}: ${err}`, { erro: true }))
    return fichaGeral;
  }

  async removerFichaGeral({ codEmpresa }: { codEmpresa: number }) {
    await this.http.delete(`${this.url}${codEmpresa}`).toPromise()
      .then(() => this.mensagem.creat('Empresa deletada com sucesso', { sucesso: true }))
      .catch(err => this.mensagem.creat(`Não foi possível deletar a empresa ${codEmpresa}: ${err}`, { erro: true }))
  }

  async buscarEmpresasAssinante(): Promise<FichaGeralEmpresa[]> {
    const cpf = sessao.cpf;
    const assinante = await this.serviceFichaAssinante.buscarAssinante({ cpf });
    let fichasGerais: FichaGeralEmpresa[] = [];
    await this.http.get<FichaGeralEmpresa[]>(`${this.url}${assinante.codigoAssinante}`, { observe: 'body' }).toPromise()
      .then(res => fichasGerais = res)
      .catch((err: HttpErrorResponse) => {
        if (err.status === 400) this.mensagem.creat('É necessário cadastrar uma empresa para esta ação.', { aviso: true })
        this.mensagem.creat(`Não foi possível listar as empresas do assinante ${assinante.codigoAssinante}: ${err}`, { erro: true })
      })
    return fichasGerais;
  }

  async buscarEmpresa(codEmpresa: Number): Promise<FichaGeralEmpresa> {
    let fichasGerais: FichaGeralEmpresa;
    await this.http.get<any>(`${this.url}FiltrarCodigoEmpresa/${codEmpresa}`, { observe: 'body' }).toPromise()
      .then(res => {
        if(res.success){
          fichasGerais = res.result
        }else{
          fichasGerais = new FichaGeralEmpresa
        }
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status === 400) this.mensagem.creat('É necessário cadastrar uma empresa para esta ação.', { aviso: true })
        this.mensagem.creat(`Não foi possível buscar a empresa: ${err}`, { erro: true })
      })
    return fichasGerais
  }
  async getNameEmpresa(codigoEmpresa) {
    let result: string
    await this.http.get<any>(`${this.url}FiltrarCodigoEmpresa/${codigoEmpresa}`).toPromise()
      .then(res => {
        try {
          if(res.success){
            const getName = res.result[0].nomeEmpresa
            result = getName
          }
     
        } catch (error) {
          result = error
        }
      })
    return result
  }

  async getRegistro(cpf: string) {
    let res
    await this.http.get<resultDadosProf[]>(`${new environment().api}CadastroDadosProfissionais/Join/${cpf}`).toPromise()
      .then((registro) => res = registro.filter(cadastro => { return cadastro.classificacaoRegistro == 'CLAREG1' })[0])
      .catch(resp => {
        res = new resultDadosProf
        this.mensagem.creat(resp.error, { erro: true })
      })
    return res
  }
  async getPlanoSaude(codigoEmpresa) {
    let result: AJ_PLAMEDEMP[] = []
    await this.http.get<AJ_PLAMEDEMP[]>(this.url_planoSaude).toPromise()
      .then(res => {
        let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa && value.status == 'SDA0001')
        result = filter
      })
    return result
  }
  async postPlanoSaude(item: AJ_PLAMEDEMP) {
    let result = false
    item.status = "SDA0001"
    item.criadoPor = sessao.cpf

    await this.http.post(this.url_planoSaude, item).toPromise().then(() => result = true)
    return result
  }
  async alterarPlanoSaude(item: AJ_PLAMEDEMP) {
    let result = false
    await this.http.put(this.url_planoSaude + `${item.id}/${sessao.sessao_id}/${new Date().toISOString()}`, item).toPromise().then(() => result = true)
    return result
  }
  async disabledPlanoSaude(codigoEmpresa) {
    let cont
    let getRegistro = []
    getRegistro = await this.getPlanoSaude(codigoEmpresa)

    if (getRegistro.length < 0) {
      return true
    }
    for (let i = 0; i < getRegistro.length; i++) {
      let item = getRegistro[i]
      item.status = "SDA0002"
      let response = await this.alterarPlanoSaude(item)
      if (response) {
        cont++
      }
    }
    if (cont < getRegistro.length) {
      return false
    } else {
      return true
    }

  }
}
