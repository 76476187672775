import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PacienteService } from '../service/paciente.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormulariosService } from '../service/formularios.service';
import { DatePipe } from '@angular/common';
import { ProntuarioService } from '../service/prontuario.service';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { switchMap } from 'rxjs/operators';
import { UploadArquivosService } from '../service/upload-arquivos.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FotoPerfilService } from '../service/foto-perfil.service';
import { BuscarDataService } from '../service/buscar-data.service';
import { SessaoUser } from '../user.reg';
import { MatTableDataSource } from '@angular/material';
import { CadastroPessoaForm } from '../cadastro-paciente/model';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { UsuarioService } from '../service/usuario.service';
import { SalaEsperaForm } from '../atendimento-clinico/sala-espera/model';
import { ListarMedicos, ListarMedicosService } from '../service/listar-medicos.service';
import { CadastroDadosProfissionais, CadastroDadosProfissionaisJoin } from '../admin-usuarios/model';
import { TicketsService } from '../service/tickets.service';
import { TicketConsulta, TicketConsultaJOIN } from '../home/monitor-clinico/consultas/solicitacoes/modal';

const sessao = new SessaoUser
@Component({
  selector: 'app-prontuario-paciente',
  templateUrl: './prontuario-paciente.component.html',
  styleUrls: ['./prontuario-paciente.component.scss']
})
export class ProntuarioPacienteComponent implements OnInit {
  @Input() registro = new SalaEsperaForm
  @Input() prontuario: boolean
  @Output() fechar = new EventEmitter

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  displayedColumnsSolicExame = ['cid', 'exame', 'descricao']
  displayedColumnsReceitaBranca: string[] = ['continuo', 'superInscriUso', 'inscriMedicamento', 'subsQuantidade', 'AdsOrientacao'];
  listProntuario = []
  selectForm
  dadosProntuario = []
  dadosEvolucaoSaude = []
  public lineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Glicemia', pointRadius: 10 },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'IMC', pointRadius: 10 },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Peso', pointRadius: 10 },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Pressão sistólica', pointRadius: 10 }
  ];
  public lineChartLabels = ['06/12/2020', '08/30/2020', '12/30/2020', '04/05/2021']
  public lineChartOptions: (ChartOptions) = {

    responsive: true,
    legend: {
      display: false
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgb(196 196 196 / 62%)',
          },
          ticks: {
            fontColor: 'red',
            display: false
          }
        }
      ]
    },

  };
  public lineChartColors: Color[] = [
    { // Glicemia
      backgroundColor: 'rgb(20 151 48)',
      borderColor: 'rgb(20 151 48)',
      pointBackgroundColor: 'rgb(20 151 48)',
      pointBorderColor: '#fff',
      // pointHoverBackgroundColor: 'rgb(20 151 48)',
      // pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgb(77,83,96,1)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      // pointHoverBackgroundColor: 'rgba(77,83,96,1)',
      // pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // Peso
      backgroundColor: 'rgb(23 162 184)',
      borderColor: 'rgb(23 162 184)',
      pointBackgroundColor: 'rgb(23 162 184)',
      pointBorderColor: '#fff',
      // pointHoverBackgroundColor: 'rgb(23 162 184)',
      // pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // Pressão sistolica
      backgroundColor: 'rgb(216 219 10)',
      borderColor: 'rgb(216 219 10)',
      pointBackgroundColor: 'rgb(216 219 10)',
      pointBorderColor: '#fff',
      // pointHoverBackgroundColor: 'rgb(216 219 10)',
      // pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'bar';

  typeChart: string;
  typeHistorico: number = 1;
  numHistorico: any;
  registroHistorico: any;
  regDiagnosticos: any = []
  fotoPerfil: any;
  completeEvolucaoSaude: boolean;
  loadingImage: boolean;
  noImage: boolean;

  dadosProntuario_fake = [
    {
      numeroDocumento: '1234234',
      dataInclusaoDocumento: '2020-11-30',
      especialidade: 'Pediatria',
      motivoConsulta: 'Retorno'
    },
    {
      numeroDocumento: '1234',
      dataInclusaoDocumento: '2020-11-20',
      especialidade: 'Pediatria',
      motivoConsulta: 'Consulta'
    },
    {
      numeroDocumento: '1234345',
      dataInclusaoDocumento: '2020-07-06',
      especialidade: 'Pediatria',
      motivoConsulta: 'Check-up'
    },

  ]
  userName: string;
  crm: string;
  unidadeFederal: string;
  formaTratamento: string;
  especialidade: string;
  form_avClinica = [{
    numeroDocumento: '1234',
    anamnese: 'Paciente apresenta dores abdominais',
    exame: 'Adbomen não apresenta nenhum Hematoma porem reclama de dor ao toque médio ',
    afericaoSV: {
      temperatura: '36.6',
      saturacao: '95',
      pressaoArterial: '10x6',
      glicemia: '80',
      batCardiaco: '90',
      batCardiacoFeto: ''
    },
    descrEstadoMental: '',
    resultadoExame: '',
    comentario: ''
  },
  {
    numeroDocumento: '1234234',
    anamnese: 'Paciente não apresenta mais sintomas de queixa no abdomen',
    exame: '',
    afericaoSV: {
      temperatura: '36.6',
      saturacao: '95',
      pressaoArterial: '10x6',
      glicemia: '80',
      batCardiaco: '90',
      batCardiacoFeto: ''
    },
    descrEstadoMental: '',
    resultadoExame: '',
    comentario: 'O resultado de exame não apresentou nenhum indicador alterado'
  }
  ]
  form_parecerMedico = {
    hipoDiagnostica: '',
    diagnostico_princical: '',
    diagnostico_descricao: '',
    quadroCirurgico: '',
    sequelas_diagnostico: '',
    sequelas_cirurgico: '',
    causasMortais: ''
  }
  dataSolicExame
  dataReceitaBranca
  dataHistorico
  dataIndtratamento
  viewProntuario: boolean;
  selectProntuario: any;
  paciente = new CadastroPessoaForm
  activeMenu = 3
  medico = new ListarMedicos;
  ultimaConsulta = new TicketConsulta;
  historicoConsulta: TicketConsultaJOIN[] = [];
  constructor(
    private url: ActivatedRoute,
    private _user: UsuarioService,
    private _medic: ListarMedicosService,
    private _prontuario: ProntuarioService,
    private service_foto: FotoPerfilService,
    private _date: BuscarDataService,
    private _filter: FiltrarDadosService,
    private _ticket: TicketsService
  ) {
    this.userName = sessao.name
    this.crm = sessao.crm
    this.unidadeFederal = sessao.unidadeFederativa
    this.formaTratamento = sessao.formaTratamento
    this.especialidade = sessao.especialidade

  }

  ngOnInit() {
    console.log('>>> REGISTRO <<<', this.registro)
    this.getHistorico()
    this.getRegistro()
    this.getEvolucaoSaude()
    this.getInfoPaciente()
    this.getUltimaConsulta()
    let solicExame = [{
      numeroDocumento: '1234',
      cid: 'A059 Intoxicação alimentar bacteriana não especificada',
      exame: 'Ultrassonografia, abdome total',
      descricaoExame: '       '
    }]
    let receitaBranca = [{
      continuo: false,
      superInscricaoDoUso: 'Uso interno via oral',
      inscricaoMedicamento: 'Lufital',
      subscricaoDaQuantidade: '10 gotas',
      adscricaoDasOrientacoes: '3 vezes ao dia por 2 dias'
    }]
    let indTratamento = [{
      numeroDocumento: '1234234',
      nomeTratamento: 'Alimentação',
      descricao: 'Alterar hábitos alimentares incluindo mais legumes e carne branca',
      quantidade: ''
    }]
    this.dataSolicExame = new MatTableDataSource(solicExame)
    this.dataReceitaBranca = receitaBranca
    this.dataIndtratamento = indTratamento


  }
  async getHistorico() {
    this._ticket.getTicketConsultaJOIN(this.registro.cpfPaciente).toPromise()
      .then(res => {
        if (res.success) {
          this.historicoConsulta = res.result as any
        }

        console.log("getHistorico", res)
      })
  }
  async getRegistro() {
    this._prontuario.getProntuarioVida(this.registro.cpfPaciente).toPromise()
      .then((res: any) => {
        this.dadosProntuario = res.result
        console.log('getProntuarioVida ->', this.dadosProntuario.map(v => v.fichaConsultaMedica))
      })

    let getProntuario = await this._prontuario.get(sessao.cpf)
    if (getProntuario.length) {
      this.listProntuario = getProntuario
      this._filter.inserirFotoPerfil('cpfMedico', this.listProntuario)
      this.openProntuario(this.listProntuario[0])
    }
  }
  getUltimaConsulta() {
    this._ticket.getTicketConsulta().toPromise()
      .then(async res => {
        let filter = res.filter(v => v.cpfPaciente == sessao.cpf)
          .sort((a, b) => { return a.nrDoc > b.nrDoc ? 1 : b.nrDoc < a.nrDoc ? -1 : 0 })
        this.ultimaConsulta = filter[0]
        if (filter[0]) {
          let getMedico = await this._medic.getMedicosEmpresa(sessao.codigoEmpresa)
          console.log(">>> MEDICO <<<", getMedico)
          let medic = getMedico[0]
          if (medic) this.medico = medic
          console.log('MEDICO!', medic)
        }

      })
  }
  async getInfoPaciente() {

    this.paciente = await this._user.getCadastroPessoa(this.registro.cpfPaciente)

    await this._filter.inserirFotoPerfilEach('cpf', this.paciente)
    console.log('registro = >', this.paciente, this.medico)


    let dataNascimento = this._date.convertCriadoEm(this.paciente.dataNascimento)
    this.paciente.idade = this._date.idade(dataNascimento)


    // this._filter.inserirFotoPerfilEach('cpfPaciente', this.paciente)

    this.loadingImage = true
    this.fotoPerfil = await this.service_foto.buscarFoto(this.registro.cpf)
    this.loadingImage = false
    // 
  }
  getEvolucaoSaude() {
    this.completeEvolucaoSaude = false
    this._prontuario.getEvolucaoSaude(this.registro.cpf).toPromise()
      .then((res: any) => {
        this.dadosEvolucaoSaude = res
        console.log('evolução', this.dadosEvolucaoSaude)
        let dates = []
        let glicemia = []
        let imc = []
        let peso = []
        let pressaoSistolica = []
        this.dadosEvolucaoSaude.forEach(value => {
          let date = this._date.convertTIMESTAMP(value.data)
          console.log('Dates', date)
          dates.push(date)

          glicemia.push(parseInt(value.glicemia ? value.glicemia : '0'))
          imc.push(parseInt(value.imc ? value.imc : '0'))
          peso.push(parseInt(value.peso ? value.peso : '0'))
          pressaoSistolica.push(parseInt(value.pressaoSistolica ? value.pressaoSistolica : '0'))

        })

        // this.lineChartData[0].data = glicemia 
        // this.lineChartData[1].data = imc
        // this.lineChartData[2].data = peso
        // this.lineChartData[3].data = pressaoSistolica



        this.lineChartLabels = dates.sort((a, b) => {
          let A = a.split('/').join('')
          let B = b.split('/').join('')
          return parseInt(A) > parseInt(B) ? 1 : 0
        })

      }).catch(error => console.log('error', error))
    this.lineChartData[0].data = [10, 5, 11]
    this.lineChartData[1].data = [3, 4, 8]
    this.lineChartData[2].data = [10, 8, 12]
    this.lineChartData[3].data = [1, 7, 9]

    console.log('lineChartData', this.lineChartData)
  }
  openProntuario(item) {
    this.selectProntuario = item
    this.viewProntuario = true
  }
  viewHistorico(type: number) {
    this.typeHistorico = type
    switch (type) {
      case 1:
        this.regDiagnosticos = this.registroHistorico[0].fichaConsultaMedica.filter(value => value.codigoCaracteristica == "PMHIPO2")
        break;

      default:
        break;
    }
    console.log('registro', this.regDiagnosticos)
  }
  viewDateHistorico(numeroDocumento, date?) {
    this.dataHistorico = date
    console.log('passou', this.dataHistorico)
    this.numHistorico = numeroDocumento
    // console.log('historico',this.registroHistorico)
    // return
    this.viewHistorico(this.typeHistorico ? this.typeHistorico : 1)
    try {
      this.registroHistorico = this.dadosProntuario.filter(value => value.numeroDocumento == numeroDocumento)
      this.regDiagnosticos = this.registroHistorico[0].fichaConsultaMedica.filter(value => value.codigoCaracteristica == "PMDIAG2")
      console.log('historico', this.registroHistorico[0])
      console.log('regDiagnosticos', this.regDiagnosticos)

    } catch { }

  }
  viewChart(type: string) {
    this.typeChart = type

    this.chart.hideDataset(0, true);
    this.chart.hideDataset(1, true);
    this.chart.hideDataset(2, true);
    this.chart.hideDataset(3, true);

    switch (type) {
      case 'glicemia':

        this.chart.hideDataset(0, false);
        break;
      case 'imc':
        this.chart.hideDataset(1, false);
        break;
      case 'peso':
        this.chart.hideDataset(2, false);
        break;
      case 'pressao':
        this.chart.hideDataset(3, false);
        break;
      case 'all':
        this.chart.hideDataset(0, false);
        this.chart.hideDataset(1, false);
        this.chart.hideDataset(2, false);
        this.chart.hideDataset(3, false);
        break;

      default:
        break;
    }


  }
  close() {
    this.fechar.emit(false)
    this.viewProntuario = false
  }
}
