import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DadosSaudePacienteService } from 'src/app/service/dados-saude-paciente/dados-saude-paciente.service';
import { TicketsService } from 'src/app/service/tickets.service';
@Component({
  selector: 'app-historico-saude',
  templateUrl: './historico-saude.component.html',
  styleUrls: ['./historico-saude.component.scss']
})
export class HistoricoSaudeComponent implements OnInit {
  @Input('cpf') cpf: string

  displayedColumnsCheckup = ['dataInclusaoDocumento', 'nomeMedico', 'descEspecialidade']
  dataSourceCheckup = new MatTableDataSource()

  displayedColumnsCirurgia = ['detalhe', 'cid', 'cirurgia', 'motivo', 'data']
  dataSourceCirurgia = new MatTableDataSource()

  openHistorico: string = 'ultiCheckup'
  viewForm: 'cirurgia' | 'h-doenca' | '' = ''
  viewChart: string;
  loading = false
  register: any;

  constructor(private _ticket: TicketsService, private _dadosSaude: DadosSaudePacienteService) { }

  async ngOnInit() {
    this.loading = true
    await this.getCheckups()
    await this.getCirurgias()
    this.loading = false
  }
  async getCheckups() {
    await this._ticket.getTicketConsultaJOIN(this.cpf).toPromise()
      .then(response => {
        if (!response.success) return
        let filter = response.result.filter(value => value.motivoConsulta == 'LIMOCO3')
        console.log('GET ----', filter)
        this.dataSourceCheckup = new MatTableDataSource(filter)
      })
  }
  async getCirurgias() {
    await this._dadosSaude.getCirurgia().toPromise()
      .then(response => {
        if (!response.success) return
        const cpf = this.cpf
        let filter = response.result.filter(v => v.cpfPaciente == cpf && v.status == 'SCA0001')
        console.log('getCirurgias --->',cpf, filter)
        this.dataSourceCirurgia = new MatTableDataSource(filter)

      })
  }
  openCadastro(register, type: 'cirurgia' | 'h-doenca') {
    this.register = register
    this.viewForm = type
  }
  exitForm() {
    this.viewForm = ''
    this.register = ''
    this.getCirurgias()
  }
  viewHistorico(type: string) {
    this.openHistorico = type
  }
  openViewChart(type: string) {
    this.viewChart = type
  }

}
