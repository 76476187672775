import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { SalaEsperaForm } from 'src/app/atendimento-clinico/sala-espera/model';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FormPaciente } from 'src/app/cadastro-paciente/model';
import { DatePipe } from '@angular/common';
import { FormPagamento } from '../modal';
import { AgendaForm } from 'src/app/agenda/agenda/model';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { FormCadastroEmpresa } from 'src/app/configuracao-inicial/cadastro-empresa/model';
(window as any).html2canvas = html2canvas;

class Recibo{
  paciente : string
  cpf?: string
  valor?: string
  data? : any
}

@Component({
  selector: 'app-emitir-recibo',
  templateUrl: './emitir-recibo.component.html',
  styleUrls: ['./emitir-recibo.component.scss']
})

export class EmitirReciboComponent implements OnInit {
  @ViewChild('content', { static: true }) content: ElementRef
  @ViewChild('registro', { static: true }) registro: any
  @Input() paciente: FormPaciente
  @Input() valorConsulta 
  @Input() registroPagamento : FormPagamento 
  @Input('medico') medico: AgendaForm
  tratamento = ''
  datePipe = new DatePipe('en-US')
  textArea = false
  recibo = new Recibo()
  nome
  cpf
  valor
  dataHoje 
  constructor(
    private _FileSaverService: FileSaverService, 
    private data : BuscarDataService,
    private service : ConfiguracaoInicialService) {  
    this.dataHoje =  this.data.hoje()
  }
  ngOnInit() {
    if(!this.medico){
      this.medico = new AgendaForm
    }
    console.log('OLHA : ' +  JSON.stringify(this.paciente))
  
  }
  teste(teste) {
    this.tratamento = teste
  }
  async  downloadPDF() {
    console.log(this.paciente)
    var doc = new jsPDF();
    // await doc.addHTML(document.getElementById('content'), function(){
    //   console.log('ok')
    //   doc.output('dataurlnewwindow');
    // })
    doc.setFontSize(15);
    doc.text(180, 10, 'N°00001');
    
    doc.setFontSize(15);
    doc.text(5, 10, `${this.medico.medico ? this.medico.medico : '-'}`);
    
    doc.setFontSize(9);
    doc.text(5, 15, `Clinica Geral - CRM 00.0000 - CPF ${this.paciente.cpf}`);
    
    doc.setFontSize(8);
    doc.text(5, 20, 'Rua, Av. Dr. Enéas Carvalho de Aguiar, 255 - Cerqueira César, São Paulo - SP, 05403-000 Telefone: (11) 2661-0000');
    
    doc.setFontSize(8);
    doc.text(5, 25, 'wwww.consultorio.com.br');
    
    
    doc.setFontSize(18);
    doc.text(10, 40, 'Recibo');
    
    doc.setFontSize(15);
    doc.text(150, 50, `Valor : R$ ${this.valorConsulta}`);
    
    doc.setFontSize(12);
    doc.text(20, 65, `Recebi(emos) de: ${this.paciente.nome} ,CPF: ${this.paciente.cpf} a quantia de R$${this.valorConsulta} , `);
    doc.text(20, 75, `referente ao tratamento : ${this.tratamento} ` );
    
    doc.setFontSize(15);
    doc.text(20, 120, `São Paulo ${this.datePipe.transform(this.dataHoje, 'dd/MM/yyyy') }`);

    // doc.save('Relatorio-DP.pdf');
    // const doc = new jsPDF();

    // const specialElementHandlers = {
    //    '#editor': function (element, renderer) {
    //     return true;
    //     }
    // };

    // const content = this.content.nativeElement;

    // doc.fromHTML(content.innerHTML, 15, 15, {
    //    width: 190,
    //   'elementHandlers': specialElementHandlers
    // });

    doc.output('dataurlnewwindow');
  }
  buscarEmpresa(){
    this.service.listarEmpresa().toPromise()
    .then(
      (dados : Array<FormCadastroEmpresa>)=>{
        let companyId = this.paciente.companyId
        let filtro = dados.map(function(c , index){return {companyId : c.id, index : index}})
        .filter((r)=>r.companyId == companyId)
      }
    )
  }
  // salvar(){
  //   const fileType = this._FileSaverService.genType('save.json');
  //   const txtBlob = new Blob([this.recibo], { type: fileType });
  //   this._FileSaverService.save(txtBlob, 'save.json')
  // }

}
