import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BuscarDataService } from "./buscar-data.service";
import {
  TicketReserva,
  TicketConsulta,
  TicketConsultaJOIN,
} from "../home/monitor-clinico/consultas/solicitacoes/modal";
import { SessaoUser } from "../user.reg";
import { cadastroUsuario } from "../admin-usuarios/model";
import {
  AgendaConfigForm,
  AgendaFechadaForm,
  AgendaFeriadosForm,
  AgendaForm,
} from "../agenda/agenda/model";
import { EventCalender } from "../calendario/model";
import { removeSummaryDuplicates } from "@angular/compiler";
import { error } from "@angular/compiler/src/util";
const sessao = new SessaoUser();
export class infoMedico {
  id: string;
  cpf: string;
  nomeCompleto: string;
  nomeSocial: string;
  profissao: string;
  status: string;
  timeStamp: string;
  classificacaoRegistro: string;
  especialidadeProfissional: string;
  nr_Registro: string;
  valor: string;
  valorEspecialidade: string;
}
@Injectable({
  providedIn: "root",
})
export class AgendaService {
  url = new environment().api;
  // url = 'https://myclinics.com.br/api/api/'
  funcionarios = "DadosProfissionais/";
  pacientes = "CadastroUsuario/";
  horarios = "Horario/";
  api = "Agenda/";
  confiAgenda = "configAgenda/";
  agendaFechada = "AgendaFechada/";
  // agendaFeriados = 'AgendaFeriados/'
  agendaHorarioDiferente = "HorarioDiferenteDeTrabalho/";

  constructor(private http: HttpClient, private date: BuscarDataService) {}
  //************************** Agenda **********************************
  listarAgenda(id?) {
    // //console.log('Agenda :' + this.url + this.api)
    if (id) {
      return this.http.get(this.url + this.api + id);
    }

    return this.http.get(this.url + this.api);
  }
  async getAgendaID(id: string) {
    let result = new AgendaForm();
    await this.http
      .get(this.url + this.api + id)
      .toPromise()
      .then((res) => (result = res));
    return result;
  }
  cadastrarAgenda(dados: any) {
    dados.criadoEm = new Date().toISOString();
    //console.log('Agenda-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados);
  }
  alterarAgenda(id: string, dados) {
    console.log("Agenda-Alterar :" + this.url + this.api + id, dados);
    const log = `${id}/${sessao.sessao_id}/${new Date().toISOString()}`;
    return this.http.put(this.url + this.api + log, dados);
  }
  excluirAgenda(id: string, dados?) {
    //console.log('Agenda-Excluir :' + this.url + this.api + id)
    const log = `${this.date.hoje()}T${this.date.horaAgoraSegundos()}/${
      sessao.sessao_id
    }`;
    return this.http.delete(this.url + this.api + id + "/" + log);
    // return this.http.put(this.url + this.api + id, dados)
  }
  //**************************END Agenda **********************************

  //************************** GET Dados Medico/Paciente ************************************
  async getMedico(cpf) {
    let info = new infoMedico();
    await this.http
      .get<any>(this.url + `CadastroDadosProfissionais/ConsultarMedico/${cpf}`)
      .toPromise()
      .then((res) => {
        if (res.success) {
          info = res.result[0];
        }
      })
      .catch(() => {
        info = new infoMedico();
      });

    return info;
  }
  async getPaciente(cpf) {
    let info = new cadastroUsuario();
    await this.http
      .get<any>(this.url + `CadastroUsuario/(filtrarCpf)/${cpf}`)
      .toPromise()
      .then((res) => {
        if (res.success) {
          info = res.result[0];
        } else {
        }
      })
      .catch((error) => {
        //console.log('erro', error)
      });

    return info;
  }
  //************************** END GET Dados Medico/Paciente ************************************

  //************************** Alterar Ticket de Reserva ****************************************
  atualizarTicket(dados: TicketReserva) {
    //console.log(this.url + 'TicketReservaConsultaMedica/' + dados.id, dados)
    return this.http.put<TicketReserva>(
      this.url +
        "TicketReservaConsultaMedica/" +
        `${dados.id}/${new Date().toISOString()}/${sessao.sessao_id}/`,
      dados
    );
  }
  //**************************END Alterar Ticket de Reserva ****************************************

  //************************** Ticket de Consulta ****************************************
  gerarTicketConsulta(ticket: TicketConsulta) {
    ticket.criadoEm = new Date().toISOString();
    ticket.ativo = true;
    //console.log(this.url + 'TicketMarcacaoConsultaMedica/', JSON.stringify(ticket))
    return this.http.post(this.url + "TicketMarcacaoConsultaMedica/", ticket);
  }
  gerarTicketReserva(ticket: TicketReserva) {
    ticket.criadoEm = new Date().toISOString();
    ticket.criadoPor = sessao.cpf;
    //console.log('TicketReservaConsultaMedica/', JSON.stringify(ticket))
    return this.http.post(this.url + "TicketReservaConsultaMedica/", ticket);
  }
  getTicketConsulta() {
    return this.http.get<TicketConsulta[]>(
      this.url + "TicketMarcacaoConsultaMedica/"
    );
  }
  getHistoricoAgenda(codigoEmpresa: number): Promise<TicketConsultaJOIN[]> {
    return new Promise(async (resolve, reject) => {
      await this.http
        .get<any>(
          this.url +
            "TicketMarcacaoConsultaMedica/FiltrarCodigoEmpresa/" +
            codigoEmpresa
        )
        .toPromise()
        .then((res) => {
          if (res.success) {
            resolve(res.result);
          } else {
            reject([]);
          }
        });
    });
  }
  async getContula(cpf) {
    let result: TicketConsultaJOIN[] = [];
    await this.http
      .get<any>(
        this.url + "TicketMarcacaoConsultaMedica/FiltrarCpfPaciente/" + cpf
      )
      .toPromise()
      .then((res) => {
        if (res.success) {
          result = res.result;
        }
      });
    return result;
  }
  atualizarTicketConsulta(id, registro) {
    let changlog = `${id}/${new Date().toISOString()}/${sessao.sessao_id}`;
    // console.log(this.url + 'TicketMarcacaoConsultaMedica/' + changlog, JSON.stringify(registro))
    return this.http.put(
      this.url + "TicketMarcacaoConsultaMedica/" + changlog,
      registro
    );
  }
  //************************** END Ticket de Consulta ****************************************

  //************************** Buscar Funcionario **********************************
  listarFuncionario(id?) {
    //console.log('Funcionario :' + this.api + this.funcionarios)
    if (id) {
      return this.http.get(this.url + this.funcionarios + id);
    }
    return this.http.get(this.url + this.funcionarios);
  }
  //**************************END Buscar Funcionario **********************************

  //************************** Buscar Paciente **********************************
  listarPaciente(id?) {
    //console.log('Paciente :' + this.url + this.pacientes)
    if (id) {
      return this.http.get(this.url + this.pacientes + id);
    }
    return this.http.get(this.url + this.pacientes);
  }
  //**************************END Buscar Paciente **********************************

  //************************** ConfigAgenda **********************************
  listarConfigAgenda(id?) {
    //console.log('ConfigAgenda :' + this.url + this.confiAgenda)
    if (id) {
      return this.http.get(this.url + this.confiAgenda + id);
    }
    return this.http.get(this.url + this.confiAgenda);
  }
  async getConfigMedico(cpfMedico) {
    let result = new AgendaConfigForm();
    await this.http
      .get<AgendaConfigForm[]>(this.url + this.confiAgenda)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) =>
            value.codigoEmpresa == sessao.codigoEmpresa &&
            value.cpfMedico == cpfMedico.toString()
        );
        if (filter.length > 0) {
          result = filter[0];
          ///Codigo abaixo responsavel para corrigir data de termino do calendario
          // let DD = parseInt(result.end.substr(8, 2)) + 1
          // result.end = result.end.substr(0, 8) + JSON.stringify(DD)
          ////////end
        }
      });
    return result;
  }
  async getConfig() {
    let result: AgendaConfigForm[] = [];
    await this.http
      .get<AgendaConfigForm[]>(this.url + this.confiAgenda)
      .toPromise()
      .then((res) => {
        let filter = res.filter(
          (value) => value.codigoEmpresa == sessao.codigoEmpresa
        );
        if (filter.length > 0) {
          result = filter;
          // ///Codigo abaixo responsavel para corrigir data de termino do calendario
          // let DD = parseInt(result.end.substr(8, 2)) + 1
          // result.end = result.end.substr(0, 8) + JSON.stringify(DD)
          // ////////end
        }
      });
    return result;
  }
  cadastrarConfigAgenda(dados: AgendaConfigForm) {
    dados.codigoEmpresa = sessao.codigoEmpresa;
    dados.criadoPor = sessao.cpf;
    dados.status = "SCA0001";
    console.log(this.url + this.confiAgenda, JSON.stringify(dados));
    return this.http.post(this.url + this.confiAgenda, dados);
  }
  alterarConfigAgenda(dados: AgendaConfigForm) {
    console.log(
      "alterarConfigAgenda",
      this.url +
        this.confiAgenda +
        dados.id +
        `/${new Date().toISOString()}/${sessao.sessao_id}`,
      JSON.stringify(dados)
    );
    return this.http.put(
      this.url +
        this.confiAgenda +
        dados.id +
        `/${new Date().toISOString()}/${sessao.sessao_id}`,
      dados
    );
  }
  alterConfigAgenda(registro: AgendaConfigForm) {
    let result;
  }
  excluirConfigAgenda(id: string, dados?) {
    //console.log('ConfigAgenda-Excluir :' + this.url + this.confiAgenda + id)
    return this.http.delete(this.url + this.confiAgenda + id);
    // return this.http.put(this.url + this.confiAgenda + id, dados)
  }

  async cadastrarConfiguracaoAgenda(
    configAgenda: {
      configEmpresa?: boolean;
      medico?: string;
      registro?: AgendaConfigForm;
    }[],
    horariosFechados: { medico?: string; registro?: EventCalender[] }[]
  ) {
    console.log("cadastro");
    let result = {
      contConfig: configAgenda.length,
      contHorarios: 0,
      emitConfig: 0,
      emitHorario: 0,
      falidConfig: [],
      falidHorario: [],
      statusConfig: false,
      statusHorarios: false,
    };
    for (let value of configAgenda) {
      let registro: AgendaConfigForm = value.registro;
      registro.cpfMedico = value.medico.toString();
      registro.userConfigEmpresa = value.configEmpresa;
      // if (registro.cpfMedico != sessao.codigoEmpresa.toString())
      try {
        Object.keys(registro).map((v) => {
          if (!registro[v]) {
            delete registro[v];
          }
        });
        if (registro.id) {
          await this.alterarConfigAgenda(registro)
            .toPromise()
            .then(() => (result.emitConfig += 1));
        } else {
          await this.cadastrarConfigAgenda(registro)
            .toPromise()
            .then((res) => (result.emitConfig += 1))
            .catch((error) => {
              let erro = { mensagem: error, item: registro };
              result.falidConfig.push(erro);
            });
        }
      } catch {}
    }

    for (let item of horariosFechados) {
      let getList = await this.getAgendaFechada(
        item.medico,
        sessao.codigoEmpresa
      );
      for (let dado of getList) {
        console.log("=====>", dado.id);
        await this.excluirAgendaFechada(dado.id);
      }
      for (let value of item.registro.filter(
        (value) => value.id != "feriado" && !value.consulta
      )) {
        result.contHorarios++;
        let registro = new AgendaFechadaForm();
        let horario = value;
        registro.diaTodo = horario.allDay;
        registro.cpfMedico = item.medico;
        registro.criadoPor = sessao.cpf;
        registro.codigoEmpresa = sessao.codigoEmpresa;
        registro.start = horario.start;
        registro.end = horario.end;
        registro.descricao = horario.title ? horario.title : "";
        registro.criadoEm = new Date().toISOString();
        registro.status = horario.status;
        await this.cadastrarAgendaFechada(registro)
          .toPromise()
          .then((res) => (result.emitHorario += 1))
          .catch((error) => {
            let erro = { mensagem: error, item: registro };
            result.falidHorario.push(erro);
          });
      }
    }

    if (result.contConfig == result.emitConfig) result.statusConfig = true;
    if (result.contHorarios == result.emitHorario) result.statusHorarios = true;
    console.log("***----result----***", result);
    return result;
  }

  //**************************END ConfigAgenda **********************************

  //************************** AgendaFechada **********************************
  async getAgendaFechada(cpf, codigoEmpresa) {
    let result: AgendaFechadaForm[] = [];
    await this.listarAgendaFechada()
      .toPromise()
      .then((res) => {
        result = res.filter(
          (value) => value.cpfMedico == cpf && codigoEmpresa == codigoEmpresa
        );
      });
    return result;
  }
  listarAgendaFechada(id?) {
    //console.log('AgendaFechada :' + this.url + this.agendaFechada)
    if (id) {
      return this.http.get<AgendaFechadaForm[]>(
        this.url + this.agendaFechada + id
      );
    }
    return this.http.get<AgendaFechadaForm[]>(this.url + this.agendaFechada);
  }
  cadastrarAgendaFechada(dados: any) {
    dados.criadoPor = sessao.cpf;
    dados.codigoEmpresa = sessao.codigoEmpresa;
    console.log(
      "cadastrarAgendaFechada",
      this.url + this.agendaFechada,
      JSON.stringify(dados)
    );
    return this.http.post(this.url + this.agendaFechada, dados);
  }
  alterarAgendaFechada(id: string, dados) {
    //console.log('AgendaFechada-Alterar :' + this.url + this.agendaFechada + id, dados)
    return this.http.put(this.url + this.agendaFechada + id, dados);
  }

  async excluirAgendaFechada(id: string) {
    let result = false;
    await this.http
      .delete(
        this.url +
          this.agendaFechada +
          `${id}/${new Date().toISOString()}/${sessao.sessao_id}`
      )
      .toPromise()
      .then((res) => (result = true))
      .catch((res) => {});
    return result;
    // return this.http.put(this.url + this.agendaFechada + id, dados)
  }
  //**************************END AgendaFechada **********************************

  // //************************** agendaFeriados **********************************
  // listarAgendaFeriados(id?) {
  //   //console.log('AgendaFeriados :' + this.url + this.agendaFeriados)
  //   if (id) {
  //     return this.http.get(this.url + this.agendaFeriados + id)
  //   }
  //   return this.http.get(this.url + this.agendaFeriados)
  // }
  // cadastrarAgendaFeriados(dados: any) {
  //   //console.log('AgendAgendaFeriadosa-Cadastrar :' + this.url + this.agendaFeriados, dados)
  //   return this.http.post(this.url + this.agendaFeriados, dados)
  // }

  // alterarAgendaFeriados(id: string, dados) {
  //   //console.log('AgendaFeriados-Alterar :' + this.url + this.agendaFeriados + id, dados)
  //   return this.http.put(this.url + this.agendaFeriados + id, dados)
  // }

  // excluirAgendaFeriados(id: string, dados?) {
  //   //console.log('AgendaFeriados-Excluir :' + this.url + this.agendaFeriados + id)
  //   return this.http.delete(this.url + this.agendaFeriados + id)
  //   // return this.http.put(this.url + this.agendaFeriados + id, dados)
  // }
  // //**************************END agendaFeriados **********************************

  //************************** agendaHorarioDiferente **********************************
  listarAgendaHorarioDiferente(id?) {
    //console.log('AgendaHorarioDiferente :' + this.url + this.agendaHorarioDiferente)
    if (id) {
      return this.http.get(this.url + this.agendaHorarioDiferente + id);
    }
    return this.http.get(this.url + this.agendaHorarioDiferente);
  }
  cadastrarAgendaHorarioDiferente(dados: any) {
    //console.log('AgendAgendaHorarioDiferentea-Cadastrar :' + this.url + this.agendaHorarioDiferente, dados)
    return this.http.post(this.url + this.agendaHorarioDiferente, dados);
  }
  alterarAgendaHorarioDiferente(id: string, dados) {
    //console.log('AgendaHorarioDiferente-Alterar :' + this.url + this.agendaHorarioDiferente + id, dados)
    return this.http.put(this.url + this.agendaHorarioDiferente + id, dados);
  }
  excluirAgendaHorarioDiferente(id: string, dados?) {
    //console.log('AgendaHorarioDiferente-Excluir :' + this.url + this.agendaHorarioDiferente + id)
    return this.http.delete(this.url + this.agendaHorarioDiferente + id);
    // return this.http.put(this.url + this.agendaHorarioDiferente + id, dados)
  }
  //**************************END agendaHorarioDiferente **********************************

  //************************** Horarios **********************************
  registrarHorarios(reg) {
    return this.http.post(this.url + this.horarios, reg);
  }
  alterarHorarios(id: string, dados) {
    //console.log('Horarios-Alterar :' + this.url + this.horarios + id, dados)
    return this.http.put(this.url + this.horarios + id, dados);
  }
  excluirHorarios(id: string) {
    //console.log('Horarios-Excluir :' + this.url + this.horarios + id)
    return this.http.delete(this.url + this.horarios + id);
  }
  listarHorarios(start, end) {
    let nStart = parseInt(start);
    let nEnd = parseInt(end);
    let hora = [{ h: JSON.stringify(nStart), m: "00" }];
    for (let i = 0; nStart <= nEnd; i++) {
      let m = JSON.stringify(parseInt(hora[i].m) + 30);
      let h = parseInt(hora[i].h);
      if (m == "60") {
        m = "00";
        h++;
        nStart++;
      }
      if (h <= nEnd) {
        // //console.log(` passou : ${h}:${end}`)
        hora.push({
          h:
            JSON.stringify(h).length < 2
              ? "0" + JSON.stringify(h)
              : JSON.stringify(h),
          m: m,
        });
      }
    }

    // delete hora[hora.length]

    //Correcões

    if (hora[0].h.length < 2) {
      hora[0].h = "0" + hora[0].h;
    }

    let verficiarUltimo = hora[hora.length - 1].m;
    if (parseInt(verficiarUltimo) > parseInt(end.split(":")[1])) {
    }

    return hora;

    // let hora = [{ h: '0', m: '00' }]
    // for (let i = 0; hora[hora.length - 1].h != '24'; i++) {
    //   let m = JSON.stringify(parseInt(hora[i].m) + 30)
    //   let h = parseInt(hora[i].h)
    //   if (m == '60') {
    //     m = '00'
    //     h++
    //   }
    //   hora.push({ h: JSON.stringify(h).length < 2 ? '0'+JSON.stringify(h) : JSON.stringify(h), m: m })
    // }
    // delete hora[hora.length - 1]
    // return hora
  }
  listarHorarios_personalizado(start: string, limit: number, range: number) {
    let h = parseInt(start.split(":")[0]);
    let m = parseInt(start.split(":")[1]);
    let hora = [];
    hora.push({
      h:
        JSON.stringify(h).length < 2
          ? "0" + JSON.stringify(h)
          : JSON.stringify(h),
      m: m > 0 ? JSON.stringify(m) : "00",
    });
    //console.log(start, limit, range, h, m)
    for (let i = 0; i <= limit; i++) {
      m = m + range;
      if (m == 60) {
        m = 0;
        h++;
      }
      hora.push({
        h:
          JSON.stringify(h).length < 2
            ? "0" + JSON.stringify(h)
            : JSON.stringify(h),
        m: m > 0 ? JSON.stringify(m) : "00",
      });
    }

    return hora;

    // let hora = [{ h: '0', m: '00' }]
    // for (let i = 0; hora[hora.length - 1].h != '24'; i++) {
    //   let m = JSON.stringify(parseInt(hora[i].m) + 30)
    //   let h = parseInt(hora[i].h)
    //   if (m == '60') {
    //     m = '00'
    //     h++
    //   }
    //   hora.push({ h: JSON.stringify(h).length < 2 ? '0'+JSON.stringify(h) : JSON.stringify(h), m: m })
    // }
    // delete hora[hora.length - 1]
    // return hora
  }
  buscarHorarios() {
    return this.http.get(this.url + this.horarios);
  }

  async getFeriados(ano: number) {
    let pushDates = [];
    const tokken = "cm9kcmlnb3BhdWxvLnM5N0BnbWFpbC5jb20maGFzaD0xMDQ0MzI0Mjk";
    const api = `https://api.calendario.com.br/?json=true&ano=${ano}&estado=SP&cidade=SAO_PAULO&token=${tokken}`;
    await this.http
      .get(api)
      .toPromise()
      .then(async (reg: any[]) => {
        let filter = reg.filter((r) => {
          return r.type_code != "4" && r.type_code != "9";
        });
        let regDates = [];
        filter.forEach((d) => {
          let valid = regDates.findIndex((x) => x.date == d.date);
          if (valid < 0) {
            regDates.push(d);
          }
        });
        let datas = regDates.map((r: any) => {
          function FormataStringData(data) {
            var dia = data.split("/")[0];
            var mes = data.split("/")[1];
            var ano = data.split("/")[2];
            return (
              ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2)
            );
            // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
          }
          return { date: FormataStringData(r.date), name: r.name };
        });
        await datas.forEach((reg) => {
          let feriados = {
            id: "feriado",
            start: reg.date,
            overlap: true,
            rendering: "background",
            color: "#707070",
            description: "OLA",
          };
          let desc = {
            id: "feriado",
            feriado: true,
            start: reg.date,
            title: reg.name,
            backgroundColor: "#ffffff00",
            textColor: "#000",
            description: reg.name,
          };
          pushDates.push(feriados);
          pushDates.push(desc);
        });
      });
    return pushDates;
  }
  getFeriadosOld(ano: number) {
    const tokken = "cm9kcmlnb3BhdWxvLnM5N0BnbWFpbC5jb20maGFzaD0xMDQ0MzI0Mjk";
    const api = `https://api.calendario.com.br/?json=true&ano=${ano}&estado=SP&cidade=SAO_PAULO&token=${tokken}`;
    return this.http.get(api);
  }
  getDatasDisponiveis(start: Date, end: Date, feriados) {
    for (
      var dates = [], dt = new Date(start);
      dt < end;
      dt.setDate(dt.getDate() + 1)
    ) {
      const semana = dt.getDay();
      if (semana < 5) {
        let d = new Date(dt).toISOString().slice(0, 10);
        dates.push(d);
      }
    }
    let getDates = dates;
    const dates_valid = [];
    getDates.forEach((d) => {
      let validar = feriados.findIndex((f) => f == d);
      if (validar < 0) {
        dates_valid.push(d);
      }
    });
    return dates_valid;
  }
  genereteRangeDatas(start: Date, end: Date) {
    for (
      var dates = [], dt = new Date(start);
      dt < end;
      dt.setDate(dt.getDate() + 1)
    ) {
      let d = new Date(dt).toISOString().slice(0, 10);
      dates.push(d);
    }
    let getDates = dates;
    return getDates;
  }
}
