import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { stat } from 'fs';
import { BuscarDataService } from './buscar-data.service';
import { SessaoUser } from '../user.reg';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = new environment().api
  constructor(private http: HttpClient, private date: BuscarDataService) { }

  async activeLogin(cpf, active?, tipoUsuario?) {
    let status = null
    let hora = this.date.horaAgora(true)
    let coordenada
    let codigoEmpresa
    await navigator.geolocation.getCurrentPosition(res => {
      // coordenada = res.coords
      console.log('COORDENADAS', res)
    })
    if (active) {
      status = 'SDO0025'
      if (tipoUsuario == 'TIPUSU5') {
        codigoEmpresa = 7
      } else {
        codigoEmpresa = sessao.codigoEmpresa
      }
      this.http.post(`${this.url}DiarioAcessoUsuario/`, { cpf: cpf, status: status, codigoEmpresa: codigoEmpresa }).toPromise()
        .then(
          (r) => { console.log(r) }
        ).catch((erro) => { console.log('erro', erro) })
    }
    else {
      status = 'SDO0044'
      console.log(`${this.url}DiarioAcessoUsuario/${cpf}/${status}/${hora}`)
      this.http.post(`${this.url}DiarioAcessoUsuario/${cpf}/${status}/${hora}`, null).toPromise()
        .then(
          (r) => { console.log(r) }
        ).catch((erro) => { console.log('erro', erro) })
    }
    console.log('RTRTRT ,', `${this.url}DiarioAcessoUsuario/`, { cpf: cpf, status: active })
  }
  get() {
    return this.http.get(this.url + 'UsuariosLogados')
  }
}
