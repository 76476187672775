import { Component, OnInit } from '@angular/core';
import { FormulariosService } from 'src/app/service/formularios.service';
import { FormBuilder } from '@angular/forms';
import { UsuarioService } from 'src/app/service/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cadastro-estrutura-organizacional',
  templateUrl: './cadastro-estrutura-organizacional.component.html',
  styleUrls: ['./cadastro-estrutura-organizacional.component.scss']
})
export class CadastroEstruturaOrganizacionalComponent implements OnInit {
  estruturaFormulario : any
  constructor(
    private formularios: FormulariosService,
    private form: FormBuilder,
    private serviceUsuario: UsuarioService,
    private router : Router,
    private url: ActivatedRoute,
    private serivceEmpresa :  ConfiguracaoInicialService,
    private mensagem : ToastrService
  ) { 
    this.estruturaFormulario = this.formularios.formularioCadastroEmpresa()
  }

  ngOnInit() {
  }

}
