export class SaldoContaContabil{
    id: string
    codigoEmpresa: number
    dataDeLancamento: string
    contaContabil: string
    saldoInicial: number
    saldoPeriodo: number
    saldoAcumuladoExercicio: number
    periodoContabil: string
    exercicioContabil: string
    criadoPor: string
    criadoEm: string
    status: string
    periodoFiscal: string
}
export class  SIS_LEGTRIB {
    id: string
    pais: string
    regimeTributario: string
    tabelaFiscal: string
    faixaFiscal: string
    baseReferencia: string
    limiteSuperior: number
    limiteInferior: number
    impostoPorFora: string
    aliquota: number
    deducao: number
    moeda: string
    valorExcedente: number
    aliquotaExcedente: number
    aliquotaPresuncao: number
    aliquotaFolhaPagamento: number
    criadoPor: string
    criadoEm: string
    status: string
  }