import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { MenuMaster } from "./modal";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Router } from "@angular/router";
import { UsuarioService } from "../service/usuario.service";
import { FormUsuario } from "../usuario/model";
import { BuscarDataService } from "../service/buscar-data.service";
import { ToastrService } from "ngx-toastr";
import { MonitorClinicoService } from "../service/monitor-clinico.service";
import { AgendaForm, HorariosForm } from "../agenda/agenda/model";
import { AgendaService } from "../service/agenda.service";
import { MatSnackBar } from "@angular/material";
import { environment } from "src/environments/environment";
import { VideoChamadaService } from "../service/video-chamada.service";
import { videoChamadaFrom } from "../atendimento-clinico/sala-espera/model";
import { MensagemService } from "../service/mensagem.service";
import { LoginService } from "../service/login.service";
import { DiarioDeAcessoService } from "../service/diario-de-acesso/diario-de-acesso.service";
import { SessaoUser } from "../user.reg";
import {
  UploadArquivosService,
  UploadArquivo,
} from "../service/upload-arquivos.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CadastroDadosProfissionaisService } from "../service/cadastro-dados-profissionais.service";
import { AutorizacaoAcessoService } from "../service/autorizacao-acesso.service";
import { FotoPerfilService } from "../service/foto-perfil.service";
import { BnNgIdleService } from "bn-ng-idle";
import { UsuariosLogadosService } from "../service/usuarios-logados.service";
import { StatusDeSessao } from "../service/diario-de-acesso/statusDeSessao.class";
import { UsuariosLogadosModal } from "../usuarios-logados/usariosLogadosModal";
import { TokenDeAcessoService } from "../service/token-de-acesso.service";
import { ConsultoriosMedicosService } from "../service/consultorios-medicos/consultorios-medicos.service";
import { ConsultorioMedico } from "../definir-consultorios-medicos/modelsDefinirConsultorioMedico";
import { FiltrarDadosService } from "../service/filtrar-dados.service";
import { CronometroService } from "../service/cronometro.service";
import { FichaGeralService } from "../service/ficha-geral-empresa/ficha-geral-empresa.service";
import { ChatService } from "../service/conversas/chat.service";

const url = new environment().api;
const sessao = new SessaoUser();
export class EstruturaMenu {
  tipo: number;
  menuName: string;
  menuID: string;
  menuURL: string;
  ordem: string;
  children: any;
  routerActive?: boolean;
  checked?: boolean;
}
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  host: { "window:beforeunload": "doSomething" },
})
export class HomeComponent implements OnDestroy {
  interval_login: NodeJS.Timer;
  listAutorizacoes = {
    ITMPERFIL1: [],
    ITMPERFIL2: [],
    ITMPERFIL3: [],
  };
  verificAutorizacao: NodeJS.Timer;
  verificEmpresa: any;
  master: boolean;
  infoConsultorio = new ConsultorioMedico();
  menu_superir_active: string[] = [];
  notificacaoMensagem: number;
  sessaoMenuID: any;
  codSessaoMenu: string;

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {
    //console.log('TENTOU SAIR!')
    if (window.location.href.indexOf("localhost") < 0) {
      this.sair(true);
    }
    return false;
  }

  @ViewChild("videoElement", { static: false }) videoElement: ElementRef;

  menu_sessoes = [
    {
      cod: "RC10",
      titulo: "Rotina Clínica",
      icon: "fas fa-user-md",
      icon_hover: "fas fa-user-md text-info",
      iconCheck: false,
      iconCheckOuver: false,
    },
    {
      cod: "GS20",
      titulo: "Gestão Simplificada",
      icon: "fas fa-calculator",
      icon_hover: "fas fa-calculator text-info",
      iconCheck: false,
      iconCheckOuver: false,
    },
    {
      cod: "AN30",
      titulo: "Analíticos",
      icon: "far fa-chart-bar font-20",
      icon_hover: "far fa-chart-bar text-info",
      iconCheck: false,
    },
    {
      cod: "AJ50",
      titulo: "Ajustes",
      icon: "fas fa-cogs",
      icon_hover: "fas fa-cogs text-info",
      iconCheck: false,
    },
    {
      cod: "SUS80",
      titulo: "SUS",
      icon: "fas fa-briefcase-medical",
      icon_hover: "fas fa-briefcase-medical text-info",
      iconCheck: false,
    },
    {
      cod: "PDE90",
      titulo: "Ajustes",
      icon: "fas fa-project-diagram",
      icon_hover: "fas fa-project-diagram text-info",
      iconCheck: false,
    },
    {
      cod: "SU0000",
      titulo: "Saúde Suplementar",
      icon: "fas fa-hospital-user",
      icon_hover: "fas fa-hospital-user text-info",
      iconCheck: false,
    },
  ];
  menu_superior = [];
  video: any;
  estrutura_menu: MenuMaster[] = [];
  sessao;
  esconderMenu: boolean = true;
  houver: boolean;
  houverInfo: boolean;
  houverUser: boolean;
  tituloPage: string;
  loading: boolean;
  hoje: any;
  iconRotina: any;
  iconGS: any;
  iconAnalitico: any;
  iconRelacionamento: any;
  iconConfig: any;
  infoContato: any;
  minhaConta: boolean;

  popup = {
    salaEspera: true,
    ticket: false,
    agenda: false,
    pesquisa: false,
    convidar: false,
  };

  modal: boolean;
  teste = "ola";
  cpf = sessao.cpf;
  viewFotoPerfil;

  idUsuarioLogado;

  tempoOciosidade = "";

  telaSair: boolean;

  private _transformer = (node: MenuMaster, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      menuName: node.menuName,
      menuURL: node.menuURL,
      menuID: node.menuID,
      level: level,
      active: node.routerActive,
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  codSessao: any;
  verificar: NodeJS.Timer;

  reservas = new AgendaForm();
  dataSelecionada: AgendaForm = {};

  verSolicitacoes: boolean;
  verHorariosLivres: boolean;
  reservaSelec: AgendaForm;
  horarios: any[];
  sugerirHorario: boolean;
  abrirCobranca: boolean;
  userName: string;
  codTransacao: string;
  usuarioId: string;
  menu: MenuMaster[];
  viewVideoChamada: boolean;
  infoVideoChamada: videoChamadaFrom;
  boxVideoChamada: boolean;
  verificarEncerramento: any;
  backgroud = {
    rotinaClinica: true,
    gestaoSimplificada: false,
    analitico: false,
    conversas: false,
    ajustes: false,
    sus: false,
    processo: false,
    saudeSuplementar: false,
    areaPaciente: false,
  };
  mensagem: any;
  mensagemColor: string;
  nameEmpresa: string;
  cod_assinante: string;
  fotoPerfil: any;
  crm: string;
  unidadeFederal: any;
  formaTratamento: string;
  loadingMenu: boolean;
  semAutorizacao: boolean;
  ocioso: boolean;
  cod_empresa: number;
  especialidade;
  registroUsuarioLogado: UsuariosLogadosModal;
  paciente;
  infoUser = {
    horaSistema: new Date().toISOString(),
    usuario: sessao.name,
    localTrabalho: `${sessao.codigoEmpresa} - ${sessao.nameEmpresa}`,
    dispositivo: null,
    acessoAtual: null,
    tempoAcesso: null,
    transacaoAtual: null,
    acessoAnterior: null,
    localTrabalhoAnterior: null,
    dispositivoAnterior: null,
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private _consultorio: ConsultoriosMedicosService,
    private data: BuscarDataService,
    private _videoChamada: VideoChamadaService,
    private toast: ToastrService,
    private _mensagem: MensagemService,
    private diarioDeAcessoService: DiarioDeAcessoService,
    private service_foto: FotoPerfilService,
    private sanitizer: DomSanitizer,
    private idleService: BnNgIdleService,
    private _autorizacao: AutorizacaoAcessoService,
    private usuarioslogadosService: UsuariosLogadosService,
    private statusDeSessao: StatusDeSessao,
    private _tokken: TokenDeAcessoService,
    private _filter: FiltrarDadosService,
    private _cronometro: CronometroService,
    private _empresa: FichaGeralService,
    private _chat: ChatService
  ) {
    this.loading = true;
    this.hoje = this.data.hoje();

    this.usuarioId = sessao.sessao_id;
    this.userName = sessao.name;
    this.crm = sessao.crm;
    this.unidadeFederal = sessao.unidadeFederativa;
    this.formaTratamento = sessao.formaTratamento;
    this.nameEmpresa = sessao.nameEmpresa;
    this.cod_assinante = sessao.assinante;
    this.cod_empresa = sessao.codigoEmpresa;
    this.especialidade = sessao.especialidade;
    let horaLogin = sessionStorage.getItem("horaLogin");

    if (horaLogin) {
      this.infoUser.acessoAtual = horaLogin;
    } else {
      horaLogin = new Date().toISOString();
      sessionStorage.setItem("horaLogin", horaLogin);
      this.infoUser.acessoAtual = horaLogin;
    }
    setInterval(() => {
      this.infoUser.horaSistema = new Date().toISOString();
      this.infoUser.tempoAcesso = this._cronometro.diferencaHorario(
        horaLogin,
        new Date().toISOString()
      );
    }, 1000);

    //console.log('RODRIGO *-*-*-', sessao)
    window.onbeforeunload = function (e) {};
    if (sessao.tipoUsuario == "TIPUSU5") {
      this.paciente = true;
    } else {
      this.paciente = false;
    }
    this._mensagem.clear();
  }

  async ngOnInit() {
    clearInterval(this.verificAutorizacao);
    // this.video = this.videoElement.nativeElement;
    // this.initCamera({ video: true, audio: true })

    this.PostDiarioAcesso(this.statusDeSessao.status.conectada);

    this.getVideoChamada();
    this.selectVideoChamada();
    this.getFotoPerfil();
    this.OciosidadeTime();
    this.PostUsuarioLogado();
    this.getAcessoEmpresas();
    this.getConsultorio();

    window.onbeforeunload = function () {
      return "Deseja mesmo sair do site?";
    };

    this.verificar = setInterval(() => {
      if (!sessionStorage.getItem("tokken")) {
        this.toast.error("Realize o login para acessar a plataforma");
        this.sair();
        return;
      }
      if (localStorage.getItem("tokkenRemove")) {
        localStorage.removeItem("tokkenRemove");
        this.sair();
        return;
      }
      if (!sessionStorage.getItem("companyId")) {
        this.toast.error("Registro não encontrado!");
        this.sair();
      }
    }, 3000);
    await this.verificar;

    this.loading = false;
    setInterval(() => this.getMensagem(), 1000);

    await this.construirMenu();
    await this.getAutorizacoes();
    this.verificarMensagens();

    let ip = await this.diarioDeAcessoService.pegarIp();
    this.infoUser.dispositivo = ip;
    let getUltimoAcesso = await this.diarioDeAcessoService.getUltimoRegistro(
      sessao.cpf
    );
    this.infoUser.dispositivoAnterior = getUltimoAcesso.ipLocal;
    this.infoUser.localTrabalhoAnterior = `${
      getUltimoAcesso.codigoEmpresa
    } ${await this._empresa.getNameEmpresa(getUltimoAcesso.codigoEmpresa)}`;
    this.infoUser.acessoAnterior = getUltimoAcesso.horarioLocal;
  }

  OciosidadeTime() {
    //this.tempoOciosidade = 5
    this.usuarioslogadosService
      .ConsultarLimiteOciosidade()
      .toPromise()
      .then((res: any) => {
        this.tempoOciosidade = res;
        this.idleService
          .startWatching(this.tempoOciosidade)
          .subscribe((isUserInactive) => {
            if (isUserInactive) {
              this.ocioso = true;
            }
          });
      });
  }

  async PostDiarioAcesso(status) {
    await this.diarioDeAcessoService.PostDiarioAcesso(status);
  }

  PostUsuarioLogado() {
    this.usuarioslogadosService
      .Post(sessao.cpf)
      .toPromise()
      .then((res: UsuariosLogadosModal) => {
        let id = res.id;

        sessionStorage.setItem("logadoId", id);
        this.idUsuarioLogado = res.id;
        this.registroUsuarioLogado = res;
      })
      .catch((err) => console.log(err));

    this.PutUsuariosLogados();
  }

  PutUsuariosLogados() {
    this.interval_login = setInterval(() => {
      // //console.log('SET TIMEOUT', this.registroUsuarioLogado)
      if (!this.registroUsuarioLogado) return;
      this.usuarioslogadosService
        .Put(this.registroUsuarioLogado, this.idUsuarioLogado)
        .toPromise()
        .then((res: any) => {})
        .catch((err) => console.log("ERRO TIMEOUT", err.error));
    }, 4000);

    this.interval_login;
  }

  ngOnDestroy() {
    clearInterval(this.interval_login);
    clearInterval(this.verificAutorizacao);
    clearInterval(this.verificEmpresa);
    //this.PostDiarioAcesso(this.statusDeSessao.status.normalLogout)
  }

  initCamera(config: any) {
    var browser = <any>navigator;

    browser.getUserMedia =
      browser.getUserMedia ||
      browser.webkitGetUserMedia ||
      browser.mozGetUserMedia ||
      browser.msGetUserMedia;

    browser.mediaDevices.getUserMedia(config).then((stream) => {
      this.video.src = window.URL.createObjectURL(stream);
      this.video.play();
    });
  }
  async getAcessoEmpresas() {
    this.verificEmpresa = setInterval(async () => {
      let empresas = await this._tokken.getEmpresas(sessao.cpf);
      sessionStorage.setItem("listEmpresa", JSON.stringify(empresas));
    }, 5000);

    this.verificEmpresa;
  }
  async getAutorizacoes(menuList?, refresh?) {
    if (this.paciente) {
      let valid_menu_sessoes = [];

      ///MENU PADRÃO
      valid_menu_sessoes.push(
        {
          cod: "AP60",
          titulo: "Área do paciente",
          icon: "fas fa-hand-holding-medical",
          icon_hover: "fas fa-hand-holding-medical text-info",
          iconCheck: false,
        },
        {
          cod: "PM70",
          titulo: "Minha conta",
          icon: "fas fa-user-circle",
          icon_hover: "fas fa-user-circle text-info",
          iconCheck: false,
        },
        {
          cod: "CN40",
          titulo: "Conversas",
          icon: "far fa-comments",
          icon_hover: "far fa-comments text-info",
          iconCheck: false,
          iconCheckOuver: false,
        }
      );
      /////
      this.menu_sessoes = this._filter.removeDublic(valid_menu_sessoes, "cod");
      //console.log('valid_menu_sessoes', valid_menu_sessoes)
      this.menu_sessoes.forEach((value) => {
        if (value.cod == this.codSessao) {
          value.iconCheck = true;
        } else {
          value.iconCheck = false;
        }
      });
      this.loadingMenu = false;
      this.goTransacao(valid_menu_sessoes[0].cod, valid_menu_sessoes[0].titulo);
      return;
    }
    console.info("CPF -- RENNAN", sessao.cpf);

    if (
      sessao.cpf == "ADMIN" ||
      sessao.cpf == "CLINICS" ||
      sessao.cpf == "WATCH" ||
      sessao.cpf === "10131122020"
    ) {
      let valid_menu_sessoes = [];
      console.log("Testando validação de menus");

      ///MENU PADRÃO
      valid_menu_sessoes.push(
        {
          cod: "RC10",
          titulo: "Rotina Clínica",
          icon: "fas fa-laptop-medical",
          icon_hover: "fas fa-laptop-medical text-info",
          iconCheck: false,
          iconCheckOuver: false,
        },
        {
          cod: "GS20",
          titulo: "Gestão Simplificada",
          icon: "fas fa-calculator",
          icon_hover: "fas fa-calculator text-info",
          iconCheck: false,
          iconCheckOuver: false,
        },
        {
          cod: "AN30",
          titulo: "Analíticos",
          icon: "far fa-chart-bar font-20",
          icon_hover: "far fa-chart-bar text-info",
          iconCheck: false,
        },
        {
          cod: "AJ50",
          titulo: "Ajustes",
          icon: "fas fa-cogs",
          icon_hover: "fas fa-cogs text-info",
          iconCheck: false,
        },
        {
          cod: "AP60",
          titulo: "Área do paciente",
          icon: "fas fa-hand-holding-medical",
          icon_hover: "fas fa-hand-holding-medical text-info",
          iconCheck: false,
        },
        {
          cod: "PM70",
          titulo: "Minha conta",
          icon: "fas fa-user-circle",
          icon_hover: "fas fa-user-circle text-info",
          iconCheck: false,
        },
        {
          cod: "CN40",
          titulo: "Conversas",
          icon: "far fa-comments",
          icon_hover: "far fa-comments text-info",
          iconCheck: false,
          iconCheckOuver: false,
        }
      );

      console.info("Informações de teste...", valid_menu_sessoes);
      /////
      this.menu_sessoes = this._filter.removeDublic(valid_menu_sessoes, "cod");
      //console.log('valid_menu_sessoes', valid_menu_sessoes)
      this.menu_sessoes.forEach((value) => {
        if (value.cod == this.codSessao) {
          value.iconCheck = true;
        } else {
          value.iconCheck = false;
        }
      });
      this.loadingMenu = false;
      this.goTransacao("RC10", "Rotina Clínica", true);
      return;
    }

    // return
    this.loadingMenu = true;
    let result = await this._autorizacao.getAcesso(
      sessao.cpf,
      sessao.codigoEmpresa
    );

    this.listAutorizacoes.ITMPERFIL1 = result
      .filter((value) => value.tipo == "ITMPERFIL1")
      .map((value) => value.valor);
    this.listAutorizacoes.ITMPERFIL2 = result
      .filter((value) => value.tipo == "ITMPERFIL2")
      .map((value) => value.valor);
    this.listAutorizacoes.ITMPERFIL3 = result
      .filter((value) => value.tipo == "ITMPERFIL3")
      .map((value) => value.valor);
    console.log("-->", this.listAutorizacoes);
    // let result = await this._autorizacao.getAcesso('45843259870', 7)
    this.verificAutorizacao = setInterval(() => {
      if (this.router.url != "/home") {
        this.router.navigateByUrl("/home");
      }
    }, 1000);
    if (result.length < 1) {
      this.semAutorizacao = true;
      this.menu_superior = [];
      this.dataSource.data = [];

      return;
    } else {
      this.semAutorizacao = false;
      clearInterval(this.verificAutorizacao);
    }
    let valid_menu_sessoes = [];

    let valid_menu_superior = [];

    let menu_list = [];

    let order_menu = [];

    await result.forEach((item) => {
      let get = this.menu_sessoes.filter((value) => value.cod == item.valor)[0];
      if (get) {
        valid_menu_sessoes.push(get);
      }
    });
    // setTimeout(() => {
    //   //console.log('RODRIGO', valid_menu_sessoes)

    // }, 3000);
    this.menu_superior = [
      {
        cod: "ICONE2",
        value: "academico",
        titulo: "Conteúdo acadêmico",
        icon: "fas fa-book",
      },
      {
        cod: "ICONE1",
        value: "salaEspera",
        titulo: "Sala de Espera",
        icon: null,
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAB4klEQVRIibXWTWtTURAG4Afrx6qKgmCtGPxEoYorET9xISIiCq4EN+5duPMH6MK/IqEbEUFbRJGqiFoXQkFxIYpuDJpUVGhrXNwT7mlykpub1heGc85kZt47M+fODZ2oYBI/MYGtCZt2bEEVjSDj2FXkNIlmJA/6IKkF21+4GfY1jPZynG0jahQQVSPbZ9gXnW+3jFYkHJ8XnNtxOtq/xgjmE791oCIr1yzuK+5RXZ7BDE5gGz7hR4FvKYxbXOomXoS1mnJ4iYfR+VLQTeFcD6Ld+JYgq2FnyuFLMNiOY/gbOc1jfw+yUVnj60Gq3UjIS3AZNxJPeKsHUSHiWzcV1sP4mrA9uRSiGIdkT/4Ge3VmNIe1y0G0SvZmL2AdPifIzg4aPC7dHF4F3UE8StgPXL72yfA0rEcsvupLJmrHefkgregs3ULQr49keBCijbL3p44hvEuQpeQuVpclmwnOZ3C1T6ImLvYKOpTQbZD1YgzXZMNxBGvwp02asttKNoTvlMloGO9DkLe4EMhTOCrP6GMZkhZ2KO7PE6zE90i3p1vA1IcPPuAArmMavxM2rWE7EelO9Z3KALgiz6hUj8pik/yT0pBfjv+CaXlWx1MG3XpUFvei/dgyxUxis+z/4OOw78A/cyes2HQaE7cAAAAASUVORK5CYII=",
      },
      {
        cod: "ICONE3",
        value: "agenda",
        titulo: "Agenda",
        icon: "far fa-calendar-alt",
      },
      {
        cod: "ICONE4",
        value: "ticket",
        titulo: "Senha de atendimento",
        icon: "fas fa-ticket-alt",
      },
    ];
    await result.forEach((item) => {
      let get = this.menu_superior.filter(
        (value) => value.cod == item.valor
      )[0];
      if (get) {
        valid_menu_superior.push(get);
      }
    });

    console.log("teste com Lucas ", this.menu_superior);

    if (menuList) {
      let codSessao = this.codSessao;
      let menus = [];
      if (codSessao) {
        menus = this.estrutura_menu.filter(
          (value) => value.menuID == codSessao
        );
        this.estrutura_menu.forEach((value) => {
          if (value.ordem) {
            order_menu.push({
              ordem: parseInt(value.ordem),
              menuID: value.menuID,
              titulo: value.menuName,
            });
          }
        });
        order_menu = order_menu.sort((a, b) => {
          return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
        });
        valid_menu_sessoes.forEach((menu) => {
          order_menu.filter((value) => {
            if (menu.cod == value.menuID) {
              menu.ordem = value.ordem;
              menu.titulo = value.titulo;
            }
          });
        });
      }
      if (menus.length < 1) menus = this.estrutura_menu;

      await result.forEach(async (item) => {
        await menus.forEach((menu) => {
          let get = menu.children.filter(
            (value) => value.menuID == item.valor
          )[0];
          if (get) {
            menu_list.push(get);
          }
        });
      });
      if (menu_list.length < 1) {
        this.dataSource.data = menus[0].children;

        console.log("MENU", menus);
      } else {
        this.dataSource.data = menu_list;
      }
    }

    valid_menu_sessoes.sort((a, b) =>
      a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0
    );

    ///MENU PADRÃO
    valid_menu_sessoes.push(
      {
        cod: "AP60",
        titulo: "Área do paciente",
        icon: "fas fa-hand-holding-medical",
        icon_hover: "fas fa-hand-holding-medical text-info",
        iconCheck: false,
      },
      {
        cod: "PM70",
        titulo: "Minha conta",
        icon: "fas fa-user-circle",
        icon_hover: "fas fa-user-circle text-info",
        iconCheck: false,
      },
      {
        cod: "CN40",
        titulo: "Conversas",
        icon: "far fa-comments",
        icon_hover: "far fa-comments text-info",
        iconCheck: false,
        iconCheckOuver: false,
      }
    );
    /////

    this.menu_sessoes = this._filter.removeDublic(valid_menu_sessoes, "cod");
    //console.log('valid_menu_sessoes', valid_menu_sessoes)
    this.menu_sessoes.forEach((value) => {
      if (value.cod == this.codSessao) {
        value.iconCheck = true;
      } else {
        value.iconCheck = false;
      }
    });

    this.menu_superior = valid_menu_superior;
    let menuSuperiorActive = this.menu_superior.map((value) => value.cod);
    this.menu_superir_active = menuSuperiorActive;

    this.loadingMenu = false;
    if (!refresh) {
      if (valid_menu_sessoes.length < 1) return;
      this.goTransacao(valid_menu_sessoes[0].cod, valid_menu_sessoes[0].titulo);
    }
    let checkedNv1 = [];
    // this.estrutura_menu.forEach(nivel_0 => {
    //   nivel_0.children.forEach((nivel_1, index) => {

    //   })
    //   this.estrutura_menu.forEach(nivel_0 => {
    //     nivel_0.children.forEach(nivel_1 => {
    //       nivel_1.children.forEach((nivel_2, index) => {
    //         let checked = this.listAutorizacoes.ITMPERFIL2.filter(v => v == nivel_2.menuID).length > 0

    //         if (!checked) {
    //           checkedNv1.push(nivel_1)
    //           delete nivel_2[index]
    //         } else {
    //           console.log(' --- > passou', nivel_2)
    //         }
    //       })
    //     })
    //   })
    // })
    console.log("fim", this.estrutura_menu);
    //
  }

  testeHouver(t) {
    //console.log(t)
  }

  async getFotoPerfil() {
    this.fotoPerfil = await this.service_foto.buscarFoto(sessao.cpf);
    //console.log('fotoPerfil', this.fotoPerfil)
    //
  }
  async getConsultorio() {
    let get = await this._consultorio.getConsultorioDoMedico(4, sessao.cpf);
    this.infoConsultorio = get;
  }
  async construirMenu() {
    this.http
      .get<Array<MenuMaster>>(`${url}MenuMaster`)
      .toPromise()
      .then(async (menuMaster) => {
        this.menu = menuMaster;
        menuMaster.forEach((v) => (v.routerActive = false));
        let routerActive = sessao.routerActive;

        if (routerActive.length > 0) {
          routerActive.forEach((router) => {
            menuMaster.filter((value) => {
              if (value.menuURL == router) {
                value.routerActive = true;
              }
            });
          });
        } else {
          setTimeout(() => {
            routerActive.forEach((router) => {
              menuMaster.filter((value) => {
                if (value.menuURL == router) {
                  value.routerActive = true;
                }
              });
            });
          }, 3000);
        }

        ///Remover menu que não faz parte da autorização (com base no terceiro filho)
        // //console.log('MENU', JSON.stringify(menu))
        //console.log('rodrigo list', this.listAutorizacoes)
        menuMaster.forEach((value, i, self) => {
          if (!value.menuID) {
            delete self[i];
          }
          if (!value.parent_MenuID) {
            delete self[i];
          }
        });

        if (this.listAutorizacoes.ITMPERFIL3.length > 0) {
          let remove = [];
          await menuMaster.forEach((value, index) => {
            this.listAutorizacoes.ITMPERFIL3.filter((item) => {
              let pai = item.slice(0, 5);
              if (
                value.parent_MenuID == pai &&
                value.menuID == item &&
                !value.active
              ) {
                value.active = true;
                remove.push({ index: index, active: true });
              } else if (value.parent_MenuID == pai) {
                remove.push({ index: index, active: false });
              }
            });
          });

          let active = remove.filter((v) => v.active);
          let noActive = remove.filter((v) => !v.active);

          active.forEach((v) => {
            noActive.filter((item, i) => {
              if (v.index == item.index) {
                delete noActive[i];
              }
            });
          });
          //console.log('rodrigo remove', active, noActive)
          let id_delete = new Set(noActive.map((v) => v.index));
          //console.log('rodrigo id_delete', id_delete)
          id_delete.forEach((index) => {
            if (index) {
              //console.log('delete 0', menuMaster[index])
              delete menuMaster[index];
            }
          });
        }
        ///////////////////////////////
        let estrutura_menu: EstruturaMenu[] = [];
        //PAI
        menuMaster.forEach((pai) => {
          if (pai.parent_MenuID == "*" && pai.tipoObjeto == "ITMPERFIL1") {
            estrutura_menu.push({
              tipo: 1,
              menuName: pai.menuName,
              menuID: pai.menuID,
              menuURL: pai.menuURL,
              ordem: pai.ordem,
              children: [],
            });
          }
        });

        menuMaster = menuMaster.filter(
          (value) =>
            value.tipoObjeto == "ITMPERFIL3" || value.tipoObjeto == "ITMPERFIL2"
        );
        estrutura_menu.sort(function (a, b) {
          return (
            parseInt(a.ordem ? a.ordem : "0") -
            parseInt(b.ordem ? b.ordem : "0")
          );
        });

        //END PAI

        // FILHO 1
        estrutura_menu.forEach((nivel_0) => {
          menuMaster.forEach((menu) => {
            if (nivel_0.menuID == menu.parent_MenuID) {
              let item = new EstruturaMenu();
              item.menuID = menu.menuID;
              item.menuName = menu.menuName;
              item.menuURL = menu.menuURL;
              item.ordem = menu.ordem;
              item.tipo = 2;
              item.children = [];
              nivel_0.children.push(item);
            }
          });
          nivel_0.children.sort(function (a, b) {
            return (
              parseInt(a.ordem ? a.ordem : "0") -
              parseInt(b.ordem ? b.ordem : "0")
            );
          });
        });

        //END FILHO 1
        // FILHO 2
        estrutura_menu.forEach((nivel_0) => {
          nivel_0.children.forEach((nivel_1, index) => {
            menuMaster.forEach((menu) => {
              if (nivel_1.menuID == menu.parent_MenuID) {
                let item = new EstruturaMenu();
                item.menuID = menu.menuID;
                item.menuName = menu.menuName;
                item.menuURL = menu.menuURL;
                item.ordem = menu.ordem;
                item.tipo = 2;
                item.children = [];
                item.routerActive = menu.routerActive;

                nivel_1.children.push(item);
              }
            });

            nivel_1.children.sort(function (a, b) {
              return (
                parseInt(a.ordem ? a.ordem : "0") -
                parseInt(b.ordem ? b.ordem : "0")
              );
            });
          });
        });

        //END FILHO 3
        estrutura_menu.forEach((nivel_0) => {
          nivel_0.children.forEach((nivel_1) => {
            nivel_1.children.forEach((nivel_2) => {
              menuMaster.forEach((menu) => {
                if (nivel_2.menuID == menu.parent_MenuID) {
                  let item = new EstruturaMenu();
                  item.menuID = menu.menuID;
                  item.menuName = menu.menuName;
                  item.menuURL = menu.menuURL;
                  item.ordem = menu.ordem;
                  item.tipo = 2;
                  item.children = [];
                  item.routerActive = menu.routerActive;
                  nivel_2.children.push(item);
                }
              });
              nivel_2.children.sort(function (a, b) {
                return (
                  parseInt(a.ordem ? a.ordem : "0") -
                  parseInt(b.ordem ? b.ordem : "0")
                );
              });
            });
          });
        });
        //END FILHO 3
        //END FILHO 4
        estrutura_menu.forEach((nivel_0) => {
          nivel_0.children.forEach((nivel_1) => {
            nivel_1.children.forEach((nivel_2) => {
              nivel_2.children.forEach((nivel_3) => {
                menuMaster.forEach((menu) => {
                  if (nivel_3.menuID == menu.parent_MenuID) {
                    let item = new EstruturaMenu();
                    item.menuID = menu.menuID;
                    item.menuName = menu.menuName;
                    item.menuURL = menu.menuURL;
                    item.ordem = menu.ordem;
                    item.tipo = 2;
                    item.children = [];
                    item.routerActive = menu.routerActive;
                    nivel_3.children.push(item);
                  }
                });
                nivel_3.children.sort(function (a, b) {
                  return (
                    parseInt(a.ordem ? a.ordem : "0") -
                    parseInt(b.ordem ? b.ordem : "0")
                  );
                });
              });
            });
          });
        });
        //END FILHO 4
        //console.log("********END************", estrutura_menu);
        console.log("estrutura_menu", estrutura_menu);
        this.dataSource.data = estrutura_menu;
        this.estrutura_menu = estrutura_menu;
        this.getAutorizacoes(true);
        // this.goTransacao('RC10')

        // this.dataSource.data = menus
      });
  }
  // verificAutorizacao(menus) {

  // }
  getParentys() {}

  goCOD(ref: string) {
    const cod = ref.toUpperCase();
    switch (cod) {
      case "RC10":
        this.router.navigateByUrl(`home/Agenda/${this.usuarioId}`);
        break;
      case "RC50":
        this.router.navigateByUrl(`home/Recepcao/${this.usuarioId}`);
        break;
      case "AC10":
        this.router.navigateByUrl(`home/SalaEspera/${this.usuarioId}`);
        break;
      case "AC60":
        this.router.navigateByUrl(`home/Consultorio/${this.usuarioId}`);
        break;
      case "AC60":
        this.router.navigateByUrl(`home/Consultorio/${this.usuarioId}`);
        break;

      default:
        this.toast.info(`Codigo ${cod} invalido`);
        break;
    }
  }
  async goTransacao(cod, name: string, master?) {
    await this.resetBackGround();
    //console.log('goTransacao', cod, name)
    this.menu_sessoes.forEach((value) => (value.iconCheckOuver = false));
    switch (cod) {
      case "RC10":
        this.backgroud.rotinaClinica = true;
        break;
      case "GS20":
        this.backgroud.gestaoSimplificada = true;
        break;
      case "AN30":
        this.backgroud.analitico = true;
        break;
      case "CN40":
        await this.router.navigateByUrl("home/conexoes");
        this.backgroud.conversas = true;
        break;
      case "AJ50":
        this.backgroud.ajustes = true;
        break;
      case "SUS80":
        this.backgroud.sus = true;
        break;
      case "PDE90":
        this.backgroud.processo = true;
        break;
      case "SU0000":
        this.backgroud.saudeSuplementar = true;
        break;
      case "AP60":
        this.backgroud.areaPaciente = true;
        break;
      case "PM70":
        this.router.navigateByUrl("home/MinhaConta");
        break;

      default:
        break;
    }

    this._mensagem.clear();
    this.codSessao = cod;

    this.tituloPage = name;
    this.infoUser.transacaoAtual = `${this.codSessao} - ${this.tituloPage}`;

    let index = this.estrutura_menu.map((x) => x.menuID).indexOf(cod);
    if (index >= 0) {
      let ord = this.estrutura_menu[index].children.sort(function (a, b) {
        return parseInt(a.ordem) - parseInt(b.ordem);
      });
      this.dataSource.data = ord;
    }

    // if (!master) {
    //   await this.getAutorizacoes(true, true)
    // }else{
    //   this.master = true
    // }

    if (this.codSessao != "CN40" && this.codSessao != "PM70") {
      this.router.navigateByUrl("home");
    }
    console.log("background : " + cod, this.backgroud);
  }

  popUp(ref) {
    this.modal = !this.modal;
    switch (ref) {
      case "salaEspera":
        this.popup.salaEspera = !this.popup.salaEspera;

        break;
      case "senha":
        this.popup.ticket = !this.popup.ticket;

        break;
      case "agenda":
        this.popup.agenda = !this.popup.agenda;

        break;
      case "pesquisa":
        this.popup.pesquisa = !this.popup.pesquisa;

        break;
      case "convidar":
        this.popup.convidar = !this.popup.convidar;

        break;

      default:
        break;
    }
  }

  reagendar(ref) {
    this.toast.warning("Por favor , vá até agenda e realize essa operação");
    this.router.navigateByUrl(`home/Agenda/${this.usuarioId}`);
  }
  selectVideoChamada() {
    this._videoChamada
      .getVideoChamda()
      .toPromise()
      .then((reg: Array<videoChamadaFrom>) => {
        let cpf = this.cpf;
        // //console.log(cpf)
        let verificar = reg.filter((x) => {
          return (
            x.destinatario == cpf &&
            x.status == "pendente" &&
            x.transacao != "AC10"
          );
        });
        // let verificar = reg.filter((x) => { return x.destinatario == cpf })
        if (verificar.length > 0) {
          if (this.viewVideoChamada) return;
          // setTimeout(()=>{this.viewVideoChamada = true},15000)
          this.viewVideoChamada = true;
          this.infoVideoChamada = verificar[verificar.length - 1];
          const id = this.infoVideoChamada.codigoUnico;
          this._videoChamada
            .banco()
            .doc(id)
            .collection("info")
            .get()
            .toPromise()
            .then((r) => {
              this.infoContato = r.docs[0].data();
              //console.log('OLHA > ', this.infoContato)
            });
          this.infoVideoChamada.status = "aceito";
          this._videoChamada
            .putVideoChamada(this.infoVideoChamada)
            .toPromise()
            .then(() => {});
          return;
          this.verificarEncerramento = setInterval(() => {
            this._videoChamada
              .getVideoChamda()
              .toPromise()
              .then((ref: Array<videoChamadaFrom>) => {
                let getEncerramento = ref.filter((x) => {
                  return x.destinatario == cpf && x.status == "encerrado";
                });
                // //console.log('encerramento >', getEncerramento)
                if (getEncerramento.length > 0) {
                  this.infoVideoChamada.status = "finalizado";
                  this._videoChamada
                    .putVideoChamada(this.infoVideoChamada)
                    .toPromise()
                    .then(() => (this.boxVideoChamada = false));
                }
              });
          }, 3000);
        } else {
        }
      });
  }
  abrirVideoChamada(aceitar) {
    const id = this.infoVideoChamada.codigoUnico;
    if (aceitar) {
      this.viewVideoChamada = false;
      this.boxVideoChamada = true;
      this.infoVideoChamada.status = "aceito";
      //console.log('GGGGG , ', this.infoVideoChamada)
      this._videoChamada
        .banco()
        .doc(id)
        .update({ aceito: true })
        .then((res) => {
          this._videoChamada
            .banco()
            .doc(id)
            .collection("info")
            .get()
            .toPromise()
            .then((res) => {
              try {
                let id_info = res.docs[0].id;
                this._videoChamada
                  .banco()
                  .doc(id)
                  .collection("info")
                  .doc(id_info)
                  .update({ registro: this.infoVideoChamada });
              } catch {
                this._videoChamada
                  .banco()
                  .doc(id)
                  .collection("info")
                  .add({ registro: this.infoVideoChamada });
              }
            });
        })
        .catch(() => this.toast.error("Algo deu errado"));
    } else {
      this.viewVideoChamada = false;
      this.infoVideoChamada.status = "recusado";
      this._videoChamada.banco().doc(id).update({ aceito: false });
      this._videoChamada
        .banco()
        .doc(id)
        .collection("info")
        .get()
        .toPromise()
        .then((res) => {
          try {
            let id_info = res.docs[0].id;
            this._videoChamada
              .banco()
              .doc(id)
              .collection("info")
              .doc(id_info)
              .update({ registro: this.infoVideoChamada });
          } catch {
            this._videoChamada
              .banco()
              .doc(id)
              .collection("info")
              .add({ registro: this.infoVideoChamada });
          }
        });
    }
  }
  getVideoChamada() {
    this._videoChamada
      .banco()
      .snapshotChanges()
      .subscribe((r) => {
        setTimeout(() => {
          this.selectVideoChamada();
        }, 15000);
      });
  }

  nav(url: string, active, menuID?, name?) {
    if (!active) {
      this.sessaoMenuID = menuID;
      this.codSessaoMenu = `${menuID} - ${name}`;
      this.infoUser.transacaoAtual = this.codSessaoMenu;
      this.router
        .navigateByUrl(`home/${url}/${this.usuarioId}`, {
          skipLocationChange: true,
        })
        .catch(() =>
          this.router
            .navigateByUrl(`home/${url}`)
            .catch(() =>
              this.router.navigateByUrl(`home/${url}/${this.usuarioId}/${true}`)
            )
        );
      //console.log(url)
    }
    this._mensagem.clear();
  }
  async resetBackGround(t?) {
    await Object.keys(this.backgroud).forEach(
      (value) => (this.backgroud[value] = false)
    );
  }
  onActivate(t) {
    //console.log(t)
  }
  verificarMensagens() {
    this._chat.verificarMensagens().subscribe(
      async (res) => {
        //console.log('**************************MENSAGEM*************************')
        let get = await this._chat.get();
        this.notificacaoMensagem = get.filter(
          (value) =>
            value.status == "SDO0143" && value.destinatario == sessao.cpf
        ).length;
      },
      (error) => {
        //console.log('**************************MENSAGEM ERRO*************************')
      }
    );
  }
  getMensagem() {
    this.mensagem = localStorage.getItem("mensagem");
    // this.mensagem = `<div class="text-info mensagem-sistema-info">
    // <i class="far fa-comment-alt mr-3"></i>
    // Mensagem teste
    // </div>`
  }
  getNavegador() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d\.]+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+(\.\d+)?)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
    return M.join(" ");
  }
  sair(closeWindow?) {
    // this.service.activeLogin(this.cpf, false)
    let diario = sessionStorage.getItem("idDiario");
    this.diarioDeAcessoService.PutDiario(
      diario,
      this.statusDeSessao.status.normalLogout
    );
    sessionStorage.removeItem("tokken");
    sessionStorage.clear();
    if (!closeWindow) {
      this.router.navigateByUrl("");
    }
    clearInterval(this.verificar);
    let id = sessionStorage.getItem("logadoId");

    this.usuarioslogadosService
      .Delete(this.idUsuarioLogado)
      .toPromise()
      .then((res) => {})
      .catch((err) => console.log("error delete: ", err));

    sessionStorage.removeItem("logadoId");
    clearInterval(this.verificAutorizacao);
  }
}
