import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CamposAtribuicaoStatus } from './model';
import { camposStatus } from '../status-do-sistema/Status.model';
import { ToastrService } from 'ngx-toastr';
import { SistemaClassificacaoDadosService } from '../../service/sistema-classificacao-dados.service';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { camposDocumentos } from 'src/app/documentos-do-sistema/camposDocumentos.model';

@Component({
  selector: 'app-atribuicao-status',
  templateUrl: './atribuicao-status.component.html',
  styleUrls: ['./atribuicao-status.component.scss']
})
export class AtribuicaoStatusComponent implements OnInit {

  Idstatus: string

  AtribuicaoStatus: any[]

  AtribuicaoStatusID: CamposAtribuicaoStatus

  detalhesStatus: CamposAtribuicaoStatus = new CamposAtribuicaoStatus

  detalhes = false

  alterar = false

  buttonAdicionar = false

  DocumentosUrl = "https://www.myclinics.com.br/api/api/Documentos/"

  StatusUrl = "https://www.myclinics.com.br/api/api/Status/"

  camposDocumentos: camposDocumentos[]

  camposStatus: camposStatus[]

  registroIcon
  galeriaIcon
  loading
  detalhe
  selecionado
  applyFilter
  modalRef: BsModalRef;
  @ViewChild('templateEditar', { static: false }) templateEditar

  constructor(
    private mensagem: ToastrService,
    private SistemaClassificacaoService: SistemaClassificacaoDadosService,
    private httpClient: HttpClient,
    private modalService: BsModalService
  ) { }

  async ngOnInit() {

    setInterval(() => {
      this.BuscarStatus();
    }, 2000)

    await this.buscarDocumento()
    await this.buscarCodigoStatus()
  }

  modalCadastrar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  modalEditar(templateEditar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateEditar);
  }

  BuscarStatus() {
    this.SistemaClassificacaoService.getAtribuicao().subscribe(
      ((sucesso: CamposAtribuicaoStatus[]) => {
        this.AtribuicaoStatus = sucesso
        this.getDescricaoStatus()
        this.getDescricaoCodDocumento()
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }

  deleteStatus(id: string) {
    if (confirm(`Deseja mesmo deletar o Status?`)) {
      this.SistemaClassificacaoService.deleteAtribuicao(id).subscribe(
        (sucesso => {
          this.mensagem.success('Status removido com sucesso!')
          this.BuscarStatus()
        })
      ), erro => {
        this.mensagem.warning("Não foi possível deletar o registro no momento, tente novamente mais tarde!")
      }
    }
  }

  BuscarStatusID() {
    console.log("ID: " + this.Idstatus)
    this.SistemaClassificacaoService.getAtribuicaoID(this.Idstatus).subscribe(
      ((sucesso: CamposAtribuicaoStatus[]) => {
        this.AtribuicaoStatusID = sucesso
        this.modalEditar(this.templateEditar)
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }


  gravarDados() {
    this.SistemaClassificacaoService.postAtribuicao(this.detalhesStatus).subscribe(
      (sucesso => {
        this.modalRef.hide()
        this.mensagem.success('Gravado com Sucesso!')
        this.BuscarStatus()
      }), erro => {
        (console.log("DEu erro: " + erro))
      })

    this.detalhesStatus = new CamposAtribuicaoStatus()
  }

  async AlterarStatus() {
    await this.SistemaClassificacaoService.putAtribuicao(this.Idstatus, this.AtribuicaoStatusID)
      .subscribe(
        (data => {
          this.modalRef.hide()
          this.mensagem.success('Alterado com Sucesso!')
          this.BuscarStatus()
        })
        , error => console.log("Deu Erro: " + JSON.stringify(error))
      );
  }

  async buscarDocumento() {
    this.httpClient.get(this.DocumentosUrl).subscribe(
      ((resultado: camposDocumentos[]) => {
        this.camposDocumentos = resultado.sort((a, b) => {
          return a.codigoDocumento < b.codigoDocumento ? -1 : a.codigoDocumento > b.codigoDocumento ? 1 : 0;
        })
        console.log("Documentos: " + this.camposDocumentos)
      })
    )
  }

  async getDescricaoStatus() {
    let dados = []
    await this.AtribuicaoStatus.forEach(reg => {
      let item = this.camposStatus.filter((r) => {
        return r.codigoStatus == reg.codigoDeStatus
      })[0]
      if (item) {
        reg.descricao = item.descricaoDoStatus ? item.descricaoDoStatus : 'indefinido'
      }
      dados.push(reg)
    });
    this.AtribuicaoStatus = dados
  }
  async getDescricaoCodDocumento() {
    let dados = []
    await this.AtribuicaoStatus.forEach(reg => {
      let item = this.camposDocumentos.filter((r) => {
        return r.codigoDocumento == reg.codigoDoDocumento
      })[0]
      if (item) {
        reg.codDescricao = item.descricao ? item.descricao : 'indefinido'
      }
      dados.push(reg)
    });
    this.AtribuicaoStatus = dados
  }

  async buscarCodigoStatus() {
    this.httpClient.get(this.StatusUrl).subscribe(
      (resultado: camposStatus[]) => {
        this.camposStatus = resultado.sort((a, b) => {
          return a.codigoStatus < b.codigoStatus ? -1 : a.codigoStatus > b.codigoStatus ? 1 : 0;
        })
        console.log("Documentos status: ", this.camposStatus)
      })
  }

}