import { Component, OnInit, ViewChild, Pipe, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { camposCaracteristicas } from './caracteristicas-de-dados.model';
import { SistemaClassificacaoDadosService } from '../../service/sistema-classificacao-dados.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { SessaoUser } from 'src/app/user.reg';
import { Session } from 'protractor';
import { MensagemService } from 'src/app/service/mensagem.service';
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';

const user = new SessaoUser


@Component({
  selector: 'app-caracteristicas-de-dados',
  templateUrl: './caracteristicas-de-dados.component.html',
  styleUrls: ['./caracteristicas-de-dados.component.scss']
})
@Pipe({ name: 'groupBy' })

export class CaracteristicasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['detalhe', 'codigoDaCaracteristica', 'descricaoCaracteristica', 'dependencia', 'editar']

  dataSource

  viewPopUp: boolean
  viewEdit: boolean

  IdCaracteristicas: string

  CaracteristicasID: camposCaracteristicas

  Caracteristicas

  Caracteristicas_backup
  detalhesCaracteristicas: camposCaracteristicas = new camposCaracteristicas

  registrosTabela

  detalhes = false

  alterar = false

  buttonAdicionar = false
  detalhesDados
  registroIcon
  galeriaIcon
  loading
  detalhe
  selecionado
  applyFilter
  loadingOpenCad: boolean;
  alteracao: boolean;
  formulario: FormGroup
  viewCadastro: boolean;

  constructor(
    private mensagem: ToastrService,
    private routerCtrl: Router,
    private SistemaClassificacaoService: SistemaClassificacaoDadosService,
    private _data: BuscarDataService,
    private message: MensagemService,
    private form: FormBuilder
  ) {

  }
  ngOnInit() {

    // setInterval(() => {
    this.BuscarCaracteristicas();
    this.creatForm();
    //}, 2000)

  }

  Excluir()
  {
    let element = this.formulario.value
    element.status = 'SCA0002'
    this.SistemaClassificacaoService.PutCaracteristica(element)
  }

  BuscarCaracteristicas() {
    this.SistemaClassificacaoService.GetCaracteristicas().toPromise().then(
      (registro: any) => {
        ///console.log("registro: ", registro[2])
        this.dataSource = new MatTableDataSource(registro)
      //  this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort


        let dados = registro
        let letra = registro.map(value => value.descricaoCaracteristica.slice(0, 1).toUpperCase()).filter((value, i, index) => { return i == index.indexOf(value) }).sort()
        let result = []

        letra.forEach(value => {
          let ref = { letra: value, registro: [] }
          result.push(ref)
        });

        result.forEach(value => {
          dados.filter(v => {
            let L = v.codigoDaCaracteristica.slice(0, 1).toUpperCase()
            if (L == value.letra) {
              value.registro.push(v)
            }
          })
        })

        this.Caracteristicas = result
        this.Caracteristicas_backup = result

      }
    )
  }

  filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }
  _filterGroup(value: string) {
    let result
    if (value) {

      result = this.Caracteristicas_backup
        .map(group => ({ letra: group.letra, registro: _filter(group.registro, value) }))
        .filter(group => group.registro.length > 0);
    
        this.Caracteristicas = result
    }else{
      this.Caracteristicas = this.Caracteristicas_backup
    }
   
  }

  gravarDados() {
    this.SistemaClassificacaoService.postCaracteristicas(this.detalhesCaracteristicas).subscribe(
      (resultado => {
        console.log("Salvo com sucesso: " + JSON.stringify(resultado))
        this.mensagem.success('Gravado com Sucesso!')
      }), erro => {
        (console.log("DEu erro: " + erro))
      })
    this.BuscarCaracteristicas()
    this.detalhesCaracteristicas = new camposCaracteristicas()
  }

  BuscarCaracteristicasID() {
    console.log("ID: " + this.IdCaracteristicas)
    this.SistemaClassificacaoService.getCaracteristicasID(this.IdCaracteristicas).subscribe(
      (sucesso: any) => {
        this.CaracteristicasID = sucesso
        console.log("Dados Api: " + JSON.stringify(sucesso))
      }), (erro => {
        console.log("Deu erro: " + erro)
      })
  }

  AlterarDocumento() {
    this.SistemaClassificacaoService.putCaracteristicasOld(this.IdCaracteristicas, this.CaracteristicasID).subscribe(
      (sucesso => {
        console.log("ALTERADO COM SUCESSO: " + sucesso)
        this.mensagem.success('Alterado com Sucesso!')
        this.detalhes = false
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }

  goPage(element) {
    sessionStorage.setItem('caracteristica', element.codigoDaCaracteristica)
    console.log("CARACTERISTICA:: " + element.codigoDaCaracteristica)
    this.routerCtrl.navigateByUrl('home/ConteudoDeDados')

  }

  async OpenAlterar(registro) {
    this.alteracao = true
    this.formulario.setValue(registro)
    this.viewCadastro = true

  }

  Alterar() {
    let registro = this.formulario.value

    if (!registro.id) return

    this.SistemaClassificacaoService.putCaracteristicasOld(this.IdCaracteristicas, this.CaracteristicasID).subscribe(
      res => {
        console.log(res)
        this.message.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
        this.BuscarCaracteristicas()
      }
    ), (erro => {
      console.log("Deu erro: " + erro)
    })
  }

  async openCadastro(registro) {
    this.loadingOpenCad = true
    this.alteracao = true

    // await this.getCaracteristicas('ATUMED')
    //await this.getCaracteristicas('SERMED')

    this.creatForm()

    this.formulario.patchValue(registro)

    this.formulario.disable()
    this.viewCadastro = true
    this.loadingOpenCad = false
    setTimeout(() => {

    }, 2000)

  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: '',
      codigoDaCaracteristica: new FormControl('', Validators.required),
      descricaoCaracteristica: '',
      dependencia: '',
      status: '',
      criadoEm:''
    })

  }

  fecharCadastro() {
    this.formulario.reset()
    this.formulario.enable()
    this.alteracao = false
    this.viewCadastro = false

  }

  Inserir() {
    let registro = this.formulario.value
    registro.criadoPor = user.cpf
    delete registro.id
    console.log('Registro: ', JSON.stringify(registro))
    this.SistemaClassificacaoService.postCaracteristicas(registro).subscribe(
      (resultado => {
        console.log("Salvo com sucesso: " + JSON.stringify(resultado))
        this.BuscarCaracteristicas()
        this.message.creat('Salvo com sucesso', { sucesso: true })

      }), erro => {
        (console.log("DEu erro: " + erro))
        this.message.creat('Erro: ' + erro.error, { erro: true })

      })
    this.BuscarCaracteristicas()
    this.viewCadastro = false
    this.formulario.reset()
    this.formulario.enable()
  }

  AlterarRegistro() {
    let registro = this.formulario.value

    if (!registro.id) return

    this.SistemaClassificacaoService.PutCaracteristica(registro).toPromise().then(
      res => {
        console.log(res)
        this.message.creat('Alterado com sucesso', { sucesso: true })
        this.formulario.disable()
        this.BuscarCaracteristicas()
        this.alteracao = false
        this.viewCadastro = false
      }
    ).catch(
      error => console.log(error.error)

    )
    this.viewCadastro = false
    this.formulario.reset()
    this.formulario.enable()
  }


}

export const _filter = (opt: camposCaracteristicas[], value: string) => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.codigoDaCaracteristica.toLowerCase().indexOf(filterValue) === 0);

   
};


