import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
export class Status {
  id: string
  codigoStatus: string
  descricaoDoStatus: string
  tipo: string
  proximoStatus: string
  faseStatus: string
  alteracaoStatus: string
  alteracaoObjeto: string
}
export class ConteudoDeDados {
  id: string
  codigoCaracteristica: string
  codValor: number
  codigoReferencia: string
  valor: string
  status: string
  codigoDeNegocio: string
  descricao: string
}
@Injectable({
  providedIn: 'root'
})
export class StatusSistemaService {
  url = new environment().api
  constructor(private http: HttpClient) { }

  async getStatus(all: boolean, onlyDescricao?: boolean, cod?: string) {
    let registro = new Status
    if (all) {
      return this.http.get<Status[]>(this.url + 'Status/')
    }
    await this.http.get<Status[]>(this.url + 'Status/')
      .toPromise()
      .then(
        (res) => {
          try {
            let getItem = res.filter((r) => { return r.codigoStatus == cod })[0]
            registro = getItem
          } catch {
            registro = new Status
          }
        }
      )
    if (onlyDescricao) {
      try {
        return registro.descricaoDoStatus
      } catch {
        return `${cod} (status não encontrado)`
      }
    }
    return registro
  }

  async getConteudo(all: boolean, onlyDescricao?: boolean, cod?: string) {
    let registro = new ConteudoDeDados
    if (all) {
      return this.http.get<ConteudoDeDados[]>(this.url + 'ConteudoDeDados/')
    }
    await this.http.get<ConteudoDeDados[]>(this.url + 'ConteudoDeDados/')
      .toPromise()
      .then(
        (res) => {
          try {
            let getItem = res.filter((r) => { return r.codigoReferencia == cod })[0]
            registro = getItem ? getItem : new ConteudoDeDados
          } catch {
            registro = new ConteudoDeDados
          }
        }
      )
    if (onlyDescricao) {
      console.log('registro', registro)
      return registro.valor ? registro.valor : 'Indefinido'
    }
    return registro

  }
}
