import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormProntuarioVidaPaciente } from './model';

@Injectable({
  providedIn: 'root'
})
export class ProntuarioDeVidaService {
  url = new environment().api
  api = 'ProntuarioVidaPaciente/'
  constructor(private http: HttpClient) { }

  post(register: FormProntuarioVidaPaciente) {
    return this.http.post(this.url + this.api, register)
  }
  get(cpf: string) {
    return this.http.get(this.url + this.api + 'FiltrarCpf/' + cpf)
  }

}
