import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CadastroPessoaForm } from 'src/app/cadastro-paciente/model';
import { TicketConsulta, TicketReserva } from 'src/app/home/monitor-clinico/consultas/solicitacoes/modal';
import { AgendaService } from 'src/app/service/agenda.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { FotoPerfilService } from 'src/app/service/foto-perfil.service';
import { ListarMedicos } from 'src/app/service/listar-medicos.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { TicketsService } from 'src/app/service/tickets.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { SessaoUser } from 'src/app/user.reg';
import { AgendaConfigForm, AgendaFechadaForm, AgendaForm, AgendaHorarioDiferenteForm, MarcaConsultaForm } from '../model';
const sessao = new SessaoUser
@Component({
  selector: 'app-marcar-consulta',
  templateUrl: './marcar-consulta.component.html',
  styleUrls: ['./marcar-consulta.component.scss']
})
export class MarcarConsultaComponent implements OnInit {
  @Input() medico = new ListarMedicos
  @Output() close = new EventEmitter
  @Output() resp_solicitacao = new EventEmitter
  @Input() solicitacao = new TicketReserva
  @Input() registroAgenda: AgendaForm[] = []
  @Input() formAgenda = new MarcaConsultaForm
  formulario: FormGroup
  pacienteLoading: boolean;
  paciente = new CadastroPessoaForm;
  horarios = []
  dateFeriados = []
  datasDisponiveis = []
  horariosDoDia = [];
  config = new AgendaConfigForm;
  loading: boolean;
  fotoPerfilPaciente: any;
  viewDadosPaciente: boolean;
  loadingUser: boolean;
  pacienteSemCPF: boolean;
  fotoPerfil: any
  tipoConsulta: ConteudoDados[] = [];
  tipoAtendimento: ConteudoDados[];
  motivoConsulta: ConteudoDados[];
  formConsulta: ConteudoDados[];
  existfechamento: boolean;
  legendaHorarioDiferente: string;
  loadingHorarios: boolean;
  semHorario: boolean;
  viewCalendario_Secundario: any;
  listFrequencia: ConteudoDados[] = [];
  viewPersonalizarFrequencia: boolean;
  typePersonal = 'FREQTMP4'
  typePersonalDesc = 'Não se repete'
  listDates = []
  openCad
  alteracao: boolean;
  viewConfirmCancelarAgenda: boolean;

  constructor(
    private form: FormBuilder,
    private _usuario: UsuarioService,
    private _agenda: AgendaService,
    private _ticket: TicketsService,
    private _mensagem: MensagemService,
    private service_foto: FotoPerfilService,
    private _filter: FiltrarDadosService,
    private _data: BuscarDataService,
    private _conteudo: ConteudoDeDadosService) { }

  async ngOnInit() {
    console.log('$$$$', this.solicitacao, this.formAgenda)
    await this.createForm()
    await this.getConteudoDeDados()

    let getConfig = await this._agenda.getConfig()

    let config = getConfig.filter(v => v.cpfMedico == this.medico.cpfProfissional)[0]

    if (config.userConfigEmpresa) {
      this.config = getConfig.filter(v => v.cpfMedico == sessao.codigoEmpresa.toString())[0]
    } else {
      this.config = config

    }

    await this.getHorarios()
    await this.getDiasDisponiveis()
    if (this.formAgenda.cpf) {
      this.getPaciente(true)
    }
    this.fotoPerfil = await this.service_foto.buscarFoto(this.medico.cpfProfissional)

  }


  async getDiasDisponiveis() {
    await this._agenda.listarAgendaFechada().toPromise()
      .then(
        async (reg: Array<AgendaFechadaForm>) => {

          let filtro = reg.filter((r) => { return r.codigoEmpresa == sessao.codigoEmpresa && r.status == 'SDO0149' })
          filtro = this._filter.removeDublic(filtro, 'start')
          let daylist = filtro.map(d => d.start)
          const dates = []

          console.log('datasDisponiveis', filtro)
          const hoje = new Date().toISOString().split('T')[0]
          await daylist.forEach(v => {
            let date = v
            let d = date.split('-').join('')
            let h = hoje.split('-').join('')
            if (parseInt(d) >= parseInt(h)) {
              dates.push({ date: date, semana: this._data.getSemanas(new Date(date).getDay() + 1) })
            }
          });
          this.datasDisponiveis = dates
          let dateForm = this.formAgenda.date
          if (dateForm) {
            this.datasDisponiveis.push({ date: dateForm, semana: this._data.getSemanas(new Date(dateForm).getDay() + 1) })
          }
          this.verificarView()
        }

      )
  }

  async getPaciente(view?) {

    this.pacienteLoading = true

    if (view) { 
      this.formulario.enable()
    }
   
    if (this._control('cpf').invalid) {
      this.fotoPerfilPaciente = null
      console.log('não passou!', this._control('cpf').invalid)
      this._control('paciente').reset()
      return
    }
    try {
      this.paciente = await this._usuario.getCadastroPessoa(this._controlValue('cpf'))
      console.log('PACIENTE', this.paciente)
      if (this.paciente.cpf) {
        this.pacienteSemCPF = false
        this._control('paciente').setValue(this.paciente.nomeCompleto)
      } else {
        this.pacienteSemCPF = true
      }
    } catch { }
    this.fotoPerfilPaciente = await this.service_foto.buscarFoto(this.paciente.cpf)
  }

  async getHorarios() {
    let start = this.config.timeStart
    let end = this.config.timeEnd
    let listhorarios = this._agenda.listarHorarios(start ? start : '00:00', end ? end : '23:00')
    this.horarios = listhorarios
  }
  async getFeriados() {
    let ano = new Date().getFullYear()
    let feriados = await this._agenda.getFeriados(ano)
    this.dateFeriados = []
  }

  async gerarDatas() {

    ///Redefinir alteração na end 
    const start = new Date(this.config.start).getDay() ? this.config.start : null
    let end = new Date(this.config.end).getDay() ? this.config.end : new Date(new Date().setDate(60)).toISOString().split('T')[0]
    // const valid_start = parseInt(this.config.end.substr(8, 2)) - 1

    console.log('DATA  : ', start, end)
    const hoje = new Date().toISOString().split('T')[0]
    var daylist = this._agenda.getDatasDisponiveis(new Date(start), new Date(end), this.dateFeriados);
    const dates = []

    await daylist.forEach(v => {
      let date = v
      let d = date.split('-').join('')
      let h = hoje.split('-').join('')
      if (parseInt(d) >= parseInt(h)) {
        dates.push({ date: date, semana: this._data.getSemanas(new Date(date).getDay() + 1) })
      }
    });
    this.datasDisponiveis = dates
    let dateForm = this.formAgenda.date
    let verificData = this.datasDisponiveis.filter(value => value.date == dateForm)
    if (dateForm && verificData.length < 1) {
      this.datasDisponiveis.push({ date: dateForm, semana: this._data.getSemanas(new Date(dateForm).getDay() + 1) })
    }


    if (this.formAgenda.start) {
      this.getHorariosDisponiveis()

    }
    console.log('=====================================>', this.formAgenda)
    this.formulario.enable()
    this.formulario.patchValue(this.formAgenda)
    this.getPaciente()

  }
  async getConteudoDeDados() {
    this.tipoConsulta = await this._conteudo.get('LITICO')
    this.tipoAtendimento = await this._conteudo.get('TIPATEND')
    this.motivoConsulta = await this._conteudo.get('LIMOCO')
    this.formConsulta = await this._conteudo.get('FORMACON')
    this.listFrequencia = await this._conteudo.get('FREQTMP')
  }

  async getHorariosDisponiveis(onit?) {
    this.loadingHorarios = true
    let filtrarAgendaSelecionada = []
    let horarioAgenda = []
    const dataSelecionada = this._controlValue('date')
    await this.verificarFechamento()
    await this.verificarHorariosPersonalizados()

    //Filtrar registro da Agenda no dia Selecionado
    try {
      filtrarAgendaSelecionada = this.registroAgenda.filter(reg => {
        if (!reg.start || !dataSelecionada) {
          return
        }
        ///Se o dia estiver bloqueado , enviar true para bloquear os horarios atravez da variavel blockDia
        return reg.date == dataSelecionada
      })

    } catch (error) {
      console.log('ERRO!><<><><', error)
    }
    //Se não tiver registro no dia selecionado , disponibilizar todos os horarios 
    if (!filtrarAgendaSelecionada.length) {
      //Verifica se o usuario selecionou um evento existente na agenda , evitando que o botão Bloquar apareca
      this.horariosDoDia = this.horarios
      //END

      // SemRegistro = true
    }
    ///END

    // Pegar apenas os horarios da Agenda Selecionada
    await filtrarAgendaSelecionada.forEach(ref => {
      horarioAgenda.push({ h: ref.start.split('T')[1] })
    });
    //END
    //Gravando filtro dos horarios na variavel para ser jogada no select do usuario
    //console.log('horarioAgendaFINAL : ' + JSON.stringify(horarioAgenda))
    //console.log('horariosFINAL : ' + JSON.stringify(this.horarios))
    this.horariosDoDia = await this.verificarHorariosDisponiveis(horarioAgenda, this.horarios)
    let date = new Date().toISOString().split('T')[0]

    //// Listar horários de agora em diante
    if (this._controlValue('date') == date) {
      let getHora = []
      let now = parseInt(this._data.horaAgora().split(':').join(''))
      if (this.horariosDoDia.length > 0) {
        this.horariosDoDia.forEach(h => {
          let hr = parseInt(h.split(':').join(''))
          if (hr >= now) {
            getHora.push(h)
          }
        });
        this.horariosDoDia = getHora
      }

    }
    if (onit) { this.loadingHorarios = false; return }
    if (this.horariosDoDia.length > 0 && this.formAgenda.start) {
      this._control('start').setValue(this.horariosDoDia[0])
      this.semHorario = false
    }
    if (this.horariosDoDia.length < 1 && this.formAgenda.start) {
      this.semHorario = true
    }
    this.loadingHorarios = false
  }
  async verificarFechamento() {
    await this._agenda.listarAgendaFechada().toPromise()
      .then(
        (reg: Array<AgendaFechadaForm>) => {

          let filtro = reg.filter((r) => { return r.codigoEmpresa == sessao.codigoEmpresa })
          console.log('RODRIGO', filtro)
          let getDates = []
          filtro.forEach(d => {
            let dates
            if (d.start != d.end) {
              this._agenda.getDatasDisponiveis(new Date(d.start), new Date(d.end), this.dateFeriados)
                .forEach((d) => getDates.push(d));
            }
            if (d.start == d.end) {
              getDates.push(d.start)
            }
            console.log(dates)
          });
          console.log(getDates)
          // console.log(getDates.length)
          // getDates = getDates.filter((x) => { return x == date })
          getDates = []

          let getRangeTime = this._agenda.listarHorarios(this.config.timeStart, this.config.timeEnd)

          if (getDates.length > 0) {
            this.existfechamento = true
            let getTime = filtro[0]
            this.legendaHorarioDiferente = getTime.descricao
            const start = getTime.timeStart
            const end = getTime.timeEnd
            let getRangeTimeFechamento = this._agenda.listarHorarios(start ? start : '00:00', end ? end : '23:00')

            const times = []
            getRangeTime.forEach(t => {
              let timeStart = `${t.h}:${t.m}`
              let compare = getRangeTimeFechamento.findIndex((x) => `${x.h}:${x.m}` == timeStart)
              // console.log(compare, timeStart)
              if (compare < 0) {
                let time = {
                  h: t.h,
                  m: t.m
                }
                console.log('times =>', times, time)
                times.push(time)
              }

            });
            this.horarios = times
            // console.log(this.horarios)

          } else {
            this.horarios = getRangeTime
            this.existfechamento = false
            this.legendaHorarioDiferente = null
          }
        }

      )
    return true
  }
  async verificarHorariosDisponiveis(agenda, horarios) {
    //  Comparar horarios da Agenda com os Horarios da Clinica , 
    // descartando horarios que ja existem na agenda 
    let horasAgenda = agenda.map(item => item.h);

    let listaHorarios = horarios.map(item => `${item.h}:${item.m}`);

    let disponiveis = listaHorarios.map((hora, index) => {
      if (horasAgenda.indexOf(hora) < 0) {
        return horarios[index]
      }
    }).filter(item => item != undefined).sort(function (a, b) { return a - b });
    // console.log('Disponiveis : ' + JSON.stringify(disponiveis));
    let ordenaHorarios = disponiveis.sort(function (a, b) {
      return parseInt(a) - parseInt(b)
    })

    let getHorarios = []
    await ordenaHorarios.forEach(h => {
      h = `${h.h}:${h.m}`
      getHorarios.push(h)
    });


    return getHorarios

  }
  async verificarHorariosPersonalizados() {
    await this._agenda.listarAgendaHorarioDiferente().toPromise()
      .then(
        (reg: Array<AgendaHorarioDiferenteForm>) => {
          const cpf = this.medico.cpfProfissional
          let date = this._controlValue('date') ? this._controlValue('date') : new Date().toISOString().split('T')[0]


          let filtro = reg.filter((r) => { return r.cpfMedico == cpf && r.start == date })
          if (filtro.length > 0) {
            let getTime = filtro[0]
            this.legendaHorarioDiferente = getTime.localDeTrabalho
            const start = getTime.timeStart
            const end = getTime.timeEnd
            let getRangeTime = this._agenda.listarHorarios(start, end)
            // console.log(getRangeTime)
            const times = []
            getRangeTime.forEach(t => {
              let time = {
                h: t.h,
                m: t.m
              }
              times.push(time)
            });
            this.horarios = times

          } else {
            if (!this.existfechamento) {
              this.legendaHorarioDiferente = null
            }
            // this.abrir('', 'refresh')
          }
          console.log('passou aqui')
        }
      )
  }
  openPersonalizacao(type) {

    switch (type) {
      case 'FREQTMP1':
        //Frequencia por dia de semana
        break
      case 'FREQTMP2':
        //Frequencia por dia do mês
        break
      case 'FREQTMP3':
        //Frequencia por dia mes e ano especifico
        break
    }
    this.typePersonal = type
    let desc = this.listFrequencia.filter(v => v.codigoReferencia == type)
    console.log(this.typePersonal, this.typePersonalDesc, desc)
    if (type == 'FREQTMP4') return
    this.viewPersonalizarFrequencia = true
  }
  async cadastrarConsulta(reagenda?) {


    console.log(this.formulario.value)
    if (this.formulario.invalid) {
      this._mensagem.creat('Formulario inválido,preencha todos os campos obrigátorios', { erro: true })
      return
    }
    if (this.formAgenda.idAgenda) {
      this.alterarConsulta()
      return
    }
    this.loading = true

    if (await this.verificarConsultaMarcada()) {
      this._mensagem.creat('Paciente já possui consulta marcada nesse dia', { erro: true })
      this.loading = false
      return
    }
    if (reagenda) {
      this.cad_agenda(this.solicitacao.nrDoc)
      this.loading = false
      this.close.emit()
      return
    }
    let numeroDocumento = await this.cad_ticketReserva()
    if (!numeroDocumento) {
      this._mensagem.creat('Não foi possivel gerar um ticket de reserva', { erro: true })
      this.loading = false
      return

    }
    let response = await this.cad_ticketConsulta(numeroDocumento)
    if (response) {
      await this.cad_agenda(numeroDocumento)
    } else {
      this._mensagem.creat('Não foi possivel gerar um ticket de consulta', { erro: true })
    }
    this.loading = false

    this.close.emit()
  }

  cancelarConsulta(confirm?) {
    if (confirm) {
      this.viewConfirmCancelarAgenda = true
      return
    } else {
      this.viewConfirmCancelarAgenda = false
      this.alterarConsulta(false, true)
    }
  }
  excluirAgenda(id) {
    this._agenda.excluirAgenda(id).subscribe(
      () => {
        this.close.emit()
      },
      error => {
        this._mensagem.creat('Não foi possivel excluir consulta', { erro: true })
        console.log('Não foi possivel excluir consulta', error)
      }
    )
  }

  getListDates(dates) {
    console.log(dates)
    this.listDates = dates
  }
  async verificarConsultaMarcada() {
    let response = false
    let consultas = await this._agenda.getContula(this._controlValue('cpf'))
    if (consultas.length > 0) {
      let date = this._controlValue('date')
      let verifc = consultas.filter(value => value.dataConsulta.split('T')[0] == date)
      response = verifc.length > 0 ? true : false
    }
    return response
  }

  async cad_ticketReserva() {
    let numeroDocumento
    let ticket = new TicketReserva
    ticket.codigoEmpresa = sessao.codigoEmpresa
    ticket.cpfMedico = this._controlValue('cpfMedico')
    ticket.cpfPaciente = this._controlValue('cpf')
    ticket.dataReserva = this._controlValue('date')
    ticket.horarioReserva = this._controlValue('start')
    ticket.motivoConsulta = this._controlValue('motivoConsulta')
    ticket.tipoAtendimento = this._controlValue('tipoAtendimento')
    ticket.tipoMarcacao = this._controlValue('formaDeConsulta')
    ticket.tipoConsulta = this._controlValue('tipoConsulta')
    ticket.ativo = true
    ticket.status = 'SDO0027'
    ticket.nrDocRef = 'indefinido'
    ticket.tipoDocRef = 'TICCON'
    ticket.tipoDoc = 'TICRES'
    console.log('creat ticketReserva', ticket)

    await this._agenda.gerarTicketReserva(ticket).toPromise()
      .then(async (res: TicketReserva) => {
        numeroDocumento = res[0].nrDoc
      }).catch(error => {
        console.log('erro ticket', error)
      })


    return numeroDocumento
  }
  async cad_ticketConsulta(numeroDocumento) {
    let response = false
    let ticketConsulta = new TicketConsulta
    ticketConsulta.status = "SDO0010"
    ticketConsulta.codigoEmpresa = sessao.codigoEmpresa
    ticketConsulta.cpfPaciente = this._controlValue('cpf')
    ticketConsulta.criadoPor = sessao.cpf
    ticketConsulta.nrDocRef = numeroDocumento
    ticketConsulta.cpfMedico = this._controlValue('cpfMedico')
    ticketConsulta.dataConsulta = this._controlValue('date')
    ticketConsulta.horaConsulta = this._controlValue('start')
    ticketConsulta.tipoDocRef = 'TICRES'

    ticketConsulta.motivoConsulta = this._controlValue('motivoConsulta')
    ticketConsulta.tipoMarcacao = this._controlValue('formaDeConsulta')
    ticketConsulta.tipoAtendimento = this._controlValue('tipoAtendimento')
    ticketConsulta.tipoConsulta = this._controlValue('tipoConsulta')

    ticketConsulta.dataInclusaoDocumento = new Date().toISOString()
    ticketConsulta.tipoDoc = 'TICCON'
    ticketConsulta.formaDeConsulta = this._controlValue('formaDeConsulta')

    console.log('creat ticketConsulta', JSON.stringify(ticketConsulta))

    if (this.listDates.length > 0) {
      for (let value of this.listDates) {
        ticketConsulta.dataConsulta = value
        await this._agenda.gerarTicketConsulta(ticketConsulta).toPromise()
          .then(async res => {
            console.log('gerarTicketConsulta', res)
            response = true
          }).catch(error => {
            console.log('error', error)
          })
      }
    } else {
      await this._agenda.gerarTicketConsulta(ticketConsulta).toPromise()
        .then(async res => {
          response = true
        }).catch(error => {
          console.log('error', error)
        })
    }

    return response
  }
  async cad_agenda(numeroDocumento) {

    let registro: AgendaForm = this.formulario.getRawValue()
    const creatEnd = this._data.somarHora(new Date(`${registro.date}T${registro.start ? registro.start : '00:00'}`), parseInt(registro.end))
    registro.codigoEmpresa = sessao.codigoEmpresa
    registro.status = 'SDO0010'
    registro.cpfMedico = this.medico.cpfProfissional
    registro.tipoAgenda = ''
    registro.backgroundColor = "#ffffff00"
    registro.end = `${registro.date}T${creatEnd}`
    registro.confirm = false
    registro.title = `${this._controlValue('paciente') ? this._controlValue('paciente') : 'paciente não identificado'}`

    if (registro.videoChamada) {
      registro.tipoAgenda = 'Adistancia'
      registro.color = "#343a40"
    }
    else {
      registro.color = "#30abbe"
    }

    registro.ticketConsultaMedica = numeroDocumento
    registro.criadoPor = sessao.cpf

    if (this.listDates.length > 0) {
      let start = registro.start
      for (let value of this.listDates) {
        registro.end = `${value}T${creatEnd}`
        registro.start = `${value}T${start ? start : '00:00'}`
        registro.date = value
        console.log('cadastrarAgenda ' + value, registro)
        await this._agenda.cadastrarAgenda(registro).toPromise().then(() => this.close.emit())
      }
    }
    else {
      registro.start = `${registro.date}T${registro.start ? registro.start : '00:00'}`
      await this._agenda.cadastrarAgenda(registro).toPromise().then(() => this.close.emit())
    }

    this._mensagem.creat('Dado salvo com sucesso', { aviso: true })
  }
  Alterar() {
    this.formulario.enable()
  }
  async alterarConsulta(naoCompareceu?: boolean, cancelarConsulta?: boolean) {
    let form: MarcaConsultaForm = this.formulario.value
    let ticketConsulta = new TicketConsulta
    let ticketReserva = new TicketReserva
    let agenda = new AgendaForm
    try {
      ticketConsulta = await this._ticket.getTicketConsultaID(this.formAgenda.idTicketConsulta)
      ticketReserva = await this._ticket.getTicketReservaID(this.formAgenda.idTicketReserva)
      agenda = await this._agenda.getAgendaID(this.formAgenda.idAgenda)
    } catch {
      this._mensagem.creat('Não foi possivel realizar procedimento', { erro: true })
      return
    }
    let response = {
      consulta: false,
      reserva: false,
      agenda: false
    }
    /// Consulta
    ticketConsulta.cpfPaciente = this._controlValue('cpf')
    ticketConsulta.cpfMedico = this._controlValue('cpfMedico')
    ticketConsulta.dataConsulta = this._controlValue('date')
    ticketConsulta.horaConsulta = this._controlValue('start')
    ticketConsulta.motivoConsulta = this._controlValue('motivoConsulta')
    ticketConsulta.tipoMarcacao = this._controlValue('formaDeConsulta')
    ticketConsulta.tipoAtendimento = this._controlValue('tipoAtendimento')
    ticketConsulta.tipoConsulta = this._controlValue('tipoConsulta')
    ticketConsulta.formaDeConsulta = this._controlValue('formaDeConsulta')

    //Reserva
    ticketReserva.cpfMedico = this._controlValue('cpfMedico')
    ticketReserva.cpfPaciente = this._controlValue('cpf')
    ticketReserva.dataReserva = this._controlValue('date')
    ticketReserva.horarioReserva = this._controlValue('start')
    ticketReserva.motivoConsulta = this._controlValue('motivoConsulta')
    ticketReserva.tipoAtendimento = this._controlValue('tipoAtendimento')
    ticketReserva.tipoMarcacao = this._controlValue('formaDeConsulta')
    ticketReserva.tipoConsulta = this._controlValue('tipoConsulta')

    //Agenda
    const creatEnd = this._data.somarHora(new Date(`${form.date}T${form.start ? form.start : '00:00'}`), parseInt(form.end))

    agenda.end = `${form.date}T${creatEnd}`
    agenda.start = `${form.date}T${form.start}`
    agenda.cpf = form.cpf
    agenda.cpfMedico = form.cpfMedico

    if (naoCompareceu) {
      ticketConsulta.status = 'SDO0098'
      ticketConsulta.status = 'SDO0098'
      agenda.start = 'SDO0098'
    }
    if (cancelarConsulta) {
      ticketConsulta.status = 'SDO0021'
      ticketConsulta.status = 'SDO0021'
      agenda.start = 'SDO0021'
    }


    response.consulta = await this._ticket.atualizarTicketConsulta(ticketConsulta)
    response.reserva = await this._ticket.atualizarTicketReserva(ticketReserva)
    await this._agenda.alterarAgenda(agenda.id, agenda).toPromise()
      .then(res => response.agenda = true)

    if (response.consulta && response.reserva && response.agenda) {
      this._mensagem.creat('Consulta alterada com sucesso', { sucesso: true })
      this.fechar()
    } else {
      this._mensagem.creat('Falha ao realizar procedimento', { aviso: true })
    }







    console.log('TicketConsulta', ticketConsulta)
    console.log('ticketReserva', ticketReserva)
    console.log('agenda', agenda)
    console.log('response', response)
  }
  alterarAgenda(dados: AgendaForm, id) {
    this._agenda.alterarAgenda(id, dados).subscribe(
      async () => {
        this.close.emit()
        this._mensagem.creat('Consulta alterada com sucesso', { aviso: true })
      },
      () => { this._mensagem.creat('Erro! não foi possivel alterar registro', { erro: true }) }
    )
  }
  async abrirDadosPaciente() {
    this.viewDadosPaciente = !this.viewDadosPaciente
  }
  createForm() {
    this.formulario = this.form.group({
      title: this.paciente.nomeCompleto,
      date: new FormControl('', Validators.required),
      end: new FormControl(''),
      start: new FormControl('', Validators.required),
      paciente: new FormControl('', Validators.required),
      medico: this.medico.nomeCompleto,
      cpf: new FormControl('', Validators.required),
      cpfMedico: this.medico.cpfProfissional,
      confirm: false,
      confirmReserva: false,
      backgroundColor: new FormControl(''),
      especialidade: this.medico.valorEspecialidadeProfissional,
      motivoConsulta: new FormControl('', Validators.required),
      tipoConsulta: new FormControl('', Validators.required),
      formaDeConsulta: new FormControl('', Validators.required),
      tipoAtendimento: new FormControl('', Validators.required),
      crm: this.medico.nr_Registro,
      repeticao: '',
      status: ''
    })
    this.formulario.get('repeticao').setValue('FREQTMP4')

    if (this.solicitacao.id) {
      this._control('tipoConsulta').disable()
      this._control('motivoConsulta').disable()
      this._control('formaDeConsulta').disable()
      this._control('cpf').disable()
      this._control('tipoAtendimento').disable()

    }
  }
  _controlValue(value) {
    let keys = this.formulario.getRawValue()
    try {
      return keys[value]
    }
    catch {
      return ''
    }
  }
  _control(value) {
    try {
      return this.formulario.get(value)
    } catch {
      return null
    }
  }
  async exibirCalendario(ref) {
    this.viewCalendario_Secundario = ref
  }
  sugerirNovoHorario() {
    if (this.solicitacao.status == 'SDO0050') {
      this.cadastrarConsulta(true)
      this.resp_solicitacao.emit(this.solicitacao)
      return
    }
    this.close.emit(this.formulario.value)
  }
  verificarView() {
    this.formulario.patchValue(this.formAgenda)
    this.getHorariosDisponiveis()
    let date = new Date(this.formAgenda.date)
    let today = new Date()
    const s = this.formAgenda.status

    if (date < today) {
      this.formulario.disable()
    } else {
      this.formulario.enable()
    }

    this.alteracao = s == 'SDO0028' || s == 'SDO0011' || s == 'SDO0098' || s == 'SDO0022' || s == 'SDO0010'
    if (this.alteracao) this.formulario.disable()

    console.log('datasDisponiveis', this.formulario.value, this.datasDisponiveis)
  }
  fechar() {
    this.close.emit()
  }
}
