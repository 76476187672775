import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ConversasService } from 'src/app/service/conversas.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FormContatos } from '../modal';
import { VideoChamadaService } from 'src/app/service/video-chamada.service';
import { SalaEsperaForm, videoChamadaFrom } from 'src/app/atendimento-clinico/sala-espera/model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @Input('chat') chat: FormContatos
  @Output('sair') sair = new EventEmitter
  @Input('key') key: string
  @Input('contato') contato: boolean
  @Input('fichaConsulta') fichaConsulta
  @Input('info') info
  @Input('fullscreen') fullscreen: boolean
  @ViewChild('scroll', { static: false }) scroll: ElementRef
  checkRemover: boolean;
  userName = sessionStorage.getItem('userName')
  adicionar: boolean;
  viewMensagem: boolean
  mensagem: string
  conversas: Observable<any[]>
  cpf = sessionStorage.getItem('cpf')
  formVideoChamada = new SalaEsperaForm
  infoVideoChamda: videoChamadaFrom
  viewVideoChamada: boolean;
  loading: boolean
  anexo: boolean;
  arquivo: any;
  constructor(public _chat: ConversasService, public service: UsuarioService, public data: BuscarDataService, private _videoChamda: VideoChamadaService, public toast: ToastrService) {

  }

  ngOnInit() {
    this.buscarConversa()
    console.log('chat', this.chat)
  }

  ngOnDestroy() {
  }
  teste(t) {
    console.log(t)
  }
  criar(file?) {
    let dados = {
      nome: this.userName,
      nome_receptor: this.chat.nomeConvidado,
      de: this.cpf,
      para: this.chat.cpfConvidado,
      mensagem: this.mensagem,
      data: this.data.hojeBrasil(false),
      hora: this.data.horaAgoraSegundos(),
      ativo: true,
      anexo: file ? true : false,
      anexo_file: null,
      anexo_size: null,
      anexo_type: null

    }
    if (file) {
      dados.mensagem = file.name
      dados.anexo_file = file.file,
        dados.anexo_size = file.size,
        dados.anexo_type = file.type
    }
    const data = this.data.hojeBrasil(true)
    this._chat.addCPF(this.cpf, data, dados, true).then(
      (r) => {
        this.scroll.nativeElement.scrollTop = 9000000000000000000000000;
        if (file) {
          this.criarEle(file)
          return
        }
        this.criarEle()
      }
    )
  }
  async criarEle(file?) {

    let dados = {
      nome: this.userName,
      de: this.cpf,
      para: this.chat.cpfConvidado,
      mensagem: this.mensagem,
      data: this.data.hojeBrasil(false),
      hora: this.data.horaAgoraSegundos(),
      ativo: true,
      anexo: file ? true : false,
      anexo_file: null,
      anexo_size: null,
      anexo_type: null
    }
    if (file) {
      dados.mensagem = file.name
      dados.anexo_file = file.file,
        dados.anexo_size = file.size,
        dados.anexo_type = file.type
    }
    const data = this.data.hojeBrasil(true)
    await this._chat.addCPF(this.chat.cpfConvidado, data, dados).then(
      (r) => {
        this.mensagem = null
        this.scroll.nativeElement.scrollTop = 9000000000000000000000000;
      }
    )

  }
  buscarConversa() {
    console.log(this.chat.cpfConvidado)
    this._chat.getHoje(this.chat.cpfConvidado).subscribe(
      (reg: any) => {
        console.log(reg)
        let cpf = this.chat.cpfMedicoConvidador
        let convidado = this.chat.cpfConvidado
        let ordenar = reg.sort(function (a, b) {
          let A = parseInt(a.hora.split(':').join(''))
          let B = parseInt(b.hora.split(':').join(''))
          return A - B
        }).filter(function (reg) {
          console.log(reg.de, cpf, reg.para, convidado, reg.de, convidado, reg.para, cpf)
          return reg.de == cpf && reg.para == convidado || reg.de == convidado && reg.para == cpf
        })
        this.conversas = ordenar
        this.scroll.nativeElement.scrollTop = 9000000000000000000000000;
      }
    )
    console.log('conversas : ', this.conversas)

  }
  videoChamada() {
    this.loading = true
    this.formVideoChamada.paciente = this.chat.nomeConvidado
    this.formVideoChamada.cpf = this.cpf
    this.formVideoChamada
    this._videoChamda.criarSala(this.formVideoChamada).then(
      (reg) => {
        localStorage.setItem('keyVC', reg.id)
        const registro = {
          codigoUnico: reg.id,
          remetente: this.cpf,
          destinatario: this.chat.cpfConvidado,
          data: this.data.hoje(),
          status: 'pendente',
          transacao : 'AJ00000'
        }
        this.viewVideoChamada = true
        this.infoVideoChamda = registro
        console.log(registro)
        this._videoChamda.registrar(registro).toPromise().then(
          () => { } //this.toast.info('Convite para video chamada enviado')
        )
        this.loading = false
      }
    )
  }

  enter(event) {
    if (event.key == 'Enter') {
      if (this.mensagem) {
        this.criar()
      }
    }
  }
  voltar() {
    localStorage.removeItem('openChat')
    localStorage.removeItem('infoChat')
    this.sair.emit(false)
    this._chat.putChat(this.key, false)
  }
  infoFile(file) {
    this.arquivo = file
    this.anexo = true
  }
  openFile(file) {
    const type = file.anexo_file.split(',')[0]
    const arquivo = file.anexo_file.split(',')[1]
    let pdfWindow = window.open("")
    pdfWindow.document.write(`<iframe width='100%' height='100%' 
    style='position: fixed;
    margin: 0;
    left: 0;
    top: 0;
    border: 0;' src='${type},${encodeURI(arquivo)}'></iframe>`)
    return
    var image = new Image();
    image.src = file
    var w = window.open("");
    // w.document.write(file);
  }
  downloadFile(file) {
    const type = file.anexo_file.split(',')[0]
    const arquivo = file.anexo_file
    window.open(file.anexo_file, "Download")
    return
  }
  calc(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
