import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, NgModule, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FichaGeralService } from '../service/ficha-geral-empresa/ficha-geral-empresa.service';
import { MinhasConexoesProfissionaisService } from '../service/minhas-conexoes-profissionais.service';
import { dadosMinhasConexoesProfissionais } from './modelsMinhasConexoesProfissionais';
import { DadosFichaGeralEmpresa } from '../configuracao-inicial/regras-de-imposto/model';
import { CadastroDadosProfissionaisService } from '../service/cadastro-dados-profissionais.service';
import { BuscarDataService } from '../service/buscar-data.service';
import { EspecialidadeCrm } from '../service/especialidade-crm.service';
import { dadosCadastroDadosProfissionais } from '../cadastro-dados-profissionais/modelsCadastroDadosProfissionais';
import { dadosEspecialidadeCrm } from '../especialidade-crm/modelsEspecialidadeCrm';
import { resultDadosProf } from '../service/ficha-geral-empresa/FichaGeralEmpresa.model';
import { MensagemService } from '../service/mensagem.service';
import { StatusSistemaService } from '../service/status-sistema.service';
import { SessaoUser } from '../user.reg';
import { CamposFichaGeralEmpresas } from '../ajustes/posicao-numeracao-nfe/campos-posicaoNfe';
import { FichaDeAssinanteService } from '../service/ficha-de-assinante/ficha-de-assinante.service';

const sessao = new SessaoUser

@NgModule({
  imports: [ModalModule.forRoot()],
})
@Component({
  selector: 'app-minhas-conexoes-profissionais',
  templateUrl: './minhas-conexoes-profissionais.component.html',
  styleUrls: ['./minhas-conexoes-profissionais.component.scss']
})
export class MinhasConexoesProfissionaisComponent implements OnInit {

  modalRef: BsModalRef;
  displayedColumns: string[] = ['CodigoEmpresa', 'nomeEmpresa', 'CPFProfissional', 'NomeProfissional', 'Profissao', 'tipoConexao', 'status', 'acao']
  @ViewChild('templateEditar', { static: false }) templateEditar
  dataDataEmpresa: DadosFichaGeralEmpresa[] = []
  ConteudoDadosMinhasConexoesProfissionais: dadosMinhasConexoesProfissionais[] = []
  dadosMinhasConexoesProfissionais = new dadosMinhasConexoesProfissionais();
  MinhasConexoesProfissionaisID: dadosMinhasConexoesProfissionais
  dataSource
  IdMinhasConexoesProfissionais: string
  dadosEspecialidadeCrm: dadosEspecialidadeCrm
  dadosCadastroDadosProfissionais: dadosMinhasConexoesProfissionais
  IdRegraMinhasConexoes: string;
  idCadastroDadosProfissionais: string;
  CpfRegraMinhasConexoes: string
  btnDesconectar = false
  btnConectar = true
  cpf: string;
  regProfissional = new resultDadosProf;
  loadingRegistro: boolean
  codEmpresa: number;
  loadingCadastro: boolean;
  loadingDados: boolean;
  itemIndex: any;
  loadings: boolean;
  resultadoAssinante: any;
  laodingErro: boolean;

  constructor(
    private modalService: BsModalService,
    private serviceEmpresa: FichaGeralService,
    private mensagem: MensagemService,
    private serviceHora: BuscarDataService,
    private _status: StatusSistemaService,
    private service: MinhasConexoesProfissionaisService,
    private serviceCadastroDadosProfissionais: CadastroDadosProfissionaisService,
    private serviceEspecialidadeCrm: EspecialidadeCrm,
    private serviceFichaAssinante: FichaDeAssinanteService
  ) {
  }

  ngOnInit() {
    this.buscarDadosMinhasConexoesProfissionais()
    this.buscarDadosEmpresa()
  }

  modalCadastrar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  modalEditar(templateEditar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateEditar);
  }

  async buscarDadosMinhasConexoesProfissionais() {
    this.loadingDados = true
    this.codEmpresa = parseInt(sessionStorage.getItem('codEmpresa'))
    await this.serviceCadastroDadosProfissionais.getConexoes(this.codEmpresa).subscribe(
      ((resultado: Array<any>) => {
        const codEmpresa = this.codEmpresa
        this.ConteudoDadosMinhasConexoesProfissionais = resultado.filter((x, i) => {
          x.id = i
          return x.codigoEmpresa == codEmpresa
        })
        console.log("Dados minhas conexões profissionais: ", resultado, codEmpresa)
        this.loadingDados = false
      }), (erro => {
        this.loadingDados = false
        console.log("Erro: ", erro)
      }))
  }

  async buscarDadosEmpresa() {
    var cpf = sessao.cpf
    this.resultadoAssinante = await this.serviceFichaAssinante.buscarAssinante({ cpf })
    this.serviceEmpresa.buscarEmpresasCodigoAssinante(this.resultadoAssinante.codigoAssinante).subscribe(
      (resultado: Array<any>) => {
        let filtro = resultado.filter(function (reg: CamposFichaGeralEmpresas) {
          return reg.status === "SDO0017"
        })
        this.dataDataEmpresa = filtro
      }
    ), erro => {
      this.mensagem.creat("Impossível buscar os dados da Empresa no momento, tente novamente mais tarde.", { erro: true })
    }
  }

  cadastrarMinhasConexoesProfissionais() {
    this.dadosMinhasConexoesProfissionais.status = "SDO0099"
    this.dadosMinhasConexoesProfissionais.cpfAutor = sessao.cpf
    this.dadosMinhasConexoesProfissionais.ativoDesde = this.serviceHora.hojeBrasil()

    if (this.dadosMinhasConexoesProfissionais.cpfProfissional != null) {

      var variavelFichaConexoesProfissionais = this.dadosMinhasConexoesProfissionais
      // this.serviceCadastroDadosProfissionais.getCadastroDadosProfissionaisCpf(this.dadosMinhasConexoesProfissionais.cpfProfissional)
      //   .subscribe(
      //     (sucesso => {
      //       this.dadosCadastroDadosProfissionais = sucesso

      //       if (this.dadosCadastroDadosProfissionais[0].profissao != null || this.dadosCadastroDadosProfissionais[0].profissao != undefined) {
      //         if (this.dadosCadastroDadosProfissionais[0].profissional === "CADPRO1") {
      //           // this.serviceEspecialidadeCrm.getEspecialidadeCrmFiltrarCpf(variavelCPF)
      //           //   .subscribe(
      //           //     (sucesso => {
      //           //       this.dadosEspecialidadeCrm = sucesso
      //           //       var variavel = this.dadosEspecialidadeCrm[0].crm
      //           //       if (variavel === null || variavel === undefined) {
      //           //         this.modalRef.hide()
      //           //         this.mensagem.creat("O CPF cadastrado não possui CRM ativo ou cadastrado no sistema.", { aviso: true })
      //           //       }
      //           //       else {
      //           //         this.dadosMinhasConexoesProfissionais = variavelFichaConexoesProfissionais
      //           //         this.dadosMinhasConexoesProfissionais.tipoConexao = "TIPCON3"
      //           //         this.service.PostCadastrarConexoesProfissionais(this.dadosMinhasConexoesProfissionais)
      //           //           .subscribe(
      //           //             (sucesso => {
      //           //               console.log("Salvo com sucesso: " + JSON.stringify(sucesso))
      //           //               this.mensagem.creat('Conexão profissional cadastrada com sucesso!', { sucesso: true })
      //           //               this.buscarDadosMinhasConexoesProfissionais()
      //           //               this.modalRef.hide()
      //           //             }), erro => {
      //           //               console.log("Deu erro" + erro)
      //           //             })
      //           //       }
      //           //     })
      //           //   )
      //         }
      //         else {
      //           this.dadosMinhasConexoesProfissionais = variavelFichaConexoesProfissionais
      //           this.dadosMinhasConexoesProfissionais.tipoConexao = "TIPCON4"
      //           this.service.PostCadastrarConexoesProfissionais(this.dadosMinhasConexoesProfissionais)
      //             .subscribe(
      //               (sucesso => {
      //                 this.modalRef.hide()
      //                 this.mensagem.creat('Conexão profissional cadastrada com sucesso!', { sucesso: true })
      //                 this.buscarDadosMinhasConexoesProfissionais()
      //               }), erro => {
      //                 this.mensagem.creat("Não foi possível adicionar a conexão no momento, por favor tente novamente mais tarde.", { erro: true })
      //               })
      //         }
      //       }
      //       else {
      //         this.mensagem.creat("Este usuário não cadastrou dados profissionais, não é possível fazer a conexão.", { aviso: true })
      //       }

      //     })
      //   )
    }
    else {
      this.modalRef.hide()
      this.mensagem.creat("Digite um CPF válido.", { aviso: true })
    }

    this.dadosMinhasConexoesProfissionais = new dadosMinhasConexoesProfissionais()
  }

  async pesquisarProfissionais() {
    this.loadingRegistro = true
    this.regProfissional = new resultDadosProf
    let cpf: string = this.dadosMinhasConexoesProfissionais.cpfProfissional
    if (cpf.length == 0) this.loadingRegistro = false
    if (cpf.length < 10) return
    let registro = await this.serviceEmpresa.getRegistro(cpf)
    this.regProfissional = registro
    this.loadingRegistro = false

  }

  BuscarMinhasConexoesProfissionais() {
    console.log("ID: " + this.IdMinhasConexoesProfissionais)
    this.service.getMinhasConexoesProfissionaisID(this.IdMinhasConexoesProfissionais).subscribe(
      (sucesso => {
        this.MinhasConexoesProfissionaisID = sucesso
        this.modalEditar(this.templateEditar)
        console.log("Dados Api: " + JSON.stringify(sucesso))
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }

  async CadastrarConexao(doc, checked) {
    this.itemIndex = doc.id
    this.loadingCadastro = true
    console.log(doc)
    await this.service.getMinhasConexoesProfissionaisID(doc.cpfProfissional).toPromise()
      .then(async (registros: Array<any>) => {

        let documento = registros.filter((reg) => {
          console.log(reg.codigoEmpresa, doc.codigoEmpresa)
          return reg.codigoEmpresa == doc.codigoEmpresa
        })[0]
        if (!documento) {
          this.loadingCadastro = false
          this.laodingErro = true
          this.buscarDadosMinhasConexoesProfissionais()
          return
        }
        if (checked) {
          documento.status = "SDO0025"
        } else {
          documento.status = "SDO0044"
        }
        await this.service.AlterarConexoesProfissionais(documento.id, documento).toPromise()
          .then(
            (res) => {
              this.loadingCadastro = false
            }
          ).catch(() => this.loadingCadastro = false)
        this.buscarDadosMinhasConexoesProfissionais()
      })
    console.log(doc, checked)
  }

  BuscarRegraConfirmacaoConsultaID() {

    this.service.getMinhasConexoesProfissionaisID(this.CpfRegraMinhasConexoes).subscribe(
      (sucesso => {
        this.MinhasConexoesProfissionaisID = sucesso
        if (this.MinhasConexoesProfissionaisID != null) {
          //Função para desconectar
          if (this.MinhasConexoesProfissionaisID[0].status === "Ativo") {
            this.MinhasConexoesProfissionaisID[0].status = "Inativo"
            this.MinhasConexoesProfissionaisID[0].ativoDesde = null
            this.MinhasConexoesProfissionaisID[0].inativoDesde = this.serviceHora.hojeBrasil()

            this.service.AlterarConexoesProfissionais(this.MinhasConexoesProfissionaisID[0].id, this.MinhasConexoesProfissionaisID[0]).subscribe(
              (sucesso => {
                this.mensagem.creat('Conexão profissional desconectada com sucesso!', { sucesso: true })
                this.btnDesconectar = true;
                this.btnConectar = null;
                this.buscarDadosMinhasConexoesProfissionais()
              }),
              error => console.log("Deu erro: " + JSON.stringify(error))
            )
          }
          else {
            //Função para conectar
            this.MinhasConexoesProfissionaisID[0].status = "Ativo"
            this.MinhasConexoesProfissionaisID[0].inativoDesde = null
            this.MinhasConexoesProfissionaisID[0].ativoDesde = this.serviceHora.hojeBrasil()

            this.service.AlterarConexoesProfissionais(this.MinhasConexoesProfissionaisID[0].id, this.MinhasConexoesProfissionaisID[0]).subscribe(
              (sucesso => {
                this.btnDesconectar = null;
                this.btnConectar = false;
                this.mensagem.creat('Conexão profissional conectada com sucesso!', { sucesso: true })
                this.buscarDadosMinhasConexoesProfissionais()
              }),
              error => console.log("Deu erro: " + JSON.stringify(error))
            )

          }
        }
        else {
          this.mensagem.creat("Impossível obter os dados da Minhas conexões profissionais", { aviso: true })
        }
      }), (erro => {
        console.log("Deu erro: " + erro)
      }))
  }
}
