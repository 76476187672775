import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estrutura-organizacional',
  templateUrl: './estrutura-organizacional.component.html',
  styleUrls: ['./estrutura-organizacional.component.scss']
})
export class EstruturaOrganizacionalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
