import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SalaEsperaForm, ConsultaForm } from 'src/app/atendimento-clinico/sala-espera/model';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { SalaEsperaService } from 'src/app/service/sala-espera.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormUsuario } from 'src/app/usuario/model';

@Component({
  selector: 'app-sala-espera-secretaria',
  templateUrl: './sala-espera-secretaria.component.html',
  styleUrls: ['./sala-espera-secretaria.component.scss']
})
export class SalaEsperaSecretariaComponent implements OnInit {
  @ViewChild('body', {static:true}) document : ElementRef
  @ViewChild('Confirmar', { static: true }) Confirmar: SwalComponent
  @ViewChild('Cancelar', { static: true }) Cancelar: SwalComponent
  id
  companyId
  imgPerfil = './assets/imgUser.png'
  chamarPaciente: SalaEsperaForm;
  registroSala: SalaEsperaForm[];
  registroConsultas: ConsultaForm[];
  idPaciente
  tituloTabela;
  erro: boolean
  erroMensagem: string
  loading: boolean
  cobrar = false
  pacienteCobrar 
  constructor(
    private dataPipe: DatePipe,
    private form: FormBuilder,
    private service: SalaEsperaService,
    private serviceUsuario: UsuarioService,
    private mensagem: ToastrService,
    private url: ActivatedRoute,
    private nav: Router
  ) {
    this.urlID()
    this.companyId = localStorage.getItem('companyId')
  }

  async ngOnInit() {
    this.loading = true
    await this.buscarId().then(
      async () => {
        await this.BuscarRegistro()
        this.loading = false
      }
    )
    this.tituloTabela = [
      { nome: 'Paciente', campo: 'paciente' }, { nome: 'Senha', campo: 'senha' },{ nome:"Medico", campo : 'medico'},{ nome: 'Horário', campo: 'horario' },
      { nome: 'Prioridade', campo: 'prioridade' }, { nome: 'Status', campo: 'status' },
    ]

  }
  urlID() {
    this.url.url.subscribe(async reg => {
      console.log('Filtro :' + reg[1].path)
      this.id = reg[1].path
    });
  }
  print(){
    this.document.nativeElement.print()
  }
  teste(dado) {
    console.log(JSON.stringify(dado))
  }
  cobrarConsulta(reg){
    this.cobrar = true
    console.log(JSON.stringify(reg))
    this.pacienteCobrar = [reg]
  }
  async buscarId() {
   if(this.companyId) return
    await this.serviceUsuario.listar().toPromise()
      .then(
        async (dados: Array<FormUsuario>) => {
          console.log('teste : '+this.id)
          let id = this.id
          let filtro =  await dados.filter(function(reg : FormUsuario){
            console.log(reg.usuarioId + ' -- ' + id)
            return reg.usuarioId.toUpperCase() ==  id.toUpperCase() 
          })
          console.log('Seu filtro '+JSON.stringify(filtro))
          let reg = filtro[0]
          this.companyId = reg.companyId
          localStorage.setItem('companyId', this.companyId)
          console.log('Company ID @ : ' + this.companyId )
          this.erro = false
        }
      )
      .catch(
        () => {
          console.log('Company ID @ erro: ' + this.companyId)
          this.erro = true
          this.erroMensagem = "Não foi possível localizar registro"
        }
      )
  }

  async BuscarRegistro() {
    let companyId = await this.companyId
    console.log("CompanyID : " + companyId)
    if(!this.companyId) return
    // await this.service.listarSalaEspera().toPromise().then(
    //   async (dados: SalaEsperaForm[]) => {
    //     let filtro = await dados.filter(function (reg: SalaEsperaForm) {
    //       return reg.companyId.toUpperCase() == companyId.toUpperCase() && reg.processo != 3
    //     })
    //     let creat = filtro[0].createdAt
    //     console.log('Removido' +creat.replace(/\D+/g,''))

    //     let orderna = await filtro.sort(function (a: SalaEsperaForm, b: SalaEsperaForm) {
    //       return parseInt(b.createdAt.replace(/\D+/g,'')) - parseInt(a.createdAt.replace(/\D+/g,'')) 
    //       // return a.senha - b.senha && parseInt(a.hora) - parseInt(b.hora)
    //     })
    //     this.registroSala = orderna
    //     if (filtro.length == 0 && !this.erro) {
    //       this.erro = true
    //       this.erroMensagem = "Sala de espera vazia "
    //     }
    //   }

    // ).catch(
    //   () => {
    //     this.erro = true
    //     this.erroMensagem = "Não foi possível localizar registro"
    //   }
    // )
  }
  async Paciente(id) {
    this.idPaciente = id
    await this.service.listarConsultas().toPromise().then(
      async (dados: ConsultaForm[]) => {
        let idPaciente = this.idPaciente
        let filtro = dados.filter(function (reg: ConsultaForm) {
          return reg.usuarioId == idPaciente
        })
        if (filtro.length >= 1) {
          this.registroConsultas = filtro
        }
        else {
          this.registroConsultas = null
        }
      }
    )
  }

  async ChamarPaciente(registro: any, ref?) {
    let dados = registro
    if (ref) { dados['processo'] = 0 }
    else { dados['processo'] = 2 }
    await this.service.alterarSalaEspera(dados).toPromise()
      .then(
        async () => {
          if (!ref) {
            // this.mensagem.success(dados['paciente'] + ' , enviado para atendimento!');
          }
          this.mensagem.warning('Senha ' + dados['senha'] + ' Removida da Sala de Espera ');
          this.BuscarRegistro()
        }
      )
      .catch(
        error => { this.mensagem.error('Erro!'); console.log('ERRo! - ' + JSON.stringify(error)) }
      )

    this.Confirmar.ngOnDestroy()
    this.Cancelar.ngOnDestroy()
    await this.encaminharAtendimento(dados.id)
  }

  async encaminharAtendimento(idPaciente) {
    await localStorage.clear()
    await localStorage.setItem('indexRefAv', 'false') // permite o sistema cadastrar a avaliação clinica do paciente 
    await localStorage.setItem('indexRefCm', 'false') // permite o sistema cadastrar a Conduta Clinica
    await localStorage.setItem('indexRefPM', 'false') // permite o sistema cadastrar a Parecer Medico
    this.nav.navigateByUrl(`home/atendimento-clinico/${this.id}/${idPaciente}`)
  }
  abrirCheckin() {
    this.nav.navigateByUrl(`home/chekin-paciente/${this.id}`)
  }

}
