import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss']
})
export class DevComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }
  teste() {
   
  }

}


