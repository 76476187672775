import { Component, OnInit, ViewChild } from '@angular/core';
import { CadastroOperadoraSaude } from './modelsCadastroOperadoraSaude';
import { CadastroOperadoraSaudeService } from '../service/cadastro-operadora-de-saudade.service';
import { HubDevService } from '../service/hub-dev.service';
import { SessaoUser } from '../user.reg';
import { MensagemService } from '../service/mensagem.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FiltrarDadosService } from '../service/filtrar-dados.service';

interface EstadosBrasil {
  value: string
  valueText: string
}

interface AutorizacaoDispositivo {
  value: string
  valueText: string
}
interface FormaPagamento {
  value: string
  valueText: string
}

interface DiaFechamento {
  value: number
  valueText: string
}

const sessao = new SessaoUser

@Component({
  selector: 'app-cadastro-operadora-saude',
  templateUrl: './cadastro-operadora-saude.component.html',
  styleUrls: ['./cadastro-operadora-saude.component.scss']
})

export class CadastroOperadoraSaudeComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'nomeOperadoraSaude', 'telefone']
  displayedFichaTecnica = ['acao', 'areaAtuacao', 'servicos']
  viewCadastro: boolean;
  listRegistro: CadastroOperadoraSaude[]
  listEmpresa = []
  mask_cep = "00000-000"
  mask_cnpj = "00.000.000/0000-00"
  mask_telefone = "(00) 00000-0000"
  formulario: FormGroup
  botaoSalvar = false
  botaoCancelar = false
  alteracao = false
  listPaises: any
  listBancos: any
  listMoedas = []
  loading: boolean
  dataSource = new MatTableDataSource()
  selectEmpresa = 0
  listPlanoContas = []
  viewConfirmCancelar: boolean
  viewConfirmDelete: boolean
  viewFichaTecnica: boolean
  excluir: boolean
  modoCriacao: boolean
  modoExibicao: boolean
  modoAlteracao: boolean
  viewCampRequired: boolean


  listAutorizacaoDisposito: AutorizacaoDispositivo[] = [
    { value: '1', valueText: 'Bloqueado' },
    { value: '2', valueText: 'Necessário autorização operadora' },
    { value: '3', valueText: 'Sempre autorizado' }
  ];

  listFormaPagamento: FormaPagamento[] = [
    { value: '1', valueText: 'Boleto' },
    { value: '2', valueText: 'Transferência' },
    { value: '3', valueText: 'Depósito' }
  ];

  listEstadosBrasil: EstadosBrasil[] = [
    { value: '1', valueText: 'Acre' },
    { value: '2', valueText: 'Alagoas' },
    { value: '3', valueText: 'Amapá' },
    { value: '4', valueText: 'Amazonas' },
    { value: '5', valueText: 'Bahia' },
    { value: '6', valueText: 'Ceará' },
    { value: '7', valueText: 'Distrito Federal' },
    { value: '8', valueText: 'Espírito Santo' },
    { value: '9', valueText: 'Goiás' },
    { value: '10', valueText: 'Maranhão' },
    { value: '11', valueText: 'Mato Grosso' },
    { value: '12', valueText: 'Mato Grosso do Sul' },
    { value: '13', valueText: 'Minas Gerais' },
    { value: '14', valueText: 'Pará' },
    { value: '15', valueText: 'Paraíba' },
    { value: '16', valueText: 'Paraná' },
    { value: '17', valueText: 'Pernambuco' },
    { value: '18', valueText: 'Piauí' },
    { value: '19', valueText: 'Rio de Janeiro' },
    { value: '20', valueText: 'Rio Grande do Norte' },
    { value: '21', valueText: 'Rio Grande do Sul' },
    { value: '22', valueText: 'Rondônia' },
    { value: '23', valueText: 'Roraima' },
    { value: '24', valueText: 'Santa Catarina' },
    { value: '25', valueText: 'São Paulo' },
    { value: '26', valueText: 'Sergipe' },
    { value: '27', valueText: 'Tocantins' },
  ]

  listDiasFechamento: DiaFechamento[] = [
    { value: 1, valueText: '01' },
    { value: 2, valueText: '02' },
    { value: 3, valueText: '03' },
    { value: 4, valueText: '04' },
    { value: 5, valueText: '05' },
    { value: 6, valueText: '06' },
    { value: 7, valueText: '07' },
    { value: 8, valueText: '08' },
    { value: 9, valueText: '09' },
    { value: 10, valueText: '10' },
    { value: 11, valueText: '11' },
    { value: 12, valueText: '12' },
    { value: 13, valueText: '13' },
    { value: 14, valueText: '14' },
    { value: 15, valueText: '15' },
    { value: 16, valueText: '16' },
    { value: 17, valueText: '17' },
    { value: 18, valueText: '18' },
    { value: 19, valueText: '19' },
    { value: 20, valueText: '20' },
    { value: 21, valueText: '21' },
    { value: 22, valueText: '22' },
    { value: 23, valueText: '23' },
    { value: 24, valueText: '24' },
    { value: 25, valueText: '25' },
    { value: 26, valueText: '26' },
    { value: 27, valueText: '27' },
    { value: 28, valueText: '28' },
    { value: 29, valueText: '29' },
    { value: 30, valueText: '30' },
    { value: 31, valueText: '31' }

  ];
  listBancos_Backup: any;

  constructor(
    private service: CadastroOperadoraSaudeService,
    private _form: FormBuilder,
    private token: TokenDeAcessoService,
    private conteudo: ConteudoDeDadosService,
    private mensagem: MensagemService,
    private _hubDev: HubDevService,
    private _filtro: FiltrarDadosService
  ) { }

  async ngOnInit() {
    this.creatForm()
    this.getEmpresa()
    this.getPaises()
    this.getMoedas()
    this.getBancos()
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  creatForm() {
    this.formulario = this._form.group({
      id: '',
      codigo: 0,
      criadoPor: sessao.cpf,
      webSite: '',
      email: new FormControl('', Validators.required),
      telefone: new FormControl('', Validators.required),
      areaAtuacao: new FormControl('', Validators.required),
      servicos: new FormControl('', Validators.required),
      cnpj: new FormControl('', Validators.required),
      razaoSocial: '',
      cnpjMatriz: '',
      moedaEmpresa: new FormControl('', Validators.required),
      prazoPagamento: new FormControl('', Validators.required),
      formaPagamento: new FormControl('', Validators.required),
      pais:new FormControl('', Validators.required),
      banco: '',
      agencia: '',
      conta: '',
      autorizacaoDeAtendimento: '',
      observacao: '',
      status: '',
      codigoEmpresa: new FormControl(0, Validators.required),
      cep: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      cidade: new FormControl('', Validators.required),
      rua: new FormControl('', Validators.required),
      bairro: new FormControl('', Validators.required),
      numeroEndereco:new FormControl('', Validators.required),
      criadoEm: '',
      nomeOperadora: new FormControl('', Validators.required),
      complemento: '',
      nomeContato: '',
      nomeResponsavel: new FormControl('', Validators.required),
      diaFechamento: new FormControl(0, Validators.required),
      telefoneContato: ''
      // :new FormControl('', Validators.required)
    })
  }

  _valid(formControlName) {
    return this.formulario.get(formControlName).valid
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SCA0001')
    this.modoCriacao = true
    this.modoAlteracao = false
    this.modoExibicao = false
  }

  openFichaTecnica() {
    this.viewFichaTecnica = true
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getPaises() {
    this.service.getPaises().subscribe(response => {
      this.listPaises = response
    })
  }

  async getMoedas() {
    let moeda = await this.conteudo.get('MOEDAS')
    this.listMoedas = moeda
  }

  async getBancos() {
    this.service.getBancos().subscribe(response => {
      this.listBancos = response
      console.log('BANCOS: ',this.listBancos)
     let order = this.listBancos.sort(function (a, b) {
        let acodigoBanco = a.codigoBanco
        let bcodigoBanco = b.codigoBanco
        if (a == b) return
        return acodigoBanco < bcodigoBanco ? -1 : 1
      })
      this.listBancos = order
      this.listBancos_Backup = order
    })
  }

  getCEP() {
    if (this.formulario.controls['cep'].invalid) return
    let cep = this.formulario.controls['cep'].value

    this._hubDev.vCEP(cep).toPromise()
      .then(resp => {
        let cep = resp.result
        this.formulario.controls['rua'].setValue(cep.logradouro)
        this.formulario.controls['bairro'].setValue(cep.bairro)
        this.formulario.controls['cidade'].setValue(cep.localidade)
        this.formulario.controls['estado'].setValue(cep.uf)
      })
  }

  async getPlanoContas(codigoEmpresa) {
    await this.service.listarContas(codigoEmpresa).toPromise()
      .then((response: any) => {
        this.listPlanoContas = response
      })
  }

  async validarCNPJ() {
    if (this._control('cnpj').invalid) {

      this._control('razaoSocial').setValue('')
      return
    }

    let cnpj = this._control('cnpj').value
    this._hubDev.vCNPJ(cnpj).subscribe(({ status, result: empresa }) => {
      if (!status) {
        throw this.mensagem.creat("CNPJ não encontrado. Verifique se foi digitado corretamente.", { erro: true })
      }
      else {
        this._control('razaoSocial').setValue(empresa.nome)
      }
    })
  }

  _control(formControlName) {
    return this.formulario.controls[formControlName]
  }


  async getRegistro() {
    this.loading = true

    await this.service.listar().toPromise()
      .then(registro => {
        this.listRegistro = registro.filter(item => item.status == 'SCA0001')
        this.listRegistro.forEach(async item => {
          let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)

          if (empresa.length > 0) {
            item.nomeEmpresa = empresa[0].nomeEmpresa
          }

        })
        this.dataSource.sort = this.sort
        this.loading = false
      })
  }

  verificarCamposValidos() {
    const invalid = [];
    const controls = this.formulario.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  
  filterBanco(value: string) {
    let registros = this.listBancos_Backup
    let result = registros.filter(item => item.NomeBanco.toUpperCase().includes(value.toUpperCase()))
    this.listBancos = result
  }
  inserir() {
    let registro = this.formulario.value
    delete registro.id
    
    if (this.formulario.invalid) {
      this.mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      let verific = this.verificarCamposValidos()
      console.log(verific)
      return
    }

    
   Object.keys(registro).forEach(
     value => {
       if (!registro[value]) {
         delete registro[value]
       }
     }
   )

    registro.criadoPor = sessao.cpf

    

    this.service.enviar(registro).toPromise()
      .then(resposta => {
        this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.botaoSalvar = false
        this.botaoCancelar = false
        this.fecharRegistro()
      }).catch(
        error => {
          console.log(error)
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )

  }

  async openCadastro(registro) {
    console.log("Open: ", registro)
    this.getBancos()
    delete registro.nomeEmpresa
    this.formulario.patchValue(registro)

    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.excluir = false   
    this.modoExibicao = true
    this.modoCriacao = false
    this.modoAlteracao = false
    
  }


  alterar() {
    let registro = this.formulario.value

    this.service.alterar(registro).toPromise()
      .then(() => {
        this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
        this.viewCadastro = true
        this.formulario.disable()
        this.modoExibicao = true
        this.modoAlteracao = false
        this.modoCriacao = false
        this.botaoSalvar = false
        this.botaoCancelar = false
        this.fecharRegistro()
      }).catch(error => {
        let erro = error.error
        this.mensagem.creat(erro, { erro: true })
      })
  }

  async cancelarRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmCancelar = true
      return
    }

    this.viewConfirmCancelar = false
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
    this.excluir = false
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.modoExibicao = true
    this.modoAlteracao = false
    this.modoCriacao = false
  }

  async fecharRegistro() {
    this.viewCadastro = false
    this.excluir = false
    this.viewCampRequired = false
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())
  }

  alterarRegistro() {
    this.formulario.enable()
    this.botaoSalvar = true
    this.botaoCancelar = true
    this.modoAlteracao = true
    this.modoExibicao = false
    this.modoCriacao = false
  }


  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    let registro = this.formulario.value
    if (!registro.codigo) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    await this.getRegistro()
      .finally(() => this.filtrarEmpresa())

    console.log('Excluir: ', registro)
  }


  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }

    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }
}
