import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValorVendaService {

  url = new environment().api
  url_local = new environment().api_local
  api = 'ValorVenda/';

  constructor(private http: HttpClient) { }

  //************************** ValorVenda **********************************
  listarValorVenda(id?) {
    console.log('ValorVenda :' + this.url + this.api)
    if (id) {
      return this.http.get(this.url + this.api + id)
    }
    return this.http.get(this.url + this.api)
  }
  cadastrarValorVenda(dados: any) {
    console.log('ValorVenda-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)

  }
  alterarValorVenda(id: string, dados) {
    console.log('ValorVenda-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirValorVenda(id: string, dados?) {
    console.log('ValorVenda-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
  //************************** END ValorVenda **********************************  

}
