import { PosicaoNumeracaoNfeService } from './../../service/posicao-numeracao-nfe.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CamposPosicaoNfe, CamposFichaGeralEmpresas } from './campos-posicaoNfe';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MensagemService } from 'src/app/service/mensagem.service';
import { DadosFichaGeralEmpresa } from 'src/app/configuracao-inicial/regras-de-imposto/model';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service';
import { SessaoUser } from 'src/app/user.reg';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';

const sessao = new SessaoUser

@Component({
  selector: 'app-posicao-numeracao-nfe',
  templateUrl: './posicao-numeracao-nfe.component.html',
  styleUrls: ['./posicao-numeracao-nfe.component.scss']
})
export class PosicaoNumeracaoNfeComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort
  displayedColumns = ['detalhe', 'nomeEmpresa', 'posicaoInicial', 'ultimaEmissaoValida', 'ultimaCancelada', 'proximaEmissao']

  viewCadastro: boolean
  listEmpresa = []
  formulario: FormGroup
  alteracao = false
  botaoSalvar = false
  botaoCancelar = false
  loading: boolean
  listRegistro: CamposPosicaoNfe[]
  dataSource
  empresaSelect: any

  selectEmpresa = 0

  id: number
  adicionar: false
  cancelarBox: false
  dataPosicaoNfe: CamposPosicaoNfe = new CamposPosicaoNfe
  dataFichaEmpresas: CamposFichaGeralEmpresas
  regPosicaoNfe: CamposPosicaoNfe
  DetalhesPosicaoNfe: CamposPosicaoNfe
  listar: boolean
  dataDadosEmpresa: DadosFichaGeralEmpresa[]
  listaCheckBox = []
  modalRef: BsModalRef;
  resultadoAssinante: FichaAssinante;
  ///


  constructor(
    private token: TokenDeAcessoService,
    private service: PosicaoNumeracaoNfeService,
    private form: FormBuilder,
    private mensagem: MensagemService,
    private posicaoNfeService: PosicaoNumeracaoNfeService,
    private modalService: BsModalService,
    private serviceEmpresa: FichaGeralService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private _filter: FiltrarDadosService
  ) { }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getRegistro(0)
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: 0,
      posicaoInicial: 0,
      ultimaEmissaoValida: 0,
      ultimaCancelada: 0,
      proximaEmissao: 0,
      status: '',
      timeStamp: '',
      ////
      criadoPor: sessao.cpf
    })
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }


  async getRegistro(codigoEmpresa?) {
    this.loading = true
    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)

            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
            }

            console.log("registro parâmetro: ", registro)
            console.log("Registro lista: ", this.listRegistro)
          })
          if (codigoEmpresa != 0) {
            this.listRegistro = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
          } else {
            let empresas = JSON.parse(sessao.listEmpresas)
            this.listRegistro = this._filter.filterTodos(this.listRegistro, empresas)
          }

          this.dataSource = new MatTableDataSource(this.listRegistro)
          this.dataSource.sort = this.sort
          this.loading = false
        })
  }

  async openCadastro(registro) {

    this.formulario.patchValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    this.formulario.disable()

    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  async inserir() {
    let registro = this.formulario.value
    Object.keys(registro).forEach(value => { if (!registro[value]) delete registro[value] })

    registro.criadoPor = sessao.cpf

    await this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          console.log("Resposta: ", resposta)
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = false
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false

        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
    this.getRegistro(this.selectEmpresa)
    console.log('Inserir:', registro)
  }

  async alterar() {
    let registro = this.formulario.value

    await this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          }
        )
    this.getRegistro(this.selectEmpresa)
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro() {
    let registro = this.formulario.value

    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
  }

  filtrar(value) {
    this.empresaSelect = value
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }


  filtrarEmpresa(codigoEmpresa) {
    this.loading = true

    this.dataSource.data = this.listRegistro

    if (!codigoEmpresa) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
      return
    }

    let filter = this.dataSource.data.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.empresaSelect = this.dataSource.data

    setTimeout(() => {
      this.loading = false
    }, 2000)
  }

}

