import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { DadosDaSaudeService } from 'src/app/service/dados-da-saude/dados-da-saude.service';
import { ProntuarioVidaPaciente, SinaisVitaisDoPaciente } from 'src/app/service/dados-da-saude/model';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { AlergiaMedicacao, CheckedAtDadosSaude, CirurgiaRealizadas, DataUltimoCheckup, Doencas, HistoricoDoencaFamilia, InternacoesHospitalares, LesoesFraturaContusoes, MedicamentoEmUso, OutrasAlergias, ProcedimentoAdicionais, TratamentoRealizados, ViewSubItem } from './model';
const sessao = new SessaoUser

@Component({
  selector: 'app-atualizar-dados-saude',
  templateUrl: './atualizar-dados-saude.component.html',
  styleUrls: ['./atualizar-dados-saude.component.scss']
})
export class AtualizarDadosSaudeComponent implements OnInit {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  atDadosSaude = new CheckedAtDadosSaude

  PCD = {
    PCD1: null, //Física
    PCD2: null, //Visual
    PCD3: null, //Auditiva
    PCD4: null, //MentalPCD
  }

  ///// CARFISICA <-- ATUALIZAR CARACTERISTICA 
  sinaisVitatis = {
    INDSAU1: null, //TEMPERATURA
    INDSAU2: null, //SATURACAO
    INDSAU3: null, //PRESSAO ARTERIAL sistólica
    INDSAU4: null, //GLICEMIA
    INDSAU5: null, //BATIMENTOS CARDIACOS
    INDSAU6: null, //BATIMENTOS CARDIACOS FETO
    INDSAU7: null, //IMC
    INDSAU10: null, //PRESSAO ARTERIAL diastólica
  }


  indicadorSaude = {
    CARFISICA1: null, //	Peso
    CARFISICA2: null, //	Altura
    CARFISICA3: null, //	Cintura
    CARFISICA4: null, //	Tipo sangûineo
    CARFISICA5: null, //	PCD Pessoa com deficência
  }

  habitosDeVida = {
    HABVID1: null,	//FUMA
    HABVID2: null,	//EXERCICIO
    HABVID3: null,	//BEBIDA ALCOOLICA
    HABVID4: null,	//ENTORPECENTE
  }
  checked_habitosDeVida = {
    fumante: false,
    exericio: false,
    bebida: false,
    entorpecente: false,
  }



  doencas = new Doencas
  medUso = new MedicamentoEmUso
  ultimoCheckup = new DataUltimoCheckup
  historicoDoencaFamilia = new HistoricoDoencaFamilia
  cirurgiaRealizadas = new CirurgiaRealizadas
  lesaoFraturaContusoes = new LesoesFraturaContusoes
  alergiaMedicamento = new AlergiaMedicacao;
  outrasAlergias = new OutrasAlergias
  tratamentoRealizados = new TratamentoRealizados
  procedimentoAdicional = new ProcedimentoAdicionais
  internacoesHospitalares = new InternacoesHospitalares

  listDoencas_congenitas = []
  listMedicamentoUso = []
  listUltimoCheckup = []
  listDoencas_preexistentes = []
  listDoencas_cronicas = []
  listDoencas_contraidas = []
  listDoencas_familiar = []
  listCirurgia_realizadas = []
  listLFC = []
  listAlergia_medicamento = []
  listAlergia_outros = []
  listTratamentoRealizados = []
  listProcedimentoAdicional = []
  listInternacoesHospitalares = []

  numeroDocumento: any
  subItem = new ViewSubItem




  constructor(private service: DadosDaSaudeService, private _mensagem: MensagemService) { }

  async ngOnInit() {
    this.atDadosSaude.caracteristicas = true
    this.subItem.indicadorSaude = true
    await this.getProntuario()
  }
  async getProntuario() {
    this.numeroDocumento = await this.service.getNumeroProntuario(sessao.cpf)
    if (this.numeroDocumento) {


    } else {
      this.numeroDocumento = await this.service.creatProntuario(sessao.cpf)
      if (!this.numeroDocumento) {
        this._mensagem.creat('Não foi possivel identificar prontuario', { erro: true })
      }
    }
  }

  async teste() {
    let result = await this.service.postConteudo(this.sinaisVitatis, this.numeroDocumento)
    console.log('RESULTADO! > ', result)
  }

  async salvar() {

    /////////// PDC
    await this.service.postItem('PCD', this.numeroDocumento).toPromise()
      .then(async res => {
        let result = await this.service.postConteudo(this.PCD, this.numeroDocumento)
        console.log('RESULTADO! > ', result)
      }).catch(error => console.log('ProntuarioDeVidaItems', error))

    /////////// Sinais Vitais
    await this.service.postItem('INDSAU', this.numeroDocumento).toPromise()
      .then(async res => {
        let result = await this.service.postConteudo(this.sinaisVitatis, this.numeroDocumento)
        console.log('RESULTADO! > ', result)
      }).catch(error => console.log('ProntuarioDeVidaItems', error))

    /////////// Data último chekup
    await this.service.postItem('DATCHEUP', this.numeroDocumento).toPromise()
      .then(async res => {
        let result = await this.service.postConteudo(this.ultimoCheckup, this.numeroDocumento)
        console.log('RESULTADO! > ', result)
      }).catch(error => console.log('ProntuarioDeVidaItems', error))

    /////////// Indicador de saúde
    await this.service.postItem('INDSAU', this.numeroDocumento).toPromise()
      .then(async res => {
        let result = await this.service.postConteudo(this.indicadorSaude, this.numeroDocumento)
        console.log('RESULTADO! > ', result)
      }).catch(error => console.log('ProntuarioDeVidaItems', error))

    /////////// Hábitos de vida
    await this.service.postItem('HABVID', this.numeroDocumento).toPromise()
      .then(async res => {
        let result = await this.service.postConteudo(this.habitosDeVida, this.numeroDocumento)
        console.log('RESULTADO! > ', result)
      }).catch(error => console.log('ProntuarioDeVidaItems', error))

    /////////// Hábitos de vida
    // await this.service.postItem('HABVID', this.numeroDocumento).toPromise()
    //   .then(async res => {
    //     let result = await this.service.postConteudo(this.habitosDeVida, this.numeroDocumento)
    //     console.log('RESULTADO! > ', result)
    //   }).catch(error => console.log('ProntuarioDeVidaItems', error))


  }
  controleHabVidas(event, type) {
    switch (type) {
      case 'fumante':
        if (!event.value) {
          this.habitosDeVida.HABVID1 = null
          this.checked_habitosDeVida.fumante = false
        } else {
          this.checked_habitosDeVida.fumante = true
        }
        break;
      case 'exericio':
        if (!event.value) {
          this.habitosDeVida.HABVID2 = null
          this.checked_habitosDeVida.exericio = false
        } else {
          this.checked_habitosDeVida.exericio = true
        }
        break;
      case 'bebida':
        if (!event.value) {
          this.habitosDeVida.HABVID3 = null
          this.checked_habitosDeVida.bebida = false
        } else {
          this.checked_habitosDeVida.bebida = true
        }
        break;
      case 'entorpecente':
        if (!event.value) {
          this.habitosDeVida.HABVID4 = null
          this.checked_habitosDeVida.entorpecente = false
        } else {
          this.checked_habitosDeVida.entorpecente = true
        }
        break;

      default:
        break;
    }
  }
  addTable(type) {

    switch (type) {

      case 'medUso':
        this.medUso.id = this.listMedicamentoUso.length
        this.listMedicamentoUso.push(this.medUso)
        this.medUso = new MedicamentoEmUso
        break;
      case 'ultimoCheckup':
        this.ultimoCheckup.id = this.listMedicamentoUso.length
        this.listUltimoCheckup.push(this.ultimoCheckup)
        this.ultimoCheckup = new DataUltimoCheckup
        break;
      case 'doencaCongenitas':
        this.doencas.id = this.listDoencas_congenitas.length
        this.listDoencas_congenitas.push({ id: this.doencas.id, DOECON: this.doencas.DOECON })
        this.ultimoCheckup = new DataUltimoCheckup
        break;
      case 'doencaPreexistentes':
        this.doencas.id = this.listDoencas_preexistentes.length
        this.listDoencas_preexistentes.push({ id: this.doencas.id, DOEPRE: this.doencas.DOEPRE })
        this.ultimoCheckup = new DataUltimoCheckup
        break;
      case 'doencaCronicas':
        this.doencas.id = this.listDoencas_cronicas.length
        this.listDoencas_cronicas.push({ id: this.doencas.id, DOECRI: this.doencas.DOECRI })
        this.ultimoCheckup = new DataUltimoCheckup
        break;
      case 'doencaContraidas':
        this.doencas.id = this.listDoencas_contraidas.length
        this.listDoencas_contraidas.push({ id: this.doencas.id, DOETRA: this.doencas.DOETRA })
        this.ultimoCheckup = new DataUltimoCheckup
        break;
      case 'doencaFamiliar':
        this.historicoDoencaFamilia.id = this.listDoencas_familiar.length
        this.listDoencas_familiar.push(this.historicoDoencaFamilia)
        this.historicoDoencaFamilia = new HistoricoDoencaFamilia
        break;
      case 'cirurgiaRealizadas':
        this.cirurgiaRealizadas.id = this.listCirurgia_realizadas.length
        this.listCirurgia_realizadas.push(this.cirurgiaRealizadas)
        this.cirurgiaRealizadas = new CirurgiaRealizadas
        break;
      case 'lesaoFraturaContusoes':
        this.lesaoFraturaContusoes.id = this.listLFC.length
        this.listLFC.push(this.lesaoFraturaContusoes)
        this.lesaoFraturaContusoes = new LesoesFraturaContusoes
        break;
      case 'alergiaMedicamento':
        this.alergiaMedicamento.id = this.listAlergia_medicamento.length
        this.listAlergia_medicamento.push(this.alergiaMedicamento)
        this.alergiaMedicamento = new AlergiaMedicacao
        break;
      case 'outrasAlergias':
        this.outrasAlergias.id = this.listAlergia_outros.length
        this.listAlergia_outros.push(this.outrasAlergias)
        this.outrasAlergias = new OutrasAlergias
        break;
      case 'tratamentoRealizados':
        this.tratamentoRealizados.id = this.listTratamentoRealizados.length
        this.listTratamentoRealizados.push(this.tratamentoRealizados)
        this.tratamentoRealizados = new TratamentoRealizados
        break;
      case 'procedimentoAdicional':
        this.procedimentoAdicional.id = this.listProcedimentoAdicional.length
        this.listProcedimentoAdicional.push(this.procedimentoAdicional)
        this.procedimentoAdicional = new ProcedimentoAdicionais
        break;
      case 'internacoesHospitalares':
        this.internacoesHospitalares.id = this.listInternacoesHospitalares.length
        this.listInternacoesHospitalares.push(this.internacoesHospitalares)
        this.internacoesHospitalares = new InternacoesHospitalares
        break;

      default:
        break;
    }
  }
  deleteTable(type, item) {
    switch (type) {
      case 'medUso':
        this.listMedicamentoUso.filter((x, index) => {
          if (x.id == item.id) {
            this.listMedicamentoUso.splice(index, 1)
          }
        })
        break;
      case 'ultimoCheckup':
        this.listUltimoCheckup.filter((x, index) => {
          if (x.id == item.id) {
            this.listUltimoCheckup.splice(index, 1)
          }
        })
        break;
      case 'doencaCongenitas':
        this.listDoencas_congenitas.filter((x, index) => {
          if (x.id == item.id) {
            this.listDoencas_congenitas.splice(index, 1)
          }
        })
        break;
      case 'doencaPreexistentes':
        this.listDoencas_preexistentes.filter((x, index) => {
          if (x.id == item.id) {
            this.listDoencas_preexistentes.splice(index, 1)
          }
        })
        break;
      case 'doencaCronicas':
        this.listDoencas_cronicas.filter((x, index) => {
          if (x.id == item.id) {
            this.listDoencas_cronicas.splice(index, 1)
          }
        })
        break;
      case 'doencaContraidas':
        this.listDoencas_contraidas.filter((x, index) => {
          if (x.id == item.id) {
            this.listDoencas_contraidas.splice(index, 1)
          }
        })
        break;
      case 'doencaFamiliar':
        this.listDoencas_familiar.filter((x, index) => {
          if (x.id == item.id) {
            this.listDoencas_familiar.splice(index, 1)
          }
        })
        break;
      case 'cirurgiaRealizadas':
        this.listCirurgia_realizadas.filter((x, index) => {
          if (x.id == item.id) {
            this.listCirurgia_realizadas.splice(index, 1)
          }
        })
        break;
      case 'lesaoFraturaContusoes':
        this.listLFC.filter((x, index) => {
          if (x.id == item.id) {
            this.listLFC.splice(index, 1)
          }
        })
        break;
      case 'alergiaMedicamento':
        this.listAlergia_medicamento.filter((x, index) => {
          if (x.id == item.id) {
            this.listAlergia_medicamento.splice(index, 1)
          }
        })
        break;
      case 'outrasAlergias':
        this.listAlergia_outros.filter((x, index) => {
          if (x.id == item.id) {
            this.listAlergia_outros.splice(index, 1)
          }
        })
        break;
      case 'tratamentoRealizados':
        this.listTratamentoRealizados.filter((x, index) => {
          if (x.id == item.id) {
            this.listTratamentoRealizados.splice(index, 1)
          }
        })
        break;
      case 'procedimentoAdicional':
        this.listProcedimentoAdicional.filter((x, index) => {
          if (x.id == item.id) {
            this.listProcedimentoAdicional.splice(index, 1)
          }
        })
        break;
      case 'internacoesHospitalares':
        this.listInternacoesHospitalares.filter((x, index) => {
          if (x.id == item.id) {
            this.listInternacoesHospitalares.splice(index, 1)
          }
        })
        break;

      default:
        break;
    }
  }
  async viewPage(sessao, page, no_reset?) {
    this.accordion.id
    console.log('passou', this.accordion.id)
    this.atDadosSaude = new CheckedAtDadosSaude
    this.subItem = new ViewSubItem
    if (sessao == 'atDadosSaude') {
      switch (page) {
        case 'ultimaMedSinaisVitais':
          this.atDadosSaude.ultimaMedSinaisVitais = true
          this.subItem.ultimaMedSinaisVitais = true
          break;
        case 'historicoSaude':
          this.atDadosSaude.historicoSaude = true
          // this.subItem.dataCheckup = true
          break;
        case 'dadosSaude':
          this.atDadosSaude.dadosSaude = true
          this.subItem.sinaisVitais = true
          break;
        case 'caracteristicas':
          this.atDadosSaude.caracteristicas = true
          this.subItem.indicadorSaude = true
          break;
        case 'pcd':
          this.atDadosSaude.pcd = true
          break;
        case 'sinaisVitais':
          this.atDadosSaude.sinaisVitais = true
          break;
        case 'medUso':
          this.atDadosSaude.medUso = true
          break;
        case 'dataUtChekup':
          this.atDadosSaude.dataUtChekup = true
          break;
        case 'cartVacinacao':
          this.atDadosSaude.cartVacinacao = true
          break;
        case 'habVida':
          this.atDadosSaude.habVida = true
          break;
        case 'doencaCong':
          this.atDadosSaude.doencaCong = true
          break;
        case 'doencaPree':
          this.atDadosSaude.doencaPree = true
          break;
        case 'doencaCronicas':
          this.atDadosSaude.doencaCronicas = true
          break;
        case 'doencaContraidas':
          this.atDadosSaude.doencaContraidas = true
          break;
        case 'doencaFamilia':
          this.atDadosSaude.doencaFamilia = true
          break;
        case 'evolucaoSaude':
          this.atDadosSaude.evolucaoSaude = true
          break;
        case 'cirurgiaRealizada':
          this.atDadosSaude.cirurgiaRealizada = true
          break;
        case 'lesaoFratCont':
          this.atDadosSaude.lesaoFratCont = true
          break;
        case 'alergiaMedic':
          this.atDadosSaude.alergiaMedic = true
          break;
        case 'outrasAlergias':
          this.atDadosSaude.outrasAlergias = true
          break;
        case 'tratRealizado':
          this.atDadosSaude.tratRealizado = true
          break;
        case 'procAdicionais':
          this.atDadosSaude.procAdicionais = true
          break;
        case 'interHosp':
          this.atDadosSaude.interHosp = true
          break;
        case 'medReceitaEsp':
          this.atDadosSaude.medReceitaEsp = true
          break;
        case 'medReceitaBranca':
          this.atDadosSaude.medReceitaBranca = true
          break;
      }
    }
  }
  viewSubItem(page) {
    this.subItem = new ViewSubItem
    switch (page) {
      case 'indicadorSaude':
        this.subItem.indicadorSaude = true
        break;
      case 'pcd':
        this.subItem.pcd = true
        break;
      case 'sinaisVitais':
        this.subItem.sinaisVitais = true
        break;
      case 'medicacaoUso':
        this.subItem.medicacaoUso = true
        break;
      case 'doencasCongenitas':
        this.subItem.doencasCongenitas = true
        break;
      case 'doencasPree':
        this.subItem.doencasPree = true
        break;
      case 'doencasCronicas':
        this.subItem.doencasCronicas = true
        break;
      case 'lesaoFratCont':
        this.subItem.lesaoFratCont = true
        break;
      case 'medicacaoUso':
        this.subItem.medicacaoUso = true
        break;
      case 'alergiaMedicacao':
        this.subItem.alergiaMedicacao = true
        break;
      case 'outrasAlergias':
        this.subItem.outrasAlergias = true
        break;
      case 'dataCheckup':
        this.subItem.dataCheckup = true
        break;
      case 'evolucaoSaude':
        this.subItem.evolucaoSaude = true
        break;
      case 'carteiraVacinacao':
        this.subItem.carteiraVacinacao = true
        break;
      case 'doencaFamilia':
        this.subItem.doencaFamilia = true
        break;
      case 'doencasContraidas':
        this.subItem.doencasContraidas = true
        break;
      case 'cirurgiaRealizadas':
        this.subItem.cirurgiaRealizadas = true
        break;
      case 'tratamentoRealizado':
        this.subItem.tratamentoRealizado = true
        break;
      case 'procedimentoAdicionais':
        this.subItem.procedimentoAdicionais = true
        break;
      case 'internacoes':
        this.subItem.internacoes = true
        break;
      case 'medicacoesRE':
        this.subItem.medicacoesRE = true
        break;
      case 'medicacoesRB':
        this.subItem.medicacoesRB = true
        break;

      default:
        break;
    }

  }
}

