import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitorComponent } from './monitor/monitor.component';
import {
  MatProgressBarModule,
  MatDividerModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
} from '@angular/material';
import { NavPortalComponent } from './nav-portal/nav-portal.component';
import { FooterPortalComponent } from './footer-portal/footer-portal.component';
import { ConfirmComponent } from './confirm/confirm.component';



@NgModule({
  declarations: [MonitorComponent, NavPortalComponent, FooterPortalComponent, ConfirmComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    
  ],
  exports: [
    MonitorComponent,
    NavPortalComponent,
    FooterPortalComponent,
    ConfirmComponent
  ]
})
export class LayoutModule { }
