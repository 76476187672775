export class PerfilDeAcessoDeUsuarioCAB {
  id: string
  codigoPerfil: number
  descricao: string
  tipoPerfil: string
  codigoEmpresa: number
  criadoPor: string
  criadoEm: string
  status: string
  tipoAcesso: string
  nomeEmpresa?: string
  descPerfil?: string
}
export class PerfilDeAcessoDeUsuarioITEM {
  id: string
  criadoPor: string
  criadoEm: string
  codigoPerfil: number
  nrItem: string
  tipoAcesso: string
  valorDeAcesso: string
  status: string
  codigoEmpresa: number
}

export class TipoPerfilDeAcesso {
  codigoCaracteristica: string;
  codigoReferencia: string;
  valor: string;
}

export class FichaDeAutorizacaoDeAcesso {
  id: number;
  descricao: string;
  status: string;

  select: boolean;
}

export class Perfis {
  id: string;
  descricao: string;
  perfil: string;
  perfilId: string;

  select: boolean;
}

export class MenuMaster {
  menuID: string;
  menuName: string;
  parent_MenuID: string;
  menuFileName?: string;
  menuURL?: string;
  aplicacao: string;
  tipoObjeto: string;
  tipoAcao?: string;

  select: boolean;
  routerActive?: boolean
  result?: boolean
  ordem?: any

}

export class ItemPerfil {
  codigoCaracteristica?: string;
  codigoReferencia: string;
  valor: string;

  select: boolean;
}
