import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EmpresaMyClinics } from './empresaMyClinics.model';
import { environment } from 'src/environments/environment';
import { MensagemService } from '../mensagem.service';
import { DiarioDeAcessoService } from '../diario-de-acesso/diario-de-acesso.service';
import { BuscarDataService } from '../buscar-data.service';
import { SessaoUser } from 'src/app/user.reg';

const url = `${new environment().api}MyClinicsPessoaJuridica`

const user = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class MyClinicsPessoaJuridicaService {

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
    private serviceDiarioAcesso: DiarioDeAcessoService,
    private serviceData: BuscarDataService,
  ) { }

  async listarEmpresas(): Promise<EmpresaMyClinics[]>{
    let empresas: EmpresaMyClinics[];
    await this.http.get<EmpresaMyClinics[]>(url, {observe:'body'}).toPromise()
    .then(res => empresas = res)
    .catch((err:HttpErrorResponse) => this.mensagem.creat(`Erro ao lista empresas ${err.status}:${err.statusText}`, {erro:true}))
    return empresas
  }

  async modificarEmpresa(empresa:EmpresaMyClinics){
   // const userId = this.serviceDiarioAcesso.idDestaSessao();
    const dataHoje = this.serviceData.horaAgoraSegundos();
    await this.http.put(`${url}/${empresa.id}/${user.sessao_id}/${dataHoje}`, empresa).toPromise()
    .then(() => this.mensagem.creat('Empresa alterada com sucesso', {sucesso:true}))
    .catch((err:HttpErrorResponse) => this.mensagem.creat(`Erro ao modificar empresa ${err.status}: ${JSON.stringify(err.error)}`, {erro: true}))
  }

  async desativarEmpresa(empresa:EmpresaMyClinics){
    empresa.status = 'SDO0063';
    await this.modificarEmpresa(empresa);
  }

  async criarEmpresa(empresa:EmpresaMyClinics){
    empresa.criadoPor = await this.serviceDiarioAcesso.cpfDestaSessao();
    empresa.status = 'SDO0017';
    await this.http.post(url, empresa).toPromise()
    .then(() => this.mensagem.creat('Empresa criada com sucesso', {sucesso:true}))
    .catch(res => {
      console.error(`Erro ao criar empresa: ${JSON.stringify(res.error)}`)
      console.table(empresa);
    })
  }
}
