import { Component, OnInit, Inject, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Documento } from '../service/documentos/documentos.model';
import { DocumentosService } from '../service/documentos/documentos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TabelasDoSistemaService } from '../service/tabelas-do-sistema/tabelas-do-sistema.service';
import { ClassificacaoDeDadosService } from '../service/classificacao-de-dados/classificacao-de-dados.service';
import { conteudoDeDado } from '../service/classificacao-de-dados/conteudo-de-dados.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-documentos-do-sistema',
  templateUrl: './documentos-do-sistema.component.html',
  styleUrls: ['./documentos-do-sistema.component.scss']
})

export class DocumentosDoSistemaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  panelOpenState: boolean = false;
  documentos: Documento[];
  documentosSelecionados: { idDocumento: String, checked: Boolean }[];
  displayedColumns = ['verMais','checkbox', 'codigoDocumento', 'descricao', 'prefixo', 'especificoEmpresa', 'tabela', 'controleDeAprovacao']
  formularioFiltro: FormGroup
  tabelas: string[];
  processos: { codigoReferencia: string, valor: string }[];
  tiposDado: { codigoReferencia: string, valor: string }[];
  tiposRegistro: { codigoReferencia: string, valor: string }[];
  tiposAplicacao: { codigoReferencia: string, valor: string }[];
  documentosSource = new MatTableDataSource();
  constructor(
    private documentosService: DocumentosService,
    public dialog: MatDialog,
    private construtorForm: FormBuilder,
    private tabelasService: TabelasDoSistemaService,
    private classificacaoService: ClassificacaoDeDadosService,
  ) { }

  criarFormularioDeFiltro() {
    this.formularioFiltro = this.construtorForm.group({
      codigoDocumento: [''],
      compartilhamentoDeDados: [''],
      descricao: [''],
      prefixo: [''],
      registroModificacao: [''],
      tipoDado: [''],
      aplicacao: [''],
      cabecalho: [''],
      item: [''],
      processo: [''],
      registroUnico: [''],
      tabela: [''],
      tipoRegistro: [''],
      transacao: ['']

    });
  }

  async ngOnInit() {
    this.listarDocumentos();
    this.criarFormularioDeFiltro();
    this.listarTabelas();
    this.processos = await this.gerarClassificacaoDados('TIPPRO');
    this.tiposDado = await this.gerarClassificacaoDados('TIPDAD');
    this.tiposAplicacao = await this.gerarClassificacaoDados('TIPAPL');
    this.tiposRegistro = await this.gerarClassificacaoDados('TIPREG');
  }

  async gerarClassificacaoDados(codigoCaracterisca: string): Promise<{ codigoReferencia: string, valor: string }[]> {
    let conteudos: conteudoDeDado[] = await this.classificacaoService.buscarConteudosDaCaracteristica({ codigoCaracteristica: codigoCaracterisca });
    let classificacao = conteudos.map(conteudo => {
      return { codigoReferencia: conteudo.codigoReferencia, valor: conteudo.valor }
    })
    return classificacao;
  }


  async listarTabelas() {
    this.tabelas = await this.tabelasService.listarTabelas();
  }

  conversaoArrayString(dado: string | string[]): string | string[] {
    if (typeof dado === 'string') {
      return dado.split(',')
    } else if (typeof dado === 'object') {
      return dado.join()
    }
  }

  /* Caso não seja passado um documento assume o valor padrão que precisa ser inicializado para não ter as propriedades
  undefined. 
  */
  popUpDocumento(documento: Documento = {
    codigoDocumento: '',
    compartilhamentoDeDados: false,
    descricao: '',
    especificoEmpresa: false,
    prefixo: 0,
    registroModificacao: false,
    tipoDado: '',
    aplicacao: '',
    cabecalho: '',
    item: '',
    processo: '',
    registroUnico: '',
    tabela: '',
    tipoRegistro: '',
    transacao: '',
    controleDeAprovacao: false,
  }): void {
    if (!!documento.item) documento.item = this.conversaoArrayString(documento.item);
    const dialogRef = this.dialog.open(novoDocumentoDialog, {
      width: '50vw',
      height: '75vh',
      data: Object.assign(documento)
    })

    dialogRef.afterClosed().subscribe(() => {
      this.listarDocumentos();
    })
  }

  filtro(reg: string) {
    this.documentosSource.filter = reg.trim().toLowerCase();
  }  
  async listarDocumentos() {
    const listaDocumentos = await this.documentosService.listarDocumentos();

    this.documentos = listaDocumentos;

    this.documentosSource.data = listaDocumentos;

    this.documentosSource.sort = this.sort

    this.documentosSource.paginator = this.paginator

    this.documentosSelecionados = this.documentos.map(documento => {
      return { idDocumento: documento.id, checked: false }
    })
    return this.documentos;
  }

  marcarDesmarcar(index: number) {
    console.log(index)
    this.documentosSelecionados[index].checked = !this.documentosSelecionados[index].checked;
  }

  async excluirDocumentos() {
    //Necessário usar "for", pois foreach e map não respeitam o await direito.
    for (let i = 0; i < this.documentosSelecionados.length; i++) {
      if (this.documentosSelecionados[i].checked) {
        await this.documentosService.removerDocumento({ id: this.documentosSelecionados[i].idDocumento });
        this.documentos.splice(i, 1);
        this.documentosSelecionados.splice(i, 1);
      }
    }
    this.listarDocumentos()
  }

  async filtrar(element: HTMLElement) {

    const propriedade = element.getAttribute('formControlName');
    const quantidadeFiltros = Object.values(this.formularioFiltro.value).filter(filtro => filtro !== '').length;
    const resultado = this.documentos.filter(documento => {
      let valorPropriedadeDocumento: string = documento[propriedade].toString().toLowerCase();
      let valorPropriedadeFiltro: string = this.formularioFiltro.value[propriedade].toLowerCase();
      return valorPropriedadeDocumento.includes(valorPropriedadeFiltro);
    })
    console.log(quantidadeFiltros);
    this.documentos = quantidadeFiltros > 0 && resultado.length > 0 ? resultado : await this.listarDocumentos();
  }
}

/*COMPONENTE POP UP*/

@Component({
  selector: 'novo-documento-dialog',
  templateUrl: 'novo-documento-dialog.html'
})
export class novoDocumentoDialog implements OnInit {
  processos: { codigoReferencia: string, valor: string }[];
  tiposDado: { codigoReferencia: string, valor: string }[];
  tiposRegistro: { codigoReferencia: string, valor: string }[];
  tiposAplicacao: { codigoReferencia: string, valor: string }[];
  registroControl = new FormControl('');
  compartilhamentoControl = new FormControl('');
  tipoRegistroControl = new FormControl('');
  tabelasControl = new FormControl('');
  itemsControl = new FormControl('');
  processosControl = new FormControl('');
  tipoDadoControl = new FormControl('');
  tipoAplicacaoControl = new FormControl('');
  novoDocumento: Boolean;
  alterar: Boolean;
  tabelas: string[];
  filtroTabelas: Observable<string[]>;

  constructor(
    private documentosService: DocumentosService,
    public dialogRef: MatDialogRef<novoDocumentoDialog>,
    private tabelasService: TabelasDoSistemaService,
    private classificacaoService: ClassificacaoDeDadosService,
    @Inject(MAT_DIALOG_DATA) public data: Documento
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  conversaoArrayString(dado: string | string[]): string | string[] {
    if (typeof dado === 'string') {
      return dado.split(',')
    } else if (typeof dado === 'object') {
      return dado.join()
    }
  }

  async ngOnInit() {
    this.novoDocumento = !this.data.codigoDocumento;
    await this.listarTabelas();
    this.filtroTabelas = this.tabelasControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    )
    this.processos = await this.gerarClassificacaoDados('TIPPRO');
    this.tiposDado = await this.gerarClassificacaoDados('TIPDAD');
    this.tiposAplicacao = await this.gerarClassificacaoDados('TIPAPL');
    this.tiposRegistro = await this.gerarClassificacaoDados('TIPREG');
  }

  async gerarClassificacaoDados(codigoCaracterisca: string): Promise<{ codigoReferencia: string, valor: string }[]> {
    let conteudos: conteudoDeDado[] = await this.classificacaoService.buscarConteudosDaCaracteristica({ codigoCaracteristica: codigoCaracterisca });
    let classificacao = conteudos.map(conteudo => {
      return { codigoReferencia: conteudo.codigoReferencia, valor: conteudo.valor }
    })
    return classificacao;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tabelas.filter(option => option.toLowerCase().includes(filterValue));
  }

  async listarTabelas() {
    this.tabelas = await this.tabelasService.listarTabelas();
  }

  async salvarDocumento(documento: Documento) {
    if (!!documento.item) documento.item = this.conversaoArrayString(documento.item);
    documento.cabecalho = documento.tabela;
    if (this.novoDocumento) {
      await this.documentosService.criarDocumento(documento);
      //Reinicializando o formulário
      this.data = {
        codigoDocumento: '',
        compartilhamentoDeDados: false,
        descricao: '',
        prefixo: 0,
        registroModificacao: false,
        tipoDado: '',
        aplicacao: '',
        cabecalho: '',
        item: '',
        processo: '',
        registroUnico: '',
        tabela: '',
        tipoRegistro: '',
        transacao: '',
        especificoEmpresa: false,
        controleDeAprovacao: false,
      }
      this.registroControl.reset()
      this.compartilhamentoControl.reset()
      this.tipoRegistroControl.reset()
    } else {
      await this.documentosService.alterarDocumento(documento);
      this.dialogRef.close();
    }
  }



}
