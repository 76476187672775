import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SalaEsperaService } from 'src/app/service/sala-espera.service';
import { SalaEsperaForm } from 'src/app/atendimento-clinico/sala-espera/model';
import { AtendimentoClinicoService } from 'src/app/service/atendimento-clinico.service';
import { FormCobranca } from 'src/app/atendimento-clinico/encerrar-atendimento/solicitar-cobranca/model';
import { MatTableDataSource, MatSort } from '@angular/material';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormPaciente } from 'src/app/cadastro-paciente/model';

@Component({
  selector: 'app-cobranca',
  templateUrl: './cobranca.component.html',
  styleUrls: ['./cobranca.component.scss']
})
export class CobrancaComponent implements OnInit {
  companyId
  displayedColumns: string[] = ['createdAt', 'cpf', 'paciente', 'encerrar'];
  dataSource: MatTableDataSource<FormCobranca>;
  paciente: FormPaciente
  pacientes: Array<SalaEsperaForm>
  cobranca: String[]
  loading: boolean;
  cobrar: boolean
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() popUp = new EventEmitter


  constructor(
    private _service: AtendimentoClinicoService,
    private _salaEspera: SalaEsperaService,
    private router: Router
  ) { }

  ngOnInit() {
    this.companyId = sessionStorage.getItem('companyId')

    this.buscarRegistro()
  }
  async buscarRegistro() {
    this.loading = true
    let validar = []
    let filtro = []
    this.loading = false
    // await this._salaEspera.listarSalaEspera().toPromise()
    //   .then(
    //     async (reg: Array<SalaEsperaForm>) => {
    //       this.pacientes = await reg.filter(function (reg) {
    //         return reg.processo == 3 && reg.companyId
    //       })
    //       await this._service.listarCobrancaAdicional(this.companyId).toPromise()
    //         .then(
    //           (reg: Array<FormCobranca>) => {
    //             this.cobranca = reg.map(x => x.cpf)
    //           }
    //         )
    //       this.loading = false
    //     }
    //   )
    await this.pacientes.forEach(p => {
      this.cobranca.forEach(c => {
        if (p.cpf == c) {
          let dublicidade = filtro.findIndex(c => { return c.cpf == c.cpf }) > -1
          if (!dublicidade) {
            filtro.push(p)
            // console.log(teste)
          }
        }

      });
      console.log(validar)
    });
    this.dataSource = new MatTableDataSource(filtro);
    this.dataSource.sort = this.sort;
    console.log(filtro)


    // this._service.listarCobrancaAdicional(this.companyId).toPromise()
    //   .then(
    //     (reg: Array<FormCobranca>) => {
    //       let companyId = this.companyId
    //       console.log(companyId)
    //       let filtro = reg.filter(function (reg) {
    //         return reg.companyId.toLowerCase() == companyId.toLowerCase()
    //       })

    //       this.dataSource = new MatTableDataSource(filtro);
    //     }
    //   )
  }
  voltar() {
    this.router.navigateByUrl('cobranca')
  }
  async goCobranca(ref) {
    this.router.navigateByUrl(`home/cobrar-consulta`)
    this.popUp.emit(false)
  }
}
