export class FormUsuario {
    id?: string
    usuarioId?: string
    companyId?: string
    foto ?: string
    //Dados Pessoais
    nome?: string 
    cpf?: string 
    rg?: string 
    nascimento?: string 
    genero?: string 
    orientacao?: string 
    civil?: string 
    //end

    //Contato
    email?: string 
    telefoneCel?: string 
    telefoneRes?: string 
    telefoneCom?: string 
    ramal?: string 
    //end

    //Endereço
    logradouro?: string 
    numero?: string 
    complemento?: string 
    cep?: string 
    bairro?: string 
    cidade?: string 
    estado?: string  
    //end

    //Dados Bancario
    cartaoBandeira?: string 
    cartaoNumero?: string 
    vencimento?: string 
    seguranca?: string 
    //end

    //Outros
    codnome?: string
    userType?: string
    pago?: Boolean
    assinante?: Boolean
    status?: any
    createdAt?: string
    data_inicio?: string
    data_expiracao?: string
    ativo?: Boolean
    //end

}
export class FormDependente {

    id?: string 
    usuarioId?:string 
    companyId?: string 
    nome?: string  
    email?: string   
    cpf?:string 
    rg?: string  
    nascimento?: Date  
    sexo?: string   
    tratamento?: string 
    telefone?: string   
    telefone2?: string   
    celular?: string   
    convenio?: string  
    medico?: string 
    tipo?:string 
    cor?: string  
    civil?: string   
    especial?: Boolean 
    localNascimento?: string  
    formaPag?: string 
    situacao?: string 
    peso?: string  
    responsavel?: string  
    rgResponsavel?: string  
    cpfResponsavel?: string 
    indicacao?: string 
    pai?: string  
    mae?: string  
    atividadePai?: string  
    atividadeMae?: string  
    endereco?: string  
    numero?: string  
    bairro?: string  
    cidade?: string  
    estado?: string  
    cep?: string  
    createdAt?: Date
    ativo?: Boolean

}
export class FormPreNatal {
    id?: string
    usuarioId?: string
    companyId?: string
    data?: string
    ig?: string
    au?: string
    pa?: string
    peso?: string
    bcf?: string
    mf?: string
    edema?: string
    apres?: string
    observacao?: string
    createdAt?: string
    ativo?: Boolean
}
export class FormUltrassom {
    id?: string
    dataUltrasson?: string
    usuarioId?: string
    companyId?: string
    igdum?: string
    igus?: string
    apresentaDorso?: string
    la?: string
    placenta?: string
    observacao?: string
    ativo?: true
}
export class FormFichaClinica {
    id?: string
    usuarioId?: string
    companyId?: string
    gestante?: string
    paridade?: string
    idade?: string
    gestacao?: string
    abortos?: string
    partoNormal?: string
    partoForceps?: string
    cesaria?: string
    ultimoParto?: Date
    ultimaMenstruacao?: Date
    dataParto?: Date
    numeroFilhos?: number
    risco?: Boolean
    anotacoes?: string
    medico?: string
    telefoneMedico?: string
    createdAt?: string
    ativo?: Boolean
}
export class FormHistoricoDoencas{
    id?: string
    usuarioId?: string
    companyId?: string
    createdAt?: string
    ativo?: Boolean
    doenca?: string
    periodo?: string
    status?: string
}
export class FormHistoricoInternacoes{
    id?:string
    usuarioId?:string
    companyId?:string
    createdAt?:string
    ativo?: Boolean
    descricao?:string
    dataHistorico?: Date
    local?:string
    motivo?: string
}
export class FormHabitos {
    id?: string
    usuarioId?: string
    companyId?: string
    createdAt?: string
    ativo?: Boolean
    fumante?: Boolean
    drogas?: Boolean
    alcoolica?: Boolean
    fisica ?: Boolean
}
export class FormCarteiraVacinacao {
    id: string
    cpfPaciente: string
    codigoVacina: string
    dataAplicacao: string
    fabricanteVacina: Boolean
    lote: string
    validadeVacina: string
    doseVacina: string
    publicoAlvo : string
    proximaDose: string
    dataProximaDose: Date
    tipoLocalVacina: string
    codigoEmpresa: string
    CPFProfissionalAplicador: string
    observacao: string
    criadoPor: string
    criadoEm: string
    status: string
}
