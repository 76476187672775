import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { UsuarioService } from 'src/app/service/usuario.service';
export class fichaDeAssinante {
  name: any
  codigoAssinante: string
  criadoPor: string
  cpfUsuario: string
  timestamp: string
  status: any
  tipoAssinante: any
  administradorAssinante: string
}
const url = new environment().api
@Component({
  selector: 'app-administrar-assinante',
  templateUrl: './administrar-assinante.component.html',
  styleUrls: ['./administrar-assinante.component.scss']
})
export class AdministrarAssinanteComponent implements OnInit {
  displayedColumns = ['name','administradorAssinante', 'cpfUsuario', 'status', 'tipoAssinante', ]
  dataSource: any;
  loading: boolean
  constructor(private http: HttpClient, private _status: StatusSistemaService, private _user: UsuarioService) { }

  ngOnInit() {
    this.get()
  }
  get() {
    this.loading = true
    this.http.get<fichaDeAssinante[]>(url + 'FichaDeAssinante/').toPromise()
      .then(async (res) => {
        await res.forEach(async item => {
          let codigo = await this._status.getStatus(false, true, item.status)
          let tipo = await this._status.getStatus(false, true, item.tipoAssinante)
          let info_user = await this._user.getRegistro(item.administradorAssinante)
          console.log(info_user)
          item.name = info_user[0].nomeCompleto
          item.tipoAssinante = tipo
          item.status = codigo
          item.status = item.status.toLocaleUpperCase()

        });
        setTimeout(() => { this.loading = false }, 3000)

        this.dataSource = new MatTableDataSource(res)
      })
  }

}
