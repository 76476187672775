import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AtribuicaoDeAcessoService } from "../../service/atribuicao-de-acesso.service";
import { AtribuicaoDeAcessoCabecalho, AtribuicaoDeAcessoItem, ItemPerfil } from "./model";
import { TokenDeAcessoService } from "../../service/token-de-acesso.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConteudoDados, ConteudoDeDadosService } from "../../service/conteudo-de-dados.service";

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessaoUser } from 'src/app/user.reg';
import { PerfilAcessoUsuarioService } from 'src/app/service/perfil-acesso-usuario.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { ActivatedRoute } from '@angular/router';
import { TokenDeAcessoModel } from 'src/app/admin-usuarios/admin-token/modalTokenDeAcesso';
import { FotoPerfilService } from 'src/app/service/foto-perfil.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';

const sessao = new SessaoUser
@Component({
  selector: 'app-atribuicao-de-acesso',
  templateUrl: './atribuicao-de-acesso.component.html',
  styleUrls: ['./atribuicao-de-acesso.component.scss']
})
export class AtribuicaoDeAcessoComponent implements OnInit {
  @Input('') onlyCad: boolean
  @Input('') infoAtribuicao: any
  @Output() exit = new EventEmitter
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns = ['acao', 'empresa', 'fotoPerfil', 'nomeCompleto', 'cpf'];
  displayedColumnsCPF = ['acao', 'cpf', 'nome'];



  codigoEmpresaCabecalho;
  empresa;
  formulario: FormGroup
  dataSource;
  dataSourceCPF;
  listEmpresas = []
  listTipoAcesso: ConteudoDados[] = [];
  viewCadastro: boolean;
  checkedMenu: any[];
  viewTreeMenu: boolean;
  viewMenu: boolean;
  viewPerfil: boolean;
  loadingListMenu: boolean;
  listMenu = []
  nameTipoAcesso: string;
  loadingLisAutorizacao: boolean;
  loadingLisPerfil: boolean;
  listPerfil = []
  alteracao: boolean;
  tablePerfil = [];
  tableAcessos = [];
  selectEmpresa = sessao.codigoEmpresa
  tipoAcesso
  listCPF = []
  loading: boolean;
  registrosPerfilItem: AtribuicaoDeAcessoItem[] = []
  loadingCadastro: boolean;
  mostrarMenuMaster: boolean;

  constructor(
    private service: AtribuicaoDeAcessoService,
    private _token: TokenDeAcessoService,
    private _mensagem: MensagemService,
    private form: FormBuilder,
    private _perfil: PerfilAcessoUsuarioService,
    private router: ActivatedRoute,
    private _conteudo: ConteudoDeDadosService,
    private _foto: FotoPerfilService,
    private _filter: FiltrarDadosService,
    private _empresa: FichaGeralService) {
  }

  async ngOnInit() {
    this.loading = true
    this.creatForm()
    await this.getEmpresa()
    this.getRegistro()
    this.getConteudo()
    if (this.onlyCad) {

      try {
        if (this.infoAtribuicao.cpfPessoa && this.infoAtribuicao.codigoEmpresa) {
          await this.newRegistro()
          await this.getCPFs(this.infoAtribuicao.codigoEmpresa, this.infoAtribuicao.cpfPessoa)
          this.formulario.get('codigoEmpresa').setValue(this.infoAtribuicao.codigoEmpresa)
          this.formulario.get('cpf').setValue(this.infoAtribuicao.cpfPessoa)
        }
      } catch {

      }
     
    }
    this.loading = false
  }

  async getEmpresa() {
    this.listEmpresas = await this._empresa.getEmpresaAssiante(sessao.assinante)
  }

  async getConteudo() {
    this.listTipoAcesso = await this._conteudo.get('ITMPERFIL')
    this.listTipoAcesso = this.listTipoAcesso.filter(value => value.codigoDeNegocio && value.codigoReferencia != 'ITMPERFIL4')
    console.log('listTipoAcesso', this.listTipoAcesso)
  }


  async getRegistro() {
    let registros = await this.service.listarCabecalho(this.selectEmpresa)
    registros.forEach(async value => {
      let foto = await this._foto.buscarFoto(value.cpf)
      try {
        let back = JSON.stringify(foto).split('"')[3]
        value.backgroud = { 'background-image': `url('${back}')` }
      } catch {
        value.backgroud = { 'background-image': `url('assets/imgUser.png')` }
      }
      value.fotoPerfil = foto
    })
    this.dataSource = new MatTableDataSource(registros)
    console.log('registro', registros)
  }
  async insertFoto(cpf) {
    let registro = this.formulario.value
    let foto = await this._foto.buscarFoto(cpf)
    try {
      let back = JSON.stringify(foto).split('"')[3]
      registro.backgroud = { 'background-image': `url('${back}')` }
    } catch {
      registro.backgroud = { 'background-image': `url('assets/imgUser.png')` }
    }
    this.formulario.patchValue(registro)
  }

  async getMenuMaster(codigo) {
    this.tipoAcesso = codigo
    this.viewTreeMenu = false
    if (codigo == 'ITMPERFIL5') {
      this.getPerfil()
      this.viewPerfil = true
      this.viewMenu = false

      return
    }
    this.viewPerfil = false
    this.checkedMenu = []
    this.loadingListMenu = true
    this.listMenu = await this._perfil.getMenuMaster(codigo)
    this.listMenu.forEach(value => value.select = false)
    this.loadingListMenu = false
    if (codigo == 'ITMPERFIL2' || codigo == 'ITMPERFIL3') {
      this.viewTreeMenu = true
      this.viewMenu = false
    } else {
      this.viewMenu = true
      this.viewTreeMenu = false
    }
    if (codigo) {
      let getName = this.listTipoAcesso.filter(value => value.codigoReferencia == codigo)[0]
      this.nameTipoAcesso = getName.valor
    }
  }


  addMenus(checked, value) {
    if (checked) value.select = true
    else value.select = false
  }
  async inserir() {
    this.loading = true
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigátorios', { erro: true })
      return
    }

    let registro: AtribuicaoDeAcessoCabecalho = this.formulario.value
    delete registro.fotoPerfil
    delete registro.backgroud
    await this.service.cadastrarCAB(registro).toPromise()
      .then(async res => {
        const codigo = res[0].codigo
        this.inserirItems(codigo, registro)
        let getToken = await this._token.getEmpresas(registro.cpf)
        let filter = getToken.filter(value => value.empresa == registro.codigoEmpresa && value.assinatura == sessao.assinante)
        if (filter.length > 0) {
          let token: TokenDeAcessoModel = filter[0]
          token.statusDaConexao = "SDO0136"
          this._token.Put(token).toPromise()
            .then(res => { })
        }
        this._mensagem.creat('Dado cadastrado com sucesso', { sucesso: true })
        this.loading = false
        this.formulario.disable()
        this.alteracao = true
        this.getRegistro()
      })

  }
  async alterar() {
    if (this.formulario.invalid) {
      this._mensagem.creat('Preencha todos os campos obrigatórios', { erro: true })
      return
    }
    let registro: AtribuicaoDeAcessoCabecalho = this.formulario.value
    delete registro.fotoPerfil
    delete registro.backgroud
    this.service.alterCAB(registro).toPromise()
      .then(async res => {
        let cont = 0
        for (let i = 0; i < this.registrosPerfilItem.length; i++) {
          const item = this.registrosPerfilItem[i];
          let result = this.service.desativeITEM(item)
          if (result) cont++
        }
        if (cont == this.registrosPerfilItem.length) {
          await this.inserirItems(registro.codigo, registro)
          this._mensagem.creat('Dado Alterado com sucesso', { sucesso: true })
          this.formulario.disable()
          this.getRegistro()
        } else {
          this._mensagem.creat('Não foi possivel alterar itens de perfil', { erro: true })
        }

      })
  }
  async cancelarRegistro() {
    let registro = this.formulario.value
    let get = await this.service.resetCAB(registro)
    this.openCadastro(get)
  }
  insertMenus(menus?) {

    let acesso = this.tipoAcesso
    let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == acesso)[0]
    console.log('MENU!', getConteudo)
    let menusCheked
    if (menus) {
      menusCheked = menus
    } else {
      menusCheked = this.listMenu.filter(value => value.select)
    }
    if (menusCheked.length < 1) return

    let verific = this.tableAcessos.findIndex(value => value.codigo == getConteudo.codigoReferencia)
    if (verific >= 0) {
      this.tableAcessos.filter((value, i) => {
        if (value.codigo == getConteudo.codigoReferencia) {
          value.registros = []
          value.registros.push(...menusCheked)
        }
      })
    } else {
      let item = {
        id: this.tableAcessos.length,
        codigo: getConteudo.codigoReferencia,
        name: getConteudo.valor,
        tipoAcesso: acesso,
        registros: menusCheked
      }
      this.tableAcessos.push(item)
    }

    console.log('tableAcesso', this.tableAcessos)
  }
  async inserirItems(codigo, registro: AtribuicaoDeAcessoCabecalho) {
    if (this.tableAcessos.length > 0) {
      for (let i = 0; i < this.tableAcessos.length; i++) {
        let dados = this.tableAcessos[i]
        for (let index = 0; index < dados.registros.length; index++) {
          let item = new AtribuicaoDeAcessoItem
          let dado = dados.registros[index]
          item.codigoEmpresa = registro.codigoEmpresa
          item.tipoAcesso = dados.tipoAcesso
          item.valorDeAcesso = dado.menuID
          item.codigoAtribuicaoAcesso = codigo
          item.nrItem = (i + 1).toString()
          let result = await this.service.cadastrarITEM(item)
          if (result) {
            dado.result = true
          } else {
            dado.result = false
          }
        }
      }
    }
    if (this.tablePerfil.length > 0) {
      for (let i = 0; i < this.tablePerfil.length; i++) {
        let dados = this.tablePerfil[i]
        for (let index = 0; index < dados.registros.length; index++) {
          let item = new AtribuicaoDeAcessoItem
          let dado = dados.registros[index]
          item.codigoEmpresa = registro.codigoEmpresa
          item.tipoAcesso = dados.tipoAcesso
          item.valorDeAcesso = dado.codigoPerfil.toString()
          item.codigoAtribuicaoAcesso = codigo
          item.nrItem = (i + 1).toString()
          let result = await this.service.cadastrarITEM(item)
          if (result) {
            dado.result = true
          } else {
            dado.result = false
          }
        }
      }
    }
  }

  insertPerfil() {
    let acesso = this.tipoAcesso
    let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == acesso)[0]
    let menusCheked = this.listPerfil.filter(value => value.select)
    if (menusCheked.length < 1) return
    let verific = this.tablePerfil.findIndex(value => value.codigo == getConteudo.codigoReferencia)
    if (verific >= 0) {
      this.tablePerfil.filter(value => {
        if (value.codigo == getConteudo.codigoReferencia) {
          value.registros = []
          value.registros.push(...menusCheked)
        }
      })
    } else {
      let item = {
        id: this.tablePerfil.length,
        codigo: getConteudo.codigoReferencia,
        name: getConteudo.valor,
        tipoAcesso: acesso,
        registros: menusCheked
      }
      this.tablePerfil.push(item)
    }
  }
  async getPerfil() {
    this.loadingLisPerfil = true
    let get = await this._perfil.listarPerfilCabecalho(this.selectEmpresa)
    this.listPerfil = get
    this.listPerfil.forEach(value => value.select = false)
    this.loadingLisPerfil = false
  }
  insertCPF(item) {
    this.formulario.get('cpf').setValue(item.cpf)
  }
  async getCPFs(codigoEmpresa, cpf?) {
    let get = await this._token.consultarEmpresa(codigoEmpresa)
    console.log('result', get)
    this.listCPF = this._filter.removeDublic(get, 'cpf')
    if (cpf) {
      this.formulario.get('cpf').setValue(cpf)
      console.log('params******', this.listCPF, this.formulario.value)
    }
    // this.dataSourceCPF = new MatTableDataSource(teste)
  }

  async openCadastro(registro: AtribuicaoDeAcessoCabecalho) {
    console.log('regisotr', registro)
    this.loadingCadastro = true
    this.formulario.patchValue(registro)
    this.tableAcessos = []
    this.tablePerfil = []
    this.registrosPerfilItem = await this.service.listarAtribuicaoDeAcessoItem(registro.codigoEmpresa, registro.codigo)

    await this.getPerfil()
    await this.getMenuMaster('')
    await this.getCPFs(registro.codigoEmpresa)

    for (let i = 0; i < this.registrosPerfilItem.length; i++) {
      const registro = this.registrosPerfilItem[i];
      if (registro.tipoAcesso == 'ITMPERFIL5') {
        let menusCheked = this.listPerfil.filter(value => value.codigoPerfil == registro.valorDeAcesso)
        let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == registro.tipoAcesso)[0]
        let verific = this.tablePerfil.findIndex(value => value.codigo == getConteudo.codigoReferencia)

        if (verific >= 0) {
          this.tablePerfil.filter(value => {
            if (value.codigo == getConteudo.codigoReferencia) {
              value.registros.push(...menusCheked)
            }
          })
        } else {
          let item = {
            id: this.tablePerfil.length,
            codigo: getConteudo.codigoReferencia,
            name: getConteudo.valor,
            tipoAcesso: registro.tipoAcesso,
            registros: menusCheked
          }
          this.tablePerfil.push(item)
        }
      }
      if (registro.tipoAcesso == 'ITMPERFIL1' || registro.tipoAcesso == 'ITMPERFIL2' || registro.tipoAcesso == 'ITMPERFIL3') {
        let menusCheked = this.listMenu.filter(value => value.menuID == registro.valorDeAcesso)
        let getConteudo = this.listTipoAcesso.filter(value => value.codigoReferencia == registro.tipoAcesso)[0]
        let verific = this.tableAcessos.findIndex(value => value.tipoAcesso == registro.tipoAcesso)
        if (verific >= 0) {
          this.tableAcessos.filter(value => {
            if (value.codigo == getConteudo.codigoReferencia) {
              value.registros.push(...menusCheked)
            }
          })
        } else {
          let item = {
            id: this.tableAcessos.length,
            codigo: getConteudo.codigoReferencia,
            name: getConteudo.valor,
            tipoAcesso: registro.tipoAcesso,
            registros: menusCheked
          }
          this.tableAcessos.push(item)
        }
      }
    }

    this.alteracao = true
    this.viewMenu = false
    this.viewPerfil = false
    this.viewCadastro = true
    this.loadingCadastro = false
    this.formulario.disable()
    this.getMenuMaster(this.registrosPerfilItem[0].tipoAcesso)
  }
  async excluirRegistro() {
    let registro: AtribuicaoDeAcessoCabecalho = this.formulario.value
    registro.status = 'SDA0002'
    await this.service.alterCAB(registro).toPromise()
      .then(res => {
        this._mensagem.creat('Dado excluido com sucesso', { sucesso: true })
        this.getRegistro()
        this.viewCadastro = false
      }).catch(error => {
        console.log('error', error)
        this._mensagem.creat('Erro ao exlcuir registro', { erro: true })
      })
  }
  async newRegistro() {
    this.listMenu = []
    this.listPerfil = []
    this.tableAcessos = []
    this.tablePerfil = []
    this.alteracao = false
    await this.formulario.reset()
    await this.formulario.enable()
    this.viewCadastro = true
    this.viewPerfil = false
    this.viewMenu = false
  }
  removeTable(type, item) {
    let index
    switch (type) {
      case 'acesso':
        index = this.tableAcessos.findIndex(value => value.id == item.id)
        if (index >= 0) delete this.tableAcessos[index]
        this.tableAcessos = this.tableAcessos.filter(value => value)
        break;
      case 'perfil':
        index = this.tablePerfil.findIndex(value => value.id == item.id)
        if (index >= 0) delete this.tablePerfil[index]
        this.tablePerfil = this.tablePerfil.filter(value => value)
        break;

      default:
        break;
    }
  }
  fechar() {
    this.viewCadastro = false;
    this.mostrarMenuMaster = false
    if (this.onlyCad) {
      this.exit.emit(true)
    }
  }
  creatForm() {
    this.formulario = this.form.group({
      id: '',
      codigo: 0,
      codigoEmpresa: new FormControl(0, Validators.required),
      criadoPor: '',
      criadoEm: '',
      status: '',
      cpf: new FormControl('', Validators.required),
      backgroud: null
    });
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim();
  }

}
