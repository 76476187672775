import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogAtendimentoService {

  url = new environment().api_local
  api = "logAtendimento/";
  constructor(private http: HttpClient) { }
  //************************** logAtendimento **********************************
  listarlogAtendimento(id?) {
    console.log('logAtendimento :' + this.url + this.api)
    if (id) {
      return this.http.get(this.url + this.api + id)
    }
    return this.http.get(this.url + this.api)
  }
  cadastrarlogAtendimento(dados: any) {
    console.log('logAtendimento-Cadastrar :' + this.url + this.api, dados)
    return this.http.post(this.url + this.api, dados)
  }
  alterarlogAtendimento(id: string, dados) {
    console.log('logAtendimento-Alterar :' + this.url + this.api + id, dados)
    return this.http.put(this.url + this.api + id, dados)
  }
  excluirlogAtendimento(id: string, dados?) {
    console.log('logAtendimento-Excluir :' + this.url + this.api + id)
    return this.http.delete(this.url + this.api + id)
    // return this.http.put(this.url + this.api + id, dados)
  }
}
