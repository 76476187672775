export class TokenDeAcessoModel{

    id?: string;
    cpf: string;
    assinatura?: number;
    empresa?: number;
    tipoHabilitacao?: string;
    statusDaAssinatura?: string;
    statusDaEmpresa?: string;
    statusDoAdministrador?: string;
    statusDoClinico?: string;
    statusDaConexao?: string;
    statusDoRegistroProfissional?: string;
    statusUsuario?: string;
    statusHabilitacao?: string;
    statusRegistro?: string
}