import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MensagemService } from 'src/app/service/mensagem.service';
import { FormProntuarioVidaPaciente } from 'src/app/service/prontuario-vida-paciente/model';
import { ProntuarioDeVidaService } from 'src/app/service/prontuario-vida-paciente/prontuario-de-vida.service';
import { TypeProntuario } from '../model';

@Component({
  selector: 'app-atualizar-prontuario-vida-paciente',
  templateUrl: './atualizar-prontuario-vida-paciente.component.html',
  styleUrls: ['./atualizar-prontuario-vida-paciente.component.scss']
})
export class AtualizarProntuarioVidaPacienteComponent implements OnInit {
  @Input('cpf') cpf: string
  @Input('type') type: TypeProntuario
  @Input('register') register: FormProntuarioVidaPaciente[] = [];
  @Output('close') exit = new EventEmitter
  @Output('refresh') refresh = new EventEmitter
  form = new FormProntuarioVidaPaciente
  loading = false
  constructor(private service: ProntuarioDeVidaService, private _mensagem: MensagemService) { }

  ngOnInit() {
  }

  async salvar() {
    this.loading = true

    let getRegister = [...this.register]
    let register = getRegister[getRegister.length > 1 ? getRegister.length - 1 : getRegister.length]

    if (register) {
      if (register.id) delete register.id
      else register = new FormProntuarioVidaPaciente
    }else{
      register = new FormProntuarioVidaPaciente
      register.cpf = this.cpf
      register.criadoPor = this.cpf
      register.criadoEm = new Date().toISOString()
      register.status = 'SCA0001'
    }


    switch (this.type) {
      case 'frequencia_cardiaca': register.pressaoSistolica = this.form.pressaoSistolica; break
      case 'frequencia_respiratoria': register.pressaoDiastolica = this.form.pressaoDiastolica; break
      case 'pressao_arterial': register.pressaoArterial = this.form.pressaoArterial; break
      case 'temperatura': register.temperatura = this.form.temperatura; break
      case 'saturacao': register.saturacaoOxigenio = this.form.saturacaoOxigenio; break
      case 'glicemia': register.glicemia = this.form.glicemia; break
      case 'peso': register.peso = this.form.peso; break
      case 'cintura': register.cintura = this.form.cintura; break
      case 'altura': register.altura = this.form.altura; break
      case 'tipoSanguineo': register.tipoSanguineo = this.form.tipoSanguineo; break
    }


    await this.service.post(register).toPromise()
      .then(res => {
        this.refresh.emit()
        this._mensagem.creat('Prontuário atualizado com sucesso', { sucesso: true })
      })
      .catch(error => {
        console.log('erro!',error)
        this._mensagem.creat('Erro ao atualizar prontuário', { erro: true })
      })
    this.loading = false
    console.log(register)
  }

  close() {
    this.exit.emit()
  }
}
