export class FormPagamento {
    id : string
    usuarioId : string
    companyId : string
    pacienteId : string
    dataEmitido : string
    periodoPagamento?: string
    pagConvenio?: string
    tipoPagamento?: string
    autConvenio?: string
    codConvenio?: string
    operadorSaude?: string
    plano?: string
    baseCalculo?: string
    valorConsulta?:string
    descontoConsulta?: string
    desconto?:string
    total?: string
    creditoConsulta?: string
    createdAt?: string
}