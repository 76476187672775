import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
import { dadosRegrasDeImposto, dadosOutrasIncidencias } from '../regras-de-imposto/model'
import { DadosFichaGeralEmpresa } from '../regras-de-imposto/model';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { identity } from 'rxjs';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { RegrasDeImpostoService } from 'src/app/service/regras-de-imposto.service';
import { OutrasIncidenciasService } from 'src/app/service/outras-incidencias.service';
import { FichaGeralService } from 'src/app/service/ficha-geral-empresa/ficha-geral-empresa.service';
import { SistemaClassificacaoDadosService } from 'src/app/service/sistema-classificacao-dados.service';
import { SistemaClassificacaoDados } from 'src/app/cadastro-item/campos-cadastroItem';
import { MensagemService } from 'src/app/service/mensagem.service';
import { FichaDeAssinanteService } from 'src/app/service/ficha-de-assinante/ficha-de-assinante.service';
import { SessaoUser } from 'src/app/user.reg';
import { FichaAssinante } from 'src/app/service/ficha-de-assinante/FichaAssinante.model';
import { ValidateBrService } from 'angular-validate-br';
import { MatCheckboxChange, MatSort, MatTableDataSource } from '@angular/material';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';


// Retirei o required no banco de dados de contaContabilContasReceber e contaContabilContasPagar
// para testar a inserção de dados. ok. 

interface ImpostoSemVendaRetido {
  value: boolean;
  valueText: string;
}

interface BaseImposto {
  value: string;
  valueText: string;
}

const sessao = new SessaoUser

@Component({
  selector: 'app-regras-de-imposto',
  templateUrl: './regras-de-imposto.component.html',
  styleUrls: ['./regras-de-imposto.component.scss']
})

export class RegrasImpostoComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['detalhe', 'nomeEmpresa', 'imposto', 'taxa']
  listRegistro: dadosRegrasDeImposto[]
  formulario: FormGroup
  listEmpresa = []
  listBaseCalculo = []
  listPeriodoArrecadacao = []
  listImposto = []
  listJurisdicao = []
  viewCadastro: boolean
  alteracao = false
  botaoSalvar = false
  botaoCancelar = false
  loading: boolean
  dataSource = new MatTableDataSource()
  selectEmpresa: number = 0


  dataDataEmpresa: DadosFichaGeralEmpresa[]
  dataRegraDeImposto = new dadosRegrasDeImposto();
  dataOutrasIncidencias = new dadosOutrasIncidencias
  variavelID: dadosRegrasDeImposto
  codigoempresa: number
  ResultadoRegraImpostoID: dadosRegrasDeImposto
  IdCodigoImposto: string
  listar: Boolean
  buttonAdicionar = false
  resultado: Array<dadosOutrasIncidencias>
  resultados = new dadosOutrasIncidencias
  GetSelectClassificacaoCADIMPItem: SistemaClassificacaoDados
  resultadoAssinante: FichaAssinante;
  viewListContaR
  viewListContaA
  listContas = []
  listContasBackup = []
  viewConfirmDelete: boolean


  constructor(
    private service: RegrasDeImpostoService,
    private serviceSistemaClassificacaoDados: SistemaClassificacaoDadosService,
    private fichaGeralService: FichaGeralService,
    private serviceFichaAssinante: FichaDeAssinanteService,
    private mensagem: MensagemService,
    private validateBrService: ValidateBrService,
    private token: TokenDeAcessoService,
    private form: FormBuilder,
    private conteudo: ConteudoDeDadosService,
    private _filtro: FiltrarDadosService
  ) { }

  async ngOnInit() {
    this.creatForm()
    this.getEmpresa()
    this.getJurisdicao()
    this.getPeriodoArrecadacao()
    this.getImposto()
    this.getBaseCalculo()
    await this.getRegistro()
    // this.getCaracteristicasCadImp()
    // this.listar = true
    // this.getBaseCalculo()
    // this.buscarDadosRegrasDeImposto()
    // this.buscarDadosEmpresa()
  }

  creatForm() {
    this.formulario = this.form.group({
      codigoImposto: 0,
      criadoPor: '',
      codigoEmpresa: 0,
      codigoReferencia: '',
      incidenciaSobreVendas: true,
      incidenciaSobreRenda: true,
      incidenciaSobreCompras: true,
      jurisdicao: '',
      pais: '',
      municipio: '',
      impostoEfetivoDesde: '',
      taxaImposto: 0,
      impostoRetidoNaFonte: true,
      aliquotaRetencao: 0,
      valorMinimoRetencao: 0,
      deducao: 0,
      isencao: 0,
      contaContabilContasReceber: '',
      contaContabilContasPagar: '',
      observacao: '',
      estado: '',
      status: '',
      outraIncidencia: '',
      retencao: 0,
      valorDeducao: 0,
      baseCalculo: '',
      periodoArrecadacao: '',
      diaRecolhimento: '',
      limiteMaximoBC: 0,
      proporcaoPercentualBC: 0,
      criadoEm: ''
    })
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getJurisdicao() {
    let juri = await this.conteudo.get('JURIMP')
    this.listJurisdicao = juri
  }

  // O conteudo de dados de Base de Cálculo de imposto não foi criado. 
  async getBaseCalculo() {
    let base = await this.conteudo.get('IMPBCAL')
    this.listBaseCalculo = base
  }

  async getPeriodoArrecadacao() {
    let periodo = await this.conteudo.get('PERIMP')
    this.listPeriodoArrecadacao = periodo
  }

  async getImposto() {
    let imposto = await this.conteudo.get('CADIMP')
    this.listImposto = imposto
  }

  async getPlanoDeContas(codigo) {
    this.listContas = await this.service.getPlanoDeContas(codigo)
    this.listContasBackup = await this.service.getPlanoDeContas(codigo)
    this.formulario.controls['codigoEmpresa'].setValue(codigo)
    console.log(this.listContas)
  }

  // testar o dataSource na property da table quando o inserir estiver ok.
  async getRegistro() {

    this.loading = true
    let codigoEmpresa = this.selectEmpresa

    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')

          if (codigoEmpresa > 0) {
            let registro = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
            registro.forEach(async item => {
              let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
              if (empresa.length > 0) {
                item.nomeEmpresa = empresa[0].nomeEmpresa
              }
            })
            this.listRegistro = registro
          } else {
            let empresas = JSON.parse(sessao.listEmpresas)
            this.listRegistro = this._filtro.filterTodos(this.listRegistro, empresas)
          }

          this.listRegistro.forEach(async item => {
            let imposto = await this.conteudo.get('CADIMP', true, item.codigoReferencia)
            item.descImposto = imposto.toString()
          })


          this.dataSource = new MatTableDataSource(this.listRegistro)
          this.dataSource.sort = this.sort
        }
      )

    this.loading = false
  }

  async inserir() {
    this.loading = true
    let registro = this.formulario.value

    // função que exclui os campos nulos.
    Object.keys(registro).forEach(value => {
      if (!registro[value]) {
        delete registro[value]
      }
    })

    registro.criadoPor = sessao.cpf

    await this.service.enviar(registro).toPromise()
      .then(
        resposta => {

          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = true
          this.alteracao = true
          this.getRegistro()
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
    console.log("Inserir registro: ", registro)
    this.loading = false
  }

  async openCadastro(registro) {
    console.log("Open: ", registro)

    delete registro.descImposto
    delete registro.nomeEmpresa

    this.formulario.patchValue(registro)

    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  alterar() {
    let registro = this.formulario.value

    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.getRegistro()
        }).catch(error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        })
  }

  cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }
    this.viewConfirmDelete = false
    let registro = this.formulario.value

    if (!registro.codigoImposto) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()

    console.log('Excluir: ', registro)
  }

  async fecharRegistro() {
    this.viewCadastro = false
    this.getRegistro()
  }

  filtrar(value) {
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa() {
    if (this.selectEmpresa == 0) {
      let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
      this.dataSource.data = filter
      return
    }
    let codigoEmpresa = this.selectEmpresa
    let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
  }




  // DADOS ANTIGOS ↓


  dadosImpostoSemVendaRetido: ImpostoSemVendaRetido[] = [
    { value: true, valueText: 'Sim' },
    { value: false, valueText: 'Não' }
  ];

  // O Conteudo de dados de IMPBCAL não foi criado. Usei uma interface.
  dadosBaseImposto: BaseImposto[] = [
    { value: '1', valueText: 'Base 1' },
    { value: '2', valueText: 'Base 2' }
  ]



  // ->> Descomentar
  async buscarDadosEmpresa() {
    var cpf = sessao.cpf
    this.resultadoAssinante = await this.serviceFichaAssinante.buscarAssinante({ cpf })
    this.fichaGeralService.buscarEmpresasCodigoAssinante(this.resultadoAssinante.codigoAssinante).subscribe(
      (resultado: Array<any>) => {
        let filtro = resultado.filter(function (reg: DadosFichaGeralEmpresa) {
          return reg.status === "SDO0017"
        })
        this.dataDataEmpresa = filtro
      }
    ), erro => {
      this.mensagem.creat("Impossível buscar os dados da Empresa no momento, tente novamente mais tarde.", { erro: true })
    }
  }

  // ->> Descomentar 
  filterContaContabil(value) {

    this.listContas = this.listContasBackup
    let index = this.listContas.filter(x => {
      return x.descricao.includes(value)
    })
    if (index.length > 0) {
      this.listContas = index
    } else {
      this.listContas = this.listContasBackup
    }
  }

  // ->> Descomentar
  selectCodigoContabil(checket: MatCheckboxChange) {
    if (checket.checked) {
      console.log('viewListContaA', checket.source.value)
      if (this.viewListContaA) {
        this.formulario.controls['contaContabilContasPagar'].setValue(checket.source.value)
        this.viewListContaA = false
      }
      if (this.viewListContaR) {
        this.formulario.controls['contaContabilContasReceber'].setValue(checket.source.value)
        this.viewListContaR = false
      }
      this.viewListContaA = false
    } else {
      if (this.viewListContaA) {
        this.formulario.controls['contaContabilContasPagar'].setValue('')
        this.viewListContaA = false
      }
      if (this.viewListContaR) {
        this.formulario.controls['contaContabilContasReceber'].setValue('')
        this.viewListContaR = false
      }
    }
  }


  // -> Descomentar
  async buscarDadosRegrasDeImposto() {
    await this.service.getDadosRegraDeImposto().subscribe(
      (resultado: Array<dadosRegrasDeImposto>) => {
        let filtro = resultado.filter(function (reg: dadosRegrasDeImposto) {
          return reg.status === "Ativo"
        })
        filtro.forEach(async (value) => {
          let empresa = await this.token.getEmpresasToken(value.codigoEmpresa)
          console.log('Nome empresa', empresa)
          value.nomeEmpresa = empresa[0].nomeEmpresa
        })

        this.listRegistro = filtro
      }), (erro => {
        this.mensagem.creat("Impossível buscar os dados da Regra de Imposto no momento.", { aviso: true })
      })
  }



  // BuscarStatusRegraDeImpostoID() {
  //   this.service.getRegraDeImpostoID(this.IdCodigoImposto).subscribe(
  //     (sucesso => {
  //       this.ResultadoRegraImpostoID = sucesso
  //       this.listar = null
  //     }), (erro => {
  //       this.mensagem.creat("Impossível buscar os dados da Regra de Imposto específica no momento.", { aviso: true })
  //     }))
  // }

  // BuscarStatusRegraDeImpostoVisualizarID() {
  //   this.service.getRegraDeImpostoID(this.IdCodigoImposto).subscribe(
  //     (sucesso => {
  //       this.ResultadoRegraImpostoID = sucesso
  //     }), (erro => {
  //       console.log("Deu erro: " + erro)
  //     }))
  // }

  // cadastrarRegraDeImposto() {
  //   this.dataRegraDeImposto.status = "Ativo"
  //   let dados: dadosRegrasDeImposto = this.formulario.value
  //   delete dados.codigoImposto
  //   console.log(this.formulario.value)
  //   this.service.PostCadastrarRegraDeImposto(this.formulario.value)
  //     .subscribe(
  //       (async sucesso => {
  //         this.variavelID = sucesso
  //         this.mensagem.creat('Regra de Imposto salvo com sucesso!', { sucesso: true })
  //         this.listar = true
  //         this.buscarDadosRegrasDeImposto()
  //       }), erro => {
  //         console.log('erro', erro.error)
  //         this.mensagem.creat("Impossível cadastrar Regra de Imposto no momento.", { erro: true })
  //       })
  //   this.dataRegraDeImposto = new dadosRegrasDeImposto()
  // }


  // ->> Descomentar
  editarRegraDeImposto() {
    this.service.AlterarRegrasDeImposto(this.IdCodigoImposto, this.ResultadoRegraImpostoID).subscribe(
      (sucesso => {
        this.mensagem.creat('Regra de Imposto editado com sucesso!', { sucesso: true })
        this.listar = true
        this.buscarDadosRegrasDeImposto()
      }), erro => {
        this.mensagem.creat("Impossível editar os dados da Regra de Imposto no momento.", { erro: true })
      }
    );
  }


  // ->> Descomentar
  excluirRegraDeImposto(dados: dadosRegrasDeImposto) {
    if (confirm(`Deseja mesmo deletar a Regra de Imposto ?`)) {
      dados.status = "Inativo"
      this.service.AlterarRegrasDeImposto(dados.codigoImposto, dados).subscribe(
        () => {
          this.mensagem.creat('Registro deletado com sucesso.', { sucesso: true })
          this.buscarDadosRegrasDeImposto();
        }
      ), erro => {
        this.mensagem.creat('Não foi possível deletar o registro no momento, tente novamente mais tarde.', { erro: true })
      }
    }
  }

  // ->> Descomentar
  getCaracteristicasCadImp() {
    var Caracteristica = "CADIMP"
    this.serviceSistemaClassificacaoDados.getCaracteristicasCodigoCaracteristica(Caracteristica).subscribe(
      (resultado => {
        this.GetSelectClassificacaoCADIMPItem = resultado
      })
    )
  }


}
