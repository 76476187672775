import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FichaGeralService } from '../ficha-geral-empresa/ficha-geral-empresa.service';
import { FichaLegislacaoService } from '../ficha-legislacao/ficha-legislacao.service';
import { ServicosMedicosDisponiveisService } from '../servicos-medicos-disponiveis/servicos-medicos-disponiveis.service';
import { EmpresaCompleta } from './empresaCompleta.model'
import { FichaGeralEmpresa } from '../ficha-geral-empresa/FichaGeralEmpresa.model';
import { AreaAtuacaoService } from '../area-atuacao/area-atuacao.service';

@Injectable({
    providedIn: 'root'
})

export class EmpresaCompletaService {

    constructor(
        // private http: HttpClient,
        // private servicoFichaGeral: FichaGeralService,
        // private servicoFichaLegislacao: FichaLegislacaoService,
        // private servicoServicosMedicos: ServicosMedicosDisponiveisService,
        // private servicoAreaAtuacao: AreaAtuacaoService,
    ) { }

    async criarNovaEmpresa(novaEmpresa: EmpresaCompleta) {
        //     let codEmpresa: number;
        //     const fichaGeralEmpresa: Omit<FichaGeralEmpresa, 'codigoEmpresa' | 'timestamp' | 'codigoAssinante'> = {
        //       fusoHorario: novaEmpresa.fusoHorario,
        //       bairro: novaEmpresa.bairro,
        //       cabecalhoImpressao: novaEmpresa.cabecalhoImpressao,
        //       cep: novaEmpresa.cep,
        //       cidade: novaEmpresa.cidade,
        //       cnpj: novaEmpresa.cnpj,
        //       cnpjMatriz: novaEmpresa.cnpjMatriz,
        //       codigoEmpresaMatriz: novaEmpresa.cnpjMatriz,
        //       cpfProprietario: novaEmpresa.cpfProprietario,
        //       cpfResponsavel: novaEmpresa.cpfResponsavel,
        //       crmResponsavel: novaEmpresa.crmResponsavel,
        //       email: novaEmpresa.email,
        //       estado: novaEmpresa.estado,
        //       moedaEmpresa: novaEmpresa.moedaEmpresa,
        //       nomeEmpresa: novaEmpresa.nomeEmpresa,
        //       numeroEndereco: novaEmpresa.numeroEndereco,
        //       observacao: novaEmpresa.observacao,
        //       razaoSocial: novaEmpresa.razaoSocial,
        //       rua: novaEmpresa.rua,
        //       telefoneEmpresa: novaEmpresa.telefoneEmpresa,
        //       tipoEmpresa: novaEmpresa.tipoEmpresa,
        //       webSiteEmpresa: novaEmpresa.webSiteEmpresa,
        //     }
        //     //<---------- INICIO CRIAÇÃO FICHA GERAL ----------------->
        //     await this.servicoFichaGeral.criarFichaGeral(fichaGeralEmpresa)
        //       .then(fichaGeral => {

        //         //Criação das áreas de atuação
        //         novaEmpresa.listaAreaAtuacaoAns.map(async areaAtuacao => {
        //           areaAtuacao.codigoEmpresa = fichaGeral.codigoEmpresa;
        //           await this.servicoAreaAtuacao.criarAreaAtuacao(areaAtuacao)
        //         })

        //         //Criação dos serviços médicos disponíveis
        //         novaEmpresa.listaServicosMedicos.forEach(async servico => {
        //           servico.codigoEmpresa = fichaGeral.codigoEmpresa
        //           await this.servicoServicosMedicos.criarServicosMedico(servico)
        //         })


        //         //Criação das fichas de legislação
        //         novaEmpresa.listaLegislacaoVigente.forEach(async legislacao => {
        //           legislacao.codigoEmpresa = fichaGeral.codigoEmpresa;
        //           await this.servicoFichaLegislacao.criarFichaLegislacao(legislacao)
        //         })

        //       })
        //     //<----------- FIM CRIAÇÃO FICHA GERAL ------------------>  
        //   } //<--------------- FIM SERVICO CRIAÇÃO EMPRESA COMPLETA

        //   async listarEmpresasDoAssinante(): Promise<EmpresaCompleta[]> {

        //     //BUSCAR EMPRESAS DO ASSINANTE
        //     const fichasGerais = await this.servicoFichaGeral.buscar();

        //     //<---- INICIO COMPOR EMPRESA COMPLETA ---->
        //     const empresasCompletas = fichasGerais.map(async fichaGeral => {
        //       //Buscar áreas de atuação
        //       // const areasDeAtuacao = await this.servicoAreaAtuacao.listarAreasAtuacaoEmpresa({ codEmpresa: fichaGeral.codigoEmpresa });

        //       //Buscar serviços
        //       // const servicosMedicos = await this.servicoServicosMedicos.listarServicosMedicosEmpresa({ codEmpresa: fichaGeral.codigoEmpresa });

        //       //Buscar fichas legislacao
        //       // const fichasLegislacao = await this.servicoFichaLegislacao.listarFichasLegislacaoEmpresa({ codEmpresa: fichaGeral.codigoEmpresa });

        //       //Gerar objeto empresa
        //       let empresa: EmpresaCompleta = {
        //         fusoHorario: fichaGeral.fusoHorario,
        //         // listaAreaAtuacaoAns: areasDeAtuacao,
        //         listaAreaAtuacaoAns: [],
        //         // listaLegislacaoVigente: fichasLegislacao,
        //         listaLegislacaoVigente: [],
        //         // listaServicosMedicos: servicosMedicos,
        //         listaServicosMedicos: [],
        //         bairro: fichaGeral.bairro,
        //         cabecalhoImpressao: fichaGeral.cabecalhoImpressao,
        //         cep: fichaGeral.cep,
        //         cidade: fichaGeral.cidade,
        //         cnpj: fichaGeral.cnpj,
        //         cnpjMatriz: fichaGeral.cnpjMatriz,
        //         codigoAssinante: fichaGeral.codigoAssinante,
        //         codigoEmpresa: fichaGeral.codigoEmpresa,
        //         codigoEmpresaMatriz: fichaGeral.codigoEmpresaMatriz,
        //         cpfProprietario: fichaGeral.cpfProprietario,
        //         cpfResponsavel: fichaGeral.cpfResponsavel,
        //         crmResponsavel: fichaGeral.crmResponsavel,
        //         email: fichaGeral.email,
        //         estado: fichaGeral.estado,
        //         moedaEmpresa: fichaGeral.moedaEmpresa,
        //         nomeEmpresa: fichaGeral.nomeEmpresa,
        //         numeroEndereco: fichaGeral.numeroEndereco,
        //         observacao: fichaGeral.observacao,
        //         razaoSocial: fichaGeral.razaoSocial,
        //         rua: fichaGeral.rua,
        //         telefoneEmpresa: fichaGeral.telefoneEmpresa,
        //         timestamp: fichaGeral.timestamp,
        //         tipoEmpresa: fichaGeral.tipoEmpresa,
        //         webSiteEmpresa: fichaGeral.tipoEmpresa
        //       }

        //       return empresa;
        //     })  //<---- FIM COMPOR EMPRESA COMPLETA ---->
        //     return Promise.all(empresasCompletas);
        //   }

        //   async atualizarEmpresa(novaEmpresa: EmpresaCompleta) {
        //     const fichaGeralEmpresa: Omit<FichaGeralEmpresa, 'timestamp' | 'codigoAssinante'> = {
        //       codigoEmpresa: novaEmpresa.codigoEmpresa,
        //       fusoHorario: novaEmpresa.fusoHorario,
        //       bairro: novaEmpresa.bairro,
        //       cabecalhoImpressao: novaEmpresa.cabecalhoImpressao,
        //       cep: novaEmpresa.cep,
        //       cidade: novaEmpresa.cidade,
        //       cnpj: novaEmpresa.cnpj,
        //       cnpjMatriz: novaEmpresa.cnpjMatriz,
        //       codigoEmpresaMatriz: novaEmpresa.cnpjMatriz,
        //       cpfProprietario: novaEmpresa.cpfProprietario,
        //       cpfResponsavel: novaEmpresa.cpfResponsavel,
        //       crmResponsavel: novaEmpresa.crmResponsavel,
        //       email: novaEmpresa.email,
        //       estado: novaEmpresa.estado,
        //       moedaEmpresa: novaEmpresa.moedaEmpresa,
        //       nomeEmpresa: novaEmpresa.nomeEmpresa,
        //       numeroEndereco: novaEmpresa.numeroEndereco,
        //       observacao: novaEmpresa.observacao,
        //       razaoSocial: novaEmpresa.razaoSocial,
        //       rua: novaEmpresa.rua,
        //       telefoneEmpresa: novaEmpresa.telefoneEmpresa,
        //       tipoEmpresa: novaEmpresa.tipoEmpresa,
        //       webSiteEmpresa: novaEmpresa.webSiteEmpresa,
        //     }

        //     this.servicoFichaGeral.alterarFichaGeral(fichaGeralEmpresa);

        //     //Utilize map, pois async await não funciona em foreach
        //     novaEmpresa.listaAreaAtuacaoAns.map(async areaAtuacao => {
        //       await this.servicoAreaAtuacao.alterarAreaAtuacao(areaAtuacao);
        //     })

        //     novaEmpresa.listaServicosMedicos.map(async servicosMedicos => {
        //       await this.servicoServicosMedicos.alterarServicosMedico(servicosMedicos)
        //     })
        //   }
    }
}
