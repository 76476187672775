import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AtestadoObito } from 'src/app/meu-consultorio/atestado-obito/model';
import { SessaoUser } from 'src/app/user.reg';
import { environment } from 'src/environments/environment';
const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class AtestadoObitoService {
  url = new environment().api
  api = 'AtestadoObito/'
  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(this.url + this.api)
  }
  post(register: AtestadoObito) {
    console.log(JSON.stringify(register))
    return this.http.post(this.url + this.api, register)
  }
  put(register: AtestadoObito) {
    return this.http.put(this.url + this.api + `${register.id}/${new Date().toISOString()}/${sessao.sessao_id}/`, register)
  }
}
