import { Component, OnInit, Input } from '@angular/core';
import { FormulariosService } from 'src/app/service/formularios.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioPreNatalService } from 'src/app/service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { FormFichaClinica, FormUltrassom } from '../model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pre-natal',
  templateUrl: './pre-natal.component.html',
  styleUrls: ['./pre-natal.component.scss']
})
export class PreNatalComponent implements OnInit {
  @Input() id
  addAcompanhamentoPreNatal
  addUltrassom
  loading
  usuarioNome: any;
  usuarioId
  idCompany

  //Registros
  registroFichaClinica: any = {}

  //Validar leyout 
  AcompanhamentoPreNatal
  Ultrassom

  //temporarios
  tempAcompanhamentoPreNatal: any = {}
  tempUltrassom: any = {}
  tempPreNatal: any = {}

  Registro: boolean

  //Forms
  formPreNatal: FormGroup
  formFichaClinica: FormGroup
  formUltrassom: FormGroup;

  dadosUsuario: any = []

  estruturaFormulario = []


  constructor(
    private formulario: FormulariosService,
    private form: FormBuilder,
    private service: UsuarioPreNatalService,
    private mensagem: ToastrService,
    private datePipe: DatePipe) {
    this.estruturaFormulario = this.formulario.formularioPreNatal()

  }

  async ngOnInit() {

    await this.buscarID().then(
      async () => {
        await this.createFormulario(this.usuarioId, this.idCompany)
        await this.buscarRegistro('all', this.usuarioId, this.idCompany)
      }
    )

    this.Registro = true
  }
  async buscarID() {
    this.usuarioId = this.id.id
    this.idCompany = this.id.idCompany
    this.usuarioNome = this.id.nome
    this.dadosUsuario.gestante = this.id.nome

  }
  async buscarRegistro(ref, userId?, idCompany?) {
    switch (ref) {
      case 'all':

        await this.service.listarFichaClinica()
          .toPromise()
          .then(
            async (dados: Array<FormFichaClinica>) => {
              console.log(userId + '' + idCompany)


              var id = idCompany
              var usuarioId = userId
              var filtro = dados.filter(function (reg: any) {
                console.log('1 - reg.companyId  FichaClinica: ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                // this.Deficiencia = true
                 this.dadosUsuario = await filtro[0]
                // this.dadosUsuario['ultimoParto'] = this.datePipe.transform(this.dadosUsuario['ultimoParto'], 'dd-MM-yyyy')
                // this.dadosUsuario['dataParto'] = this.datePipe.transform(this.dadosUsuario['dataParto'], 'dd-MM-yyyy')
                // this.dadosUsuario['ultimaMenstruacao'] = this.datePipe.transform(this.dadosUsuario['ultimaMenstruacao'], 'dd-MM-yyyy')

                console.log('FichaClinica Registro : ' + JSON.stringify(this.dadosUsuario))
              }
            }
          ).catch(
            error => {}//this.mensagem.error('Não foi possivel encontrar registros do Ficha Clinica')
          )

        await this.service.listarFichaPreNatal()
          .toPromise()
          .then(
            async (dados: Array<FormFichaClinica>) => {
              var id = idCompany
              var usuarioId = userId
              var filtro = dados.filter(function (reg: any) {
                console.log('1 - reg.companyId  FichaClinica: ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                this.AcompanhamentoPreNatal = true
                this.registroFichaClinica.AcPreNatal = await filtro
                for(let i = 0; i <= filtro.length;i++){
                  console.log('PreNatal Data : ' + JSON.stringify(this.registroFichaClinica.AcPreNatal[i].data))
                  this.registroFichaClinica.AcPreNatal[i].data = this.datePipe.transform(this.registroFichaClinica.AcPreNatal[i].data, 'dd/MM/yyyy')
                }
              }
              else {
                this.addAcompanhamentoPreNatal = false
              }
            }
          ).catch(
            error => this.mensagem.error('Não foi possivel encontrar registros do Pré-Natal')
          )

        await this.service.listarUltrassom()
          .toPromise()
          .then(
            async (dados: Array<FormUltrassom>) => {
              console.log(userId + '' + idCompany)
              var id = idCompany
              var usuarioId = userId
              var filtro = dados.filter(function (reg: any) {
                console.log('1 - reg.companyId  Ultrassom: ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                this.Ultrassom = true
                this.registroFichaClinica.ultrassom = await filtro
                console.log('Ultrassom Registro : ' + JSON.stringify(this.dadosUsuario))
              }
              else {
                this.Ultrassom = false
              }
            }
          ).catch(
            error => this.mensagem.error('Não foi possivel encontrar registros do Ultrassom')
          )
        break

      case 'fichaClinica':
        await this.service.listarFichaClinica()
          .toPromise()
          .then(
            async (dados: Array<FormFichaClinica>) => {
              console.log(userId + '' + idCompany)
              var id = idCompany
              var usuarioId = userId
              var filtro = dados.filter(function (reg: any) {
                console.log('1 - reg.companyId  FichaClinica: ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                // this.Deficiencia = true
                this.dadosUsuario = await filtro[0]
                console.log('FichaClinica Registro : ' + JSON.stringify(this.dadosUsuario))
              }
            }
          ).catch(
            error =>{} //this.mensagem.error('Não foi possivel encontrar registros do Ficha Clinica')
          )

        break

      case 'preNatal':
        await this.service.listarFichaPreNatal()
          .toPromise()
          .then(
            async (dados: Array<FormFichaClinica>) => {
              console.log(userId + '' + idCompany)
              var id = idCompany
              var usuarioId = userId
              var filtro = dados.filter(function (reg: any) {
                console.log('1 - reg.companyId  FichaClinica: ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                this.AcompanhamentoPreNatal = true
                this.registroFichaClinica.AcPreNatal = await filtro
              }
              else {
                this.AcompanhamentoPreNatal = false
              }
            }
          ).catch(
            error => this.mensagem.error('Não foi possivel encontrar registros do Pré-Natal')
          )

        break

      case 'ultrassom':
        await this.service.listarUltrassom()
          .toPromise()
          .then(
            async (dados: Array<FormUltrassom>) => {
              console.log(userId + '' + idCompany)
              var id = idCompany
              var usuarioId = userId
              var filtro = dados.filter(function (reg: any) {
                console.log('1 - reg.companyId  Ultrassom: ' + reg.companyId.toUpperCase() + ' : ' + id)
                return reg.companyId.toUpperCase() == id.toUpperCase() && reg.usuarioId.toUpperCase() == usuarioId.toUpperCase() && reg.ativo == true
              })
              if (filtro.length >= 1) {
                this.Ultrassom = true
                this.registroFichaClinica.ultrassom = await filtro
                console.log('Ultrassom Registro : ' + JSON.stringify(this.dadosUsuario))
              }
              else {
                this.Ultrassom = false
              }
            }
          ).catch(
            error => this.mensagem.error('Não foi possivel encontrar registros do Ultrassom')
          )
        break

    }
  }
  async cadastroParcial(ref, dados?, id?) {
    // alert(dados.ultimoParto.replace(/[^\w\-]+/g, '-'))
    // dados.ultimoParto = dados.ultimoParto.replace(/[^\w\-]+/g, '-')
    // dados.dataParto = dados.dataParto.replace(/[^\w\-]+/g, '-')
    // dados.ultimaMenstruacao = dados.ultimaMenstruacao.replace(/[^\w\-]+/g, '-')
    switch (ref) {
      case 'fichaClinica':
        if (id) {
          dados.id = id
          dados.numeroFilhos = parseInt(dados.numeroFilhos)
          this.service.alterarFichaClinica(id, dados).toPromise()
            .then(() => {
              this.mensagem.success('Alterado com sucesso')
              this.buscarRegistro('fichaClinica', dados.usuarioId, dados.companyId)

            })
            .catch(error => { this.mensagem.error('Algo deu errado'), console.log('FichaClinica : ' + JSON.stringify(error)) })
        }
        else {
          this.service.cadastrarFichaClinica(dados).toPromise()
            .then(() => {
              this.mensagem.success('Cadastrado com sucesso')
              this.buscarRegistro('fichaClinica', dados.usuarioId, dados.companyId)
            })
            .catch(error => { this.mensagem.error('Algo deu errado'), console.log('FichaClinica : ' + JSON.stringify(error)) })
        }
        break

      case 'preNatal':
        if (id) {
          dados.id = id
          dados.numeroFilhos = parseInt(dados.numeroFilhos)
          this.service.alterarFichaPreNatal(id, dados).toPromise()
            .then(() => {
              this.mensagem.success('Alterado com sucesso')
              this.buscarRegistro('preNatal', dados.usuarioId, dados.companyId)

            })
            .catch(error => { this.mensagem.error('Algo deu errado'), console.log('PreNatal : ' + JSON.stringify(error)) })
        }
        else {
          this.service.cadastrarFichaPreNatal(dados).toPromise()
            .then(() => {
              this.mensagem.success('Cadastrado com sucesso')
              this.buscarRegistro('preNatal', dados.usuarioId, dados.companyId)
            })
            .catch(error => { this.mensagem.error('Algo deu errado'), console.log('PreNatal : ' + JSON.stringify(error)) })
        }
        break

      case 'ultrassom':
        if (id) {
          dados.id = id
          this.service.alterarUltrassom(id, dados).toPromise()
            .then(() => {
              this.mensagem.success('Alterado com sucesso')
              this.buscarRegistro('ultrassom', dados.usuarioId, dados.companyId)

            })
            .catch(error => { this.mensagem.error('Algo deu errado'), console.log('Ultrassom : ' + JSON.stringify(error)) })
        }
        else {
          this.service.cadastrarUltrassom(dados).toPromise()
            .then(() => {
              this.mensagem.success('Cadastrado com sucesso')
              this.buscarRegistro('ultrassom', dados.usuarioId, dados.companyId)
            })
            .catch(error => { this.mensagem.error('Algo deu errado'), console.log('Ultrassom : ' + JSON.stringify(error)) })
        }
        break

      case 'excluir-ultrassom':
        dados.ativo = false
        this.service.excluirUltrassom(id, dados).toPromise()
          .then(() => {
            this.mensagem.success('Deletado com sucesso')
            this.buscarRegistro('ultrassom', dados.usuarioId, dados.companyId)
          })
          .catch(error => { this.mensagem.error('Algo deu errado'), console.log('Ultrassom : ' + JSON.stringify(error)) })
        break

      case 'excluir-preNatal':
        dados.ativo = false
        this.service.excluirFichaPreNatal(id, dados).toPromise()
          .then(() => {
            this.mensagem.success('Deletado com sucesso')
            this.buscarRegistro('preNatal', dados.usuarioId, dados.companyId)
          })
          .catch(error => { this.mensagem.error('Algo deu errado'), console.log('preNatal : ' + JSON.stringify(error)) })
        break
    }
  }

  createFormulario(usuarioId, idCompany) {
    this.formFichaClinica = this.form.group({
      usuarioId: new FormControl(usuarioId),
      companyId: new FormControl(idCompany),
      gestante: new FormControl(),
      paridade: new FormControl('', Validators.required),
      idade: new FormControl('', Validators.required),
      gestacao: new FormControl('', Validators.required),
      abortos: new FormControl('', Validators.required),
      partoNormal: new FormControl('', Validators.required),
      partoForceps: new FormControl('', Validators.required),
      cesaria: new FormControl('', Validators.required),
      ultimoParto: new FormControl(new Date('dd/MM/yyyy'), Validators.required),
      ultimaMenstruacao: new FormControl(new Date(), Validators.required),
      dataParto: new FormControl('', Validators.required),
      numeroFilhos: new FormControl(Number, Validators.required),
      risco: new FormControl('', Validators.required),
      anotacoes: new FormControl('', Validators.required),
      medico: new FormControl('', Validators.required),
      telefoneMedico: new FormControl('', Validators.required),
      ativo: true
    })
    this.formPreNatal = this.form.group({
      usuarioId: new FormControl(usuarioId),
      companyId: new FormControl(idCompany),
      data: new FormControl(new Date(), Validators.required),
      ig: new FormControl('', Validators.required),
      au: new FormControl('', Validators.required),
      pa: new FormControl('', Validators.required),
      peso: new FormControl('', Validators.required),
      bcf: new FormControl('', Validators.required),
      mf: new FormControl('', Validators.required),
      edema: new FormControl('', Validators.required),
      apres: new FormControl('', Validators.required),
      observacao: new FormControl('', Validators.required),
      ativo: true
    })
    this.formUltrassom = this.form.group({
      usuarioId: new FormControl(usuarioId),
      companyId: new FormControl(idCompany),
      dataUltrasson: new FormControl(new Date(), Validators.required),
      igdum: new FormControl('', Validators.required),
      igus: new FormControl('', Validators.required),
      apresentaDorso: new FormControl('', Validators.required),
      la: new FormControl('', Validators.required),
      placenta: new FormControl('', Validators.required),
      observacao: new FormControl('', Validators.required),
      ativo: true
    })
  }

  altualizarForm(nome) {
    switch (nome) {
      case "fichaClinica":
    }
  }


}
