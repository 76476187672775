import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { TabelaMenu } from '../tabela/model';
import { EnciclopediaService } from '../service/enciclopedia.service';
import { BuscarDataService } from '../service/buscar-data.service';
interface Enciclopedia {
  id: any | string,
  titulo1: string
  titulo2: string
  texto: string
  index?: number
}
@Component({
  selector: 'app-enciclopedia',
  templateUrl: './enciclopedia.component.html',
  styleUrls: ['./enciclopedia.component.scss']
})
export class EnciclopediaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ['acao','id', 'titulo1', 'titulo2'];
  dataSource: MatTableDataSource<Enciclopedia>;
  loading: boolean;
  viewDetalhe: boolean;
  detalhe: Enciclopedia;
  enciclopedia: Enciclopedia[]
  alterando: boolean;
  registro: Enciclopedia[];
  registro_2: Enciclopedia[];
  comentario: string
  comentar: boolean;
  viewChat: boolean;
  getComentario: any[];
  registrando: boolean;
  cpf: string;

  constructor(private serivce: EnciclopediaService, private data: BuscarDataService) {
    this.enciclopedia = JSON.parse(localStorage.getItem('enciclopedia'))
    console.log(this.enciclopedia)
  }

  async ngOnInit() {
    this.cpf = sessionStorage.getItem('cpf')
    await this.buscarDados()
  }
  buscarDados() {
    this.loading = true
    console.log('buscando')
    // let reg = localStorage.getItem('dev')
    // if (reg) {
    //   console.log(JSON.parse(reg))
    //   this.dataSource = new MatTableDataSource([JSON.parse(reg)])
    //   this.loading = false
    //   return

    // }
    this.serivce.listarIndex().toPromise().then(
      (dados) => {
        this.loading = false
        this.dataSource = new MatTableDataSource(dados)
        this.registro = dados
        try {
          localStorage.setItem('dev', JSON.stringify(this.registro))
        }
        catch{

        }

        this.dataSource.paginator = this.paginator

        this.ajustarIds()
      }
    ).catch(
      (erro) => { console.log(erro) }
    )


  }

  ajustarIds() {
    this.dataSource.filteredData.forEach((reg, i) => {
      this.dataSource.filteredData[i].id = `.${this.dataSource.filteredData[i].id}`
    });
  }
  abrirDetalhe(reg: Enciclopedia) {
    this.viewDetalhe = true
    this.detalhe = reg
    this.serivce.listar(reg.index).toPromise()
      .then(
        (reg) => this.detalhe.texto = reg.texto.split(".").join(". \n ")
      )
    console.log(reg.texto)
    this.buscarComentarios(reg.index)
  }
  buscarComentarios(index) {
    this.serivce.listarComent().toPromise()
      .then(
        (res) => {
          let filtro = res.filter(function (reg) {
            return reg.index == index
          })
          if (filtro) {
            this.getComentario = filtro
          }
          else {
            this.getComentario = []
          }

        }
      )
  }
  exclurComentario(id, index) {

    this.serivce.excluirComentario(id).toPromise()
      .then(
        () => this.getComentario.splice(index)
      )
  }
  async registrarComentario() {
    this.registrando = true
    const nome = await sessionStorage.getItem('userName')
    const usuarioId = await sessionStorage.getItem('tokken')
    const cpf = await sessionStorage.getItem('cpf')
    const index = await this.detalhe.index
    const reg = await {
      comentario: this.comentario,
      nome: nome,
      usuarioId: usuarioId,
      cpf: cpf,
      index: index,
      data: this.data.hoje()
    }
    this.serivce.adicionarComent(reg).toPromise()
      .then(
        (reg) => {
          this.registrando = false
          this.comentar = false
          this.comentario = ''
          if (reg.id) {
            this.getComentario.push(reg)
          }

        }
      )

  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
