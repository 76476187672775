import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';
import { DadosCadastroCentroDeLucro } from '../cadastro-centro-de-lucro/modelsCadastroCentroLucro';

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})
export class CadastroCentroLucroService {
    url = new environment().api
    api = "CadastroCentroLucro/"

    constructor(private http: HttpClient, private data: BuscarDataService) { }


    listar() {
        return this.http.get<DadosCadastroCentroDeLucro[]>(this.url + this.api)
    }

    enviar(registro: DadosCadastroCentroDeLucro) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: DadosCadastroCentroDeLucro) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: DadosCadastroCentroDeLucro, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        } else {
            registro.status = 'SDA0002'
        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }

    getCadastroCentroLucro(codigoEmpresa: any): Observable<Object> {
        return this.http.get(this.url + this.api + "FiltrarCodigoEmpresa/" + codigoEmpresa)
    }

    async get(codigoEmpresa) {
        let result = []
        await this.http.get<DadosCadastroCentroDeLucro[]>(this.url + this.api + `FiltrarCodigoEmpresa/` + codigoEmpresa).toPromise()
            .then(res => {
                if (res.length > 0) result = res
            })
        return result
    }

    // postCadastroCentroLucro(dados: any): Observable<Object> {
    //     return this.http.post(this.url + this.apiCadastroCentroLucro, dados)
    // }


    // getCadastroCentroLucroID(id: any): Observable<Object> {
    //     return this.http.get(this.url + this.apiCadastroCentroLucro + id)
    // }

    // PutCadastroCentroLucro(id: any, userId: any, data: string, dados) {
    //     return this.http.put(`${this.url}${this.apiCadastroCentroLucro}${id}/${userId}/${data}`, dados);
    // }
}


