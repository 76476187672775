import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { NotaFiscalService } from 'src/app/service/emitir-recibo-nota-fiscal.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { SessaoUser } from 'src/app/user.reg';
import { EmitirReciboNotaFiscalModel } from './emitir-recibo-nota-fiscal.model';

const sessao = new SessaoUser

@Component({
  selector: 'app-emitir-recibo-nota-fiscal',
  templateUrl: './emitir-recibo-nota-fiscal.component.html',
  styleUrls: ['./emitir-recibo-nota-fiscal.component.scss']
})
export class EmitirReciboNotaFiscalComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['acao', 'nomeEmpresa', 'nomeTitular', 'numeroNota']
  mask_cpf = "000.000.000-00"
  listRegistro: EmitirReciboNotaFiscalModel[]
  listEmpresa = []
  formulario: FormGroup
  viewCadastro = false
  alteracao = false
  botaoSalvar = false
  botaoCancelar = false
  dataSource
  empresaSelect: any
  loading: boolean
  nome: string

  constructor(
    private form: FormBuilder,
    private service: NotaFiscalService,
    private token: TokenDeAcessoService,
    private mensagem: MensagemService,
    private usuario: UsuarioService
  ) { }

  ngOnInit() {
    this.createForm()
    this.getEmpresa()
    this.getRegistro()
  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: sessao.cpf,
      status: '',
      codigoEmpresa: 0,
      nomeEmpresa: '',
      nomeTitular: new FormControl({ disabled: true }),
      cpfTitular: '',
      nomeDependente: '',
      numeroNota: ''
    })
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.nome = null
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
    this.formulario.controls['nomeTitular'].disable()
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async buscarUsuario(cpf, openCadastro?) {
    let valid = this.formulario.controls['cpfTitular'].valid

    if (valid || openCadastro) {
      let resposta = await this.usuario.getRegistro(cpf)
      this.nome = resposta[0].nomeCompleto
    } else this.nome = null
  }

  async getRegistro() {

    this.loading = true

    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)

            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
            }
          })
          this.dataSource = new MatTableDataSource()
          this.dataSource.sort = this.sort
          this.loading = false
        }
      )
  }

  inserir() {
    let registro = this.formulario.value

    registro.criadoPor = sessao.cpf

    this.service.enviar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat(resposta[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          })
  }


  alterar() {
    let registro = this.formulario.value

    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          })
  }

  async openCadastro(registro) {
    this.formulario.setValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    await this.buscarUsuario(registro.cpfTitular, true)
    this.formulario.controls['nomeTitular'].disable()
    this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    this.formulario.setValue(registro)
    await this.buscarUsuario(registro.cpfTitular, true)
    this.formulario.disable()
  }

  async excluirRegistro() {
    let registro = this.formulario.value

    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
  }

  filtrar(value) {
    this.empresaSelect = value
    this.dataSource.filter = value.toLocaleUpperCase().trim()


  }

  filtrarEmpresa(codigoEmpresa) {
    this.loading = true

    this.dataSource.data = this.listRegistro

    if (!codigoEmpresa) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
      return
    }

    let filter = this.dataSource.data.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.empresaSelect = this.dataSource.data

    setTimeout(() => {
      this.loading = false
    }, 2000)
  }

}
