import { Component, OnInit } from '@angular/core';
import { Estados } from 'src/app/service/saude-az/estados';
import { SaudeAZService } from 'src/app/service/saude-az/saude-az.service';

@Component({
  selector: 'app-saude-az',
  templateUrl: './saude-az.component.html',
  styleUrls: ['./saude-az.component.scss']
})
export class SaudeAZComponent implements OnInit {
  processo = 1
  menuOptions = [
    { type: 'clinica', img: 'assets/img/saudeAZ/consultorio.jpg', title: 'Clínicas' },
    { type: 'vacina', img: 'assets/img/saudeAZ/vacina.png', title: 'Vacinas' },
    { type: 'exames', img: 'assets/img/saudeAZ/exame.png', title: 'Exames' },
    { type: 'hospital', img: 'assets/img/saudeAZ/hospital.png', title: 'Hospitais' },
    { type: 'convenio', img: 'assets/img/saudeAZ/convenio.png', title: 'Seguradoras' },
  ]
  typeActive: { type: string; img: string; title: string; };
  listEstados = []
  listCidades = []
  listEstadosBackup = []
  listCidadesBackup = []
  constructor(private service: SaudeAZService) { }

  ngOnInit() {
    this.getConteudo()
  }
  getConteudo() {
    this.listEstados = this.service.getEstados()
    this.listEstadosBackup = this.service.getEstados()
  }


  selectType(type) {
    switch (type) {
      case 'clinica':

        break;
      case 'vacina':

        break;
      case 'exames':

        break;
      case 'hospital':

        break;
      case 'convenio':

        break;

      default:
        break;
    }
    this.typeActive = this.menuOptions.filter(v => v.type == type)[0]
    this.processo = 2
  }
  selectEstado(sigla) {
    this.listCidades = this.listEstados.filter(value => value.sigla == sigla)[0].cidades
    this.listCidadesBackup = this.listEstados.filter(value => value.sigla == sigla)[0].cidades
    console.log(this.listCidades)
  }
  filter(type, value: string) {
    let key = value.toUpperCase()
    let result
    switch (type) {
      case 'estado':
        result = this.listCidadesBackup.filter(value => value.nome.toUpperCase().includes(key))
        this.listCidades = result
        break;
      case 'cidade':
        result = this.listCidadesBackup.filter(value => value.toUpperCase().includes(key))
        this.listCidades = result
        break;
    }
  }

}
