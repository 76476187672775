import { Injectable } from "@angular/core";
import { SessaoUser } from "../user.reg";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {
  AtividadeCaracteristica,
  AutorizacaoDeAcesso,
  ConsultarObjeto,
  InfoObjeto,
} from "../ajustes/autorizacao-de-acesso/model";
import { take } from "rxjs/operators";
import { BuscarDataService } from "./buscar-data.service";
import { RegraAprovacaoDocumento } from "../ajustes/regra-aprovacao-documento/model";

const user = new SessaoUser();
export class AtribuicaoDeAcessoCabecalho {
  codigo: number;
  codigoEmpresa: number;
  criadoPor: string;
  criadoEm: string;
  status: string;
  cpf: string;
}
export class AtribuicaoDeAcessoItem {
  id: string;
  criadoPor: string;
  criadoEm: string;
  codigoEmpresa: number;
  codigoAtribuicaoAcesso: number;
  nrItem: string;
  tipoAcesso: string;
  valorDeAcesso: string;
  status: string;
}
@Injectable({
  providedIn: "root",
})
export class AutorizacaoAcessoService {
  // base_url = 'https://myclinics.com.br/api/api/'
  url = new environment().api;
  api_cab = "AtribuicaoDeAcessoCabecalho/";
  api_item = "AtribuicaoDeAcessoItem/";
  apiFichaAuth = "FichaAutorizacaoDeAcesso/";
  apiInfoObjeto = "ObjetoDeInformacao/";
  apiAuthAtiv = "ConteudoDeDados/FiltrarCodigoCaracteristica/";
  apiConsultarObjeto = "ConsultarObjeto/";
  apiAuthAtivParam = "AUTHATIV";

  constructor(private http: HttpClient, private data: BuscarDataService) {}

  listar() {
    return this.http.get<AutorizacaoDeAcesso[]>(this.url + this.apiFichaAuth);
  }
  async getAutorizacao(codigoEmpresa) {
    let result = [];
    await this.http
      .get<any>(
        this.url +
          this.apiFichaAuth +
          "JoinFiltrarCodigoEmpresa/" +
          codigoEmpresa
      )
      .toPromise()
      .then((res) => {
        if (res.success) {
          result = res.result;
        }
      });
    return result;
  }

  listarInfoObjeto() {
    return this.http.get<InfoObjeto[]>(this.url + this.apiInfoObjeto);
  }

  listarCampoDeDadoInfoObjeto(codigoInfoObjeto, codigoEmpresa) {
    return this.http.get<ConsultarObjeto[]>(
      this.url +
        this.apiInfoObjeto +
        this.apiConsultarObjeto +
        codigoInfoObjeto +
        "/" +
        codigoEmpresa
    );
  }

  listarCaracteristicas() {
    return this.http.get<AtividadeCaracteristica[]>(
      this.url + this.apiAuthAtiv + this.apiAuthAtivParam
    );
  }

  cadastrar(dadosFichaAutorizacaoAcesso: AutorizacaoDeAcesso) {
    return this.http
      .post(this.url + this.apiFichaAuth, dadosFichaAutorizacaoAcesso)
      .pipe(take(1));
  }

  alterar(dadosFichaAutorizacaoAcesso) {
    console.log(
      this.url +
        this.apiFichaAuth +
        `${dadosFichaAutorizacaoAcesso.id}/${
          user.sessao_id
        }/${this.data.getTIMESTAMP()}/`,
      JSON.stringify(dadosFichaAutorizacaoAcesso)
    );
    return this.http.put(
      this.url +
        this.apiFichaAuth +
        `${dadosFichaAutorizacaoAcesso.id}/${
          user.sessao_id
        }/${this.data.getTIMESTAMP()}/`,
      dadosFichaAutorizacaoAcesso
    );
  }

  async desativar(registro: RegraAprovacaoDocumento, ativo) {
    if (ativo) {
      registro.status = "SDA0001";
    } else {
      registro.status = "SDA0002";
    }

    let reposta;

    await this.alterar(registro)
      .toPromise()
      .then((res) => (reposta = res))
      .catch((error) => console.error(error));

    return reposta;
  }

  async getAcesso(cpf: string, codigoEmpresa: number) {
    let result: { tipo: string; valor: string }[] = [];
    await this.http
      .get<AtribuicaoDeAcessoCabecalho[]>(this.url + this.api_cab)
      .toPromise()
      .then(async (res) => {
        let registro = res.filter(
          (value) =>
            value.cpf == cpf &&
            value.codigoEmpresa == codigoEmpresa &&
            value.status == "SDA0001"
        );
        if (registro.length > 0) {
          await this.http
            .get<AtribuicaoDeAcessoItem[]>(this.url + this.api_item)
            .toPromise()
            .then(async (res) => {
              let getRegistros: AtribuicaoDeAcessoItem[] = [];

              await registro.forEach((item) => {
                try {
                  let get = res.filter((value) => {
                    return (
                      value.codigoAtribuicaoAcesso == item.codigo &&
                      value.status == "SDA0001" &&
                      value.codigoEmpresa == codigoEmpresa
                    );
                  });
                  getRegistros.push(...get);
                } catch {}
              });

              result = getRegistros.map((value) => {
                return { tipo: value.tipoAcesso, valor: value.valorDeAcesso };
              });
            });
        }
      });
    return result;
  }
}
