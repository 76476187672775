export class Acesso{
    id?: string
    inicioDaSessao: string
    fimDaSessao?: String
    cpf: String
    tempoDeSessao: string
    localDeSessao: String
    ipLocal: String
    status: String
    horarioBancoDeDados?: String
    horarioLocal?: String
    horarioServidor?: String
    dispositivoDeAcesso?: String
    codigoEmpresa?: number
    coordenadaGps: string
  }
