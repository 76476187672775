import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RegrasOrcamentoModel } from '../ajustes/regras-orcamento/regras-orcamento.model';
import { SessaoUser } from '../user.reg';
import { BuscarDataService } from './buscar-data.service';

const user = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class RegrasOrcamentoService {

    url = new environment().api
    api = "AJ_REGORC/"

    constructor(private http: HttpClient, private data: BuscarDataService) {
    }

    listar() {
        return this.http.get<RegrasOrcamentoModel[]>(this.url + this.api)
    }

    enviar(registro: RegrasOrcamentoModel) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: RegrasOrcamentoModel) {
        return this.http.put(this.url + this.api + `${registro.id}/${user.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: RegrasOrcamentoModel, ativo) {
        if (ativo) {
            registro.status = 'SDA0001'
        } else {
            registro.status = 'SDA0002'

        }

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })

        return resposta
    }

}