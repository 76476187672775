export class FichaGeralLocalDeArmazenamento {
    codigoLocalArmazenamento?: number;
    criadoPor?: string;
    codigoEmpresa?: number;
    nomeLocalDeArmazenamento?: string;
    ativoDesde?: string;
    inativoDesde?: string;
    status?: string;
    timeStamp?: string;
    observacao?: string;
    contaContabilDespesas?: string;
    contaContabilLancamentoEntradaEstoque?: string;
    contaContabilLancamentoSaidaEstoque?: string;
    contaContabilLancamentoCompras?: string;
    contaContabilLancamentoVendas?: string;
    contaContabilLancamentoManual?: string;
}

export class PosicaoDeArmazenamento {
    identificadorPosicao?: number;
    criadoPor?: string;
    codigoLocalDeArmazenamento?: number;
    descricao?: string;
    metodoDeAlocacaoEConsumo?: string;
    quantidadeMaximaDeArmazenamento?: number;
    pesoMaximoArmazenamento?: number;
    codigoDeItemSKU?: number;
    status?: string;
    timeStamp?: string;
    cpfAutor?: string;
}

