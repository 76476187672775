import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SessaoUser } from 'src/app/user.reg';
import { CadastroOperadoraCartao } from './cadastro-operadora-cartao.model';
import { CadastroOperadoraCartaoService } from 'src/app/service/cadastro-operadora-cartao.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { StatusSistemaService } from 'src/app/service/status-sistema.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { LoadingComponent } from 'src/app/loading/loading.component';

const user = new SessaoUser

@Component({
  selector: 'app-cadastro-operadora-cartao',
  templateUrl: './cadastro-operadora-cartao.component.html',
  styleUrls: ['./cadastro-operadora-cartao.component.scss']
})

export class CadastroOperadoraCartaoComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort

  /*
  *  NO DIA 02/10, FOI ALTERADO A TABELA QUE O MODEL ENVIA A API
  *  ANTIGO NOME >> CadastrOperadora 
  *  VERIFICAR COM BACK-END O NOME DO NOVO SERVICE E TABELA
  */


  displayedColumns = ['acao', 'empresa', 'operadora', 'bandeira']
  formulario: FormGroup
  viewCadastro = false
  listRegistro: CadastroOperadoraCartao[]
  listBandeira = []
  listOperadora = []
  listEmpresa = []
  alteracao: boolean = false;
  dataSource
  loading = false
  empresaSelec: any

  botaoSalvar = false
  botaoCancelar = false
  viewConfirmDelete: boolean

  constructor(
    private form: FormBuilder,
    private service: CadastroOperadoraCartaoService,
    private mensagem: MensagemService,
    private status: StatusSistemaService,
    private token: TokenDeAcessoService,
    private conteudo: ConteudoDeDadosService

  ) { }

  ngOnInit() {
    this.creatForm()
    this.getBandeira()
    this.getOperadora()
    this.getEmpresa()
    this.getRegistro()
  }

  creatForm() {
    this.formulario = this.form.group({
      id: '',
      criadoPor: user.cpf,
      timestamp: '',
      status: '',
      banco: '',
      agencia: '',
      contaCorrente: '',
      operadora: '',
      bandeira: '',
      taxaDebito: 0,
      prazoDebito: 0,
      taxaCreditoAvista: 0,
      prazoCreditoAvista: 0,
      taxaCreditoAprazo: 0,
      prazoCreditoAprazo: 0,
      codigoContaBancaria: 0,
      codigoEmpresa: 0,
      descBandeira: '',
      descOperadora: '',
      //

    })

  }


  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  async getBandeira() {
    let getBandeira = await this.conteudo.get('BANDCARD')
    this.listBandeira = getBandeira
  }

  async getOperadora() {
    let getOperadora = await this.conteudo.get('OPERCARD')
    this.listOperadora = getOperadora
  }

  adicionarCadastro() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.formulario.enable()
    this.formulario.reset()
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getRegistro() {

    this.loading = true

    await this.service.listar().toPromise()
      .then(
        registro => {

          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          this.listRegistro.forEach(async item => {
            let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
            let getBandeira = await this.conteudo.get('BANDCARD', true, item.bandeira)
            let getOperadora = await this.conteudo.get('OPERCARD', true, item.operadora)

            if (empresa.length > 0) {
              item.nomeEmpresa = empresa[0].nomeEmpresa
            }

            item.descBandeira = getBandeira.toString()
            item.descOperadora = getOperadora.toString()
          })

          this.dataSource = new MatTableDataSource()
          this.dataSource.sort = this.sort
          this.loading = false

        }
      )
  }

  /*getRegistro() {
    this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.nomeEmpresa == user.name)

        }
      )
  }*/

  inserir() {
    let registro = this.formulario.value

    delete registro.id
    delete registro.timestamp
    delete registro.descBandeira
    delete registro.descOperadora
    // delete registro.status
    delete registro.banco
    delete registro.agencia
    delete registro.contaCorrente
    delete registro.codigoContaBancaria
    delete registro.nomeEmpresa


    registro.criadoPor = user.cpf


    console.log("Empresa: ", registro)
    this.service.enviar(registro).toPromise()
      .then(
        res => {

          this.mensagem.creat(res[0].mensagem, { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  alterar() {
    let registro = this.formulario.value

    delete registro.descBandeira
    delete registro.descOperadora

    this.service.alterar(registro).toPromise()
      .then(
        resposta => {
          console.log(resposta)
          this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
        }
      ).catch(
        error => {
          let erro = error.error
          this.mensagem.creat(erro, { erro: true })
        }
      )
  }

  openCadastro(registro) {
    //delete registro.descBandeira
    //delete registro.descOperadora

    delete registro.nomeEmpresa

    console.log("Open Cadastro: ", registro)
    this.formulario.setValue(registro)
    localStorage.setItem('backup_registro', JSON.stringify(registro))
    this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
  }




  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    await this.formulario.setValue(registro)
    await this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if(confirm){
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false

    let registro = this.formulario.value

    if (!registro.id) return

    delete registro.descBandeira
    delete registro.descOperadora

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
  }

  filtrar(value) {
    this.empresaSelec = value
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa(codigoEmpresa) {
    this.loading = true

    this.dataSource.data = this.listRegistro

    if (!codigoEmpresa) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
      return
    }

    let filter = this.dataSource.data.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.empresaSelec = this.dataSource.data

    setTimeout(() => {
      this.loading = false
    }, 2000);
  }

}


