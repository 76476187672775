import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CadastroPessoaForm, FormPaciente } from 'src/app/cadastro-paciente/model';
import { AtestadoObitoService } from 'src/app/service/atestado-obito/atestado-obito.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { SessaoUser } from 'src/app/user.reg';
import { AtestadoObito } from './model';
const sessao = new SessaoUser
@Component({
  selector: 'app-atestado-obito',
  templateUrl: './atestado-obito.component.html',
  styleUrls: ['./atestado-obito.component.scss']
})
export class AtestadoObitoComponent implements OnInit {

  @Output('close') close = new EventEmitter
  @Input('pacientes') pacientes: CadastroPessoaForm[] = []
  loading: boolean = false

  list_tipoObito: ConteudoDados[] = [];
  list_localObito: ConteudoDados[] = [];
  list_tipoGravidez: ConteudoDados[];
  list_tipoParto: ConteudoDados[];
  list_relacaoParto: ConteudoDados[];
  list_morteParto: ConteudoDados[];
  list_periodoGravidez: ConteudoDados[];
  list_puerperio: ConteudoDados[];
  list_assistenteMedica: ConteudoDados[];
  list_exame: ConteudoDados[];
  list_necropsia: ConteudoDados[];
  list_atestadoObito: ConteudoDados[];
  list_circunstancia: ConteudoDados[];
  list_acidenteTrabalho: ConteudoDados[];
  list_fontInformacao: ConteudoDados[];
  list_cirurgia: ConteudoDados[];
  formulario: FormGroup;

  constructor(
    private _conteudo: ConteudoDeDadosService,
    private formBuild: FormBuilder,
    private service: AtestadoObitoService,
    private _mensagem: MensagemService) { }

  ngOnInit() {
    this.creatForm()
    this.getConteudo()

  }
  creatForm() {
    this.formulario = this.formBuild.group({
      id: '',
      paciente: new FormControl(''),
      tipoObtio: new FormControl(''),
      duracaoGravidez: new FormControl(''),
      tipoGravidez: new FormControl(''),
      tipoParto: new FormControl(''),
      morteRelacaoParto: new FormControl(''),
      mortePeriodoGravidez: new FormControl(''),
      mortePuerperio: new FormControl(''),
      assistenciaMedica: new FormControl(''),
      exameComplementar: new FormControl(''),
      cirurgia: new FormControl(''),
      necropsia: new FormControl(''),
      doenca: new FormControl(''),
      causas: new FormControl(''),
      meioContato: new FormControl(''),
      medicoAtesta: new FormControl(''),
      tipoCircunstancia: new FormControl(''),
      acidenteTrabalho: new FormControl(''),
      fonte: new FormControl(''),
      descricaoSumaria: new FormControl(''),
      endereco: new FormControl(''),
      criadoPor: new FormControl(''),
      criadoEm: new FormControl(''),
      status: new FormControl(''),
    })
  }
  async gerartestado() {
    this.loading = true
    let register = this.formulario.getRawValue() as AtestadoObito
    register.criadoEm = new Date().toISOString()
    register.criadoPor = sessao.cpf
    register.status = 'SDA0001'
    delete register.id
    await this.service.post(register).toPromise()
      .then(res => {
        this._mensagem.creat('Atestado gerado com sucesso', { sucesso: true })
      })
      .catch(error => {
        console.log('erro->', error)
        this._mensagem.creat('Não foi possível realizar procedimento', { erro: true })
      })
    this.loading = false
  }

  async getConteudo() {
    this.list_tipoObito = await this._conteudo.get('TPOBITO')
    this.list_localObito = await this._conteudo.get('LOCOBITO')
    this.list_tipoGravidez = await this._conteudo.get('TPGRAVID')
    this.list_tipoParto = await this._conteudo.get('TPPARTO')
    this.list_relacaoParto = await this._conteudo.get('MORTEPARTO')
    this.list_morteParto = await this._conteudo.get('MORTEPARTO')
    this.list_periodoGravidez = await this._conteudo.get('OBITMUL')
    this.list_puerperio = await this._conteudo.get('OBITPUERP')
    this.list_assistenteMedica = await this._conteudo.get('OBITASSIST')
    this.list_exame = await this._conteudo.get('OBITDIAG')
    this.list_necropsia = await this._conteudo.get('OBITNECRO')
    this.list_atestadoObito = await this._conteudo.get('OBITMED')
    this.list_circunstancia = await this._conteudo.get('OBITNNAT')
    this.list_acidenteTrabalho = await this._conteudo.get('OBITACIDT')
    this.list_fontInformacao = await this._conteudo.get('OBITFONT')
    this.list_cirurgia = await this._conteudo.get('OBITCIRUR')
  }
  exit() {
    this.close.emit()
  }
}
