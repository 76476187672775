export class CheckedAtDadosSaude {
    caracteristicas: boolean = false
    historicoSaude: boolean = false
    dadosSaude: boolean = false
    pcd: boolean = false
    sinaisVitais: boolean = false
    medUso: boolean = false
    dataUtChekup: boolean = false
    cartVacinacao: boolean = false
    habVida: boolean = false
    doencaCong: boolean = false
    doencaPree: boolean = false
    doencaCronicas: boolean = false
    doencaContraidas: boolean = false
    doencaFamilia: boolean = false
    evolucaoSaude: boolean = false
    cirurgiaRealizada: boolean = false
    lesaoFratCont: boolean = false
    alergiaMedic: boolean = false
    outrasAlergias: boolean = false
    tratRealizado: boolean = false
    procAdicionais: boolean = false
    interHosp: boolean = false
    medReceitaEsp: boolean = false
    medReceitaBranca: boolean = false
    ultimaMedSinaisVitaisBasic: boolean = false
    ultimaMedSinaisVitais: boolean = false
    caracteristicasFisicas: boolean = false
    cirugia: boolean = false
    tratamentoRealizado: boolean = false
    procedimentoAdicionais: boolean = false
    internacaoHospitalares: boolean = false
    medicacaoReceitaEspecial: boolean = false
    medicacaoReceitaBranca: boolean = false
}
export class ViewSubItem{
    indicadorSaude: boolean = false
    pcd: boolean = false
    sinaisVitais: boolean = false
    medicacaoUso: boolean = false
    doencasCongenitas: boolean = false
    doencasPree: boolean = false
    doencasCronicas: boolean = false
    lesaoFratCont: boolean = false
    alergiaMedicacao: boolean = false
    outrasAlergias: boolean = false
    dataCheckup: boolean = false
    evolucaoSaude: boolean = false
    carteiraVacinacao: boolean = false
    doencaFamilia: boolean = false
    doencasContraidas: boolean = false
    cirurgiaRealizadas: boolean = false
    tratamentoRealizado: boolean = false
    procedimentoAdicionais: boolean = false
    internacoes: boolean = false
    medicacoesRE: boolean = false
    medicacoesRB: boolean = false
    ultimaMedSinaisVitaisBasic: boolean = false
    ultimaMedSinaisVitais: boolean = false
    caracteristicasFisicas: boolean = false
    
}
export class MedicamentoEmUso {
    id?: number
    principioAtivo?: string
    nomeComercial?: string
    dosagem?: string
    via?: string
    frequencia?: string
    duracao: string
}
export class DataUltimoCheckup {
    id?: number
    DATACHEKUP: string
}
export class Doencas {
    id?: number
    DOECON: string//DOENCA CONGENITA
    DOEPRE: string//DOENCAS PREEXISTENTES
    DOECRI: string //DOENCAS CRONICAS
    DOETRA: string //DOENCAS CONTRAIDAS
}
export class HistoricoDoencaFamilia {
    id?: number
    descricao: string
    parentesco: string
}
export class CirurgiaRealizadas {
    id?: number
    cid: string
    tipoCirurgia: string
    motivo: string
    data: string
}

export class LesoesFraturaContusoes {
    id?: number
    tipo: string
    descricao: string
    data: string
}

export class AlergiaMedicacao {
    id?: number
    principioAtivo: string
    nomeComercial: string
}

export class OutrasAlergias {
    id?: number
    tipo: string
    antigeno: string
}

export class TratamentoRealizados {
    id?: number
    procedimentoAdicional: string
    data: string
}

export class ProcedimentoAdicionais {
 id?: number
 nomeTratamento : string
 descricao : string
 quantidade : string
 data: string
}
export class InternacoesHospitalares {
    id?: number
    dataInternacao : string
    periodo : string
    quantidade : string
    data: string
}