import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { PlanoContaContabeisModel } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';
import { InformarSaldoContabilInicialService } from 'src/app/service/informar-saldo-contabil-inicial.service';
import { MensagemService } from 'src/app/service/mensagem.service';
import { TokenDeAcessoService } from 'src/app/service/token-de-acesso.service';
import { SessaoUser } from 'src/app/user.reg';
import { InformarSaldoContabilInicialModel } from './informar-saldo-contabil-inicial.model';


const sessao = new SessaoUser

@Component({
  selector: 'app-informar-saldo-contabil-inicial',
  templateUrl: './informar-saldo-contabil-inicial.component.html',
  styleUrls: ['./informar-saldo-contabil-inicial.component.scss']
})

export class InformarSaldoContabilInicialComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort


  displayedColumns = ['acao', 'nomeEmpresa', 'periodoContabil']
  displayedColumnsPlano = ['acaoPlano', 'planoConta']

  formulario: FormGroup
  listRegistro: InformarSaldoContabilInicialModel[]
  listIndividual: PlanoContaContabeisModel[]
  viewCadastro: boolean
  listEmpresa = []
  alteracao = false
  botaoSalvar = false
  mask_mes_ano = "00/0000"
  botaoCancelar = false
  empresaSelect: any
  dataSource
  loading: boolean
  listPlanoDeContas = []
  dataSourcePlano = new MatTableDataSource()
  selectCheck: any

  listItens = []
  desativarCheckbox = false
  codigoEmpresaSelectResult: number
  viewConfirmDelete: boolean
  selectEmpresa = 0

  constructor(
    private form: FormBuilder,
    private token: TokenDeAcessoService,
    private service: InformarSaldoContabilInicialService,
    private conteudo: ConteudoDeDadosService,
    private mensagem: MensagemService,
    private _filter: FiltrarDadosService
  ) { }

  ngOnInit() {
    this.createForm()
    this.getRegistro()
    this.getEmpresa()

  }

  createForm() {
    this.formulario = this.form.group({
      id: '',
      codigoEmpresa: 0,
      dataDeLancamento: '',
      contaContabil: '',
      saldoInicial: 0,
      periodoContabil: '',
      exercicioContabil: '',
      criadoPor: '',
      criadoEm: '',
      status: '',
      ////
      nomeEmpresa: '',
      planoDeConta: ''

    })
  }

  adicionarSaldo() {
    this.alteracao = false
    this.viewCadastro = true
    this.botaoSalvar = true
    this.botaoCancelar = false
    this.codigoEmpresaSelectResult = 0
    this.formulario.enable()
    this.formulario.reset()
    this.desativarCheckbox = false
    this.formulario.controls['status'].setValue('SDA0001')
  }

  async getEmpresa() {
    let empresa = await this.token.getEmpresasToken()
    this.listEmpresa = empresa
  }

  getRegistroPlanoContas(e) {
    this.codigoEmpresaSelectResult = null
    setTimeout(() => {
      this.codigoEmpresaSelectResult = 1
    }, 100)
    this.getPlanoContas(e.codigoEmpresa)
  }

  async getPlanoContas(codigoEmpresa) {
    let registro = this.formulario.value

    codigoEmpresa = registro.codigoEmpresa

    await this.service.listarPlanoDeContas(codigoEmpresa).toPromise()
      .then(registro => {

        this.listPlanoDeContas = registro.filter(item => item.status == 'SDA0001')
        this.listIndividual = this.listPlanoDeContas

        this.dataSourcePlano = new MatTableDataSource(this.listPlanoDeContas)

      })

  }

  // criei listItens p/ testar se array receberá itens do parametro
  // tentar trocar por listPlanoDeContas
  onChangeCheckbox(item, valorDado) {
    if (item.checked) {
      this.selectCheck = item.id
      this.listItens.push(valorDado)
    } else {
      this.selectCheck = null
      this.listItens.filter((value, index) => {
        if (value.id == valorDado.id) {
          this.listItens.splice(index, 1)
        }
      })
    }
    console.log("ONCHANGECHACKBOX VALORDADO: ", this.listItens)
  }


  async getRegistro() {
    this.loading = true

    await this.service.listar().toPromise()
      .then(
        registro => {
          this.listRegistro = registro.filter(item => item.status == 'SDA0001')
          if (this.selectEmpresa) {
            let codigoEmpresa = this.selectEmpresa
            this.listRegistro = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
            this.listRegistro.forEach(async value => {
              let empresa = await this.token.getEmpresasToken(value.codigoEmpresa)
              value.nomeEmpresa = empresa[0].nomeEmpresa
            })

          } else {
            let empresas = JSON.parse(sessao.listEmpresas)
            this.listRegistro = this._filter.filterTodos(this.listRegistro, empresas)
          }
          this.dataSource = new MatTableDataSource(this.listRegistro)
          this.dataSource.sort = this.sort
          this.loading = false
        })
  }

  inserir() {
    let registro = this.formulario.value

    Object.keys(registro).forEach(value => { if (!registro[value]) delete registro[value] })

    registro.criadoPor = sessao.cpf

    console.log('Inserir:', registro)
    this.service.enviar(registro).toPromise()
      .then(
        resposta => {

          this.mensagem.creat('Cadastrado com sucesso', { sucesso: true })
          this.viewCadastro = false
          this.getRegistro()
          this.formulario.disable()
          this.botaoSalvar = false
          this.botaoCancelar = false
        })
      .catch(
        error => {
          let erro = error.error
          console.log(error)
          this.mensagem.creat('Não foi possivel cadastrar', { erro: true })
        })
  }

  alterar() {
    this.loading = true
    let registro = this.formulario.value

    this.service.alterar(registro).toPromise()
      .then(
        res => {
          this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
          this.viewCadastro = true
          this.formulario.disable()
          this.loading = false
        }).catch(
          error => {
            let erro = error.error
            this.mensagem.creat(erro, { erro: true })
          }
        )

    console.log("Alteracao: ", registro)
  }

  async openCadastro(registro) {

    delete registro.planoDeConta

    console.log("OpenCadastro: ", registro)

    this.formulario.controls['codigoEmpresa'].disable()
    this.formulario.controls['periodoContabil'].disable()

    this.formulario.patchValue(registro)
    localStorage.setItem("backup_registro", JSON.stringify(registro))
    this.formulario.disable()
    this.viewCadastro = true
    this.alteracao = true
    this.botaoSalvar = false
    this.botaoCancelar = false
    this.desativarCheckbox = true
    this.getRegistroPlanoContas(registro.codigoEmpresa)
  }

  async cancelarRegistro() {
    let get = localStorage.getItem('backup_registro')
    let registro = JSON.parse(get)
    await this.formulario.setValue(registro)
    this.formulario.disable()
  }

  async excluirRegistro(confirm?) {
    if (confirm) {
      this.viewConfirmDelete = true
      return
    }

    this.viewConfirmDelete = false

    let registro = this.formulario.value

    if (!registro.id) return

    await this.service.desativar(registro, false)
    this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
    this.viewCadastro = false
    this.getRegistro()
  }

  filtrar(value) {
    this.empresaSelect = value
    this.dataSource.filter = value.toLocaleUpperCase().trim()
  }

  filtrarEmpresa(codigoEmpresa) {
    this.loading = true

    this.dataSource.data = this.listRegistro

    if (!codigoEmpresa) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
      return
    }

    let filter = this.dataSource.data.filter(value => value.codigoEmpresa == codigoEmpresa)
    this.dataSource.data = filter
    this.empresaSelect = this.dataSource.data

    setTimeout(() => {
      this.loading = false
    }, 2000)
  }

}
