import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ConciliacaoBancariaModel, FichaControleBancarioCabecalhoModel, FichaControleBancarioItemModel } from "../gestao-simplificada/conciliacao-bancaria/conciliacao-bancaria.model";
import { SessaoUser } from "../user.reg";
import { BuscarDataService } from "./buscar-data.service";

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})

export class ConciliacaoBancariaService {

    url = new environment().api
    api = 'CadastroContaBancaria/'
    apiPeriodo = 'FichaControleBancarioCabecalho/'
    apiExtrato = 'FichaControleBancarioItem/'
    jsonBancos = './assets/json/bancos-de-todo-brasil.json'

    constructor(private http: HttpClient, private data: BuscarDataService) { }

    getBancos(): Observable<any> {
        return this.http.get(this.jsonBancos)
    }

    listarContas() {
        return this.http.get<ConciliacaoBancariaModel[]>(this.url + this.api)
    }

    listarPeriodo() {
        return this.http.get<FichaControleBancarioCabecalhoModel[]>(this.url + this.apiPeriodo)
    }

    listarExtrato() {
        return this.http.get<FichaControleBancarioItemModel[]>(this.url + this.apiExtrato)
    }

    enviarExtrato(registro: FichaControleBancarioItemModel) {
        return this.http.post(this.url + this.apiExtrato, registro)
    }

    enviarPeriodo(registro: FichaControleBancarioCabecalhoModel) {
        return this.http.post(this.url + this.apiPeriodo, registro)
    }

    alterarExtrato(registro: FichaControleBancarioItemModel) {
        // console.log('Alterar extrato: ', this.url + this.apiExtrato + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, JSON.stringify(registro))
        return this.http.put(this.url + this.apiExtrato + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)

    }

    alterarPeriodo(registro: FichaControleBancarioCabecalhoModel) {
        return this.http.put(this.url + this.apiPeriodo + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativarPeriodo(registro: FichaControleBancarioCabecalhoModel, ativo: boolean) {//
        if (ativo) {
            registro.status = "SDO00134"
        } else {
            registro.status = "SDA0002"
        }

        let resposta = false
        await this.alterarPeriodo(registro).toPromise()
            .then(res => { resposta = true })
            .catch(error => { console.log('erro', error) })
        return resposta
    }

    async desativarExtrato(registro: FichaControleBancarioItemModel) {
        registro.status = "SDA0002"

        let resposta
        await this.alterarExtrato(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('erro', error) })
        return resposta
    }

    async conciliacao(registro: FichaControleBancarioCabecalhoModel, encerrar) {
        if (encerrar) {
            registro.status = 'SDO0135'
        }
        else {
            registro.status = 'SDO0134'
        }

        let resposta
        await this.alterarPeriodo(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('erro', error) })
        return resposta
    }

}