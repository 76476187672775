import { Input, OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CadastroPessoaForm } from '../cadastro-paciente/model';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { UsuarioService } from '../service/usuario.service';

@Component({
  selector: 'app-info-paciente',
  templateUrl: './info-paciente.component.html',
  styleUrls: ['./info-paciente.component.scss']
})
export class InfoPacienteComponent implements OnInit, OnChanges {
  @Input('paciente') paciente = new CadastroPessoaForm
  fotoPerfilPaciente: any;
  infoPronturio: any;
  constructor(private _foto: FiltrarDadosService, private _user: UsuarioService) { }

  async ngOnInit() {

  }
  async ngOnChanges() {
    if (this.paciente.prontuario) {
      this.infoPronturio = this.paciente.prontuario
      let infoMedico = await this._user.getRegistro(this.infoPronturio.cpfMedico)
      this.infoPronturio.nomeMedico = infoMedico.nomeCompleto
      // this.infoPronturio.criadoEm = this._foto.convertCriadoEm(this.infoPronturio.criadoEm)
      console.log('---->',this.infoPronturio,this.infoPronturio.criadoEm)
    } else {
      this.infoPronturio = null
    }

    this.fotoPerfilPaciente = await this._foto.inserirFotoPerfilEach('cpf', this.paciente, true)
  }

}
