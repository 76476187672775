import { Injectable } from '@angular/core';
import { ServicoMedico } from './ServicosMedicos.model'
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MensagemService } from '../mensagem.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicosMedicosDisponiveisService {

  url = `${new environment().api}ServicosMedicosDisponiveis`
  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
  ) { }

  getServicosMedicoCodigoEmpresa(codigoempresa: any): Observable<Object> {
    return this.http.get(`${this.url}/FiltrarCodigoEmpresa/${codigoempresa}`)
  }

  AlterarServicosMedico(id: any, dados): Observable<Object> {
    return this.http.put(`${this.url}/${id}`, dados)
  }

  async criarServicosMedico(novoServico: Omit<ServicoMedico, 'codigoServico' | 'timestamp'>) {
    await this.http.post(`${this.url}`, novoServico).toPromise()
      .then(() => this.mensagem.creat(`Servico médico ${novoServico.nomeServico} criado com sucesso`, { sucesso: true }))
      .catch(err => this.mensagem.creat(`Erro ao criar serviço ${novoServico.nomeServico}: ${err}`, { erro: true }))
  }

  async alterarServicosMedico(novoServico: Omit<ServicoMedico, 'timestamp'>) {
    await this.http.put(`${this.url}/${novoServico.codigoServico}`, novoServico).toPromise()
      .then(() => this.mensagem.creat(`Servico médico ${novoServico.nomeServico} alterado com sucesso`, { sucesso: true }))
      .catch(err => this.mensagem.creat(`Erro ao alterar serviço ${novoServico.nomeServico}: ${err}`, { erro: true }))
  }

  async listarServicos(): Promise<ServicoMedico[]> {
    let servicosMedicos: ServicoMedico[];
    await this.http.get(this.url, { observe: 'body' }).toPromise()
      .catch(err => this.mensagem.creat(`Erro ao listar serviços médicos: ${err}`, { erro: true }))
    return servicosMedicos;
  }

  async listarServicosMedicosEmpresa({ codEmpresa }: { codEmpresa: number }): Promise<ServicoMedico[]> {
    let servicosMedicos: ServicoMedico[];
    await this.http.get<ServicoMedico[]>(`${this.url}/FiltrarCodigoEmpresa/${codEmpresa}`, { observe: 'body' }).toPromise()
      .then(res => servicosMedicos = res)
      .catch(err => this.mensagem.creat(`Não foi possível listar os serviços médicos da empresa ${codEmpresa}: ${err}`, { erro: true }))
    return servicosMedicos;
  }

  async removerServicoMedico(id: any) {
    await this.http.delete(`${this.url}/${id}`).toPromise()
      .then(() => this.mensagem.creat(`Serviço médico removido com sucesso`, { sucesso: true }))
      .catch(err => this.mensagem.creat(`Não foi possível remover o serviço médico ${id}: ${err}`, { erro: true }));
  }
}
