import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ChekinPacienteForm } from '../model';
import { formLogAtendimento } from '../../model';
import { SenhaAtendimentoService } from 'src/app/service/senha-atendimento.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import { LogAtendimentoService } from 'src/app/service/log-atendimento.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CountupTimerService } from 'ngx-timer';
import { NgxSpinnerService } from 'ngx-spinner';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { FormUsuario } from 'src/app/usuario/model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkin-paciente-flash',
  templateUrl: './chekin-paciente-flash.component.html',
  styleUrls: ['./chekin-paciente-flash.component.scss']
})
export class ChekinPacienteFlashComponent implements OnDestroy {
  // @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  // @ViewChild('ChamarPaciente', { static: true }) swal: SwalComponent
  // @Input() usuarioId: string
  // @Output() event  = new EventEmitter
  // gerarSenha = false
  // id
  // companyId
  // senha: any
  // form = new ChekinPacienteForm
  // regCheckin: ChekinPacienteForm[];
  // erro: boolean;
  // erroMensagem: string;
  // loading
  // logAtendimento = new formLogAtendimento
  // teste: any;
  // _verificar: any;
  // senhaGerada: boolean;

  // constructor(
  //   private service: SenhaAtendimentoService,
  //   private serviceUsuario: UsuarioService,
  //   private serviceLog: LogAtendimentoService,
  //   private _info: MatSnackBar,
  //   private url: ActivatedRoute,
  //   private time: CountupTimerService,
  //   private spinner: NgxSpinnerService,
  //   private nav: Router,
  //   private data: BuscarDataService,
  //   private mensagem: ToastrService
  // ) {
  //   // this.urlID()
  //   this.time.startTimer()
  //   this.companyId = sessionStorage.getItem('companyId')
  // }

  async ngOnInit() {
    // this.form.prioridade = 3
    // this.form.atendimento = 5
    // this._verificar = setInterval(() => this.verificar(), 100)
    // this._verificar
    // this.buscarRegistro(this.companyId)
    // if (this.usuarioId) {
    //   this.id = this.usuarioId
    // }
    // this.spinner.show();
    // this.erro = true


  }
  ngOnDestroy() {
    // clearInterval(this._verificar)
  }
  // async abrirRecepcao(senha: string, dados) {
  //   console.log('teste :' + JSON.stringify(dados))
  //   console.log('Senha : ' + this.senha)
  //   await localStorage.setItem('senha', senha)
  //   setTimeout(async () => {
  //     await this.nav.navigateByUrl(`home/recepcao/${this.id}`).then(
  //       () => this.registrarLogAtendimento()
  //     )
  //   }, 1000)
  //   this.removerSenha(dados.id, dados)

  // }

  // registrarLogAtendimento() {
  //   this.logAtendimento.usuarioId = this.id
  //   this.logAtendimento.companyId = this.companyId
  //   this.logAtendimento.data = this.data.hoje()
  //   this.logAtendimento.senha = this.senha
  //   this.logAtendimento.timeCheckin = this.data.horaAgora()
  //   this.serviceLog.cadastrarlogAtendimento(this.logAtendimento).toPromise()
  //     .then(
  //       () => { this.close() }
  //     )
  // }
  // removerSenha(id, dados) {
  //   dados.ativo = false
  //   this.service.alterarCheckinPaciente(id, dados).toPromise()
  //     .then(
  //       () => console.log('removido com sucesso!')
  //     ).catch(
  //       () => console.log('ERRO!!')
  //     )
  // }
  // verificar() {
  //   if (this.form.motivo >= 5) {
  //     this.form.prioridade = 1
  //     return
  //   }
  //   if (this.form.atendimento < 5) {
  //     if (this.form.atendimento != 0) this.form.prioridade = 2
  //     return
  //   }
  //   if (this.form.atendimento == 5) {
  //     this.form.prioridade = 3
  //   }

  // }

  // urlID() {
  //   this.url.url.subscribe(async reg => {
  //     console.log('Filtro :' + reg[1].path)
  //     this.id = reg[1].path
  //   });
  // }
  // reset() {
  //   this.buscarRegistro(this.companyId)
  //   this.form = new ChekinPacienteForm
  //   this.gerarSenha = false
  // }
  // async buscarId() {
  //   if (this.companyId) return
  //   await this.serviceUsuario.listar().toPromise()
  //     .then(
  //       async (dados: Array<FormUsuario>) => {
  //         console.log('SEU ID : ' + this.id)
  //         let id = this.id
  //         let filtro = await dados.filter(function (reg: FormUsuario) {
  //           return reg.usuarioId == id
  //         })
  //         let reg = filtro[0]
  //         this.companyId = reg.companyId
  //         console.log('Company ID @ : ' + this.companyId)
  //         this.erro = false
  //       }
  //     )
  //     .catch(
  //       () => {
  //         this.erro = true
  //         this.erroMensagem = "Não foi possível localizar registro"
  //       }
  //     )
  // }
  // async buscarRegistro(companyId) {

  //   // console.log('companyID : ' + companyId)
  //   if (!this.companyId) this.buscarId()
  //   else this.erro = false
  //   this.service.listarCheckinPaciente().toPromise()
  //     .then(
  //       async (dados: ChekinPacienteForm[]) => {
  //         let filtro = await dados
  //           .filter(function (reg: ChekinPacienteForm) {
  //             return reg.companyId == companyId && reg.ativo == true
  //           })
  //         if (filtro.length < 1) {
  //           return
  //         }
  //         let orderna = await filtro.sort(function (a: ChekinPacienteForm, b: ChekinPacienteForm) {
  //           return a.senha - b.senha
  //           // return a.senha - b.senha && parseInt(a.hora) - parseInt(b.hora)
  //         })
  //         this.regCheckin = orderna
  //         console.log(this.regCheckin)
  //         this.senha = this.regCheckin.slice().length
  //         // console.log('Registro : ' + companyId)

  //       }
  //     )
  // }
  // async cadastrar() {
  //   this.loading = true
  //   if (this.regCheckin) {
  //     let i = this.regCheckin.length - 1
  //     this.form.senha = parseInt(this.regCheckin[i].senha ? this.regCheckin[i].senha : "0") + 1
  //   }
  //   else {
  //     this.form.senha = 1
  //   }

  //   this.form.prioridade = this.form.atendimento >= 4 ? 1 : 0
  //   this.form.usuarioId = this.id
  //   this.form.companyId = this.companyId
  //   let reg = JSON.stringify(this.form)
  //   await this.service.cadastrarCheckinPaciente(JSON.parse(reg)).toPromise()
  //     .then(
  //       (reg) => {
  //         this.gerarSenha = true
  //         this.close()
  //       }
  //     ).catch(
  //       () => this.mensagem.error('Algo deu errado')
  //     )
  //   this.loading = false
  // }
  // info() {
  //   this._info.open('Salvo com sucesso', '', {
  //     duration: 2000
  //   })
  // }
  // close(){
  //   this.event.emit(false)
  // }

}