import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService, UsuarioDependenteService, UsuarioQuadroDeSaudeService } from '../service/usuario.service';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FormUsuario, FormDependente, FormHabitos } from './model';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { async } from '@angular/core/testing';
import { FormulariosService } from '../service/formularios.service';
import { SwalPortalTargets, SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DatePipe, formatDate } from '@angular/common';
import { RecuperarSenhaService } from '../service/recuperar-senha.service';


@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  @ViewChild('abrirCadastro', { static: true }) private abrirCadastro: SwalComponent;
  @ViewChild('tempHistoricoDoenca', { static: true }) private addHistoricoDoenca: SwalComponent;
  @ViewChild('tempHistoricoInternacao', { static: true }) private addHistoricoInternacao: SwalComponent;
  @ViewChild('excluirHistoricoInternacao', { static: true }) private excluirHistoricoInternacao: SwalComponent;
  @ViewChild('EditarFoto', { static: true }) private EditarFoto: SwalComponent;
  @ViewChild('loading', { static: true }) loading
  public abrirCadastroDependente = false
  id: string
  idCompany: string
  imgPerfil = './assets/imgUser.png'
  dadosUsuario = new FormUsuario
  dadosDependente: FormDependente[] = []
  //Registros
  registroDependente: any = {}
  registroQuadroSaude: any = {}

  //Estruturas de Formulario
  estruturaFormulario: any = []
  estruturaFormularioDependente = []
  Registro: Boolean;
  ngFormulario = new FormUsuario
  Formulario: FormGroup
  FormularioDependente: FormGroup
  erro: boolean;
  erroMensagem: string
  fotoPerfil = this.dadosUsuario['foto'] ? this.dadosUsuario['foto'] : this.imgPerfil
  dadosSaude: boolean;
  newSenha : string
  confirmSenha : string
  constructor(
    private service: UsuarioService,
    private serviceDependente: UsuarioDependenteService,
    private serviceQuadroDeSaude: UsuarioQuadroDeSaudeService,
    private form: FormBuilder,
    private url: ActivatedRoute,
    private mensagem: ToastrService,
    private formularios: FormulariosService,
    private datePipe: DatePipe,
    private serviceSenha : RecuperarSenhaService) {
    this.estruturaFormulario = formularios.formularioUsuario()
    this.url.url.subscribe(async ok => {
      // console.log('Filtro :' + ok[1].path)
      this.id = ok[1].path;
    });

  }

  async ngOnInit() {
    this.id = sessionStorage.getItem('tokken')
    this.loading = true
    await this.buscarUsuario().then(
      async () => {
        this.loading = false
        await this.createFormularioUsuario()
        this.Registro = true
        await this.buscarDependentes()
      }
    ).catch(
      () => {
        this.mensagem.error('Erro!')
        this.Registro = false
        this.loading = false
      }
    )




  }
  teste(dado: any) {
    // console.log(JSON.stringify(dado))
  }
  async  adicionarImagemPerfil(img) {
    this.dadosUsuario['foto'] = img[0].base64
    await this.EditarFoto.ngOnDestroy()
    setTimeout(() => this.EditarFoto.fire(), 500)
  }
  DeleteImg($event) {
    // console.log(JSON.stringify($event))
  }

  ////////////////////////////////////USUARIO///////////////////////////  
  async buscarUsuario() {
    this.loading = true
    await this.service.listar().toPromise().then(

      async (dados: Array<FormUsuario>) => {
        const usuarioId = this.id.toUpperCase()
        let filtro = dados.filter(function (reg: FormUsuario) {
          console.log(reg.usuarioId + ' --- ' + usuarioId)
          return reg.usuarioId.toUpperCase() == usuarioId
        })
        if (!filtro.length) {
          this.dadosUsuario = new FormUsuario
          this.mensagem.error('Usuario não encontrado!'),
            this.erro = true
          this.erroMensagem = "USUARIO NÃO ENCONTRADO"
          return;
        }
        this.dadosUsuario = await filtro[0];
        // console.log(JSON.stringify(this.dadosUsuario))
        this.dadosUsuario.nascimento = this.datePipe.transform(this.dadosUsuario.nascimento, 'dd-MM-yyyy')
        // console.log('idCompany : ' + this.dadosUsuario.companyId)
        this.idCompany = this.dadosUsuario.companyId
        // console.log('$$$$$$$$$$ : ')

        if (!dados) {
          alert('Erro')
          this.mensagem.error('Registro não encontrado!'),
            this.erro = true
          this.erroMensagem = "Não foi possível localizar registro"
        }
        this.loading = false
      }
    ).catch(
      erro => {
        this.loading = false
      }
    )
  }
  async buscarCompanyId() {
    this.service.listar(this.dadosUsuario.id).toPromise().then(
      async (id: FormUsuario) => {
        this.idCompany = id.companyId
      })

  }

  async alterarUsuario() {
    this.loading = true
    let dia = this.dadosUsuario.nascimento.substr(0, 2)
    let mes = this.dadosUsuario.nascimento.substr(3, 2)
    let ano = this.dadosUsuario.nascimento.substr(6, 4)
    let nascimento = `${ano}-${mes}-${dia}`
    this.dadosUsuario.nascimento = nascimento

    try {
      await this.service.alterar(this.dadosUsuario.id, this.dadosUsuario).subscribe(
        async (ok: FormUsuario) => {
          this.dadosUsuario.nascimento = this.datePipe.transform(this.dadosUsuario.nascimento, 'dd-MM-yyyy')
          await this.mensagem.success('Alterado com sucesso')
          // await this.buscarUsuario()
          this.loading = false
        },
        erro => this.mensagem.error('Erro ao realizar alteração!')
      )
    } catch{
      this.mensagem.error('Erro desconhecido!')
    }


  }




  /////////////////////////DEPENDENTES////////////////////////////////////////////////////

  async buscarDependentes() {
    this.serviceDependente.listarDependente().toPromise().then(
      async (dados: FormDependente[]) => {
        let usuarioId = await this.id

        if (usuarioId) {
          this.dadosDependente = await dados.filter(function (data) {
            // console.log('ID :' + data.usuarioId == idUsuario)

            //// console.log(data)
            return data.usuarioId == usuarioId && data.tipo == "Dependente"
          })
          // console.log(this.dadosDependente)
        }
      }
    ).catch(
      erro => this.mensagem.warning('Lista de Dependentes não encontrada')
    )
  }

  async cadastrarDependente() {

    this.loading = true
    if (this.registroDependente.id) {
      this.alterarDependente(this.registroDependente.id)
    }
    else {
      this.serviceDependente.cadastrarDependente(this.FormularioDependente.value).subscribe(
        async (ok: FormDependente) => {
          await this.abrirCadastro.ngOnDestroy()
          this.mensagem.success('Usuario ' + this.registroDependente.nome + ' Alterado com sucesso')
          this.loading = false
          await this.buscarDependentes()
        },
        erro => {
          this.mensagem.error('Não foi possível alterar usuario')
          this.loading = false
        }
      )
    }
  }
  async alterarDependente(id) {
    this.loading = true
    var registro = this.FormularioDependente.value
    Object.assign(registro, { id: id })

    this.serviceDependente.alterarDependente(id, registro).subscribe(
      async ok => {
        await this.abrirCadastro.ngOnDestroy()
        this.mensagem.success('Usuario ' + this.registroDependente.nome + ' Alterado com sucesso')
        this.loading = false
        await this.buscarDependentes()
      },
      erro => {
        this.mensagem.error('Não foi possível alterar usuario')
        this.loading = false
        // console.log('Erro!' + JSON.stringify(erro))
      }
    )
  }
  async excluirDependente(id) {
    this.loading = true
    this.serviceDependente.excluirDependente(id).toPromise().then(
      async ok => {
        await this.abrirCadastro.ngOnDestroy()
        this.mensagem.success('Usuario Excluido')
        this.loading = false
        await this.buscarDependentes()
      },
      erro => {
        this.mensagem.error('Não foi possível excluir dependente')
        this.loading = false
        // console.log('Erro!' + JSON.stringify(erro))
      }
    )
  }
  confirmDependente(event) {
    if (event) {
      this.abrirCadastroDependente = false
      this.buscarDependentes()
    }
  }
  recuperarSenha(){
    const senha = this.newSenha
    const confSenha = this.confirmSenha
    const email = this.Formulario.controls['cpf'].value
    if(senha != confSenha){this.mensagem.error('Senha divergente');return}
    
    this.serviceSenha.resetSenha(email,senha).toPromise().then(
      ()=>{
        this.mensagem.success('Senha alterada com sucesso')
      }
    ).catch(
      (res)=>{
        console.log(res)
        this.mensagem.success('Senha alterada com sucesso')
      }
    )
  }
  async createFormularioUsuario() {
    this.Formulario = this.form.group({
      id: '',
      usuarioId: '',
      companyId: '',
      nome: new FormControl('', Validators.required),
      cpf: new FormControl('', Validators.required),
      rg: new FormControl('', [Validators.required, Validators.minLength(9)]),
      nascimento: new FormControl('', Validators.required),
      genero: new FormControl('', Validators.required),
      profissao: new FormControl('', Validators.required),
      civil: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      telefoneCel: new FormControl(''),
      telefoneRes: new FormControl(''),
      telefoneCom: new FormControl(''),
      ramal: new FormControl(''),
      logradouro: new FormControl(''),
      numero: new FormControl(''),
      complemento: new FormControl(''),
      cep: new FormControl(''),
      bairro: new FormControl(''),
      cidade: new FormControl(''),
      estado: new FormControl(''),
      cartaoBandeira: new FormControl(''),
      cartaoNumero: new FormControl(''),
      vencimento: new FormControl(''),
      seguranca: new FormControl(''),
      celular: new FormControl(''),
      telefone: new FormControl(''),
      cor: new FormControl(''),
      especial: new FormControl(false),
      localNascimento: new FormControl(''),
      codnome: new FormControl(''),
      userType: new FormControl(''),
      pago: new FormControl(''),
      assinante: new FormControl(''),
      status: new FormControl(''),
      createedAt: new FormControl(''),
      data_inicio: new FormControl(''),
      data_expiracao: new FormControl(''),
      ativo: new FormControl('')
    })
  }

}

