export class DicionarioPortal {
    id: string
    fotoLink: string
    titulo: string
    subtitulo: string
    tipoConteudo: string
    link: string
    criadoPor: string
    criadoEm: string
    status: 'SDA0001' | 'SDA0002'
    descTipoConteudo?: string
}