export class CadastroOperadoraCartao {
    id: string
    criadoPor: string
    timestamp: string
    status: string
    banco: string
    agencia: string
    contaCorrente: string
    operadora: string
    bandeira: string
    taxaDebito: number
    prazoDebito: number
    taxaCreditoAvista: number
    prazoCreditoAvista: number
    taxaCreditoAprazo: number
    prazoCreditoAprazo: number
    codigoContaBancaria: number
    codigoEmpresa: number
    descBandeira?: string
    descOperadora?: string
    operadoraDesc?: string
    //
    nomeEmpresa: string

}