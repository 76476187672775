import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PlanoContaContabeisModel, SaldoContaContabil } from 'src/app/plano-conta-contabeis/plano-contato-contabeis.model';
import { SessaoUser } from 'src/app/user.reg';


const url = `${new environment().api}PlanoContaContabeis/`

const user = new SessaoUser

let objeto = new PlanoContaContabeisModel



@Injectable({
  providedIn: 'root'
})


export class CopiarRegistrosService {

  
constructor(private http: HttpClient) { }
  
CopiarRegistros(deCodigoEmpresa, paraCodigoEmpresa, tabela)
{
  return this.http.post(`${new environment().api}CopiarRegistros/${deCodigoEmpresa}/${paraCodigoEmpresa}/${user.cpf}/${tabela}`,objeto,{reportProgress: true, observe:'events'})
}

}