export class ConsultorioMedico {
    codConsultorio: string
    criadoPor: string
    codigo: number
    codigoEmpresa: number
    identificacaoConsultorio: string
    status: string
    timeStamp: string
    cpfMedico: string
    alocadoDesde: string
    alocadoAte: string
    atendimento?: boolean
    nomePaciente?: string
    cpfPaciente?: string
    formaDeTratamento?: string
    unidadeFederacao? : string
}

export class dadosAreaAtuacao {
    itemAreaAtuacao?: number;
    codigoEmpresa?: number;
    areaDeAtuacaoAns?: string;
    cpfAutor?: string;
    status?: string;
    timeStamp?: string;
}

