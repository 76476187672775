import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { PacienteService } from '../service/paciente.service';
import { CadastroPessoaForm, FormPaciente } from '../cadastro-paciente/model';
import { Sort } from '@angular/material/sort';
import { NgControlStatus } from '@angular/forms';
import { REQUEST } from '../service/modal';
import { FiltrarDadosService } from '../service/filtrar-dados.service';

@Component({
  selector: 'app-meu-consultorio',
  templateUrl: './meu-consultorio.component.html',
  styleUrls: ['./meu-consultorio.component.scss']
})
export class MeuConsultorioComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns: string[] = ['select', 'nProntuario', 'fotoPerfil', 'nomeCompleto', 'cpf', 'endereco', 'estado', 'ultimaConsulta', 'indicadorSaude'];
  dataSource: any
  registro: FormPaciente[]
  sortedData: FormPaciente[]
  sortCPF: Sort
  value = true
  indexChecked
  loading
  sessionActive = 'meusPacientes'

  selectPaciente = new CadastroPessoaForm;
  viewPaciente: boolean;
  viewAtestado : boolean

  constructor(private servePaciente: PacienteService, private _filter: FiltrarDadosService) { }

  async ngOnInit() {
    this.loading = true
    await this.buscarPaciente()
    this.loading = false
  }

  filtrar(ref) {
    const sortState: Sort = { active: '', direction: '' };

    if (ref == 1) sortState.active = 'nome'
    if (ref == 2) sortState.active = 'ultimaConsulta'
    if (ref == 3) sortState.active = 'indicadorSaude'

    console.log(this.sort.direction)
    switch (this.sort.direction) {
      case 'desc':
        this.sort.direction = 'asc'
        console.log(sortState.direction)
        break;
      case 'asc':
        sortState.direction = 'desc'
        console.log(sortState.direction)
        break
      default:
        sortState.direction = 'asc'
        console.log(sortState.direction)
        break
    }
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }
  teste(value, indexChecked) {
    this.value = value
    this.indexChecked = indexChecked
    console.log(value + ' - ' + indexChecked)
  }
  openPaciente(paciente: CadastroPessoaForm) {
    console.log(paciente)
    this.selectPaciente = paciente
    this.viewPaciente = true
  }

  filtro(reg: string) {
    this.dataSource.filter = reg.trim().toLowerCase();
  }

  async buscarPaciente() {
    await this.servePaciente.listarPaciente().toPromise()
      .then(
        async dados => {
          if (dados.success) {
            this.registro = dados.result as any
            this._filter.inserirFotoPerfil('cpf', this.registro)
            console.log('buscarPaciente',this.registro)
            this.dataSource = await new MatTableDataSource<FormPaciente>(this.registro)
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort
          }

        }
      )
  }
}

export interface PeriodicElement {
  name: string;
  weight: number;
  symbol: string;
}

