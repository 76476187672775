import { Component, OnInit, ViewChild } from '@angular/core';
import { InteracaoMedicamentosaService, InteracoesMedicamentosas } from '../service/interacao-medicamentosa.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MensagemService } from '../service/mensagem.service';

@Component({
  selector: 'app-interacao-medicamentosa',
  templateUrl: './interacao-medicamentosa.component.html',
  styleUrls: ['./interacao-medicamentosa.component.scss']
})
export class InteracaoMedicamentosaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  displayedColumns: string[] = ['detalhe','id', 'medicamento', 'interageCom'];
  dataSource: MatTableDataSource<InteracoesMedicamentosas>;
  parametro1 = new InteracoesMedicamentosas
  parametro2 = new InteracoesMedicamentosas
  temp: InteracoesMedicamentosas[];
  limpar: boolean;
  consultar: boolean = false;
  medicamentos: string[];
  filteredOptions: Observable<InteracoesMedicamentosas[]>;
  filteredOptions2: Observable<InteracoesMedicamentosas[]>;
  result = []
  loading: boolean
  backup = [];
  openDetalhe: boolean;
  detalhe: InteracoesMedicamentosas;
  getValues: string[];
  temp_backup: InteracoesMedicamentosas[];
  constructor(private service: InteracaoMedicamentosaService, private _mensagem: MensagemService) {
  }
  async ngOnInit() {
    await this.listar().finally(
      () => {
        this.filteredOptions = this.myControl1.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.backup.slice())
          );

        this.filteredOptions2 = this.myControl2.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter2(name) : this.backup.slice())
          );
      }
    );

  }

  async listar(pesquisar?) {
    this.loading = true
    await this.service.listar().toPromise()
      .then(
        (dados) => {
          let filter = []

          dados.forEach(value => {

            let duplicat = filter.findIndex(item => value.medicamento == item.medicamento)
            if (duplicat < 0) {
              filter.push(value)
            }
          })
          this.loading = false
          this.dataSource = new MatTableDataSource(dados)
          this.dataSource.paginator = this.paginator
          this.temp = dados
          this.temp_backup = dados
          this.backup = filter
          this.medicamentos = this.temp.map(x => x.medicamento)
          if (pesquisar) {
            this.pesquisar()
          }
        }
      )
  }
  viewDetalhe(registro: InteracoesMedicamentosas) {
    this.openDetalhe = true
    registro.resultado = registro.resultado.replace('Importância clínica:', '##Importância clínica:')
    registro.resultado = registro.resultado.replace(' Gravidade da interação:', '##Gravidade da interação:')
    registro.resultado = registro.resultado.replace('Início da interação:', '##Início da interação:')
    registro.resultado = registro.resultado.replace('Probabilidade de ocorrência', '##Probabilidade de ocorrência:')
    registro.resultado = registro.resultado.replace('Efeito:', '##Efeito:')
    registro.resultado = registro.resultado.replace('Mecanismo:', '##Mecanismo:')
    registro.resultado = registro.resultado.replace('Sugestão de conduta:', '##Sugestão de conduta:')

    let getValues = registro.resultado.split('##')
    this.parametro1.medicamento = registro.medicamento
    this.parametro2.interageCom = registro.interageCom
    getValues.shift()
    this.getValues = getValues
  } 
  clear() {
    this.limpar = false
    this.parametro1 = new InteracoesMedicamentosas
    this.parametro2 = new InteracoesMedicamentosas
  }
  fechar() {
    this.openDetalhe = false
    this.parametro1 = new InteracoesMedicamentosas
    this.parametro2 = new InteracoesMedicamentosas
  }
  pesquisar(reset?) {
    if (reset) {
      this.temp = []
      this.temp = this.temp_backup
    }
    this.limpar = true
    let medicamento = this.parametro1.medicamento
    let interage = this.parametro2.interageCom
    this.result = this.temp.filter(value => {
      return value.medicamento == medicamento && value.interageCom == interage ||
        value.interageCom == interage && value.medicamento == medicamento
    })
    if (this.result.length > 0) {
      this.viewDetalhe(this.result[0])
      this.consultar = false
    } else {
      this.limpar = false
      this._mensagem.creat('Combinação não obteve registro', { erro: true })
      return
    }
  }

  displayFn(user: InteracoesMedicamentosas): string {
    return user && user.medicamento ? user.medicamento : '';
  }

  displayFn2(user: InteracoesMedicamentosas): string {
    return user && user.interageCom ? user.interageCom : '';
  }

  private _filter(name: string): InteracoesMedicamentosas[] {
    const filterValue = name.toLowerCase();
    return this.dataSource.data.filter(option => option.medicamento.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filter2(name: string): InteracoesMedicamentosas[] {
    const filterValue = name.toLowerCase();
    return this.dataSource.data.filter(option => option.interageCom.toLowerCase().indexOf(filterValue) === 0);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
