export class CadastroFuncionarioModel {
    id: string
    codigoEmpresa: number
    cpf: string
    nrRegistro: number
    nrFuncao: number
    funcao: string
    departamento: string
    cpfResponsavel: string
    categoriaContratoTrabalho: string
    tipoSalario: string
    dataExperiencia: string
    dataAdmissao: string
    dataProrrogacao: string
    dataRescisao: string
    motivoDemissao: string
    salarioInicial: number
    salarioAtual: number
    pais: string
    banco: string
    agencia: string
    contaBancaria: string
    centroCustoResp: number
    centroLucroResp: number
    observacao: string

    atribuicaoResponsavelDesde: string
    atribuicaoResponsavelAte: string
    ativoDesde: string
    inativoDesde: string
    criadoPor: string
    criadoEm: string
    status: string
    avisoPrevio: string
    saldoFgts: number
    recolheuFgts: number

    //
    nomeEmpresa: string
    descNome: string
    nomeCoordenador: string
    cpfResponsavelLucro: string
}