export class formIndicacaoTratamento {
    id: string
    usuarioId: string
    companyId: string
    pacienteId: string
    crmAtuante: string
    data: any
    tratamento: string
    descricao: string
    frequencia: string
    duracao: string
}
export class formSolicitacaoExames {
    id: string
    usuarioId: string
    companyId: string
    pacienteId: string
    data: any
    nome: string
    descricaoExame: string
    CIDs: string
}
export class ReceituarioDoPaciente {
    id: string
    criadoPor: string
    dataHoraInclusaoDeTabela: string
    cpfPaciente: string
    nomePaciente: string
    observacao: string
    codigoUnico: string
    codigoMedicamento: string
    frequencia: string
    dosagem: string
    periodo: string
    crmMedico: string
    via: string
    status: string
    principioAtivo: string
    nomeComercial: string
    codigoDeMedicamento: string
    codigoDeBulaDeRemedio: string
    item?:number
}
export class formReceituario{
    id: string
    pacienteId: string
    usuarioId: string
    companyId: string
    data: string
    nomeComercial: string
    dosagem: number
    tipoDosagem: string
    via: string
    formaAdministracao: string
    frequencia: number
    tipoFrequencia: string
    periodo: number
    tipoPeriodo: string
    principioAtivo: string
    observacao: string
    usoContinuo: string
    uf: string
    reTipo: string
    numRecibo: string
    createdAt: string
    ativo: boolean
}
export class formProcedimentoAdicional {
    id: string
    data: string
    usuarioId: string
    companyId: string
    pacienteId: string
    procedimentoAdicional: string
    cobrar: true
    quantidade: string
}
export class formCirurgias {
    id: string
    usuarioId: string
    companyId: string
    pacienteId: string
    data: any
    tipoCirurgia: string
    motivo: string
    resultado: string
}