import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import {
  FichaDeAutorizacaoDeAcesso, ItemPerfil, MenuMaster,
  PerfilDeAcessoDeUsuarioCAB,
  PerfilDeAcessoDeUsuarioITEM,
  Perfis,
  TipoPerfilDeAcesso
} from "../ajustes/perfil-de-acesso-usuario/model";
import { take } from "rxjs/operators";
import { FiltrarDadosService } from './filtrar-dados.service';
import { SessaoUser } from '../user.reg';

const sessao = new SessaoUser
@Injectable({
  providedIn: 'root'
})
export class PerfilAcessoUsuarioService {
  url = new environment().api;
  apiMenuMaster = 'MenuMaster/';
  apiPerfilCabecalho = 'PerfilDeAcessoCabecalho/';
  apiPerfilItem = 'PerfilDeAcessoItem/';

  constructor(private http: HttpClient, private _filter: FiltrarDadosService) { }

  async listarPerfilCabecalho(codigoEmpresa) {
    let result: PerfilDeAcessoDeUsuarioCAB[] = []
    await this.http.get<PerfilDeAcessoDeUsuarioCAB[]>(this.url + this.apiPerfilCabecalho).toPromise()
      .then(res => {
        let empresas = JSON.parse(sessao.listEmpresas)
        if (codigoEmpresa > 0) {
          result = res.filter(value => value.codigoEmpresa == codigoEmpresa && value.status == "SDA0001")
          empresas.forEach(emp => {
            result.filter(value => {
              if (emp.empresa == value.codigoEmpresa) {
                value.nomeEmpresa = emp.nomeEmpresa
              }
            })
          });
        } else {
          result = this._filter.filterTodos(res)
        }
      })
    return result
  }
  async resetRegistro(id) {
    let result = new PerfilDeAcessoDeUsuarioCAB
    await this.http.get<PerfilDeAcessoDeUsuarioCAB>(this.url + this.apiPerfilCabecalho + id).toPromise()
      .then(res => {
        result = res
      })
    return result
  }

  async listarPerfilItem(codigoEmpresa, codigoPerfil) {
    let result: any[] = []
    await this.http.get<any[]>(this.url + this.apiPerfilItem).toPromise()
      .then(res => {
        result = res.filter(value => value.codigoPerfil == codigoPerfil && value.codigoEmpresa == codigoEmpresa && value.status == "SDA0001")
      })
    return result
  }

  cadastrarPerfilCabecalho(registro: PerfilDeAcessoDeUsuarioCAB) {
    registro.criadoPor = sessao.cpf
    return this.http.post(this.url + this.apiPerfilCabecalho, registro)
  }

  async cadastrarPerfilItem(dados: PerfilDeAcessoDeUsuarioITEM) {
    let result = false
    dados.status = "SDA0001"
    dados.criadoPor = sessao.cpf
    await this.http.post(this.url + this.apiPerfilItem, dados).toPromise()
      .then(res => { result = true })
      .catch(error => console.log('erro item', error))
    return result
  }

  async getMenuMaster(tipoObjeto?) {
    let result: MenuMaster[] = []
    await this.http.get<MenuMaster[]>(this.url + this.apiMenuMaster).toPromise()
      .then(res => {
        if (tipoObjeto) {
          result = res.filter(value => value.tipoObjeto == tipoObjeto)
        } else {
          result = res
        }
        if (result.length > 0) {
          result = result.sort((a, b) => {
            try {
              return (a.tipoObjeto.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '') >
                b.tipoObjeto.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) ? 1 :
                (b.tipoObjeto.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '') >
                  a.tipoObjeto.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) ? -1 : 0;
            } catch {
              return -1
            }

          });
        }
      });
    return result
  }

  alterarCAB(registro) {
    return this.http.put(this.url + this.apiPerfilCabecalho + `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro)
  }
  async alterarITEM(registro: PerfilDeAcessoDeUsuarioITEM) {
    let result = false
    await this.http.put(this.url + this.apiPerfilItem + `${registro.id}/${sessao.sessao_id}/${new Date().toISOString()}`, registro).toPromise()
      .then(res => result = true)
  }
  async desativar(registro: PerfilDeAcessoDeUsuarioCAB, ativo) {

  }
}
