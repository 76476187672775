export class Documento{
    id?: String
    codigoDocumento: String
    descricao: String
    prefixo: number
    aplicacao?: String
    processo?: String
    transacao?: String
    tabela?: String
    tipoDado: String
    registroModificacao: Boolean
    compartilhamentoDeDados: Boolean
    tipoRegistro?: String
    cabecalho?: String
    especificoEmpresa: boolean
    item?:string | string[]
    registroUnico?:String 
    controleDeAprovacao : boolean
}