import { Component, OnInit, ViewChild } from '@angular/core';
import { LivroRazaoService } from '../service/livro-razao.service';
import { MensagemService } from '../service/mensagem.service';
import { LivroRazaoModels } from './modelsLivroRazao';
import { SessaoUser } from '../user.reg';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenDeAcessoService } from '../service/token-de-acesso.service';
import { ConteudoDeDadosService } from '../service/conteudo-de-dados.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FiltrarDadosService } from '../service/filtrar-dados.service';
import { PlanoContaContabeisModel } from '../plano-conta-contabeis/plano-contato-contabeis.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CopiarRegistrosService } from '../service/CopiarRegistros.service';

const sessao = new SessaoUser

@Component({
	selector: 'app-livro-razao',
	templateUrl: './livro-razao.component.html',
	styleUrls: ['./livro-razao.component.scss']
})

export class LivroRazaoComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort

	displayedColumns = ['acao', 'empresa', 'modeloDeLancamento', 'areaNegocio', 'processo']
	formulario: FormGroup
	viewCadastro = false
	listRegistro: LivroRazaoModels[]
	listEmpresa = []
	listAreaNegocio = []
	listProcesso = []
	listDebito = []
	listCredito = []
	alteracao: boolean = false
	dataSource = new MatTableDataSource()
	loading: boolean;
	contRegistro: number;
	totalContRegistro: number;
	botaoSalvar = false
	botaoCancelar = false
	viewConfirmDelete: boolean
	selectEmpresa = sessao.codigoEmpresa
	listCredito_backup: PlanoContaContabeisModel[] = []
	listDebito_backup: PlanoContaContabeisModel[] = []
	viewConfirmCancelar: boolean
	modoCriacao: boolean
	modoAlteracao: boolean
	modoExibicao: boolean

	viewCopia = false
	formCopy: FormGroup
	porcentagem: number;
	uploadSuccess: boolean;

	constructor(
		private form: FormBuilder,
		private token: TokenDeAcessoService,
		private conteudo: ConteudoDeDadosService,
		private service: LivroRazaoService,
		private mensagem: MensagemService,
		private _filtro: FiltrarDadosService,
		private copyService: CopiarRegistrosService
	) { }

	async ngOnInit() {
		this.createForm()
		this.getEmpresa()
		this.getAreaNegocio()
		this.getProcesso()
		this.getRegistro(this.selectEmpresa)
	}

	CreateCopyForm(){
		this.formCopy = this.form.group({
		  deCodigoEmpresa: '',
		  paraCodigoEmpresa: ''
		})
	  }
	
	  OpenCopyView()
	  {
		this.viewCopia = true
		this.getEmpresa()
	
		this.CreateCopyForm()
	  }
	
	
	 CopiarRegistros()
	  {
		let form  = this.formCopy.value;
		
	   this.copyService.CopiarRegistros(form.deCodigoEmpresa, form.paraCodigoEmpresa,'LivroRazao').subscribe(
		event => {
		  if (event.type === HttpEventType.UploadProgress) {
			this.porcentagem = Math.round(100 * event.loaded / event.total);
	
		  } else if (event instanceof HttpResponse) {
			this.uploadSuccess = true;
			setTimeout(() => {
			  this.uploadSuccess = false
			  this.porcentagem = 0
			  this.fecharCadastro()
			}, 3000)
	
			this.mensagem.creat('Registros salvos com Sucesso',{sucesso:true})
			
		   // console.log('Response :', event.body[0])
		  }
		},
		error => {
		  console.log('ERRO!', error)
		}
	  );
	
	  }

	createForm() {
		this.formulario = this.form.group({
			id: '',
			codigoLancamento: 0,
			criadoPor: '',
			codigoEmpresa: 0,
			contaDebito: '',
			contaCredito: '',
			areaNegocio: '',
			descricaoProcesso: '',
			status: '',
			criadoEm: ''
		})
	}

	adicionarProcesso() {
		this.alteracao = false
		this.viewCadastro = true
		this.botaoSalvar = true
		this.botaoCancelar = false
		this.modoCriacao = true
		this.modoAlteracao = false
		this.modoExibicao = false
		this.formulario.enable()
		this.formulario.reset()
		this.formulario.controls['status'].setValue('SCA0001')
	}

	async getEmpresa() {
		let empresa = await this.token.getEmpresasToken()
		this.listEmpresa = empresa
	}

	async getAreaNegocio() {
		let areaNegocio = await this.conteudo.get('AREANE')
		this.listAreaNegocio = areaNegocio
	}

	async getProcesso() {
		let processo = await this.conteudo.get('ATIVID')
		this.listProcesso = processo.sort((a, b) => { return a.valor < b.valor ? -1 : a.valor > b.valor ? 1 : 0 })
	}

	async getContaDebito(codigoEmpresa) {

		await this.service.listarContas(codigoEmpresa).toPromise()
			.then((response: any) => {
				response = response.sort((a, b) => { return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0 })
				this.listDebito = response
				this.listDebito_backup = response
			})
	}

	async getContaCredito(codigoEmpresa) {
		// let credito = await this.conteudo.get('CCNATU')
		// this.listCredito = credito

		await this.service.listarContas(codigoEmpresa).toPromise()
			.then((response: any) => {
				response = response.sort((a, b) => { return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0 })
				this.listCredito = response
				this.listCredito_backup = response
				console.log("List credito", this.listCredito)
			})
	}

	async getRegistro(codigoEmpresa) {
		this.loading = true
		await this.service.listar().toPromise()
			.then(
				registro => {
					this.listRegistro = registro.filter(item => item.status == 'SCA0001')
					this.listRegistro.forEach(async item => {
						let empresa = await this.token.getEmpresasToken(item.codigoEmpresa)
						let area = await this.conteudo.get('AREANE', true, item.areaNegocio)

						if (empresa.length > 0) {
							item.nomeEmpresa = empresa[0].nomeEmpresa
						}

						item.descAreaNegocio = area.toString()
					})

					this.dataSource.sort = this.sort
					this.loading = false
					if (codigoEmpresa) {
						this.filtrarEmpresa()
					}

				})
	}

	inserir() {
		let registro = this.formulario.value

		delete registro.codigoLancamento
		delete registro.nomeEmpresa
		delete registro.id
		delete registro.criadoEm

		registro.criadoPor = sessao.cpf

		this.service.enviar(registro).toPromise()
			.then(
				async res => {
					this.mensagem.creat(res[0].mensagem, { sucesso: true })
					this.viewCadastro = true
					this.formulario.disable()
					this.botaoSalvar = false
					this.botaoCancelar = false
					this.alteracao = false
					this.modoExibicao = false
					this.modoCriacao = true
					this.modoAlteracao = false
					console.log('Inserir: ', res)
				}
			).catch(
				error => {
					let erro = error.error
					this.mensagem.creat(erro, { erro: true })
				}
			)
	}

	async openCadastro(registro) {
		await this.getContaDebito(registro.codigoEmpresa)
		await this.getContaCredito(registro.codigoEmpresa)

		this.formulario.patchValue(registro)
		localStorage.setItem('backup_registro', JSON.stringify(registro))
		await this.formulario.disable()
		this.viewCadastro = true
		this.alteracao = true
		this.botaoSalvar = false
		this.botaoCancelar = false
		this.modoExibicao = true
		this.modoAlteracao = false
		this.modoCriacao = false
	}

	alterar() {
		let registro = this.formulario.value

		delete registro.causaPatrimonio
		delete registro.identificadorDoProcesso
		delete registro.razonete

		this.service.alterar(registro).toPromise()
			.then(
				resposta => {
					this.mensagem.creat('Cadastro alterado com sucesso.', { sucesso: true })
					this.viewCadastro = true
					this.formulario.disable()
					this.botaoSalvar = false
					this.botaoCancelar = false
					this.modoExibicao = true
					this.modoAlteracao = false
					this.modoCriacao = false
				}
			).catch(
				error => {
					let erro = error.error
					this.mensagem.creat(erro, { erro: true })
				})
	}

	modificarRegistro() {
		this.formulario.enable()
		this.botaoSalvar = true
		this.botaoCancelar = true
		this.modoAlteracao = true
		this.modoCriacao = false
		this.modoExibicao = false
	}

	cancelarRegistro(confirm?) {
		if (confirm) {
			this.viewConfirmCancelar = true
			return
		}

		this.viewConfirmCancelar = false

		let get = localStorage.getItem('backup_registro')
		let registro = JSON.parse(get)
		this.formulario.setValue(registro)
		this.formulario.disable()
		this.botaoSalvar = false
		this.botaoCancelar = false
		this.modoExibicao = true
		this.modoAlteracao = false
		this.modoCriacao = false

	}

	async excluirRegistro(confirm?) {
		if (confirm) {
			this.viewConfirmDelete = true
			return
		}

		this.viewConfirmDelete = false
		let registro = this.formulario.value
		if (!registro.id) return

		await this.service.desativar(registro, false)

		console.log('Excluiu: ', registro)

		this.mensagem.creat('Cadastro excluído com sucesso.', { sucesso: true })
		this.viewCadastro = false
		this.getRegistro(true)
		this.filtrar('')
	}

	async fecharCadastro() {
		this.viewCadastro = false
		this.viewCopia = false
		await this.getRegistro(this.selectEmpresa)
			.finally(() => this.filtrarEmpresa())
	}

	filtrar(value) {
		this.dataSource.filter = value.toLocaleUpperCase().trim()
	}

	filtrarEmpresa() {
		if (this.selectEmpresa == 0) {
			let filter = this._filtro.filterTodos(this.listRegistro, this.listEmpresa)
			this.dataSource.data = filter
			return
		}
		let codigoEmpresa = this.selectEmpresa
		let filter = this.listRegistro.filter(value => value.codigoEmpresa == codigoEmpresa)
		this.dataSource.data = filter
	}


	filterContaDebito(key: string) {
		key = key.toLocaleLowerCase()
		let registros = this.listDebito_backup
		let filter = registros.filter(value => value.descricao.toLocaleLowerCase().includes(key))
		this.listDebito = filter
	}
	filterContaCredito(key: string) {
		key = key.toLocaleLowerCase()
		let registros = this.listCredito_backup
		let filter = registros.filter(value => value.descricao.toLocaleLowerCase().includes(key))
		this.listCredito = filter
	}
}
