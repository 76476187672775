import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
interface Enciclopedia {
  id: number
  titulo1: string
  titulo2: string
  texto: string
}
interface EnciclopediaComent{
  id?: string
  ativo?: boolean
  usuarioId?: string
  nome?: string
  cpf?: string
  comentario?: string
  index?: number
}
@Injectable({
  providedIn: 'root'
})
export class EnciclopediaService {
  url = new environment().api + 'Enciclopedia/'
  url_index = new environment().api + 'IndexEnciclopedias/'
  url_coment = new environment().api + 'EnciclopediaComent/'
  constructor(private http: HttpClient) { }

  listar(id) {
    console.log('olha : ' + this.url)
    return this.http.get<Enciclopedia>(this.url+id)

  }
  listarIndex(){
    return this.http.get<Enciclopedia[]>(this.url_index)
  }
  listarComent(){
    return this.http.get<EnciclopediaComent[]>(this.url_coment)
  }

  adicionarComent(dados : any){
    return this.http.post<EnciclopediaComent>(this.url_coment, dados)
  }
  adicionarIndex(dados: any) {
    console.log(this.url_index,dados)
    return this.http.post<Enciclopedia>(this.url_index, dados)
  }
  adicionar(dados: Enciclopedia) {
    //console.log('Adicionar : ' + this.url+'MenuMaster/',dados)
    return this.http.post<Enciclopedia>(this.url, dados)
  }

  editar(dados: Enciclopedia, id: number) {
    console.log('Editar : ' + this.url + id, dados)
    return this.http.put<Enciclopedia>(this.url + id, dados)
  }
  editarComent(dados: EnciclopediaComent, id: number) {
    return this.http.put<EnciclopediaComent>(this.url_coment + id, dados)
  }
  excluir(id: number) {
    //console.log('Deletar : ' + this.url + id)
    return this.http.delete<Enciclopedia>(this.url + id)

  }
  excluirComentario(id: number) {
    //console.log('Deletar : ' + this.url + id)
    return this.http.delete<Enciclopedia>(this.url_coment + id)

  }
}
