import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../service/usuario.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormulariosService } from '../service/formularios.service';
import { DatePipe } from '@angular/common';
import { PacienteService } from '../service/paciente.service';
import { FormUsuario } from '../usuario/model';
import { async } from 'q';
import { isNumber } from 'util';
import { FormPaciente } from './model';
import { HubDevService } from '../service/hub-dev.service';

@Component({
  selector: 'app-cadastro-paciente',
  templateUrl: './cadastro-paciente.component.html',
  styleUrls: ['./cadastro-paciente.component.scss']
})
export class CadastroPacienteComponent implements OnInit {
  @Input() registro: any
  @Input() companyId: any
  @Input() usuarioId: any
  @Output() verificar = new EventEmitter()

  estruturaFormulario
  Formulario: FormGroup
  selectForm = 'dp'
  loading: boolean;
  constructor(
    private url: ActivatedRoute,
    private service: PacienteService,
    private serviceUsuario: UsuarioService,
    private form: FormBuilder,
    private mensagem: ToastrService,
    private formularios: FormulariosService,
    private datePipe: DatePipe,
    private hub: HubDevService,
    private toast: ToastrService
  ) {
    this.estruturaFormulario = this.formularios.formularioPaciente()

  }

  async ngOnInit() {
    console.log('PPPPP' + this.usuarioId)
    if (!this.registro) {
      this.registro = new FormPaciente
    }

    this.createForm()

    console.log(this.usuarioId)

    console.log('companyId - ' + this.companyId)
  }
  async  buscarID() {

    this.serviceUsuario.listar(this.usuarioId).toPromise()
      .then(
        (dados: FormUsuario) => {
          this.companyId = dados.companyId
          console.log(this.companyId)
        }
      )
  }
  cadastrarPaciente() {
    if (this.registro.id) return
    // alert('passou')
    this.service.cadastrarPaciente(this.Formulario.value).toPromise()
      .then(
        async (result) => {
          this.registro = result
          await this.verificar.emit(this.registro)
          this.mensagem.success('Paciente Cadastrado com sucesso')
        }
      )
      .catch(
        error => {
          this.mensagem.error('Não foi possivel cadastrar Paciente')
          console.log('Erro : ' + JSON.stringify(error))
        }
      )
  }
  alterarPaciente() {
    // if(this.registro.companyId == '00000000-0000-0000-0000-000000000000'){
    //     this.registro.companyId = this.companyId
    // }
    this.service.alterarPaciente(this.registro.id, this.registro).toPromise()
      .then(
        async () => {
          this.mensagem.success('Cadastro alterado com sucesso')
          await this.verificar.emit(this.registro)
        }
      ).catch(
        () => {
          this.registro.id = null
          this.cadastrarPaciente()
        }
      )
  }
  buscarCEP() {
    console.log('passou')
    let cep = this.Formulario.controls['cep'].value
    this.hub.vCEP(cep).toPromise()
      .then(
        (res) => {
          this.registro['endereco'] = res.result.logradouro
          this.registro['bairro'] = res.result.bairro
          this.registro['cidade'] = res.result.localidade
          this.registro['estado'] = res.result.uf
          console.log(res)
        }
      )
  }
  validarCPF() {
    this.loading = true
    const cpf = this.Formulario.controls['cpf'].value
    const nascimento = this.Formulario.controls['nascimento'].value
    const nome = this.Formulario.controls['nome'].value
    this.hub.vCPF(cpf, nascimento, nome).then(async (res) => {
      if (res) {
        this.cadastrarPaciente()
      }
      else {
        this.toast.error('Data de Nascimento invalida')
        this.loading = false
      }
    })
  }
  async createForm() {
    this.Formulario = this.form.group({
      usuarioId: new FormControl(this.usuarioId),
      companyId: new FormControl(this.companyId ? this.companyId : "00000000-0000-0000-0000-000000000000"),
      nome: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      cpf: new FormControl('', Validators.required),
      rg: new FormControl('', Validators.required),
      nascimento: new FormControl('', Validators.required),
      sexo: new FormControl('', Validators.required),
      tratamento: new FormControl(''),
      telefone: new FormControl(''),
      telefone2: new FormControl(''),
      celular: new FormControl(''),
      convenio: new FormControl(''),
      medico: new FormControl(''),
      tipo: new FormControl(''),
      cor: new FormControl(''),
      pis: new FormControl(''),
      cartaosus: new FormControl(''),
      rne: new FormControl(''),
      nacionalidade: new FormControl(''),
      naturalidade: new FormControl(''),
      nomeConjuge: new FormControl(''),
      civil: new FormControl(''),
      especial: true,
      localNascimento: new FormControl(''),
      formaPag: new FormControl(''),
      situacao: new FormControl(''),
      peso: new FormControl(''),
      responsavel: new FormControl(''),
      rgResponsavel: new FormControl(''),
      cpfResponsavel: new FormControl(''),
      indicacao: new FormControl(''),
      pai: new FormControl('', Validators.required),
      mae: new FormControl('', Validators.required),
      atividadePai: new FormControl(''),
      atividadeMae: new FormControl(''),
      endereco: new FormControl(''),
      numero: new FormControl(''),
      bairro: new FormControl(''),
      cidade: new FormControl(''),
      estado: new FormControl(''),
      cep: new FormControl(''),
      operadora: new FormControl(''),
      numeroBeneficiario: new FormControl(''),
      modalidade: new FormControl(''),
      numeroCarteirinha: new FormControl(''),
      bandeira: new FormControl(''),
      numerocartao: new FormControl(''),
      validade: new FormControl(''),
      cvv: new FormControl(''),
      pais: new FormControl(''),
      ativo: true
    })

  }

}
