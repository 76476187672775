import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { RegistroService } from './registro.service';


const url= `${new environment().api}ConsultarTabelas/`;

@Injectable({
    providedIn: 'root'
  })

export class TabelaTussModal
{
      id: string
      criadoPor: string
      timestamp: string
      tab22TussCodigo: string
      tab22TussTerminologia: string
      versaoTuss: string
      correlacao: true
      rolANS: string
      resolucaoNormativa: string
      capitulo: string
      grupo: string
      subGrupo: string
      od: string
      amb: string
      hco: string
      hso: string
      pac: string
      dut: string
      status: string
} 

export class TabelaTussService{
    api = 'https://www.myclinics.com.br/api/api/TabelaTuss/'
    constructor(private http: HttpClient, private mensagem: ToastrService) {}

async Get(){
    let registros = [] 
     await this.http.get<TabelaTussModal[]>(this.api).toPromise()
    .then(
        (res) => {
            registros = res
        }
    ).catch((error: HttpErrorResponse) => this.mensagem.error(error.message, error.statusText))
    return registros;  
    }
   
}