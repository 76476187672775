import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgendaForm, HorariosForm } from 'src/app/agenda/agenda/model';
import { MonitorClinicoService } from 'src/app/service/monitor-clinico.service';
import { AgendaService } from 'src/app/service/agenda.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { TicketConsulta } from './consultas/solicitacoes/modal';
import { SessaoUser } from 'src/app/user.reg';
const sessao = new SessaoUser

class Monitor {
  encerrada: number
  retorno: number
  solicitacoes: number
  CAD: number
  DP: number
  CF: number
  SDA: number
  agendadas: number
  confirmar: number
  confirmado: number
  canceladas: number
  situacao: any
  tempoMedico: any
  confirmadasPorcento: number
  confirmarPorcento: number
  conectAguardandoResposta: number
  conectAguardandoAcesso: number
}
@Component({
  selector: 'app-monitor-clinico',
  templateUrl: './monitor-clinico.component.html',
  styleUrls: ['./monitor-clinico.component.scss']
})
export class MonitorClinicoComponent implements OnInit, OnDestroy {
  ////backup
  verHorariosLivres
  reagendar
  dataSelecionada
  reservas
  abrirCobranca
  ////END
  monitorClinico = new Monitor
  monitorProxConsulta: TicketConsulta;
  monitorHorariosLivres = []
  monitorHoraroLivresAmanha: AgendaForm;
  loadingMonitor: boolean;
  verSolicitacoes: boolean
  horarios: any[];
  sugerirHorario: boolean;
  reservaSelec: AgendaForm;
  solicitacoes: TicketConsulta[];
  solicitacoesHistorico: TicketConsulta[];
  atualizar
  not_consultasCanceladas = [];
  viewNotificacao = {
    view: false,
    ref: '',
    value: 'any'
  }
  constructor(
    private monitor: MonitorClinicoService,
    private _agenda: AgendaService,
    private toast: ToastrService,
    private _info: MatSnackBar,
    private data: BuscarDataService
  ) { }

  ngOnInit() {
    this.getMonitorClinico()
    this.getNotificacoes()
    setInterval(() => {
      if (localStorage.getItem('atualizarMonitor')) {
        this.getMonitorClinico()
      }
    }, 1000)
    this.atualizar = setInterval(() => {
      this.getMonitorClinico()
    }, 10000)
    this.atualizar
  }
  ngOnDestroy() {
    clearInterval(this.atualizar)
  }
  async getMonitorClinico() {
    localStorage.removeItem('atualizarMonitor')


    ///Solicitações
    let getSolicitacao = await this.monitor.get('solicitacoes') as any
    this.monitorClinico.solicitacoes = getSolicitacao.ativos.length
    this.solicitacoes = getSolicitacao.ativos.map(v => v.ticketReserva)
    this.solicitacoesHistorico = getSolicitacao.historico.map(v => v.ticketReserva)

    //Encerramento
    let getEncerramento = await this.monitor.get('encerramento')
    this.monitorClinico.encerrada = getEncerramento.length

    //Solicitações prontuario
    let solicProntuario = await this.monitor.get('solicitacaoDeAcesso')
    this.monitorClinico.SDA = solicProntuario.length


    //Atendimento a confirmar
    let aConfirmar = await this.monitor.get('aConfirmar')
    this.monitorClinico.confirmar = aConfirmar.length

    //Atendimento a confirmada
    let confirmada = await this.monitor.get('aConfirmadas')
    this.monitorClinico.confirmado = confirmada.length

    //Atendimento a agendadas
    this.monitorClinico.agendadas = aConfirmar.length + confirmada.length

    //Atendimento a canceladas
    let canceladas = await this.monitor.get('canceladas')
    this.monitorClinico.canceladas = canceladas.length

    //Proximas consultas
    let proxConsultas = await this.monitor.get('proximasConsultas')
    this.monitorProxConsulta = proxConsultas as any
    //Conexçoes
    let aguardandoResp = await this.monitor.get('conectAguardandoResposta')
    this.monitorClinico.conectAguardandoResposta = aguardandoResp.length

    let aguardandoAtrib = await this.monitor.get('conectAguardandoAcesso')
    this.monitorClinico.conectAguardandoAcesso = aguardandoAtrib.length

    let agenda = [this.monitorProxConsulta]
    this.getHorariosDisponiveis(agenda)
  }
  async getHorariosDisponiveis(consultas: Array<TicketConsulta>) {

    let configAgenda = await this._agenda.getConfigMedico(sessao.cpf)
    try {
      let horariosAbertos = await this._agenda.getAgendaFechada(sessao.cpf, sessao.codigoEmpresa)
      horariosAbertos = horariosAbertos.filter(v => {
        return new Date(new Date().toISOString().split('T')[0]).toUTCString() == new Date(v.start).toUTCString() && v.status == 'SDO0149'
      })

      let horarios = this._agenda.listarHorarios(configAgenda.timeStart ? configAgenda.timeStart : '00:00', configAgenda.timeEnd ? configAgenda.timeEnd : '23:00')

      let horariosAgenda = consultas.filter(v => {
        return new Date(new Date().toISOString().split('T')[0]).toUTCString() == new Date(v.dataConsulta).toUTCString()
      }
      ).map(v => v.horaConsulta)

      if (!horariosAbertos.length) return

      let horariosDisponiveis = []

      let getHorariosDoDia = horarios.filter(h => {
        return Number(`${h.h}${h.m}`) >= Number(new Date().toLocaleTimeString().split(':', 2).join(''))
      })

      this.monitorHorariosLivres = getHorariosDoDia

    }
    catch {
      this.monitorHorariosLivres = []
     }


  }
  getNotificacoes() {
    this.monitor.getTicket().toPromise()
      .then((reg) => {
        this.not_consultasCanceladas = reg.filter((r) => { return r.codigoEmpresa == sessao.codigoEmpresa && r.status == 'SDO0022' })
      })
  }
  viewNotificacoes(ref, value) {
    switch (ref) {
      case 'consultaCancelada':
        this.viewNotificacao.view = true
        this.viewNotificacao.ref = ref
        this.viewNotificacao.value = value
        break;

      default:
        break;
    }
  }

  info() {
    this._info.open('Salvo com sucesso', '', {
      duration: 2000
    })
  }

}
