import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PacienteService } from '../service/paciente.service';
import { UsuarioService } from '../service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { FormulariosService } from '../service/formularios.service';
import { DatePipe } from '@angular/common';
import { HubDevService } from '../service/hub-dev.service';
import { CadastroPessoaForm, FormPaciente } from '../cadastro-paciente/model';
import { FormUsuario } from '../usuario/model';
import { filter } from 'rxjs/operators';
import { ValidateBrService } from 'angular-validate-br';
import { MensagemService } from '../service/mensagem.service';
import { BuscarDataService } from '../service/buscar-data.service';
import { FichaConexoesProfissionaisService } from '../service/ficha-conexoes-profissionais/ficha-conexoes-profissionais.service';
import { SessaoUser } from '../user.reg';
import { cadastroUsuario } from '../admin-usuarios/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const sessao = new SessaoUser
const url_email = `${new environment().api}SendEmail/`;
@Component({
  selector: 'app-cadastro-paciente-basico',
  templateUrl: './cadastro-paciente-basico.component.html',
  styleUrls: ['./cadastro-paciente-basico.component.scss']
})
export class CadastroPacienteBasicoComponent implements OnInit {

  @Input() registro = new CadastroPessoaForm
  @Input() companyId: any
  @Input() usuarioId: any
  @Input() convenio?: boolean
  @Input() acompanhante?: boolean
  @Input() tipoPaciente?: any

  @Output() verificar = new EventEmitter()
  @Output() viewEncaixe = new EventEmitter()
  @Output() exit = new EventEmitter()
  @Output() emitAcompanhante = new EventEmitter()


  estruturaFormulario
  Formulario: FormGroup
  selectForm = 'dp'
  loading: boolean;
  nascimento_invalido: boolean;
  teste: number
  viewConfirmConexao: boolean;
  viewConexao: boolean;
  mensagemInfo: string
  semTitular: boolean;
  addDependente: boolean;
  FormularioDependente: FormGroup;
  registroDependente = new CadastroPessoaForm
  constructor(
    private url: ActivatedRoute,
    private service: PacienteService,
    private serviceUsuario: UsuarioService,
    private form: FormBuilder,
    private mensagem: MensagemService,
    private formularios: FormulariosService,
    private http: HttpClient,
    private hub: HubDevService,
    private _conexao: FichaConexoesProfissionaisService,
    private ValidCPF: ValidateBrService,
    private _data: BuscarDataService
  ) {
    this.estruturaFormulario = this.formularios.formularioPacienteBasico()
  }

  async ngOnInit() {
    console.log('PPPPP', this.registro)
    if (!this.registro.cpf) {
      this.registro = new CadastroPessoaForm
    }
    if (this.acompanhante) {
      this.tipoPaciente = 1
    }
    this.createForm()

    console.table(this.registro)
    if (this.tipoPaciente == 2) {
      this.getTitular()
    }
    console.log('companyId - ' + this.companyId)
  }

  async cadastrarPaciente() {
    if (this.registro.id) return

    const cpf = this.Formulario.controls['cpf'].value
    let response = await this.serviceUsuario.getRegistro(cpf)
    if (response.id) {
      this.mensagemInfo = "CPF já cadastrado!"
      this.loading = false
      return
    }

    let registro: cadastroUsuario = this.Formulario.value
    registro.status = 'SDO0085'
    registro.criadoPor = sessao.cpf
    registro.tipoUsuario = 'TIPUSU5'

    this.serviceUsuario.cadastrarUsuario(registro).toPromise()
      .then(
        async (result: CadastroPessoaForm) => {
          if (!result) {
            this.mensagemInfo = 'Não foi possivel realizar cadastro'
            this.loading = false
            return
          }
          this.registro = result
          if (this.acompanhante) {
            this.emitAcompanhante.emit(result)
          } else {
            await this.verificar.emit(result)
          }
          this.viewConfirmConexao = true

          this.mensagem.creat('Paciente Cadastrado com sucesso', { sucesso: true })
        }
      )
      .catch(
        error => {
          this.mensagem.creat('Não foi possivel cadastrar Paciente', { erro: true })
          console.log('Erro : ', error)
          this.loading = false
        }
      )

    if (this.addDependente) {
      let dependente: CadastroPessoaForm = this.FormularioDependente.value
      dependente.cpfResponsavelTitular = this.registro.cpf
      // dependente.registroProfissional = 'Não informado'
      dependente.cep = '00000000'
      dependente.possuiPlanoDeSaude = dependente.operadoraConvenio ? true : false
      dependente.endereco = 'Não informado'
      dependente.numeroEndereco = '0'
      dependente.cidade = 'Não informado'
      dependente.estado = 'Não informado'
      dependente.bairro = "Não informado"
      dependente.tipoPessoa = 'Não informado'
      dependente.status = 'SCA0001'
      dependente.tipoPessoa = 'TIPPES2'
      this.service.cadastrarPaciente(dependente).toPromise()
        .then(
          async (result: CadastroPessoaForm) => {
            if (!result) {
              this.mensagemInfo = 'Não foi possivel realizar cadastro'
              this.loading = false
              return
            }
            this.registro = result
            await this.verificar.emit(result)
            this.viewConfirmConexao = true
            this.Formulario.reset()

            this.mensagem.creat('Paciente Cadastrado com sucesso', { sucesso: true })
          }
        )
        .catch(
          error => {
            this.mensagem.creat('Não foi possivel cadastrar Paciente', { erro: true })
            console.log('Erro : ', error)
            this.loading = false
          }
        )
    }


  }
  async conctarPaciente() {
    let result = await this._conexao.conectar('TIPCON5', this.Formulario.get('cpf').value, sessao.codigoEmpresa)
    if (result) {
      let email = this.Formulario.get('email').value
      let name = this.Formulario.get('nomeCompleto').value
      let cpf = this.Formulario.get('cpf').value
      console.log(url_email + `${email}/${name}/${cpf}`)
      this.http.get(url_email + `${email}/${name}/${cpf}`).toPromise()
        .then(res => {
          this.viewConexao = true
          this.Formulario.reset()
        })

    }
    console.log(this.Formulario)
  }
  verificarCPF() {
    if (this.Formulario.controls['dataNascimento'].valid) {
      this.nascimento_invalido = false
    }
  }
  alterarPaciente() {
    // if(this.registro.companyId == '00000000-0000-0000-0000-000000000000'){
    //     this.registro.companyId = this.companyId
    // }
    this.service.alterarPaciente(this.registro.id, this.registro).toPromise()
      .then(
        async () => {
          this.mensagem.creat('Cadastro alterado com sucesso', { sucesso: true })
          await this.verificar.emit(this.registro)
        }
      ).catch(
        () => {
          this.registro.id = null
          this.cadastrarPaciente()
        }
      )
  }
  buscarCEP() {
    console.log('passou')
    let cep = this.Formulario.controls['cep'].value
    this.hub.vCEP(cep).toPromise()
      .then(
        (res) => {
          this.registro['endereco'] = res.result.logradouro
          this.registro['bairro'] = res.result.bairro
          this.registro['cidade'] = res.result.localidade
          this.registro['estado'] = res.result.uf
          console.log(res)
        }
      )
  }
  validarCPF(consultar?) {

    this.mensagemInfo = null
    if (this.Formulario.invalid) {
      this.mensagemInfo = 'Preencha todos os campos obrigatórios'
      return
    }
    this.loading = true
    if (this.Formulario.controls['cpf'].invalid) {
      this.loading = false
      this.mensagemInfo = "CPF invalido"
      return
    }
    const cpf = this.Formulario.controls['cpf'].value
    const nascimento = this._data.convertBR(this.Formulario.controls['dataNascimento'].value)
    const nome = this.Formulario.controls['nomeCompleto'].value
    console.log('FORMULARIO', this.Formulario.value)
    this.hub.vCPF(cpf, nascimento, nome).then(async (res) => {
      if (res) {
        if (consultar) {
          this.nascimento_invalido = false
          return
        }
        this.cadastrarPaciente()
      }
      else {
        this.mensagemInfo = "Data de nascimento divergente com o CPF informado "
        this.nascimento_invalido = true
        this.loading = false
        if (consultar) return

      }
    })
  }
  async createForm() {
    this.Formulario = this.form.group({
      usuarioId: new FormControl(this.usuarioId ? this.usuarioId : "00000000-0000-0000-0000-000000000000"),
      nomeCompleto: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      cpf: new FormControl('', [Validators.required, this.ValidCPF.cpf]),
      dataNascimento: new FormControl('', Validators.required),
      convenio: new FormControl(''),
      operadora: new FormControl(''),
      genero: new FormControl('', Validators.required),
      numeroCarteirinha: new FormControl(''),
      cpfTitular: new FormControl(''),
      telefone: new FormControl(''),
      ativo: true,
      grauParentescoTitular: ''
    })
    this.FormularioDependente = this.form.group({
      usuarioId: new FormControl(this.usuarioId ? this.usuarioId : "00000000-0000-0000-0000-000000000000"),
      nomeCompleto: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      cpf: new FormControl('', [Validators.required, this.ValidCPF.cpf]),
      dataNascimento: new FormControl('', Validators.required),
      convenio: new FormControl('', Validators.required),
      operadora: new FormControl('', Validators.required),
      genero: new FormControl('', Validators.required),
      numeroCarteirinha: new FormControl(''),
      cpfTitular: new FormControl(''),
      telefone: new FormControl(''),
      ativo: true,
      grauParentescoTitular: ''
    })
    if (this.registro.nomeCompleto) {
      this.getPaciente()
    }
    this.Formulario.patchValue(this.registro)
    // this.validarCPF(true)
  }
  getPaciente() {
    this.service.listarPaciente().toPromise()
      .then(
        reg => {
          if (!reg.success) return
          let cpf = this.registro.cpf
          let dados = reg.result.filter(function (reg) {
            return reg.cpf == cpf
          })
          console.log(dados)
          if (dados.length > 0) {
            this.registro = dados[0]
          }
        }
      )
  }
  getTitular() {
    this.service.listarPaciente().toPromise()
      .then(
        reg => {
          if (!reg.success) return
          let cpf = this.registro.cpf
          let dados = reg.result.filter(function (reg) {
            return reg.cpfResponsavelTitular == cpf
          })
          console.log(dados)
          if (dados.length > 0) {
            this.registro.cpfResponsavelTitular = dados[0].cpfResponsavelTitular
            this.semTitular = false
          } else {
            // this.semTitular = true
          }
        }
      )
  }
  return(emitEncaixe?) {
    if (emitEncaixe) {
      this.viewEncaixe.emit(true)
    }
    this.exit.emit(true)

    this.Formulario.reset()
    this.FormularioDependente.reset()
  }

}
