import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { caracteristicaDeDado } from './caracteristica-de-dados.model';
import { conteudoDeDado } from './conteudo-de-dados.model';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MensagemService } from '../mensagem.service';

const urlConteudoDados = `${new environment().api}ConteudoDeDados`;
const urlCaracteristicaDados = `${new environment().api}Caracteristicas`;

@Injectable({
  providedIn: 'root'
})

export class ClassificacaoDeDadosService {

  constructor(
    private http: HttpClient,
    private mensagem: MensagemService,
  ) { }

  //CARACTERÍSTICAS DE DADOS
  GetListarCaracteristicas(){
    return this.http.get(urlCaracteristicaDados)
  }

  async listarCaracteristicas(): Promise<caracteristicaDeDado[]> {
    let listaCaracteristicas: caracteristicaDeDado[];
    await this.http.get<caracteristicaDeDado[]>(`${urlCaracteristicaDados}`, { observe: 'body' }).toPromise()
      .then(res => {
        
        listaCaracteristicas = res.sort((a, b) => {
          return a.codigoDaCaracteristica < b.codigoDaCaracteristica ? -1 : a.codigoDaCaracteristica > b.codigoDaCaracteristica ? 1 : 0;
        })
        
      })
      .catch((error: HttpErrorResponse) => this.mensagem.creat(`Erro ao listar características: ${error.error}`, { erro: true }))
    return listaCaracteristicas;
  }

  async criarCaracteristica(novaCaracteristica: Omit<caracteristicaDeDado, 'id'>) {
    await this.http.post(`${urlCaracteristicaDados}`, novaCaracteristica, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Caracteristica ${novaCaracteristica.codigoDaCaracteristica} criada com sucesso`, { sucesso: true }))
      .catch((error: HttpErrorResponse) => this.mensagem.creat(`Erro ao criar característica: ${error.error}`, { erro: true }))
  }

  async editarCatacteristica(novaCaracteristica: caracteristicaDeDado) {
    await this.http.put(`${urlCaracteristicaDados}/${novaCaracteristica.id}`, novaCaracteristica, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Caracteristica ${novaCaracteristica.codigoDaCaracteristica} alterada com sucesso`, { sucesso: true }))
      .catch((error: HttpErrorResponse) => this.mensagem.creat(`Erro ao alterar característica: ${error.error}`, { erro: true }))
  }

  async removerCatacteristica({ id }: { id: string }) {
    await this.http.delete(`${urlCaracteristicaDados}/${id}`, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Caracteristica removida com sucesso`, { sucesso: true }))
      .catch((error: HttpErrorResponse) => this.mensagem.creat(`Erro ao remover característica: ${error.error}`, { erro: true }))
  }

  //CONTEÚDOS DE DADOS

  async criarConteudo({ codigoCaracteristica, valor, codigoDeNegocio }: { codigoCaracteristica: string, valor: string, codigoDeNegocio?: string }) {
    const novoConteudo: conteudoDeDado = {
      codigoCaracteristica,
      valor,
      codigoDeNegocio,
      status: 'SDO0017',
    }
    await this.http.post(`${urlConteudoDados}`, novoConteudo, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Conteúdo de dados ${novoConteudo.valor} criado com sucesso`, { sucesso: true }))
      .catch((error: HttpErrorResponse) => this.mensagem.creat(`Erro ao criar conteúdo de dado: ${error.message}`, { erro: true }))
  }

  
  async gravarListaConteudo({ listaConteudos }: { listaConteudos: conteudoDeDado[] }) {
    await this.http.post(`${urlConteudoDados}/Array`, listaConteudos, { reportProgress: true, observe: 'events' }).toPromise()
  }

  async listarConteudos(): Promise<conteudoDeDado[]> {
    let listaConteudos: conteudoDeDado[];
    await this.http.get<conteudoDeDado[]>(`${urlConteudoDados}`, { observe: 'body' }).toPromise()
      .then(res => listaConteudos = res.map(conteudo => {
        if (conteudo.status === 'SDO0017') { conteudo.status = 'ativo' }
        else if (conteudo.status === 'SDO0066') { conteudo.status = 'inativo' }
        else { conteudo.status = '?' }
        return conteudo
      }))
      .catch((error) => this.mensagem.creat(`Erro ao listar conteúdos de dados: ${error.error} listarConteudos`, { erro: true }))
    return listaConteudos;
  }

  GetConteudoDeDados(){
    return this.http.get(urlConteudoDados)
  }

  GetConsultarCodigoReferencia(codigoReferencia)
  {
    return this.http.get(urlConteudoDados + "/ConsultarCodigoReferencia/" + codigoReferencia)
  }

  GetFiltrarCodigoCaracteristica(codigoCaracteristica){
    return this.http.get(urlConteudoDados + "/FiltrarCodigoCaracteristica/" + codigoCaracteristica)
   
  }
  GetJoinCodigoCaracteristica(codigoCaracteristica){
    return this.http.get(urlConteudoDados + "/JoinFiltrarCodigoCaracteristica/" + codigoCaracteristica)
  }

  GetConsultarCaracteristica(codigoCaracteristica){
    return this.http.get(urlCaracteristicaDados + "/FiltrarCaracteristicas/" + codigoCaracteristica)
  }
  



  async buscarConteudosDaCaracteristica({ codigoCaracteristica }: { codigoCaracteristica: string }): Promise<conteudoDeDado[]> {
    let listaConteudos: conteudoDeDado[];
    await this.http.get<conteudoDeDado[]>(urlConteudoDados + "/FiltrarCodigoCaracteristica/" + codigoCaracteristica, { observe: 'body' }).toPromise()
      .then(res => listaConteudos = res.map(conteudo => {
        if (conteudo.status === 'SDO0017') { conteudo.status = 'ativo' }
        else if (conteudo.status === 'SDO0066') { conteudo.status = 'inativo' }
        else { conteudo.status = '?' }
        return conteudo
      }))
      .catch((error) => this.mensagem.creat(`Erro ao buscar conteúdos desta característica: ${error.error} buscarConteudosDaCaracteristica`, { erro: true }))
    return listaConteudos;
  }

  async alterarConteudo(novoConteudo: conteudoDeDado) {
    novoConteudo.status = 'SDO0017';
    await this.http.put(`${urlConteudoDados}/${novoConteudo.id}`, novoConteudo, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Conteúdo ${novoConteudo.valor} alterado com sucesso`, { sucesso: true }))
      .catch((error) => this.mensagem.creat(`Erro ao alterar conteúdo de dado: ${error.error}`, { erro: true }))
  }

  async removerConteudo({ id }: { id: string }) {
    await this.http.delete(`${urlConteudoDados}/${id}`, { observe: 'body' }).toPromise()
      .then(() => this.mensagem.creat(`Conteúdo removido com sucesso`, { sucesso: true }))
      .catch((error) => this.mensagem.creat(`Erro ao remover conteúdo: ${error.error}`, { erro: true }))
  }
}
