import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CabecalhoPedidoCompra } from './cabecalhoCompra.model';
import { PedidoCompraItem } from './itemCompra.model';

const urlCabecalho = `${new environment().api}PedidoDeCompraCabecalho`;
const urlItems = `${new environment().api}PedidoDeCompraCabecalho`;
@Injectable({
  providedIn: 'root'
})
export class PedidoDeCompraService {

  constructor(
    private http: HttpClient) {
  }

  async realizarPedidoDeCompra({ cabecalho, items }: { cabecalho: any, items: any[] }) {
    let documentoReferencia = '';
    this.http.post(urlCabecalho, cabecalho).toPromise()
      .then(async (res: CabecalhoPedidoCompra) => {
        documentoReferencia = res.numeroDocumento;
        for (let i = 0; i < items.length; i++) {
          items[i].numeroDocumento = res.numeroDocumento;
          await this.http.post(urlItems, items[i]).toPromise()
        }
      })
      .then(() => console.log('Pedido de compra salvo com sucesso'))
      .catch((err: HttpErrorResponse) => console.error(`Erro: ${err.status}, ${err.statusText}`, err.message))
    return documentoReferencia;
  }

  async listarPedidosDeCompra() {
    let pedidoDeCompra: { cabecalho: CabecalhoPedidoCompra, items: PedidoCompraItem[] }[]
    await this.http.get<CabecalhoPedidoCompra[]>(urlCabecalho, { observe: 'body' }).toPromise()
      .then(async cabecalhos => {
        for (let i = 0; i < cabecalhos.length; i++) {
          //colocar rota que filtra items pelo número de documento
          await this.http.get<PedidoCompraItem[]>(`${urlItems}/`).toPromise()
            .then(items => {
              pedidoDeCompra.push({ cabecalho: cabecalhos[i], items: items })
            })
        }
      })
      .catch((err: HttpErrorResponse) => console.error(`Erro: ${err.status}, ${err.statusText}`, err.message))
    return pedidoDeCompra
  }
}
