import { Directive, ElementRef, HostListener } from "@angular/core";
import { FiltrarDadosService } from "../service/filtrar-dados.service";

@Directive({
    selector: '[convertData]'
})
export class ConvertDataDirective {
    element
    constructor(private el: ElementRef, private _filter: FiltrarDadosService) {

    }
    ngOnInit() {
        this.element = this.el.nativeElement as HTMLInputElement
        setTimeout(() => {
            const value = this.element.value
            const format = this._filter.formatDateRev(value)
            console.log('/================/-',this._filter.formatDateRev(value), format, value)
            this.element.value = format
        }, 1000)

    }

    @HostListener('keyup')
    Trasnform() {
        const value = this.element.value
        if (value.match(/^\d{2}$/) !== null) {
            this.element.value = value + '/';
        } else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
            this.element.value = value + '/';
        }
    }

}