import { Component, OnInit, Input } from '@angular/core';
import { FormCadastroEmpresa } from 'src/app/configuracao-inicial/cadastro-empresa/model';
import * as jsPDF from 'jspdf';
import { SalaEsperaForm } from '../../sala-espera/model';
import { DatePipe } from '@angular/common';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConfiguracaoInicialService } from 'src/app/service/configuracao-inicial.service';
@Component({
  selector: 'app-emitir-encaminhamento',
  templateUrl: './emitir-encaminhamento.component.html',
  styleUrls: ['./emitir-encaminhamento.component.scss']
})
export class EmitirEncaminhamentoComponent implements OnInit {

  @Input() paciente
  info: SalaEsperaForm
  dias
  datePipe = new DatePipe('en-US')
  dataHoje: string;
  empresa: FormCadastroEmpresa;
  especialidade
  servico
  constructor(private hoje: BuscarDataService, private service: ConfiguracaoInicialService) {
    this.dataHoje = this.hoje.hoje()
  }

  ngOnInit() {
    console.log(this.paciente)
    this.info = JSON.parse(localStorage.getItem('infoSalaEspera'))
    this.buscarEmpresa()
  }

  async  downloadPDF() {
    // var image = new Image
    // var img = image.src = "../../../../assets/img/logoDoctorJP.jpg"
    // var teste = 'data:image/jpeg;base64,' + btoa(img);
    var doc = new jsPDF();
    // await doc.addHTML(document.getElementById('content'), function(){
    //   console.log('ok')
    //   doc.output('dataurlnewwindow');
    // })
    doc.setFontSize(12);
    doc.text(5, 10, `${this.empresa.name ? this.empresa.name : '-'}`);

    doc.setFontSize(9);
    doc.text(5, 15, `${this.empresa.consultorio} - CRM ${this.empresa.crm ? this.empresa.crm : '___________________'} - CPF ${this.empresa.cpf ? this.empresa.cpf : '_______________'}`);
    doc.line(0, 25, 1000, 25);
    doc.setFontSize(22);
    doc.text("ENCAMINHAMENTO", 70, 35);
    
    doc.setFontSize(12);
    doc.text("Prezado(a) Dr(a) _____________________________________________", 10, 60);
    
    doc.setFontSize(12);
    doc.text(`Encaminho o Paciente : ${this.info.paciente}` , 10, 70);
    
    doc.setFontSize(12);
    doc.text(`Para : ${this.especialidade} , ${this.servico}`, 10, 80);
    
     doc.setFontSize(12);
    doc.text("Grato(a) desde já.", 10, 90);

    doc.setFontSize(15);
    doc.text("_____________________________", 100, 130);
    doc.setFontSize(15);
    doc.text(`CRM : ${this.empresa.crm ? this.empresa.crm : ''}`, 100, 140);
    doc.setFontSize(15);
    doc.text(`São Paulo ${this.datePipe.transform(this.dataHoje, 'dd/MM/yyyy')}`, 10, 160);

    doc.output('dataurlnewwindow');

    doc.autoPrint();

  }
  buscarEmpresa() {
    this.service.listarEmpresa().toPromise()
      .then(
        (dados: Array<FormCadastroEmpresa>) => {
          let companyId = this.info.companyId
          let filtro = dados.filter((r) => r.id.toLocaleUpperCase() == companyId.toLocaleUpperCase())
          this.empresa = filtro[0]
        }
      )
  }

}
