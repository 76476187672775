import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { BuscarDataService } from './buscar-data.service';
import { SalaEsperaForm } from '../atendimento-clinico/sala-espera/model';
import { HttpClient } from '@angular/common/http';
import { FormContatos } from '../conversas/modal';
import { TicketConsultaJOIN } from '../home/monitor-clinico/consultas/solicitacoes/modal';

@Injectable({
  providedIn: 'root'
})
export class VideoChamadaService {
  url = new environment().api
  db = this.firestore.collection('videoChamada')
  api = 'VideoChamada/'
  constructor(public firestore: AngularFirestore, private _data: BuscarDataService, private http: HttpClient) { }

  criarSala(dados: SalaEsperaForm | TicketConsultaJOIN | any, medico?: boolean): Promise<any> {
    let info = {
      paciente: dados.nomePaciente,
      data: this._data.hoje(),
      cpf: dados.cpfPaciente

    }
    if (medico) {
      info = {
        paciente: dados.nomeMedico,
        data: this._data.hoje(),
        cpf: dados.cpfMedico
      }
    }
    console.log('add')
    return this.db.add(info)
  }
  registrar(reg) {
    console.log(this.url + this.api, reg)
    return this.http.post(this.url + this.api, reg)
  }
  getVideoChamda() {
    return this.http.get(this.url + this.api)
  }
  putVideoChamada(dados) {
    return this.http.put(this.url + this.api + dados.id, dados)
  }
  banco() {
    return this.db
  }
  addContato(id, active) {
    return this.db.doc(id).update({ contato: active })
  }

  observarVideoChamada(){
    
  }
}
