import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BuscarDataService } from './buscar-data.service';
import { CadastroOperadoraSaude } from '../cadastro-operadora-saude/modelsCadastroOperadoraSaude';
import { SessaoUser } from '../user.reg';

const sessao = new SessaoUser

@Injectable({
    providedIn: 'root'
})
export class CadastroOperadoraSaudeService {


    url = new environment().api
    api = "CadastroOperadoraSaude/"
    apiConta = 'PlanoContaContabeis/'
    jsonPais = './assets/json/json-paises.json'
    jsonBancos = './assets/json/bancos-de-todo-brasil.json'

    constructor(
        private http: HttpClient, private data: BuscarDataService) { }

    getPaises(): Observable<any> {
        return this.http.get(this.jsonPais)
    }

    getBancos(): Observable<any> {
        return this.http.get(this.jsonBancos)
    }

    listarContas(codigoEmpresa) {
        return this.http.get(this.url + this.apiConta + `FiltrarCodigoEmpresa/${codigoEmpresa}`)
    }

    listar() {
        return this.http.get<CadastroOperadoraSaude[]>(this.url + this.api)
    }
    async get(codigoEmpresa: number) {
        let result: CadastroOperadoraSaude[] = []
        await this.http.get<CadastroOperadoraSaude[]>(this.url + this.api).toPromise()
            .then(res => {
                if (res.length < 1) return
                let filter = res.filter(value => value.codigoEmpresa == codigoEmpresa)
                if (filter.length > 0) result = filter
            })
        return result
    }

    enviar(registro: CadastroOperadoraSaude) {
        return this.http.post(this.url + this.api, registro)
    }

    alterar(registro: CadastroOperadoraSaude) {
        return this.http.put(this.url + this.api + `${registro.id}/${sessao.sessao_id}/${this.data.getTIMESTAMP()}/`, registro)
    }

    async desativar(registro: CadastroOperadoraSaude, ativo) {

        if (ativo) registro.status = 'SDA0001'
        else registro.status = 'SDA0002'

        let resposta
        await this.alterar(registro).toPromise()
            .then(res => { resposta = res })
            .catch(error => { console.log('error', error) })
        return resposta
    }

}
