export class InfoEvent {
    backgroud: any
    block: boolean
    codigoEmpresa: number
    cpf: string
    cpfMedico: string
    criadoEm: string
    criadoPor: string
    desStatus: string
    especialidade: string
    fotoPerfil: any
    id: string
    medico: string
    motivoConsulta: string
    status: string
    ticketConsultaMedica: string
    tipoAgenda: string
    videoChamada: boolean
    nomePaciente?:string
}