import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { AgendaService } from 'src/app/service/agenda.service';
import { BuscarDataService } from 'src/app/service/buscar-data.service';
import { ConteudoDados, ConteudoDeDadosService } from 'src/app/service/conteudo-de-dados.service';
import { FiltrarDadosService } from 'src/app/service/filtrar-dados.service';

@Component({
  selector: 'app-personalizar-frequencia',
  templateUrl: './personalizar-frequencia.component.html',
  styleUrls: ['./personalizar-frequencia.component.scss']
})
export class PersonalizarFrequenciaComponent implements OnInit {
  @Output('close') close = new EventEmitter
  @Input('type') type: string
  @Input('date') date: string
  @Output('listDates') listDates = new EventEmitter
  condicao: number = 1
  semana = [
    { id: 1, dia: 'Segunda', active: false },
    { id: 2, dia: 'Terça', active: false },
    { id: 3, dia: 'Quarta', active: false },
    { id: 4, dia: 'Quinta', active: false },
    { id: 5, dia: 'Sexta', active: false },
    { id: 6, dia: 'Sábado', active: false },
    { id: 0, dia: 'Domingo', active: false },
  ]
  title: any
  activeDates = []
  meses: { name: string; active: boolean; }[];
  unidadeMedida: ConteudoDados[] = [];
  dias: any[];
  loading: boolean;
  selectUnidadeMedida: number = 1;
  ano = []
  activeMes: number;
  activeAno: number;
  mesesValid = [];

  constructor(
    private _conteudo: ConteudoDeDadosService,
    private _agenda: AgendaService,
    private _dates: BuscarDataService,
    private _filter: FiltrarDadosService
  ) { }

  async ngOnInit() {
    console.log(this.type)
    let getType = await this._conteudo.get('FREQTMP', true, this.type)
    this.unidadeMedida = await this._conteudo.get('UNIDTMP')

    this.title = getType.toString()

    this.meses = this._dates.getMesesAll().map(value => { return { name: value.name, active: false } })

    this.mesesValid = []
    this.dias = []
    this.ano = []

    let startMes = new Date(this.date).getMonth()

    for (let i = 1; i < 31; i++) this.dias.push({ dia: i, active: false })

    for (let i = 0; i < 4; i++) this.ano.push({ value: new Date().getFullYear() + i })

    for (let i = 0; i < this.meses.length; i++) if (i >= startMes) this.mesesValid.push(this.meses[i])




    this.getDate()
  }
  getDate() {
    let getSemana = new Date(this.date).getDay()
    let getDay = new Date(this.date).getDate()
    this.semana.forEach(v => {
      if (v.id == getSemana) v.active = true
    })

    this.dias.forEach(v => {
      if (v.dia == getDay) v.active = true
    })

    this.genereteDates()
  }
  genereteDates(param?) {
    this.activeDates = []

    switch (this.type) {
      case 'FREQTMP1':
        this.addSemanas()
        break
      case 'FREQTMP2':
        this.addMeses()
        break
      case 'FREQTMP3':
        this.addDates(param)
        break
    }

    this.activeDates.sort((a, b) => {
      return new Date(a.date).valueOf() - new Date(b.date).valueOf()
    })
  }
  addSemanas() {
    console.log('addSemana')
    let Qtrange = this.condicao * 7
    let addDates = new Date(this.date).setDate(new Date(this.date).getDate() + Qtrange)
    let getDate = new Date(addDates)
    let range = this._agenda.genereteRangeDatas(new Date(this.date), getDate)
    let validDates = []

    this.semana.filter(v => v.active).forEach(dia => {
      let getDays = range.filter(d => {
        console.log('day:' + new Date(d).getUTCDay(), ' id : ' + dia.id)
        return new Date(d).getUTCDay() == dia.id
      })

      if (getDays.length) validDates.push(...getDays)
    })

    validDates.forEach(value => {
      let day = new Date(value).getUTCDay()
      this.activeDates.push({ date: value, semana: this._dates.getSemanas(day).toString() })
    })

  }
  addMeses() {

    this.dias.filter(d => d.active)
      .forEach(value => {
        let ano = new Date(this.date).getFullYear()
        let mes = new Date(this.date).getMonth() + 1
        let creatDate = `${ano}-${mes}-${value.dia}`
        let genereteDate = new Date(creatDate).toISOString()

        let getIndex = this.activeDates.findIndex(v => v.date == genereteDate)

        if (getIndex >= 0) {
          delete this.activeDates[getIndex]
          this.activeDates = this.activeDates.filter(v => v)
        } else {
          this.activeDates.push({ date: genereteDate })
        }
      })

    this.rangeUnidadeMedida(this.selectUnidadeMedida)
  }
  addDates(dates: Array<any>) {
    if (dates.length < 1) return
    try {
      let get = dates.map(value => { return { date: value.start } })
      this.activeDates = get
    } catch {
      this.activeDates = []
    }
  }
  teste(t) {
    console.log(t)
  }
  getMes(mes) {
    let getIndex = this.meses.findIndex(v => v.name == mes)
    this.activeMes = getIndex
  }
  removeDate(date) {
    this.activeDates.forEach(() => {
      let getIndex = this.activeDates.findIndex(value => value.date == date)
      delete this.activeDates[getIndex]
      this.activeDates = this.activeDates.filter(v => v)
    })

    if (this.type == 'FREQTMP2') {
      let day = new Date(date).getDate()
      console.log(day)
      this.dias.filter(day => day.active).forEach(v => {
        if (v.dia == day) {
          v.active = false
        }
      })
    }
    console.log(date)
  }
  rangeUnidadeMedida(medida) {

    const condicao = this.condicao
    if (this.selectUnidadeMedida < 1) return

    this.loading = true

    let range = parseInt(medida)
    let cont = 0
    while (cont < condicao) {
      cont++
      this.activeDates.forEach(day => {
        let date = new Date(day.date)
        let addDate = new Date(day.date).setDate(date.getDate() + range)
        let getNewDate = new Date(addDate).toISOString()
        let semana = new Date()
        this.activeDates.push({ date: getNewDate, semana: this._dates.getSemanas(day).toString() })
      })
    }

    this.activeDates.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    this.activeDates = this._filter.removeDublic(this.activeDates, 'date')

    this.loading = false
  }
  emitDates() {
    try {
      let getDates = this.activeDates.map(v => v.date.split('T')[0])
      this.listDates.emit(getDates)
    } catch { }

    this.exit()
  }
  exit() {
    this.close.emit(true)
  }

}
